/* eslint-disable max-len */
import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgFolder = (props: SvgProps) => (
  <Svg
    width={32}
    height={32}
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M27 9H16.414L13 5.586A1.982 1.982 0 0 0 11.586 5H5a2 2 0 0 0-2 2v18.078A1.925 1.925 0 0 0 4.923 27H27.11A1.892 1.892 0 0 0 29 25.111V11a2 2 0 0 0-2-2ZM5 7h6.586l2 2H5V7Zm22 18H5V11h22v14Z"
      fill={props.color ?? '#fff'}
    />
  </Svg>
);
export default SvgFolder;
