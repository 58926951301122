import React, { PropsWithChildren } from 'react';
import { DNAContextMenu } from '@alucio/lux-ui';
import { useDispatch } from 'src/state/redux';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import AddGroupModal from './AddGroupModal';
import { useSlideSettingsState }
  from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider.proxy';
import { useGroupState } from './GroupProvider.proxy';
import DeleteGroupModal from './RemoveGroupModal';

const GroupContextMenu: React.FC<PropsWithChildren<{
  groupName: string
  isLocked: boolean,
}>> = (props) => {
  const { groupName, children } = props
  const { renameGroup, removeGroup, removeAllGroupSlides } = useSlideSettingsState()
  const { groups } = useGroupState()
  const dispatch = useDispatch();

  const openRenameGroupModal = () => {
    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: false,
      component: (props) => (
        <AddGroupModal
          defaultValue={groupName}
          groupNames={groups.map(group => group.name)}
          mode="edit"
          onSuccess={( name ) => renameGroup(groupName, name)}
          {...props}
        />
      ),
    }))
  }

  const openRemoveGroupModal = () => {
    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: (props) => (
        <DeleteGroupModal
          groupName={groupName}
          onSuccess={() => removeGroup(groupName)}
          {...props}
        />
      ),
    }))
  }

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        { children }
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        <DNAContextMenu.Item
          onPress={openRenameGroupModal}
          title="Rename group"
        />
        <DNAContextMenu.Item
          onPress={() => removeAllGroupSlides(groupName)}
          title="Remove all slides"
        />
        <DNAContextMenu.Item
          onPress={openRemoveGroupModal}
          title="Delete group"
          status="danger"
        />
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

export default GroupContextMenu
