import React from 'react';
import { CustomDeckORM, FolderItemORM } from 'src/types/types';
import {
  useDNACustomDeckActions,
  customDeckContextOptions,
  CustomDeckActions,
} from './DNACustomDeck.actions';
import { DNAContextMenu } from '@alucio/lux-ui';
import { useAppSettings } from 'src/state/context/AppSettings';
import useCurrentPage from '../hooks/useCurrentPage';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { isSharedEditableFolder } from 'src/utils/foldersHelpers';
import { PermissionType } from '@alucio/aws-beacon-amplify/src/models';

export interface DNACustomDeckContextMenuProps {
  folderItemORM: FolderItemORM,
  children: React.ReactElement,
  actions?: CustomDeckActions[],
  unavailableContent?: boolean,
}

export const DNACustomDeckContextMenu: React.FC<DNACustomDeckContextMenuProps> = (props) => {
  const {
    folderItemORM,
    actions,
    children,
  } = props

  const route = useCurrentPage({ exact: false })
  const isSharedFolders = route?.PATH.includes('shared_folders');
  const featureFlags = useFeatureFlags(
    'enableCustomDecksCollaborationEdit',
    'enableEditSharedFolder',
    'enableShareCustomDeck',
  );
  const { deviceMode, isOnline } = useAppSettings()
  const DNACustomDeckActions = useDNACustomDeckActions()
  /** TODO: Refactor improper use of as assertion */
  const customDeckORM = folderItemORM.relations.itemORM as CustomDeckORM
  const actionsToUse: CustomDeckActions[] = []
  const isDesktop = deviceMode === 'desktop'
  const isOfllineAndSharedFolders = !isOnline && isSharedFolders

  const isReviewRequired: boolean = customDeckORM.meta.version.requiresReview;

  const isOfflineSynced = (!isOnline && customDeckORM.meta.assets.isContentCached)

  const rootFolderORM =
    folderItemORM.relations.parentORM?.relations.parentFolderORM ?? folderItemORM.relations.parentORM;

  const isSharedEditFolder = rootFolderORM &&
    isSharedEditableFolder(rootFolderORM) &&
    featureFlags.enableEditSharedFolder;

  // Button permissions
  const canEdit = !isSharedFolders
    ? !isReviewRequired && (isOnline || isOfflineSynced)
    : !isOfllineAndSharedFolders && featureFlags.enableCustomDecksCollaborationEdit && isSharedEditFolder

  const canDelete = rootFolderORM?.meta.permission === PermissionType.EDIT
  const canAddToFolder =  !isReviewRequired

  const canDuplicate = (!isSharedFolders || isSharedEditFolder)
    ? !isReviewRequired
    : false

  const canPresent = !isSharedFolders
    ? !isReviewRequired && (isOnline || isOfflineSynced)
    // [TODO] - Add check for external dependencies
    : !isReviewRequired && (isOnline || isOfflineSynced)

  const canDownloadAsPdf = isOnline && !isReviewRequired && customDeckORM.meta.permissions.MSLDownload
  const canShareEmail = canDownloadAsPdf &&
    featureFlags.enableShareCustomDeck &&
    customDeckORM.meta.permissions.MSLShare;

  // display the text in the dropdown
  canPresent && actionsToUse.push('present')
  canEdit && actionsToUse.push('edit')
  canDuplicate && actionsToUse.push('duplicate')
  canAddToFolder && actionsToUse.push('addToFolder')
  canDownloadAsPdf && actionsToUse.push('downloadAsPDF')
  canShareEmail && actionsToUse.push('shareEmail')
  canDelete && actionsToUse.push('delete')

  const MenuItems = actions?.length || actionsToUse.length
    ? (actions ?? actionsToUse)
      .map(action => {
        const { onPress, ...rest } = customDeckContextOptions[action]
        return (
          <DNAContextMenu.Item
            disabled={ actions ? !actions.length : !actionsToUse.length}
            key={action}
            status={isDesktop ? 'neutral' : 'dark'}
            onPress={DNACustomDeckActions[action](customDeckORM, folderItemORM)}
            {...rest}
          />
        )
      })
    : (<DNAContextMenu.Item
        title="(No actions available)"
        onPress={() => {}}
    />)

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        { children }
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        { MenuItems }
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

export default DNACustomDeckContextMenu
