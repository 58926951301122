import React, { useEffect, useMemo, useState } from 'react'
import { compose } from 'redux'

import {
  DNABox,
  DNAButton,
  DNACard,
  DNADivider,
  DNAText,
  Iffy,
  Tabs,
} from '@alucio/lux-ui'

import { useSort, withSort } from 'src/components/DNA/hooks/useSort'
import { useAllDocumentsInstance } from 'src/state/redux/selector/document'
import { merge, filters } from 'src/state/redux/document/query'

import DocumentDropzone from './DocumentDropzone/DocumentDropzone';
import DNAPublisherList from 'src/components/DNA/GridList/DNAPublisherGridList'
import DNADocumentFilters,
{
  useDNADocumentFilters,
  withDNADocumentFilters,
} from 'src/components/DNA/Document/DNADocumentFilters/DNADocumentFilters'
import SelectFileButton from 'src/components/SelectFileButton/SelectFileButton';
import DNAPagination from 'src/components/DNA/Pagination/DNAPagination'
import useFixedHeader from '@alucio/lux-ui/src/hooks/useFixedLayout'
import { exportData } from './Export'
import { useCurrentUser } from 'src/state/redux/selector/user'
import { useTenant, useTenantList } from 'src/state/redux/selector/tenant'
import { ScrollView, StyleSheet } from 'react-native';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage'
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import FiltersSidebar, { FilterButton, VariantOptions } from 'src/components/QuickFilters/FiltersSidebar'
import QuickFiltersProvider from 'src/components/QuickFilters/QuickFiltersProvider'
import ActiveUser from 'src/state/global/ActiveUser'

const ExportControl = () => {
  const allDocumentsCSV = useAllDocumentsInstance()
  const tenants = useTenantList();
  const currentUser = useCurrentUser();
  return (
    <DNAButton
      style={{ marginRight: 8 }}
      appearance="outline"
      status="tertiary"
      iconLeft="download"
      onPress={() => exportData(allDocumentsCSV, currentUser, tenants)}
    >
      Export content list
    </DNAButton>
  )
}

const styles = StyleSheet.create({
  mainWrapper: {
    backgroundColor: colors['color-text-white'],
    flex: 1,
    flexDirection: 'column',
    minHeight: 'calc(100vh - 160px)',
    minWidth: 'auto',
    paddingHorizontal: 33,
    paddingVertical: 24,
  },
});

export const DNALibrary: React.FC = () => {
  const [filesToUpload, setFilesToUpload] = useState<File[]>([])
  const featureFlags = useFeatureFlags('enableWebVideoDocuments', 'enablePublisherUpload')

  const { sortSelectorOpts } = useSort()
  const fixedHeaderWidth = useFixedHeader({ offSet: 163 });
  const {
    unpublishedToggle,
    toggleUnpublished,
    filterSelectorOpts,
    resetToDefaultFilters,
    clearFilters,
  } = useDNADocumentFilters()
  const { quickFilters } =  { ...useTenant(ActiveUser.user!.tenantId)?.tenant.config }
  const [filtersVisible, setFiltersVisible] = useState(false);

  const selectorOpts = useMemo(
    () => merge(
      filters.nonDeleted,
      filterSelectorOpts,
      sortSelectorOpts,
    ),
    [sortSelectorOpts, filterSelectorOpts],
  )

  const filteredDocuments = useAllDocumentsInstance(selectorOpts)
  const currentPage = useCurrentPage()

  function onSelectedFiles(files: File[]): void {
    setFilesToUpload(files);
  }
  const tabs = [
    {
      title: 'All',
      style: {
        width: 88,
      },
    },
    {
      title: 'In Progress',
    },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0].title);

  useEffect(() => {
    setSelectedTab(tabs[unpublishedToggle ? 1 : 0].title);

    if (unpublishedToggle) {
      clearFilters()
    }
    else {
      resetToDefaultFilters()
    }
  }, [unpublishedToggle])

  return (
    <QuickFiltersProvider quickFilters={quickFilters ?? []}>
      <DNABox style={styles.mainWrapper} spacing="md">
        <DNABox fill spacing="md" appearance="col">
          {/* Header */}
          <DNABox alignY="end" spacing="between" style={{ maxWidth : fixedHeaderWidth }}>
            <DNABox appearance="col" spacing="md">
              <DNAText testID="page-title" h5 status="subtle">{currentPage?.LABEL}</DNAText>
              {/* Select File Input [TODO] - Web Only */}
              <Iffy is={featureFlags.enablePublisherUpload}>
                <SelectFileButton
                  onSelect={onSelectedFiles}
                  text="Upload"
                  multiple={true}
                  rounded="md"
                  size="sm"
                  showContextMenu={featureFlags.enableWebVideoDocuments}
                />
              </Iffy>
            </DNABox>
            <DNABox>
              <ExportControl />
            </DNABox>
          </DNABox>
          <DNACard appearance="outline">
            <Tabs
              selectedTab={selectedTab}
              selectedTabVariant="primary"
              tabs={tabs}
              onSelectTab={(tab) => {
                if (selectedTab !== tab) {
                  toggleUnpublished();
                }
              }}
              style={{ paddingTop: 12 }}
            />
            <DNADivider />
            {/* Content */}
            <DocumentDropzone
              files={filesToUpload}
              toggleMyUnpublishedDocument={() => {
                if (!unpublishedToggle) {
                  toggleUnpublished()
                }
              }}
              disableDrag={!featureFlags.enablePublisherUpload}
              onCloseModal={() => { setFilesToUpload([]) }}
            >
              <DNABox appearance="col" spacing="md">
                <DNABox
                  spacing="between"
                  alignY="start"
                  childFill={0}
                  style={{ maxWidth :  fixedHeaderWidth, paddingTop: 16, paddingLeft: 12, marginBottom: -8 }}
                >
                  <DNABox spacing="sm">
                    <FilterButton
                      setFiltersVisible={setFiltersVisible}
                      filtersVisible={filtersVisible}
                      variant={VariantOptions.publisher}
                    />
                    <DNADocumentFilters.Chips documents={filteredDocuments} chipsStatus="primary" />
                  </DNABox>
                </DNABox>
                <DNADivider />
                <DNABox appearance="row">
                  {filtersVisible && <DNABox as={ScrollView} style={{ maxHeight: 'calc(100vh - 350px)' }}>
                    <FiltersSidebar setVisible={setFiltersVisible} />
                  </DNABox>}
                  <DNABox style={{ paddingLeft: 16 }}>
                    <DNAPagination query={selectorOpts}>
                      <DNABox appearance="col" spacing="md">
                        <DNABox style={filtersVisible ? { borderWidth: 1, borderColor: colors['color-gray-100'] } : {}}>
                          <DNAPublisherList />
                        </DNABox>
                        <DNABox style={{ alignSelf: 'flex-start', padding: 12 }}>
                          <DNAPagination.Nav />
                        </DNABox>
                      </DNABox>
                    </DNAPagination>
                  </DNABox>
                </DNABox>
              </DNABox>
            </DocumentDropzone>
          </DNACard>
        </DNABox>
      </DNABox>
    </QuickFiltersProvider>
  );
}

export default compose(
  withDNADocumentFilters,
  withSort,
)(DNALibrary)
