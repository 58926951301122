import format from 'date-fns/format'
import {
  CustomFieldDefinition,
  CustomFieldUsage,
  FieldDataType,
  FieldStatus,
  Tenant,
} from '@alucio/aws-beacon-amplify/src/models';
import { CurrentUser } from 'src/state/redux/selector/user';
import { TenantORM } from 'src/state/redux/selector/tenant';
import { UserORM } from 'src/types/orms';

enum INTERNAL_DATA_TYPE {
  'BOOLEAN' = 'BOOLEAN'
}

enum INTERNAL_FIELD_TYPE {
  'SYSTEM' = 'SYSTEM',
  'TENANT_RESTRICT' = 'TENANT_RESTRICT',
  'TENANT_DEFAULT' = 'TENANT_DEFAULT'
}

const config = {
  'version': '0.0.1',
  'defaultFields': [
    {
      'name': 'EMAIL',
      'systemName': 'email',
      'required': true,
      'dataType': FieldDataType.STRING,
      'fieldType': INTERNAL_FIELD_TYPE.SYSTEM,
    },
    {
      'name': 'STATUS',
      'systemName': 'status',
      'required': true,
      'dataType': FieldDataType.STRING,
      'fieldType': INTERNAL_FIELD_TYPE.SYSTEM,
    },
    {
      'name': 'GIVEN_NAME',
      'systemName': 'givenName',
      'required': true,
      'dataType': FieldDataType.STRING,
      'fieldType': INTERNAL_FIELD_TYPE.SYSTEM,
    },
    {
      'name': 'FAMILY_NAME',
      'systemName': 'familyName',
      'required': true,
      'dataType': FieldDataType.STRING,
      'fieldType': INTERNAL_FIELD_TYPE.SYSTEM,
    },
    {
      'name': 'PHONE_NUMBER',
      'systemName': 'phoneNumber',
      'required': false,
      'dataType': FieldDataType.STRING,
      'fieldType': INTERNAL_FIELD_TYPE.SYSTEM,
    },
    {
      'name': 'ROLE',
      'systemName': 'role',
      'required': true,
      'dataType': FieldDataType.CATEGORICAL,
      'fieldType': INTERNAL_FIELD_TYPE.SYSTEM,
      'values': [
        'TenantPublisher',
        'TenantViewer',
      ],
    },
    {
      'name': 'EXCLUDE_REPORTING',
      'systemName': 'isExcludedFromReporting',
      'required': false,
      'dataType': INTERNAL_DATA_TYPE.BOOLEAN,
      'fieldType': INTERNAL_FIELD_TYPE.SYSTEM,
    },
  ],
}

interface FieldExportConfig {
  dataType: FieldDataType | INTERNAL_DATA_TYPE,
  fieldType?: INTERNAL_FIELD_TYPE,
  fieldId?: string,
  name: string,
  systemName?: string,
}

function getFieldConfigFromTenant(tenant: Tenant): FieldExportConfig[] {
  return tenant.config.customFields?.reduce<FieldExportConfig[]>((acc, field: CustomFieldDefinition) => {
    if (field.status === FieldStatus.DISABLED || !field.usage.includes(CustomFieldUsage.USER_FILTER)) {
      return acc;
    }

    acc.push({
      fieldId: field.id,
      name: field.fieldLabel,
      dataType: field.fieldType as FieldDataType,
    });
    return acc
  }, []) || []
}

function getUserFieldConfigForTenant(tenant: Tenant): FieldExportConfig[] {
  const tenantLabelConfig = getFieldConfigFromTenant(tenant);

  const expandedTenantlabelConfigs = tenantLabelConfig.reduce<FieldExportConfig[]>((acc, config) => {
    acc.push({
      ...config,
      fieldType: INTERNAL_FIELD_TYPE.TENANT_RESTRICT,
      name: `RESTRICT:${config.name.toUpperCase()}`,
    });
    acc.push({
      ...config,
      fieldType: INTERNAL_FIELD_TYPE.TENANT_DEFAULT,
      name: `DEFAULT:${config.name.toUpperCase()}`,
    });
    return acc;
  }, []);
  const fieldConfigs = [...config.defaultFields, ...expandedTenantlabelConfigs]

  return fieldConfigs;
}

export const exportData = (users: UserORM[], currentUser: CurrentUser, tenant?: TenantORM): void => {
  if (!users.length || !tenant) return;

  const fieldConfigs = getUserFieldConfigForTenant(tenant.tenant);

  const rows = users.map((user) => {
    const { lockedFilters, defaultFilters } = user.meta.customFilterValues;

    return fieldConfigs.map((field) => {
      if (field.fieldType === 'SYSTEM') {
        if (field.dataType === INTERNAL_DATA_TYPE.BOOLEAN) {
          return user.model[`${field.systemName}`] === null
            ? '' : `${user.model[`${field.systemName}`] ? 'YES' : 'NO'}`;
        }

        return user.model[`${field.systemName}`] ? user.model[`${field.systemName}`] + '' : '';
      } else if (field.fieldType === INTERNAL_FIELD_TYPE.TENANT_DEFAULT) {
        if (defaultFilters[`${field.fieldId}`]) {
          return defaultFilters[`${field.fieldId}`].displayValues.join('|');
        }
        return '';
      } else if (field.fieldType === INTERNAL_FIELD_TYPE.TENANT_RESTRICT) {
        if (lockedFilters[`${field.fieldId}`]) {
          return lockedFilters[`${field.fieldId}`].displayValues.join('|');
        }
        return '';
      }
    })
  });

  const headerRow = fieldConfigs.map((field) => field.name);
  const headerLine = `"${headerRow.join('","')}"`;
  const lines = rows.map((row) => `"${row.map(d => d?.replace(/"/g, '""')).join('","')}"`);

  // add headers
  const csvContent = 'data:text/csv;charset=utf-8,' + `${headerLine}\n${lines.join('\n')}`;
  const tenantName = tenant.tenant.name.replace(/[^a-z0-9+]+/gi, '_').substring(0, 50)

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${tenantName || ''}usersReport${format(new Date(), 'MM-dd-yyyy')}.csv`);
  document.body.appendChild(link);

  link.click();
}
