import {
  Hub,
  HubStatus,
  HubSection,
} from '@alucio/aws-beacon-amplify/src/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { commonReducers, initialState, SliceState } from './common';
import { DataStore } from '@aws-amplify/datastore';
import ActiveUser from '../../global/ActiveUser';
import * as logger from 'src/utils/logger';

const sliceName = 'hub'
const { reducers, extraReducers } = commonReducers<Hub>(sliceName)

interface CreateHubPayload {
  name: string
  status: HubStatus
  hubSections: HubSection[]
  meetingId?: string,
  callback: (hub: Hub) => void,
}

interface EditHubPayload {
  hub: Hub,
  updatedContent: Hub
  callback?: () => void
}

const hubSlice = createSlice({
  name: sliceName,
  initialState: initialState<Hub>(),
  reducers: {
    ...reducers,
    createHub: (
      _state: SliceState<Hub>,
      action: PayloadAction<CreateHubPayload>,
    ): void => {
      const { payload } = action;
      const user = ActiveUser.user
      const now = new Date().toISOString()
      const hub = new Hub({
        tenantId: user!.tenantId,
        name: payload.name,
        status: payload.status,
        createdAt: now,
        createdBy: user!.id,
        updatedAt: now,
        updatedBy: user!.id,
        hubSections: payload.hubSections || [],
        meetingId: payload.meetingId,
      });

      DataStore.save(hub).then((hub) => {
        payload.callback?.(hub)
        analytics?.track('HUB_CREATE', {
          action: 'CREATE',
          category: 'HUB',
          hubId: hub.id,
        });
      }).catch((e) => {
        const errorText = 'Failed to create new hub'
        logger.hub.hubManagement.error(errorText, e)
        throw Error(errorText, e)
      })
    },
    updateHub: (_state: SliceState<Hub>, action: PayloadAction<EditHubPayload>): void => {
      const { hub, updatedContent, callback } = action.payload
      const user = ActiveUser.user
      const now = new Date().toISOString()

      const updatedHub = Hub.copyOf(hub, (updated) => {
        updated.name = updatedContent.name
        updated.updatedAt = now
        updated.updatedBy = user!.id
        updated.hubSections = updatedContent.hubSections || []
      })

      DataStore.save(updatedHub).then(() => {
        callback?.()
      }).catch((e) => {
        const errorText = 'Failed to update hub'
        logger.hub.hubManagement.error(errorText, e)
        throw Error(errorText, e)
      })
    },
    deleteHub: {
      prepare: (hub: Hub) => {
        analytics?.track('HUB_DELETE', {
          action: 'DELETE',
          category: 'HUB',
          hubId: hub.id,
        });
        return {
          payload: {
            model: Hub,
            entity: hub,
            updates: {
              updatedAt: (new Date().toISOString()),
              updatedBy: ActiveUser?.user?.id,
              status: HubStatus.DELETED,
            },
          },
        }
      },
      reducer: reducers.save,
    },
  },
  extraReducers,
});

export default hubSlice;
export const hubActions = {
  ...hubSlice.actions,
};
