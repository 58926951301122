import React from 'react'
import { ImageBackground, StyleSheet } from 'react-native'
import { DNABox, DNAText, Iffy } from '@alucio/lux-ui'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { PlayerMode } from '@alucio/core'

const styles = StyleSheet.create({
  initialTextContainer: {
    backgroundColor: colors['color-black-transparent-40'],
  },
  initialTextCopy: { maxWidth: 558, textAlign: 'center', lineHeight: 24 },
});

interface MeetingBackgroundProps {
  mode: PlayerMode,
  hasPresented: boolean
}

export const MeetingBackground: React.FC<MeetingBackgroundProps> = ({
  mode,
  hasPresented,
}) => {
  return (
    <ImageBackground
      source={require('../../../assets/images/beacon-presentation-bg.jpg')}
      style={{ flex: 1 }}
    >
      <Iffy is={mode === 'NON_INTERACTIVE' && !hasPresented}>
        <DNABox
          appearance="col"
          alignX="center"
          alignY="center"
          fill
          spacing="xl"
          style={styles.initialTextContainer}
        >
          <DNABox
            appearance="col"
            alignX="center"
          >
            <DNAText h1 bold status="basic">Share this window with your attendees.</DNAText>
            <DNAText h1 bold status="basic">Ensure it remains visible.</DNAText>
          </DNABox>
          <DNABox
            spacing="md"
            alignX="center"
            appearance="col"
          >

            <DNAText
              status="basic"
              style={styles.initialTextCopy}
            >
              {/* eslint-disable-next-line max-len */}
              Most screen sharing applications only focus on windows that can be seen. If your window gets covered, viewers will no longer be able to see what you’re sharing.
            </DNAText>
          </DNABox>

        </DNABox>
      </Iffy>
    </ImageBackground>
  )
}

export default MeetingBackground
