import React, { useRef } from 'react'
import debounce from 'lodash/debounce';
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage'
import { useCanPerformSearch } from 'src/state/redux/selector/documentSearch/documentSearch'
import { DNABox, DNADivider, DNAText, luxColors } from '@alucio/lux-ui'
import { Input } from '@ui-kitten/components'

import { TabOptions, useFileDrawerContext } from './FileDrawerProvider'
import DNAActionBar from '../DNA/ActionBar/DNAActionBar'
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';
import { DNATextProps } from '@alucio/lux-ui/src/components/type/DNAText/DNAText';

const FileDrawerTab: React.FC = () => {
  const {
    selectedTab,
    setSelectedTab,
    localSearchText,
    setLocalSearchText,
    setSearchText,
  } = useFileDrawerContext()

  const getTabAppearance = (tabID:TabOptions) => {
    const appearance:DNAButtonProps['appearance'] = selectedTab === tabID ? 'filled' : 'ghost'
    return appearance
  }
  const getTabStatus = () => {
    const status:DNAButtonProps['status'] = 'gray'
    return status
  }
  const getTextStatus = (tabID:TabOptions) => {
    const status:DNATextProps['status'] = selectedTab === tabID ? 'dark' : 'flat'
    return status
  }

  const route = useCurrentPage({ exact: false })
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const canPerformSearch = useCanPerformSearch();
  const tabSelectedBeforeSearch = useRef<TabOptions>(TabOptions.BOOKMARKS)
  const inputRef = useRef<Input>(null)

  const handleTabSelect = (tab: TabOptions) => {
    setLocalSearchText('')
    setSelectedTab(tab)
  }

  const onSearchTextChange = (text: string) => {
    const canTextBeSearchable = canPerformSearch(text);
    setLocalSearchText(text);

    if (text) {
      debounce(() => {
        setSearchText(text);
      }, 1000)();
    }

    if (canTextBeSearchable && selectedTab && selectedTab !== 'Search results') {
      tabSelectedBeforeSearch.current = selectedTab;
      setSelectedTab(TabOptions.SEARCH_RESULTS);
    } else if (!canTextBeSearchable && selectedTab === 'Search results') {
      setSelectedTab(tabSelectedBeforeSearch.current);
    }
  }

  return (
    <DNABox alignY="center" style={{ paddingHorizontal: 32, justifyContent: 'space-between' }}>
      <DNABox alignY="center" style={{ paddingTop: 24 }} appearance="col" fill spacing="md">
        <Input
          ref={inputRef}
          onChangeText={onSearchTextChange}
          placeholderTextColor={luxColors.contentPanelBackground.quinary}
          value={localSearchText}
          placeholder={'Search files'}
          testID="search-presenation-input"
        />
        <DNABox>
          <DNAActionBar
            spacing="sm"
            fill
            itemDefaults={{
              size:'md',
              padding:'sm',
            }}
          >

            <DNAActionBar.Item
              appearance={getTabAppearance(TabOptions.BOOKMARKS)}
              status={getTabStatus()}
              hidden={isPublisherRoute}
              onPress={() => handleTabSelect(TabOptions.BOOKMARKS)}
              key={TabOptions.BOOKMARKS}
              testID="DNATab"
            >
              <DNAText status={getTextStatus(TabOptions.BOOKMARKS)} b1>{TabOptions.BOOKMARKS}</DNAText>
            </DNAActionBar.Item>

            <DNAActionBar.Item
              appearance={getTabAppearance(TabOptions.LIBRARY)}
              status={getTabStatus()}
              onPress={() => handleTabSelect(TabOptions.LIBRARY)}
              key={TabOptions.LIBRARY}
              testID="DNATab"
            >
              <DNAText status={getTextStatus(TabOptions.LIBRARY)} b1>{TabOptions.LIBRARY}</DNAText>
            </DNAActionBar.Item>

          </DNAActionBar>
        </DNABox>
        <DNADivider />
      </DNABox>
    </DNABox>
  );
}

export default FileDrawerTab
