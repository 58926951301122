import React, { useEffect, useState, useRef, useCallback, Fragment } from 'react';
import { DNABox, DNAButton, DNADivider, DNAIcon, DNAText, Icon, Iffy } from '@alucio/lux-ui';
import InputComponent from 'src/components/Publishers/InputComponent';
import useFileTextSearch, { MatchedPage, PageData } from '../useFileTextSearch';
import { FlatList, Pressable, StyleSheet, TouchableOpacity } from 'react-native';

import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useContentViewerModalState } from '../../ContentPreviewModal/state/ContentViewerModalStateProvider';
import { useDispatch } from 'src/state/redux';
import { presentationControlActions } from 'src/state/redux/slice/PresentationControl/presentationControl';
import { PlayerActions } from '@alucio/core';
import { DocumentVersionORM, PageExtended } from 'src/types/types';
import { fetchJsonFromCloudfront } from 'src/utils/loadCloudfrontAsset/common';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import debounce from 'lodash/debounce';
import { LuxSizeEnum } from '@alucio/lux-ui/src/typings';
import { highlightText } from 'src/utils/searchHelpers';

/** TODO: much of this content has been segemented out into shareable files.
 * I have left this implementation intact to reduce the impact of these
 * updates on the existing implementaion, however, this should be refactored
 * in the upcoming story to refresh the content preview modal.
 * https://alucioinc.atlassian.net/browse/BEAC-2930 */

const styles = StyleSheet.create({
  searchBar: {
    backgroundColor: colors['color-text-white'],
    borderColor: colors['color-gray-100'],
    borderRadius: 4,
    width: 252,
    overflow: 'hidden',
  },
  subtitle: {
    color: colors['color-gray-300'],
  },
  menuButtonContainer: {
    borderColor: colors['color-gray-100'],
    borderRadius: 4,
    borderWidth: 1,
    marginRight: 16,
  },
  searchIcon: {
    color: colors['color-gray-300'],
    height: 16,
    width: 16,
  },
  thumbnail: {
    marginLeft: 16,
    marginRight: 8,
    marginVertical: 8,
    height: 54,
    width: 96,
  },
  mainContainer: {
    borderColor: colors['color-gray-100'],
    borderRightWidth: 1,
    width: 332,
    backgroundColor: colors['color-text-white'],
  },
})

interface SearchResultsItemProps {
  item: MatchedPage,
  folderKey: string,
  searchQuery: string,
}
const SearchResultsItem: React.FC<SearchResultsItemProps> = React.memo(({ item, folderKey, searchQuery }) => {
  const matches = item.matches.slice(0, 3); // render only up to 3 matches
  return (
    <DNABox appearance="row">
      <DNABox style={styles.thumbnail}>
        <DNAThumbnail
          s3URL={`${folderKey}${item.pageNumber}_small_thumb.png`}
          height={54}
          width={96}
        />
      </DNABox>
      <DNABox fill appearance="col">
        <DNABox appearance="row" spacing="between" style={{ marginTop: 8, marginRight: 16 }}>
          <DNAText bold>Slide {item.labelNumber}</DNAText>
          <DNAText style={styles.subtitle}>
            {item.matches.length} match{item.matches.length > 1 ? 'es' : ''}
          </DNAText>
        </DNABox>
        {matches.map((match, index) => {
          return (
            <Fragment key={`slide-${item.pageNumber}-match-${index}`}>
              <DNAText style={{ marginBottom: 8, marginRight: 16 }} numberOfLines={3}>
                {highlightText(match, searchQuery)}
              </DNAText>
              <Iffy is={index < item.matches.length - 1}>
                <DNADivider />
              </Iffy>
            </Fragment>
          )
        })}
      </DNABox>
    </DNABox>
  )
});

interface SearchResultsProps {
  matches: MatchedPage[],
  searchQuery: string,
  folderKey: string,
  activeIndex: number,
  setActiveIndex: (index: number) => void,
}

const SearchResults: React.FC<SearchResultsProps> = React.memo((
  { matches, searchQuery, folderKey, activeIndex, setActiveIndex }) => {
  const { setActiveSlide } = useContentViewerModalState();
  const dispatch = useDispatch();
  const flatlistRef = useRef<FlatList<MatchedPage> | null>(null);

  useEffect(() => {
    if (flatlistRef.current && matches.length && activeIndex > -1) {
      flatlistRef.current.scrollToIndex({ animated: true, index: activeIndex })
    }
  }, [activeIndex]);

  return (
    <FlatList
      data={matches}
      keyExtractor={(item) => `slide-${item.pageNumber}`}
      renderItem={({ item, index }) => {
        return (
          <Pressable
            onPress={() => {
              setActiveIndex(index);
              setActiveSlide(item.pageNumber);
              dispatch(presentationControlActions.triggerPresentationAction({
                action: PlayerActions.gotoSlide,
                param: item.pageNumber,
              }))
            }}
            style={{ backgroundColor : activeIndex === index ? colors['color-gray-80'] : undefined }}
          >
            <SearchResultsItem item={item} folderKey={folderKey} searchQuery={searchQuery} />
          </Pressable>
        )
      }}
      style={{ height: 300, overflow: 'scroll' }}
      ref={flatlistRef}
      ItemSeparatorComponent={DNADivider}
    />
  )
});
interface ContentPreviewModalVariantProps {
  documentVersionORM: DocumentVersionORM
  onClose: () => void,
  visiblePages: PageExtended[],
}
const ContentPreviewModalVariant: React.FC<ContentPreviewModalVariantProps> =
  React.memo(({ documentVersionORM, onClose, visiblePages }) => {
    const [pages, setPages] = useState<PageData[] | []>([]);
    const [thumbnailKey, setThumbnailKey] = useState<string>('');
    const { searchText, setSearchText, setActiveSlide } = useContentViewerModalState();
    const {
      matches,
      searchQuery,
      search,
      activeIndex,
      setActiveIndex,
      totalMatchCount,
    } = useFileTextSearch(pages, visiblePages, documentVersionORM, setActiveSlide);
    const [, refresh] = useState(false);

    useEffect(() => {
      const loadJson = async () => {
        const tenantId = documentVersionORM.model.tenantId;
        const documentId = documentVersionORM.model.documentId;
        const modelId = documentVersionORM.model.id;
        // TODO: update key to documentVersionORM.model.convertedFolderKey/json/ after clean up for v2
        const res = await fetchJsonFromCloudfront(`${tenantId}/${documentId}/${modelId}/v2/json/Pages.json`);
        setThumbnailKey(`${tenantId}/${documentId}/${modelId}/v2/thumbnails/`);
        const json = await res.json();
        setPages(json.pages)
      }
      if (documentVersionORM) {
        loadJson();
      }
    }, [documentVersionORM])

    const getMatchNumber = useCallback(() => {
      let num = 1;
      for (let i = 0; i < activeIndex; i++) {
        num += matches[i].matches.length;
      }
      return num;
    }, [matches, activeIndex]);

    useEffect(() => {
      if (searchText) {
        debouncedSearch(searchText);
      }
      else {
        search(searchText);
      }
    }, [searchText])

    const debouncedSearch = useCallback(debounce((text) => {
      search(text);
      refresh(false); // this is a work around for 'No results found' briefly flashing
    }, 500), [])

    return (
      <DNABox
        appearance="col"
        style={styles.mainContainer}
      >
        <DNABox appearance="row" style={{ marginLeft: -8 }}>
          <InputComponent
            value={searchText}
            onChangeText={setSearchText}
            required={false}
            size={LuxSizeEnum.sm}
            autoFocus={true}
            placeHolder="Search"
            inputStyle={styles.searchBar}
            getLeftIconFunction={() => (
              <TouchableOpacity>
                <Icon style={styles.searchIcon} name="magnify" />
              </TouchableOpacity>
            )
        }
            getRightIconFunction={() => (
              <Iffy is={searchText}>
                <Pressable onPress={() => setSearchText('')}>
                  <DNAIcon.Styled
                    name="close-circle"
                    appearance="ghost"
                    status="tertiary"
                  />
                </Pressable>
              </Iffy>
            )
      }
          />
          <DNAButton
            appearance="outline"
            status="info"
            iconLeft="close"
            onPress={onClose}
            style={{ marginTop: 16, marginRight: 16, height: 32, width: 32 }}
          />
        </DNABox>
        <Iffy is={searchQuery.length >= 3}>
          <DNABox appearance="row" alignY="center" style={{ marginHorizontal: 16, marginBottom: 16 }}>
            <DNABox style={styles.menuButtonContainer}>
              <DNAButton
                appearance="ghost"
                status="tertiary"
                size="sm"
                iconLeft="menu-up"
                onPress={() => setActiveIndex((prev) => Math.max(0, prev - 1))}
                disabled={!matches.length || activeIndex < 0}
              />
              <DNADivider vertical />
              <DNAButton
                appearance="ghost"
                status="tertiary"
                size="sm"
                iconLeft="menu-down"
                onPress={() => setActiveIndex((prev) => Math.min(matches.length - 1, prev + 1))}
                disabled={!matches.length}
              />
            </DNABox>
            {matches.length > 0 ? (
              <DNAText style={styles.subtitle}>
                {getMatchNumber()} of {totalMatchCount} match{totalMatchCount > 1 ? 'es' : ''}
              </DNAText>)
              : <DNAText style={{ color: colors['color-danger-500'] }}>No results found</DNAText>}
          </DNABox>
        </Iffy>
        <DNADivider />
        <SearchResults
          matches={matches}
          searchQuery={searchQuery}
          folderKey={thumbnailKey}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />

      </DNABox>
    )
  });

export default ContentPreviewModalVariant;
