import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { ContentPresented, MeetingStatus, MeetingType } from '@alucio/aws-beacon-amplify/src/models';
import { useDispatch } from 'src/state/redux';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { MeetingORM } from 'src/types/orms';
import * as logger from 'src/utils/logger'
import { useIsExternalPlayer } from 'src/screens/Loading';
import DNACommonConfirmation from 'src/components/DNA/Modal/DNACommonConfirmation';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { MeetingComponentsVisibility } from './useMeetingsComponentVisibility';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { isFolderItemORM, isWebHtmlOrVideoDocumentVersionORM } from 'src/types/typeguards';
import { sharedFolderActions, useFetchSharedFolders } from 'src/state/redux/slice/sharedFolder';
import useMeetingExternalWindowDependencies from './useExternalWindowDependencies';
import debounce from 'lodash/debounce';

const REOPEN_MODAL_TEXT = `We noticed you closed your content window. \n
To continue sharing content, re-open your content window and share via your video conferencing tool.`;
const VIDEO_TYPE_DOC = ['MP4', 'PPTX'];

export enum PopoutContentBCTypes {
  endMeeting = 'endMeeting',
  getSharedFolders = 'getSharedFolders',
  getSharedFolderDependencies = 'getSharedFolderDependencies',
  popoutWindowLoaded = 'popoutWindowLoaded',
  setActivePresentation = 'setActivePresentation',
  setContentPresented = 'setContentPresented',
  toggleMeetingType = 'toggleMeetingType'
}

interface PopoutContentBCPayload {
  type: Exclude<PopoutContentBCTypes,
    PopoutContentBCTypes.setActivePresentation |
    PopoutContentBCTypes.getSharedFolderDependencies |
    PopoutContentBCTypes.setContentPresented>
  meetingId: string
}

interface SourceVideoLoadedPayload {
  type: 'SOURCE_VIDEO_LOADED'
}

interface SyncIframeReferencePayload {
  type: 'SYNC_IFRAME_REFERENCE'
}

export type VideoViewerBCPayloads = SourceVideoLoadedPayload | SyncIframeReferencePayload

interface MeetingLoadedPayload extends Omit<PopoutContentBCPayload, 'type'> {
  type: PopoutContentBCTypes.setActivePresentation
  activePresentationModelId?: string
  presentationPageNumber?: number
  isWebHtmlOrVideo: boolean
}

interface SetContentPresentedPayload extends Omit<PopoutContentBCPayload, 'type'> {
  type: PopoutContentBCTypes.setContentPresented,
  contentPresented: ContentPresented[],
}

interface GetSharedFolderDependencies extends Omit<PopoutContentBCPayload, 'type'> {
  type: PopoutContentBCTypes.getSharedFolderDependencies
  folderId: string
  parentFolderId: string
}

export type PopoutContentBCPayloads = SetContentPresentedPayload | PopoutContentBCPayload |
  MeetingLoadedPayload | GetSharedFolderDependencies;

/** NOTE: Popout window config */
const target = '_blank'
const leftMargin = (window.screen.width * 0.1) / 2;
const topMargin = (window.screen.height * 0.1) / 2;

const usePopOutWindowFeatures = (): string => {
  const enableNew3PC = useFeatureFlags('enableNew3PC');
  const height = window.screen.height - (window.screen.height * (enableNew3PC ? 0.3 : 0.12));
  const width = window.screen.width - (window.screen.width * (enableNew3PC ? 0.25 : 0.1));

  return useMemo(() =>
  // eslint-disable-next-line max-len
    `directories=no, resizable=0, titlebar=no, toolbar=no, location=no, status=no, menubar=no, scrollbars=no, toolbar=0, scrollbars=0, location=0, statusbar=0, menubar=0, height=${height}, width=${width},left=${leftMargin}, top=${topMargin}`
  , [enableNew3PC]);
};

const useMeetingsPopoutContentState = (
  meetingORM: MeetingORM | undefined,
  endMeeting: () => void,
  toggleMeetingType: () => void,
  isMainPlayer: boolean,
  meetingComponentsVisibility: MeetingComponentsVisibility) => {
  const { fetchSharedFolders } = useFetchSharedFolders();
  const { onPresentFolderItem } = useMeetingExternalWindowDependencies(meetingORM?.model.id);
  const broadcastChannel = useRef<BroadcastChannel<PopoutContentBCPayloads>>();
  const videoViewerChannel = useRef<BroadcastChannel<VideoViewerBCPayloads>>();
  const featureFlags = useFeatureFlags('enableNew3PC', 'enableMultipleIFrames');
  const features = usePopOutWindowFeatures();
  const url = new URL(`${window.location.origin}/meeting-content/${meetingORM?.model.id}`);
  // WE NEED TO APPEND THESE 2 FEATURE FLAGS HERE BECAUSE THE POPOUT WINDOW DOES NOT HAVE ACCESS TO IT
  url.searchParams.append('enableNew3PC', `${featureFlags.enableNew3PC}`)
  url.searchParams.append('enableMultipleIFrames', `${featureFlags.enableMultipleIFrames}`)
  const isExternalPlayer = useIsExternalPlayer();
  const isVirtual = meetingORM?.model.type === MeetingType.VIRTUAL;
  const dispatch = useDispatch();
  const {
    activePresentation,
    contentPresented,
    presentations,
    unsetActivePresentation,
    setContentPresented,
    setActiveAndOpenedPresentations,
  } = useContent();
  const popoutReference = useRef<Window | null>();
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const [triggerToAttachIframeReference, setTriggerToAttachIframeReference] = useState<boolean>(false)
  const broadcastMessageHandlerRef = useRef(broadcastMessageHandler);
  // THE IDEA OF THIS IS SO THE "SLAVE WINDOW" KEEPS TRACK OF THE ACTIVE PRESENTATION OF //
  // THE MAIN WINDOW TO SET IT TO ITS CONTEXT BASED ON THE OPENED PRESENTATION'S LIST  //
  const [mainWindowActivePresentation, setMainWindowActivePresentation] =
    useState<{pageNumber?: number, ormId: string} | undefined>();
  const debouncedHandlePresentationChange =
    useCallback(debounce((contentPresentedToSend: ContentPresented[], meetingId: string) =>
      broadcastContentPresentationMessage(contentPresentedToSend, meetingId), 1000), []);

  // BROADCASTS AN ACTIVE PRESENTATION MESSAGE TO UPDATE THE SHARED WINDOW //
  useEffect(() => {
    const shouldBroadcastActivePresentationMessage = featureFlags.enableNew3PC &&
      isMainPlayer && meetingORM?.model.type === MeetingType.VIRTUAL;
    if (shouldBroadcastActivePresentationMessage) {
      broadcastActivePresentationMessage(meetingORM?.model.id);
    }
  }, [activePresentation?.currentPresentablePage.id]);

  // UPDATES THE LOCALSTORAGE WITH THE DEPENDENCIES THE EXTERNAL WINDOW NEEDS TO FETCH //
  useEffect(() => {
    const shouldUpdateLocalStorage = featureFlags.enableNew3PC && isMainPlayer;
    if (shouldUpdateLocalStorage && isFolderItemORM(activePresentation?.presentable.orm)) {
      onPresentFolderItem(activePresentation?.presentable.orm!);
    }
  }, [activePresentation?.presentable.id]);

  // UPDATES THE MESSAGE HANDLER FUNCTION //
  useEffect(() => {
    broadcastMessageHandlerRef.current = broadcastMessageHandler;
  }, [presentations, activePresentation, contentPresented, isMainPlayer]);

  // SENDS A MESSAGE TO THE SHARED WINDOW TO UPDATE ITS CONTENT PRESENTED //
  useEffect(() => {
    if (featureFlags.enableNew3PC && isMainPlayer && isVirtual) {
      debouncedHandlePresentationChange(contentPresented || [], meetingORM?.model.id);
    }
  }, [contentPresented]);

  // UPDATES THE PRESENTATIONS/ACTIVE PRESENTATION OF THE SHARED WINDOW //
  useEffect(() => {
    if (featureFlags.enableNew3PC && !isMainPlayer) {
      setActiveAndOpenedPresentations(
        mainWindowActivePresentation?.ormId,
        mainWindowActivePresentation?.pageNumber,
      );
    }
  }, [meetingORM?.model.contentPresented, mainWindowActivePresentation]);

  useEffect(() => {
    const documentVersionORM = activePresentation?.currentPresentablePage.documentVersionORM;
    if (!documentVersionORM) return;
    const isVideoDocument = VIDEO_TYPE_DOC.includes(documentVersionORM.model.type);
    const shouldAttemptToAttachParentIFrameReference = featureFlags.enableNew3PC &&
      !isExternalPlayer && isVideoDocument && popoutReference.current && meetingORM?.model.type === MeetingType.VIRTUAL;
    // THIS BLOCK IS TO ATTACH THE POPOUT WINDOW REFERENCE (PRESENTER WINDOW) INTO THE CURRENT IFRAME,
    // SO WE HAVE ACCESS TO IT FOR VIDEO STREAMING IN PRESENTATION-PLAYER-PDF
    if (shouldAttemptToAttachParentIFrameReference) {
      const attemptToAttachIframeReference = (): boolean => {
        const documentVersionId = documentVersionORM.model.id;
        const documentElement = popoutReference.current?.document;
        logger.meetingsPopoutContent.debug('Checking if both iframes are loaded....');
        const presenterIframe = documentElement
          ?.getElementById(`player-iframe-${documentVersionId}`) as HTMLIFrameElement | null;
        const shareWindowIframe = document
          .getElementById(`player-iframe-${documentVersionId}`) as HTMLIFrameElement | null;

        let parentVideos: HTMLCollectionOf<HTMLVideoElement> | HTMLVideoElement | null = null;
        if (documentVersionORM.model.type === 'MP4') {
          parentVideos =
            presenterIframe?.contentWindow?.document.getElementById('main-player') as HTMLVideoElement | null;
        } else {
          parentVideos = presenterIframe?.contentWindow?.document.getElementsByTagName('video') ?? null;
        }

        if (presenterIframe && shareWindowIframe && parentVideos && shareWindowIframe.contentWindow) {
          logger.meetingsPopoutContent.info('Attaching presenter iframe reference to share window iframe...');
          // @ts-ignore
          shareWindowIframe.contentWindow.popOutWindowReference = presenterIframe;
          return true;
        } else return false;
      };

      const firstAttemptResult = attemptToAttachIframeReference();
      if (firstAttemptResult) return;

      // SET INTERVAL TO PERIODICALLY CHECK IF BOTH IFRAMES EXIST (CURRENT WINDOW & POPOUT WINDOW)
      // SAVE INTERVAL IN REF SO WE CAN PROPERLY REMOVE IT WHEN ACTIVE PRESENTATION CHANGES (TO PREVENT MEMORY LEAK)
      intervalIdRef.current = setInterval(() => {
        const result = attemptToAttachIframeReference();
        if (result) clearInterval(intervalIdRef.current!);
      }, 500);

      // CLEANUP INTERVAL WHEN COMPONENT UNMOUNT
      return () => {
        if (intervalIdRef.current) clearInterval(intervalIdRef.current);
      };
    }
  }, [activePresentation?.currentPresentablePage, meetingORM?.model.type, triggerToAttachIframeReference]);

  const focusPopoutWindow = (): void => {
    if (!popoutReference.current || popoutReference.current?.closed) {
      handleMeetingStatusUpdate();
    } else {
      popoutReference.current?.focus();
    }
  };

  // CLEARS EXTERNAL WINDOW'S REFERENCE //
  const handleClearExternalWindow = (): void => {
    if (!isExternalPlayer && popoutReference.current) {
      popoutReference.current.onunload = null;
      popoutReference.current.close?.();
      popoutReference.current = null;
    }
  }

  const handlePopoutUnloaded = (e: Event) => setTimeout(() => {
    if (popoutReference.current && !popoutReference.current?.closed) {
      return;
    }
    handleClearExternalWindow();
    logger.meetingsPopoutContent.debug(
      'Popout unloaded',
      { event: e, closed: popoutReference.current?.closed, meetingORM },
    )

    /** NOTE: In some cases, the popout window can end up getting closed
     * before our main window gets a chance to reset the visibility state.
     * For example, when moving the popup to another virtual desktop,
     * then switching to that desktop and closing the popup.
    */
    setPopoutContentHidden(false)

    /** TODO: Identify why the meeting status does not get updated here Even after the
     * meeting has ended the status prop on the model still registers as IN_PROGRESS */
    if (meetingORM?.model.status === 'IN_PROGRESS') {
      dispatch(
        DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: false,
          component: () => (<DNACommonConfirmation
            confirmActionText="Re-open content window"
            descriptionText={REOPEN_MODAL_TEXT}
            hideCancelButton
            onConfirmAction={handleMeetingStatusUpdate}
            onCancelCallback={() => featureFlags.enableNew3PC && toggleMeetingType()}
            title="Re-open content window"
          />),
        }));
    }
  }, 500)

  const [popoutContentHidden, setPopoutContentHidden] = useState<boolean>(false)

  const handleVisibilityUpdate = () => { setPopoutContentHidden(!!popoutReference.current?.document.hidden) }

  const handlePopoutLoaded = () => {
    if (popoutReference.current) {
      popoutReference.current.onunload = handlePopoutUnloaded
      if (!featureFlags.enableNew3PC) {
        popoutReference.current.document.onvisibilitychange = handleVisibilityUpdate
        popoutReference.current.document.title = 'Presentation Content';
      }
    }
  }

  const launchPopoutContentWindow = () => {
    popoutReference.current = window.open(url, target, features)

    /* FOR THE NEW EXTERNAL WINDOW, WE WANT TO ATTACH THE ONUNLOAD HANDLER IN ADVANCE AS THE "LOAD"
    * MESSAGE IS SENT BY THE EXTERNAL WINDOW ONCE IT FINISHES LOADING (INCLUDING DATASTORE) */
    setTimeout(() => {
      if (featureFlags.enableNew3PC && popoutReference.current) {
        popoutReference.current.onunload = handlePopoutUnloaded
        if (activePresentation) {
          setMainWindowActivePresentation({
            ormId: activePresentation?.presentable?.orm.model.id,
            pageNumber: activePresentation.currentPresentablePage.presentationPageNumber,
          });
        }
      }
    }, 1000);
  }

  /** Meeting Status Update Event Listener */
  const handleMeetingStatusUpdate = () => {
    logger.meetingsPopoutContent.debug('Meeting updated, meeting status:', meetingORM?.model.status)
    if (meetingORM && !isExternalPlayer) {
      const { status, type } = meetingORM.model
      // [TEMPORARY SOLUTION]: CLOSES THE CONFIRMATION MODAL IF IT GOT OPENED BY A RACE CONDITION //
      if (type === MeetingType.VIRTUAL) {
        dispatch(DNAModalActions.closeModal());
      } else {
        // WHEN SWITCHING TO INPERSON, ENSURES CLEARING EXTERNAL WINDOW'S REF //
        handleClearExternalWindow();
      }

      const shouldLaunchPopoutContentWindow =
        type === MeetingType.VIRTUAL &&
        status === MeetingStatus.IN_PROGRESS &&
        (!popoutReference.current || popoutReference.current.closed)

      /** Virtual Meeting Popup */
      shouldLaunchPopoutContentWindow && launchPopoutContentWindow()
    }
  }
  useEffect(handleMeetingStatusUpdate, [meetingORM?.model.type])

  /** Back Button Event Listener */
  const onPressBackButton = () => {
    window.history.pushState(null, '', window.location.pathname);
    dispatch(
      DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: () => (<DNACommonConfirmation
          status="danger"
          confirmActionText="End Meeting"
          descriptionText="Are you sure you want to end this meeting?"
          onConfirmAction={endMeeting}
          title="End Meeting"
        />),
      }));
  }

  function broadcastContentPresentationMessage(contentPresentedToSend: ContentPresented[], meetingId: string): void {
    logger.meetingsPopoutContent.debug('Broadcasting ContentPresented message');
    broadcastChannel.current?.postMessage({
      type: PopoutContentBCTypes.setContentPresented,
      contentPresented: contentPresentedToSend,
      meetingId,
    });
  }

  const broadcastActivePresentationMessage = (meetingId?: string): void => {
    logger.meetingsPopoutContent.debug('Broadcasting ActivePresentation message',
      activePresentation?.presentable?.orm.model.id);
    const isWebHtmlOrVideo = isWebHtmlOrVideoDocumentVersionORM(activePresentation?.presentable?.orm)
    broadcastChannel.current?.postMessage({
      type: PopoutContentBCTypes.setActivePresentation,
      meetingId: meetingId || '',
      activePresentationModelId: activePresentation?.presentable?.orm.model.id,
      presentationPageNumber: activePresentation?.currentPresentablePage.presentationPageNumber,
      isWebHtmlOrVideo,
    });
  };

  /** NOTE: Init hook */
  useEffect(() => {
    logger.meetingsPopoutContent.debug('Init hook useMeetingsPopoutContentState')
    broadcastChannel.current = new BroadcastChannel<PopoutContentBCPayloads>('MEETING_CONTENT')
    if (isExternalPlayer) {
      broadcastChannel.current?.postMessage({
        type: PopoutContentBCTypes.popoutWindowLoaded,
        meetingId: meetingORM?.model.id || '',
      });

      if (featureFlags.enableNew3PC) {
        window.opener.document.onvisibilitychange = () => {
          setPopoutContentHidden(!!window.opener.document.hidden)
        }
      }
    }
    broadcastChannel.current.onmessage = (payload) => broadcastMessageHandlerRef.current(payload);

    if (featureFlags.enableNew3PC) {
      videoViewerChannel.current = new BroadcastChannel<VideoViewerBCPayloads>('VideoViewer')
      videoViewerChannel.current.onmessage = (payload) => {
        logger.meetingsPopoutContent.debug('Received message from Video Viewer channel', payload);
        switch (payload.type) {
          case 'SYNC_IFRAME_REFERENCE':
            setTriggerToAttachIframeReference(p => !p)
            break;
        }
      }
    }
    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', onPressBackButton);
    window.addEventListener('beforeunload', handleClearExternalWindow);
    return () => {
      window.removeEventListener('popstate', onPressBackButton);
      window.removeEventListener('beforeunload', handleClearExternalWindow);
      broadcastChannel.current?.close()
      broadcastChannel.current = undefined;
      videoViewerChannel.current?.close()
      videoViewerChannel.current = undefined;
    }
  }, []);

  function broadcastMessageHandler(payload: PopoutContentBCPayloads): void {
    logger.meetingsPopoutContent.debug('Received message from Broadcast channel', payload);
    if (payload.meetingId !== meetingORM?.model.id) {
      return;
    }

    switch (payload.type) {
      case 'popoutWindowLoaded':
        setPopoutContentHidden(false);
        handlePopoutLoaded();
        broadcastActivePresentationMessage(meetingORM?.model.id);
        break;
      case 'endMeeting':
        !isExternalPlayer && endMeeting();
        break;
      case 'getSharedFolderDependencies':
        if (featureFlags.enableNew3PC && !isMainPlayer) {
          dispatch(sharedFolderActions.getFolderExternalDependencies({
            folderId: payload.folderId,
            parentFolderId: payload.parentFolderId,
          }))
        }
        break;
      case 'setContentPresented':
        if (!isMainPlayer) {
          setContentPresented(payload.contentPresented);
        }
        break;
      case 'getSharedFolders':
        if (featureFlags.enableNew3PC && !isMainPlayer) {
          fetchSharedFolders();
        }
        break;
      case 'toggleMeetingType':
        !isExternalPlayer && toggleMeetingType();
        break;
      case 'setActivePresentation': {
        const activePresentationModelId = payload.activePresentationModelId;
        if (activePresentationModelId) {
          if (isMainPlayer) {
            setActiveAndOpenedPresentations(activePresentationModelId, payload.presentationPageNumber);
          } else {
            setMainWindowActivePresentation({
              pageNumber: payload.presentationPageNumber,
              ormId: activePresentationModelId,
            });
          }
          // IF SOME CONTENT IS PRESENTED, OPENS THE SLIDE ROLL / NOTES
          if (isExternalPlayer) {
            !payload.isWebHtmlOrVideo && meetingComponentsVisibility.setSlideRollVisible(true);
            meetingComponentsVisibility.setNotesPanelVisible(true);
          }
        } else {
          unsetActivePresentation();
          // IF NO CONTENT IS PRESENTED, OPENS THE CONTENT PANEL
          if (isExternalPlayer) {
            meetingComponentsVisibility.setPresentationControlsVisible(true);
          }
        }
      }
    }
  }

  return {
    focusPopoutWindow,
    popoutReference,
    popoutContentHidden,
    handleClearExternalWindow,
  }
}

export default useMeetingsPopoutContentState
