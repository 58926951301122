import React from 'react'
import { DocumentVersionORM } from 'src/types/orms';
import CustomFieldBadge from './CustomFieldBadge';
import { DNABox } from '@alucio/lux-ui';

interface Props {
  documentVersionORM?: DocumentVersionORM;
}

const CustomFieldBadgeList: React.FC<Props> = (props) => {
  const { documentVersionORM } = props;

  if (!documentVersionORM?.meta.badges) {
    return null;
  }

  return (
    <DNABox spacing="sm">
      {documentVersionORM.meta.badges.map((badge) =>
        <CustomFieldBadge key={badge.id} badge={badge} />)}
    </DNABox>
  )
}

export default CustomFieldBadgeList;
