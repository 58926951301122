import {
  SyncEntryContext,
  SyncEntryState,
  SyncEntryEvents,
  DOWNLOAD_ARCHIVE_DONE_EVENT,
  EXTRACT_ARCHIVE_DONE_EVENT,
  HANDLE_ERROR_DONE_EVENT,
} from './sync.types'

import { createMachine, sendParent } from 'xstate'
import { Unzipped } from 'fflate'

import CacheHandler from 'src/worker/machines/CacheHandler'
import * as logger from 'src/utils/logger'
import CacheDB from 'src/worker/db/cacheDB'

// Max file size by default is 300MB
export const MAX_OFFLINE_FILE_SIZE_BYTES = 314572800

const syncEntry = createMachine<
  SyncEntryContext,
  SyncEntryEvents,
  SyncEntryState
>(
  {
    predictableActionArguments: false,
    id: 'SyncEntry',
    strict: true,
    initial: 'validateStorageSpace',
    states: {
      validateStorageSpace: {
        invoke: {
          src: 'validateStorageSpace',
          onDone: 'downloadFile',
          onError: 'handleError',
        },
      },
      downloadFile: {
        invoke: {
          src: 'downloadFile',
          onDone: [
            { target: 'extractArchive', cond: 'isContent' },
            { target: 'storeCachePayload' },
          ],
          onError: 'handleError',
        },
      },
      extractArchive: {
        invoke: {
          src: 'extractArchive',
          onDone: 'storeCachePayload',
          onError: 'handleError',
        },
      },
      storeCachePayload: {
        invoke: {
          src: 'storeCachePayload',
          onDone: 'updateManifestSyncEntry',
          onError: 'handleError',
        },
      },
      updateManifestSyncEntry: {
        invoke: {
          src: 'updateManifestSyncEntrySuccess',
          onDone: 'success',
          onError: 'handleError',
        },
      },
      handleError: {
        invoke: {
          src: 'handleError',
          onDone: 'error',
          onError: 'error',
        },
      },
      success: {
        type: 'final',
        data: (context) => context.syncEntry,
      },
      error: {
        type: 'final',
        data: (context) => context.syncEntry,
        entry: sendParent((context, event) => {
          const evt = event as HANDLE_ERROR_DONE_EVENT
          logger
            .PWALogger
            .warn(`SyncMachine [${context.syncEntry.documentVersionId}]-Error occurred in processing document:` +
            ` ErrorType: ${evt.data.event} Message: ${evt.data.message}`)
          return {
            type: 'BUBBLE_ERROR',
            errorType: evt.data.event,
            errorData: evt.data.message,
            entryId: context.syncEntry.id,
            documentVersionId: context.syncEntry.documentVersionId,
          }
        }),
      },
      paused: {
        type: 'final',
        data: (context) => context.syncEntry,
        entry: sendParent(() => ({ type: 'PAUSE_SYNC_INTERNAL' })),
      },
    },
    on: {
      'PAUSE_SYNC': {
        actions: 'abortSync',
        target: 'paused',
      },
    },
  },
  {
    actions: {
      abortSync: (context) => context.abortController.abort(),
    },
    guards: {
      isContent: (context) => context.syncEntry.cacheType === 'CONTENT',
    },
    services: {
      downloadFile: async (context): Promise<ArrayBuffer> => {
        logger.PWALogger.debug(`SyncMachine [${context.syncEntry.documentVersionId}] - Downloading File `)
        logger.PWALogger.debug(`Entry size: ${context.syncEntry.fileSize}`)
        if (context.syncEntry.fileSize > MAX_OFFLINE_FILE_SIZE_BYTES) {
          logger.PWALogger.warn(`SyncMachine [${context.syncEntry.documentVersionId}]:` +
            `Entry exceeded max file size ${context.syncEntry.fileSize}`)
          throw Error(`Entry of size ${context.syncEntry.fileSize} exceeded max size`)
        }

        return CacheHandler.downloadFile(
          context.syncEntry,
          context.authHeaders,
          context.abortController,
        )
      },
      validateStorageSpace: async (context, event) => {
        logger.PWALogger.debug(`SyncMachine [${context.syncEntry.documentVersionId}] - Checking Storage`)
        let isStorageFull = false;
        try {
          if (navigator?.storage && navigator.storage.estimate) {
            const { quota, usage } = await navigator.storage.estimate();
            if (quota && usage) {
              // TO THE ACTUAL FREE SPACE, WE'LL SUBTRACT 20MB TO HAVE IT AS A GAP //
              const freeSpace = quota - usage - (20 * (1024 * 1024));
              if (freeSpace < context.syncEntry.fileSize) {
                isStorageFull = true;
                logger.PWALogger.warn(`SyncMachine [${context.syncEntry.documentVersionId}] - QuotaSize reached`);
              }
            }
          } else {
            logger.PWALogger.debug(`SyncMachine [${context.syncEntry.documentVersionId}] - Storage API not supported`)
          }
        } catch (error) {
          // IF AN ERROR OCCURRED WHILE CHECKING THE STORAGE,
          // WE'LL LET THE ERROR HANDLER ACT IF AN ERROR OCCURS WHEN TRYING TO INSERT //
          if (error instanceof Error) {
            logger.PWALogger.warn(error.message);
          }
        }

        if (isStorageFull) {
          throw Error(`Entry of size [${context.syncEntry.fileSize}] exceeds free storage space`);
        }

        return event.data;
      },
      extractArchive: async (context, event): Promise<Unzipped> => {
        logger.PWALogger.debug(`SyncMachine [${context.syncEntry.documentVersionId}] - Extacting archive`)

        const evt = event as DOWNLOAD_ARCHIVE_DONE_EVENT
        return CacheHandler.extractArchive(evt.data)
      },
      handleError: async (context, event) => {
        logger.PWALogger.warn(`SyncMachine [${context.syncEntry.documentVersionId}] - Updating manifest entry - FAILED`)
        const cacheDB = new CacheDB()
        await cacheDB.open()

        await cacheDB.updateCacheManifestEntry(
          context.syncEntry.id,
          { status: 'FAILED' },
        )
        await cacheDB.close()

        return {
          message: event.data,
          event: event.type,
        }
      },
      storeCachePayload: async (context, event): Promise<void> => {
        logger.PWALogger.info(`SyncMachine [${context.syncEntry.documentVersionId}] - Storing Cache Payload`)

        const evt = event as EXTRACT_ARCHIVE_DONE_EVENT | DOWNLOAD_ARCHIVE_DONE_EVENT
        return CacheHandler.storeCachePayload(evt.data, context.syncEntry)
      },
      updateManifestSyncEntrySuccess: async (context): Promise<void> => {
        logger
          .PWALogger
          .info(`SyncMachine [${context.syncEntry.documentVersionId}] - Updating manifest entry - SUCCESS`)
        return CacheHandler.updateCacheManifestEntry(
          context.syncEntry.id,
          { status: 'LOADED', loadedAt: new Date() },
        )
      },
    },
  },
)

export default syncEntry
