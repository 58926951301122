import React, { createContext, useContext } from 'react';
import { DNAConditionalButtonProps } from './DNAConditionalButton';

interface DNAConditionalButtonStateType {
}

const DNAConditionalButtonStateContext = createContext<DNAConditionalButtonStateType | null>(null!)
DNAConditionalButtonStateContext.displayName = 'DNAConditionalButtonContext'

interface DNAConditionalButtonInitialValues extends Pick<DNAConditionalButtonProps, 'children'> {}

/**
 * This state provider registers and handles the logic for the Disable children
 */
const DNAConditionalButtonStateProvider: React.FC<DNAConditionalButtonInitialValues> = ({
  children,
//   ...initialValues
}) => {
//   const conditionalButtonItemDefaults:DNAConditionalButtonItemProps = initialValues.itemDefaults ?? staticConditionalButtonItemDefaults

  /** SECTION: Handlers */
  /** Uncomment if additional context initialization is necessary */
  // const handleContextInit = () => {}
  // useEffect(handleContextInit, [])
  /** !SECTION: Handlers */

  const contextValue: DNAConditionalButtonStateType = {

  }

  return (
    <DNAConditionalButtonStateContext.Provider value={contextValue}>
      {children}
    </DNAConditionalButtonStateContext.Provider>
  )
}

DNAConditionalButtonStateProvider.displayName = 'DNAConditionalButton State Provider'

export const useDNAConditionalButtonState = () => {
  const context = useContext(DNAConditionalButtonStateContext)
  if (!context) {
    throw new Error('useDNAConditionalButtonState must be used within the DNAConditionalButton State Provider')
  }
  return context;
}

export default DNAConditionalButtonStateProvider
