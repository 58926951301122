import { useSelector } from 'react-redux'
import { createSelector, Selector } from '@reduxjs/toolkit'
import { matchesState, StateValue } from 'xstate'

import { RootState } from 'src/state/redux'
import { CacheSliceState } from 'src/state/redux/slice/Cache/cache'
import { SyncManagerStateValue } from 'src/worker/machines/sync/sync.types'
import { CacheManifestEntry } from '@alucio/core'

export const selectCacheManifests =
  (
    state: RootState,
  ): CacheSliceState['manifestEntries'] =>
    state.cache.manifestEntries
export const selectCacheSync = (state: RootState): CacheSliceState['sync'] => state.cache.sync
export const selectCacheSyncValue = (state: RootState): CacheSliceState['sync']['value'] => state.cache.sync.value

export type SyncStateORM = Pick<
  SyncManagerStateValue,
  'context' | 'value'
> & {
  matches: (value: StateValue) => ReturnType<typeof matchesState>
}

export type SyncStatusORM = Pick<SyncManagerStateValue, 'value'> & {
  matches: (value: StateValue) => ReturnType<typeof matchesState>
}

const syncManifests: Selector<RootState, CacheManifestEntry[]> = createSelector(
  selectCacheManifests,
  entries => entries,
)

// This is the full sync machine selector (includes context)
export const syncState: Selector<RootState, SyncStateORM> = createSelector(
  selectCacheSync,
  (syncSlice): SyncStateORM => {
    return {
      context: syncSlice.context,
      value: syncSlice.value,
      // [TODO:BEAC-6355] - While convenient, we may want to remove this as it could be generating tons of
      //          of one off functions.
      //        - This can probably be replaced whenever we backport newer machine approaches into the rest of Beacon
      matches: (match: StateValue) => matchesState(match, syncSlice.value),
    }
  },
)

// This is a lighter selector that is only concered with the current state value
//  [TODO-PWA]
//    - Consider some type of error status
//      maybe a simple boolean to check if there are error entries
const syncStatus: Selector<RootState, SyncStatusORM> = createSelector(
  selectCacheSyncValue,
  (syncValue): SyncStatusORM => ({
    value: syncValue,
    matches: (match: StateValue) => matchesState(match, syncValue),
  }),
)

export const useSyncState = (): ReturnType<typeof syncState> =>
  useSelector((state: RootState) => syncState(state))

export const useSyncStatus = (): ReturnType<typeof syncStatus> =>
  useSelector((state: RootState) => syncStatus(state))

export const useSyncManifests = (): ReturnType<typeof syncManifests> =>
  useSelector((state: RootState) => syncManifests(state))
