import { useSelector } from 'react-redux';
import { createSelector, Selector } from '@reduxjs/toolkit';
import {
  CustomFieldDefinition,
  CustomFieldUsage,
  FieldConfig,
  FieldStatus,
  FieldDataType,
  Tenant,
  User,
  TenantForm,
  TenantFormStatus,
} from '@alucio/aws-beacon-amplify/src/models';
import { RootState } from '../store';
import { isRequiredField, Usages } from './common';
import { useMemo } from 'react';

type Options = {
  defaultSearchFilter?: FieldConfig['defaultSearchFilter'],
  usages: Usages,
  fieldDataType?: FieldDataType[],
  tenantId?: string,
  parentFieldId?: string,
}

// [TODO]: REFACTOR THE ORM STRUCTURE
export interface TenantORM {
  tenant: Tenant,
  meta: {
    users: User[],
  }
}

export interface IndexedCustomFields {
  [id: string]: CustomFieldDefinition
}

const selUsers = (state: RootState): User[] => state.user.records;
export const selTenants = (state: RootState): Tenant[] => state.tenant.records;
export const selOptions = (_, __, opts: Options) => opts;
export const selTenantId = (_, __, tenantId: string) => tenantId;

export const tenantListORM: Selector<RootState, TenantORM[]> = createSelector(
  selUsers,
  selTenants,
  (users, tenants): TenantORM[] =>
    tenants.map((tenant) => ({
      tenant,
      meta: {
        users: users.filter((user) => user.tenantId === tenant.id),
      },
    })),
);

export const tenantList: Selector<RootState, Tenant[]> = createSelector(
  selTenants,
  (tenants): Tenant[] => tenants,
);

const tenantForms: Selector<RootState, TenantForm[]> = createSelector(
  tenantList,
  selOptions,
  (tenants, opts): TenantForm[] => {
    const { tenantId } = opts || {};
    const tenant = tenantId ? tenants.find(({ id }) => id === tenantId) : tenants[0];
    return tenant?.config?.forms?.filter( f => f.status === TenantFormStatus.ACTIVE) || []
  },
);

export const tenantCustomFields: Selector<RootState, CustomFieldDefinition[]> = createSelector(
  tenantList,
  selOptions,
  (tenants, opts): CustomFieldDefinition[] => {
    const { defaultSearchFilter, fieldDataType, usages, tenantId, parentFieldId } = opts
    const tenant = tenantId ? tenants.find(({ id }) => id === tenantId) : tenants[0];
    const parentField = tenant?.config?.customFields?.find(({ id }) => id === parentFieldId)
    const childFieldIds = parentField?.objectSetting?.childrenFieldIds || [];
    if (parentField && (!childFieldIds || childFieldIds.length === 0 )) return []
    return tenant?.config?.customFields
      ?.filter(customField => {
        const isValidChildField = childFieldIds.length > 0 ? childFieldIds.includes(customField.id) : true;
        // CURRENTLY THE OBJECT CUSTOM FIELD TYPE CAN ONLY BE USED WITHIN A MEETING CONTEXT
        const isObjectOutsideMeetings = customField.fieldType === FieldDataType.OBJECT &&
          !usages.internalUsages?.includes(CustomFieldUsage.MEETING);
        const isValidDataType = fieldDataType ? fieldDataType.includes(customField.fieldType as FieldDataType) : true;

        return isRequiredField(customField, usages) && customField.status !== FieldStatus.DISABLED &&
          (defaultSearchFilter ? customField.showByDefault : true) && isValidChildField &&
          !isObjectOutsideMeetings && isValidDataType;
      }) || [];
  },
);

const indexedTenantCustomFields: Selector<RootState, IndexedCustomFields>  = createSelector(
  tenantCustomFields,
  (customFields): IndexedCustomFields =>
    customFields.reduce((acc, field) => {
      acc[field.id] = field;
      return acc;
    }, {}),
);

export const tenant: Selector<RootState, TenantORM | undefined> = createSelector(
  tenantListORM,
  selTenantId,
  (tenants, tenantId): TenantORM | undefined => {
    return tenants.find(({ tenant }) => tenant.id === tenantId);
  },
);

// [TODO-3073] - Implement these instead of the tightly coupled options
// const tenantCustomFieldsFilteredFactory = () => { }
// const tenantCustomFieldsSortedFactory = () => { }
// const tenantCustomFieldsSortedAndFilteredFactory = () => { }

/** Returns the list of Tenants */
export const useTenantList = (): ReturnType<typeof tenantListORM> =>
  useSelector((state: RootState) => tenantListORM(state));

/** Returns a tenant based on the given id */
export const useTenant = (tenantId: string): ReturnType<typeof tenant> =>
  useSelector((state: RootState) => tenant(state, undefined, tenantId));

/** Returns custom fields who match the provided filter options */
export const useTenantCustomFields = (opts?: Options):
    ReturnType<typeof tenantCustomFields> => {
  const options = useMemo(
    () => opts,
    [],
  )

  return useSelector((state: RootState) => tenantCustomFields(state, undefined, options))
}

export const useTenantForms = (opts?: Options):
    ReturnType<typeof tenantForms> => {
  const options = useMemo(
    () => opts,
    [],
  )

  return useSelector((state: RootState) => tenantForms(state, undefined, options))
}

/** Returns indexed custom fields who match the provided filter options */
export const useIndexedTenantCustomFields = (opts?: Options): ReturnType<typeof indexedTenantCustomFields> =>
  useSelector((state: RootState) => indexedTenantCustomFields(state, undefined, opts))
