import React, { useState } from 'react'
import { StyleSheet, ViewStyle } from 'react-native'

import {
  DNABox,
  DNAButton,
  DNAIcon,
  DNAText,
} from '@alucio/lux-ui'
import { colors } from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import useCurrentPage from '../DNA/hooks/useCurrentPage'
import { useAppSettings } from 'src/state/context/AppSettings'

const styles = StyleSheet.create({
  bannerText: {
    margin: 15,
  },
  bannerBtn: {
    marginRight: 45,
  },
  container: {
    backgroundColor: colors['warning-500'],
  },
  hiddenStyle: {
    display: 'none',
  },
  icon: {
    width: 29,
    height: 24,
    marginLeft: 25,
    marginTop: 15,
    marginBottom: 15,
  },
  textColor: {
    color: colors['text-basic'],
  },
})

const NetworkConnectionBanner:React.FC<{ style?: ViewStyle }> = (props) => {
  const { deviceMode } = useAppSettings()
  const currentPage = useCurrentPage()
  /** Route config options */
  const {
    disableNetworkStatusBanner,
  } = { ...currentPage?.configOptions?.[deviceMode] }

  /** Used to hide the banner while we are still offline */
  const [isHidden, setIsHidden] = useState<boolean>(false)

  if (disableNetworkStatusBanner) return null

  const containerStyle = [
    props.style,
    styles.container,
    isHidden && styles.hiddenStyle,
  ]
  return (
    <DNABox
      spacing="between"
      alignY="center"
      fill
      style={containerStyle}
    >
      <DNABox>
        <DNAIcon
          name="wifi-off"
          status="tertiary"
          size="lg"
          style={[styles.textColor, styles.icon]}
        />
        <DNAText b1 style={[styles.textColor, styles.bannerText]}>
          No internet connection. Some features will be unavailable in offline mode.
        </DNAText>
      </DNABox>
      <DNABox>
        <DNAButton
          onPress={() => setIsHidden(true)}
          status="warning"
          appearance="ghost"
          style={styles.bannerBtn}
        >
          <DNAText style={styles.textColor}>
            Hide
          </DNAText>
        </DNAButton>
      </DNABox>
    </DNABox>
  )
}

export default NetworkConnectionBanner
