import React from 'react';
import { Popover } from '@alucio/lux-ui';
import { PopoverComponent } from '@alucio/lux-ui/src/components/visual/Popover/PopoverCore';
import { useAppSettings } from 'src/state/context/AppSettings';

export const DNAPopover: PopoverComponent = ({
  children,
  disablePopover,
  ...rest
}) => {
  const { platform } = useAppSettings()

  /** NOTE: Updated this to support */
  // If disablePopover includes tabletPWA, we also want to disable for tabletWEB
  const shouldDisablePopover = typeof disablePopover === 'boolean'
    ? disablePopover
    : disablePopover?.includes(platform) ||
    (disablePopover?.includes('tabletPWA') && platform === 'tabletWEB')

  return (
    <Popover {...rest} disablePopover={shouldDisablePopover}>
      {children}
    </Popover>
  )
}

DNAPopover.displayName = 'DNAPopover'
DNAPopover.Anchor = Popover.Anchor;
DNAPopover.Content = Popover.Content;

export default DNAPopover
