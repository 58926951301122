import React from 'react'
import { DNABox, DNACard, DNAText, Iffy, DNAChip, DNAButton, DNACollapsibleCard } from '@alucio/lux-ui'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector'
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail'
import { FlatList, StyleSheet } from 'react-native';
import { LoadedPresentation } from 'src/state/context/ContentProvider/ContentProvider';
import { OpenedPresentationsVariantOption } from './OpenedPresentations';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import { isDocumentVersionORM } from 'src/types/typeguards';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';

const styles = StyleSheet.create({
  presentButton: {
    borderRadious: 4,
    borderWidth: 1,
  },
  containerStyle: {
    padding: 16,
    backgroundColor: colors['color-gray-800'],
    borderTopWidth: 1,
    borderColor: colors['color-gray-600'],
  },
  activeItem: {
    backgroundColor: 'rgba(48, 173, 127, 0.16)',
    borderWidth: 1,
    borderColor: colors['color-success-500'],
    bordeRadius: 4,
    padding: 16,
    marginVertical: 4,
  },
  icon: {
    color: colors['color-success-500'],
    width: 32,
    height: 32,
  },
  accordion: {
    backgroundColor: colors['color-gray-800'],
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderTopColor: colors['color-gray-600'],
    borderTopWidth: 0,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
});

export interface SlideProps {
  orm: LoadedPresentation,
}

export interface DocumentAssociatedProps {
  associatedDocuments?:LoadedPresentation[],
  handleShowPresentation: (id: string) => void,
  isActive: boolean,
  parentPresentableId: string,
}

interface RenderItem {
  item:LoadedPresentation,
  variant?:OpenedPresentationsVariantOption,
}

export interface OpenedPresentationAssociatedRowProps {
  item: LoadedPresentation,
  changeActivePresentation: (id: string) => void,
  activePresentation?: LoadedPresentation,
  variant?:OpenedPresentationsVariantOption
}

const DocumentAssociatedRow: React.FC<OpenedPresentationAssociatedRowProps> = ({
  item, changeActivePresentation, activePresentation,
}) => {
  const s3URL = detectArchivedFileKeyPath(item.currentPresentablePage.documentVersionORM?.model,
    item.currentPresentablePage.page)
  const isActive = activePresentation?.presentable.id === item.presentable.id;
  const { setSelectedAssociatedDocument, setSelectedPresentableId } = useMeetingsState()

  const rowStyle = [
    isActive && styles.activeItem,
    !isActive && styles.containerStyle,
  ]

  const setDocumentVersion = (item: LoadedPresentation) => {
    const documentVersionORM = item.currentPresentablePage.documentVersionORM
    if (isDocumentVersionORM(documentVersionORM)) {
      setSelectedAssociatedDocument(documentVersionORM)
      setSelectedPresentableId(item.meta?.parentPresentableId)
    }
  }

  return (
    <DNACard
      appearance="flat"
      style={rowStyle}
    >
      <DNABox spacing="between" childFill={0}>
        <DNABox spacing="between" childFill={0}>
          <DNABox spacing="md" childFill={1}>
            <DNABox
              style={{
                backgroundColor: colors['color-gray-500'],
                borderWidth: 1,
                borderColor: 'rgba(255, 255, 255, 0.32)',
              }}
            >
              <DNAThumbnail
                s3URL={s3URL}
                useLoadingIndicator={true}
                width={80}
                height={46}
              />
            </DNABox>
            <DNABox spacing="sm" fill childFill={0}>

              {/* Content Info */}
              <DNABox spacing="sm" appearance="col" fill>
                <DNABox spacing="xs" appearance="col" fill>
                  <DNAText numberOfLines={2} status="basic">
                    {item.presentable.title}
                  </DNAText>
                </DNABox>
                <DNABox spacing="sm">
                  <DNABox spacing="sm">
                    <Iffy is={isActive}>
                      <DNAChip
                        appearance="tag"
                        style={{ backgroundColor: colors['color-success-500'] }}
                      >
                        PRESENTING...
                      </DNAChip>
                    </Iffy>
                    <CustomFieldBadgeList documentVersionORM={item.currentPresentablePage.documentVersionORM} />
                    <DNAChip appearance="tag">
                      {item.currentPresentablePage.documentVersionORM.model?.type}
                    </DNAChip>
                  </DNABox>
                </DNABox>
              </DNABox>

              {/* Action buttons */}
              <DNABox spacing="sm" fill alignY="center">
                <DNAButton
                  testID="document-associated-view-slides-btn"
                  onPress={() => setDocumentVersion(item)}
                  appearance="filled"
                  status="dark"
                  size="sm"
                  colorMode="dark"
                  style={styles.presentButton}
                >
                  <DNAText status="basic">View slides</DNAText>
                </DNAButton>
                <DNAButton
                  testID="document-associated-present-btn"
                  onPress={() => changeActivePresentation(item.presentable.id)}
                  appearance="filled"
                  status="dark"
                  size="sm"
                  colorMode="dark"
                  style={styles.presentButton}
                >
                  <DNAText status="basic">Present</DNAText>
                </DNAButton>
              </DNABox>
            </DNABox>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNACard >
  )
}

const DocumentsAssociated: React.FC<DocumentAssociatedProps> = ({
  associatedDocuments,
  handleShowPresentation,
  parentPresentableId,
}) => {
  const {
    activePresentation,
    presentations,
  } = useContent()

  const { setSelectedAssociatedDocument, setSelectedPresentableId, selectedPresentableId } = useMeetingsState()

  const isOpenCollapsible =  (parentId: string) => {
    let result = false

    if (selectedPresentableId === parentId) {
      result = true
    }

    if (activePresentation && selectedPresentableId !== parentId) {
      const presentation = presentations.find((presentation) =>
        presentation.presentable.id === activePresentation.presentable.id)
      result = presentation?.meta?.parentPresentableId === parentId ||
        presentation?.presentable.id === parentId
      setSelectedAssociatedDocument(undefined)
    }
    return result
  }

  const RenderItem:React.FC<RenderItem> = (
    {
      item,
      variant = 'default',
    },
  ) => {
    return (
      <DNABox appearance="col">
        <DocumentAssociatedRow
          variant={variant}
          activePresentation={activePresentation}
          changeActivePresentation={handleShowPresentation}
          item={item}
        />
      </DNABox>
    )
  }
  return (
    <DNABox fill appearance="col" style={{ marginTop : 4 }}>
      <DNACollapsibleCard
        headerTitle={`Associated Files (${associatedDocuments?.length})`}
        isCollapsed={!isOpenCollapsible(parentPresentableId)}
        customWrapperStyle={styles.accordion}
        variant="darkMode"
        onToggleChanged={() => {
          setSelectedAssociatedDocument(undefined)
          setSelectedPresentableId(undefined)
        }}
      >
        <FlatList
          keyExtractor={(item) => item.presentable.id}
          data={associatedDocuments}
          initialNumToRender={10}
          renderItem={({ item }) => <RenderItem item={item} />}
          contentContainerStyle={{ borderRadius: 8 }}
        />
      </DNACollapsibleCard>

    </DNABox>
  )
}

export default DocumentsAssociated
