import useThumbnailSize from 'src/hooks/useThumbnailSize/useThumbnailSize'

const useSelectorState = () => {
  const {
    thumbnailSize,
    setThumbnailSize,
    cycleThumbnailSize,
    thumbnailDimensions,
  } = useThumbnailSize(['xxl', 'xl', 'lg'], 'lg')

  return {
    selectorThumbnailSize : thumbnailSize,
    setSelectorThumbnailSize : setThumbnailSize,
    cycleSelectorThumbnailSize : cycleThumbnailSize,
    selectorThumbnailDimensions: thumbnailDimensions,
  }
}

export default useSelectorState
