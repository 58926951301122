import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAChip, DNAIcon, DNAText, Iffy } from '@alucio/lux-ui';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import { ORMTypes, isDocumentVersionORM, isFolderItemORM, isPageGroupORM } from 'src/types/types';
import useVirtualnActionBarComponents from '../VirtualActionBar/useVirtualActionBarComponents';

const S = StyleSheet.create({
  headerContentSpacing: {
    marginRight: 12,
  },
  headerContainer: {
    padding: 16,
    maxHeight: 56,
  },
});

const Header: React.FC = () => {
  const { activePresentation } = useContent();
  const { isMainPlayer } = useMeetingsState();
  const { endMeetingButton } = useVirtualnActionBarComponents()

  const isPresentingDocument = !!activePresentation

  const activePresentationInfo = useMemo(() => {
    if (!isPresentingDocument) return null

    const { orm, title } = activePresentation.presentable
    const isDocumentVersion =
      !isPageGroupORM(orm) &&
      (isDocumentVersionORM(orm) ||
        isDocumentVersionORM(orm.relations.itemORM));
    const isModifiedDocumentVersion =
      isFolderItemORM(orm) &&
      isDocumentVersion &&
      orm.model.visiblePages?.length;
    const showModifiedBadge =
      isModifiedDocumentVersion ||
      !isDocumentVersion;
    const isCustomDeck = isFolderItemORM(activePresentation?.presentable.orm)
      ? activePresentation?.presentable.orm.model.type === ORMTypes.CUSTOM_DECK : false

    // @ts-ignore can be ignored here since we are checking and making sure it is customDeck
    const isWithinGracePeriod = isCustomDeck && orm.relations.itemORM.meta.version.withinGracePeriod

    return (
      <DNABox fill>
        <Iffy is={isWithinGracePeriod}>
          <DNABox style={S.headerContentSpacing} spacing="xs">
            <DNAIcon.Styled
              appearance="ghost"
              status="warning"
              size="md"
              name="alert"
            />
            <DNAText status="warning">
              Needs review
            </DNAText>
          </DNABox>
        </Iffy>
        <DNABox style={S.headerContentSpacing}>

          { /* DOCUMENT VERSION BADGE */}
          {isDocumentVersion &&
            <CustomFieldBadgeList documentVersionORM={activePresentation.currentPresentablePage.documentVersionORM} />
          }

          { /* MODIFIED BADGE */}
          {showModifiedBadge &&
            <DNAChip appearance="tag">
              MODIFIED
            </DNAChip>
          }
        </DNABox>
        <DNABox fill style={S.headerContentSpacing}>
          <DNAText numberOfLines={1} status="basic">
            {title}
          </DNAText>
        </DNABox>
      </DNABox>
    )
  }, [activePresentation])

  return (
    <Iffy is={isMainPlayer}>
      <DNABox
        fill
        alignY="center"
        alignX={isPresentingDocument ? undefined : 'end'}
        style={S.headerContainer}
      >
        {activePresentationInfo}
        {endMeetingButton}
      </DNABox>
    </Iffy>
  );
};

export default Header
