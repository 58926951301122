import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgLightbulbOn20 = (props: SvgProps) => (
  <Svg
    width={48}
    height={48}
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      // eslint-disable-next-line max-len
      d="M.333 20h6v4h-6v-4Zm7.8-15-2.8 2.8 4.2 4.2 2.8-2.8-4.2-4.2Zm16.2-5h-4v6h4V0Zm14 20v4h6v-4h-6Zm-20 22c0 1.2.8 2 2 2h4c1.2 0 2-.8 2-2v-2h-8v2Zm18.2-37-4.2 4.2 2.8 2.8 4.2-4.2-2.8-2.8Zm-2.2 17c0 4.4-2.4 8.4-6 10.4V36c0 1.2-.8 2-2 2h-8c-1.2 0-2-.8-2-2v-3.6c-3.6-2-6-6-6-10.4 0-6.6 5.4-12 12-12s12 5.4 12 12Zm-4 0c0-4.42-3.58-8-8-8s-8 3.58-8 8 3.58 8 8 8 8-3.58 8-8Z"
      fill="#44A2DA"
    />
  </Svg>
);
export default SvgLightbulbOn20;
