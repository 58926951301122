import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'src/state/redux'
import DNACommonConfirmationSignOut from 'src/components/DNA/Modal/DNAConfirmationSignOut';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { useAppSettings } from 'src/state/context/AppSettings';
import { SessionStatus } from '../IdleComponent/IdleComponent';
import { useActor, useSelector } from '@xstate/react';
import * as logger from 'src/utils/logger'

export enum SOURCE {
  USER = 'USER',
  IDLE_TIMER = 'IDLE_TIMER',
  IDLE_CHANNEL = 'IDLE_CHANNEL',
}

export type SignOutType = {
  signOut: (source: SOURCE) => Promise<void>;
  automaticSignOut: (reason: SessionStatus | undefined, fromChannel: boolean) => Promise<void>;
  isPerformingLogOut: boolean;
}

export const SignOut = createContext<SignOutType>(undefined!);

export const SignOutProvider = (props) => {
  const { children } = props;
  const { logOutMachine, isOfflineEnabled, isSSO } = useAppSettings();
  const storeDispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, send] = useActor(logOutMachine);
  const showPromptDialog = useSelector(logOutMachine,
    (state) => state.matches('user.promptUserConfirmation.showPrompt'));
    // check if the machine is in an intermediate state
  const isPerformingLogOut = useSelector(logOutMachine, (state) => state.tags.has('IS_IN_INTERMEDIATE_STATE'));

  useEffect(() => {
    send({ type: 'SET_SSO', payload: { isSSO } });
    logger.auth.signOut.info(`SSO status set to ${isSSO}`);
  }, [isSSO]);

  useEffect(() => {
    send({
      type: 'SET_OFFLINE_ENABLED',
      payload: { isOfflineEnabled },
    });
    logger.auth.signOut.info(`Offline status set to ${isOfflineEnabled}`);
  }, [isOfflineEnabled]);

  const cond = useMemo(() => ({
    showPromptDialog: showPromptDialog,
  }), [showPromptDialog]);

  useEffect(() => {
    if (cond.showPromptDialog) {
      logger.auth.signOut.info('Prompting user for confirmation to sign out');
      storeDispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: false,
        component: () => (
          <DNACommonConfirmationSignOut
            onConfirmAction={(clearLocalData) => {
              logger.auth.signOut.info('User confirmed sign out with clear local data:', clearLocalData);
              send({
                type: 'SET_CLEAR_LOCAL_DATA',
                payload: {
                  clearLocalData,
                },
              })
            }}
            onCancelAction={() => {
              logger.auth.signOut.info('User cancelled sign out');
              send({
                type: 'CANCEL_LOG_OUT',
              })
            }}
          />
        ),
      }));
    }
  }, [cond.showPromptDialog])

  const automaticSignOut = useMemo(() =>
    async (reason: SessionStatus = SessionStatus.end, fromChannel: boolean) => {
      logger.auth.signOut.info(
        `Automatic sign out initiated. Reason: ${reason}, From Channel: ${fromChannel}`
      );
      if (fromChannel) {
        send({
          type: 'LOG_OUT_SIGNAL',
          payload: {
            source: SOURCE.IDLE_CHANNEL,
            disconnectionReason: reason,
          },
        })
      }
      else {
        send({
          type: 'LOG_OUT_SIGNAL',
          payload: {
            source: SOURCE.IDLE_TIMER,
            disconnectionReason: reason,
          },
        })
      }
    }, [send])

  const signOut = useMemo(() => async (source: SOURCE) => {
    logger.auth.signOut.info(`Manual sign out initiated. Source: ${source}`);
    send({
      type: 'LOG_OUT_SIGNAL',
      payload: {
        source,
      },
    })
  }, [send])

  const context = { signOut, automaticSignOut, isPerformingLogOut };

  return (
    <SignOut.Provider value={context}>
      {children}
    </SignOut.Provider>
  )
}

const useLogOut = () => {
  const context = useContext(SignOut);
  if (context === undefined) {
    throw new Error('useLogOut must be used within a SignOutProvider');
  }
  return context;
}

export default useLogOut;
