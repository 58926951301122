import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import {
  DNABox,
  DNAButton,
  DNACheckbox,
  DNAContextMenu,
  DNAText,
  Iffy,
} from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { ToDo, TodoStatus } from '@alucio/aws-beacon-amplify/src/models';
import { useTodoState } from './ToDoListProvider';
import { Controller } from 'react-hook-form';
import InputComponent from 'src/components/Publishers/InputComponent';
import { Input } from '@ui-kitten/components';

const TODO_TITLE_CHARACTER_LIMIT = 150
const TODO_RESOLUTION_CHARACTER_LIMIT = 500

type TodoRowProps = {
  item: ToDo
  isOverlay?: boolean
}

export const styles = StyleSheet.create({
  row: {
    paddingVertical: 15
  },
  completed: {
    textDecorationLine: 'line-through',
  },
  overlayContentContainer: {
    paddingLeft: 28,
    paddingRight: 52,
  },
  errorMessage: {
    color: colors['color-danger-500'],
    marginTop: 4,
  },
});

const ViewMode: React.FC<TodoRowProps> = ({ item, isOverlay }) => {
  const { setEditingId, updateItemStatus, handleOnCompleted, editingId } = useTodoState()
  const [isCompleted, setIsCompleted] = useState(item.status === TodoStatus.COMPLETED)

  const onChangeCompleted = (checked: boolean) => {
    handleOnCompleted(item.id, checked, setIsCompleted)
  };

  return (
    <DNABox fill alignY="start">
      <DNABox spacing="sm" fill childFill={1}>
        <Iffy is={!isOverlay}>
          <DNACheckbox
            status="gray"
            checked={isCompleted}
            onChange={onChangeCompleted}
            disabled={!!editingId}
          />
        </Iffy>
        <DNABox
          appearance="col"
          spacing="sm"
          style={isOverlay && styles.overlayContentContainer}
          fill
        >
          <DNAText testID='hub-to-do-result-title' style={isCompleted ? styles.completed : {}} bold status="flatAlt">{item.title}</DNAText>
          <DNAText testID='hub-to-do-result-description' style={isCompleted ? styles.completed : {}} status="flatAlt">{item.resolution}</DNAText>
        </DNABox>
      </DNABox>
      <Iffy is={!isOverlay}>
        <DNABox spacing="sm">
          <Iffy is={item.status !== TodoStatus.COMPLETED}>
            <DNAButton
              iconRight="pencil"
              appearance="ghostLink"
              status="gray"
              size="md"
              padding="none"
              onPress={() => setEditingId(item.id)}
              disabled={!!editingId}
            />
          </Iffy>
          <DNAContextMenu>
            <DNAContextMenu.Anchor>
              <DNAButton
                appearance="ghost"
                size="md"
                status="gray"
                padding="none"
                iconLeft="dots-vertical"
              />
            </DNAContextMenu.Anchor>
            <DNAContextMenu.Items>
              <DNAContextMenu.Item
                onPress={() => updateItemStatus(item.id, TodoStatus.DELETED)}
                icon="trash-can-outline"
                status="danger"
                iconStatus="danger"
                title="Delete"
              />
            </DNAContextMenu.Items>
          </DNAContextMenu>
        </DNABox>
      </Iffy>
    </DNABox>
  );
};

const EditMode: React.FC<TodoRowProps> = ({ item }) => {
  const { rhForm, items, isNewItem, updateFormValue, onCancelEdit } = useTodoState()
  const [title] = useState(item.title)
  const [resolution] = useState(item.resolution)
  const index = items.findIndex((toDo) => toDo.id === item.id)
  const { control } = rhForm
  const error = rhForm.formState.errors?.todoListWidget?.toDos?.[index]
  const titleRef = useRef<Input>(null)
  const resolutionRef = useRef<Input>(null)

  const onCancel = useCallback(() => {
    if (!isNewItem) {
      rhForm.setValue(`todoListWidget.toDos[${index}]` as `todoListWidget.toDos.${number}`, { ...item, title, resolution })
    }
    onCancelEdit()
  }, [isNewItem, rhForm, onCancelEdit, index, item, title, resolution])

  useEffect(() => {
    titleRef.current && isNewItem ? titleRef.current.focus() : resolutionRef.current?.focus()
  }, []);

  return (
    <DNABox appearance="col" alignY="center" spacing="sm">
      <DNABox spacing="xs" fill appearance="col">
        <Controller
          name={`todoListWidget.toDos[${index}].title` as 'name'}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <InputComponent
              testID='hub-task-name-text-input'
              onChangeText={onChange}
              value={value}
              title="Task Name"
              characterLimit={TODO_TITLE_CHARACTER_LIMIT}
              required={true}
              removeMarginPadding
              onKeyPress={(e) => {
                if (e.nativeEvent.key === 'Enter') {
                  e.preventDefault();
                  resolutionRef.current?.focus();
                }
              }}
              ref={titleRef}
            />
          )}
        />
        {error?.title &&
          <DNAText style={styles.errorMessage}>
            {Array.isArray(error.title)
              ? error.title.map((e, idx) => <DNABox key={`title_${idx}_error_message`}>{e}</DNABox>)
              : error.title.message}
          </DNAText>
        }
      </DNABox>
      <Controller
        name={`todoListWidget.toDos[${index}].resolution` as 'name'}
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputComponent
            testID='hub-description-text-input'
            onChangeText={onChange}
            value={value}
            title="Resolution / Description"
            characterLimit={TODO_RESOLUTION_CHARACTER_LIMIT}
            removeMarginPadding
            multiline
            showCharacterCounter={false}
            required={false}
            numOfLines={5}
            onKeyPress={({ nativeEvent }) => {
              if (nativeEvent.key === 'Enter' && 
                rhForm.getValues(`todoListWidget.toDos[${index}].title` as `todoListWidget.toDos.${number}.title`)) {
                updateFormValue();
              }
            }}
            ref={resolutionRef}
          />
        )}
      />
      <DNABox alignX="end" spacing="sm">
        <DNAButton
          appearance="outline"
          status="tertiary"
          padding="sm"
          children="Cancel"
          onPress={() => onCancel()}
          testID='hub-todo-cancel-btn'
        />
        <DNAButton
          padding="sm"
          children="Done"
          onPress={() => updateFormValue()}
          testID='hub-todo-done-btn'
        />
      </DNABox>
    </DNABox >
  );
};

const TodoRow: React.FC<TodoRowProps> = (props) => {
  const { editingId } = useTodoState()
  const { item } = props
  const mode = item.id === editingId ? 'edit' : 'view'
  return (
    <DNABox
      testID='hub-to-do-result-list'
      alignY="center"
      appearance="col"
      spacing="md"
      fill
      style={styles.row}
    >
      <Iffy is={mode === 'view'}>
        <ViewMode {...props} />
      </Iffy>
      <Iffy is={mode !== 'view'}>
        <EditMode {...props} />
      </Iffy>
    </DNABox>
  )
}

export default TodoRow
