import React, { useState, useEffect, ReactElement, LegacyRef, forwardRef } from 'react';
import {
  Box,
  Collapsible,
  Icon,
  Text,
  TextField,
  Iffy,
  util,
  DNAButton,
  DNABox,
} from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import { styles } from './Styles';
import { NativeSyntheticEvent, StyleProp, TextInputKeyPressEventData, TextStyle, ViewStyle } from 'react-native';
import { Input } from '@ui-kitten/components'
import { LuxSizeEnum } from '@alucio/lux-ui/src/typings';

type ComponentSizes =
  LuxSizeEnum.sm|
  LuxSizeEnum.md|
  LuxSizeEnum.lg

// [TODO]
//  - We could probably import/partial most of this definition
//  - No need to manually define them, but some of them could use extra overrides (i.e. autoCompleteType)
//    since React Native Web supports more than the React Native definition might
export interface TextAreaProps {
  autoFocus?: boolean,
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters',
  autoCorrect?: boolean,
  // [NOTE]
  //  - Even though we use `off`, password managers may still disregard this
  //    https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values
  //  - This is noticable in "Change Password" where opting into autofilling will also fill the header search bar with the email
  autoCompleteType?: 'off' | 'new-password' // there are more types but thats for android only, should we still add them?
  isPassword?: boolean;
  defaultValue?: string,
  title?: string;
  titleColor?: string;
  required?: boolean;
  multiline?: boolean;
  numOfLines?: number;
  value?: string;
  onChangeText?: (text:string)=> void
  isNumeric?: boolean;
  showCharacterCounter?: boolean;
  characterLimit?: number;
  collapsible?: boolean;
  initCollapsed?: boolean;
  descriptionText?: string;
  hidePlaceholder?: boolean;
  disabled?: boolean;
  status?: string;
  hideLabel?: boolean;
  getRightIconFunction?: (props: any) => ReactElement
  getLeftIconFunction?: (props: any) => ReactElement
  onBlur?: () => void;
  inputStyle?: StyleProp<TextStyle>
  removeMarginPadding?: boolean;
  placeHolder?: string;
  onSubmitEditing?: () => void;
  placeholderTextColor?: string;
  size?: ComponentSizes
  titleSpacing?: ComponentSizes
  buttonStyle?: StyleProp<ViewStyle>
  testID?: string;
  onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
}

type TitleSpacing = Record<ComponentSizes, ViewStyle>

const TITLE_SPACING:TitleSpacing = {
  sm: {
    marginBottom: 4,
  },
  md: {
    marginBottom: 8,
  },
  lg: {
    marginBottom: 16,
  },
};

const InputComponent = forwardRef<Input, TextAreaProps>((props: TextAreaProps, ref: LegacyRef<Input>) => {
  /** TODO: consider refactoring this for cleaner implementation
   * see: https://github.com/alucioinc/eeb/pull/1571#discussion_r788221306 */
  const {
    autoFocus = false,
    autoCapitalize,
    autoCorrect = true, // RN has default value as true
    autoCompleteType,
    characterLimit,
    collapsible = false,
    defaultValue,
    descriptionText,
    disabled = false,
    getRightIconFunction,
    getLeftIconFunction,
    hideLabel = false,
    initCollapsed = false,
    inputStyle,
    isPassword = false,
    multiline = false,
    hidePlaceholder = false,
    titleSpacing = LuxSizeEnum.sm,
    numOfLines,
    placeHolder,
    removeMarginPadding = false,
    required = true,
    showCharacterCounter = false,
    status,
    title,
    titleColor,
    value,
    onChangeText,
    isNumeric,
    onSubmitEditing,
    size = LuxSizeEnum.lg,
    buttonStyle,
    onBlur,
    onKeyPress,
    testID,
    ...rest
  } = props

  const [collapsed, setCollapsed] = useState<boolean>(initCollapsed);
  const toggleCollapsed = () => setCollapsed(p => !p);

  const [charactersCounter, setCharactersCounter] = useState<number>(value?.length ?? 0);
  useEffect(() => { setCharactersCounter(value?.length ?? 0) }, [value]);

  function onChange(text: string): void {
    if (isNumeric) {
      text = text.replace(/[^0-9.]+/g, '');
    }
    onChangeText?.(text);
  }

  return (
    <Box
      style={util.mergeStyles(
        undefined,
        styles.Container,
        [styles.NoMarginPadding, removeMarginPadding])
      }
    >
      <Iffy is={!hideLabel}>
        <Box style={styles.TitleContainer}>
          <Iffy is={collapsible}>
            <DNAButton
              style={buttonStyle}
              status="primary"
              appearance="ghost"
              iconLeft={collapsed ? 'plus-box' : 'minus-box'}
              onPress={toggleCollapsed}
            />
          </Iffy>
          <Box flexRow>
            <Iffy is={required}>
              <Text style={styles.required}>*</Text>
            </Iffy>
            <DNABox alignY="center">
              <Text
                style={
                  util.mergeStyles(
                    undefined,
                    styles.Title,
                    [{ color: titleColor }, titleColor],
                    [TITLE_SPACING[titleSpacing], titleSpacing],
                  )
                }
              >{title}
              </Text>
              <Iffy is={descriptionText}>
                <DNAPopover >
                  <DNAPopover.Anchor>
                    <Icon style={styles.HelpToolTipIconStyle} name="help-circle" />
                  </DNAPopover.Anchor>
                  <DNAPopover.Content>
                    <Text style={{ color: 'white' }}>{descriptionText}</Text>
                  </DNAPopover.Content>
                </DNAPopover>
              </Iffy>
            </DNABox>
          </Box>
          <Box style={styles.CounterContainer}>
            {
              (showCharacterCounter && !collapsed) &&
                <Text style={styles.CharacterCounter}>{charactersCounter}/{characterLimit}</Text>
            }
          </Box>
        </Box>
      </Iffy>
      <Collapsible collapsed={collapsible && collapsed}>
        <TextField.Kitten
          accessoryLeft={getLeftIconFunction}
          accessoryRight={getRightIconFunction}
          autoFocus={autoFocus}
          disabled={disabled}
          defaultValue={defaultValue}
          maxLength={characterLimit ?? undefined}
          multiline={multiline}
          numberOfLines={numOfLines ?? 1}
          onSubmitEditing={onSubmitEditing}
          placeholder={hidePlaceholder ? '' : placeHolder || title}
          ref={ref as React.Ref<Input>}
          secureTextEntry={isPassword}
          size={size}
          status={status}
          style={util.mergeStyles(
            undefined,
            styles.DefaultInputComponent,
            [inputStyle, !!inputStyle],
          )}
          textStyle={inputStyle}
          value={value}
          onChangeText={onChange}
          nativeID={testID}
          testID={testID}
          onBlur={onBlur}
          autoCapitalize={autoCapitalize}
          autoCorrect={autoCorrect}
          onKeyPress={onKeyPress}
          // @ts-expect-error - Currently, RNWeb supports more definitions than vanilla RN - May be fixed by upgrading RNWeb/RN/types
          autoCompleteType={autoCompleteType}
          {...rest}
        />
      </Collapsible>
    </Box>
  )
});

export default React.memo(InputComponent);
