
import React from 'react';
import { PlayerMode } from '@alucio/core';
import HighlighterPlayer from './HighlighterPlayer';
import HighlighterSource from './HighlighterSource';

type HighlighterProps = {
    iFrameRef: React.MutableRefObject<HTMLIFrameElement | null>;
    mode: PlayerMode;
    popoutReference?: React.MutableRefObject<Window | null | undefined>;
  }

type ModeVariants = Record<PlayerMode, React.ElementType>

const modeVariants:ModeVariants = {
  NON_INTERACTIVE: HighlighterPlayer,
  INTERACTIVE: HighlighterSource,
}

const Highlighter:React.FC<HighlighterProps> = (props) => {
  const { iFrameRef, mode, popoutReference } = props;
  const additionalProps = mode === 'NON_INTERACTIVE' ? { popoutReference } : {};

  const CurrentModeVariant = modeVariants[mode]

  return <CurrentModeVariant iFrameRef={iFrameRef} {...additionalProps} />
}

export default Highlighter
