import React from 'react';
import { DNABox, DNAButton, DNAText, Iffy } from '@alucio/lux-ui';
import DNATextLink from '@alucio/lux-ui/lib/components/type/DNAText/DNATextLink';
import SendLogs from 'src/screens/Profile/SendLogs';
import { logout } from '../../App';
import DNAPopover from '../DNA/Popover/DNAPopover';
import { goToCloseAppAppleLink } from './QuotaErrorMessage';

const POP_OVER_TEXT = 'To force quit, enter chrome://quit or edge://quit \n' +
  'into your Chrome or Edge browser.'

interface Props {
  hideLogoutOption?: boolean
}

const LoadingErrorMessage = (props: Props) => {
  const isPWAStandalone = window.matchMedia('(display-mode: standalone)').matches;

  return (
    <DNABox alignX="center" alignY="center">
      <DNABox appearance="col" spacing="lg">
        <DNAText bold h2>Try again! This seems to be taking awhile</DNAText>
        <DNABox appearance="col" spacing="md">
          <DNAText bold h3>Here are some steps that may help:</DNAText>
          <DNABox appearance="col" spacing="md">
            <Iffy is={!props.hideLogoutOption}>
              <DNAText h5 bold={false}>
                • Select the logout option
                <DNAPopover interactive>
                  <DNAPopover.Anchor>
                    <DNATextLink status="primary" onPress={logout}>
                      &nbsp;here&nbsp;
                    </DNATextLink>
                  </DNAPopover.Anchor>
                  <DNAPopover.Content>
                    <DNAText status="basic">
                      This will clear all files synced for offline usage
                    </DNAText>
                  </DNAPopover.Content>
                </DNAPopover>
                and try logging back in
              </DNAText>
            </Iffy>
            <DNABox>
              <DNAText>•&nbsp;</DNAText>
              <DNAPopover interactive>
                <DNAPopover.Anchor>
                  <DNATextLink status="primary" h5 bold={false}>
                    Force quit&nbsp;
                  </DNATextLink>
                </DNAPopover.Anchor>
                <DNAPopover.Content>
                  <DNAText status="basic">
                    {POP_OVER_TEXT}
                  </DNAText>
                </DNAPopover.Content>
              </DNAPopover>
              <DNAText h5 bold={false}>
                the browser and relaunch it.
              </DNAText>
              <Iffy is={isPWAStandalone}>
                <DNAButton onPress={goToCloseAppAppleLink} appearance="ghostLink" padding="none">See how</DNAButton>
              </Iffy>
            </DNABox>
            <DNAText>
              • If you continue to have issues, please use the button below to send logs and contact
              <DNATextLink status="primary" href="mailto:support@alucio.io">
                &nbsp;support@alucio.io
              </DNATextLink>
            </DNAText>
          </DNABox>
        </DNABox>
        <SendLogs />
      </DNABox>
    </DNABox>
  );
};

export default LoadingErrorMessage;
