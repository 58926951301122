import { useState, useEffect } from 'react';
import { ContentPageData } from '../useContentPageData/useContentPageData';

export interface MatchedPage {
  presentationPageNumber: number,
  matches: string[],
  s3Key: string,
}

const textSearch = (data: ContentPageData[], query: string): MatchedPage[] => {
  const totalMatches: MatchedPage[] = [];
  data.forEach((page: ContentPageData) => {
    if (!page.content || query.length < 3) {
      return [];
    }

    const indices: number[] = [];
    const textWithoutLineBreaks = page.content?.replace(/(?:\r\n|\r|\n)/g, ' ').toLowerCase();
    const text = textWithoutLineBreaks.toLowerCase();
    const matches: string[] = [];
    let index = text.indexOf(query);
    while (index !== -1) {
      indices.push(index);
      index = text.indexOf(query, index + 1);
    }

    indices.forEach((i) => {
      matches.push(getTextBlurb(textWithoutLineBreaks, query, i));
    });

    if (matches.length) {
      totalMatches.push({
        presentationPageNumber: page.presentationPageNumber,
        s3Key: page.s3Key,
        matches,
      });
    }
  });

  return totalMatches;
}

const getTextBlurb = (text: string, str: string, index: number): string => {
  const start = Math.max(0, index - 30);
  const end = Math.min(text.length, index + str.length + 30);
  return `${start > 0 ? '...' : ''}` + text.substring(start, end) + `${end < text.length ? '...' : ''}`;
}

/**
 * Function for searching text in a presentable's pages content
 * @param presentable: Presentable - Contains the pages to search over
 * @param searchText: string - Text to find in presentable's pages
 * @returns { matches: MatchedPage[], totalMatchCount: number }
 */
const useFileTextSearch = (searchText: string, pageData?: ContentPageData[]) => {
  const [matches, setMatches] = useState<MatchedPage[]>([]);
  const totalMatchCount = matches?.reduce((acc, page) => acc + page.matches.length, 0);

  // PERFORMS THE SEARCH UPON THE SEARCHTEXT BEING UPDATED
  useEffect(() => {
    if (pageData) {
      const matches = textSearch(pageData, searchText.toLowerCase());
      setMatches(matches)
    }
  }, [searchText, pageData]);

  return {
    matches,
    totalMatchCount,
  }
}

export default useFileTextSearch;
