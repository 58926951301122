import React, { PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { luxColors, responsive } from '@alucio/lux-ui';
import useRetryOnErrorsSSO from './components/DNA/hooks/useRetryOnSSoError';
import { SearchTextContextProvider } from './components/Header/SearchInput/SearchInput';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import useCurrentPage from './components/DNA/hooks/useCurrentPage';

const styles = StyleSheet.create({
  AppContainer: {
    backgroundColor: luxColors.applicationBackground.primary,
    flex: 1,
    height: responsive.dimensions.trueHundredHeight,
    maxHeight: '-webkit-fill-available',
  },
});

const AppContainer:React.FC<PropsWithChildren> = ({ children }) => {
  useRetryOnErrorsSSO();
  const route = useCurrentPage({ exact: false })
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const viewStyle = [
    styles.AppContainer,
    isPublisherRoute && { backgroundColor: colors['color-text-white'] },
  ]

  return (
    <SearchTextContextProvider>
      <View style={viewStyle}>
        {children}
      </View>
    </SearchTextContextProvider>
  )
}
export default AppContainer;
