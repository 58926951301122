import { FileType } from '@alucio/aws-beacon-amplify/src/models';
import { PresentableModelORM } from 'src/state/context/ContentProvider/ContentProvider';
import { isCustomDeckORM, isDocumentVersionORM, isFolderItemORM } from 'src/types/types';

const CUSTOM_DECK_WEB_HTML_MSG = 'This deck contains a WEB or HTML document which cannot be used in Presenter View';
const WEB_HTML_DOC_MSG = 'This type of document cannot be used in Presenter view';

interface PresentationVirtualPresentStatus {
  isHTMLWebPresentation: boolean
  message?: string
}

/** CHECKS IF THE GIVEN FILE CAN BE PRESENTED IN VIRTUAL MODE */
export const getPresentationVirtualStatus = (orm?: PresentableModelORM): PresentationVirtualPresentStatus => {
  let message: string | undefined;
  if (isDocumentVersionORM(orm) && [FileType.WEB, FileType.HTML].includes(FileType[orm.model.type])) {
    message = WEB_HTML_DOC_MSG;
  } else if (isFolderItemORM(orm)) {
    if (isCustomDeckORM(orm.relations.itemORM)) {
      const customDeckORM = orm.relations.itemORM;
      if (customDeckORM.meta.containsWebDocs || customDeckORM.meta.containsHTMLDocs) {
        message = CUSTOM_DECK_WEB_HTML_MSG;
      }
    } else if (isDocumentVersionORM(orm.relations.itemORM)) {
      if ([FileType.WEB, FileType.HTML].includes(FileType[orm.relations.itemORM.model.type])) {
        message = WEB_HTML_DOC_MSG;
      }
    }
  }

  return {
    isHTMLWebPresentation: !!message,
    message,
  };
};
