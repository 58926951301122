/* eslint-disable max-len */
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgFileDocumentMultipleOutline = (props: SvgProps) => (
  <Svg
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <Path
      fill={props.color ?? '#fff'}
      d="m16 0h-8c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-12zm4 18h-12v-16h7v5h5zm-16-14v18h16v2h-16c-1.1 0-2-.9-2-2v-18zm6 6v2h8v-2zm0 4v2h5v-2z"
    />
  </Svg>
);

export default SvgFileDocumentMultipleOutline;
