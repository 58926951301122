import { useSelector } from 'react-redux'
import { createSelector, Selector } from '@reduxjs/toolkit'
import {
  User,
  IntegrationSettings,
} from '@alucio/aws-beacon-amplify/src/models'
import { RootState } from '../store'

export interface UserORM {
  user: User,
  meta: {
    formattedName?: string;
  }
}

export interface LockedFilterEntry {
  [key: string]: string[]
}

export interface IndexedUserORM {
  [key: string]: UserORM
}

export const selIntegrationSettings = (state: RootState): IntegrationSettings[] => state.integrationSettings.records;
export const selIntegrationSettingId = (_, __, tenantId: string) => tenantId;

export const integrationSettingsList: Selector<RootState, IntegrationSettings[]> = createSelector(
  selIntegrationSettings,
  (integrationSettings): IntegrationSettings[] => integrationSettings,
);

export const integrationSetting: Selector<RootState, IntegrationSettings | undefined> = createSelector(
  selIntegrationSettings,
  selIntegrationSettingId,
  (integrationSettings, integrationSettingsId): IntegrationSettings | undefined => {
    return integrationSettings.find((integrationSetting) => integrationSetting.id === integrationSettingsId);
  },
);

export const useIsIntegrationSettingsHydrated = () =>
  useSelector((state: RootState) =>
    state.integrationSettings.hydrated)

/** Returns the list of Integration Settings */
export const useIntegrationSettingsList = (): ReturnType<typeof integrationSettingsList> =>
  useSelector((state: RootState) => integrationSettingsList(state));

/** Returns a Integration Setting based on the given id */
export const useIntegrationSetting = (integrationSettingsId: string): ReturnType<typeof integrationSetting> =>
  useSelector((state: RootState) => integrationSetting(state, undefined, integrationSettingsId));
