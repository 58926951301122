import React from 'react';
import { DNABox, DNAButton, DNAModal, DNAText } from '@alucio/lux-ui';
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal';

export type CreateGroupModalProps = {
  groupName: string,
  onSuccess: (name: string) => void
}

const DeleteGroupModal: DNAModalVariant<CreateGroupModalProps> = props => {
  const { groupName, closeModal, onSuccess } = props;

  const onSubmit = (data: any) => {
    onSuccess(data.name)
    closeModal()
  }

  return (
    <DNAModal>
      <DNAModal.Header onClose={closeModal}>
        <DNAText h5>Delete group?</DNAText>
      </DNAModal.Header>
      <DNAModal.Body>
        <DNABox appearance="col" style={{ minWidth: 560 }}>
          <DNAText>{groupName}</DNAText>
        </DNABox>

      </DNAModal.Body>
      <DNAModal.Footer>
        <DNABox fill alignX="end">
          <DNAButton size="sm"appearance="outline" status="tertiary" onPress={closeModal}>
            Cancel
          </DNAButton>
          <DNAButton
            size="sm"
            status="danger"
            style={{ marginLeft: 8 }}
            onPress={(onSubmit)}
          >
            Delete
          </DNAButton>
        </DNABox>
      </DNAModal.Footer>
    </DNAModal>
  )
}

export default DeleteGroupModal;
