
import useThumbnailSize, { ThumbnailSize } from 'src/hooks/useThumbnailSize/useThumbnailSize'
import { BuilderMode } from './PresentationBuilderStateProvider'

type BuilderModeConfigVariants = Record<BuilderMode, ThumbnailSize[]>
const defaultVariant:ThumbnailSize[] = ['xxl', 'xl', 'lg', 'md']
const builderModeConfigVariants : BuilderModeConfigVariants = {
  selection: ['xl', 'lg', 'md'],
  customization: defaultVariant,
  replace: defaultVariant,
}

const useEditorState = (builderMode:BuilderMode) => {
  const currentVariant = builderModeConfigVariants[builderMode]
  const {
    thumbnailSize,
    setThumbnailSize,
    cycleThumbnailSize,
    thumbnailDimensions,
  } = useThumbnailSize(currentVariant, 'lg')

  return {
    editorThumbnailSize: thumbnailSize,
    setEditorThumbnailSize: setThumbnailSize,
    cycleEditorThumbnailSize: cycleThumbnailSize,
    editorThumbnailDimensions: thumbnailDimensions,
  }
}

export default useEditorState
