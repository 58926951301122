import { DocumentVersionORMMap } from 'src/state/redux/selector/document';

/**
 * If the document is deleted, or the latest version is revoked, or not shareable, return true
 */
export function isDocVerHidden (
  allDocumentMap: DocumentVersionORMMap,
  contentId: string,
): boolean {
  const docVerORM = allDocumentMap[contentId]
  if (!docVerORM) return true
  const latestDocVer = docVerORM.relations.documentORM.relations.version.latestPublishedDocumentVersionORM!
  const isParentDocRevoked = docVerORM.relations.documentORM.model.status === 'REVOKED'
  const islatestVersionNotShareable = !latestDocVer.meta.permissions.fieldLevel.share
  return isParentDocRevoked || islatestVersionNotShareable
}