import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch } from 'src/state/redux'

import { FlatList, View, Button, Text, ScrollView } from 'react-native'
import { DNABox, DNAText, DNAButton, DNADivider, Iffy, Select, IndexPath } from '@alucio/lux-ui'
import Accordion from 'react-native-collapsible/Accordion'
import { useSyncState, useSyncManifests } from 'src/state/redux/selector/cache'

import { useAllDocumentVersionsMap, useAllDocumentVersionMap } from 'src/state/redux/selector/document'

import { useAppSettings } from 'src/state/context/AppSettings';
import { cacheActions } from 'src/state/redux/slice/Cache/cache'
import workerChannel from 'src/worker/channels/workerChannel'
import CacheDB from 'src/worker/db/cacheDB'
import { bytesToSize } from 'src/utils/documentHelpers'

import { syncCacheManifest } from 'src/state/cache/syncManifests'
import { CacheManifestEntry } from '@alucio/core';
import { DocumentVersion } from '@alucio/aws-beacon-amplify/src/models';

const CacheSyncInfo: React.FC = () => {
  const cacheDB = useRef(new CacheDB()).current
  const [selectedVersion, setSelectedVersion] = useState<IndexPath|IndexPath[]>()
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [activeSections, setActiveSections] = useState<Array<number>>([])
  const documentVersionsMap = useAllDocumentVersionsMap()
  const documentVersionMap = useAllDocumentVersionMap()
  const syncState = useSyncState()
  const syncManifests = useSyncManifests()
  const dispatch = useDispatch()

  const selectedVersionObj = (
    !Array.isArray(selectedVersion) &&
    selectedVersion?.section !== undefined &&
    selectedVersion?.row !== undefined
  )
    ? Object.values(documentVersionsMap)[selectedVersion.section][selectedVersion.row]
    : undefined

  useEffect(() => {
    cacheDB.open()
    return () => { cacheDB.close() }
  }, [])

  const refreshSyncManifests = async () => {
    const cacheManifest = await cacheDB.getCacheManifest()
    dispatch(cacheActions.setManifestEntries(cacheManifest))
  }

  const purgeCacheDB = () => {
    workerChannel.postMessageExtended({
      type: 'CACHE_DB',
      value: 'PURGE',
    })
  }

  const startSync = async () => {
    await syncCacheManifest()
    await refreshSyncManifests()

    workerChannel.postMessageExtended({ type: 'CLIENT_CONNECTED', value: 'START_SYNC' })
  }

  const handlePausedSync = async () => {
    if (syncState.matches('online.paused')) { workerChannel.postMessageExtended({ type: 'RESUME_SYNC' }) }

    if (syncState.matches('online.sync')) { workerChannel.postMessageExtended({ type: 'PAUSE_SYNC' }) }
  }

  const toggleClientWorkerLogging = (value: boolean) => () => {
    workerChannel.postMessageExtended({ type: 'SET_WORKER_CLIENT_LOGGING', value: value })
  }

  const removeSyncedFile = (entry: CacheManifestEntry) => async () => {
    setIsDeleting(true)
    await cacheDB.removeCacheManifestEntry(entry.documentVersionId)
    await cacheDB.removeCachePayload(entry.documentVersionId)
    setIsDeleting(false)
    await refreshSyncManifests()
  }

  const addSyncFile = (docVer?: DocumentVersion) => async () => {
    if (!docVer) return;
    const docVerORM = documentVersionMap[docVer.id]

    if (!docVerORM) return;

    setIsDeleting(true)
    await cacheDB.syncCacheManifest([docVerORM])
    setIsDeleting(false)
    await refreshSyncManifests()
  }

  const syncInfo = useMemo(
    () => {
      const contentSyncManifest = Object
        .values(syncState.context.syncManifest)
        .sort((a, b) => a.documentVersionId.localeCompare(b.documentVersionId))

      const contentFilesRemaining = contentSyncManifest
        ? `${contentSyncManifest.length} file(s) remaining`
        : ''

      const contentSizeLeft =
      contentSyncManifest.length
        ? bytesToSize(
          contentSyncManifest.reduce<number>(
            (acc, entry) => acc + entry.fileSize,
            0,
          ),
        )
        : '0 MB'

      return {
        contentSyncManifest,
        contentFilesRemaining,
        contentSizeLeft,
      }
    },
    [syncState.context.syncManifest],
  )

  const syncManifestsSorted = useMemo(
    () => [...syncManifests].sort((a, b) => b.documentVersionId.localeCompare(a.documentVersionId)),
    [syncManifests],
  )

  const renderContent = (section: string) => {
    if (!syncState)
    { return <></> }

    if (section === 'Sync additional versions')
    { return (
      <DNABox appearance="col">
        <Select
          value={selectedVersionObj?.title}
          selectedIndex={selectedVersion}
          onSelect={setSelectedVersion}
        >
          {
              Object
                .entries(documentVersionsMap)
                .map(([docId, docVers]) => (
                  <Select.Group title={`Document Id - ${docId}`}>
                    {
                      docVers.map(docVer => (
                        <Select.Item
                          title={`(${docVer.semVer?.major}.${docVer.semVer?.minor}) | ${docVer.title}`}
                        />
                      ))
                    }
                  </Select.Group>
                ))
            }
        </Select>
        <DNAButton
          disabled={
              !selectedVersionObj ||
              !!syncManifestsSorted.find(entry => entry.documentVersionId === selectedVersionObj?.id)
            }
          onPress={addSyncFile(selectedVersionObj)}
        >
          Add SyncEntry
        </DNAButton>
      </DNABox>
    ) }
    else if (section === 'Manifests')
    { return (
      <DNABox fill appearance="col">
        <DNAText h3>Sync Manifests ({syncManifestsSorted.length})</DNAText>
        <ScrollView horizontal>
          <FlatList
            keyExtractor={(entry) => entry.id}
            data={syncManifestsSorted}
            renderItem={({ item: entry }) => (
              <View style={{ flexDirection: 'row', marginBottom: 8 }}>
                <Button
                  disabled={
                      entry.status !== 'LOADED' ||
                      isDeleting ||
                      syncState.matches('online.sync')
                    }
                  onPress={removeSyncedFile(entry)}
                  title="REMOVE"
                />
                <Text style={{ minWidth: 480, maxWidth: 480 }}>
                  <Text style={{ fontWeight: 'bold' }} numberOfLines={1}>TITLE:</Text>
                  {' ' + documentVersionMap[entry.documentVersionId]?.model.title}
                </Text>
                <Text style={{ minWidth: 180 }}>
                  <Text style={{ fontWeight: 'bold' }}>STATUS:</Text>
                  {' ' + entry.status}
                </Text>
                <Text style={{ minWidth: 120 }}>
                  <Text style={{ fontWeight: 'bold' }}>DOC_VER:</Text>
                  {
                    ' ' + `${documentVersionMap[entry.documentVersionId]?.model.semVer?.major}` +
                          `.${documentVersionMap[entry.documentVersionId]?.model.semVer?.minor}`
                  }
                </Text>
                <Text style={{ minWidth: 380 }}>
                  <Text style={{ fontWeight: 'bold' }}>ENTRY ID:</Text>
                  {' ' + entry.id}
                </Text>
                <Text style={{ minWidth: 380 }}>
                  <Text style={{ fontWeight: 'bold' }}>VER ID:</Text>
                  {' ' + entry.documentVersionId}
                </Text>
                <Text style={{ minWidth: 200 }}>
                  <Text style={{ fontWeight: 'bold' }}>TYPE:</Text>
                  {
                      (' ' + entry.cacheType) +
                      (entry.cacheType === 'CONTENT'
                        ? ` (${entry.type})`
                        : ''
                      )
                    }
                </Text>
              </View>
            )}
          />
        </ScrollView>
        <DNADivider/>
      </DNABox>
    ) }
    else if (section === 'Content List to Sync')
    { return (
      <DNABox appearance="col">
        <DNAText h3>Content List To Sync</DNAText>
        <DNAText>{syncInfo.contentFilesRemaining}</DNAText>
        <DNAText>{syncInfo.contentSizeLeft} remaining</DNAText>
        <Iffy is={!syncInfo.contentSyncManifest.length}>
          <DNAText>No files to sync</DNAText>
        </Iffy>

        <FlatList
          keyExtractor={(entry) => entry.id}
          data={syncInfo.contentSyncManifest.filter(entry => entry.cacheType === 'CONTENT')}
          renderItem={({ item: entry }) => (
            <Text>{entry.id} : {entry.fileSize}</Text>
          )}
        />
        <DNADivider/>
      </DNABox>
    ) }
    else if (section === 'Thumbnail List to Sync')
    { return (
      <DNABox appearance="col">
        <DNAText h3>Thumbnail List To Sync</DNAText>
        <Iffy is={!syncState.context.syncManifest.length}>
          <DNAText>No files to sync</DNAText>
        </Iffy>
        <FlatList
          keyExtractor={(entry) => entry.id}
          data={syncInfo.contentSyncManifest.filter(entry => entry.cacheType === 'THUMBNAIL')}
          renderItem={({ item: entry }) => (
            <DNAText>{entry.id} : {entry.fileSize}</DNAText>
          )}
        />
        <DNADivider/>
      </DNABox>
    ) }
    else
    { return <></> }
  }

  const syncStats = syncState
    ? (
      <DNABox appearance="col" fill spacing="md">
        {/* CURRENT MACHINE STATE */}
        <DNAText>State: {JSON.stringify(syncState.value)}</DNAText>
        <DNAText>Has attempted sync? {' ' + syncState.context.attemptedSync}</DNAText>

        {/* UTIL BUTTONS */}
        <DNABox appearance="col">
          <DNAText h3>Util</DNAText>
          <DNABox spacing="md">
            <DNAButton
              status="danger"
              onPress={purgeCacheDB}
              disabled={syncState.matches('online.sync')}
            >
              Purge CacheDB
            </DNAButton>
            <DNAButton onPress={refreshSyncManifests}>
              Get current sync manifests
            </DNAButton>
            <DNAButton onPress={() => dispatch(cacheActions.clearManifests())}>
              Clear sync manifests (client only)
            </DNAButton>
            <DNAButton
              status="success"
              onPress={startSync}
              disabled={syncState.matches('online.sync')}
            >
              Start Sync
            </DNAButton>
            <DNAButton
              disabled={!(
                syncState.matches('online.sync') ||
                syncState.matches('online.paused')
              )}
              onPress={handlePausedSync}
            >
              {
                syncState.matches('online.paused')
                  ? 'Resume Sync'
                  : 'Pause Sync'
              }
            </DNAButton>
          </DNABox>
          <DNABox spacing="md">
            <DNAButton onPress={toggleClientWorkerLogging(true)}>
              Enable Worker Client Logging
            </DNAButton>
            <DNAButton onPress={toggleClientWorkerLogging(false)}>
              Disable Worker Client Logging
            </DNAButton>
          </DNABox>
        </DNABox>

        <Accordion
          expandMultiple
          underlayColor="transparent"
          activeSections={activeSections}
          renderHeader={(props) => <DNAText h1>{props}</DNAText>}
          renderSectionTitle={() => <></>}
          sections={[
            'Sync additional versions',
            'Manifests',
            'Content List to Sync',
            'Thumbnail List to Sync',
          ]}
          renderContent={renderContent}
          onChange={setActiveSections}
        />

        {/* ERRORS */}
        <DNABox appearance="col">
          <DNAText h3>Error</DNAText>
          <DNAText>Machine Errors: {syncState.context.error.machine ?? 'None 👍'}</DNAText>
          <DNAText>SyncEntries Errors:{' '}
            {
              syncState.context.error.syncEntries
                ? JSON.stringify(syncState.context.error.syncEntries, null, '\t')
                : 'None 👍'
            }
          </DNAText>
        </DNABox>
      </DNABox>
    )
    : <></>

  return (
    // @ts-expect-error
    <DNABox appearance="col" fill style ={{ overflowX: 'scroll' }}>
      <DNAText h2>Content Sync Manager</DNAText>
      {syncStats}
    </DNABox>
  )
}

const SyncStatusTablet = () => {
  return <CacheSyncInfo />
}

const SyncStatusDesktop = () => {
  return <CacheSyncInfo />
}

export default () => {
  const appSettings = useAppSettings()

  return (appSettings.deviceMode === 'tablet')
    ? <SyncStatusTablet />
    : <SyncStatusDesktop />
}
