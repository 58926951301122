import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCustomFormRecordById } from 'src/state/redux/selector/customFormRecord';
import { StandaloneForm } from 'src/state/redux/selector/crm';
import { useComposableForm } from 'src/components/CustomFields/ComposableForm';
import { CustomFormRecordORM } from 'src/types/orms';
import useSaveCustomFormRecord from './useSaveCustomFormRecord';
import { CustomFormRecordRelationshipModel, CustomFormRecordStatus } from '@alucio/aws-beacon-amplify/src/models';
import useCRMMeetingFormHandler from 'src/hooks/useCRMMeetingFormHandler/useCRMMeetingFormHandler';
import { CUSTOM_FORM_RECORD_ENTITY } from '@alucio/aws-beacon-amplify/src/API';
import { consolidateFormErrorMessages } from 'src/components/Meeting/Util';
import { drawerActions } from '../../redux/slice/drawer';

interface CustomFormRecordType {
  errorMessage?: string;
  isDirty?: boolean;
  isReadOnly: boolean;
  isSaving: boolean;
  recordORM?: CustomFormRecordORM;
  standaloneForm: StandaloneForm;
  onSave: (isSubmit?: boolean) => void;
}

const CustomFormRecordContext = React.createContext<CustomFormRecordType>({
  isSaving: false,
  isReadOnly: false,
  onSave: () => null,
  standaloneForm: {
    name: '',
    type: CUSTOM_FORM_RECORD_ENTITY.CRM_OBJECT,
    id: '',
  },
});

interface CustomFormRecordProviderProps {
  onSave?: (recordId: string) => void;
  parentId?: string;
  parentModel?: CustomFormRecordRelationshipModel;
  recordId?: string;
  standaloneForm: StandaloneForm;
}

const CustomFormRecordProvider: React.FC<PropsWithChildren<CustomFormRecordProviderProps>> = (props) => {
  const { children, parentModel, parentId, recordId, standaloneForm } = props;
  const dispatch = useDispatch();
  const recordORM = useCustomFormRecordById(recordId);
  const { rhForm } = useComposableForm();
  const { isDirty } = rhForm.formState;
  const savePayload = { recordId, parentId, parentModel, standaloneForm, onSave: props.onSave };
  const utils = useSaveCustomFormRecord(savePayload);
  useCRMMeetingFormHandler({ rhForm });

  useEffect(() => {
    dispatch(drawerActions.setPendingChanges({ hasPendingChanges: isDirty }));
  }, [isDirty]);

  const onSave = (isSubmit?: boolean): void => {
    rhForm.handleSubmit((values) => {
      isSubmit ? utils.submitToCRMCustomForm({ values, recordORM })
        : utils.saveCustomForm({ values, recordORM })
    }, (errors: { [s: string]: unknown; } | ArrayLike<unknown>) => {
      const formattedFormErrors = consolidateFormErrorMessages(errors);
      utils.setErrorMessage(formattedFormErrors);
    })();
  };

  const value: CustomFormRecordType = {
    errorMessage: utils.errorMessage,
    isDirty,
    isReadOnly: recordORM?.model.status === CustomFormRecordStatus.LOCKED,
    isSaving: utils.isSaving,
    recordORM,
    standaloneForm,
    onSave,
  };

  return (
    <CustomFormRecordContext.Provider value={value}>
      {children}
    </CustomFormRecordContext.Provider>
  );
};

export const useCustomFormRecordProvider = () => React.useContext(CustomFormRecordContext);

export default CustomFormRecordProvider;
