import React from 'react'
import { StyleSheet } from 'react-native'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors'
import { DNABox, DNAText } from '@alucio/lux-ui'

const styles = StyleSheet.create({
  panel: {
    backgroundColor: colors['color-gray-900'],
  },
  documentRow: {
    height: 62,
    backgroundColor: '#FFFFFF14',
    padding: 8,
    borderRadius: 4,
  },
  badge: {
    width: 32,
    height: 20,
    backgroundColor: '#FFFFFF14',
  },
  thumbnail: {
    width: 80,
    height: 46,
    backgroundColor: '#FFFFFF14',
  },
})

const OpenedPresentationsEmptyState: React.FC = () => {
  return (
    <DNABox spacing="sm" appearance="col" fill style={styles.panel}>
      {[...Array(4).keys()].map((idx) =>
        (<DNABox fill style={styles.documentRow} spacing="sm" key={idx}>
          <DNABox style={styles.thumbnail} />
          <DNABox appearance="col" spacing="between" fill>
            <DNAText style={{ color: '#FFFFFF52' }}>File {idx + 1}</DNAText>
            <DNABox spacing="sm">
              <DNABox style={styles.badge} />
              <DNABox style={styles.badge} />
              <DNABox />
            </DNABox>
          </DNABox>
        </DNABox>),
      )}
    </DNABox>
  )
}

export default OpenedPresentationsEmptyState
