import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, Iffy } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import useSelector, { composite } from 'src/hooks/useSelector';
import * as findReplacementSelector from 'src/state/machines/findReplacement/FindReplacement.selectors';
import { useFindReplacementState } from '../FindReplacementProvider';
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import LeftContent from './LeftContent';
import RightContent from './RightContent';

const S = StyleSheet.create({
  contentContainer: {
    backgroundColor: colors['color-gray-10'],
  },
  thumbnailContainer: {
    borderColor: colors['color-gray-80'],
    borderWidth: 3,
    borderRadius: 6,
    padding: 3,
  },
});

const Content: React.FC = () => {
  const { service } = useFindReplacementState();

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      findReplacementSelector.needReviewGroups,
    ),
  );

  return (
    <DNABox fill style={S.contentContainer}>
      <Iffy is={!cond.needReviewGroups.length}>
        <DNAEmpty
          useContainer={false}
          emptyVariant={EmptyVariant.FindReplacementEmpty}
        />
      </Iffy>
      <Iffy is={cond.needReviewGroups.length}>
        <LeftContent />
        <RightContent />
      </Iffy>
    </DNABox>
  )
}

export default Content
