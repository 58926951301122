/* eslint-disable max-len */
import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgAnalytics = (props: SvgProps) => (
  <Svg
    width={32}
    height={32}
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M28 25h-1V5a1 1 0 0 0-1-1h-7a1 1 0 0 0-1 1v5h-6a1 1 0 0 0-1 1v5H6a1 1 0 0 0-1 1v8H4a1 1 0 0 0 0 2h24a1 1 0 0 0 0-2ZM20 6h5v19h-5V6Zm-7 6h5v13h-5V12Zm-6 6h4v7H7v-7Z"
      fill={props.color ?? '#fff'}
    />
  </Svg>
);
export default SvgAnalytics;
