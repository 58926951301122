import React, { Fragment } from 'react'
import { StyleSheet } from 'react-native'

import { DocumentORM } from 'src/types/types'
import { CustomFieldDefinition, CustomFieldUsage } from '@alucio/aws-beacon-amplify/src/models';
import { useTenantCustomFields } from 'src/state/redux/selector/tenant'
import { DNAText, Iffy } from '@alucio/lux-ui'

export type CustomLabelDisplayProps = {
  documentORM: DocumentORM;
}

const S = StyleSheet.create({
  bulletText: {
    marginHorizontal: 8,
    textAlignVertical: 'center',
  },
})

export function CustomLabelDisplay(props: CustomLabelDisplayProps) {
  const { documentORM } = props;
  const tenantCustomFields = useTenantCustomFields({
    defaultSearchFilter: true,
    usages: { internalUsages: [CustomFieldUsage.DOCUMENT] },
  });
  const { configsMap } = documentORM.meta.customValues;

  const fieldsWithData = tenantCustomFields.filter((field: CustomFieldDefinition) => (configsMap &&
    configsMap?.[field.id]?.displayValues.length > 0));

  return (
    <DNAText numberOfLines={1} status="secondary">
      <>
        {
          fieldsWithData
            .map((field: CustomFieldDefinition, idx) => (
              <Fragment key={`custom_label_${idx}`}>
                <Iffy is={idx !== 0}>
                  <DNAText c3 status="secondary" style={S.bulletText} numberOfLines={1}>
                    •
                  </DNAText>
                </Iffy>
                {configsMap?.[field.id]?.displayValues.join(', ')}
              </Fragment>
            ))
        }
      </>
    </DNAText>
  );
}
