import { StyleSheet } from 'react-native';
import { luxColors } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';

export const styles = StyleSheet.create({
  CharacterCounter: {
    color: '#8F9BB3',
    fontSize: 14,
    fontWeight: 'normal',
    justifyContent: 'flex-end',
  },
  ColumnDivider: {
    marginHorizontal: 24,
    maxWidth: 2,
  },
  // [TODO-3073] - Use DNABox props instead
  Container: {
    flex: 1,
    flexDirection: 'column',
    marginLeft: 14,
    marginRight: 14,
    padding: 10,
  },
  CounterContainer: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row-reverse',
  },
  EditFooter: {
    paddingBottom: 18,
  },
  HelpToolTipIconStyle: {
    color: colors['color-gray-200'],
    height: 16,
    paddingLeft: 5,
    width: 16,
  },
  DefaultInputComponent: {
    backgroundColor: colors['color-text-white'],
  },
  NoMarginPadding: {
    marginLeft: 0,
    marginRight: 0,
    padding: 0,
  },
  required: {
    color: '#FF0000',
    paddingLeft: 5,
    paddingRight: 5,
  },
  ScrollViewContainer: {
    paddingVertical: 40,
    backgroundColor: colors['color-gray-10'],
  },
  tabContentWrapper: {
    paddingVertical: 40,
    paddingHorizontal: 40,
  },
  tabContainer: {
    flex: 1,
    justifyContent: 'space-between',
    marginLeft: 24,
    marginRight: 24,
  },
  // [TODO-3073] - This should be stlyed from DNAThumbnail already?
  ThumbnailContainer: {
    borderColor: luxColors.formFieldBorder.primary,
    borderWidth: 1,
  },
  Title: {
    color: colors['color-gray-800'],
    fontSize: 13,
    fontWeight: 'bold',
    marginBottom: 4,
    marginTop: 4,
    textTransform: 'uppercase',
  },
  TitleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  SelectPrompt: {
    color: '#8F9BB3',
  },
  DisabledPrompt: {
    color: '#C6CDC9',
  },
});
