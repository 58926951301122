export const listIntegrationSettings = /* GraphQL */ `
  query ListIntegrationSettings(
    $filter: ModelIntegrationSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        tenantId
        syncContentEvery
        enabled
        integrationType
        notificationEmail
        errorSyncEmail
        clientConfigurationFields {
          value
          key
        }
        mapping {
          key
          fieldType
          dataType
          srcFieldname
          targetFieldName
          valueMappings {
            srcValue
            targetValue
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const listIntegrationLogs = /* GraphQL */ `
  query ListIntegrationLogs(
    $filter: ModelIntegrationLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        integrationId
        log {
          level
          message
          srcDocId
          srcDocVersion
          timestamp
        }
        id
        status
        tenantId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const listTenantsIntegrationEngine = /* GraphQL */ `
  query ListTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        licensedUsers
        fields {
          dataType
          defaultSearchFilter
          description
          fieldName
          isEmailTemplateFilter
          required
          userFilter
          values
        }
        config {
          requiredSlidesHiddenMessage
          meetingFields {
            id
            fieldName
            status
            description
            required
            maxLength
            type
            controlType
            fieldValueDefinitions {
              id
              value
              disabled
              createdAt
            }
            createdAt
          }
          customFields {
            id
            fieldName
            fieldLabel
            reportingName
            status
            description
            required
            maxLength
            fieldType
            controlType
            order
            settings {
              isBadge
            }
            usage
            documentSettings {
              fieldPosition
            }
            fieldValueDefinitions {
              id
              value
              label
              disabled
              isDefault
              documentSettings {
                presentationWatermarkText
                permission {
                  present
                  modify
                  share
                  download
                }
              }
              badgeColor
              badgeLabel
              createdAt
            }
            createdAt
          }
          mslListConfig {
            sort {
              field
              isAsc
            }
            fields {
              field
              width
            }
          }
          publisherListConfig {
            sort {
              field
              isAsc
            }
            fields {
              field
              width
            }
          }
        }
        status
        folderUpdateGracePeriodDays
        medInfoURL
        createdAt
        createdBy
        updatedAt
        statusChangedAt
        statusChangedBy
        ssoDomains
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listDocumentVersionsEngine = /* GraphQL */ `
  query ListDocumentVersions(
    $filter: ModelDocumentVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        associatedFiles {
          id
          isDistributable
          isDefault
          type
          attachmentId
          status
          createdAt
          createdBy
        }
        documentId
        versionNumber
        srcFilename
        conversionStatus
        status
        srcHash
        srcSize
        type
        releaseNotes
        changeType
        title
        shortDescription
        longDescription
        srcFile {
          bucket
          key
          region
          url
       }
        customValues {
          fieldId
          values
        }
        pageSettings {
          pageId
          number
          isRequired
          linkedSlides
        }
        pageGroups {
          id
          pageIds
        }
        owner
        expiresAt
        hasCopyright
        purpose
        canHideSlides
        distributable
        downloadable
        isInternalGenerated
        notificationScope
        selectedThumbnail
        publishedAt
        uploadedAt
        uploadedBy
        convertedArchiveKey
        convertedArchiveSize
        convertedFolderKey
        converterVersion
        createdAt
        createdBy
        updatedAt
        updatedBy
        integrationType
        integration {
          externalVersionId
          timestamp
          version
          srcFileHash
          srcDocumentHash
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listDocumentsEngine = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        status
        owner
        expiresAt
        integrationType
        type
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
        integration {
          externalId
          firstSync
          lastSync
        }
        integrationType
      }
      nextToken
      startedAt
    }
  }
`;
export const getIntegrationDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      tenantId
      status
      owner
      expiresAt
      integration {
        externalId
        version
        timestamp
      }
      integrationType
      type
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDocumentsIntegration = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        status
        owner
        expiresAt
        type
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listDocumentVersions = /* GraphQL */ `
  query ListDocumentVersions(
    $filter: ModelDocumentVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        documentId
        versionNumber
        srcFilename
        conversionStatus
        status
        srcHash
        srcSize
        type
        releaseNotes
        changeType
        title
        shortDescription
        longDescription
        srcFile {
          bucket
          key
          region
          url
       }
        customValues {
          fieldId
          values
        }
        owner
        expiresAt
        hasCopyright
        purpose
        canHideSlides
        distributable
        downloadable
        isInternalGenerated
        notificationScope
        selectedThumbnail
        publishedAt
        uploadedAt
        uploadedBy
        convertedArchiveKey
        convertedArchiveSize
        convertedFolderKey
        converterVersion
        createdAt
        createdBy
        updatedAt
        updatedBy
        integrationType
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
