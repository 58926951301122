import { openDB, IDBPDatabase } from 'idb'
import { DocumentVersionORM } from 'src/types/types'
import {
  CachePayload,
  CacheDBSchema,
  CacheManifestEntry,
  CONTENT_CACHE_TYPE,
  DocumentVersionsCacheEntriesMap,
} from '@alucio/core'
import * as logger from 'src/utils/logger'
import { v4 as uuid } from 'uuid';
import { createPartialResponse } from 'workbox-range-requests';

// [TODO-PWA] - Make the !this.db check automatic, maybe through a proxy
// [TODO-PWA] - Consider making this a singleton so we don't have to open so many IDB instances
class CacheDB {
  public db?: IDBPDatabase<CacheDBSchema>

  public async open(): Promise<void> {
    this.db = await openDB<CacheDBSchema>(
      'CacheDB',
      undefined,
      {
        terminated: () => {
          console.error('CacheDB unexpectedly closed')
          this.db = undefined
        },
      },
    )
  }

  public async purge(): Promise<void> {
    if (!this.db) throw new Error('CacheDB is not opened')

    await Promise.all([
      this.db.clear('CACHE_MANIFEST'),
      this.db.clear('CACHE_PAYLOAD'),
    ])

    logger.PWALogger.info('CacheDB - Purged tables')
  }

  public async cacheMatch(request: Request): Promise<Response | undefined> {
    if (!this.db) throw new Error('CacheDB is not opened')

    const tx = this.db.transaction('CACHE_PAYLOAD', 'readonly')
    const store = tx.store

    // Remove the domain name and any URL parameters / hash
    const cacheKey = (new URL(request.url)).pathname
    const cachePayload = await store.get(cacheKey)

    await tx.done

    if (cachePayload) {
      logger.PWALogger.debug('Found cache entry', cachePayload)
      // [TODO-PWA]
      //  - Need to include other header response data?
      //  - Consider storing said header data in the payload table as well
      if (
        request.headers.get('Alucio-Action') === 'download' &&
        cachePayload.mimeType === 'video/mp4'
      ) {
        return new Response(cachePayload.data)
      }
      else if (cachePayload.mimeType === 'video/mp4') {
        logger.PWALogger.debug('Video Request')
        return await createPartialResponse(request, new Response(cachePayload.data))
      } else {
        return new Response(cachePayload.data)
      }
    }
  }

  public async getCacheManifest(filters?: CONTENT_CACHE_TYPE[]): Promise<CacheManifestEntry[]> {
    if (!this.db) throw new Error('CacheDB is not opened')

    const tx = this.db.transaction('CACHE_MANIFEST', 'readonly')
    const store = tx.store
    const manifest = await store.getAll()

    // `OR` filtering
    const filtered = filters
      ? manifest.filter(entry => filters.some(filter => entry.cacheType === filter))
      : manifest

    await tx.done

    return filtered
  }

  public async syncCacheManifest(docVerORMs: DocumentVersionORM[]): Promise<void> {
    if (!this.db) throw new Error('CacheDB is not opened')

    const cacheManifest = await this.getCacheManifest()
    const docVerManifestMap = cacheManifest
      .reduce<DocumentVersionsCacheEntriesMap>(
        (acc, entry) => {
          const docVerId = entry.documentVersionId

          if (!acc[entry.documentVersionId]) { acc[docVerId] = {} }

          if (entry.cacheType === 'CONTENT') { acc[docVerId].content = entry }
          else if (entry.cacheType === 'THUMBNAIL') { acc[docVerId].thumbnail = entry }
          else if (entry.cacheType === 'PAGES_JSON') { acc[docVerId].pagesJson = entry }

          return acc
        },
        {},
      )

    const tx = this.db.transaction('CACHE_MANIFEST', 'readwrite')
    const store = tx.store

    // Remove entries that don't exist in the input array
    const existingDocVerIds = new Set(docVerORMs.map(docVerORM => docVerORM.model.id))
    const entriesToRemove = cacheManifest.filter(entry => !existingDocVerIds.has(entry.documentVersionId))
    await Promise.all(entriesToRemove.map(entry => store.delete(entry.id)))

    for (const docVerORM of docVerORMs) {
      // [NOTE] - This triggers a resync of content if files were re-processed (processor version gets bumped)
      const contentKeyWasUpdated =
        docVerORM.model.convertedArchiveKey !== docVerManifestMap[docVerORM.model.id]?.content?.fileKey ||
        docVerORM.model.convertedFolderKey !== docVerManifestMap[docVerORM.model.id]?.content?.folderKey

      if (contentKeyWasUpdated) {
        logger.PWALogger.warn('Updating entry due to key changed')
      }

      // Insert or update Content Entry
      if (!docVerManifestMap[docVerORM.model.id]?.content || contentKeyWasUpdated) {
        const newContentEntry: CacheManifestEntry = {
          id: uuid(),
          documentId: docVerORM.model.documentId,
          documentVersionId: docVerORM.model.id,
          versionNumber: docVerORM.model.versionNumber,
          status: 'REQUESTED',
          cacheType: 'CONTENT',
          type: docVerORM.model.type,
          folderKey: docVerORM.model.convertedFolderKey!,
          fileKey: docVerORM.model.convertedArchiveKey!,
          fileSize: docVerORM.model.convertedArchiveSize!,
          requestedAt: new Date(),
        }

        await store.put(newContentEntry)
      }

      // Insert or update PagesJson Entry
      if (!docVerManifestMap[docVerORM.model.id]?.pagesJson || contentKeyWasUpdated) {
        // insert pages.json entry into the plan for syncing offline
        const model = docVerORM.model
        const jsonKey  = `${model.tenantId}/${model.documentId}/${model.id}/v2/json/Pages.json`
        const pagesJsonEntry: CacheManifestEntry = {
          id: uuid(),
          documentId: docVerORM.model.documentId,
          documentVersionId: docVerORM.model.id,
          versionNumber: docVerORM.model.versionNumber,
          status: 'REQUESTED',
          cacheType: 'PAGES_JSON',
          type: docVerORM.model.type,
          folderKey: docVerORM.model.convertedFolderKey!,
          fileKey: jsonKey,
          // We don't have the size. Using 2KB as an estimate.
          fileSize: 2000,
          requestedAt: new Date(),
        }

        await store.put(pagesJsonEntry);
      }

      // Insert or update Thumbnail Entry
      if (!docVerManifestMap[docVerORM.model.id]?.thumbnail || contentKeyWasUpdated) {
        const newThumbnailEntry: CacheManifestEntry = {
          id: uuid(),
          documentId: docVerORM.model.documentId,
          documentVersionId: docVerORM.model.id,
          versionNumber: docVerORM.model.versionNumber,
          status: 'REQUESTED',
          cacheType: 'THUMBNAIL',
          type: docVerORM.model.type,
          folderKey: docVerORM.model.convertedFolderKey!,
          // [NOTE] - If the thumbnail key is undefined, let the sync machine fail
          fileKey: docVerORM.meta.assets.thumbnailKey!,
          // [TODO-PWA] - we don't have the size of thumbnails use 50KB as a estimate
          fileSize: 50000,
          requestedAt: new Date(),
        }

        await store.put(newThumbnailEntry)
      }
    }

    await tx.done
  }

  public async putCachePayload(payload: CachePayload): Promise<void> {
    if (!this.db) throw new Error('CacheDB is not opened')

    const tx = this.db.transaction('CACHE_PAYLOAD', 'readwrite')
    const store = tx.store

    await store.put(payload)
    await tx.done
  }

  public async updateCacheManifestEntry(
    id: string,
    updates: Partial<CacheManifestEntry>,
  ): Promise<void> {
    if (!this.db) throw new Error('CacheDB is not opened')

    const tx = this.db.transaction('CACHE_MANIFEST', 'readwrite')
    const store = tx.store

    const targetRecord = await store.get(id)

    if (!targetRecord) { throw new Error('Could not find record to update') }

    await store.put({
      ...targetRecord,
      ...updates,
    })

    await tx.done
  }

  public async removeCachePayload(docVerId: string): Promise<void> {
    if (!this.db) throw new Error('CacheDB is not opened')

    const tx = this.db.transaction('CACHE_PAYLOAD', 'readwrite')
    const store = tx.store

    let cursor = await store.openCursor()
    let foundPayload = false

    while (cursor) {
      if (cursor?.value.path.includes(docVerId)) {
        foundPayload = true
        await cursor.delete()
      }

      cursor = await cursor.continue()
    }

    if (!foundPayload) {
      throw new Error('Could not find payload entries to delete')
    }

    await tx.done
  }

  public async removeCacheManifestEntry(docVerId: string): Promise<void> {
    if (!this.db) throw new Error('CacheDB is not opened')

    const tx = this.db.transaction('CACHE_MANIFEST', 'readwrite')
    const store = tx.store

    let cursor = await store.openCursor()
    let foundPayload = false

    while (cursor) {
      if (cursor?.value.documentVersionId === docVerId) {
        foundPayload = true
        await cursor.delete()
      }

      cursor = await cursor.continue()
    }

    if (!foundPayload) {
      throw new Error('Could not find manifest entry to delete')
    }

    await tx.done
  }

  public async close(): Promise<void> {
    await this.db?.close()
    this.db = undefined
  }
}

export default CacheDB
