import { FolderPermission } from '@alucio/aws-beacon-amplify/src/models';
import { createSlice } from '@reduxjs/toolkit';
import { commonReducers, initialState, SliceStatus } from './common';

const sliceName = 'folderPermission';
const { reducers, extraReducers } = commonReducers<FolderPermission>(sliceName)

const folderPermissionSlice = createSlice({
  name: sliceName,
  initialState: initialState<FolderPermission>(),
  reducers: {
    ...reducers,
    replace: (state, { payload }) => {
      state.status = SliceStatus.OK;
      state.hydrated = true;
      state.records = payload;
    },
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
  },
  extraReducers,
})

export default folderPermissionSlice;

export const folderPermissionActions = {
  ...folderPermissionSlice.actions,
}
