import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import format from 'date-fns/format'
import { luxColors, DNABox, DNAButton, Iffy, DNAChip } from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';

import { DocumentAccessLevel, PurposeType } from '@alucio/aws-beacon-amplify/src/models'
import ExtensionBadge from '../../../screens/Documents/ExtensionBadge';
import PurposeBadge from 'src/components/PurposeBadge/PurposeBadge';
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip';

import { DocumentORM, DocumentVersionORM, FolderItemORM } from 'src/types/types';

import { useAppSettings } from 'src/state/context/AppSettings';
import { useContentViewerModalState } from '../state/ContentViewerModalStateProvider';
import { batch, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/state/redux';
// eslint-disable-next-line max-len
import { useAllDocumentVersionMap, useAllDocumentsInstance, useAllPersonalDocumentsInstance } from 'src/state/redux/selector/document';
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
import head from 'lodash/head';

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: luxColors.backgroundButton.primary,
    borderWidth: 0,
    marginBottom: 5,
    marginLeft: 10,
    marginTop: 10,
    padding: 10,
  },
  headerBarIcons: {
    flexDirection: 'row-reverse',
    marginRight: 0,
    marginTop: 5,
  },
  headerBarSubTitle: {
    flexDirection: 'row',
    marginTop: 5,
  },
  headerBarSubTitleText: {
    color: luxColors.subtitle.primary,
  },
  headerBarTitle: {
    marginLeft: 25,
    marginTop: 10,
  },
  icon: {
    height: 24,
    width: 24,
  },
  tablet: {
    flexDirection: 'row-reverse',
  },
  title: {
    fontSize: 16,
    fontWeight: '500',
  },
  tooltipTextColor: {
    color: 'white',
  },
});

interface MenuItem {
  iconName: string,
  tooltip: string,
  testID: string,
}
interface HeaderProps {
  onOptionSelected: (option: string) => void;
  isPublisherMode: boolean;
  isContentUnavailable?: boolean;
  documentORM: DocumentORM;
}
interface IconsProps {
  iconsArray: MenuItem[],
  onOptionSelected: (option: string) => void
}

const useHeaderSharedResources = () => {
  const {
    customDeckORM,
    folderItemORM,
    activeDocORM,
    activeDocVersionORM,
    isModifiedDoc,
    setActiveDocVersionORMAndSlide,
    setActiveFolderItemORMAndSlide,
  } = useContentViewerModalState()
  const { isCurrentVersionDoc, isLastVersionDoc } = isVersions(activeDocVersionORM, folderItemORM);
  const docVersionMap = useAllDocumentVersionMap()

  // Because the header needs to show the latest version of the document object (to reflect bookmarking)
  // we always fetch from redux
  // TODO: Per Kenny's discussion, this should be moved to the content preview modal context and used to populate the activeDoc
  const documentORM = useAllDocumentsInstance({ filter: { model: { id: activeDocORM.model.id } } })
  const personalDocument = useAllPersonalDocumentsInstance({ filter: { model: { id: activeDocORM.model.id } } });
  const [currentDocumentORM] = documentORM.length ? documentORM : personalDocument;

  const title = folderItemORM.meta.title || activeDocVersionORM.model.title

  const dispatch = useDispatch()

  const modalState = useSelector((state: RootState) => state.contentPreviewModal)

  /**
   * This file name indicates the top parent file that was initially opened by the user. Regardless of how many levels deep the
   * user navigates to, this file name should remain the same. User can also click on this text link to return to this file.
   */
  const prevElement = head(modalState.documentHistoryVersionNavigation);

  const documentVersion = prevElement
    ? docVersionMap[prevElement]
    : undefined

  // Because the header needs to show the latest version of the document object (to reflect bookmarking)
  // we always fetch from redux

  const goBack = () => {
    // This is required to return to the correct folder document version
    if (modalState.folderItemORM) {
      setActiveFolderItemORMAndSlide(modalState.folderItemORM, 1)
    }
    else {
      setActiveDocVersionORMAndSlide(documentVersion!, 1)
    }
    prevElement && batch((): void => {
      dispatch(
        contentPreviewModalActions.changeDocument({
          documentVersionId: prevElement,
          addToHistory: false,
        },
        ))

      dispatch(
        contentPreviewModalActions.removeFromHistory(true),
      )
    })
  }

  return {
    isCustomDeck: !!customDeckORM,
    title,
    activeDoc: currentDocumentORM,
    activeDocVersionORM,
    isModifiedDoc,
    folderItemORM,
    isCurrentVersionDoc,
    isLastVersionDoc,
    prevElement,
    goBack,
  }
}

type IconOptionProps = {
  isCustomDeck?: boolean,
  isCurrentVersionDoc: boolean,
  isContentUnavailable?: boolean,
  isLastVersionDoc: boolean,
  isModifiedDoc: boolean,
  isOnline?: boolean,
}

const isVersions = (activeDocVersionORM: DocumentVersionORM, folderItemORM: FolderItemORM) => {
  const docVersion = activeDocVersionORM.model.versionNumber;
  // @ts-expect-error
  const folderVersionNumber = folderItemORM?.relations.itemORM.model.versionNumber;
  return {
    isCurrentVersionDoc: folderItemORM && folderVersionNumber === docVersion,
    isLastVersionDoc: activeDocVersionORM.meta.version.isLatestPublished,
  };
}

function getIcons(documentORM: DocumentORM, isPublisherMode: boolean, options: IconOptionProps) {
  const { isCurrentVersionDoc, isLastVersionDoc, isModifiedDoc, isOnline, isCustomDeck } = options;
  const disableCustomFile = isCurrentVersionDoc && !isLastVersionDoc;

  const canDownload = (isPublisherMode || documentORM.meta.permissions.MSLDownload) && !isModifiedDoc && !isCustomDeck;
  const canAddToFolder = (documentORM.meta.permissions.addToFolder) && !disableCustomFile && !isCustomDeck;
  const canPresent = options.isCustomDeck ||
    (!options.isContentUnavailable && (isPublisherMode || documentORM.meta.permissions.MSLPresent));

  const canBookmark =
    !options.isContentUnavailable &&
    documentORM.meta.permissions.bookmark &&
    !(disableCustomFile || isModifiedDoc) && !isCustomDeck && !isPublisherMode;

  const downloadIcon = {
    iconName: 'download',
    tooltip: 'Download this file',
    testID: 'content-preview-modal-download-icon',
  }
  const addToFolderIcon = {
    iconName: 'folder-plus-outline',
    tooltip: 'Add file to folder',
    testID: 'content-preview-modal-add-to-folder-icon',
  }
  const presentIcon = {
    iconName: 'presentation-play',
    tooltip: 'Present in full window',
    testID: 'content-preview-modal-present-icon',
  }
  const bookmarkIcon = {
    iconName: `bookmark${documentORM.meta.bookmark.isBookmarked
      ? ''
      : '-outline'}`,
    tooltip: documentORM.meta.bookmark.isBookmarked
      ? 'Remove from bookmarks'
      : 'Bookmark this file',
    testID: 'content-preview-modal-bookmark-icon',
  }
  /** Conditionally generate the iconsArray */
  const iconsArray: MenuItem[] = [
    ...canDownload && isOnline ? [downloadIcon] : [],
    ...canAddToFolder ? [addToFolderIcon] : [],
    ...canBookmark ? [bookmarkIcon] : [],
    ...canPresent ? [presentIcon] : [],
  ]

  return iconsArray;
}

function DesktopIcons(props: IconsProps) {
  const { iconsArray, onOptionSelected } = props;
  return (
    <DNABox style={styles.headerBarIcons} >
      {iconsArray.map(
        ({ iconName, tooltip, testID }, index: number) => (
          <DNAPopover key={iconName}>
            <DNAPopover.Anchor>
              <DNAButton
                status="tertiary"
                key={`head_bar_icon_${index}`}
                style={styles.button}
                iconLeft={iconName}
                // @ts-ignore
                iconStyle={styles.icon}
                onPress={() => onOptionSelected(iconName)}
                testID={testID}
              />
            </DNAPopover.Anchor>
            <DNAPopover.Content offset={2}>
              <Text numberOfLines={1} style={styles.tooltipTextColor}>{tooltip}</Text>
            </DNAPopover.Content>
          </DNAPopover>
        ),
      )}
    </DNABox>
  )
}

function TabletIcons(props: IconsProps) {
  const { iconsArray, onOptionSelected } = props
  const reverseIconsArray = [...iconsArray.reverse()]

  return (
    <DNABox spacing="md">
      {
        reverseIconsArray.map(({ iconName, tooltip }) => (
          <DNAPopover key={iconName}>
            <DNAPopover.Anchor>
              <DNAButton
                status="tertiary"
                onPress={() => onOptionSelected(iconName)}
                iconLeft={iconName}
              />
            </DNAPopover.Anchor>
            <DNAPopover.Content offset={2}>
              <Text numberOfLines={1} style={styles.tooltipTextColor}>{tooltip}</Text>
            </DNAPopover.Content>
          </DNAPopover>
        ))
      }
    </DNABox>
  )
}

function HeaderDesktop(props: HeaderProps) {
  const { onOptionSelected, isPublisherMode, isContentUnavailable } = props
  const {
    activeDocVersionORM,
    isCustomDeck,
    title,
    activeDoc,
    isModifiedDoc,
    isCurrentVersionDoc,
    isLastVersionDoc,
    prevElement,
    goBack,
  } = useHeaderSharedResources()
  const { isOnline } = useAppSettings()

  // [TODO] - With the new refactor this will will be deprecated, although it is weird that sometimes activeDoc comes undefined
  const activeDocument = activeDoc || activeDocVersionORM.relations.documentORM
  const iconsArray: MenuItem[] = getIcons(
    activeDocument,
    isPublisherMode,
    { isCurrentVersionDoc, isLastVersionDoc, isModifiedDoc, isOnline, isContentUnavailable, isCustomDeck },
  );

  const isPersonalDocument = activeDocument.model.accessLevel === DocumentAccessLevel.USER;
  const displayIcons = isCustomDeck || ((isCurrentVersionDoc || isLastVersionDoc) && !isPersonalDocument);

  return (
    <>
      <DNABox fill>
        <DNABox fill>
          <Iffy is={prevElement}>
            <DNABox alignX="center" alignY="center">
              <DNAButton
                status="tertiary"
                appearance="ghost"
                style={[styles.button, { marginRight: 16, height: 40, width: 40 }]}
                iconLeft="chevron-left"
                iconStyle={styles.icon}
                onPress={goBack}
              />
            </DNABox>
          </Iffy>
          <DNABox appearance="col" alignX="start" style={styles.headerBarTitle} fill alignY="center">
            <Text
              testID="preview-modal-title"
              numberOfLines={2}
              style={styles.title}
            >
              {title}
            </Text>
            <Iffy is={!isCustomDeck && !isPersonalDocument}>
              <DNABox appearance="col" style={styles.headerBarSubTitle}>
                <DNABox appearance="row" spacing="sm" alignY="center">
                  <PurposeBadge
                    purpose={activeDocVersionORM.model.purpose as PurposeType}
                    description={activeDocVersionORM.model.purpose}
                  />
                  <ExtensionBadge extension={activeDocVersionORM.model.type} />
                  <DNADocumentChip item={activeDocVersionORM} variant={'status'} />
                  <Iffy is={isModifiedDoc}>
                    <DNAChip
                      appearance="tag"
                    >MODIFIED
                    </DNAChip>
                  </Iffy>
                  <Text testID="last-update" style={styles.headerBarSubTitleText}>
                    Last Updated {format(
                    new Date(activeDocVersionORM.model.publishedAt || activeDocVersionORM.model.createdAt),
                    'MM/dd/yyyy',
                  )}
                  </Text>
                </DNABox>
              </DNABox>
            </Iffy>
            <Iffy is={isPersonalDocument}>
              <ExtensionBadge extension={activeDocVersionORM.model.type} />
            </Iffy>
          </DNABox>
        </DNABox>
        <Iffy is={displayIcons}>
          <DesktopIcons iconsArray={iconsArray} onOptionSelected={onOptionSelected} />
        </Iffy>
      </DNABox>
    </>
  );
}

function HeaderTablet(props: HeaderProps) {
  const { onOptionSelected, isPublisherMode, isContentUnavailable } = props
  const {
    isCustomDeck,
    title,
    activeDoc,
    activeDocVersionORM,
    isCurrentVersionDoc,
    isLastVersionDoc,
    isModifiedDoc,
    prevElement,
    goBack,
  } = useHeaderSharedResources()
  const { isOnline } = useAppSettings()
  // [TODO] - With the new refactor this will will be deprecated, although it is weird that sometimes activeDoc comes undefined
  const activeDocument = activeDoc || activeDocVersionORM.relations.documentORM
  const iconsArray: MenuItem[] = getIcons(
    activeDocument,
    isPublisherMode,
    { isCurrentVersionDoc, isLastVersionDoc, isModifiedDoc, isOnline, isContentUnavailable, isCustomDeck },
  )

  const isPersonalDocument = activeDocument.model.accessLevel === DocumentAccessLevel.USER;
  const displayIcons = isCustomDeck || ((isCurrentVersionDoc || isLastVersionDoc) && !isPersonalDocument);

  return (
    <DNABox fill>
      <Iffy is={prevElement}>
        <DNABox alignX="center" alignY="center">
          <DNAButton
            status="tertiary"
            appearance="ghost"
            style={[styles.button, { marginRight: 16, height: 40, width: 40 }]}
            iconLeft="chevron-left"
            iconStyle={styles.icon}
            onPress={goBack}
          />
        </DNABox>
      </Iffy>
      {/* Left Side */}
      <DNABox appearance="col" fill>
        <Text
          testID="preview-modal-title"
          numberOfLines={2}
          style={styles.title}
        >
          {title}
        </Text>
        <View style={styles.headerBarSubTitle}>
          <DNABox appearance="row" spacing="sm" alignY="center">
            <Iffy is={!isCustomDeck && !isPersonalDocument}>
              <PurposeBadge
                purpose={activeDocVersionORM.model.purpose as PurposeType}
                description={activeDocVersionORM.model.purpose}
              />
              <ExtensionBadge extension={activeDocVersionORM.model.type} />
              <DNADocumentChip item={activeDocVersionORM} variant={'status'} />
              <Iffy is={isModifiedDoc}>
                <DNAChip
                  appearance="tag"
                >MODIFIED
                </DNAChip>
              </Iffy>
              <Text style={styles.headerBarSubTitleText}>
                Last Updated {format(
                new Date(activeDocVersionORM.model.publishedAt || activeDocVersionORM.model.updatedAt), 'MM/dd/yyyy')}
              </Text>
            </Iffy>
            <Iffy is={isPersonalDocument}>
              <ExtensionBadge extension={activeDocVersionORM.model.type} />
            </Iffy>
          </DNABox>
        </View>
      </DNABox>

      {/* Right Side Icons */}
      <Iffy is={displayIcons}>
        <TabletIcons iconsArray={iconsArray} onOptionSelected={onOptionSelected} />
      </Iffy>
    </DNABox>
  );
}

const Header = (props) => {
  const { deviceMode } = useAppSettings()

  return deviceMode === 'desktop'
    ? <HeaderDesktop {...props} />
    : <HeaderTablet {...props} />
}

export default Header
