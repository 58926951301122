import { useMemo } from 'react';
import { CustomFieldDefinition, CustomFieldUsage } from '@alucio/aws-beacon-amplify/src/models';
import { StaticFields } from 'src/components/CustomFields/ComposableForm';

import addMeetingConfig, { getDefaultValues, getSeparatedAttendees } from './AddMeetingConfig';
import onGoingMeetingConfig, { initialDefaultValues } from './MeetingDetailsConfig';
import { CustomFieldValuesMap, MeetingORM } from 'src/types/orms';
import { useMeeting } from 'src/state/redux/selector/meeting';
import { useCRMFormConfig } from 'src/state/redux/selector/crm';
import { useTenantCustomFields } from 'src/state/redux/selector/tenant';
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import { getMappedCustomValues } from 'src/state/redux/selector/common';
import merge from 'lodash/merge';
import { CRMAvailabilityStatus } from 'src/state/machines/CRM/crmMachineTypes';
import { useIsMeetingLocked } from 'src/components/Meeting/AddMeetingProvider';
import cloneDeep from 'lodash/cloneDeep';

export interface MeetingFormConfig {
  customFields: CustomFieldDefinition[],
  meetingORM?: MeetingORM,
  staticFields: StaticFields,
  staticValues: CustomFieldValuesMap,
  values: CustomFieldValuesMap,
  isLoading: boolean,
  isReadOnly: boolean,
}

const useMeetingFormConfig = (meetingId?: string, isOngoingMeeting?: boolean):
  MeetingFormConfig => {
  const meetingORM = useMeeting(meetingId ?? 'placeholder');
  const { canUseMeetingCRMFields, availabilityStatus } = useCRMStatus();
  const isPlannedCRMMeetingEnabled = availabilityStatus === CRMAvailabilityStatus.ENABLED
  const defaultValues = getDefaultValues(meetingORM, isOngoingMeeting, isPlannedCRMMeetingEnabled);
  const isMeetingLocked = useIsMeetingLocked(meetingORM)

  const { crmCustomFields, crmDefaultValues, isLoading } = useCRMFormConfig();

  const meetingFormConfig = isOngoingMeeting ? onGoingMeetingConfig(meetingORM) : addMeetingConfig
  const staticFields = { ...meetingFormConfig, customValues: defaultValues };
  const customFields = useTenantCustomFields({ usages: { internalUsages: [CustomFieldUsage.MEETING] } });

  const usage = { internalUsages: [CustomFieldUsage.MEETING] }

  const separatedAttendees = useMemo(() => getSeparatedAttendees(meetingORM), [meetingORM?.model.attendees]);
  const shouldIncludeCrmFields = canUseMeetingCRMFields && !isOngoingMeeting;

  const calculatedCustomFields = shouldIncludeCrmFields
    ? [...customFields, ...crmCustomFields]
    // id CRM is enabled but not connected, we should not show the custom fields
    : customFields

  const values = useMemo(() => {
    const values = isOngoingMeeting
      ? initialDefaultValues(meetingORM, customFields)
      : meetingORM?.meta.customValues.configsMap || {};
    const crmCustomValues = getMappedCustomValues(usage, meetingORM?.model.crmRecord?.crmCustomValues, crmCustomFields);

    // lodash merge mutate the first argument, so we need to create a new object
    return merge(cloneDeep(crmDefaultValues), crmCustomValues, values);
  }, [meetingORM, crmCustomFields, canUseMeetingCRMFields]);

  return {
    isLoading: !!isLoading,
    customFields: calculatedCustomFields,
    meetingORM,
    staticFields,
    staticValues: { ...defaultValues, ...separatedAttendees },
    values,
    isReadOnly: isMeetingLocked,
  }
}

export default useMeetingFormConfig;
