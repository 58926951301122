import React, { useCallback, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import {
  DNABox,
  DNAButton,
  DNACard,
  DNAChip,
  DNACollapsibleCard,
  DNAText,
  Iffy,
  Stack,
} from '@alucio/lux-ui/src';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useAppSettings } from 'src/state/context/AppSettings';
import { DocumentVersionORM } from 'src/types/types'
import { useDocumentVersionORM } from 'src/state/redux/selector/document';
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList';
import { styles } from 'src/screens/Hubs/EditHub/Widgets/SharedFiles';
import mergeStyles from '@alucio/lux-ui/src/components/util/mergeStyles';
import {
  AssociatedFileDetails,
  RowProps,
  useSharedFilesState,
} from 'src/screens/Hubs/EditHub/Widgets/SharedFilesComponents/SharedFilesProvider'
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import { DocumentStatus, FileType, HubSectionItemStatus, HubSharedFileContentType, Notation } from '@alucio/aws-beacon-amplify/src/models';
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';

export const S = StyleSheet.create({
  notationGroupContainer: {
    paddingHorizontal: 24,
    paddingBottom: 24,
  },
  notationGroupCardBody: {
    padding: 24,
  },
  notationRow: {
    paddingVertical: 12,
  },
  notationRowBorder: {
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
  },
  tooltipText: {
    color: colors['color-text-white'],
  },
  unavailableContent: {
    opacity: 0.4,
  },
})
interface DocumentRowWrapperProps extends RowProps {
  associatedContent: AssociatedFileDetails
}

interface DocumentRowProps extends RowProps {
  documentVersionORM: DocumentVersionORM
  associatedContent?: AssociatedFileDetails
  notations?: Notation[]
}

interface DocumentBadgesProps {
  documentVersionORM: DocumentVersionORM
  isRequired: boolean
  unavailableContent: boolean
}

interface NotationGroupProps {
  documentVersionORM: DocumentVersionORM
  notations?: Notation[]
}

const NotationGroup: React.FC<NotationGroupProps> = ({ documentVersionORM, notations }) => {
  const { isOnline } = useAppSettings()
  const hubSharedFilesState = useSharedFilesState()
  const { handleDeleteCallout, handleOpenContentPreview } = hubSharedFilesState
  const activeNotations = notations?.filter(notation => notation.status === HubSectionItemStatus.ACTIVE) ?? []
  const visibleNotationMappedByIndex: Record<string, Notation[]> = useMemo(() => {
    const result = {}
    notations?.forEach(notation => {
      if (notation.status === HubSectionItemStatus.ACTIVE) {
        const pageNum = notation.pageId.split('_').pop() ?? ''
        result[pageNum]
          ? result[pageNum].push(notation)
          : result[pageNum] = [notation]
      }
    })
    // sort each array (key) base on creation
    Object.keys(result).forEach(pageNum => {
      result[pageNum].sort((a, b) => {
        return a.createdAt - b.createdAt
      })
    })
    return result
  }, [notations])
  const visibleNotationPageNumArray = Object.keys(visibleNotationMappedByIndex).sort((a, b) => Number(a) - Number(b))
  const unavailableContent = !isOnline && !documentVersionORM.meta.assets.isContentCached

  const openContentPreview = useCallback((pageNumber: number, notationId: string) => {
    handleOpenContentPreview(
      hubSharedFilesState,
      HubSharedFileContentType.DOCUMENT_VERSION,
      documentVersionORM.model.id,
      pageNumber,
      notationId,
    )
  }, [hubSharedFilesState])

  if (!visibleNotationPageNumArray.length) return null
  const title = `Callouts (${activeNotations.length})`
  return (
    <DNABox
      appearance="col"
      style={S.notationGroupContainer}
    >
      <DNACollapsibleCard
        headerTitle={title}
        isCollapsed
        customWrapperStyle={S.notationGroupCardBody}
      >
        <DNABox appearance="col">
          {visibleNotationPageNumArray.map((pageNum, index) => {
            const isLastGroup = index === visibleNotationPageNumArray.length - 1
            return visibleNotationMappedByIndex[pageNum].map((notation, index) => {
              const isLastItem = isLastGroup && index === visibleNotationMappedByIndex[pageNum].length - 1
              const pageNumber = Number(pageNum)
  
              const page = documentVersionORM.meta.allPages[pageNumber - 1]
              const customThumbnailKey = detectArchivedFileKeyPath(documentVersionORM.model, page)
              const onPress = () => {
                if (unavailableContent) return
                openContentPreview(pageNumber, notation.id)
              }
              return (
                <DNACard
                  key={notation.id}
                  onPress={onPress}
                  interactive="pressableAlt"
                  appearance="flat"
                  style={mergeStyles(
                    undefined,
                    S.notationRow,
                    [S.notationRowBorder, !isLastItem],
                    [S.unavailableContent, unavailableContent],
                  )}
                >
                  <DNABox fill childFill={1} alignY="center" spacing="md">
                    {/* Thumbnail */}
                    <Stack anchor="bottomLeft">
                      {/* Thumbnail Layer */}
                      <Stack.Layer>
                        <DNAThumbnail
                          width={104}
                          height={58}
                          s3URL={customThumbnailKey}
                          useLoadingIndicator
                          unavailableContent={unavailableContent}
                        />
                      </Stack.Layer>
                      {/* Page Number Layer */}
                      <Stack.Layer anchor="bottomLeft">
                        <DNAChip appearance="tag">
                          { `#${pageNumber}` }
                        </DNAChip>
                      </Stack.Layer>
                    </Stack>
                    {/* Notation Description & tags */}
                    <DNABox fill appearance="col" spacing="sm">
                      <DNAText
                        status="primary"
                        numberOfLines={2}
                        style={{ textDecorationLine: 'underline' }}
                        >
                          {notation.description || `Callout ${index + 1}`}
                        </DNAText>
                      <Iffy is={unavailableContent}>
                        <DNABox>
                          <DNAChip
                            appearance="tag"
                            testID={`content-not-available-${documentVersionORM.model.title}`}
                          >
                            CONTENT NOT AVAILABLE
                          </DNAChip>
                        </DNABox>
                      </Iffy>
                    </DNABox>
                    {/* Buttons */}
                    <DNABox>
                      <DNAButton
                        iconLeft="trash-can-outline"
                        appearance="ghostLink"
                        status={unavailableContent ? 'tertiary' : 'gray'}
                        size="md"
                        padding="sm"
                        onPress={() => handleDeleteCallout(documentVersionORM.model.id, notation.id)}
                      />
                    </DNABox>
                  </DNABox>
                </DNACard>
              )
            })
          })}
        </DNABox>
      </DNACollapsibleCard>
    </DNABox>
  )
}

const DocumentBadges: React.FC<DocumentBadgesProps> = ({ documentVersionORM, isRequired, unavailableContent }) => {
  const isArchived = documentVersionORM.relations.documentORM.model.status === DocumentStatus.ARCHIVED
  const { isLatestPublished } = documentVersionORM.meta.version
  return (
    <DNABox spacing="sm">
      <CustomFieldBadgeList documentVersionORM={documentVersionORM} />
      <Iffy is={isRequired}>
        <DNAChip>REQUIRED</DNAChip>
      </Iffy>
      <Iffy is={isArchived}>
        <DNAChip>ARCHIVED</DNAChip>
      </Iffy>
      <Iffy is={!isLatestPublished}>
        <DNAChip>NEW VERSION AVAILABLE</DNAChip>
      </Iffy>
      <Iffy is={unavailableContent}>
        <DNAChip
          appearance="tag"
          testID={`content-not-available-${documentVersionORM.model.title}`}
        >
          CONTENT NOT AVAILABLE
        </DNAChip>
      </Iffy>
    </DNABox>
  )
}

export const DocumentRow: React.FC<DocumentRowProps> = ({ documentVersionORM, associatedContent, notations, isLastItem, handleDeleteItem }) => {
  const { isOnline } = useAppSettings()
  const { hubORM, showFullSizeBtn } = useHubsState()
  const hubSharedFilesState = useSharedFilesState()
  const { handleOpenContentPreview } = hubSharedFilesState
  const isRequired = !!associatedContent?.isRequired
  const calloutsAvailableDocTypes = ['PDF', 'PPTX']
  const isValidDocTypeForCallouts = calloutsAvailableDocTypes.includes(documentVersionORM.model.type)
  const isNotatable = isValidDocTypeForCallouts && documentVersionORM.meta.permissions.MSLExternalNotatable
  const isWebDoc = documentVersionORM.model.type === FileType.WEB
  const unavailableContent =
    (!isOnline && !documentVersionORM.meta.assets.isContentCached) ||
    (isWebDoc && !isOnline)

  const openContentPreview = useCallback((pageNumber?: number) => {
    if (unavailableContent) return
    handleOpenContentPreview(hubSharedFilesState, HubSharedFileContentType.DOCUMENT_VERSION, documentVersionORM.model.id, pageNumber)
  }, [hubSharedFilesState, unavailableContent])

  if (!hubORM) return null
  return (
    <DNABox
      fill={!associatedContent}
      appearance="col"
      style={[{ minHeight: 113 }, isLastItem ? undefined : styles.bottomBorder]}
    >
      <DNACard
        onPress={() => openContentPreview()}
        interactive="pressableAlt"
        appearance="flat"
        style={mergeStyles(
          undefined,
          { minHeight: 173 },
          [{ minHeight: 113 }, showFullSizeBtn],
          [S.unavailableContent, unavailableContent],
        )}
      >
        <DNABox alignY="center" style={styles.row}>
          {/* Thumbnail */}
          <DNABox style={styles.documentThumbnailBorder}>
            <DNADocumentThumbnail
              width={120}
              height={65}
              documentVersionORM={documentVersionORM}
              unavailableContent={unavailableContent}
            />
          </DNABox>
          {/* Document title & badges */}
          <DNABox fill appearance="col" alignY="center" spacing="sm">
            <DNAText
              status="primary"
              numberOfLines={2}
              style={{ textDecorationLine: 'underline' }}
              testID="hub-shared-files-item-title"
            >
              {documentVersionORM.model.title}
            </DNAText>
            <DocumentBadges
              documentVersionORM={documentVersionORM}
              isRequired={isRequired}
              unavailableContent={unavailableContent}
            />
          </DNABox>
          {/* Buttons */}
          <DNABox alignY="center" spacing="sm">
            <Iffy is={isNotatable}>
              <DNAPopover placement="top" disablePopover={['tabletPWA', 'tabletWEB']}>
                <DNAPopover.Anchor>
                  <DNAButton
                    iconLeft="sticker-plus-outline"
                    appearance="ghostLink"
                    status="gray"
                    size="md"
                    padding="sm"
                    onPress={() => openContentPreview(1)}
                  />
                </DNAPopover.Anchor>
                <DNAPopover.Content>
                <DNAText
                  style={S.tooltipText}
                  numberOfLines={1}
                >
                  Create callout
                </DNAText>
                </DNAPopover.Content>
              </DNAPopover>
            </Iffy>
            <Iffy is={!isRequired}>
              <DNAButton
                iconLeft="trash-can-outline"
                appearance="ghostLink"
                status={unavailableContent ? 'tertiary' : 'gray'}
                size="md"
                padding="sm"
                onPress={() => handleDeleteItem(documentVersionORM.model.id, associatedContent)}
              />
            </Iffy>
          </DNABox>
        </DNABox>
      </DNACard>
      <NotationGroup documentVersionORM={documentVersionORM} notations={notations}/>
    </DNABox>
  )
}

export const DocumentRowWrapper: React.FC<DocumentRowWrapperProps> = ({ associatedContent, isLastItem, handleDeleteItem }) => {
  const documentVersionORM = useDocumentVersionORM(associatedContent.versionId || '')
  if (!documentVersionORM) return null
  return (
    <DocumentRow
      documentVersionORM={documentVersionORM}
      associatedContent={associatedContent}
      notations={associatedContent.notation}
      isLastItem={isLastItem}
      handleDeleteItem={handleDeleteItem}
    />
  )
}

export default DocumentRow