import isValid from 'date-fns/isValid'
import parseISO from 'date-fns/parseISO'
import compareAsc from 'date-fns/compareAsc'
import {
  ControlType,
  CustomFieldDefinition,
  CustomFieldUsage,
  CustomValues,
  FieldDataType,
  FieldStatus,
  ObjectRecord,
  ObjectRecordStatus,
} from '@alucio/aws-beacon-amplify/src/models';
import { CustomFieldValuesMap, CustomObjectFieldValue } from 'src/types/orms';

export type FilterOptions<T> = {
  [P in keyof T]?: {
    [K in keyof T[P]]?: T[P][K] | // Single value Check
    ((val: T[P][K]) => boolean) | // Custom function
    T[P][K][]                     // Array of values
  }
}

export type SortOptions<T> = {
  [P in keyof T]?: {
    [K in keyof T[P]]?: 'asc' | 'desc' | ((a: T[P][K], b: T[P][K]) => number)
  } | ('asc' | 'desc' | ((a: T[P], b: T[P]) => number))
}

export type PaginationOptions = {
  currentPage: number,
  pageSize: number,
}

export type FilterAndSortOptions<T> = {
  filter?: FilterOptions<T>,
  sort?: SortOptions<T>[],
  paginate?: PaginationOptions
}

export const selectOptions = <T extends unknown>(_, __, opts: FilterAndSortOptions<T>) => opts

// [TODO] - Use any for now, can we use [T][P][K] ?
// - This definitely needs to be unit tested
export function multiTypeSort(
  a: any,
  b: any,
  opts: {
    dir?: ('asc' | 'desc'),
    fn?: (
      a: any,
      b: any,
      dir?: ('asc' | 'desc')
    ) => number
  },
): number {
  const dirModifier = opts.dir
    ? opts.dir === 'asc' ? 1 : -1
    : 1

  if (
    (a === undefined || a === null) ||
    (b === undefined || b === null)
  ) { return ((a && -1) ?? (b && 1) ?? 0) * dirModifier }

  if (opts.fn) {
    return opts.fn(a, b, opts.dir)
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    // Comparing arrays by comparing elements of arrays
    let result = 0;
    let idx = 0;
    do {
      result = multiTypeSort(a[idx], b[idx], opts);
      idx++;
    } while (result === 0 && idx < a.length && idx < b.length)
    return result;
  }

  if (typeof a === 'number' && typeof b === 'number') { return (a - b) * dirModifier }

  if (typeof a === 'string' && typeof b === 'string') {
    const isValidDates = isValid(parseISO(a)) && isValid(parseISO(b))
    if (isValidDates) { return compareAsc(parseISO(a), parseISO(b)) * dirModifier }

    return a.localeCompare(b) * dirModifier
  }

  if (typeof a === 'boolean' && typeof b === 'boolean') { return (+b - +a) * dirModifier }

  throw new Error('Could not determine values for sorting')
}

export function filterCollection<T>(
  collection: T[],
  opts?: FilterAndSortOptions<T>,
): T[] {
  if (!opts?.filter) { return collection; }

  return [...collection].filter(collectionItem => {
    const allCheck = Object
      .entries(opts.filter ?? {})
      // [TODO]: Figure out this TS issue to describe the generic shape of an ORM
      .every(([entityKey, entityValue]: [string, any]) => {
        const entityCheck = Object
          .entries(entityValue ?? {})
          .every(([filterKey, filterValue]) => {
            const entityValue = collectionItem[entityKey][filterKey]

            // [TODO]: Type check this a boolean
            const singleCheck = (typeof filterValue === 'function')
              ? filterValue(entityValue)
              : Array.isArray(filterValue)
                ? !!filterValue.find(a => a === entityValue)
                : entityValue === filterValue

            return singleCheck
          })
        return entityCheck
      })
    return allCheck
  }) as T[]
}

export function sortCollection<T>(
  records: T[],
  opts?: FilterAndSortOptions<T>,
): T[] {
  if (!opts?.sort) { return records; }

  return [...records].sort((a, b) => {
    let compareVal = 0

    for (const sortCriteria of opts.sort ?? []) { // [TODO] if (check is not catching this)
      for (const entity in sortCriteria) {
        // [NOTE] - Sorting on the first level is currently not implemented!!!!
        //        - Tech debt ticket is [BEAC-4605]
        for (const sortKey in sortCriteria[entity.toString()]) {
          const sortVal = sortCriteria[entity.toString()][sortKey]
          const sortType = typeof sortVal === 'function'
            ? { fn: sortVal }
            : { dir: sortVal }

          compareVal = multiTypeSort(
            // @ts-ignore
            a[entity][sortKey],
            // @ts-ignore
            b[entity][sortKey],
            sortType as any, // [TODO]: Figure out this TS issue to describe the generic shape of an ORM
          )

          if (compareVal !== 0) { return compareVal }
        }
      }
    }

    return 0
  }) as T[]
}

export function paginateCollection<T>(
  records: T[],
  opts: FilterAndSortOptions<T>,
): {
  totalRecords: number,
  pagedRecords: T[]
} {
  if (!opts?.paginate) { return { totalRecords: records.length, pagedRecords: records } }

  const { currentPage, pageSize } = opts.paginate
  const startIdx = (currentPage - 1) * pageSize
  const endIdx = startIdx + pageSize

  return {
    totalRecords: records.length,
    pagedRecords: records.slice(startIdx, endIdx),
  }
}

export enum ADDITIONAL_FILTER {
  FOLDER_FILTER = 'FOLDER_FILTER'
}

export interface Usages {
  internalUsages?: CustomFieldUsage[],
  additionalUsages?: ADDITIONAL_FILTER[],
}

export function isRequiredField(customField: CustomFieldDefinition, usages: Usages): boolean {
  const { additionalUsages = [], internalUsages = [] } = usages;

  if (customField.status === FieldStatus.DISABLED) {
    return false;
  }

  let isRequired = internalUsages.every((usage) => customField.usage.includes(usage));

  additionalUsages.forEach((additionalUsage) => {
    switch (additionalUsage) {
      case ADDITIONAL_FILTER.FOLDER_FILTER: {
        isRequired = customField.usage.includes(CustomFieldUsage.USER_FILTER) &&
          !!customField.showByDefault;
      }
    }
  });
  return isRequired;
}

export function getMappedCustomValues(
  usages: Usages,
  customValues?: CustomValues[],
  customFields?: CustomFieldDefinition[],
  mapChildFields = false,
): CustomFieldValuesMap {
  const indexedCustomValues = (customValues || []).reduce<{ [id: string]: CustomValues }>((acc, customValue) => {
    acc[customValue.fieldId] = customValue;
    return acc;
  }, {});

  return customFields?.filter( p => !p.isChildField || (mapChildFields && p.fieldType !== FieldDataType.OBJECT))
    .reduce<CustomFieldValuesMap>((acc, customField) => {
      if (!isRequiredField(customField, usages)) {
        return acc;
      }

      acc[customField.id] = {
        field: customField,
        values: indexedCustomValues[customField.id]?.values || [],
        displayValues: [],
      };

      if (customField.controlType === ControlType.OBJECT) {
        const childFields = customFields.filter(p => customField.objectSetting?.childrenFieldIds?.includes(p.id));
        const objRecords = indexedCustomValues[customField.id]?.objectRecords || [];
        acc[customField.id].objectValues = getMappedCustomObjectValues(usages, customField, childFields, objRecords);
      }

      if (!indexedCustomValues[customField.id]) {
        return acc;
      }

      if ([FieldDataType.CATEGORICAL, FieldDataType.MULTICATEGORICAL]
        .includes(customField.fieldType as FieldDataType)) {
        acc[customField.id].valuesDefinition = [];

        indexedCustomValues[customField.id].values.forEach((value) => {
          const valueDefinition = customField.fieldValueDefinitions.find(({ id }) => id === value);
          if (valueDefinition) {
            if (!valueDefinition.disabled) {
              acc[customField.id].displayValues.push(valueDefinition.label || valueDefinition.value);
              acc[customField.id].valuesDefinition?.push(valueDefinition);
            }
          } else {
            console.warn('Value Definition not found for' + value);
          }
        });
      } else {
        customField.fieldType === FieldDataType.DATE
          ? acc[customField.id].displayValues.push(...indexedCustomValues[customField.id].values.map((value) =>
            new Date(value).toLocaleDateString()))
          : acc[customField.id].displayValues.push(...indexedCustomValues[customField.id].values)
      }

      acc[customField.id].displayValues.sort();
      return acc;
    }, {}) || {};
}

export function hasMissingRequiredFields(
  customValues: CustomValues[],
  customFields: CustomFieldDefinition[],
  usage: CustomFieldUsage,
): boolean {
  const requiredFields = customFields.filter((customField) =>
    customField.required && customField.status === FieldStatus.ENABLED && customField.usage.includes(usage));
  const indexedValues = customValues.reduce<{ [id: string]: string[] }>((acc, field) => {
    acc[field.fieldId] = field.values;
    return acc;
  }, {}) || {};

  return requiredFields.some(({ id }) =>
    !indexedValues[id] || !indexedValues[id].length);
}

export function getMappedCustomObjectValues(
  usages: Usages,
  customField: CustomFieldDefinition,
  childrenCustomFields: CustomFieldDefinition[],
  objectRecords?: ObjectRecord[],
): CustomObjectFieldValue[] | undefined {
  if (!isRequiredField(customField, usages) || !objectRecords) {
    return;
  }
  const customFieldValues: CustomObjectFieldValue[] = []
  objectRecords.forEach((objectRecord) => {
    if (objectRecord.status === ObjectRecordStatus.REMOVED) return;
    const customFieldValuesMap = getMappedCustomValues(usages, objectRecord.values, childrenCustomFields, true);
    customFieldValues.push({
      customFieldValues : customFieldValuesMap,
      id: objectRecord.id,
      externalId: objectRecord.externalId,
      status: ObjectRecordStatus[objectRecord.status],
    });
  })
  return customFieldValues
}
