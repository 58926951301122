import React, { useState } from 'react'
import { useDispatch } from 'src/state/redux'

import { FolderORM } from 'src/types/types'
import { folderActions } from 'src/state/redux/slice/folder'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'

import {
  TextField,
  DNAButton,
  DNAModal,
  DNAText,
  DNABox,
  GenericToast,
  ToastOrientations,
  useDisableSwipe,
} from '@alucio/lux-ui'
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal'
import { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast'
import { useAppSettings } from 'src/state/context/AppSettings'
import { isSharedEditableFolder } from 'src/utils/foldersHelpers'
import { useCurrentUser } from 'src/state/redux/selector/user'
import { UpdateType, sharedFolderActions } from 'src/state/redux/slice/sharedFolder'

export enum FOLDER_OP {
  CREATE = 'CREATE',
  CREATE_SUBFOLDER = 'CREATE_SUBFOLDER',
  RENAME = 'RENAME',
}

export type FolderOp = {
  [K in keyof typeof FOLDER_OP] : {
    header: string,
    confirm: string
  }
}

const folderOp: FolderOp = {
  CREATE: {
    header: 'Create new folder',
    confirm: 'Create folder',
  },
  CREATE_SUBFOLDER: {
    header: 'Create new sub folder',
    confirm: 'Create sub folder',
  },
  RENAME: {
    header: 'Rename folder',
    confirm: 'Rename folder',
  },
}

export type DNAFolderUpsertModalProps = {
  folderORM?: FolderORM,
  mode?: FOLDER_OP | keyof typeof FOLDER_OP
  toast?: ToastActions
}

const Label = () => (
  <DNABox spacing="xs" style={{ marginBottom: 4 }}>
    <DNAText b2 status="danger">*</DNAText>
    <DNAText b2>NAME</DNAText>
  </DNABox>
)

const DNAFolderUpsertModal: DNAModalVariant<DNAFolderUpsertModalProps> = (props) => {
  useDisableSwipe()
  const {
    toggleModal,
    folderORM,
    mode = folderORM ? FOLDER_OP.RENAME : FOLDER_OP.CREATE,
    toast,
  } = props
  const dispatch = useDispatch()
  const [isSaving, setIsSaving] = useState(false)
  const [input, setInput] = useState<string>(
    mode === FOLDER_OP.RENAME && folderORM
      ? folderORM.model.name
      : '',
  )

  const { deviceMode } = useAppSettings()
  const currentUser = useCurrentUser()
  const isTablet = deviceMode === 'tablet';
  const headerCopy = folderOp[mode].header
  const confirmCopy = folderOp[mode].confirm

  const onAction = () => {
    setIsSaving(true)
    if (mode === FOLDER_OP.RENAME && folderORM) {
      analytics?.track('FOLDER_RENAME', {
        action: 'RENAME',
        category: 'FOLDER',
        folderId: folderORM.model.id,
      });
      const isSharedEditFolder = folderORM && isSharedEditableFolder(folderORM, currentUser.userProfile?.id)
      if (isSharedEditFolder) {
        dispatch(sharedFolderActions.updateSharedFolder(
          {
            folderORM,
            folderUpdates: {
              id: folderORM.model.id,
              name: input,
            },
            updateType: UpdateType.RENAME_SUB_FOLDER,
          },
        ))
      }
      else {
        dispatch(folderActions.rename(input, folderORM.model))
      }
      toast?.add(
        <GenericToast
          title="Folder renamed."
          status="success"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    }
    if (mode === FOLDER_OP.CREATE_SUBFOLDER && folderORM) {
      const isSharedEditFolder = folderORM && isSharedEditableFolder(folderORM, currentUser.userProfile?.id)
      if (isSharedEditFolder) {
        dispatch(sharedFolderActions.createShareSubFolder(
          {
            folder: folderORM,
            title: input,
          },
        ))
      }
      else {
        dispatch(folderActions.createSubFolder(folderORM, input))
      }
      toast?.add(
        <GenericToast
          title="Folder created."
          status="success"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    }

    if (mode === FOLDER_OP.CREATE) {
      dispatch(folderActions.create({ name: input }))
      toast?.add(
        <GenericToast
          title="Folder created."
          status="success"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    }

    setTimeout(() => {
      dispatch(DNAModalActions.toggleModal())
      setIsSaving(false)
    }, 500)
  }
  const canSave = input.trim().length > 0 && !isSaving
  return (
    <DNAModal>
      <DNAModal.Header onClose={toggleModal}>
        { headerCopy }
      </DNAModal.Header>
      <DNAModal.Body>
        <TextField.Kitten
          testID="create-new-folder-name-input"
          style={{ minWidth: 567 }}
          autoFocus={!isTablet}
          onKeyPress={ (event) => {
            if (!canSave && event.nativeEvent.key === 'Enter') {
              event.preventDefault();
              return;
            }
            event.nativeEvent.key === 'Enter' && onAction()
          }}
          label={Label}
          value={input}
          onChangeText={setInput}
        />
      </DNAModal.Body>
      <DNAModal.Confirmation>
        <DNAButton onPress={toggleModal} status="tertiary">
          Cancel
        </DNAButton>
        <DNAButton testID="confirmation-button" disabled={!canSave} onPress={onAction} status="primary">
          { confirmCopy }
        </DNAButton>
      </DNAModal.Confirmation>
    </DNAModal>
  )
}

export default DNAFolderUpsertModal
