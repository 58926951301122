import React, { useState } from 'react'
import { StyleSheet } from 'react-native'
import { DNABox, DNAButton, DNAText } from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { useAppSettings, DeviceMode } from 'src/state/context/AppSettings'
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton'

const styles = StyleSheet.create({
  offlineContent: {
    backgroundColor: colors['color-text-white'],
    borderRadius: 8,
    padding: 24,
    marginRight: 24,
    borderColor: colors['color-brand2-5'],
    shadowColor: colors['color-brand2-5'],
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 6,
  },
  title: {
    paddingBottom: 16,
  },
})

type DeviceModeButtonProps = Record<DeviceMode, DNAButtonProps>
const deviceModeButtonProps:DeviceModeButtonProps = {
  desktop: {
    padding: 'sm',
    size: 'lg',
  },
  tablet: {
    appearance: 'ghostLink',
    padding: 'none',
    rounded: 'full',
    size: 'xl',
  },
}

const NetwrokOfflineButton: React.FC = () => {
  const [contentVisible, setContentVisible] = useState<boolean>(false);
  const toggleContent = () => setContentVisible(p => !p)
  const { deviceMode } = useAppSettings()
  const currentDeviceModeButtonProps = deviceModeButtonProps[deviceMode]

  return (
    <DNAPopover
      lazyMount
      placement="bottom-end"
      interactive={true}
      visible={contentVisible}
      onBackdropPress={toggleContent}
      type="menu"
      style={{ marginRight: 40 }}
    >
      <DNAPopover.Anchor style={{ height: '100%' }}>
        <DNAButton
          onPress={toggleContent}
          status="warning"
          iconLeft="wifi-off"
          {...currentDeviceModeButtonProps}
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content offset={10} style={{ marginRight: 40 }}>
        <NetworkOfflineContent />
      </DNAPopover.Content>
    </DNAPopover>
  )
}

export const NetworkOfflineContent: React.FC = () => {
  return (
    <DNABox appearance="row" fill>
      <DNABox appearance="col" style={styles.offlineContent}>
        <DNAText b1 bold style={styles.title}>No internet connection</DNAText>
        <DNAText b1>Some features will be unavailable in offline mode.</DNAText>
      </DNABox>
    </DNABox>
  )
}

export default NetwrokOfflineButton
