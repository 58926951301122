import he from 'he'

export const decodeHtml = (html?: string) => {
  if (!html) return ''
  return he.decode(html)
}

export const decodeHTMLObject = (obj: unknown) => {
  if (obj === undefined || obj === null) {
    return obj;
  }

  if (typeof obj === 'string') {
    return he.decode(obj);
  } else if (Array.isArray(obj)) {
    return obj.map(item => decodeHTMLObject(item));
  } else if (typeof obj === 'object') {
    const decodedObj = {};
    for (const key of Object.keys(obj)) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        decodedObj[key] = decodeHTMLObject(obj[key]);
      }
    }
    return decodedObj;
  }
  return obj;
}
