import React from 'react';
import { ImageStyle, Pressable, StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import { DNABox, DNAChip, DNAText, Iffy, Stack } from '@alucio/lux-ui';
import { PresentablePage } from 'src/types/types';
import { PresentedMeta } from '@alucio/aws-beacon-amplify/src/models';
import { detectArchivedFileKeyPath } from '../../SlideSelector/useThumbnailSelector';
import DNAThumbnail from '../Thumbnail/DNAThumbnail';
import {
  SLIDE_ROLL_VARIANT,
  ThumbnailNumberPosition,
  thumbnailNumberPositionProps,
  thumbnailVariantSettings,
  thumbnailVariantStyles,
} from './StyleSetting';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import ItemWrapper from 'src/components/ContentPreviewModal/SlideRoll/Wrapper/Wrapper';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import SlidePreviewModal from '../../ContentPreviewModal/SlidePreviewModal';

const S = StyleSheet.create({
  full: { width: '100%', height: '100%' },
  tintOverlay: {
    backgroundColor: colors['color-text-white'],
    opacity: 0.5,
  },
})

interface DNAPresentableThumbnailProps {
  fileTypeVisible?: boolean,
  hasNotation?: boolean,
  height?: number,
  isContentUnavailable?: boolean,
  isSelected?: boolean,
  isTablet?: boolean,
  tintOverlay?: boolean,
  numberPosition?: ThumbnailNumberPosition,
  onSelect?: () => void,
  page: PresentablePage,
  presentedMeta?: PresentedMeta,
  showPreview?: boolean,
  title?: string,
  variant?: SLIDE_ROLL_VARIANT,
  width?: number,
}

const DNASlideRollThumbnail: React.FC<DNAPresentableThumbnailProps> = ({
  height,
  isContentUnavailable,
  isSelected,
  isTablet,
  numberPosition,
  onSelect,
  page,
  variant = SLIDE_ROLL_VARIANT.DEFAULT,
  presentedMeta,
  width,
  tintOverlay,
  fileTypeVisible,
  title,
  showPreview,
  hasNotation,
}) => {
  const s3URL = detectArchivedFileKeyPath(page.documentVersionORM.model, page.page, isTablet ? 'sm' : 'xl');
  const variantStyles = thumbnailVariantStyles[variant];
  const variantSettings = thumbnailVariantSettings[variant];
  const thumbnailPlacementProps = thumbnailNumberPositionProps[numberPosition ?? variantSettings.numberPosition];
  const titleWidth = (width ?? variantSettings.thumbnailWidth);
  const bypassItemWrapper = variant !== SLIDE_ROLL_VARIANT.MEETINGS;
  const itemWrapperHeight = Number(variantStyles.pressableContainer.height || variantSettings.thumbnailHeight || 0) + 2;
  const dispatch = useDispatch();

  if (isContentUnavailable) {
    return (
      <DNABox style={[variantStyles.container, { margin: 3 }]} />
    );
  }

  const onPreview = (): void => {
    const previewS3URL = detectArchivedFileKeyPath(page.documentVersionORM.model, page.page, 'xl');
    dispatch(
      DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (props) => (<SlidePreviewModal
          presentPage={onSelect}
          archivedKey={previewS3URL || ''}
          title={title || ''}
          {...props}
        />),
      }))
  }

  return (
    <ItemWrapper
      bypassWrapper={bypassItemWrapper}
      itemHeight={itemWrapperHeight}
      onPresent={onSelect}
      onPreview={onPreview}
      showPreview={showPreview}
    >
      <Pressable
        disabled={!bypassItemWrapper && !isTablet}
        onPress={onSelect}
        style={variantStyles.pressableContainer}
      >
        {/* CONTAINER  */}
        <DNABox testID={`slide-roll-image-${page.presentationPageNumber}`}{...thumbnailPlacementProps} spacing="xs">
          <Stack anchor="center">
            <Stack.Layer>
              {/* OUTER THUMBNAIL CONTAINER */}
              <DNABox
                key={page.id}
                style={[variantStyles.container, isSelected && variantStyles.selected]}
                alignX="center"
                alignY="center"
              >
                {/* INNER THUMBNAIL CONTAINER */}
                <DNABox
                  alignX="center"
                  alignY="center"
                  style={[
                    variantStyles.innerContainer,
                    !isSelected
                      ? variantStyles.unselected
                      : variantStyles.selected,
                  ]}
                >
                  {/* THUMBNAIL */}
                  <DNAThumbnail.Lazy
                    style={variantStyles.thumbnail as ImageStyle}
                    s3URL={s3URL}
                    presentedMeta={presentedMeta}
                    height={height ?? variantSettings.thumbnailHeight}
                    width={width ?? variantSettings.thumbnailWidth}
                    hasNotation={hasNotation}
                  />
                </DNABox>
              </DNABox>
            </Stack.Layer>
            <Stack.Layer style={S.full}>
              {/* TINT OVERLAY */}
              <Iffy is={tintOverlay && !presentedMeta?.presented}>
                <DNABox fill style={[S.tintOverlay]}/>
              </Iffy>
            </Stack.Layer>
          </Stack>
          <DNABox spacing="between">

            {/* PAGE NUMBER */}
            <DNAText
              testID='slide-thumbnail-page-number'
              numberOfLines={1}
              style={[
                { ...variantStyles.pageText, maxWidth: titleWidth },
                isSelected && variantStyles.pageTextSelected,
              ]}
            >
              {`${page.presentationPageNumber}. ${title}` }
            </DNAText>

            {/* Badge */}
            {fileTypeVisible &&
              <DNAChip
                appearance="tag"
                status="basic"
                size="sm"
              >{page.documentVersionORM.model.type}
              </DNAChip>
            }
          </DNABox>
        </DNABox>
      </Pressable>
    </ItemWrapper>
  );
};

DNASlideRollThumbnail.displayName = 'DNASlideRollThumbnail';

export default DNASlideRollThumbnail;
