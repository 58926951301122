import React from 'react';
import { DNABox, DNAButton } from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import { StyleSheet, Text } from 'react-native';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { useContentPreviewModal } from '../State/ContentPreviewModalStateProvider';

const styles = StyleSheet.create({
  headerBarIcons: {
    flexDirection: 'row-reverse',
    marginRight: 0,
  },
  tooltipTextColor: {
    color: 'white',
  },
});

interface MenuItem {
  iconName: string,
  tooltip: string,
  testID?: string,
}

const TopMenuBar:React.FC = () => {
  const {
    isModifiedDocumentVersion,
    isPublisher, activeDocumentVersionORM,
    activeDocumentORM,
    bookmarkDocument, addDocumentToFolder,
  } = useContentPreviewModal()
  const { activePresentation } = useContent();

  if (!activePresentation || !activeDocumentVersionORM || !activeDocumentORM) return null
  const { latestPublishedDocumentVersionORM } = activeDocumentVersionORM.relations.documentORM.relations.version

  const getIcons = () => {
    const { addToFolder: canAddToFolder, bookmark } = activeDocumentORM.meta.permissions
    const { isBookmarked } = activeDocumentORM.meta.bookmark
    const canBookmark = bookmark && !isPublisher && !isModifiedDocumentVersion

    const addToFolderIcon = {
      iconName: 'folder-plus-outline',
      tooltip: 'Add file to folder',
      testID: 'content-preview-header-add-to-folder-btn',
    }
    const bookmarkIcon = {
      iconName: isBookmarked ? 'bookmark' : 'bookmark-outline',
      tooltip: isBookmarked
        ? 'Remove from bookmarks'
        : 'Add to bookmarks',
      testID: 'bookmark-header-button',
    }
    const iconsArray: MenuItem[] = [
      ...canAddToFolder ? [addToFolderIcon] : [],
      ...canBookmark ? [bookmarkIcon] : [],
    ]
    return iconsArray;
  }

  const onOptionSelected = (option: string) => {
    if (option.startsWith('bookmark')) {
      bookmarkDocument()
    }
    if (option === 'folder-plus-outline') {
      addDocumentToFolder()
    }
  }

  if (latestPublishedDocumentVersionORM?.model.id !==
    activePresentation?.currentPresentablePage?.documentVersionORM.model.id) {
    return null
  }

  return (<DNABox alignY="center" fill style={styles.headerBarIcons} >
    {getIcons().map(
      ({ iconName, tooltip, testID }, index: number) => (
        (
          <DNAPopover key={iconName} disablePopover={['tabletPWA']}>
            <DNAPopover.Anchor>
              <DNAButton
                status="tertiary"
                appearance="ghost"
                key={`head_bar_icon_${index}`}
                size="md"
                iconLeft={iconName}
                style={{ 'height': 35, 'width': 35, 'marginHorizontal': 5 }}
                onPress={() => onOptionSelected(iconName)}
                testID={testID}
              />
            </DNAPopover.Anchor>
            <DNAPopover.Content offset={2}>
              <Text numberOfLines={1} style={styles.tooltipTextColor}>{tooltip}</Text>
            </DNAPopover.Content>
          </DNAPopover>)
      ),
    )}
  </DNABox>)
}

export default TopMenuBar
