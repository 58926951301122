import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet, Animated, Easing } from 'react-native';
import { DNABox, DNAButton, DNAText, Iffy, InformationMessage, util } from '@alucio/lux-ui';
import { arrayMove } from '@dnd-kit/sortable';
import { DragStartEvent, DragEndEvent } from '@dnd-kit/core';
import {
  WidgetContentCardProps,
  WidgetCardHeader,
  WidgetCardBody,
  WidgetContextMenu,
  widgetContentCardStyles,
} from 'src/screens/Hubs/EditHub/Widgets/WidgetContentCard';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { HubSectionType, ToDo, TodoStatus } from '@alucio/aws-beacon-amplify/src/models';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import DNARearranger, { ModifiersListEnum } from 'src/components/DnD/DNARearranger';
import { TodoProvider, useTodoState } from 'src/screens/Hubs/EditHub/Widgets/ToDoComponents/ToDoListProvider';
import ToDoRow from 'src/screens/Hubs/EditHub/Widgets/ToDoComponents/ToDoRow';

const DNAREARRANGER_MODIFIERS = [ModifiersListEnum.restrictToVerticalAxis]
const DELAY_TIMEOUT = 1000

export const styles = StyleSheet.create({
  completedHeader: {
    backgroundColor: colors['color-gray-20'],
    borderRadius: 6,
    marginTop: 20,
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  activeItemContainer: {
    borderBottomWidth: 1,
    borderBottomColor: colors['color-gray-80'],
  },
  completedItemContainer: {
    borderBottomWidth: 1,
    borderBottomColor: colors['color-gray-80'],
    paddingLeft: 56,
  }
});

const TodoList: React.FC = () => {
  const { showFullSizeBtn } = useHubsState()
  const {
    widget,
    items,
    errorMsg,
    setErrorMsg,
    showCompleted,
    setShowCompleted,
    activeItems,
    completedItems,
    isNewItem,
    editingId,
    updateFormValue,
    addToDo,
  } = useTodoState()

  const [activeItem, setActiveItem] = useState<ToDo>()
  const [showAllActiveItems, setShowAllActiveItems] = useState<boolean>(false)
  const [showAllCompletedItems, setShowAllCompletedItems] = useState<boolean>(false)
  const [previousActiveItems, setPreviousActiveItems] = useState<ToDo[]>(activeItems)
  const [previousCompletedItems, setPreviousCompletedItems] = useState<ToDo[]>(completedItems)

  const bounceValue = useRef(new Animated.Value(0)).current

  const startBouncing = useCallback(() => {
    bounceValue.setValue(0)
    Animated.loop(
      Animated.sequence([
        Animated.timing(bounceValue, {
          toValue: 1,
          duration: 200,
          easing: Easing.linear,
          useNativeDriver: false,
        }),
        Animated.timing(bounceValue, {
          toValue: 0,
          duration: 200,
          easing: Easing.linear,
          useNativeDriver: false,
        }),
      ]),
      { iterations: 2 }
    ).start()
  }, [bounceValue])

  const translateY = bounceValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0%', '-15%'],
  })

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined
    let shouldDelay
    if (!isNewItem && previousActiveItems.length !== activeItems.length) {
      const longerArray = previousActiveItems.length > activeItems.length ? previousActiveItems : activeItems
      const shorterArray = previousActiveItems.length < activeItems.length ? previousActiveItems : activeItems
      for (const toDo of longerArray) {
        const found = shorterArray.some(item => item.id === toDo.id)
        if (!found) {
          const updatedItem = items.find(item => item.id === toDo.id)
          shouldDelay = updatedItem?.status === TodoStatus.ACTIVE || updatedItem?.status === TodoStatus.COMPLETED
        }
      }
    }
    if (shouldDelay) {
      timeout = setTimeout(() => {
        setPreviousActiveItems(activeItems)
        setPreviousCompletedItems(completedItems)
        // Button Animation - only bounce when completedItems increased
        if (completedItems.length > (previousCompletedItems?.length || 0)) startBouncing()
      }, DELAY_TIMEOUT)
    } else {
      setPreviousActiveItems(activeItems)
      setPreviousCompletedItems(completedItems)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [activeItems, completedItems])

  const itemIds = useMemo(() => activeItems.map(toDo => toDo.id), [activeItems])

  const handleDragStart = useCallback((event: DragStartEvent) => {
    const { active } = event
    if (active.id) {
      const activeItemId = active.id
      const activeItem = activeItems.find(item => item.id === activeItemId)
      setActiveItem(activeItem)
    }
  }, [activeItems])

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event
    if (active.id !== over?.id && activeItems.length > 1) {
      const oldIndex = items.findIndex((item) => item.id === active.id)
      const newIndex = items.findIndex((item) => item.id === over?.id)
      const newArr = arrayMove(items, oldIndex, newIndex)
      const newOrder = newArr.map((item, index) => {
        return { ...item, order: index }
      })
      updateFormValue(newOrder)
    }
    setActiveItem(undefined)
  }, [items])

  const rightElement = useMemo(() => {
    const showCompletedBtnText = `${showCompleted ? 'Hide' : 'Show'} completed (${previousCompletedItems.length})`
    return (
      <DNABox alignY="center" spacing="sm">
        <DNAButton
          iconLeft="plus"
          appearance="outline"
          status="tertiary"
          onPress={addToDo}
          testID="hub-add-item-btn"
          children={showFullSizeBtn ? 'Add Item' : undefined}
          style={util.mergeStyles(
            undefined,
            [widgetContentCardStyles.contextMenuAnchorBtn, !showFullSizeBtn],
          )}
        />
        <Animated.View style={{ transform: [{ translateY }] }}>
          <DNAButton
            iconLeft={showFullSizeBtn ? undefined : 'clipboard-check-outline'}
            appearance="outline"
            status="tertiary"
            onPress={() => setShowCompleted(p => !p)}
            testID='hub-show-completed-btn'
            children={showFullSizeBtn ? showCompletedBtnText : undefined}
            style={util.mergeStyles(
              undefined,
              [widgetContentCardStyles.contextMenuAnchorBtn, !showFullSizeBtn],
            )}
          />
        </Animated.View>
        <WidgetContextMenu widgetType={HubSectionType.TODO_LIST} id={widget.id} />
      </DNABox>
    )
  }, [addToDo, showCompleted, previousCompletedItems, showFullSizeBtn])

  return (
    <>
      <WidgetCardHeader
        widget={widget}
        rightElement={rightElement}
      />
      <WidgetCardBody>
        <Iffy is={!activeItems.length}>
          <DNAText status="flatAlt" >Add to-do items to see them appear here</DNAText>
        </Iffy>
        {/* Error Message */}
        <Iffy is={errorMsg.length}>
          <InformationMessage
            variance="danger"
            text={errorMsg.join(', ')}
            actionButtonFunction={() => setErrorMsg([])}
            actionButtonText="Close"
          />
        </Iffy>
        <Iffy is={activeItems.length}>
          <DNARearranger
            collisionDetection={'closestCenter'}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            modifiers={DNAREARRANGER_MODIFIERS}
            items={itemIds}
            strategy={'verticalListSortingStrategy'}
            overlay={activeItem && (
              <DNARearranger.SortableItem
                id={activeItem.id}
                itemId={activeItem.id}
                containerId="todoContainer"
                handle
                boxContainerStyle={styles.activeItemContainer}
                iconAlignY="start"
              >
                <ToDoRow item={activeItem} isOverlay={true}/>
              </DNARearranger.SortableItem>
            )}
          >
            {
              previousActiveItems.map((item, index) => {
                if (!showAllActiveItems && index > 4) return null
                return (
                  <React.Fragment key={item.id}>
                    <DNARearranger.SortableItem
                      id={item.id}
                      itemId={item.id}
                      containerId="toDos-base"
                      disabled={editingId === item.id}
                      handle
                      boxContainerStyle={styles.activeItemContainer}
                      iconAlignY="start"
                    >
                      <ToDoRow item={item} />
                    </DNARearranger.SortableItem>
                  </React.Fragment>
                )
              },
              )
            }
          </DNARearranger>
          <Iffy is={activeItems.length > 5}>
            <DNAButton
              appearance="outline"
              status="tertiary"
              onPress={() => setShowAllActiveItems(p => !p)}
            >
              {showAllActiveItems ? 'Show Less' : 'Show All'}
            </DNAButton>
          </Iffy>
        </Iffy>
        <Iffy is={showCompleted}>
          <DNABox fill appearance="col" spacing="md" alignY="center">
            <DNABox style={styles.completedHeader}>
              <DNAText bold>Completed </DNAText>
              <DNAText bold>{`(${completedItems.length})`}</DNAText>
            </DNABox>
            <Iffy is={!completedItems.length}>
              <DNAText status="flatAlt" >No completed items</DNAText>
            </Iffy>
            <DNABox
              fill
              appearance="col"
              spacing="md"
              alignY="center"
            >
              {
                previousCompletedItems.map((item, index) => {
                  if (!showAllCompletedItems && index > 4) return null
                  return (
                    <DNABox key={item.id} style={styles.completedItemContainer}>
                      <ToDoRow item={item} />
                    </DNABox>
                  )
                })
              }
            </DNABox>
            <Iffy is={completedItems.length > 5}>
              <DNAButton
                appearance="outline"
                status="tertiary"
                onPress={() => setShowAllCompletedItems(p => !p)}
              >
                {showAllCompletedItems ? 'Show Less' : 'Show All'}
              </DNAButton>
            </Iffy>
          </DNABox>
        </Iffy>
      </WidgetCardBody>
    </>
  );
};

const TodoListWrapper: React.FC<WidgetContentCardProps> = (props) => {
  return (
    <TodoProvider {...props}>
      <TodoList />
    </TodoProvider>
  );
}

export default TodoListWrapper;
