import React from 'react'
import { DNABox, DNACard, DNAChip, DNAText, useTheme, util } from '@alucio/lux-ui'
import { FolderItemORM, CustomDeckORM } from 'src/types/types'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'
import DNACustomDeckThumbnail from 'src/components/DNA/Document/DNACustomDeckThumbnail'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'

const DNACustomDeckFolderItemCustomDeckRow: DNARowsComponent<FolderItemORM> = (props) => {
  const {
    ORM: folderItem,
    onPress,
    isOnline,
  } = props

  const theme = useTheme()

  const customDeckORM = (folderItem.relations.itemORM as CustomDeckORM)
  const {
    containsWebDocs,
    assets:{ isContentCached },
  } = customDeckORM.meta
  const contentAvailable = isOnline || (isContentCached && !containsWebDocs)

  const allStyle = {
    paddingHorizontal: theme['row-padding-horizontal'],
    paddingVertical: theme['row-padding-vertical'],
  }

  const popoverInfoText = 'Modified files cannot be used when building presentations'

  return (
    <DNACard
      appearance="flat"
      disabled={true}
      onPress={() => onPress?.(folderItem)}
      style={[util.mergeStyles(props, allStyle)]}
    >
      <DNAPopover disablePopover={['tabletPWA']} placement="top">
        <DNAPopover.Anchor style={{ flex: 1, flexDirection: 'column', width: '100%' }}>
          <DNABox
            fill
            testID="folder-item-row"
            alignY="center"
            style={{ opacity: 0.4 }}
          >
            <DNABox
              fill
              childFill={1}
              spacing="lg"
              alignY="center"
            >
              {/* Thumbnail */}
              <DNACustomDeckThumbnail
                customDeckORM={customDeckORM}
                width={76}
                height={44}
                unavailableContent={!contentAvailable}
              />
              <DNABox
                childFill
                spacing="sm"
                appearance="col"
                style={{ paddingRight: 16 }}
              >
                {/* Title */}
                <DNAText testID="document-item-title" b1 numberOfLines={1}>
                  {folderItem.meta.title}
                </DNAText>
                {/* Badge & Tags */}
                <DNABox
                  fill
                  appearance="row"
                  spacing="sm"
                  alignY="center"
                  childStyle={[4, { flex: 1 }]}
                >
                  <DNAChip appearance="tag">
                    MODIFIED
                  </DNAChip>
                </DNABox>
              </DNABox>
            </DNABox>
          </DNABox>
        </DNAPopover.Anchor>
        <DNAPopover.Content>
          <DNAText status="basic">{popoverInfoText}</DNAText>
        </DNAPopover.Content>
      </DNAPopover>
    </DNACard>
  )
}

export default DNACustomDeckFolderItemCustomDeckRow
