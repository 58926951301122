import { useEffect, useRef } from 'react';
import { square } from './Shapes/SquareCanvas';
import { arrow } from './Shapes/ArrowCanvas';
import { CanvasEvent } from './Shapes/shared';

type useCanvasType = {
  canvasMounted?: (() => void) | undefined;
  canvasRef: React.MutableRefObject<HTMLCanvasElement | null>;
  clearCanvas?: (() => void) | undefined;
  initCanvas?: (() => void) | undefined;
  isDrawStart?: boolean | undefined;
  mouseDownListener?: ((event: CanvasEvent) => void) | undefined;
  mouseMoveListener?: ((event: CanvasEvent) => void) | undefined;
  mouseUpListener?: ((event: CanvasEvent) => void) | undefined;
  touchStartListener?: ((event: CanvasEvent) => void) | undefined;
  touchMoveListener?: ((event: CanvasEvent) => void) | undefined;
  touchEndListener?: ((event: CanvasEvent) => void) | undefined;
  prepareCanvas?: (() => void) | undefined;
  selectedType: CanvasVariantEnum | undefined;
}

export enum CanvasVariantEnum {
  square = 'square',
  arrow = 'arrow',
}
export type CanvasTypeResult = ReturnType<typeof square> | ReturnType<typeof arrow>;
type CanvasType = (canvasRef:
  React.MutableRefObject<HTMLCanvasElement | null>,
  isDrawStart: boolean
) => CanvasTypeResult

const canvasTypes : Record<CanvasVariantEnum, CanvasType> = {
  square : square,
  arrow : arrow,
}

export const useCanvas = (type: CanvasVariantEnum | undefined) : useCanvasType => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const isDrawStart = (useRef(false)).current;
  const canvas = type ? canvasTypes[type](canvasRef, isDrawStart) : undefined;

  useEffect(() => {
    if (!canvas?.onCanvasMounted) return;
    const interval = setInterval(() => {
      window.requestAnimationFrame(canvas.onCanvasMounted)
    }, 200)

    return () => {
      clearInterval(interval)
    }
  }, [canvas?.onCanvasMounted])

  useEffect(() => {
    if (canvasRef.current) {
      canvas?.initCanvas();
    }
  }, [canvasRef.current])

  return {
    canvasRef,
    selectedType: type,
    ...canvas,
  }
}
