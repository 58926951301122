import React, { ReactElement, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import FormWrapper from '../../FormWrapper';
import { Button, Iffy, luxColors } from '@alucio/lux-ui';
import InputComponent from 'src/components/Publishers/InputComponent';
import Auth from '@aws-amplify/auth';
import { IAuthPieceProps } from 'aws-amplify-react/lib-esm/Auth/AuthPiece';
import withPasswordChecker, { ValidationType } from '../PasswordChecker';
import PasswordCriterias from '../PasswordCriterias';
import useSSO from 'src/components/DNA/hooks/useSso';
import * as logger from 'src/utils/logger'

const styles = StyleSheet.create({
  changePasswordButton: {
    backgroundColor: luxColors.thumbnailBorder.primary,
    borderColor: luxColors.thumbnailBorder.primary,
    borderRadius: 50,
    marginBottom: 24,
    marginTop: 24,
  },
  disabledTextColor: {
    color: luxColors.disabled.quinary,
  },
  disabledButton: {
    backgroundColor: luxColors.disabled.quaternary,
    borderColor: luxColors.disabled.quaternary,
  },
  emailSentText: {
    alignSelf: 'center',
    color: luxColors.success.primary,
    fontSize: 13.5,
    marginTop: 16,
  },
  enterEmailText: {
    color: luxColors.text.secondary,
    fontSize: 14,
    marginBottom: 25,
  },
  errorWrapper: {
    height: 20,
    marginTop: 10,
    marginBottom: 10,
  },
  invalidMessage: {
    color: luxColors.error.primary,
    fontSize: 12,
  },
  mainWrapper: {
    flexDirection: 'column',
    width: '100%',
  },
  marginTop: {
    marginTop: 20,
  },
  passwordUpdatedText: {
    alignSelf: 'center',
    color: luxColors.success.primary,
    fontSize: 13,
    marginTop: 20,
    marginBottom: 20,
  },
  resendCode: {
    color: luxColors.thumbnailBorder.primary,
    fontSize: 13,
  },
  resendWrapper: {
    alignItems: 'flex-end',
    zIndex: 1,
  },
  return: {
    color: luxColors.thumbnailBorder.primary,
    fontSize: 13,
  },
  row: {
    flexDirection: 'row',
  },
  sendCodeButton: {
    backgroundColor: luxColors.thumbnailBorder.primary,
    borderColor: luxColors.thumbnailBorder.primary,
    borderRadius: 50,
    height: 43,
    marginTop: 10,
  },
  setPasswordText: {
    color: luxColors.alucioPurple.primary,
    fontSize: 24,
    fontWeight: 'bold',
    paddingBottom: 20,
    paddingTop: 20,
  },
});

const VALID_STATES = ['forgotPassword'];
const USER_NOT_FOUND = 'UserNotFoundException';
const INVALID_CODE = 'CodeMismatchException';

interface Props extends IAuthPieceProps {
  isPasswordValid: boolean;
  onPasswordChange: (pswd: string) => void,
  onRepeatedPasswordChange: (pswd: string) => void,
  password: string;
  passwordValidations: ValidationType;
  repeatedPassword: string;
  setDisplayLoginButton: (show: boolean) => void;
}

interface EnterUsernameProps {
  setUsername: (username: string) => void;
}

const ForgotPassword = (props: Props) => {
  const {
    isPasswordValid,
    passwordValidations,
    onPasswordChange,
    onRepeatedPasswordChange,
    password,
    repeatedPassword,
  } = props;
  const [username, setUsername] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [unexpectedError, setUnexpectedError] = useState<boolean>(false);
  const [invalidCode, setInvalidCode] = useState<boolean>(false);
  const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
  const [emailResent, setEmailResent] = useState<boolean>(false);
  const isSubmitDisabled = !isPasswordValid || disabled;

  function goToLogin(): void {
    logger.auth.signIn.forgotPassword.info('Navigating to login');
    props.onStateChange?.('signIn');
  }

  async function onSendCode(): Promise<void> {
    setDisabled(true);
    logger.auth.signIn.forgotPassword.info('Sending password reset code');
    try {
      await Auth.forgotPassword(username?.toLocaleLowerCase());
      setUnexpectedError(false);
      setEmailResent(true);
      setCode('');
      onPasswordChange('')
      onRepeatedPasswordChange('')
    } catch (e) {
      logger.auth.signIn.forgotPassword.error('Unexpected error happened within onSendCode function', e);
      setUnexpectedError(true);
      setInvalidCode(false);
    } finally {
      setDisabled(false);
    }
  }

  function onCodeChange(value: string): void {
    setCode(value.trim());
    logger.auth.signIn.forgotPassword.info('Code input changed');
  }

  async function changePassword(): Promise<void> {
    setDisabled(true);
    logger.auth.signIn.forgotPassword.info('Changing password');
    try {
      await Auth.forgotPasswordSubmit(username?.toLocaleLowerCase(), code, password);
      setPasswordChanged(true);

      analytics?.track('LOGIN_RESET_PASSWORD_SUCCESS', {
        action: 'RESET_PASSWORD_SUCCESS',
        category: 'LOGIN',
      });

      props.setDisplayLoginButton(false);
    } catch (e: any) {
      if (e.code === INVALID_CODE) {
        setInvalidCode(true);
        setUnexpectedError(false);
        logger.auth.signIn.forgotPassword.warn('Invalid verification code provided');
      } else {
        logger.auth.signIn.forgotPassword.error('Unexpected error happened within changePassword function', e);
        setInvalidCode(false);
        setUnexpectedError(true);
      }
      setDisabled(false);
    }
  }

  if (!username) {
    return <EnterUsername setUsername={(username) => setUsername(username.toLocaleLowerCase())} />;
  }

  if (passwordChanged) {
    return <SuccessfulChangedPassword goToLogin={goToLogin} />;
  }

  return (
    <View style={styles.mainWrapper}>
      <Iffy is={!emailResent}>
        <Text
          testID="confirmation-label"
          style={styles.emailSentText}
        >
          Email sent! Please enter your code below to reset password.
        </Text>
      </Iffy>
      <Iffy is={emailResent}>
        <Text style={styles.emailSentText}>Email resent! Please enter your new code below.</Text>
      </Iffy>
      <PasswordCriterias passwordValidations={passwordValidations} />
      <View style={styles.errorWrapper}>
        <Iffy is={invalidCode}>
          <Text style={styles.invalidMessage}>Invalid verification code provided, please try again.</Text>
        </Iffy>
        <Iffy is={unexpectedError}>
          <Text style={styles.invalidMessage}>Something unexpected happened.</Text>
          <Text style={styles.invalidMessage}>Please try again or contact support@alucio.io</Text>
        </Iffy>
      </View>
      <View style={styles.marginTop}>
        <View style={styles.resendWrapper}>
          <TouchableOpacity onPress={onSendCode} style={{ position: 'absolute' }}>
            <Text style={styles.resendCode}>Resend code</Text>
          </TouchableOpacity>
        </View>
        <InputComponent
          disabled={disabled}
          removeMarginPadding={true}
          required={false}
          title="CODE"
          placeHolder="Code"
          titleColor={luxColors.text.primary}
          value={code}
          onChangeText={onCodeChange}
        />
      </View>
      <View style={styles.marginTop}>
        <InputComponent
          disabled={disabled}
          isPassword={true}
          removeMarginPadding={true}
          required={false}
          title="PASSWORD"
          placeHolder="Password"
          titleColor={luxColors.text.primary}
          value={password}
          onChangeText={onPasswordChange}
        />
      </View>
      <View style={styles.marginTop}>
        <InputComponent
          disabled={disabled}
          isPassword={true}
          removeMarginPadding={true}
          required={false}
          title="CONFIRM PASSWORD"
          placeHolder="Confirm Password"
          titleColor={luxColors.text.primary}
          value={repeatedPassword}
          onChangeText={onRepeatedPasswordChange}
        />
      </View>
      <Button.Kitten
        disabled={isSubmitDisabled}
        style={[styles.changePasswordButton, isSubmitDisabled && styles.disabledButton]}
        onPress={changePassword}
      >
        <Text style={[isSubmitDisabled && styles.disabledTextColor]}>Set password</Text>
      </Button.Kitten>
    </View>
  );
};

function EnterUsername(props: EnterUsernameProps): ReactElement {
  const [username, setUsername] = useState<string>('');
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
  const [unexpectedError, setUnexpectedError] = useState<boolean>(false);
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false);
  const isSubmitDisabled = disabledSubmit || username.length === 0;

  const { SSOUserValidation, isSSoUser, isValidating } = useSSO();

  async function onUsernameChange(email: string): Promise<void> {
    setUsername(email?.toLocaleLowerCase());
    logger.auth.signIn.forgotPassword.info('Username input changed');
  }

  async function onSendCode(): Promise<void> {
    setInvalidEmail(false)
    setUnexpectedError(false)
    logger.auth.signIn.forgotPassword.info('Sending password reset code');
    const result = await SSOUserValidation(username?.toLocaleLowerCase(), false);
    if (result.isSSO) return;

    analytics?.track('LOGIN_RESET_PASSWORD_REQUEST', {
      action: 'RESET_PASSWORD_REQUEST',
      category: 'LOGIN',
    });

    setDisabledSubmit(true);
    try {
      await Auth.forgotPassword(username.trim());
      props.setUsername(username?.toLocaleLowerCase()?.trim());
    } catch (e: any) {
      if (e.code === USER_NOT_FOUND) {
        setInvalidEmail(true);
        setUnexpectedError(false);
        logger.auth.signIn.forgotPassword.warn('User not found');
      } else {
        logger.auth.signIn.forgotPassword.error('Unexpected error happened within onSendCode function', e);
        setInvalidEmail(false);
        setUnexpectedError(true);
      }
      setDisabledSubmit(false);
    }
  }

  return (
    <View style={[styles.mainWrapper, { marginBottom: 20 }]}>
      <Text style={styles.enterEmailText}>Enter the email address associated with your account.</Text>
      <View style={styles.marginTop}>
        <InputComponent
          testID="email-login"
          removeMarginPadding={true}
          required={false}
          title="EMAIL"
          placeHolder="Email"
          titleColor={luxColors.text.primary}
          onSubmitEditing={isSubmitDisabled ? () => null : onSendCode}
          onChangeText={onUsernameChange}
        />
      </View>
      <View style={[styles.errorWrapper, { marginBottom: isSSoUser || unexpectedError ? 20 : 0 }, { margin: 0 }]}>
        <Iffy is={invalidEmail}>
          <Text style={styles.invalidMessage}>Invalid email.</Text>
        </Iffy>
        <Iffy is={unexpectedError}>
          <Text style={styles.invalidMessage}>Something unexpected happened.</Text>
          <Text style={styles.invalidMessage}>Please try again or contact support@alucio.io</Text>
        </Iffy>
        <Iffy is={isSSoUser}>
          <Text style={styles.invalidMessage}>
            Please contact your organization system’s administrator to change your password.
          </Text>
        </Iffy>
      </View>
      <Button.Kitten
        disabled={isSubmitDisabled || (isSSoUser || isValidating)}
        style={[styles.sendCodeButton, (isSubmitDisabled || (isSSoUser || isValidating)) && styles.disabledButton]}
        onPress={onSendCode}
        testID="send-code"
      >
        <Text style={[isSubmitDisabled && styles.disabledTextColor]}>Send code</Text>
      </Button.Kitten>
    </View>
  );
}

function SuccessfulChangedPassword(props): ReactElement {
  logger.auth.signIn.forgotPassword.info('Password successfully changed');
  return (
    <View style={[styles.mainWrapper, { marginBottom: 20 }]}>
      <Text style={styles.passwordUpdatedText}>Your password was successfully updated!</Text>
      <Button.Kitten style={styles.sendCodeButton} onPress={props.goToLogin}>
        Log in
      </Button.Kitten>
    </View>
  );
}

const ForgotPasswordWrapper = (props) => {
  const [displayLoginButton, setDisplayLoginButton] = useState<boolean>(true);

  if (!VALID_STATES.includes(props.authState)) {
    return null;
  }

  return (
    <FormWrapper title="FORGOT PASSWORD?" showReturnToLogin={displayLoginButton} {...props}>
      <ForgotPassword {...props} setDisplayLoginButton={setDisplayLoginButton} />
    </FormWrapper>
  );
};

ForgotPasswordWrapper.displayName = 'ForgotPassword';

export default withPasswordChecker(ForgotPasswordWrapper);
