export {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
  matchPath,
} from 'react-router-dom';

export type { match } from 'react-router-dom'
