import React from 'react'
import { FolderORM } from 'src/types/types'

import {
  DNAButton,
  DNACard,
  DNABox,
  DNAText,
  DNACheckbox,
  Iffy,
} from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import DNAFolder from 'src/components/DNA/Folder/DNAFolder'
import DNAFolderPinButton from 'src/components/DNA/Folder/DNAFolderPinButton'

type DNANestedFolderRowProps = {
  folderORM: FolderORM,
  isChecked?: boolean,
  isCheckedDisabled?: boolean,
  disabledMessage?: string,
  onExpand?: () => void,
  isExpanded?: boolean,
  onPress?: () => void
}

const DNANestedFolderRow: React.FC<DNANestedFolderRowProps> = ({
  folderORM,
  isChecked,
  isExpanded,
  disabledMessage,
  isCheckedDisabled,
  onPress,
  onExpand,
}) => {
  const handleItemSelection = () => !isCheckedDisabled && onPress?.()

  const showPopover = disabledMessage && isCheckedDisabled;
  const checkbox = (
    <DNACheckbox
      checked={isChecked}
      disabled={isCheckedDisabled}
      onChange={handleItemSelection}
      testID="add-to-folder-checkbox"
    />
  )

  return (
    <DNACard
      appearance="outline"
      interactive={!isCheckedDisabled ? 'pressable' : undefined}
      onPress={handleItemSelection}
      style={{ padding: 12 }}
    >
      <DNABox testID="add-folder-container" fill childFill={0} alignY="center" spacing="between">
        <DNABox fill childFill={3} alignY="center" spacing="md">
          {/* Expand Chevron */}
          <Iffy is={folderORM.meta.folderCount}>
            <DNAButton
              appearance="ghostLink"
              padding="none"
              iconLeft={isExpanded ? 'chevron-up' : 'chevron-right'}
              onPress={onExpand}
              style={{ width: 22, height: 25 }}
            />
          </Iffy>
          {/* Chevron Placeholder */}
          <Iffy is={!folderORM.meta.folderCount}>
            <DNABox style={{ width: 22, height: 25 }} />
          </Iffy>

          {/* Folder Info */}
          <DNAFolder mode={isChecked ? 'SELECTED' : 'ADD_TO_FOLDER' } folder={folderORM} />
          <DNAText testID="add-to-folders-title-text" numberOfLines={2} style={{ flex: 1 }}>
            {folderORM.model.name}
          </DNAText>
        </DNABox>

        {/* Right side controls */}
        <DNABox alignY="center" spacing="md">
          <DNAFolderPinButton
            isHidden={!folderORM.model.isPinned || !!folderORM.meta.isSharedFolder}
          />
          {
            showPopover
              ? <DNAPopover placement="top">
                <DNAPopover.Anchor>
                  {checkbox}
                </DNAPopover.Anchor>
                <DNAPopover.Content>
                  <DNAText status="basic">{disabledMessage}</DNAText>
                </DNAPopover.Content>
              </DNAPopover> : checkbox
          }
        </DNABox>
      </DNABox>
    </DNACard>
  )
}

export default DNANestedFolderRow
