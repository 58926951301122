import React, { useEffect, useMemo, useState } from 'react'
import { DNASelect } from '@alucio/lux-ui'
import { IndexPath } from '@ui-kitten/components'

import {
  Attendee,
  CrmIntegrationType,
  CustomFieldDefinition,
  CustomFieldValueDefinition,
} from '@alucio/aws-beacon-amplify/src/models'
import { Singleton as IndexDbCrm } from 'src/classes/CRM/CRMIndexedDB'
import { CRMAccount } from 'src/classes/CRM/CRMIndexedDBTypes'
import { useComposableForm } from 'src/components/CustomFields/ComposableForm'
import { IFieldComponentProps, ReadOnly, useFilterVaDef } from 'src/components/CustomFields/ComposableField'
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import type { DNASelectProps } from '@alucio/lux-ui/src/components/controls/DNASelect/DNASelect'

/*
    This hook takes a custom is a helper to filter out the custom value definition for CRMAttendeeList
    based on the attendees in the form. It returns a custom field definition with only the attendees
    that are in the form, the main goal of this hook is to get the accounts anf filter by PersonContactId
    this is due the CRMAttendeeList uses PersonContactId instead of CRMAccountId this is because at the end
    when it is saving the form it will use the PersonContactId to save the attendees.
*/
const useAttendeeToCustomField = (field: CustomFieldDefinition) => {
  const [customField, setCustomField] = useState<CustomFieldDefinition>({
    ...field,
    fieldValueDefinitions: [],
  }) // Initializing a state variable with an empty array of fieldValueDefinitions
  const { rhForm } = useComposableForm() // Destructuring a value from a custom hook
  const { crmIntegrationType } = useCRMStatus();

  const attendees = useMemo(() => {
    const primaryAttendees = (rhForm.getValues('primaryAttendee') || []) as unknown as Attendee[] // Getting primary attendees from a form
    const additionalAttendees = (rhForm.getValues('additionalAttendees') || []) as unknown as Attendee[] // Getting additional attendees from a form
    const attendees = [...primaryAttendees, ...additionalAttendees].map(attendee => attendee.crmAccountId) // Combining primary and additional attendees and mapping to get only the crmAccountId
    return attendees
  }, [rhForm]) // Memoizing the attendees array

  useEffect(() => {
    const fetchAccounts = async () => {
      if (!attendees) return; // If there are no attendees, return
      let accounts = await Promise.all(
        attendees
          .filter((attendee) => attendee) // Filtering out any falsy values
          .map((attendee) => IndexDbCrm.getById<CRMAccount>('ACCOUNT', attendee!)), // Getting accounts by attendee id
      );

      if (crmIntegrationType === CrmIntegrationType.VEEVA) {
        accounts = accounts.filter((account) => account?.IsPersonAccount);
      }

      setCustomField({
        ...field,
        fieldValueDefinitions: accounts.map((account) => ({
          id: account.PersonContactId as string,
          value: account.PersonContactId as string,
          label: account.Name as string,
          createdAt: new Date().toISOString(),
          dependentValueIds: [],
        })),
      });
    };
    fetchAccounts(); // Fetching accounts
  }, [attendees]); // Running the effect whenever the attendees array changes

  return customField // Returning the updated custom field definition
}

export const CRMAttendeeList : React.FC<
  IFieldComponentProps<string | undefined> & { status: DNASelectProps['status'] }
> = (props) => {
  const { onChange, value: valueId, field, disabled, dependencyValues, status, isReadOnly } = props
  const CustomField = useAttendeeToCustomField(field)
  const { activeFieldValueDefs, selectedFieldValueIdx } = useFilterVaDef(CustomField, valueId, dependencyValues)

  // [NOTE] - This value can be an active or non-active fields
  const selectedFieldValue: CustomFieldValueDefinition | undefined = activeFieldValueDefs[selectedFieldValueIdx]
  const selectedIdxPath = new IndexPath(selectedFieldValueIdx)

  const handleSelect = (index: IndexPath | IndexPath[]): void => {
    const idx = index as IndexPath
    const targetFieldValDefId = activeFieldValueDefs[idx.row].id
    onChange(targetFieldValDefId)
  }

  const val = selectedFieldValueIdx === -1
    ? undefined
    : valueId
      ? selectedIdxPath : undefined

  if (isReadOnly) {
    return (<ReadOnly {...props} value={selectedFieldValue?.label} />)
  }

  return (
    <DNASelect
      selectedIndex={val}
      onSelect={handleSelect}
      value={selectedFieldValue?.label || selectedFieldValue?.value}
      disabled={disabled}
      placeholder={'Select...'}
      status={status}
    >
      {
          activeFieldValueDefs
            .map(fieldValueDef => (
              <DNASelect.Item
                disabled={fieldValueDef.disabled || disabled}
                key={fieldValueDef.id}
                title={fieldValueDef.label ?? fieldValueDef.value}
              />
            ))
        }
    </DNASelect>
  )
}
