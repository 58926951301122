import { PlayerActions } from '@alucio/core';
import { useState, useEffect } from 'react';
import { useDispatch } from 'src/state/redux';
import { presentationControlActions } from 'src/state/redux/slice/PresentationControl/presentationControl';
import { PageExtended, DocumentVersionORM } from 'src/types/types';

export interface PageData {
  content?: string,
  number: number,
  labelNumber?: number,
  speakerNotes?: string,
}

export interface MatchedPage {
  pageNumber: number,
  labelNumber: number,
  matches: string[]
}
/**
 * Function for searching text for PageData[]
 * @param data PageData[]
 * @param searchQuery the text we are searching for
 * @returns array of array of strings
 */

const textSearch = (data: PageData[], searchQuery: string) => {
  const totalMatches: MatchedPage[] = [];
  data
    .sort((a, b) => a.number - b.number)
    .map((page, index) => ({ ...page, labelNumber: index + 1 }))
    .forEach((page: PageData) => {
      if (!page.content) {
        return;
      }

      const indices: number[] = [];
      const textWithoutLineBreaks = page.content.replace(/(?:\r\n|\r|\n)/g, ' ');
      const text = textWithoutLineBreaks.toLowerCase();
      const query = searchQuery.toLowerCase();
      const matches: string[] = [];
      let index = text.indexOf(query);
      while (index !== -1) {
        indices.push(index);
        index = text.indexOf(query, index + 1);
      }

      indices.forEach((i) => {
        matches.push(getTextBlurb(textWithoutLineBreaks, query, i));
      });

      if (matches.length) {
        totalMatches.push({
          pageNumber: page.number,
          labelNumber: page.labelNumber!,
          matches,
        });
      }
    });

  return totalMatches;
}

const getTextBlurb = (text: string, str: string, index: number) => {
  const length = str.length;
  const start = Math.max(0, index - 30);
  const end = Math.min(text.length, index + length + 30);
  return `${start > 0 ? '...' : ''}` + text.substring(start, end) + `${end < text.length ? '...' : ''}`;
}

const useFileTextSearch = (
  data: PageData[],
  visiblePages: PageExtended[],
  docVersionORM: DocumentVersionORM,
  setActiveSlide: (slide:number) => void,
) => {
  const [matches, setMatches] = useState<MatchedPage[]>([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [searchQuery, search] = useState('');
  const [totalMatchCount, setTotalMatchCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.length && searchQuery.length >= 3) {
      const filteredData = data.filter((page) => visiblePages.some((v) => v.number === page?.number))
      const matches = textSearch(visiblePages.length ? filteredData : data, searchQuery);
      matches.sort((a, b) => a.pageNumber - b.pageNumber);
      setMatches(matches);
      setActiveIndex(-1);
    }
    else {
      setMatches([]);
    }
  }, [searchQuery, data]);

  useEffect(() => {
    let count = 0;
    matches.forEach((page) => {
      count += page.matches.length
    });
    setTotalMatchCount(count);
  }, [matches]);

  useEffect(() => {
    if (activeIndex > -1 && matches.length) {
      const activePage = matches[activeIndex];
      if (activePage) {
        analytics.track('DOCUMENT_SEARCH_SELECT', {
          action: 'SEARCH_SELECT',
          category: 'DOCUMENT',
          pageNumber: activePage.pageNumber,
          searchTerm: searchQuery,
          documentId: docVersionORM.relations.documentORM.model.id,
          documentVersionId: docVersionORM.model.id,
        });
        setActiveSlide(activePage.labelNumber);
        dispatch(presentationControlActions.triggerPresentationAction({
          action: PlayerActions.gotoSlide,
          param: activePage.pageNumber,
        }))
      }
    }
  }, [activeIndex, matches])

  return {
    matches,
    activeIndex,
    searchQuery,
    totalMatchCount,
    search,
    setActiveIndex,
  }
}

export default useFileTextSearch;
