import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAText, Iffy, TextField } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useMeetingPresentedMeta } from '../../State/MeetingPresentedMetaProvider';
import { SidebarOptions, tabConfigs } from 'src/components/ContentPreviewModalV2/SideBar/SideBar';
import SidebarContent from 'src/components/ContentPreviewModalV2/SideBar/SideBarContent';

const styles = StyleSheet.create({
  noteWrapper: {
    height: '100%',
    paddingHorizontal: 8,
  },
  notesInput: {
    borderColor: colors['color-gray-100'],
    borderRadius: 4,
    borderWidth: 1,
    color: colors['color-gray-800'],
    height: '100%',
    outlineWidth: 0,
    paddingHorizontal: 8,
    paddingVertical: 7,
    width: '100%',
  },
});

const MeetingNotes: React.FC = () => {
  const { currentPresentedMeta, setNotes, isPresentedSlideEmpty } = useMeetingPresentedMeta();
  const [myNotes, setMyNotes] = useState<string>(currentPresentedMeta?.note || '');
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    setMyNotes(isPresentedSlideEmpty ? '' : currentPresentedMeta?.note || '');
  }, [currentPresentedMeta, isPresentedSlideEmpty]);

  const handleOnNotesChange = (notes: string): void => {
    setMyNotes(notes);
  };

  const handleSaveNotes = (): void => {
    setNotes(myNotes);
    setIsEditing(false);
  };

  return (
    <DNABox appearance="col" fill style={styles.noteWrapper}>
      <Iffy is={!isEditing}>
        <DNABox appearance="col" spacing="md">
          <Iffy is={!isPresentedSlideEmpty}>
            <DNAButton
              status="tertiary"
              appearance="outline"
              size="sm"
              padding="sm"
              onPress={() => setIsEditing(true)}
            >
              Edit
            </DNAButton>
          </Iffy>
          <DNAText status={!myNotes ? 'flat' : 'dark'}>
            {myNotes || 'No comments'}
          </DNAText>
        </DNABox>
      </Iffy>
      <Iffy is={isEditing}>
        <DNAButton
          size="sm"
          padding="sm"
          onPress={handleSaveNotes}
          style={{ marginBottom: 20 }}
        >
          Done
        </DNAButton>
        <DNABox fill>
          <TextField.Native
            autoFocus
            maxLength={1000}
            onBlur={handleSaveNotes}
            onChangeText={handleOnNotesChange}
            multiline
            value={myNotes}
            style={styles.notesInput}
          />
        </DNABox>

      </Iffy>
    </DNABox>
  );
};

const MeetingNotesWrapper: React.FC = () => {
  return (
    <SidebarContent>
      <SidebarContent.Header
        sidebarHeader={tabConfigs[SidebarOptions.meetingNotes].label}
      />
      <SidebarContent.Body>
        <MeetingNotes />
      </SidebarContent.Body>
    </SidebarContent>
  )
}

export default MeetingNotesWrapper;
