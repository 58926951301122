import React, { useEffect, useState } from 'react'

import {
  DNABox,
  DNAText,
  DNADivider,
  TabBar,
  Tab,
  luxColors,
} from '@alucio/lux-ui'
import { Text } from '../FAQ/FAQ.components'
import { StyleSheet, Pressable } from 'react-native'
import ReactMarkdown from 'react-markdown';
import { useHistory } from 'react-router';
import { List, ListItem } from 'src/screens/FAQ/FAQ.components'

const sourceFileURL = require('../../../assets/html/Notifications.md')

const S = StyleSheet.create({
  container: {
    maxWidth: 1440,
    paddingHorizontal: 48,
    paddingBottom: 40,
    paddingVertical: 20,
    backgroundColor: 'white',
  },
  icon: {
    width: 8,
    height: 8,
    color: luxColors.basicBlack.primary,
  },
  content: {
    maxWidth: 1440,
    backgroundColor: 'white',
  },
  // Release Date Header
  contentEmphasis: {
    paddingBottom: 10,
  },
  // Release Description Text
  contentBlockQuote: {
    paddingBottom: 10,
  },
  // Changes List Header
  contentStrong: {
    paddingTop: 10,
    paddingBottom: 3,
  },
  contentH4: {
    paddingTop: 16
  }
})

const renderers = {
  text: Text,
  em: (props) => (
    <DNAText h3>
      { props.children }
    </DNAText>
  ),
  p: (props) => (
    <DNABox>
      { props.children}
    </DNABox>
  ),
  ul: (props) => <List {...props} minimizePadding />,
  li: ListItem,
  blockquote: (props) => (
    <DNAText style={S.contentBlockQuote}>
      {props.children}
    </DNAText>
  ),
  strong: (props) => (
    <DNAText b2 bold style={S.contentStrong}>
      { props.children }
    </DNAText>
  ),
  h4: (props) => (
    <DNAText h4 style={S.contentH4}>
      { props.children }
    </DNAText>
  )
}

export const DNANotifications: React.FC = () => {
  const history = useHistory();
  const [rawFile, setRawFile] = useState<string>()

  useEffect(() => {
    let ignore = false

    // We have to pass in the raw string for the input
    async function fetchRawInput() {
      const res = await fetch(sourceFileURL)
      const rawInput = await res.text()

      if (ignore) return;
      setRawFile(rawInput)
    }

    fetchRawInput()
    return () => { ignore = true }
  }, [])

  return (
    <DNABox alignX="center">
      <DNABox
        fill
        style={S.container}
      >
        <DNABox fill spacing="md" appearance="col">
          {/* Header */}
          <Pressable onPress={() => () => history.push('/notifications')}>
            <DNABox alignY="center" spacing="between">
              <DNAText testID="page-title" h5 status="secondary">Notifications</DNAText>
            </DNABox>
          </Pressable>
          <DNADivider />
          {/* Content */}
          <DNABox style={S.content} appearance="col" spacing="md">
            <DNABox style={{ width: 178 }}>
              <TabBar
                selectedIndex={0}
                style={{ height: 56 }}
              >
                <Tab title="What’s new with Beacon" />
              </TabBar>
            </DNABox>
            <DNABox appearance="col" fill >
              <ReactMarkdown
                sourcePos
                includeElementIndex
                components={renderers}
              >
                {rawFile!}
              </ReactMarkdown>
            </DNABox>
          </DNABox>

        </DNABox>
      </DNABox>
    </DNABox>
  );
}

export default DNANotifications
