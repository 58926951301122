import React, { useEffect, useRef, useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import {
  DNABox,
  DNAButton,
  DNAText,
  DNADivider,
  DNAContextMenu,
  Iffy,
  InformationMessage,
} from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { Notation } from '@alucio/aws-beacon-amplify/src/models';
import { useSharedFilesState } from 'src/screens/Hubs/EditHub/Widgets/SharedFilesComponents/SharedFilesProvider.proxy';
import { NotationsProvider, useNotationsState } from './NotationsProvider';
import InputComponent from 'src/components/Publishers/InputComponent';
import { SidebarOptions, tabConfigs } from 'src/components/ContentPreviewModalV2/SideBar/SideBar';
import SidebarContent from 'src/components/ContentPreviewModalV2/SideBar/SideBarContent';

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
  contextBtn: {
    marginLeft: 10,
  },
  createCalloutBtnContainer: {
    marginBottom: 20,
  },
  emptyStateContainer: {
    paddingHorizontal: 50,
  },
  emptyStateText: {
    textAlign: 'center',
  },
  informationMessage: {
    margin: 0,
  },
  activeNotationContainer: {
    backgroundColor: colors['color-gray-20'],
  },
  notationCard: {
    marginTop: 16,
  },
  notationPageNum: {
    marginBottom: 16,
  },
});

type ReadOnlyNotationRowProps = {
  notation: Notation
  index: number
}

type EditModeNotationRowProps = {
  notation: Notation
}

type NotationPageGroupProps = {
  visibleNotationMappedByIndex: Record<number, Notation[]>
  pageNum: string
  editingId?: string
  currentBrowsingPage?: string
}

const ReadOnlyNotationRow: React.FC<ReadOnlyNotationRowProps> = ({ notation, index }) => {
  const {
    notationsMachineState,
    setCurrentActiveNotationId,
    editDescription,
    deleteNotation,
  } = useNotationsState()
  const editingId = notationsMachineState.matches('editDescription')
    ? notationsMachineState.context.editingNotationId
    : undefined
  const hasPermissionToEditDescription = notationsMachineState.context.hasPermissionToEditDescription
  const isCurrentActiveNotation: boolean = notationsMachineState.context.currentActiveNotationId === notation.id
  const notationRowRef = useRef<null | HTMLDivElement>(null)
  useEffect(() => {
    // [TODO]: BEAC-5263 Refactor to use FlatList for the component render and will be able to use scrollToIndex()
    const sideBarScrollView = document.querySelector("[data-testid='content-preview-modal-side-bar-scroll-view']")
    if (isCurrentActiveNotation) {
      (notationRowRef.current as any)?.measure(
        (fx, fy, w, h, px, py) => {
          const offSet = (sideBarScrollView?.scrollTop ?? 0) - 74
          sideBarScrollView?.scroll({
            top: py + offSet,
            behavior: 'smooth',
          })
        },
      )
    }
  }, [isCurrentActiveNotation])

  return (
    <Pressable onPress={() => setCurrentActiveNotationId(notation.id)}>
      <DNABox
        ref={notationRowRef}
        fill
        appearance="col"
        spacing="md"
        style={isCurrentActiveNotation && styles.activeNotationContainer}
      >
        <DNABox fill childFill={0} spacing="between" style={styles.notationCard}>
          <DNABox fill alignY="center">
            <DNAText style={styles.fullWidth} numberOfLines={isCurrentActiveNotation ? undefined : 5}>
              {notation.description || `Callout ${index + 1}`}
            </DNAText>
          </DNABox>
          <DNAContextMenu placement="bottom end">
            <DNAContextMenu.Anchor>
              <DNAButton
                iconRight="dots-vertical"
                appearance="ghostLink"
                size="md"
                status="gray"
                padding="none"
                style={styles.contextBtn}
                disabled={!!editingId}
              />
            </DNAContextMenu.Anchor>
            <DNAContextMenu.Items>
              {
                hasPermissionToEditDescription && <DNAContextMenu.Item
                  icon="pencil"
                  title="Edit"
                  onPress={() => editDescription(notation.id)}
                />
              }
              <DNAContextMenu.Item
                icon="trash-can-outline"
                title="Delete"
                iconStatus="danger"
                status="danger"
                onPress={() => deleteNotation(notation.id)}
              />
            </DNAContextMenu.Items>
          </DNAContextMenu>
        </DNABox>
        <DNADivider/>
      </DNABox>
    </Pressable>
  )
}

const EditModeNotationRow: React.FC<EditModeNotationRowProps> = ({ notation }) => {
  const { cancelEdit, updateDescription } = useNotationsState()
  const [description, setDescription] = useState<string>(notation.description ?? '')
  const notationRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    notationRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }, [])

  return (
    <DNABox ref={notationRef} fill appearance="col" spacing="sm">
      <InputComponent
        onChangeText={setDescription}
        value={description}
        removeMarginPadding
        multiline
        showCharacterCounter={false}
        required={false}
        numOfLines={7}
        autoFocus
      />
      <DNABox fill childFill spacing="sm">
        <DNAButton
          appearance="outline"
          status="tertiary"
          style={styles.fullWidth}
          onPress={() => cancelEdit(notation.id)}
        >
          Cancel
        </DNAButton>
        <DNAButton
          style={styles.fullWidth}
          onPress={() => updateDescription(description)}
        >
          Save
        </DNAButton>
      </DNABox>
      <DNADivider/>
    </DNABox>
  )
}

const NotationPageGroup: React.FC<NotationPageGroupProps> = ({
  visibleNotationMappedByIndex,
  pageNum,
  editingId,
  currentBrowsingPage,
}) => {
  const { notationsMachineState } = useNotationsState()
  const pageGroupRef = useRef<null | HTMLDivElement>(null)
  const currentActiveNotationId = notationsMachineState.context.currentActiveNotationId

  useEffect(() => {
    if (currentBrowsingPage === pageNum && !currentActiveNotationId) {
      /*  There is an open issue about scrollIntoView not working in chrome sometimes
          https://github.com/facebook/react/issues/23396
          The work around will be to remove smooth behavior or use scroll instead (which is this case) */
      // [TODO]: BEAC-5263 Refactor to use FlatList for the component render and will be able to use scrollToIndex()
      const sideBarScrollView = document.querySelector("[data-testid='content-preview-modal-side-bar-scroll-view']")
      if (pageNum === '1') {
        sideBarScrollView?.scroll({
          top: 0,
          behavior: 'smooth',
        })
      } else {
        (pageGroupRef.current as any)?.measure(
          (fx, fy, w, h, px, py) => {
            const offSet = (sideBarScrollView?.scrollTop ?? 0) - 74
            sideBarScrollView?.scroll({
              top: py + offSet,
              behavior: 'smooth',
            })
          },
        )
      }
    }
  }, [currentBrowsingPage])

  return (
    <DNABox ref={pageGroupRef} fill appearance="col">
      <DNAText bold style={styles.notationPageNum}>{`Slide ${pageNum}`}</DNAText>
      {
        visibleNotationMappedByIndex[pageNum].map((notation, index) => {
          if (editingId === notation.id) {
            return <EditModeNotationRow key={notation.id} notation={notation}/>
          }
          else return <ReadOnlyNotationRow key={notation.id} notation={notation} index={index}/>
        })
      }
    </DNABox>
  )
}

const Notations: React.FC = () => {
  const {
    createNotation,
    notationsMachineState,
    visibleNotationMappedByIndex,
    editingId,
    presentableState,
    currentBrowsingPage,
  } = useNotationsState()
  const canCreateCallout = notationsMachineState.can('ENTER_SET_COORDINATE_MODE') && presentableState
  const isSetCoordinateMode = notationsMachineState.matches('setCoordinateMode')
  const visibleNotationPageNumArray = Object.keys(visibleNotationMappedByIndex)

  return (
    <DNABox appearance="col" fill>
      <DNABox appearance="col" spacing="md" style={styles.createCalloutBtnContainer}>
        <DNAButton
          iconLeft="plus"
          style={styles.fullWidth}
          onPress={createNotation}
          disabled={!canCreateCallout}
        >
          Create callout
        </DNAButton>
        <Iffy is={isSetCoordinateMode}>
          <InformationMessage
            text="Click anywhere on the slide to create a new callout"
            customWrapperStyle={styles.informationMessage}
          />
        </Iffy>
        <DNADivider/>
      </DNABox>
      <DNABox fill appearance="col">
        { visibleNotationPageNumArray.length
          ? (
            <DNABox fill appearance="col" spacing="md">
              {visibleNotationPageNumArray.map(pageNum => {
                if (!visibleNotationMappedByIndex[pageNum].length) return null
                return (
                  <NotationPageGroup
                    key={pageNum}
                    visibleNotationMappedByIndex={visibleNotationMappedByIndex}
                    pageNum={pageNum}
                    editingId={editingId}
                    currentBrowsingPage={currentBrowsingPage}
                  />)
              })}
            </DNABox>
          ) : (
            <DNABox fill style={styles.emptyStateContainer} alignY="center" alignX="center">
              <DNAText status="flatAlt" numberOfLines={2} style={styles.emptyStateText}>
                Create callouts to see them appear here
              </DNAText>
            </DNABox>
          )
        }
      </DNABox>
    </DNABox>
  )
}

const NotationsWrapper: React.FC = () => {
  const sharedFilesState = useSharedFilesState()

  if (!sharedFilesState) return null
  return (
    <NotationsProvider sharedFilesState={sharedFilesState}>
      <SidebarContent>
        <SidebarContent.Header
          sidebarHeader={tabConfigs[SidebarOptions.callouts].label}
        />
        <SidebarContent.Body>
          <Notations />
        </SidebarContent.Body>
      </SidebarContent>
    </NotationsProvider>
  )
}

export default NotationsWrapper
