import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, Hoverable, Iffy, util } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { useInPersonActionBarSharedComponents } from './useInPersonActionBarSharedComponents';
import { ActionBarState } from '../../SharedComponents/PresentationMenu';

const S = StyleSheet.create({
  actionsContainer: {
    backgroundColor: colors['color-black'],
    padding: 8,
    borderTopLeftRadius: 4,
  },
  actionsContainerV2: {
    backgroundColor: colors['color-black'],
    paddingHorizontal: 8,
    height: 40,
    borderTopWidth: 1,
    borderTopColor: colors['color-gray-400'],
  },
})

const DesktopVariant: React.FC = () => {
  const enableNew3PC = useFeatureFlags('enableNew3PC');
  const {
    presentationNav,
    myContentButton,
    presentationsButton,
    slidesButton,
    highlighterButton,
    highlighterActions,
    highlighterContextMenu,
    textSearchButton,
    reactionButtons,
    followUpButton,
    sentimentsContextMenu,
    myNotesButton,
    fullScreenButton,
    endMeetingButton,
    changeToVirtualModeButton,
    collapseButton,
    actionBarState,
  } = useInPersonActionBarSharedComponents();
  const [isHovered, setIsHovered] = useState<boolean>(true)
  const toggleHover = (isHovered?: boolean) => {
    setIsHovered(!!isHovered);
  }

  if (!enableNew3PC) {
    return (
      <DNABox spacing="sm" style={S.actionsContainer} alignY="center">
        <Iffy is={actionBarState === ActionBarState.PresentationMenu}>
          {presentationNav}
          {myContentButton}
          {presentationsButton}
          {slidesButton}
          {highlighterButton}
          {textSearchButton}
          {reactionButtons}
          {myNotesButton}
          {followUpButton}
          {fullScreenButton}
          {endMeetingButton}
          {changeToVirtualModeButton}
          {collapseButton}
        </Iffy>
        <Iffy is={actionBarState === ActionBarState.HighlighterMenu}>
          {highlighterActions}
        </Iffy>
        <Iffy is={actionBarState === ActionBarState.Collapsed}>
          {presentationNav}
          {collapseButton}
        </Iffy>
      </DNABox>
    )
  } else {
    return (
      <Hoverable onHoverIn={() => toggleHover(true)} onHoverOut={toggleHover}>
        <DNABox
          spacing="between"
          style={util.mergeStyles(
            undefined,
            S.actionsContainerV2,
            [{ opacity: 0.32 }, !isHovered],
          )}
          alignY="center"
        >
          {/* LEFT */}
          <DNABox spacing="sm">
            {myContentButton}
            {presentationsButton}
            {slidesButton}
          </DNABox>
          {/* MIDDLE */}
          <DNABox>
            {presentationNav}
          </DNABox>
          {/* RIGHT */}
          <DNABox spacing="sm">
            {highlighterContextMenu}
            {textSearchButton}
            {sentimentsContextMenu}
            {myNotesButton}
            {fullScreenButton}
            {endMeetingButton}
            {changeToVirtualModeButton}
          </DNABox>
        </DNABox>
      </Hoverable>
    )
  }
}

export default DesktopVariant
