import { useReducer } from 'react';

export enum ActionBarState {
  PresentationMenu = 'presentationMenu',
  HighlighterMenu = 'highlighterMenu',
  Collapsed = 'collapsed',
}

export type MenuActionBarReducerAction = {
    type: 'presentationState',
    payload: ActionBarState,
  }

export type MenuActionBarReducerState = {
    actionBarState: ActionBarState,
}

export const useMenuActionBar = () => {
  const [state, dispatch] = useReducer((state: MenuActionBarReducerState, action: MenuActionBarReducerAction)
  : MenuActionBarReducerState => {
    switch (action.type) {
      case 'presentationState':
        return {
          ...state,
          actionBarState: action.payload,
        };

      default:
        return state;
    }
  },
  {
    actionBarState: ActionBarState.PresentationMenu,
  });

  const setPresentationMenu = (state: ActionBarState) => {
    dispatch({ type: 'presentationState', payload: state });
  };

  const { actionBarState } = state;

  return {
    setPresentationMenu,
    actionBarState,
  };
}
