import React from 'react'
import DNACommonConfirmationModal from './DNACommonConfirmation'

export const PublishVersionModal: React.FC<{onConfirm: () => void}> = (props) => {
  const { onConfirm } = props

  return (
    <DNACommonConfirmationModal
      title="Publish document version?"
      descriptionText="This document version will be visible and accessible to your audience"
      confirmActionText="Publish"
      cancelText="Cancel"
      status="primary"
      onConfirmAction={onConfirm}
    />
  )
}

export const PublishDocumentModal: React.FC<{onConfirm: () => void}> = (props) => {
  const { onConfirm } = props

  return (
    <DNACommonConfirmationModal
      title="Publish document?"
      descriptionText="This document will be visible and accessible to your audience"
      confirmActionText="Publish"
      cancelText="Cancel"
      status="primary"
      onConfirmAction={onConfirm}
    />
  )
}

export const DiscardChangesModal: React.FC<{onConfirm: () => void}> = (props) => {
  const { onConfirm } = props

  return (
    <DNACommonConfirmationModal
      title="Unsaved changes"
      descriptionText="All changes will be lost."
      confirmActionText="Discard changes"
      cancelText="Continue editing"
      status="primary"
      onConfirmAction={onConfirm}
    />
  )
}

export const DeleteDraftModal: React.FC<{onConfirm: () => void}> = (props) => {
  const { onConfirm } = props

  return (
    <DNACommonConfirmationModal
      title="Delete draft?"
      descriptionText="This draft will be deleted. This action cannot be undone."
      confirmActionText="Delete draft"
      cancelText="Cancel"
      status="primary"
      onConfirmAction={onConfirm}
    />
  )
}

export const CancelSchedulePublishModal: React.FC<{onConfirm: () => void}> = (props) => {
  const { onConfirm } = props

  return (
    <DNACommonConfirmationModal
      title="Cancel scheduled publish?"
      confirmActionText="Cancel scheduled publish"
      cancelText="Cancel"
      status="danger"
      onConfirmAction={onConfirm}
    />
  )
}
