import { DNABox, DNAButton, DNAIcon, DNAText } from '@alucio/lux-ui'
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton'
import { colors } from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import React, { useState } from 'react'
import LottieAnimation from 'react-lottie-player'
import { Pressable, StyleSheet, ViewStyle } from 'react-native'

interface OptionProps {
  title: string
  subTitle: string
  icon: string
  animationData: object
  onSelect: () => void
  style?: ViewStyle
  disabled?: boolean
}

const styles = StyleSheet.create({
  mainContainer: {},
  headerStyle: {
    maxWidth:40,
    height:40,
    backgroundColor: colors['gray-80'],
    borderRadius: 4,
    alignSelf:'flex-start',
  },
  animationContainer: {
    borderColor: colors['gray-80'],
    padding: 16,
  },
})

export const Option:React.FC<OptionProps> = ({
  icon,
  animationData,
  title,
  subTitle,
  style,
  onSelect,
  disabled,
}) => {
  const [showAnimation, setShowAnimation] = useState<boolean>(false)
  const [playAnimation, setPlayAnimation] = useState<boolean>(true)
  const [animationFrame, setAnimationFrame] = useState<number>(0)
  const handleReplay = () => {
    /** TODO: There is likely a better way to restart an animation. This works for now. */
    setAnimationFrame(0)
    setPlayAnimation(false)
    setTimeout(() => {
      setPlayAnimation(true)
    }, 0);
  }

  const animationControlsProps:DNAButtonProps = {
    appearance:'outline',
    status:'tertiary',
    size:'md',
    padding: 'sm',
  }

  return (
    <DNABox
      pointerEvents={disabled ? 'none' : undefined}
      appearance="col"
      style={[
        style,
        {
          width:560,
          opacity: disabled ? 0.4 : undefined,
        },
      ]
  }
    >
      {/* Header */}
      <DNABox
        testID="individual-meeting-mode-container"
        fill
        style={{
          padding: 16,
        }}
        alignY="center"
      >
        <DNABox
          style={styles.headerStyle}
          alignX="center"
          alignY="center"
          fill
        >
          <DNAIcon.Styled
            appearance="ghost"
            status="tertiary"
            size="xl"
            name={icon}
          />
        </DNABox>
        <DNABox appearance="col" fill spacing="sm" style={{ paddingHorizontal:16 }}>
          <DNAText testID="meeting-mode-title" bold>{title}</DNAText>
          <DNAText
            status="flat"
            c1
          >{subTitle}</DNAText>
          <Pressable
            onPress={() => setShowAnimation(true)}
          >
            <DNAText c1 status="primary">See how it works</DNAText>
          </Pressable>
        </DNABox>
        <DNAButton
          status="primary"
          appearance="filled"
          size="md"
          padding="sm"
          rounded="md"
          onPress={onSelect}
          testID="present-start-button"
        >
          <DNAText status="basic">Start</DNAText>
        </DNAButton>
      </DNABox >

      {/* Animation */}
      {showAnimation &&
        <DNABox
          style={[styles.animationContainer, { borderTopWidth:showAnimation ? 1 : 0 }]}
        >

          <LottieAnimation
            play={playAnimation}
            loop={false}
            goTo={animationFrame}
            animationData={animationData}
          />
          <DNABox spacing="sm">
            <DNAButton
              {...animationControlsProps}
              iconLeft="replay"
              onPress={handleReplay}
            />
            <DNAButton
              {...animationControlsProps}
              iconLeft="close"
              onPress={() => setShowAnimation(false)}
            />
          </DNABox>
        </DNABox>}

    </DNABox>
  )
}
