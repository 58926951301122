import { DNABox, DNAButton, DNAModal, DNAText } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import React from 'react';
import { DNAModalVariant } from './DNAConnectedModal';

const DNAShareFolderWarning: DNAModalVariant<{}> = (props) => {
  const { closeModal } = props;
  return (
    <DNAModal style={{}}>
      <DNAModal.Header style={{ paddingVertical: 16, paddingHorizontal: 24 }}>
        <DNABox style={{ minWidth: 560 }}>
          <DNAText bold>Cannot share folder</DNAText>
        </DNABox>
      </DNAModal.Header>
      <DNAModal.Body style={{ padding : 24 }}>
        <DNAText style={{ color: colors['color-gray-300'] }}>
          Please remove all modified files from this folder before sharing
        </DNAText>
      </DNAModal.Body>
      <DNAModal.Confirmation style={{ paddingVertical: 12, paddingHorizontal: 24 }}>
        <DNAButton size="sm" onPress={closeModal} status="primary">
          Close
        </DNAButton>
      </DNAModal.Confirmation>
    </DNAModal>
  )
};

export default DNAShareFolderWarning;
