import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNADivider, DNASlider } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

import { usePresentationBuilderState } from '../../state/PresentationBuilderStateProvider';
import { PresentationBuilderStateProvider } from '../../state/PresentationBuilderStateProvider.proxy';
import FindReplacementProvider from './FindReplacementProvider';

import Header from './Header';
import SlideRoll from './SlideRoll';
import Content from './Content/Content';

const S = StyleSheet.create({
  findReplacementContainer: {
    backgroundColor: colors['color-text-white'],
  },
});

const FindReplacement: React.FC = () => {
  return (
    <DNABox fill appearance="col" style={S.findReplacementContainer}>
      <Header />
      <DNADivider />
      <SlideRoll />
      <DNADivider />
      <Content />
    </DNABox>
  )
}

const FindReplacementWrapper: React.FC = () => {
  const presentationBuilderState = usePresentationBuilderState()
  const {
    findReplacementVisible,
    setFindReplacementVisible,
  } = presentationBuilderState

  return (
    <DNASlider
      visible={findReplacementVisible}
      setVisible={setFindReplacementVisible}
    >
      <PresentationBuilderStateProvider value={presentationBuilderState}>
        <FindReplacementProvider>
          <FindReplacement />
        </FindReplacementProvider>
      </PresentationBuilderStateProvider>
    </DNASlider>
  )
}

export default FindReplacementWrapper
