import React, { useMemo, useCallback, useEffect, useState } from 'react'
import { View, Pressable, ViewStyle, Platform } from 'react-native'
import { Modal } from '@ui-kitten/components'
import throttle from 'lodash/throttle'
import {
  DNABox,
  Icon,
  Stack,
  Text,
  useMouseEvent,
  useInputConfig,
  DNAButton,
  useDisableSwipe,
  InformationMessage,
  Iffy,
} from '@alucio/lux-ui'

import ContentPreview from './ContentPreview'
import Header from './Header/Header';
import SlideRoll from './SlideRoll/SlideRoll';
import ActionBar from './ActionBar/ActionBar';
import ContentViewerModalStateProvider, { useContentViewerModalState } from './state/ContentViewerModalStateProvider';
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
import { downloadContentFromCloudfront } from '../../utils/loadCloudfrontAsset/loadCloudfrontAsset';
import { batch, useDispatch, useSelector } from 'react-redux';
import { FileType, FolderItemType, PurposeType } from '@alucio/aws-beacon-amplify/src/models';
import { RootState } from 'src/state/redux';
import { handleBookmark } from 'src/components/Library/Util/util';
import { useCurrentUser } from 'src/state/redux/selector/user';
import { PresentationPlayerProvider, PresentationContext, PRESENTATION_CONTEXT } from '@alucio/core';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import DNADocumentAddToFolder from 'src/components/DNA/Modal/DNADocumentAddToFolder'
import { DocumentVersionORM, DocumentORM, FolderItemORM, CustomDeckORM } from 'src/types/types'
import { isDocumentVersionORM, isCustomDeckORM } from 'src/types/typeguards'
import { styles } from './ContentPreviewModal.styles'
import GetInputConfig from './ContentPreviewModal.inputConfig'
import { presentationControlActions } from 'src/state/redux/slice/PresentationControl/presentationControl'
import { useAllDocumentVersionMap } from 'src/state/redux/selector/document'
import { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast'
import { useAppSettings } from 'src/state/context/AppSettings'
import { folderActions } from 'src/state/redux/slice/folder'

import SideBar from './SideBar/SideBar'
import DocumentVersionSelector from './DocumentVersionSelector'
import { useMediaQuery } from 'react-responsive'
import { useFolderItemORMById } from 'src/state/redux/selector/folder'
import { AUTO_UPDATE_DEFAULT_DATE, customDeckActions } from 'src/state/redux/slice/customDeck'
import TextSearchPanel from '../TextSearchPanel/TextSearchPanel'
import ActiveDocumentTabs from './ActiveDocumentTabs/ActiveDocumentTabs'
import MyContentPanel from '../MyContentPanel/MyContentPanel'
import { useBeforeunload } from 'react-beforeunload'
import useCurrentPage from '../DNA/hooks/useCurrentPage'

enum SelectionOptions {
  'presentation-play',
  'download',
  'bookmark',
  'bookmark-outline',
  'content-panel',
  'exit-fullwindow',
  'exit-fullscreen',
  'acknowledge-update',
  'enter-fullscreen',
  'exit',
  'slides',
  'folder-plus-outline',
}
export type SelectionOption = keyof typeof SelectionOptions

interface ContentPreviewModalProps {
  documentVersionORM: DocumentVersionORM | undefined
  folderItemORM: FolderItemORM | undefined
  isPublisherMode: boolean
  isFullWindow: boolean
  toast?: ToastActions
  fromEllipsis?: boolean
}

interface DesktopFullScreenContentProps {
  isPublisherMode: boolean
  onOptionSelected: (option: SelectionOption) => void
}

interface TabletFullScreenContentProps {
  onOptionSelected: (option: SelectionOption) => void
}

interface ModalContentProps {
  documentORM?: DocumentORM,
  folderItemORM?: FolderItemORM,
  onClose: () => void,
  isPublisherMode: boolean,
  onOptionSelected: (opt: SelectionOption) => void,
  documentVersionORM?: DocumentVersionORM,
}

const useContentPreviewModalSharedResources = (folderItemId?: string, folderId?: string) => {
  const {
    customDeckORM,
    activeSlide,
    setActiveSlide,
    visiblePages,
    isFullWindow,
    slideRollVisible,
    setSlideRollVisible,
    controlsVisible,
    adjustedSlideNumber,
    totalSlides,
    isViewingAssociatedFile,
    currentDocumentVersionORM,
    contentPanelVisible,
    setContentPanelVisible,
    visibleSearch,
    setVisibleSearch,
    visibleSidebar,
    setVisibleSidebar,
  } = useContentViewerModalState()

  const folderItemORM = useFolderItemORMById({ id: folderItemId, folderId });
  const { documentORM } = { ...currentDocumentVersionORM?.relations }
  const isSharedWithTheUser = folderItemORM?.relations?.parentORM?.meta?.isSharedWithTheUser

  // This is needed for when the acknowledge is clicked in that case the ORM is not being updated
  const isCustomAcknowledged = customDeckORM
    ? (customDeckORM?.model.autoUpdateAcknowledgedAt ?? AUTO_UPDATE_DEFAULT_DATE) !== AUTO_UPDATE_DEFAULT_DATE : false
  const hideAutoUpdateMessage = isCustomAcknowledged || !!folderItemORM?.model.updateAcknowledgedAt ||
    isSharedWithTheUser
  const { isOnline } = useAppSettings()

  const showContentUnavailable = !isOnline
    ? isDocumentVersionORM(folderItemORM?.relations.itemORM) // Check FolderItem Prop
      ? !folderItemORM?.relations.itemORM.meta.assets.isContentCached // is DocumentVersionORM
      : isCustomDeckORM(folderItemORM?.relations.itemORM)
        ? !folderItemORM?.relations.itemORM.meta.assets.isContentCached // is CustomDeckORM
        : !currentDocumentVersionORM?.meta.assets.isContentCached // check DocumentVersion Prop
    : false

  return {
    isCustomDeck: !!customDeckORM,
    activeSlide,
    setActiveSlide,
    visiblePages,
    isFullWindow,
    slideRollVisible,
    setSlideRollVisible,
    controlsVisible,
    adjustedSlideNumber,
    totalSlides,
    hideAutoUpdateMessage,
    isViewingAssociatedFile,
    isOnline,
    documentORM,
    currentDocumentVersionORM,
    showContentUnavailable,
    contentPanelVisible,
    setContentPanelVisible,
    visibleSearch,
    setVisibleSearch,
    visibleSidebar,
    setVisibleSidebar,
  }
}

const ContentPreviewElement: React.FC = () => {
  const { isFullWindow, documentORM, showContentUnavailable } = useContentPreviewModalSharedResources()
  if (!documentORM) return null
  return (
    <ContentPreview
      fullwindow={isFullWindow}
      hideControls={isFullWindow}
      showContentUnavailable={showContentUnavailable}
      watermark={
        documentORM.relations.version.latestUsableDocumentVersionORM?.model.purpose === PurposeType.INTERNAL_USE_ONLY
          ? documentORM.relations.version.latestUsableDocumentVersionORM?.model.purpose
          : undefined
      }
    />
  )
}

const SlideRollElement: React.FC = () => {
  const {
    activeSlide,
    setActiveSlide,
    visiblePages,
    isCustomDeck,
    isFullWindow,
    showContentUnavailable,
    currentDocumentVersionORM,
  } = useContentPreviewModalSharedResources()

  if (!currentDocumentVersionORM) return (<></>)

  return (
    <SlideRoll
      activeSlide={activeSlide}
      /** TODO: figure out why using documentVersion here prevents the thumbs from loading properly */
      documentVersionORM={currentDocumentVersionORM}
      setActiveSlide={setActiveSlide}
      isCustomDeck={isCustomDeck}
      scrollToSelected={true}
      isFullWindow={isFullWindow}
      contentUnavailable={showContentUnavailable}
      numberColor={isFullWindow ? 'white' : undefined}
      selectedNumberColor={isFullWindow ? undefined : 'default'}
      itemWidth={isFullWindow ? 240 : undefined}
      itemHeight={isFullWindow ? 135 : undefined}
      visiblePages={visiblePages}
    />

  )
}

/** DESKTOP COMPONENTS & CONTENT */
function DesktopModalContent(props: ModalContentProps) {
  const {
    documentORM,
    folderItemORM,
    onClose,
    isPublisherMode,
    onOptionSelected,
    documentVersionORM,
  } = props

  const {
    hideAutoUpdateMessage,
    isViewingAssociatedFile,
    isCustomDeck,
    showContentUnavailable,
    visibleSearch,
    setVisibleSearch,
    visiblePages,
  } = useContentPreviewModalSharedResources(folderItemORM?.model.id, folderItemORM?.relations.parentORM?.model.id)

  const [playerMaxHeight, setPlayerMaxHeight] = useState(0)
  const handleAcknowledge = () => {
    onOptionSelected('acknowledge-update')
  }

  const isSmallScreen = useMediaQuery({
    maxHeight: 769,
  })

  const mainContainerHeight: ViewStyle = isSmallScreen
    ? { minHeight: '95vh' }
    : { minHeight: 770 }

  const isCurrentVersionSameFromFolder = folderItemORM?.model.itemId === documentVersionORM?.model.id
  const showAutoUpdateBanner =
    !hideAutoUpdateMessage &&
    (isCustomDeck || isCurrentVersionSameFromFolder) &&
    folderItemORM &&
    folderItemORM.meta.hasAutoUpdatedItem

  const isPersonalDocument = documentORM?.model.accessLevel === 'USER';

  return (<View style={[styles.mainContainer, mainContainerHeight]}>
    <DNABox
      style={[styles.headerBarDesktop, isCustomDeck && { minHeight: 59 }]}
    >
      <Header
        document={documentORM!}
        isPublisherMode={isPublisherMode}
        onOptionSelected={onOptionSelected}
        isContentUnavailable={showContentUnavailable}
      />

      <Pressable
        testID="content-present-mode-exit-button"
        onPress={onClose}
      >
        <View style={styles.headerCloseButton}>
          <Icon style={styles.icon} name="close" />
        </View>
      </Pressable>
    </DNABox>
    <Iffy is={showAutoUpdateBanner}>
      <DNABox style={styles.autoUpdateBannerContainer}>
        <InformationMessage
          text="This file was auto-updated to a new version"
          variance="warning"
          actionButtonFunction={handleAcknowledge}
          actionButtonText="Okay"
        />
      </DNABox>
    </Iffy>
    <Iffy is={!isViewingAssociatedFile && !isCustomDeck && !isPersonalDocument}>
      <DNABox>
        <DocumentVersionSelector
          documentORM={documentORM!}
          documentVersionORM={documentVersionORM!}
          isPublisherMode={isPublisherMode}
        />
      </DNABox>
    </Iffy>
    <DNABox fill style={styles.mainContent}>
      <Iffy is={visibleSearch}>
        <TextSearchPanel
          onClose={() => setVisibleSearch(false)}
          documentVersionORM={documentVersionORM!}
          visiblePages={visiblePages}
          variant="contentPreviewModal"
        />
      </Iffy>
      <DNABox
        onLayout={({ nativeEvent: { layout } }) => setPlayerMaxHeight(layout.height)}
        fill
        appearance="col"
        style={[styles.slideContentContainer, !!playerMaxHeight && { maxHeight: playerMaxHeight }]}
      >
        <ContentPreviewElement />
        <SlideRollElement />
      </DNABox>
      <SideBar documentVersionORM={documentVersionORM!} />
    </DNABox>
  </View>)
}

function DesktopModalComponent(props) {
  return (<Modal visible backdropStyle={styles.backdrop} onBackdropPress={props.onClose}>
    {props.children}
  </Modal>)
}

function DesktopFullScreenContent(props: DesktopFullScreenContentProps) {
  const {
    onOptionSelected,
    isPublisherMode,
  } = props

  const {
    controlsVisible,
    contentPanelVisible,
    currentDocumentVersionORM,
    setContentPanelVisible,
    setVisibleSearch,
    slideRollVisible,
    visiblePages,
    visibleSearch,
  } = useContentPreviewModalSharedResources()

  // Show a prompt when closing while in full window but not in full screen (BEAC-2257)
  useBeforeunload((event) => {
    const isCurrentlyFullScreen = Platform.OS === 'web' &&
      !!((document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement && document.webkitFullscreenElement !== null))
    if (!isCurrentlyFullScreen) {
      event.preventDefault()
    }
  })

  return (
    <Stack anchor={'bottomRight'}>
      <Stack.Layer style={styles.mainContainerFS}>
        <ActiveDocumentTabs
          onOptionSelected={onOptionSelected}
        />
        <DNABox fill style={{ flexDirection: 'row' }}>
          <Iffy is={visibleSearch}>
            <TextSearchPanel
              onClose={() => setVisibleSearch(false)}
              documentVersionORM={currentDocumentVersionORM!}
              visiblePages={visiblePages}
              variant="contentPreviewModal"
            />
          </Iffy>
          <ContentPreviewElement />
        </DNABox>
      </Stack.Layer>

      {controlsVisible &&
        <Stack.Layer style={styles.fullwindowControls}>
          <ActionBar
            fileExtension={currentDocumentVersionORM?.model.type as FileType}
            isPublisherMode={isPublisherMode}
            onOptionSelected={onOptionSelected}
          />
          {
            slideRollVisible &&
              <SlideRollElement />
          }
        </Stack.Layer>
      }

      <Stack.Layer
        style={{
          width: '100%',
          height: contentPanelVisible ? '100%' : undefined,
        }}
      >
        <DNABox fill>
          {/* TODO: Identify best approach for triggering togglePanelView from
          useMyContentPanelSharedResources to trigger close animation (adding a
          MyContentPanel context would work for this but would require a
          semi-significant refactor) */}
          <Pressable
            onPress={() => {
              setContentPanelVisible(false)
            }}
            style={{ flex: 1 }}
          />
          <MyContentPanel
            variant={'ContentPreviewModal'}
            onClosePanel={() => {
              setContentPanelVisible(false)
            }}
            addGap={false}
            displayContentPanel={contentPanelVisible}
          />
        </DNABox>
      </Stack.Layer>

    </Stack>
  )
}

/** TABLET COMPONENTS & CONTENT */
function TabletModalContent(props: ModalContentProps) {
  const {
    documentORM,
    folderItemORM,
    onClose,
    isPublisherMode,
    onOptionSelected,
    documentVersionORM,
  } = props

  const {
    hideAutoUpdateMessage,
    isViewingAssociatedFile,
    showContentUnavailable,
    visiblePages,
    visibleSearch,
    setVisibleSearch,
  } = useContentPreviewModalSharedResources(folderItemORM?.model.id, folderItemORM?.relations.parentORM?.model.id)

  const handleAcknowledge = () => {
    onOptionSelected('acknowledge-update')
  }
  const isPersonalDocument = documentORM?.model.accessLevel === 'USER';

  return (
    <DNABox
      appearance="col"
      fill
      style={{ minHeight: '100vh', minWidth: '100vw' }}
    >
      {/* HEADER */}
      <DNABox
        childFill={0}
        spacing="md"
        style={styles.headerBarTablet}
      >
        <Header
          document={documentORM!}
          isPublisherMode={isPublisherMode}
          onOptionSelected={onOptionSelected}
          isContentUnavailable={showContentUnavailable}
        />
        <DNAButton
          testID="content-present-mode-exit-button"
          status="tertiary"
          onPress={() => onClose()}
          iconLeft={'close'}
        />
      </DNABox>

      {/* Auto Update Notification */}
      <Iffy is={!hideAutoUpdateMessage && folderItemORM && folderItemORM.meta.hasAutoUpdatedItem}>
        <DNABox style={styles.autoUpdateBannerContainer}>
          <InformationMessage
            text="This file was auto-updated to a new version"
            variance="warning"
            actionButtonFunction={handleAcknowledge}
            actionButtonText="Okay"
          />
        </DNABox>
      </Iffy>

      {/* Associated file dropdown  */}
      <Iffy is={!isViewingAssociatedFile && !isCustomDeckORM(folderItemORM?.relations.itemORM) && !isPersonalDocument}>
        <DNABox>
          <DocumentVersionSelector
            documentORM={documentORM!}
            documentVersionORM={documentVersionORM!}
            isPublisherMode={isPublisherMode}
          />
        </DNABox>
      </Iffy>

      {/* CONTENT + SLIDE ROLL + SIDEBAR */}
      <DNABox testID="content-navigation" fill>
        <>
          <Iffy is={visibleSearch}>
            <TextSearchPanel
              onClose={() => setVisibleSearch(false)}
              documentVersionORM={documentVersionORM!}
              visiblePages={visiblePages}
              variant="contentPreviewModal"
            />
          </Iffy>
        </>
        {/* CONTENT + SLIDE ROLL COL */}
        <DNABox
          fill
          appearance="col"
          style={{ padding: 16 }}
        >
          {/* Content */}
          <DNABox fill>
            <DNABox fill>
              <ContentPreviewElement />
            </DNABox>
          </DNABox>

          {/* SlideRoll */}
          <DNABox>
            <SlideRollElement />
          </DNABox>
        </DNABox>

        {/* SideBar */}
        <DNABox>
          <SideBar documentVersionORM={documentVersionORM!} />
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

function TabletModalComponent(props) {
  return (<Modal
    visible
    style={{ width: '100vw', height: '100vh' }}
    backdropStyle={{ backgroundColor: 'white' }}
  >
    {props.children}
  </Modal>)
}

function TabletFullScreenContent(props: TabletFullScreenContentProps) {
  const
    {
      onOptionSelected,
    } = props

  const {
    currentDocumentVersionORM,
    slideRollVisible,
    setSlideRollVisible,
    adjustedSlideNumber,
    totalSlides,
    visiblePages,
    visibleSearch,
    setVisibleSearch,
    contentPanelVisible,
    setContentPanelVisible,
  } = useContentPreviewModalSharedResources()

  return (
    <>
      <>
        <Iffy is={visibleSearch}>
          <TextSearchPanel
            onClose={() => setVisibleSearch(false)}
            documentVersionORM={currentDocumentVersionORM!}
            visiblePages={visiblePages}
            variant="contentPreviewModal"
          />
        </Iffy>
      </>
      <Stack anchor={'bottomRight'}>
        <Stack.Layer style={styles.tabletMainContainerFS}>
          <ActiveDocumentTabs
            onOptionSelected={onOptionSelected}
          />
          {slideRollVisible &&
            <DNABox style={styles.tabletTopBar}>
              <Pressable onPress={() => onOptionSelected('exit-fullwindow')}>
                <View style={styles.exitButton}>
                  <Icon name="close" style={styles.exitIcon} />
                </View>
              </Pressable>
              <DNABox style={{ flex: 1, alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={styles.slideCounter}>Slide {adjustedSlideNumber} of {totalSlides}</Text>
              </DNABox>
              <Pressable onPress={() => setSlideRollVisible((visible) => !visible)}>
                <View style={styles.hideFullScreenBars}>
                  <Icon name="chevron-up" style={styles.exitIcon} />
                </View>
              </Pressable>
            </DNABox>
          }
          {<ContentPreviewElement />}
        </Stack.Layer>

        {slideRollVisible &&
          <Stack.Layer style={styles.fullwindowControls}>
            <SlideRollElement />
          </Stack.Layer>
        }
        <Stack.Layer
          style={{
            width: '100%',
            height: contentPanelVisible ? '100%' : undefined,
          }}
        >
          <DNABox fill>
            {/* TODO: Identify best approach for triggering togglePanelView from
            useMyContentPanelSharedResources to trigger close animation (adding a
            MyContentPanel context would work for this but would require a
            semi-significant refactor) */}
            <Pressable
              onPress={() => {
                setContentPanelVisible(false)
              }}
              style={{ flex: 1 }}
            />
            <MyContentPanel
              variant={'ContentPreviewModal'}
              onClosePanel={() => {
                setContentPanelVisible(false)
              }}
              addGap={true}
              displayContentPanel={contentPanelVisible}
            />
          </DNABox>
        </Stack.Layer>
      </Stack >
    </>
  )
}

const selectModalState = (state: RootState) => state.contentPreviewModal

/** ROOT SCAFFOLDING COMPONENTS & CONTENT */
const ModalWrapper = () => {
  const modalState = useSelector(selectModalState)
  const folderItemORM = modalState.folderItemORM
  const docVersionMap = useAllDocumentVersionMap()
  const documentVersionORM = modalState.documentVersionId
    ? docVersionMap[modalState.documentVersionId]
    : undefined

  const route = useCurrentPage({ exact: false })
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')

  return (
    modalState.isOpen &&
      <ContentPreviewModal
        isPublisherMode={!!isPublisherRoute}
        documentVersionORM={documentVersionORM}
        folderItemORM={folderItemORM}
        isFullWindow={!!modalState.isFullWindow}
        toast={modalState.toast}
        fromEllipsis={!!modalState.fromEllipsis}
      />)
}

function ContentPreviewModal(props: ContentPreviewModalProps) {
  const { isPublisherMode, isFullWindow: fullWindow, documentVersionORM, toast, folderItemORM, fromEllipsis } = props
  const dispatch = useDispatch()
  const { deviceMode } = useAppSettings()
  const isTablet = deviceMode === 'tablet'
  const { documentORM } = { ...documentVersionORM?.relations }

  useDisableSwipe()

  function onClose(): void {
    batch((): void => {
      dispatch(contentPreviewModalActions.setModalVisibility({ isOpen: false }))
      dispatch(presentationControlActions.resetState())
    })
  }

  const Component = () => {
    const {
      isFullWindow,
      setIsFullWindow,
      setSlideRollVisible,
      setControlsTimeout,
      setActiveDocVersionORM,
      currentDocumentVersionORM,
      customDeckORM,
    } = useContentViewerModalState()
    const user = useCurrentUser()
    const bumpControlsTimeout = useCallback(
      throttle(() => setControlsTimeout(), 100),
      [setControlsTimeout, isFullWindow],
    )
    const { setContentPanelVisible } = useContentPreviewModalSharedResources()

    useMouseEvent(bumpControlsTimeout)

    /**
     * Initialize keyboard input event listeners
     */
    useInputConfig(GetInputConfig())

    /**
     * This handler will fire a timeout for the controls overlay visibility when the fullscreen mode is activated
     */
    useEffect(bumpControlsTimeout, [isFullWindow])

    useEffect(() => {
      if (!fullWindow) {
        analytics?.track('DOCUMENT_CONTENT_PREVIEW', {
          action: 'CONTENT_PREVIEW',
          category: 'DOCUMENT',
          context: isPublisherMode ? 'PUBLISHER' : 'APP',
          documentId: documentORM?.model.id,
          documentVersionId: documentVersionORM?.model.id,
          folderId: folderItemORM?.relations.parentORM?.model.id,
          customDeckId: customDeckORM?.model.id,
        })
      }
      else {
        analytics?.track('DOCUMENT_PRESENT', {
          action: 'PRESENT',
          category: 'DOCUMENT',
          context: isPublisherMode ? 'PUBLISHER' : 'APP',
          documentId: documentORM?.model.id,
          documentVersionId: documentVersionORM?.model.id,
          folderId: folderItemORM?.relations.parentORM?.model.id,
          customDeckId: customDeckORM?.model.id,
        })
      }
    }, [])
    function onExitFullScreen(): void {
      if (document.exitFullscreen && document.fullscreenElement !== null) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen && document.webkitFullscreenElement !== null) { /* Safari */
        document.webkitExitFullscreen()
      }
    }

    function onEnterFullScreen(): void {
      const docElm = document.documentElement
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen()
      } else if (docElm.webkitRequestFullscreen) { /* Safari */
        docElm.webkitRequestFullscreen()
      }
    }

    /**
     * This method is a handler for child components or root components that determines what action to take on interaction.
     * TODO: This should probably be refactored to use the contentpreview modal context or the redux slice
     * rather than keeping these states tracked here.
     * @param option string passed back from selected option to determine action taken
     */
    function onOptionSelected(option: SelectionOption): void {
      // This is a workaround because for some reason the prev and next buttons do not fire an event when the presentation is changing slides.
      // Super duper strange.
      setControlsTimeout()

      switch (option) {
        case 'presentation-play': {
          setActiveDocVersionORM(currentDocumentVersionORM)
          analytics?.track('DOCUMENT_PRESENT', {
            action: 'PRESENT',
            category: 'DOCUMENT',
            context: isPublisherMode ? 'PUBLISHER' : 'APP',
            documentId: currentDocumentVersionORM?.model.documentId,
            documentVersionId: currentDocumentVersionORM?.model.id,
            folderId: folderItemORM?.relations.parentORM?.model.id,
            customDeckId: customDeckORM?.model.id,
          })
          setIsFullWindow(!isFullWindow)
          dispatch(contentPreviewModalActions.setIsFullWindow(!isFullWindow))
          break
        }
        case 'download':
          analytics?.track('DOCUMENT_DOWNLOAD', {
            action: 'DOWNLOAD',
            category: 'DOCUMENT',
            documentId: documentVersionORM?.model.documentId,
            documentVersionId: documentVersionORM?.model.id,
          })

          downloadContentFromCloudfront(
            documentVersionORM!.model.srcFile?.key!,
            documentVersionORM!.model.srcFilename!,
            documentVersionORM!.model.type,
          )
          break
        case 'bookmark':
        case 'bookmark-outline':
          handleBookmark(documentORM!.model, user, dispatch)
          break
        case 'content-panel':
          setContentPanelVisible(true)
          break
        case 'exit-fullwindow':
          setActiveDocVersionORM(currentDocumentVersionORM)
          analytics?.track('DOCUMENT_STOP_PRESENT', {
            action: 'STOP_PRESENT',
            category: 'DOCUMENT',
            context: isPublisherMode ? 'PUBLISHER' : 'APP',
            documentId: currentDocumentVersionORM?.model.documentId,
            documentVersionId: currentDocumentVersionORM?.model.documentId,
            customDeckId: customDeckORM?.model.id,
          })
          if (fromEllipsis) {
            onExitFullScreen()
            onClose()
          } else {
            onExitFullScreen()
            setIsFullWindow(false)
          }
          dispatch(contentPreviewModalActions.setIsFullWindow(false))
          break
        case 'exit-fullscreen':
          onExitFullScreen()
          break
        case 'acknowledge-update':
          if (folderItemORM?.model.type === FolderItemType.CUSTOM_DECK) {
            /** TODO: fix this incorrect usage of as */
            dispatch(customDeckActions.acknowledgeAutoUpdate([folderItemORM.relations.itemORM as CustomDeckORM]))
          } else if (folderItemORM?.model.type === FolderItemType.DOCUMENT_VERSION) {
            dispatch(folderActions.acknowledgeAutoUpdate([folderItemORM!]))
          }
          break
        case 'enter-fullscreen':
          onEnterFullScreen()
          break
        case 'exit':
          onExitFullScreen()
          onClose()
          break
        case 'slides':
          setSlideRollVisible((isVisible) => !isVisible)
          break
        case 'folder-plus-outline':
          dispatch(DNAModalActions.setModal({
            isVisible: true,
            allowBackdropCancel: true,
            component: (props) => (
              <DNADocumentAddToFolder
                {...props}
                itemORM={documentORM!}
                toast={toast}
              />
            ),
          }),
          )

          break
      }
    }

    return (
      <View style={styles.container} testID="WrapperView">
        {isFullWindow
          // Fullscreen Layout
          // We need this div here as a temporary workaround to overcome issues with UI Kitten's modal's
          // incorrect handling of window resizes. Hopefully RN upgrade /w native modals would fix this
          ? <div style={{ position: 'fixed', width: '100vw', height: '100vh', top: 0, left: 0, display: 'flex' }}>
            {isTablet
              ? <TabletFullScreenContent
                  onOptionSelected={onOptionSelected}
              />
              : <DesktopFullScreenContent
                  isPublisherMode={isPublisherMode}
                  onOptionSelected={onOptionSelected}
              />
            }
          </div>
          // Modal Layout
          : (isTablet
            ? <TabletModalContent
                documentORM={documentORM}
                onClose={onClose}
                folderItemORM={folderItemORM}
                isPublisherMode={isPublisherMode}
                onOptionSelected={onOptionSelected}
                documentVersionORM={documentVersionORM}
            />
            : <DesktopModalContent
                documentORM={documentORM}
                onClose={onClose}
                folderItemORM={folderItemORM}
                isPublisherMode={isPublisherMode}
                onOptionSelected={onOptionSelected}
                documentVersionORM={documentVersionORM}
            />
          )
        }
      </View>
    )
  }

  const MemoizedModalContent = useMemo(
    () => {
      return (
        <PresentationContext context={isPublisherMode ? PRESENTATION_CONTEXT.PUBLISHER : PRESENTATION_CONTEXT.APP}>
          <PresentationPlayerProvider>
            <ContentViewerModalStateProvider
              context={isPublisherMode ? 'PUBLISHER' : 'APP'}
              isFullWindow={fullWindow}
              documentVersionORM={documentVersionORM}
              folderItemORM={folderItemORM}
            >
              <Component />
            </ContentViewerModalStateProvider>
          </PresentationPlayerProvider >
        </PresentationContext>
      )
    }, [documentVersionORM?.model.id],
  )

  return (isTablet
    ? <TabletModalComponent onClose={onClose}>
      {MemoizedModalContent}
    </TabletModalComponent>
    : <DesktopModalComponent onClose={onClose}>
      {MemoizedModalContent}
    </DesktopModalComponent>)
}

export default ModalWrapper
