import React from 'react';
import { DNABox, DNAButton, DNADivider, DNAText, Iffy } from '@alucio/lux-ui';
import { styles } from '../AddMeetingHeader';
import { useCustomFormRecordProvider } from 'src/state/context/CustomFormProvider/CustomFormProvider';
import { FORM_VARIANT, useCancelCustomForm } from './CustomFormRecordFooter';

interface Props {
  onConfirmCancel: () => void;
  variant?: FORM_VARIANT;
}

const CustomFormRecordHeader = (props: Props) => {
  const { onConfirmCancel, variant = FORM_VARIANT.STANDALONE } = props;
  const { standaloneForm, isSaving } =  useCustomFormRecordProvider();
  const spacing = variant === FORM_VARIANT.STANDALONE ? 'between' : 'md';
  const onCancel = useCancelCustomForm(onConfirmCancel);

  return (
    <DNABox appearance="col">
      <DNABox alignY="center" appearance="row" spacing={spacing} style={styles.titleContainer}>
        <Iffy is={variant === FORM_VARIANT.DEPENDENT}>
          <DNAButton
            disabled={isSaving}
            appearance="outline"
            status="tertiary"
            size="md"
            padding="sm"
            iconLeft="chevron-left"
            onPress={onCancel}
          >Back</DNAButton>
        </Iffy>
        <DNAText bold h5 style={styles.title}>{standaloneForm.name}</DNAText>
        <Iffy is={variant === FORM_VARIANT.STANDALONE}>
          <DNAButton
            appearance="ghost"
            status="tertiary"
            size="md"
            padding="sm"
            iconLeft="close"
            onPress={onCancel}
          />
        </Iffy>
      </DNABox>
      <DNADivider />
    </DNABox>
  );
};

export default CustomFormRecordHeader;
