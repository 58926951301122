import { Dispatch } from 'redux';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  CRMSubmitMeetingPayload,
  CRMSubmitResponseReferences, CRMSubmitStandaloneFormPayload,
  HANDLED_SYNC_ERROR, isCRMStandaloneSubmitPayload,
  SYNC_STATUS,
} from 'src/classes/CRM/CRMIndexedDBTypes';
import { MeetingORM } from 'src/types/orms';
import { meetingActions } from 'src/state/redux/slice/meeting';
import {
  Attendee,
  AttendeeType,
  ContentPresented,
  CRMMeetingRecordInfo,
  CrmSyncStatus,
  CustomValues,
  Meeting,
  MeetingAttendeeStatus,
  MeetingStatus,
  ObjectRecordStatus,
} from '@alucio/aws-beacon-amplify/src/models';
import { formToModel } from 'src/components/CustomFields/ComposableFormUtilities';
import { MEETING_SUBMIT_TYPE } from 'src/components/Meeting/AddMeetingProvider';
import { useGetFormConfigFromIndexDB } from 'src/state/redux/selector/crm';
import { useCRMStatus, useRefreshToken } from 'src/screens/Profile/CRMIntegration';
import { useUserTenant } from 'src/state/redux/selector/user';
import { CRMHandler } from 'src/classes/CRM/CRMHandler';
import { FormValuesType } from 'src/components/CustomFields/ComposableForm';
import { getPreprocessedMeetingForm, handleCRMErrorResponses, SeparatedFields } from './saveMeetingUtilities';
import { useCustomDeckORMMap } from 'src/state/redux/selector/folder';
import { useCRMCustomFormRecordByParentId } from '../../redux/selector/customFormRecord';
import { updateCustomFormRecordsFromCRMResponse } from '../CustomFormProvider/useSaveCustomFormRecord';
import * as logger from 'src/utils/logger'

export const RETRY_MESSAGES = [
  'Your CRM session has expired. Please log in to CRM and submit again',
  'Session expired or invalid',
]

export const ERROR_REFRESH_TOKEN_MESSAGES = 'Your CRM session has expired. Please log in to CRM and submit again'

export interface SubmitMeetingProps {
  contentPresented?: ContentPresented[];
  formValues: FormValuesType;
  meetingORM: MeetingORM;
  submitType?: MEETING_SUBMIT_TYPE;
}

// GIVEN THE AMOUNT OF CONDITIONS, THIS OBJECT WILL BE PASSED ACROSS FUNCTIONS
interface SubmitMeetingPayload extends SubmitMeetingProps {
  formValuesAttendees: FormValuesType[];
  crmSubmitResponse?: CRMSubmitResponseReferences;
  dispatch: Dispatch;
  saveToCRM: boolean;
  canUseMeetingCRMFields: boolean;
  hasCRMConfig: boolean;
  separatedCustomFields: SeparatedFields;
}

export interface AttendeeForm extends Attendee {
  crmValues?: FormValuesType;
}

// GIVEN THE AMOUNT OF INFORMATION THAT'S REQUIRED TO SAVE A MEETING AND AVOID
// HAVING THE PARENT COMPONENT HANDLING THEM ALL, THIS HOOK WILL RETURN THE
// SUBMITMEETING FUNCTION AND ALSO USE THE REQUIRED HOOKS TO GET SOME INF
export const useSaveMeeting = (meetingId?: string) => {
  const rawCRMFormConfig = useGetFormConfigFromIndexDB();
  const tenant = useUserTenant();
  const hasCRMConfig = !!tenant?.config.crmIntegration?.meetingSetting;
  const indexedCustomDecks = useCustomDeckORMMap();
  const { canUseMeetingCRMFields, crmIntegrationType } = useCRMStatus();
  const refreshToken = useRefreshToken();
  const crmCustomFormRecords = useCRMCustomFormRecordByParentId(meetingId);
  const memoizedSubmit = useCallback((payload: SubmitMeetingProps) =>
    submitMeeting(payload), [rawCRMFormConfig, canUseMeetingCRMFields, crmCustomFormRecords]);
  const dispatch = useDispatch();

  async function submitMeeting(submitMeetingPayload: SubmitMeetingProps): Promise<void> {
    logger.saveMeetingHandler.debug('Submitting meeting.', submitMeetingPayload);
    const { meetingORM, submitType = MEETING_SUBMIT_TYPE.DEFAULT } = submitMeetingPayload;
    const saveToCRM = [MEETING_SUBMIT_TYPE.SUBMIT_LOCK_TO_CRM, MEETING_SUBMIT_TYPE.SAVE_TO_CRM].includes(submitType);
    const preprocessedMeetingForm = getPreprocessedMeetingForm({
      ...submitMeetingPayload,
      crmIntegrationType,
      hasCRMConfig,
      tenant,
    });

    let crmSubmitResponse: CRMSubmitResponseReferences | undefined;

    // ** CRM SUBMIT ** //
    if (saveToCRM && hasCRMConfig && canUseMeetingCRMFields && rawCRMFormConfig) {
      const crmCallId = meetingORM.model.crmRecord?.crmCallId;
      try {
        crmSubmitResponse = await performCRMSubmit({
          mainCrmValues: { ...preprocessedMeetingForm.separatedCustomFields.crmValues },
          startTime: preprocessedMeetingForm.startTime,
          endTime: preprocessedMeetingForm.endTime,
          mainCrmRecordId: crmCallId,
          formValuesAttendees: preprocessedMeetingForm.formValuesAttendees as unknown as AttendeeForm[],
          formSettings: rawCRMFormConfig,
          crmCustomFormRecords,
          submitType,
          tenant,
          indexedCustomDecks,
          contentPresented: submitMeetingPayload.contentPresented,
          meetingORM,
          title: preprocessedMeetingForm.title,
          status: preprocessedMeetingForm.status,
        }, refreshToken);
      } catch (e) {
        logger.saveMeetingHandler.debug('An error occurred while submitting to CRM', e);
        if (e instanceof ErrorEvent) {
          crmSubmitResponse = {
            ...crmSubmitResponse,
            'main-record': {
              syncStatus: SYNC_STATUS.ERROR,
              errorMessages: Array.isArray(e.error) ? [Array.from(new Set(e.error)).join(', ')] : [e.error],
              externalId: crmCallId,
            },
          }
        } else if (e instanceof Error) {
          // ERROR THROWN BY BEACON (EX: MISSING CONFIG)
          throw Error('An error occurred while submitting to CRM.');
        }
      }
      logger.saveMeetingHandler.debug('CRM Submit Response', crmSubmitResponse);

      analytics.track(submitType === MEETING_SUBMIT_TYPE.SUBMIT_LOCK_TO_CRM ? 'MEETING_SUBMITTED' : 'MEETING_SYNC', {
        category: 'MEETING',
        meetingID: meetingORM.model.id,
        meetingStatus: meetingORM.model.status,
        mainCrmRecordId: crmCallId,
      });
    }

    // ** BEACON SAVE ** //
    const saveMeetingPayload: SubmitMeetingPayload = {
      dispatch,
      saveToCRM,
      hasCRMConfig,
      crmSubmitResponse,
      canUseMeetingCRMFields,
      ...submitMeetingPayload,
      ...preprocessedMeetingForm,
    };
    await saveBeaconMeeting(saveMeetingPayload);
    // UPDATES IN DYNAMO THE CUSTOM FORM RECORDS
    if (crmSubmitResponse) {
      updateCustomFormRecordsFromCRMResponse(crmCustomFormRecords, crmSubmitResponse, dispatch, submitType);
    }

    // CHECKS AND HANDLES IF THERE'S AN ERROR
    handleCRMErrorResponses(crmSubmitResponse);
  }

  return memoizedSubmit;
};

function saveBeaconMeeting(saveMeetingPayload: SubmitMeetingPayload): void {
  const {
    dispatch,
    meetingORM,
    separatedCustomFields,
    hasCRMConfig,
    contentPresented,
  } = saveMeetingPayload;

  const startTime = separatedCustomFields.beaconInternalValues.startTime as string;
  const endTime = separatedCustomFields.beaconInternalValues.endTime as string | undefined;
  const title = separatedCustomFields.beaconInternalValues.title as string;

  // CREATES / UPDATES THE MAIN CRM RECORD AND PER ATTENDEE
  const { crmRecord, attendees } = getFormattedCRMRecords(saveMeetingPayload);
  const customValues = formToModel(separatedCustomFields.customFieldValues)
  const mergeCustomValues = hasCRMConfig
    ? customValues
    : mergeRemovedObjectRecordValues(meetingORM.model.customValues || [], customValues || [])

  const payload: Meeting = {
    ...meetingORM.model,
    status: getUpdatedMeetingStatus(saveMeetingPayload),
    startTime: new Date(startTime || meetingORM.model.startTime).toISOString(),
    // IN CASE THE SAVE COMES FROM AN ONGOING MEETING (WHICH DOESN'T HAVE AN ENDTIME YET)
    endTime: endTime ? new Date(endTime).toISOString() : undefined,
    title,
    contentPresented: contentPresented || [...meetingORM.model.contentPresented],
    crmRecord,
    customValues: mergeCustomValues,
    attendees,
  };

  logger.saveMeetingHandler.debug('Saving meeting', { ...payload });
  dispatch(meetingActions.updateMeeting(meetingORM.model, payload));
}

function getUpdatedMeetingStatus(saveMeetingPayload: SubmitMeetingPayload): MeetingStatus {
  const { submitType, crmSubmitResponse, separatedCustomFields } = saveMeetingPayload;
  const submitResponse = crmSubmitResponse?.['main-record'];
  const status = separatedCustomFields.beaconInternalValues.status as MeetingStatus;

  // ** CHECKS IF IT NEEDS TO BE LOCKED ** //
  if (submitResponse) {
    const alreadySubmittedError = submitResponse.handledError === HANDLED_SYNC_ERROR.RECORD_SUBMITTED;
    const submittedToCRM = submitResponse.syncStatus === SYNC_STATUS.SYNCED &&
      submitType === MEETING_SUBMIT_TYPE.SUBMIT_LOCK_TO_CRM;
    const entityDeleted = submitResponse.handledError === HANDLED_SYNC_ERROR.ENTITY_DELETED
    if (alreadySubmittedError || submittedToCRM || entityDeleted) {
      return MeetingStatus.LOCKED;
    }
  }

  return status === MeetingStatus.IN_PROGRESS ? MeetingStatus.ENDED_EXIT : status;
}

interface FormattedCRMRecordsResponse {
  attendees: Attendee[],
  crmRecord?: CRMMeetingRecordInfo
}

// ** RETURNS THE ATTENDEES WITH THEIR CRM RECORDS AND THE MAIN CRM MEETING RECORD ** //
function getFormattedCRMRecords(saveMeetingPayload: SubmitMeetingPayload): FormattedCRMRecordsResponse {
  const {
    hasCRMConfig,
    meetingORM,
    formValuesAttendees,
    crmSubmitResponse,
    separatedCustomFields,
    canUseMeetingCRMFields,
  } = saveMeetingPayload;

  if (!hasCRMConfig || !canUseMeetingCRMFields) {
    return {
      crmRecord: meetingORM.model.crmRecord,
      attendees: getFormattedAttendees(formValuesAttendees as unknown as Attendee[], meetingORM) || [],
    };
  }

  const formattedCustomValues = formToModel(separatedCustomFields.crmValues);

  // FORMATS THE MAIN CRM RECORD
  const oldPrimaryAttendeeRecord = meetingORM.model.attendees
    .find(({ attendeeType }) => attendeeType === AttendeeType.PRIMARY)
  const mainCrmRecord = getCRMRecord(
    formattedCustomValues,
    'main-record',
    crmSubmitResponse,
    meetingORM.model.crmRecord,
    oldPrimaryAttendeeRecord);

  // FORMATS THE ATTENDEE OBJECTS
  const attendeesWithCrmRecords = formValuesAttendees.map((formValueAttendee) => {
    const { crmValues = {}, ...rest } = formValueAttendee;
    const attendee = rest as unknown as Attendee;
    const isMainAttendee = attendee.attendeeType === AttendeeType.PRIMARY;
    const formattedCrmValues = formToModel(crmValues as unknown as FormValuesType);
    const oldAttendeeRecord = meetingORM.model.attendees.find(({ id }) =>
      id === attendee.id);

    return {
      ...attendee,
      crmRecord: getCRMRecord(
        formattedCrmValues || [],
        isMainAttendee ? 'main-record' : attendee.id,
        crmSubmitResponse,
        attendee.crmRecord,
        oldAttendeeRecord),
    };
  });

  return {
    crmRecord: mainCrmRecord,
    attendees: getFormattedAttendees(attendeesWithCrmRecords, meetingORM, crmSubmitResponse) || [],
  };
}

// ** RETURNS THE CRM RECORD OBJECT FORMATTED (INCLUDING THE CRM ID) ** //
function getCRMRecord(
  crmValues: CustomValues[],
  referenceId: string,
  crmSubmitResponse?: CRMSubmitResponseReferences,
  crmRecord?: CRMMeetingRecordInfo,
  oldAttendeeRecord?: Attendee): CRMMeetingRecordInfo | undefined {
  let crmId: string | undefined = crmRecord?.crmCallId;
  const isRecordDeletedOnCRM = crmSubmitResponse?.[referenceId]?.syncStatus === SYNC_STATUS.ERROR &&
    crmSubmitResponse?.[referenceId]?.handledError === HANDLED_SYNC_ERROR.ENTITY_DELETED;
  let isRecordUpdatedOnCRM = crmSubmitResponse?.[referenceId]?.syncStatus === SYNC_STATUS.SYNCED ||
    crmSubmitResponse?.[referenceId]?.syncStatus === SYNC_STATUS.DELETED || isRecordDeletedOnCRM
  const isPrimaryAttendee = oldAttendeeRecord?.attendeeType === AttendeeType.PRIMARY;

  // WE USE THE OLDATTENDEERECORD TO AVOID LOSING NON-UPDATED VALUES
  const oldCustomValues = (isPrimaryAttendee
    ? crmRecord?.crmCustomValues : oldAttendeeRecord?.crmRecord?.crmCustomValues) || [];

  if (!crmId) {
    // GETS THE ID OF THE CRM RECORD'S SUBMIT RESPONSE
    crmId = crmSubmitResponse?.[referenceId]?.externalId;
  }

  // IF THERE'S A CHILD RECORD, THE ID FROM THE SUBMIT RESPONSE MUST BE SET TO THE BEACON RECORD
  const crmCustomValues: CustomValues[] = crmValues.map((crmValue) => {
    // IF IT'S A REGULAR FIELD, NO CHECK NEEDS TO BE DONE
    if (!crmValue.objectRecords?.length) {
      return crmValue;
    }
    const oldObjectInMeeting = oldCustomValues.find(({ fieldId }) => fieldId === crmValue.fieldId);
    // IF IT'S AN OBJECT RECORD (CHILD RECORD FROM CRM), THE ID OF THAT RECORD NEEDS TO BE SET
    return {
      ...crmValue,
      objectRecords: crmValue.objectRecords.map((objectRecord) => {
        const oldRecord = oldObjectInMeeting?.objectRecords?.find(({ id }) => id === objectRecord.id);
        const objectResponse = crmSubmitResponse?.[objectRecord.id];
        const previousSyncStatus = objectRecord.syncStatus || oldRecord?.syncStatus as CrmSyncStatus;
        if (!objectResponse?.externalId) {
          return {
            ...objectRecord,
            syncStatus: previousSyncStatus,
          };
        } else if (objectResponse.syncStatus === SYNC_STATUS.ERROR) {
          logger.saveMeetingHandler.warn('Object with error', objectResponse, objectRecord);
          return {
            ...objectRecord,
            syncStatus: previousSyncStatus,
            status: objectResponse.handledError === HANDLED_SYNC_ERROR.ENTITY_DELETED
              ? ObjectRecordStatus.REMOVED : objectRecord.status,
          }
        }

        return {
          ...objectRecord,
          externalId: objectRecord.externalId || objectResponse.externalId,
          syncStatus: CrmSyncStatus.SYNCED,
        };
      }),
    };
  });

  // ADDS, TO THE VALID/EXISTING OBJECTS, THE REMOVED ONES WITH A "REMOVED" STATUS
  const mergedCustomValues = mergeRemovedObjectRecordValues(oldCustomValues, crmCustomValues, crmSubmitResponse);

  // IF THERE'S A CRM_ID BUT AND NO CRM RECORD, THE RECORD WAS CREATED BUT SUBSEQUENT CALLS FAILED
  if (crmId && (!crmRecord && !oldAttendeeRecord?.crmRecord)) {
    isRecordUpdatedOnCRM = true;
  }

  return {
    lastSyncedAt: isRecordUpdatedOnCRM ? new Date().toISOString()
      : crmRecord?.lastSyncedAt || oldAttendeeRecord?.crmRecord?.lastSyncedAt,
    crmSyncStatus: isRecordUpdatedOnCRM ? isRecordDeletedOnCRM ? CrmSyncStatus.DELETED : CrmSyncStatus.SYNCED
      : crmRecord?.crmSyncStatus || oldAttendeeRecord?.crmRecord?.crmSyncStatus,
    crmCallId: crmId || oldAttendeeRecord?.crmRecord?.crmCallId,
    crmCustomValues: mergedCustomValues,
  };
}

function mergeRemovedObjectRecordValues(modelCustomValues: CustomValues[],
  customValues: CustomValues[], crmSubmitResponse?: CRMSubmitResponseReferences): CustomValues[] {
  if (modelCustomValues.length === 0) return customValues;
  const result = [...customValues]
  modelCustomValues.filter((customValue) => customValue.objectRecords).forEach((customValue) => {
    const payloadCustomValue = result.find((payloadCustomValue) =>
      payloadCustomValue.fieldId === customValue.fieldId);
    if (!payloadCustomValue) {
      result?.push({
        ...customValue,
        objectRecords: customValue.objectRecords?.map((objectRecord) => {
          const isDeletedOnCRM = crmSubmitResponse?.[objectRecord.id] &&
            (crmSubmitResponse?.[objectRecord.id].syncStatus === SYNC_STATUS.DELETED ||
              crmSubmitResponse?.[objectRecord.id].handledError === HANDLED_SYNC_ERROR.ENTITY_DELETED);
          return {
            ...objectRecord,
            status: ObjectRecordStatus.REMOVED,
            syncStatus: isDeletedOnCRM ? CrmSyncStatus.DELETED : objectRecord.syncStatus,
          };
        }),
      });
    }
    else {
      customValue.objectRecords?.forEach((record) => {
        const objectRecord = payloadCustomValue.objectRecords?.find((objectRecord) =>
          objectRecord.id === record.id);
        if (!objectRecord) {
          const isDeletedOnCRM = crmSubmitResponse?.[record.id] &&
            (crmSubmitResponse?.[record.id].syncStatus === SYNC_STATUS.DELETED ||
            crmSubmitResponse?.[record.id].handledError === HANDLED_SYNC_ERROR.ENTITY_DELETED);

          payloadCustomValue?.objectRecords?.push({
            ...record,
            status: ObjectRecordStatus.REMOVED,
            syncStatus: isDeletedOnCRM ? CrmSyncStatus.DELETED : record.syncStatus,
          });
        }
      });
    }
  });
  return result;
}

function getFormattedAttendees(
  attendees: Attendee[],
  meeting: MeetingORM,
  crmSubmitResponse?: CRMSubmitResponseReferences): Attendee[] {
  const removedAttendees = meeting?.model.attendees?.reduce<Attendee[]>((acc, attendee) => {
    let crmRecord = attendee.crmRecord;
    const isAlreadyRemoved = attendee.status === MeetingAttendeeStatus.REMOVED;
    const isRecentlyRemoved = !attendees.find(({ id }) => id === attendee.id);

    if (isAlreadyRemoved || isRecentlyRemoved) {
      // CHECKS IF THE REMOVAL OF THEM HAS JUST BEEN SYNCED TO CRM (TO UPDATE THEIR CRM RECORD STATUS)
      if (crmSubmitResponse?.[attendee.id] && crmRecord &&
        crmSubmitResponse[attendee.id].syncStatus === SYNC_STATUS.DELETED) {
        crmRecord = {
          ...crmRecord,
          crmSyncStatus: CrmSyncStatus.DELETED,
        }
      }

      acc.push({
        ...attendee,
        crmRecord,
        status: MeetingAttendeeStatus.REMOVED,
        updatedAt: isAlreadyRemoved ? attendee.updatedAt : new Date().toISOString(),
      });
    }

    return acc;
  }, []) || [];

  return [...attendees, ...removedAttendees];
}

// ** IN CHARGE OF CALLING THE APPROPRIATE ADAPTER TO SUBMIT TO CRM ** //
export async function performCRMSubmit(
  payload: CRMSubmitMeetingPayload | CRMSubmitStandaloneFormPayload,
  refreshToken?: () => Promise<void>,
): Promise<CRMSubmitResponseReferences> {
  if (!payload.tenant.config.crmIntegration) {
    throw Error('No CRMConfig is defined.');
  }

  try {
    const crmHandlerInstance = CRMHandler(payload.tenant.config.crmIntegration);
    const result = await (isCRMStandaloneSubmitPayload(payload)
      ? crmHandlerInstance.Syncer().submitStandaloneFormToCRM(payload)
      : crmHandlerInstance.Syncer().submitToCRM(payload));
    return result;
  } catch (e) {
    // if the submit fails because of a session expiration, we retry once
    if (refreshToken &&
      e instanceof ErrorEvent &&
      (RETRY_MESSAGES.includes(e.error) ||
      RETRY_MESSAGES.includes(e.error?.[0]))
    ) {
      await refreshToken();
      return performCRMSubmit(payload);
    }
    throw e;
  }
}
