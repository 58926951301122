import React from 'react'
import { MeetingButtonProps, useMeetingButtonSharedComponents } from '../MeetingButton';

interface DefaultVariantProps extends MeetingButtonProps {}

export const DefaultVariant: React.FC<DefaultVariantProps> = () => {
  const {
    meetingButtonComponents,
  } = useMeetingButtonSharedComponents()

  return meetingButtonComponents
}
