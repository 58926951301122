import { useCallback, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import { Attendee, CrmIntegrationType } from '@alucio/aws-beacon-amplify/src/models';
import { CRM_SUBMIT_STATE, GLOBAL_FORM_ERROR } from 'src/components/Meeting/AddMeetingProvider';
import { FormValuesType } from 'src/components/CustomFields/ComposableForm';
import { Singleton as IndexDbCrm } from 'src/classes/CRM/CRMIndexedDB';
import { useAppSettings } from 'src/state/context/AppSettings';
import { ObjectWithId } from 'src/components/CustomFields/ComposableFormUtilities';
import { CRMAccount } from 'src/classes/CRM/CRMIndexedDBTypes';
import useVeevaWatchFormHandler from './useVeevaWatchFormHandler';

interface CRMMeetingFormHandler {
  canSubmitToCRM
  crmIntegrationType: CrmIntegrationType | 'VEEVA' | 'SALESFORCE' | undefined
  crmSubmitState: CRM_SUBMIT_STATE,
  setCrmSubmitState: (state: CRM_SUBMIT_STATE) => void
}

type canSubmitToCRMType = (
  values: FormValuesType,
  rhForm: UseFormReturn<FormValuesType>,
  setErrorMessage: (value: React.SetStateAction<string>) => void,
  crmIntegrationType: CrmIntegrationType | 'VEEVA' | 'SALESFORCE' | undefined,
) => Promise<boolean>;

interface CRMMeetingFormHandlerProps {
  meetingId?: string
  rhForm: UseFormReturn<FormValuesType>
}

const integrationTypeValidation : Record<CrmIntegrationType, canSubmitToCRMType> = {
  [CrmIntegrationType.VEEVA]: async (values, rhForm, setErrorMessage) => {
    if (!values.primaryAttendee?.length) {
      rhForm.setError('primaryAttendee', { message: 'This field is required' });
      setErrorMessage(GLOBAL_FORM_ERROR.too_small);
      return false;
    }
    if (
      Array.isArray(values.primaryAttendee) &&
      Array.isArray(values.additionalAttendees)
    ) {
      const primaryAttendeeIds = values.primaryAttendee.map((attendee) => attendee.crmAccountId)
      const additionalAttendeeIds = values.additionalAttendees.map((attendee) => attendee.crmAccountId)

      const attendedeesIds = [...primaryAttendeeIds, ...additionalAttendeeIds].filter((id) => !!id);

      const additionalAttendeesAccounts = await IndexDbCrm.filterById('ACCOUNT', attendedeesIds)

      const isAtleastOnePersonalAccount = additionalAttendeesAccounts
        // eslint-disable-next-line dot-notation
        ?.some((account) => account ? account['IsPersonAccount'] : false);

      if (!isAtleastOnePersonalAccount) {
        rhForm.setError('additionalAttendees',
          { message: 'This field is required' },
        );
        setErrorMessage(GLOBAL_FORM_ERROR.too_small);
        return false;
      }
    }

    return true;
  },
  [CrmIntegrationType.SALESFORCE]: async (_values, _rhForm, _setErrorMessage, _crmIntegrationType) => {
    return true;
  },
};

const useRemoveOrphanCRMTasks = (meetingId: string | undefined, rhForm: UseFormReturn<FormValuesType>) => {
  const { watch } = rhForm
  const { isCRMEnabled } = useAppSettings()
  const primaryAttendee = watch('primaryAttendee');
  const additionalAttendees = watch('additionalAttendees');
  const { crmIntegrationType } = useCRMStatus();
  const isVeeva = crmIntegrationType === CrmIntegrationType.VEEVA;

  const orphanTasks = useCallback( async (attendees: Attendee[], tasks: ObjectWithId[]) => {
    // first we need to get all the attendees with the person id field, sadly attendees object doesn't have that field
    // ask if we can include it in the attendees object so we don't have to do this

    if (!tasks || !tasks.length || !isCRMEnabled || !isVeeva) return

    const accounts = await Promise.all(
      attendees
        .filter((attendee) => attendee.crmAccountId) // Filtering out any falsy values
        .map((attendee) => IndexDbCrm.getById<CRMAccount>('ACCOUNT', attendee.crmAccountId!)), // Getting accounts by attendee id
    );

    // RETURNS TASKS WHICH ACCOUNTS WERE REMOVED
    const tasksToRemove = tasks
      .filter(task => task.Task_WhoId && !accounts.find(account => account.PersonContactId === task.Task_WhoId))
    return tasksToRemove;
  }, [meetingId, isCRMEnabled, isVeeva])

  useEffect(() => {
    if (meetingId && isCRMEnabled && isVeeva) {
      const removeOrphanTasks = async () => {
        const attendees = [...(primaryAttendee || []), ...(additionalAttendees || [])] as unknown as Attendee[]
        // eslint-disable-next-line dot-notation
        const tasks = rhForm.getValues()['Task'] as unknown as ObjectWithId[]
        const tasksToRemove = await orphanTasks(attendees, tasks)

        if (tasksToRemove?.length) {
          // modify rhForm values to remove the orphan tasks
          const newTasks = tasks
            .filter(task => !tasksToRemove
              .find(taskToRemove => taskToRemove.id === task.id),
            )
          rhForm.setValue('Task', newTasks)
        }
      }

      removeOrphanTasks()
    }
  }, [primaryAttendee, additionalAttendees, meetingId, isCRMEnabled, isVeeva]);
}

const useCRMMeetingFormHandler = (props: CRMMeetingFormHandlerProps): CRMMeetingFormHandler => {
  const { meetingId, rhForm } = props;
  const { crmIntegrationType } = useCRMStatus();
  const [crmSubmitState, setCrmSubmitState] = useState<CRM_SUBMIT_STATE>(CRM_SUBMIT_STATE.IDLE);
  useRemoveOrphanCRMTasks(meetingId, rhForm);
  useVeevaWatchFormHandler(rhForm, setCrmSubmitState);

  const canSubmitToCRM: canSubmitToCRMType = async (
    values,
    rhForm,
    setErrorMessage,
    crmIntegrationType,
  ): Promise<boolean> => {
    if (!crmIntegrationType) {
      throw new Error('CRM Integration Type is not defined');
    }

    return integrationTypeValidation[crmIntegrationType](values, rhForm, setErrorMessage, crmIntegrationType);
  };

  return {
    canSubmitToCRM,
    crmIntegrationType,
    crmSubmitState,
    setCrmSubmitState,
  }
};

export default useCRMMeetingFormHandler;
