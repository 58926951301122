import React, { useEffect, useRef, useState } from 'react';
import { Pressable, ScrollView, StyleSheet } from 'react-native';
import {
  Collapsible,
  DNABox,
  DNAButton,
  DNAConditionalWrapper,
  DNADivider,
  DNAText,
  Hoverable,
  Iffy,
  TextField,
  util,
} from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { MeetingType } from '@alucio/aws-beacon-amplify/src/models';
import { isCustomDeckORM, isFolderItemORM } from 'src/types/types';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import { ANALYTICS_EVENT_NAME } from 'src/components/ContentPreviewModalV2/State/MeetingPresentedMetaProvider';
import { NOTES } from './AllNotes';

export const styles = StyleSheet.create({
  hoveredNotes: {
    backgroundColor: colors['color-gray-700'],
    borderRadius: 4,
  },
  inpersonNotesHeader: {
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  virtualNotesHeader: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    backgroundColor: colors['color-gray-800'],
  },
  notesInput: {
    backgroundColor: colors['color-text-white'],
    borderColor: colors['color-gray-100'],
    borderRadius: 4,
    color: colors['color-gray-800'],
    height: '100%',
    outlineWidth: 0,
    paddingHorizontal: 8,
    paddingVertical: 7,
    width: '100%',
  },
  notesPadding: {
    borderRadius: 4,
    padding: 8,
  },
  notesContent: {
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
});

interface MeetingNotesProps {
  openNotes: NOTES[]
  containerDimension: { width: number, height: number }
}

const MeetingNotes: React.FC<MeetingNotesProps> = ({ openNotes, containerDimension }) => {
  const { activePresentation } = useContent();
  const { meetingORM, addNotes, presentedMeta, toggleMeetingNotes } = useMeetingsState();
  const isInPerson = meetingORM?.model.type === MeetingType.IN_PERSON;
  const isMeetingNoteOpen = openNotes.includes(NOTES.MEETING_NOTES);
  const [isEditingNotes, setIsEditingNotes] = useState<boolean>(false);
  const [areMeetingNotesHovered, setAreMeetingNotesHovered] = useState<boolean>(false);
  const [meetingNotes, setMeetingNotes] = useState<string>('');
  const latestMeetingNotes = useRef<NOTES[]>(openNotes);

  // WILL UPDATE THE SLIDE NOTES UPON CHANGING PAGES
  useEffect(() => {
    if (presentedMeta?.note) setMeetingNotes(presentedMeta.note);
    else setMeetingNotes('');
  }, [presentedMeta]);

  useEffect(() => {
    // THE USER OPENED THEIR OWN NOTES
    if (openNotes.includes(NOTES.MEETING_NOTES) &&
      (!latestMeetingNotes.current.includes(NOTES.MEETING_NOTES) || openNotes.length === 1)) {
      const customDeckId = isFolderItemORM(activePresentation?.presentable.orm) &&
        isCustomDeckORM(activePresentation?.presentable.orm.relations.itemORM)
        ? activePresentation?.presentable.orm.relations.itemORM.model.id : undefined

      analytics?.track(ANALYTICS_EVENT_NAME.SLIDE_NOTES_OPENED, {
        category: 'MEETING',
        context: 'MEETING',
        documentId: activePresentation?.currentPresentablePage?.documentVersionORM.model.documentId,
        documentVersionId: activePresentation?.currentPresentablePage?.documentVersionORM.model.id,
        customDeckId,
        folderItemId: isFolderItemORM(activePresentation?.presentable.orm)
          ? activePresentation?.presentable.orm.model.id : undefined,
        meetingId: meetingORM?.model.id,
        pageNumber: activePresentation?.currentPresentablePage?.page.number,
      })
    }
    latestMeetingNotes.current = openNotes;
  }, [openNotes]);

  const onHoverIn = (): void => {
    setAreMeetingNotesHovered(true);
  };

  const onHoverOut = (): void => {
    setAreMeetingNotesHovered(false);
  };

  const handleAllowEditNotes = (): void => {
    setIsEditingNotes(true);
  };

  const handleOnNotesChange = (notes: string): void => {
    setMeetingNotes(notes);
  }

  const handleSaveNotes = (): void => {
    addNotes(meetingNotes);
    setIsEditingNotes(false);
  };

  if (isInPerson && !isMeetingNoteOpen) return null
  return (
    <DNABox appearance="col" fill={isMeetingNoteOpen}>
      {/* In Person Header */}
      <Iffy is={isInPerson}>
        <DNABox spacing="between" alignY="center" style={styles.inpersonNotesHeader}>
          <DNAText status="basic">Comments</DNAText>
          <DNAButton
            status="dark"
            appearance="filled"
            iconLeft="close"
            onPress={toggleMeetingNotes}
            testID="close-speaker-notes-button"
          />
        </DNABox>
      </Iffy>
      {/* Virtual Header */}
      <Iffy is={!isInPerson}>
        <Pressable onPress={toggleMeetingNotes}>
          <DNABox
            spacing="sm"
            alignY="center"
            style={styles.virtualNotesHeader}
          >
            <DNAButton
              status="dark"
              appearance="ghost"
              rounded="full"
              padding="xs"
              iconLeft={isMeetingNoteOpen ? 'chevron-down' : 'chevron-right'}
              onPress={toggleMeetingNotes}
            />
            <DNAText status="basic">Comments</DNAText>
          </DNABox>
        </Pressable>
      </Iffy>
      <DNADivider variant="virtual" />
      {/* Content */}
      <DNABox fill>
        <DNAConditionalWrapper
          condition={!isInPerson}
          wrapper={children => <Collapsible collapsed={!isMeetingNoteOpen}>{children}</Collapsible>}
        >
          <Iffy is={isEditingNotes}>
            <DNABox
              fill={isInPerson}
              style={util.mergeStyles(undefined,
                styles.notesContent,
                [containerDimension, !isInPerson],
              )}
              appearance="col"
            >
              <DNAButton
                size="sm"
                onPress={handleSaveNotes}
                style={{ marginBottom: 20, minWidth: 271 }}
              >
                Done
              </DNAButton>
              <TextField.Native
                autoFocus
                maxLength={1000}
                onBlur={handleSaveNotes}
                onChangeText={handleOnNotesChange}
                multiline
                value={meetingNotes}
                style={styles.notesInput}
              />
            </DNABox>
          </Iffy>
          <Iffy is={!isEditingNotes}>
            <DNABox
              fill={isInPerson}
              style={util.mergeStyles(undefined,
                styles.notesContent,
                [containerDimension, !isInPerson],
              )}
            >
              <Hoverable onHoverIn={onHoverIn} onHoverOut={onHoverOut}>
                <Pressable onPress={handleAllowEditNotes} style={{ width: '100%' }}>
                  <ScrollView style={areMeetingNotesHovered && styles.hoveredNotes}>
                    <DNAText
                      status={meetingNotes !== '' ? 'basic' : 'flat'}
                      style={styles.notesPadding}
                    >
                      {meetingNotes === '' ? 'Enter comments...' : meetingNotes}
                    </DNAText>
                  </ScrollView>
                </Pressable>
              </Hoverable>
            </DNABox>
          </Iffy>
        </DNAConditionalWrapper>
      </DNABox>
    </DNABox>
  )
}

export default MeetingNotes
