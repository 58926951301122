import React, { useMemo } from 'react'
import { DNABox, DNASelect } from '@alucio/lux-ui'
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { useContentPreviewModal } from './State/ContentPreviewModalStateProvider';

const SlideGroupSelector: React.FC = () => {
  const {
    activeDocumentVersionORM,
    selectedIndex,
    setSelectedIndex,
  } = useContentPreviewModal()
  const { addPresentation } = useContent()

  const groups = useMemo(() =>
    [...(activeDocumentVersionORM?.relations.pageGroups || [])]
      .sort((a, b) =>
        a.model.name.localeCompare(b.model.name)), [activeDocumentVersionORM]);

  const onGroupChange = e => {
    if (groups) {
      const { row } = e
      if (row === 0) {
        setSelectedIndex(e)
        activeDocumentVersionORM && addPresentation(activeDocumentVersionORM)
      } else {
        const group = groups[row - 1]
        setSelectedIndex(e)
        addPresentation(group)

        // ANALYTIC TRACKING: group shown in the content preview modal
        analytics?.track('CONTENT_PREVIEW', {
          pageGroupId: group.model.id,
        })
      }
    }
  }

  const selectedValue = (): string => {
    if (selectedIndex.row === 0) {
      return 'All slides'
    } else if (groups) {
      return groups[selectedIndex.row - 1].model.name
    }
    throw Error('unknown group selected')
  };

  const groupOptions = groups?.map(group => {
    return (
      <DNASelect.Item
        key={group.model.id}
        title={group.model.name}
      />
    )
  })

  const firstOption = <DNASelect.Item key="allSlides" title="All slides" />

  groupOptions?.unshift(firstOption)

  return (
    <DNABox alignY="center" fill testID="slide-groups-selection">
      <DNASelect
        style={{ minWidth: 190, maxWidth: 260 }}
        value={selectedValue()}
        onSelect={onGroupChange}
      >
        {groupOptions}
      </DNASelect>
    </DNABox>
  )
}

export default SlideGroupSelector
