import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  PropsWithChildren,
} from 'react';
import { StyleSheet } from 'react-native';
import { Controller } from 'react-hook-form';
import {
  DNABox,
  DNAText,
  DNAButton,
  DNACard,
  DNAContextMenu,
  Iffy,
  TextField,
  } from '@alucio/lux-ui/src';
  import { Input } from '@ui-kitten/components';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { HubSectionType } from '@alucio/aws-beacon-amplify/src/models';
import { ModifiedHubSection } from 'src/state/context/Hubs/HubsStateProvider';
import { EditHubRHForm } from 'src/screens/Hubs/EditHub/useHubForm';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import { rhFormFieldName, useHubRHFromState } from 'src/screens/Hubs/EditHub/useHubRHFormStateProvider';

// Widgets
import Textarea from 'src/screens/Hubs/EditHub/Widgets/Textarea';
import SharedFiles from 'src/screens/Hubs/EditHub/Widgets/SharedFiles';
import ToDoList from 'src/screens/Hubs/EditHub/Widgets/ToDoList';
import Links from 'src/screens/Hubs/EditHub/Widgets/Links';

export const widgetContentCardStyles = StyleSheet.create({
  card: {
    borderColor: colors['color-gray-80'],
    borderWidth: 1,
    borderRadius: 4,
  },
  cardHeader: {
    minHeight: 64,
    maxHeight: 64,
    paddingHorizontal: 24,
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
  },
  cardBody: {
    minHeight: 72,
    padding: 24,
  },
  contextMenuItems: {
    minWidth: 160,
    right: 66,
  },
  contextMenuAnchorBtn: {
    width: 32,
    height: 32
  },
  editHubTitleIcon: {
    marginLeft: 6,
  },
  editHeaderInput: {
    maxWidth: '100%',
  },
});

export interface WidgetContentCardProps {
  widget: ModifiedHubSection,
  rhForm: EditHubRHForm,
}

const WidgetContentCardVariants: Record<HubSectionType, React.FC<WidgetContentCardProps>> = {
  [HubSectionType.TEXTAREA]: Textarea,
  [HubSectionType.SHARED_FILES]: SharedFiles,
  [HubSectionType.TODO_LIST]: ToDoList,
  [HubSectionType.LINKS]: Links,
}

const WidgetContentCard: React.FC<WidgetContentCardProps> = ({ widget, rhForm }) => {
  const WidgetVariant = WidgetContentCardVariants[widget.type]

  return (
    <DNACard
      style={ widgetContentCardStyles.card }
      appearance="flat"
    >
      <Controller
        name={rhFormFieldName[widget.type]}
        control={rhForm.control}
        render={() => (
          <WidgetVariant widget={widget} rhForm={rhForm}/>
        )}
      />
    </DNACard>
  )
}

export default WidgetContentCard


// Below are reusable components for widgets

type WidgetContextMenuProp = {
  widgetType: HubSectionType
  id: string
}

export const WidgetContextMenu: React.FC<WidgetContextMenuProp> = ({ widgetType, id }) => {
  const { handleDeleteWidget } = useHubsState()
  const { rhForm } = useHubRHFromState()
  return (
    <DNAContextMenu style={ widgetContentCardStyles.contextMenuItems }>
      <DNAContextMenu.Anchor>
        <DNAButton
          iconRight="dots-vertical"
          appearance="outline"
          status="tertiary"
          padding="xs"
          size="md"
          style={ widgetContentCardStyles.contextMenuAnchorBtn }
          testID={`widget-section-header-${widgetType.toLowerCase()}-ellipsis-button`}
        />
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        <DNAContextMenu.Item
          collapseOnPress
          delay={100} // Workaround because sometimes the item deletes too fast before the ContextMenu can hide itself
          title="Delete"
          status="danger"
          iconStatus="danger"
          icon="trash-can-outline"
          onPress={() => handleDeleteWidget(widgetType, rhForm, id)}
        />
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

type WidgetCardHeaderProps = {
  widget: ModifiedHubSection
  rightElement?: React.ReactElement
}

export const WidgetCardHeader: React.FC<WidgetCardHeaderProps> = (props) => {
  const { widget, rightElement } = props
  const { rhForm, isSaving } = useHubRHFromState()
  const { control, setValue, getValues, trigger, formState: { errors } } = rhForm
  const [editHeaderMode, setEditHeaderMode] = useState<boolean>(false)
  const [sectionHeader, setSectionHeader] = useState<string>(widget.sectionHeader || '')
  const [previousSectionHeader, setPreviousSectionHeader] = useState<string>(widget.sectionHeader || '')
  const headerRef = useRef<Input>(null)
  const formName = rhFormFieldName[widget.type]

  useEffect(() => {
    if (isSaving) {
      const widgetFormValue = getValues(formName)
      setEditHeaderMode(false)
      setSectionHeader(widgetFormValue?.sectionHeader || '')
      setPreviousSectionHeader(widgetFormValue?.sectionHeader || '')
    }
  }, [isSaving])

  useEffect(() => {
    if (editHeaderMode) headerRef.current?.focus()
  }, [editHeaderMode])

  const onDone = useCallback(() => {
    const widgetFormValue = getValues(formName)
    setSectionHeader(widgetFormValue?.sectionHeader || '')
    setPreviousSectionHeader(widgetFormValue?.sectionHeader || '')
    setEditHeaderMode(false)
  }, [getValues])

  const cancelHeaderChange = useCallback(() => {
    setEditHeaderMode(false)
    setSectionHeader(previousSectionHeader)
    setValue(`${formName}.sectionHeader`, previousSectionHeader)
    trigger(`${formName}.sectionHeader`)
  }, [getValues, setValue, previousSectionHeader])
  
  return (
    <DNABox
      fill
      style={widgetContentCardStyles.cardHeader}
    >
      <DNABox
        fill
        alignY="center"
        childFill={0}
      >
        {/* Hub Title Read Only Mode - Text & Edit Button & rightElement */}
        <Iffy is={!editHeaderMode}>
          <DNABox testID='widget-card-header' fill childFill={0} alignY="center">
            <DNAText
              h4
              bold
              numberOfLines={1}
              style={ widgetContentCardStyles.editHeaderInput }
              testID="widget-card-header-text"
            >
              { sectionHeader }
            </DNAText>
            <DNAButton
              appearance="ghostLink"
              status="gray"
              iconLeft="pencil"
              padding="sm"
              size="md"
              style={ widgetContentCardStyles.editHubTitleIcon }
              onPress={() => setEditHeaderMode(true)}
            />
          </DNABox>
          { rightElement }
        </Iffy>
        {/* Edit Hub Title Mode - Input & Buttons */}
        <Iffy is={editHeaderMode}>
        <DNABox fill alignY="center">
          <Controller
            name={`${formName}.sectionHeader`}
            rules={{ required: false }}
            control={control}
            defaultValue={sectionHeader}
            render={() => (
              <DNABox fill alignY="center" spacing="sm" childFill={0}>
                <TextField.Kitten
                  testID='hub-sub-header-text-input-box'
                  ref={headerRef}
                  status={errors[formName]?.sectionHeader && 'danger'}
                  onChangeText={(text) => {
                    setSectionHeader(text.trimStart())
                    rhForm.setValue(`${formName}.sectionHeader`, text.trimStart())
                  }}
                  value={sectionHeader}
                  maxLength={150}
                  onKeyPress={(e) => {
                    // we allow empty input for textareaWidget header only
                    if (e.nativeEvent.key === 'Enter' && (formName === "textareaWidget" || sectionHeader)) {
                      e.preventDefault()
                      onDone()
                    }
                  }}
                  accessoryRight={() => (
                    sectionHeader.length > 0
                      ? <DNAButton
                          onPress={() => setSectionHeader('')}
                          appearance="ghostLink"
                          status="gray"
                          iconLeft="close-circle"
                          size="sm"
                          padding="none"
                      /> : <></>
                    )
                  }
                />
                <DNAButton
                  appearance="outline"
                  status="tertiary"
                  padding="sm"
                  onPress={cancelHeaderChange}
                  children="Cancel"
                  testID="hub-sub-title-cancel-btn"
                />
                <DNAButton
                  padding="sm"
                  onPress={onDone}
                  children="Done"
                  disabled={ formName !== "textareaWidget" && !sectionHeader }
                  testID="hub-sub-title-done-btn"
                />
              </DNABox>
            )}
          />
        </DNABox>
        </Iffy>
      </DNABox>
    </DNABox>
  )
}

export const WidgetCardBody: React.FC<PropsWithChildren> = (props) => {
  const { children, ...rest } = props
  return (
    <DNABox
      fill
      appearance="col"
      spacing="md"
      alignY="center"
      style={widgetContentCardStyles.cardBody}
      {...rest}
    >
      { children }
    </DNABox>
  )
}
