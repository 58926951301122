import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import EditableText from '../EditableText/EditableText';
import { DNABox, DNAButton, Iffy, luxColors } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { LuxSizeEnum } from '@alucio/lux-ui/src/typings';

const styles = StyleSheet.create({
  commonPadding: {
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 4,
    paddingTop: 8,
  },
  inputStyle: {
    backgroundColor: colors['color-text-white'],
    borderColor: colors['color-gray-100'],
  },
});

interface EmailDrawerHeaderProps {
  closeDrawer: () => void,
  onChangeTitle: (title: string) => void,
  title: string,
  placeHolder?: string,
  error?: string | undefined,
}

const EmailDrawerHeader:React.FC<EmailDrawerHeaderProps> = ({
  title,
  onChangeTitle,
  placeHolder,
  error,
  closeDrawer,
}) => {
  const [isInputShown, setIsInputShown] = useState<boolean>(false);

  return (
    <View style={[styles.commonPadding, styles.header]}>
      <EditableText
        value={title}
        required={true}
        onChangeText={onChangeTitle}
        size={LuxSizeEnum.md}
        toggleShowInputIcon={''}
        triggerInputShown={setIsInputShown}
        inputStyle={styles.inputStyle}
        placeHolder={placeHolder}
        placeholderTextColor={luxColors.contentText.quaternary}
        error={error}
        testID="email-template-name-input-text-box"
        immediateUpdate
      />
      <Iffy is={!isInputShown}>
        <DNABox style={{ flexShrink: 1 }}>
          <DNAButton
            status="tertiary"
            appearance="ghost"
            iconLeft="close"
            onPress={closeDrawer}
          />
        </DNABox>
      </Iffy>
    </View>
  );
};

EmailDrawerHeader.displayName = 'EmailDrawerHeader';

export default EmailDrawerHeader;
