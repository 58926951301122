import React from 'react';
import { DNABox, Iffy } from '@alucio/lux-ui';
import { CrmIntegrationType } from '@alucio/aws-beacon-amplify/src/models';
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import { CRMConnectionStatus, CRMSyncStatus } from 'src/state/machines/CRM/crmMachineTypes';
import VeevaLocation from './VeevaLocation';

const CRMPrimaryAttendeeComponents = () => {
  const { connectionStatus, syncStatus, crmIntegrationType } = useCRMStatus();

  if (connectionStatus !== CRMConnectionStatus.CONNECTED || syncStatus !== CRMSyncStatus.SYNCED) {
    return null;
  }

  return (
    <DNABox fill>
      <Iffy is={crmIntegrationType === CrmIntegrationType.VEEVA}>
        <VeevaLocation />
      </Iffy>
    </DNABox>
  );
};

export default CRMPrimaryAttendeeComponents;
