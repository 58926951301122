import React, { useEffect } from 'react'
import {
  Iffy,
  DNABox,
  DNAButton,
  DNAText,
  DNAIcon,
} from '@alucio/lux-ui'
import { filterCollection } from 'src/state/redux/selector/common'
import { filters, sorts, merge } from 'src/state/redux/folder/query'
import { FolderORM, isDocumentVersionORM } from 'src/types/types'
import { DisplayModes } from 'src/components/DNA/hooks/useDisplayMode'
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage'
import DNAFlatList from 'src/components/DNA/FlatList/DNAFlatList'
import DNADefaultHeader from 'src/components/DNA/FlatList/Headers/DNADefaultHeaders'
import DNAGrid from 'src/components/DNA/Grid/DNAGrid'
import { S, useDNAMyFoldersSharedResources } from './DNAMyFolders'
import { Pressable } from 'react-native';

const ArchivedFolders: React.FC = () => {
  const {
    displayMode,
    displayModeIconName,
    toggleDisplayMode,
    folderNav,
    folderNavURL,
    onPress,
    emptyVariantType,
  } = useDNAMyFoldersSharedResources()
  const { setDisplaySharedFolders } = folderNav

  const archivedFolders = filterCollection(
    folderNav.currentFolders,
    merge(filters.archived, filters.rootFolder, sorts.pinnedAsc),
  )
  const route = useCurrentPage({ exact: false })
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const archivedFoldersTab = !!route?.PATH.includes('archived_folders')
  const viewerItems =  folderNav.currentItems.filter(
    item => (
      isDocumentVersionORM(item.relations.itemORM)
        ? item.relations.itemORM.model.status !== 'NOT_PUBLISHED'
        : true
    ),
  )
  const items = folderNav.isCurrentlyNested ? isPublisherRoute ? folderNav.currentItems : viewerItems : archivedFolders

  useEffect(() => {
    if (archivedFoldersTab) setDisplaySharedFolders(false)
  }, [archivedFoldersTab]);

  return (
    <DNABox fill appearance="col">
      {/* Breadcrumb Nav */}
      <DNABox
        alignY="center"
        spacing="between"
        style={S.header}
        childFill={0}
        childStyle={[1, { marginLeft: 3 }]}
      >
        <DNABox alignY="center" fill>
          <DNAButton appearance="ghostLink" status="gray" padding="none" onPress={() => folderNavURL.popToFolder()}>
            <DNAText testID="page-title" status="secondary" style={S.breadcrumb}>
              Archived Folders
            </DNAText>
          </DNAButton>
          {folderNav.folderStack.map(folderORM => (
            <DNABox shrink>
              <DNAIcon.Styled
                name="chevron-right"
                appearance="ghostLink"
                status="gray"
                style={S.chevronIcon}
              />
              <Pressable onPress={() => folderNavURL.popToFolder(folderORM)} style={{ flexShrink: 1 }}>
                <DNAText status="secondary" style={S.breadcrumb} ellipsizeMode="tail" numberOfLines={1}>
                  {folderORM.model.name}
                </DNAText>
              </Pressable>
            </DNABox>
          ))}
        </DNABox>

        {/* Right Controls */}
        <DNABox spacing="md">
          {/* list/grid button */}
          <DNAButton
            size="md"
            appearance="outline"
            padding="sm"
            status="tertiary"
            iconLeft={displayModeIconName}
            onPress={toggleDisplayMode}
          />
        </DNABox>
      </DNABox>

      {/* Content */}
      <Iffy is={displayMode === DisplayModes.grid}>
        <DNABox style={S.gridViewContainer}>
          <DNAGrid
            items={items}
            onPress={onPress}
            emptyVariant={emptyVariantType}
          />
        </DNABox>
      </Iffy>
      <Iffy is={displayMode === DisplayModes.list}>
        <DNAFlatList<FolderORM>
          items={items}
          onPress={onPress}
          emptyVariant={emptyVariantType}
        >
          <DNADefaultHeader />
        </DNAFlatList>
      </Iffy>
    </DNABox>
  )
}

export default ArchivedFolders;
