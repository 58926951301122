/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://fpyv6a6aafeohheg3yhg72ttjq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:957b8305-eb4c-48ef-9219-315d37b4f533",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_kfe55KmrB",
    "aws_user_pools_web_client_id": "5mcmg4gto8s0ol6v4as6s54f3q",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ObjectAudit-prod",
            "region": "us-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "alucio-beacon-content223921-prod",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
