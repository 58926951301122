import { DNABox } from '@alucio/lux-ui'
import React from 'react'
import { StyleSheet } from 'react-native'
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings'
import { FolderORM } from 'src/types/orms'
import { ActionBarOption, useInfoPanelState } from '../State/InfoPanelStateProvider'
import Details, { DetailsProps } from './Details/Details'
import Sharing, { SharingProps } from './Sharing/Sharing'

interface ContentPanelsProps {
  itemORM: FolderORM
  /** TODO: add this type in the future once documents are supported */
  // | DocumentORM
}

type ContentPanelOptionTypes = React.ElementType<SharingProps> | React.ElementType<DetailsProps>

type ContentPanelOptions = Record<ActionBarOption, ContentPanelOptionTypes>
const contentPanelOptions:ContentPanelOptions = {
  Sharing: Sharing,
  Details: Details,
}

type DeviceModeStyles = Record<DeviceMode, StyleSheet.NamedStyles<{ [keys: string]: StyleSheet }>>
const deviceModeStyles:DeviceModeStyles = {
  desktop: StyleSheet.create({
    container: {
      width: 225,
    },
  }),
  tablet: StyleSheet.create({
    container: {
      width: 285,
    },
  }),
}

const ContentPanels: React.FC<ContentPanelsProps> = (
  { itemORM },
) => {
  const { activeActionBarOption, collapsed } = useInfoPanelState()
  const { deviceMode } = useAppSettings()

  if (collapsed) return null

  const CurrentContentPanelOption = contentPanelOptions[activeActionBarOption]
  const { container } = deviceModeStyles[deviceMode]

  return (
    <DNABox style={container}>
      <CurrentContentPanelOption itemORM={itemORM} />
    </DNABox>
  )
}

export default ContentPanels
