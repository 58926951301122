import React, { createContext, useContext } from 'react';
import { DNAActionBarItemProps, DNAActionBarProps } from './DNAActionBar';

interface DNAActionBarStateType {
    actionBarItemDefaults: DNAActionBarItemProps,
    // setActionBarItemDefaults: React.Dispatch<React.SetStateAction<DNAActionBarItemProps>>
}

const staticActionBarItemDefaults: DNAActionBarItemProps = {
  appearance: 'filled',
  status: 'dark',
  size: 'md',
  padding: 'sm',
}

const DNAActionBarStateContext = createContext<DNAActionBarStateType | null>(null!)
DNAActionBarStateContext.displayName = 'DNAActionBarContext'

interface DNAActionBarInitialValues extends Pick<DNAActionBarProps, 'itemDefaults' | 'children'> {}

/**
 * This state provider provides a way to pass default props which can be consumed by the action bar items
 */
const DNAActionBarStateProvider: React.FC<DNAActionBarInitialValues> = ({
  children,
  ...initialValues
}) => {
  const actionBarItemDefaults:DNAActionBarItemProps = initialValues.itemDefaults ?? staticActionBarItemDefaults

  /** SECTION: Handlers */
  /** Uncomment if additional context initialization is necessary */
  // const handleContextInit = () => {}
  // useEffect(handleContextInit, [])
  /** !SECTION: Handlers */

  const contextValue: DNAActionBarStateType = {
    actionBarItemDefaults,
    // setActionBarItemDefaults,
  }

  return (
    <DNAActionBarStateContext.Provider value={contextValue}>
      {children}
    </DNAActionBarStateContext.Provider>
  )
}

DNAActionBarStateProvider.displayName = 'DNAActionBar State Provider'

export const useDNAActionBarState = () => {
  const context = useContext(DNAActionBarStateContext)
  if (!context) {
    throw new Error('useDNAActionBarState must be used within the DNAActionBar State Provider')
  }
  return context;
}

export default DNAActionBarStateProvider
