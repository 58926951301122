import { FilterAndSortOptions } from 'src/state/redux/selector/common'
import { MeetingORM } from 'src/types/orms'

type FilterSortMap<T extends string> = {
  [K in T]: FilterAndSortOptions<MeetingORM>
}

export const sorts: FilterSortMap<'startTimeAsc'> = {
  startTimeAsc: {
    sort: [
      { model: { startTime: 'asc' } },
    ],
  },
}

export default {
  sorts,
}
