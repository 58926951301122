import { isDocumentVersionORM, isFolderItemORM } from 'src/types/typeguards';
import { FOLDER_ITEM_STATUS, FOLDER_ITEM_TYPE } from '@alucio/aws-beacon-amplify/src/API';
import { v4 as uuid } from 'uuid';
import { DocumentORM, FolderItemORM } from 'src/types/orms';

export const addFolderItem = (item: FolderItemORM | DocumentORM) => {
  const now = new Date().toISOString();
  if (isFolderItemORM(item)) {
    return {
      ...item.model,
      type: FOLDER_ITEM_TYPE[item.model.type],
      addedAt: now,
      status: FOLDER_ITEM_STATUS.ACTIVE,
      updateAcknowledgedAt: now,
      itemLastUpdatedAt: now,
    }
  }

  const { latestPublishedDocumentVersionORM } = item.relations.version;
  if (!latestPublishedDocumentVersionORM) {
    throw new Error('Could not find latest published version');
  }

  const documentVersionId = latestPublishedDocumentVersionORM.model.id;
  return {
    type: FOLDER_ITEM_TYPE.DOCUMENT_VERSION,
    status: FOLDER_ITEM_STATUS.ACTIVE,
    id: uuid(),
    itemId: documentVersionId,
    addedAt: now,
    itemLastUpdatedAt: now,
    updateAcknowledgedAt: now,
  }
}

export const updateFolderDocumentVersion = (item) => {
  const now = new Date().toISOString();
  const currDocVersion = isDocumentVersionORM(item.relations.itemORM)
    ? item.relations.itemORM : undefined;

  if (!currDocVersion) {
    throw new Error('Could not find document version');
  }

  const targetParentDoc = currDocVersion.relations.documentORM;
  const targetNewDocVerId = targetParentDoc.relations.version.latestPublishedDocumentVersionORM?.model.id;

  if (!targetNewDocVerId) {
    throw new Error('Could not determine document version to update to');
  }

  analytics?.track('FOLDER_ITEM_UPDATE_VERSION', {
    action: 'ITEM_UPDATE_VERSION',
    category: 'FOLDER',
    folderItemId: item.model.id,
  });

  return {
    ...item.model,
    itemId: targetNewDocVerId,
    itemLastUpdatedAt: now,
    updateAcknowledgedAt: now,
  };
}
