import { useSelector } from 'react-redux';
import { createSelector, Selector } from '@reduxjs/toolkit';
import { CustomFormRecord, CustomFormRecordEntity } from '@alucio/aws-beacon-amplify/src/models';
import { RootState } from '../store';
import { CustomFormRecordORM, ORMTypes } from 'src/types/types';
import { getConvertedModelValuesToForm } from 'src/components/CustomFields/ComposableFormUtilities';
export const selCustomFormRecords = (state: RootState): CustomFormRecord[] => state.customFormRecord.records;

const toCustomFormRecordORM = (customFormRecord: CustomFormRecord): CustomFormRecordORM => {
  const customFormRecordORM: CustomFormRecordORM = {
    model: customFormRecord,
    type: ORMTypes.CUSTOM_FORM_RECORD,
    meta: {
      formValues: getConvertedModelValuesToForm(customFormRecord.values),
    },
  }

  return customFormRecordORM
}

const customFormRecordList: Selector<RootState, CustomFormRecordORM[]> = createSelector(
  selCustomFormRecords,
  (customFormRecords): CustomFormRecordORM[] =>
    customFormRecords.map(customFormRecord => toCustomFormRecordORM(customFormRecord)),
);

const customFormRecordByParentApiName: Selector<RootState, CustomFormRecordORM[]> = createSelector(
  customFormRecordList,
  (_: RootState, parentId: string, apiName: string) => ({ parentId, apiName }),
  (customFormRecords, { parentId, apiName }): CustomFormRecordORM[] =>
    customFormRecords.filter((record) => record.model.parentId === parentId &&
      record.model.crmFields?.apiName === apiName && record.model.status !== 'DELETED'),
);

const crmCustomFormRecordByParentId: Selector<RootState, CustomFormRecordORM[]> = createSelector(
  customFormRecordList,
  (_: RootState, parentId: string) => parentId,
  (customFormRecords: CustomFormRecordORM[], parentId: string): CustomFormRecordORM[] =>
    customFormRecords.filter((record) =>
      CustomFormRecordEntity.CRM_OBJECT === record.model.entity && record.model.parentId === parentId),
);

const customFormRecordById: Selector<RootState, CustomFormRecordORM | undefined> = createSelector(
  customFormRecordList,
  (_: RootState, id: string) => id,
  (customFormRecords, id): CustomFormRecordORM | undefined =>
    customFormRecords.find((record) => record.model.id === id),
);

/** Returns a Custom Form Record by Id */
export const useCustomFormRecordById = (id?: string): CustomFormRecordORM | undefined =>
  useSelector((state: RootState) => id
    ? customFormRecordById(state, id)
    : undefined);

/** Returns a list of Custom Form Records by ParentId and CRM ApiName */
export const useCustomFormRecordByParentIdApiName = (parentId: string, apiName: string): CustomFormRecordORM[] =>
  useSelector((state: RootState) => customFormRecordByParentApiName(state, parentId, apiName));

/** Returns a list of Custom Form Records by ParentId */
export const useCRMCustomFormRecordByParentId = (parentId?: string): CustomFormRecordORM[] =>
  useSelector((state: RootState) => crmCustomFormRecordByParentId(state, parentId));
