import React, { useContext } from 'react';
import { QuickFiltersContext } from './QuickFiltersProvider';
import { DNACard } from '@alucio/lux-ui';

const QuickFiltersHome : React.FC = () => {
  const { renderPathLabels } = useContext(QuickFiltersContext);

  return (
    <DNACard appearance="outline" style={{ padding: 12 }}>
      {renderPathLabels()}
    </DNACard>
  )
}

export default QuickFiltersHome;
