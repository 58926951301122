import { useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormValuesType } from 'src/components/CustomFields/ComposableForm';
import { useGetFormConfigFromIndexDB } from 'src/state/redux/selector/crm';
import { TABLES } from 'src/classes/CRM/Translators/VeevaTranslatorUtils';
import { getRHWatchArrayToObject, isArrayOfObjects } from 'src/components/CustomFields/ComposableFormUtilities';
import { FormSettings, LookupRecord } from 'src/classes/CRM/CRMIndexedDBTypes';
import { getProductGroups } from 'src/classes/CRM/Translators/VeevaMarkerHandler';
import { CRM_SUBMIT_STATE } from 'src/components/Meeting/AddMeetingProvider';
import { useInquiryWatch } from './useInquiryWatch';

// THIS HOOK WILL WATCH AND HANDLE ONCHANGE BEHAVIORS OF SPECIFIC VEEVA FIELDS WITHIN FORM
const useVeevaWatchFormHandler = (rhForm: UseFormReturn<FormValuesType>,
  setCRMSubmitState: (error: CRM_SUBMIT_STATE) => void): void => {
  useMedicalDiscussionWatch(rhForm);
  useCallDiscussionWatch(rhForm);
  useInquiryWatch(rhForm);
  useClearAttendeeSelectionError(rhForm, setCRMSubmitState);
};

// ** MEDICAL DISCUSSION'S HANDLING ** //
// THIS SECTION AIMS TO HANDLE PRODUCT DEPENDENT PICKLIST //
const useMedicalDiscussionWatch = (rhForm: UseFormReturn<FormValuesType>): void => {
  const formSettings = useGetFormConfigFromIndexDB();

  const { objectIdsToWatch: medicalIdsToWatch, productsLookup } =
    useMemo(() => getObjectFieldKeysDependingOnProductsAndProducts({
      tableHoldingTheProducts: TABLES.MEDICAL_DISCUSSION,
      tableToWatch: TABLES.MEDICAL_DISCUSSION,
      formSettings: formSettings,
    }), [formSettings]);

  const arrWatchMedicalDiscussions = rhForm.watch(medicalIdsToWatch);

  // convert arrWatchCallDiscussions to object
  const watchMedicalDiscussions =  useMemo(() =>
    getRHWatchArrayToObject(medicalIdsToWatch, arrWatchMedicalDiscussions), [arrWatchMedicalDiscussions],
  );

  // HANDLES MEDICAL DISCUSSIONS
  useEffect(() => {
    handleProductDependentFieldChange(watchMedicalDiscussions, productsLookup, rhForm);
  }, [watchMedicalDiscussions]);
};

// ** ATTENDEES SELECTION ERROR CLEAR ** //
// IF THERE'S AN ATTENDEE ERROR (NOT SELECTED ATTENDEE), THIS HOOK WILL CLEAR OUT THE ERROR WHEN SELECTED
const useClearAttendeeSelectionError = (rhForm: UseFormReturn<FormValuesType>,
  setCRMSubmitState: (error: CRM_SUBMIT_STATE) => void): void => {
  const [primaryAttendee, additionalAttendees] = rhForm.watch(['primaryAttendee', 'additionalAttendees']);

  useEffect(() => {
    const requiredMessage = 'This field is required';
    const { primaryAttendee: primaryError, additionalAttendees: additionalError } = rhForm.formState.errors;

    if ((primaryError?.message?.includes(requiredMessage) && primaryAttendee.length) ||
      (additionalError?.message?.includes(requiredMessage) && additionalAttendees.length)) {
      setCRMSubmitState(CRM_SUBMIT_STATE.IDLE);
    }
  }, [primaryAttendee, additionalAttendees]);
};

// ** CALLS DISCUSSION'S HANDLING ** //
// THIS SECTION AIMS TO HANDLE PRODUCT DEPENDENT PICKLIST //
const useCallDiscussionWatch = (rhForm: UseFormReturn<FormValuesType>): void => {
  const formSettings = useGetFormConfigFromIndexDB();

  const { objectIdsToWatch: callDiscussionIdsToWatch, productsLookup } =
    useMemo(() => getObjectFieldKeysDependingOnProductsAndProducts({
      tableHoldingTheProducts: TABLES.CALL_DETAIL,
      tableToWatch: TABLES.CALL_DISCUSSION,
      formSettings: formSettings,
      includeProductsGroups: true,
    }), [formSettings]);

  const arrWatchCallDiscussions = rhForm.watch(callDiscussionIdsToWatch);

  // convert arrWatchCallDiscussions to object
  const watchCallDiscussions = useMemo(() =>
    getRHWatchArrayToObject(callDiscussionIdsToWatch, arrWatchCallDiscussions), [arrWatchCallDiscussions],
  );

  useEffect(() => {
    handleProductDependentFieldChange(watchCallDiscussions, productsLookup, rhForm);
  }, [watchCallDiscussions]);
};

// WHEN AN OBJECT THAT HAS A FIELD, THAT DEPENDS ON A PRODUCT, CHANGES, THIS
// FUNCTION WILL ADD THE PRODUCT'S ID/LABEL TO THE "PLACEHOLDER" FIELD SO
// THE FIELDS DEPENDING ON IT CAN GET ENABLED/DISABLED ACCORDINGLY
function handleProductDependentFieldChange(
  objectsToWatch: FormValuesType,
  productsLookup: {[id: string]: string},
  rhForm: UseFormReturn<FormValuesType>): void {
  Object.keys(objectsToWatch).forEach((objectFieldKey) => {
    const objectFieldRecords = objectsToWatch[objectFieldKey];
    if (Array.isArray(objectFieldRecords) && isArrayOfObjects(objectFieldRecords)) {
      objectFieldRecords.forEach((object, idx) => {
        const selectedProductId = object[`${objectFieldKey}_Product_vod__c`];
        const selectedProductLabel = productsLookup[selectedProductId];
        const zvodValues = object[`${objectFieldKey}_zvod_Product_Map_vod__c`];
        const productKeyInObject = `${objectFieldKey}_zvod_Product_Map_vod__c`;
        const productMapKey = `${objectFieldKey}.${idx}.${productKeyInObject}`;

        // IF THERE'S A SELECTED PRODUCT, SET ITS LABEL TO THE "PLACEHOLDER" FIELD SO THE FIELDS THAT
        // DEPEND ON IT GET ENABLED, OTHERWISE, CLEAN THE "PLACEHOLDER" FIELD
        if (selectedProductLabel && !zvodValues?.includes(selectedProductLabel)) {
          rhForm.setValue(productMapKey, [selectedProductLabel]);
        } else if (!selectedProductId && object[productKeyInObject]?.length) {
          rhForm.setValue(productMapKey, []);
        }
      });
    }
  });
}

interface ProductsDependencies {
  tableToWatch: string,
  tableHoldingTheProducts?: string,
  formSettings?: FormSettings[],
  includeProductsGroups?: boolean,
}

// FOR DEPENDENT FIELDS WITH PRODUCTS, THIS FUNCTION WILL BE USED FOR PRODUCT'S WATCH. IT'LL RETURN AN INDEXED
// LIST OF PRODUCTS AND THE FIELDNAME OF OBJECTS THAT HAVE PRODUCT FIELDS TO WATCH
function getObjectFieldKeysDependingOnProductsAndProducts(params: ProductsDependencies):
  { objectIdsToWatch: string[], productsLookup: {[id: string]: string}} {
  const { tableHoldingTheProducts, tableToWatch, formSettings, includeProductsGroups } = params;
  // GETS THE FORM SETTINGS THE OBJECTS THAT HAS FIELDS THAT DEPEND ON PRODUCT MAPS
  const formSettingsWithProductMap = formSettings?.filter((setting) =>
    setting.apiName === tableToWatch && setting.objectInfos.dependentFields.zvod_Product_Map_vod__c);
  let lookups = formSettingsWithProductMap?.[0]?.lookups;

  if (tableHoldingTheProducts) {
    lookups = formSettings?.find(({ apiName }) => apiName === tableHoldingTheProducts)?.lookups;
  }

  const objectIdsToWatch = formSettingsWithProductMap?.map(({ id }) => id) || [];
  // GETS THE PRODUCTS LOOKUPS
  const productsLookup = includeProductsGroups
    ? getProductWithGroupsMapped(lookups?.Product_vod__c, lookups?.Product_Group_vod__c)
    : getProductsMapped(lookups?.Product_vod__c);
  return { objectIdsToWatch, productsLookup };
}

// RETURNS AN INDEXED OBJECT OF THE PRODUCT IDS WITH THEIR NAMES
function getProductsMapped(productLookup?: LookupRecord): {[id: string]: string} {
  return productLookup?.records.reduce<{[id: string]: string}>((acc, record) => {
    acc[record.fields.Id] = record.fields.Name;
    return acc;
  }, {}) || {};
}

// RETURNS AN INDEXED OBJECT OF THE PRODUCT IDS WITH THEIR NAMES + GROUP NAMES
function getProductWithGroupsMapped(
  productLookup?: LookupRecord, groupLookup?: LookupRecord): {[id: string]: string} {
  return productLookup?.records.reduce<{[id: string]: string}>((acc, record) => {
    if (record.fields.Product_Type_vod__c === 'Detail') {
      const productGroups = getProductGroups(
        productLookup.records,
        groupLookup?.records || [], record.fields.Id);

      if (productGroups.length) {
        productGroups.forEach((group) => {
          acc[`${record.fields.Id}.${group.fields.Id}`] = `${record.fields.Name} | ${group.fields.Name}`;
        });
      } else {
        acc[record.fields.Id] = record.fields.Name;
      }
    }
    return acc;
  }, {}) || {};
}

export function isFieldRequiredInLayout(formSetting: FormSettings, apiName: string): boolean {
  return formSetting.layout.sections.some((layout) =>
    layout.layoutRows.some((row) =>
      row.layoutItems.some((item) =>
        item.layoutComponents.some((component) =>
          component.apiName === apiName && item.required))));
}

export default useVeevaWatchFormHandler;
