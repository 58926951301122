import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react'
import { FlatList, StyleSheet } from 'react-native'
import { DocumentORM, Variant } from 'src/types/types'
import { DisplayModes } from 'src/components/DNA/hooks/useDisplayMode'
import { DNAText, DNABox, DNAButton, DNADivider, GridList, Iffy, util } from '@alucio/lux-ui'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import CollapsibleCard from 'src/components/CollapsibleCard/CollapsibleCard'
import { DNADocumentListRow } from 'src/components/DNA/GridList/DNAPublisherGridList'
import DNADocumentDefaultCard from 'src/components/DNA/Grid/Cards/default/DNADocumentDefaultCard'
import { FieldHeader, getFieldConfigsForTenant } from 'src/components/DNA/GridList/common'
import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions'
import { useUserTenant } from 'src/state/redux/selector/user'
import { useAppSettings } from 'src/state/context/AppSettings'
import { useSort } from 'src/components/DNA/hooks/useSort'
import { SelectedDocuments } from './DocumentSelectManager'
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags'
import { SearchResultRow } from 'src/components/DNA/GridList/DNADocumentGridList'

const S = StyleSheet.create({
  HeaderCol: {
    paddingVertical: 12,
  },
  MobileHeader: {
    backgroundColor: colors['color-gray-10'],
    paddingHorizontal: 16,
    minHeight: 49,
  },
  MobileRow: {
    paddingHorizontal: 16,
  },
  DataCell: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  cardSpacerStyle: { marginBottom: 16 },
})

type SelectedCollapsibleCardProps = {
  selectedDocuments: DocumentORM[],
  onSelectDocuments: (documents: DocumentORM[]) => void,
  setSelectedDocuments: Dispatch<SetStateAction<SelectedDocuments>>,
  displayMode: DisplayModes,
  isSearch?: boolean,
}

const SelectedCollapsibleCard: React.FC<SelectedCollapsibleCardProps> = (props) => {
  const { selectedDocuments, onSelectDocuments, setSelectedDocuments, displayMode, isSearch } = props
  const featureFlags = useFeatureFlags('enableBulkAddToFolder', 'enableBulkShareEmail')
  const { sorts, toggleSort } = useSort()
  const [isCollapsed, setIsCollapsed] = useState(true);
  const userTenant = useUserTenant()
  const { isOnline, deviceMode, isPWAStandalone } = useAppSettings()
  const isDesktop = deviceMode === 'desktop';
  const fieldConfigs = getFieldConfigsForTenant(userTenant, Variant.MSL, isDesktop)
  const DNADocumentActions = useDNADocumentActions()
  const cols = fieldConfigs
    .reduce(
      (acc, field) => (field.fieldType === 'ACTION_MENU' || selectedDocuments.length ? `${acc} ${field.width}px` : acc),
      '',
    )
  // if none of the selected document are shareable or if user is not online, then disabled the share button
  const isShareButtonDisabled = !selectedDocuments.some(docVer => docVer.meta.permissions.MSLShare) || !isOnline

  const selectedItemsHeaderTitle: ReactElement = (
    <DNABox spacing="md" alignY="center">
      <DNAText b1 bold>
        {`SELECTED (${selectedDocuments.length})`}
      </DNAText>
      {/* Action buttons */}
      <Iffy is={selectedDocuments.length}>
        {featureFlags.enableBulkAddToFolder && <DNAButton
          appearance="outline"
          status="tertiary"
          padding="sm"
          onPress={DNADocumentActions.bulkAddToFolder(selectedDocuments)}
        >
          Add to folders
        </DNAButton>}
        {featureFlags.enableBulkShareEmail && <DNAButton
          appearance="outline"
          status="tertiary"
          padding="sm"
          children={'Share'}
          onPress={DNADocumentActions.bulkShareEmail(selectedDocuments)}
          disabled={isShareButtonDisabled}
        />}
        <DNAButton
          appearance="outline"
          status="tertiary"
          padding="sm"
          children={'Clear'}
          onPress={() => setSelectedDocuments({})}
        />
      </Iffy>
    </DNABox>
  )

  const renderSelectedItem = ({ item : documentORM, index } : { item: DocumentORM, index: number } ) => {
    return (
      <>
        {index ? <DNADivider /> : null}
        <DNADocumentListRow
          isOnline={isOnline}
          isDesktop={isDesktop}
          documentORM={documentORM}
          variant={Variant.MSL}
          onPress={() => onSelectDocuments([documentORM])}
          onThumbnailPress={DNADocumentActions.present(documentORM)}
          fields={fieldConfigs}
          rowStyle={isDesktop ? undefined : S.MobileRow}
          cellStyle={S.DataCell}
          selectEnabled
          checked
          onCheck={() => onSelectDocuments([documentORM])}
        />
      </>
    )
  }

  const renderSearchSelectedItem = ({ item : documentORM, index } : { item: DocumentORM, index: number } ) => {
    const onSelect = onSelectDocuments ? () => onSelectDocuments([documentORM]) : undefined
    return (
      <DNABox>
        {index ? <DNADivider /> : null}
        <SearchResultRow
          documentORM={documentORM}
          selectEnabled
          onCheck={onSelect}
          checked
          onPress={onSelect}
        />
      </DNABox>
    )
  }

  const contentPadding = (isPWAStandalone && selectedDocuments.length) ||
    (isSearch && selectedDocuments.length) ? 'none' : undefined

  return (
    <CollapsibleCard
      headerTitle={selectedItemsHeaderTitle}
      onToggleChanged={(collapsed) => setIsCollapsed(collapsed)}
      isCollapsed={isCollapsed}
      contentPadding={contentPadding}
    >
      <Iffy is={!selectedDocuments.length}>
        <DNAText status="subtle">No selected files</DNAText>
      </Iffy>
      <Iffy is={displayMode === DisplayModes.list}>
        <GridList
          cols={cols}
          gap="12px"
          style={{ width: '100%' }}
        >
          <Iffy is={selectedDocuments.length && !isSearch}>
            <GridList.Header style={util.mergeStyles(undefined, [S.MobileHeader, !isDesktop])}>
              {
              fieldConfigs.map(field => (<FieldHeader
                key={field.fieldName}
                field={field}
                style={S.HeaderCol}
                toggleSort={toggleSort}
                sortIcon={sorts?.[field.fieldName]?.icon}
              />))
            }
            </GridList.Header>
            <DNADivider />
          </Iffy>
          <Iffy is={!isCollapsed}>
            <FlatList
              data={selectedDocuments}
              keyExtractor={(documentORM, index) => `selected-${documentORM.model.id}-${index}`}
              renderItem={isSearch ? renderSearchSelectedItem : renderSelectedItem}
            />
          </Iffy>
        </GridList>
      </Iffy>
      <Iffy is={displayMode === DisplayModes.grid && !isSearch}>
        <DNABox
          fill
          wrap="start"
          spacing="md"
          childStyle={S.cardSpacerStyle}
        >
          {selectedDocuments.map((documentORM, index) => (
            <DNADocumentDefaultCard
              key={`selected-${documentORM.model.id}-${index}`}
              isOnline={isOnline}
              isDesktop={isDesktop}
              ORM={documentORM}
              onPress={DNADocumentActions.preview(documentORM)}
              selectEnabled
              checked
              onSelect={() => onSelectDocuments([documentORM])}
            />
          ))}
        </DNABox>
      </Iffy>
    </CollapsibleCard>
  )
}

export default SelectedCollapsibleCard
