import React from 'react';
import { DNABox, DNAText, Iffy } from '@alucio/lux-ui/src';
import { AssociatedFileType, SharedFileHubSetting, HubSharedAssociatedFile } from '@alucio/aws-beacon-amplify/src/models';
import { DocumentRow, DocumentRowWrapper} from 'src/screens/Hubs/EditHub/Widgets/SharedFilesComponents/DocumentRow';
import AttachedFileRow from 'src/screens/Hubs/EditHub/Widgets/SharedFilesComponents/AttachedFileRow';
import { useDocumentVersionORM } from 'src/state/redux/selector/document';
import { styles } from 'src/screens/Hubs/EditHub/Widgets/SharedFiles';
import { AssociatedFileDetails, RowProps } from 'src/screens/Hubs/EditHub/Widgets/SharedFilesComponents/SharedFilesProvider'

interface SharedFilesDocumentGroupProps extends RowProps {
  sharedFile: SharedFileHubSetting
}

const SharedFilesDocumentGroup: React.FC<SharedFilesDocumentGroupProps> = ({ sharedFile, isLastItem, handleDeleteItem }) => {
  const documentVersionORM = useDocumentVersionORM(sharedFile.contentId)
  const allAssociatedFiles: HubSharedAssociatedFile[] = sharedFile.documentVersionSettings?.associatedFiles || []
  const associatedContents: AssociatedFileDetails[] = []
  allAssociatedFiles.forEach(associatedFile => {
    const associatedFileORM = documentVersionORM?.relations.associatedFiles.find(af => af.model.id === associatedFile.associatedFileId)
    if (associatedFileORM) {
      associatedContents.push({
        ...associatedFile,
        parentId: sharedFile.id,
        isRequired: associatedFileORM?.model.isDefault,
        associatedFileORM,
      })
    }
  })

  if (!documentVersionORM) return null
  return (
    <DNABox appearance="col" style={isLastItem ? undefined : styles.bottomBorder}>
      <DNABox>
        <DocumentRow
          documentVersionORM={documentVersionORM}
          notations={sharedFile.notation}
          isLastItem={true}
          handleDeleteItem={handleDeleteItem}
        />
      </DNABox>
      <Iffy is={associatedContents.length}>
        <DNABox fill appearance="col" style={styles.associatedFilesGroupContainer}>
          <DNABox fill appearance="col" alignY="center" style={styles.associatedFilesGroupBorderBox}>
            <DNABox style={styles.associatedFilesGroupHeader}>
              <DNAText status="flatAlt">Associated Files</DNAText>
            </DNABox>
            {associatedContents.map((associatedContent, index) => {
              if (associatedContent.associatedFileORM.model.type === AssociatedFileType.ATTACHED_FILE) {
                return (
                  <AttachedFileRow
                    key={`attachedFileRow-${associatedContent.parentId}-${associatedContent.associatedFileORM.model.id}`}
                    associatedContent={associatedContent}
                    isLastItem={index === associatedContents.length - 1}
                    handleDeleteItem={handleDeleteItem}
                  />)
              } else if (associatedContent.associatedFileORM.model.type === AssociatedFileType.DOCUMENT) {
                return (
                  <DocumentRowWrapper
                    key={`documentRow-${associatedContent.parentId}-${associatedContent.associatedFileORM.model.id}`}
                    associatedContent={associatedContent}
                    isLastItem={index === associatedContents.length - 1}
                    handleDeleteItem={handleDeleteItem}
                  />
                )
              }
            })}
          </DNABox>
        </DNABox>
      </Iffy>
    </DNABox>
  )
}

export default SharedFilesDocumentGroup