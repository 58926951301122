import { DNABox, DNAButton } from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton'
import { DNABoxProps } from '@alucio/lux-ui/src/components/layout/DNABox/DNABox'
import { PopoverProps } from '@alucio/lux-ui/src/components/visual/Popover/PopoverCore'
import React from 'react'
import DNAActionBarStateProvider, { useDNAActionBarState } from './DNAActionBarStateProvider'

export interface DNAActionBarItemProps extends DNAButtonProps {
  /** TODO: Styling of the popover components is currently unconstrained and not well suited for
   * control via prop passing. In contrast to <DNAButton> & <DNAText> which utilize status and
   * appearance props, the <Popover> component was only designed with passing in styles via the
   * style props. Ideally, we should update the Popover component to support style props that
   * control the appearance without having to manually define styles. See this ticket for
   * additional details:
   * https://alucioinc.atlassian.net/browse/BEAC-3964  */
  _popoverProps?: PopoverProps
  /** This prop differs from disabled in that it will prevent the item
   * from being rendered rather than simply making it non-interactive. */
  hidden?: boolean
}

export interface DNAActionBarProps extends DNABoxProps {
  itemDefaults?: DNAActionBarItemProps
}

type DNAActionBar = React.FC<DNAActionBarProps> & {
  Item: typeof DNAActionBarItem
}

type DNAActionBarItemComponent = React.FC<DNAActionBarItemProps> & {
  PopoverContent: typeof DNAPopover.Content
}

/**
 * This subcomponent of DNAActionBar incorporates both DNAButton and Popover components. Placing a
 * <DNAActionBar.Item.PopoverContent> inside this component will enable the popover functionality.
 * @pararm `popoverProps` - `PopoverProps` object to configure popover behavior
 * */
const DNAActionBarItem: DNAActionBarItemComponent = (
  {
    hidden,
    children,
    _popoverProps,
    ...itemProps
  },
) => {
  const { actionBarItemDefaults } = useDNAActionBarState()

  if (hidden) return null

  /** TODO: Create a reusable utility function which returns these items. See:
   * https://alucioinc.atlassian.net/browse/BEAC-3969
   */
  const nonComposableElementChildren: React.ReactNode[] = []
  const composableElementChildren:
    React.ReactPortal | React.ReactElement<unknown, string | React.JSXElementConstructor<any>>[] = []

  /** This iterator picks out the composable element children for use in the popover content */
  React.Children
    .toArray(children)
    .forEach(child => {
      !React.isValidElement(child)
        ? nonComposableElementChildren.push(child)
        : composableChildComponentTypes.some(type => child.type === type)
          ? composableElementChildren.push(child)
          : nonComposableElementChildren.push(child)
    })

  /** TODO: Consider only allowing a single instance of composable child components.
* In this implementation only the first one will render and all subsequent will be ignored. */
  const popoverContent = composableElementChildren.find(child => child.type === DNAActionBarItem.PopoverContent)

  const buttonContent = nonComposableElementChildren.length ? nonComposableElementChildren : undefined

  return (
    <DNAPopover
      /** TODO: Add support for "menu" popover mode. (Button becomes a toggle for popover
       * content in addition to triggering any functionality passed into the onPress) */
      {..._popoverProps}
      disablePopover={!popoverContent}
    >
      <DNAPopover.Anchor>
        <DNAButton
          {...actionBarItemDefaults}
          {...itemProps}
        >
          {buttonContent}
        </DNAButton>
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        {popoverContent}
      </DNAPopover.Content>
    </DNAPopover>
  )
}

DNAActionBarItem.PopoverContent = DNAPopover.Content

const composableChildComponentTypes = [
  DNAActionBarItem.PopoverContent,
]

/**
 * This component builds on the DNAButton & DNABox & Popover components to create a reusable menu generation component structure.
 * @param props
 * @returns ActionBar
 * @example
 * <DNAActionBar
 *  {...DNABoxProps}
 *  itemDefaults = {...DNAActionBarItemProps}
 * >
 *
 *    <DNAActionBar.Item
 *    {...DNAButtonProps}
 *    _popoverProps = {...PopoverProps}
 *    >
 *
 *      Example Button Label
 *
 *      <DNAActionBar.Item.PopoverContent>
 *         <DNABox>
 *           <DNAText status="basic">
 *             Example popover content
 *           </DNAText>
 *         </DNABox>
 *     </DNAActionBar.Item.PopoverContent>
 *
 *    </DNAActionBar.Item>
 *
 * </DNAActionBar>
 */
const DNAActionBar: DNAActionBar = ({ children, itemDefaults, ...dnaBoxProps }) =>
  (<DNAActionBarStateProvider itemDefaults={itemDefaults} >
    <DNABox {...dnaBoxProps}>
      {children}
    </DNABox>
  </DNAActionBarStateProvider>)

DNAActionBar.Item = DNAActionBarItem
/** NOTE: Setting this is normally not necessary but for these types of sub-components it is required to prevent renaming due to minification */
DNAActionBar.Item.displayName = 'DNAActionBarItem'

export default DNAActionBar
