import { DNAFlatListRows } from 'src/components/DNA/FlatList/Rows/types'

import DNACustomDeckDocumentRow from './DNACustomDeckDocumentRow'
import DNACustomDeckFolderRow from './DNACustomDeckFolderRow'
import DNACustomDeckFolderItemRow from './DNACustomDeckFolderItemRow'
import DNACustomDeckFolderItemCustomDeckRow from './DNACustomDeckFolderItemCustomDeckRow'

import { DNADivider } from '@alucio/lux-ui';
import DNAFileDrawerDefaultRow from '../fileDrawer/DNAFileDrawerDefaultRow';

export const DNADefaultRows: DNAFlatListRows = {
  DNADocumentRow: DNACustomDeckDocumentRow,
  DNADocumentFolderItemRow: DNACustomDeckFolderItemRow,
  DNADocumentFolderItemCustomDeckRow: DNACustomDeckFolderItemCustomDeckRow,
  DNAFolderRow: DNACustomDeckFolderRow,
  DNAListDivider: DNADivider,
  DNAFileDrawerRow: DNAFileDrawerDefaultRow,
}

export default DNADefaultRows
