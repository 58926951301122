import { ShareNotificationScope } from '@alucio/aws-beacon-amplify/src/models';
import type { ShareStateMachine } from './shareMachine.types';

type State = ShareStateMachine.State

// Tag
export const isHubs = (state: State) => ({ isHubs: state.hasTag('HUBS') })
export const copyFileSuccess = (state: State) => ({ copyFileSuccess: (state.hasTag('COPY_FILE_NAME_SUCCESS')) })
export const copyFileError = (state: State) => ({ copyFileError: (state.hasTag('COPY_FILE_NAME_ERROR')) })

export const openEmailSuccess = (state: State) => ({ openEmailSuccess: (state.hasTag('OPEN_EMAIL_SUCCESS')) })

export const openEmailError = (state: State) => ({ openEmailError: (state.hasTag('OPEN_EMAIL_ERROR')) })

export const copyEmailSuccess = (state: State) => ({ copyEmailSuccess: (state.hasTag('COPY_EMAIL_SUCCESS')) })

export const copyEmailError = (state: State) => ({ copyEmailError: (state.hasTag('COPY_EMAIL_ERROR')) })

// Context
export const totalNumAvailableContent =
  (state: State) => ({ totalNumAvailableContent: state.context.totalNumAvailableContent })
export const selectedNumContent = (state: State) => ({ selectedNumContent: state.context.selectedNumContent })
export const entityShareIds = (state: State) => ({ entityShareIds: state.context.entityShareIds })
export const selectedEmailTemplate = (state: State) => ({ selectedEmailTemplate: state.context.selectedEmailTemplate })
export const shareVariant = (state: State) => ({ shareVariant: state.context.variant })
export const availableContentToShare =
  (state: State) => ({ availableContentToShare: state.context.availableContentToShare })

export const existingContentShareORM =
  (state: State) => ({ existingContentShareORM: state.context.existingContentShareORM })

export const isCopyingFile = (state: State) => ({ isCopyingFile: state.context.isCopyingFile })
export const isOpeningEmail = (state: State) => ({ isOpeningEmail: state.context.isOpeningEmail })
export const isCopyingEmail = (state: State) => ({ isCopyingEmail: state.context.isCopyingEmail })
export const notificationScope = (state: State) => ({
  notificationScope: state.context.notificationScope ||
  ShareNotificationScope.NONE,
})
export const isAllSelected = (state: State) => {
  const { totalNumAvailableContent: totalAvailable } = totalNumAvailableContent(state)
  const { selectedNumContent: selectedNum } = selectedNumContent(state)

  return { isAllSelected: totalAvailable === selectedNum }
}

export const copyFileNameButtonStatus = (state: State) => {
  const { copyFileSuccess: success } = copyFileSuccess(state)
  const { copyFileError: error } = copyFileError(state)
  const { isCopyingFile: copying } = isCopyingFile(state)

  if (error) return { copyFileNameButtonStatus: 'error' }
  else if (success) return { copyFileNameButtonStatus: 'success' }
  else if (copying) return { copyFileNameButtonStatus: 'copying' }
  else return { copyFileNameButtonStatus: 'idle' }
}

export const copyEmailButtonStatus = (state: State) => {
  const { copyEmailSuccess: success } = copyEmailSuccess(state)
  const { copyEmailError: error } = copyEmailError(state)
  const { isCopyingEmail: copying } = isCopyingEmail(state)

  if (error) return { copyEmailButtonStatus: 'error' }
  else if (success) return { copyEmailButtonStatus: 'success' }
  else if (copying) return { copyEmailButtonStatus: 'copying' }
  else return { copyEmailButtonStatus: 'idle' }
}

export const openEmailButtonStatus = (state: State) => {
  const { openEmailSuccess: success } = openEmailSuccess(state)
  const { openEmailError: error } = openEmailError(state)
  const { isOpeningEmail: opening } = isOpeningEmail(state)

  if (error) return { openEmailButtonStatus: 'error' }
  else if (success) return { openEmailButtonStatus: 'success' }
  else if (opening) return { openEmailButtonStatus: 'opening' }
  else return { openEmailButtonStatus: 'idle' }
}

export const noEntitySelectedToShare =
  (state: State) => ({ noEntitySelectedToShare: !selectedNumContent(state).selectedNumContent })
