import { InvokeCallback } from 'xstate';
import im from 'immer';
import {
  SharedFileHubSetting,
  Notation,
  HubSharedAssociatedFile,
  HubSectionItemStatus,
} from '@alucio/aws-beacon-amplify/src/models';
import { NotationsContext, NotationsEvents } from './notationTypes';
import { PW } from 'src/state/machines/presentation/playerWrapper';
import * as logger from 'src/utils/logger'

export const CALLOUTS_AVAILABLE_DOC_TYPE = ['PDF', 'PPTX']

export function getNotations (
  sharedFiles: SharedFileHubSetting[] | undefined,
  docVerId: string,
): Notation[] {
  let notations: Notation[] = []
  if (!sharedFiles) return notations
  for (let i = 0; i < sharedFiles.length; i++) {
    const sharedFile = sharedFiles[i]
    if (sharedFile.contentId === docVerId) {
      notations = sharedFile.notation || []
      break
    }
    if (sharedFile.documentVersionSettings?.associatedFiles) {
      const associatedFiles = sharedFile.documentVersionSettings.associatedFiles
      for (let i = 0; i < associatedFiles.length; i++) {
        const associatedFile = associatedFiles[i]
        if (associatedFile.versionId === docVerId) {
          notations = associatedFile.notation || []
          break
        }
      }
    }
  }
  return notations
}

type GetUpdatedSharedFilesOperation =
  | { type: 'add', notation: Notation }
  | { type: 'edit', notationId: string, description: string }
  | { type: 'delete', notationId: string }

function getAssociatedFileUpdatedNotation (
  associatedFile: HubSharedAssociatedFile,
  operation: GetUpdatedSharedFilesOperation,
): Notation[] | undefined {
  if (operation.type === 'add') {
    return associatedFile.notation
      ? [...associatedFile.notation, operation.notation]
      : [operation.notation]
  } else if (operation.type === 'edit') {
    return associatedFile.notation?.map(notation => {
      if (notation.id === operation.notationId) {
        return {
          ...notation,
          description: operation.description,
          updatedAt: new Date().toISOString(),
        }
      } else return notation
    })
  } else if (operation.type === 'delete') {
    return associatedFile.notation?.map(notation => {
      if (notation.id === operation.notationId) {
        return {
          ...notation,
          status: HubSectionItemStatus.DELETED,
          updatedAt: new Date().toISOString(),
        }
      } else return notation
    })
  }
}

export function getUpdatedSharedFiles (
  sharedFiles: SharedFileHubSetting[],
  docVerId: string,
  operation: GetUpdatedSharedFilesOperation,
): SharedFileHubSetting[] {
  // Update notation in a new SharedFiles array (both main doc and associated doc)
  const updatedSharedFiles = im(
    sharedFiles,
    draft => {
      return draft.map(mainDoc => {
        const associatedFiles: HubSharedAssociatedFile[] | undefined = mainDoc
          .documentVersionSettings
          ?.associatedFiles
          ?.map(associatedFile => {
            if (associatedFile.versionId === docVerId) {
              const updatedNotation = getAssociatedFileUpdatedNotation(associatedFile, operation)
              return {
                ...associatedFile,
                notation: updatedNotation,
              }
            } else return associatedFile
          })

        const documentVersionSettings = mainDoc.documentVersionSettings?.associatedFiles
          ? { associatedFiles }
          : undefined

        let notationResult: Notation[] | undefined
        if (operation.type === 'add') {
          notationResult = mainDoc.contentId === docVerId
            ? mainDoc.notation
              ? [...mainDoc.notation, operation.notation]
              : [operation.notation]
            : mainDoc.notation
        } else if (operation.type === 'edit') {
          notationResult = mainDoc.contentId === docVerId
            ? mainDoc.notation?.map(notation => {
              if (notation.id === operation.notationId) {
                return {
                  ...notation,
                  description: operation.description,
                  updatedAt: new Date().toISOString(),
                }
              } else return notation
            })
            : mainDoc.notation
        } else if (operation.type === 'delete') {
          if (mainDoc.contentId === docVerId) {
            notationResult = mainDoc.notation?.map(notation => {
              if (notation.id === operation.notationId) {
                return {
                  ...notation,
                  status: HubSectionItemStatus.DELETED,
                  updatedAt: new Date().toISOString(),
                }
              } else return notation
            })
          }
          else notationResult = mainDoc.notation
        }

        return {
          ...mainDoc,
          documentVersionSettings,
          notation: notationResult,
          updatedAt: new Date().toISOString(),
        }
      })
    },
  )
  return updatedSharedFiles
}

type ChannelObserverCallback<T extends NotationsEvents> = InvokeCallback<T, NotationsEvents>

export const presentationStateObserver = (
  channel: NotationsContext['presentationStateChannel'],
  meetingId: string,
):
  ChannelObserverCallback<PW.EVT_PRESENTATION_STATE_SYNC> => (send) =>
{
  const handler = (msg: PW.PresentationChannelMessage) => {
    if (meetingId !== msg.meetingId) return
    // We only pay attention to PRESENTATION_STATE_SYNC events which are broadcast
    // by the PresentationBroadCastProvider
    if (msg.type === 'PRESENTATION_STATE_SYNC') {
      send({
        type: 'PRESENTATION_STATE_SYNC',
        meetingId: msg.meetingId,
        payload: msg.payload,
        messageNumber: msg.messageNumber,
      })
    }
    if (msg.type === 'IFRAME_READY') {
      logger.hub.widgets.sharedFiles.callOuts.debug('Presentation state observer received: IFRAME_READY')
      send({
        type: 'IFRAME_READY',
      })
    }
    if (msg.type === 'SEND_NOTATION_COORDINATE') {
      logger.hub.widgets.sharedFiles.callOuts.debug('Presentation state observer received: SEND_NOTATION_COORDINATE')
      if (msg.coordinate) {
        send({
          type: 'SET_COORDINATE',
          coordinate: [msg.coordinate],
          pageId: msg.pageId,
        })
      } else send({ type: 'BACK_TO_IDLE' })
    }
    if (msg.type === 'SYNC_CURRENT_ACTIVE_NOTATION') {
      logger.hub.widgets.sharedFiles.callOuts.debug(
        'Presentation state observer received: SYNC_CURRENT_ACTIVE_NOTATION',
      )
      send({
        type: 'SET_CURRENT_ACTIVE_NOTATION',
        notationId: msg.notationId,
      })
    }
  }

  logger.hub.widgets.sharedFiles.callOuts.debug(`attaching listener to presentation channel ${meetingId}`, channel)
  channel.addEventListener(
    'message',
    handler,
  )
  return () => {
    logger.hub.widgets.sharedFiles.callOuts.debug('Cleanup Called for presentation channel observer')
    channel.removeEventListener('message', handler);
  }
}
