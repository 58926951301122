/* eslint-disable max-len */
import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export function HubsIcon(props: SvgProps) {
  return (
    <Svg
    // @ts-ignore
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 256 256"
      {...props}
    >
      <Path
        d="M224,64a32,32,0,1,0-40,31v9a16,16,0,0,1-16,16H88a16,16,0,0,1-16-16V95a32,32,0,1,0-16,0v9a32,32,0,0,0,32,32h32v25a32,32,0,1,0,16,0V136h32a32,32,0,0,0,32-32V95A32.06,32.06,0,0,0,224,64ZM48,64A16,16,0,1,1,64,80,16,16,0,0,1,48,64Zm96,128a16,16,0,1,1-16-16A16,16,0,0,1,144,192ZM192,80a16,16,0,1,1,16-16A16,16,0,0,1,192,80Z"
        fill={props.color ?? '#fff'}
      />
    </Svg>
  );
}

export default HubsIcon;
