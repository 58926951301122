import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { WebTooltip, luxColors, DNAIcon } from '@alucio/lux-ui';

const styles = StyleSheet.create({
  badgeText: {
    color: 'white',
    fontFamily: 'Lato',
    fontSize: 13,
    fontWeight: 'bold',
  },
  defaultIconColor: {
    color: 'gray',
  },
  extensionBadge: {
    borderRadius: 2,
    height: 22,
    padding: 3,
  },
  pptx: {
    backgroundColor: luxColors.basicBlack.primary,
    opacity: 0.6,
  },
  statusIcon: {
    height: 22,
    width: 22,
  },
});

const extensionColor = {
  pptx: styles.pptx,
  pdf: styles.pptx,
  video: styles.pptx,
  zip: styles.pptx,
  html: styles.pptx,
};

type Props = {
  extension: string;
};

const validVideoExtensions = ['MP4', 'MOV', 'AVI', 'WMV', 'FLV', 'MKV', 'WEBM', 'MPEG'];

function ExtensionBadge(props: Props) {
  const extension = validVideoExtensions.includes(props.extension.toUpperCase())
    ? 'VIDEO'
    : props.extension.toUpperCase();
  const color = extensionColor[extension.toLowerCase()];

  // If there's no color, the file extension is not supported
  if (!color) {
    return (
      <View style={styles.extensionBadge}>
        <WebTooltip toolTipText="File type is not supported">
          <DNAIcon
            style={[styles.statusIcon, styles.defaultIconColor]}
            name="help-circle-outline"
            testID="not-supported-icon"
          />
        </WebTooltip>
      </View>
    );
  }

  return (
    <View style={[styles.extensionBadge, color]}>
      <Text style={[styles.badgeText]} testID="preview-modal-file-type">{extension}</Text>
    </View>
  );
}

export default ExtensionBadge;
