import React, { ReactElement, useRef, useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Icon, luxColors, DNABox } from '@alucio/lux-ui';
import { OverflowMenu, Input } from '@ui-kitten/components';
import { useCanPerformSearch } from 'src/state/redux/selector/documentSearch/documentSearch';
import { DocumentORM, EntityWithFiles } from 'src/types/types';
import InputComponent from 'src/components/Publishers/InputComponent'
import AssociatedFilesResultBox from '../../components/Publishers/AssociatedFilesResultBox';

interface Props<T> {
  parentDoc: T;
  onSelect: (parentDoc: T, linkedDoc: DocumentORM) => void;
}

const styles = StyleSheet.create({
  icon: {
    color: luxColors.disabled.primary,
    height: 25,
    width: 25,
  },
  menu: {
    maxHeight: 300,
  },
  searchBarStyle: {
    backgroundColor: luxColors.info.primary,
    borderColor: luxColors.disabled.secondary,
    borderRadius: 4,
    maxWidth: '100%',
    overflow: 'hidden',
  },
  searchBarWrapper: {
    flex: 1,
  },
});

const ICONS = {
  CLOSE: 'close',
  MAGNIFY: 'magnify',
}

const useSearchInput = () => {
  const [searchText, setSearchText] = useState<string>('')
  const canPerformSearch = useCanPerformSearch();
  const [displaySearchResults, setDisplaySearchResults] = useState<boolean>(false)

  function onChangeText(text: string): void {
    const canSearch = canPerformSearch(text.trim());
    setSearchText(text);

    if (!displaySearchResults && canSearch) {
      setDisplaySearchResults(true);
    } else if (displaySearchResults && !canSearch) {
      setDisplaySearchResults(false);
    }
  }

  function clearText(): void {
    onChangeText('');
  }

  function toggleSearchResultComponent(open: boolean): void {
    setDisplaySearchResults(open);
  }

  return {
    displaySearchResults,
    onChangeText,
    clearText,
    toggleSearchResultComponent,
    searchText,
    setDisplaySearchResults,
  }
}

const AssociatedFilesSearchInput = <T extends EntityWithFiles>(props: Props<T>) => {
  const {
    onChangeText,
    displaySearchResults,
    clearText,
    toggleSearchResultComponent,
    searchText,
  } = useSearchInput();

  const { parentDoc, onSelect } = props;

  const inputRef = useRef<Input>(null)

  function renderIcon(icon: string): ReactElement {
    const handleClear = () => {
      clearText()
      inputRef.current?.focus()
    }

    if (icon === ICONS.CLOSE && !searchText) {
      return <DNABox />;
    }

    return (
      <TouchableOpacity onPress={icon === ICONS.CLOSE ? handleClear : () => { }}>
        <Icon style={styles.icon} name={icon} />
      </TouchableOpacity>
    );
  }

  function onBackdropPressHandler() {
    toggleSearchResultComponent(false)
  }

  function renderSearchInput(): ReactElement {
    return (
      <DNABox style={styles.searchBarWrapper}>
        <InputComponent
          ref={inputRef}
          value={searchText}
          hideLabel={true}
          onChangeText={onChangeText}
          title="Search for files"
          removeMarginPadding={true}
          inputStyle={styles.searchBarStyle}
          getLeftIconFunction={() => renderIcon(ICONS.MAGNIFY)}
          getRightIconFunction={() => renderIcon(ICONS.CLOSE)}
          testID="associated-files-search-input"
        />
      </DNABox>
    );
  }

  return (
    <OverflowMenu
      appearance="noDivider"
      anchor={renderSearchInput}
      style={styles.menu}
      visible={displaySearchResults}
      placement="bottom start"
      fullWidth={true}
      onBackdropPress={onBackdropPressHandler}
    >
      <AssociatedFilesResultBox
        searchText={searchText}
        parentDoc={parentDoc}
        onSelect={onSelect}
      />
    </OverflowMenu>
  )
};

export default AssociatedFilesSearchInput
