import React, { useCallback, useMemo } from 'react';
import { ScrollView, StyleSheet, useWindowDimensions } from 'react-native';
import { DNABox, DNAButton, DNAChip, DNAContextMenu, DNAText, Iffy, Stack, util } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { Page } from '@alucio/aws-beacon-amplify/src/models';
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector';
import { getSlideContentPageDataTitle } from 'src/hooks/useContentPageData/useContentPageData';
import useSelector, { composite } from 'src/hooks/useSelector';
import * as findReplacementSelector from 'src/state/machines/findReplacement/FindReplacement.selectors';
import {
  useFindReplacementState,
  GroupDraftStatus,
  ReplaceGroupDraft,
  UNAVAILABLE_DOCUMENT_STATUS,
} from '../FindReplacementProvider';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import CollapsibleCard from 'src/components/CollapsibleCard/CollapsibleCard';

const S = StyleSheet.create({
  cardContainer: {
    padding: 12,
  },
  card: {
    padding: 12,
    minHeight: 52,
    backgroundColor: colors['color-text-white'],
    borderColor: colors['color-gray-100'],
    borderWidth: 1,
    borderRadius: 4,
  },
  previewContainer: {
    padding: 20,
  },
  contentContainer: {
    backgroundColor: colors['color-gray-10'],
  },
  thumbnailContainer: {
    borderColor: colors['color-gray-80'],
    borderWidth: 3,
    borderRadius: 6,
    padding: 3,
  },
  needReviewThumbnailContainer: {
    borderColor: colors['color-warning-500'],
    borderWidth: 3,
    borderRadius: 6,
    padding: 3,
  },
  thumbnailBackGround: {
    backgroundColor: colors['color-gray-100'],
  },
  reviewedThumbnailContainer: {
    borderColor: colors['color-success-500'],
    borderWidth: 3,
    borderRadius: 6,
    padding: 3,
  },
  disableThumbnailBackdrop: {
    backgroundColor: colors['color-warning-5'],
  },
  cardContentContainer: {
    paddingHorizontal: 20,
  },
  warningCollapsibleCard: {
    minHeight: 'fit-content',
    borderRadius: 4,
    borderWidth: 3,
    borderColor: colors['color-warning-500'],
  },
  successCollapsibleCard: {
    minHeight: 'fit-content',
    borderRadius: 4,
    borderWidth: 3,
    borderColor: colors['color-success-500'],
  },
  tooltipText: {
    color: colors['color-text-white'],
  },
  buttonIconStyle: {
    marginRight: 4,
  },
  leftRemoveButton: {
    borderRadius: 4,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  contextMenuButton: {
    borderRadius: 4,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginLeft: 1,
  },
  contextMenuItems: {
    top: 4,
  },
});

const THUMBNAIL_RATIO = 66 / 120;

const Slide: React.FC<{
  group: ReplaceGroupDraft,
  page?: Page,
  pageIndex?: number,
  isGroup?: boolean,
}> = ({ group, page, pageIndex = 0, isGroup }) => {
  const {
    service,
    removeGroup,
    removeAllUnavailableGroups,
    allContentPageDataVersions,
  } = useFindReplacementState();
  const dimensions = useWindowDimensions();

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      findReplacementSelector.replaceGroups,
      findReplacementSelector.activeGroupId,
      findReplacementSelector.disableRemoveButton,
    ),
  );

  const isUnavailableDocument = UNAVAILABLE_DOCUMENT_STATUS.includes(group.status);
  const targetPage = page || group.pages[0];
  const s3URL = detectArchivedFileKeyPath(group.documentVersionORM?.model, targetPage);
  const contentPageData = allContentPageDataVersions[group.documentVersionORM?.model.id ?? Infinity] ?? [];
  const pageTitle = contentPageData.length > 0
    ? getSlideContentPageDataTitle(targetPage.number - 1, contentPageData)
    : '';
  const currentGroupIndex = useMemo(() => {
    return cond.replaceGroups.findIndex(g => g.groupId === group.groupId);
  }, [cond.replaceGroups, group.groupId]);
  const isGroupReviewed = group.status === GroupDraftStatus.REPLACED;
  const targetPageNum = useMemo(() => {
    return cond.replaceGroups.reduce((acc, curr, currentIndex) => {
      if (currentIndex > currentGroupIndex) return acc
      return currentIndex < currentGroupIndex ? acc + curr.pages.length : acc + pageIndex
    }, 0) + 1;
  }, [cond.replaceGroups, currentGroupIndex, pageIndex, group.groupId]);
  const slideFooterText = useMemo(() => {
    return (group.visible ? `${targetPageNum}. ` : '') + pageTitle;
  }, [targetPageNum, pageTitle, group.groupId])
  const thumbnailWidth = useMemo(() => {
    let width: number
    if (isGroup) width = dimensions.width / 3
    else width = (dimensions.width / 2) - 80
    return width > 650 ? 650 : width
  }, [dimensions, isGroup]);

  const onRemoveGroup = useCallback(() => {
    if (cond.activeGroupId)removeGroup(cond.activeGroupId)
  }, [cond.activeGroupId, removeGroup])

  return (
    <DNABox appearance="col" spacing="sm">
      {/* THUMBNAIL */}
      <DNABox
        style={util.mergeStyles(
          undefined,
          [S.thumbnailContainer, isGroup],
          [S.needReviewThumbnailContainer, !isGroupReviewed && !isGroup],
          [S.reviewedThumbnailContainer, isGroupReviewed && !isGroup],
        )}
      >
        {!isUnavailableDocument ? (
          <DNABox testID="old-version-thumbnail" style={S.thumbnailBackGround}>
            <DNAThumbnail
              height={thumbnailWidth * THUMBNAIL_RATIO}
              width={thumbnailWidth}
              s3URL={s3URL}
            />
          </DNABox>
        ) : (
          <Stack anchor="center">
            <Stack.Layer>
              <DNABox
                style={[S.disableThumbnailBackdrop, {
                  height: thumbnailWidth * THUMBNAIL_RATIO,
                  width: thumbnailWidth,
                }]}
              />
            </Stack.Layer>
            <Stack.Layer>
              <DNABox fill appearance="col">
                <DNAText bold b1 status="warning">
                  {group.status === GroupDraftStatus.DELETED
                    ? 'Source file is unavailable'
                    : `Source file was ${group.status.toLowerCase()}`
                  }
                </DNAText>
              </DNABox>
            </Stack.Layer>
          </Stack>
        )}
      </DNABox>
      {/* THUMBNAIL FOOTER */}
      <DNAText numberOfLines={1} style={{ width: thumbnailWidth }}>
        {slideFooterText}
      </DNAText>
      <Iffy is={isUnavailableDocument && !isGroup}>
        <DNABox fill>
          <DNAButton
            status="warning"
            iconLeft="trash-can-outline"
            iconStyle={S.buttonIconStyle}
            onPress={onRemoveGroup}
            style={S.leftRemoveButton}
            children="Remove"
            stretch
          />
          <DNAContextMenu
            style={[S.contextMenuItems, { width: thumbnailWidth + 10 }]}
            placement="bottom end"
          >
            <DNAContextMenu.Anchor>
              <DNAButton
                onPress={() => { }}
                iconLeft="chevron-down"
                status="warning"
                padding="sm"
                style={S.contextMenuButton}
              />
            </DNAContextMenu.Anchor>
            <DNAContextMenu.Items>
              <DNAContextMenu.Item
                collapseOnPress
                delay={100}
                title="Remove all unavailable slides"
                onPress={removeAllUnavailableGroups}
              />
            </DNAContextMenu.Items>
          </DNAContextMenu>
        </DNABox>
      </Iffy>
    </DNABox>
  )
}

export const GroupCard: React.FC<{ group: ReplaceGroupDraft}> = ({ group }) => {
  const { service, removeGroup } = useFindReplacementState();

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      findReplacementSelector.activeGroupId,
      findReplacementSelector.disableRemoveButton,
    ),
  );

  const isUnavailableDocument = UNAVAILABLE_DOCUMENT_STATUS.includes(group.status);

  const onRemoveGroup = useCallback(() => {
    if (cond.activeGroupId)removeGroup(cond.activeGroupId)
  }, [cond.activeGroupId, removeGroup])

  const groupHeaderTitle = (
    <DNABox fill appearance="col" alignY="center" style={{ width: 100 }}>
      <DNAText h5 numberOfLines={1}>{group.name}</DNAText>
      <DNAText c1 status="flat" numberOfLines={1}>{group.documentVersionORM?.model.title}</DNAText>
    </DNABox>
  )

  const cardStyle = util.mergeStyles(
    undefined,
    [S.warningCollapsibleCard, group.status !== GroupDraftStatus.REPLACED],
    [S.successCollapsibleCard, group.status === GroupDraftStatus.REPLACED],
  )
  return (
    <DNABox appearance="col" spacing="md">
      <CollapsibleCard
        headerTitle={groupHeaderTitle}
        isCollapsed={false}
        cardStyle={cardStyle}
      >
        <DNABox fill appearance="col" alignX="center" style={S.cardContentContainer}>
          {
            group.pages.map((page, idx) => (
              <DNABox
                key={`${group.groupId}-${page.pageId}-${idx}`}
                appearance="col"
                style={{ marginBottom: 24 }}
              >
                <Slide
                  group={group}
                  page={page}
                  pageIndex={idx}
                  isGroup
                />
              </DNABox>
            ))
          }
        </DNABox>
      </CollapsibleCard>
      <Iffy is={isUnavailableDocument}>
        <DNAPopover placement="top" disablePopover={!cond.disableRemoveButton}>
          <DNAPopover.Anchor style={{ flex: 1 }}>
            <DNAButton
              status="warning"
              iconLeft="trash-can-outline"
              iconStyle={{ marginRight: 4 }}
              onPress={onRemoveGroup}
              children="Remove"
              disabled={cond.disableRemoveButton}
              stretch
            />
          </DNAPopover.Anchor>
          <DNAPopover.Content>
            <DNAText style={S.tooltipText} numberOfLines={1}>
              Presentation must contain at least one slide
            </DNAText>
          </DNAPopover.Content>
        </DNAPopover>
      </Iffy>
    </DNABox>
  )
}

const LeftContent: React.FC = () => {
  const { service, removeGroup } = useFindReplacementState();

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      findReplacementSelector.activeGroup,
      findReplacementSelector.activeGroupId,
      findReplacementSelector.replaceGroups,
      findReplacementSelector.disableRemoveButton,
    ),
  );

  const hasBeenReplaced = useMemo(() => {
    return !!cond.activeGroup?.groupReplacement
  }, [cond.activeGroup])

  const onRemoveGroup = useCallback(() => {
    if (cond.activeGroupId)removeGroup(cond.activeGroupId)
  }, [cond.activeGroupId, removeGroup])

  if (!cond.activeGroup) return null
  return (
    <DNABox fill appearance="col">
      {/* TOP BAR */}
      <DNABox style={S.cardContainer}>
        <DNABox fill alignY="center" spacing="between" style={S.card}>
          <DNAChip>OLD VERSION</DNAChip>
          <DNAPopover placement="top" disablePopover={!cond.disableRemoveButton}>
            <DNAPopover.Anchor>
              <DNAButton
                iconLeft="trash-can-outline"
                appearance="ghostLink"
                status="danger"
                size="lg"
                padding="none"
                onPress={onRemoveGroup}
                disabled={cond.disableRemoveButton}
              />
            </DNAPopover.Anchor>
            <DNAPopover.Content>
              <DNAText style={S.tooltipText} numberOfLines={1}>
                Presentation must contain at least one slide
              </DNAText>
            </DNAPopover.Content>
          </DNAPopover>
        </DNABox>
      </DNABox>
      {/* MAIN CONTENT */}
      <ScrollView style={S.previewContainer}>
        <DNABox
          alignX="center"
          style={util.mergeStyles(
            undefined,
            [{ padding: 32 }, hasBeenReplaced],
          )}
        >
          <Iffy is={cond.activeGroup.pages.length === 1}>
            <Slide group={cond.activeGroup}/>
          </Iffy>
          <Iffy is={cond.activeGroup.pages.length > 1}>
            <GroupCard group={cond.activeGroup}/>
          </Iffy>
        </DNABox>
      </ScrollView>
    </DNABox>
  )
}

export default LeftContent
