import { PayloadAction } from '@reduxjs/toolkit';
import createSlice from '../util/createImmerlessSlice';
import { FileEditTabBarSelected } from 'src/screens/Publishers/TabBar';
import { FolderORM } from 'src/types/orms';
import { StandaloneForm } from '../selector/crm';

export enum DRAWER_ENTITIES {
  EMAIL_TEMPLATE,
  USER,
  MEETING,
  ORGANIZATION,
  FILES,
  TENANT_FORM,
  CRM_FORM,
}

const DEFAULT_WIDTH = 960;

type DrawerSliceState = {
  entity?: DRAWER_ENTITIES,
  entityId?: string,
  tenantId?: string;
  isOpen?: boolean,
  orientation?: 'left' | 'right',
  selectedTab?: FileEditTabBarSelected,
  width?: number,
  keepOpen?: boolean,
  hasPendingChanges?: boolean,
  folderORM?: FolderORM,
  standaloneForm?: StandaloneForm
}

const initialState: DrawerSliceState = {
  entity: undefined,
  folderORM: undefined,
  entityId: undefined,
  tenantId: undefined,
  orientation: 'right',
  width: DEFAULT_WIDTH,
  isOpen: false,
  selectedTab: FileEditTabBarSelected.LABELS,
}

const drawerSlice = createSlice({
  name: 'DrawerState',
  initialState: initialState,
  reducers: {
    toggle: (state, action: PayloadAction<DrawerSliceState>) => {
      const {
        entityId,
        width,
        orientation,
        selectedTab,
        entity,
        tenantId,
        standaloneForm,
        keepOpen = false,
      } = action.payload;

      const tabSelected = state.isOpen && !keepOpen ? FileEditTabBarSelected.LABELS : selectedTab;
      const isOpen = !(state.isOpen && !keepOpen);
      return {
        ...state,
        isOpen,
        standaloneForm,
        entityId,
        tenantId,
        width: !isOpen ? DEFAULT_WIDTH : width,
        orientation,
        selectedTab: tabSelected,
        entity,
      }
    },
    hide: (state) => {
      return { ...state, isOpen: false, currentDocId: undefined }
    },
    setPendingChanges: (state, action: PayloadAction<DrawerSliceState>) => {
      return { ...state, hasPendingChanges: action.payload.hasPendingChanges }
    },
  },
})

export default drawerSlice
export const drawerActions = drawerSlice.actions
