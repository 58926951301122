import React from 'react'
import {
  DNAModal,
  DNABox,
  useDisableSwipe,
  DNAButton,
} from '@alucio/lux-ui'
import { StyleSheet } from 'react-native';
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

const styles = StyleSheet.create({
  container: {
    minWidth: '100VW',
    minHeight: '100VH',
    padding: 15,
  },
  iframeContainer: {
    borderColor: colors['color-gray-100'],
    borderWidth: 1,
  },
})

interface TabletPreviewModalProps {
  url: string
}

const TabletPreviewModal: DNAModalVariant<TabletPreviewModalProps> = ({
  toggleModal,
  url,
}) => {
  useDisableSwipe()

  return (
    <DNAModal style={styles.container}>
      <DNAModal.Body style={styles.container}>
        <DNABox fill appearance="col">
          <DNABox alignX="end">
            <DNAButton
              testID="content-present-mode-exit-button"
              status="tertiary"
              appearance="ghostLink"
              iconLeft="close"
              size="md"
              onPress={toggleModal}
            />
          </DNABox>
          <DNABox fill style={styles.iframeContainer}>
            <iframe
              title="MicrositeViewer"
              src={url}
              style={{ flex: 1, border: 0 }}
            />
          </DNABox>
        </DNABox>
      </DNAModal.Body>
    </DNAModal>
  )
}

export default TabletPreviewModal
