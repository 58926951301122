import React, { useState } from 'react'
import { StyleSheet } from 'react-native'
import { DNABox } from '@alucio/lux-ui'
import { TabView, Tab } from '@ui-kitten/components'
// Tabs
import General from './Tabs/General'
import Logging from './Tabs/Logging'
import ServiceWorker from './Tabs/ServiceWorker'
import MatchSlides from './Tabs/MatchSlides'

export const S = StyleSheet.create({
  tabView: {
    flex: 1,
  },
  tabTitle: {
    paddingVertical: 5,
  },
  tabContainer: {
    padding: 20,
  },
});

const Debug: React.FC = () => {
  const [selectedIdx, setSelectedIdx] = useState(0)

  return (
    <DNABox>
      <TabView
        selectedIndex={selectedIdx}
        onSelect={setSelectedIdx}
        style={S.tabView}
      >
        {/* GENERAL TAB */}
        <Tab title="General" style={S.tabTitle}>
          <General />
        </Tab>

        {/* LOGGING TAB */}
        <Tab title="Logging" style={S.tabTitle}>
          <Logging />
        </Tab>

        {/* SERVICE WORKER TAB */}
        <Tab title="Service Worker" style={S.tabTitle}>
          <ServiceWorker />
        </Tab>

        {/* MATCH SLIDES TAB */}
        <Tab title="Match Slides" style={S.tabTitle}>
          <MatchSlides />
        </Tab>
      </TabView>
    </DNABox>
  )
}

export default Debug
