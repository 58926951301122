import { DNADivider } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import React, { useEffect, useRef } from 'react'
import { FlatList, Pressable } from 'react-native';
import SearchResultsItem from './SearchResultsItem';
import { MatchedPage } from 'src/hooks/useFileTextSearch/useFileTextSearch';

interface SearchResultsContentProps {
    matches: MatchedPage[],
    searchQuery: string,
    activeIndex: number,
    setActiveIndex: (index: number) => void,
    setActiveSlide: (slide:number) => void,
  }

const SearchResultsContent: React.FC<SearchResultsContentProps> = React.memo((
  { matches, searchQuery, activeIndex, setActiveIndex, setActiveSlide }) => {
  const flatlistRef = useRef<FlatList<MatchedPage> | null>(null);

  useEffect(() => {
    if (flatlistRef.current && matches.length && activeIndex > -1) {
      flatlistRef.current.scrollToIndex({ animated: true, index: activeIndex })
    }
  }, [activeIndex]);

  return (
    <FlatList
      data={matches}
      keyExtractor={(item) => `slide-${item.presentationPageNumber}`}
      renderItem={({ item, index }) => {
        return (
          <Pressable
            onPress={() => {
              setActiveIndex(index);
              setActiveSlide(item.presentationPageNumber)
            }}
            style={{ backgroundColor : activeIndex === index ? colors['color-gray-80'] : undefined }}
          >
            <SearchResultsItem item={item} searchQuery={searchQuery} />
          </Pressable>
        )
      }}
      style={{ height: 300, overflow: 'scroll' }}
      ref={flatlistRef}
      ItemSeparatorComponent={DNADivider}
    />
  )
})

export default SearchResultsContent
