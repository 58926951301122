import { AccessTokenData } from '@alucio/aws-beacon-amplify/src/models';
import { createSlice } from '@reduxjs/toolkit';
import { commonReducers, initialState } from './common';

// [TODO BEAC-3118] add the id on the  graph ql schema and send the id from the backend
export class AccessTokenDataExtended {
    id : string
    documentVersionId: string;
    accessToken: string;
    accessTokenExpire: string;

    constructor(accessTokenData: AccessTokenData) {
      this.id = accessTokenData.documentVersionId;
      this.documentVersionId = accessTokenData.documentVersionId;
      this.accessToken = accessTokenData.accessToken;
      this.accessTokenExpire = accessTokenData.accessTokenExpire;
    }
}

const sliceName = 'authToken'
const { reducers, extraReducers } = commonReducers<AccessTokenDataExtended>(sliceName)

const authTokenSlice = createSlice({
  name: sliceName,
  initialState: initialState<AccessTokenDataExtended>(),
  reducers : {
    ...reducers,
  },
  extraReducers: {
    ...extraReducers,
  },
})

export default authTokenSlice
export const authTokenSliceActions = {
  ...authTokenSlice.actions,
}
