import { useEffect } from 'react';

export interface KeyEventSettings {
    keyEvent: string // Ex: 'keyDown', 'keyUp'
    keys: string[] // Ex: 'ArrowLeft', 'ArrowUp'
    onAction: () => void
}

const useKeyPressedEventHandler = (keyEventSettings: KeyEventSettings[]) => {
  useEffect(() => {
    const keyActionHandler = (event) => {
      keyEventSettings.forEach((keyEventSetting) => {
        if (keyEventSetting.keys.includes(event.key) || keyEventSetting.keys.includes(event.code)) {
          keyEventSetting.onAction();
        }
      });
    };

    const uniqueKeyEvents = [...new Set(keyEventSettings.map(({ keyEvent }) => keyEvent))];

    uniqueKeyEvents.forEach((keyEvent) => {
      window.addEventListener(keyEvent, keyActionHandler)
    });

    return () => {
      uniqueKeyEvents.forEach((keyEvent) => {
        window.removeEventListener(keyEvent, keyActionHandler);
      });
    };
  }, [keyEventSettings]);
}

export default useKeyPressedEventHandler;
