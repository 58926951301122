import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAText, Iffy } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { usePresentationBuilderState }
  from 'src/components/PresentationBuilder/state/PresentationBuilderStateProvider';
import TopHeader from './TopHeader';
import SubHeader from './SubHeader';
import { EditMutex } from '@alucio/aws-beacon-amplify/src/models';
import { useUserById } from 'src/state/redux/selector/user';

const styles = StyleSheet.create({
  headerMainWrapper: {
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
  },
  warningBanner: {
    backgroundColor: colors['color-warning-500'],
    height: 56,
  },
  bulkAction: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingVertical: 8,
  },
});

const HeaderMutexOverlay: React.FC<{
  editMutex: EditMutex
}> = (props) => {
  const { editMutex } = props
  const userORM = useUserById(editMutex?.userId || '');
  const { cancelPresentation } = usePresentationBuilderState()
  return (
    <DNABox
      appearance="row"
      alignY="center"
      style={styles.warningBanner}
      spacing="between"
    >
      <DNABox appearance="row" style={{ marginLeft: 12 }}>
        <DNAText status="basic" bold testID="current-user-editing-name">{`${userORM?.meta.formattedName} `}</DNAText>
        <DNAText status="basic">
          is currently editing. Only one person can edit a file at a time. Please come back later.
        </DNAText>
      </DNABox>
      <DNABox style={{ marginRight: 4 }}>
        <DNAButton
          size="xs"
          status="tertiary"
          onPress={cancelPresentation}
        >
          Close
        </DNAButton>
      </DNABox>
    </DNABox>
  )
}

const Header: React.FC = () => {
  const {
    selectedGroups,
    customDeck,
    isCustomDeckLockedByOthers,
    disablePreview,
  } = usePresentationBuilderState();

  return (
    <DNABox appearance="col" style={styles.headerMainWrapper}>
      <Iffy is={isCustomDeckLockedByOthers}>
        <HeaderMutexOverlay editMutex={customDeck?.model.editMutex!} />
      </Iffy>
      <TopHeader />
      <SubHeader showActionButtons={!!selectedGroups.length} disablePreview={disablePreview} />
    </DNABox>
  )
}

export default Header
