import React from 'react'
import { StyleSheet } from 'react-native';
import { useDispatch } from 'src/state/redux'
import { DNAButton, DNAModal, DNAText, Iffy, luxColors, LuxStatus } from '@alucio/lux-ui'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'

interface ButtonProps {
  status: LuxStatus
  iconLeft?: string
  appearance?: 'outline' | 'ghost' | 'filled'

}

export const styles = StyleSheet.create({
  body: {
    paddingHorizontal: 24,
    paddingVertical: 12,
    width: 560,
  },
  header: {
    paddingLeft: 24,
    paddingRight: 16,
    paddingBottom: 16,
    width: 560,
  },
});

interface DNACommonConfirmation {
  title?: string
  descriptionText?: string
  confirmActionText?: string
  onConfirmAction?: () => void
  hideCancelButton?: boolean
  status?: LuxStatus
  cancelText?: string
  cancelButtonProps?: ButtonProps
  onCancelCallback?: () => void
}

const DNACommonConfirmation:React.FC<DNACommonConfirmation> = ({
  onConfirmAction, title, status = 'primary', descriptionText, cancelText, confirmActionText,
  onCancelCallback,
  hideCancelButton,
  cancelButtonProps = { appearance: 'outline', status: 'tertiary' },
}) => {
  const dispatch = useDispatch()

  const closeModal = () => dispatch(DNAModalActions.closeModal())

  const onCancel = () => {
    closeModal()
    onCancelCallback?.()
  }

  const onAction = () => {
    onConfirmAction && onConfirmAction()
    dispatch(DNAModalActions.closeModal())
  }

  return (
    <DNAModal>
      <DNAModal.Header style={styles.header} onClose={onCancel}>
        <DNAText h5 testID="confirmation-modal-header-text">{title}</DNAText>
      </DNAModal.Header>
      <Iffy is={descriptionText}>
        <DNAModal.Body style={styles.body}>
          <DNAText style={{ color: luxColors.flat.primary }}>{descriptionText}</DNAText>
        </DNAModal.Body>
      </Iffy>
      <DNAModal.Confirmation bodyless={!descriptionText}>
        <Iffy is={!hideCancelButton}>
          <DNAButton testID="cancel-button" {...cancelButtonProps} onPress={onCancel} size="sm">
            { cancelButtonProps.status
              ? `${cancelText || 'Cancel'}`
              : <DNAText status="flat">{cancelText || 'Cancel'}</DNAText>
            }
          </DNAButton>
        </Iffy>
        <Iffy is={onConfirmAction}>
          <DNAButton
            testID="confirmation-button"
            onPress={onAction}
            status={status}
            size="sm"
          >{confirmActionText}</DNAButton>
        </Iffy>
      </DNAModal.Confirmation>
    </DNAModal>
  )
}

export default DNACommonConfirmation
