import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Pressable, ScrollView, StyleSheet, TouchableOpacity, ViewStyle, useWindowDimensions } from 'react-native';
import { DNABox, DNAButton, DNAConditionalWrapper, DNAText, Icon, Iffy } from '@alucio/lux-ui';
import { Input } from '@ui-kitten/components';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import { Page } from '@alucio/aws-beacon-amplify/src/models';
import { DocumentVersionORM } from 'src/types/orms';
import { isDocumentVersionORM } from 'src/types/typeguards';
import useContentPageData, {
  ContentPageData,
  getSlideContentPageDataTitle,
} from 'src/hooks/useContentPageData/useContentPageData';
import { ThumbnailSize, thumbnailSizeDimensions } from 'src/hooks/useThumbnailSize/useThumbnailSize';
import ContentSearchBar, { ContentSearchResult } from 'src/components/ContentSearchBar/ContentSearchBar';
import { getThumbURL } from 'src/utils/thumbnailHelpers';
import { getPresentable } from 'src/state/context/ContentProvider/helper';
import { S } from '..'
// Virtualized thumbnail
import {
  RecyclerListView,
  DataProvider,
  LayoutProvider,
  RecyclerListViewProps,
} from 'recyclerlistview';
import { RecyclerListViewState } from 'recyclerlistview/dist/reactnative/core/RecyclerListView';
import { useAllDocumentVersionMap } from 'src/state/redux/selector/document';

const DEBUG_PAGE_PADDING = 40;
const BEACON_NAV_BAR_WIDTH = 88;

const styles = StyleSheet.create({
  flex: {
    flex: 1,
    minHeight: 500,
  },
  header: {
    paddingBottom: 20,
  },
  searchIcon: {
    width: 17.5,
    height: 17.5,
  },
  searchWithIDBtn: {
    height: 40,
    marginLeft: 8,
    marginBottom: 4,
  },
});

/** RECYCLERLISTVIEW TYPES */
type RViewProps = {
  layoutProvider: LayoutProvider,
  dataProvider: DataProvider,
  rowRenderer: RecyclerListViewProps['rowRenderer'],
  style: ViewStyle,
  canChangeSize: boolean
}

enum ItemType {
  CURRENT_SLIDE,
  RECOMMENDATION,
}

type SlideData = {
  pageId: string,
  score?: number,
}

type VirtualizedThumbnailListProps = {
  selectedDocumentVersionORM: DocumentVersionORM,
  documentVersionORM: DocumentVersionORM,
  contentPageData: ContentPageData[],
  isPreviousVersion?: boolean,
  selectedDocumentContentPageData?: ContentPageData[],
  openCPMInNewTab?: (documentId: string, pageNum?: number) => void,
}

type ContentProps = {
  selectedDocumentVersionORM?: DocumentVersionORM
}

const THUMBNAIL_RATIO = 66 / 120;

const MAX_NUMBER_OF_VISIBLE_ROWS = 3;
const HORIZONTAL_MARGIN = 8;
const VERTICAL_MARGIN = 11 + 18;

const AssociatedSlidesThumbnails: React.FC<{
    pages: Page[],
    docVerORM: DocumentVersionORM,
    size: ThumbnailSize,
    getSlideData?: (number: number) => SlideData,
}> = (props) => {
  const {
    docVerORM,
    pages,
    size,
    getSlideData,
  } = props

  const [expanded, setExpanded] = useState<boolean>(false)

  const thumbnailSize = thumbnailSizeDimensions[size]
  const rowItemCount = (thumbnailSize.width / (thumbnailSizeDimensions.xxs.width + HORIZONTAL_MARGIN))
  const visibleRow = !expanded
    ? pages.slice(0, rowItemCount)
    : pages

  const maxContainerWidth = thumbnailSize.width
  const maxContainerHeight = expanded
    ? (thumbnailSizeDimensions.xxs.height * MAX_NUMBER_OF_VISIBLE_ROWS) + (VERTICAL_MARGIN * MAX_NUMBER_OF_VISIBLE_ROWS)
    : (thumbnailSizeDimensions.xxs.height) + VERTICAL_MARGIN

  const showExpandButton = (
    (visibleRow.length > 0) &&
    (pages.length > rowItemCount)
  )

  const getSlideTitle = useCallback((pageNumber?: number): string => {
    if (!getSlideData || !pageNumber) return ''
    const { pageId, score } = getSlideData(pageNumber)
    return `pageId: ${pageId}, score: ${score}`;
  }, [getSlideData])

  return (
    <DNABox
      appearance="col"
      spacing="sm"
      childFill={1}
      style={{ maxWidth: maxContainerWidth }}
    >
      <ScrollView contentContainerStyle={{ maxHeight: maxContainerHeight }}>
        <DNABox
          spacing="sm"
          alignX="center"
          fill
          wrap="center"
          keepSpacingLastItem
          childStyle={{ marginBottom: 4 }}
        >
          {/* PREVIEW ROW */}
          {
            visibleRow.map(page => {
              if (!page) return null;
              const score = getSlideData?.(page.number).score
              const thumbURL = docVerORM.meta
                .assets
                .thumbnailKey
                ?.split('/')
                .slice(0, -1)
                .join('/') + `/${page.number}_medium_thumb.png`

              return (
                <DNABox appearance="col">
                  <DNAThumbnail
                    key={page.pageId}
                    s3URL={thumbURL}
                    size="xxs"
                    pageNumber={page.number}
                    mode={DNAThumbnail.Modes.READ_ONLY}
                    variant={DNAThumbnail.Variants.ASSOCIATED}
                    resizeMode="cover"
                    preview={{ pages: pages, docVerORM, getSlideTitle }}
                  />
                  <Iffy is={score}>
                    <DNAText>{score}</DNAText>
                  </Iffy>
                </DNABox>
              )
            })
          }
        </DNABox>
      </ScrollView>

      <DNABox
        fill
        appearance="row"
        spacing="sm"
        childFill
      >
        <Iffy is={showExpandButton}>
          <DNAButton
            appearance="outline"
            status="tertiary"
            stretch
            onPress={() => setExpanded(p => !p)}
          >
            {
              !expanded
                ? `Show all (+${pages.length - visibleRow.length})`
                : 'Hide'
            }
          </DNAButton>
        </Iffy>

      </DNABox>
    </DNABox>
  )
}

const Slide: React.FC<{
  page: Page,
  thumbURL: string,
  thumbnailTitle: string,
  recommendedPages?: Page[],
  documentVersionORM?: DocumentVersionORM,
  getSlideData?: (pageNum: number) => SlideData,
}> = ({
  page,
  thumbURL,
  thumbnailTitle,
  recommendedPages,
  documentVersionORM,
  getSlideData,
}) => {
  const dimensions = useWindowDimensions();
  const thumbnailWidth = useMemo(() => {
    const containerWidth = ((dimensions.width - DEBUG_PAGE_PADDING - BEACON_NAV_BAR_WIDTH) / 3) - 10;
    return containerWidth
  }, [dimensions]);

  return (
    <DNABox fill appearance="col" spacing="sm" alignX="center">
      <DNAThumbnail
        variant={DNAThumbnail.Variants.INFO}
        key={thumbURL}
        s3URL={thumbURL}
        useLoadingIndicator
        thumbnailTitle={thumbnailTitle}
        height={thumbnailWidth * THUMBNAIL_RATIO}
        width={thumbnailWidth}
        pageNumber={page.number}
      />
      {
        documentVersionORM && recommendedPages &&
          <AssociatedSlidesThumbnails
            docVerORM={documentVersionORM}
            pages={recommendedPages}
            size="lg"
            getSlideData={getSlideData}
          />
      }
    </DNABox>
  )
}

const VirtualizedThumbnailList: React.FC<VirtualizedThumbnailListProps> = (props) => {
  const {
    selectedDocumentVersionORM,
    documentVersionORM,
    contentPageData,
    isPreviousVersion,
    selectedDocumentContentPageData,
    openCPMInNewTab,
  } = props
  const dimensions = useWindowDimensions();
  const scrollViewRef = useRef<RecyclerListView<RViewProps, RecyclerListViewState>>(null);

  const dataProviderInit = useMemo(() => {
    return new DataProvider((r1: string, r2: string) => {
      return r1 !== r2;
    });
  }, []);

  // ROW DATA
  const dataProvider = useMemo(() => {
    return dataProviderInit.cloneWithRows(selectedDocumentVersionORM.relations.pages)
  }, [selectedDocumentVersionORM, contentPageData, selectedDocumentContentPageData]);

  const layoutProvider = useMemo(() => {
    const _layoutProvider = new LayoutProvider((_idx) => {
      if (isPreviousVersion) return ItemType.RECOMMENDATION
      else return ItemType.CURRENT_SLIDE
    },
    (type, dim, _idx) => {
      const containerWidth = (dimensions.width - DEBUG_PAGE_PADDING - BEACON_NAV_BAR_WIDTH) / 3;
      const containerHeight = (containerWidth * THUMBNAIL_RATIO) + 220

      switch (type) {
        case ItemType.RECOMMENDATION: {
          dim.width = containerWidth;
          dim.height = containerHeight;
          break;
        }
        case ItemType.CURRENT_SLIDE: {
          dim.width = containerWidth;
          dim.height = containerHeight;
          break;
        }
      }
    })
    // _layoutProvider.shouldRefreshWithAnchoring = false;
    return _layoutProvider;
  }, [selectedDocumentVersionORM, contentPageData, selectedDocumentContentPageData, dimensions]);

  const rowRenderer = useCallback<RecyclerListViewProps['rowRenderer']>(
    // @ts-expect-error
    (type, data, idx, _extendedState: ExtendedState) => {
      switch (type) {
        case ItemType.RECOMMENDATION: {
          // We assume recommandations should be sorted by highest score to lowest
          const recommendations = selectedDocumentContentPageData?.[idx]?.recommendations || [];
          const highestScorePage = documentVersionORM.relations.pages
            .find(page => page.model.pageId === recommendations?.[0]?.pageId)?.model;
          if (!recommendations.length || !highestScorePage) return null

          const thumbURL = getThumbURL(documentVersionORM, highestScorePage.number)
          const thumbnailTitle = `score: ${recommendations[0].score} | ` +
            getSlideContentPageDataTitle(highestScorePage.number - 1, contentPageData, true)
          const recommendedPages = recommendations.reduce<Page[]>((acc, curr) => {
            const page = documentVersionORM.relations.pages
              .find(page => page.model.pageId === curr.pageId)?.model;
            if (page) return [...acc, page]
            else return acc
          }, [])

          const getSlideData = (pageNumber?: number): SlideData => {
            const pageId = `${documentVersionORM.model.id}_${pageNumber}`
            const score = recommendations.find(recommendation => recommendation.pageId === pageId)?.score
            return { pageId, score }
          }

          return (
            <Slide
              page={highestScorePage}
              thumbURL={thumbURL}
              thumbnailTitle={thumbnailTitle}
              recommendedPages={recommendedPages}
              documentVersionORM={documentVersionORM}
              getSlideData={getSlideData}
            />
          )
        }
        case ItemType.CURRENT_SLIDE: {
          const { model } = data;
          const page = model as Page
          const thumbURL = getThumbURL(documentVersionORM, page.number)
          const thumbnailTitle = getSlideContentPageDataTitle(page.number - 1, contentPageData, true)
          return (
            <DNAConditionalWrapper
              condition={openCPMInNewTab}
              wrapper={children => (
                <Pressable onPress={() => openCPMInNewTab?.(documentVersionORM.model.documentId, page.number)}>
                  {children}
                </Pressable>
              )}
            >
              <Slide
                page={page}
                thumbURL={thumbURL}
                thumbnailTitle={thumbnailTitle}
              />
            </DNAConditionalWrapper>
          )
        }
        default: {
          return null
        }
      }
    },
    [layoutProvider, dataProvider, documentVersionORM, contentPageData, selectedDocumentContentPageData])

  return (
    <RecyclerListView
      key={documentVersionORM.model.id}
      layoutProvider={layoutProvider}
      dataProvider={dataProvider}
      rowRenderer={rowRenderer}
      style={styles.flex}
      canChangeSize
      ref={scrollViewRef}
    />
  )
}

const Content: React.FC<ContentProps> = (props) => {
  const { selectedDocumentVersionORM } = props;
  const allVersions = selectedDocumentVersionORM?.relations.documentORM.relations.documentVersions;
  const currentVersionIndex = allVersions?.findIndex(docVer => docVer.model.id === selectedDocumentVersionORM?.model.id)
  const previousDocumentVersionORM = (
    allVersions &&
    currentVersionIndex !== undefined &&
    currentVersionIndex !== -1
  )
    ? allVersions[currentVersionIndex + 1]
    : undefined;
  const selectedDocumentPresentable = getPresentable(selectedDocumentVersionORM);
  const previousDocumentPresentable = getPresentable(previousDocumentVersionORM);
  const { contentPageData: selectedDocumentContentPageData } =
    useContentPageData(selectedDocumentPresentable, true);
  const { contentPageData: previousDocumentContentPageData } =
    useContentPageData(previousDocumentPresentable, true);

  const openCPMInNewTab = (documentId: string, pageNum?: number) => {
    const url = new URL(`/library/${documentId}/${pageNum ?? 1}/`, window.location.origin)
    window.open(url.toString(), '_blank')
  }

  return (
    <DNABox fill appearance="col">
      {/* HEADER */}
      <DNABox>
        { previousDocumentVersionORM &&
          <DNABox spacing="sm" appearance="col" style={[styles.header, { flexBasis: '66.66%' }]}>
            <DNABox appearance="col">
              <DNAText h4>Previous Published Version</DNAText>
              <DNAText h4>status: {previousDocumentVersionORM.model.status}</DNAText>
              <DNAText h4>DocumentVersionId: {previousDocumentVersionORM.model.id}</DNAText>
              <DNAText h4 numberOfLines={2}>Title: {previousDocumentVersionORM.model.title || ''}</DNAText>
            </DNABox>
            <DNABox>
              <DNAButton
                appearance="outline"
                status="tertiary"
                onPress={() => openCPMInNewTab(previousDocumentVersionORM.model.documentId)}
              >
                Open CPM
              </DNAButton>
            </DNABox>
          </DNABox>
        }
        { selectedDocumentVersionORM &&
          <DNABox spacing="sm" appearance="col" style={[styles.header, { flexBasis: '33.33%' }]}>
            <DNABox appearance="col">
              <DNAText h4>Selected Version</DNAText>
              <DNAText h4>status: {selectedDocumentVersionORM.model.status}</DNAText>
              <DNAText h4>DocumentVersionId: {selectedDocumentVersionORM.model.id}</DNAText>
              <DNAText h4 numberOfLines={2}>Title: {selectedDocumentVersionORM.model.title || ''}</DNAText>
            </DNABox>
            <DNABox>
              <DNAButton
                appearance="outline"
                status="tertiary"
                onPress={() => openCPMInNewTab(selectedDocumentVersionORM.model.documentId)}
              >
                Open CPM
              </DNAButton>
            </DNABox>
          </DNABox>
        }
      </DNABox>
      {/* SLIDES */}
      <DNABox fill>
        { selectedDocumentVersionORM && previousDocumentVersionORM && previousDocumentContentPageData.length &&
          <VirtualizedThumbnailList
            selectedDocumentVersionORM={previousDocumentVersionORM}
            documentVersionORM={previousDocumentVersionORM}
            contentPageData={previousDocumentContentPageData}
          />
        }
        { selectedDocumentVersionORM && previousDocumentVersionORM && previousDocumentContentPageData.length &&
          <VirtualizedThumbnailList
            selectedDocumentVersionORM={selectedDocumentVersionORM}
            documentVersionORM={previousDocumentVersionORM}
            contentPageData={previousDocumentContentPageData}
            isPreviousVersion
            selectedDocumentContentPageData={selectedDocumentContentPageData}
          />
        }
        { selectedDocumentVersionORM && selectedDocumentContentPageData.length &&
          <VirtualizedThumbnailList
            selectedDocumentVersionORM={selectedDocumentVersionORM}
            documentVersionORM={selectedDocumentVersionORM}
            contentPageData={selectedDocumentContentPageData}
            openCPMInNewTab={openCPMInNewTab}
          />
        }
      </DNABox>
    </DNABox>
  )
}

const MatchSlides: React.FC = () => {
  const docVersionMap = useAllDocumentVersionMap();
  const [isSearchBarVisible, setIsSearchBarVisible] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedDocumentVersionORM, setsSelectedDocumentVersionORM] = useState<DocumentVersionORM | undefined>();

  const handleSelectItem = (item: ContentSearchResult) => {
    if (isDocumentVersionORM(item.orm)) setsSelectedDocumentVersionORM(item.orm)
  };

  const onSearchWithID = useCallback(() => {
    if (searchText) setsSelectedDocumentVersionORM(docVersionMap[searchText])
  }, [docVersionMap, searchText])

  const renderSearchIcon = () => {
    return (
      <TouchableOpacity>
        <Icon style={styles.searchIcon} name="magnify" />
      </TouchableOpacity>
    )
  }

  return (
    <DNABox fill appearance="col" style={S.tabContainer}>
      {/* HEADER */}
      <DNABox appearance="col" style={styles.header}>
        <Iffy is={!isSearchBarVisible}>
          <DNAButton onPress={() => setIsSearchBarVisible(true)}>
            Show search bar
          </DNAButton>
        </Iffy>
        <Iffy is={isSearchBarVisible}>
          <ContentSearchBar
            onSelectItem={handleSelectItem}
            variant="HUBS"
            isVisible={isSearchBarVisible}
            setIsVisible={setIsSearchBarVisible}
          />
          <DNAText>Or</DNAText>
          <DNABox>
            <Input
              placeholder="Search for document version ID"
              value={searchText}
              onChangeText={setSearchText}
              autoFocus={true}
              style={{ flex: 1 }}
              accessoryLeft={renderSearchIcon}
            />
            <DNAButton
              appearance="outline"
              status="tertiary"
              size="sm"
              onPress={onSearchWithID}
              style={styles.searchWithIDBtn}
            >
              Search with ID
            </DNAButton>
          </DNABox>
        </Iffy>
      </DNABox>
      {/* CONTENT */}
      <Content selectedDocumentVersionORM={selectedDocumentVersionORM}/>
    </DNABox>
  )
}

export default MatchSlides
