import React from 'react'
import DNAButton, { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton'
import DNAPopover from '../Popover/DNAPopover'
import DNAConditionalButtonStateProvider from './DNAConditionalButtonStateProvider'
import { DNABox } from '@alucio/lux-ui'

interface ComposableChildBaseProps {
  children: React.ReactNode
}

interface ActiveProps extends ComposableChildBaseProps {}

const Active: React.FC<ActiveProps> = ({ children }) => {
  return <>{children}</>
}

interface DisableProps extends ComposableChildBaseProps {
  condition:boolean,
}

const Disable: React.FC<DisableProps> = ({ children }) => {
  return <>{children}</>
}

export interface DNAConditionalButtonProps extends DNAButtonProps {}

type DNAConditionalButtonComponent = React.FC<DNAConditionalButtonProps> & {
  Active: typeof Active
  Disable: typeof Disable
}

type DisableElement = React.ReactElement<DisableProps>
export const isDisableChild = (child: unknown): child is DisableElement => child
  ? React.isValidElement(child) && child.type === DNAConditionalButton.Disable
  : false

type ActiveElement = React.ReactElement<ActiveProps>
export const isActiveChild = (child: unknown): child is ActiveElement => child
  ? React.isValidElement(child) && child.type === DNAConditionalButton.Active
  : false

const DNAConditionalButton: DNAConditionalButtonComponent = ({
  children,
  ...rest
}) => {
  const nonComposableElementChildren: React.ReactNode[] = []
  const disableChildren:DisableElement[] = []
  const activeChildren:ActiveElement[] = []

  /** This iterator picks out the composable element children for use in the popover content */
  React.Children
    .toArray(children)
    .forEach(child => {
      React.isValidElement(child)
        ? composableChildComponentTypes.some(composableType => child.type === composableType)
          ? isDisableChild(child)
            ? disableChildren.push(child)
            : isActiveChild(child)
              ? activeChildren.push(child)
              : nonComposableElementChildren.push(child)
          : nonComposableElementChildren.push(child)
        : nonComposableElementChildren.push(child)
    })

  const disableContentElement = disableChildren.find(child => child.props.condition)
  /** TODO: Consider only allowing a single instance of the DNAConditionalButton.Active child components.
* In this implementation only the first one will render and all subsequent will be ignored. */
  const activeContentElement = activeChildren[0]

  const buttonContentNodes = nonComposableElementChildren.length ? nonComposableElementChildren : undefined

  return (
    /** NOTE: Currently this context is unused but serves as a placeholder for any possible
     * composable component communication that may need to be added in the future */
    <DNAConditionalButtonStateProvider>
      <DNABox>
        <DNAPopover disablePopover={!activeContentElement && !disableContentElement}>

          <DNAPopover.Anchor>
            <DNAButton {...rest} disabled={!!disableContentElement}>{buttonContentNodes}</DNAButton>
          </DNAPopover.Anchor>

          <DNAPopover.Content>{disableContentElement ?? activeContentElement}</DNAPopover.Content>

        </DNAPopover>
      </DNABox>
    </DNAConditionalButtonStateProvider>
  )
}

DNAConditionalButton.Active = Active
DNAConditionalButton.Active.displayName = 'Active'
DNAConditionalButton.Disable = Disable
DNAConditionalButton.Disable.displayName = 'Disable'

const composableChildComponentTypes = [
  DNAConditionalButton.Active,
  DNAConditionalButton.Disable,
]

export default DNAConditionalButton
