import { DNABox, DNADivider } from '@alucio/lux-ui';
import { DNABoxProps } from '@alucio/lux-ui/src/components/layout/DNABox/DNABox';
import mergeStyles from '@alucio/lux-ui/src/components/util/mergeStyles';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import React from 'react';
import { FlatList, StyleSheet, View } from 'react-native';

const styles = StyleSheet.create({
  thumbnailSkeleton: {
    // width: 120,
    // height: 65,
    width: 160,
    height: 87,
    borderRadius: 4,
  },
  skeletonTextWrapper: {
    flexGrow: 1,
    marginLeft: 16,
  },
  skeletonMainText: {
    height: 14,
  },
  skeletonSubText: {
    height: 14,
    width: 'calc(100% - 40px)',
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: 16,
  },
})

interface Props {
  numberOfItems?: number
  variant?: 'default' | 'dark'
}

// todo: add variant for meetings
const ListSkeleton: React.FC<Props> = ({ numberOfItems = 3, variant = 'default' }) => {
  const row = (
    <View style={[styles.loadingWrapper, variant === 'default' && { backgroundColor: 'white' }]}>
      <Skeleton variant={variant} style={styles.thumbnailSkeleton}/>
      <DNABox spacing="sm" style={styles.skeletonTextWrapper} appearance="col">
        <Skeleton variant={variant} style={styles.skeletonMainText}/>
        <Skeleton variant={variant} style={styles.skeletonSubText}/>
      </DNABox>
    </View>
  )

  const Divider = <DNADivider variant={variant === 'dark' ? 'virtual' : 'default'} />

  return (
    <FlatList
      data={Array.from(Array(numberOfItems).keys())}
      renderItem={(_) => row}
      keyExtractor={(_, index) => `${index}`}
      ItemSeparatorComponent={() => Divider}
    />
  )
}

// todo: i think this can be a lux ui component
interface SkeletonProps {
  variant?: 'default' | 'dark'

}

export const Skeleton: React.FC<DNABoxProps & SkeletonProps> = (props) => {
  const { style, variant, ...rest } = props;
  const mergedStyle = mergeStyles(undefined, style,
    { backgroundColor: variant === 'dark' ? colors['color-gray-500'] : colors['color-gray-50'] })

  return (
    <DNABox {...rest} style={mergedStyle}/>
  )
}

export default ListSkeleton;
