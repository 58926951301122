import {
  FieldDataType,
  ControlType,
  MeetingFieldDefinition,
  MeetingAttendeeStatus, CustomFieldDefinition, AttendeeType,
} from '@alucio/aws-beacon-amplify/src/models';
import isEmpty from 'lodash/isEmpty';
import { CustomFieldValuesMap, MeetingORM } from 'src/types/orms';
import { fields } from 'src/hooks/useMeetingFormConfig/AddMeetingConfig';

const beaconConfig = {
  fields: [
    // [TODO-3077] - Convert to object with named key so it's more clear than just an array idx
    { ...fields[0] },
    { ...fields[3] },
    { ...fields[4] },
  ],
}

beaconConfig.fields.push(...fields.slice(5));

const getDynamicDefaultValues = (meetingDefinition: CustomFieldDefinition[]) : CustomFieldValuesMap => {
  return meetingDefinition?.reduce<CustomFieldValuesMap>((acc, curr) => {
    if (curr.controlType === ControlType.TEXTAREA || curr.controlType === ControlType.INPUT) {
      acc[curr.id] = {
        field: curr,
        displayValues: [''],
        values: [''],
      }
    }
    return acc;
  }, {})
}

export const initialDefaultValues = (
  meeting : MeetingORM | undefined,
  customFieldsDefinition: CustomFieldDefinition[],
) => meeting
  ? isEmpty(meeting.meta.customValues.configsMap)
    ? getDynamicDefaultValues(customFieldsDefinition)
    : meeting.meta.customValues.configsMap
  : {}

const getBeaconConfig = (meetingORM?: MeetingORM ) => {
  const baseDefPrimaryAttendee = fields[3];
  const baseDefAdditionalAttendee = fields[4];

  beaconConfig.fields[1] = {
    ...baseDefPrimaryAttendee,
    fieldValueDefinitions: meetingORM
      ?.model?.attendees?.filter(attendee =>
        attendee.status !== MeetingAttendeeStatus.REMOVED && attendee.attendeeType === AttendeeType.PRIMARY)

      ?.map(attendee => ({
        id: attendee.id, value: attendee.name, disabled: false, createdAt: attendee.updatedAt, dependentValueIds: [],
      })) || [],
  }

  beaconConfig.fields[2] = {
    ...baseDefAdditionalAttendee,
    fieldValueDefinitions: meetingORM
      ?.model?.attendees?.filter(attendee =>
        attendee.status !== MeetingAttendeeStatus.REMOVED && attendee.attendeeType === AttendeeType.SECONDARY)
      ?.map(attendee => ({
        id: attendee.id, value: attendee.name, disabled: false, createdAt: attendee.updatedAt, dependentValueIds: [],
      })) || [],
  }

  return beaconConfig;
}

export const setDefaultDynamicValues = (customFields: MeetingFieldDefinition[], key: string) => {
  const customField = customFields.find(field => field.id === key);
  const defaultValues = {}
  if (customField?.type === FieldDataType.STRING) {
    defaultValues[key] = '';
  }
  else if (customField?.type === FieldDataType.DATE) {
    defaultValues[key] = new Date().toISOString();
  }
  else if (customField?.type === FieldDataType.CATEGORICAL) {
    defaultValues[key] = [];
  }
  else if (customField?.type === FieldDataType.MULTICATEGORICAL) {
    defaultValues[key] = [];
  }
  else if (customField?.type === FieldDataType.USER_LIST) {
    defaultValues[key] = [];
  }
  else {
    console.warn('[WARN] - Custom field type not supported');
  }

  return defaultValues
}

export default getBeaconConfig;
