import { Tab } from 'src/screens/Meetings/PresentationControls/PresentationControls';
import { useEffect, useRef, useState } from 'react'

const usePresentationControlsState = (initialTab: Tab) => {
  const [currentTab, setCurrentTab] = useState<Tab>(initialTab ?? 'BROWSE_CONTENT');
  const previousTab = useRef<Tab>(initialTab ?? 'BROWSE_CONTENT');

  const handleCurrentTabUpdate = () => {
    currentTab !== 'PRESENTING' && (previousTab.current = currentTab)
  }

  useEffect(handleCurrentTabUpdate, [currentTab])

  return {
    currentTab,
    setCurrentTab,
    previousTab,
  }
}

export default usePresentationControlsState
