import { MouseEvent, TouchEvent } from 'react';
import { isIOS } from 'react-device-detect';

export type CanvasEvent = MouseEvent<HTMLButtonElement> & TouchEvent<HTMLButtonElement>;

export const getClientOffset = (canvas: HTMLCanvasElement) => {
  if (!canvas)
  { return; }

  if (isIOS) {
    // For ios devices i get better results with offsetLeft and offsetTop
    const canvasOffset = canvas.getBoundingClientRect();

    const x = canvasOffset.left;
    const y = canvasOffset.top + window.scrollY;

    return { x, y };
  }

  return { x: canvas.offsetLeft, y: canvas.offsetTop };
};

export const getClientPosition = (event: CanvasEvent, touch?: boolean, index?: number) => {
  const { nativeEvent } = event;

  if (touch && index !== undefined) {
    const touches = nativeEvent.changedTouches;
    const touch = touches[index];

    return { x: touch.clientX, y: touch.clientY };
  }

  if (isIOS) {
    const x = nativeEvent.pageX;
    const y = nativeEvent.pageY;

    return { x, y };
  }

  const x = nativeEvent.offsetX;
  const y = nativeEvent.offsetY;

  return { x, y };
}
