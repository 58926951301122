import React, { useEffect, useRef, useState, useMemo } from 'react'
import { StyleSheet } from 'react-native'
import {
  DNABox,
  DNAButton,
  DNAChip,
  DNAContextMenu,
  DNAText,
  Iffy,
  DNADivider,
  DNACheckbox,
  util,
} from '@alucio/lux-ui'
import { DNABoxProps } from '@alucio/lux-ui/src/components/layout/DNABox/DNABox'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import {
  GroupStatus,
  ModifiedPayloadGroup,
  PayloadGroup,
  unavailableStatus,
  usePresentationBuilderState,
} from 'src/components/PresentationBuilder/state/PresentationBuilderStateProvider'
import CollapsibleCard, { CollapsibleActionMenu } from 'src/components/CollapsibleCard/CollapsibleCard'
import { thumbnailSizeDimensions } from 'src/hooks/useThumbnailSize/useThumbnailSize'
import { GroupItemOverlay } from './Overlays'
import Slide from './Slide'

interface GroupHeaderTitleProps {
  group: ModifiedPayloadGroup
}

interface GroupHeaderActionItemsProps {
  group: ModifiedPayloadGroup
  isMutexLocked?: boolean,
  onCheck?: () => void,
  isChecked?: boolean,
}

const S = StyleSheet.create({
  greyBox: {
    width: '28%',
    height: '35%',
    backgroundColor: colors['color-gray-80'],
    margin: '1.5%',
    borderRadius: 0.5,
  },
  greyBoxContainer: {
    padding: '5%',
  },
  overlayHeader: {
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  selected: {
    borderColor: colors['color-brand2-500'],
    borderRadius: 6,
    borderWidth: 3,
  },
  opaqueBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    borderRadius: 4,
  },
  warningCollapsibleCard: {
    minHeight: 'fit-content',
    borderRadius: 4,
    borderWidth: 3,
    borderColor: colors['color-warning-500'],
  },
  selectedCollapsibleCard: {
    minHeight: 'fit-content',
    borderRadius: 4,
    borderWidth: 3,
    borderColor: colors['color-brand2-500'],
  },
  leftRemoveButton: {
    borderRadius: 4,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  contextMenuButton: {
    borderRadius: 4,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginLeft: 1,
  },
  contextMenuItems: {
    top: 4,
  },
})

export const GroupHeaderTitle: React.FC<GroupHeaderTitleProps> = ({ group:{ name, documentVersionORM } }) => {
  return (
    <DNABox fill appearance="col" alignY="center" style={{ width: 100 }}>
      <DNAText testID="group-header-title" h5 numberOfLines={1}>{name ?? 'Untitled Group'}</DNAText>
      <DNAText c1 status="flat" numberOfLines={1}>{documentVersionORM?.model.title ?? 'Untitled Document'}</DNAText>
    </DNABox>
  )
}

export const GroupHeaderActionItems: React.FC<GroupHeaderActionItemsProps> = ({
  group, isMutexLocked, onCheck, isChecked,
}) => {
  const {
    setSelectedGroups,
    customDeck,
    editorType,
    openFindReplacement,
    onRemoveGroup,
    onRemoveAllUnavailableGroups,
    onSeparateGroup,
  } = usePresentationBuilderState()
  const isUnavailable = !!unavailableStatus[group.groupStatus]
  const isFindAndReplace = group.groupStatus === GroupStatus.MAJOR_UPDATE

  const onHide = () => {
    setSelectedGroups(
      groups => {
        const rv = groups.reduce<PayloadGroup[]>(
          (acc, grp) => {
            if (grp.id === group.id) {
              const action = grp.visible ? 'HIDE' : 'UNHIDE';

              grp.pages.forEach((page) => {
                analytics?.track(`CUSTOM_SLIDE_${action}`, {
                  action: `SLIDE_${action}`,
                  category: 'CUSTOM',
                  customDeckId: customDeck?.model.id,
                  groupId: group.id,
                  pageId: page.pageId,
                  editorType: editorType,
                });
              });

              return [...acc, { ...grp, visible: !grp.visible }]
            }

            return [...acc, grp]
          },
          [],
        )

        return rv
      },
    )
  }

  if (isUnavailable || isFindAndReplace) {
    return (
      <DNABox spacing="sm" alignY="center">
        <Iffy is={isFindAndReplace}>
          <DNAButton
            testID="find-and-replace-button"
            status="warning"
            iconLeft="sync"
            iconStyle={{ marginRight: 4 }}
            onPress={() => openFindReplacement(group)}
            children="Find replacement"
          />
          <DNAButton
            iconLeft="trash-can-outline"
            onPress={() => onRemoveGroup(group)}
            size="sm"
            padding="sm"
            style={S.opaqueBackground}
          />
        </Iffy>
        <Iffy is={isUnavailable}>
          <DNABox>
            <DNAButton
              status="warning"
              iconLeft="trash-can-outline"
              iconStyle={{ marginRight: 4 }}
              onPress={() => onRemoveGroup(group)}
              style={S.leftRemoveButton}
              children="Remove"
            />
            <DNAContextMenu style={S.contextMenuItems}>
              <DNAContextMenu.Anchor>
                <DNAButton
                  onPress={() => { }}
                  iconLeft="chevron-down"
                  status="warning"
                  padding="sm"
                  style={S.contextMenuButton}
                />
              </DNAContextMenu.Anchor>
              <DNAContextMenu.Items>
                <DNAContextMenu.Item
                  collapseOnPress
                  delay={100}
                  title="Remove all unavailable slides"
                  onPress={onRemoveAllUnavailableGroups}
                />
              </DNAContextMenu.Items>
            </DNAContextMenu>
          </DNABox>
        </Iffy>
      </DNABox>
    )
  }
  else {
    return (
      <DNABox spacing="sm" alignY="center">
        <DNAChip
          appearance="subtle"
          status="basic"
          size="lg"
          style={{ borderRadius: 2 }}
        >
          {`${group.pages.length}`}
        </DNAChip>
        <DNAButton
          size="md"
          padding="sm"
          appearance="ghost"
          status="gray"
          iconLeft={group.locked ? 'lock' : 'vector-difference-ba'}
          disabled={group.locked}
          onPress={() => onSeparateGroup(group)}
        />
        <Iffy is={!isMutexLocked}>
          <DNAContextMenu>
            <DNAContextMenu.Anchor>
              <DNAButton
                iconLeft="dots-vertical"
                size="md"
                padding="sm"
                appearance="ghost"
                status="gray"
              />
            </DNAContextMenu.Anchor>
            <DNAContextMenu.Items>
              <DNAContextMenu.Item
                title={ group.visible ? 'Hide' : 'Unhide'}
                icon={ group.visible ? 'eye-off-outline' : 'eye-outline'}
                onPress={onHide}
              />
              <DNAContextMenu.Item
                title="Remove"
                icon="trash-can-outline"
                onPress={() => onRemoveGroup(group)}
              />
            </DNAContextMenu.Items>
          </DNAContextMenu>
        </Iffy>
        <DNACheckbox
          status="gray"
          onChange={onCheck}
          checked={isChecked}
        />
      </DNABox>
    )
  }
}

const GroupedSlides: React.FC<{
    group: ModifiedPayloadGroup,
    startingIdx: number,
    orientation: DNABoxProps['appearance'],
    isDraggingMode: boolean,
    islocked?: boolean,
    isCollapsed?: boolean,
    onCheck?: () => void,
    isChecked?: boolean,
  }> = (props) => {
    const {
      group, orientation, startingIdx, isDraggingMode, islocked, onCheck, isChecked,
    } = props
    const { editorThumbnailSize } = usePresentationBuilderState()
    const thumbnailDimensions = useMemo(() => {
      return {
        height: thumbnailSizeDimensions[editorThumbnailSize].height,
        width: thumbnailSizeDimensions[editorThumbnailSize].width,
      }
    }, [editorThumbnailSize])
    const draggingOverlayStyle = useMemo(() => StyleSheet.create([
      {
        borderRadius: 4,
        borderWidth: 1,
        borderColor: colors['color-gray-100'],
        marginTop: 4,
        marginBottom: 18,
        backgroundColor: colors['color-text-basic'],
        height: thumbnailDimensions.height + 14,
        width: thumbnailDimensions.width + 14,
      },
    ]), [thumbnailDimensions])

    const [isCollapsed, setIsCollapsed] = useState(props.isCollapsed ?? false)
    const currentCollapsedState = useRef<undefined | boolean>(false)

    const isUnavailable = !!unavailableStatus[group.groupStatus]
    const isFindAndReplace = group.groupStatus === GroupStatus.MAJOR_UPDATE

    useEffect(() => {
      // for the ui we wan to drag and drop on collapsed mode to facilitate the drag and drop interaction
      if (isDraggingMode) {
        setIsCollapsed(true)
      }
      // this means the drag and drop finished, so we need to reset the collapse state
      else if ( !isDraggingMode && currentCollapsedState.current !== undefined) {
        setIsCollapsed(currentCollapsedState.current)
      }
    }, [isDraggingMode])

    const cardStyle = util.mergeStyles(
      undefined,
      [S.warningCollapsibleCard, !group.visible || isUnavailable || isFindAndReplace],
      [S.selectedCollapsibleCard, isChecked],
    )

    return (
      <DNABox fill>
        <Iffy is={isDraggingMode}>
          <DNABox appearance="col" style={draggingOverlayStyle}>
            {/* header */}
            <DNABox style={S.overlayHeader} spacing="between" alignY="center">
              <GroupHeaderTitle group={group} />
              <DNAChip
                appearance="subtle"
                status="basic"
                size="lg"
                style={{ borderRadius: 2 }}
              >
                {`${group.pages.length}`}
              </DNAChip>
            </DNABox>
            <DNADivider />
            {/* content */}
            <DNABox fill style={S.greyBoxContainer} wrap="start" alignX="center" alignY="center">
              <DNABox style={S.greyBox} />
              <DNABox style={S.greyBox} />
              <DNABox style={S.greyBox} />
              <DNABox style={S.greyBox} />
              <DNABox style={S.greyBox} />
              <DNABox style={S.greyBox} />
            </DNABox>
          </DNABox>
        </Iffy>
        <Iffy is={!isDraggingMode}>
          <GroupItemOverlay
            group={group}
            enabled
            isLocked={props.islocked}
          >
            <CollapsibleCard
              headerTitle={<GroupHeaderTitle group={group}/>}
              isCollapsed={isCollapsed}
              onToggleChanged={(isCollapsed) => {
                currentCollapsedState.current = isCollapsed
              }}
              cardStyle={cardStyle}
            >
              <CollapsibleActionMenu>
                <GroupHeaderActionItems
                  onCheck={onCheck}
                  isChecked={isChecked}
                  isMutexLocked={islocked}
                  group={group}
                />
              </CollapsibleActionMenu>
              <DNABox
              // [NOTE] - This might be achievable without a prop by normal flexing
              //  but flexing certain containers might mess with DND
                appearance={orientation}
                spacing="lg"
                wrap="stretch"
              >
                {
                group.pages.map((page, idx) => (
                  <DNABox appearance="col" key={`${group.id}-${page.pageId}-${idx}`} style={{ marginBottom: 24 }}>
                    <Slide
                      group={group}
                      page={page}
                      displayIdx={startingIdx ? (startingIdx + idx) : -1}
                      enableOverlay={false}
                    />
                  </DNABox>
                ))
              }
              </DNABox>
            </CollapsibleCard>
          </GroupItemOverlay>
        </Iffy>
      </DNABox>
    )
  }

export default GroupedSlides
