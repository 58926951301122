/* eslint-disable */

import React from 'react';
import {
  DNABox,
  DNAText,
} from '@alucio/lux-ui'
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';



const PlaygroundDesktop = () => {
  return (
    <DNABox>
      <DNAText>Desktop Playground</DNAText>
    </DNABox>
  )
}

const PlaygroundTablet = () => {
  return (
    <DNABox>
      <DNAText>Tablet Playground</DNAText>
    </DNABox>
  )
}

type DeviceModeVariants = Record<DeviceMode, React.ElementType>

const deviceModeVariants:DeviceModeVariants = {
  desktop: PlaygroundDesktop,
  tablet: PlaygroundTablet,
}

export default () => {
  const { deviceMode } = useAppSettings()

  const CurrentDeviceModeVariant = deviceModeVariants[deviceMode]

  return <CurrentDeviceModeVariant />
}
