import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DNABox, DNAButton, DNADivider, DNAText, Iffy } from '@alucio/lux-ui';
import { styles } from '../AddMeetingFooter';
import { useCustomFormRecordProvider } from 'src/state/context/CustomFormProvider/CustomFormProvider';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import ConfirmationModal from 'src/screens/Meetings/PresentationControls/TabContent/MeetingDetails/ConfirmationModal';
import { CustomFormRecordORM } from 'src/types/orms';
import { useUserTenant } from 'src/state/redux/selector/user';
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import { useAppSettings } from 'src/state/context/AppSettings';
import DNAPopover from '../../DNA/Popover/DNAPopover';
import { GLOBAL_TENANT_FORM_ERROR } from '../../TenantForm/TenantFormProvider';

export const useCancelCustomForm = (onConfirmCancel: () => void) => {
  const dispatch = useDispatch();
  const { isDirty } = useCustomFormRecordProvider();

  const onCancel = useCallback(() => {
    if (isDirty) {
      dispatch(
        DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: true,
          component: (props) => (<ConfirmationModal
            {...props}
            onConfirmAction={onConfirmCancel}
          />),
        }));
    } else {
      onConfirmCancel();
    }
  }, [isDirty, onConfirmCancel, dispatch]);

  return onCancel;
};

export enum FORM_VARIANT {
  STANDALONE,
  DEPENDENT
}

interface Props {
  onConfirmCancel: () => void;
  variant?: FORM_VARIANT;
}

const CustomFormRecordFooter = (props: Props) => {
  const { onConfirmCancel, variant = FORM_VARIANT.STANDALONE } = props;
  const { displayCRMConnectButton } = useCRMStatus();
  const { isOnline } = useAppSettings();
  const { isReadOnly, isSaving, recordORM, onSave } = useCustomFormRecordProvider();
  const onCancel = useCancelCustomForm(onConfirmCancel);
  const canOpenInCRM = variant === FORM_VARIANT.STANDALONE && recordORM?.model.crmFields?.externalId;
  const canSubmitToCRM = !displayCRMConnectButton && variant === FORM_VARIANT.STANDALONE && !isReadOnly;

  return (
    <DNABox appearance="col">
      <DNADivider />
      <DNABox spacing="sm" alignX="end" alignY="center" style={styles.content}>
        {/* Cancel Button */}
        <Iffy is={!isReadOnly}>
          <DNAButton
            disabled={isSaving}
            appearance="outline"
            status="tertiary"
            onPress={onCancel}
            testID="form-cancel-button"
          >
            Cancel
          </DNAButton>
        </Iffy>
        {/* Save Button */}
        <Iffy is={variant === FORM_VARIANT.DEPENDENT && !isReadOnly}>
          <DNAButton
            disabled={isSaving}
            style={{ maxHeight: 43 }}
            appearance="filled"
            onPress={() => onSave()}
            testID="form-save-button"
          >
            Save
          </DNAButton>
        </Iffy>
        {/* Submit Button */}
        <Iffy is={canSubmitToCRM}>
          <DNAPopover disablePopover={isOnline}>
            <DNAPopover.Anchor>
              <DNAButton
                disabled={isSaving || !isOnline}
                style={{ maxHeight: 43 }}
                appearance="filled"
                onPress={() => onSave(true)}
                testID="form-submit-button"
              >
                Submit
              </DNAButton>
            </DNAPopover.Anchor>
            <DNAPopover.Content>
              <DNAText status="basic">{GLOBAL_TENANT_FORM_ERROR.submit_offline}</DNAText>
            </DNAPopover.Content>
          </DNAPopover>
        </Iffy>
        {/* Open in CRM Button */}
        {
          canOpenInCRM &&
            <OpenInCRMButton recordORM={recordORM} />
        }
      </DNABox>
    </DNABox>
  );
};

interface OpenInCRMProps {
  recordORM: CustomFormRecordORM;
}

function OpenInCRMButton(props: OpenInCRMProps) {
  const { recordORM } = props;
  const tenant = useUserTenant();

  const openInCRM = (): void => {
    const externalId = recordORM.model.crmFields?.externalId;
    const instanceUrl = tenant?.config.crmIntegration?.instanceUrl;
    if (externalId && instanceUrl) {
      window.open(`${instanceUrl}/${externalId}`, '_blank');
    }
  };

  return (
    <DNAButton
      style={{ maxHeight: 43 }}
      appearance="outline"
      status="tertiary"
      size="sm"
      onPress={openInCRM}
      testID="open-in-crm-button"
    >
      View in CRM
    </DNAButton>
  );
}

export default CustomFormRecordFooter;
