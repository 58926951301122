import React, { useCallback, useMemo } from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { DNABox, util } from '@alucio/lux-ui';
import { useMediaQuery } from 'react-responsive';
import { useMatchSlidesState } from 'src/state/machines/publisherVersioning/MatchSlides/MatchSlidesProvider';
// Dnd
import { POOL_CONTAINER_ID, useSingleItemDnd } from 'src/components/DnD/DNASingleItemDnd';
import SlideGroupTargetsStateProvider, {
  MATCH_SLIDE_STATUS,
  useSlideGroupTargetsState,
} from '../SlideGroupTargetsProvider';
import { DndMonitorListener, useDndMonitor } from '@dnd-kit/core';
import { DataProvider, LayoutProvider, RecyclerListView, RecyclerListViewProps } from 'recyclerlistview';
import { thumbnailSizeDimensions } from 'src/hooks/useThumbnailSize/useThumbnailSize';
import { Page } from '@alucio/aws-beacon-amplify/src/models';
// COMPONENT
import Header from './Header';
import RecommendedConfirmation from './RecommendedConfirmation';
import SlideMatchingIcon from './SlideMatchingIcon';
import PreviousVersionContainer from './PreviousVersionContainer';
import NewVersionContainer from './NewVersionContainer';

const S = StyleSheet.create({
  recyclerListView: {
    flex: 1,
    paddingVertical: 24,
  },
})

interface SlideGroupTargetsRowType {
  page: Page,
  index: number
}

// Renderless component that minimizes rerenders due to chatty over event
const GroupHoverTrigger = React.memo(() => {
  const { activeContainerOrigin } = useSingleItemDnd();
  const { setOverContainer } = useSlideGroupTargetsState()

  const listeners = useMemo<DndMonitorListener>(
    () => ({
      onDragOver(event) {
        const isFromPool = activeContainerOrigin.current === POOL_CONTAINER_ID
        const overContainer = event.over?.data.current?.containerId

        if (isFromPool && overContainer) {
          setOverContainer(overContainer)
        }
      },
      onDragEnd() {
        setOverContainer(null)
      },
    }),
    [setOverContainer],
  )

  useDndMonitor(listeners)

  return null
})

const SlideGroupTargetsRow: React.FC<SlideGroupTargetsRowType> = ({ page, index }) => {
  const { matchSlideIconStates } = useSlideGroupTargetsState();

  const needsReview = matchSlideIconStates[page.pageId] === MATCH_SLIDE_STATUS.NEEDS_REVIEW
  return (
    <DNABox testID="slide-group-container" fill appearance="col">
      <DNABox
        fill
        appearance="col"
        alignX="center"
        spacing="md"
      >
        <RecommendedConfirmation pageId={page.pageId} />
        <DNABox appearance="row" spacing="md" style={util.mergeStyles(undefined, [{ marginTop: 24 }, !needsReview])}>
          <PreviousVersionContainer pageId={page.pageId} index={index}/>
          <SlideMatchingIcon pageId={page.pageId} />
          <NewVersionContainer pageId={page.pageId} newPageNum={page.number} />
        </DNABox>
      </DNABox>
      <GroupHoverTrigger />
    </DNABox>
  )
}

const SlideGroupTargets: React.FC = () => {
  const {
    currentDocumentVersionORM,
    latestPublishedDocumentVersionORM,
  } = useMatchSlidesState();
  const { slideGroupTargetRef } = useMatchSlidesState()
  const dimensions = useWindowDimensions()
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1300px)',
  })

  const dataProviderInit = useMemo(() => {
    return new DataProvider((r1: string, r2: string) => {
      return r1 !== r2;
    });
  }, []);

  const dataProvider = useMemo(() => {
    return dataProviderInit.cloneWithRows(currentDocumentVersionORM.relations.pages)
  }, [currentDocumentVersionORM]);

  const layoutProvider = useMemo(() => {
    const _layoutProvider = new LayoutProvider(() => 0,
      (type, dim, _idx) => {
        const containerWidth = dimensions.width
        const containerHeight = 92
        const thumbnailSizeDimension = isLargeScreen ? thumbnailSizeDimensions.lg : thumbnailSizeDimensions.md;
        dim.width = containerWidth;
        dim.height = containerHeight + thumbnailSizeDimension.height;
      })
    _layoutProvider.shouldRefreshWithAnchoring = false;
    return _layoutProvider;
  }, []);

  const rowRenderer = useCallback<RecyclerListViewProps['rowRenderer']>(
    (type, data, idx) => {
      const { model } = data;
      const page = model as Page
      return (
        <SlideGroupTargetsRow index={idx} page={page} key={page.pageId} />
      )
    },
    [layoutProvider, dataProvider, latestPublishedDocumentVersionORM])

  return (
    <SlideGroupTargetsStateProvider>
      <DNABox fill appearance="col">
        {/* HEADER SECTION */}
        <Header />
        <RecyclerListView
          key={latestPublishedDocumentVersionORM.model.id}
          layoutProvider={layoutProvider}
          dataProvider={dataProvider}
          rowRenderer={rowRenderer}
          style={S.recyclerListView}
          canChangeSize
          ref={slideGroupTargetRef}
        />
      </DNABox>
    </SlideGroupTargetsStateProvider>
  )
}

export default SlideGroupTargets;
