import React, { useState } from 'react';
import truncate from 'lodash/truncate';
import { DNABox, DNAButton, DNACheckbox, DNAContextMenu, DNADivider, DNAText } from '@alucio/lux-ui';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as slideSettingsSelector from 'src/state/machines/publisherVersioning/SlideSettings/slideSettings.selectors';
import { useGroupState }
  from 'src/screens/Publishers/Versioning/SlideSettings/Modals/SetGroupsModal/GroupProvider.proxy';
import { useSlideSettingsState }
  from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider.proxy';
import { styles } from '../../SlideSettings';

export const AddToGroupMenuButton: React.FC = () => {
  const { service, addSlideToGroup } = useSlideSettingsState()
  const { groups, selectedPageIds, addGroupHandler } = useGroupState()
  const [optionsVisible, setOptionsVisible] = useState<boolean>(false);

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      slideSettingsSelector.canAddGroupSlide,
    ),
  )

  const addToGroupHandler = (groupName: string) => {
    addSlideToGroup(groupName)
    setOptionsVisible(false)
  }

  const toggleGroupOptionsVisibility = (): void => {
    if (groups.length === 0) addGroupHandler(selectedPageIds);
    else setOptionsVisible(true);
  }

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        <DNAButton
          onPress={toggleGroupOptionsVisibility}
          appearance="outline"
          status="tertiary"
          size="xs"
          disabled={!cond.canAddGroupSlide}
        >
          Add to group
        </DNAButton>
      </DNAContextMenu.Anchor>
      {
        optionsVisible && (
          <DNAContextMenu.Items>
            {
              groups.length > 1 && <DNAContextMenu.Item
                key="all_add_to_group"
                onPress={() => addToGroupHandler('ADD_TO_ALL_GROUPS')}
                title="All groups"
              />
            }
            {
              groups.map((pageGroup) => (
                <DNAContextMenu.Item
                  key={pageGroup.name}
                  onPress={() => addToGroupHandler(pageGroup.name)}
                  title={truncate(pageGroup.name)}
                />
              ))
            }
          </DNAContextMenu.Items>
        )
      }
    </DNAContextMenu>
  )
}

export const GroupHeader: React.FC = () => {
  const { service, backToIdle, saveGroupSlides } = useSlideSettingsState()

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      slideSettingsSelector.canSaveGroupSlide,
    ),
  )

  return (
    <DNABox style={ styles.headerBar } spacing="between">
      <DNABox
        alignY="center"
        spacing="sm"
      >
        <DNAText h4>Slide Groups</DNAText>
      </DNABox>
      <DNABox>
        <DNAButton
          appearance="outline"
          status="tertiary"
          padding="sm"
          onPress={backToIdle}
          style={{ marginRight: 8 }}
        >
          Cancel
        </DNAButton>
        <DNAButton
          padding="sm"
          onPress={saveGroupSlides}
          disabled={!cond.canSaveGroupSlide}
        >
          Save
        </DNAButton>
      </DNABox>
    </DNABox>
  )
}

export const ActionBar: React.FC = () => {
  const { selectedPageIds, pages } = useGroupState()
  const { selectAllGroupSlide, thumbnailSizes } = useSlideSettingsState()

  return (
    <DNABox
      style={ styles.rowWrapper }
      spacing="between"
      alignY="center"
    >
      {/* LEFT SIDE - ACTIONS */}
      <DNABox spacing="md" alignY="center">
        {/* SELECT CHECKBOX */}
        <DNACheckbox
          onChange={selectAllGroupSlide}
          checked={selectedPageIds.length === pages.length}
        />
        <DNAText status="flatAlt">Select all</DNAText>
        <DNADivider style={styles.verticalDivider} />

        {/* SELECTED COUNT */}
        <DNAText status="flatAlt" style={{ marginRight: 8 }}>
          {`${selectedPageIds.length} of ${pages.length} selected`}
        </DNAText>

        {/* ADD TO GROUP BUTTON */}
        <AddToGroupMenuButton />
      </DNABox>

      {/* RIGHT SIDE - VIEW TOGGLES */}
      <DNABox spacing="sm" alignY="center">
        <DNAButton
          appearance="ghostLink"
          status="tertiary"
          size="md"
          padding="none"
          onPress={thumbnailSizes.cycleThumbnailSize}
          iconLeft={thumbnailSizes.thumbnailSize === 'lg' ? 'view-comfy' : 'view-grid'}
        />
      </DNABox>
    </DNABox>
  )
}
