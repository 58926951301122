import { FOLDER_STATUS } from '@alucio/aws-beacon-amplify/src/API';
import { DNABox, DNACard, DNAText, GenericToast, Iffy, ToastOrientations, useToast } from '@alucio/lux-ui';
import React from 'react'
import { ScrollView, StyleSheet } from 'react-native';
import { RootState, useSelector, useDispatch } from 'src/state/redux';

import { ROUTES } from 'src/router/routes';
import documentQuery, { filters } from 'src/state/redux/document/query';
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage';
import { useAllDocumentsInstance } from 'src/state/redux/selector/document';
import { useAllFoldersInstance } from 'src/state/redux/selector/folder';
import { folderActions } from 'src/state/redux/slice/folder';
import { UpdateType, sharedFolderActions } from 'src/state/redux/slice/sharedFolder';
import { DocumentORM, FolderORM } from 'src/types/types';
import { EmptyVariant } from '../DNA/Empty/DNAEmpty';
import DNAFlatList from '../DNA/FlatList/DNAFlatList';
import { TabOptions, useFileDrawerContext } from './FileDrawerProvider';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useDocumentSearchV2 } from 'src/hooks/useDocumentSearchV2';
import ListSkeleton from '../ListSkeleton/ListSkeleton';
import { isSharedEditableFolder } from 'src/utils/foldersHelpers';
import { useCurrentUser } from 'src/state/redux/selector/user';

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 32,
    marginTop: 16,
  },
  search: {
    marginLeft: 4,
    marginBottom: 16,
  },
  listWrapper: {
    borderWidth: 1,
    borderColor: colors['color-gray-80'],
  },
})

const BOOKMARKED_DOCS_QUERY = documentQuery.merge(
  documentQuery.filters.published,
  documentQuery.filters.bookmarked,
  documentQuery.sorts.bookmarkedDesc,
)

const VIEWER_ALL_DOCS_QUERY = documentQuery.merge(
  documentQuery.sorts.updatedAtDesc,
  documentQuery.sorts.titleAsc,
  documentQuery.filters.published,
  documentQuery.sorts.bookmarkedDesc,
)

const PUBLISHER_ALL_DOCS_QUERY = documentQuery.merge(
  documentQuery.sorts.updatedAtDesc,
  documentQuery.sorts.titleAsc,
  documentQuery.filters.publishedNUnpublished,
  documentQuery.sorts.bookmarkedDesc,
)

const FileDrawerContent = () => {
  const route = useCurrentPage({ exact: false })
  const isSharedFolders = route?.PATH.includes('shared_folders');
  // We do not allow non-publshed documents to be added to shared folders
  const isPublisherNonSharedFolderRoute = route?.configOptions?.modules?.includes('publisher') &&
    route?.LABEL !== ROUTES.PUBLISHER_SHARED_FOLDERS.LABEL
  const { selectedTab, searchText, localSearchText } = useFileDrawerContext()
  const dispatch = useDispatch()
  const drawer = useSelector((state: RootState) => state.drawer)
  const [currentFolder] = useAllFoldersInstance({ filter: { model: { id: drawer.entityId } } }, isSharedFolders)
  const currentUser = useCurrentUser()
  const isSharedEditFolder = isSharedEditableFolder(currentFolder, currentUser.userProfile?.id)

  const bookmarkedDocs = useAllDocumentsInstance(BOOKMARKED_DOCS_QUERY)
  const viewerDocuments = useAllDocumentsInstance(VIEWER_ALL_DOCS_QUERY)
  const publisherDocuments = useAllDocumentsInstance(PUBLISHER_ALL_DOCS_QUERY)

  const {
    documentORMSearchResults: results,
    isLoadingSearch,
  } = useDocumentSearchV2(
    searchText,
    isPublisherNonSharedFolderRoute ? filters.publishedNUnpublished : filters.published,
  );

  const toast = useToast()

  const currentTab = {
    [TabOptions.BOOKMARKS]: {
      items: bookmarkedDocs,
      label: 'Bookmarks',
    },
    [TabOptions.LIBRARY]: {
      items: isPublisherNonSharedFolderRoute ? publisherDocuments : viewerDocuments,
      label: 'Library',
    },
    // "Ghost tab". similar to the Content panel in the meetings, this is a tab but not shown in the UI
    [TabOptions.SEARCH_RESULTS]: {
      items: results,
      label: 'Search results',
    },
  }

  const isLoading = isLoadingSearch && selectedTab === currentTab['Search results'].label;

  const isDocumentInCurrentFolder = (doc: DocumentORM, currentFolder: FolderORM) => {
    return currentFolder.model.items.filter(item => item.status !== FOLDER_STATUS.REMOVED).some(folderItem => {
      const idToCompare = folderItem.itemId.split('_')
      return idToCompare[0] === doc.model.id
    })
  }

  const onItemPress = (doc: DocumentORM) => {
    if (currentFolder) {
      const isDocDuplicate = isDocumentInCurrentFolder(doc, currentFolder)
      if (isDocDuplicate) {
        toast.add(
          <GenericToast
            title="File already in folder"
            status="warning"
          />,
          ToastOrientations.TOP_RIGHT,
        )
        return
      }
      dispatch(
        isSharedEditFolder
          ? sharedFolderActions.updateItems({
            folder: currentFolder,
            folderItems: [doc],
            action: UpdateType.ADD_DOCUMENT,
          })
          : folderActions.addDocument([doc], currentFolder),
      );
      toast.add(
        <GenericToast
          title="Added to folder"
          status="success"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    }
  }

  let emptyVariant: EmptyVariant;
  switch (selectedTab) {
    case TabOptions.BOOKMARKS:
      emptyVariant = EmptyVariant.FolderListBookmark
      break
    case TabOptions.SEARCH_RESULTS:
      emptyVariant = EmptyVariant.DocumentResultsListEmpty
      break
    default:
      emptyVariant = EmptyVariant.DocumentListEmpty
  }

  const searchResultsText: string = selectedTab !== TabOptions.SEARCH_RESULTS
    ? `${currentTab[selectedTab].items.length} item(s)` : `Search results for "${localSearchText}" ` +
    `${isLoading ? '' : ` | ${currentTab[selectedTab].items.length} result(s)`}`;

  return (
    <DNABox appearance="col" fill style={styles.container}>
      <DNAText b2 status="secondary" style={styles.search}>
        {searchResultsText}
      </DNAText>
      <Iffy is={isLoading}>
        <DNACard appearance="outline">
          <ListSkeleton numberOfItems={5} />
        </DNACard>
      </Iffy>
      <Iffy is={!isLoading}>
        <DNACard
          appearance="outline"
          as={ScrollView}
        >
          <DNAFlatList<(DocumentORM)>
            items={currentTab[selectedTab].items}
            variant="fileDrawer"
            emptyVariant={emptyVariant}
            onPress={onItemPress}
            useEmptyContainer={false}
            isSearch={selectedTab === TabOptions.SEARCH_RESULTS}
          />
        </DNACard>
      </Iffy>
    </DNABox>
  )
}

export default FileDrawerContent
