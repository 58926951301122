import React, { useEffect, useState } from 'react'
import { DNABox, DNAText } from '@alucio/lux-ui'
import { bytesToSize } from 'src/utils/documentHelpers'
import { S } from '..'

const General: React.FC = () => {
  const [memoryUsage, setMemoryUsage] = useState<{
    heapSize: string,
    usedHeapSize: string,
    heapSizeLimit: string,
  }>({
    heapSize: 'N/A',
    usedHeapSize: 'N/A',
    heapSizeLimit: 'N/A',
  })

  useEffect(
    () => {
      // @ts-expect-error - Deprecated API
      if (performance.memory) {
        const intervalId = setInterval(
          () => {
            // @ts-expect-error - Deprecated API
            const { jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize } = performance.memory
            setMemoryUsage({
              heapSize: bytesToSize(totalJSHeapSize),
              usedHeapSize: bytesToSize(usedJSHeapSize),
              heapSizeLimit: bytesToSize(jsHeapSizeLimit),
            })
          },
          15000,
        )

        return () => clearInterval(intervalId)
      }
    },
    [],
  )

  return (
    <DNABox fill appearance="col" style={S.tabContainer}>
      <DNAText>
        <DNAText bold>
          Build Run:&nbsp;
        </DNAText> (Have CI Inject)
      </DNAText>
      <DNAText>
        <DNAText bold>
          Build Commit SHA:&nbsp;
        </DNAText>
        (Have CI Inject)
      </DNAText>
      <DNAText>
        <DNAText bold>
          Build Date:&nbsp;
        </DNAText>
        (Have CI Inject)
      </DNAText>
      <DNAText bold>Current Reachability: (TODO)</DNAText>
      <DNAText>
        <DNAText bold>Memory Usage (Chromium Only): &nbsp;</DNAText>
        {JSON.stringify(memoryUsage, null, 2)}
      </DNAText>
    </DNABox>
  )
}

export default General
