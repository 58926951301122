import React from 'react'
import { useDispatch } from 'src/state/redux'

import { FolderItemORM, FolderORM, isFolderORM, isFolderItemORM, isDocumentVersionORM } from 'src/types/types'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'

import {
  DNABox,
  DNAButton,
  DNAIcon,
  DNAText,
  Iffy,
} from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import DNADocumentVersionUpdateModal from 'src/components/DNA/Modal/DNADocumentVersionUpdate'
import { CustomDeck, DocumentVersion, DocumentStatus } from '@alucio/aws-beacon-amplify/src/models'

const DNADocumentVersionUpdateButton: React.FC<{
  orm: FolderORM | FolderItemORM,
  disabled?: boolean,
}> = (props) => {
  const { orm, disabled } = props
  const dispatch = useDispatch()

  const containsPendingReview = isFolderORM(orm)
    ? orm.meta.version.containsPendingReviewItem
    : false
  const hasAutoUpdate = isFolderORM(orm)
    ? orm.meta.version.containsAutoUpdatedItem
    : orm.meta.hasAutoUpdatedItem;
  const hasOutdated = isFolderORM(orm)
    ? orm.meta.version.containsOutdatedDocVer
    : orm.meta.hasOutdatedItem
  const isUnpublishedDocument = isFolderItemORM(orm) && isDocumentVersionORM(orm.relations.itemORM)
    ? orm.relations.itemORM.relations.documentORM.model.status === DocumentStatus.NOT_PUBLISHED
    : false

  const hideUpdateWarning = isFolderORM(orm)
    ? orm.meta.isSharedWithTheUser : orm.relations?.parentORM?.meta.isSharedWithTheUser;

  // [NOTE] - This is a hacky way to check if this is an external record
  //        - We can calculate this in the ORM meta instead
  //        - This shouldn't happen currently as collaborators cannot add to a folder shared with them
  //          but it's an extra safe guard for now
  const isExternalRecord = isFolderItemORM(orm) && (
    orm.relations.itemORM instanceof DocumentVersion ||
    orm.relations.itemORM instanceof CustomDeck
  )

  const onPress = () => {
    !isFolderORM(orm) && dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: (props) => (
        <DNADocumentVersionUpdateModal
          {...props}
          folderItemORM={orm}
        />
      ),
    }))
  }

  if (!(hasAutoUpdate || hasOutdated || containsPendingReview) || isUnpublishedDocument) {
    return null;
  }

  return (
    <DNABox appearance="row" alignY="center">
      <Iffy is={hasOutdated}>
        <DNAPopover type="tooltip" placement="top" >
          <DNAPopover.Anchor>
            {/* MANUAL UPDATE BUTTON FOR FOLDER ITEMS */}
            <Iffy is={!isFolderORM(orm)}>
              <DNAButton
                status="danger"
                appearance="ghost"
                onPress={onPress}
                size="md"
                padding="sm"
                iconLeft="update"
                disabled={isExternalRecord || disabled}
              />
            </Iffy>

            {/* MANUAL UPDATE ICON ONLY FOR FOLDERS */}
            <Iffy is={isFolderORM(orm)}>
              <DNABox
                alignY="center"
                alignX="center"
                style={{ width: 32, height: 32 }}
              >
                <DNAIcon.Styled
                  status="danger"
                  appearance="ghost"
                  name="update"
                  size="md"
                />
              </DNABox>
            </Iffy>
          </DNAPopover.Anchor>
          <DNAPopover.Content>
            <DNAText
              status="basic"
            >
              {
                !isFolderORM(orm)
                  ? disabled
                    ? 'File may have outdated content from an older version'
                    : 'Update file to the latest version'
                  : 'File(s) in this folder need to be updated'
              }
            </DNAText>
          </DNAPopover.Content>
        </DNAPopover>
      </Iffy>
      <Iffy is={(hasAutoUpdate || containsPendingReview) && !hideUpdateWarning}>
        <DNAPopover type="tooltip" placement="top" disablePopover={['tabletPWA']}>
          <DNAPopover.Anchor>
            <DNABox
              alignY="center"
              alignX="center"
              style={{ width: 32, height: 32 }}
            >
              <DNAIcon.Styled
                status="warning"
                appearance="ghost"
                name="alert-circle"
                size="md"
              />
            </DNABox>
          </DNAPopover.Anchor>
          <DNAPopover.Content>
            <DNAText status="basic">
              {
                !isFolderORM(orm)
                  ? 'This file was auto-updated to a new version'
                  : containsPendingReview
                    ? 'File(s) in this folder need to be reviewed'
                    : 'File(s) in this folder have been auto-updated to a new version'
              }
            </DNAText>
          </DNAPopover.Content>
        </DNAPopover>
      </Iffy>
    </DNABox>
  )
}

export default DNADocumentVersionUpdateButton
