import { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'src/state/redux';
import { Options } from 'src/components/MyContentPanel/useMyContentPanelSharedResources';
import { BroadcastChannel } from 'broadcast-channel';
import { sharedFolderActions, useFetchSharedFolders } from 'src/state/redux/slice/sharedFolder';
import { useIsExternalPlayer } from 'src/screens/Loading';
import * as logger from 'src/utils/logger';
import { isCustomDeckORM } from 'src/types/typeguards';
import { FolderItemORM } from 'src/types/orms';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { PopoutContentBCPayloads, PopoutContentBCTypes } from './useMeetingsPopoutContentState';

interface SharedFolderDependenciesPayload {
  folderId: string
  parentFolderId: string
}

const EXTERNAL_WINDOW_STORAGE_KEY = 'external-window-dependencies';

// THIS HOOK KEEPS TRACK OF SHARED FOLDERS BEING USED IN A MEETING TO FETCH THEM
// UPON THE USER SWITCHING MODES (GIVEN THAT THESE ITEMS WON'T GET CARRIED OVER)
// IT ALSO FETCHES THE DEPENDENCIES DURING LOAD TIME OF THE EXTERNAL WINDOW (IF REQUIRED)
const useMeetingExternalWindowDependencies = (meetingId?: string, selectedTab?: Options) => {
  const dispatch = useDispatch();
  const sharedFoldersMap = useRef(new Map<string, SharedFolderDependenciesPayload>());
  const broadcastChannel = useRef<BroadcastChannel<PopoutContentBCPayloads>>();
  const { fetchSharedFolders } = useFetchSharedFolders();
  const isExternalPlayer = useIsExternalPlayer();
  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const enableNew3PC = useFeatureFlags('enableNew3PC');
  const isNew3PCEnabled = enableNew3PC || urlParams.get('enableNew3PC') === 'true';

  useEffect(() => {
    if (selectedTab === Options.SHARED_WITH_ME && isNew3PCEnabled && meetingId) {
      broadcastChannel.current?.postMessage({
        type: PopoutContentBCTypes.getSharedFolders,
        meetingId,
      })
    }
  }, [selectedTab]);

  // USED BY THE EXTERNAL WINDOW ONLOAD PROCESS TO GET THE DEPENDENCIES OF THE PRESENTED SHARED FILES //
  const getExternalWindowDependencies = async (meetingId: string): Promise<void> => {
    if (isExternalPlayer && isNew3PCEnabled) {
      const getExternalWindowDependenciesProps = localStorage.getItem(EXTERNAL_WINDOW_STORAGE_KEY);
      if (getExternalWindowDependenciesProps) {
        const getExternalWindowDependenciesParsed = JSON.parse(getExternalWindowDependenciesProps);
        const shouldPerformDependenciesFetching = meetingId === getExternalWindowDependenciesParsed.meetingId &&
          getExternalWindowDependenciesParsed.getSharedFolders;
        if (shouldPerformDependenciesFetching) {
          logger.userInit.debug('Getting external window dependencies');
          try {
            await Promise.all([
              fetchSharedFolders(),
              ...getExternalWindowDependenciesParsed.sharedFoldersParams?.reduce?.((acc, param) => {
                if (param.folderId && param.parentFolderId) {
                  acc.push(dispatch(sharedFolderActions.getFolderExternalDependencies({
                    folderId: param.folderId,
                    parentFolderId: param.parentFolderId,
                  })));
                } else {
                  logger.userInit.warn('FolderId and ParentFolderId not found. Skipping dependencies object.');
                }
                return acc;
              }, []),
            ]);
          } catch (e) {
            logger.userInit.error('An error occurred while fetching the External Window dependencies', e);
          }
        }
      }
    }
  };

  // SETS IN THE LOCALSTORAGE THE DEPENDENCIES THE EXTERNAL WINDOW NEEDS TO FETCH IN CASE OF SWITCHING MODES //
  const onPresentFolderItem = (folderItemORM: FolderItemORM): void => {
    const parentFolder = folderItemORM.relations.parentORM;
    if (parentFolder?.meta.isSharedWithTheUser && meetingId && isNew3PCEnabled) {
      if (isCustomDeckORM(folderItemORM.relations?.itemORM)) {
        sharedFoldersMap.current.set(parentFolder.model.id, {
          folderId:  parentFolder.model.id,
          parentFolderId: parentFolder.relations.parentFolderORM?.model.id || parentFolder.model.id,
        })
      }
      localStorage.setItem(EXTERNAL_WINDOW_STORAGE_KEY, JSON.stringify({
        getSharedFolders: true,
        meetingId,
        sharedFoldersParams: Array.from(sharedFoldersMap.current.values()),
      }));
    }
  };

  // SENDS A MESSAGE TO THE PRESENTER WINDOW TO FETCH THE DEPENDENCIES OF A FOLDER //
  const sendGetSharedFoldersDependenciesMessage = (folderId: string, parentFolderId: string): void => {
    if (meetingId && isNew3PCEnabled) {
      broadcastChannel.current?.postMessage({
        type: PopoutContentBCTypes.getSharedFolderDependencies,
        meetingId,
        folderId,
        parentFolderId,
      })
    }
  };

  useEffect(() => {
    broadcastChannel.current = new BroadcastChannel<PopoutContentBCPayloads>('MEETING_CONTENT');
    return () => {
      broadcastChannel.current?.close();
      broadcastChannel.current = undefined;
    };
  }, []);

  return {
    getExternalWindowDependencies,
    onPresentFolderItem,
    sendGetSharedFoldersDependenciesMessage,
  };
};

export default useMeetingExternalWindowDependencies;
