import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useInterpret } from '@xstate/react';
import { BroadcastChannel } from 'broadcast-channel';
import useLazyRef from 'src/hooks/useLazyRef';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as customNotesSelector from 'src/state/machines/customNote/customNote.selectors';
import { customNoteSM } from 'src/state/machines/customNote/customNote.machine';
import { CustomNoteService } from 'src/state/machines/customNote/customNote.types';
import { PW } from 'src/state/machines/presentation/playerWrapper';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import { CurrentPageNotationsLevelMap } from 'src/state/machines/customNote/customNote.selectors';
import { isFolderItemORM, isCustomDeckORM } from 'src/types/typeguards';

type ICustomNotesContext = {
  service: CustomNoteService
  setCurrentActiveNotationId: (notationId: string) => void
  currentPageNotationsInfo: {
    currentPageNotationsLevelMap: CurrentPageNotationsLevelMap
    hasMeetNotationLimits: boolean
  }
  isCustomDeck: boolean
}

const presentationStateChannel = new BroadcastChannel<PW.PresentationChannelMessage>('PRESENTATION_CHANNEL')

const CustomNotesContext = React.createContext<ICustomNotesContext>(undefined!)
export const useCustomNotes = () => useContext(CustomNotesContext)

const CustomNotesProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  const { meetingId, activePresentation } = useContent()
  const modalStateORM = useMemo(() => activePresentation?.presentable.orm, [activePresentation])
  const isCustomDeck = useMemo(() => isFolderItemORM(modalStateORM) && isCustomDeckORM(modalStateORM.relations.itemORM),
    [modalStateORM])

  /** STATE MACHINE */
  const machineInstance = useLazyRef(() => customNoteSM.withContext({
    meetingId: meetingId ?? 'customNoteSM',
    presentationStateChannel,
    isReadOnlyMode: true,
  }))
  const service = useInterpret(
    machineInstance.current!,
  )

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      customNotesSelector.currentPageNotationsInfo,
    ),
  )

  const currentPageNotationsInfo = cond.currentPageNotationsInfo(activePresentation)

  useEffect(() => {
    // Clean up function when the machine stops
    const onStop = () => {
      // Inform player machine to exit out notation mode
      presentationStateChannel.postMessage({
        type: 'TOGGLE_NOTATIONS',
        meetingId: meetingId ?? 'customNoteSM',
        toggleOn: false,
      })
    };

    service.onStop(onStop);
  }, [service]);

  /* FUNCTIONS START */
  const setCurrentActiveNotationId = useCallback((notationId?: string) => {
    service.send({ type: 'SET_CURRENT_ACTIVE_NOTATION', notationId })
  }, [service])
  /* FUNCTIONS END */

  const contextValue: ICustomNotesContext = {
    service,
    setCurrentActiveNotationId,
    currentPageNotationsInfo,
    isCustomDeck,
  }

  return (
    <CustomNotesContext.Provider value={contextValue}>
      {children}
    </CustomNotesContext.Provider>
  )
}

export default CustomNotesProvider
