import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'src/router'
import {
  DNABox,
  DNAText,
  DNAIcon,
  DNAButton,
} from '@alucio/lux-ui'
import { DRAWER_ENTITIES, drawerActions } from 'src/state/redux/slice/drawer'

const FolderEmptyLabel = () => {
  const dispatch = useDispatch();
  const location = useLocation()
  const pathTokens = location.pathname.split('/').slice(2)
  const isArchivedOrShared = location.pathname.includes('archived') || location.pathname.includes('shared')
  const currentFolderId = pathTokens[pathTokens.length - 1];

  const openFileDrawer = (): void => {
    dispatch(drawerActions.toggle({
      entity: DRAWER_ENTITIES.FILES,
      entityId: currentFolderId,
    }))
  };

  return (
    <DNABox appearance="col" spacing="md" alignX="center">
      <DNAText testID="empty-folder-text" bold>
        There are no files in this folder.
      </DNAText>
      {!isArchivedOrShared && (
        <DNAButton
          onPress={openFileDrawer}
          appearance="outline"
          status="gray"
        >
          <DNABox testID="add-files-empty-btn" spacing="xs" alignY="center">
            <DNAIcon.Styled
              status="tertiary"
              appearance="ghost"
              name="file-plus-outline"
              size="md"
            />
            <DNAText bold>
              Add files
            </DNAText>
          </DNABox>
        </DNAButton>
      )}
    </DNABox>
  )
}

export default FolderEmptyLabel
