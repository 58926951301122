import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAIcon, DNAText, util } from '@alucio/lux-ui';
import { useMediaQuery } from 'react-responsive';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useMatchSlidesState } from 'src/state/machines/publisherVersioning/MatchSlides/MatchSlidesProvider';
// Dnd
import { useSingleItemDnd } from 'src/components/DnD/DNASingleItemDnd';
import { MATCH_SLIDE_STATUS, useSlideGroupTargetsState } from '../SlideGroupTargetsProvider';

const S = StyleSheet.create({
  recommendedConfirmationContainer: {
    backgroundColor: colors['color-warning-5'],
    borderColor: colors['color-warning-500'],
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  recommendedConfirmationAlert: {
    fontSize: 20,
    color: colors['color-warning-500'],
  },
})

interface RecommendationConfirmationProps {
  pageId: string
}

const RecommendedConfirmation: React.FC<RecommendationConfirmationProps> = ({ pageId }) => {
  const { groupedTargetItems } = useSingleItemDnd();
  const { onMatch, onUnmatch } = useMatchSlidesState();
  const { matchSlideIconStates } = useSlideGroupTargetsState();
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1300px)',
  })

  // TODO maybe move this to useSlideGroupTargetsState for less repatitve code. mappedItem is being calculated in that provider already
  const mappedItem = useMemo(() => groupedTargetItems[pageId]?.[0]?.itemId || null, [groupedTargetItems, pageId])

  const showConfirmationMessage = matchSlideIconStates[pageId] === MATCH_SLIDE_STATUS.NEEDS_REVIEW

  if (!showConfirmationMessage || !mappedItem) return null
  return (
    <DNABox
      alignX="start"
      alignY="center"
      spacing="sm"
      fill
      style={util.mergeStyles(
        undefined,
        S.recommendedConfirmationContainer,
        { width: isLargeScreen ? 748 : 548 },
      )}
    >
      <DNAIcon
        name="alert-circle"
        status="warning"
        style={S.recommendedConfirmationAlert}
      />
      <DNAText status="warning">
        Do these slides match?
      </DNAText>
      <DNAButton
        status="warning"
        appearance="outline"
        onPress={() => onMatch(pageId, mappedItem)}
        size="xs"
      >
        Yes
      </DNAButton>
      <DNAButton
        status="warning"
        appearance="outline"
        onPress={() => onUnmatch(pageId)}
        size="xs"
      >
        No
      </DNAButton>
    </DNABox>
  )
}

export default RecommendedConfirmation
