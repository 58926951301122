import React from 'react';
import {
  DNACollapsibleCard,
  DNABox,
  DNAButton,
  DNAText,
  Iffy,
} from '@alucio/lux-ui';
import { AssociatedFileORM, DocumentORM, EntityWithFiles } from 'src/types/types'
import AssociatedFilesList, {
  Mode,
} from 'src/components/DNA/AssociatedFiles/AssociatedFilesList'
import FileUpload, { ManagedFile, UploadStatus } from 'src/components/DNA/FileUpload/FileUpload'
import AssociatedFilesSearchInput from 'src/screens/Publishers/AssociatedFilesSearchInput'

// AMPLIFY
import {
  AssociatedFile,
  AttachedFile,
  FileType,
} from '@alucio/aws-beacon-amplify/src/models';

const ASSOCIATED_FILES_DESCRIPTION = 'Associated files are files related to the master version that can \n' +
  'be designated for the field team to share externally.';

const fileExtensions = Object.keys(FileType)
const notAllowedFiles =  ['ZIP', 'HTML', 'WEB', 'MP4']
const allowedFileExtensions = fileExtensions.filter((file) => !notAllowedFiles.includes(file))

interface AssociatedFilesManagerProps<T> {
  entityORM: T,
  isDisabled: boolean;
  isCollapsed?: boolean;
  searching: boolean;
  setSearching: (value: React.SetStateAction<boolean>) => void;
  associatedFilesMode: Mode | keyof typeof Mode,
  files: (AssociatedFileORM | ManagedFile)[],
  onUploadStatusChange: (status: UploadStatus) => void,
  onSelect: (parentDoc: T, linkedDoc: DocumentORM) => void
  onDelete: (entityORM: T, item: DocumentORM | AttachedFile) => void,
  title?: string;
  canLinkFiles?: boolean;
  canUploadFiles?: boolean;
  emptyMessage?: string;
  showTooltip?: boolean;
  onFinishedUpload?: (attachedFile: AttachedFile) => void,
  onUpdateAssociatedFile?: (associatedFile: AssociatedFile) => void,
}

const AssociatedFilesManager = <T extends EntityWithFiles>(props: AssociatedFilesManagerProps<T>) => {
  const {
    entityORM,
    files,
    canLinkFiles = true,
    canUploadFiles = canLinkFiles,
    isDisabled,
    isCollapsed,
    searching,
    setSearching,
    emptyMessage,
    onUploadStatusChange,
    associatedFilesMode,
    onSelect,
    onDelete,
    onFinishedUpload,
    title = 'Associated Files',
    onUpdateAssociatedFile,
    showTooltip = true,
  } = props;

  return (
    <FileUpload>
      {
        (fileUpload) => (
          <>
            <DNACollapsibleCard
              headerTitle={title}
              collapsedDefault={isCollapsed ?? !files.length}
              popoverInfoText={showTooltip ? ASSOCIATED_FILES_DESCRIPTION : undefined}
              customWrapperStyle={{
                paddingVertical: 26,
              }}
            >
              <Iffy is={canLinkFiles || canUploadFiles}>
                <DNABox spacing="sm">
                  <Iffy is={canLinkFiles}>
                    <DNABox spacing="sm" alignY="center" testID="link-files">
                      <DNAButton
                        testID="links-file-button"
                        iconLeft="link-variant"
                        appearance="outline"
                        status="tertiary"
                        size="sm"
                        disabled={isDisabled}
                        onPress={() => setSearching(true)}
                      >
                        <DNAText>Link files</DNAText>
                      </DNAButton>
                    </DNABox>
                  </Iffy>
                  <Iffy is={canUploadFiles}>
                    <DNABox spacing="sm" alignY="center">
                      <FileUpload.Input
                        s3PathPrefix="publish_tmp/"
                        fileExt={allowedFileExtensions}
                        multiFile
                        processOnSelect
                        disabled={isDisabled}
                      >
                        <DNAButton
                          testID="upload-button"
                          iconLeft="upload"
                          appearance="outline"
                          status="tertiary"
                          disabled={isDisabled}
                          size="sm"
                        >
                          <DNAText>Upload</DNAText>
                        </DNAButton>
                      </FileUpload.Input>
                    </DNABox>
                  </Iffy>
                </DNABox>
              </Iffy>
              { /* SEARCH FILE INPUT */ }
              <Iffy is={searching}>
                <DNABox fill spacing="md" childFill={0} alignY="center">
                  <AssociatedFilesSearchInput parentDoc={entityORM} onSelect={onSelect} />
                  <DNAButton
                    appearance="outline"
                    status="tertiary"
                    size="sm"
                    style={{ marginBottom: 5 }}
                    onPress={() => setSearching(false)}
                  >
                    <DNAText>Cancel</DNAText>
                  </DNAButton>
                </DNABox>
              </Iffy>
              {/* NORMAL ASSOCIATED FILES LIST */}
              <AssociatedFilesList
                emptyMessage={emptyMessage}
                entityORM={entityORM}
                onStatusChange={onUploadStatusChange}
                mode={associatedFilesMode}
                onDelete={onDelete}
                onFinishedUpload={onFinishedUpload}
                items={[...files, ...(fileUpload?.files ?? [])]}
                onUpdateAssociatedFile={onUpdateAssociatedFile}
              />
            </DNACollapsibleCard>
          </>
        )
      }
    </FileUpload>
  )
}

AssociatedFilesManager.displayeName = 'AssociatedFilesManager';

export default AssociatedFilesManager;
