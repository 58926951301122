import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import { LoadedPresentation, PresentableModelORM } from 'src/state/context/ContentProvider/ContentProvider';
import DNASlideRoll from 'src/components/DNA/SlideRoll/DNASlideRoll';
import { SLIDE_ROLL_VARIANT } from 'src/components/DNA/SlideRoll/StyleSetting';
import { DNABox } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

const styles = StyleSheet.create({
  mainWrapper: {
    backgroundColor: colors['color-black'],
    paddingHorizontal: 14,
    paddingVertical: 14,
  },
});

interface Props {
  presentation: LoadedPresentation,
  onPageSelect: (presentationPageNumber: number) => void,
  showCloseButton?: boolean,
  closeButtonOnPress?: () => void,
  addPresentation?: (item: PresentableModelORM, pageNumber?: number) => void
}

const SlideRollWrapper = (props: Props) => {
  const { presentation, onPageSelect, closeButtonOnPress, addPresentation } = props;
  const memoizedSlideRoll = useMemo(() => (
    <DNASlideRoll
      activeSlide={presentation.currentPresentablePage}
      presentable={presentation.presentable}
      variant={SLIDE_ROLL_VARIANT.MEETINGS}
      horizontal={false}
      onSelect={onPageSelect}
      closeButtonOnPress={closeButtonOnPress}
      addPresentation={addPresentation}
    />
  ), [presentation]);

  return (
    <DNABox style={styles.mainWrapper}>
      {memoizedSlideRoll}
    </DNABox>
  );
};

const SlideRollConnector = (props) => {
  const { activePresentation, setActiveSlideByPresentationPageNumber, addPresentation } = useContent();
  return (
    <SlideRollWrapper
      presentation={activePresentation!}
      onPageSelect={setActiveSlideByPresentationPageNumber}
      closeButtonOnPress={props.closeButtonOnPress}
      addPresentation={addPresentation}
    />
  );
};

SlideRollWrapper.title = 'SlideRollWrapper';

export default SlideRollConnector;
