import React from 'react';
import { StyleSheet } from 'react-native';
import { FolderItemORM } from 'src/types/types';
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types';
import DNACustomDeckThumbnail from 'src/components/DNA/Document/DNACustomDeckThumbnail'
import { isCustomDeckORM } from 'src/types/typeguards'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { DNABox, DNACard, DNAText, DNAChip } from '@alucio/lux-ui';

const styles = StyleSheet.create({
  mainWrapper: {
    backgroundColor: colors['color-gray-500'],
    borderRadius: 4,
    marginVertical: 1,
    paddingLeft: 12,
    paddingRight: 20,
    paddingVertical: 12,
  },
});

const DNADocumentFolderItemCustomDeckVirtualRow: DNARowsComponent<FolderItemORM> = (props) => {
  const {
    ORM: folderItemORM,
    onPress,
    style,
    isOnline,
  } = props;

  if (!isCustomDeckORM(folderItemORM.relations.itemORM)) {
    return <></>;
  }

  const customDeckORM = folderItemORM.relations.itemORM;
  const unavailableContent = !isOnline && !customDeckORM.meta.assets.isContentCached;
  const isReviewRequired = customDeckORM.meta.version.requiresReview;

  return (
    <DNACard
      appearance="flat"
      interactive="pressable"
      onPress={() => !isReviewRequired && onPress?.(folderItemORM)}
      style={[styles.mainWrapper, style]}
    >
      <DNABox alignY="center" spacing="between" childFill={0} style={isReviewRequired && { opacity: 0.4 }}>
        <DNABox spacing="lg" alignY="center" childFill={1}>
          <DNABox style={{ backgroundColor: 'black' }}>
            <DNACustomDeckThumbnail
              customDeckORM={customDeckORM}
              width={126}
              height={72}
              unavailableContent={unavailableContent}
            />
          </DNABox>
          {/* Title */}
          <DNABox spacing="sm" appearance="col" childFill>
            <DNAText b2 status="basic" numberOfLines={1}>
              {folderItemORM.model.customTitle}
            </DNAText>
            <DNABox alignY="center" spacing="sm">
              <DNAChip
                appearance="tag"
                style={{ backgroundColor: 'rgba(255,255,255,0.16)' }}
              >
                MODIFIED
              </DNAChip>
            </DNABox>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNACard>
  );
};

export default DNADocumentFolderItemCustomDeckVirtualRow;
