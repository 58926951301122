import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'src/state/redux';
import { meetingActions } from 'src/state/redux/slice/meeting';
import {
  ContentPresented,
  CustomValues,
  Meeting,
  MeetingStatus,
  MeetingType,
} from '@alucio/aws-beacon-amplify/src/models';
import DNAMeetingDeleteModal from '../DNA/Modal/DNAMeetingDeleteModal';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { DRAWER_ENTITIES, drawerActions } from 'src/state/redux/slice/drawer';
import { useMeeting } from 'src/state/redux/selector/meeting';
import DNAMeetingContentDeleteModal from '../DNA/Modal/DNAMeetingContentDeleteModal';
import { MeetingORM } from 'src/types/orms';
import { isEmpty } from 'lodash';
import { DeepMap } from 'react-hook-form';
import { CONTENT_PRESENTED_STATUS } from '@alucio/aws-beacon-amplify/src/API';
import { replaceContentPresented } from 'src/state/context/Meetings/helper';
import { GenericToast, ToastOrientations, useToast } from '@alucio/lux-ui'
import isEqual from 'lodash/isEqual';
import { formToModel, isTouched } from '../CustomFields/ComposableFormUtilities';
import { useSaveMeeting } from 'src/state/context/Meetings/saveMeetingHelper';
import { useLocation } from 'react-router';
import workerChannel from 'src/worker/channels/workerChannel';
import { useSyncState } from 'src/state/redux/selector/cache';
import useCRMMeetingFormHandler from 'src/hooks/useCRMMeetingFormHandler/useCRMMeetingFormHandler';
import { CRMAvailabilityStatus } from 'src/state/machines/CRM/crmMachineTypes';
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import { StandaloneForm } from 'src/state/redux/selector/crm';
import DNACustomFormConfirmDelete from '../DNA/Modal/DNACustomFormConfirmDelete';
import { consolidateFormErrorMessages, removeEmptyFields } from './Util';
import { useComposableForm } from '../CustomFields/ComposableForm';
import * as logger from 'src/utils/logger'

export const GLOBAL_FORM_ERROR = {
  invalid_type: 'Missing required fields.',
  too_small: 'Missing required fields.',
  invalid_date: 'End time must be after start time.',
}

export const useIsMeetingLocked = (meetingORM?: MeetingORM) => {
  const { pathname } = useLocation();
  const isLocked = meetingORM?.model.status === 'LOCKED';
  const isHub = pathname.includes('hub');
  return isLocked || isHub;
}

export interface CustomFormsSettings {
  customFormRecordId?: string
  standaloneForm: StandaloneForm;
}

interface MeetingContext {
  onCancel: () => void
  onDelete: () => void
  formToModel: (values: { [x: string]: string | string[]}) => CustomValues[]
  onDeleteContent: (contentPresentedId: string, folderItemId?: string) => void
  meetingORM: MeetingORM | undefined
  setCustomFormsSettings: (settings?: CustomFormsSettings) => void;
  customFormsSettings?: CustomFormsSettings,
  isDirty: boolean,
  isValid: boolean,
  touched: DeepMap<{
    [x: string]: string | string[];
}, true>,
  isSubmitting: boolean,
  crmSubmitState: CRM_SUBMIT_STATE,
  isFormEdited: boolean,
  contentPresented: ContentPresented[],
  onSave: (submitType?: MEETING_SUBMIT_TYPE, overrideErrorMessage?: string) => Promise<void>
  addContentPresented: (item: ContentPresented) => void;
  errorMessage: string,
  isReadOnly?: boolean,
  meetingStatus: MeetingStatus,
  removeCustomRecord: (customFormRecordId: string, label: string) => void,
}

const AddMeetingContext = React.createContext<MeetingContext>(null!);

export enum MEETING_SUBMIT_TYPE {
  DEFAULT,
  SILENT_SAVE,
  SAVE_TO_CRM,
  SUBMIT_LOCK_TO_CRM
}

export enum CRM_SUBMIT_STATE {
  IDLE,
  SUBMITTING,
  SUCCESS,
  ERROR
}

interface AddMeetingProviderProps {
  meetingId?: string
  toggleDrawer: (ignoreChanges?: boolean) => void
}

export const AddMeetingProvider:React.FC<PropsWithChildren<AddMeetingProviderProps>> = ({
  meetingId,
  toggleDrawer,
  children,
}) => {
  const meetingORM = useMeeting(meetingId!)
  const [customFormsSettings, setCustomFormsSettings] = useState<CustomFormsSettings | undefined>();
  const submitMeeting = useSaveMeeting(meetingId);
  const dispatch = useDispatch();
  const toast = useToast()
  const { rhForm, resetWithLatestDefaultValues, isReadOnly } = useComposableForm()
  const { formState, watch } = rhForm
  const { isValid, isSubmitSuccessful, dirtyFields } = formState
  const touched = useMemo(() => isTouched(formState), [formState.touchedFields]);

  const isDirty = Object.keys(dirtyFields).length > 0
  const initialContentPresented =  meetingORM?.model.contentPresented || []
  logger.addMeetingPanel.debug({ contentPresented: meetingORM?.model.contentPresented })
  const [contentPresented, setContentPresented] = useState<ContentPresented[]>(initialContentPresented);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const crmFormHandler = useCRMMeetingFormHandler({ meetingId, rhForm });
  const { availabilityStatus } = useCRMStatus();
  const startTime = watch('startTime');
  const endTime = watch('endTime');
  const isMeetingLocked = useIsMeetingLocked(meetingORM)
  const meetingStatus = watch('status')
  const syncState = useSyncState()
  const isCRMEnabled =  availabilityStatus === CRMAvailabilityStatus.ENABLED

  useEffect(() => {
    let shouldResumeSync = false;
    const handleSync = async () => {
      const registration = await navigator.serviceWorker.getRegistration('/');
      if (registration && syncState?.matches('online.sync')) {
        workerChannel.postMessageExtended({ type: 'PAUSE_SYNC' });
        shouldResumeSync = true;
      }
    };
    const resumeSync = () => {
      if (shouldResumeSync) {
        workerChannel.postMessageExtended({ type: 'RESUME_SYNC' });
      }
    };
    handleSync();
    return resumeSync;
  }, []);

  useEffect(() => {
    if (meetingId) {
      rhForm.trigger('endTime')
    }
  }, [startTime, endTime, meetingId]);

  // CREATES THE MEETING IN CASE IT DOESN'T EXIST
  useEffect(() => {
    const onNewMeetingCreated = (meeting: Meeting): void => {
      dispatch(drawerActions.toggle({
        entity: DRAWER_ENTITIES.MEETING,
        entityId: meeting.id,
        keepOpen: true,
      }));
    };

    if (!meetingId) {
      // TODO: we need to stop getting private values, i will left this untill we find a beeter way to do it
      const { title, startTime, endTime } = rhForm.control._formValues;
      dispatch(meetingActions.createMeeting({
        startTime: startTime!.toString(),
        endTime: endTime!.toString(),
        status: isCRMEnabled ? MeetingStatus.PLANNED : MeetingStatus.ENDED_EXIT,
        type: MeetingType.MANUAL,
        contentPresented,
        customValues: [],
        callback: onNewMeetingCreated,
        attendees: [],
        title: title!.toString(),
      }))
    }

    logger.addMeetingPanel.debug('Inside init useEffect', contentPresented)
  }, []);

  // IF THERE WAS A CRM SUBMIT, THE FORM NEEDS TO BE RESET WITH THE LATEST DEFAULT VALUES
  // AS THERE MIGHT BE NEW EXTERNAL IDS TO BE SET THAT WERE NOT FORM VALUES
  useEffect(() => {
    if ([CRM_SUBMIT_STATE.SUCCESS, CRM_SUBMIT_STATE.SUBMITTING].includes(crmFormHandler.crmSubmitState)) {
      resetWithLatestDefaultValues();
    }
  }, [meetingORM]);

  useEffect(() => {
    // IF THERE'S A CHANGE OF A CONTENT FROM THE MEETING CPM (SENTIMENTS), THE
    // CONTENTPRESENTED OF THE STATE NEEDS TO BE UPDATED TO REFLECT THIS CHANGE
    const updatedContentPresented = replaceContentPresented(meetingORM?.model.contentPresented || [], contentPresented);
    setContentPresented(updatedContentPresented);
  }, [meetingORM?.model.contentPresented]);

  /** NOTE: The formState declared above has an 'isSubmitting' property, however,
   * the submission of the form appears to block UI updates. This useState is a
   * workaround to allow for UI updates just prior to form submission */
  const [isSubmitting, setIsSubmitting] = useState(isSubmitSuccessful);

  /** NOTE: This is a workaround for the blocking nature of the form
   * submission to allow for UI updates just prior to the submission */
  const onSave = (submitType?: MEETING_SUBMIT_TYPE, overrideErrorMessage?: string) => {
    return handleSubmit(submitType, overrideErrorMessage).finally(() => {
      // THIS WILL BLOCK THE CRMSYNC BUTTON TO AVOID SUBMISSIONS WHILE SAVING
      const waitTime = !submitType ? 2000 : 0;
      setTimeout(() => {
        setIsSubmitting(false);
      }, waitTime);
    });
  }

  const onCancel = () => {
    toggleDrawer()
  }

  const onDelete = () => {
    if (isMeetingLocked) {
      console.warn('Cannot delete a locked meeting');
      return;
    }

    const toggleDrawerAndIgnoreChanges = async () => {
      return toggleDrawer(true)
    }
    if (meetingORM) {
      const payload = {
        isVisible: true,
        allowBackdropCancel: true,
        component: () =>
          (<DNAMeetingDeleteModal
            meeting={meetingORM}
            onDelete={toggleDrawerAndIgnoreChanges}
          />),
      };
      dispatch(DNAModalActions.setModal(payload));
    }
  }

  const onDeleteContent = (contentPresentedId: string, folderItemId?: string) => {
    const handleUpdateContent = () => {
      // just filter it from the array if it didn't exist before
      if (initialContentPresented.find((content) => content.contentId)) {
        setContentPresented((prev) => prev.filter((content) => content.contentId !== contentPresentedId));
      }
      // otherwise set status to deleted
      else {
        setContentPresented((prev) => prev.map((item) => (item.contentId === contentPresentedId &&
          !folderItemId && !item.folderItemId) ||
          (item.folderItemId === folderItemId && item.contentId === contentPresentedId)
          ? { ...item, status: CONTENT_PRESENTED_STATUS.DELETED, presentedMeta: [] } : item),
        );
      }
    }
    const payload = {
      isVisible: true,
      allowBackdropCancel: false,
      component: () =>
        (<DNAMeetingContentDeleteModal
          onDelete={handleUpdateContent}
        />),
    };
    dispatch(DNAModalActions.setModal(payload));
  }

  const isFormEdited =
    (isDirty && isValid) ||
    (isEmpty(touched) && !isDirty)

  const handleSubmit = (
    submitType: MEETING_SUBMIT_TYPE = MEETING_SUBMIT_TYPE.DEFAULT,
    overrideErrorMessage?: string,
  ): Promise<void> => {
    return rhForm.handleSubmit(async (rawValues) => {
      // we need to remove empty fields from the form values
      const values = removeEmptyFields(rawValues);

      const isSubmitCRMSave =
        submitType && [MEETING_SUBMIT_TYPE.SUBMIT_LOCK_TO_CRM, MEETING_SUBMIT_TYPE.SAVE_TO_CRM].includes(submitType);
      if (isSubmitCRMSave) {
        if (!await crmFormHandler.canSubmitToCRM(values, rhForm, setErrorMessage, crmFormHandler.crmIntegrationType)) {
          crmFormHandler.setCrmSubmitState(CRM_SUBMIT_STATE.ERROR);
          return;
        }
        crmFormHandler.setCrmSubmitState(CRM_SUBMIT_STATE.SUBMITTING);
      } else {
        crmFormHandler.setCrmSubmitState(CRM_SUBMIT_STATE.IDLE);
      }
      setIsSubmitting(true);
      logger.addMeetingPanel.debug('Inside handleSubmit: form valid, saving...', { contentPresented });

      if (meetingORM) {
        try {
          await submitMeeting({
            meetingORM,
            contentPresented,
            formValues: values,
            submitType,
          });
        } catch (e) {
          if (e instanceof Error) setErrorMessage(e.message);
          crmFormHandler.setCrmSubmitState(CRM_SUBMIT_STATE.ERROR);
          return;
        }

        analytics?.track('MEETING_UPDATED', {
          action: 'UPDATED',
          category: 'MEETING',
          meetingId: meetingORM.model.id,
        });

        if (submitType === MEETING_SUBMIT_TYPE.SAVE_TO_CRM &&
          meetingORM.model.status === MeetingStatus.PLANNED) {
          analytics?.track('MEETING_PLANNED_SYNC', {
            action: 'UPDATED',
            category: 'MEETING',
            meetingId: meetingORM.model.id,
          });
        }

        // ON SUCCESS SAVE HANDLING
        if (MEETING_SUBMIT_TYPE.DEFAULT === submitType) {
          toast.add(<GenericToast
            title="Changes saved"
            status="success"
          />, ToastOrientations.TOP_RIGHT);

          if (isCRMEnabled) {
            const status = meetingStatus === MeetingStatus.IN_PROGRESS ? MeetingStatus.ENDED_EXIT
              : meetingStatus
            rhForm.reset({ ...rhForm.getValues(), status }, { keepDirty: false })
          } else {
            toggleDrawer(true)
          }
        } else if (isSubmitCRMSave) {
          setTimeout(() => {
            crmFormHandler.setCrmSubmitState(CRM_SUBMIT_STATE.SUCCESS);
            setErrorMessage('');
          }, 800);
        }
      }
    }, (errors: { [s: string]: unknown; } | ArrayLike<unknown>) => {
      logger.addMeetingPanel.debug('Inside handleSubmit: form invalid, show error message', { contentPresented });

      if (overrideErrorMessage) {
        setErrorMessage(overrideErrorMessage);
        return;
      }
      const formattedFormErrors = consolidateFormErrorMessages(errors);
      setErrorMessage(formattedFormErrors)
    })();
  }

  const addContentPresented = (item: ContentPresented) => {
    setContentPresented((prev) => [...prev, item])
  }

  const removeCustomRecord = (customFormRecordId: string, label: string) => {
    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: false,
      component: () => (<DNACustomFormConfirmDelete
        toast={toast}
        customFormRecordId={customFormRecordId}
        label={label}
      />),
    }));
  }

  useEffect(() => {
    dispatch(drawerActions.setPendingChanges(
      {
        hasPendingChanges: isDirty || !isEqual(initialContentPresented, contentPresented),
      },
    ),
    )
  }, [isDirty, contentPresented])

  const context:MeetingContext = {
    onCancel,
    onDelete,
    formToModel,
    onDeleteContent,
    meetingORM,
    addContentPresented,
    contentPresented,
    isDirty,
    isValid,
    touched,
    isSubmitting,
    crmSubmitState: crmFormHandler.crmSubmitState,
    isFormEdited,
    onSave,
    errorMessage,
    setCustomFormsSettings,
    customFormsSettings,
    isReadOnly,
    meetingStatus: meetingStatus as MeetingStatus,
    removeCustomRecord,
  }

  return (
    <AddMeetingContext.Provider value={context}>
      {children}
    </AddMeetingContext.Provider>
  )
}

export const useAddMeeting = () => React.useContext(AddMeetingContext)
