/* eslint-disable dot-notation */
import { useEffect, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormValuesType, useComposableForm } from 'src/components/CustomFields/ComposableForm';
import { CRMAccount, CRMAddress } from 'src/classes/CRM/CRMIndexedDBTypes';
import { INQUIRY_SUPPORTED_METHODS } from 'src/classes/CRM/Translators/VeevaMarkerHandler';
import { Singleton as IndexDbCrm } from 'src/classes/CRM/CRMIndexedDB';
import { CustomFieldValueDefinition } from '@alucio/aws-beacon-amplify/src/models';
import { v4 as uuid } from 'uuid';
import isEquals from 'lodash/isEqual'
import { ObjectWithId } from 'src/components/CustomFields/ComposableFormUtilities';
import get from 'lodash/get';

const ACCOUNT_PROPERTY_NAME = 'Account_vod__c';
const DELIVERY_METHOD_PROPERTY_NAME = 'Delivery_Method_vod__c';

const getAddress = (address: CRMAddress) => {
  const addressParts = [
    address.Name,
    address.City_vod__c,
    address.State_vod__c,
    address.Zip_vod__c,
  ].filter(part => part !== undefined && part !== '');

  return addressParts.join(', ').trim();
};

const getPhones = (account: CRMAccount): string[] => {
  const phones: string[] = [
    account.Phone as string,
    account.PersonMobilePhone as string,
    ...account.addresses?.reduce<string[]>((acc, { Phone_vod__c }) => Phone_vod__c ? [...acc, Phone_vod__c] : acc, []),
  ];

  return phones.filter((phone) => phone);
};

type SUPPORTED_METHODS = 'Mail_vod' | 'Phone_vod' | 'Email_vod' | 'Urgent_Mail_vod' | 'Fax_vod';

const getUpdate = (account: CRMAccount) : {
  [key in SUPPORTED_METHODS]: () => {
    updates: CustomFieldValueDefinition[];
    inquiry: INQUIRY_SUPPORTED_METHODS | INQUIRY_SUPPORTED_METHODS.MAIL;
  };
} => ({
  'Mail_vod': () => {
    const addresses = account.addresses.map<CustomFieldValueDefinition>((address) => {
      const formattedAddress = getAddress(address);
      return {
        id: address.id,
        value: formattedAddress,
        createdAt: new Date().toISOString(),
        dependentValueIds: ['Mail_vod'],
      };
    });
    return {
      updates: addresses,
      inquiry: INQUIRY_SUPPORTED_METHODS.MAIL,
    }
  },
  'Phone_vod': () => {
    const phones = getPhones(account);
    if (!phones.length) return { updates: [], inquiry: INQUIRY_SUPPORTED_METHODS.PHONE, optionalFields: [] };
    const newPhones: CustomFieldValueDefinition[] =
      phones.map((phone) => ({
        id: phone || uuid(),
        value: phone || 'INVALID',
        label: phone || '',
        createdAt: new Date().toISOString(),
        dependentValueIds: ['Phone_vod'],
      }));
    return {
      updates: newPhones,
      inquiry: INQUIRY_SUPPORTED_METHODS.PHONE,
    }
  },
  'Email_vod': () => {
    const email = account.PersonEmail as string;
    if (!email) return { updates: [], inquiry: INQUIRY_SUPPORTED_METHODS.EMAIL, optionalFields: [] };
    const newEmail : CustomFieldValueDefinition[] = [
      {
        id: email || uuid(),
        value: email || 'INVALID',
        label: email || '',
        createdAt: new Date().toISOString(),
        dependentValueIds: ['Email_vod'],
      },
    ]
    return {
      updates: newEmail,
      inquiry: INQUIRY_SUPPORTED_METHODS.EMAIL,
    }
  },
  'Urgent_Mail_vod': () => {
    const urgEmail = account.Urgent_Mail_vod__c as string;
    if (!urgEmail) return { updates: [], inquiry: INQUIRY_SUPPORTED_METHODS.MAIL, optionalFields: [] };
    const newUrgEmail : CustomFieldValueDefinition[] = [
      {
        id: urgEmail || uuid(),
        value: urgEmail || 'INVALID',
        label: urgEmail || '',
        createdAt: new Date().toISOString(),
        dependentValueIds: ['Urgent_Mail_vod'],
      },
    ]
    return {
      updates: newUrgEmail,
      inquiry: INQUIRY_SUPPORTED_METHODS.MAIL,
    }
  },
  'Fax_vod': () => {
    const faxVod = account.Fax as string;
    if (!faxVod) return { updates: [], inquiry: INQUIRY_SUPPORTED_METHODS.FAX, optionalFields: [] };
    const newFaxVod : CustomFieldValueDefinition[] = [
      {
        id: faxVod || uuid(),
        value: faxVod || 'INVALID',
        label: faxVod || '',
        createdAt: new Date().toISOString(),
        dependentValueIds: ['Fax_vod'],
      },
    ]
    return {
      updates: newFaxVod,
      inquiry: INQUIRY_SUPPORTED_METHODS.FAX,
    }
  },
})

type InquiryWatchParams = {
    Account_vod__c: string | string[] | ObjectWithId[];
    Delivery_Method_vod__c: string | string[] | ObjectWithId[];
}

export const useInquiryWatch = (
  rhForm: UseFormReturn<FormValuesType>,
): void => {
  const { watch, getValues } = rhForm;
  const watchMedicalInquiry = watch(['Account_vod__c', 'Delivery_Method_vod__c']);
  const { updateCustomFieldValues } = useComposableForm();
  const lastInquiryMethodSelected = useRef<null | INQUIRY_SUPPORTED_METHODS>(null);
  const lastElementSelected = useRef<null | InquiryWatchParams>(null);

  const updateCustomFieldValuesBasedOnMethod = async (
    method: SUPPORTED_METHODS,
    account: CRMAccount,
  ) => {
    const result = getUpdate(account)?.[method]?.();
    if (!result) return;
    const { updates, inquiry } = result;
    updateCustomFieldValues(updates, inquiry);
    lastInquiryMethodSelected.current = inquiry;
  };

  useEffect(() => {
    const doAction = async () => {
      if (!watchMedicalInquiry) return;
      const inquiry = getValues() as InquiryWatchParams;

      // if inquiry has no value
      if (!inquiry) return;

      // check if lastElementSelected.current is different from inquiry
      if (isEquals(lastElementSelected.current, inquiry)) return;

      const selectedAccountId = get(inquiry, ACCOUNT_PROPERTY_NAME, '') as string;
      const selectedDeliveryMethod = get(inquiry, DELIVERY_METHOD_PROPERTY_NAME, '') as SUPPORTED_METHODS;

      // check is the delivery method has a value
      if (!selectedAccountId || !selectedDeliveryMethod) return;

      // update lastElementSelected after all the checks
      lastElementSelected.current = inquiry;

      const account = await IndexDbCrm.getById<CRMAccount>('ACCOUNT', selectedAccountId);

      // create custom field values based on the delivery method
      await updateCustomFieldValuesBasedOnMethod(selectedDeliveryMethod, account);
    }

    doAction();
  }, [watchMedicalInquiry]);
};
