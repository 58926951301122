import React, {
  useState,
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
} from 'react';
import { HubSectionType } from '@alucio/aws-beacon-amplify/src/models';
import useHubFormState, { EditHubRHForm, getDefaultHubFormValue } from 'src/screens/Hubs/EditHub/useHubForm';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import { GLOBAL_FORM_ERROR } from 'src/components/Meeting/AddMeetingProvider';
import * as logger from 'src/utils/logger';

export interface HubRHFormStateType {
  rhForm: EditHubRHForm
  isSaving: boolean
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>
  formErrorMessage: string
}

export enum HubFormWidgetKeys {
  textarea = 'textarea',
  sharedFiles = 'sharedFiles',
  todoList = 'todoList',
  links = 'links',
}

interface InitialValues {
  hubId: string
}

export type FormName = 'textareaWidget' | 'sharedFilesWidget' | 'todoListWidget' | 'linksWidget'

export const rhFormFieldName: Record<HubSectionType, FormName> = {
  [HubSectionType.TEXTAREA]: 'textareaWidget',
  [HubSectionType.SHARED_FILES]: 'sharedFilesWidget',
  [HubSectionType.TODO_LIST]: 'todoListWidget',
  [HubSectionType.LINKS]: 'linksWidget',
}

export const HubRHFormStateContext = createContext<HubRHFormStateType | null>(null!)
HubRHFormStateContext.displayName = 'HubRHFormContext'

const HubRHFormStateProvider: React.FC<PropsWithChildren<InitialValues>> = (props) => {
  const { hubId, children } = props
  const { hubORM } = useHubsState()
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const rhForm = useHubFormState({ hubId })
  const [formErrorMessage, setFormErrorMessage] = useState<string>('');
  const { errors } = rhForm.formState

  useEffect(() => {
    if (Object.keys(errors).length) {
      logger.hub.hubManagement.warn('Hub form error occurred', errors)
      setFormErrorMessage(GLOBAL_FORM_ERROR.too_small)
    } else setFormErrorMessage('')
  }, [errors])

  useEffect(() => {
    // After the form is saved and not closed (preview), we need to reset the form
    const defaultValues = getDefaultHubFormValue(hubORM)
    rhForm.reset(defaultValues)
  }, [hubORM])

  const contextValue: HubRHFormStateType = {
    rhForm,
    isSaving,
    setIsSaving,
    formErrorMessage,
  }

  return (
    <HubRHFormStateContext.Provider value={contextValue}>
      {children}
    </HubRHFormStateContext.Provider>
  )
}

HubRHFormStateProvider.displayName = 'HubRHFormStateProvider'
export const useHubRHFromState = () => {
  const context = useContext(HubRHFormStateContext)
  if (!context) {
    throw new Error('useHubRHFromState must be used within the HubRHFormStateProvider')
  }
  return context;
}

export default HubRHFormStateProvider


