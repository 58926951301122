import { InvokeCallback } from 'xstate';
import { PW } from 'src/state/machines/presentation/playerWrapper';
import * as logger from 'src/utils/logger'
import type * as CustomNote from './customNote.types';

export const HIGHLIGHT_AVAILABLE_DOC_TYPE = ['PDF', 'PPTX']

type ChannelObserverCallback<T extends CustomNote.SMEvents> = InvokeCallback<T, CustomNote.SMEvents>

export const presentationStateObserver = (
  channel: CustomNote.SMContext['presentationStateChannel'],
  meetingId: string,
):
  ChannelObserverCallback<PW.EVT_PRESENTATION_STATE_SYNC> => (send) =>
{
  const handler = (msg: PW.PresentationChannelMessage) => {
    if (meetingId !== msg.meetingId) return
    // We only pay attention to PRESENTATION_STATE_SYNC events which are broadcast
    // by the PresentationBroadCastProvider
    if (msg.type === 'PRESENTATION_STATE_SYNC') {
      send({
        type: 'PRESENTATION_STATE_SYNC',
        meetingId: msg.meetingId,
        payload: msg.payload,
        messageNumber: msg.messageNumber,
      })
    }
    if (msg.type === 'IFRAME_READY') {
      logger.userNotations.debug('Presentation state observer received: IFRAME_READY')
      send({
        type: 'IFRAME_READY',
      })
    }
    if (msg.type === 'SEND_NOTATION_COORDINATE') {
      logger.userNotations.debug('Presentation state observer received: SEND_NOTATION_COORDINATE')
      if (msg.coordinate) {
        send({
          type: 'SET_COORDINATE',
          coordinate: [msg.coordinate],
          pageId: msg.pageId,
        })
      } else send({ type: 'BACK_TO_IDLE' })
    }
    if (msg.type === 'SYNC_CURRENT_ACTIVE_NOTATION') {
      logger.userNotations.debug('Presentation state observer received: SYNC_CURRENT_ACTIVE_NOTATION')
      send({
        type: 'SET_CURRENT_ACTIVE_NOTATION',
        notationId: msg.notationId,
      })
    }
  }

  logger.userNotations.debug(`Attaching listener to presentation channel ${meetingId}`, channel)
  channel.addEventListener(
    'message',
    handler,
  )
  return () => {
    logger.userNotations.debug('Cleanup Called for presentation channel observer')
    channel.removeEventListener('message', handler);
  }
}
