import type {
  StateFrom,
  InterpreterFrom,
  Prop,
  EventObject,
  ActorRefWithDeprecatedState,
} from 'xstate';
import type publisherVersioningSM from './publisherVersioning.machine';
import type { Typegen0 } from './publisherVersioning.machine.typegen';

/** AMPLIFY */
import { GraphQLResult, GraphQLQuery } from '@aws-amplify/api'
import {
  DocumentVersion,
  DocumentVersionChangeType,
  AttachedFile,
  AssociatedFile,
} from '@alucio/aws-beacon-amplify/src/models';
import {
  CreateDocumentVersionFromS3UploadInput,
  CreateDocumentVersionFromS3UploadMutation,
  CreateDocumentVersionFromExistingMutation,
  UpdateDocumentThumbnailInput,
} from '@alucio/aws-beacon-amplify/src/API';

/** BEACON */
import { UploadStatus } from 'src/components/DNA/FileUpload/FileUpload';
import { DocumentORM } from 'src/types/types';
import { DocumentVersionDiff } from 'src/state/machines/publisherVersioning/observableVersion';
import * as SS from './SlideSettings/slideSettings.types';
import * as MS from './MatchSlides/matchSlides.types';

/** LUX-UI */
import {
  InformationMessageVariants,
} from '@alucio/lux-ui/src/components/controls/InformationMessage/InformationMessage';
import { ContentPageData } from 'src/hooks/useContentPageData/useContentPageData';

export enum TabOptions {
  DOCUMENT_INFO_TAB = 'DOCUMENT_INFO_TAB',
  DOCUMENT_MATCH_SLIDES_TAB = 'DOCUMENT_MATCH_SLIDES_TAB',
  DOCUMENT_SETTINGS_TAB = 'DOCUMENT_SETTINGS_TAB',
  DOCUMENT_ASSOCIATED_FILES = 'DOCUMENT_ASSOCIATED_FILES',
  DOCUMENT_PUBLISH_TAB = 'DOCUMENT_PUBLISH_TAB',
}

export enum Versioning_Tabs {
  NAV_TO_DOCUMENT_INFO_TAB = 'NAV_TO_DOCUMENT_INFO_TAB',
  NAV_TO_DOCUMENT_MATCH_SLIDES_TAB = 'NAV_TO_DOCUMENT_MATCH_SLIDES_TAB',
  NAV_TO_DOCUMENT_SETTINGS_TAB = 'NAV_TO_DOCUMENT_SETTINGS_TAB',
  NAV_TO_DOCUMENT_ASSOCIATED_FILES = 'NAV_TO_DOCUMENT_ASSOCIATED_FILES',
  NAV_TO_DOCUMENT_PUBLISH_TAB = 'NAV_TO_DOCUMENT_PUBLISH_TAB',
}

export type Versioning_Tab = keyof typeof Versioning_Tabs

export type PublisherVersioningState = StateFrom<typeof publisherVersioningSM>
export type PublisherVersioningService = InterpreterFrom<typeof publisherVersioningSM>
export type PublisherVersioningSend = Prop<PublisherVersioningService, 'send'>
export type PublisherVersioningMachine = typeof publisherVersioningSM

/**
 * ---------------------
 *  META VALUES
 * ---------------------
 */

export type InfoMessage = { message: string, status: InformationMessageVariants }
export type InfoMessageMeta = { infoMessage: InfoMessage }
export type CancelMessageMeta = { cancelMessage: InfoMessage }
export type MetaValues = Partial<InfoMessageMeta & CancelMessageMeta>
export type StateMeta = Record<string, MetaValues>

/**
 * ---------------------
 *  STATE MACHINE
 * ---------------------
 */

export type SMContext = {
  enableMatchSlides: boolean,
  availabletabs: TabOptions[],
  documentInfoIsDirty: boolean,
  documentSettingsIsDirty: boolean,
  documentPublishIsDirty: boolean,
  documentSlidesDataIsDirty: boolean,
  changesCheckResult?: ChangesCheckResult,
  errors: Record<string, string>,
  documentVersionId: string,
  versionForm: Partial<DocumentVersion>,
  hasOptimizedFinishedThisSession: boolean,
  getDocumentORM: () => DocumentORM,
  cancelUpload: boolean,
  selectedTabIndex: number,
  // [TODO] - Fix versionActor type
  versionActor?: any,
  slideSettingsActor?: ActorRefWithDeprecatedState<SS.SMContext, SS.SMEvents, any, any>
  matchSlidesActor?: ActorRefWithDeprecatedState<MS.SMContext, MS.SMEvents, any, any>
}

export type SMContextKeys = keyof SMContext
export type StringStates = Extract<Typegen0['matchesStates'], string>

type ChangesCheckResult = {
  disableMinor: boolean,
  detectedChangeType: DocumentVersionChangeType,
}

/**
 * ---------------------
 *  SERVICES
 * ---------------------
 */

export type SMServices = {
  uploadFile: {
    data: 'CANCEL' | {
      S3Key: string,
      apiInputObj: CreateDocumentVersionFromS3UploadInput,
      file: File,
    },
  },
  createRecordForUpload: {
    data: GraphQLResult<CreateDocumentVersionFromS3UploadMutation>
  },
  uploadThumbnailImage: {
    data: 'CANCEL' | {
      S3Key?: string | undefined;
      apiInputObj: UpdateDocumentThumbnailInput;
      file?: File | undefined;
    },
  },
  updateThumbnail: {
    data: void | {
      selectedThumbnail?: number,
      hasCustomThumbnail?: boolean,
    },
  },
  createFromExistingOptimizing: {
    data: GraphQLResult<GraphQLQuery<CreateDocumentVersionFromExistingMutation>>,
  },
  updateContentPageData: {
    data: void
  },
  publishDocument: {
    data: void
  },
}

/**
 * ---------------------
 *  EVENTS
 * ---------------------
 */

export type EvtValues<
  K extends T['type'],
  T extends EventObject = SMEvents
> = T extends { type: K }
  ? Omit<T, 'type'>
  : never

export type EVT_ATTACHED_FILE_UPLOAD_STATUS_CHANGE = {
  type: 'ATTACHED_FILE_UPLOAD_STATUS_CHANGE',
  payload: {
    status: UploadStatus
  }
}

export type EVT_ATTACHED_FILE_UPLOADED = {
  type: 'ATTACHED_FILE_UPLOADED',
  payload: {
    attachedFile: AttachedFile
  }
}

export type EVT_ASSOCIATED_FILE_DELETE = {
  type: 'ASSOCIATED_FILE_DELETE',
  payload: { attachmentId: string }
}

export type EVT_ASSOCIATED_FILE_UPDATED = {
  type: 'ASSOCIATED_FILE_UPDATED',
  payload: {
    associatedFile: AssociatedFile
  }
}

export type EVT_ASSOCIATED_DOCUMENT_LINK = {
  type: 'ASSOCIATED_DOCUMENT_LINK',
  payload: { documentId: string }
}

export type EVT_SET_IS_DIRTY = {
  type: 'SET_IS_DIRTY',
  payload: {
    type: 'info' | 'settings' | 'publish' | 'slidesData',
    isDirty: boolean
  }
}

export type EVT_UPLOAD_THUMBNAIL = {
  type: 'UPLOAD_THUMBNAIL',
  payload: { file: File }
}

export type EVT_REMOVE_THUMBNAIL = {
  type: 'REMOVE_THUMBNAIL'
}

export type EVT_UPDATE_THUMBNAIL = {
  type: 'UPDATE_THUMBNAIL' | 'REMOVE_THUMBNAIL',
  data: {
    S3Key?: string,
    apiInputObj: UpdateDocumentThumbnailInput,
    file?: File,
  }
}

export type EVT_UPDATED_THUMBNAIL = {
  type: 'UPDATED_THUMBNAIL',
  data: {
    selectedThumbnail?: number,
    hasCustomThumbnail?: boolean,
  }
}

export type EVT_CREATE_FROM_EXISTING = { type: 'CREATE_FROM_EXISTING' }

export type EVT_CREATE_FROM_UPLOAD = {
  type: 'CREATE_FROM_UPLOAD',
  payload: { file: File }
}
export type EVT_CANCEL_UPLOAD = {
  type: 'CANCEL_UPLOAD'
}

export type EVT_NAV_TO_DOCUMENT_INFO_TAB = {
  type: 'NAV_TO_DOCUMENT_INFO_TAB'
}

export type EVT_NAV_TO_DOCUMENT_MATCH_SLIDES_TAB = {
  type: 'NAV_TO_DOCUMENT_MATCH_SLIDES_TAB'
}

export type EVT_NAV_TO_DOCUMENT_SETTINGS_TAB = {
  type: 'NAV_TO_DOCUMENT_SETTINGS_TAB',
  payload?: {
    versionForm?: Partial<DocumentVersion>
  }
}

export type EVT_NAV_TO_DOCUMENT_ASSOCIATED_FILES = {
  type: 'NAV_TO_DOCUMENT_ASSOCIATED_FILES',
  payload?: {
    versionForm?: Partial<DocumentVersion>
  }
}

export type EVT_NAV_TO_DOCUMENT_PUBLISH_TAB = {
  type: 'NAV_TO_DOCUMENT_PUBLISH_TAB',
  payload?: {
    versionForm?: Partial<DocumentVersion>
  }
}

export type EVT_NAV_TAB_NEXT = {
  type: 'NAV_TAB_NEXT',
  payload?: {
    versionForm?: Partial<DocumentVersion>
  }
}

export type EVT_NAV_TAB_PREV = {
  type: 'NAV_TAB_PREV'
}

export type EVT_SAVE_DRAFT = {
  type: 'SAVE_DRAFT',
  payload: {
    versionForm?: Partial<DocumentVersion>,
    contentPageData?: ContentPageData[],
  }
}

export type EVT_DELETE_DRAFT = {
  type: 'DELETE_DRAFT'
}

export type EVT_SWITCH_DOCUMENT_VERSION = {
  type: 'SWITCH_DOCUMENT_VERSION',
  payload: {
    documentVersionId: string,
  }
}

export type EVT_SYNC_VERSION_FORM = {
  type: 'SYNC_VERSION_FORM',
  payload: {
    versionForm: Partial<DocumentVersion>,
  }
}

export type EVT_SYNC_DISTRIBUTABLE = {
  type: 'SYNC_DISTRIBUTABLE',
  payload: {
    versionForm: Pick<DocumentVersion, 'distributable'>
  }
}

export type EVT_PUBLISH_VERSION = {
  type: 'PUBLISH_VERSION',
  payload: {
    versionForm?: Partial<DocumentVersion>,
    contentPageData?: ContentPageData[],
  }
}

export type EVT_SCHEDULE_PUBLISH_VERSION = {
  type: 'SCHEDULE_PUBLISH_VERSION',
  payload: {
    versionForm?: Partial<DocumentVersion>,
    contentPageData?: ContentPageData[],
  }
}

export type EVT_CANCEL_SCHEDULE_VERSION = {
  type: 'CANCEL_SCHEDULE_VERSION',
}

export type EVT_CREATE_FILE = {
  type: 'done.invoke.uploadFile',
  data: {
    S3Key: string,
    apiInputObj: CreateDocumentVersionFromS3UploadInput,
    file: File,
  }
}

export type EVT_CREATE_FILE_RECORD = {
  type: 'done.invoke.createRecordForUpload',
  data: GraphQLResult<CreateDocumentVersionFromS3UploadMutation>
}

export type EVT_UPDATED_SLIDES_DATA = {
  type: 'done.invoke.UpdateVersion.draft.draftSaveProcessing:invocation[0]',
}

// This event will be fired from publish service automatically when code throws an exception
export type EVT_PUBLISH_ERROR = {
  type: 'error.platform.publish',
  data: Error
}

export type EVT_VERSION_UPDATE = { type: 'VERSION_UPDATE', payload: DocumentVersionDiff }

// From Child -> Parent Machine
export type EVT_SLIDE_SETTINGS_SYNC = {
  type: 'SLIDE_SETTINGS_SYNC',
  payload: {
    versionForm: Partial<DocumentVersion>,
  }
}

export type EVT_SLIDE_SETTINGS_SYNC_AND_SAVE_DRAFT = {
  type: 'SLIDE_SETTINGS_SYNC_AND_SAVE_DRAFT',
  payload: {
    versionForm: Partial<DocumentVersion>,
  }
}

export type EVT_OVERWRITE_SLIDE_SETTINGS = {
  type: 'OVERWRITE_SLIDE_SETTINGS',
  payload: {
    versionForm: Partial<DocumentVersion>,
    contentPageData?: ContentPageData[],
  }
}

export type SMEvents =
  | EVT_CREATE_FROM_UPLOAD
  | EVT_CANCEL_UPLOAD
  | EVT_CREATE_FROM_EXISTING
  | EVT_SET_IS_DIRTY
  | EVT_CREATE_FILE_RECORD
  | EVT_ATTACHED_FILE_UPLOAD_STATUS_CHANGE
  | EVT_ATTACHED_FILE_UPLOADED
  | EVT_ASSOCIATED_FILE_DELETE
  | EVT_ASSOCIATED_FILE_UPDATED
  | EVT_ASSOCIATED_DOCUMENT_LINK
  | EVT_SYNC_VERSION_FORM
  | EVT_SYNC_DISTRIBUTABLE
  | EVT_SWITCH_DOCUMENT_VERSION
  | EVT_NAV_TO_DOCUMENT_INFO_TAB
  | EVT_NAV_TO_DOCUMENT_MATCH_SLIDES_TAB
  | EVT_NAV_TO_DOCUMENT_SETTINGS_TAB
  | EVT_NAV_TO_DOCUMENT_ASSOCIATED_FILES
  | EVT_NAV_TO_DOCUMENT_PUBLISH_TAB
  | EVT_NAV_TAB_NEXT
  | EVT_NAV_TAB_PREV
  | EVT_VERSION_UPDATE
  | EVT_PUBLISH_VERSION
  | EVT_SCHEDULE_PUBLISH_VERSION
  | EVT_CANCEL_SCHEDULE_VERSION
  | EVT_CREATE_FILE
  | EVT_PUBLISH_ERROR
  | EVT_SAVE_DRAFT
  | EVT_UPDATE_THUMBNAIL
  | EVT_UPLOAD_THUMBNAIL
  | EVT_REMOVE_THUMBNAIL
  | EVT_UPDATED_THUMBNAIL
  | EVT_DELETE_DRAFT
  | EVT_SLIDE_SETTINGS_SYNC
  | EVT_SLIDE_SETTINGS_SYNC_AND_SAVE_DRAFT
  | EVT_OVERWRITE_SLIDE_SETTINGS
  | EVT_UPDATED_SLIDES_DATA

export type SwitchTabEvents =
  | EVT_NAV_TO_DOCUMENT_INFO_TAB
  | EVT_NAV_TO_DOCUMENT_MATCH_SLIDES_TAB
  | EVT_NAV_TO_DOCUMENT_SETTINGS_TAB
  | EVT_NAV_TO_DOCUMENT_ASSOCIATED_FILES
  | EVT_NAV_TO_DOCUMENT_PUBLISH_TAB
  | EVT_NAV_TAB_PREV
  | EVT_NAV_TAB_NEXT

export type SyncFormEvents =
  | EVT_SYNC_VERSION_FORM
  | EVT_SYNC_DISTRIBUTABLE
  | EVT_NAV_TO_DOCUMENT_SETTINGS_TAB
  | EVT_NAV_TO_DOCUMENT_PUBLISH_TAB
  | EVT_NAV_TAB_NEXT
