import React from 'react'
import { GenericToast, ToastOrientations } from '@alucio/lux-ui';
import DNACommonConfirmation from './DNACommonConfirmation';
import { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast';
import { useCustomFormRecordById } from 'src/state/redux/selector/customFormRecord';
import { useDispatch } from 'react-redux';
import { customFormRecordActions } from 'src/state/redux/slice/customFormRecord';

interface DeleteConfirmationProps {
    toast: ToastActions | undefined,
    label: string,
    onDelete?: () => void,
    customFormRecordId: string,
}

const DNACustomFormConfirmDeleteModal = (props: DeleteConfirmationProps) => {
  const { toast, customFormRecordId, onDelete, label } = props
  const customFormRecord = useCustomFormRecordById(customFormRecordId)
  const dispatch = useDispatch();

  const onConfirm = () => {
    if (!customFormRecord) {
      console.warn('CustomForm is undefined')
      return
    }

    dispatch(customFormRecordActions.deleteCustomFormRecord(customFormRecord.model));

    toast?.add(
      <GenericToast title={`${label} deleted`} status="error" />,
      ToastOrientations.TOP_RIGHT,
    )
    onDelete?.()
  }

  return (<DNACommonConfirmation
    status="danger"
    cancelText="Cancel"
    descriptionText=""
    confirmActionText="Delete"
    onConfirmAction={onConfirm}
    title={`Delete ${label}?`}
  />)
}

export default DNACustomFormConfirmDeleteModal
