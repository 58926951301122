import {
  CRMSubmitMeetingPayload,
  FormSettings,
  RecordToDelete,
  SalesforceFirstSubmitRecord,
  SalesforceRecordToUpsert,
} from '../CRMIndexedDBTypes';
import { FormValuesType } from 'src/components/CustomFields/ComposableForm';
import { getConvertedModelValuesToForm } from 'src/components/CustomFields/ComposableFormUtilities';
import { getFirstSubmitRecordPayload, getUpsertRecords } from './SalesforceFormTranslator';
import { addAdditionalFieldsToCustomFormRecord } from './VeevaFormTranslator';
import { CustomFormRecordORM } from 'src/types/orms';
import { CrmSyncStatus, CustomFormRecordStatus, Tenant } from '@alucio/aws-beacon-amplify/src/models';
import { MEETING_SUBMIT_TYPE } from 'src/components/Meeting/AddMeetingProvider';
import * as logger from 'src/utils/logger'

interface CRMCustomFormRecordFirstSubmitResponse {
  // WILL BE ADDED TO THE FIRST SUBMIT REQUEST AS IT'S OWN SEPARATED RECORDS
  standAloneCustomRecords: SalesforceFirstSubmitRecord[],
  // WILL BE ADDED AS CHILDREN OF THE MAIN RECORD
  mainRecordChildrenCustomRecords: {
    [relationshipName: string]: {
      records: SalesforceFirstSubmitRecord[]
    }
  }
}

interface CustomFormRecordProps {
  crmCustomFormRecords: CustomFormRecordORM[];
  isLockedStatus?: boolean;
  formSettings: FormSettings[];
  tenant: Tenant;
}

// ** TRANSFORMS THE CUSTOM FORM RECORDS INTO SALESFORCE FIRST SUBMIT STRUCTURE ** //
export async function getFormattedCRMCustomFormRecordsFirstSubmitPayload(
  mainFormSetting: FormSettings,
  crmSubmitPayload: CustomFormRecordProps): Promise<CRMCustomFormRecordFirstSubmitResponse> {
  logger.CRMFormTranslatorlogger.debug('Getting CRMCustomFormRecords first submit payload');
  const { crmCustomFormRecords, tenant, formSettings, isLockedStatus } = crmSubmitPayload;
  const mainRecordApiName = mainFormSetting.apiName;
  const standaloneForms = tenant.config.crmIntegration?.crmStandaloneForms;
  const response: CRMCustomFormRecordFirstSubmitResponse = {
    standAloneCustomRecords: [],
    mainRecordChildrenCustomRecords: {},
  };

  const filteredCustomFormRecords = crmCustomFormRecords.filter((record) =>
    record.model.status === CustomFormRecordStatus.ACTIVE);

  await Promise.all(filteredCustomFormRecords.map(async (record) => {
    logger.CRMFormTranslatorlogger.debug('Creating CRMCustomFormRecord first submit payload for: ', record);
    const formSetting = formSettings.find(({ id, apiName }) => id ===
      record.model.customFormId || apiName === record.model.crmFields?.apiName);

    if (!formSetting) {
      console.log('CRM FormSetting not found', record);
      throw Error(`CRM FormSetting not found for: ${record.model.crmFields?.apiName}`);
    }

    const valuesWithVeevaFields: FormValuesType =
      await addAdditionalFieldsToCustomFormRecord(record.meta.formValues, formSetting.apiName, isLockedStatus);

    const payload: SalesforceFirstSubmitRecord = getFirstSubmitRecordPayload(
      formSetting.apiName,
      record.model.id,
      valuesWithVeevaFields,
      mainFormSetting,
      formSettings,
      undefined,
      record.model.crmFields?.recordTypeId);

    logger.CRMFormTranslatorlogger.debug('Generated payload: ', payload);

    // CHECKS IF IT'S A STANDALONE RECORD OR CHILD OF THE MAIN ONE
    const standaloneFormOfRecord = standaloneForms?.find(({ id }) => id === record.model.customFormId);

    if (!standaloneFormOfRecord) {
      console.log('StandaloneForms:', standaloneForms);
      console.log('StandaloneForm not found in TenantConfig for ', record);
      throw Error(`StandaloneForm not found in TenantConfig for ${record.model.customFormId}`);
    }

    const isChildOfMainRecord = standaloneFormOfRecord.parents.some(({ apiName }) => apiName === mainRecordApiName);

    if (isChildOfMainRecord) {
      const relationshipName = mainFormSetting.objectInfos.childRelationships
        .find((relationship) => relationship.childObjectApiName === record.model.crmFields?.apiName)?.relationshipName;

      if (!relationshipName) {
        throw Error(`Custom Form Record Relationship not found for ${record.model.crmFields?.apiName}`);
      }

      if (response.mainRecordChildrenCustomRecords[relationshipName]) {
        response.mainRecordChildrenCustomRecords[relationshipName].records.push(payload);
      } else {
        response.mainRecordChildrenCustomRecords[relationshipName] = {
          records: [payload],
        };
      }
    } else {
      response.standAloneCustomRecords.push(payload);
    }
  }));

  return response;
}

// ** ITERATE OVER A GROUP OF CUSTOM FORM RECORDS AND DETERMINE WHICH NEED TO BE DELETED ON CRM ** //
export function getCustomFormRecordsToDelete(crmCustomFormRecords: CustomFormRecordORM[]): RecordToDelete[] {
  logger.CRMFormTranslatorlogger.debug('Getting CRMCustomFormRecords records to delete', crmCustomFormRecords);
  const recordsToBeDeleted: RecordToDelete[] = [];

  // IF THE RECORD'S SYNC_STATUS IS "SYNCED" BUT ITS BEACON'S STATUS IS "DELETED", IT'LL BE DELETED ON CRM
  crmCustomFormRecords.forEach((record) => {
    if (record.model.crmFields?.externalId &&
      record.model.status === CustomFormRecordStatus.DELETED &&
      record.model.crmFields?.syncStatus === CrmSyncStatus.SYNCED) {
      recordsToBeDeleted.push({
        crmId: record.model.crmFields?.externalId,
        beaconId: record.model.id,
      });
    }
  });

  return recordsToBeDeleted;
}

// ** RECEIVES A GROUP OF CUSTOM FORM RECORDS AND TRANSFORMS THEM INTO A UPSERT SALESFORCE STRUCTURE ** //
export async function getCustomFormRecordsToUpsert(
  crmSubmitPayload: CRMSubmitMeetingPayload,
  mainFormSetting?: FormSettings): Promise<SalesforceRecordToUpsert[]> {
  logger.CRMFormTranslatorlogger.debug('Getting CRMCustomFormRecords records to upsert', crmSubmitPayload);
  const recordsToUpsert: SalesforceRecordToUpsert[] = [];

  await Promise.all(crmSubmitPayload.crmCustomFormRecords.map(async (record) => {
    if (record.model.status === CustomFormRecordStatus.ACTIVE) {
      const values: FormValuesType = getConvertedModelValuesToForm(record.model.values);
      const formSetting = crmSubmitPayload.formSettings.find(({ id, apiName }) => id ===
        record.model.customFormId || apiName === record.model.crmFields?.apiName);

      if (!formSetting) {
        console.log('CRM FormSetting not found', record);
        throw Error(`CRM FormSetting not found for: ${record.model.crmFields?.apiName}`);
      }

      // IF IT'S A NEW RECORD, WE'LL ADD TO IT THE ID OF ITS PARENT (IF IT HAS A PARENT DEFINED)
      if (record.model.crmFields?.syncStatus !== CrmSyncStatus.SYNCED &&
        mainFormSetting && crmSubmitPayload.mainCrmRecordId) {
        const parentRelationship = formSetting.parentTablesRelationship?.[mainFormSetting.apiName];
        if (parentRelationship) {
          values[parentRelationship] = crmSubmitPayload.mainCrmRecordId;
        }
      }

      const valuesWithVeevaFields: FormValuesType =
        await addAdditionalFieldsToCustomFormRecord(values, formSetting.apiName,
          crmSubmitPayload.submitType === MEETING_SUBMIT_TYPE.SUBMIT_LOCK_TO_CRM);

      // TRANSFORMS IT AND ITS CHILDREN INTO AN UPSERT STRUCTURE
      const upsert: SalesforceRecordToUpsert[] = getUpsertRecords(valuesWithVeevaFields,
        crmSubmitPayload.formSettings,
        record.model.crmFields?.externalId || '',
        formSetting, crmSubmitPayload, undefined, record.model.id);

      recordsToUpsert.push(...upsert);
    }
  }));

  return recordsToUpsert;
}
