import React from 'react'

import { DocumentORM } from 'src/types/types'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'
import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions'
import { DNABox, DNACard, DNAText, DNAButton, useTheme, Iffy, DNAChip, luxColors } from '@alucio/lux-ui'
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import DNADocumentContextMenu from 'src/components/DNA/Document/DNADocumentContextMenu/DNADocumentContextMenu'
import { CustomLabelDisplay } from '../default/CustomLabelDisplay'
import { useAppSettings } from 'src/state/context/AppSettings';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList'
import { FileType } from '@alucio/aws-beacon-amplify/src/models'

export type DNADocumentDefaultRowProps = {
  onPin?: () => void,
  isOnline?: boolean,
}

const DNADocumentSearchRow: DNARowsComponent<
  DocumentORM,
  DNADocumentDefaultRowProps
> = (props) => {
  const {
    ORM: documentORM,
    isOnline,
    onPin,
    onPress,
    style,
  } = props

  const theme = useTheme()

  const isBookmarked = documentORM.meta.bookmark.isBookmarked
  const documentActions = useDNADocumentActions();
  const { deviceMode } = useAppSettings();
  const unavailableContent =
    (!isOnline &&
    !documentORM.relations.version.cachedDocumentVersionORM?.meta.assets.isContentCached) ||
    (documentORM.model.type === FileType.WEB && !isOnline)
  const title = documentORM.relations.version.latestPublishedDocumentVersionORM?.model.title

  return (
    <DNACard
      appearance="flat"
      interactive="pressable"
      onPress={() => { onPress?.(documentORM) }}
      style={[
        {
          paddingHorizontal: deviceMode === 'desktop' ? theme['row-padding-horizontal'] : 29,
          paddingVertical: theme['row-padding-vertical'],
        },
        style,
      ]}
    >
      <DNABox alignY="center" spacing="md" childFill={1}>
        {/* Actions */}
        <DNABox spacing={deviceMode === 'desktop' ? 'md' : 'lg'}>
          <DNABox style={{ width: 22 }}>
            <DNAButton
              status="gray"
              appearance="ghost"
              onPress={() => {
                documentActions.bookmark(documentORM)()
                onPin?.()
              }}
              iconLeft={isBookmarked ? 'bookmark' : 'bookmark-outline'}
              size={deviceMode === 'desktop' ? undefined : 'xl'}
            />
          </DNABox>
          <DNADocumentContextMenu documentORM={documentORM}>
            <DNAButton
              status="gray"
              appearance="ghost"
              iconLeft="dots-vertical"
              size={deviceMode === 'desktop' ? undefined : 'xl'}
            />
          </DNADocumentContextMenu>
        </DNABox>
        <DNABox alignY="center" spacing="lg" childFill={1} style={{ opacity: unavailableContent ? 0.4 : 1 }}>
          <DNADocumentThumbnail
            documentVersionORM={
              (!isOnline && documentORM.relations.version.cachedDocumentVersionORM) ||
              documentORM.relations.version.latestPublishedDocumentVersionORM ||
              documentORM.relations.version.latestDocumentVersionORM
            }
            width={76}
            height={44}
            unavailableContent={unavailableContent}
            // Assessing implementation in #BEAC-2155
            // onPress={() => { console.log('pressed search') }}
          />
          {/* Title */}
          <DNABox
            spacing="sm"
            appearance="col"
            childFill
            style={{ paddingRight: 16 }}
          >
            <DNAText b1 numberOfLines={1}>{title}
            </DNAText>
            <DNABox alignY="center" spacing="sm" childFill={unavailableContent ? 2 : 1}>
              <CustomFieldBadgeList documentVersionORM={documentORM.relations.version.latestUsableDocumentVersionORM} />
              <Iffy is={unavailableContent}>
                <DNAChip
                  appearance="tag"
                  style={{ backgroundColor: luxColors.basicBlack.primary }}
                  testID={`content-not-available-${title}`}
                >
                  CONTENT NOT AVAILABLE
                </DNAChip>
              </Iffy>
              <CustomLabelDisplay documentORM={documentORM} />
            </DNABox>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNACard>
  )
}

export default DNADocumentSearchRow
