import React, { useMemo } from 'react'
import { batch } from 'react-redux'
import im from 'immer'
import { GenericToast, LuxStatusEnum, LuxStatusV2Enum, ToastOrientations, useToast } from '@alucio/lux-ui'
import { FolderItemORM, CustomDeckORM, EmailTemplateORM, isCustomDeckORM } from 'src/types/types'
import { ToastActions } from '@alucio/lux-ui/lib/components/Toast/useToast'
import { folderActions } from 'src/state/redux/slice/folder'
import DNADocumentAddToFolder from '../Modal/DNADocumentAddToFolder'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import {
  EDITOR_TYPE,
  presentationBuilderActions,
} from 'src/state/redux/slice/PresentationBuilder/PresentationBuilder'
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal'
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings'

import { API, graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import {
  downloadCustomDeckAsPdfFile,
} from '@alucio/aws-beacon-amplify/src/graphql/mutations'
import { downloadAsLink } from 'src/screens/Documents/Export'
import { getCustomDeckLambda } from '@alucio/aws-beacon-amplify/src/graphql/queries';
import { GetCustomDeckLambdaQuery } from '@alucio/aws-beacon-amplify/src/API';
import { customDeckActions } from 'src/state/redux/slice/customDeck';
import { userNotationsActions } from 'src/state/redux/slice/userNotations'
import emailTemplateQuery from 'src/state/redux/emailTemplate/query'
import { getRootFolderORM } from 'src/state/redux/selector/folder';
import DNACommonConfirmation from '../Modal/DNACommonConfirmation';
import { isSharedEditableFolder } from 'src/utils/foldersHelpers'
import { sharedFolderActions, UpdateType } from 'src/state/redux/slice/sharedFolder'
import { store, useDispatch } from 'src/state/redux'
import ActiveUser from 'src/state/global/ActiveUser';
import { useEmailTemplateListForMSL } from 'src/state/redux/selector/emailTemplate'
import { sortCollection } from 'src/state/redux/selector/common'
import { useTenantCustomFields } from 'src/state/redux/selector/tenant'
import { CustomFieldDefinition, CustomFieldUsage } from '@alucio/aws-beacon-amplify/src/models';
import { CurrentUser, useCurrentUser } from 'src/state/redux/selector/user'
import { ContentShareModal, ShareVariantOptions } from '../Modal/ContentShareModal/ContentShareModal'
import * as logger from 'src/utils/logger'

export type CustomDeckContextOptions = {
  [key in keyof typeof CustomDeckContextActions]: {
    icon: string,
    title: CustomDeckContextActions,
    status?: LuxStatusV2Enum
    iconStatus?: LuxStatusEnum
    onPress: (
      d: CustomDeckORM,
      dispatch: typeof store.dispatch,
      toast: ToastActions,
      folderItemORM?: FolderItemORM,
      isTablet?: boolean,
      context?: string,
      isOnline?: boolean,
      currentUser?: CurrentUser,
      emailTemplates?: EmailTemplateORM[],
      customFieldsContentShare?: CustomFieldDefinition[],
    ) => () => void
  }
}

export type BindCustomDeckContextActions = {
  [key in keyof typeof CustomDeckContextActions]: (d: CustomDeckORM, f?: FolderItemORM, c?: string) => () => void
}

export enum CustomDeckContextActions {
  addToFolder = 'Add to folder',
  downloadAsPDF = 'Download as PDF',
  duplicate = 'Duplicate',
  edit = 'Edit',
  delete = 'Delete',
  present = 'View',
  shareEmail = 'Share',

}

export type CustomDeckActions = (keyof typeof CustomDeckContextActions)

export const useDNACustomDeckActions = (): BindCustomDeckContextActions => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const toast = useToast()
  const { deviceMode, isOnline } = useAppSettings()
  const emailTemplates = useEmailTemplateListForMSL()
  const orderedTemplates = sortCollection(
    emailTemplates,
    emailTemplateQuery.sorts.titleAsc,
  )
  const customTenantFieldsConfig = useMemo(() => ({ usages: { internalUsages: [CustomFieldUsage.CONTENT_SHARE] } }), [])
  const customFieldsContentShare = useTenantCustomFields(customTenantFieldsConfig)
  const bound = useMemo(() => im(customDeckContextOptions, draft => {
    for (const action in customDeckContextOptions) {
      const actionTyped = action as keyof typeof CustomDeckContextActions
      draft[action] = (
        customDeckORM: CustomDeckORM,
        folderItemORM: FolderItemORM,
        context?: string, // indicates from where this action was done for tracking purposes
      ) => customDeckContextOptions[actionTyped].onPress(
        customDeckORM,
        dispatch,
        toast,
        folderItemORM,
        deviceMode === DeviceMode.tablet,
        context,
        isOnline,
        currentUser,
        orderedTemplates,
        customFieldsContentShare,

      )
    }
  }) as unknown as BindCustomDeckContextActions,
  [dispatch, toast],
  )

  return bound
}

export const customDeckContextOptions: CustomDeckContextOptions = {
  addToFolder: {
    icon: 'folder-plus',
    title: CustomDeckContextActions.addToFolder,
    onPress: (_, dispatch, toast, folderItemORM) => () => {
      if (!folderItemORM) {
        console.error('Could not find the current custom deck to add to folder')
        return;
      }

      const itemORM = folderItemORM.relations.itemORM
      const userNotations = isCustomDeckORM(itemORM) ? itemORM.relations.userNotations : undefined
      const duplicateUserNotationsCallback = (newCustomDeckId: string) => {
        userNotations && dispatch(userNotationsActions.duplicateUserNotations({
          newCustomDeckId,
          userNotations,
        }))
      }

      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (props) => (
          <DNADocumentAddToFolder
            {...props}
            itemORM={folderItemORM!}
            toast={toast}
            duplicateUserNotationsCallback={duplicateUserNotationsCallback}
          />
        ),
      }),
      )
    },
  },
  edit: {
    icon: 'pencil',
    title: CustomDeckContextActions.edit,
    onPress: (currentCustomDeck, dispatch, _, folderItemORM, __, context, isOnline) => async () => {
      let customDeck;

      // The Custom deck culd be updated from the lasted time that we get all the shared folders
      // If that is the case we need to refresh the store
      // Only applies for the collaborators due the owner is able to get the changes inmediately
      // because it is subscribed to DATASTORE
      if (isOnline && currentCustomDeck.meta.permissions?.isCollaborator) {
        const rootFolder = folderItemORM?.relations.parentORM
          ? getRootFolderORM(folderItemORM?.relations.parentORM)
          : folderItemORM?.relations.parentORM
        const { data } = await API.graphql(graphqlOperation(getCustomDeckLambda, {
          customDeckId: currentCustomDeck.model.id,
          folderId: folderItemORM?.relations.parentORM?.model.id,
          rootFolderId: rootFolder?.model.id,
        })) as GraphQLResult<GetCustomDeckLambdaQuery>;

        if (!data || !data.getCustomDeckLambda) {
          throw new Error('Could not find custom deck')
        }

        customDeck = data?.getCustomDeckLambda;
      }

      batch(() => {
        customDeck && dispatch(customDeckActions.upsert(customDeck))
        dispatch(presentationBuilderActions.openPresentationBuilderEdit({
          customDeckId: currentCustomDeck.model.id,
          folderItemORM,
          targetFolder: folderItemORM?.relations.parentORM!,
          context,
          editorType: currentCustomDeck.meta.permissions?.isCollaborator
            ? EDITOR_TYPE.COLLABORATOR
            : EDITOR_TYPE.OWNER,

        }));
      })
    },
  },
  delete: {
    icon: 'trash-can-outline',
    title: CustomDeckContextActions.delete,
    status: LuxStatusV2Enum.danger,
    iconStatus: LuxStatusEnum.danger,
    onPress: (_, dispatch, toast, folderItemORM) => () => {
      const folderORM = folderItemORM?.relations.parentORM;

      if (!folderORM) {
        console.error('Could not determine the current folder path')
        return;
      }

      // NOTE: currently we are not removing the custom deck from the DB
      // we are only removing the reference in the folderItem
      if (folderItemORM) {
        function onConfirmDelete(): void {
          analytics?.track('CUSTOM_DELETE', {
            action: 'DELETE',
            category: 'CUSTOM',
            customDeckId: folderItemORM!.model.id,
          })

          dispatch(
            isSharedEditableFolder(folderORM!, ActiveUser?.user?.id)
              ? sharedFolderActions.updateItems({
                folder: folderORM!,
                folderItems: [folderItemORM!],
                action: UpdateType.DELETE_ITEM,
              })
              : folderActions.removeItem(folderItemORM!),
          );
          toast?.add(
            <GenericToast
              title="File deleted"
              status="success"
            />,
            ToastOrientations.TOP_RIGHT,
          )
        }
        dispatch(DNAModalActions.setModal(
          {
            isVisible: true,
            allowBackdropCancel: true,
            component: () => (
              <DNACommonConfirmation
                descriptionText={folderItemORM.model.customTitle}
                confirmActionText="Delete"
                title="Delete item?"
                status="danger"
                onConfirmAction={onConfirmDelete}
              />
            ),
          }));
      } else {
        console.error('Could not determine the current personalized file to delete')
      }
    },
  },
  duplicate: {
    icon: 'content-copy',
    title: CustomDeckContextActions.duplicate,
    onPress: (_, dispatch, __, folderItemORM) => () => {
      const targetFolder = folderItemORM?.relations.parentORM

      if (!targetFolder) {
        console.error('Could not determine the current folder path')
        return;
      }

      if (!folderItemORM) {
        console.error('Could not determine the current custom deck')
        return;
      }
      const isSharedEditFolder = targetFolder && isSharedEditableFolder(targetFolder)
      const itemORM = folderItemORM.relations.itemORM
      const userNotations = isCustomDeckORM(itemORM) ? itemORM.relations.userNotations : undefined
      const duplicateUserNotationsCallback = (newCustomDeckId: string) => {
        userNotations && dispatch(userNotationsActions.duplicateUserNotations({
          newCustomDeckId,
          userNotations,
        }))
      }
      if (isSharedEditFolder) {
        dispatch(sharedFolderActions.duplicateItem({
          targetFolderORM: targetFolder,
          itemToDupe: folderItemORM,
          duplicateUserNotationsCallback,
        }))
      }
      else {
        dispatch(folderActions.duplicateItem({
          targetFolderORM: targetFolder,
          itemToDupe: folderItemORM,
          duplicateUserNotationsCallback,
        }))
      }
    },
  },
  present: {
    icon: 'eye-outline',
    title: CustomDeckContextActions.present,
    onPress: (customDeckORM, dispatch, __, folderItemORM) => () => {
      for (const group of customDeckORM.meta.customDeckGroups) {
        if (group.model.visible && group.pages[0]) {
          dispatch(
            contentPreviewModalActions.setModalVisibility({
              documentVersionId: group.pages[0].documentVersionORM.model.id,
              folderItemORM: folderItemORM,
              content: folderItemORM,
              isFullWindow: true,
              fromEllipsis: true,
              isOpen: true,
            },
            ))
          break;
        }
      }
    },
  },
  downloadAsPDF: {
    icon: 'file-pdf-box',
    title: CustomDeckContextActions.downloadAsPDF,
    onPress: (customDeck, _, toast) => async () => {
      const processingToastId = toast.add(
        <GenericToast
          title="Generating PDF download..."
          status="information"
        />,
        ToastOrientations.TOP_RIGHT,
        true,
        0,
      )

      try {
        logger.util.info('Downloading Custom Deck as PDF')
        const { data } = await API.graphql(
          graphqlOperation(downloadCustomDeckAsPdfFile, {
            customDeckId: customDeck.model.id,
          }),
        ) as { data: { downloadCustomDeckAsPdfFile: string } };

        logger.util.info('Generated download URL -- Starting download')
        toast.add(
          <GenericToast
            title="Download Started..."
            status="download"
          />,
          ToastOrientations.TOP_RIGHT,
          true,
        )
        await downloadAsLink(data.downloadCustomDeckAsPdfFile)
      } catch (e) {
        logger.util.error(e)
        toast.add(
          <GenericToast
            title={'Download error.\nPlease contact support.'}
            status="error"
          />,
          ToastOrientations.TOP_RIGHT,
          true,
          0,
        )
      } finally {
        toast.remove(processingToastId)
      }
    },
  },
  shareEmail: {
    icon: 'email-send-outline',
    title: CustomDeckContextActions.shareEmail,
    onPress: (currentCustomDeck, dispatch, toast, folderItemORM, _, __, ___, user) => () => {
      if (!folderItemORM || !user) return
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: false,
        backdropVisible: true,
        component: (modalProps) => (
          <ContentShareModal
            entityShareIds={[folderItemORM.model.id]}
            {...modalProps}
            variant={ShareVariantOptions.CUSTOM_DECK_SHARE}
          />
        ),
      }))
    },
  },
}
