import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import {
  DNABox,
  DNAButton,
  DNAContextMenu,
  DNAText,
  Iffy,
} from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { Link } from '@alucio/aws-beacon-amplify/src/models';
import { useLinksState } from './LinksProvider';
import { Controller } from 'react-hook-form';
import InputComponent from 'src/components/Publishers/InputComponent';
import { Input } from '@ui-kitten/components';

const LINK_TITLE_CHARACTER_LIMIT = 150

type LinkRowProps = {
  item: Link
  isOverlay?: boolean
}

export const styles = StyleSheet.create({
  row: {
    paddingVertical: 15,
  },
  link: {
    color: colors['color-primary-500'],
    textUnderlineOffset: 2,
  },
  overlayContentContainer: {
    paddingRight: 52,
    minHeight: 23,
  },
  errorMessage: {
    color: colors['color-danger-500'],
    marginTop: 4,
  },
});

const ViewMode: React.FC<LinkRowProps> = ({ item, isOverlay }) => {
  const { setEditingId, deleteLink, editingId } = useLinksState()
  const displayValue = item.title?.trim() ? item.title?.trim() : item.url

  return (
    <DNABox fill alignY="center">
      <DNABox spacing="sm" fill childFill={0}>
        <DNABox
          appearance="col"
          spacing="sm"
          style={isOverlay && styles.overlayContentContainer}
          fill
        >
          <a
            target="_blank"
            href={item.url.startsWith('http://') || item.url.startsWith('https://') ? item.url : `https://${item.url}`}
            style={styles.link}
          >
            <DNAText testID='hub-links-title-list' status="primary" numberOfLines={2}>{displayValue}</DNAText>
          </a>
        </DNABox>
      </DNABox>
      <Iffy is={!isOverlay}>
        <DNABox spacing="sm">
          <DNAButton
            iconRight="pencil"
            appearance="ghostLink"
            status="gray"
            size="md"
            padding="none"
            onPress={() => setEditingId(item.id)}
            disabled={!!editingId}
          />
          <DNAContextMenu>
            <DNAContextMenu.Anchor>
              <DNAButton
                appearance="ghost"
                size="md"
                status="gray"
                padding="none"
                iconLeft="dots-vertical"
              />
            </DNAContextMenu.Anchor>
            <DNAContextMenu.Items>
              <DNAContextMenu.Item
                onPress={() => deleteLink(item.id)}
                icon="trash-can-outline"
                status="danger"
                iconStatus="danger"
                title="Delete"
              />
            </DNAContextMenu.Items>
          </DNAContextMenu>
        </DNABox>
      </Iffy>
    </DNABox>
  )
}

const EditMode: React.FC<LinkRowProps> = ({ item }) => {
  const { rhForm, items, isNewItem, updateFormValue, onCancelEdit } = useLinksState()
  const [url] = useState(item.url)
  const [title] = useState(item.title)
  const index = items.findIndex((link) => link.id === item.id)
  const { control } = rhForm
  const error = rhForm.formState.errors?.linksWidget?.links?.[index]
  const urlRef = useRef<Input>(null)
  const titleRef = useRef<Input>(null)

  const onCancel = useCallback(() => {
    if (!isNewItem) {
      rhForm.setValue(`linksWidget.links[${index}]` as `linksWidget.links.${number}`, { ...item, url, title })
    }
    onCancelEdit()
  }, [isNewItem, rhForm, onCancelEdit, index, item, url, title])

  useEffect(() => {
    urlRef.current && isNewItem ? urlRef.current.focus() : titleRef.current?.focus()
  }, [])

  return (
    <DNABox appearance="col" alignY="center" spacing="sm">
      <DNAText
        status="flatAlt"
      >
        Enter a URL link and link title. This will allow you to track whether it was accessed.
      </DNAText>
      <DNABox spacing="xs" fill appearance="col">
        <Controller
          name={`linksWidget.links[${index}].url` as 'name'}
          control={control}
          defaultValue={item.url}
          render={({ field: { onChange, value, ref } }) => (
            <InputComponent
              testID='hub-url-link-input'
              onChangeText={onChange}
              value={value}
              title="URL Link"
              required={true}
              removeMarginPadding
              onKeyPress={(e) => {
                if (e.nativeEvent.key === 'Enter') {
                  e.preventDefault();
                  titleRef.current?.focus();
                }
              }}
              ref={ref}
            />
          )}
        />
        {error?.url &&
          <DNAText style={styles.errorMessage}>
            {Array.isArray(error.url)
              ? error.url.map((e, idx) => <DNABox key={`url_${idx}_error_message`}>{e}</DNABox>)
              : error.url?.message}
          </DNAText>
        }
      </DNABox>
      <Controller
        name={`linksWidget.links[${index}].title` as 'name'}
        control={control}
        defaultValue={item.title}
        render={({ field: { onChange, value, ref } }) => (
          <InputComponent
            testID='hub-link-title-input'
            onChangeText={onChange}
            value={value}
            title="Link Title"
            characterLimit={LINK_TITLE_CHARACTER_LIMIT}
            removeMarginPadding
            showCharacterCounter={false}
            required={false}
            onKeyPress={({ nativeEvent }) => {
              if (nativeEvent.key === 'Enter' && rhForm.getValues(`linksWidget.links[${index}].url` as `linksWidget.links.${number}.url`)) {
                updateFormValue();
              }
            }}
            ref={ref}
          />
        )}
      />
      <DNABox alignX="end" spacing="sm">
        <DNAButton
          appearance="outline"
          status="tertiary"
          padding="sm"
          children="Cancel"
          onPress={() => onCancel()}
          testID='hub-links-cancel-btn'
        />
        <DNAButton
          padding="sm"
          children="Done"
          onPress={() => updateFormValue()}
          testID='hub-links-done-btn'
        />
      </DNABox>
    </DNABox >
  )
}

const LinkRow: React.FC<LinkRowProps> = (props) => {
  const { editingId } = useLinksState()
  const { item } = props
  const mode = item.id === editingId ? 'edit' : 'view'
  return (
    <DNABox
      alignY="center"
      appearance="col"
      spacing="md"
      fill
      style={styles.row}
    >
      { mode === 'view' ? <ViewMode {...props} /> : <EditMode {...props} /> }
    </DNABox>
  )
}

export default LinkRow
