import { DNABox, DNADivider, DNAText, Iffy } from '@alucio/lux-ui';
import React, { Fragment } from 'react'
import DNAThumbnail from '../DNA/Thumbnail/DNAThumbnail';
import { styles } from './TextSearchPanel';
import { MatchedPage } from 'src/hooks/useFileTextSearch/useFileTextSearch';
import { highlightText } from 'src/utils/searchHelpers';

interface SearchResultsItemProps {
    item: MatchedPage,
    searchQuery: string,
  }

const SearchResultsItem: React.FC<SearchResultsItemProps> = React.memo(({ item, searchQuery }) => {
  const matches = item.matches.slice(0, 3); // render only up to 3 matches
  return (
    <DNABox appearance="row">
      <DNABox style={styles.thumbnail}>
        <DNAThumbnail
          s3URL={item.s3Key}
          height={54}
          width={96}
        />
      </DNABox>
      <DNABox fill appearance="col">
        <DNABox appearance="row" spacing="between" style={{ marginTop: 8, marginRight: 16 }}>
          <DNAText testID="cpm-search-results-slide-title" bold>Slide {item.presentationPageNumber}</DNAText>
          <DNAText style={styles.subtitle}>
            {item.matches.length} match{item.matches.length > 1 ? 'es' : ''}
          </DNAText>
        </DNABox>
        {matches.map(
          (match, index) =>
            (<Fragment key={`slide-${item.presentationPageNumber}-match-${index}`}>
              <DNAText style={{ marginBottom: 8, marginRight: 16 }} numberOfLines={3}>
                {highlightText(match, searchQuery)}
              </DNAText>
              <Iffy is={index < item.matches.length - 1}>
                <DNADivider />
              </Iffy>
            </Fragment>),
        )}
      </DNABox>
    </DNABox>
  )
})
export default SearchResultsItem
