import { useSelector } from 'react-redux'
import { createSelector, Selector } from '@reduxjs/toolkit'
import {
  Tenant,
  Hub,
  // ContentShare,
} from '@alucio/aws-beacon-amplify/src/models'
import { RootState } from '../store'
import { ORMTypes, HubORM } from 'src/types/types';

export type HubORMMap = { [hubId: string]: HubORM }
export const selTenants = (state: RootState): Tenant[] => state.tenant.records
export const selHubs = (state: RootState): Hub[] => state.hub.records;
export const selHubId = (_, __, hubId: string) => hubId;

const toHubORM = (
  hub: Hub,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tenants: Tenant[],
  // contentShares: ContentShare[],
): HubORM => {
  // [TODO-2780] - Technically there should only be one tenant
  //               And it should always match, You can probably use a ! here
  //             - This would be safe
  // const [tenant] = tenants

  const hubORM: HubORM = {
    model: hub,
    type: ORMTypes.HUB,
    meta: {
      // relations: {},
    },
  }

  return hubORM
}

const hubList: Selector<RootState, HubORM[]> = createSelector(
  selTenants,
  selHubs,
  (tenants, hubs): HubORM[] => hubs.map(hub => toHubORM(hub, tenants)),
);

export const hub: Selector<RootState, HubORM | undefined> = createSelector(
  hubList,
  selHubId,
  (hubs, hubId): HubORM | undefined =>
    hubs.find(({ model }) => model.id === hubId),
)

export const useHubList = (): ReturnType<typeof hubList> =>
  useSelector((state: RootState) => hubList(state));

export const useHub = (hubId: string): ReturnType<typeof hub> =>
  useSelector((state: RootState) => hub(state, undefined, hubId));
