import {
  FieldStatus, FieldDataType, CustomFieldUsage, ControlType,
  CustomFieldDefinition,
} from '@alucio/aws-beacon-amplify/src/models';

const createdAt = new Date().toISOString();

export const internalObjectFields: CustomFieldDefinition[] = [
  {
    id: 'id',
    fieldLabel: 'ID',
    fieldName: 'id',
    status: FieldStatus.ENABLED,
    required: true,
    maxLength: 1000,
    fieldType: FieldDataType.STRING,
    usage: [CustomFieldUsage.MEETING],
    controlType: ControlType.CUSTOM,
    fieldValueDefinitions: [],
    createdAt: createdAt,
  },
  {
    id: 'externalId',
    fieldLabel: 'externalID',
    fieldName: 'externalId',
    status: FieldStatus.ENABLED,
    required: false,
    maxLength: 1000,
    fieldType: FieldDataType.STRING,
    usage: [CustomFieldUsage.MEETING],
    controlType: ControlType.CUSTOM,
    fieldValueDefinitions: [],
    createdAt: createdAt,
  },
];
