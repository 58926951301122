import React, { useState } from 'react';
import {
  Image,
  StyleSheet,
  Text,
  View,
  Pressable,
  Platform,
  StyleProp,
  ViewStyle,
  TouchableWithoutFeedback,
} from 'react-native';
import { WebLinking as Linking } from '@alucio/core'
import { Hoverable, luxColors, Disclaimer, DISCLAIMER_MODE, Iffy, DNABox } from '@alucio/lux-ui';
import { useLocation } from '../../router';
import { ROUTES } from 'src/router/routes'
import { useUserTenant } from 'src/state/redux/selector/user'
import { DisclaimerType } from 'src/screens/Profile/Profile';
import useCurrentPage from '../DNA/hooks/useCurrentPage';
import useScreenNav from 'src/components/DNA/hooks/useScreenNav';

const styles = StyleSheet.create({
  hoveredLink: {
    borderBottomWidth: 1,
    borderBottomColor: luxColors.subtitle.tertiary,
  },
  logo: {
    height: 48,
    marginLeft: 10,
    width: 48,
  },
  mainWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBottom: 10,
    paddingTop: 30,
    paddingLeft: 40,
    paddingRight: 40,
  },
  text: {
    color: luxColors.subtitle.tertiary,
    fontSize: 13,
  },
  verticalDivider: {
    height: 16,
    borderRightColor: luxColors.subtitle.tertiary,
    borderRightWidth: 1,
    marginLeft: 15,
    marginRight: 15,
  },
  whiteBackground: {
    backgroundColor: luxColors.info.primary,
  },
});

enum FOOTER_OPTION {
  PRIVACY_POLICY = 'Privacy Policy',
  TERMS_OF_USE = 'Terms of Use',
  MEDICAL_INFORMATION = 'Medical Information'
}

const BACKGROUND_COLOR = {
  [ROUTES.PUBLISHER_DASHBOARD.PATH]: luxColors.info.primary,
  [ROUTES.EMAIL_TEMPLATES.PATH]: luxColors.info.primary,
  [ROUTES.TENANTS.PATH]: luxColors.backgroundColor.secondary,
  [ROUTES.INTEGRATIONS.PATH]: luxColors.backgroundColor.secondary,
  [ROUTES.REPORTS.PATH]: luxColors.info.primary,
  [ROUTES.PUBLISHER_FOLDERS.PATH]: luxColors.info.primary,
};

interface FooterProps {
  width?: number,
}

interface FooterOptionTextProps {
  type: FOOTER_OPTION,
  onPress: (type: FOOTER_OPTION) => void;
}

const FooterOptionText:React.FC<FooterOptionTextProps> = (props) => {
  const { type, onPress } = props;
  const [hovered, setIsHovered] = useState(false);

  const toggleHover = () => {
    setIsHovered(!hovered);
  }

  const onSelect = () => {
    onPress(type);
  }

  return (
    <Hoverable onHoverIn={toggleHover} onHoverOut={toggleHover}>
      <Pressable onPress={onSelect}>
        <View>
          <Text style={[styles.text, hovered && styles.hoveredLink]}>{type.toString()}</Text>
        </View>
      </Pressable>
    </Hoverable>
  );
}

const Footer:React.FC<FooterProps> = (props) => {
  const { pathname } = useLocation();
  const [disclaimer, setDisclaimer] =
    useState<DisclaimerType>({ isVisble: false, mode: DISCLAIMER_MODE.Privacy });
  const tenant = useUserTenant();
  const currentPage = useCurrentPage()
  const screenNav = useScreenNav()
  const disableGlobalFooter = currentPage?.configOptions?.desktop?.disableGlobalFooter

  /** No need to continue instantiating if this component is disabled on current route */
  if (disableGlobalFooter) return null

  const lowerCasedPathname = pathname.toLowerCase()
  const isPublisherSearchPage = lowerCasedPathname.indexOf('/publisher-search') > -1
  const isFolderPage = lowerCasedPathname.indexOf('/publisher_folders') > -1

  /** TODO: Consider whether we are trying to make this component reusable. The nomenclature follows the DNAxxx pattern which
   * is used for reusable components, however this component is only ever utilized once in the app for a singular purpose. If
   * we're trying to make this reusable, we should consider refactoring the props to allow for the styles to be passed in as
   * the bg color variable is starting to get unnecessarily complex. Another approach to consider would be to add a theme
   * property to routes to inform single use/multi style components of styling definition on a per view basis  */
  const backgroundColor =
    /** Esists in map */
    BACKGROUND_COLOR[lowerCasedPathname] ??
    /** Otherwise if pub search or folder page */
    (isPublisherSearchPage || isFolderPage)
      ? luxColors.info.primary
      : 'transparent';
  const mainWrapperStyle: StyleProp<ViewStyle> = [
    styles.mainWrapper,
    { backgroundColor },
    { width: props.width },
  ]

  const onCloseDisclaimer = () => {
    setDisclaimer({ ...disclaimer, isVisble: false });
  }

  const onPress = async (type: FOOTER_OPTION) => {
    if (type === FOOTER_OPTION.PRIVACY_POLICY) {
      setDisclaimer({
        isVisble: true,
        mode: DISCLAIMER_MODE.Privacy,
      })
    }
    else if (type === FOOTER_OPTION.TERMS_OF_USE) {
      setDisclaimer({
        isVisble: true,
        mode: DISCLAIMER_MODE.ToU,
      })
    } else if (type === FOOTER_OPTION.MEDICAL_INFORMATION) {
      if (tenant?.medInfoURL) {
        if (Platform.OS === 'web') {
          window.open(tenant.medInfoURL, '_blank');
        } else {
          const supported = await Linking.canOpenURL(tenant.medInfoURL);

          if (supported) {
            await Linking.openURL(tenant.medInfoURL, '_self');
          }
        }
      }
    }
  }

  return (
    <>
      {disclaimer.isVisble && <Disclaimer mode={disclaimer.mode} onClose={onCloseDisclaimer} />}
      <DNABox style={mainWrapperStyle}>
        <Text style={styles.text}>Copyright {(new Date()).getFullYear()} Alucio, Inc.</Text>
        <DNABox style={styles.verticalDivider} />
        <FooterOptionText type={FOOTER_OPTION.PRIVACY_POLICY} onPress={onPress} />
        <DNABox style={styles.verticalDivider} />
        <FooterOptionText type={FOOTER_OPTION.TERMS_OF_USE} onPress={onPress} />
        <Iffy is={tenant?.medInfoURL}>
          <DNABox style={styles.verticalDivider} />
          <FooterOptionText type={FOOTER_OPTION.MEDICAL_INFORMATION} onPress={onPress} />
        </Iffy>
        <TouchableWithoutFeedback
          onLongPress={() => screenNav.goTo.GENERAL_DEBUG()}
          delayLongPress={2000}
        >
          <Image style={styles.logo} source={require('../../../assets/images/BeaconLogo.svg')} />
        </TouchableWithoutFeedback>
      </DNABox>
    </>
  );
}

export default Footer;
