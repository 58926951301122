import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAContextMenu, DNADivider, DNAText, Iffy } from '@alucio/lux-ui';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as publisherVersioningSelector from 'src/state/machines/publisherVersioning/publisherVersioning.selectors';
import { usePublisherVersioningState } from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import { ScheduleDocumentIcon } from 'src/components/DNA/GridList/common';

export const S = StyleSheet.create({
  readOnlyFooterContainer: {
    padding: 16,
  },
  editModeFooterContainer: {
    margin: 12,
  },
  contextMenu: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginLeft: 1,
  },
})

const FooterReadOnlyMode: React.FC = () => {
  const { service, currentDocumentVersionORM, handleCancelSchedulePublish } = usePublisherVersioningState();
  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.isScheduledViewMode,
    ),
  )
  if (!currentDocumentVersionORM) return null;

  const scheduledPublish = currentDocumentVersionORM.meta.schedule.publish.scheduledAt;

  return (
    <>
      <DNADivider />
      <DNABox spacing="between" style={S.readOnlyFooterContainer}>
        {/* READ ONLY MESSAGING */}
        <DNABox spacing="xs">
          <DNAText bold status="warning">
            This is view-only. You can only make edits when versioning up.
          </DNAText>
        </DNABox>
        {/* SCHEDULING MESSAGING */}
        <Iffy is={cond.isScheduledViewMode}>
          <DNABox spacing="sm" alignY="center">
            <ScheduleDocumentIcon mode="TEXT" scheduledPublish={scheduledPublish}/>
            <DNAButton
              status="tertiary"
              appearance="outline"
              onPress={handleCancelSchedulePublish}
            >
              Cancel scheduled publish
            </DNAButton>
          </DNABox>
        </Iffy>
      </DNABox>
    </>
  )
}

const FooterEditMode: React.FC = () => {
  const {
    service,
    navigateToPreviousTab,
    navigateToNextTab,
    handlePublish,
    handleSchedulePublish,
  } = usePublisherVersioningState();
  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.availabletabs,
      publisherVersioningSelector.isInDraftingState,
      publisherVersioningSelector.selectedTabIndex,
      publisherVersioningSelector.isPublishing,
    ),
  )

  const isFirstTab = useMemo(() => cond.selectedTabIndex === 0, [cond.selectedTabIndex],)
  const isLastTab = useMemo(
    () => cond.selectedTabIndex === cond.availabletabs.length - 1,
    [cond.selectedTabIndex, cond.availabletabs],
  )

  return (
    <>
      <DNADivider />
      <DNABox spacing="between" style={S.editModeFooterContainer}>
        <DNABox>
          <Iffy is={!isFirstTab}>
            <DNAButton onPress={navigateToPreviousTab} disabled={cond.isPublishing}>
              Back
            </DNAButton>
          </Iffy>
        </DNABox>

        <DNABox>
          <Iffy is={!isLastTab}>
            <DNAButton
              onPress={navigateToNextTab}
            >
              Next
            </DNAButton>
          </Iffy>
          <Iffy is={isLastTab}>
            <DNABox spacing="md" alignY="center">
              <DNABox>
                <DNAButton
                  testID="publish-button"
                  disabled={cond.isPublishing}
                  onPress={handlePublish}
                >
                  Publish
                </DNAButton>
                <DNAContextMenu>
                  <DNAContextMenu.Anchor>
                    <DNAButton
                      onPress={() => { }}
                      iconLeft="chevron-down"
                      disabled={cond.isPublishing}
                      padding="sm"
                      style={S.contextMenu}
                    />
                  </DNAContextMenu.Anchor>
                  <DNAContextMenu.Items>
                    <DNAContextMenu.Item
                      collapseOnPress
                      delay={100}
                      title="Schedule publish"
                      onPress={handleSchedulePublish}
                    />
                  </DNAContextMenu.Items>
                </DNAContextMenu>
              </DNABox>
            </DNABox>
          </Iffy>
        </DNABox>
      </DNABox>
    </>
  )
}

const Footer: React.FC = () => {
  const { service, isCriticalError } = usePublisherVersioningState();
  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.isInDraftingState,
    ),
  )

  if (!cond.isInDraftingState) return <FooterReadOnlyMode />
  else if (cond.isInDraftingState && !isCriticalError) return <FooterEditMode />
  else return null
}

export default Footer
