import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  createContext,
  useContext,
  ReactNode,
} from 'react';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'src/state/redux';
import {
  HubSectionItemStatus,
  SharedFileHubSetting,
  AssociatedFileType,
  DocumentStatus,
  HubSharedFileContentType,
  HubSharedAssociatedFile,
} from '@alucio/aws-beacon-amplify/src/models';
import { AssociatedFileORM, HubORM, isDocumentVersionORM } from 'src/types/types'
import { EditHubRHForm } from 'src/screens/Hubs/EditHub/useHubForm';
import { ContentSearchResult } from 'src/components/ContentSearchBar/ContentSearchBar';
import { ModifiedHubSection } from 'src/state/context/Hubs/HubsStateProvider';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import DNACommonConfirmation from 'src/components/DNA/Modal/DNACommonConfirmation';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { useAllDocumentVersionMap } from 'src/state/redux/selector/document';
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
import { WritableDraft } from 'immer/dist/internal';
import { getUpdatedSharedFiles } from 'src/state/machines/notation/notationUtils';
import { isDocVerHidden } from 'src/screens/Hubs/EditHub/util';
import * as logger from 'src/utils/logger';

const ACTIVE_DOCUMENT_LIMIT = 50
const ERROR_MESSAGES = {
  HAD_REACHED_MAX: `You have reached the maximum limit of ${ACTIVE_DOCUMENT_LIMIT} documents. Please remove documents to continue.`,
  WILL_EXCEED_MAX: `The document(s) you are trying to add will exceed the maximum limit of ${ACTIVE_DOCUMENT_LIMIT} documents. Please remove documents to continue.`,
}
const CONFIRMATION_MODAL_DETAILS = {
  MAIN_DOCUMENT: {
    title: "Remove file?",
    descriptionText: "All callouts for this file and associated files will also be removed.",
  },
  ASSOCIATED_CONTENT: {
    title: "Remove associated file?",
    descriptionText: "All callouts for this file will be removed.",
  },
}

export interface AssociatedFileDetails extends HubSharedAssociatedFile {
  isRequired?: boolean
  parentId: string
  associatedFileORM: AssociatedFileORM
}

export interface RowProps {
  isLastItem: boolean
  handleDeleteItem: (contentId: string, associatedFileDetails?: AssociatedFileDetails) => void
}

export interface SharedFilesProviderProps {
  children: ReactNode;
  rhForm: EditHubRHForm;
  widget: ModifiedHubSection;
}

export interface SharedFilesContextType {
  hubORM?: HubORM
  rhForm: EditHubRHForm
  widget: ModifiedHubSection
  items: SharedFileHubSetting[]
  setItems: React.Dispatch<React.SetStateAction<SharedFileHubSetting[]>>
  activeItems: SharedFileHubSetting[]
  selectedIds: string[]
  hasReachedMaxNum: boolean
  errorMsg: string[]
  isSearchBarVisible: boolean
  setIsSearchBarVisible: React.Dispatch<React.SetStateAction<boolean>>
  handleShowSearchBar: () => void
  handleSelectItem: (item: ContentSearchResult) => void
  handleDeleteItem: (contentId: string, associatedFileDetails?: AssociatedFileDetails) => void
  handleDeleteCallout: (contentId: string, notationId: string) => void
  handleOpenContentPreview: (
    hubSharedFilesState: SharedFilesContextType,
    contentType: HubSharedFileContentType,
    contentId: string,
    pageNumber?: number,
    currentActiveNotationId?: string,
  ) => void,
}

export const SharedFilesContext = createContext<SharedFilesContextType>(undefined!);

export const SharedFilesProvider: React.FC<SharedFilesProviderProps> = ({ children, rhForm, widget }) => {
  const { hubORM } = useHubsState()
  const { setValue, getValues } = rhForm
  const dispatch = useDispatch()

  const allDocumentMap = useAllDocumentVersionMap()
  const initialItems = useMemo(() => {
    return widget.sharedFiles
      ? [...widget.sharedFiles].sort((a, b) => a.title.localeCompare(b.title, 'en', { numeric: true }))
      : []
  }, [widget])

  const [isSearchBarVisible, setIsSearchBarVisible] = useState<boolean>(false)
  const [items, setItems] = useState<SharedFileHubSetting[]>(initialItems)
  const [errorMsg, setErrorMsg] = useState<string[]>([])

  const activeItems = useMemo(() => {
    return items
      .filter(item => item.status === HubSectionItemStatus.ACTIVE && !isDocVerHidden(allDocumentMap, item.contentId))
      .map(sharedFile => {
        const associatedFiles = sharedFile.documentVersionSettings?.associatedFiles?.filter(associatedFile => {
          if (associatedFile.versionId) return !isDocVerHidden(allDocumentMap, associatedFile.versionId)
          else return true
        })
        return {
          ...sharedFile,
          documentVersionSettings: { associatedFiles },
        }
      })
      .sort((a, b) => a.title.localeCompare(b.title, 'en', { numeric: true }))
  }, [items])

  const totalNumOfDoc = useMemo(() => activeItems.reduce((accu, curr) => {
    const numOfAssociatedFiles = curr.documentVersionSettings?.associatedFiles?.length ?? 0
    return accu + 1 + numOfAssociatedFiles
  }, 0), [activeItems])

  const hasReachedMaxNum = useMemo(() => totalNumOfDoc >= ACTIVE_DOCUMENT_LIMIT, [totalNumOfDoc])

  const selectedIds = useMemo(() => {
    return activeItems.map(sharedFile => sharedFile.contentId)
  }, [activeItems])

  useEffect(() => {
    if (totalNumOfDoc < ACTIVE_DOCUMENT_LIMIT) {
      setErrorMsg(p => {
        const errMsgIdx = p.findIndex(errMsg => errMsg === ERROR_MESSAGES.HAD_REACHED_MAX)
        if (errMsgIdx !== -1) {
          const errors = [...p]
          errors.splice(errMsgIdx, 1)
          return errors
        }
        else return p
      })
    } else {
      setErrorMsg(p => {
        if (p.includes(ERROR_MESSAGES.HAD_REACHED_MAX)) return p
        else return [...p, ERROR_MESSAGES.HAD_REACHED_MAX]
      })
    }
  }, [activeItems])

  // *** Functions ***
  const handleShowSearchBar = () => setIsSearchBarVisible(true)

  const handleSelectItem = useCallback((item: ContentSearchResult) => {
    const widgetFormValue = getValues('sharedFilesWidget')
    if (!widgetFormValue) return
    // Check if document already existed (could be non-active)
    const existedDocIndex = widgetFormValue.sharedFiles?.findIndex(sharedFile => sharedFile.contentId === item.id) ?? -1
    const docVerORM = item.orm
    if (isDocumentVersionORM(docVerORM)) {
      const associatedFiles = docVerORM.relations.associatedFiles
        .filter(associatedFile => {
          return (
              associatedFile.model.type === AssociatedFileType.ATTACHED_FILE &&
              associatedFile.meta.canBeSharedByMSL
            ) ||
            (
              associatedFile.model.type === AssociatedFileType.DOCUMENT &&
              associatedFile.meta.canBeSharedByMSL &&
              associatedFile.file?.['status'] === DocumentStatus.PUBLISHED
            )
        })
        .map(associatedFile => {
          let versionId: string | undefined
          if (associatedFile.model.type === AssociatedFileType.DOCUMENT) {
            versionId = associatedFile.relations.latestUsableDocumentVersion?.id ?? undefined
          }
          return {
            associatedFileId: associatedFile.model.id,
            versionId,
          }
        })

      const newItem: SharedFileHubSetting = {
        id: uuid(),
        contentId: item.id,
        title: item.title,
        contentType: HubSharedFileContentType.DOCUMENT_VERSION,
        documentVersionSettings: associatedFiles.length ? { associatedFiles } : undefined,
        status: HubSectionItemStatus.ACTIVE,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      }
      // check if adding this document (and associated document(s)) will exceed maximum limit
      const numOfDocsToAdd = 1 + associatedFiles.length
      if ((totalNumOfDoc + numOfDocsToAdd) > ACTIVE_DOCUMENT_LIMIT) {
        setErrorMsg([ERROR_MESSAGES.WILL_EXCEED_MAX])
        setTimeout(() => {
          setErrorMsg([])
        }, 5000);
        return
      }

      const newSharedFiles = widgetFormValue.sharedFiles ? [...widgetFormValue.sharedFiles] : []
      if (existedDocIndex !== -1) newSharedFiles.splice(existedDocIndex, 1, {...newItem, createdAt: newSharedFiles[existedDocIndex].createdAt })
      else newSharedFiles.push(newItem)
      setValue(
        'sharedFilesWidget',
        { ...widgetFormValue, sharedFiles: newSharedFiles, updatedAt: new Date().toISOString() },
        { shouldDirty: true }
      )
      setItems(newSharedFiles)
    }
    analytics?.track('HUB_DOCUMENT_ADD', {
      action: 'DOCUMENT_ADD',
      category: 'HUB',
    });
  }, [activeItems, getValues, setValue, totalNumOfDoc])

  const deleteMainFile = useCallback((contentId: string) => {
    logger.hub.widgets.sharedFiles.debug(`Attempt to delete a main file, contentId: ${contentId}`)
    const widgetFormValue = getValues('sharedFilesWidget')
    const sharedFilesFormValue = widgetFormValue?.sharedFiles
    if (!sharedFilesFormValue) return
    const newSharedFiles = sharedFilesFormValue.slice()
    const targetIndex = newSharedFiles.findIndex(sharedFile => sharedFile.contentId === contentId)
    if (targetIndex !== -1) {
      const targetSharedFile = newSharedFiles[targetIndex]
      newSharedFiles.splice(targetIndex, 1, {
        ...targetSharedFile,
        status: HubSectionItemStatus.DELETED,
        updatedAt: new Date().toISOString()
      })
      setValue('sharedFilesWidget',
        { ...widgetFormValue, sharedFiles: newSharedFiles, updatedAt: new Date().toISOString() },
        { shouldDirty: true })
      setItems(newSharedFiles)
    }
  }, [getValues, setValue])

  const deleteAssociatedFile = useCallback((associatedFileId: string, parentId?: string) => {
    logger.hub.widgets.sharedFiles.debug(`Attempt to delete an associated file, associatedFileId: ${associatedFileId}`)
    const widgetFormValue = getValues('sharedFilesWidget')
    const sharedFilesFormValue = widgetFormValue?.sharedFiles
    if (!sharedFilesFormValue) return
    let newSharedFiles = sharedFilesFormValue.slice()

    if (parentId) {
      const parentIndex = newSharedFiles.findIndex(sharedFile => sharedFile.id === parentId)
      const partent = newSharedFiles[parentIndex]
      if (parentIndex !== -1) {
        const previousAssociatedFiles = partent.documentVersionSettings?.associatedFiles?.slice() || []
        const targetIndex = previousAssociatedFiles.findIndex(hubSharedAssociatedFile => {
          return hubSharedAssociatedFile.associatedFileId === associatedFileId
        })
        if (targetIndex !== -1) {
          const newAssociatedFiles = [...previousAssociatedFiles]
          newAssociatedFiles.splice(targetIndex, 1)
          newSharedFiles.splice(parentIndex, 1, {
            ...newSharedFiles[parentIndex],
            documentVersionSettings: {
              associatedFiles: newAssociatedFiles,
            },
            updatedAt: new Date().toISOString(),
          })
        }
      }
    } else {
      // If no parentId, we want to delete all files that matches associatedFileId
      newSharedFiles = sharedFilesFormValue.map(shareFile => {
        const associatedFiles = shareFile.documentVersionSettings?.associatedFiles?.filter(hubSharedAssociatedFile => {
          return hubSharedAssociatedFile.versionId !== associatedFileId
        })
        return {
          ...shareFile,
          documentVersionSettings: { associatedFiles }
        }
      })
    }
    setValue('sharedFilesWidget',
      { ...widgetFormValue, sharedFiles: newSharedFiles, updatedAt: new Date().toISOString() },
      { shouldDirty: true })
    setItems(newSharedFiles)
  }, [getValues, setValue])

  const removeNonShareableItems = useCallback(() => {
    activeItems.forEach(sharedFile => {
      const isMainFileShareable = !isDocVerHidden(allDocumentMap, sharedFile.contentId)
      if (isMainFileShareable === false) deleteMainFile(sharedFile.contentId)
      else {
        // Checking associated documents...
        sharedFile.documentVersionSettings?.associatedFiles?.forEach(associatedFile => {
          if (associatedFile.versionId) {
            const isAssociatedDocShareable = !isDocVerHidden(allDocumentMap, associatedFile.versionId)
            if (isAssociatedDocShareable === false) deleteAssociatedFile(associatedFile.versionId)
          }
        })

      }
    })
  }, [allDocumentMap, activeItems])

  const handleDeleteItem = useCallback((contentId: string, associatedContent?: AssociatedFileDetails) => {
    const modalDetails = associatedContent ? CONFIRMATION_MODAL_DETAILS.ASSOCIATED_CONTENT : CONFIRMATION_MODAL_DETAILS.MAIN_DOCUMENT
    dispatch(
      DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: () => (<DNACommonConfirmation
          status="danger"
          cancelText="Cancel"
          confirmActionText="Remove"
          title={modalDetails.title}
          descriptionText={modalDetails.descriptionText}
          onConfirmAction={() => {
            if (associatedContent) deleteAssociatedFile(associatedContent.associatedFileId, associatedContent.parentId)
            else deleteMainFile(contentId)
          }}
        />),
      })
    );
  }, [getValues, setValue])

  const handleDeleteCallout = useCallback((contentId: string, notationId: string) => {
    const widgetFormValue = getValues('sharedFilesWidget')
    const sharedFilesFormValue = widgetFormValue?.sharedFiles
    if (!sharedFilesFormValue) return
    const updatedSharedFiles = getUpdatedSharedFiles(
      sharedFilesFormValue,
      contentId,
      { type: 'delete', notationId },
    )
    setValue('sharedFilesWidget',
      { ...widgetFormValue, sharedFiles: updatedSharedFiles, updatedAt: new Date().toISOString() },
      { shouldDirty: true })
    setItems(updatedSharedFiles)
  }, [getValues, setValue])

  const handleOpenContentPreview = useCallback((
    hubSharedFilesState: SharedFilesContextType,
    contentType: HubSharedFileContentType,
    contentId: string,
    pageNumber?: number,
    currentActiveNotationId?: string,
  ) => {
    if (!hubORM) return

    const content = allDocumentMap[contentId]

    dispatch(
      contentPreviewModalActions.setModalVisibility({
        documentVersionId: contentId,
        content,
        isOpen: true,
        hubId: hubORM.model.id,
        pageNumber,
        hubShareFileContext: hubSharedFilesState as WritableDraft<SharedFilesContextType>,
        currentActiveNotationId,
      })
    )
  }, [hubORM, allDocumentMap])

  const value: SharedFilesContextType = {
    hubORM,
    rhForm,
    widget,
    items,
    setItems,
    activeItems,
    selectedIds,
    hasReachedMaxNum,
    errorMsg,
    isSearchBarVisible,
    setIsSearchBarVisible,
    handleShowSearchBar,
    handleSelectItem,
    handleDeleteItem,
    handleDeleteCallout,
    handleOpenContentPreview,
  }

  return (
    <SharedFilesContext.Provider value={value}>
      {children}
    </SharedFilesContext.Provider>
  )
}

export const useSharedFilesState = () => {
  const context = useContext(SharedFilesContext)
  if (!context) {
    const errorText = 'useSharedFilesState must be used within the SharedFilesProvider'
    logger.hub.widgets.sharedFiles.error(errorText)
    throw new Error(errorText)
  }
  return context;
}
