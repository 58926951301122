import React from 'react'
import { DNABox, DNACard, DNAText, Iffy, useTheme, util } from '@alucio/lux-ui'
import { FolderORM } from 'src/types/types'
import Folder from 'src/components/DNA/Folder/DNAFolder'
import PinButton from 'src/components/DNA/Folder/DNAFolderPinButton'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'

export const DNACustomDeckFolderRow: DNARowsComponent<FolderORM> = (props) => {
  const {
    ORM: folderORM,
    onPress,
  } = props

  const theme = useTheme()

  const isPinned = folderORM.model.isPinned
  const allowPin = folderORM.relations.parentFolderORM === null // Only root folders can be pinned

  const allStyle = {
    paddingHorizontal: theme['row-padding-horizontal'],
    paddingVertical: theme['row-padding-vertical'],
  }

  return (
    <DNACard
      appearance="flat"
      style={util.mergeStyles(props, allStyle)}
      interactive="pressable"
      onPress={() => onPress?.(folderORM)}
    >
      <DNABox
        testID="folder-row"
        childFill={0}
        spacing="between"
        alignY="center"
      >
        <DNABox spacing="lg" alignY="center" childFill={1} style={{ marginLeft: 7 }}>
          <Folder folder={folderORM} />
          <DNAText
            testID="folder-item-title"
            b1
            style={{ marginLeft: 9 }}
            numberOfLines={2}
          >
            { folderORM.model.name }
          </DNAText>
        </DNABox>
        <DNABox alignY="center">
          <Iffy is={!folderORM.meta.isSharedWithTheUser}>
            <PinButton
              isHidden={!isPinned || !allowPin}
              interactive={false}
            />
          </Iffy>
        </DNABox>
      </DNABox>
    </DNACard>
  )
}

export default DNACustomDeckFolderRow
