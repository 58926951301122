import React, { useState } from 'react'
import { FolderORM } from 'src/types/types'
import { useAppSettings } from 'src/state/context/AppSettings';
import { useDNAFolderActions } from 'src/components/DNA/Folder/DNAFolder.actions'

import {
  DNABox,
  DNACard,
  DNAButton,
  DNAText,
  Iffy,
} from '@alucio/lux-ui'
import { DNACardComponent } from 'src/components/DNA/Grid/Cards/types'

import Folder from 'src/components/DNA/Folder/DNAFolder'
import DNAFolderContextMenu from 'src/components/DNA/Folder/DNAFolderContextMenu'
import PinButton from 'src/components/DNA/Folder/DNAFolderPinButton'
import DNADocumentVersionUpdateButton from 'src/components/DNA/Document/DNADocumentVersionUpdateButton'
import { isTablet } from 'react-device-detect';
import { CUSTOM_DECK_CARD_MAX_HEIGHT } from './constants';
import { FolderStatus } from '@alucio/aws-beacon-amplify/src/models';

export interface FolderCardProps {
  onPin?: () => void,
}

export const FolderCard: DNACardComponent<FolderORM, FolderCardProps> = (props) => {
  const { ORM: folderORM, onPress } = props
  const [showPin, setShowPin] = useState<boolean>()
  const { deviceMode } = useAppSettings();
  const isPinned = folderORM.model.isPinned
  const hideEllipsis = folderORM.model.status === FolderStatus.ARCHIVED && folderORM.meta.isSharedWithTheUser;
  const folderActions = useDNAFolderActions()

  return (
    <DNABox alignX="start" style={{ minWidth: 272, height: CUSTOM_DECK_CARD_MAX_HEIGHT }}>
      <DNACard
        interactive="pressable"
        style={{
          minWidth: 232,
          minHeight: 140,
          overflow: 'visible',
          zIndex: -1,
        }}
        // [TODO] Consider adding a default?
        onPress={() => {
          onPress?.(folderORM);
        }}
        // Ignoring these events in tablet because they will also fire on tap
        onMouseEnter={() => {
          if (isTablet) return;
          setShowPin(true);
        }}
        onMouseLeave={() => {
          if (isTablet) return;
          setShowPin(false);
        }}
      >
        <DNABox fill appearance="col" spacing="between" style={{ padding: 12 }}>
          {/* Pin Button & Notification Icons */}
          <DNABox
            spacing="between"
            alignY="center"
            style={ isTablet ? { height:46 } : { height:34 }}
          >
            <Iffy is={!folderORM.meta.isSharedWithTheUser}>
              <PinButton
                onPin={folderActions.pin(folderORM)}
                isHidden={
                  (!showPin && !isPinned) || !!folderORM.relations.parentFolderORM
                }
              />
            </Iffy>
            {/* Update Version Notification */}
            <DNADocumentVersionUpdateButton orm={folderORM} />
          </DNABox>

          {/* Folder */}
          <DNABox
            appearance="col"
            alignX="center"
            alignY="center"
            fit="fill"
            spacing="md"
          >
            <Folder folder={folderORM} width={72} height={72} />
            <DNAText
              b1
              numberOfLines={2}
              style={{ maxWidth: 200, overflow: 'hidden' }}
              testID="folder-item-title"
            >
              {folderORM.model.name}
            </DNAText>
          </DNABox>
          {/* Context Menu */}
          <DNABox style={{ alignSelf: 'flex-end' }}>
            <Iffy is={!hideEllipsis}>
              <DNAFolderContextMenu folderORM={folderORM}>
                <DNAButton
                  appearance={deviceMode === 'tablet' ? 'ghostLink' : 'ghost'}
                  status="gray"
                  size={deviceMode === 'tablet' ? 'lg' : 'md'}
                  padding={deviceMode === 'tablet' ? 'md' : 'sm'}
                  iconLeft="dots-vertical"
                />
              </DNAFolderContextMenu>
            </Iffy>
          </DNABox>
        </DNABox>
      </DNACard>
    </DNABox>
  );
}

export default FolderCard
