import React from 'react'
import { ScrollView } from 'react-native'
import { S } from 'src/screens/Publishers/Versioning/VersioningPanel/VersioningPanel'

import { DNABox } from '@alucio/lux-ui'

import AssociatedFiles from './AssociatedFiles';

const DocumentSettings: React.FC = () => {
  return (
    <ScrollView style={S.tabContentContainer}>
      <DNABox
        fill
        appearance="col"
        spacing="lg"
      >
        {/* ASSOCIATED FILES */}
        <AssociatedFiles />
      </DNABox>
    </ScrollView>
  )
}

export default DocumentSettings
