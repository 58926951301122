import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAText, Iffy, InformationMessage, util } from '@alucio/lux-ui/src';
import { arrayMove } from '@dnd-kit/sortable';
import { DragStartEvent, DragEndEvent } from '@dnd-kit/core';
import {
  WidgetContentCardProps,
  WidgetCardHeader,
  WidgetCardBody,
  WidgetContextMenu,
  widgetContentCardStyles,
} from 'src/screens/Hubs/EditHub/Widgets/WidgetContentCard';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { HubSectionType, Link } from '@alucio/aws-beacon-amplify/src/models';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import DNARearranger, { ModifiersListEnum } from 'src/components/DnD/DNARearranger';
import { LinksProvider, useLinksState } from 'src/screens/Hubs/EditHub/Widgets/LinksComponents/LinksProvider';
import LinkRow from 'src/screens/Hubs/EditHub/Widgets/LinksComponents/LinkRow';

const DNAREARRANGER_MODIFIERS = [ModifiersListEnum.restrictToVerticalAxis]

const styles = StyleSheet.create({
  ItemContainer: {
    borderBottomWidth: 1, 
    borderBottomColor: colors['color-gray-80'],
  },
});

const Links: React.FC = () => {
  const { showFullSizeBtn } = useHubsState()
  const {
    widget,
    items,
    errorMsg,
    setErrorMsg,
    activeItems,
    editingId,
    updateFormValue,
    addLink,
  } = useLinksState()

  const [activeItem, setActiveItem] = useState<Link>()
  const [showAllItems, setShowAllItems] = useState<boolean>(false)

  const visibleItems = useMemo(() => {
    return showAllItems ? activeItems : activeItems.slice(0, 5)
  }, [activeItems, showAllItems])

  const itemIds = useMemo(() => visibleItems.map(link => link.id), [items])

  const handleDragStart = useCallback((event: DragStartEvent) => {
    const { active } = event
    if (active.id) {
      const activeItemId = active.id
      const activeItem = visibleItems.find(item => item.id === activeItemId)
      setActiveItem(activeItem)
    }
  }, [visibleItems])

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event
    if (active.id !== over?.id && activeItems.length > 1) {
      const oldIndex = items.findIndex((item) => item.id === active.id)
      const newIndex = items.findIndex((item) => item.id === over?.id)
      const newArr = arrayMove(items, oldIndex, newIndex)
      const newOrder = newArr.map((item, index) => {
        return { ...item, order: index }
      })
      updateFormValue(newOrder)
    }
    setActiveItem(undefined)
  }, [items])

  const rightElement = useMemo(() => (
    <DNABox alignY="center" spacing="sm">
      <DNAButton
        iconLeft="plus"
        appearance="outline"
        status="tertiary"
        onPress={addLink}
        testID="hub-add-link-btn"
        children={showFullSizeBtn ? 'Add link' : undefined}
        style={util.mergeStyles(
          undefined,
          [widgetContentCardStyles.contextMenuAnchorBtn, !showFullSizeBtn],
        )}
      />
      <WidgetContextMenu widgetType={HubSectionType.LINKS} id={widget.id} />
    </DNABox>
  ), [addLink, showFullSizeBtn])

  return (
    <>
      <WidgetCardHeader
        widget={widget}
        rightElement={rightElement}
      />
      <WidgetCardBody>
        <Iffy is={!visibleItems.length}>
          <DNAText status="flatAlt" >Add links to see them appear here</DNAText>
        </Iffy>
        {/* Error Message */}
        <Iffy is={errorMsg.length}>
          <InformationMessage
            variance="danger"
            text={errorMsg.join(', ')}
            actionButtonFunction={() => setErrorMsg([])}
            actionButtonText="Close"
          />
        </Iffy>
        <Iffy is={activeItems.length}>
          <DNARearranger
            collisionDetection={'closestCenter'}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            modifiers={DNAREARRANGER_MODIFIERS}
            items={itemIds}
            strategy={'verticalListSortingStrategy'}
            overlay={activeItem && (
              <DNARearranger.SortableItem
                id={activeItem.id}
                itemId={activeItem.id}
                containerId="linkContainer"
                handle
                boxContainerStyle={styles.ItemContainer}
              >
                <LinkRow item={activeItem} isOverlay={true}/>
              </DNARearranger.SortableItem>
            )}
          >
            {
              visibleItems.map(item => {
                return (
                  <React.Fragment key={item.id}>
                    <DNARearranger.SortableItem
                      id={item.id}
                      itemId={item.id}
                      containerId="links-base"
                      disabled={editingId === item.id}
                      handle
                      boxContainerStyle={styles.ItemContainer}
                    >
                      <LinkRow item={item} />
                    </DNARearranger.SortableItem>
                  </React.Fragment>
                )
              },
              )
            }
          </DNARearranger>
          <Iffy is={activeItems.length > 5}>
            <DNAButton
              appearance="outline"
              status="tertiary"
              onPress={() => setShowAllItems(p => !p)}
            >
              {showAllItems ? 'Show Less' : 'Show All'}
            </DNAButton>
          </Iffy>
        </Iffy>
      </WidgetCardBody>
    </>
  )
}

const LinksWrapper: React.FC<WidgetContentCardProps> = (props) => {
  return (
    <LinksProvider {...props}>
      <Links />
    </LinksProvider>
  )
}

export default LinksWrapper
