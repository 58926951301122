import debounce from 'lodash/debounce'
import { useCallback, useEffect, useState } from 'react'

interface UseDeboucedStateConfig<T> {
    /** Initial value to use for the state. This prop dictates the typing of
     * all states and functions working with the value and thus is required). */
    initialValue: T,
    /** Fire the trigger prior to debouncing. */
    leading?:boolean,
    /** Fire the trigger uppon conclusion of debouncing. */
    trailing?:boolean
    /** Ammount of time to wait before allowing the trigger to fire. */
    wait?:number
    /**
     * Allows us to bypass the debounce behavior unless a condition is met.
     *
     * Note, the passed argument MUST match the type used for the initialValue
     */
    triggerCondition?: boolean | ((value:T) => boolean)
}

const useDebouncedState = <T, >(config:UseDeboucedStateConfig<T>) => {
  const {
    initialValue,
    leading = true,
    trailing = true,
    wait = 500,
    triggerCondition,
  } = config

  const [activeValue, setActiveValue] = useState<T>(initialValue)
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue)
  const [debouncingActive, setDebouncingActive] = useState<boolean>()

  const endDebounce = (value:T) => {
    setDebouncedValue(value);
    setDebouncingActive(false)
  }

  const startDebounce = useCallback(debounce((value:T) => {
    endDebounce(value)
  }, wait, { leading:leading, trailing:trailing }), [])

  const handleActiveValueUpdate = (value:T) => {
    setDebouncingActive(true)
    if (typeof triggerCondition !== 'undefined' ) {
      /** Conditional Bypass */
      typeof triggerCondition === 'boolean'
        ? triggerCondition
          ? startDebounce(value)
          : endDebounce(value)
        : triggerCondition(activeValue)
          ? startDebounce(value)
          : endDebounce(value)
    } else {
      /** Regular debounce */
      startDebounce(value)
    }
  }

  useEffect(() => handleActiveValueUpdate(activeValue), [activeValue])

  return {
    activeValue,
    setActiveValue,
    debouncedValue,
    setDebouncedValue,
    debouncingActive,
    setDebouncingActive,
  }
}

export default useDebouncedState
