import React, { useEffect, useMemo } from 'react'
import { StyleSheet, View } from 'react-native';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { useReport } from './ReportProvider';
import { DNABox, Iffy } from '@alucio/lux-ui';
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { useTenantCustomFields } from 'src/state/redux/selector/tenant';
import { CustomFieldUsage } from '@alucio/aws-beacon-amplify/src/models';
import { DashboardContentOptions, FrameOptions, SheetOptions } from 'amazon-quicksight-embedding-sdk';
import capitalize from 'lodash/capitalize';

const MAX_CUSTOM_FIELDS = 5;
const CONTAINER_HEIGHT = '700px';
const OFFSET = new Date().getTimezoneOffset() / -60;

const styles = StyleSheet.create({
  loadingContainer: {
    borderWidth: 1,
    borderColor: '#EBEBEB',
    borderRadius: 4,
  },
});

export const EmbeddedReport = () => {
  const {
    isLoadedReportsList, isLoadingReport, setIsLoadingReport,
    setEmbeddableObject, selectedDashboard,
    isSharingBlocked, isDownloadingBlocked,
    selectedReport, onSelectedSheetChange,
  } = useReport();

  const {
    createEmbeddingContext,
  } = QuickSightEmbedding;

  const initializeEmbeddingContext = async () => {
    const context = await createEmbeddingContext();
    return context;
  };

  const reportUrl = selectedDashboard?.dashboardUrl;
  const enableMeetingReactions = useFeatureFlags('enableMeetingReactions');
  const sheet = reportUrl === selectedDashboard?.dashboardUrl && selectedReport
    ? selectedDashboard?.dashboardSheets.find(sheet => sheet.name.includes(selectedReport.name))
    : undefined
  const filter = useMemo(
    () => ({ usages: { internalUsages: [CustomFieldUsage.CONTENT_SHARE] } }),
    [],
  )
  const shareCustomFields = useTenantCustomFields(filter)
  const shareCustomFieldsReportingNames: Record<string, string> = useMemo(() => {
    const fields = {};
    for (let i = 0; i < MAX_CUSTOM_FIELDS; i++) {
      fields[`shareCustomField${i + 1}`] = shareCustomFields[i]?.reportingName ?? '-';
      fields[`headerField${i + 1}`] = shareCustomFields[i]?.fieldLabel
        ? capitalize(shareCustomFields[i].fieldLabel)
        : String.fromCharCode(127); // Display CharCode(127) as column title if no custom field
    }
    return fields;
  }, [shareCustomFields]);

  useEffect(() => {
    const setupEmbeddingContext = async () => {
      const embeddingContext = await initializeEmbeddingContext();
      if (reportUrl) {
        const containerDiv = document.getElementById('embedded-report-id')
        if (!containerDiv) return
        const frameOptions: FrameOptions = {
          url: reportUrl,
          container: containerDiv,
          resizeHeightOnSizeChangedEvent: true,
          height: CONTAINER_HEIGHT,
        };

        const sheetOptions : SheetOptions = {
          singleSheet: true,
          emitSizeChangedEventOnSheetChange: true,
        }

        if (sheet) sheetOptions.initialSheetId = sheet.sheetId

        const contentOptions: DashboardContentOptions = {
          sheetOptions,
          attributionOptions: {
            overlayContent: true,
          },
          parameters: [
            {
              Name: 'hideSentimentsCount',
              Values: [enableMeetingReactions ? 'NO' : 'YES'],
            },
            {
              Name: 'hideSharedReports',
              Values: [isSharingBlocked ? 'YES' : 'NO'],
            },
            {
              Name: 'hideDownloadReport',
              Values: [isDownloadingBlocked ? 'YES' : 'NO'],
            },
            {
              Name: 'localDatetimeOffset',
              Values: [OFFSET.toString()],
            },
            ...Object.entries(shareCustomFieldsReportingNames).map(([key, value]) => ({
              Name: key,
              Values: [value],
            })),
          ],
          onMessage: async (messageEvent) => {
            switch (messageEvent.eventName) {
              case 'SELECTED_SHEET_CHANGED': {
                onSelectedSheetChange(messageEvent.message?.selectedSheet)
                break;
              }
            }
          },
        }

        const embedExperience = await embeddingContext.embedDashboard(frameOptions, contentOptions);
        if (embedExperience) {
          setEmbeddableObject(embedExperience)
        }
        setIsLoadingReport(false)
      }
    };
    setupEmbeddingContext();
  }, [reportUrl, isLoadedReportsList]);

  const emptyVariant = (!isLoadedReportsList || isLoadingReport)
    ? EmptyVariant.AnalyticsLoading : EmptyVariant.AnalyticsEmpty;

  return (
    <DNABox fill>
      <Iffy is={!isLoadedReportsList || isLoadingReport || !reportUrl}>
        <DNABox fill style={styles.loadingContainer}>
          <DNAEmpty emptyVariant={emptyVariant} />
        </DNABox>
      </Iffy>
      <Iffy is={reportUrl}>
        <View style={{ flex: 1 }} nativeID="embedded-report-id" />
      </Iffy>
    </DNABox >
  )
}
