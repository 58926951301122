import { DocumentVersion, Notation, UserNotations, UserNotationsType } from '@alucio/aws-beacon-amplify/src/models';
import { fetchPagesJson } from 'src/utils/loadCloudfrontAsset/common';
import { store } from '../redux';
import logger from 'src/utils/logger';
import { ContentPageData } from 'src/hooks/useContentPageData/useContentPageData';
import { v4 as uuid } from 'uuid';
import { DataStore } from '@aws-amplify/datastore';
import { CreateUserNotationsPayload, userNotationsActions } from '../redux/slice/userNotations';

export async function handleUserNotationsUpdate(updatedObj: DocumentVersion) {
  try {
    const keyPagesJson = `${updatedObj.convertedFolderKey}json/Pages.json`;
    const pages = await fetchPagesJson([keyPagesJson], false);

    if (!pages || pages.length === 0) {
      return;
    }

    const contentPageData = transformToContentPageData(pages[0], updatedObj.convertedFolderKey || '');
    const { documentId, id: documentVersionId } = updatedObj;
    const newUserNotations = await getNewUserNotations(contentPageData, documentId, documentVersionId);

    if (newUserNotations) {
      store.dispatch(userNotationsActions.createUserNotations(newUserNotations));
    }
  } catch (error) {
    logger.userNotations.error('Error handling user notations update:', error);
  }
}

function transformToContentPageData(data: any, s3BaseKey: string): ContentPageData[] {
  return data.content.pages.map((page: any) => {
    return {
      content: page.content || '',
      presentationPageNumber: page.number,
      speakerNotes: page.speakerNotes || null,
      s3Key: `${s3BaseKey}/${page.hash}.pdf`,
      title: page.title || null,
      recommendations: page.recommendations || [],
      mapping: page.mapping || null,
    };
  });
}

async function getNewUserNotations(
  contentPageData: ContentPageData[],
  documentId: string,
  documentVersionId: string): Promise<CreateUserNotationsPayload | null> {
  const pageDataWithMapping = contentPageData.filter((page) => page.mapping !== null);
  if (pageDataWithMapping.length === 0) {
    return null;
  }

  const prevDocumentVersionId = getPreviousDocumentVersionId(pageDataWithMapping);
  if (!prevDocumentVersionId) {
    return null;
  }

  let prevUserNotations;
  try {
    prevUserNotations = await DataStore.query(UserNotations, (c) => c.documentVersionId.eq(prevDocumentVersionId));
  } catch (error) {
    logger.clientState.dataStore.error('Error getting previous user notations', error);
    return null;
  }

  if (prevUserNotations.length === 0) {
    return null;
  }

  const prevNotation = prevUserNotations[0].notation;

  const newNotation = pageDataWithMapping.flatMap((page) =>
    transformNotations(prevNotation, page, documentVersionId)).filter(Boolean);

  return {
    documentId,
    documentVersionId,
    notation: newNotation,
    type: UserNotationsType.DOCUMENT_VERSION,
  };
}

function getPreviousDocumentVersionId(pageDataWithMapping: ContentPageData[]): string | null {
  return pageDataWithMapping[0]?.mapping?.split('_').slice(0, 2).join('_') || null;
}

function transformNotations(prevNotation: Notation[], page: ContentPageData,
  documentVersionId: string): Notation[] {
  const now = new Date().toISOString();
  const matchingNotations = prevNotation.filter((n) => n.pageId === page.mapping);
  if (matchingNotations.length === 0) {
    return [];
  }
  return matchingNotations.map((notation) => ({
    ...notation,
    pageId: `${documentVersionId}_${page.presentationPageNumber}`,
    id: uuid(),
    createdAt: now,
    updatedAt: now,
  }));
}
