import React, { createContext, PropsWithChildren, useEffect } from 'react'
import { Tabs, DNABox, DNADivider } from '@alucio/lux-ui'
import { DisplayNameChild } from '@alucio/core'
import { useLocation, useHistory } from 'src/router'
import { StyleProp, ViewStyle } from 'react-native'

type DNARouteTabsContextValues = {
  rewriteToRoot: () => void,
  goToTab: (title: string) => void
}
const DNARouteTabsContext = createContext<DNARouteTabsContextValues>({
  rewriteToRoot: () => {},
  goToTab: () => {},
})

type DNARouteTabsProps = { }
type DNARouteTabsComponent = {
  Router: React.FC<PropsWithChildren<DNARouteTabsProps>>,
  Tabs: typeof DNARouteTabsContainer,
  Tab: typeof DNARouteTabsTab,
  Unmatched: typeof DNARouteTabsUnmatched
}

export const DNARouteTabsRouter: React.FC<PropsWithChildren<DNARouteTabsProps>> = (props) => {
  const { children } = props

  const values: DNARouteTabsContextValues = {
    rewriteToRoot: () => {},
    goToTab: () => { },
  }

  return (
    <DNARouteTabsContext.Provider value={values}>
      { children }
    </DNARouteTabsContext.Provider>
  )
}
DNARouteTabsRouter.displayName = 'DNARouteTabs'

// Checks if a path has the form of a navigation path
// i.e. /folders/{uuid}
const isNavigationPath = (basePath: string, currentPath: string) => {
  const uuidRegex = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}'
  const regexp = new RegExp(`${basePath}/${uuidRegex}`)
  return regexp.test(currentPath)
}

type DNARouteTabsContainerProps = {
  withDivider?: boolean,
  children: React.ReactElement<DNARouteTabsTabProps> | React.ReactElement<DNARouteTabsTabProps>[],
  style?: StyleProp<ViewStyle>
}

export const DNARouteTabsContainer: React.FC<DNARouteTabsContainerProps> = (props) => {
  const { children, withDivider, style } = props
  const location = useLocation()
  const history = useHistory()

  const paths: (DNARouteTabsTabProps)[] = React.Children.map(children, child => {
    if (React.isValidElement<DNARouteTabsTabProps>(child)) {
      return child.props
    }
  })

  const selectedTab = paths.find(path => path.hasNavigationPaths
    ? path.path === location.pathname || isNavigationPath(path.path, location.pathname)
    : path.path === location.pathname)

  const tabOptions = React.Children.map(children, child => {
    if (React.isValidElement<DNARouteTabsTabProps>(child)) {
      return { title: child.props.title, style: child.props.style }
    }
  })

  const UnmatchedRoute = React.Children
    .toArray(children)
    .find(child => {
      if (React.isValidElement(child)) {
        const typedChild = child as DisplayNameChild<typeof child>
        if (typedChild.type?.displayName === DNARouteTabsUnmatched.displayName)
        { return true }
      }
    })

  if (!selectedTab?.children) {
    if (React.isValidElement<DNARouteTabsUnmatchedProps>(UnmatchedRoute)) {
      const autoRewritePath = UnmatchedRoute.props.rewrite
      if (autoRewritePath) history.replace(`${autoRewritePath}${history.location.search}`)
    }
  }

  return (
    <DNABox fill appearance="col">
      <Tabs
        selectedTabVariant="primary"
        selectedTab={selectedTab?.title ?? ''}
        onSelectTab={(title) => {
          const nextPath = paths.find(path => path.title === title)
          if (nextPath) history.replace(`${nextPath.path}${history.location.search}`)
        }}
        tabs={tabOptions}
        style={style}
      />
      { withDivider && <DNADivider /> }
      { selectedTab?.children ?? UnmatchedRoute }
    </DNABox>
  )
}
DNARouteTabsContainer.displayName = 'DNARouteTabsContainer'

type DNARouteTabsTabProps = {
  path: string,
  title: string,
  style?: StyleProp<ViewStyle>,
  children: React.ReactElement<any>,
  // If true will also match navigation subpaths with uuids
  // i.e. path="/folders" will match /folders and also paths such as
  // /folders/bb664abc-a93e-4e86-a9bb-debda0984f92
  hasNavigationPaths?: boolean
}
export const DNARouteTabsTab: React.FC<DNARouteTabsTabProps> = () => {
  return null
}
DNARouteTabsTab.displayName = 'DNATab'

type DNARouteTabsUnmatchedProps = { rewrite?: string }
export const DNARouteTabsUnmatched: React.FC<PropsWithChildren<DNARouteTabsUnmatchedProps>> = (props) => {
  const { rewrite, children } = props
  const history = useHistory()

  useEffect(() => {
    if (rewrite) history.replace(`${rewrite}${history.location.search}`);
  }, [rewrite])

  return (children ?? null) as React.ReactElement
}
DNARouteTabsUnmatched.displayName = 'DNARouteTabsUnmatched'

const DNARouteTabs: DNARouteTabsComponent = {
  Router: DNARouteTabsRouter,
  Tabs: DNARouteTabsContainer,
  Tab: DNARouteTabsTab,
  Unmatched: DNARouteTabsUnmatched,
}

export default DNARouteTabs
