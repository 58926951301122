import React, { PropsWithChildren, useState } from 'react';

type IntegrationContextType = {
  handleSetIntegrationType: (integrationType: string) => void;
  integrationType: string | null; // TODO should this be a string?
}

export const IntegrationContext = React.createContext<IntegrationContextType>(null!);
export const useIntegrationContext = () => React.useContext(IntegrationContext);
const IntegrationProvider : React.FC<PropsWithChildren> = (props) => {
  const [integrationType, setIntegrationType] = useState<string | null>(null)

  const handleSetIntegrationType = (integrationType: string) => {
    setIntegrationType(integrationType)
  }

  const context = {
    handleSetIntegrationType,
    integrationType,
  }

  return (
    <IntegrationContext.Provider value={context}>
      {props.children}
    </IntegrationContext.Provider>
  )
}

export default IntegrationProvider;
