import React, { useState, useEffect } from 'react';
import { Image, StyleSheet } from 'react-native';
import {
  Iffy,
  DNABox,
  DNAButton,
  DNADivider,
  DNAIcon,
  DNAText,
  InformationMessage,
} from '@alucio/lux-ui';
import { WebLinking as Linking } from '@alucio/core';
import workerChannel from 'src/worker/channels/workerChannel';
import { useAppSettings } from 'src/state/context/AppSettings';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

import { useSyncState } from 'src/state/redux/selector/cache';
import { SyncStatusInfo } from 'src/components/DNA/Header/SyncStatusButton';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import * as logger from 'src/utils/logger';
import SendLogs from './SendLogs';

const installScreenshot = require('../../../assets/images/pwa_install.png');
const OfflineSyncedIcon = require('../../../assets/images/offline_synced_icon.png');

const styles = StyleSheet.create({
  mainWrapper: {
    minWidth: 500,
    paddingTop: 40,
  },
  installStepContainer: {
    borderColor: colors['color-gray-80'],
    borderWidth: 1,
    borderRadius: 4,
  },
  installStep: {
    borderColor: colors['color-gray-80'],
    borderBottomWidth: 1,
    padding: 20,
  },
  installStepLastItem: {
    padding: 20,
  },
  installStepNumber: {
    marginRight: 20,
  },
  exampleContainer: {
    paddingVertical: 4,
    backgroundColor: colors['color-gray-800'],
    borderColor: colors['color-gray-800'],
  },
});

interface BeaconAppInstallStepRowProps {
  stepNum: number,
  text: string,
  button?: React.ReactElement,
  isLastItem?: boolean,
}

const BeaconAppInstallStepRow: React.FC<BeaconAppInstallStepRowProps> = (props: BeaconAppInstallStepRowProps) => {
  const { stepNum, text, button, isLastItem } = props
  return (
    <DNABox fill style={isLastItem ? styles.installStepLastItem : styles.installStep} alignY="center">
      <DNABox fill>
        <DNAIcon.Styled
          appearance="outline"
          name={`numeric-${stepNum}-box`}
          size="lg"
          status="primary"
          style={styles.installStepNumber}
        />
        <DNAText>{text}</DNAText>
      </DNABox>
      <Iffy is={button}>
        <DNABox style={{ marginLeft: 20 }} alignX="end">{button}</DNABox>
      </Iffy>
    </DNABox>
  )
}

const InfoMessageButton = (
  <DNAPopover placement="bottom-end">
    <DNAPopover.Anchor>
      <DNAText status="success">See example</DNAText>
    </DNAPopover.Anchor>
    <DNAPopover.Content>
      <Image
        source={OfflineSyncedIcon}
        style={{ width: 60, height: 60, marginVertical: 4 }}
      />
    </DNAPopover.Content>
  </DNAPopover>
)

const Offline = () => {
  const { isOfflineEnabled } = useAppSettings()
  const [isWaitingToDisable, setIsWaitingToDisable] = useState<boolean>(false)
  const [exampleVisible, setExampleVisible] = useState<boolean>(false);
  const syncState = useSyncState()

  const disableServiceWorker = async () => {
    // We subscribe to watch for a purge success message from the SW before terminating
    // the service worker
    workerChannel
      .observable
      .filter(msg => msg.type === 'CACHE_DB' && msg.value === 'PURGE_COMPLETE')
      .subscribe(async () => {
        logger.userInit.debug('DB Purge Successful.')
        const registration = await navigator.serviceWorker.getRegistration('/')
        registration?.unregister().then((isUnregistered) => {
          logger.userInit.info('SW Unregistered', isUnregistered)
          Linking.openURL('/profile/offline', '_self');
        })
      })

    workerChannel.postMessageExtended({
      type: 'CACHE_DB',
      value: 'PURGE',
    })
  }

  useEffect(() => {
    if (isWaitingToDisable && syncState.matches('online.paused')) {
      disableServiceWorker()
    }
  }, [isWaitingToDisable, syncState])

  const toggleOffline = async () => {
    if (isOfflineEnabled) {
      if (syncState.matches('online.sync')) {
        workerChannel.postMessageExtended({ type: 'PAUSE_SYNC' })
        setIsWaitingToDisable(true)
      } else {
        disableServiceWorker();
      }
      analytics.track('SYNC_UNINSTALL', {
        action: 'UNINSTALL',
        category: 'SYNC',
      });
    } else {
      // Register the service worker and reload
      navigator.serviceWorker
        .register('/worker.js')
        .then(registration => {
          logger.userInit.info('SW registered: ', registration);
          Linking.openURL('/profile/offline', '_self');
        })
        .catch(registrationError => {
          logger.userInit.error('SW registration failed: ', registrationError);
        });

      analytics.track('SYNC_INSTALL', {
        action: 'install',
        category: 'sync',
      });
    }
  }

  const toggleExampleVisibility = () => {
    setExampleVisible((exampleVisible) => !exampleVisible);
  }

  const activateButton = isOfflineEnabled
    ? (<DNABox>
      <DNAIcon.Styled name="check" appearance="ghost" status="success"/>
      <DNAText
        status="success"
        style={{ marginLeft: 5 }}
        testID="offline-activated-text"
      >
        Activated
      </DNAText>
    </DNABox>)
    : (
      <DNAButton
        padding="sm"
        onPress={toggleOffline}
        testID="offline-activate-button"
      >
        Activate
      </DNAButton>
    )

  const deactivateButton = (
    <DNAButton
      padding="sm"
      onPress={toggleOffline}
      testID="offline-deactivate-button"
    >
      Deactivate
    </DNAButton>
  )

  const seeExampleButton = (
    <DNAPopover
      lazyMount
      placement="bottom-end"
      interactive={true}
      visible={exampleVisible}
      onBackdropPress={toggleExampleVisibility}
    >
      <DNAPopover.Anchor>
        <DNAButton
          appearance="ghostLink"
          onPress={toggleExampleVisibility}
          padding="sm"
        >
          See example
        </DNAButton>
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNABox appearance="col" spacing="xs" style={styles.exampleContainer}>
          <DNABox fill alignX="end">
            <DNAButton
              iconLeft="close"
              status="dark"
              onPress={toggleExampleVisibility}
              padding="xs"
            />
          </DNABox>
          <Image
            source={installScreenshot}
            style={{ width: 300, height: 157 }}
          />
        </DNABox>
      </DNAPopover.Content>
    </DNAPopover>
  )

  return (
    <DNABox fill appearance="col" spacing="lg" alignY="center" style={styles.mainWrapper}>
      {/* ENHANCED LOGGING BUTTON */}
      <DNABox fill alignY="center" spacing="sm">
        <SendLogs />
      </DNABox>
      <DNADivider height={2}/>
      <Iffy is={isOfflineEnabled}>
        <InformationMessage
          // eslint-disable-next-line max-len
          text="Document sync has been successfully activated. Beacon can now be used offline once documents are finished downloading, indicated by a green check above the cloud icon located in the top header bar."
          variance="success"
          customWrapperStyle={{ margin: 0 }}
        >
          {InfoMessageButton}
        </InformationMessage>
      </Iffy>
      {/* STEPS FOR BEACON APP INSTALLATION */}
      <DNAText h5>
        To install Beacon for offline use on your computer as an application, follow the steps below.
      </DNAText>
      <DNABox appearance="col" style={styles.installStepContainer}>
        <BeaconAppInstallStepRow
          stepNum={1}
          text="Ensure you are connected to the internet. Don't use private browsing."
        />
        <BeaconAppInstallStepRow
          stepNum={2}
          text="Click the “Activate” button to activate document sync for offline use. This screen will refresh."
          button={activateButton}
        />
        <BeaconAppInstallStepRow
          stepNum={3}
          // eslint-disable-next-line max-len
          text="After the screen refreshes, an install icon will appear in the address bar. Click the icon to install the application."
          button={seeExampleButton}
          isLastItem={true}
        />
      </DNABox>
      {/* SYNC STATUS INFO */}
      <Iffy is={isOfflineEnabled}>
        <DNABox alignX="start" fill>
          <DNABox style={{ maxWidth: 400 }}>
            <SyncStatusInfo />
          </DNABox>
        </DNABox>
      </Iffy>
      {/* STEPS OF UNINSTALL BEACON APP */}
      <Iffy is={isOfflineEnabled}>
        <DNADivider height={2}/>
        <DNAText h5>
          To deactivate offline use, follow the steps below.
        </DNAText>
        <DNABox appearance="col" style={styles.installStepContainer}>
          <BeaconAppInstallStepRow
            stepNum={1}
            text="Ensure you are connected to the internet. Don’t use private browsing."
          />
          <BeaconAppInstallStepRow
            stepNum={2}
            text="Click the “Deactivate” button to deactivate document sync for offline use."
            button={deactivateButton}
            isLastItem={true}
          />
        </DNABox>
      </Iffy>
    </DNABox>
  );
};

export default Offline;
