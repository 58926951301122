import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HubSectionType } from '@alucio/aws-beacon-amplify/src/models';
import {
  WidgetContentCardProps,
  WidgetCardHeader,
  WidgetCardBody,
  WidgetContextMenu,
} from 'src/screens/Hubs/EditHub/Widgets/WidgetContentCard';
import InputComponent from 'src/components/Publishers/InputComponent';

const Textarea: React.FC<WidgetContentCardProps> = ({ widget, rhForm }) => {
  const [content, setContent] = useState<string | undefined>(widget.textarea?.content)

  useEffect(() => {
    rhForm.setValue('textareaWidget',
      widget,
      { shouldDirty: true })
  }, [])

  const updateContent = useCallback((text: string) => {
    setContent(text)
    const textareaFormValue = rhForm.getValues().textareaWidget
    
    if (textareaFormValue) {
      const newValue = {
        ...textareaFormValue,
        textarea: { content: text },
        updatedAt: new Date().toISOString()
      }
      rhForm.setValue('textareaWidget', newValue, { shouldDirty: true })
    }
  }, [rhForm])

  const rightElement = useMemo(() => (
    <WidgetContextMenu widgetType={HubSectionType.TEXTAREA} id={widget.id} />
  ), [])

  if (widget.type !== HubSectionType.TEXTAREA) return null
  return (
    <>
      <WidgetCardHeader
        widget={widget}
        rightElement={rightElement}
      />
      <WidgetCardBody>
        <InputComponent
          testID='hub-text-box-input'
          onChangeText={updateContent}
          value={content}
          removeMarginPadding
          multiline
          showCharacterCounter={false}
          required={false}
          numOfLines={7}
        />
      </WidgetCardBody>
    </>
  )
}

export default Textarea
