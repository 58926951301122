import { ROUTES, DNARoute } from 'src/router/routes'
import reduce from 'lodash/reduce'
import { useLocation } from 'react-router'

type UseCurrentPage = (opts?: { exact?: boolean }) => DNARoute | undefined

/** TODO: refactor this into a hook named useActiveRoute */

const useCurrentPage: UseCurrentPage = () => {
  const { pathname } = useLocation()

  const routeSearch: { bestMatch?: DNARoute, count?: number } = {}

  const homeRegex = [
    // Copied page
    // check if a path start with / and its is follower by and uuid then another slash and a number and finally another slash
    /^\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\/\d+\/$/,
    // '/'
    /^\/$/,
  ]

  // check if patname match homePatterns and return the home route
  if (homeRegex.some(regex => regex.test(pathname))) {
    return ROUTES.HOME
  }

  /** Child Routes */
  const substrings = new Set(pathname.split('/').filter(segment => segment !== ''));
  const countMatches = str => str.split('/').reduce((a, substr) => a + (substrings.has(substr)), 0);
  const { bestMatch } = reduce(ROUTES, ({ bestMatch, count = 0 }, route) => {
    const thisCount = countMatches(route.PATH);
    return thisCount > count
      ? { count: thisCount, bestMatch: route }
      : { count, bestMatch };
  }, routeSearch)

  return bestMatch
}

export default useCurrentPage
