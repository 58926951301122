import React, { useMemo } from 'react'

import { CustomFieldValue } from 'src/types/orms'
import { DNABox, DNAText } from '@alucio/lux-ui'
import { ComposableVariant } from './ComposableForm'

// [TODO-3073] - Component selector that allows between label only mode
//               and more rich components

const ReadonlyField: React.FC<{
  customFieldValue: CustomFieldValue,
  variant?: ComposableVariant,
  labelOnly?: boolean
}> = (props) => {
  const { customFieldValue } = props

  const displayValue = customFieldValue.displayValues.join(', ') || 'N/A'

  return (
    <DNABox appearance="col" fill>
      <DNABox alignY="center">
        <DNABox alignY="center" fill>
          {/* FIELD NAME LABEL */}
          <DNAText bold status="dark" b1>
            { customFieldValue.field.fieldLabel }
          </DNAText>
        </DNABox>
      </DNABox>

      {/* FORM COMPONENT */}
      <DNABox style={{ marginVertical: 4 }} spacing="md" fill appearance="col">
        <DNAText status="flat">
          { displayValue }
        </DNAText>
      </DNABox>
    </DNABox>
  )
}

// Small wrapper to render fields in columns with sort support
// [TODO-3073] - Consider using in ContentPreviewModal sidebar
export const ReadonlyFields: React.FC<{
  customFieldValues: CustomFieldValue[],
  variant?: ComposableVariant,
  sort?: 'asc' | 'desc',
  labelsOnly?: boolean,
}> = (props) => {
  const { customFieldValues, labelsOnly, sort, variant } = props

  const sortedFieldValues = useMemo(() => {
    if (!sort)
    { return customFieldValues }

    return customFieldValues.sort((a, b) => a.field.fieldLabel.localeCompare(b.field.fieldLabel))
  }, [customFieldValues, sort])

  return (
    <DNABox appearance="col" spacing="md">
      {
        sortedFieldValues.map(fieldValue => (
          <ReadonlyField
            key={fieldValue.field.id}
            customFieldValue={fieldValue}
            variant={variant}
            labelOnly={labelsOnly}
          />
        ))
      }
    </DNABox>
  )
}

export default ReadonlyField
