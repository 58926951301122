import React, { useEffect, useState } from 'react'
import { useDispatch } from 'src/state/redux'

import { DNAModal, DNABox, DNAButton, DNAText, Iffy, Stack } from '@alucio/lux-ui'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors'

import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import { DNAThumbnail } from './DNAThumbnail'
import { Page } from '@alucio/aws-beacon-amplify/src/models'
import { DocumentVersionORM } from 'src/types/orms'

export type AssociatedSlidePreviewModalProps = {
  pageNumber?: number,
  payload?: {pages: Page[], docVerORM: DocumentVersionORM, getSlideTitle?: (number: number) => string},
}

export const AssociatedSlidePreviewModal: DNAModalVariant<AssociatedSlidePreviewModalProps> = (props) => {
  const { pageNumber, payload } = props
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState<Page>()
  const [pageNum, setPageNum] = useState<number>()
  const [currentThumbURL, setCurrentThumbURL] = useState<string>()
  const [currentTitle, setCurrentTitle] = useState<string>('')

  useEffect(() => {
    const curPageIndex = payload?.pages?.findIndex(page => page.number === pageNumber)
    if (typeof curPageIndex === 'number' && curPageIndex !== -1) {
      const curPage = payload?.pages[curPageIndex]
      setCurrentTitle(payload?.getSlideTitle?.((pageNum || 0) + 1) ?? '')
      const thumbURL = payload?.docVerORM.meta
        .assets
        .thumbnailKey
        ?.split('/')
        .slice(0, -1)
        .join('/') + `/${curPage?.number}_medium_thumb.png` ?? ''

      setPageNum(curPageIndex + 1)
      setCurrentPage(curPage)
      setCurrentThumbURL(thumbURL)
      setCurrentTitle(payload?.getSlideTitle?.(((curPage?.number || 0) )) ?? '')
    }
  }, [pageNumber])

  function closeModal(): void {
    dispatch(DNAModalActions.closeModal());
  }

  const toggleSlide = (direction: 'next' | 'previous') => () => {
    if (typeof pageNum === 'number') {
      if (direction === 'next' && pageNum !== payload?.pages?.length) {
        const curPage = payload?.pages[pageNum]
        const thumbURL = payload?.docVerORM.meta
          .assets
          .thumbnailKey
          ?.split('/')
          .slice(0, -1)
          .join('/') + `/${curPage?.number}_medium_thumb.png` ?? ''

        setPageNum(pageNum + 1)
        setCurrentPage(curPage)
        setCurrentThumbURL(thumbURL)
        setCurrentTitle(payload?.getSlideTitle?.((curPage?.number || 0)) ?? '')
      }
      if (direction === 'previous' && pageNum !== 0) {
        const curPage = payload?.pages[pageNum - 2]
        const thumbURL = payload?.docVerORM.meta
          .assets
          .thumbnailKey
          ?.split('/')
          .slice(0, -1)
          .join('/') + `/${curPage?.number}_medium_thumb.png` ?? ''

        setPageNum(pageNum - 1)
        setCurrentPage(curPage)
        setCurrentThumbURL(thumbURL)
        setCurrentTitle(payload?.getSlideTitle?.(((curPage?.number || 0) )) ?? '')
      }
    }
  }

  return (
    <DNAModal>
      <DNAModal.Header onClose={closeModal}>
        Preview
      </DNAModal.Header>
      <DNAModal.Body>
        <DNABox style={{ padding: 9, paddingTop: 0 }} appearance="col" spacing="sm">
          <DNABox>
            <DNAText bold style={{ color: colors['color-gray-400'] }}> {currentTitle || ''}</DNAText>
          </DNABox>
          <DNABox alignX="center" fill>
            <DNABox style={{ borderColor: colors['color-gray-80'], borderWidth: 1 }}>
              <Stack anchor="bottom">
                <Stack.Layer>
                  <Iffy is={currentPage && currentThumbURL}>
                    <DNABox style={{ minWidth: 700, minHeight: 396 }}>
                      <DNAThumbnail
                        key={currentPage?.pageId}
                        s3URL={currentThumbURL}
                        size="xxl"
                      />
                    </DNABox>
                  </Iffy>
                </Stack.Layer>
                <Stack.Layer>
                  <DNABox
                    alignY="center"
                    style={{ backgroundColor: colors['color-gray-900'], padding: 8, borderRadius: 4 }}
                  >
                    <DNAButton
                      iconLeft="chevron-left"
                      onPress={toggleSlide('previous')}
                      disabled={pageNum === 1}
                      size="md"
                      padding="xs"
                      status="tertiary"
                      style={{ width: 24, height: 24 }}
                    />
                    <DNAText style={{ marginHorizontal: 8, color: colors['color-text-basic'] }}>
                      {`Slide ${currentPage?.number} (${pageNum} of ${payload?.pages?.length})`}
                    </DNAText>
                    <DNAButton
                      iconLeft="chevron-right"
                      onPress={toggleSlide('next')}
                      disabled={pageNum === payload?.pages?.length}
                      size="md"
                      padding="xs"
                      status="tertiary"
                      style={{ width: 24, height: 24 }}
                    />
                  </DNABox>

                </Stack.Layer>
              </Stack>
            </DNABox>
          </DNABox>

        </DNABox>
      </DNAModal.Body>
    </DNAModal>
  )
}

export default AssociatedSlidePreviewModal
