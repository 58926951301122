import React from 'react'
import { useDispatch } from 'src/state/redux'
import { FolderItemORM } from 'src/types/types'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'

import {
  DNABox,
  DNACard,
  DNAText,
  Iffy,
  useTheme,
  DNAChip,
  DNAButton,
  luxColors,
} from '@alucio/lux-ui'

import DNACustomDeckContextMenu from 'src/components/DNA/Document/DNACustomDeckContextMenu'
import DNACustomDeckThumbnail from 'src/components/DNA/Document/DNACustomDeckThumbnail'
import { isCustomDeckORM } from 'src/types/typeguards'
import DNADocumentVersionUpdateButton from 'src/components/DNA/Document/DNADocumentVersionUpdateButton'
import { useDNACustomDeckActions } from 'src/components/DNA/Document/DNACustomDeck.actions'
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage'
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags'
import { getRootFolderORM } from 'src/state/redux/selector/folder'
import { PermissionType } from '@alucio/aws-beacon-amplify/src/models'
import DNACommonConfirmation from 'src/components/DNA/Modal/DNACommonConfirmation'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import DNAConditionalButton from 'src/components/DNA/ConditionalButton/DNAConditionalButton'
import { isSharedEditableFolder } from 'src/utils/foldersHelpers'

export type DNADocumentVersionDefaultRowProps = {
  isOnline?: boolean,
}

const DNADocumentFolderItemCustomDeckRow: DNARowsComponent<
  FolderItemORM,
  DNADocumentVersionDefaultRowProps
> = (props) => {
  const {
    ORM: folderItem,
    onPress,
    style,
    isDesktop = true,
    isOnline,
  } = props
  const theme = useTheme()
  const dispatch = useDispatch()
  const DNACustomDeckActions = useDNACustomDeckActions()

  const route = useCurrentPage({ exact: false })
  const enableCustomDecksCollaborationEdit = useFeatureFlags('enableCustomDecksCollaborationEdit');

  if (!isCustomDeckORM(folderItem.relations.itemORM)) { return null }

  /**
   * This is where typescript really helps us identify coding best practice. The way this and related components were structured/typed,
   * there is a possibility of getting into this component with the wrong ORM. This is a broader issue than just checking what type the related
   * item is. Basically, the folderItem comes in as any number of folderItemORM types and should be limited to just CustomDeckORM folderitem
   * types. Since this component is intended to only ever be loaded when we are using a customDeckORM, the data structure/typings should be identified
   * prior to loading the component. Then only the necessary props should be passed in.
   *
   * Note, that the folder item is being passed in and then passed right back out unnecessarily in the onPress call. Additionally, the DNACustomDeckContextMenu
   * component is being designed as a type specific component, but being passed a property that is multi-type. If the component is intended to be
   * specific to the datatype the props should reflect this approach as well. However, if this intended to be a reusable component, then passing the
   * more generic FolderItemORM is ok.
   * */

  const rootFolder =
    folderItem.relations.parentORM
      ? getRootFolderORM(folderItem.relations.parentORM)
      : folderItem.relations.parentORM

  if (!isCustomDeckORM(folderItem.relations.itemORM)) { return null }

  const customDeckORM = folderItem.relations.itemORM
  const {
    containsWebDocs,
    assets:{ isContentCached },
    version: { requiresReview, withinGracePeriod },
  } = customDeckORM.meta
  const isReviewRequired = requiresReview
  const openDeckEditor = DNACustomDeckActions.edit(customDeckORM, folderItem, 'REVIEW')

  const reviewUnavailable = !isOnline && !isContentCached
  const contentAvailable = isOnline ||
    (isContentCached && !containsWebDocs)
  const isSharedFolders = route?.PATH.includes('shared_folders')
  const isSharedEditFolder = (rootFolder && isSharedEditableFolder(rootFolder)) || false

  const hasEditPermission = rootFolder?.meta.permission === PermissionType.EDIT
  const isReviewAllowed = hasEditPermission &&
    ((!isSharedFolders || isSharedEditFolder) || enableCustomDecksCollaborationEdit)

  const handleReviewButtonPress = () => {
    if (isReviewRequired && isReviewAllowed) {
      openDeckEditor()
    }
    else
    {
      handleRowPress(false)
    }
  }

  const handleRowPress = (isThumbnail: boolean) => {
    // [TODO-2126] - We should have a property that combines isOnline and isContentCached
    //               on the CustomDeckORM rather than checking both at the component level
    if (contentAvailable) {
      if (isReviewRequired && isReviewAllowed && !withinGracePeriod) {
        openDeckEditor()
      } else if (!isReviewRequired || withinGracePeriod) {
        isThumbnail ? DNACustomDeckActions.present(customDeckORM, folderItem)() : onPress?.(folderItem)
      } else {
        const confirmModal = () => (
          <DNACommonConfirmation
            cancelText="OK"
            title=""
            // eslint-disable-next-line max-len
            descriptionText="This document is currently pending review; only editors may access until the review has been completed."
          />
        )
        const payload = {
          isVisible: true,
          allowBackdropCancel: true,
          component: confirmModal,
        };
        dispatch(DNAModalActions.setModal(payload));
      }
    }
  }

  return (
    <DNACard
      appearance="flat"
      interactive="pressable"
      onPress={() => handleRowPress(false)}
      style={[
        {
          paddingHorizontal: theme['row-padding-horizontal'],
          paddingVertical: theme['row-padding-vertical'],
        },
        style,
      ]}
    >
      <DNABox
        alignY="center"
        spacing={isDesktop ? 'between' : 'md'}
        childFill={1}
        testID="customDeck-item-row"
      >
        <Iffy is={!isDesktop}>
          <DNABox spacing="sm" style={{ marginLeft: 56 }}>
            {/* Context menu */}
            <DNACustomDeckContextMenu
              folderItemORM={folderItem}
            >
              <DNAButton
                status="gray"
                appearance="ghost"
                padding="md"
                rounded="md"
                iconLeft="dots-vertical"
                size="lg"
              />
            </DNACustomDeckContextMenu>
          </DNABox>
        </Iffy>
        <DNABox
          spacing="lg"
          alignY="center"
          childFill={1}
          disabled={!contentAvailable}
        >
          <DNACustomDeckThumbnail
            customDeckORM={customDeckORM}
            width={76}
            height={44}
            unavailableContent={!contentAvailable}
            // Assessing implementation in #BEAC-2155
            onPress={() => handleRowPress(true)}
          />
          {/* Title */}
          <DNABox
            spacing="sm"
            appearance="col"
            childFill
            style={{ paddingRight: 16 }}
          >
            <DNAText b1 numberOfLines={1} testID="customDeck-item-title">
              {folderItem.meta.title}
            </DNAText>
            <DNABox
              alignY="center"
              spacing="sm"
              fill
              childStyle={[4, { flex: 1 }]}
            >
              <DNAChip appearance="tag">
                MODIFIED
              </DNAChip>
              {!contentAvailable && (
                <DNAChip
                  appearance="tag"
                  style={{ backgroundColor: luxColors.basicBlack.primary }}
                  testID={`content-not-available-${folderItem.meta.title}`}
                >
                  CONTENT NOT AVAILABLE
                </DNAChip>
              )
              }
            </DNABox>
          </DNABox>
          {/* Update Version Notification */}
          <Iffy is={!isReviewRequired}>
            <DNADocumentVersionUpdateButton
              orm={folderItem}
              disabled={isSharedFolders && !isSharedEditFolder}
            />
          </Iffy>
        </DNABox>
        {/* Actions */}
        <DNABox alignY="center" spacing="md">
          <Iffy is={isReviewRequired}>
            <DNAConditionalButton
              appearance="outline"
              onPress={() => handleReviewButtonPress()}
              iconLeft="alert"
              size="sm"
              status="warning"
              testID="customDeck-review-row-button"
            >
              <DNAConditionalButton.Disable condition={reviewUnavailable}>
                <DNAText status="basic">This file must be synced prior to review.</DNAText>
              </DNAConditionalButton.Disable>
              Review
            </DNAConditionalButton>
          </Iffy>
          <Iffy is={isDesktop}>
            <DNABox spacing="sm">
              <DNACustomDeckContextMenu
                folderItemORM={folderItem}
              >
                <DNAButton
                  status="gray"
                  appearance="ghost"
                  size="md"
                  padding="sm"
                  rounded="md"
                  iconLeft="dots-vertical"
                />
              </DNACustomDeckContextMenu>
            </DNABox>
          </Iffy>
        </DNABox>
      </DNABox>
    </DNACard>
  );
}

export default DNADocumentFolderItemCustomDeckRow
