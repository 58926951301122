import React from 'react';
import { DNABox, createPortal } from '@alucio/lux-ui';
import { getSlideTitle, getThumbURL } from 'src/utils/thumbnailHelpers';
import { usePublisherVersioningState }
  from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import { useMatchSlidesState } from 'src/state/machines/publisherVersioning/MatchSlides/MatchSlidesProvider';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
// Dnd
import {
  useSingleItemDnd,
  DragOverlay,
  snapCenterToCursor,
} from 'src/components/DnD/DNASingleItemDnd';

const SlidesGroupOverlay: React.FC = () => {
  const { latestPublishedVersionContentPageData } = usePublisherVersioningState();
  const { latestPublishedDocumentVersionORM } = useMatchSlidesState();

  const { activeItemId } = useSingleItemDnd();
  const pageNum = Number(activeItemId?.split('_').pop() ?? 0);
  const thumbnailTitle = getSlideTitle(
    latestPublishedDocumentVersionORM,
    pageNum,
    latestPublishedVersionContentPageData,
  )

  return (
    createPortal(
      <DragOverlay modifiers={[snapCenterToCursor]}>
        {
          activeItemId
            ? <DNABox appearance="col">
              <DNAThumbnail
                s3URL={getThumbURL(latestPublishedDocumentVersionORM, pageNum)}
                thumbnailTitle={thumbnailTitle}
                useLoadingIndicator
                size="lg"
                mode={DNAThumbnail.Modes.READ_ONLY}
                variant={DNAThumbnail.Variants.INFO}
                pageNumber={pageNum}
              />
            </DNABox>
            : null
        }
      </DragOverlay>,
      window.document.body,
    )
  )
}

export default SlidesGroupOverlay;
