import React, { useEffect } from 'react'
import { DNABox, DNAButton, DNAText } from '@alucio/lux-ui'
import { Pressable, StyleSheet } from 'react-native'
import { SelectionOption } from '../ContentPreviewModal'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import { LoadedDocument, useContentViewerModalState } from '../state/ContentViewerModalStateProvider'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { isDocumentVersionORM, isFolderItemORM } from 'src/types/typeguards';
import { isDocumentVersion } from 'src/components/DNA/AssociatedFiles/AssociatedFilesList';

interface ActiveDocumentTabsProps {
    onOptionSelected: (option: SelectionOption) => void;
}

const styles = StyleSheet.create({
  tabBase:{
    borderColor: colors['color-gray-500'],
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  inactiveTab: {
    backgroundColor: colors['color-gray-900'],
  },
  activeTab: {
    backgroundColor: colors['color-gray-700'],
  },
})

const ActiveDocumentTabs:React.FC<ActiveDocumentTabsProps> = (
  props,
) => {
  const { onOptionSelected } = props
  const {
    setActiveDocVersionORMAndSlide,
    setActiveFolderItemORMAndSlide,
    loadedDocuments,
    setLoadedDocuments,
    activeSlide,
  } = useContentViewerModalState()

  const handleClose = (selectedLoadedDocument: LoadedDocument) => {
    const { itemORM } = selectedLoadedDocument
    if (loadedDocuments.length === 1) {
      /** If this is the only loaded doc exit the content preview modal */
      onOptionSelected('exit-fullwindow')
    } else {
      /** Otherwise pop it out of the loaded docs array */
      setLoadedDocuments(p => {
        const selectedItemIdx = p.findIndex(loadedDoc => loadedDoc.itemORM.model.id === itemORM.model.id)
        p.splice(selectedItemIdx, 1)

        if (selectedLoadedDocument.active) {
          const itemToSwitchTo = p[0]
          itemToSwitchTo.active = true
          if (isFolderItemORM(itemToSwitchTo.itemORM)) {
            setActiveFolderItemORMAndSlide(itemToSwitchTo.itemORM, itemToSwitchTo.slide)
          } else if (isDocumentVersionORM(itemToSwitchTo.itemORM)) {
            setActiveDocVersionORMAndSlide(itemToSwitchTo.itemORM, itemToSwitchTo.slide)
          }
        }

        return [...p]
      })
    }
  }

  const handleTabSelection = (selectedLoadedDocument:LoadedDocument) => {
    const { itemORM } = selectedLoadedDocument

    /** Reset all loaded docs to not active */
    loadedDocuments
      .forEach(loadedDoc => { loadedDoc.active = false })
    /** Update our current doc to be the active item */
    selectedLoadedDocument.active = true

    if (isFolderItemORM(itemORM)) {
      setActiveFolderItemORMAndSlide(itemORM, selectedLoadedDocument.slide)
    } else if (isDocumentVersionORM(itemORM)) {
      setActiveDocVersionORMAndSlide(itemORM, selectedLoadedDocument.slide);
    }
  }

  const tabEls = loadedDocuments.map(loadedDoc => {
    const { itemORM, active } = loadedDoc
    let title:string|undefined
    let chip
    if (isFolderItemORM(itemORM)) {
      if (itemORM.model.customTitle) {
        title = itemORM.model.customTitle
      } else if (isDocumentVersion(itemORM.relations.itemORM)) {
        title = itemORM.relations.itemORM.model.title
        chip = (<DNADocumentChip
          item={itemORM.relations.itemORM.relations.documentORM}
          variant="purpose"
        />)
      }
    } else if (isDocumentVersionORM(itemORM)) {
      title = itemORM.model.title
      chip = (<DNADocumentChip
        item={itemORM.relations.documentORM}
        variant="purpose"
      />)
    }

    const tabStyle = active ? [styles.tabBase, styles.activeTab] : [styles.tabBase, styles.inactiveTab]

    return (
      <DNABox
        key={itemORM.model.id}
        as={Pressable}
        alignY="center"
        style={tabStyle}
        fill
        // @ts-ignore - using the as prop to switch to a pressable element does not add onPress as a recognized prop. Ping @alucio-kinthirath for details
        onPress={() => { handleTabSelection(loadedDoc) }}
        childFill={1}
      >
        <DNABox
          style={{
            marginLeft: 8,
            marginRight: 4,
            opacity: active ? undefined : 0.4,
          }}
        >

          {/* Iffy doesn't work here because the typescript compiler doesn'trecognize the guard for activeItem */}
          {chip}

        </DNABox>

        <DNABox
          fill
        >
          <DNAText
            b2
            status="basic"
            style={{
              opacity: active ? undefined : 0.4,
            }}
            numberOfLines={1}
          >{title}</DNAText>
        </DNABox>
        <DNABox>
          <DNAButton
            appearance="ghost"
            status="secondary"
            iconLeft={'close'}
            style={{ marginRight: 12 }}
            onPress={() => { handleClose(loadedDoc) }}
          />
        </DNABox>
      </DNABox>
    )
  })

  const handleActiveSlideUpdate = () => {
    const activeDocIdx = loadedDocuments.findIndex(loadedDoc => loadedDoc.active)
    if (activeDocIdx === -1) return
    loadedDocuments[activeDocIdx].slide = activeSlide
  }
  useEffect(handleActiveSlideUpdate, [activeSlide])

  return (
    <DNABox
      appearance="row"
      fill
      style={{ maxHeight:32, maxWidth: '100%' }}
    >
      {tabEls}
    </DNABox>
  )
}

export default ActiveDocumentTabs
