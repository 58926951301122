import React from 'react'
import { DNAButton, DNAContextMenu } from '@alucio/lux-ui'
import {
  FolderContextMenuAction,
  folderContextMenuActions,
  useDNAFolderActions,
} from 'src/components/DNA/Folder/DNAFolder.actions'
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage'
import { useLocation } from 'react-router'
import { useDNAFolderNav } from 'src/components/DNA/Folder/Nav/DNAFolderNav'
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags'
import { isSharedEditableFolder } from 'src/utils/foldersHelpers'
import { useCurrentUser } from 'src/state/redux/selector/user'

const FolderActions: React.FC = () => {
  const DNAFolderActions = useDNAFolderActions()
  const route = useCurrentPage({ exact: false })
  const { pathname } = useLocation();
  const folderNav = useDNAFolderNav()
  const currentUser = useCurrentUser()
  const folderRegex = /^\/folders\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b.*/gm;
  // eslint-disable-next-line max-len
  const sharedFoldersRegex = /^\/shared_folders\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{4}-\b[0-9a-f]{12}\b.*/gm;
  // eslint-disable-next-line max-len
  const publisherFolderRegex = /^\/publisher_folders\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b.*/gm;
  // eslint-disable-next-line max-len
  const publisherSharedFolderRegex = /^\/publisher_shared_folders\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b.*/gm;

  const lowerCasedPathName = pathname.toLowerCase();
  const isInsideFolder = !!lowerCasedPathName.match(folderRegex) ||
    !!lowerCasedPathName.match(publisherFolderRegex) ||
    !!lowerCasedPathName.match(sharedFoldersRegex) ||
    !!lowerCasedPathName.match(publisherSharedFolderRegex);
  const subFolderId = isInsideFolder ? pathname.split('/')[3] : null;
  const sharedFoldersTab = !!route?.PATH.includes('shared_folders');
  let currentFolder = folderNav.currentFolders[folderNav.currentFolders.length - 1] ||
  folderNav.allFolders[folderNav.allFolders.length - 1]
  if (subFolderId) {
    currentFolder = folderNav.allFolders.find((folder) => folder.model.id === subFolderId) || currentFolder
  }
  const featureFlags = useFeatureFlags(
    'enableEditSharedFolder',
    'enableCustomDeckSharedFolders',
    'enableAllowAddFileToFolder',
  );
  const isSharedEditFolder = currentFolder
    ? isInsideFolder && isSharedEditableFolder(currentFolder, currentUser.userProfile?.id) : false
  const rootIsShared = featureFlags.enableCustomDeckSharedFolders ? false : folderNav.folderStack.length &&
  folderNav.folderStack[0].model?.sharePermissions?.some((item) => !item.isDeleted)

  const actionsToUse: FolderContextMenuAction[] = []

  if (sharedFoldersTab && (!isSharedEditFolder || !featureFlags.enableEditSharedFolder) ) {
    return null;
  }

  const canAddFilesToFolder = ((featureFlags.enableAllowAddFileToFolder &&
    !rootIsShared && isInsideFolder)) || (isSharedEditFolder && isInsideFolder)
  const canCreateFolder = !folderNav.isMaxDepth && !folderNav.isCurrentlyNested && !sharedFoldersTab
  const canCreateSubFolder = !folderNav.isMaxDepth && folderNav.isCurrentlyNested &&
  (!sharedFoldersTab || isSharedEditFolder)

  canAddFilesToFolder && actionsToUse.push('openFilesDrawer')
  canCreateFolder && actionsToUse.push('createFolder')
  canCreateSubFolder && actionsToUse.push('createSubFolder')

  if (actionsToUse.length === 0) {
    return null
  }

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        <DNAButton
          testID="plus-new-button"
          appearance="outline"
          status="tertiary"
          size="md"
          padding="sm"
          iconLeft="plus"
        >
          New
        </DNAButton>
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        {
          actionsToUse.map(action => {
            const { onPress, ...rest } = folderContextMenuActions[action]
            return (
              <DNAContextMenu.Item
                key={action}
                status="dark"
                onPress={DNAFolderActions[action](currentFolder)}
                {...rest}
              />
            )
          })
        }
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

export default FolderActions;
