import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FlatList, Pressable, StyleSheet } from 'react-native';
import {
  DNABox,
  DNAButton,
  DNAText,
  DNADivider,
  DNAContextMenu,
  Iffy,
  InformationMessage,
  DNAIcon,
} from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { Notation } from '@alucio/aws-beacon-amplify/src/models';
import useSelector, { composite } from 'src/hooks/useSelector';
import InputComponent from 'src/components/Publishers/InputComponent';
import * as customNotesSelector from 'src/state/machines/customNote/customNote.selectors';
import { SidebarOptions, tabConfigs } from 'src/components/ContentPreviewModalV2/SideBar/SideBar';
import SidebarContent from 'src/components/ContentPreviewModalV2/SideBar/SideBarContent';
import CustomNotesProvider, { useCustomNotes } from './CustomNotesProvider';

const styles = StyleSheet.create({
  contextBtn: {
    marginLeft: 10,
  },
  fullWidth: {
    width: '100%',
  },
  emptyStateContainer: {
    paddingHorizontal: 50,
  },
  emptyStateText: {
    textAlign: 'center',
  },
  informationMessage: {
    margin: 0,
  },
  activeNotationContainer: {
    backgroundColor: colors['color-gray-20'],
  },
  notationCard: {
    marginTop: 16,
  },
  yellowDot: {
    position: 'absolute',
    left: -18,
    top: 24,
    width: '12px',
    height: '12px',
    borderRadius: 50,
    backgroundColor: colors['color-notation-yellow-dot'],
  },
});

type CustomNoteRowProps = {
  notation: Notation
}

type EditModeCustomNoteRowProps = {
  notation?: Notation
}

export const CustomNoteRightElement: React.FC = () => {
  const {
    service,
    createNote,
    createNoteWithHighlight,
    currentPageNotationsInfo,
  } = useCustomNotes()

  useEffect(() => {
    analytics?.track('USERNOTES_OPENED', {
      action: 'VIEW',
      category: 'USERNOTES',
      context: 'CONTENT_PREVIEW',
    });
  }, [])

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      customNotesSelector.isInitializingState,
      customNotesSelector.isIdleState,
      customNotesSelector.canAddHighlight,
      customNotesSelector.isReadOnlyMode,
    ),
  )

  const hideContextMenu = cond.isReadOnlyMode ||
    cond.isInitializingState ||
    currentPageNotationsInfo.hasMeetNotationLimits

  if (hideContextMenu) return null
  return (
    <DNAContextMenu placement="bottom end">
      <DNAContextMenu.Anchor>
        <DNAButton
          testID="viewer-notes-context-menu-btn"
          iconRight="plus"
          appearance="outline"
          size="lg"
          status="tertiary"
          padding="xs"
          style={{ width: 32, height: 32 }}
          disabled={!cond.isIdleState}
        />
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        <DNAContextMenu.Item
          title="Note"
          onPress={createNote}
        />
        {cond.canAddHighlight && <DNAContextMenu.Item
          title="Note with highlight"
          onPress={createNoteWithHighlight}
        />}
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

const CustomNoteRow: React.FC<CustomNoteRowProps> = ({ notation }) => {
  const {
    service,
    deleteNote,
    editNote,
    setCurrentActiveNotationId,
    currentPageNotationsInfo,
  } = useCustomNotes()
  const customNoteRowRef = useRef<null | HTMLDivElement>(null)

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      customNotesSelector.isIdleState,
      customNotesSelector.currentActiveNotationId,
      customNotesSelector.customDeckId,
      customNotesSelector.isReadOnlyMode,
    ),
  )

  const isCustomDeck = !!cond.customDeckId
  const isCurrentActiveNotation = cond.currentActiveNotationId === notation.id
  const displayYellowDot = !!notation.coordinate
  // SHOW LOCK ICON FOR THE NOTE FROM SOURCE DOC WHEN VIEWING CUSTOM DECK
  const showLockIcon = isCustomDeck &&
    currentPageNotationsInfo.currentPageNotationsLevelMap[notation.id] === 'documentLevel'

  useEffect(() => {
    if (isCurrentActiveNotation) {
      customNoteRowRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [isCurrentActiveNotation])

  return (
    <>
      {displayYellowDot && <DNABox style={styles.yellowDot}/>}
      <Pressable onPress={() => setCurrentActiveNotationId(notation.id)}>
        <DNABox
          testID="my-speaker-notes-text-container"
          ref={customNoteRowRef}
          fill
          appearance="col"
          spacing="md"
          style={isCurrentActiveNotation && styles.activeNotationContainer}
        >
          <DNABox fill childFill={0} spacing="between" style={styles.notationCard}>
            <DNABox fill alignY="center">
              <DNAText
                testID="my-speaker-notes-text-row"
                style={styles.fullWidth}
                numberOfLines={isCurrentActiveNotation ? undefined : 5}
              >
                {notation.description || ''}
              </DNAText>
            </DNABox>
            <Iffy is={!cond.isReadOnlyMode && !showLockIcon}>
              <DNAContextMenu placement="bottom end">
                <DNAContextMenu.Anchor>
                  <DNAButton
                    testID="viewer-notes-row-ellipsis-btn"
                    iconRight="dots-vertical"
                    appearance="ghostLink"
                    size="md"
                    status="gray"
                    padding="none"
                    style={styles.contextBtn}
                    disabled={!cond.isIdleState}
                  />
                </DNAContextMenu.Anchor>
                <DNAContextMenu.Items>
                  <DNAContextMenu.Item
                    icon="pencil"
                    title="Edit"
                    onPress={() => editNote(notation.id)}
                  />
                  <DNAContextMenu.Item
                    icon="trash-can-outline"
                    title="Delete"
                    iconStatus="danger"
                    status="danger"
                    onPress={() => deleteNote(notation.id)}
                  />
                </DNAContextMenu.Items>
              </DNAContextMenu>
            </Iffy>
            <Iffy is={showLockIcon}>
              <DNAPopover placement="top">
                <DNAPopover.Anchor>
                  <DNAIcon
                    testID="viewer-notes-row-lock-icon"
                    name="sort-variant-lock"
                    size="md"
                    style={{ fontSize: 20, color: colors['color-gray-200'] }}
                  />
                </DNAPopover.Anchor>
                <DNAPopover.Content offset={2}>
                  <DNAText status="basic">
                    Original notes from source file cannot be edited
                  </DNAText>
                </DNAPopover.Content>
              </DNAPopover>
            </Iffy>
          </DNABox>
          <DNADivider/>
        </DNABox>
      </Pressable>
    </>
  )
}

const EditModeCustomNoteRow: React.FC<EditModeCustomNoteRowProps> = ({ notation }) => {
  const {
    service,
    cancelEdit,
    saveNote,
    descriptionRef,
  } = useCustomNotes()
  const [description, setDescription] = useState<string>(notation?.description ?? '')
  const customNoteRowRef = useRef<null | HTMLDivElement>(null)

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      customNotesSelector.isCreatingNoteState,
      customNotesSelector.isEditingNoteState,
      customNotesSelector.presentableState,
      customNotesSelector.notationDraft,
    ),
  )

  const documentVersionId = cond.presentableState?.documentVersionId ?? '';
  const currentPageNum = cond.presentableState?.state.page ?? 0;
  const currentPageId = `${documentVersionId}_${currentPageNum}`;

  useEffect(() => {
    descriptionRef.current = description
  }, [description])

  useEffect(() => {
    // THIS BLOCK HANDLES AUTO SAVE OR DISCARD NOTE IF USER IS IN THE MIDDLE OF
    // CREATING OR EDITING NOTE AND THE PAGE NUMBER CHANGED
    if (cond.isCreatingNoteState && currentPageId !== cond.notationDraft?.pageId) {
      if (!description) cancelEdit()
      else saveNote(description)
    }

    return () => {
      if (cond.isEditingNoteState) {
        if (!descriptionRef.current) cancelEdit()
        else saveNote(descriptionRef.current)
      }
    }
  }, [currentPageId])

  useEffect(() => {
    customNoteRowRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }, [])

  return (
    <DNABox ref={customNoteRowRef} appearance="col" spacing="sm">
      <InputComponent
        onChangeText={text => setDescription(text.slice(0, 1000))}
        characterLimit={1000}
        value={description}
        removeMarginPadding
        multiline
        showCharacterCounter={false}
        required={false}
        numOfLines={7}
        autoFocus
        testID="my-speaker-notes-input-text-box"
      />
      <DNABox fill childFill spacing="sm">
        <DNAButton
          appearance="outline"
          status="tertiary"
          style={styles.fullWidth}
          onPress={cancelEdit}
          testID="my-speaker-notes-cancel-btn"
        >
          Cancel
        </DNAButton>
        <DNAButton
          style={styles.fullWidth}
          onPress={() => saveNote(description)}
          disabled={!description}
          testID="my-speaker-notes-save-btn"
        >
          Save
        </DNAButton>
      </DNABox>
      <DNADivider/>
    </DNABox>
  )
}

const CustomNotes: React.FC = () => {
  const { service } = useCustomNotes()

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      customNotesSelector.isInitializingState,
      customNotesSelector.isCreatingNewNote,
      customNotesSelector.isSetCoordinateMode,
      customNotesSelector.isEditingNoteState,
      customNotesSelector.currentActiveNotationId,
      customNotesSelector.currentPageNotations,
      customNotesSelector.isReadOnlyMode,
    ),
  )

  const hideContextMenu = cond.isReadOnlyMode || cond.isInitializingState
  const showEmptyState = !cond.currentPageNotations.length && !cond.isCreatingNewNote

  const renderItem = useCallback(({ item : notation } : { item: Notation }) => {
    if (cond.isEditingNoteState && cond.currentActiveNotationId === notation.id) {
      return <EditModeCustomNoteRow notation={notation}/>
    } else return <CustomNoteRow notation={notation} />
  }, [cond.isEditingNoteState, cond.currentActiveNotationId])

  return (
    <DNABox appearance="col" fill>
      <Iffy is={cond.isSetCoordinateMode}>
        <DNABox>
          <InformationMessage
            testID="my-speaker-notes-highlight-text"
            text="Click anywhere on the slide to create a new callout"
            customWrapperStyle={styles.informationMessage}
          />
        </DNABox>
      </Iffy>
      <DNABox fill appearance="col">
        <Iffy is={cond.isCreatingNewNote}>
          <EditModeCustomNoteRow />
        </Iffy>
        <Iffy is={cond.currentPageNotations.length}>
          <DNABox appearance="col" spacing="md">
            <FlatList
              data={cond.currentPageNotations}
              keyExtractor={(notation) => `custom-note-row-${notation.id}`}
              renderItem={renderItem}
              style={{ overflow: 'visible' }}
            />
          </DNABox>
        </Iffy>
        <Iffy is={showEmptyState}>
          {
            hideContextMenu
              ? (
                <DNABox fill>
                  <DNAText numberOfLines={2} style={styles.emptyStateText}>
                    No slide notes
                  </DNAText>
                </DNABox>
              ) : (
                <DNABox fill style={styles.emptyStateContainer} alignY="center" alignX="center">
                  <DNAText
                    testID="empty-my-speaker-notes"
                    status="flatAlt"
                    numberOfLines={2}
                    style={styles.emptyStateText}
                  >
                    Create notes to see them appear here
                  </DNAText>
                </DNABox>
              )
          }
        </Iffy>
      </DNABox>
    </DNABox>
  )
}

const CustomNotesWrapper: React.FC = () => {
  return (
    <CustomNotesProvider>
      <SidebarContent>
        <SidebarContent.Header
          sidebarHeader={tabConfigs[SidebarOptions.customNotes].label}
          rightElement={<CustomNoteRightElement/>}
          descriptionText={'My Speaker Notes are only visible to you \neven when this file is in a shared folder.'}
        />
        <SidebarContent.Body>
          <CustomNotes />
        </SidebarContent.Body>
      </SidebarContent>
    </CustomNotesProvider>
  )
}

export default CustomNotesWrapper
