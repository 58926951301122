import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import equal from 'fast-deep-equal';
import throttle from 'lodash/throttle';
import {
  DocumentVersionORM,
  FolderItemORM,
  isCustomDeckORM,
  isDocumentVersionORM,
  isFolderItemORM,
  isPageGroupORM,
  PageGroupORM,
  Presentable,
  PresentablePage,
} from 'src/types/types';
import { PPZTransform } from '@alucio/core';
import {
  ContentPresented,
  DocumentAccessLevel,
  FileType,
  MeetingContentType,
  MeetingType,
  Notation,
} from '@alucio/aws-beacon-amplify/src/models';
import { getPresentable, updatePresentableDocVer } from './helper';
import useContentInfoBroadcastChannel from './useContentInfoBroadcastChannel';
import { AccessTokenDataExtended } from 'src/state/redux/slice/authToken';
import { useAuthTokens } from 'src/state/redux/selector/authToken';
import { useMeeting } from 'src/state/redux/selector/meeting';
import useContentPageData, { ContentPageData } from 'src/hooks/useContentPageData/useContentPageData';
import { getContentPresentedIndexByContent } from 'src/state/context/Meetings/helper';
import { PresentableORM, useAllPresentableOrm } from 'src/components/Meeting/useAllPresentableOrm';
import { DocumentORMMap, useAllDocumentORMMap } from 'src/state/redux/selector/document';
import { useAppSettings } from '../AppSettings';
import * as logger from 'src/utils/logger'

interface InitialValues {
  item?: PresentableModelORM
  meetingId?: string
}

export interface LoadedPresentation {
  presentable: Presentable,
  currentPresentablePage: PresentablePage
  currentPPZCoords?: PPZTransform,
  currentStep?: number,
  totalSteps?: number,
  isHub?: boolean,
  meta?: {
    parentPresentableId: string,
  }
}

export type PresentableModelORM = DocumentVersionORM | FolderItemORM | PageGroupORM;

export interface ContentType {
  /** NOTE: DO NOT RELY ON THIS FOR UPDATES THAT COULD OCCUR IN THE BACKEND (e.g bookmarks, etc...)
   * This nomenclature is a bit misleading. This property will not reflect any updates to the
   * relative models that have been recently updated on the backend. We shoud align on a nomenclature
   * that indicates a model which is syncronized with the backend vs asynchronous
   * See: https://alucioinc.atlassian.net/browse/BEAC-3541 */
  activePresentation?: LoadedPresentation,
  addPresentation: (
    item: PresentableModelORM,
    pageNumber?: number,
    isHub?: boolean,
    notations?: Notation[],
    isAssociatedFile?: boolean,
  ) => void,
  changeActivePresentation: (presentableId: string, presentationPageNumber?: number) => void,
  initPresentation: (item: PresentableModelORM, pageNumber?: number, visibleOverwrittenPageIds?: string[]) => void,
  nextStepPage: () => void,
  presentations: LoadedPresentation[],
  contentPresented?: ContentPresented[],
  contentPageData: ContentPageData[],
  addContentPresented: (contentPresented: ContentPresented) => void,
  updateContentPresented: (contentPresented: ContentPresented) => void,
  updateNotations: (notations?: Notation[]) => void,
  updateFolderItemORM: (folderItemORM: FolderItemORM) => void,
  setContentPresented: (contentPresented: ContentPresented[]) => void,
  setIsPlayerReady: (isPlayerReady: boolean) => void,
  prevStepPage: () => void,
  setActiveAndOpenedPresentations: (ormId?: string, presentationPageNumber?: number) => void,
  removePresentation: (presentableId: string,
  canOpenNextPresentation?: (orm: PresentableModelORM) => boolean) => void,
  setActiveSlideByPresentationPageNumber: (presentationPageNumber: number, step?: number, totalSteps?: number) => void,
  setCurrentPPZCoords: (coords?: PPZTransform) => void,
  meetingId?: string,
  isCustomDeck?: boolean,
  unsetActivePresentation: () => void,
  isPlayerReady?: boolean,
}

type ContentReducerAction = (
  {
    type: 'addPresentation', payload: {
      item: PresentableModelORM,
      pageNumber?: number,
      authTokens?: AccessTokenDataExtended[],
      isHub?: boolean,
      notations?: Notation[],
      docsORMMap?: DocumentORMMap,
      isOnline?: boolean,
      meetingType?: MeetingType | keyof typeof MeetingType,
      isAssociatedFile?: boolean,
    }
  } |
  { type: 'removePresentation', payload: {
      presentationId: string,
      canOpenNextPresentation?: (orm: PresentableModelORM) => boolean
  } } |
  { type: 'changeActivePresentation', payload: { presentationId: string, presentationPageNumber?: number } } |
  {
    type: 'initPresentation', payload: {
      item: PresentableModelORM, pageNumber?: number, visibleOverwrittenPageIds?: string[],
      docsORMMap?: DocumentORMMap,
      isOnline?: boolean,
      meetingType?: MeetingType | keyof typeof MeetingType
    }
  } |
  { type: 'unsetActivePresentation' } |
  {
    type: 'setActiveSlideByPresentationPageNumber', payload: {
      presentationPageNumber: number, step?: number, totalSteps?: number
    }
  } |
  { type: 'setIsPlayerReady', payload: { isPlayerReady: boolean } } |
  { type: 'setCurrentPPZCoords', payload: { coords: PPZTransform | undefined } } |
  { type: 'addContentPresented', payload: { contentPresented: ContentPresented } } |
  { type: 'setContentPresented', payload: { contentPresented: ContentPresented[] } } |
  { type: 'setActiveAndOpenedPresentations', payload: { openedPresentations: LoadedPresentation[],
      ormId?: string, presentationPageNumber?: number } } |
  { type: 'updateContentPresented', payload: { contentPresented: ContentPresented } } |
  { type: 'updateNotations', payload: { notations?: Notation[] } } |
  { type: 'updateFolderItemORM', payload: { folderItemORM: FolderItemORM } }
);

interface ContentProviderState {
  activePresentation?: LoadedPresentation,
  presentations: LoadedPresentation[],
  contentPresented: ContentPresented[],
  isPlayerReady: boolean,
}

function canPresentContent(orm: FolderItemORM | DocumentVersionORM,
  isOnline?: Boolean, meetingType?: MeetingType | keyof typeof MeetingType): Boolean {
  if (!orm) return false
  let documentVersionORM: DocumentVersionORM | undefined = isDocumentVersionORM(orm) ? orm : undefined;
  if (isFolderItemORM(orm)) {
    if (isCustomDeckORM(orm.relations.itemORM)) return orm.relations.itemORM.meta.permissions.MSLPresent
    if (isDocumentVersionORM(orm.relations.itemORM)) {
      documentVersionORM = orm.relations.itemORM;
    }
  }
  if (documentVersionORM) {
    const isUserDocument = documentVersionORM.relations.documentORM.model.accessLevel === DocumentAccessLevel.USER;
    const isVirtualMeeting = meetingType === 'VIRTUAL'
    const isWebDoc = documentVersionORM.model.type === FileType.WEB
    const isHTMLDoc = documentVersionORM.model.type === FileType.HTML

    const unavailableContent =
      (!isOnline && !documentVersionORM.meta.assets.isContentCached) ||
      (isWebDoc && (isVirtualMeeting || !isOnline)) ||
      (isHTMLDoc && isVirtualMeeting)

    return documentVersionORM.meta.permissions.MSLPresent &&
      documentVersionORM.meta.version.withinGracePeriod &&
      !isUserDocument &&
      !unavailableContent;
  }
  return false;
}

function getAssociatedFiles(
  presentableId: string,
  documentVersionORM: DocumentVersionORM,
  docVersionMap: DocumentORMMap,
  isOnline?: boolean,
  meetingType?: MeetingType | keyof typeof MeetingType,
): LoadedPresentation[] {
  if (documentVersionORM.relations.associatedFiles.length <= 0) return [];

  const loadedPresentations: LoadedPresentation[] = [];
  documentVersionORM.relations.associatedFiles.forEach(file => {
    if (file.model.type === 'DOCUMENT' && file.file?.id) {
      const orm = docVersionMap[file.file.id];
      if (orm && orm.relations.version.latestPublishedDocumentVersionORM &&
        canPresentContent(orm.relations.version.latestPublishedDocumentVersionORM, isOnline, meetingType)) {
        const presentable = getPresentable(orm.relations.version.latestPublishedDocumentVersionORM);
        if (presentable) {
          loadedPresentations.push({
            presentable,
            currentPresentablePage: presentable.presentablePages[0],
            meta: {
              parentPresentableId: presentableId,
            },
          });
        }
      }
    }
  });

  return loadedPresentations;
}

function getNewLoadedPresentation(presentable: Presentable, initialPage?: number): LoadedPresentation {
  let activePage: PresentablePage | undefined;

  const allPages = presentable.presentableGroups.reduce<PresentablePage[]>((acc, { pages }) =>
    [...acc, ...pages], []);

  if (initialPage) {
    if (!isPageGroupORM(presentable.orm) &&
      (isDocumentVersionORM(presentable.orm) ||
        isDocumentVersionORM(presentable.orm.relations.itemORM))) {
      activePage = allPages.find((page) => page.page.number === initialPage);
    } else if (!isPageGroupORM(presentable.orm) && isCustomDeckORM(presentable.orm.relations.itemORM)) {
      activePage = allPages.find((page) => page.presentationPageNumber === initialPage);
    } else if (isPageGroupORM(presentable.orm)) {
      activePage = allPages.find(page => page.presentationPageNumber === initialPage)
    }
  }
  if (!activePage) activePage = presentable.presentableGroups[0]?.pages[0];

  if (!activePage) {
    const orm = presentable.orm
    const errorText = 'Presentable Page not found';
    logger.contentPresenting.contentProvider.error(
      errorText,
      { ormType: orm.type, id: orm.model.id },
    );
    throw new Error(errorText);
  }

  const isVersionORM = isDocumentVersionORM(presentable.orm)
  if (isVersionORM) {

  }

  return {
    presentable,
    currentPresentablePage: activePage,
  }
}

function getPresentablePage(presentable: Presentable, presentationPageNumber: number): PresentablePage | undefined {
  for (const group of presentable.presentableGroups) {
    for (const page of group.pages) {
      if (page.presentationPageNumber === presentationPageNumber) {
        return page;
      }
    }
  }
}

const contentReducer = (state: ContentProviderState, action: ContentReducerAction): ContentProviderState => {
  logger.contentPresenting.contentProvider.debug(`Performing ${action.type} in contentReducer`)
  switch (action.type) {
    case 'addPresentation': {
      if (!state.isPlayerReady) {
        return state
      }

      // IF THE NEW PRESENTATION IS ALREADY IN OUR LOADED PRESENTATION'S ARRAY
      // THAT ONE WILL BE SELECTED INSTEAD OF ADDING A DUPLICATED PRESENTATION
      let loadedPresentation
      if (isPageGroupORM(action.payload.item)) {
        // REMOVE after BEAC-3611 is resolved and pageGroups ids are unique across versions
        const pageGroup = action.payload.item
        loadedPresentation = state.presentations.find(({ presentable }) =>
          isPageGroupORM(presentable.orm) && presentable.orm?.model.id === pageGroup.model.id &&
          presentable.orm.relations.documentVersionORM.model.id === pageGroup.relations.documentVersionORM.model.id,
        );
      } else {
        loadedPresentation = state.presentations.find(({ presentable, meta }) =>
          presentable.orm?.model.id === action.payload.item.model.id &&
        (action.payload.isAssociatedFile ? meta?.parentPresentableId : !meta?.parentPresentableId),
        );
      }

      if (loadedPresentation) {
        loadedPresentation.isHub = action.payload.isHub
        // check if the presentation is the same and the page is the same
        if (state.activePresentation?.presentable.orm?.model.id === action.payload.item.model.id &&
          state.activePresentation?.currentPresentablePage.presentationPageNumber === action.payload.pageNumber) {
          const currentDocVer = state.activePresentation.currentPresentablePage.documentVersionORM
          const currentNotation = currentDocVer.relations.userNotations?.notation
          const payloadDocVer = isDocumentVersionORM(action.payload.item) ? action.payload.item : undefined
          const payloadNotation = payloadDocVer?.relations?.userNotations?.notation
          if (state.activePresentation?.presentable && payloadDocVer && !equal(currentNotation, payloadNotation)) {
            // CUSTOM NOTE ARE ATTACHED IN DOCUMENTVERSIONORM AND WHEN IT IS UPDATED, WE NEED TO SEND AN UPDATE VERSION
            // OF ORM TO UPDATE THE PRESENTABLE. THIS BLOCK IS TO CHECK IF THE PAYLOAD NOTATION DOES NOT MATCH THE CURRENT ONE IN STATE,
            // WE ARE GOING TO GENERATE AN UPDATED PRESENTABLE (WITH SAME IDS) AND UPDATE THE CURRENT PRESENTATIONS ARRAY.
            logger.contentPresenting.contentProvider.debug('Update notation', payloadNotation)
            const presentable = updatePresentableDocVer(
              state.activePresentation.presentable,
              payloadDocVer,
              action.payload.authTokens,
            )
            const activePresentation = getNewLoadedPresentation(presentable!, action.payload.pageNumber);
            const updatedPresentations = [...state.presentations]
            const targetPresentationIndex = updatedPresentations.findIndex(presentation => {
              return presentation.presentable.orm.model.id === activePresentation.presentable.orm.model.id
            })
            if (targetPresentationIndex !== -1) {
              updatedPresentations.splice(targetPresentationIndex, 1, activePresentation)
            }
            return {
              ...state,
              activePresentation,
              presentations: updatedPresentations,
            }
          } else return state;
        }

        if (action.payload.pageNumber) {
          const newPage = getPresentablePage(loadedPresentation.presentable, action.payload.pageNumber);
          loadedPresentation.currentPresentablePage = newPage || loadedPresentation.currentPresentablePage;
        }

        return {
          activePresentation: loadedPresentation,
          presentations: state.presentations,
          contentPresented: state.contentPresented,
          isPlayerReady: true,
        }
      }

      const presentable = getPresentable(
        action.payload.item,
        action.payload.authTokens,
        undefined,
        action.payload.notations,
      );
      const activePresentation = getNewLoadedPresentation(presentable!, action.payload.pageNumber);
      const { isOnline, meetingType, docsORMMap, isHub } = action.payload
      const associatedFiles = isDocumentVersionORM(action.payload.item) && docsORMMap
        ? getAssociatedFiles(activePresentation.presentable.id, action.payload.item, docsORMMap, isOnline, meetingType)
        : []
      activePresentation.isHub = isHub

      return {
        activePresentation,
        presentations: [...state.presentations, activePresentation, ...associatedFiles],
        contentPresented: state.contentPresented,
        isPlayerReady: true,
      }
    }
    case 'removePresentation': {
      const presentations = state.presentations.filter(({ presentable, meta }) => {
        return presentable.id !== action.payload.presentationId &&
          meta?.parentPresentableId !== action.payload.presentationId;
      });

      let activePresentation = state.activePresentation;

      if (state.activePresentation?.presentable.id === action.payload.presentationId) {
        if (action.payload.canOpenNextPresentation) {
          activePresentation = presentations.find((presentation) =>
            action.payload.canOpenNextPresentation?.(presentation.presentable.orm));
        } else {
          activePresentation = presentations[0];
        }
      }

      if (!activePresentation) {
        console.warn('The current presentation was closed.');
      } else if (presentations.length === state.presentations.length) {
        console.warn('The presentation to be removed was not found.');
      }

      return {
        presentations,
        activePresentation,
        contentPresented: state.contentPresented,
        isPlayerReady: true,
      }
    }
    case 'changeActivePresentation': {
      if (!state.isPlayerReady) {
        return state
      }
      const activePresentation = state.presentations.find(({ presentable }) =>
        presentable.id === action.payload.presentationId);

      if (!activePresentation) {
        console.warn('The requested presentation was not found. \' Keeping the current one.');
      }

      if (action.payload.presentationPageNumber && activePresentation) {
        const currentPresentablePage =
          activePresentation?.presentable.presentablePages.find((page) =>
            page.presentationPageNumber === action.payload.presentationPageNumber);

        if (currentPresentablePage) {
          activePresentation.currentPresentablePage = currentPresentablePage;
        }
      }

      return {
        ...state,
        activePresentation: activePresentation || state.activePresentation,
      };
    }
    case 'setActiveSlideByPresentationPageNumber': {
      if (!state.activePresentation) {
        return {
          presentations: [],
          activePresentation: undefined,
          contentPresented: state.contentPresented,
          isPlayerReady: true,
        }
      } else if (!state.isPlayerReady) {
        return state
      }

      const activePresentationId = state.activePresentation?.presentable.id;
      const newPage = getPresentablePage(state.activePresentation.presentable, action.payload.presentationPageNumber);

      if (!newPage) {
        const orm = state.activePresentation.presentable.orm
        const errorText = 'Page not found';
        logger.contentPresenting.contentProvider.error(
          errorText,
          { ormType: orm.type, id: orm.model.id },
        );
        throw new Error(errorText);
      }

      return {
        ...state,
        presentations: state.presentations
          .map(({ presentable, currentPresentablePage, currentPPZCoords, currentStep, totalSteps, isHub, meta }) =>
            (
              {
                presentable,
                currentPresentablePage: presentable.id === activePresentationId ? newPage : currentPresentablePage,
                currentPPZCoords,
                meta,
                currentStep: presentable.id === activePresentationId ? action.payload.step : currentStep,
                totalSteps: presentable.id === activePresentationId ? action.payload.totalSteps : totalSteps,
                isHub: isHub,
              })),
        activePresentation: {
          presentable: state.activePresentation.presentable,
          currentPPZCoords: undefined,
          currentPresentablePage: newPage,
          currentStep: action.payload.step || 0,
          totalSteps: action.payload.totalSteps,
          isHub: state.activePresentation?.isHub,
        },
      }
    }
    case 'initPresentation': {
      const presentable = getPresentable(action.payload.item, undefined, action.payload.visibleOverwrittenPageIds);
      const activePresentation = getNewLoadedPresentation(presentable!, action.payload.pageNumber);
      const { isOnline, meetingType, docsORMMap } = action.payload

      const associatedFiles = isDocumentVersionORM(action.payload.item) && docsORMMap
        ? getAssociatedFiles(activePresentation.presentable.id, action.payload.item, docsORMMap, isOnline, meetingType)
        : []

      return {
        activePresentation,
        presentations: [activePresentation, ...associatedFiles],
        contentPresented: state.contentPresented,
        isPlayerReady: true,
      }
    }
    case 'setCurrentPPZCoords': {
      if (!state.activePresentation || !action.payload.coords) {
        return state;
      }
      state.activePresentation.currentPPZCoords = action.payload.coords;
      return {
        ...state,
        activePresentation: {
          ...state.activePresentation,
          currentPPZCoords: {
            ...action.payload.coords,
          },
        },
      };
    }
    case 'addContentPresented': {
      if (!action.payload.contentPresented) {
        return state;
      }
      return {
        ...state,
        contentPresented: [...state.contentPresented, action.payload.contentPresented],
      };
    }
    case 'updateContentPresented': {
      if (!action.payload.contentPresented) {
        return state;
      }

      const contentToUpdateIndex =
        getContentPresentedIndexByContent(state.contentPresented, action.payload.contentPresented);

      return {
        ...state,
        contentPresented: state.contentPresented.map((contentPresented, idx) =>
          idx === contentToUpdateIndex ? action.payload.contentPresented : contentPresented),
      }
    }
    case 'setIsPlayerReady': {
      return {
        ...state,
        isPlayerReady: action.payload.isPlayerReady,
      }
    }
    case 'setContentPresented': {
      return {
        ...state,
        contentPresented: action.payload.contentPresented,
      }
    }
    case 'setActiveAndOpenedPresentations': {
      const shouldAvoidCalculations = !action.payload.openedPresentations.length &&
        !state.presentations.length && !action.payload.ormId && !state.activePresentation;

      // NOTICED A SCENARIO THAT, EVEN WITHOUT ANY CHANGES, A NEW EMPTY
      // ARRAY OF PRESENTATIONS BEING SET COULD LEAD INTO AN INFINITE LOOP.
      if (shouldAvoidCalculations) {
        return state;
      }

      let activePresentation = state.activePresentation;
      if (action.payload.ormId) {
        activePresentation = action.payload.openedPresentations.find((presentable) =>
          presentable.presentable.orm.model.id === action.payload.ormId);

        if (activePresentation && action.payload.presentationPageNumber) {
          const currentPresentablePage = activePresentation?.presentable.presentablePages.find((page) =>
            page.presentationPageNumber === action.payload.presentationPageNumber);

          if (currentPresentablePage) {
            activePresentation.currentPresentablePage = currentPresentablePage;
          }
        }
      }

      return {
        ...state,
        activePresentation,
        presentations: action.payload.openedPresentations,
      }
    }
    case 'updateNotations': {
      const updatedPresentable = state.activePresentation?.presentable
        ? {
          ...state.activePresentation?.presentable,
          notations: action.payload.notations,
        } : state.activePresentation?.presentable

      if (!updatedPresentable || !state.activePresentation?.currentPresentablePage) return state
      const updatedActivePresentation: LoadedPresentation = {
        presentable: updatedPresentable,
        currentPresentablePage: state.activePresentation?.currentPresentablePage,
        isHub: state.activePresentation.isHub,
      }

      return {
        ...state,
        activePresentation: updatedActivePresentation,
        presentations: [updatedActivePresentation],
      }
    }
    case 'updateFolderItemORM': {
      const updatedPresentable = state.activePresentation?.presentable
        ? {
          ...state.activePresentation?.presentable,
          orm: action.payload.folderItemORM,
        } : state.activePresentation?.presentable

      if (!updatedPresentable || !state.activePresentation?.currentPresentablePage) return state
      const updatedActivePresentation: LoadedPresentation = {
        ...state.activePresentation,
        presentable: updatedPresentable,
      }

      return {
        ...state,
        activePresentation: updatedActivePresentation,
        presentations: [updatedActivePresentation],
      }
    }
    case 'unsetActivePresentation': {
      return {
        ...state,
        activePresentation: undefined,
      }
    }
  }
};

const ContentProvider: React.FC<PropsWithChildren<InitialValues>> = ({
  meetingId,
  item,
  children,
}) => {
  const authTokens = useAuthTokens()
  const meetingORM = useMeeting(meetingId || '');
  const orms = useAllPresentableOrm()
  const docsORMMap = useAllDocumentORMMap()
  const { isOnline } = useAppSettings()
  const prevLoadedPresentations = useMemo(() =>
    getPrevPresentations(meetingORM?.model.contentPresented || [], orms, docsORMMap, isOnline, meetingORM?.model.type)
  , [meetingORM?.model.contentPresented, meetingORM?.model.type]);
  const [{ activePresentation, presentations, contentPresented, isPlayerReady }, dispatch] =
    useReducer(contentReducer, {
      presentations: prevLoadedPresentations,
      activePresentation: undefined,
      contentPresented: [],
      isPlayerReady: true,
    });
  const { contentPageData } = useContentPageData(activePresentation?.presentable)
  const activePresentationRef = useRef<LoadedPresentation | undefined>(activePresentation);
  const nextStepPage = useCallback(throttle(goToNextStepPage, 300), []);
  const prevStepPage = useCallback(throttle(goToPrevStepPage, 300), []);
  useContentInfoBroadcastChannel(
    setActiveSlideByPresentationPageNumber,
    setCurrentPPZCoords,
    activePresentation,
    meetingId,
  )

  useEffect(() => {
    if (item) {
      dispatch({
        type: 'initPresentation',
        payload:
          { item: item, docsORMMap, isOnline, meetingType: meetingORM?.model.type },
      });
    }
  }, []);

  useEffect(() => {
    activePresentationRef.current = activePresentation;
  }, [activePresentation]);

  function addPresentation(
    item: PresentableModelORM,
    pageNumber?: number,
    isHub?: boolean,
    notations?: Notation[],
    isAssociatedFile?: boolean,
  ): void {
    dispatch({
      type: 'addPresentation',
      payload: {
        item,
        pageNumber,
        authTokens,
        isHub,
        notations,
        docsORMMap,
        isOnline,
        meetingType: meetingORM?.model.type,
        isAssociatedFile,
      },
    });
  }

  function removePresentation(
    presentationId: string,
    canOpenNextPresentation?: (orm: PresentableModelORM) => boolean): void {
    dispatch({ type: 'removePresentation', payload: { presentationId, canOpenNextPresentation } });
  }

  function changeActivePresentation(presentationId: string, presentationPageNumber?: number): void {
    dispatch({ type: 'changeActivePresentation', payload: { presentationId, presentationPageNumber } });
  }

  function getPrevPresentations(contentPresented: ContentPresented[], orms:
    PresentableORM, docMap: any, isOnline?: boolean,
  meetingType?: MeetingType | keyof typeof MeetingType): LoadedPresentation[] {
    if (!contentPresented) return [];
    const prevPresentations: LoadedPresentation[] = [];
    for (const content of contentPresented) {
      const { contentType, contentId, folderItemId, groupId } = content;
      // [NOTE]: THE FOLDER ITEMS' LIST IS UNDER THE "CUSTOM_DECK" TYPE AS THERE'S NO "FOLDER_ITEM" TYPE
      const presentedContentType = folderItemId ? MeetingContentType.CUSTOM_DECK : contentType;
      const orm = orms?.[presentedContentType]?.[folderItemId || contentId];
      if (!orm || !canPresentContent(orm, isOnline, meetingType)) continue;
      const presentable = contentType === 'DOCUMENT_VERSION_GROUP' && isDocumentVersionORM(orm)
        ? getPresentable(orm.relations.pageGroups.find((pageGroup) => pageGroup.model.id === groupId))
        : getPresentable(orm);
      if (presentable) prevPresentations.push(getNewLoadedPresentation(presentable));

      if (isDocumentVersionORM(orm) && presentable && orm.relations.associatedFiles.length > 0) {
        const associatedFiles = getAssociatedFiles(presentable.id, orm, docMap, isOnline, meetingType)
        prevPresentations.push(...associatedFiles)
      }
    }
    return prevPresentations
  }

  function setActiveSlideByPresentationPageNumber(
    presentationPageNumber: number,
    step?: number,
    totalSteps?: number,
  ): void {
    dispatch({ type: 'setActiveSlideByPresentationPageNumber', payload: { presentationPageNumber, step, totalSteps } });
  }

  function initPresentation(
    item: PresentableModelORM,
    pageNumber?: number,
    visibleOverwrittenPageIds?: string[]): void {
    dispatch({
      type: 'initPresentation',
      payload: {
        item,
        pageNumber,
        visibleOverwrittenPageIds,
        docsORMMap,
        isOnline,
        meetingType: meetingORM?.model.type,
      },
    });
  }

  function setIsPlayerReady(isPlayerReady: boolean): void {
    dispatch({ type: 'setIsPlayerReady', payload: { isPlayerReady } });
  }

  function setCurrentPPZCoords(coords?: PPZTransform): void {
    dispatch({ type: 'setCurrentPPZCoords', payload: { coords } });
  }

  function unsetActivePresentation(): void {
    dispatch({ type: 'unsetActivePresentation' });
  }

  function addContentPresented(contentPresented: ContentPresented): void {
    dispatch({ type: 'addContentPresented', payload: { contentPresented } });
  }

  function setContentPresented(contentPresented: ContentPresented[]): void {
    dispatch({ type: 'setContentPresented', payload: { contentPresented } });
  }

  function updateContentPresented(contentPresented: ContentPresented): void {
    dispatch({ type: 'updateContentPresented', payload: { contentPresented } });
  }

  function updateNotations(notations?: Notation[]): void {
    dispatch({ type: 'updateNotations', payload: { notations } })
  }

  function updateFolderItemORM(folderItemORM: FolderItemORM): void {
    dispatch({ type: 'updateFolderItemORM', payload: { folderItemORM } })
  }

  function setActiveAndOpenedPresentations(ormId?: string, presentationPageNumber?: number): void {
    dispatch({
      type: 'setActiveAndOpenedPresentations',
      payload: {
        openedPresentations: prevLoadedPresentations,
        ormId,
        presentationPageNumber,
      },
    })
  }

  function goToNextPage(): void {
    if (isPlayerReady && activePresentationRef.current?.currentPresentablePage &&
      activePresentationRef.current?.currentPresentablePage.presentationPageNumber <
      activePresentationRef.current.presentable.numberOfPages) {
      dispatch({
        type: 'setActiveSlideByPresentationPageNumber',
        payload: {
          presentationPageNumber: activePresentationRef.current?.currentPresentablePage.presentationPageNumber + 1,
        },
      });
    }
  }

  function goToPrevPage(): void {
    if (activePresentationRef.current?.currentPresentablePage &&
      activePresentationRef.current?.currentPresentablePage.presentationPageNumber > 1 &&
      isPlayerReady) {
      dispatch({
        type: 'setActiveSlideByPresentationPageNumber',
        payload: {
          presentationPageNumber: activePresentationRef.current?.currentPresentablePage.presentationPageNumber - 1,
        },
      });
    }
  }

  function goToNextStepPage(): void {
    logger.contentPresenting.contentProvider.debug('Entered goToNextStepPage');
    if (activePresentationRef.current?.currentPresentablePage && isPlayerReady) {
      // IF WE'RE ON THE LAST STEP, GO TO THE NEXT SLIDE
      const currentStep = activePresentationRef.current?.currentStep;
      if (currentStep === undefined || !activePresentationRef.current?.totalSteps ||
        currentStep >= activePresentationRef.current?.totalSteps) {
        logger.contentPresenting.contentProvider.debug('Go to next page...');
        goToNextPage();
      } else {
        // OTHERWISE, GO TO NEXT STEP
        logger.contentPresenting.contentProvider.debug('Go to next step...', { currentStep });
        dispatch({
          type: 'setActiveSlideByPresentationPageNumber',
          payload: {
            presentationPageNumber: activePresentationRef.current?.currentPresentablePage.presentationPageNumber,
            step: currentStep + 1,
          },
        });
      }
    }
  }

  function goToPrevStepPage(): void {
    logger.contentPresenting.contentProvider.debug('Entered goToPreviousStepPage');
    if (activePresentationRef.current?.currentPresentablePage && isPlayerReady) {
      // IF WE'RE ON THE FIRST (0) STEP, GO TO THE PREVIOUS SLIDE
      const currentStep = activePresentationRef.current?.currentStep;
      if (!currentStep) {
        logger.contentPresenting.contentProvider.debug('Go to previous page...');
        goToPrevPage();
      } else {
        // OTHERWISE, GO TO THE PREVIOUS STEP
        logger.contentPresenting.contentProvider.debug('Go to previous step...', { currentStep });
        dispatch({
          type: 'setActiveSlideByPresentationPageNumber',
          payload: {
            presentationPageNumber: activePresentationRef.current?.currentPresentablePage.presentationPageNumber,
            step: currentStep - 1,
          },
        });
      }
    }
  }

  const isCustomDeck = useMemo(() => activePresentation &&
    isFolderItemORM(activePresentation.presentable.orm) &&
    isCustomDeckORM(activePresentation.presentable.orm.relations.itemORM), [activePresentation])

  const contextValue: ContentType = {
    activePresentation,
    changeActivePresentation,
    addPresentation,
    presentations,
    setActiveAndOpenedPresentations,
    contentPresented,
    contentPageData,
    addContentPresented,
    updateContentPresented,
    updateNotations,
    updateFolderItemORM,
    removePresentation,
    setActiveSlideByPresentationPageNumber,
    setIsPlayerReady,
    isPlayerReady,
    setCurrentPPZCoords,
    initPresentation,
    nextStepPage,
    unsetActivePresentation,
    setContentPresented,
    prevStepPage,
    meetingId,
    isCustomDeck,
  };

  return (
    <ContentContext.Provider value={contextValue}>
      {children}
    </ContentContext.Provider>
  )
}

export const ContentContext = createContext<ContentType>(null!);
ContentContext.displayName = 'ContentContext';
ContentProvider.displayName = 'ContentProvider';

export function useContent() {
  const context = useContext(ContentContext)
  if (!context) {
    const errorText = 'useContent must be used within the ContentProvider';
    logger.contentPresenting.contentProvider.error(errorText);
    throw new Error(errorText);
  }
  return context;
}

export default ContentProvider;
