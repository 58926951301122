import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useInterpret } from '@xstate/react';
import { v4 as uuid } from 'uuid';
import equal from 'fast-deep-equal';
import { BroadcastChannel } from 'broadcast-channel';
import {
  Notation,
  NotationType,
  HubSectionItemStatus,
  UserNotations,
  UserNotationsType,
} from '@alucio/aws-beacon-amplify/src/models';
import ActiveUser from 'src/state/global/ActiveUser';
import useLazyRef from 'src/hooks/useLazyRef';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as customNotesSelector from 'src/state/machines/customNote/customNote.selectors';
import { CurrentPageNotationsLevelMap } from 'src/state/machines/customNote/customNote.selectors';
import { customNoteSM } from 'src/state/machines/customNote/customNote.machine';
import { CustomNoteService, NotationDraft } from 'src/state/machines/customNote/customNote.types';
import { PW } from 'src/state/machines/presentation/playerWrapper';
import { RootState, store } from 'src/state/redux';
import { userNotationsActions } from 'src/state/redux/slice/userNotations';
import { useDocumentVersionORM } from 'src/state/redux/selector/document';
import { useFolderItemORMById } from 'src/state/redux/selector/folder';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { useContentPreviewModal } from 'src/components/ContentPreviewModalV2/State/ContentPreviewModalStateProvider';
import { isFolderItemORM, isCustomDeckORM, isDocumentVersionORM } from 'src/types/typeguards';
import * as logger from 'src/utils/logger';

type ICustomNotesContext = {
  service: CustomNoteService
  createNoteWithHighlight: () => void
  createNote: () => void
  cancelEdit: () => void
  deleteNote: (notationId: string) => void
  editNote: (notationId: string) => void
  saveNote: (description: string) => void
  setCurrentActiveNotationId: (notationId: string) => void
  descriptionRef: React.MutableRefObject<string>
  currentPageNotationsInfo: {
    currentPageNotationsLevelMap: CurrentPageNotationsLevelMap
    hasMeetNotationLimits: boolean
  }
}

const presentationStateChannel = new BroadcastChannel<PW.PresentationChannelMessage>('PRESENTATION_CHANNEL')

const CustomNotesContext = React.createContext<ICustomNotesContext>(undefined!)
export const useCustomNotes = () => useContext(CustomNotesContext)

const CustomNotesProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  const { activePresentation, meetingId, addPresentation, updateNotations, updateFolderItemORM } = useContent()

  const { isPublisher } = useContentPreviewModal()
  const modalState = useSelector((state: RootState) => state.contentPreviewModal)
  const modalStateDocVerORM = activePresentation?.currentPresentablePage.documentVersionORM
  const modalStateORM = activePresentation?.presentable.orm
  const isFolderItem = isFolderItemORM(modalStateORM)
  const isCustomDeck = isFolderItem && isCustomDeckORM(modalStateORM.relations.itemORM)
  const folderItemSearchParam = useMemo(() => {
    return isFolderItem ? { id: modalStateORM?.model.id, folderId: modalStateORM.relations.parentORM?.model.id } : {}
  }, [isFolderItem, modalStateORM])
  const modalStateSourceDocUserNotations = modalStateDocVerORM?.relations.userNotations
  const modalStateFolderItemUserNotations = isFolderItem && isCustomDeckORM(modalStateORM.relations.itemORM)
    ? modalStateORM.relations.itemORM.relations.userNotations
    : isFolderItem && isDocumentVersionORM(modalStateORM.relations.itemORM)
      ? modalStateORM.relations.itemORM.relations.userNotations
      : undefined
  const isMeetingHistoryMode = !!modalState.meetingId
  // In customDeckORMToPreview function inside PresentationBuilderStateProvider
  // preview mode's customDeckORM does not have all details in model
  const isCustomDeckPreviewMode = isCustomDeck && !modalStateORM?.relations.itemORM.model.createdBy
  const isLatestPublishedVersion = modalStateDocVerORM?.meta.version.isLatestPublished
  const isReadOnlyMode = isMeetingHistoryMode || isPublisher || isCustomDeckPreviewMode || !isLatestPublishedVersion
  const descriptionRef = useRef<string>('')

  /** STATE MACHINE */
  const machineInstance = useLazyRef(() => customNoteSM.withContext({
    meetingId: meetingId ?? 'customNoteSM',
    presentationStateChannel,
    isReadOnlyMode,
    customDeckId: isCustomDeck ? modalStateORM.relations.itemORM.model.id : undefined,
  }))
  const service = useInterpret(
    machineInstance.current!,
    // { devTools: true },
  )

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      customNotesSelector.presentableState,
      customNotesSelector.notationDraft,
      customNotesSelector.isIdleState,
      customNotesSelector.isSetCoordinateMode,
      customNotesSelector.isEditingNoteState,
      customNotesSelector.isCreatingNoteState,
      customNotesSelector.isSaveNotationState,
      customNotesSelector.isDeletingNoteState,
      customNotesSelector.customDeckId,
      customNotesSelector.currentPageNotationsInfo,
      customNotesSelector.isReadOnlyMode,
    ),
  )

  const currentPageNotationsInfo = cond.currentPageNotationsInfo(activePresentation)

  // SOURCE DOCUMENT
  const documentVersionId = cond.presentableState?.documentVersionId || ''
  const reduxStoreDocVerORM = useDocumentVersionORM(documentVersionId)
  const reduxStoreSourceDocUserNotations = reduxStoreDocVerORM?.relations.userNotations
  const reduxStoreSourceDocUserNotationsRef = useRef(reduxStoreSourceDocUserNotations)

  // CUSTOM DECK
  const reduxStoreFolderItemORM = useFolderItemORMById(folderItemSearchParam)
  const reduxStoreFolderItemUserNotations = isFolderItem && isCustomDeckORM(reduxStoreFolderItemORM?.relations.itemORM)
    ? reduxStoreFolderItemORM?.relations.itemORM.relations.userNotations
    : isFolderItem && isDocumentVersionORM(reduxStoreFolderItemORM?.relations.itemORM)
      ? reduxStoreFolderItemORM?.relations.itemORM.relations.userNotations
      : undefined
  const reduxStoreCustomDeckUserNotationsRef = useRef(reduxStoreFolderItemUserNotations)
  const isCustomDeckRef = useRef(!!cond.customDeckId)

  const isCreatingNoteStateRef = useRef(cond.isCreatingNoteState)
  const isEditingNoteStateRef = useRef(cond.isEditingNoteState)
  const notationDraftRef = useRef(cond.notationDraft)

  useEffect(() => {
    isCreatingNoteStateRef.current = cond.isCreatingNoteState
    isEditingNoteStateRef.current = cond.isEditingNoteState
    notationDraftRef.current = cond.notationDraft
    isCustomDeckRef.current = !!cond.customDeckId
    reduxStoreSourceDocUserNotationsRef.current = reduxStoreSourceDocUserNotations
    reduxStoreCustomDeckUserNotationsRef.current = reduxStoreFolderItemUserNotations
  }, [
    cond.isCreatingNoteState,
    cond.isEditingNoteState,
    cond.notationDraft,
    cond.customDeckId,
    reduxStoreSourceDocUserNotations,
    reduxStoreFolderItemUserNotations,
  ])

  useEffect(() => {
    // THIS BLOCK HANDLES ADDING/REMOVING TEMPORARY YELLOW DOT TO PLAYER WHEN CREATING NOTATION
    if (cond.isCreatingNoteState && cond.notationDraft?.coordinate) {
      const temporaryNotation: Notation = {
        id: '',
        type: NotationType.CALLOUT,
        description: '',
        status: HubSectionItemStatus.ACTIVE,
        pageId: cond.notationDraft.pageId!,
        coordinate: cond.notationDraft.coordinate,
        createdAt: '',
        createdBy: '',
        updatedAt: '',
        updatedBy: '',
      }
      updateNotations([temporaryNotation])
    } else if (cond.presentableState?.notations) updateNotations()
  }, [cond.isCreatingNoteState])

  useEffect(() => {
    /* --------------------------FOR SOURCE DECK--------------------------------- */
    /*   THIS BLOCK HANDLES UPDATING THE PRESENTATION/PRESENTABLE IN MODAL STATE  */
    /*   IF THE NOTATIONS IS DIFFERENT THAN REDUX STORE NOTATIONS                 */
    /*   THIS ONLY APPLIES FOR AFTER USER MAKE CHANGES TO THE NOTES               */
    /* -------------------------------------------------------------------------- */
    const pageNum = cond.presentableState?.state.page
    if (!isFolderItem &&
      reduxStoreDocVerORM &&
      pageNum &&
      !isReadOnlyMode &&
      reduxStoreDocVerORM.model.id === modalStateDocVerORM.model.id &&
      !equal(reduxStoreSourceDocUserNotations?.notation, modalStateSourceDocUserNotations?.notation)
    ) addPresentation(reduxStoreDocVerORM, pageNum)
  }, [
    isFolderItem,
    service,
    cond.presentableState,
    reduxStoreSourceDocUserNotations,
    modalStateSourceDocUserNotations,
  ])

  useEffect(() => {
    /* --------------------------FOR FOLDER ITEM--------------------------------- */
    /*   THIS BLOCK HANDLES UPDATING THE PRESENTATION/PRESENTABLE IN MODAL STATE  */
    /*   IF THE NOTATIONS IS DIFFERENT THAN REDUX STORE NOTATIONS                 */
    /*   THIS ONLY APPLIES FOR AFTER USER MAKE CHANGES TO THE NOTES               */
    /* -------------------------------------------------------------------------- */
    if (isFolderItem &&
      reduxStoreFolderItemORM &&
      !isReadOnlyMode &&
      reduxStoreFolderItemORM.model.id === modalStateORM.model.id &&
      !equal(reduxStoreFolderItemUserNotations?.notation, modalStateFolderItemUserNotations?.notation)
    ) updateFolderItemORM(reduxStoreFolderItemORM)
  }, [
    isFolderItem,
    service,
    cond.presentableState,
    reduxStoreFolderItemUserNotations,
    modalStateFolderItemUserNotations,
    reduxStoreFolderItemORM,
  ])

  useEffect(() => {
    /* --------------------------FOR CUSTOM DECK--------------------------------- */
    /*  THIS BLOCK UPDATES MACHINE CONTEXT THAT PASSED IN WHEN INIT THE MACHINE   */
    /*  IF MY SPEAKER NOTE TAB IS OPEN BEFORE THE CONTENT PREVIEW MODAL IS LOADED */
    /*          CUSTOM NOTE MACHINE COULD INIT WITHOUT THE CORRECT INFO           */
    /* -------------------------------------------------------------------------- */
    if ((isCustomDeck && !cond.customDeckId) || (isReadOnlyMode !== cond.isReadOnlyMode)) {
      service.send({
        type: 'SYNC_MACHINE_INFO',
        customDeckId: isCustomDeck ? modalStateORM?.relations.itemORM.model.id : undefined,
        isReadOnlyMode,
      })
    }
  }, [isCustomDeck, modalStateORM, cond.customDeckId, isReadOnlyMode, cond.isReadOnlyMode, service])

  useEffect(() => {
    // THIS BLOCK HANDLES SAVING/DELETING THE NOTE IF USER IS IN 'SAVE NOTATION STATE'
    if (cond.isSaveNotationState) {
      if (!cond.notationDraft) {
        const errorText = 'Something went wrong, cannot find notationDraft while saving notation'
        logger.userNotations.error(errorText)
        throw new Error(errorText)
      }
      handleSaveNoteToDataStore(
        cond.notationDraft,
        isCustomDeck ? reduxStoreFolderItemUserNotations : reduxStoreSourceDocUserNotations,
      )
        .then(() => service.send({ type: 'RECORD_SAVED' }))
    } else if (cond.isDeletingNoteState) {
      if (!cond.notationDraft) {
        const errorText = 'Something went wrong, cannot find notationDraft while deleting notation'
        logger.userNotations.error(errorText)
        throw new Error(errorText)
      }

      if (isCustomDeck) {
        if (!reduxStoreFolderItemUserNotations) {
          const errorText = 'Something went wrong, cannot find customDeck userNotations record while deleting notation'
          logger.userNotations.error(errorText)
          throw new Error(errorText)
        }
        handleDeleteNoteFromDataStore(cond.notationDraft, reduxStoreFolderItemUserNotations)
          .then(() => service.send({ type: 'RECORD_SAVED' }))
      } else {
        if (!reduxStoreSourceDocUserNotations) {
          const errorText = 'Something went wrong, cannot find sourceDoc userNotations record while deleting notation'
          logger.userNotations.error(errorText)
          throw new Error(errorText)
        }
        handleDeleteNoteFromDataStore(cond.notationDraft, reduxStoreSourceDocUserNotations)
          .then(() => service.send({ type: 'RECORD_SAVED' }))
      }
    }
  }, [
    service,
    isCustomDeck,
    cond.isSaveNotationState,
    cond.notationDraft,
    reduxStoreSourceDocUserNotations,
    reduxStoreFolderItemUserNotations,
  ])

  const eventsToMonitor = ['click', 'press']
  // This clickHandler is for when user click outside the iframe, we want to exit out the state
  const clickHandler = useCallback(event => {
    const playerIframe = document.getElementById(`player-iframe-${documentVersionId}`)
    if (event.target !== playerIframe) service.send({ type: 'BACK_TO_IDLE' })
  }, [service, documentVersionId])

  useEffect(() => {
    // Listen to any click/press event during set coordinate mode
    if (cond.isSetCoordinateMode) {
      eventsToMonitor.forEach(event => document.addEventListener(event, clickHandler, true))
    } else eventsToMonitor.forEach(event => document.removeEventListener(event, clickHandler, true))
    return () => {
      eventsToMonitor.forEach(event => document.removeEventListener(event, clickHandler, true))
    }
  }, [cond.isSetCoordinateMode])

  useEffect(() => {
    // Clean up function when the machine stops
    const onStop = () => {
      // Clean up any event listeners
      eventsToMonitor.forEach(event => document.removeEventListener(event, clickHandler, true))
      // Inform player machine to exit out notation mode
      presentationStateChannel.postMessage({
        type: 'TOGGLE_NOTATIONS',
        meetingId: meetingId ?? 'customNoteSM',
        toggleOn: false,
      })

      // If user is in the middle of creating or editing note, save the note
      if (
        (isCreatingNoteStateRef.current || isEditingNoteStateRef.current) &&
        descriptionRef.current &&
        notationDraftRef.current
      ) {
        const newNotationDraft = { ...notationDraftRef.current, description: descriptionRef.current }
        handleSaveNoteToDataStore(
          newNotationDraft,
          isCustomDeckRef.current
            ? reduxStoreCustomDeckUserNotationsRef.current
            : reduxStoreSourceDocUserNotationsRef.current,
        )
      }
    };

    service.onStop(onStop);
  }, [service]);

  /* FUNCTIONS START */
  const createNote = useCallback(() => {
    service.send({ type: 'CREATE_NOTE' })
  }, [service])

  const createNoteWithHighlight = useCallback(() => {
    service.send({ type: 'ENTER_SET_COORDINATE_MODE' })
  }, [service])

  const cancelEdit = useCallback(() => {
    service.send({ type: 'BACK_TO_IDLE' })
  }, [service])

  const deleteNote = useCallback((notationId: string) => {
    service.send({ type: 'DELETE_NOTE', notationId })
  }, [service])

  const editNote = useCallback((notationId: string) => {
    service.send({ type: 'EDIT_NOTE', notationId })
  }, [service])

  const saveNote = useCallback((description: string) => {
    service.send({ type: 'SAVE_NOTE', description })
  }, [service])

  const setCurrentActiveNotationId = useCallback((notationId?: string) => {
    service.send({ type: 'SET_CURRENT_ACTIVE_NOTATION', notationId })
  }, [service])

  /**
   * @function handleSaveNoteToDataStore
   * @param notationDraft
   * @param userNotations: optional
   * @description If there is notation id in notationDraft and userNotations,
   * it means it is editing and saving to an existing record.
   * Else it will create a new notation record.
   * It also does error handling if missing required data.
  */
  const handleSaveNoteToDataStore = async (
    notationDraft: NotationDraft,
    userNotations?: UserNotations,
  ): Promise<void> => {
    if (!ActiveUser.user) {
      const errorText = 'Something went wrong, cannot find current user when trying to save to dataStore.'
      logger.userNotations.error(errorText)
      throw new Error(errorText)
    }
    if (!notationDraft.description) {
      const errorText = 'Something went wrong, missing description in notationDraft when trying to save to dataStore.'
      logger.userNotations.error(errorText)
      throw new Error(errorText)
    }

    if (notationDraft.id && userNotations) {
      // HAVE EXISTING USERNOTATIONS, EDITING EXISTING NOTATION
      store.dispatch(userNotationsActions.updateNotation({
        userNotations,
        notationId: notationDraft.id,
        notationDescription: notationDraft.description.trim(),
      }))
    } else {
      if (!notationDraft.pageId) {
        const errorText = 'Something went wrong, missing pageId in notationDraft when trying to save to dataStore.'
        logger.userNotations.error(errorText)
        throw new Error(errorText)
      }
      // CREATE NEW NOTATIOM
      const isPageNote = !notationDraft.coordinate
      const notationType = isPageNote
        ? NotationType.PAGE_NOTE
        : NotationType.CALLOUT

      const newNotation: Notation = {
        id: uuid(),
        type: notationType,
        description: notationDraft.description.trim(),
        status: HubSectionItemStatus.ACTIVE,
        pageId: notationDraft.pageId,
        coordinate: notationDraft.coordinate,
        createdAt: new Date().toISOString(),
        createdBy: ActiveUser.user.id,
        updatedAt: new Date().toISOString(),
        updatedBy: ActiveUser.user.id,
      }

      const documentId = notationDraft.documentId
      const documentVersionId = notationDraft.documentVersionId
      const customDeckId = notationDraft.customDeckId

      analytics?.track('USERNOTES_CREATED', {
        action: 'CREATE',
        category: 'USERNOTES',
        documentId,
        documentVersionId,
        customDeckId,
        notationId: newNotation.id,
        notationType,
      });

      if (userNotations) {
        // HAVE EXISTING USERNOTATIONS, UPDATE NOTATION
        store.dispatch(userNotationsActions.updateNotation({
          userNotations,
          notation: newNotation,
        }))
      } else {
        // NEED TO CREATE NEW USERNOTATIONS
        store.dispatch(userNotationsActions.createUserNotations({
          documentId,
          documentVersionId,
          customDeckId,
          notation: [newNotation],
          type: customDeckId ? UserNotationsType.CUSTOM_DECK : UserNotationsType.DOCUMENT_VERSION,
        }))
      }
    }
  }

  /**
   * @function handleDeleteNoteFromDataStore
   * @param notationDraft
   * @param userNotations
   * @description delete notation in userNotations record.
   * If there is no notation id in notationDraft, it will error out.
   * It also does error handling if missing required data.
  */
  const handleDeleteNoteFromDataStore = async (
    notationDraft: NotationDraft,
    userNotations: UserNotations,
  ): Promise<void> => {
    if (!notationDraft.id) {
      const errorText = 'Something went wrong, missing id in notationDraft when trying to delete from dataStore.'
      logger.userNotations.error(errorText)
      throw new Error(errorText)
    }
    store.dispatch(userNotationsActions.deleteNotation({
      userNotations,
      notationId: notationDraft.id,
    }))
  }
  /* FUNCTIONS END */

  const contextValue: ICustomNotesContext = {
    service,
    createNote,
    createNoteWithHighlight,
    cancelEdit,
    deleteNote,
    editNote,
    saveNote,
    setCurrentActiveNotationId,
    descriptionRef,
    currentPageNotationsInfo,
  }

  return (
    <CustomNotesContext.Provider value={contextValue}>
      {children}
    </CustomNotesContext.Provider>
  )
}

export default CustomNotesProvider
