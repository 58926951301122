import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { FormValuesType, useComposableForm } from 'src/components/CustomFields/ComposableForm';
import { StandaloneForm, useGetFormConfigFromIndexDB } from 'src/state/redux/selector/crm';
import { CustomFormRecordORM } from 'src/types/orms';
import {
  CrmSyncStatus,
  CustomFormRecord,
  CustomFormRecordCRMFields,
  CustomFormRecordRelationshipModel,
  CustomFormRecordStatus,
} from '@alucio/aws-beacon-amplify/src/models';
import { CreateCustomFormRecordPayload, customFormRecordActions } from 'src/state/redux/slice/customFormRecord';
import { formToModel } from 'src/components/CustomFields/ComposableFormUtilities';
import { CUSTOM_FORM_RECORD_ENTITY } from '@alucio/aws-beacon-amplify/src/API';
import {
  CRMSubmitResponseReferences,
  CRMSubmitStandaloneFormPayload,
  HANDLED_SYNC_ERROR,
  RecordSubmitResponse,
  SYNC_STATUS,
} from 'src/classes/CRM/CRMIndexedDBTypes';
import { MEETING_SUBMIT_TYPE } from 'src/components/Meeting/AddMeetingProvider';
import { useUserTenant } from '../../redux/selector/user';
import { useRefreshToken } from '../../../screens/Profile/CRMIntegration';
import { useCustomFormRecordById } from '../../redux/selector/customFormRecord';
import { handleCRMErrorResponses } from '../Meetings/saveMeetingUtilities';
import { performCRMSubmit } from '../Meetings/saveMeetingHelper';
import * as logger from 'src/utils/logger'

interface Props {
  parentId?: string;
  parentModel?: CustomFormRecordRelationshipModel;
  recordId?: string;
  standaloneForm: StandaloneForm;
  onSave?: (recordId: string) => void,
}

interface SubmitPayload {
  recordORM?: CustomFormRecordORM;
  name?: string;
  values: FormValuesType;
}

export function useRemoveCustomFormRecordUtil() {
  const dispatch = useDispatch();

  const removeCustomFormRecord = (customRecord: CustomFormRecord): void => {
    dispatch(customFormRecordActions.deleteCustomFormRecord(customRecord));
  };

  return removeCustomFormRecord;
}

interface HookResponse {
  isSaving: boolean,
  errorMessage?: string,
  setErrorMessage: (err: string) => void,
  saveCustomForm: (payload: SubmitPayload) => void,
  submitToCRMCustomForm: (payload: SubmitPayload) => void,
}

export const useSaveCustomFormRecord = (props: Props): HookResponse => {
  const { standaloneForm, recordId, parentId, parentModel, onSave } = props;
  const { rhForm } = useComposableForm();
  const recordFormORM = useCustomFormRecordById(recordId);
  // WE FIRST SAVE IN BEACON AND, WHEN THE ORM IS UPDATED, WE SUBMIT TO CRM //
  const isSavingSubmittingFlag = useRef<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const refreshToken = useRefreshToken();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const dispatch = useDispatch();
  const tenant = useUserTenant();
  const formSettings = useGetFormConfigFromIndexDB();

  // ** PERFORMS A SUBMIT TO CRM ONCE THE ORM IS UPDATED AFTER A SAVE ** //
  useEffect(() => {
    // NOTE: WE'RE ACCESSING THE "_version" OF THE ORM TO AVOID USING A MODEL WITHOUT IT
    // (THE FIRST OBJECT WE RECEIVE FROM DATASTORE (ON CREATION), DOESN'T INCLUDE IT)
    if (isSavingSubmittingFlag.current && recordFormORM && recordFormORM?.model['_version']) {
      isSavingSubmittingFlag.current = false;
      handleSubmitToCRM(recordFormORM);
    }
  }, [recordFormORM]);

  // ** RESETS THE ERRORS UPON NEW SAVING ** //
  useEffect(() => {
    if (isSaving) {
      setErrorMessage(undefined);
    }
  }, [isSaving]);

  // ** SAVES IN BEACON ** //
  const saveCustomForm = (submitPayload: SubmitPayload): void => {
    const preprocessedDraft: CreateCustomFormRecordPayload['draft'] = {
      parentId,
      parentModel,
      entity: standaloneForm.type,
      customFormId: standaloneForm.id,
      name: submitPayload.name ||
        submitPayload.recordORM?.model.name || standaloneForm.name,
      values: formToModel(submitPayload.values),
    };

    // ADDS ADDITIONAL FIELDS IF NECESSARY
    const draft = processDraft(submitPayload, preprocessedDraft, standaloneForm);
    logger.saveCustomFormRecord.debug('Upserting CustomFormRecord ', draft);

    if (!submitPayload.recordORM) {
      dispatch(customFormRecordActions.createCustomFormRecord({ draft, onSave }));
    } else {
      dispatch(customFormRecordActions.updateCustomFormRecord(
        { draft, customFormRecord: submitPayload.recordORM.model, onSave }));
    }
    rhForm.reset({ ...rhForm.getValues() }, { keepDirty: false });
  };

  // ** FIRST CALLS TO SAVE IN BEACON TO LATER SUBMIT TO CRM ** //
  const submitToCRMCustomForm = (submitPayload: SubmitPayload): void => {
    isSavingSubmittingFlag.current = true;
    setIsSaving(true);
    saveCustomForm(submitPayload);

    analytics?.track('TENANT_FORM_SUBMIT', {
      category: 'STANDALONE',
    });
  }

  // ** SUBMITS TO CRM ** //
  const handleSubmitToCRM = async (recordFormORM: CustomFormRecordORM): Promise<void> => {
    try {
      if (tenant && formSettings?.length) {
        const payload: CRMSubmitStandaloneFormPayload = {
          isSubmit: true,
          standaloneForm,
          recordFormORM,
          tenant,
          formSettings,
        };
        // ** SUBMITS TO CRM ** //
        const crmSubmitResponse = await performCRMSubmit(payload, refreshToken);
        // ** SAVES IN BEACON ** //
        handleCRMResponse(recordFormORM, crmSubmitResponse);
      }
    } catch (e) {
      const errorMessage = 'An error occurred while submitting to CRM.';
      // NON-HANDLED ERROR FROM SALESFORCE RESPONSE
      if (e instanceof ErrorEvent) {
        setErrorMessage(`${errorMessage} ${e.message || e.error}`);
      } else if (e instanceof Error) {
        // ERROR THROWN BY BEACON (EX: MISSING CONFIG)
        setErrorMessage(errorMessage);
      }
      setIsSaving(false);
      isSavingSubmittingFlag.current = false;
      logger.saveCustomFormRecord.debug(errorMessage, e);
    }
  }

  // ** HANDLES CRM RESPONSE ** //
  const handleCRMResponse = (recordFormORM: CustomFormRecordORM, crmSubmitResponse: CRMSubmitResponseReferences) => {
    logger.saveCustomFormRecord.debug('Handling CRMResponse to update in Beacon', { recordFormORM, crmSubmitResponse });
    // ** UPDATES IN BEACON ** //
    updateCustomFormRecordsFromCRMResponse(
      [recordFormORM], crmSubmitResponse, dispatch, MEETING_SUBMIT_TYPE.SUBMIT_LOCK_TO_CRM);

    // ** CHECKS AND HANDLES IF THERE'S AN ERROR ** //
    const error = handleCRMErrorResponses(crmSubmitResponse, true);
    if (error) {
      setErrorMessage(error);
    }
    setTimeout(() => {
      setIsSaving(false);
    }, 500)
  };

  return {
    errorMessage,
    isSaving,
    saveCustomForm,
    setErrorMessage,
    submitToCRMCustomForm,
  };
}

interface DRAFT_PROCESSOR {
  [handler: string]: (submitPayload: SubmitPayload,
      draft:CreateCustomFormRecordPayload['draft'],
      standaloneForm: StandaloneForm) => CreateCustomFormRecordPayload['draft']
}

const DRAFT_PROCESSOR: DRAFT_PROCESSOR = {
  [CUSTOM_FORM_RECORD_ENTITY.CRM_OBJECT]: CRM_DRAFT_PROCESSOR,
  [CUSTOM_FORM_RECORD_ENTITY.SURVEY_TARGET]: CRM_DRAFT_PROCESSOR,
};

// ADDS ADDITIONAL DETAILS TO THE CUSTOMFORMRECORD'S DRAFT IF REQUIRED
function processDraft(
  submitPayload: SubmitPayload,
  draft:CreateCustomFormRecordPayload['draft'],
  standaloneForm: StandaloneForm): CreateCustomFormRecordPayload['draft'] {
  return DRAFT_PROCESSOR[standaloneForm.type]?.(submitPayload, draft, standaloneForm) || draft;
}

function CRM_DRAFT_PROCESSOR(
  submitPayload: SubmitPayload,
  draft:CreateCustomFormRecordPayload['draft'],
  standaloneForm: StandaloneForm): CreateCustomFormRecordPayload['draft'] {
  const crmFields: CustomFormRecordCRMFields = {
    syncStatus: CrmSyncStatus.NOT_SYNCED,
    apiName: standaloneForm.apiName || '',
    recordTypeId: standaloneForm.crmFormSetting?.recordTypeId,
    ...submitPayload.recordORM?.model.crmFields,
    accountId: typeof submitPayload.values.Account_vod__c === 'string'
      ? submitPayload.values.Account_vod__c : submitPayload.recordORM?.model.crmFields?.accountId,
  };

  return {
    ...draft,
    crmFields,
  };
}

// ** UPON SAVING TO CRM, THIS FUNCTION WILL UPDATE THE RECORDS ON DYNAMO ** //
export function updateCustomFormRecordsFromCRMResponse(
  crmCustomFormRecords: CustomFormRecordORM[],
  crmSubmitResponse: CRMSubmitResponseReferences,
  dispatch: Dispatch,
  submitType?: MEETING_SUBMIT_TYPE): void {
  crmCustomFormRecords.forEach((record) => {
    const recordCRMResponse = crmSubmitResponse[record.model.id];
    if (recordCRMResponse) {
      const { status, syncStatus } = getCustomFormRecordStatus(record, recordCRMResponse, submitType);

      dispatch(customFormRecordActions.updateFromCRMResponse(
        {
          customFormRecord: record.model,
          status,
          externalId: recordCRMResponse.externalId,
          syncStatus,
        }));
    } else {
      logger.saveCustomFormRecord.log('CustomFormRecord not update on CRM', record);
    }
  });
}

interface UpdatedCustomFormRecordStatus {
  syncStatus: CrmSyncStatus;
  status: CustomFormRecordStatus;
}

function getCustomFormRecordStatus(record: CustomFormRecordORM,
  recordCRMResponse: RecordSubmitResponse, submitType?: MEETING_SUBMIT_TYPE): UpdatedCustomFormRecordStatus {
  const updatedStatus: UpdatedCustomFormRecordStatus = {
    syncStatus: record.model.crmFields?.syncStatus as CrmSyncStatus,
    status: record.model.status as CustomFormRecordStatus,
  };

  switch (recordCRMResponse.syncStatus) {
    case SYNC_STATUS.DELETED: {
      updatedStatus.syncStatus = CrmSyncStatus.DELETED;
      updatedStatus.status = CustomFormRecordStatus.DELETED;
      break;
    }

    case SYNC_STATUS.ERROR: {
      const deletedInCRM = recordCRMResponse.handledError === HANDLED_SYNC_ERROR.ENTITY_DELETED;

      if (deletedInCRM) {
        updatedStatus.syncStatus = CrmSyncStatus.DELETED;
        updatedStatus.status = CustomFormRecordStatus.DELETED;
      }
      break;
    }

    case SYNC_STATUS.SYNCED: {
      updatedStatus.syncStatus = CrmSyncStatus.SYNCED;
      break;
    }
  }

  // LOCK THE RECORD //
  const mainRecordSubmittedError = recordCRMResponse['main-record']?.handledError ===
    HANDLED_SYNC_ERROR.RECORD_SUBMITTED;
  const alreadySubmitted = recordCRMResponse.handledError === HANDLED_SYNC_ERROR.RECORD_SUBMITTED;
  const submittedToCRM = submitType === MEETING_SUBMIT_TYPE.SUBMIT_LOCK_TO_CRM &&
    recordCRMResponse.syncStatus === SYNC_STATUS.SYNCED;
  if (alreadySubmitted || submittedToCRM || mainRecordSubmittedError) {
    updatedStatus.status = CustomFormRecordStatus.LOCKED;
  }

  return updatedStatus;
}

export default useSaveCustomFormRecord;
