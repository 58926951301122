import { useLDClient, LDClient } from 'launchdarkly-react-client-sdk'
import { Tenant } from '@alucio/aws-beacon-amplify/src/models';
import { useUserTenant } from 'src/state/redux/selector/user';
import {
  FeatureFlags,
  FeatureFlagKeys,
} from 'src/types/featureFlags'

function getFeatureFlagValue<
  T extends FeatureFlagKeys,
  RV extends typeof FeatureFlags[T]['defaultValue']
>(
  alias: T,
  LDClient?: LDClient,
  currentTenant?: Tenant,
): RV {
  const { flag, defaultValue } = FeatureFlags[alias]

  // 1. Tenant config takes precedence
  let configValue = currentTenant
    ?.featureFlags
    ?.find(tenantFlag => tenantFlag.flagId === flag)
    ?.value

  // 2. Fallback to LD value
  // 3. Fallback to hardcoded defaultValue
  if (typeof configValue !== 'boolean') {
    configValue = LDClient?.variation(flag, defaultValue)
  }

  return configValue as RV
}

type MappedFeatureFlags = { [K in FeatureFlagKeys]: typeof FeatureFlags[K]['defaultValue'] }

function useFeatureFlags():MappedFeatureFlags
function useFeatureFlags<T extends FeatureFlagKeys>(key: T):
  typeof FeatureFlags[T]['defaultValue']
function useFeatureFlags<T extends FeatureFlagKeys>(...keys: T[]):
  { [K in T]: typeof FeatureFlags[K]['defaultValue']}
function useFeatureFlags<
  T extends FeatureFlagKeys,
  S extends typeof FeatureFlags[T]['defaultValue'],
  M extends { [K in T]: typeof FeatureFlags[K]['defaultValue']}
>(
  ...keys: T[]
): (
  | MappedFeatureFlags
  | S
  | M
) {
  const LDClient = useLDClient();
  const currentTenant = useUserTenant();

  // Case 1: Return all flags
  if (!keys.length) {
    const rv = Object
      .keys(FeatureFlags)
      .reduce<MappedFeatureFlags>(
        (acc, alias) => {
          const typedAlias = alias as FeatureFlagKeys
          return {
            ...acc,
            [alias]: getFeatureFlagValue(typedAlias, LDClient, currentTenant),
          }
        },
        {} as MappedFeatureFlags,
      )

    return rv
  }

  // Case 2: Return single flag value
  if (keys.length === 1) {
    const alias = keys[0]
    const val = getFeatureFlagValue(alias, LDClient, currentTenant)
    return val as S
  }

  // Case 3: Return mapped flags + values
  else {
    const rv = keys
      .reduce<M>(
        (acc, alias) => {
          const typedAlias = alias as FeatureFlagKeys
          return {
            ...acc,
            [alias]: getFeatureFlagValue(typedAlias, LDClient, currentTenant),
          }
        },
        {} as M,
      )

    return rv
  }
}

export default useFeatureFlags
