import { ControlType, CustomFieldDefinition, CustomFieldValueDefinition } from '@alucio/aws-beacon-amplify/src/models';
import { TABLES } from 'src/classes/CRM/Translators/VeevaTranslatorUtils';
import { Singleton as IndexDbCrm } from 'src/classes/CRM/CRMIndexedDB';
import { CRMAccount } from 'src/classes/CRM/CRMIndexedDBTypes';
import { toORMAccount } from 'src/screens/Profile/CRMIntegration';
import { CRMSyncStatus } from 'src/state/machines/CRM/crmMachineTypes';
import { orderArray } from 'src/utils/arrayHelpers';
import { FORM_SOURCE, Props } from './useCustomFormAdditionalSetting';

interface ADDITIONAL_HANDLER_TYPE {
  [table: string]: (props: Props) => Promise<CustomFieldDefinition[]>
}

const ADDITIONAL_SETTINGS_HANDLERS: ADDITIONAL_HANDLER_TYPE = {
  [TABLES.MEDICAL_INQUIRY]: handleStandaloneMedicalInquiries,
  [TABLES.MEDICAL_INSIGHT]: handleStandaloneMedicalInsights,
};

export const handleVeevaAdditionalFormSettings = async (props: Props): Promise<CustomFieldDefinition[]> =>
  ADDITIONAL_SETTINGS_HANDLERS[props.standaloneForm?.apiName || '']?.(props) || props.customFields;

//* MEDICAL INSIGHTS *//
async function handleStandaloneMedicalInsights(props: Props): Promise<CustomFieldDefinition[]> {
  // GETS THE ACCOUNT'S PICKLIST //
  const accountsValues =  await addAccountsCustomValues(props);
  const isForMeetings = props.formSource === FORM_SOURCE.MEETING;

  // TREAT INDIVIDUAL FIELDS
  return props.customFields.reduce<CustomFieldDefinition[]>((acc, field) => {
    switch (field.id) {
      case 'Account_vod__c': {
        acc.push({
          ...field,
          controlType: isForMeetings ? ControlType.SELECT : field.controlType,
          fieldValueDefinitions: accountsValues,
        })
        break;
      }
      default: {
        acc.push(field);
      }
    }

    return acc;
  }, []);
}

//* MEDICAL INQUIRIES *//
async function handleStandaloneMedicalInquiries(props: Props): Promise<CustomFieldDefinition[]> {
  // GETS THE ACCOUNT'S PICKLIST //
  const accountsValues =  await addAccountsCustomValues(props);
  const isForMeetings = props.formSource === FORM_SOURCE.MEETING;

  // TREAT INDIVIDUAL FIELDS
  return props.customFields.reduce<CustomFieldDefinition[]>((acc, field) => {
    switch (field.id) {
      case 'Account_vod__c': {
        acc.push({
          ...field,
          controlType: isForMeetings ? ControlType.SELECT : field.controlType,
          fieldValueDefinitions: accountsValues,
        })
        break;
      }
      default: {
        acc.push(field);
      }
    }

    return acc;
  }, []);
}

// RETURNS AN ARRAY OF CUSTOMFIELDVALUEDEFINITION BASED ON THE GIVEN CRM ACCOUNT IDS
async function addAccountsCustomValues(props: Props): Promise<CustomFieldValueDefinition[]> {
  const accountIds = props.additionalProps?.crmAccountIds;
  const accounts = await (accountIds?.length ? IndexDbCrm.filterById('ACCOUNT', accountIds || [])
    : IndexDbCrm.getAll<CRMAccount>('ACCOUNT'));
  const orderedAccounts = orderArray<CRMAccount>(accounts, ['Formatted_Name_vod__c']);
  const ormAccounts = toORMAccount(orderedAccounts, CRMSyncStatus.SYNCED);

  const now = new Date().toISOString();

  return ormAccounts.reduce<CustomFieldValueDefinition[]>((acc, account, idx) => {
    if (!account) {
      return acc;
    }

    return [...acc, {
      id: account.model.id,
      isDefault: idx === 0,
      createdAt: now,
      dependentValueIds: [],
      label: account.meta.formattedName,
      value: account.model.id,
    }]
  }, []);
}
