import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAText, DNADivider, Iffy } from '@alucio/lux-ui/src';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { HubORM } from 'src/types/orms';
import { useAppSettings } from 'src/state/context/AppSettings';
import { SidebarOptions, tabConfigs } from 'src/state/context/Hubs/HubsStateProvider';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';

// TAB CONTENT COMPONENT
import AddMeeting from 'src/components/Meeting/AddMeeting';
import SharedWith from './RightSideBarComponents/SharedWith';

type RightSideBarProps = {
  hubORM: HubORM,
}

const styles = StyleSheet.create({
  sideBarMenu: {
    flex: 1,
    backgroundColor: colors['color-text-white'],
    borderLeftColor: colors['color-gray-80'],
    borderLeftWidth: 1,
    padding: 10,
  },
  chevronIcon: {
    height: 35,
    width: 35,
    marginTop: 8,
    marginBottom: 4,
  },
  sideBarIcon: {
    height: 35,
    width: 30,
    marginTop: 10,
  },
  tooltipText: {
    color: colors['color-text-white'],
  },
  sideBarContainerDesktop: {
    backgroundColor: colors['color-text-white'],
    borderLeftColor: colors['color-gray-80'],
    borderLeftWidth: 1,
  },
  sideBarContainerTablet: {
    backgroundColor: colors['color-text-white'],
    position: 'absolute',
    width: 'calc(100vw - 64px)',
    height: 'calc(100vh - 64px)',
    right: 64,
    zIndex: 1,
  },
});

const RightSideBarContent: React.FC<RightSideBarProps> = (props) => {
  const { hubORM } = props
  const { selectedRightSideBarOption, toggleRightSidebar } = useHubsState()
  const { deviceMode } = useAppSettings()
  const isTablet = deviceMode === 'tablet'
  const isSharedWith = selectedRightSideBarOption === SidebarOptions.SHARED_WITH_SIDE_BAR
  const isMeetingInfo = selectedRightSideBarOption === SidebarOptions.DETAILS_SIDE_BAR

  return (
    <DNABox appearance="row" style={isTablet ? styles.sideBarContainerTablet : styles.sideBarContainerDesktop}>
      <Iffy is={isSharedWith}>
        <SharedWith hubORM={hubORM} tabConfig={tabConfigs[SidebarOptions.SHARED_WITH_SIDE_BAR]} />
      </Iffy>
      <Iffy is={!!hubORM.model.meetingId && (isMeetingInfo)}>
        <AddMeeting meetingId={hubORM.model.meetingId} toggleDrawer={toggleRightSidebar} />
      </Iffy>
    </DNABox>
  )
}

const RightSideBar: React.FC<RightSideBarProps> = (props) => {
  const { hubORM } = props
  const {
    hubRightSideBarOptions,
    selectedRightSideBarOption,
    setSelectedRightSideBarOption,
    toggleRightSidebar,
  } = useHubsState()

  const disablePopover: 'tabletPWA'[] = ['tabletPWA']

  return (
    <DNABox
      testID="edit-hub-side-bar"
      appearance="row"
    >
      {/* Side Bar Content */}
      <Iffy is={selectedRightSideBarOption}>
        <RightSideBarContent hubORM={hubORM} />
      </Iffy>
      {/* Side Bar Menu */}
      <DNABox
        appearance="col"
        spacing="between"
        alignX="center"
        style={ styles.sideBarMenu }
      >
        <DNABox spacing="xs" appearance="col">
          {/* Chevron Icon */}
          <DNAPopover placement="left" disablePopover={disablePopover}>
            <DNAPopover.Anchor>
              <DNAButton
                status="tertiary"
                appearance="ghostLink"
                style={styles.chevronIcon}
                iconLeft={!selectedRightSideBarOption ? 'chevron-left' : 'chevron-right'}
                onPress={toggleRightSidebar}
                size="md"
              />
            </DNAPopover.Anchor>
            <DNAPopover.Content>
              <DNAText
                style={styles.tooltipText}
                numberOfLines={1}
              >
                {selectedRightSideBarOption ? 'Collapse' : 'Expand'}
              </DNAText>
            </DNAPopover.Content>
          </DNAPopover>
          <DNADivider />
          {hubRightSideBarOptions.map(config => {
            
            const item = config && tabConfigs[config]
            return (item &&
              <DNAPopover placement="left" key={item.key} disablePopover={disablePopover}>
                <DNAPopover.Anchor>
                  <DNAButton
                    appearance={config === selectedRightSideBarOption ? 'filled' : 'ghost'}
                    status={config === selectedRightSideBarOption ? 'primary' : 'tertiary'}
                    style={styles.sideBarIcon}
                    iconLeft={item.icon}
                    onPress={() => setSelectedRightSideBarOption(item.id)}
                    size="md"
                  />
                </DNAPopover.Anchor>
                <DNAPopover.Content>
                  <DNAText style={styles.tooltipText} numberOfLines={1}>{item?.tooltip}</DNAText>
                </DNAPopover.Content>
              </DNAPopover>)
          })}
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

export default RightSideBar
