import React from 'react'
import { useDispatch } from 'src/state/redux';
import { FolderItemORM } from 'src/types/types'
import { DNACardComponent } from 'src/components/DNA/Grid/Cards/types'

import { useAppSettings } from 'src/state/context/AppSettings';
import {
  DNAButton,
  DNABox,
  DNAText,
  DNACard,
  DNADivider,
  Iffy,
  DNAChip,
  luxColors,
} from '@alucio/lux-ui'

import DNACustomDeckContextMenu from 'src/components/DNA/Document/DNACustomDeckContextMenu'
import DNACustomDeckThumbnail from 'src/components/DNA/Document/DNACustomDeckThumbnail';
import { isCustomDeckORM } from 'src/types/typeguards'
import DNADocumentVersionUpdateButton from 'src/components/DNA/Document/DNADocumentVersionUpdateButton';
import { useDNACustomDeckActions } from 'src/components/DNA/Document/DNACustomDeck.actions';
import { CUSTOM_DECK_CARD_MAX_HEIGHT } from './constants';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage';
import { getRootFolderORM } from 'src/state/redux/selector/folder';
import { PermissionType } from '@alucio/aws-beacon-amplify/src/models';
import DNACommonConfirmation from 'src/components/DNA/Modal/DNACommonConfirmation';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import DNAConditionalButton from 'src/components/DNA/ConditionalButton/DNAConditionalButton';
import { isSharedEditableFolder } from 'src/utils/foldersHelpers';

export const DNACustomDeckCard: DNACardComponent<FolderItemORM> = ({ ORM: folderItemORM, isOnline, onPress }) => {
  const { deviceMode } = useAppSettings();
  const dispatch = useDispatch()
  const DNACustomDeckActions = useDNACustomDeckActions()

  const route = useCurrentPage({ exact: false })
  const enableCustomDecksCollaborationEdit = useFeatureFlags('enableCustomDecksCollaborationEdit');

  if (!isCustomDeckORM(folderItemORM.relations.itemORM)) { return (<></>) }

  const rootFolder =
    folderItemORM.relations.parentORM
      ? getRootFolderORM(folderItemORM.relations.parentORM)
      : folderItemORM.relations.parentORM

  const customDeckORM = folderItemORM.relations.itemORM
  const {
    assets: { isContentCached },
    version: { requiresReview, withinGracePeriod },
  } = customDeckORM.meta
  const contentAvailable = isOnline || isContentCached
  const reviewUnavailable = !isOnline && !isContentCached

  const openDeckEditor = DNACustomDeckActions.edit(customDeckORM, folderItemORM, 'REVIEW')
  const isSharedFolders = route?.PATH.includes('shared_folders')
  const isSharedEditFolder = rootFolder && isSharedEditableFolder(rootFolder) && enableCustomDecksCollaborationEdit
  const hasEditPermission = rootFolder?.meta.permission === PermissionType.EDIT
  const isReviewAllowed = hasEditPermission &&
    ((!isSharedFolders || isSharedEditFolder) || enableCustomDecksCollaborationEdit) &&
    !withinGracePeriod

  const handleCardPress = (isThumbnail: boolean) => {
    // [TODO-2126] - We should have a property that combines isOnline and isContentCached
    //               on the CustomDeckORM rather than checking both at the component level
    if (contentAvailable) {
      if (requiresReview && isReviewAllowed) {
        openDeckEditor()
      } else if (!requiresReview || withinGracePeriod) {
        isThumbnail ? DNACustomDeckActions.present(customDeckORM, folderItemORM)() : onPress?.(folderItemORM)
      } else {
        const confirmModal = () => (
          <DNACommonConfirmation
            cancelText="OK"
            title=""
            // eslint-disable-next-line max-len
            descriptionText="This document is currently pending review; only editors may access until the review has been completed."
          />
        )
        const payload = {
          isVisible: true,
          allowBackdropCancel: true,
          component: confirmModal,
        };
        dispatch(DNAModalActions.setModal(payload));
      }
    }
  }

  return (
    <DNABox
      alignX="start"
      style={{ minWidth: 272, height: CUSTOM_DECK_CARD_MAX_HEIGHT }}
    >
      <DNACard
        interactive="pressable"
        onPress={() => handleCardPress(false)}
        style={{ padding: 12 }}
      >
        <DNABox appearance="col" spacing="between" style={{ minHeight:'100%', opacity: !contentAvailable ? 0.4 : 1 }}>
          <DNACustomDeckThumbnail
            customDeckORM={customDeckORM}
            width={248}
            height={142}
            unavailableContent={!contentAvailable}
            onPress={() => handleCardPress(true)}
          />
          <DNABox appearance="col" spacing="md">
            <DNAText
              style={{ width: 208, overflow: 'hidden' }}
              numberOfLines={2}
            >
              {/* This is folderItemORM instead of customDeckORM because the title prop is in the folder item orm */}
              {folderItemORM.meta.title}
            </DNAText>
            <DNABox appearance="col" spacing="sm">
              <DNADivider />
              <DNABox alignY="center" spacing="between">
                <DNABox alignY="center" spacing="sm">
                  <DNAChip appearance="tag">MODIFIED</DNAChip>
                  <Iffy is={!contentAvailable}>
                    <DNAChip appearance="tag" style={{ backgroundColor: luxColors.basicBlack.primary }}>
                      NOT DOWNLOADED
                    </DNAChip>
                  </Iffy>
                </DNABox>
                <DNABox spacing="sm">
                  {/* ellipsis */}
                  <Iffy is={!requiresReview}>
                    <DNADocumentVersionUpdateButton orm={folderItemORM} />
                    <DNACustomDeckContextMenu folderItemORM={folderItemORM}>
                      <DNAButton
                        status="gray"
                        appearance="ghost"
                        iconLeft="dots-vertical"
                        size={deviceMode === 'tablet' ? 'xl' : 'md'}
                        padding={deviceMode === 'tablet' ? 'md' : 'sm'}
                      />
                    </DNACustomDeckContextMenu>
                  </Iffy>
                  <Iffy is={requiresReview}>
                    <DNAConditionalButton
                      appearance="outline"
                      onPress={() => handleCardPress(false)}
                      iconLeft="alert"
                      size="sm"
                      status="warning"
                    >
                      <DNAConditionalButton.Disable condition={reviewUnavailable}>
                        <DNAText status="basic">This file must be synced prior to review.</DNAText>
                      </DNAConditionalButton.Disable>
                      Review
                    </DNAConditionalButton>
                  </Iffy>
                </DNABox>
              </DNABox>
            </DNABox>
          </DNABox>
        </DNABox>
      </DNACard>
    </DNABox>
  )
}

export default DNACustomDeckCard
