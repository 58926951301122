import {
  DNABox,
  DNAButton,
  DNACard,
  DNAChip,
  DNADivider,
  DNAText,
  GridList,
  Iffy,
  util,
} from '@alucio/lux-ui';
import format from 'date-fns/format';
import React, { useCallback, useMemo, useState } from 'react';
import {
  DataProvider,
  LayoutProvider,
  RecyclerListView,
  RecyclerListViewProps,
} from 'recyclerlistview';
import { LayoutChangeEvent, StyleSheet, useWindowDimensions, ViewProps, ViewStyle } from 'react-native';
import { MeetingORM } from 'src/types/types';
import ContentPresentedList from './ContentPresentedList';
import MeetingContextMenu from './MeetingContextMenu';
import meetingQuery from 'src/state/redux/meeting/query';
import { sortCollection } from 'src/state/redux/selector/common';
import { Attendee, MeetingAttendeeStatus, AttendeeType } from '@alucio/aws-beacon-amplify/src/models';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import { drawerActions, DRAWER_ENTITIES } from 'src/state/redux/slice/drawer';
import { useDispatch } from 'src/state/redux';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useIsMeetingLocked } from './AddMeetingProvider';
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import { CRMAvailabilityStatus } from 'src/state/machines/CRM/crmMachineTypes';

const S = StyleSheet.create({
  Col: {
    paddingVertical: 12,
    alignItems: 'flex-start',
  },
  HeaderColTablet: {
    backgroundColor: colors['color-gray-10'],
  },
  PaddedContentRow: {
    paddingVertical: 12,
    paddingLeft: 30,
  },
  SortableButton: {
    minHeight: 17,
    maxHeight: 17,
    right: 8,
  },
  Row: {
    flexBasis: 'auto',
  },
  hoveredRow: {
    backgroundColor: '#F5F2F6',
    cursor: 'pointer',
  },
  MobileHeader: {
    backgroundColor: colors['color-gray-10'],
    minHeight: 49,
    height: 49,
  },
  attendeeListToggle: {
    right: 8,
  },
  contextMenu: {
    marginLeft: 15,
  },
  tableContainer: {
    overflow: 'scroll',
  },
  gridListContainer: {
    alignItems: 'flex-start',
    paddingHorizontal: 16,
  },
  desktopCardContainer: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    overflow: 'hidden',
    borderRadius: 4,
    flex: 1,
  },
  rowContainer: {
    width: '100%',
  },
});

const GRID_COL_GAP = '12px'
const GRID_COL_GAP_NUM = 12

interface MeetingHistoryListRowProps {
  meetingORM: MeetingORM,
  isCRMMeetingEnabled?: boolean,
}

const AttendeesList: React.FC<{ attendees: Attendee[] }> = ({ attendees }) => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const activePrimaryAttendee = useMemo(
    () => attendees.find(({ status, attendeeType }) =>
      status !== MeetingAttendeeStatus.REMOVED && attendeeType === AttendeeType.PRIMARY),
    [attendees],
  )
  const activeSecondaryAttendees = useMemo(
    () => attendees
      .filter(({ status, attendeeType }) =>
        status !== MeetingAttendeeStatus.REMOVED && attendeeType === AttendeeType.SECONDARY,
      )
      .sort((a, b) => a.name.localeCompare(b.name)),
    [attendees],
  )

  const toggleAttendeeList = useCallback(
    () => { setShowAll(p => !p) },
    [setShowAll],
  )

  return (
    <DNABox appearance="col" spacing="sm" fill>
      <Iffy is={activePrimaryAttendee}>
        <DNAText bold numberOfLines={1}>
          {activePrimaryAttendee?.name}
        </DNAText>
      </Iffy>
      {
        activeSecondaryAttendees
          .map((attendee) => (
            <DNAText numberOfLines={1} key={attendee.id}>
              {attendee.name}
            </DNAText>
          ))
          .filter((_, idx) => showAll || idx < (activePrimaryAttendee ? 4 : 5))
      }
      <Iffy is={activeSecondaryAttendees.length > (activePrimaryAttendee ? 4 : 5)}>
        <DNAButton
          status="primary"
          appearance="ghostLink"
          padding="none"
          onPress={toggleAttendeeList}
          style={S.attendeeListToggle}
        >
          {showAll ? 'Show less' : 'Show all'}
        </DNAButton>
      </Iffy>
    </DNABox>
  );
};

const MeetingHistoryListRow: React.FC<MeetingHistoryListRowProps> = ({ meetingORM, isCRMMeetingEnabled }) => {
  const { startTime, endTime, title, contentPresented, attendees, id, status } = meetingORM.model
  const { deviceMode } = useAppSettings()
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const isTablet = deviceMode === 'tablet'
  const dispatch = useDispatch()
  const isMeetingLocked = useIsMeetingLocked(meetingORM)

  const toggleDrawer = useCallback(
    () => {
      dispatch(drawerActions.toggle({
        entity: DRAWER_ENTITIES.MEETING,
        entityId: meetingORM.model.id,
      }));
    },
    [dispatch],
  )

  const onMouseEnter = useCallback(
    () => { setIsHovered(true) },
    [setIsHovered],
  )

  const onMouseLeave = useCallback(
    () => { setIsHovered(false) },
    [setIsHovered],
  )

  const activeStyle = useMemo(
    () => ({
      row: [S.Row, isHovered && S.hoveredRow],
      contextMenu: [S.Col, isTablet && S.contextMenu],
    }),
    [isTablet, isHovered],
  )

  const contextMenu = (
    <GridList.Col
      style={activeStyle.contextMenu}
      span={1}
    >
      <MeetingContextMenu
        isCRMMeetingEnabled={isCRMMeetingEnabled}
        meetingORM={meetingORM}
        isTablet={isTablet}
      />
    </GridList.Col>
  )

  return (
    <GridList.Row
      style={activeStyle.row}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onPress={toggleDrawer}
      testID="meeting-list-row"
    >
      {/* CONTEXT MENU */}
      {contextMenu}
      {/* START TIME */}
      <GridList.Col style={S.Col} span={1}>
        <DNABox appearance="col">
          <DNAText>{format(new Date(startTime), 'LLL d, y')}</DNAText>
          <DNAText>{format(new Date(startTime), 'p')}</DNAText>
        </DNABox>
      </GridList.Col>
      {/* END TIME */}
      <GridList.Col style={S.Col} span={1}>
        <DNABox appearance="col">
          <DNAText>{endTime ? format(new Date(endTime), 'LLL d, y') : ''}</DNAText>
          <DNAText>{endTime ? format(new Date(endTime), 'p') : ''}</DNAText>
        </DNABox>
      </GridList.Col>
      {/* TITLE */}
      <GridList.Col style={S.Col} span={1}>
        <DNABox appearance="col" spacing="sm" fill>
          <DNAText testID="meeting-title-row" numberOfLines={2}>{title}</DNAText>
          <Iffy is={isMeetingLocked}>
            <DNABox shrink>
              <DNAChip appearance="subtle">MEETING IS LOCKED</DNAChip>
            </DNABox>
          </Iffy>
        </DNABox>
      </GridList.Col>
      {/* ATTENDEES */}
      <GridList.Col style={S.Col} span={1}>
        <AttendeesList attendees={attendees} />
      </GridList.Col>
      {/* CONTENT PRESENTED */}
      <GridList.Col style={S.Col} span={1}>
        <DNABox appearance="col" fill>
          <ContentPresentedList
            isEditForm={false}
            meetingId={id}
            contentPresented={contentPresented || []}
          />
        </DNABox>
      </GridList.Col>
      {/* CRM STATUS */}
      {isCRMMeetingEnabled &&
        <GridList.Col style={S.Col} span={1}>
          <DNABox appearance="col" spacing="sm" fill alignX="center">
            <DNAChip
              testID="file-chip-badge"
              status={status === 'PLANNED' ? 'primary' : 'basic'}
              appearance="subtle"
              style={{ minWidth: 80, justifyContent: 'center' }}
            >
              {status === 'PLANNED' ? 'PLANNED' : status === 'LOCKED' ? 'LOCKED' : 'COMPLETED'}
            </DNAChip>
          </DNABox>
        </GridList.Col>}
    </GridList.Row>
  )
}

const MeetingTableContent: React.FC<MeetingTableProps> = ({ meetingORM }) => {
  const [isSortedAsc, setIsSortedAsc] = useState<boolean>(false);
  const sortedData = useMemo(
    () => {
      return isSortedAsc
        ? sortCollection(meetingORM, meetingQuery.sorts.startTimeAsc)
        : meetingORM;
    },
    [isSortedAsc, meetingORM],
  )

  const { deviceMode } = useAppSettings()
  const { availabilityStatus } = useCRMStatus();
  const dimensions = useWindowDimensions();
  const isCRMMeetingEnabled = availabilityStatus === CRMAvailabilityStatus.ENABLED
  const {
    headers,
    columnConfig,
    columnConfigMinWidth,
    headersCRM,
    columnConfigCRM,
    columnConfigCRMMinWidth,
  } = deviceModeVariants[deviceMode]
  const [headerWidth, setHeaderWidth] = useState<number>(isCRMMeetingEnabled
    ? columnConfigCRMMinWidth
    : columnConfigMinWidth,
  )

  const isDesktop = deviceMode === 'desktop';
  const enabledHeaders = isCRMMeetingEnabled
    ? (headersCRM || headers)
    : headers

  const enabledColumnConfig = isCRMMeetingEnabled
    ? columnConfigCRM
    : columnConfig

  const dataProviderInit = useMemo(
    () => (new DataProvider((prev: MeetingORM, next: MeetingORM) => prev !== next))
      .cloneWithRows(sortedData),
    [sortedData],
  );

  const measureHeaderWidth = useCallback<NonNullable<ViewProps['onLayout']>>(
    (ev) => {
      const webEvent = ev.nativeEvent as any as LayoutChangeEvent & { target: HTMLElement }
      const scrollWidth = webEvent.target.scrollWidth
      setHeaderWidth(p => p !== scrollWidth ? scrollWidth : p)
    },
    [],
  )

  const layoutProvider = useMemo(
    () => new LayoutProvider(
      () => 'DEFAULT',
      (_, dim) => {
        dim.width = 2000;
        dim.height = 60;
      },
    ),
    [],
  );

  const renderItem = useCallback<RecyclerListViewProps['rowRenderer']>(
    (type, meetingORM: MeetingORM) => {
      return (
        <>
          <DNADivider />
          <MeetingHistoryListRow
            isCRMMeetingEnabled={isCRMMeetingEnabled}
            meetingORM={meetingORM}
          />
        </>
      );
    },
    [],
  )

  const renderItemWrapper = useCallback<NonNullable<RecyclerListViewProps['renderItemContainer']>>(
    (props, parentProps, children) => {
      const { style } = props as { style: ViewProps };
      return (
        <DNABox {...props} style={{ ...style, width: '100%' }}>
          {children}
        </DNABox>
      );
    },
    [],
  )

  const startTimeSort = useCallback(
    () => {
      setIsSortedAsc((prev) => !prev)
    },
    [setIsSortedAsc],
  )

  const activeStyle = useMemo<Record<string, ViewStyle>>(
    () => ({
      headerContainer: util.mergeStyles(
        undefined,
        [S.MobileHeader, !isDesktop],
        [{ minWidth: columnConfigMinWidth }, !isCRMMeetingEnabled],
        [{ minWidth: columnConfigCRMMinWidth }, isCRMMeetingEnabled],
      ) as ViewStyle,
      listContainer: {
        flex: 1,
        width: headerWidth,
      },
      headerColPresented: [
        S.PaddedContentRow,
        !isDesktop && S.HeaderColTablet,
      ] as ViewStyle,
      headerCol: [
        S.Col,
        !isDesktop && S.HeaderColTablet,
      ] as ViewStyle,
    }),
    [
      isDesktop,
      headerWidth,
      isCRMMeetingEnabled,
      columnConfigMinWidth,
      columnConfigCRMMinWidth,
    ],
  )

  return (
    <DNABox
      fill
      style={S.tableContainer}
    >
      <GridList
        cols={enabledColumnConfig}
        gap={GRID_COL_GAP}
        style={S.gridListContainer}
      >
        <GridList.Header
          onLayout={measureHeaderWidth}
          style={activeStyle.headerContainer}
        >
          {
            enabledHeaders.map((header) => {
              const isSortable = header === 'Start Time';

              return (
                <GridList.Col
                  key={header}
                  style={
                    header === 'Content Presented'
                      ? activeStyle.headerColPresented
                      : activeStyle.headerCol
                  }
                  span={1}
                >
                  {
                    isSortable
                      ? (
                        <DNAButton
                          appearance="ghostLink"
                          style={S.SortableButton}
                          padding="none"
                          status="tertiary"
                          onPress={startTimeSort}
                          iconRight={isSortedAsc ? 'menu-up' : 'menu-down'}
                          testID={`header-sort-${header}`}
                        >
                          <DNAText b2 bold status="flat">{header}</DNAText>
                        </DNAButton>
                      )
                      : <DNAText b2 bold status="flat">{header}</DNAText>
                  }
                </GridList.Col>
              )
            })
          }
        </GridList.Header>
        {/* Meeting Rows */}
        {
          headerWidth
            ? (
              <RecyclerListView
                style={activeStyle.listContainer}
                layoutProvider={layoutProvider}
                renderAheadOffset={dimensions.height}
                dataProvider={dataProviderInit}
                rowRenderer={renderItem}
                renderItemContainer={renderItemWrapper}
                canChangeSize
                forceNonDeterministicRendering
              />
            )
            : <></>
        }
      </GridList>
    </DNABox>
  )
}

interface MeetingTableProps {
  meetingORM: MeetingORM[],
}

const MeetingTableTablet: React.FC<MeetingTableProps> = ({ meetingORM }) => {
  return <MeetingTableContent meetingORM={meetingORM} />
}

const MeetingTableDesktop: React.FC<MeetingTableProps> = ({ meetingORM }) => {
  return (
    <DNACard
      appearance="float"
      style={S.desktopCardContainer}
    >
      <MeetingTableContent meetingORM={meetingORM} />
    </DNACard>
  )
}

interface DeviceModeVariant {
  component: React.ElementType,
  headers: string[],
  columnConfig: string
  columnConfigMinWidth: number,
  headersCRM?: string[],
  columnConfigCRM?: string,
  columnConfigCRMMinWidth: number,
}

type DeviceModeVariants = Record<DeviceMode, DeviceModeVariant>
const deviceModeVariants: DeviceModeVariants = {
  desktop: {
    component: MeetingTableDesktop,
    headers: ['', 'Start Time', 'End Time', 'Title', 'Attendees', 'Content Presented'],
    headersCRM: ['', 'Start Time', 'End Time', 'Title', 'Attendees', 'Content Presented', 'Status'],
    columnConfig: [
      '50px',
      'minmax(150px,100%)',
      'minmax(150px,100%)',
      'minmax(280px,100%)',
      'minmax(180px,100%)',
      'minmax(300px,100%)',
    ].join(' '),
    columnConfigMinWidth: (50 + 150 + 150 + 280 + 180 + 300 + (6 * GRID_COL_GAP_NUM)),
    columnConfigCRM: [
      '50px',
      'minmax(150px,100%)',
      'minmax(150px,100%)',
      'minmax(280px,100%)',
      'minmax(180px,100%)',
      'minmax(300px,100%)',
      '80px',
    ].join(' '),
    columnConfigCRMMinWidth: (50 + 150 + 150 + 280 + 180 + 300 + 80 + (7 * GRID_COL_GAP_NUM)),
  },
  tablet: {
    component: MeetingTableTablet,
    headers: ['', 'Start Time', 'End Time', 'Title', 'Attendees', 'Content Presented'],
    headersCRM: ['', 'Start Time', 'End Time', 'Title', 'Attendees', 'Content Presented', 'Status'],
    columnConfig: '72px 150px 150px minmax(200px,500px) minmax(180px,500px) minmax(300px,700px)',
    columnConfigMinWidth: (72 + 150 + 150 + 200 + 180 + 300 + (6 * GRID_COL_GAP_NUM)),
    columnConfigCRM: '72px 150px 150px minmax(200px,500px) minmax(180px,500px) minmax(300px,700px) 100px',
    columnConfigCRMMinWidth: (72 + 150 + 150 + 200 + 180 + 300 + 100 + (7 * GRID_COL_GAP_NUM)),
  },
}

const MeetingTable: React.FC<MeetingTableProps> = (props) => {
  const { deviceMode } = useAppSettings()
  const CurrentDeviceModeVariant = deviceModeVariants[deviceMode].component
  return <CurrentDeviceModeVariant {...props} />
}

export default MeetingTable;
