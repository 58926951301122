import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { userTenant } from './user';
import {
  CRMStandaloneForm,
  CustomFieldDefinition,
  StandaloneFormUsage,
  Tenant, TenantFormStatus,
} from '@alucio/aws-beacon-amplify/src/models'
import { CUSTOM_FORM_RECORD_ENTITY } from '@alucio/aws-beacon-amplify/src/API';
import { FormSettings } from 'src/classes/CRM/CRMIndexedDBTypes';
import { Singleton as IndexDbCrm } from 'src/classes/CRM/CRMIndexedDB';
import { CRMHandler, FormTranslatorResponse } from 'src/classes/CRM/CRMHandler';
import { useCRMStatus, CRM_STATUS } from 'src/screens/Profile/CRMIntegration';
import { CRMAvailabilityStatus, CRMConnectionStatus, CRMSyncStatus } from 'src/state/machines/CRM/crmMachineTypes';
import { CustomFieldValuesMap } from 'src/types/orms';

export interface StandaloneForm {
  id: string,
  name: string,
  type: CUSTOM_FORM_RECORD_ENTITY,
  apiName?: string,
  crmFormSetting?: FormSettings,
}

interface StandaloneFormTranslatorResponse {
  customFields: CustomFieldDefinition[];
  defaultValues: CustomFieldValuesMap;
}

export enum FORMS {
  BEACON,
  CRM
}

interface CRMSelectorOptions {
  formSettingId?: string,
  formSettings: FormSettings[],
  formsToFetch: FORMS[],
  standaloneFormId?: string,
  usage?: StandaloneFormUsage,
}

const selOptions = (_, options: CRMSelectorOptions): CRMSelectorOptions => options;
const selRawFormConfig = (_, __, rawFormConfig: FormSettings[]) => rawFormConfig;
const selSyncingStatus = (_, __, ___, syncStatus: CRM_STATUS) => syncStatus

export const useGetFormConfigFromIndexDB = () => {
  const { canUseMeetingCRMFields } = useCRMStatus();
  const [rawFormConfig, setRawFormConfig] = useState<FormSettings[] | undefined>();

  useEffect(() => {
    async function fetchFormConfig(): Promise<void> {
      const response = await IndexDbCrm.getFormSettingsRaw();
      setRawFormConfig(response);
    }

    if (canUseMeetingCRMFields) {
      fetchFormConfig();
    }
  }, [canUseMeetingCRMFields]);

  return rawFormConfig;
};

const tenantStandaloneForms = createSelector(
  userTenant,
  selOptions,
  (tenant: Tenant | undefined, options: CRMSelectorOptions): StandaloneForm[] => {
    const standaloneForms: StandaloneForm[] = [];

    // ** CRM FORMS ** //
    if (options.formsToFetch.includes(FORMS.CRM)) {
      options.formSettings.forEach((setting) => {
        const standaloneForm: CRMStandaloneForm | undefined = tenant?.config.crmIntegration?.crmStandaloneForms
          ?.find(({ apiName }) => apiName === setting.apiName);
        const hasValidUsage = !options.usage || (options.usage && standaloneForm?.usage?.includes(options.usage));

        if (setting.isStandaloneForm && standaloneForm && hasValidUsage) {
          standaloneForms.push({
            id: standaloneForm.id,
            apiName: setting.apiName,
            name: setting.objectInfos.label,
            type: CUSTOM_FORM_RECORD_ENTITY.CRM_OBJECT,
            crmFormSetting: setting,
          })
        }
      });
    }

    // ** TENANT FORMS ** //
    if (options.formsToFetch.includes(FORMS.BEACON)) {
      tenant?.config?.forms?.forEach( (form) => {
        if (form.status === TenantFormStatus.ACTIVE) {
          standaloneForms.push({
            id: form.id,
            name: form.label,
            type: CUSTOM_FORM_RECORD_ENTITY.BEACON_TENANT_FORM,
          })
        }
      });
    }

    return standaloneForms;
  },
);

/** Returns a FormTranslatorResponse based on the selected standalone crm form **/
const crmStandaloneFormConfig = createSelector(
  userTenant,
  tenantStandaloneForms,
  selOptions,
  (tenant: Tenant | undefined, forms: StandaloneForm[], opts: CRMSelectorOptions): StandaloneFormTranslatorResponse => {
    const { formSettingId } = opts;
    const formSettings = forms.reduce<FormSettings[]>((acc, { crmFormSetting }) =>
      crmFormSetting?.isStandaloneForm ? [...acc, crmFormSetting] : acc, []);

    if (tenant?.config.crmIntegration) {
      const response = CRMHandler(tenant?.config.crmIntegration)
        .Translator(formSettings, formSettingId).getFormCustomFieldConfig();
      return { customFields: response.crmCustomFields, defaultValues: response.crmDefaultValues };
    }

    return { customFields: [], defaultValues: {} };
  });

/** Returns a FormTranslatorResponse based on the CRM form response **/
const meetingFormConfig = createSelector(
  userTenant,
  selRawFormConfig,
  (tenant: Tenant | undefined, rawFormConfig: FormSettings[]): FormTranslatorResponse => {
    if (tenant?.config.crmIntegration) {
      return CRMHandler(tenant?.config.crmIntegration)
        .Translator(rawFormConfig).getFormCustomFieldConfig();
    }

    return { crmCustomFields: [], crmDefaultValues: {}, isLoading: false };
  })

const crmFormConfig = createSelector(
  userTenant,
  selRawFormConfig,
  selSyncingStatus,
  (tenant: Tenant | undefined, rawFormConfig: FormSettings[], crmSyncingStatus: CRM_STATUS): FormTranslatorResponse => {
    const baseResponse = {
      crmCustomFields: [],
      crmDefaultValues: {},
    };

    if (
      crmSyncingStatus.availabilityStatus === CRMAvailabilityStatus.DISABLED ||
      (crmSyncingStatus.connectionStatus !== CRMConnectionStatus.CONNECTED && !crmSyncingStatus.isCRMSyncing)
    ) {
      return baseResponse;
    }

    if (crmSyncingStatus.syncStatus === CRMSyncStatus.ERROR) {
      return { ...baseResponse, isLoading: false };
    }

    const isSyncingOrGettingTheForms = (
      crmSyncingStatus.isCRMSyncing ||
      !rawFormConfig ||
      !rawFormConfig.length
    )

    if (isSyncingOrGettingTheForms) {
      return { ...baseResponse, isLoading: true };
    }

    if (tenant?.config.crmIntegration) {
      return CRMHandler(tenant?.config.crmIntegration)
        .Translator(rawFormConfig).getFormCustomFieldConfig();
    }

    return baseResponse
  },
)

// ** Returns the CustomFields for the Main CRM Form ** //
export const useCRMFormConfig = (): ReturnType<typeof meetingFormConfig> => {
  const rawFormConfig = useGetFormConfigFromIndexDB();
  const crmSyncingStatus = useCRMStatus();

  return useSelector((state: RootState) => crmFormConfig(state, undefined, rawFormConfig, crmSyncingStatus));
};

// ** Returns an array of available Custom Forms ** //
export const useTenantStandaloneForms = (formsToFetch: FORMS[], usage?: StandaloneFormUsage):
  ReturnType<typeof tenantStandaloneForms> => {
  const rawFormConfig = useGetFormConfigFromIndexDB();
  const config = useMemo(
    () => {
      return {
        formSettings: rawFormConfig || [],
        formsToFetch,
        usage,
      }
    },
    [rawFormConfig, formsToFetch, usage],
  )

  return useSelector((state: RootState) => tenantStandaloneForms(state, config))
}

// ** Given a CRM standaloneFormId, returns its CustomFields ** //
export const useStandaloneFormConfig = (standaloneFormId: string, mainFormSettingId?: string)
  : ReturnType<typeof crmStandaloneFormConfig> => {
  const forms = useGetFormConfigFromIndexDB();
  const options = useMemo<CRMSelectorOptions>(() => ({
    formSettingId: mainFormSettingId,
    formSettings: forms || [],
    formsToFetch: [FORMS.CRM],
    standaloneFormId,
  }),
  [standaloneFormId, forms, mainFormSettingId]);
  const { availabilityStatus } = useCRMStatus();
  return useSelector((state: RootState) => {
    if (!forms || availabilityStatus !== CRMAvailabilityStatus.ENABLED) {
      return { customFields: [], defaultValues: {} };
    }
    return crmStandaloneFormConfig(state, options);
  });
}
