import React from 'react'
import { Pressable, StyleSheet } from 'react-native'
import { DNABox, DNAText, Iffy, luxColors, util } from '@alucio/lux-ui'
import { LuxSizeEnum } from '@alucio/lux-ui/src/typings'

import { Action, usePresentationBuilderSharedResources } from '../PresentationEditor'
import { usePresentationBuilderState } from '../../state/PresentationBuilderStateProvider'
import { useAppSettings } from 'src/state/context/AppSettings'
import { useUserById } from 'src/state/redux/selector/user'
import { PRESENTATION_BUILDER_VARIANT } from 'src/state/redux/slice/PresentationBuilder/PresentationBuilder'
import EditableText, {
  CustomReadOnlyValueProps,
  CustomShowInputButtonProps,
} from 'src/components/EditableText/EditableText'

const S = StyleSheet.create({
  headerRow: {
    minHeight: 47,
    paddingHorizontal: 16,
    paddingTop: 4,
  },
  buttonStyle: {
    paddingHorizontal: 12,
  },
  containerStyle: {
    borderBottomColor: '#F1F1F1',
    borderBottomWidth: 1,
    backgroundColor: 'white',
    paddingVertical: 4,
  },
  errorStateContainerStyle: {
    height: 76,
  },
  readOnlyTitle: {
    marginLeft: 8,
    flex: 1,
  },
  lastUpdated: {
    fontSize: 13,
    marginLeft: 8,
    flex: 1,
  },
})

const EDIT_TITLE_BUTTON_PROPS: CustomShowInputButtonProps = {
  appearance: 'outline',
  status: 'tertiary',
  padding: 'sm',
  size: 'md',
  iconLeft: 'pencil',
}

export const LastUpdated: React.FC = () => {
  const { customDeck } = usePresentationBuilderState()

  const updatedAt = customDeck?.model.updatedAt
  const updatedBy = customDeck?.model.updatedBy
  const userORM = useUserById(updatedBy || '')
  const lastUpdatedDate = updatedAt
    ? new Date(updatedAt).toLocaleDateString()
    : null;

  const lastUpdatedTime = updatedAt
    ? new Date(updatedAt).toLocaleTimeString()
    : null;

  if (!updatedAt) return null;

  return (
    <DNAText testID="last-update" numberOfLines={1} status="flat" style={S.lastUpdated}>
      {`Last updated ${lastUpdatedDate} ${lastUpdatedTime} by ${userORM?.meta.formattedName}`}
    </DNAText>
  )
}

const CustomReadOnlyValue: React.FC<CustomReadOnlyValueProps> = (props) => {
  const { value, placeHolder, toggleShowInput } = props
  const { customDeck } = usePresentationBuilderState()
  const placeholderTextColor = luxColors.contentText.quaternary
  const textStyles = util.mergeStyles(
    undefined,
    S.readOnlyTitle,
    [{ color: placeholderTextColor }, placeholderTextColor && !value],
  )
  return (
    <DNABox appearance="col" fill>
      <Pressable onPress={toggleShowInput}>
        <DNAText
          testID="custom-deck-title-input-text-box"
          bold
          numberOfLines={1}
          style={textStyles}
        >
          {value || placeHolder}
        </DNAText>
        <DNAText numberOfLines={1} bold status="flatAlt">
          <Iffy is={customDeck?.model.editMutex}>
            <LastUpdated />
          </Iffy>
        </DNAText>
      </Pressable>
    </DNABox>
  )
}

export const TopHeader: React.FC = () => {
  const { getActionEls } = usePresentationBuilderSharedResources()
  const {
    title,
    setTitle,
    saveAttemptedWithoutTitle,
    selectedGroups,
    savePresentation,
    cancelPresentation,
    handleModeChange,
    builderMode,
    variant,
    isLocked,
  } = usePresentationBuilderState()

  const { isOnline } = useAppSettings()
  const allContentCached = selectedGroups.every(group => group.documentVersionORM?.meta.assets.isContentCached)

  const isRead = variant === PRESENTATION_BUILDER_VARIANT.READ

  const actions: Action[] = [
    {
      hidden: !isRead,
      iconLeft: builderMode === 'customization' ? 'arrow-collapse' : 'arrow-expand',
      fill: true,
      onPress: () => handleModeChange(),
      disabled: isLocked,
    },
    {
      label: 'Cancel',
      appearance: 'filled',
      status: 'tertiary',
      style: [S.buttonStyle, { marginRight: 8 }],
      onPress: () => cancelPresentation(),
      testID: 'presentation-editor-cancel-button',
    },
    {
      label: 'Save',
      appearance: 'filled',
      status: 'primary',
      disabled: (
        isLocked ||
        !selectedGroups.length ||
        !selectedGroups.some(({ visible }) => visible) ||
        (!isOnline && !allContentCached) // or there is at least one visible group
      ),
      style: [S.buttonStyle, { marginRight: 12 }],
      onPress: () => savePresentation(),
      testID: 'presentation-editor-save-button',
    },
  ]

  const actionEls = getActionEls(actions)

  return (
    <DNABox
      style={util.mergeStyles(
        undefined,
        S.containerStyle,
        [S.errorStateContainerStyle, saveAttemptedWithoutTitle],
      )}
      alignY="center"
    >
      <DNABox fill style={S.headerRow} alignY="center">
        <EditableText
          bold
          size={LuxSizeEnum.md}
          value={title}
          disabled={isLocked}
          removeMarginPadding={true}
          onChangeText={setTitle}
          placeHolder="File Name"
          placeholderTextColor={luxColors.contentText.quaternary}
          required
          hideRequiredIcon
          error={saveAttemptedWithoutTitle ? 'This field is required' : undefined}
          customShowInputButtonProps={EDIT_TITLE_BUTTON_PROPS}
          customReadOnlyValue={CustomReadOnlyValue}
        />
      </DNABox>
      {actionEls}
    </DNABox>
  )
}

export default React.memo(TopHeader);
