import { DNABox, DNAButton } from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import React from 'react'
import { Pressable, ViewStyle } from 'react-native'
import { useInfoPanelState } from '../State/InfoPanelStateProvider'

enum ActionBarOrientations {
  'row',
  'col'
}
type ActionBarOrientation = keyof typeof ActionBarOrientations

enum ActionBarOrders {
  'normal',
  'reverse'
}
type ActionBarOrder = keyof typeof ActionBarOrders

export interface ActionBarElement {
  icon?: string
  label?: string
  popoverContent?: React.ReactElement
  onPress?: () => void
  style?: ViewStyle
  active?: boolean
  key?: string
}

interface ActionBarProps {
  options: ActionBarElement[]
  order?: ActionBarOrder
  orientation?: ActionBarOrientation
  style?: ViewStyle
}

const ActionBar: React.FC<ActionBarProps> = ({ orientation, options, order, style }) => {
  const { collapsed } = useInfoPanelState()

  const actionBarOptionElements = options.map(option => {
    const { icon, label, popoverContent, active, ...optionProps } = option

    const anchor = (
      <DNAButton
        onPress={optionProps.onPress}
        iconLeft={icon}
        appearance={!collapsed && active ? 'filled' : 'ghostLink'}
        size="md"
        status={!collapsed && active ? 'primary' : 'secondary'}
      />
    )

    /** TODO: This component was implemented prior to DNAPopover supporting the disablePopover prop.
     * This can be refactored to remove the redundant derivied element in a maint cycle.
     */
    const popover = (
      <DNAPopover placement="left" disablePopover={['tabletPWA']}>
        <DNAPopover.Anchor>
          {anchor}
        </DNAPopover.Anchor>
        {popoverContent
          ? <DNAPopover.Content >
            {popoverContent}
          </DNAPopover.Content> : null}
      </DNAPopover>
    )

    const derivedElement = popoverContent
      ? popover
      : anchor

    return (
      <Pressable {...optionProps}>
        {derivedElement}
      </Pressable>
    )
  })

  const orderedActionBarOptionElements = order === 'reverse'
    ? actionBarOptionElements.reverse()
    : actionBarOptionElements

  return (
    <DNABox appearance={orientation} style={style} spacing="sm">
      {orderedActionBarOptionElements}
    </DNABox>
  )
}

export default ActionBar
