import React, { useMemo } from 'react';
import { API, graphqlOperation } from '@aws-amplify/api';
import { updateUserStatusLambda } from '@alucio/aws-beacon-amplify/src/graphql/mutations';
import { useDispatch } from 'src/state/redux';
import im from 'immer';
import { Dispatch } from 'redux';
import { User, UserStatus } from '@alucio/aws-beacon-amplify/src/models';
import { DRAWER_ENTITIES, drawerActions } from 'src/state/redux/slice/drawer';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import UserReactivateConfirmation, { UserReactivateMode } from '../Modal/UserDeactivateConfirmation';
import { DisableUserLambdaInput, USER_STATUS } from '@alucio/aws-beacon-amplify/src/API';
import { GenericToast, ToastOrientations, useToast } from '@alucio/lux-ui';
import { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast';

export type UserContextOptions = {
  [key in keyof typeof UserContextActions]: {
    title: string,
    onPress: (d: Dispatch, f: User, toast: ToastActions) => () => void,
  }
}

export type BindUserContextActions = {
  [key in keyof typeof UserContextActions]: (f: User) => () => void
}

export enum UserContextActions {
  edit = 'Edit',
  reactivate = 'Reactivate',
  deactivate = 'Deactivate',
}

export type UserActions = (keyof typeof UserContextActions)

export const useDNAUserActions = (): BindUserContextActions => {
  const dispatch = useDispatch();
  const toast = useToast()

  const bound = useMemo(() => im(userContextActions, draft => {
    for (const action in userContextActions) {
      const actionTyped = action as keyof typeof UserContextActions
      draft[action] = (user: User) => userContextActions[actionTyped].onPress(dispatch, user, toast)
    }
  }) as unknown as BindUserContextActions,
  [dispatch],
  );

  return bound
};

// Deactivate or Reactivate an user
function deactivateReactivateUser(dispatch, user: User, toast: ToastActions) {
  dispatch(DNAModalActions.setModal({
    isVisible: true,
    allowBackdropCancel: true,
    component: (props) => (<UserReactivateConfirmation
      {...props}
      username={`${user.familyName} ${user.givenName}`}
      tenantId={user.tenantId}
      onClose={async () => {
        const updateUserPayload: DisableUserLambdaInput = {
          id: user?.id || '',
          status: user.status === UserStatus.ACTIVE ? USER_STATUS.ACTIVE : USER_STATUS.DEACTIVATED,
        };

        const nextStatus = user.status === UserStatus.ACTIVE ? 'ADMIN_DISABLE_USER' : 'ADMIN_ACTIVATE_USER';

        await API.graphql(graphqlOperation(updateUserStatusLambda, { user: updateUserPayload }));
        analytics.track(nextStatus, {
          category: 'ADMIN',
          properties: {
            userId: user.id,
            emailAddress: user.email,
          },
        });

        toast.add(
          <GenericToast
            title={user.status === UserStatus.ACTIVE ? 'User deactivated' : 'User reactivated'}
            status={user.status === UserStatus.ACTIVE ? 'warning' : 'success'}
          />,
          ToastOrientations.TOP_RIGHT,
        )
      } }
      mode={user.status === UserStatus.ACTIVE
        ? UserReactivateMode.deactivate
        : UserReactivateMode.reactivate}
    />),
  }));
}

/** This is specific to the Context Menus */
export const userContextActions: UserContextOptions = {
  edit: {
    title: UserContextActions.edit,
    onPress: (dispatch, user) => () => {
      dispatch(drawerActions.toggle({
        entityId: user.id,
        entity: DRAWER_ENTITIES.USER,
      }));
    },
  },
  reactivate: {
    title: UserContextActions.reactivate,
    onPress: (dispatch, user, toast) => () => {
      deactivateReactivateUser(dispatch, user, toast);
    },
  },
  deactivate: {
    title: UserContextActions.deactivate,
    onPress: (dispatch, user, toast) => () => {
      deactivateReactivateUser(dispatch, user, toast);
    },
  },
};
