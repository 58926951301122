import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import {
  DNASlider,
  DNABox,
  DNAButton,
  DNAText,
  Iffy,
} from '@alucio/lux-ui';
import DNAError from 'src/components/DNA/Error';
import { DocumentORM } from 'src/types/orms';
import { useAllDocumentsInstance } from 'src/state/redux/selector/document';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as publisherVersioningSelector from 'src/state/machines/publisherVersioning/publisherVersioning.selectors';
import VersionHistory, { SidebarWrapper } from 'src/screens/Publishers/Versioning/VersionHistory';
import PublisherVersioningStateProvider, { usePublisherVersioningState }
  from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
// Component
import Footer from './Footer';
import Tabs from './Tabs';
import Header from './Header';

export const S = StyleSheet.create({
  tabContentContainer: {
    paddingTop: 40,
    paddingHorizontal: 40,
  },
  versioningPanelContainer: {
    backgroundColor: 'white',
  },
})

interface VersioningPanelWrapperProps {
  documentORM?: DocumentORM
  toggleSlider: (onInvisCb?: () => void) => void
}

interface VersioningSliderProps {
  documentId?: string,
  setDocumentId?: React.Dispatch<React.SetStateAction<string | undefined>>
}

const VersioningPanel: React.FC = () => {
  const {
    service,
    documentORM,
    currentDocumentVersionORM,
    toggleSlider,
    onVersionHistorySelect,
  } = usePublisherVersioningState();
  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.documentVersionId,
      publisherVersioningSelector.isDraftDirty,
    ),
  )

  // [NOTE] - This would happen if we have a Document with no child versions
  if (!currentDocumentVersionORM) {
    return (
      <DNABox
        fill
        appearance="col"
        alignX="center"
        alignY="center"
        style={{ backgroundColor: 'white' }}
      >
        <DNAError
          promptLogout={false}
          message="Could not open Document for versioning!"
        >
          <DNAText>{documentORM.model.id}</DNAText>
          <DNAButton
            appearance="ghost"
            onPress={() => toggleSlider()}
          >
            Go back
          </DNAButton>
        </DNAError>
      </DNABox>
    )
  }
  return (
    <DNABox fill style={S.versioningPanelContainer}>
      {/* SIDEBAR */}
      <SidebarWrapper>
        <VersionHistory
          isAnyFormDirty={cond.isDraftDirty}
          selectedDocVerId={cond.documentVersionId}
          documentORM={documentORM}
          onSelect={onVersionHistorySelect}
        />
      </SidebarWrapper>
      {/* CONTENT */}
      <DNABox fill appearance="col">
        {/* TITLE */}
        <Header />
        {/* CONTENT TABS */}
        <Tabs />
        <Footer />
      </DNABox>
    </DNABox>
  )
}

const VersioningPanelWrapper: React.FC<VersioningPanelWrapperProps> = (props) => {
  const { documentORM, toggleSlider } = props;

  if (!documentORM) return null;
  return (
    <PublisherVersioningStateProvider
      documentORM={documentORM}
      toggleSlider={toggleSlider}
    >
      <VersioningPanel />
    </PublisherVersioningStateProvider>
  )
}

const VersioningSlider: React.FC<VersioningSliderProps> = (props) => {
  const { documentId, setDocumentId } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const onInvisibleCallback = useRef<() => void>();

  const toggleSlider = useCallback((onInvisCb?: () => void) => {
    onInvisibleCallback.current = onInvisCb;
    setVisible(p => !p);
  }, [setVisible])

  const documentFilter = useMemo(() => ({ filter: { model: { id: documentId } } }), [documentId]);
  const [documentORM] = useAllDocumentsInstance(documentFilter);

  useEffect(() => {
    if (documentId) setVisible(true);
  }, [documentId])

  useEffect(() => {
    const gracefullyHandleDeletedDocument = () => {
      if (!documentORM && visible) setVisible(false);
    }
    gracefullyHandleDeletedDocument();
  }, [documentORM, visible])

  return (
    <DNABox appearance="col">
      {/* [NOTE] - This is playground specific */}
      <Iffy is={!setDocumentId}>
        <DNAText>Versioning Redesign</DNAText>
        <DNAButton onPress={() => toggleSlider()}>
          Toggle Slider
        </DNAButton>
      </Iffy>
      <DNASlider
        visible={visible}
        setVisible={setVisible}
        onInvisible={() => {
          if (typeof onInvisibleCallback.current === 'function')
          { onInvisibleCallback.current?.() }
          setDocumentId?.(undefined)
        }}
        orientation="horizontal"
      >
        <VersioningPanelWrapper
          documentORM={documentORM}
          toggleSlider={toggleSlider}
        />
      </DNASlider>
    </DNABox>
  )
}

export default VersioningSlider;
