import React from 'react'
import { DNABox, DNAButton, DNAChip, DNAText, Iffy } from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'

export const RightGroupMenu: React.FC<{
  pages: number,
  locked: boolean,
  onAdd?: () => void,
  onDelete?: () => void,
}> = (props) => {
  const { locked, pages, onAdd, onDelete } = props
  return (
    <DNABox spacing="between">
      <DNABox alignX="center" alignY="center" style={{ marginRight: 4 }} fill childFill>
        <DNAChip
          appearance="subtle"
          status="basic"
          size="lg"
        >
          {`${pages}`}
        </DNAChip>
      </DNABox>
      <DNAPopover>
        <DNAPopover.Anchor>
          <DNAButton
            appearance="ghostLink"
            disabled
            size="lg"
            iconStyle={{ color: colors['color-gray-200'] }}
            padding="md"
            rounded="md"
            status="gray"
            iconLeft={locked ? 'lock' : 'lock-open-variant-outline'}
          />
        </DNAPopover.Anchor>
        <DNAPopover.Content>
          <DNAText style={{ color: colors['color-text-white'] }}>
            {locked ? 'Ungrouping restricted' : 'Ungrouping allowed'}
          </DNAText>
        </DNAPopover.Content>
      </DNAPopover>
      <Iffy is={onDelete}>
        <DNAPopover>
          <DNAPopover.Anchor>
            <DNAButton
              appearance="ghostLink"
              onPress={onDelete}
              size="lg"
              iconStyle={{ color: colors['color-gray-200'] }}
              padding="md"
              rounded="md"
              status="gray"
              iconLeft={'trash-can-outline'}
            />
          </DNAPopover.Anchor>
          <DNAPopover.Content>
            <DNAText style={{ color: colors['color-text-white'] }}>
              delete group
            </DNAText>
          </DNAPopover.Content>
        </DNAPopover>
      </Iffy>
      <Iffy is={onAdd}>
        <DNAPopover>
          <DNAPopover.Anchor>
            <DNAButton
              appearance="ghostLink"
              size="lg"
              padding="md"
              rounded="md"
              iconLeft="arrow-right"
              // TODO: Check why this padding is required
              style={{ paddingLeft: 0 }}
              onPress={onAdd}
            />
          </DNAPopover.Anchor>
          <DNAPopover.Content>
            <DNAText style={{ color: colors['color-text-white'] }}>
              Select with this group
            </DNAText>
          </DNAPopover.Content>
        </DNAPopover>
      </Iffy>
    </DNABox>
  )
}
