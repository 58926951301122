import React from 'react';
import { DNABox, DNAButton, DNADivider, DNAIcon, DNAChip, DNAText, Iffy } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { StyleSheet } from 'react-native';
import { useAddMeeting, useIsMeetingLocked } from './AddMeetingProvider';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import { CRMConnectionStatus } from 'src/state/machines/CRM/crmMachineTypes';
import { CRMOpenCallButton, CRMSubmitButton, useCanDeleteMetting } from './AddMeetingFooter';
import { useLocation } from 'react-router';
import { useSubmitButtonStatus } from './Util';

export const styles = StyleSheet.create({
  titleContainer: {
    backgroundColor: colors['color-text-white'],
    height: 56,
    paddingLeft: 16,
    paddingRight: 16,
  },
  title: {
    color: colors['gray-600'],
  },
  contentContainer: {
    paddingLeft: 24,
    paddingRight: 24,
  },
})

const AddMeetingHeaderDesktop = () => {
  const { onCancel } = useAddMeeting()
  const { pathname } = useLocation()
  const isHubsRoute = pathname.includes('hubs')

  return (
    <DNABox appearance="col">
      <DNABox alignY="center" appearance="row" spacing="between" style={styles.titleContainer}>
        <DNAText bold h5 style={styles.title}>Meeting Event</DNAText>
        <Iffy is={!isHubsRoute}>
          <DNAButton
            appearance="ghost"
            status="tertiary"
            size="md"
            padding="sm"
            iconLeft="close"
            onPress={onCancel}
          />
        </Iffy>
      </DNABox>
      <DNADivider />
    </DNABox>
  )
}

const AddMeetingHeaderHubTablet: React.FC = () => {
  const { onCancel } = useAddMeeting()

  return (
    <DNABox appearance="col">
      <DNABox alignY="center" appearance="row" spacing="between" style={styles.titleContainer}>
        <DNAText bold h5 style={styles.title}>Meeting Event</DNAText>
        <DNAButton
          status="gray"
          appearance="ghost"
          iconLeft="close"
          onPress={onCancel}
          size="lg"
          padding="sm"
        />
      </DNABox>
      <DNADivider />
    </DNABox>
  )
}

const AddMeetingHeaderTablet = () => {
  const {
    onCancel,
    meetingORM,
    onSave,
    onDelete,
    isSubmitting,
    isReadOnly,
  } = useAddMeeting()
  const { pathname } = useLocation()
  const isHubsRoute = pathname.includes('hubs')

  const useBtnSubmitStatus = useSubmitButtonStatus()
  const dateLastSyncedAt = new Date(meetingORM?.model.crmRecord?.lastSyncedAt || '');
  const lastSyncedAt = (!isReadOnly ? 'Saved to CRM on ' : '') + dateLastSyncedAt.toLocaleDateString() + ' at ' +
    dateLastSyncedAt.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  const { connectionStatus } = useCRMStatus();
  const isMeetingLocked = useIsMeetingLocked(meetingORM)

  const { deleteDisabled } = useCanDeleteMetting(meetingORM);
  if (isHubsRoute) return <AddMeetingHeaderHubTablet />
  return (
    <DNABox appearance="col">
      <DNABox alignY="center" appearance="row" spacing="md" childFill={2} style={styles.titleContainer}>
        <DNAButton
          disabled={isSubmitting}
          status="tertiary"
          appearance="ghost"
          iconLeft="close"
          onPress={onCancel}
        />
        <DNAText bold h5 style={styles.title}>Meeting Event</DNAText>
        <DNABox alignX="end" spacing="sm" alignY="center">
          <Iffy is={isReadOnly}>
            <DNABox spacing="sm" alignY="center">
              {/* Open in CRM */}
              <Iffy is={meetingORM?.model.crmRecord?.crmCallId}>
                <CRMOpenCallButton meetingORM={meetingORM} />
              </Iffy>
              <DNAIcon name="lock" size="md" style={{ fontSize: 20, color: colors['color-gray-400'] }} />
              <DNAText status="flat">
                Submitted to CRM on {lastSyncedAt} and is now locked.
              </DNAText>
            </DNABox>
          </Iffy>
          <Iffy is={meetingORM?.model.crmRecord?.crmCallId && !isReadOnly}>
            <DNAChip status="basic" size="md">
              {lastSyncedAt}
            </DNAChip>
          </Iffy>
          <Iffy is={!isReadOnly}>
            {/* Delete Button */}
            <DNAButton
              disabled={isMeetingLocked || deleteDisabled}
              appearance="outline"
              size="sm"
              padding="sm"
              status="tertiary"
              style={{ paddingVertical: 5 }}
              iconRight="trash-can-outline"
              onPress={onDelete}
            />
          </Iffy>
          {/* Open in CRM */}
          <Iffy is={meetingORM?.model.crmRecord?.crmCallId && !isReadOnly}>
            <CRMOpenCallButton meetingORM={meetingORM} />
          </Iffy>
          {/* CRM Submit Button */}
          <Iffy is={connectionStatus === CRMConnectionStatus.CONNECTED && !isReadOnly}>
            <CRMSubmitButton />
          </Iffy>
          {/* Save Button */}
          <Iffy is={!isReadOnly}>
            <DNAButton
              style={{ maxHeight: 43 }}
              appearance="filled"
              status={useBtnSubmitStatus.color}
              iconLeft={useBtnSubmitStatus.icon}
              disabled={isSubmitting || useBtnSubmitStatus.disabled}
              onPress={() => onSave()}
            >
              {isSubmitting ? 'Saving...' : useBtnSubmitStatus.text}
            </DNAButton>
          </Iffy>
        </DNABox>
      </DNABox>
      <DNADivider />
    </DNABox>
  )
}

type DeviceModeVariant = Record<DeviceMode, React.ElementType>
const deviceModeVariant:DeviceModeVariant = {
  desktop: AddMeetingHeaderDesktop,
  tablet: AddMeetingHeaderTablet,
}

const AddMeetingHeader = () => {
  const { deviceMode } = useAppSettings()
  const CurrentDeviceModeVariant = deviceModeVariant[deviceMode]

  return <CurrentDeviceModeVariant/>
}

export default AddMeetingHeader
