import React, { useCallback, useMemo } from 'react'
import { DNABox, DNASlider } from '@alucio/lux-ui'
import PresentationEditor from './PresentationEditor/PresentationEditor'
import PresentationBuilderStateProvider, { usePresentationBuilderState } from './state/PresentationBuilderStateProvider'
import PresentationSelector from './PresentationSelector/PresentationSelector'
import { useSelector } from 'react-redux'
import { RootState } from 'src/state/redux'
import { presentationBuilderActions } from 'src/state/redux/slice/PresentationBuilder/PresentationBuilder'
import { StyleProp, ViewStyle } from 'react-native'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import PresentationSelectorStateProvider, { usePresentationSelector }
  from './PresentationSelector/PresentationSelectorStateProvider'

import CloneBuilder, { POOL_CONTAINER_ID } from 'src/components/DnD/Clone/CloneBuilder'
import { Active, DndMonitorListener, useDndMonitor } from '@dnd-kit/core'
import SlidesGroupOverlay from 'src/components/DnD/Clone/PresentationBuilder/SlidesGroupOverlay'
import { TargetItem } from 'src/components/DnD/DnDWrapper'
import DocumentSearchProvider from 'src/hooks/useDocumentSearchV2'

interface PresentationBuilderProps {
}

const DndMonitor = React.memo(() => {
  const { setActive } = usePresentationSelector()
  const dndListeners = useMemo<DndMonitorListener>(
    () => ({
      onDragStart(event) {
        setActive(event.active)
      },
      onDragEnd() {
        setActive(undefined)
      },
    }),
    [],
  )
  useDndMonitor(dndListeners)
  return null
})

const PresentationBuilderComponents: React.FC = () => {
  const containerStyle:StyleProp<ViewStyle> = {
    backgroundColor: colors['color-text-white'],
  }
  const {
    poolItems,
    targetItems,
    selectedItemIds,
    getGroupDrafts,
    handleSelectGroup,
    selectedGroups,
    handleGroupAdd,
    rearrangeEditorSlides,
  } = usePresentationSelector()

  const {
    selectedTargetItems,
    setSelectedTargetItems,
  } = usePresentationBuilderState();

  const allGroups = getGroupDrafts()
  const useRef = React.useRef<Active | undefined>()

  const onDragEndChanged = useCallback((targetItems: { [key: string]: TargetItem[] }) => {
    // After finished dragging, sync targetItems. Determine if we are adding or reordering...
    // Drag interactions can never remove an item from target
    const addingItemToTarget = targetItems.default.length > selectedGroups.length
    const reorderingTargetItems = targetItems.default.length === selectedGroups.length
    if (addingItemToTarget) {
      // Add items drag from pool
      let insertingIndex: number | undefined
      for (let i = 0; i < targetItems.default.length; i++) {
        const targetItem = targetItems.default[i]
        const isFound = selectedGroups.find(payloadGroup => payloadGroup.id === targetItem.itemId)
        if (!isFound) {
          insertingIndex = i
          break
        }
      }
      handleGroupAdd(insertingIndex)
    }
    // Rearrange editor slide order
    else if (reorderingTargetItems) rearrangeEditorSlides(targetItems.default, useRef.current)

    useRef.current = undefined
  }, [selectedGroups, handleGroupAdd, rearrangeEditorSlides])

  const onDragStart = useCallback((active: Active) => {
    if (active.data.current?.containerId === POOL_CONTAINER_ID) {
      // Mark the current dragging item as checked (if it is not already checked)
      const currentDraggingItemId = active.data.current?.itemId
      const currentDraggingGroup = allGroups.find(({ id }) => id === currentDraggingItemId)
      currentDraggingGroup && handleSelectGroup(currentDraggingGroup, true)
    }
    // TODO: i have tried to use the containerId to determine if the dragging item is from pool or target
    //  but it seems that when i use a different container id i get issues on the useCloneBuilder hook
    else if (active.data.current?.containerId === 'default') {
      const currentDraggingItemId = active.data.current?.itemId as string

      // add if does not exist into the array selectedTargetItems
      if (!selectedTargetItems.includes(currentDraggingItemId)) {
        setSelectedTargetItems([...selectedTargetItems, currentDraggingItemId])
      }
    }
    useRef.current = active
  }, [handleSelectGroup, allGroups])

  const targetItemsObj = useMemo(() => {
    return { default: targetItems }
  }, [targetItems])

  return (
    <DNABox
      fill
      style={containerStyle}
    >
      <CloneBuilder
        poolItems={poolItems}
        targetItems={targetItemsObj}
        selectedItemIds={selectedItemIds}
        onDragEndChanged={onDragEndChanged}
        onDragStart={onDragStart}
      >
        <PresentationSelector/>
        <PresentationEditor />
        <SlidesGroupOverlay />
        <DndMonitor />
      </CloneBuilder>
    </DNABox>
  )
}

const PresentationBuilder:React.FC<PresentationBuilderProps> = () => {
  const globalBuilderState = useSelector((state: RootState) => state.PresentationBuilder)

  return (
    <DNASlider
      visible={globalBuilderState.visible}
      setVisible={() => presentationBuilderActions.closePresentationBuilder()}
    >
      <PresentationBuilderStateProvider>
        <DocumentSearchProvider mode="CUSTOM">
          <PresentationSelectorStateProvider>
            <PresentationBuilderComponents/>
          </PresentationSelectorStateProvider>
        </DocumentSearchProvider>
      </PresentationBuilderStateProvider>
    </DNASlider>
  )
}

export default PresentationBuilder
