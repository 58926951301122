// [DNARefactor] - Replace with DNAChip
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Icon } from '@ui-kitten/components';
import { Iffy, luxColors } from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import { PurposeType } from '@alucio/aws-beacon-amplify/src/models';
import { toPurposeText } from '../Library/Util/util';

const styles = StyleSheet.create({
  badgeText: {
    color: 'white',
    fontFamily: 'Lato',
    fontSize: 13,
    fontWeight: 'bold',
  },
  defaultIconColor: {
    color: 'gray',
  },
  porpuseBadge: {
    borderRadius: 2,
    height: 22,
    padding: 3,
  },
  er: {
    backgroundColor: luxColors.orange.primary,
  },
  ep: {
    backgroundColor: luxColors.publish.primary,
  },
  int: {
    backgroundColor: luxColors.error.primary,
  },
  statusIcon: {
    height: 22,
    width: 22,
  },
});

const purposeColor = {
  [PurposeType.EXTERNAL_REACTIVE]: styles.er,
  [PurposeType.EXTERNAL_PROACTIVE]: styles.ep,
  [PurposeType.INTERNAL_USE_ONLY]: styles.int,
};

type Props = {
  purpose: PurposeType;
  description?: string;
};

function PurposeBadge(props: Props) {
  const porpuse = props.purpose;
  const color = purposeColor[porpuse];

  // If there's no color, the purpose is not supported
  if (!color) {
    return (
      <View style={styles.porpuseBadge}>
        <DNAPopover placement="top" >
          <DNAPopover.Anchor>
            <Icon style={[styles.statusIcon, styles.defaultIconColor]} name="help-circle-outline" />
          </DNAPopover.Anchor>
          <DNAPopover.Content offset={2}>
            <Text numberOfLines={1} style={{ color: 'white' }}>Invalid Status</Text>
          </DNAPopover.Content>
        </DNAPopover>
      </View>
    );
  }

  return (
    <View style={[styles.porpuseBadge, color]}>
      <Iffy is={props.description}>
        <DNAPopover placement="top" >
          <DNAPopover.Anchor>
            <Text testID="purpose-badge" style={[styles.badgeText]}>{toPurposeText(porpuse)}</Text>
          </DNAPopover.Anchor>
          <DNAPopover.Content offset={2}>
            <Text numberOfLines={1} style={{ color: 'white' }}>{props.description || ''}</Text>
          </DNAPopover.Content>
        </DNAPopover>
      </Iffy>
      <Iffy is={!props.description}>
        <Text testID="purpose-badge" style={[styles.badgeText]}>{toPurposeText(porpuse)}</Text>
      </Iffy>
    </View>
  );
}

export default PurposeBadge;
