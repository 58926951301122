import React from 'react'
import { FolderORM } from 'src/types/types'
import { DNACardComponent } from 'src/components/DNA/Grid/Cards/types'

import {
  DNABox,
  DNACard,
  DNAText,
  useTheme,
  util,
} from '@alucio/lux-ui'
import Folder from 'src/components/DNA/Folder/DNAFolder'
import PinButton from 'src/components/DNA/Folder/DNAFolderPinButton'

export interface FolderCardProps {
  onPin?: () => void,
}

export const FolderCard: DNACardComponent<FolderORM, FolderCardProps> = (props) => {
  const { ORM: folderORM, onPress } = props
  const theme = useTheme()

  const containerStyle = {
    backgroundColor: theme['color-dark-300'],
    minWidth: 232,
    minHeight: 188,
    overflow: 'visible',
    zIndex: -1,
  }

  return (
    <DNABox
      alignX="start"
      style={{ minWidth: 232, minHeight: 212 }}
    >
      <DNACard
        interactive="pressable"
        style={util.mergeStyles(props, containerStyle)}
        onPress={() => { onPress?.(folderORM) }}
      >
        <DNABox fill appearance="col" style={{ padding: 8 }}>
          <PinButton
            isHidden={!folderORM.model.isPinned}
            variant="virtual"
          />
          {/* Folder */}
          <DNABox
            appearance="col"
            alignX="center"
            alignY="center"
            fit="fill"
            spacing="md"
          >
            <Folder folder={folderORM} width={72} height={72} />
            <DNAText
              b1
              numberOfLines={2}
              status="basic"
              style={{ maxWidth: 200, overflow: 'hidden' }}
            >
              {folderORM.model.name}
            </DNAText>
          </DNABox>
        </DNABox>
      </DNACard>
    </DNABox>
  )
}

export default FolderCard
