import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import {
  Collapsible,
  DNABox,
  DNAButton,
  DNACard,
  DNACheckbox,
  DNAText,
  GridList, Icon,
  Iffy,
  luxColors,
} from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { CustomFieldDefinition } from '@alucio/aws-beacon-amplify/src/models';

const styles = StyleSheet.create({
  iconStyle: {
    color: luxColors.contentText.quaternary,
    height: 16,
    paddingLeft: 5,
    width: 16,
  },
  itemTextColor: {
    color: colors['color-gray-600'],
  },
  mainWrapper: {
    marginBottom: 12,
    minHeight: 'fit-content',
    paddingHorizontal: 18,
    paddingVertical: 16,
  },
  row: {
    borderBottomColor: colors['color-gray-100'],
    borderBottomWidth: 1,
    paddingVertical: 12,
  },
  tableHeader: {
    backgroundColor: colors['color-gray-10'],
  },
});

const ALLOW_ACCESS_DESCRIPTION = 'User to have access to content associated \n with the selected value(s)';
const DEFAULT_FILTERS_DESCRIPTION = 'User’s library default view to reflect \n the selected filter(s)';

export const DEFAULT_FILTERS = 'defaultFiltersCustomValues';
export const LOCKED_FILTERS = 'lockedFiltersCustomValues';
export type CONFIG_FIELDS = 'defaultFiltersCustomValues' | 'lockedFiltersCustomValues';

export interface FilterValue {
  defaultFiltersCustomValues: string[],
  lockedFiltersCustomValues: string[],
}

interface Props {
  fieldDefinition: CustomFieldDefinition,
  disabled?: boolean,
  values: FilterValue,
  onValueSelected: (fieldName: string, fieldValue: string, column: CONFIG_FIELDS) => void,
  onAllValuesSelected: (fieldName: string, column: CONFIG_FIELDS, values: string[], checked: boolean) => void,
  disableLockedFilters?: boolean,
}

const UserFilter = (props: Props) => {
  const { fieldDefinition, values, disabled, disableLockedFilters, onValueSelected, onAllValuesSelected } = props;
  const enabledValueDefinitions = fieldDefinition.fieldValueDefinitions.filter(({ disabled }) => !disabled);
  const valuesIds = enabledValueDefinitions.map(({ id }) => id);
  const areAllLockedChecked = values.lockedFiltersCustomValues.length === enabledValueDefinitions.length;
  const areAllDefaultChecked = values.defaultFiltersCustomValues.length === enabledValueDefinitions.length ||
  !!(values.lockedFiltersCustomValues.length &&
      values.lockedFiltersCustomValues.length === values.defaultFiltersCustomValues.length)
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function toggleFilter(): void {
    setIsOpen(open => !open);
  }

  return (
    <DNACard style={styles.mainWrapper} appearance="outline">
      <DNABox fill childFill={1} spacing="sm" alignY="center">
        <DNAButton
          status="tertiary"
          appearance="ghost"
          iconLeft={isOpen ? 'chevron-down' : 'chevron-right'}
          onPress={toggleFilter}
          rounded="full"
          padding="sm"
        />
        <DNABox fill appearance="col" spacing="xs">
          {/* FIELD LABEL */}
          <DNAText c1 bold style={{ color: luxColors.contentText.tertiary }}>
            {fieldDefinition.fieldLabel.toUpperCase()}
          </DNAText>
          {/* ALLOW ACCESS */}
          <Iffy is={values.lockedFiltersCustomValues?.length}>
            <DNAText c2 bold>Allow access:  <DNAText c2>
              {
                values.lockedFiltersCustomValues?.map((valueId) =>
                  enabledValueDefinitions.find(({ id }) => id === valueId)?.value)?.join(', ')
              }
            </DNAText></DNAText>
          </Iffy>
          {/* DEFAULT FILTERS */}
          <Iffy is={values.defaultFiltersCustomValues?.length}>
            <DNAText c2 bold>Default filters: <DNAText c2>
              {
                values.defaultFiltersCustomValues?.map((valueId) =>
                  enabledValueDefinitions.find(({ id }) => id === valueId)?.value)?.join(', ')
              }
            </DNAText></DNAText>
          </Iffy>
        </DNABox>
      </DNABox>
      <Collapsible collapsed={!isOpen}>
        <DNACard appearance="flat">
          <GridList cols={'repeat(12, minmax(50px, 1fr))'} gap="12px">
            {/* HEADER */}
            <GridList.Row style={styles.row}>
              <GridList.Col span={6} />
              <GridList.Col span={3} style={{ justifyContent: 'center' }}>
                <DNAText bold>Allow access</DNAText>
                <DNAPopover >
                  <DNAPopover.Anchor>
                    <Icon style={styles.iconStyle} name="help-circle-outline" />
                  </DNAPopover.Anchor>
                  <DNAPopover.Content>
                    <DNAText status="basic">{ALLOW_ACCESS_DESCRIPTION}</DNAText>
                  </DNAPopover.Content>
                </DNAPopover>
              </GridList.Col>
              <GridList.Col span={3} style={{ justifyContent: 'center' }}>
                <DNAText bold>Default filters</DNAText>
                <DNAPopover >
                  <DNAPopover.Anchor>
                    <Icon style={styles.iconStyle} name="help-circle-outline" />
                  </DNAPopover.Anchor>
                  <DNAPopover.Content>
                    <DNAText status="basic">{DEFAULT_FILTERS_DESCRIPTION}</DNAText>
                  </DNAPopover.Content>
                </DNAPopover>
              </GridList.Col>
            </GridList.Row>
            <GridList.Row style={[styles.row, styles.tableHeader]}>
              <GridList.Col span={6}>
                <DNAText bold>Select all</DNAText>
              </GridList.Col>
              <GridList.Col span={3} style={{ justifyContent: 'center' }}>
                <DNACheckbox
                  checked={areAllLockedChecked}
                  disabled={disabled || disableLockedFilters}
                  onChange={() => onAllValuesSelected(
                    fieldDefinition.id,
                    LOCKED_FILTERS,
                    valuesIds,
                    !areAllLockedChecked,
                  )}
                />
              </GridList.Col>
              <GridList.Col span={3} style={{ justifyContent: 'center' }}>
                <DNACheckbox
                  disabled={disabled}
                  checked={areAllDefaultChecked}
                  onChange={() => onAllValuesSelected(
                    fieldDefinition.id,
                    DEFAULT_FILTERS,
                    valuesIds,
                    !areAllDefaultChecked,
                  )}
                />
              </GridList.Col>
            </GridList.Row>
            {/* CONTENT */}
            {
              enabledValueDefinitions.map((value) => (
                <GridList.Row key={value.id} style={styles.row}>
                  <GridList.Col span={6}>
                    <DNAText style={styles.itemTextColor}>{value.value}</DNAText>
                  </GridList.Col>
                  <GridList.Col span={3} style={{ justifyContent: 'center' }}>
                    <DNACheckbox
                      disabled={disabled || disableLockedFilters}
                      checked={values.lockedFiltersCustomValues.includes(value.id)}
                      onChange={() => onValueSelected(fieldDefinition.id, value.id, LOCKED_FILTERS)}
                    />
                  </GridList.Col>
                  <GridList.Col span={3} style={{ justifyContent: 'center' }}>
                    <DNACheckbox
                      disabled={(!!values.lockedFiltersCustomValues.length &&
                          !values.lockedFiltersCustomValues.includes(value.id)) || disabled}
                      checked={values.defaultFiltersCustomValues.includes(value.id)}
                      onChange={() => onValueSelected(fieldDefinition.id, value.id, DEFAULT_FILTERS)}
                    />
                  </GridList.Col>
                </GridList.Row>
              ))
            }
          </GridList>
        </DNACard>
      </Collapsible>
    </DNACard>
  );
};

export default React.memo(UserFilter);
