import {
  ControlType,
  CustomFieldUsage,
  FieldDataType,
  FieldStatus,
  DocumentVersion,
  CustomFieldDefinition,
} from '@alucio/aws-beacon-amplify/src/models';
import { formToModel } from 'src/components/CustomFields/ComposableFormUtilities';
import { CustomFieldValuesMap, DocumentVersionORM } from 'src/types/orms';
import { FormValuesType } from 'src/components/CustomFields/ComposableForm';

export const documentVersionFields : {
  [key: string]: CustomFieldDefinition
} = {
  title: {
    id: 'title',
    fieldLabel: 'Title',
    fieldName: 'Title',
    status: FieldStatus.ENABLED,
    required: true,
    maxLength: 250,
    fieldType: FieldDataType.STRING,
    usage: [CustomFieldUsage.DOCUMENT],
    controlType: ControlType.TEXTAREA,
    fieldValueDefinitions: [],
    createdAt: new Date().toUTCString(),
  },
  contentSource: {
    id: 'contentSource',
    fieldLabel: 'Content Source',
    fieldName: 'contentSource',
    status: FieldStatus.ENABLED,
    required: false,
    fieldType: FieldDataType.STRING,
    usage: [CustomFieldUsage.DOCUMENT],
    controlType: ControlType.INPUT,
    fieldValueDefinitions: [],
    createdAt: new Date().toUTCString(),
  },
  contentURL: {
    id: 'contentURL',
    fieldLabel: 'Website URL',
    fieldName: 'contentURL',
    status: FieldStatus.ENABLED,
    required: false,
    fieldType: FieldDataType.STRING,
    usage: [CustomFieldUsage.DOCUMENT],
    controlType: ControlType.INPUT,
    fieldValueDefinitions: [],
    createdAt: new Date().toUTCString(),
  },
}

const contentURLRequired = ['WEB'];

type Transformation = { [key: string]: (field: CustomFieldDefinition) => CustomFieldDefinition }

const transformations  = (documentVersionORM: DocumentVersionORM) : Transformation => ({
  title: (field) => field,
  contentSource: (field) => field,
  contentURL: (field) => {
    if (field.id === 'contentURL' &&
      contentURLRequired.includes(documentVersionORM.relations.documentORM.model.type)) {
      return {
        ...field,
        required: true,
      }
    }

    return field;
  },
})

export const getHydratedStaticValues = (documentVersionORM: DocumentVersionORM) : CustomFieldValuesMap => {
  const versionTitle = documentVersionORM.model.title ?? ''
  const versionContentURL = documentVersionORM.model.contentURL ?? ''
  const contentSourceValue = documentVersionORM.meta.integration.source
    ? [documentVersionORM.meta.integration.source]
    : []

  const adaptField = transformations(documentVersionORM)

  return {
    [documentVersionFields.title.id]: {
      field: documentVersionFields.title,
      displayValues: [versionTitle],
      values: [versionTitle],
    },
    [documentVersionFields.contentSource.id]: {
      field: documentVersionFields.contentSource,
      displayValues: contentSourceValue,
      values: contentSourceValue,
    },
    [documentVersionFields.contentURL.id]: {
      field: adaptField[documentVersionFields.contentURL.id](documentVersionFields.contentURL),
      displayValues: [versionContentURL],
      values: [versionContentURL],
    },
  }
}

// [TODO-3073] - Consider exposing this as part of `useComposableForm`
//              - customValues are generic enough
//              - but will need a custom definition for the rest of a model for conversion
export const parseFormValuesToModel = (formValues: FormValuesType)
: Partial<DocumentVersion> => {
  const { title, contentURL, contentSource, ...customValues } = formValues

  return {
    title: title?.toString(),
    contentURL: contentURL?.toString(),
    customValues: formToModel(customValues),
  }
}

export const documentVersionConfig = (documentVersionORM: DocumentVersionORM) => {
  return {
    fields: Object.values(documentVersionFields).map((field) => {
      const adaptedField = transformations(documentVersionORM)[field.id](field)

      return {
        ...adaptedField,
        required: adaptedField.required ?? false,
      }
    }),
  }
}
