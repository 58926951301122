import React from 'react'
import {
  DNABox,
  DNAText,
  luxColors,
} from '@alucio/lux-ui'

import { Controller, useFormContext } from 'react-hook-form'

import InputComponent from 'src/components/Publishers/InputComponent'

type ContextType = {
  name: string
  clientConfigurationFields: {
    domain: string
    username: string
    relationType: string
    namedFilter: string
    vqlQuery: string
    revokedValues: string
    archivedValues: string
  }
}

// convert this into a variant
const VeevaIntegration = () => {
  const { control, formState } = useFormContext<ContextType>()
  const { errors } = formState

  return (
    <DNABox fill>
      <DNABox appearance="col" spacing="md" fill>
        <DNABox appearance="col" spacing="sm">
          <Controller
            rules={{ required: 'This field is required' }}
            control={control}
            render={({ field }) =>
              (<InputComponent
                title="Integration name"
                characterLimit={250}
                showCharacterCounter={true}
                required={true}
                removeMarginPadding
                inputStyle={errors.name && { borderColor: luxColors.error.primary }}
                {...field}
              />)
            }
            name="name"
          />
          {errors.name && errors.name.type === 'required' &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.name.message}
            </DNAText>
          }
          {errors.name && errors.name.type === 'maxLength' &&
            <DNAText style={{ color: luxColors.error.primary }}>
              Maximum character limit is 250
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="sm">
          <Controller
            name="clientConfigurationFields.domain"
            rules={{ required: 'This field is required' }}
            control={control}
            render={({ field }) =>
              (<InputComponent
                title="Vault domain name"
                required={true}
                removeMarginPadding
                inputStyle={
                  (errors.clientConfigurationFields && errors.clientConfigurationFields.domain) &&
                  { borderColor: luxColors.error.primary }}
                {...field}
              />)
            }
          />
          {(errors.clientConfigurationFields && errors.clientConfigurationFields.domain) &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.domain.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="sm">
          <Controller
            name="clientConfigurationFields.username"
            rules={{ required: 'This field is required' }}
            control={control}
            render={({ field }) =>
              (<InputComponent
                title="Username"
                required={true}
                removeMarginPadding
                inputStyle={
                  (errors.clientConfigurationFields && errors.clientConfigurationFields.username) &&
                  { borderColor: luxColors.error.primary }}
                {...field}
              />)}
          />
          {(errors.clientConfigurationFields && errors.clientConfigurationFields.username) &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.username.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="sm">
          <Controller
            name="clientConfigurationFields.relationType"
            rules={{ required: false }}
            render={({ field }) =>
              (<InputComponent
                title="Relation Type"
                required={false}
                removeMarginPadding
                inputStyle={
                  (errors.clientConfigurationFields && errors.clientConfigurationFields.relationType) &&
                  { borderColor: luxColors.error.primary }}
                {...field}
              />)}
            control={control}
          />
          {(errors.clientConfigurationFields && errors.clientConfigurationFields.relationType) &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.relationType.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="sm">
          <Controller
            render={({ field }) =>
              (<InputComponent
                removeMarginPadding
                title="Named Filter"
                {...field}
              />)}
            name="clientConfigurationFields.namedFilter"
            rules={{ required: false }}
            control={control}
          />
        </DNABox>
        <DNABox appearance="col" spacing="sm">
          <Controller
            name="clientConfigurationFields.vqlQuery"
            render={({ field }) =>
              (<InputComponent
                removeMarginPadding
                multiline
                numOfLines={3}
                title="Vql Query"
                {...field}
              />)}
            control={control}
          />
        </DNABox>
        <DNABox appearance="col" spacing="sm">
          <Controller
            control={control}
            name="clientConfigurationFields.revokedValues"
            render={({ field }) => (
              <InputComponent
                {...field}
                title="Revoked values (comma separated)"
                required={false}
                removeMarginPadding
              />
            )}
          />
        </DNABox>
        <DNABox appearance="col" spacing="sm">
          <Controller
            control={control}
            name="clientConfigurationFields.archivedValues"
            render={({ field }) => (
              <InputComponent
                {...field}
                title="Archived values (comma separated)"
                required={false}
                removeMarginPadding
              />
            )}
          />
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

export default VeevaIntegration
