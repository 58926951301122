import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  ReactNode,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/state/redux';
import { useMachine } from '@xstate/react';
import { StateFrom } from 'xstate';
import { BroadcastChannel } from 'broadcast-channel';
import { Notation, HubSectionItemStatus } from '@alucio/aws-beacon-amplify/src/models';
import { PW } from 'src/state/machines/presentation/playerWrapper';
import { SharedFilesContextType } from 'src/screens/Hubs/EditHub/Widgets/SharedFilesComponents/SharedFilesProvider';
import notationsMachineConfig from 'src/state/machines/notation/notation';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { PresentationBroadcastContent } from '@alucio/core/lib/state/context/PresentationPlayer/types';
import { useTenant } from 'src/state/redux/selector/tenant';
import ActiveUser from 'src/state/global/ActiveUser';

type NotationsMachine = ReturnType<typeof notationsMachineConfig>

export interface NotationsProviderProps {
  children: ReactNode
  sharedFilesState: SharedFilesContextType
}

export interface NotationsContextType {
  notationsMachineState: StateFrom<NotationsMachine>
  visibleNotationMappedByIndex: Record<string, Notation[]>
  editingId?: string
  presentableState?: PresentationBroadcastContent
  currentBrowsingPage?: string
  createNotation: () => void
  cancelEdit: (notationId: string) => void
  editDescription: (notationId: string) => void
  updateDescription: (description: string) => void
  deleteNotation: (notationId: string) => void
  setCurrentActiveNotationId: (notationId: string) => void
}

const presentationChannel = new BroadcastChannel<PW.PresentationChannelMessage>('PRESENTATION_CHANNEL')

export const NotationsContext = createContext<NotationsContextType>(undefined!)

export const NotationsProvider: React.FC<NotationsProviderProps> = ({
  children,
  sharedFilesState,
}) => {
  /** STATE MACHINE */
  const { activePresentation, meetingId, updateNotations, setActiveSlideByPresentationPageNumber } = useContent()
  const modalState = useSelector((state: RootState) => state.contentPreviewModal)
  const tenant = useTenant(ActiveUser.user?.tenantId || '');
  const disableNotationDescription = tenant?.tenant.config.hubsConfig.disableNotationDescription || false;
  const documentVersionId = activePresentation?.currentPresentablePage?.documentVersionORM?.model.id;

  const notationsMachine = useRef(notationsMachineConfig(
    sharedFilesState,
    presentationChannel,
    disableNotationDescription,
    updateNotations,
    setActiveSlideByPresentationPageNumber,
    meetingId ?? 'notationSM',
    modalState.currentActiveNotationId,
  )).current
  const [
    notationsMachineState,
    notationsMachineSend,
    notationsMachineService,
  ] = useMachine(notationsMachine)

  const eventsToMonitor = ['click', 'press']
  // This clickHandler is for when user click outside the iframe, we want to exit out the state
  const clickHandler = useCallback(event => {
    const playerIframe = document.getElementById(`player-iframe-${documentVersionId}`)
    if (event.target !== playerIframe) {
      notationsMachineSend({ type: 'BACK_TO_IDLE' })
    }
  }, [notationsMachineSend, documentVersionId])
  const isSetCoordinateMode = notationsMachineState.matches('setCoordinateMode')

  useEffect(() => {
    // Listen to any click/press event during set coordinate mode
    if (isSetCoordinateMode) {
      eventsToMonitor.forEach(event => document.addEventListener(event, clickHandler, true))
    } else eventsToMonitor.forEach(event => document.removeEventListener(event, clickHandler, true))
    return () => {
      eventsToMonitor.forEach(event => document.removeEventListener(event, clickHandler, true))
    }
  }, [isSetCoordinateMode])

  useEffect(() => {
    // Clean up function when the machine stops
    const onStop = () => {
      // Clean up any event listeners
      eventsToMonitor.forEach(event => document.removeEventListener(event, clickHandler, true))
      // Inform player machine to exit out notation mode
      presentationChannel.postMessage({
        type: 'TOGGLE_NOTATIONS',
        meetingId: meetingId ?? 'notationSM',
        toggleOn: false,
      })
    };

    notationsMachineService.onStop(onStop);
  }, [notationsMachineService]);

  const presentableState = notationsMachineState.context.presentableState
  const [currentBrowsingPage, setCurrentBrowsingPage] =
    useState<string | undefined>(presentableState?.state.page.toString())

  useEffect(() => {
    // presentableState updates every 2 seconds
    // Only reassign currentBrowsingPage when the value actually changed
    if (presentableState?.state.page !== currentBrowsingPage) {
      setCurrentBrowsingPage(presentableState?.state.page.toString())
      const currentActiveNotationId = notationsMachineState.context.currentActiveNotationId
      const currentActiveNotationPageId = notationsMachineState.context.presentableState?.notations
        ?.find(notation => notation.id === currentActiveNotationId)?.pageId
      const documentVersionId = presentableState?.documentVersionId ?? ''
      const presentingPageNum = presentableState?.state.page ?? 0
      const presentingPageId = `${documentVersionId}_${presentingPageNum}`
      if (presentingPageId !== currentActiveNotationPageId) {
        notationsMachineSend({ type: 'SET_CURRENT_ACTIVE_NOTATION' })
      }
    }
  }, [presentableState?.state.page])

  /* CONTEXT START */
  const notations: Notation[] | undefined = presentableState?.notations

  const visibleNotationMappedByIndex: Record<string, Notation[]> = useMemo(() => {
    const result = {}
    notations?.forEach(notation => {
      if (notation.status === HubSectionItemStatus.ACTIVE) {
        const pageNum = notation.pageId.split('_').pop() ?? ''
        result[pageNum]
          ? result[pageNum].push(notation)
          : result[pageNum] = [notation]
      }
    })
    // sort each array (key) base on creation
    Object.keys(result).forEach(pageNum => {
      result[pageNum].sort((a, b) => {
        return a.createdAt - b.createdAt
      })
    })
    return result
  }, [notations])

  const editingId = notationsMachineState.matches('editDescription')
    ? notationsMachineState.context.editingNotationId
    : undefined

  /* CONTEXT END */

  /* FUNCTIONS START */
  const createNotation = useCallback(() => {
    notationsMachineSend({ type: 'ENTER_SET_COORDINATE_MODE' })
  }, [notationsMachineSend])

  const cancelEdit = useCallback((notationId: string) => {
    if (notationsMachineState.context.isNewNotation) {
      notationsMachineSend({ type: 'DELETE_NOTATION', notationId })
    }
    else notationsMachineSend({ type: 'BACK_TO_IDLE' })
  }, [notationsMachineSend, notationsMachineState])

  const editDescription = useCallback((notationId: string) => {
    notationsMachineSend({ type: 'EDIT_NOTATION', notationId })
  }, [notationsMachineSend])

  const updateDescription = useCallback((description: string) => {
    notationsMachineSend({ type: 'UPDATE_DESCRIPTION', description })
  }, [notationsMachineSend])

  const deleteNotation = useCallback((notationId: string) => {
    notationsMachineSend({ type: 'DELETE_NOTATION', notationId })
  }, [notationsMachineSend])

  const setCurrentActiveNotationId = useCallback((notationId?: string) => {
    notationsMachineSend({ type: 'SET_CURRENT_ACTIVE_NOTATION', notationId })
  }, [notationsMachineSend])

  /* FUNCTIONS END */

  const value: NotationsContextType = {
    notationsMachineState,
    visibleNotationMappedByIndex,
    editingId,
    presentableState,
    currentBrowsingPage,
    createNotation,
    cancelEdit,
    editDescription,
    updateDescription,
    deleteNotation,
    setCurrentActiveNotationId,
  }

  return (
    <NotationsContext.Provider value={value}>
      {children}
    </NotationsContext.Provider>
  )
}

export const useNotationsState = () => {
  const context = useContext(NotationsContext)
  if (!context) {
    throw new Error('useNotationsState must be used within the NotationsProvider')
  }
  return context
}
