import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { SvgProps } from 'react-native-svg'
import { useSelector } from 'react-redux';
import { RootState } from 'src/state/redux';
import { DNABox, util, Iffy, DNAButton, DNADivider, DNAText } from '@alucio/lux-ui';
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions';
import { useContentPreviewModal } from 'src/components/ContentPreviewModalV2/State/ContentPreviewModalStateProvider';
import { DocumentAccessLevel, DocumentStatus, FileType } from '@alucio/aws-beacon-amplify/src/models';
import { useDNADocumentVersionActions } from 'src/components/DNA/Document/DNADocumentVersion.actions';
import { useDNACustomDeckActions } from 'src/components/DNA/Document/DNACustomDeck.actions';
import { useMeetingPresentedMeta } from 'src/components/ContentPreviewModalV2/State/MeetingPresentedMetaProvider';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { useAppSettings } from 'src/state/context/AppSettings';
import { CALLOUTS_AVAILABLE_DOC_TYPE } from 'src/state/machines/notation/notationUtils';
import TextBoxEditOutline from 'src/assets/icons/TextBoxEditOutline';

/** Sidebar Content Components */
import AssociatedFiles from './Panels/AssociatedFiles';
import Callouts from './Panels/Notations/Notations';
import CustomNotes from './Panels/CustomNotes/CustomNotes';
import Details from './Panels/Details';
import MeetingNotes from './Panels/MeetingNotes';
import SlideNotes from './Panels/SlideNotes';
import VersionHistory from './Panels/VersionHistory';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { isCustomDeckORM, isFolderItemORM } from 'src/types/typeguards';

/** SECTION: Enums */
export enum SidebarOptions {
  versionHistorySideBar = 'versionHistorySideBar',
  meetingNotes = 'meetingNotes',
  detailsSideBar = 'detailsSideBar',
  speakerNotesSidebar = 'speakerNotesSidebar',
  associatedFilesSidebar = 'associatedFilesSidebar',
  callouts = 'callouts',
  customNotes = 'customNotes'
}
/** !SECTION: Enums */

/** SECTION: Interfaces */
interface OptionAvailabilityConfig {
  condition: boolean
}

interface TabConfig {
  key: string,
  id: SidebarOptions,
  label: string,
  icon: string | React.FC<SvgProps>,
  customIconName?: string,
  tooltip: string,
  testID?: string,
  component: React.ElementType,
}

/** !SECTION: Interfaces */

/** SECTION: Types */
type OptionAvailabilityConfigs = Record<SidebarOptions, OptionAvailabilityConfig>
/** !SECTION: Types */

/** SECTION: Globals */
export const styles = StyleSheet.create({
  arrowIcon: {
    height: 35,
    width: 35,
    marginTop: 8,
    marginBottom: 4,
  },
  sideBarIcon: {
    height: 35,
    width: 35,
    marginTop: 10,
  },
  sideBarCustomIcon: {
    marginTop: 10,
  },
  tooltipText: {
    color: colors['color-text-white'],
  },
  mainWrapper: {
    backgroundColor: colors['color-text-white'],
    borderColor: colors['color-gray-80'],
    borderRightWidth: 1,
    width: 56,
  },
  sideBarHeaderContainer: {
    minHeight: 75,
    padding: 18,
  },
  sidebarContentContainer: {
    paddingHorizontal: 24,
    paddingBottom: 20,
  },
});

export const tabConfigs: Record<SidebarOptions, TabConfig> = {
  [SidebarOptions.speakerNotesSidebar]: {
    key: 'slides-button',
    id: SidebarOptions.speakerNotesSidebar,
    label: 'Speaker Notes',
    icon: 'text-box-outline',
    tooltip: 'Speaker Notes',
    testID: 'content-preview-sidebar-speaker-note',
    component: SlideNotes,
  },
  [SidebarOptions.customNotes]: {
    key: 'custom-notes-button',
    id: SidebarOptions.customNotes,
    label: 'My Speaker Notes',
    icon: TextBoxEditOutline,
    customIconName: 'text-box-edit-outline',
    tooltip: 'My Speaker Notes',
    testID: 'content-preview-sidebar-my-speaker-notes',
    component: CustomNotes,
  },
  [SidebarOptions.meetingNotes]: {
    key: 'meeting-notes-button',
    id: SidebarOptions.meetingNotes,
    label: 'Comments',
    icon: 'account-details',
    tooltip: 'Comments',
    testID: 'content-preview-sidebar-comments',
    component: MeetingNotes,
  },
  [SidebarOptions.associatedFilesSidebar]: {
    key: 'associated-files-button',
    id: SidebarOptions.associatedFilesSidebar,
    label: 'Associated Files',
    icon: 'file-multiple-outline',
    tooltip: 'Associated Files',
    component: AssociatedFiles,
  },
  [SidebarOptions.versionHistorySideBar]: {
    key: 'version-history',
    id: SidebarOptions.versionHistorySideBar,
    label: 'Version History',
    icon: 'layers-triple-outline',
    tooltip: 'Version History',
    testID: 'content-preview-sidebar-version-history',
    component: VersionHistory,
  },
  [SidebarOptions.detailsSideBar]: {
    key: 'details-button',
    id: SidebarOptions.detailsSideBar,
    label: 'Details',
    icon: 'information-outline',
    tooltip: 'Details',
    testID: 'content-preview-sidebar-details',
    component: Details,
  },
  [SidebarOptions.callouts]: {
    key: 'callouts-button',
    id: SidebarOptions.callouts,
    label: 'Callouts',
    icon: 'lightbulb-on-outline',
    tooltip: 'Callouts',
    testID: 'content-preview-sidebar-callouts',
    component: Callouts,
  },
}
/** !SECTION: Globals */

/** SECTION: Functional Components */
const Sidebar: React.FC = () => {
  const {
    isFolderItem, activeDocumentVersionORM, isPublisher, isModifiedDocumentVersion, isAssociatedFile,
  } = useContentPreviewModal()
  const { onOpenMeetingNotes } = useMeetingPresentedMeta();
  const documentActions = useDNADocumentActions()
  const { activePresentation } = useContent()
  const { shareEmail } = useDNADocumentVersionActions();
  const { shareEmail: shareCustomDeckEmail } = useDNACustomDeckActions();
  const featureFlags = useFeatureFlags('enableShareCustomDeck', 'enableMeetingNotes', 'enableCustomNotes');
  const { meetingId, hubId, pageNumber } = useSelector((state: RootState) => state.contentPreviewModal)
  const areNotesOpenByDefault = meetingId && featureFlags.enableMeetingNotes;
  const [selectedSideBarOption, setSelectedSideBarOption] = useState<SidebarOptions | null>(
    (areNotesOpenByDefault && SidebarOptions.meetingNotes) || null)
  const isUserDocument = activeDocumentVersionORM?.relations.documentORM.model.accessLevel === DocumentAccessLevel.USER;
  const meetingHistoryMode = !!meetingId
  const hubMode = !!hubId
  const isValidDocTypeForCallouts = CALLOUTS_AVAILABLE_DOC_TYPE.includes(activeDocumentVersionORM?.model.type || '')
  const hasPermissionToCallout = activeDocumentVersionORM?.meta.permissions.MSLExternalNotatable
  const showCalloutTab = hubMode && isValidDocTypeForCallouts && !!hasPermissionToCallout

  useEffect(() => {
    isAssociatedFile && setSelectedSideBarOption(null)
  }, [isAssociatedFile])

  useEffect(() => {
    if (selectedSideBarOption === SidebarOptions.meetingNotes) {
      onOpenMeetingNotes();
    }
  }, [selectedSideBarOption]);

  useEffect(() => {
    if (showCalloutTab && pageNumber) setSelectedSideBarOption(SidebarOptions.callouts)
  }, [showCalloutTab])

  const isUserDocumentOrFolder = (isFolderItem && !activeDocumentVersionORM) || isUserDocument
  const hasAssociatedFiles = (activeDocumentVersionORM?.model.associatedFiles?.length || 0) >
  0 && !isAssociatedFile

  /** Mapping of visibility conditions for each option */
  const optionAvailabilityConfigs: OptionAvailabilityConfigs = {
    speakerNotesSidebar: {
      condition: true,
    },
    customNotes: {
      condition: featureFlags.enableCustomNotes && !hubMode && !isUserDocument && !isPublisher,
    },
    meetingNotes: {
      condition: featureFlags.enableMeetingNotes && meetingHistoryMode,
    },
    versionHistorySideBar: {
      condition: !isUserDocumentOrFolder && !hubMode,
    },
    detailsSideBar: {
      condition: !isUserDocumentOrFolder && !hubMode,
    },
    associatedFilesSidebar: {
      condition: !isUserDocumentOrFolder && hasAssociatedFiles && !hubMode,
    },
    callouts: {
      condition: showCalloutTab,
    },
  }

  /** Ensure we are only allowing a single instance of each option */
  const sidebarOptionOrder = new Set<SidebarOptions>([
    SidebarOptions.speakerNotesSidebar,
    SidebarOptions.customNotes,
    SidebarOptions.meetingNotes,
    SidebarOptions.versionHistorySideBar,
    SidebarOptions.associatedFilesSidebar,
    SidebarOptions.detailsSideBar,
    SidebarOptions.callouts,
  ])

  /** Iterator to generate available options array */
  const getAvailableSidebarOptions = () => {
    const options: SidebarOptions[] = []
    sidebarOptionOrder.forEach(option => options.push(
      ...(optionAvailabilityConfigs[option].condition ? [option] : []),
    ))
    return options
  }

  const { isOnline } = useAppSettings()
  const availableSidebarOptions = getAvailableSidebarOptions()

  const { latestPublishedDocumentVersionORM } = { ...activeDocumentVersionORM?.relations.documentORM.relations.version }
  const { MSLShare } = { ...latestPublishedDocumentVersionORM?.meta.permissions }

  const isLatestPublished = activeDocumentVersionORM?.meta.version.isLatestPublished
  const canDownload = activeDocumentVersionORM?.model.type !== FileType.WEB && (isPublisher ||
    (isLatestPublished && activeDocumentVersionORM?.meta.permissions.MSLDownload))

  // Only the lastest published version of the distributable document that is not revoked can be shared
  const isNotRevoked = activeDocumentVersionORM?.meta.sealedStatus !== DocumentStatus.REVOKED
  const isShareableDeck = isFolderItemORM(activePresentation?.presentable.orm) &&
    isCustomDeckORM(activePresentation?.presentable.orm.relations.itemORM) && featureFlags.enableShareCustomDeck &&
    activePresentation?.presentable.orm.relations.itemORM.meta.permissions.MSLShare

  const canShare = (isNotRevoked && isLatestPublished && MSLShare) || isShareableDeck

  const toggleSidebar = () => {
    selectedSideBarOption ? setSelectedSideBarOption(null) : setSelectedSideBarOption(availableSidebarOptions[0])
  }

  const SelectedSidebar = selectedSideBarOption && tabConfigs[selectedSideBarOption].component

  const onShare = () => {
    if (activeDocumentVersionORM) {
      shareEmail(activeDocumentVersionORM, undefined, meetingId)()
    }
    if (isFolderItemORM(activePresentation?.presentable.orm) &&
    isCustomDeckORM(activePresentation?.presentable.orm.relations.itemORM)) {
      activePresentation?.presentable.orm.relations.itemORM &&
      shareCustomDeckEmail(activePresentation?.presentable.orm.relations.itemORM,
        activePresentation.presentable.orm, meetingId)()
    }
  }

  return (
    <DNABox
      testID="content-preview-modal-side-bar"
      appearance="row"
      style={util.mergeStyles(
        undefined,
        styles.mainWrapper,
        [{ width: 377, borderLeftWidth: 1, borderColor: colors['color-gray-80'] }, !!selectedSideBarOption],
      )}
    >
      <DNABox appearance="row">
        <Iffy is={!!selectedSideBarOption}>
          {SelectedSidebar && <SelectedSidebar/>}
        </Iffy>
        <DNABox
          spacing="between"
          alignX="center"
          appearance="col"
          style={{ borderLeftWidth: 1, borderColor: colors['color-gray-80'], width: 56 }}
        >
          <DNABox spacing="xs" appearance="col">
            <DNAPopover placement="left" disablePopover={['tabletPWA']}>
              <DNAPopover.Anchor>
                <DNAButton
                  status="tertiary"
                  appearance="ghostLink"
                  style={styles.arrowIcon}
                  iconLeft={!selectedSideBarOption ? 'chevron-left' : 'chevron-right'}
                  onPress={toggleSidebar}
                  size="md"
                />
              </DNAPopover.Anchor>
              <DNAPopover.Content>
                <DNAText
                  style={styles.tooltipText}
                  numberOfLines={1}
                >
                  {selectedSideBarOption ? 'Collapse' : 'Expand'}
                </DNAText>
              </DNAPopover.Content>
            </DNAPopover>
            <DNADivider />
            {availableSidebarOptions.map(config => {
              const item = tabConfigs[config]
              const iconProp: DNAButtonProps = {}
              if (typeof item.icon === 'string') {
                iconProp.iconLeft = item.icon
                iconProp.style = styles.sideBarIcon
              }
              else {
                iconProp.customIconLeft = item.icon
                iconProp.customIconName = item.customIconName
                iconProp.style = styles.sideBarCustomIcon
                iconProp.padding = 'md'
              }

              return (item &&
                <DNAPopover placement="left" key={item.key} disablePopover={['tabletPWA']}>
                  <DNAPopover.Anchor>
                    <DNAButton
                      appearance={config === selectedSideBarOption ? 'filled' : 'ghost'}
                      status={config === selectedSideBarOption ? 'primary' : 'tertiary'}
                      onPress={() => setSelectedSideBarOption(item.id)}
                      size="md"
                      {...iconProp}
                    />
                  </DNAPopover.Anchor>
                  <DNAPopover.Content>
                    <DNAText style={styles.tooltipText} numberOfLines={1}>{item?.tooltip}</DNAText>
                  </DNAPopover.Content>
                </DNAPopover>)
            })
            }
          </DNABox>

          {isOnline && ((activeDocumentVersionORM && !isModifiedDocumentVersion && !hubMode) || isShareableDeck) &&
            <DNABox appearance="col" alignX="center" alignY="end">
              {canShare &&
                <DNAPopover placement="left" disablePopover={['tabletPWA']}>
                  <DNAPopover.Anchor>
                    <DNAButton
                      testID="share-icon"
                      status="tertiary"
                      appearance="ghostLink"
                      size="md"
                      style={styles.sideBarIcon}
                      iconLeft="share"
                      onPress={onShare}
                    />
                  </DNAPopover.Anchor>
                  <DNAPopover.Content>
                    <DNAText style={styles.tooltipText} numberOfLines={1}>Share</DNAText>
                  </DNAPopover.Content>
                </DNAPopover>
              }

              {canDownload && activeDocumentVersionORM &&
                <DNAPopover placement="left" disablePopover={['tabletPWA']}>
                  <DNAPopover.Anchor>
                    <DNAButton
                      status="tertiary"
                      appearance="ghostLink"
                      size="md"
                      style={styles.sideBarIcon}
                      iconLeft="download"
                      onPress={() => documentActions.download(activeDocumentVersionORM?.relations.documentORM)()}
                    />
                  </DNAPopover.Anchor>
                  <DNAPopover.Content>
                    <DNAText style={styles.tooltipText} numberOfLines={1}>Download</DNAText>
                  </DNAPopover.Content>
                </DNAPopover>}
            </DNABox>}

        </DNABox>
      </DNABox>

    </DNABox>
  )
}
/** !SECTION: Functional Components */

export default Sidebar
