import { CustomFieldDefinition, CRMIntegration, CrmIntegrationType } from '@alucio/aws-beacon-amplify/src/models';
import { SalesforceSyncer } from './Syncers/SalesforceSyncer';
import { VeevaSyncer } from './Syncers/VeevaSyncer';
import { SalesforceFormTranslator } from './Translators/SalesforceFormTranslator';
import { VeevaFormTranslator } from './Translators/VeevaFormTranslator';
import {
  CRMSubmitMeetingPayload,
  CRMSubmitResponseReferences,
  CRMSubmitStandaloneFormPayload,
  FormSettings,
} from './CRMIndexedDBTypes';
import { SyncEntry } from './ISyncManifest';
import { CustomFieldValuesMap, MeetingORM } from 'src/types/types';

export interface FormTranslatorResponse {
  isLoading?: boolean;
  crmCustomFields: CustomFieldDefinition[];
  crmDefaultValues: CustomFieldValuesMap;
}

export interface ICRMFormTranslator {
  getFormCustomFieldConfig: () => FormTranslatorResponse;
}

export interface ICRMSyncer {
  initialize(): SyncEntry[];
  processManifestEntry(entry: SyncEntry): Promise<SyncEntry[]>;
  configSyncComplete(entries: SyncEntry[]): Promise<FormSettings[]>;
  submitToCRM(crmSubmitPayload: CRMSubmitMeetingPayload): Promise<CRMSubmitResponseReferences>;
  submitStandaloneFormToCRM(crmSubmitPayload: CRMSubmitStandaloneFormPayload): Promise<CRMSubmitResponseReferences>;
  handleDeleteMeeting(meeting: MeetingORM): Promise<CRMSubmitResponseReferences>;
}

const HANDLERS = {
  TRANSLATORS: {
    [CrmIntegrationType.SALESFORCE]: SalesforceFormTranslator,
    [CrmIntegrationType.VEEVA]: VeevaFormTranslator,
  },
  SYNCERS: {
    [CrmIntegrationType.SALESFORCE]: SalesforceSyncer,
    [CrmIntegrationType.VEEVA]: VeevaSyncer,
  },
};

class CRMHandlerClass {
  private crmIntegrationConfig: CRMIntegration;

  constructor(crmIntegrationConfig: CRMIntegration) {
    if (!crmIntegrationConfig.meetingSetting) {
      throw Error('No meetingSetting is defined.');
    }
    this.crmIntegrationConfig = crmIntegrationConfig;
  }

  Translator(formSettings: FormSettings[], primaryTable?: string) {
    const mainTable = primaryTable || this.crmIntegrationConfig.meetingSetting?.apiName || '';
    return new HANDLERS.TRANSLATORS[this.crmIntegrationConfig.crmIntegrationType](formSettings, mainTable);
  }

  Syncer() {
    return new HANDLERS.SYNCERS[this.crmIntegrationConfig.crmIntegrationType](this.crmIntegrationConfig);
  }
}

export const CRMHandler = (crmIntegrationConfig: CRMIntegration) =>
  new CRMHandlerClass(crmIntegrationConfig);
