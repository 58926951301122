
import { DNABox } from '@alucio/lux-ui'
import React from 'react'
import { DocumentORM, FolderORM } from 'src/types/orms'
import { isDocumentORM, isFolderORM } from 'src/types/typeguards'
import InfoPanelStateProvider from './State/InfoPanelStateProvider'
import FolderVariant, { FolderVariantProps } from './Variants/Folder'
import DocumentVariant, { DocumentVariantProps } from './Variants/Document'
import { StyleSheet } from 'react-native'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import useCurrentPage from '../DNA/hooks/useCurrentPage'
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings'
import mergeStyles from '@alucio/lux-ui/src/components/util/mergeStyles'

enum InfoPanelVariants {
  Folder,
  Document,
}
type InfoPanelVariant = keyof typeof InfoPanelVariants

const styles = StyleSheet.create(
  {
    hostContainer: {
      backgroundColor: colors['color-text-white'],
      shadowColor: '#EBEBEB',
      shadowRadius: 8,
    },
    tabletStyles: {
      backgroundColor: colors['color-text-white'],
      borderColor: '#EBEBEB',
    },
  },
)
export interface BaseInfoPanelProps {
  variant?: InfoPanelVariant
  onClosePanel?: () => void
  displayInfoPanel?: boolean
  /** If this component is intended to stay as a global component, we can simply
   * move this from a prop to a wrapper element which watches the InfoPanel slice */
  itemORM?: FolderORM | DocumentORM
}

type VariantInfoPanelProps = FolderVariantProps | DocumentVariantProps

const InfoPanel: React.FC<VariantInfoPanelProps> = props => {
  const { deviceMode } = useAppSettings()

  const route = useCurrentPage({ exact: true })

  const { activateInfoPanel } = { ...route?.configOptions?.[deviceMode] }
  const { itemORM } = props

  /** Kickout if we don't have what we need to render or
   * if the panel is deactivated on the current route */
  if (!activateInfoPanel || !itemORM) return null

  const isTablet = deviceMode === DeviceMode.tablet

  /** Dynamic variant element selection based on the variant prop */
  const InfoPanelVariant = () => {
    let detectedVariant: false | JSX.Element | null
    switch (props.variant) {
      case 'Folder':
        detectedVariant = (isFolderORM(itemORM) &&
          (<FolderVariant
            {...props}
            folderORM={itemORM}
          />)
        )
        break
      /** The document case is currently unused but will likely be implemented in the near future */
      case 'Document':
        detectedVariant = (isDocumentORM(itemORM) &&
          (<DocumentVariant
            {...props}
            documentORM={itemORM}
          />)
        )
        break
      default:
        detectedVariant = null
    }

    return (
      <DNABox
        testID="info-panel-container"
        style={mergeStyles(undefined, styles.hostContainer, [styles.tabletStyles, isTablet])}
      >
        {detectedVariant}
      </DNABox>
    )
  }

  return (
    <InfoPanelStateProvider>
      <InfoPanelVariant />
    </InfoPanelStateProvider>
  )
}

export default InfoPanel
