import React from 'react'
import { Image, ImageSourcePropType } from 'react-native'
import { FolderORM } from 'src/types/types'
import { ContentVariant } from 'src/components/DNA/types'
import {
  Iffy,
  DNACard,
  DNABox,
  DNAText,
  DNAIconStyleProps,
  DNAIcon,
  util,
} from '@alucio/lux-ui'
import { useHistory } from 'src/router'
import { useDNAFolderActions } from 'src/components/DNA/Folder/DNAFolder.actions'
import { useAppSettings } from 'src/state/context/AppSettings'
import { Dimensions } from '@alucio/core'
import FolderEmptyLabel from 'src/components/DNA/Empty/FolderEmptyLabel'
import OpenedPresentationsEmptyState from 'src/components/DNA/Empty/OpenedPresentationsEmptyState'
import HubEmptyState from 'src/components/DNA/Empty/HubsEmptyState'

const DocumentGridBookmarkImg = require('../../../../assets/images/EmptyUI/DocumentGridBookmark.png'),
  DocumentGridEmptyImg = require('../../../../assets/images/EmptyUI/DocumentGridEmpty.png'),
  EmailTemplatesEmptyImg = require('../../../../assets/images/EmptyUI/EmailTemplatesEmpty.png'),
  AnalyticsEmptyImg = require('../../../../assets/images/EmptyUI/EmailTemplatesEmpty.png'),
  FolderListBookmarkImg = require('../../../../assets/images/EmptyUI/FolderListBookmark.png'),
  FolderEmptyImg = require('../../../../assets/images/EmptyUI/FolderEmpty.png'),
  FolderListCreateImg = require('../../../../assets/images/EmptyUI/FolderListCreate.png'),
  FolderUpdatesEmptyImg = require('../../../../assets/images/EmptyUI/FolderUpdatesEmpty.png'),
  MeetingsHistoryEmptyImg = require('../../../../assets/images/EmptyUI/MeetingsHistoryEmpty.png'),
  UsersListEmptyImg = require('../../../../assets/images/EmptyUI/UserEmpty.png'),
  NoNetworkImg = require('../../../../assets/images/NoNetwork.svg'),
  IntegrationSettingsEmptyImg = require('../../../../assets/images/EmptyUI/IntegrationSettingsEmpty.png'),
  LoaderImg = require('../../../../assets/images/LoaderDNA.gif'),
  LoaderImgDark = require('../../../../assets/images/LoaderDNA_dark.gif');

export enum EmptyVariant {
  // Document
  DocumentGridBookmark = 'DocumentGridBookmark',
  DocumentGridRecentlyUpdated = 'DocumentGridRecentlyUpdated',
  DocumentGridEmpty = 'DocumentGridEmpty',
  DocumentGridEmptySearch = 'DocumentGridEmptySearch',
  DocumentListEmpty = 'DocumentListEmpty',
  DocumentListEmptySearch = 'DocumentListEmptySearch',
  // Email Templates
  EmailTemplatesListEmpty = 'EmailTemplatesListEmpty',
  // Search Results
  DocumentResultsListEmpty = 'DocumentResultsListEmpty',
  DocumentResultsGridEmpty = 'DocumentResultsGridEmpty',
  MeetingsDocumentResultsEmpty = 'MeetingsDocumentResultsEmpty',
  // Publisher Search Results
  PublisherDocumentResultsListEmpty = 'PublisherDocumentResultsListEmpty',
  // Folder
  FolderListBookmark = 'FolderListBookmark',
  FolderListCreate = 'FolderListCreate',
  FolderGridEmpty = 'FolderGridEmpty',
  FolderListEmpty = 'FolderListEmpty',
  FolderListEmptyWithNoInteraction = 'FolderListEmptyWithNoInteraction',
  FolderUpdatesEmpty = 'FolderUpdatesEmpty',
  FolderListLoading = 'FolderListLoading',
  FolderListLoadingDark = 'FolderListLoadingDark',
  FolderListSharedOffline = 'FolderListSharedOffline',
  UploadsEmpty = 'UploadsEmpty',
  FolderListPresentEmpty = 'FolderListPresentEmpty',
  FolderSharedPresentEmpty = 'FolderSharedPresentEmpty',
  FileListPresentEmpty = 'FileListPresentEmpty',
  FolderBookmarkPresentEmpty = 'FolderBookmarkPresentEmpty',
  ArchivedFolderEmpty = 'ArchivedFolderEmpty',
  // Meetings History
  MeetingsHistoryEmpty = 'MeetingsHistoryEmpty',
  NoNetworkMeetingsHistory = 'NoNetworkMeetingsHistory',
  // Meetings Opened Presentation List
  MeetingsOpenedPresentationsEmpty = 'MeetingsOpenedPresentationsEmpty',
  // Hubs
  HubsEmpty = 'HubsEmpty',
  // Users List
  UsersListEmpty = 'UsersListEmpty',
  // Reports
  AnalyticsEmpty = 'AnalyticsEmpty',
  AnalyticsLoading = 'AnalyticsLoading',
  // Offline
  NoNetworkGenericUnavailable = 'NoNetworkGenericUnavailable',
  // Search
  SearchInstructions = 'SearchInstructions',
  // Integrations
  IntegrationSettingsEmpty = 'IntegrationSettingsEmpty',
  IntegrationPublisherSettingsEmpty = 'IntegrationPublisherSettingsEmpty',
  // Custom Deck - Find Replacement
  FindReplacementEmpty = 'FindReplacementEmpty',
}

interface VariantOptsProps {
  image?: ImageSourcePropType,
  icon?: DNAIconStyleProps,
  imageDimension?: Dimensions,
  label: string | React.ReactElement,
  actionLabel?: string,
  Container?: any,
  testPrefix?: string,
  onPress?: (
    history: ReturnType<typeof useHistory>,
    folderActions?: ReturnType<typeof useDNAFolderActions>
  ) => void
}

type VariantOpts = Record<EmptyVariant, VariantOptsProps>

const variantOpts: VariantOpts = {
  [EmptyVariant.DocumentGridBookmark]: {
    image: DocumentGridBookmarkImg,
    label: 'No current bookmarks',
    actionLabel: 'Start bookmarking',
    onPress: (history) => history.push('/library'),
  },
  [EmptyVariant.DocumentGridEmpty]: {
    image: DocumentGridEmptyImg,
    label: 'No results available for current filters',

  },
  [EmptyVariant.FolderListPresentEmpty]: {
    label: (<DNAText bold status="basic">
      There are no folders.
    </DNAText>),
  },
  [EmptyVariant.FileListPresentEmpty]: {
    label: (<DNAText bold status="basic">
      There are no files in this folder.
    </DNAText>),
  },
  [EmptyVariant.FolderSharedPresentEmpty]: {
    label: (<DNAText bold status="basic">
      There are no shared folders.
    </DNAText>),
  },
  [EmptyVariant.FolderBookmarkPresentEmpty]: {
    label: (<DNAText bold status="basic">
      There are no bookmarked files.
    </DNAText>),
  },
  [EmptyVariant.DocumentGridRecentlyUpdated]: {
    image: DocumentGridEmptyImg,
    label: 'No recently updated files to review',

  },
  [EmptyVariant.DocumentGridEmptySearch]: {
    image: DocumentGridEmptyImg,
    label: 'No results available for current filters',
  },
  [EmptyVariant.UsersListEmpty]: {
    image: UsersListEmptyImg,
    label: 'There are currently no users.',
  },
  [EmptyVariant.EmailTemplatesListEmpty]: {
    image: EmailTemplatesEmptyImg,
    label: 'There are currently no templates.',
  },
  [EmptyVariant.AnalyticsEmpty]: {
    image: AnalyticsEmptyImg,
    label: 'No report selected',
  },
  [EmptyVariant.DocumentListEmpty]: {
    image: DocumentGridEmptyImg,
    label: 'No results available for current filters',
  },
  [EmptyVariant.DocumentResultsListEmpty]: {
    image: FolderListBookmarkImg,
    label: 'No items match your search. Please try a different search.',
    Container: ({ children, style }) => (
      <DNABox fill style={style}>
        {children}
      </DNABox>
    ),
  },
  [EmptyVariant.MeetingsDocumentResultsEmpty]: {
    label: <DNAText status="basic">No items match your search. Please try a different search.</DNAText>,
  },
  [EmptyVariant.DocumentResultsGridEmpty]: {
    image: DocumentGridBookmarkImg,
    label: 'No items match your search. Please try a different search.',
  },
  [EmptyVariant.PublisherDocumentResultsListEmpty]: {
    label: 'No items match your search. Please try a different search.',
    Container: ({ children, style }) => (
      <DNABox fill style={[...style, { height: 400 }]}>
        {children}
      </DNABox>
    ),
  },
  [EmptyVariant.DocumentListEmptySearch]: {
    image: DocumentGridEmptyImg,
    label: 'No results available for current filters',
    Container: ({ children, style }) => (
      <DNABox fill style={style}>
        {children}
      </DNABox>
    ),
  },
  [EmptyVariant.FolderListBookmark]: {
    image: FolderListBookmarkImg,
    label: 'No current bookmarks',
  },
  [EmptyVariant.FolderListEmptyWithNoInteraction]: {
    image: FolderEmptyImg,
    label: 'There are no files in this folder.',
  },
  [EmptyVariant.FolderListEmpty]: {
    image: FolderEmptyImg,
    label: <FolderEmptyLabel />,
  },
  [EmptyVariant.FolderGridEmpty]: {
    image: FolderEmptyImg,
    label: <FolderEmptyLabel />,
  },
  [EmptyVariant.FolderListCreate]: {
    image: FolderListCreateImg,
    label: 'No active folders',
    actionLabel: 'Create folder',
    testPrefix: 'my-folders',
    /** History both passed in here and declared globally in this file in a const above. */
    onPress: (history, folderActions) => () => folderActions
      // Cheating because this action doesn't require a FolderORM
      ?.createFolder?.(history as any as FolderORM)?.(),
  },
  [EmptyVariant.ArchivedFolderEmpty]: {
    image: FolderListCreateImg,
    label: 'No archived folders',
  },
  [EmptyVariant.FolderListLoading]: {
    image: LoaderImg,
    imageDimension: {
      height: 120,
      width: 120,
    },
    label: 'Loading folders ...',
  },
  [EmptyVariant.FolderListLoadingDark]: {
    image: LoaderImgDark,
    imageDimension: {
      height: 120,
      width: 120,
    },
    label: <DNAText testID="loading-folders" h5 status="basic">Loading folders ...</DNAText>,
  },
  [EmptyVariant.AnalyticsLoading]: {
    image: LoaderImg,
    label: 'Loading Reports ...',
  },
  [EmptyVariant.FolderListSharedOffline]: {
    image: NoNetworkImg,
    imageDimension: {
      width: 60,
      height: 73,
    },
    label: 'Shared folders are unavailable in offline mode.',
  },
  [EmptyVariant.FolderUpdatesEmpty]: {
    image: FolderUpdatesEmptyImg,
    label: 'No updates outstanding',
  },
  [EmptyVariant.MeetingsHistoryEmpty]: {
    image: MeetingsHistoryEmptyImg,
    label: 'You have not hosted any meetings.',
  },
  [EmptyVariant.MeetingsOpenedPresentationsEmpty]: {
    label: '',
    Container: () => (
      <DNABox fill appearance="col">
        <OpenedPresentationsEmptyState />
      </DNABox>),
  },
  [EmptyVariant.NoNetworkMeetingsHistory]: {
    image: NoNetworkImg,
    imageDimension: {
      width: 60,
      height: 73,
    },
    label: 'Meeting history is unavailable in offline mode.',
  },
  [EmptyVariant.HubsEmpty]: {
    label: '',
    Container: () => (
      <DNABox fill childFill appearance="col">
        <HubEmptyState />
      </DNABox>),
  },
  [EmptyVariant.NoNetworkGenericUnavailable]: {
    icon: {
      status: 'warning',
      name: 'wifi-off',
      appearance: 'ghost',
      size: 'xl',
    },
    label: 'Unavailable offline',
  },
  [EmptyVariant.SearchInstructions]: {
    icon: {
      status: 'primary',
      appearance: 'ghost',
      name: 'magnify',
      size: 'xl',
    },
    label: 'Enter a search term to begin',
  },
  [EmptyVariant.UploadsEmpty]: {
    image: FolderUpdatesEmptyImg,
    label: 'Upload files to use in your presentations',
    actionLabel: 'Upload',
  },
  [EmptyVariant.IntegrationSettingsEmpty]: {
    image: IntegrationSettingsEmptyImg,
    label: 'Start by adding a new integration',
    actionLabel: 'Add integration',
  },
  [EmptyVariant.IntegrationPublisherSettingsEmpty]: {
    image: IntegrationSettingsEmptyImg,
    label: 'Currently, there are no integrations available for this tenant.',
    actionLabel: '',
  },
  [EmptyVariant.FindReplacementEmpty]: {
    image: DocumentGridEmptyImg,
    label: (<DNAText bold status="flatAlt">
      No slides remaining
    </DNAText>),
  },
}

// [TODO] - Move to lux-ui?
export interface EmptyStateProps {
  emptyVariant: EmptyVariant,
  variant?: ContentVariant,
  useContainer?: boolean,
}

export const DNAEmpty: React.FC<EmptyStateProps> = (props) => {
  const { deviceMode } = useAppSettings()
  const {
    emptyVariant,
    variant = 'default',
    useContainer = true,
  } = props
  const {
    Container,
    image,
    imageDimension,
    label,
    icon,
  } = variantOpts[emptyVariant]

  // Some variants are wrapped in Cards
  const ConditionalContainer = useContainer
    ? Container || DNACard
    : React.Fragment

  const isVirtual = variant === 'virtual'
  const isMeetings = variant === 'meetings'

  // this flag is used so that the white background of the main dnaEmpty box does not overlap the border of the lists
  const desktopBorder = emptyVariant === EmptyVariant.FolderListCreate ||
    emptyVariant === EmptyVariant.UploadsEmpty ||
    emptyVariant === EmptyVariant.FolderListEmpty ||
    emptyVariant === EmptyVariant.MeetingsOpenedPresentationsEmpty ||
    emptyVariant === EmptyVariant.FindReplacementEmpty

  const isCustomDeck = variant === 'customDeck'
  const bgColor = {
    backgroundColor: isMeetings || isVirtual || deviceMode === 'tablet' || desktopBorder
      ? 'transparent' : 'white',
  }
  const conditionalProps = useContainer
    ? { style: [{ shadowOpacity: 0 }, bgColor] }
    : {}

  return (
    <DNABox
      fill
      alignY={emptyVariant !== EmptyVariant.MeetingsOpenedPresentationsEmpty ? 'center' : 'start'}
      style={bgColor}
    >
      <ConditionalContainer {...conditionalProps}>
        <DNABox
          fill
          appearance="col"
          alignX="center"
          alignY="center"
          spacing="md"
          style={[{ padding: 48 }, bgColor]}
        >
          <Iffy is={(!isVirtual && !isCustomDeck && image)}>
            <Image
              source={image!}
              style={util.mergeStyles(
                undefined,
                [{ height: 205, width: 341 }, !imageDimension],
                [{ height: imageDimension?.height, width: imageDimension?.width }, !!imageDimension],
              )}
              resizeMode="contain"
            />
          </Iffy>

          <Iffy is={icon}>
            <DNAIcon.Styled {...icon} />
          </Iffy>

          <DNAText
            h5
            testID="empty-content-placeholder"
            {...((isVirtual || isCustomDeck || isMeetings) ? { status: 'subtle' } : {})}
          >
            {label}
          </DNAText>
        </DNABox>
      </ConditionalContainer>
    </DNABox>
  )
}

export default DNAEmpty
