import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useSelector, { composite } from 'src/hooks/useSelector';
import { Page, PageGroup } from '@alucio/aws-beacon-amplify/src/models';
import * as findReplacementSelector from 'src/state/machines/findReplacement/FindReplacement.selectors';
import { useFindReplacementState, GroupDraftStatus } from '../FindReplacementProvider';
import { DocumentVersionORM } from 'src/types/orms';

interface InitialValues {
  documentVersionORM: DocumentVersionORM
}

export interface RightContentStateType {
  documentVersionORM: DocumentVersionORM,
  isReplacedGroup: boolean,
  allPageGroups: PageGroup[],
  allSingleSlide: Page[],
  groupCardExpandState: Record<string, number>,
  setGroupCardExpandState: React.Dispatch<React.SetStateAction<Record<string, number>>>,
}

export const RightContentStateContext = createContext<RightContentStateType | null>(null!);
RightContentStateContext.displayName = 'RightContentContext';

const RightContentStateProvider: React.FC<PropsWithChildren<InitialValues>> = (props) => {
  const { service } = useFindReplacementState();
  const { documentVersionORM } = props
  const [groupCardExpandState, setGroupCardExpandState] = useState<Record<string, number>>({})

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      findReplacementSelector.activeGroup,
    ),
  );

  const isReplacedGroup = useMemo(() => cond.activeGroup?.status === GroupDraftStatus.REPLACED, [cond.activeGroup])
  const allPageGroups: PageGroup[] = useMemo(() => documentVersionORM.model.pageGroups || [], [documentVersionORM])
  const allSingleSlide: Page[] = useMemo(() => documentVersionORM.meta.allPages, [documentVersionORM])

  useEffect(() => {
    setGroupCardExpandState(allPageGroups
      .reduce<Record<string, number>>((acc, _, index) => {
        acc[index] = 0;
        return acc;
      }, {}))
  }, [documentVersionORM])

  const contextValue: RightContentStateType = {
    documentVersionORM,
    isReplacedGroup,
    allPageGroups,
    allSingleSlide,
    groupCardExpandState,
    setGroupCardExpandState,
  };

  return (
    <RightContentStateContext.Provider value={contextValue}>
      {props.children}
    </RightContentStateContext.Provider>
  );
};

RightContentStateProvider.displayName = 'RightContentStateProvider';

export const useRightContentState = () => {
  const context = useContext(RightContentStateContext);
  if (!context) {
    throw new Error('useRightContentState must be used within the RightContentStateProvider');
  }
  return context;
};

export default RightContentStateProvider;
