import { useEffect } from 'react';
import useLazyRef from 'src/hooks/useLazyRef';
import debounce from 'lodash/debounce'
import { observeSlice } from 'src/state/redux';
import { SyncStateORM, syncState } from 'src/state/redux/selector/cache'
import { matchesState } from 'xstate';

const track = debounce(
  (event, data) => {
    analytics?.track(event, data)
  },
  100,
)

let initialSyncManifestCount: number | null = null;

const contentSyncAnalyticsHandler = (val: { prev: SyncStateORM, next: SyncStateORM }) => {
  const { prev, next } = val;

  const syncManifest = next.context?.syncManifest || {};

  // Save the initial sync manifest count
  if (initialSyncManifestCount === null && Object.keys(syncManifest).length > 0) {
    initialSyncManifestCount = Object.keys(syncManifest).length;
  }

  const prevState = {
    isSyncing: matchesState('online.sync', prev.value),
    isComplete: matchesState('online.idle', prev.value),
    isPaused: matchesState('online.paused', prev.value),
  };

  const nextState = {
    isSyncing: matchesState('online.sync', next.value),
    isComplete: matchesState('online.idle', next.value),
    isPaused: matchesState('online.paused', next.value),
  };

  if (!prevState.isPaused && nextState.isPaused) {
    track('SYNC_PAUSE', {
      action: 'PAUSE',
      category: 'SYNC',
    });
  }

  if (!prevState.isSyncing && nextState.isSyncing) {
    track('SYNC_START', {
      action: 'START',
      category: 'SYNC',
    });
  }

  if (prevState.isPaused && !nextState.isPaused && nextState.isSyncing) {
    track('SYNC_RESUME', {
      action: 'RESUME',
      category: 'SYNC',
    });
  }

  if (prevState.isSyncing && nextState.isComplete) {
    const errorEntries = next.context?.error?.syncEntries || {};
    const totalDocsToSync = initialSyncManifestCount || 0;
    const totalErrors = Object.keys(errorEntries).length;

    track('SYNC_COMPLETE', {
      action: 'COMPLETE',
      category: 'SYNC',
      totalErrors: totalErrors,
      totalSynced: totalDocsToSync - totalErrors,
    });
  }
}

const contentSyncObserver = () => {
  let prevState: SyncStateORM
  let tempState: SyncStateORM

  const obs = observeSlice<SyncStateORM>(syncState)
    .filter(next => {
      if (!prevState) {
        prevState = next
        return false
      }

      return true
    })
    .map(next => {
      tempState = prevState
      prevState = next

      return {
        prev: tempState,
        next,
      }
    })

  return obs.subscribe({
    next: contentSyncAnalyticsHandler,
  })
}

/**
 * Headless monitor on Redux's contentSync slice to fire analytic events as side-effects
 * Follows the same approach as we do in other State machine analytics
 * @returns void
 */
const ContentSyncMonitor = () => {
  const subscription = useLazyRef(contentSyncObserver)

  useEffect(
    () => () => {
      subscription.current?.unsubscribe()
    },
    [],
  )

  return null
}

export default ContentSyncMonitor
