import React, { useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { useContentViewerModalState } from '../../state/ContentViewerModalStateProvider'
import { DNABox, DNAButton, DNAChip, DNAText, Hoverable, Iffy, luxColors } from '@alucio/lux-ui';
import { AssociatedFileORM, getAssociatedFileTitle } from 'src/types/types';
import {
  AssociatedFileType,
  AttachedFile,
  Document,
  DocumentStatus,
  FileType,
} from '@alucio/aws-beacon-amplify/src/models';
import { useAllDocumentsInstance } from 'src/state/redux/selector/document';
import { useDispatch } from 'src/state/redux';
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
import { downloadContentFromCloudfront } from 'src/utils/loadCloudfrontAsset/loadCloudfrontAsset';
import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

import mergeStyles from '@alucio/lux-ui/src/components/util/mergeStyles';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList';

const styles = StyleSheet.create({
  fileRow: {
    paddingBottom: 16,
    paddingTop: 16,
    borderTopColor: colors['color-gray-80'],
    borderTopWidth: 1,
  },
  hoveredRow: {
    backgroundColor: luxColors.mainContainer.primary,
  },
})

interface FileRowProps {
  file: AssociatedFileORM,
}

const AssociatedDocumentRow = (props: FileRowProps) => {
  const { file } = props
  const { activeDocVersionORM } = useContentViewerModalState()
  const documentActions = useDNADocumentActions()
  const dispatch = useDispatch()
  const { setActiveDocVersionORMAndSlide } = useContentViewerModalState()
  const [documentORM] = useAllDocumentsInstance({ filter: { model: { id: file.file?.id } } })
  const [isHovered, setIsHovered] = useState<boolean>(false);

  function toggleHover(): void {
    setIsHovered(currVal => !currVal);
  }

  const onFileSelected = () => {
    const docVersion = documentORM.relations.version.latestUsableDocumentVersionORM
    dispatch(
      contentPreviewModalActions.changeDocument({
        documentVersionId: docVersion.model.id,
        addToHistory: true,
      }))
    setActiveDocVersionORMAndSlide(docVersion, 1)
    analytics?.track('DOCUMENT_AF_OPENED', {
      action: 'AF_OPENED',
      attachedContentId: file.model.attachmentId,
      category: 'DOCUMENT',
      documentId: activeDocVersionORM.model.documentId,
      documentVersionId: activeDocVersionORM.model.id,
    });
  }
  const isWebDoc = documentORM.model.type === FileType.WEB
  return (
    <Pressable onPress={onFileSelected}>
      <Hoverable onHoverIn={toggleHover} onHoverOut={toggleHover}>
        <DNABox
          fill
          spacing="between"
          alignY="center"
          style={mergeStyles(undefined, styles.fileRow, [styles.hoveredRow, isHovered])}
        >
          {/* TITLE + CHIPS */}
          <DNABox
            appearance="col"
            spacing="sm"
          >
            <DNAText>{getAssociatedFileTitle(file)}</DNAText>
            <DNABox spacing="sm">
              <DNAChip appearance="tag">{documentORM.model.type}</DNAChip>
              <CustomFieldBadgeList documentVersionORM={activeDocVersionORM} />
            </DNABox>
          </DNABox>

          <Iffy is={documentORM.meta.permissions.MSLDownload && !isWebDoc}>
            {/* DOWNLOAD BUTTON */}
            <DNAButton
              appearance="ghost"
              status="tertiary"
              iconLeft="download"
              onPress={documentActions.download(documentORM)}
            />
          </Iffy>
        </DNABox>
      </Hoverable>
    </Pressable>
  )
}

const AttachedFileRow = (props: FileRowProps) => {
  const { file } = props
  const { activeDocVersionORM } = useContentViewerModalState();
  const attachedFile = file.file as AttachedFile;
  const [isHovered, setIsHovered] = useState<boolean>(false);

  function toggleHover(): void {
    setIsHovered(currVal => !currVal);
  }

  const onFileSelected = () => {
    downloadContentFromCloudfront(attachedFile.srcFile.key, attachedFile.srcFileName, attachedFile.type)
    analytics?.track('DOCUMENT_AF_DOWNLOAD', {
      action: 'AF_DOWNLOAD',
      attachedContentId: file.model.attachmentId,
      category: 'DOCUMENT',
      documentId: activeDocVersionORM.relations.documentORM.model.id,
      documentVersionId: activeDocVersionORM.model.id,
      type: file.model.type,
    });
  }
  return (
    <Pressable onPress={onFileSelected}>
      <Hoverable onHoverIn={toggleHover} onHoverOut={toggleHover}>
        <DNABox
          fill
          spacing="between"
          alignY="center"
          style={mergeStyles(undefined, styles.fileRow, [styles.hoveredRow, isHovered])}
        >
          {/* TITLE + CHIPS */}
          <DNABox
            appearance="col"
            spacing="sm"
          >
            <DNAText>{attachedFile.title}</DNAText>
            <DNAChip
              appearance="tag"
              status="basic"
              style={{ alignSelf: 'flex-start' }}
            >
              {attachedFile.type}
            </DNAChip>
          </DNABox>

          {/* DOWNLOAD BUTTON */}
          <DNAButton
            appearance="ghost"
            status="tertiary"
            iconLeft="download"
            onPress={onFileSelected}
          />
        </DNABox>
      </Hoverable>
    </Pressable>
  )
}

function AssociatedFilesSideBar() {
  const { activeDocVersionORM } = useContentViewerModalState()

  const versionElements = activeDocVersionORM.relations.associatedFiles
    .filter(associatedFile => {
      if (associatedFile.model.type !== AssociatedFileType.DOCUMENT) {
        return true;
      }

      return (associatedFile.file as Document).status === DocumentStatus.PUBLISHED;
    })
    .map(assocFile => {
      if (assocFile.model.type === AssociatedFileType.DOCUMENT) {
        return <AssociatedDocumentRow file={assocFile} key={assocFile.model.id} />
      } else if (assocFile.model.type === AssociatedFileType.ATTACHED_FILE) {
        return <AttachedFileRow file={assocFile} key={assocFile.model.id} />
      }
    })

  return (
    <DNABox appearance="col">
      {versionElements}
    </DNABox>
  )
}

export default AssociatedFilesSideBar;
