import _merge from 'lodash/merge'
import _capitalize from 'lodash/capitalize'
import { DocumentStatus, FileType, ConversionStatus } from '@alucio/aws-beacon-amplify/src/models'
import { CustomFieldValuesMap, DocumentORM } from 'src/types/types'
import { FilterAndSortOptions, multiTypeSort } from 'src/state/redux/selector/common'

export type FilterEntry = {
  fieldId: string,
  fieldName: string,
  valueId: string,
  fieldValue: string,
  default: boolean,
  active: boolean,
  isCustomLabel?: boolean,
}

type FilterSortMap<T extends string> = {
  [K in T]: FilterAndSortOptions<DocumentORM>
}

type FilterMap =
  FilterSortMap<
  'published'|
  'publishedNUnpublished'|
  'presentable'|
  'bookmarked'|
  'hasLatestPublishedVersion'|
  'unpublished'|
  'notPublished'|
  'hidingEnabled'|
  'hidingEnabledAndNonModifiableWithGroup'|
  'hasUnpublishedVersion'|
  'hasntUnpublishedVersion'|
  'nonDeleted'> &
  { defaultFilters: (activeFilters: CustomFieldValuesMap) => FilterAndSortOptions<DocumentORM> }

// [TODO-1793]
// - Merge should be able to take in a configuration to accept multiple
//    filter criteria on the same field
// - By default, it could be AND but the user should be able to configure it to OR
// - Would have to update the filterCollection to be able to handle an array of functions that apply the given criteria
export const merge: (
  ...args: (FilterAndSortOptions<DocumentORM> | null | undefined)[]
) => FilterAndSortOptions<DocumentORM> = (...args) => _merge({}, ...args.filter(arg => arg))

export const filters: FilterMap = {
  published: {
    filter: { model: { status: 'PUBLISHED' } },
  },
  // This is used for FileDrawerContent, so we will not display documents that are not successfully processed
  publishedNUnpublished: {
    filter: {
      model: { status: (s) => s === 'NOT_PUBLISHED' || s === 'PUBLISHED' },
      relations: {
        version: (v) => v.latestDocumentVersionORM.model.conversionStatus === ConversionStatus.PROCESSED,
      },
    },
  },
  bookmarked: {
    filter: { meta: { bookmark: (b) => b.isBookmarked } },
  },
  // [NOTE] - This might be able to be inferred from other filters, but use this for now to guarantee some better safety in other code
  hasLatestPublishedVersion: {
    filter: {
      relations: {
        version: (v) => !!v.latestPublishedDocumentVersionORM,
      },
    },
  },
  presentable: {
    filter: {
      relations: {
        version: (v) => v.latestUsableDocumentVersionORM.meta.permissions.MSLPresent,
      },
    },
  },
  hidingEnabled: {
    filter: { relations: { version: (v) => v.latestUsableDocumentVersionORM.meta.permissions.MSLSelectSlides } },
  },
  hidingEnabledAndNonModifiableWithGroup: {
    filter: {
      relations: {
        version: (v) => v.latestUsableDocumentVersionORM.meta.permissions.MSLSelectSlides ||
          (v.latestUsableDocumentVersionORM.meta.permissions.MSLNonModifiable &&
          v.latestUsableDocumentVersionORM.relations.pageGroups.length > 0),
      },
    },
  },
  unpublished: {
    filter: { model: { status: (s) => s === 'NOT_PUBLISHED' } },
  },
  notPublished: {
    filter: { model: { status: (s) => s !== 'NOT_PUBLISHED' } },
  },
  nonDeleted: {
    filter: { model: { status: (s) => s !== 'DELETED' } },
  },
  hasUnpublishedVersion: {
    filter: { meta: { hasUnpublishedVersion: true } },
  },
  hasntUnpublishedVersion: {
    filter: { meta: { hasUnpublishedVersion: false } },
  },
  defaultFilters: (activeFilters: CustomFieldValuesMap) => ({
    filter: {
      meta: {
        customValues: filterBasedOnCustomValues(activeFilters),
      },
    },
  }),
}

export const modalFilterSystemFieldsQueries:
  Record<string, (activeFilterFieldValues: string[]) => FilterAndSortOptions<DocumentORM>> =
  {
    'Content Source': (activeFilterFieldValues: string[]) => ({
      filter: {
        relations: {
          version: (v) => {
            const docVer = v.latestUsableDocumentVersionORM.model
            return docVer.integrationType
              ? activeFilterFieldValues.includes(_capitalize(docVer.integrationType))
              : false
          },
        },
      },
    }),
    'Associated Files': (activeFilterFieldValues: string[]) => ({
      filter: {
        relations: {
          version: (v) => {
            const hasAsociatedFiles = v.latestUsableDocumentVersionORM.relations.associatedFiles.length
            return activeFilterFieldValues.some(activeFilterFieldValue => (
              ((activeFilterFieldValue === 'Has associated files') && hasAsociatedFiles)
            ))
          },
        },
      },
    }),
    'Status': (activeFilterFieldValues: string[]) => ({
      filter: {
        model: {
          status: (status) => {
            return activeFilterFieldValues.some(activeFilterFieldValue => (
              ((activeFilterFieldValue === 'Archived') && (status === DocumentStatus.ARCHIVED)) ||
            ((activeFilterFieldValue === 'Published') && (status === DocumentStatus.PUBLISHED)) ||
            ((activeFilterFieldValue === 'Not Published') && (status === DocumentStatus.NOT_PUBLISHED)) ||
            ((activeFilterFieldValue === 'Revoked') && (status === DocumentStatus.REVOKED))
            ))
          },
        },
      },
    }),
    'Type': (activeFilterFieldValues: string[]) => ({
      filter: {
        model: {
          type : (type) => {
            return activeFilterFieldValues.some(activeFilterFieldValue => (
              ((activeFilterFieldValue === 'PDF') && (type === FileType.PDF)) ||
            ((activeFilterFieldValue === 'PPTX') && (type === FileType.PPTX)) ||
            ((activeFilterFieldValue === 'WEB') && (type === FileType.WEB)) ||
            ((activeFilterFieldValue === 'VIDEO') && (type === FileType.MP4)) ||
            ((activeFilterFieldValue === 'HTML') && (type === FileType.HTML))
            ))
          },
        },
      },
    }),
  }

// For every tenant field that has an active filter value
//  The document must match at least 1 value for the identified tenant field
export function defaultTenantFieldDocumentFilter(requiredFilterFieldIds: string[], activeFilters: FilterEntry[]) {
  return (customValues: DocumentORM['meta']['customValues']): boolean => {
    if (!activeFilters.length) return true;
    const satisfyRequiredFieldNames = {}

    for (const activeFilter of activeFilters) {
      const valuesIds = customValues.configsMap?.[activeFilter.fieldId]?.values;

      if (!valuesIds) {
        satisfyRequiredFieldNames[activeFilter.fieldId] = false;
      } else if (valuesIds.includes(activeFilter.valueId)) {
        satisfyRequiredFieldNames[activeFilter.fieldId] = true
      }

      if (Object.keys(satisfyRequiredFieldNames).length === requiredFilterFieldIds.length) { return true }
    }

    return false
  }
}

// RETURNS A FUNCTION THAT RETURNS A BOOLEAN BASED ON WHETHER OR NOT, THE GIVEN CustomFieldValuesMap OF AN ENTITY,
// MATCH THE REQUIRED GIVEN CustomFieldValuesMap (FILTERS)
export function filterBasedOnCustomValues(filters: CustomFieldValuesMap) {
  return (customValues: DocumentORM['meta']['customValues']): boolean => {
    if (!Object.keys(filters).length) {
      return true;
    }

    for (const customFieldValue of Object.values(filters)) {
      // IF IT HAS VALUES DEFINED FOR THE FIELD AND NON MATCHES THE VALIDVALUES, IT DOESN'T HAVE ACCESS
      const validValueDefinitionIds = customFieldValue.valuesDefinition?.reduce<string[]>((acc, { id, disabled }) =>
        disabled ? acc : [...acc, id]
      , []) || [];
      const matchesFilters = customValues.configsMap[customFieldValue.field.id]?.values.some((valueId) =>
        validValueDefinitionIds.includes(valueId));

      if (validValueDefinitionIds.length && !matchesFilters) {
        return false;
      }
    }

    return true;
  }
}

export const sorts: FilterSortMap<
  'updatedAtAsc' |
  'updatedAtDesc' |
  'titleAsc' |
  'titleDesc' |
  'bookmarkedAsc' |
  'bookmarkedDesc'
> = {
  updatedAtAsc: {
    sort: [{ model: { updatedAt: 'asc' } }],
  },
  updatedAtDesc: {
    sort: [{ model: { updatedAt: 'desc' } }],
  },
  titleAsc: {
    sort: [{
      relations: {
        version: (a, b) => {
          const docVerA = a.latestUsableDocumentVersionORM.model
          const docVerB = b.latestUsableDocumentVersionORM.model
          return multiTypeSort(docVerA?.title, docVerB?.title, { dir: 'asc' })
        },
      },
    }],
  },
  titleDesc: {
    sort: [{
      relations: {
        version: (a, b) => {
          const docVerA = a.latestUsableDocumentVersionORM.model
          const docVerB = b.latestUsableDocumentVersionORM.model
          return multiTypeSort(docVerA?.title, docVerB?.title, { dir: 'desc' })
        },
      },
    }],
  },
  bookmarkedAsc: {
    sort: [{ meta: { bookmark: (a, b) => multiTypeSort(a.createdAt, b.createdAt, { dir: 'asc' }) } }],
  },
  bookmarkedDesc: {
    sort: [{ meta: { bookmark: (a, b) => multiTypeSort(a.createdAt, b.createdAt, { dir: 'desc' }) } }],
  },
}

export default {
  merge,
  filters,
  sorts,
}
