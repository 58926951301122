import { RootState } from 'src/state/redux'
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

// [TODO] - This could live in the document slice file
//          but support all other slices generically later down the line (keeping performance in mind on selector updates)
export const selDocVerSlice = (state: RootState) => state.documentVersion

export const docVerSliceErrors = createSelector(
  selDocVerSlice,
  (docVer => docVer.errorStatus)
)

export const useDocVerSliceErrors = () => useSelector((state: RootState) => docVerSliceErrors(state))
