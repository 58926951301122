import { Page } from '@alucio/aws-beacon-amplify/src/models'
import {
  DNABox,
  DNAButton,
  DNACheckbox,
  DNAChip,
  DNADivider,
  DNAText,
  Iffy,
  luxColors,
  Stack,
  DNAIcon,
} from '@alucio/lux-ui'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Image, ImageStyle, Modal, Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native'
import { useSelector } from 'react-redux'
import { folderActions } from 'src/state/redux/slice/folder'
import { RootState, useDispatch } from 'src/state/redux'
import useThumbnailSelector from './useThumbnailSelector'
import { useFolderItemORMById } from 'src/state/redux/selector/folder'
import { ThumbnailDimensions } from 'src/hooks/useThumbnailSize/useThumbnailSize'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import EditableText from '../EditableText/EditableText';
import { LuxSizeEnum } from '@alucio/lux-ui/src/typings'
import { DocumentVersionORM, isDocumentVersionORM } from 'src/types/types'
import AssociatedSlidesThumbnails from 'src/components/DNA/Thumbnail/AssociatedSlidesThumbnails'
import PageGroupList from './PageGroupList'
import { isSharedEditableFolder } from 'src/utils/foldersHelpers'
import { sharedFolderActions } from 'src/state/redux/slice/sharedFolder'

const styles = StyleSheet.create({
  content: {
    paddingVertical: 48,
    paddingHorizontal: 48,
  },
  rowWrapper: {
    backgroundColor: colors['color-text-white'],
    paddingHorizontal: 16,
  },
  thumbnail: {
    borderColor: colors['color-text-white'],
    borderRadius: 3,
    borderWidth: 3,
  },
  thumbnailWrapper: {
    borderColor: colors['color-gray-100'],
    borderRadius: 6,
    borderWidth: 3,
  },
  spinnerWrapper: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  verticalDivider: {
    backgroundColor: luxColors.headerTopColor.primary,
    height: 20,
    marginHorizontal: 12,
    width: 1,
  },
});

export enum SlideMode {
  'FolderItem',
  'DocumentVersion'
}

export interface ThumbnailPage extends Page {
  thumbnailObjectURL?: string
  checked: boolean
  disabled: boolean
  isRequired?: boolean
  disableCheckbox?:boolean
  isPlaceholder?: boolean
  isCover: boolean
  linkedSlides?: string[]
  parentIds?: string[],
}

export interface SlideThumbProps {
  page: ThumbnailPage,
  onChange?: () => void
  disabled: boolean
  checked: boolean,
  dimensions?: ThumbnailDimensions,
  disableCheckbox?: boolean,
  unavailableContent?: boolean,
  documentVersionORM?: DocumentVersionORM
  alwaysShowAssociationSlide?: boolean,
}

/**
 * Composeable slide thumbnail component. Could probably convert
 * this to a generic shared component for use in other places in the app.
 * @param props page, onChange, disabled, checked
 */
export const SlideThumb: React.FC<SlideThumbProps> = (props) => {
  const {
    page,
    checked,
    disabled,
    onChange,
    dimensions,
    disableCheckbox,
    unavailableContent,
    documentVersionORM,
    alwaysShowAssociationSlide,
  } = props

  const imageStyle: StyleProp<ImageStyle> = {
    width: dimensions?.width || 184,
    height: dimensions?.height || 104,
    backgroundColor: 'lightgrey',
    opacity: disabled ? 0.4 : undefined,
  }

  const containerStyle: StyleProp<ViewStyle> = {
    marginRight: 48,
    marginBottom: 32,
  }

  const associatedPages:Page[] = []
  page.linkedSlides?.forEach(slideId => {
    const page = documentVersionORM?.meta.allPages.find(page => page.pageId === slideId)
    if (page) associatedPages.push(page)
  })

  const showAssociation = (checked && associatedPages.length) || alwaysShowAssociationSlide

  return (
    <DNABox appearance="col" style={containerStyle} spacing="sm">
      <Pressable onPress={onChange}>
        <Stack>
          {/* First layer - Cached Thumbnail Image */}
          <Stack.Layer>
            <DNABox
              style={[styles.thumbnailWrapper,
                checked && { borderColor: colors['color-brand2-500'] }]}
            >
              <Image
                source={{ uri: page.thumbnailObjectURL }}
                style={[imageStyle, styles.thumbnail]}
                resizeMode="contain"
              />
            </DNABox>
          </Stack.Layer>

          {/* Second Layer - Overlay to produce the darkened effect when deselected */}
          {/* TODO: Figure out how to  accomplish this without the dummy text element */}
          <Stack.Layer>
            <Iffy is={!checked}>
              <DNABox fill style={{ backgroundColor: 'rgba(0,0,0,.4)', width: 184, height: 104 }}>
                <DNAText />
              </DNABox>
            </Iffy>
          </Stack.Layer>

          {/* Third Layer - Checkbox overlay for selection of thumb */}
          <Stack.Layer>
            <Iffy is={!disableCheckbox}>
              <DNACheckbox
                style={{ margin: 7 }}
                context="altBg"
                checked={checked}
                disabled={disabled}
                onChange={onChange}
              />
            </Iffy>
          </Stack.Layer>

          {/* Offline Overlay */}
          <Stack.Layer fill>
            <Iffy is={unavailableContent}>
              <DNABox fill alignX="center" alignY="center">
                <DNAIcon.Styled
                  appearance="ghost"
                  status="primary"
                  name="cloud-off-outline"
                  size="xl"
                />
              </DNABox>
            </Iffy>
          </Stack.Layer>
        </Stack>
      </Pressable>

      {/* Thumbnail number */}
      <DNABox alignY="center" spacing="between">
        <DNAText bold>{page.number}</DNAText>
        <DNABox spacing="sm">
          <Iffy is={page.isCover}>
            <DNAChip appearance="tag">COVER</DNAChip>
          </Iffy>
          <Iffy is={page.isRequired}>
            <DNAChip appearance="tag" status="danger">REQUIRED</DNAChip>
          </Iffy>
        </DNABox>
      </DNABox>
      {/* Associated Slides */}
      { showAssociation && documentVersionORM ? <AssociatedSlidesThumbnails
        docVerORM={documentVersionORM}
        pages={associatedPages}
        size="lg"
      /> : null}
    </DNABox>
  )
}

/**
 * Cancel Save for Slide Control
 * @param props
 * @returns
 */
export const CancelSave: React.FC<PropsWithChildren<{
  closeModal: () => void,
  handleSave: () => void,
  saveDisabled: boolean,
  title: string,
  isReadOnly?: boolean,
}>> = (props) => {
  const { handleSave, children, closeModal, saveDisabled, title, isReadOnly: readOnly } = props;

  return (
    <DNABox fill style={{ maxHeight:64, paddingHorizontal: 16 }} alignY="center">
      <DNABox fill>
        <Iffy is={children}>
          {children}
        </Iffy>
        <Iffy is={!children}>
          <DNAText>{title}</DNAText>
        </Iffy>
      </DNABox>
      <DNABox spacing="md" style={{ paddingLeft: 5 }}>
        <Iffy is={!readOnly}>
          <DNAButton
            size="sm"
            appearance="outline"
            status="primary"
            onPress={closeModal}
          >Cancel
          </DNAButton>
          <DNAButton
            size="sm"
            onPress={handleSave}
            disabled={saveDisabled}
          >Save
          </DNAButton>
        </Iffy>
        <Iffy is={readOnly}>
          <DNAButton
            size="sm"
            onPress={closeModal}
          >Close
          </DNAButton>
        </Iffy>
      </DNABox>
    </DNABox>
  )
}

/**
 * Main slide selector component. Defaults to watching associated
 * slice but should be able to be used standalone with props as well
 * @param props folderItemORM, visible, toggleVisibility
 */
const SlideSelector = () => {
  /** Props & Dependancies */
  const dispatch = useDispatch();

  /** State related declarations */
  const modalState = useSelector((state: RootState) => state.SlideSelectorModal);

  const selectedFolderItem = useFolderItemORMById({
    id: modalState.activeFolderItemId,
    folderId: modalState.folderId,
  });
  const activeItemORM = selectedFolderItem?.relations.itemORM

  /**
   * This stuff would probably be better implemented as a context. In addition, much of the code
   * in this component was duplicated for the slidedocumentversionselector and could likely be
   * refactored to be a lighter variant file
   * */
  const {
    allPagesSelected,
    selectionCount,
    isOpen,
    pages,
    selectedPages,
    thumbnailSize,

    cycleThumbnailSize,
    handleSelectGroup,
    handleSelectAllToggle,
    closeModal,
    initializeThumbnails,
    getGroupDrafts,
  } = useThumbnailSelector(
    {
      preSelectedThumbnails: selectedFolderItem?.model?.visiblePages,
      disableRequiredToggle: true,
    },
  );

  /** @ts-ignore - ts does not appear to know about this deep nesting relationship. typical superficial ts. */
  const defaultTitle = selectedFolderItem?.relations.itemORM.model.title;
  const [customTitle, setCustomTitle] = useState<string>(selectedFolderItem?.model.customTitle || defaultTitle || '');
  const isCustomTitle = customTitle !== defaultTitle;
  const defaultDocumentConfig = !isCustomTitle && allPagesSelected;
  const folderORM = selectedFolderItem?.relations.parentORM;
  const isSharedEditFolder = folderORM && isSharedEditableFolder(folderORM);

  /** Contemplated and discussed having '|| defaultDocumentConfig' here but this prevents us from removing the modified state
     * once set. If we add a 'reset' feature to this component in the future we can then add this additional conditional */
  const saveDisabled = selectedPages.length < 1;

  const handleTitleEdit = () => {
    if (customTitle === '' ) {
      setCustomTitle(defaultTitle)
    }
  };

  const handleSave = () => {
    const updatedVisiblePages = defaultDocumentConfig || allPagesSelected
      ? undefined
      : selectedPages;

    dispatch( isSharedEditFolder ? sharedFolderActions.updateItemPagesAndTitle(
      {
        folderORM: folderORM!,
        folderItemORM: selectedFolderItem!,
        updates: {

          visiblePages: updatedVisiblePages,
          customTitle: isCustomTitle ? customTitle : undefined,
        },
      },
    )
      : folderActions.updateItemPagesAndTitle(
      selectedFolderItem!, {
        visiblePages: updatedVisiblePages,
        customTitle: isCustomTitle ? customTitle : undefined,
      }),
    )
    closeModal()
  };

  /** useEffect Hooks */
  const handleStateUpdate = () => {
    isDocumentVersionORM(activeItemORM) && initializeThumbnails(activeItemORM)
  }
  useEffect(handleStateUpdate, [modalState])

  /** Main Component Structure */
  return (
    <Modal
      visible={isOpen}
      animationType="slide"
    >
      {/* To hide the content on the close animation */}
      <Iffy is={isOpen}>
        <DNABox fill appearance="col">
          {/* Header */}
          <CancelSave
            title="Slide selector"
            saveDisabled={saveDisabled}
            closeModal={closeModal}
            handleSave={handleSave}
          >
            <EditableText
              bold={true}
              size={LuxSizeEnum.md}
              triggerInputShown={(isShown) => !isShown && handleTitleEdit()}
              value={customTitle}
              removeMarginPadding={true}
              onChangeText={setCustomTitle}
            />
          </CancelSave>
          <DNADivider />
          {/* Body */}
          <DNABox
            fill
            style={{ backgroundColor: colors['color-gray-10'] }}
            appearance="col"
          >
            <DNABox style={styles.rowWrapper} alignY="center">
              {/* 'Select all' check box */}
              <DNACheckbox
                checked={allPagesSelected}
                onChange={handleSelectAllToggle}
                style={{ marginRight:7 }}
              />
              <DNAText>Select all</DNAText>
              <DNADivider style={styles.verticalDivider} />
              <DNAText>{`${selectionCount} of ${pages.length} Selected`}</DNAText>
              <DNABox fill alignX="end">
                <DNAButton
                  appearance="ghost"
                  status="tertiary"
                  iconLeft = {thumbnailSize === 'xxl' ? 'view-comfy' : 'view-grid'}
                  onPress= {cycleThumbnailSize}
                />
              </DNABox>
            </DNABox>
            <DNADivider />
            { /* CONTENT */ }
            <DNABox
              style={styles.content}
              fill
            >
              <PageGroupList
                handleGroupSelect={handleSelectGroup}
                selectorThumbnailSize={thumbnailSize}
                allGroups={getGroupDrafts()}
                documentVersionORM={isDocumentVersionORM(activeItemORM) ? activeItemORM : undefined}
                hideGroups
                hideAssociatedSlides
              />
            </DNABox>
          </DNABox>
        </DNABox>
      </Iffy>
    </Modal>
  )
}

function SlideSelectorWrapper() {
  const modalState = useSelector((state: RootState) => state.SlideSelectorModal);
  const selectedFolderItem = useFolderItemORMById({
    id: modalState.activeFolderItemId,
    folderId: modalState.folderId,
  });

  if (!modalState.isOpen) {
    return null;
  }

  if (selectedFolderItem) {
    return <SlideSelector />
  }

  return null
}
export default SlideSelectorWrapper;
