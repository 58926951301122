import React from 'react';
import Modal from 'react-native-modal'
import { StyleSheet } from 'react-native'

import {
  DNABox,
  DNAButton,
  DNAIcon,
  DNAText,
} from '@alucio/lux-ui';
import { DNAModalProps } from 'src/state/redux/slice/DNAModal/DNAModal';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

interface ConfirmationModalProps extends Partial<DNAModalProps> {
  onClosed: () => void;
}

const styles = StyleSheet.create({
  container: {
    width: '100vw',
    height: '100vh',
    backgroundColor: colors['color-text-basic'],
    alignSelf: 'center',
    borderRadius: 4,
  },
  header: {
    paddingHorizontal: 16,
    paddingVertical: 16,
    maxHeight: 64,
  },
  body: {
    margin: 8,
    backgroundColor: colors['color-gray-50'],
  },
})
const ForbiddenModal = (props: ConfirmationModalProps) => {
  const { isVisible } = props

  const closeModal = () => {
    props.onClosed()
    props.closeModal?.()
  }

  return (
    <Modal
      isVisible={isVisible}
      hasBackdrop={false}
    >
      <DNABox appearance="col" style={styles.container}>
        {/* Header */}
        <DNABox fill appearance="row" alignX="end" style={styles.header} childFill={1}>
          <DNAButton
            appearance="outline"
            status="tertiary"
            iconLeft="close"
            onPress={closeModal}
            rounded="md"
          />
        </DNABox>
        {/* Body */}
        <DNABox alignX="center" alignY="center" style={styles.body} fill>
          <DNABox appearance="col" spacing="sm">
            <DNABox alignX="center">
              <DNAIcon.Styled
                color={colors['color-gray-400']}
                appearance="ghost"
                status="tertiary"
                size="xl"
                name="alert"
              />
            </DNABox>
            <DNABox alignX="center"><DNAText h4>File not found</DNAText></DNABox>
            <DNAText c1>
              File is no longer available or you don’t have permission to view the file.
            </DNAText>
          </DNABox>
        </DNABox>
      </DNABox>
    </Modal>
  )
};

export default ForbiddenModal;
