/* eslint-disable max-len */
import * as React from 'react'
import Svg, { G, Path, Defs, ClipPath, SvgProps } from 'react-native-svg'

const AdminSettings = (props: SvgProps) => (
  <Svg
    width={32}
    height={32}
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
  >
    <G clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill={props.color}>
      <Path d="M9.463 9.073C9.17 5.059 12.063 2 15.96 2c3.903 0 6.79 3.128 6.497 7.074C22.184 12.752 19.37 16 15.96 16c-3.409 0-6.23-3.246-6.497-6.927Zm1.743-.102c-.212-2.908 1.884-5.124 4.706-5.124 2.827 0 4.919 2.266 4.707 5.125-.198 2.664-2.236 5.017-4.707 5.017-2.47 0-4.512-2.351-4.706-5.018ZM3.038 27.485C4.177 21.174 10.278 18 16 18c5.721 0 11.823 3.174 12.962 9.485.103.568.003 1.175-.334 1.667A1.941 1.941 0 0 1 27 30H5a1.94 1.94 0 0 1-1.628-.848c-.337-.492-.436-1.1-.334-1.667ZM16 20c-5.153 0-10.09 2.826-10.993 7.84a.366.366 0 0 0 .005.16h21.976a.365.365 0 0 0 .006-.16C26.089 22.826 21.154 20 16 20ZM28.025 13.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-3 1a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z" />
      <Path d="M28.025 10a1 1 0 0 1 1 1v1.5a1 1 0 1 1-2 0V11a1 1 0 0 1 1-1Z" />
      <Path d="M24.136 12.247a1 1 0 0 1 1.367-.361l1.288.75a1 1 0 1 1-1.007 1.728l-1.287-.75a1 1 0 0 1-.36-1.367Z" />
      <Path d="M27.152 14.997a1 1 0 0 1-.361 1.367l-1.288.75a1 1 0 1 1-1.006-1.728l1.287-.75a1 1 0 0 1 1.368.36Z" />
      <Path d="M28.025 15.5a1 1 0 0 1 1 1V18a1 1 0 1 1-2 0v-1.5a1 1 0 0 1 1-1Z" />
      <Path d="M28.899 14.997a1 1 0 0 1 1.367-.361l1.288.75a1 1 0 1 1-1.007 1.728l-1.288-.75a1 1 0 0 1-.36-1.367Z" />
      <Path d="M31.914 12.247a1 1 0 0 1-.36 1.367l-1.288.75a1 1 0 1 1-1.006-1.728l1.287-.75a1 1 0 0 1 1.367.36Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h32v32H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default AdminSettings;
