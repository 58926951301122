import { SessionStatus } from 'src/components/IdleComponent/IdleComponent';

const key = 'setDisconnectedDue';
export const defaultDisconnectMsg = {
  type: 'logout',
  msg: 'You’ve been logged out',
  visible: false,
}

export function setDisconnectedDue(reason: SessionStatus) {
  const disconnectedObjReason = reason === SessionStatus.end
    ? { msg: defaultDisconnectMsg.msg }
    : { msg: 'You’ve been logged out due to inactivity.', type: 'timeExpired' };
  localStorage.setItem(key,
    JSON.stringify({ ...defaultDisconnectMsg, visible: true, ...disconnectedObjReason }),
  );
}

export function setDisconnectedDueDefault() {
  localStorage.setItem(key, JSON.stringify(defaultDisconnectMsg));
}

export function getDisconnectedDue() {
  return JSON.parse(localStorage.getItem(key) || '{}');
}

export function cleanDisconnectedDue() {
  localStorage.removeItem(key);
}
