import React from 'react';
import { DNABox } from '@alucio/lux-ui';
import { StandaloneFormUsage } from '@alucio/aws-beacon-amplify/src/models';
import { ContentCard } from '../AddMeeting';
import CustomFormSection from './CustomFormSection';
import { FORMS, StandaloneForm, useTenantStandaloneForms } from 'src/state/redux/selector/crm';
import { CustomFormsSettings } from '../AddMeetingProvider';
import { CRMAvailabilityStatus } from 'src/state/machines/CRM/crmMachineTypes';
import { MeetingORM } from 'src/types/types';

interface Props {
  isReadOnly: boolean;
  setCustomFormSettings: (setting: CustomFormsSettings) => void;
  parentId: string;
}

const CustomFormsWrapper = (props: Props) => {
  const customForms = useTenantStandaloneForms([FORMS.CRM], StandaloneFormUsage.MEETINGS);

  if (!customForms.length) return null;

  // MULTIPLE RECORD TYPES HANDLING //
  const groupedByTypeCustomForms: StandaloneForm[][] = customForms.reduce<StandaloneForm[][]>((acc, form) => {
    const sameApiNameIndex = acc.findIndex((forms) => form.apiName && forms[0]?.apiName === form.apiName);

    if (sameApiNameIndex > -1) {
      acc[sameApiNameIndex].push(form);
    } else {
      acc.push([form]);
    }
    return acc;
  }, []);

  return (
    <DNABox>
      <ContentCard cardTitle="Forms">
        <DNABox spacing="lg" appearance="col">
          {
            groupedByTypeCustomForms.map((customForms, idx) => (
              <DNABox key={idx}>
                <CustomFormSection
                  setCustomFormSettings={props.setCustomFormSettings}
                  customForms={customForms}
                  parentId={props.parentId}
                  isReadOnly={props.isReadOnly}
                />
              </DNABox>
            ))
          }
        </DNABox>
      </ContentCard>
    </DNABox>
  )
};

export const useCustomFormsVisibility = (
  availabilityStatus: CRMAvailabilityStatus,
  meetingORM?: MeetingORM,
): boolean => {
  const customForms = useTenantStandaloneForms([FORMS.CRM], StandaloneFormUsage.MEETINGS);
  return !!meetingORM && !!customForms.length && availabilityStatus === CRMAvailabilityStatus.ENABLED
}

export default CustomFormsWrapper;
