import { DNAFlatListRows } from 'src/components/DNA/FlatList/Rows/types'

import DNADocumentVirtualRow from './DNADocumentVirtualRow'
import DNADocumentFolderItemVirtualRow from './DNADocumentFolderItemVirtualRow'
import DNADocumentFolderItemCustomDeckVirtualRow from './DNADocumentFolderItemCustomDeckVirtualRow'
import DNAFolderVirtualRow from './DNAFolderVirtualRow'
import DNAFileDrawerDefaultRow from '../fileDrawer/DNAFileDrawerDefaultRow'

export const DNADefaultRows: DNAFlatListRows = {
  DNADocumentRow: DNADocumentVirtualRow,
  DNADocumentFolderItemRow: DNADocumentFolderItemVirtualRow,
  DNADocumentFolderItemCustomDeckRow: DNADocumentFolderItemCustomDeckVirtualRow,
  DNAFolderRow: DNAFolderVirtualRow,
  DNAFileDrawerRow: DNAFileDrawerDefaultRow,
}

export default DNADefaultRows
