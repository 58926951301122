import React, {
  createContext,
  useState,
  useReducer,
  useContext,
  useEffect,
  useRef,
  PropsWithChildren,
} from 'react'
import { Animated, StyleSheet, useWindowDimensions, View, ViewStyle } from 'react-native'
import { useHistory, matchPath } from 'src/router'
import { DNABox } from '@alucio/lux-ui'
import { DisplayNameChild } from '@alucio/core'

// [TODO] - Support unmatched route
// [TODO] - More render/usage convenience
//  - Add ref support
//  - Add render prop support

/**
 * STYLES
 */
const S = StyleSheet.create({
  container: {
    position: 'absolute',
    flex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  containerBg: {
    backgroundColor: 'white',
  },
})

/**
 * CONTEXT
 */
type DNARouteStackContextValues = {
  pop: () => void,
  push: (path: string) => void,
  isCurrentPath: (path: string, exact?: boolean) => boolean,
  initialPath?: string,
  currentPath?: string,
}

const DNARouteStackContext = createContext<DNARouteStackContextValues>({
  pop: () => {},
  push: () => {},
  isCurrentPath: () => false,
  initialPath: undefined,
  currentPath: undefined,
})
export const useDNARouteStack = () => useContext(DNARouteStackContext)

/**
 * IMPLEMENTATIONS
 */
type currentPathActions = {
  type: 'push' | 'pop',
  payload?: {
    value: string
  }
}

const currentPathReducer = (
  state: DNARouteStackContextValues['currentPath'],
  action: currentPathActions,
) => {
  switch (action.type) {
    case 'pop': {
      return state?.split('/').slice(0, -1).join('/')
    }
    case 'push': {
      return action?.payload?.value ?? state
    }
    default: {
      return state
    }
  }
}

type DNARouteStackHook = (props: DNARouteStackProps) => DNARouteStackContextValues

const useDNARouteStackInternal: DNARouteStackHook = (props) => {
  const { initialPath } = props
  const [currentPath, dispatch] = useReducer(currentPathReducer, initialPath)

  return {
    pop: () => dispatch({ type: 'pop' }),
    push: (value: string) => dispatch({ type: 'push', payload: { value } }),
    isCurrentPath: (path: string, exact: boolean = false) => Boolean(
      matchPath(currentPath ?? '', { path, exact }),
    ),
    initialPath,
    currentPath,
  }
}

const useDNARouteStackRoute: DNARouteStackHook = () => {
  const history = useHistory()
  const currentPath = history.location.pathname
  const [initialPath] = useState<string>(currentPath)

  return {
    pop: () => history.push(currentPath.split('/').slice(0, -1).join('/')),
    push: (value: string) => history.push(value),
    isCurrentPath: (path: string, exact: boolean = false) => Boolean(
      matchPath(currentPath ?? '', { path, exact }),
    ),
    initialPath,
    currentPath,
  }
}

/**
 * COMPONENTS
 */
type DNARouteStackProps = {
  initialPath?: string
}
type DNARouteStackComponent = {
  Internal: React.FC<PropsWithChildren<DNARouteStackProps>>,
  Router: React.FC<PropsWithChildren<DNARouteStackProps>>,
  Screen: typeof DNARouteStackScreen,
  Header: typeof DNARouteStackHeader,
  Footer: typeof DNARouteStackFooter,
}

const DNARouteStackFactory = (useHook: DNARouteStackHook): React.FC<
  PropsWithChildren<DNARouteStackProps>
> => (props) => {
  const { children } = props

  /* eslint-disable react-hooks/rules-of-hooks */
  /**
   * Is fine yes, although we use a hook in a callback
   * it's in a factory pattern and ran only once at start time
   * like regular functional components
   */
  const values = useHook(props)

  const [HeaderChildren, FooterChildren, RestChildren] = React.Children
    .toArray(children)
    .reduce<[React.ReactElement<any>[], React.ReactElement<any>[], React.ReactElement<any>[]]>(
      (acc, child) => {
        if (React.isValidElement(child)) {
          const typedChild = child as DisplayNameChild<typeof child>
          const displayName = typedChild.type?.displayName

          if (displayName === DNARouteStackHeader.displayName) {
            acc[0].push(child)
          }
          else if (displayName === DNARouteStackFooter.displayName) {
            acc[1].push(child)
          }
          else
          { acc[2].push(child) }
        }

        return acc
      },
      [[], [], []],
    )

  return (
    <DNARouteStackContext.Provider value={values}>
      <DNABox fill appearance="col">
        {/* Header */}
        { HeaderChildren }

        {/* Content */}
        <DNABox
          style={{ position: 'relative', width: '100%', height: '100%' }}
          fill
        >
          {/* Guiding Layer */}
          <DNABox style={{ position: 'relative' }} fill />
          { RestChildren }
        </DNABox>

        {/* Footer */}
        { FooterChildren }
      </DNABox>
    </DNARouteStackContext.Provider>
  )
}

type DNARouteStackScreenProps = {
  path: string,
  required?: boolean,
  onRequired?: (context: DNARouteStackContextValues) => void,
  skipAnimation?: boolean,
  style?: ViewStyle
  children?: (
    ((context: DNARouteStackContextValues) => (React.ReactElement<any, any> | null)) |
    (React.ReactElement<any, any> | null)
  )
}
const DNARouteStackScreen: React.FC<DNARouteStackScreenProps> = (props) => {
  const { children, path, required, skipAnimation, style, onRequired } = props
  const stack = useDNARouteStack()
  const screenDim = useWindowDimensions()
  const isAnimating = useRef<boolean>(false)
  const anim = useRef(new Animated.Value(0)).current
  const screenRef = useRef<View>()

  const isOnstack = stack.isCurrentPath(path)
  const isExactstack = stack.isCurrentPath(path, true)

  const [shouldMount, setShouldMount] = useState<boolean>(!!isExactstack)

  // 1st render -- determine mount status
  useEffect(() => {
    if (isOnstack && !shouldMount)
    { setShouldMount(true) }

    if (isExactstack && !shouldMount && !skipAnimation) {
      anim.setValue(screenDim.width)
    }
  }, [screenDim, isOnstack, isExactstack, shouldMount])

  // onRequired handler
  useEffect(() => {
    if (isExactstack && required !== undefined && !required) {
      onRequired?.(stack)
    }
  }, [required, onRequired, isExactstack])

  // Mounting/Unmounting animations
  useEffect(() => {
    // Do nothing if unmounted
    if (!shouldMount) return;

    screenRef.current?.measure((x) => {
      // Mount animation
      if (isExactstack && !isAnimating.current && x !== 0) {
        isAnimating.current = true
        Animated
          .timing(anim, {
            toValue: 0,
            duration: 600,
            useNativeDriver: false,
          })
          .start(() => { isAnimating.current = false })
      }

      // Initial unmounting
      else if (!isExactstack && !isOnstack && !isAnimating.current) {
        if (skipAnimation) {
          setShouldMount(false)
          return;
        }

        isAnimating.current = true
        Animated
          .timing(anim, {
            toValue: screenDim.width,
            duration: 600,
            useNativeDriver: false,
          })
          .start((res) => {
            if (res.finished) {
              isAnimating.current = false
              // Unmount on finished animation
              // [TODO] Double check if animation gets interrupted if this sets twice
              setShouldMount(false)
            }
          })
      }

      else if (!isExactstack && !isOnstack && isAnimating.current) {
        anim.stopAnimation()
        Animated
          .timing(anim, {
            toValue: screenDim.width,
            duration: 200, // Speed up
            useNativeDriver: false,
          })
          .start(() => {
            isAnimating.current = false
            setShouldMount(false)
          })
      }
    })
  }, [screenDim, shouldMount, isExactstack, isOnstack, stack.currentPath])

  if (!shouldMount) return null

  return (
    <Animated.View
      ref={screenRef}
      style={[
        S.containerBg,
        style,
        S.container,
        { transform: [{ translateX: anim }] }]
      }
    >
      {
        typeof children === 'function'
          ? children(stack)
          : children
      }
    </Animated.View>
  )
}

const DNARouteStackHeader: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  return children as React.ReactElement
}
DNARouteStackHeader.displayName = 'DNARouteStackHeader'

const DNARouteStackFooter: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  return children as React.ReactElement
}
DNARouteStackFooter.displayName = 'DNARouteStackFooter'

const DNARouteStack: DNARouteStackComponent = {
  Internal: DNARouteStackFactory(useDNARouteStackInternal),
  Router: DNARouteStackFactory(useDNARouteStackRoute),
  Screen: DNARouteStackScreen,
  Header: DNARouteStackHeader,
  Footer: DNARouteStackFooter,
}

export default DNARouteStack
