import React from 'react';

import { DNABox, DNAButton, DNAModal, DNAText, GenericToast, ToastOrientations, useToast } from '@alucio/lux-ui';
import { DNAModalVariant } from './DNAConnectedModal';
import { useUsersByTenantId } from 'src/state/redux/selector/user';
import { useTenant } from 'src/state/redux/selector/tenant';

export enum UserReactivateMode {
  reactivate = 'reactivate',
  deactivate = 'deactivate'
}

interface UserReactivateConfirmationProps {
  onClose: () => void
  mode: UserReactivateMode,
  username: string,
  tenantId: string,
}

const reactivateText = {
  title: {
    reactivate: 'Reactivate User',
    deactivate: 'Deactivate User',
  },
  body: {
    reactivate: 'Are you sure you want to reactivate',
    deactivate: 'Are you sure you want to deactivate',
  },
}

const UserReactivateConfirmation:DNAModalVariant<UserReactivateConfirmationProps> = (props) => {
  const { toggleModal, onClose, mode, username } = props
  const tenantUsers = useUsersByTenantId(props.tenantId);
  const tenant = useTenant(props.tenantId);
  const toast = useToast();

  const handleContinue = () => {
    const activeUsers = tenantUsers.filter((user) => user.model.status === 'ACTIVE').length;
    const isInvalid = tenant && activeUsers >= tenant.tenant.licensedUsers

    if (mode === UserReactivateMode.reactivate && isInvalid) {
      toast?.add(
        <GenericToast title="User limit has been reached" status="error" />,
        ToastOrientations.TOP_RIGHT,
      );
      toggleModal()
      return;
    }

    toggleModal()
    onClose()
  }

  const handleCancel = () => {
    toggleModal()
  }

  return (
    <DNAModal>
      <DNAModal.Header>
        <DNAText h5>{reactivateText.title[mode]}</DNAText>
      </DNAModal.Header>
      <DNAModal.Body>
        <DNABox style={{ padding: 8 }}>
          <DNAText status="flat">
            {`${reactivateText.body[mode]} ${username}?`}
          </DNAText>
        </DNABox>
      </DNAModal.Body>
      <DNAModal.Confirmation>
        <DNAButton size="sm" status="tertiary" onPress={handleCancel}>
          Cancel
        </DNAButton>
        <DNAButton size="sm" onPress={handleContinue} status="primary">
          {mode === UserReactivateMode.deactivate ? 'Deactivate' : 'Reactivate'}
        </DNAButton>
      </DNAModal.Confirmation>
    </DNAModal>
  )
};

export default UserReactivateConfirmation;
