
import type {
  StateFrom,
  InterpreterFrom,
  Prop,
  EventObject,
} from 'xstate'
import { BroadcastChannel } from 'broadcast-channel';
import {
  Coordinate,
  HubSectionItemStatus,
  Notation,
} from '@alucio/aws-beacon-amplify/src/models';
import { PW } from 'src/state/machines/presentation/playerWrapper';
import { PresentationBroadcastContent } from '@alucio/core/lib/state/context/PresentationPlayer/types';
import type customNoteSM from './customNote.machine';
import type { Typegen0 } from './customNote.machine.typegen';

export enum StateTags {
  INITIALIZING = 'INITIALIZING',
  NEW_NOTE = 'NEW_NOTE',
}

export type CustomNoteState = StateFrom<typeof customNoteSM>
export type CustomNoteService = InterpreterFrom<typeof customNoteSM>
export type CustomNoteSend = Prop<CustomNoteService, 'send'>
export type CustomNoteMachine = typeof customNoteSM

export type NotationDraft = Partial<Pick<Notation, 'id' | 'pageId' | 'coordinate' | 'description'>> & {
  documentId?: string
  documentVersionId?: string
  customDeckId?: string
  status: HubSectionItemStatus
}

export type SMContext = {
  presentationStateChannel: BroadcastChannel<PW.PresentationChannelMessage>
  presentationStateObserver?: any
  presentableState?: PresentationBroadcastContent
  currentActiveNotationId?: string
  meetingId: string
  notationDraft?: NotationDraft
  isReadOnlyMode: boolean
  customDeckId?: string
}

export type SMServices = {}

export type SMContextKeys = keyof SMContext
export type StringStates = Extract<Typegen0['matchesStates'], string>

export type EvtValues<
  K extends T['type'],
  T extends EventObject = SMEvents
> = T extends { type: K }
  ? Omit<T, 'type'>
  : never

export type EVT_IFRAME_READY = {
  type: 'IFRAME_READY'
}

export type EVT_ENTER_SET_COORDINATE_MODE = {
  type: 'ENTER_SET_COORDINATE_MODE'
}

export type EVT_BACK_TO_IDLE = {
  type: 'BACK_TO_IDLE'
}

export type EVT_SET_COORDINATE = {
  type: 'SET_COORDINATE'
  coordinate: Coordinate[]
  pageId: string
}

export type EVT_SET_CURRENT_ACTIVE_NOTATION = {
  type: 'SET_CURRENT_ACTIVE_NOTATION'
  notationId?: string
}

export type EVT_CREATE_NOTE = {
  type: 'CREATE_NOTE'
}

export type EVT_SAVE_NOTE = {
  type: 'SAVE_NOTE'
  description: string
}

export type EVT_RECORD_SAVED = {
  type: 'RECORD_SAVED'
}

export type EVT_EDIT_NOTE = {
  type: 'EDIT_NOTE'
  notationId: string
}

export type EVT_DELETE_NOTE = {
  type: 'DELETE_NOTE'
  notationId: string
}

export type EVT_SYNC_MACHINE_INFO = {
  type: 'SYNC_MACHINE_INFO',
  customDeckId?: string,
  isReadOnlyMode: boolean,
}

export type SMEvents =
  | PW.EVT_PRESENTATION_STATE_SYNC
  | EVT_IFRAME_READY
  | EVT_ENTER_SET_COORDINATE_MODE
  | EVT_SET_COORDINATE
  | EVT_BACK_TO_IDLE
  | EVT_SET_CURRENT_ACTIVE_NOTATION
  | EVT_CREATE_NOTE
  | EVT_SAVE_NOTE
  | EVT_RECORD_SAVED
  | EVT_DELETE_NOTE
  | EVT_EDIT_NOTE
  | EVT_SYNC_MACHINE_INFO
