import { CrmIntegrationType } from '@alucio/aws-beacon-amplify/src/models';

export enum SYNC_ENTRY_STATUS {
  PENDING = 'PENDING',
  SYNCED = 'SYNCED',
  ERROR = 'ERROR',
}

export enum SYNC_ENTRY_REQUEST_TYPE {
  TABLE = 'TABLE',
  PICKLIST = 'PICKLIST',
  LOOKUP = 'LOOKUP',
  RECORD_TYPE = 'RECORD_TYPE',
}

export enum SYNC_ENTRY_FETCH_TYPE {
  REGULAR = 'REGULAR',
  SOQL = 'SOQL',
}

export type SyncEntry = {
    url: string,
    parameters: {
      id?: string,
      apiName?: string,
      lookupApiName?: string,
      parentId?: string,
      recordTypeId?: string,
      isStandaloneForm?: boolean,
    },
    type: CrmIntegrationType,
    subType?: SYNC_ENTRY_REQUEST_TYPE,
    data?: any,
    id: string,
    lastSynced: number,
    status: SYNC_ENTRY_STATUS,
    firstSynced?: number,
    fetchType: SYNC_ENTRY_FETCH_TYPE,
}
