import React from 'react'
import { StyleSheet } from 'react-native'
import {
  Iffy,
  DNABox,
  DNAText,
  DNADivider,
} from '@alucio/lux-ui'
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings'
import HubsTable from 'src/components/Hubs/HubsTable'
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import HubsStateProvider, { useHubsState } from 'src/state/context/Hubs/HubsStateProvider'
import { useParams } from 'react-router'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import EditHubWrapper from 'src/screens/Hubs/EditHub/EditHub'

const S = StyleSheet.create({
  desktopContainer: {
    marginHorizontal: 48,
    marginVertical: 20,
  },
  tabletContainer: {
    backgroundColor: colors['color-text-white'],
    overflow:'scroll',
  }
})

export const DNAHubListDesktop: React.FC = () => {
  const { hubsORM } = useHubsState()

  return (
    <DNABox
      alignX="center"
      fill
    >
      <DNABox
        fill
        style={S.desktopContainer}
        alignX="center"
      >
        <DNABox
          fill
          spacing="md"
          appearance="col"
          testID="page-container"
          childFill={2}
        >
          {/* Header */}
          <DNABox alignY="center">
            <DNAText testID="page-title" h5 status="secondary">Hubs</DNAText>
          </DNABox>
          
          <DNADivider />
          
          {
            hubsORM.length
              ? <HubsTable />
              : <DNAEmpty emptyVariant={EmptyVariant.HubsEmpty} />
          }
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

export const DNAHubListTablet: React.FC = () => {
  const { hubsORM } = useHubsState()

  return (
    <DNABox
      fill
      style={S.tabletContainer}
    >
      {/* Empty */}
      <Iffy is={hubsORM.length <= 0}>
        <DNAEmpty
          emptyVariant={EmptyVariant.HubsEmpty}
        />
      </Iffy>
      {/* Table */}
      <Iffy is={hubsORM.length > 0}>
        <HubsTable />
      </Iffy>
    </DNABox>
  )
}

const DNAHubListSwitcher: React.FC = () => {
  const { deviceMode } = useAppSettings()
  const { hubId } = useParams<{ hubId: string }>()

  const hubModeVariants: Record<DeviceMode, React.FC> = {
    [DeviceMode.desktop]: DNAHubListDesktop,
    [DeviceMode.tablet]: DNAHubListTablet,
  }

  const CurrentHubModeVariant = hubModeVariants[deviceMode]

  return (
    <HubsStateProvider hubId={hubId}>
      <CurrentHubModeVariant />
      <EditHubWrapper />
    </HubsStateProvider>
  )
}

export default DNAHubListSwitcher
