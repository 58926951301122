import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { validateStorageSpace } from './worker/db/setupCacheDB';
validateStorageSpace();

// [NOTE] - Can skip doing "window.LOG_LEVEL" during development
// window.LOG_LEVEL = 'DEBUG'

// [TODO-PWA] - Not needed until a machine is in the client
// import { inspect } from '@xstate/inspect';

// inspect({
//   // options
//   // url: 'https://statecharts.io/inspect', // (default)
//   iframe: false, // open in new windowS
// });

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);
