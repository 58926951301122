import { DocumentVersionORM } from 'src/types/orms'
import { ContentPageData, getSlideContentPageDataTitle } from 'src/hooks/useContentPageData/useContentPageData'
import { FileType } from '@alucio/aws-beacon-amplify/src/models'

/**
 * Generate thumbnail URL
 * @param DocumentVersionORM
 * @param pageNumber
 * @returns string (URL)
 */
export const getThumbURL = (documentVersionORM: DocumentVersionORM, pageNumber: number) => {
  return documentVersionORM.meta
    .assets
    .thumbnailKey
    ?.split('/')
    .slice(0, -1)
    .join('/') + `/${pageNumber}_medium_thumb.png` ?? ''
}

/**
 * Generate slide title
 * @param DocumentVersionORM
 * @param pageNumber
 * @param contentPageData
 * @returns string (title)
 */
export const getSlideTitle = (
  documentVersionORM: DocumentVersionORM,
  pageNumber?: number,
  contentPageData?: ContentPageData[],
) : string => {
  if (!pageNumber || !contentPageData || contentPageData.length === 0) return ''
  return getSlideContentPageDataTitle(pageNumber - 1,
    contentPageData, documentVersionORM.model.type === FileType.PPTX)
}
