import React, { useCallback } from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import { DNABox, DNAButton, DNAText, DNASlider, Iffy, DNAChip } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { Active } from '@dnd-kit/core';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as slideSettingsSelector from 'src/state/machines/publisherVersioning/SlideSettings/slideSettings.selectors';

import CloneBuilder, { POOL_CONTAINER_ID } from 'src/components/DnD/Clone/CloneBuilder';
import SlidesGroupPool from 'src/components/DnD/Clone/PublisherGroups/SlidesGroupPool';
import SlidesGroupTarget from 'src/components/DnD/Clone/PublisherGroups/SlidesGroupTarget';
import SlidesGroupOverlay from 'src/components/DnD/Clone/PublisherGroups/SlidesGroupOverlay';
import { ActionBar, GroupHeader } from './GroupHeader';

import { useSlideSettingsState } from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider';
import { SlideSettingsStateProvider }
  from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider.proxy';
import useGroups, { GroupsProvider } from './GroupsProviders';
import { GroupStateProvider } from './GroupProvider.proxy';

const S = StyleSheet.create({
  poolContainer: {
    flex: 1,
    paddingHorizontal: 64,
    paddingVertical: 36,
  },
  targetContainer: {
    backgroundColor: colors['color-gray-80'],
  },
  targetSlidesHeader: {
    borderColor: colors['color-gray-80'],
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    height: 50,
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
})

const GroupModal: React.FC = () => {
  const slideSettingsState = useSlideSettingsState()
  const {
    service,
    backToIdle,
    syncGroupSlides,
    selectGroupSlide,
  } = slideSettingsState

  const {
    isExpanded,
    addGroupHandler,
    showEmptyMessage,
    groups,
    toggleExpanded,
    collapsedGroups,
    toggleCollapsedGroups,
    poolItemIds,
    groupItemIds,
    selectedPageIds,
  } = useGroups()

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      slideSettingsSelector.isSetGroupSlidesMode,
    ),
  )

  const onDragStart = useCallback(
    (active: Active) => {
      if (active.data.current?.containerId === POOL_CONTAINER_ID) {
        selectGroupSlide(active.data.current?.itemId, true)
      }
    }, [])

  return (
    <DNASlider
      visible={cond.isSetGroupSlidesMode}
      setVisible={backToIdle}
      orientation="horizontal"
    >
      <GroupStateProvider value={useGroups()}>
        <SlideSettingsStateProvider value={slideSettingsState}>
          <CloneBuilder
            poolItems={poolItemIds}
            targetItems={groupItemIds}
            selectedItemIds={selectedPageIds}
            onDragEndChanged={(groupItems) => syncGroupSlides(groupItems)}
            onDragStart={onDragStart}
          >
            <DNABox
              fill
              appearance="col"
              style={{ backgroundColor: colors['color-text-white'] }}
            >
              {/* HEADER */}
              <GroupHeader />

              {/* CONTENT */}
              <DNABox fill appearance="row">
                {/* LEFT SIDE */}
                <DNABox
                  fill
                  appearance="col"
                  style={{ display: isExpanded ? 'none' : 'flex' }}
                >
                  <ActionBar />
                  <ScrollView contentContainerStyle={S.poolContainer}>
                    <SlidesGroupPool />
                  </ScrollView>

                </DNABox>

                {/* RIGHT SIDE */}
                <DNABox
                  appearance="col"
                  fill
                  style={{ maxWidth: !isExpanded ? 600 : undefined }}
                >
                  <DNABox style={S.targetSlidesHeader}>
                    {/* GROUP ACTION BAR */}
                    <DNABox spacing="between" fill>
                      {/* GROUP COUNT */}
                      <DNABox alignY="center" spacing="xs">
                        <DNAText status="flatAlt" bold>
                          Groups
                        </DNAText>
                        <DNAChip
                          appearance="subtle"
                          status="basic"
                          size="md"
                        >
                          {`${groups.length}`}
                        </DNAChip>
                      </DNABox>

                      {/* ACTION ITEMS */}
                      <DNABox appearance="row" spacing="sm">
                        {/* CREATE */}
                        <DNAButton
                          appearance="outline"
                          status="tertiary"
                          size="xs"
                          onPress={() => addGroupHandler()}
                          testID="create-group-btn"
                        >
                          Create group
                        </DNAButton>
                        {/* COLLAPSE */}
                        <DNAButton
                          appearance="outline"
                          status="tertiary"
                          size="xs"
                          onPress={() => toggleCollapsedGroups()}
                        >
                          {
                            Object
                              .values(collapsedGroups)
                              .some(val => !val)
                              ? 'Collapse groups'
                              : 'Expand groups'
                          }
                        </DNAButton>
                        {/* EXPAND */}
                        <DNAButton
                          testID="collapse-expand-canvas-button"
                          appearance="outline"
                          status="tertiary"
                          size="xs"
                          onPress={toggleExpanded}
                        >
                          { !isExpanded ? 'Expand canvas' : 'Collapse canvas'}
                        </DNAButton>
                      </DNABox>
                    </DNABox>
                  </DNABox>

                  <Iffy is={showEmptyMessage}>
                    <DNABox style={S.targetContainer} fill alignX="center" alignY="center">
                      <DNAText>Create a group to get started</DNAText>
                    </DNABox>
                  </Iffy>

                  <Iffy is={!showEmptyMessage}>
                    <DNABox fill style={S.targetContainer}>
                      <SlidesGroupTarget />
                    </DNABox>
                  </Iffy>
                </DNABox>
              </DNABox>
            </DNABox>

            {/* DRAG OVERLAY */}
            <SlidesGroupOverlay />
          </CloneBuilder>
        </SlideSettingsStateProvider>
      </GroupStateProvider>
    </DNASlider>
  )
}

const Groups: React.FC = () => {
  return (
    <GroupsProvider>
      <GroupModal />
    </GroupsProvider>
  )
}

export default Groups
