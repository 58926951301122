import {
  configureStore,
  AnyAction,
  Reducer,
  Selector,
} from '@reduxjs/toolkit'
import Observable from 'zen-observable'

import { multiSliceReducer } from 'src/state/redux/slice/multiSlice'
import { rootReducer } from 'src/state/redux/rootReducer'
import cacheSyncMiddleware from './middleware/cacheSync'

// [NOTE] - A bit of a crude workaround, but due to using the customRootReducer (maybe which can possibly be refactored later)
//          that we cannot get the proper types per the official documentation
export type RootState = Exclude<Parameters<typeof rootReducer>[0], undefined>

/**
 * - Root level reducer/action to be able to reset the store back to its initial state
 *    i.e. To use during logout
 * - Also allows us to use the multislice reducer which can modify multiple slices at once
 */
const RESET_TYPE = 'store/reset'
export const storeReset = () => ({ type: RESET_TYPE });

const customRootReducer: Reducer<RootState, AnyAction> = (state, action) => {
  const isMultiSliceAction = action.type.includes('multiSlice/')

  if (isMultiSliceAction) {
    return multiSliceReducer(state, action)
  }

  return action.type === RESET_TYPE
    ? rootReducer(undefined, action)
    : rootReducer(state, action)
}

export const store = configureStore({
  reducer: customRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
      .prepend(cacheSyncMiddleware.middleware),
  devTools: true,
})

// [TODO] - Consider replacing this with the new createListenerMiddleware?
//        - Because we could listen to specific slices instead (and might be typed safely)
export function observeSlice<T = unknown>(
  selector: Selector<RootState, any>,
  ...args
) {
  return new Observable<T>(
    observer => {
      let previousVal

      const unsubscribeRedux = store.subscribe(() => {
        // @ts-ignore - [TODO]: Fix these types based on the selector passed in
        const currentVal = selector(store.getState(), ...args)

        if (previousVal !== currentVal) {
          observer.next(currentVal)
          previousVal = currentVal
        }
      })

      return () => {
        previousVal = undefined
        unsubscribeRedux()
      }
    },
  )
}

export default store
