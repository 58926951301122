import { useSelector } from 'react-redux'
import { createSelector, Selector } from '@reduxjs/toolkit'
import {
  CustomFieldUsage,
  FieldDataType,
  Meeting,
  MeetingFieldDefinition,
  Tenant,
} from '@alucio/aws-beacon-amplify/src/models'
import { RootState } from '../store'
import { CurrentValuesMap, MeetingORM, ORMTypes } from 'src/types/types';
import { getMappedCustomValues, hasMissingRequiredFields } from './common';

export type MeetingORMMap = { [meetingId: string]: MeetingORM }
export const selTenants = (state: RootState): Tenant[] => state.tenant.records
export const selMeetings = (state: RootState): Meeting[] => state.meeting.records;
export const selMeetingId = (_, __, meetingId: string) => meetingId;

const toMeetingORM = (
  meeting: Meeting,
  tenants: Tenant[],
): MeetingORM => {
  // [TODO-2780] - Technically there should only be one tenant
  //               And it should always match, You can probably use a ! here
  //             - This would be safe
  const [tenant] = tenants
  const meetingFields = tenant?.config?.meetingFields || [];
  const tenantCustomFields = tenant?.config?.customFields || [];
  const customValues = meeting.fieldValues

  const customValuesByFieldId = customValues
    // Map of { fieldId: value | fieldDefId }
    .reduce<Record<string, string>>(
      (acc, val) => ({ ...acc, [val.fieldId]: val.valueOrId }),
      {},
    )

  const fieldDefinitionMap = meetingFields
    .reduce<Record<string, MeetingFieldDefinition>>((acc, fieldDef) => ({ ...acc, [fieldDef.id]: fieldDef }), {})

  const customValuesMap = customValues.reduce<CurrentValuesMap>(
    (acc, fieldValue) => {
      const matchedField = fieldDefinitionMap[fieldValue.fieldId]

      if (!matchedField) {
        return acc
      }

      if (!acc[fieldValue.fieldId]) {
        acc[fieldValue.fieldId] = { field: matchedField }
      }

      const hasFieldValues = [
        FieldDataType.CATEGORICAL,
        FieldDataType.MULTICATEGORICAL,
      ].some(f => f === matchedField.type)

      if (hasFieldValues) {
        // Will match disabled && non-disabled fields

        const matchedFieldValueDefinitions = matchedField
          .fieldValueDefinitions
          // [NOTE] : Changed to support MultiCategorical
          .filter(fieldValDef =>
            fieldValDef.id === fieldValue.valueOrId,
          )

        const fieldValues = acc[matchedField.id].fieldValues

        if (!fieldValues) {
          acc[matchedField.id].fieldValues = matchedFieldValueDefinitions
        }
        else {
          acc[matchedField.id].fieldValues = fieldValues.concat(matchedFieldValueDefinitions)
        }
      } else {
        const matchedFieldValue = customValuesByFieldId[matchedField.id]
        acc[matchedField.id].value = matchedFieldValue
      }

      return acc
    }, {})

  const meetingORM: MeetingORM = {
    model: meeting,
    type: ORMTypes.MEETING,
    meta: {
      customValues: {
        areRequiredFieldsCompleted: !hasMissingRequiredFields(
          meeting.customValues || [],
          tenantCustomFields,
          CustomFieldUsage.MEETING),
        configsMap: getMappedCustomValues(
          { internalUsages: [CustomFieldUsage.MEETING] },
          meeting.customValues,
          tenantCustomFields),
      },
      fields: {
        staticFields: [],
        customFields: meetingFields,
        customValues: customValuesMap,
        // [TODO-2780] - Implement this
        customFieldValueDefinitionsMap: {},
      },
    },
  }

  return meetingORM
}

const meetingList: Selector<RootState, MeetingORM[]> = createSelector(
  selTenants,
  selMeetings,
  (tenants, meetings): MeetingORM[] => meetings.map(meeting => toMeetingORM(meeting, tenants)),
);

const meeting: Selector<RootState, MeetingORM | undefined> = createSelector(
  meetingList,
  selMeetingId,
  (meetings, meetingId): MeetingORM | undefined =>
    meetings.find(({ model }) => model.id === meetingId),
)

export const useMeetingList = (): ReturnType<typeof meetingList> =>
  useSelector((state: RootState) => meetingList(state));

export const useMeeting = (meetingId: string): ReturnType<typeof meeting> =>
  useSelector((state: RootState) => meeting(state, undefined, meetingId));
