import React from 'react'
import {
  Stack,
  DNAIcon,
  DNAText,
  DNABox,
  Rotate, DNAChip, luxColors, Iffy,
} from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail'
import { DocumentVersionORM } from 'src/types/types'
import { ConversionStatus } from '@alucio/aws-beacon-amplify/src/models'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { Pressable } from 'react-native'
import useIsPublisherView from 'src/hooks/useIsPublisherView/useIsPublisherView'
import { ERROR_MESSAGES } from 'src/screens/Publishers/Versioning/VersioningCriticalError'

export interface DNADocumentThumbnailProps {
  documentVersionORM: DocumentVersionORM,
  showProcessing?: boolean,
  unavailableContent?: boolean,
  width: number,
  height: number,
  // [TODO-2126] - Instead of passing in an s3 key directly, we should instead pass in a page index
  //               since we have access to the DocumentVersionORM
  //             - Instead of passing in customThumbnail keys for offline/versioning use cases,
  //               this component should choose the thumbnailKey based on latestUsableVersion
  customThumbnailKey?: string,
  onPress?: () => void,
}

interface PlaceholderThumbnailProps {
  fileFormat?: string;
  height: number;
  width: number;
}

const styles = {
  genericBackGround: {
    backgroundColor: luxColors.border.primary,
  },
  popoverAnchor: {
    borderRadius: 2,
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
};

export type ThumbnailProps = Exclude<DNADocumentThumbnailProps, 'showProcessing'>

const ErrorThumbnail: React.FC<ThumbnailProps> = (props) => {
  const { documentVersionORM } = props
  const errorCode = documentVersionORM.model.conversionErrorCode ?? ''
  const errorMessage  = ERROR_MESSAGES[errorCode]?.message ?? 'Error occurred. Please contact support.'

  return (
    <DNAPopover >
      <DNAPopover.Anchor>
        <DNAIcon.Styled
          appearance="ghost"
          status="danger"
          size="xl"
          name="alert-circle"
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">
          {errorMessage}
        </DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  )
}

const ProcessingThumbnail: React.FC<ThumbnailProps> = () => {
  return (
    <DNAPopover >
      <DNAPopover.Anchor>
        <Stack anchor="center">
          <Stack.Layer>
            <DNABox />
          </Stack.Layer>
          <Stack.Layer>
            <Rotate>
              <DNAIcon.Styled
                appearance="ghost"
                status="tertiary"
                size="xl"
                name="refresh"
                testID="thumbnail-optimizing-icon"
              />
            </Rotate>
          </Stack.Layer>
        </Stack>
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">
          Optimizing
        </DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  )
}

const QueuedThumbnail: React.FC<ThumbnailProps> = () => {
  return (
    <DNAPopover >
      <DNAPopover.Anchor>
        <DNAIcon.Styled
          appearance="ghost"
          size="xl"
          status="dark"
          name="image"
          testID="no-content-thumbnail"
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">Queued</DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  )
}

const ProcessedThumbnail: React.FC<ThumbnailProps> = (props) => {
  const { height, width, documentVersionORM, onPress, customThumbnailKey } = props
  const canPresent = documentVersionORM.relations.documentORM.meta.permissions.MSLPresent
  const isPublisherView = useIsPublisherView()
  const showPlayIcon = !!onPress && canPresent && !isPublisherView

  const thumbnailStacks = (
    <Stack anchor="bottomLeft">
      {/* Thumbnail Layer */}
      <Stack.Layer>
        <DNAThumbnail
          s3URL={customThumbnailKey ?? documentVersionORM.meta.assets.thumbnailKey}
          height={height}
          width={width}
          useLoadingIndicator
        />
      </Stack.Layer>

      {/* TODO: Consider reworking these stack.layers to be passed in and customizable rather than statically defined. */}
      {/* Document Type Layer */}
      <Stack.Layer anchor="bottomLeft">
        <DNAChip appearance="tag">
          { documentVersionORM.model.type }
        </DNAChip>
      </Stack.Layer>

      {/* Play Icon Layer */}
      <Stack.Layer anchor="bottomRight">
        <Iffy is={showPlayIcon}>
          <DNAIcon.Styled
            appearance="ghost"
            name="play"
            status="dark"
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.64)',
              borderRadius: 2,
              paddingBottom:1,
            }}
          />
        </Iffy>
      </Stack.Layer>

    </Stack>
  )

  /** Allow for optional custom interaction behavior */
  if (onPress) {
    return (
      <Pressable onPress={onPress}>
        {thumbnailStacks}
      </Pressable>
    )
  }

  /** Otherwise, return non-interactive version allowing for any parent container interactions to take effect */
  return (
    <>
      { thumbnailStacks }
    </>
  )
}

const processingMap = {
  [ConversionStatus.ERROR]: ErrorThumbnail,
  [ConversionStatus.PENDING]: QueuedThumbnail,
  [ConversionStatus.PROCESSING]: ProcessingThumbnail,
  [ConversionStatus.PROCESSED]: ProcessedThumbnail,
}

export const DNADocumentThumbnail: React.FC<DNADocumentThumbnailProps> = (props) => {
  const {
    unavailableContent,
    showProcessing,
    documentVersionORM,
    width,
    height,
    onPress,
    customThumbnailKey,
  } = props

  const forwardProps = { width, height, documentVersionORM, onPress, customThumbnailKey };

  // Shows Document Thumbnail regardless of any new version being processed
  if (!showProcessing) {
    return (
      <React.Fragment>
        <Iffy is={!unavailableContent}>
          <ProcessedThumbnail {...forwardProps} />
        </Iffy>
        { /* NO CONTENT AVAILABLE THUMBNAIL */}
        <Iffy is={unavailableContent}>
          <Stack anchor="center">
            <Stack.Layer>
              <ProcessedThumbnail {...forwardProps} />
            </Stack.Layer>
            <Stack.Layer>
              <DNABox
                alignY="center"
                alignX="center"
                style={{ backgroundColor: colors['color-gray-transparent-40'], minHeight: height, width }}
              >
                <DNAIcon.Styled
                  appearance="ghost"
                  status="primary"
                  name="cloud-off-outline"
                  width={width > 200 ? 48 : 24}
                  height={width > 200 ? 32 : 16}
                />
              </DNABox>
            </Stack.Layer>
          </Stack>
        </Iffy>
      </React.Fragment>
    );
  }

  const ThumbnailToRender = documentVersionORM ? processingMap[documentVersionORM.model.conversionStatus]
    : QueuedThumbnail;

  return (
    <DNABox
      alignX="center"
      alignY="center"
      style={{
        // [TODO] - These are temporary since the thumbnails will be getting
        // reworked soon
        backgroundColor: '#F1F1F1',
        minWidth: width,
        minHeight: height,
      }}
    >
      <ThumbnailToRender {...forwardProps} />
    </DNABox>
  )
}

export function DNAPlaceholderThumbnail(props: PlaceholderThumbnailProps) {
  const { fileFormat, height, width } = props;
  return (
    <Stack anchor="bottomLeft" style={styles.popoverAnchor}>
      <Stack.Layer>
        <DNABox style={[styles.genericBackGround, { width, height }]} />
      </Stack.Layer>
      <Stack.Layer>
        <DNAChip appearance="tag">
          {fileFormat}
        </DNAChip>
      </Stack.Layer>
    </Stack>
  );
}

export default DNADocumentThumbnail
