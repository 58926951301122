import { AttendeeType } from '@alucio/aws-beacon-amplify/src/models';
import {
  CRMSubmitMeetingPayload,
  CRMSubmitResponseReferences,
} from '../CRMIndexedDBTypes';
import { store } from 'src/state/redux';
import { CRMUtil } from 'src/state/machines/CRM/util';

export interface BeaconContentCRMField {
  Id?: string,
  Name: string,
  content_id_beac__c: string,
  content_title_beac__c: string | undefined,
  content_version_beac__c: string,
  page_title_beac__c: string | undefined,
  page_number_beac__c: number | null,
  presented_at_beac__c: string | undefined,
  follow_up_beac__c: boolean | null,
  call_date_beac__c: string | undefined,
  call_account_name_beac__c: string,
  call_account_beac__c?: string,
  call_user_beac__c: string,
  reaction_beac__c: string | null,
  call_call_beac__c?: string,
}

export const detailContentQuery = (
  tableName: string,
  relationShipField: string,
  callIds: string,
  payload: CRMSubmitMeetingPayload,
) => {
  const prefix =
    payload.tenant.config.crmIntegration?.additionalSettings
      ?.detailContentTableSettings?.prefix || '';
  return `
    SELECT id, Name, ${prefix}content_id_beac__c, ${prefix}call_call_beac__c,
    ${prefix}content_title_beac__c, ${prefix}content_version_beac__c, 
    ${prefix}page_title_beac__c, ${prefix}page_number_beac__c, 
    ${prefix}presented_at_beac__c, ${prefix}follow_up_beac__c, 
    ${prefix}call_date_beac__c, ${prefix}call_account_name_beac__c, 
    ${prefix}call_account_beac__c, ${prefix}call_user_beac__c, 
    ${prefix}reaction_beac__c FROM ${tableName} 
    where ${relationShipField} in ${callIds}`;
};

export const getContentPresentedByAttendee = (crmPayload: CRMSubmitMeetingPayload,
  responseReference: CRMSubmitResponseReferences): BeaconContentCRMField[] => {
  const detailContentTableSettings =
  crmPayload.tenant.config.crmIntegration?.additionalSettings?.detailContentTableSettings
  const documentVersions = store.getState().documentVersion.records;
  const { title = '', startTime } = crmPayload
  const { userInfo } = new CRMUtil().getAuthInformation();
  const beaconContentFields: BeaconContentCRMField[] = [];
  const prefix = detailContentTableSettings?.prefix;
  Object.keys(responseReference).forEach((key) => {
    const attendee = key === 'main-record'
      ? crmPayload.formValuesAttendees.find((attendee) => attendee.attendeeType === AttendeeType.PRIMARY)
      : crmPayload.formValuesAttendees.find((attendee) => attendee.id === key);
    if (!attendee) return;
    const callId = responseReference[key].externalId;
    crmPayload.contentPresented?.forEach((content) => {
      content.presentedMeta.filter(p => p.presented).forEach((meta) => {
        const splitArray = meta.pageId.split('_');
        const pageNumber = Number(splitArray.pop());
        const contentId = splitArray.join('_');
        const version = documentVersions.find((doc) => doc.id === contentId);

        const contentField : Partial<BeaconContentCRMField> = {
          call_account_beac__c: attendee?.crmAccountId,
          call_account_name_beac__c: attendee?.name || '',
          call_date_beac__c: startTime,
          call_user_beac__c: userInfo?.id,
          content_id_beac__c: contentId,
          content_title_beac__c: version?.title || '',
          content_version_beac__c: version?.semVer ? `${version.semVer.major}.${version.semVer.minor}` : '',
          follow_up_beac__c: !!meta.followUp,
          page_number_beac__c : pageNumber ?? null,
          page_title_beac__c: meta.title,
          presented_at_beac__c: content.openedAt,
          reaction_beac__c: meta.sentiment || '',
          call_call_beac__c: callId,
        }

        if (prefix) {
          // add prefix to all fields
          Object.keys(contentField).forEach((key) => {
            contentField[`${prefix}${key}`] = contentField[key];
            delete contentField[key];
          })
        }

        contentField.Name = title;
        // the content field match the type the only difference is the name is added after the prefix
        // due the prefix cannot be prefixed to the name for salesforce restrictions
        beaconContentFields.push(contentField as BeaconContentCRMField)
      })
    })
  })

  return beaconContentFields;
}
