import React, { useCallback, useMemo, useState } from 'react';
import {
  DNABox,
  DNAButton,
  DNACard,
  DNAText,
  DNAHeader,
  DNADivider,
  GridList,
  util,
  Iffy,
} from '@alucio/lux-ui';
import { StyleSheet, useWindowDimensions, ViewProps } from 'react-native';
import format from 'date-fns/format';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { Hub } from '@alucio/aws-beacon-amplify/src/models';
import { SortDir } from '../DNA/hooks/useSort';
import { FieldHeader, ListFieldConfig } from '../DNA/GridList/common';
import sortBy from 'lodash/sortBy'
import HubsContextMenu from './HubsContextMenu';
import { DataProvider, LayoutProvider, RecyclerListView, RecyclerListViewProps } from "recyclerlistview";

const S = StyleSheet.create({
  header: {
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
    padding: 0,
    shadowOpacity: 0,
  },
  TableHeader: {
    backgroundColor: colors['color-gray-10'],
    paddingHorizontal: 24,
    paddingVertical: 14,
  },
  SortableButton: {
    minHeight: 17,
    maxHeight: 17,
  },
  Row: {
    justifyContent: 'center',
    flexBasis: 'auto',
    paddingHorizontal: 24,
    paddingVertical: 14,
  },
  hoveredRow: {
    backgroundColor: '#F5F2F6',
    cursor: 'pointer',
  },
  desktopContainer: {
    overflow: 'hidden',
    borderRadius: 4,
    flex: 1,
  },
  contextMenuContainer: {
    paddingRight: 12
  }
});

interface HubRowProps {
  height?: number,
  hub: Hub,
}

enum SortOrders {
  'ascending',
  'descending'
}

type SortOrder = keyof typeof SortOrders
type hubsFields = keyof Hub
interface SelectedSort {
  fieldName: hubsFields
  sortOrder: SortOrder
}

interface HubsListConfig extends ListFieldConfig {
  sortable?: boolean
}

const fields: HubsListConfig[] = [
  {
    fieldName: 'name',
    fieldLabel: 'Name',
    fieldType: 'LABEL',
    dataType: 'string',
    defaultSort: SortDir.asc,
    sortable: true,
    width: 2,
  },
  {
    fieldName: 'createdAt',
    fieldLabel: 'Date Created',
    fieldType: 'LABEL',
    dataType: 'date',
    defaultSort: SortDir.asc,
    sortable: true,
    width: 2,
  },
]

const HubRow : React.FC<HubRowProps> = ({ hub }) => {
  const { name, createdAt, id } = hub
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { handleEditHub, openPreviewWindow } = useHubsState()
  const { isOnline } = useAppSettings()

  function onMouseEnter(): void {
    setIsHovered(true);
  }

  function onMouseLeave(): void {
    setIsHovered(false);
  }

  const onPreview = () => openPreviewWindow(id)

  return (
    <GridList.Row
      style={[S.Row, isHovered && S.hoveredRow]}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onPress={() => handleEditHub(id)}
      testID="hub-item-row"
    >
      {/* Name */}
      <GridList.Col span={1}>
        <DNAText testID="hub-item-row-name" numberOfLines={4}>
          {name}
        </DNAText>
      </GridList.Col>

      {/* Date Created */}
      <GridList.Col span={1}>
        <DNAText testID="hub-item-row-date">
          {format(new Date(createdAt), 'LLL d, y')}
        </DNAText>
      </GridList.Col>

      {/* Context Menu */}
      <GridList.Col span={1}>
        <DNABox fill spacing="sm" alignX='end' style={S.contextMenuContainer}>
          <DNAButton
            iconRight="pencil"
            appearance="outline"
            status="tertiary"
            padding="sm"
            onPress={() => handleEditHub(id)}
          />
          <Iffy is={isOnline}>
            <DNAButton
              iconRight="eye-outline"
              appearance="outline"
              status="tertiary"
              padding="sm"
              onPress={onPreview}
            />
          </Iffy>
          <HubsContextMenu hub={hub} />
        </DNABox>
      </GridList.Col>
    </GridList.Row>
  )
}

const HubsTableContent: React.FC = () => {
  const { deviceMode } = useAppSettings()
  const dimensions = useWindowDimensions();
  const isTablet = deviceMode === 'tablet'
  const { columnConfig } = deviceModeVariants[deviceMode]
  const [selectedSort, setSelectedSort] = useState<SelectedSort>({
    fieldName: 'createdAt',
    sortOrder: 'descending',
  })
  const { handleCreateHubs, hubsORM } = useHubsState()
  const hubs = hubsORM.map(hubORM => hubORM.model)

  const sortedData = selectedSort.sortOrder === 'ascending'
    ? sortBy(hubs, selectedSort.fieldName)
    : sortBy(hubs, selectedSort.fieldName).reverse()

  const toggleSort = (fieldName) => {
    const fieldIsActive = selectedSort.fieldName = fieldName
    if (fieldIsActive) {
      setSelectedSort(p => ({ ...p, sortOrder:  selectedSort.sortOrder === 'ascending' ? 'descending' : 'ascending' }))
    } else {
      setSelectedSort({
        fieldName: fieldName,
        sortOrder: 'ascending',
      })
    }
  }

  // RECYCLER LIST //
  const dataProviderInit = useMemo(
    () => (new DataProvider((prev: string, next: string) => prev !== next))
      .cloneWithRows(sortedData),
    [sortedData]
  );

  const layoutProvider = useMemo(
    () => new LayoutProvider(_ => 0,
      (_, dim) => {
        dim.width = 2000;
        dim.height = 58;
      },
    ),
    []
  );

  const rowRenderer = useCallback<RecyclerListViewProps['rowRenderer']>(
    (type, hub: Hub) => {
      return (
        <React.Fragment key={hub.id}>
          <DNADivider />
          <HubRow hub={hub} />
        </React.Fragment>
      );
    },
    []
  )

  const renderItemWrapper = useCallback<NonNullable<RecyclerListViewProps['renderItemContainer']>>(
    (props, parentProps, children) => {
      const { style } = props as { style?: ViewProps };
      return (
        <DNABox {...props} style={{ ...style, width: '100%' }}>
          {children}
        </DNABox>
      )
    },
    []
  )

  return (
    <DNABox appearance="col" fill>
      {/* Header */}
      <DNAHeader
        status={'neutral'}
        size="medium"
        style={S.header}
      >
        <DNAHeader.Right>
          <DNAButton
            testID="hub-table-header-create-hub-btn"
            size="lg"
            status="tertiary"
            appearance="outline"
            padding="sm"
            iconLeft="plus"
            onPress={handleCreateHubs}
          >
            Create hub
          </DNAButton>
        </DNAHeader.Right>
      </DNAHeader>
      {/* Table */}
      <GridList
        cols={columnConfig}
        gap="12px"
      >
        <GridList.Header
          style={util.mergeStyles(undefined, S.TableHeader)}
        >
          {
            fields.map(field => {
              const sortIcon = selectedSort.fieldName === field.fieldName
                ? selectedSort.sortOrder === 'ascending'
                  ? 'menu-down'
                  : 'menu-up'
                : undefined
              return (
                <FieldHeader
                  key={field.fieldName}
                  style={S.SortableButton}
                  toggleSort={toggleSort}
                  sortIcon={sortIcon}
                  field={field}
                  variant="HUBS"
                  testID={`header-sort-${field.fieldName}`}
                />
              )
            })
          }
          <GridList.Col span={1}>
            <></>
          </GridList.Col>
        </GridList.Header>
        {/* Hub Rows */}
        <RecyclerListView
          layoutProvider={layoutProvider}
          dataProvider={dataProviderInit}
          rowRenderer={rowRenderer}
          renderAheadOffset={dimensions.height}
          canChangeSize
          forceNonDeterministicRendering
          renderItemContainer={renderItemWrapper}
        />
      </GridList>
    </DNABox>
  )
}

const HubsTableTablet: React.FC = () => {
  return <HubsTableContent />
}

const HubsTableDesktop: React.FC = () => {
  return (
    <DNACard
      appearance="float"
      style={S.desktopContainer}
    >
      <HubsTableContent />
    </DNACard>
  )
}

interface DeviceModeVariant {
  component: React.ElementType,
  columnConfig: string
}

type DeviceModeVariants = Record<DeviceMode, DeviceModeVariant>

const deviceModeVariants: DeviceModeVariants = {
  desktop: {
    component: HubsTableDesktop,
    columnConfig: 'minmax(300px, 100%) minmax(150px, 100%) minmax(110px,100%)',
  },
  tablet: {
    component: HubsTableTablet,
    columnConfig:'minmax(300px, 100%) minmax(150px, 100%) minmax(110px,100%)',
  },
}

const HubsTable:React.FC = () => {
  const { deviceMode } = useAppSettings()

  const CurrentDeviceModeVariant = deviceModeVariants[deviceMode].component
  return <CurrentDeviceModeVariant />
}

export default HubsTable;
