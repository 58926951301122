/* eslint-disable max-len */
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

const SvgSentimentsIcon = (props: SvgProps) => {
  const sizeSmallPath = (
    <Path
      fill={props.color ?? '#fff'}
      d="M9.905 12.19h2.286V9.906h1.523v2.286H16v1.523h-2.286V16H12.19v-2.286H9.906zm-2.286-.38a4.19 4.19 0 0 1-3.893-2.667h6.705a4.52 4.52 0 0 0-1.875 2.56 4.3 4.3 0 0 1-.937.107M4.952 6.857c-.632 0-1.142-.51-1.142-1.143a1.141 1.141 0 1 1 2.286 0c0 .633-.511 1.143-1.144 1.143m5.334 0c-.633 0-1.143-.51-1.143-1.143 0-.632.51-1.143 1.143-1.143.632 0 1.143.51 1.143 1.143s-.51 1.143-1.143 1.143m-2.667 6.857.815-.053c.084.518.252 1.013.496 1.463a7.5 7.5 0 0 1-1.311.114C3.406 15.238 0 11.81 0 7.62S3.406 0 7.619 0c4.19 0 7.62 3.429 7.62 7.619q0 .673-.115 1.31a4.6 4.6 0 0 0-1.463-.495l.053-.815A6.094 6.094 0 0 0 7.62 1.524a6.094 6.094 0 0 0-6.095 6.095 6.094 6.094 0 0 0 6.095 6.095"
    />
  )

  const sizeLargePath = (
    <Path
      fill={props.color ?? '#fff'}
      d="M13 16h3v-3h2v3h3v2h-3v3h-2v-3h-3zm-3-.5c-2.33 0-4.31-1.46-5.11-3.5h8.8a5.94 5.94 0 0 0-2.46 3.36c-.4.09-.81.14-1.23.14M6.5 9C5.67 9 5 8.33 5 7.5S5.67 6 6.5 6 8 6.67 8 7.5 7.33 9 6.5 9m7 0c-.83 0-1.5-.67-1.5-1.5S12.67 6 13.5 6s1.5.67 1.5 1.5S14.33 9 13.5 9M10 18l1.07-.07c.11.68.33 1.33.65 1.92-.56.1-1.14.15-1.72.15-5.53 0-10-4.5-10-10S4.47 0 10 0c5.5 0 10 4.5 10 10 0 .59-.05 1.16-.15 1.72-.59-.32-1.23-.54-1.92-.65L18 10c0-4.42-3.58-8-8-8s-8 3.58-8 8 3.58 8 8 8"
    />
  )
  return (
    <Svg
      // @ts-ignore
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 24}
      height={props.height || 24}
      fill="none"
      {...props}
    >
      {props.width === 16 ? sizeSmallPath : sizeLargePath}
    </Svg>
  )
}

export default SvgSentimentsIcon;
