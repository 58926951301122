import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgListBoxOutline = (props: SvgProps) => (
  <Svg
    width={48}
    height={48}
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      // eslint-disable-next-line max-len
      d="M16.667 24h12v4h-12v-4Zm-4-16h-4v4h4V8Zm4 12h12v-4h-12v4Zm0-8h12V8h-12v4Zm-4 4h-4v4h4v-4Zm24-12v28c0 2.2-1.8 4-4 4h-28c-2.2 0-4-1.8-4-4V4c0-2.2 1.8-4 4-4h28c2.2 0 4 1.8 4 4Zm-4 0h-28v28h28V4Zm-20 20h-4v4h4v-4Z"
      fill="#44A2DA"
    />
  </Svg>
);
export default SvgListBoxOutline;
