import { createAction } from '@reduxjs/toolkit'
import { createReducer } from 'src/state/redux/util/createImmerlessSlice'
import { RootState } from 'src/state/redux'
import { datastoreSave, SliceStatus } from 'src/state/redux/slice/common'

import { Document, DocumentVersion, GetSharedFoldersOutput } from '@alucio/aws-beacon-amplify/src/models'
import { DocumentVersionORM } from 'src/types/orms'

export const multiSliceActions = {
  deleteDocumentDraft: createAction<DocumentVersionORM>('multiSlice/deleteDocumentDraft'),
  replaceSharedFolders: createAction<GetSharedFoldersOutput>('multiSlice/replaceSharedFolders'),
}

export const multiSliceReducer = createReducer<RootState>(
  /** TODO: Investigate whether this is the best approach here. Ideally we would have an initial RootState object to pass
   * in but that may require adjusting to use a partial wrapped RootState which would necessitate reliant components.  */
  null!,
  builder => {
    builder.addCase(
      multiSliceActions.deleteDocumentDraft,
      (state, action) => {
        const documentVersionORM = action.payload
        const parentDocumentORM = documentVersionORM.relations.documentORM
        const isVersionPublished = documentVersionORM.model.status === 'PUBLISHED'
        const isDocumentPublished = parentDocumentORM.model.status === 'PUBLISHED'

        if (isVersionPublished) { throw new Error('Cannot delete Published document version') }

        if (!isDocumentPublished) {
          datastoreSave(
            Document,
            parentDocumentORM.model,
            { status: 'DELETED' },
          )

          return state
        }

        if (!isVersionPublished && isDocumentPublished) {
          datastoreSave(
            DocumentVersion,
            documentVersionORM.model,
            { status: 'DELETED' },
          )

          return state
        }

        throw new Error(`Could not determine deletion status for DocumentVersion ${documentVersionORM.model.id}`)
      },
    );
    builder.addCase(
      multiSliceActions.replaceSharedFolders,
      (state, action) => {
        const newSharedFolder = { ...state.sharedFolder }
        newSharedFolder.records = action.payload.folders;
        newSharedFolder.hydrated = true;
        newSharedFolder.status = SliceStatus.OK;

        const newFolderPermissions = { ...state.folderPermission }
        newFolderPermissions.records = action.payload.permissions
        newFolderPermissions.hydrated = true;
        newFolderPermissions.status = SliceStatus.OK;

        return {
          ...state,
          sharedFolder: newSharedFolder,
          folderPermission: newFolderPermissions,
        };
      },
    );
  },
)
