import { Logger } from '@aws-amplify/core';
import { SET_WORKER_CLIENT_LOGGING } from '@alucio/core';
import mapValues from 'lodash/mapValues'
import workerChannel from 'src/worker/channels/workerChannel';
import { LogLevel } from 'src/state/machines/logger/logger.types'
import { bytesToSize } from 'src/utils/documentHelpers'
import { isDev } from 'src/utils';
import type { Convert, RecursiveObject } from 'src/types/util'
import { circularDependencyRemover } from './objectHelpers';

/**
 * This section is dedicated to hooking into Loggers with the purpose of Broadcasting their messages
 * This is useful for communicating from the Service worker to the Client for displaying debugging Client-side
 * i.e. Useful for Physical device debugging when it's difficult to access DevTools
 */

/**
 * This wraps normal logging with callbacks that allow us to pipe the output to additional places
 * i.e. Service Worker logging that can send messages over Broadcast Channel to store in Redux for easy client-side access (instead of through devtools)
 */
export type BroadcastLogger = {
  name: string
  level: string
  debug: (...msg: any[]) => void
  info: (...msg: any[]) => void
  warn: (...msg: any[]) => void
  error: (...msg: any[]) => void
  verbose: (...msg: any[]) => void
  setCallback: (cb: (msg: string) => void) => void
  setClientLogging: (val: boolean) => void
}

let enableClientLogging = false
let callback

export const PWALogger: BroadcastLogger = {
  name: 'PWALogger',
  level: LogLevel.INFO,
  debug: (...msg: any[]) => {
    const [message] = msg
    serviceWorker.debug(...msg)

    if (enableClientLogging)
    { workerChannel.postMessage({ type: 'LOG_MESSAGE', level: LogLevel.DEBUG, message: message }) }
  },
  info: (...msg: any[]) => {
    const [message] = msg
    serviceWorker.info(...msg)

    if (enableClientLogging)
    { workerChannel.postMessage({ type: 'LOG_MESSAGE', level: LogLevel.INFO, message: message }) }
    callback?.(message)
  },
  warn: (...msg: any[]) => {
    const [message] = msg
    serviceWorker.warn(...msg)

    if (enableClientLogging)
    { workerChannel.postMessage({ type: 'LOG_MESSAGE', level: LogLevel.WARN, message: message }) }
  },
  error: (...msg: any[]) => {
    const [message] = msg
    serviceWorker.error(...msg)

    if (enableClientLogging)
    { workerChannel.postMessage({ type: 'LOG_MESSAGE', level: LogLevel.ERROR, message: message }) }
  },
  verbose: (...msg: any[]) => {
    const [message] = msg
    serviceWorker.verbose(...msg)

    if (enableClientLogging)
    { workerChannel.postMessage({ type: 'LOG_MESSAGE', level: LogLevel.VERBOSE, message: message }) }
  },
  setCallback: (cb: (msg: string) => void) => {
    callback = cb
  },
  setClientLogging: (val: boolean) => {
    enableClientLogging = val
  },
}

workerChannel
  .observable
  .filter(v => v.type === 'SET_WORKER_CLIENT_LOGGING')
  .subscribe(m => {
    const msg = m as SET_WORKER_CLIENT_LOGGING
    PWALogger.setClientLogging(msg.value)
  })

// ------------------------------------------------------------

/* If you have questions regarding the loggers, please see documentation
https://alucioinc.atlassian.net/wiki/spaces/ENG/pages/1907687431/Logger+guidelines */

/* This is global log level */
Logger.LOG_LEVEL = 'WARN';

export const addMeetingPanel = new Logger('AddMeetingPanel', LogLevel.INFO);
export const cacheSlice = new Logger('CacheSlice', LogLevel.INFO);
export const contentPresentedList = new Logger('ContentPresentedList', LogLevel.INFO);
export const graphqlClient = new Logger('GraphqlClient', LogLevel.INFO);
export const CRMConfigMachine = new Logger('CRMConfigMachine', LogLevel.INFO);
export const CRMMachine = new Logger('CRMMachine', LogLevel.INFO);
export const CRMFormTranslatorlogger = new Logger('CRMFormTranslator', LogLevel.INFO);
export const customDeckSlice = new Logger('CustomDeckSlice', LogLevel.INFO);
export const debug = new Logger('debug', LogLevel.DEBUG);
export const findReplacementMachine = new Logger('FindReplacementSM', LogLevel.INFO);
export const folder = new Logger('Folder', LogLevel.INFO);
export const highlighterPlayer = new Logger('HighlighterPlayer', LogLevel.INFO);
export const iFrameWeb = new Logger('IFrameWeb', LogLevel.INFO);
export const loggerMachine = new Logger('LoggerMachine', LogLevel.ERROR);
export const meetingsPopoutContent = new Logger('MeetingsPopoutContentState', LogLevel.INFO);
export const pwa = new Logger('PWA', LogLevel.WARN);
export const playerWrapperMachine = new Logger('PlayerWrapperSM', LogLevel.INFO);
export const popoutContentWindow = new Logger('PopoutContentWindow', LogLevel.INFO);
export const requireNewPassword = new Logger('RequireNewPassword', LogLevel.INFO);
export const salesForceTranslator = new Logger('SalesforceTranslator', LogLevel.INFO);
export const salesforceSyncer = new Logger('SalesforceSyncer', LogLevel.INFO);
export const saveCustomFormRecord = new Logger('SaveCustomFormRecord', LogLevel.INFO);
export const saveMeetingHandler = new Logger('SaveMeetingHandler', LogLevel.INFO);
export const deleteMeetingHandler = new Logger('DeleteMeetingHandler', LogLevel.INFO);
export const saveMeetingUtil = new Logger('SaveMeetingUtilities', LogLevel.INFO);
export const serviceWorker = new Logger('ServiceWorker', LogLevel.INFO);
export const useContentInfoChannel = new Logger('useContentInfoBroadcastChannel', LogLevel.INFO);
export const useDidHookRender = new Logger('UseDidHookRender', LogLevel.INFO);
export const useRetryOnSSOError = new Logger('useRetryOnSSOError', LogLevel.INFO);
export const userNotations = new Logger('userNotations', LogLevel.INFO);
export const useSyncContentPresented = new Logger('UseSyncContentPresented', LogLevel.INFO);
export const userInit = new Logger('UserInit', LogLevel.INFO);
export const util = new Logger('Util', LogLevel.INFO);
export const veevaSyncer = new Logger('VeevaSyncer', LogLevel.INFO);
export const veevaTranslator = new Logger('VeevaTranslator', LogLevel.INFO);
export const veevaTranslatorUtil = new Logger('VeevaTranslatorUtil', LogLevel.INFO);

enum LOG_LEVELS {
  warn = 'warn',
  error = 'error',
  info = 'info',
  debug = 'debug',
  verbose = 'verbose',
}

type LogLevels = keyof typeof LOG_LEVELS

type LogLevelsFunctions = {
  [key in keyof typeof LOG_LEVELS]: Logger[key]
}

const initialize = () => {
  if (typeof window === 'undefined' || typeof localStorage === 'undefined') {
    console.warn('window or localStorage is not available.');
    return {
      LOCALSTORAGE_LOG_LEVEL: 'warn',
      LOCALSTORAGE_LOG_ENABLE_STATS: false,
    };
  }

  const localStorageItems = {
    logLevel: localStorage.getItem('LOG_WINDOW_LOG_LEVEL'),
    enableStats: localStorage.getItem('LOG_ENABLE_STATS'),
  }

  const LOCALSTORAGE_LOG_LEVEL = typeof localStorageItems.logLevel === 'string'
    ? LOG_LEVELS[localStorageItems.logLevel.toLowerCase()].toUpperCase()
    : undefined

  if (LOCALSTORAGE_LOG_LEVEL) {
    // @ts-ignore
    window.LOG_LEVEL = LOCALSTORAGE_LOG_LEVEL
    console.log('Setting Window Log Level from LocalStorage config')
  }

  const LOCALSTORAGE_LOG_ENABLE_STATS = typeof localStorageItems.enableStats === 'string'
    ? localStorageItems.enableStats === 'enable'
    : localStorageItems.enableStats === 'disable'
      ? false
      : isDev

  if (LOCALSTORAGE_LOG_ENABLE_STATS) {
    console.log('Enabling Logging Stats', { localStorageItems, isDev })
  }

  return {
    LOCALSTORAGE_LOG_LEVEL,
    LOCALSTORAGE_LOG_ENABLE_STATS,
  }
}

const { LOCALSTORAGE_LOG_ENABLE_STATS } = initialize()

type LogStatsCounter = Record<
  LogLevels,
  {
    count: number,
    // [NOTE] - Ints can save about ~2GB representation of bytes which should be plenty so no need for BigInt
    size: number,
    largeLogs: 0,
  }
>

/**
 * @param enable - Enable or disable stat updating (defaults to build environment)
 */
class LogStats {
  private enable: boolean = false
  private LOG_SIZE_BYTE_WARNING: number = 128 * 1024 // (128KB)

  stats: LogStatsCounter = {
    warn: {
      count: 0,
      size: 0,
      largeLogs: 0,
    },
    error: {
      count: 0,
      size: 0,
      largeLogs: 0,
    },
    info: {
      count: 0,
      size: 0,
      largeLogs: 0,
    },
    debug: {
      count: 0,
      size: 0,
      largeLogs: 0,
    },
    verbose: {
      count: 0,
      size: 0,
      largeLogs: 0,
    },
  }

  constructor(enable: boolean = LOCALSTORAGE_LOG_ENABLE_STATS) {
    this.enable = enable
  }

  update(level: LogLevels, ...msg: any[]) {
    if (!this.enable) return;
    this.stats[level].count++
    const serializedLogSizeBytes = Buffer.byteLength(JSON.stringify(msg, circularDependencyRemover(20)))

    if (serializedLogSizeBytes > this.LOG_SIZE_BYTE_WARNING) {
      console.warn("We've noticed a large log (>128KB)")
      console.warn(...msg)
      this.stats[level].largeLogs++
    }

    this.stats[level].size += serializedLogSizeBytes
  }

  getStats(): Record<LogLevels, { count: number, size: string, largeLogs: number }> &
  {
    totalSize: string,
    totalCount: number,
    totalLargeLogs: number
    } {
    const totals = Object
      .values(this.stats)
      .reduce<{ size: number, count: number, largeLogs: number }>(
        (acc, level) => {
          acc.size += level.size
          acc.count += level.count
          acc.largeLogs += level.largeLogs
          return acc
        },
        { size: 0, count: 0, largeLogs: 0 },
      )

    return {
      warn: { ...this.stats.warn, size: bytesToSize(this.stats.warn.size) },
      error: { ...this.stats.error, size: bytesToSize(this.stats.error.size) },
      info: { ...this.stats.info, size: bytesToSize(this.stats.info.size) },
      debug: { ...this.stats.debug, size: bytesToSize(this.stats.debug.size) },
      verbose: { ...this.stats.verbose, size: bytesToSize(this.stats.verbose.size) },
      totalSize: bytesToSize(totals.size),
      totalCount: totals.count,
      totalLargeLogs: totals.largeLogs,
    }
  }
}

/**
 * Recursive statement that traverses an object and adds log functionality to objects and their nested object
 * @param valIn Value in
 * @param logger Logger to reference
 * @param path  Path builder during recursion
 * @returns
 */
const decorateLogStructure = <T extends RecursiveObject>(
  valIn: T,
  logger: Logger,
  path: Array<string> = [],
  // [TS] - Not exactly the right type as we add logging levels to the parent object as well
): RecursiveObject<LogLevelsFunctions> => mapValues(
    valIn,
    (val, key) => {
      const currentPath = [...path, key]
      const currentPathConcat = currentPath
        .map(path => typeof path === 'string' ? path.toUpperCase() : path)
        .join(' - ')
        .concat(' -')

      if (typeof val === 'boolean') {
        // [TODO] - Maybe we should consider creating one LogStats (at the root level)
        //          and have the ability to register nested loggers
        //          so it's easier to call and not do deep object traversal for individual stats
        const stats = new LogStats()

        return {
          warn: (...msg: any[]) => {
            logger.warn(currentPathConcat, ...msg)
            stats.update('warn', ...msg)
          },
          error: (...msg: any[]) => {
            logger.error(currentPathConcat, ...msg)
            stats.update('error', ...msg)
          },
          info: (...msg: any[]) => {
            logger.info(currentPathConcat, ...msg)
            stats.update('info', ...msg)
          },
          debug: (...msg: any[]) => {
            logger.debug(currentPathConcat, ...msg)
            stats.update('debug', ...msg)
          },
          verbose: (...msg: any[]) => {
            logger.verbose(currentPathConcat, ...msg)
            stats.update('verbose', ...msg)
          },
          stats,
        } as any as RecursiveObject<LogLevelsFunctions>
      }
      else if (typeof val === 'object') {
        const stats = new LogStats()

        return {
          ...decorateLogStructure(val, logger, currentPath),
          warn: (...msg: any[]) => {
            logger.warn(currentPathConcat, ...msg)
            stats.update('warn', ...msg)
          },
          error: (...msg: any[]) => {
            logger.error(currentPathConcat, ...msg)
            stats.update('error', ...msg)
          },
          info: (...msg: any[]) => {
            logger.info(currentPathConcat, ...msg)
            stats.update('info', ...msg)
          },
          debug: (...msg: any[]) => {
            logger.debug(currentPathConcat, ...msg)
            stats.update('debug', ...msg)
          },
          verbose: (...msg: any[]) => {
            logger.verbose(currentPathConcat, ...msg)
            stats.update('verbose', ...msg)
          },
          stats,
        } as any as RecursiveObject<LogLevelsFunctions>
      }
      else return valIn as any as RecursiveObject<LogLevelsFunctions>
    },
  )

/**
 * Creates a Logger class where a given structured is passed in and becomes typesafe as valid object keys (nested and unnested)
 * @param: struc - Any object structure that uses booleans for values
 * @param: ...rest - Normal Parameters from the Logger class
 */
export const KeyedLogger = class KeyedLogger<T extends RecursiveObject> {
  constructor(struc: T, ...args: ConstructorParameters<typeof Logger>) {
    const logger = new Logger(...args)
    const mappedStruc = decorateLogStructure(struc, logger)
    const stats = new LogStats()

    const originalWarn = logger.warn.bind(logger)
    const originalError = logger.error.bind(logger)
    const originalInfo = logger.info.bind(logger)
    const originalDebug = logger.debug.bind(logger)
    const originalVerbose = logger.verbose.bind(logger)

    const decoreatedWarn = (...msg: any[]) => {
      originalWarn(...msg)
      stats.update('warn', ...msg)
    }
    const decoreatedError = (...msg: any[]) => {
      originalError(...msg)
      stats.update('error', ...msg)
    }
    const decoreatedInfo = (...msg: any[]) => {
      originalInfo(...msg)
      stats.update('info', ...msg)
    }
    const decoreatedDebug = (...msg: any[]) => {
      originalDebug(...msg)
      stats.update('debug', ...msg)
    }
    const decoreatedVerbose = (...msg: any[]) => {
      originalVerbose(...msg)
      stats.update('verbose', ...msg)
    }

    logger.warn = decoreatedWarn
    logger.error = decoreatedError
    logger.info = decoreatedInfo
    logger.debug = decoreatedDebug
    logger.verbose = decoreatedVerbose

    return Object.assign(
      logger,
      { ...mappedStruc, stats },
    )
  }
} as new <T extends RecursiveObject>(
  struc: T,
  ...args: ConstructorParameters<typeof Logger>
) => (
  Logger
  & Convert<LogLevelsFunctions & { stats: LogStats }, T>
  & { stats: LogStats }
)

// New Loggers
export const appInit = new KeyedLogger(
  {
    appSettings: true,
    analytics: true,
  },
  'AppInit',
  LogLevel.INFO,
)

export const auth = new KeyedLogger(
  {
    signIn: {
      authenticator: true,
      forgotPassword: true,
      sso: true,
    },
    signOut: true,
    sessionManagement: {
      subscriptions: true,
    },
  },
  'Auth',
  LogLevel.INFO,
)

export const clientState = new KeyedLogger(
  {
    dataStore: true,
    hydrate: true,
  },
  'ClientState',
  LogLevel.INFO,
)

export const contentPresenting = new KeyedLogger(
  {
    contentProvider: true,
    CPM: true,
    contentPageData: true,
  },
  'ContentPresenting',
  LogLevel.INFO,
)

export const customDeck = new KeyedLogger(
  {
    updates: {
      findAndReplace: true,
    },
  },
  'CustomDeck',
  LogLevel.INFO,
)

export const hub = new KeyedLogger(
  {
    hubManagement: true,
    sharing: true,
    widgets: {
      sharedFiles: {
        callOuts: true,
      },
      todo: true,
      links: true,
    },
  },
  'Hub',
  LogLevel.INFO,
)

export const offline = new KeyedLogger(
  {
    contentCache: {
      manifest: true,
    },
    sync: {
      download: true,
      file: true,
    },
    serviceWorker: true,
  },
  'Offline',
  LogLevel.INFO,
)

export const versioning = new KeyedLogger(
  {
    create: {
      upload: true,
      existing: true,
    },
    infoForm: true,
    matchSlides: true,
    settings: {
      descriptors: true,
      required: true,
      groups: true,
    },
    associated: true,
    publish: {
      notifications: true,
      schedule: true,
      versioning: true,
    },
    update: true,
  },
  'Versioning',
  LogLevel.INFO,
)

export const email = new KeyedLogger(
  {
    template: true
  },
  'Email',
  LogLevel.INFO
)

export const redux = new KeyedLogger(
  {
    actions: {
      batchUpsert: true
    }
  },
  'Redux',
  LogLevel.VERBOSE
)

export const search = new KeyedLogger(
  {
    contentSearch: true
  },
  'Search',
  LogLevel.INFO
)

const loggers = {
  addMeetingPanel,
  cacheSlice,
  contentPresentedList,
  CRMConfigMachine,
  CRMMachine,
  CRMFormTranslatorlogger,
  customDeckSlice,
  debug,
  findReplacementMachine,
  graphqlClient,
  folder,
  highlighterPlayer,
  iFrameWeb,
  loggerMachine,
  meetingsPopoutContent,
  pwa,
  playerWrapperMachine,
  popoutContentWindow,
  requireNewPassword,
  salesForceTranslator,
  salesforceSyncer,
  saveCustomFormRecord,
  saveMeetingHandler,
  deleteMeetingHandler,
  saveMeetingUtil,
  search,
  serviceWorker,
  useContentInfoChannel,
  useDidHookRender,
  useRetryOnSSOError,
  userNotations,
  useSyncContentPresented,
  userInit,
  util,
  veevaSyncer,
  veevaTranslator,
  veevaTranslatorUtil,
  // New Loggers
  appInit,
  auth,
  clientState,
  contentPresenting,
  customDeck,
  hub,
  offline,
  versioning,
  redux,
  email,
} satisfies Record<string, (Logger | typeof KeyedLogger)>

export type LoggerNames = keyof typeof loggers
// [NOTE] - Technically this should be a tuple, but this works as well as is less type intensive
export const loggerNames = Object.keys(loggers) as Array<LoggerNames>

export default loggers
