import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { TouchableOpacity, StyleSheet, ScrollView } from 'react-native';
import { DNABox, DNAButton, MenuItem, DNAText, DNAChip, Icon, luxColors } from '@alucio/lux-ui';
import { OverflowMenu, Input } from '@ui-kitten/components';
import { CustomFieldUsage, FileType } from '@alucio/aws-beacon-amplify/src/models';
import { DocumentVersionORM, CustomDeckORM, DocumentORM } from 'src/types/orms';
import { isDocumentVersionORM } from 'src/types/types';
import { filters, merge } from 'src/state/redux/document/query';
import DNADocumentThumbnail from '../DNA/Document/DNADocumentThumbnail';
import DNAPopover from '../DNA/Popover/DNAPopover';
import DNAThumbnail from '../DNA/Thumbnail/DNAThumbnail';
import CustomFieldBadgeList from '../CustomFields/CustomFieldBadgeList';
import { useMSLDocumentSearch, useCustomDeckSearch } from 'src/state/redux/selector/documentSearch/documentSearch';
import { useTenantCustomFields } from 'src/state/redux/selector/tenant';
import { useAppSettings } from 'src/state/context/AppSettings';
import { useAddMeeting } from '../Meeting/AddMeetingProvider';
import { highlightText } from 'src/utils/searchHelpers';
import logger from 'src/utils/logger'

const styles = StyleSheet.create({
  resultsWrapper: {
    maxHeight: 300,
  },
  searchIcon: {
    width: 17.5,
    height: 17.5,
  },
  cancelBtn: {
    height: 40,
    marginLeft: 8,
    marginBottom: 4,
  },
  customFieldsText: {
    color: luxColors.subtitle.quinary,
    fontSize: 12,
    lineHeight: 20,
  },
})
export interface ContentSearchResult {
  id: string
  title: string,
  orm: DocumentVersionORM | CustomDeckORM,
}

enum VariantOptions {
  MEETING_HISTORY = 'MEETING_HISTORY',
  HUBS = 'HUBS',
}

type VariantOption = keyof typeof VariantOptions

interface ContentSearchBarProps {
  onSelectItem: (item: ContentSearchResult) => void,
  selectedIds?: string[],
  variant: VariantOption,
  isVisible: boolean,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  disable?: boolean,
}

const DocumentVersionResult: React.FC<{ orm: DocumentVersionORM, searchText: string }> = (props) => {
  const { orm, searchText } = props;
  const tenantCustomFields = useTenantCustomFields({
    defaultSearchFilter: true,
    usages: { internalUsages: [CustomFieldUsage.DOCUMENT] },
  });
  const tenantValues = tenantCustomFields.reduce((acc, customField) => {
    if (isDocumentVersionORM(orm)) {
      const value = orm.meta.customValues?.configsMap?.[customField.id]?.displayValues
      if (value && value.length > 0) {
        acc += acc ? `, ${value.join(', ')}` : ` ⋅ ${value.join(', ')}`;
      }
    }
    return acc;
  }, '');

  const type = orm.model.type === FileType.MP4 ? 'VIDEO' : orm.model.type

  return (
    <DNABox fill childFill={1} spacing="sm">
      <DNADocumentThumbnail
        width={72}
        height={40}
        documentVersionORM={orm}
        showProcessing
      />
      <DNABox fill appearance="col">
        {highlightText(orm.model.title || '', searchText, undefined, 1, 'search-result-document-title')}
        <DNAText status="flatAlt" style={styles.customFieldsText} numberOfLines={1}>{type} {tenantValues}</DNAText>
        <CustomFieldBadgeList documentVersionORM={orm} />
      </DNABox>
    </DNABox>
  )
}

const CustomDeckResult: React.FC<{ orm: CustomDeckORM, searchText: string }> = (props) => {
  const { orm, searchText } = props;

  return (
    <DNABox fill childFill={1} spacing="sm">
      <DNAThumbnail
        s3URL={orm.meta.assets.thumbnailKey}
        useLoadingIndicator
        width={72}
        height={40}
      />
      <DNABox fill appearance="col">
        {highlightText(orm.model.title || '', searchText, undefined, 1)}
        <DNABox>
          <DNAChip appearance="tag">
            MODIFIED
          </DNAChip>
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

const useContentSearchBar = (props: ContentSearchBarProps) => {
  const { onSelectItem, selectedIds, variant, setIsVisible, disable } = props;

  const [resultsVisibility, setResultsVisibility] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  const searchFilter = useMemo<Parameters<typeof useMSLDocumentSearch>>(
    () => [
      { text: searchText },
      merge(filters.hasLatestPublishedVersion, filters.presentable),
    ],
    [searchText],
  )

  const documentResults: DocumentORM[] = useMSLDocumentSearch(...searchFilter)
  // Add a presentable filter here when BEAC-3779 custom deck filtering is implemented
  const customDeckResults: CustomDeckORM[] = useCustomDeckSearch(searchText);
  const inputRef = useRef<Input>(null)
  const combinedResults = useMemo(() => {
    const documentVersions: ContentSearchResult[] = documentResults
      .filter((result) => {
        const hasLatestPublishedVersion = !!result.relations.version.latestPublishedDocumentVersionORM
        if (!hasLatestPublishedVersion) {
          logger.search.contentSearch.error('Possible invalid record found, no latest Published doc found', result)
        }

        return hasLatestPublishedVersion
      })
      .map((result) => ({
        id: result.relations.version.latestPublishedDocumentVersionORM!.model.id,
        title: result.relations.version.latestPublishedDocumentVersionORM!.model.title!,
        orm: result.relations.version.latestPublishedDocumentVersionORM!,
      }));
    if (variant === VariantOptions.HUBS) {
      // For HUBS, filter out documents that are not shareable, and not in the selectedIds
      const hubsSearchResult = [...documentVersions].filter((item) => {
        const docVer = item.orm as DocumentVersionORM
        // If the document is not shareable but the associated doc is shareable, MSLShare will be true
        // So we also need to check the fieldLevel that this docVer is also sharable
        return !selectedIds?.includes(item.id) &&
          docVer.meta.permissions.MSLShare &&
          docVer.meta.permissions.fieldLevel.share
      })
      return hubsSearchResult;
    }
    const customDecks: ContentSearchResult[] = customDeckResults.map((result) => ({
      id: result.model.id,
      title: result.model.title,
      orm: result,
    }))

    return [...documentVersions, ...customDecks].filter((item) => !selectedIds?.includes(item.id));
  }, [documentResults, customDeckResults]);

  const meeting = useAddMeeting();

  const renderSearchIcon = () => {
    return (
      <TouchableOpacity>
        <Icon style={styles.searchIcon} name="magnify" />
      </TouchableOpacity>
    )
  }

  const renderSearchInput = () => {
    return (
      <Input
        ref={inputRef}
        placeholder="Search for files"
        value={searchText}
        onChangeText={setSearchText}
        autoFocus={true}
        style={{ flex: 1 }}
        accessoryLeft={renderSearchIcon}
        testID="text-input"
        disabled={disable}
      />
    )
  }

  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  }
  const onBackdropPressHandler = () => {
    setResultsVisibility(false);
  }

  const handleItemPress = (content: ContentSearchResult) => {
    if (variant === VariantOptions.MEETING_HISTORY) {
      analytics?.track('MEETING_MANUAL_ADD_DOCUMENT', {
        action: 'MANUAL_ADD_DOCUMENT',
        category: 'MEETING',
        meetingId: meeting.meetingORM?.model.id,
        meetingType: meeting.meetingORM?.model.type,
        contentId: content.id,
        contentType: content.orm.type,
      });
    }
    onSelectItem(content);
    setSearchText('');
    if (variant === VariantOptions.MEETING_HISTORY) setIsVisible(false);
  }

  useEffect(() => {
    setResultsVisibility(searchText.length >= 3 && combinedResults.length > 0);
  }, [searchText])

  return {
    resultsVisibility,
    renderSearchInput,
    setSearchText,
    onBackdropPressHandler,
    combinedResults,
    toggleVisibility,
    handleItemPress,
    renderSearchIcon,
    searchText,
  }
}

const ContentSearchBar: React.FC<ContentSearchBarProps> = (props) => {
  const {
    resultsVisibility,
    renderSearchInput,
    onBackdropPressHandler,
    combinedResults,
    toggleVisibility,
    handleItemPress,
    searchText,
  } = useContentSearchBar(props);

  const { isVisible } = props;

  if (!isVisible) return null
  return (
    <DNABox alignY="center">
      <OverflowMenu
        style={styles.resultsWrapper}
        anchor={renderSearchInput}
        visible={resultsVisibility}
        placement="bottom start"
        fullWidth={true}
        onBackdropPress={onBackdropPressHandler}
      >
        {combinedResults.map((content) => {
          return (
            <MenuItem
              key={content.id}
              testID="dropdown-results"
              onPress={() => handleItemPress(content)}
              title={() =>
                isDocumentVersionORM(content.orm)
                  ? <DocumentVersionResult orm={content.orm} searchText={searchText} />
                  : <CustomDeckResult orm={content.orm} searchText={searchText} />
              }
            />
          )
        })}
      </OverflowMenu>
      <DNAButton
        appearance="outline"
        status="tertiary"
        size="sm"
        onPress={toggleVisibility}
        style={styles.cancelBtn}
      >
        Cancel
      </DNAButton>
    </DNABox>
  )
}

const ContentSearchBarTablet: React.FC<ContentSearchBarProps> = (props) => {
  const {
    resultsVisibility,
    onBackdropPressHandler,
    combinedResults,
    toggleVisibility,
    handleItemPress,
    renderSearchIcon,
    setSearchText,
    searchText,
  } = useContentSearchBar(props);

  const { isVisible, disable } = props;
  const inputRef = useRef<Input>(null);
  const [inputWidth, setInputWidth] = useState(0);

  const handleInputFocus = useCallback(() => {
    if (!inputRef.current) return;
    // eslint-disable-next-line dot-notation
    const textInputRef = inputRef.current?.['textInputRef']
    if (textInputRef) {
      textInputRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [inputRef]);

  const resultsContainerStyle = useMemo(() => {
    return { maxWidth: inputWidth + 51, maxHeight: 200 }
  }, [inputWidth])

  if (!isVisible) return null
  return (
    <DNABox alignY="center" appearance="col">
      <DNABox fill spacing="sm" childFill={0}>
        <DNAPopover
          visible={resultsVisibility}
          interactive
          placement="bottom-start"
          type="menu"
          onBackdropPress={onBackdropPressHandler}
        >
          <DNAPopover.Anchor style={{ flex: 1 }}>
            <Input
              ref={inputRef}
              placeholder="Search for files"
              value={searchText}
              onChangeText={setSearchText}
              autoFocus={true}
              onFocus={handleInputFocus}
              style={{ flex: 1 }}
              accessoryLeft={renderSearchIcon}
              testID="text-input"
              disabled={disable}
              onLayout={evt => setInputWidth(evt.nativeEvent.layout.width)}
            />
          </DNAPopover.Anchor>
          <DNAPopover.Content>
            <DNABox as={ScrollView} style={resultsContainerStyle} appearance="col">
              {resultsVisibility && combinedResults.map((content) => {
                return (
                  <MenuItem
                    key={content.id}
                    testID="dropdown-results"
                    onPress={() => handleItemPress(content)}
                    title={() =>
                      isDocumentVersionORM(content.orm)
                        ? <DocumentVersionResult orm={content.orm} searchText={searchText}/>
                        : <CustomDeckResult orm={content.orm} searchText={searchText}/>
                }
                  />
                )
              })}
            </DNABox>
          </DNAPopover.Content>
        </DNAPopover>
        <DNAButton
          appearance="outline"
          status="tertiary"
          size="sm"
          onPress={toggleVisibility}
          style={styles.cancelBtn}
        >
          Cancel
        </DNAButton>
      </DNABox>
    </DNABox>
  )
}

const ContentSearchBarSwitch: React.FC<ContentSearchBarProps> = (props) => {
  const { deviceMode } = useAppSettings();
  const isTablet = deviceMode === 'tablet'

  return isTablet ? <ContentSearchBarTablet {...props} /> : <ContentSearchBar {...props} />
}

export default ContentSearchBarSwitch
