import { PayloadGroup } from 'src/components/PresentationBuilder/state/PresentationBuilderStateProvider';
import { ReplaceGroupDraft, GroupDraftStatus }
  from 'src/components/PresentationBuilder/PresentationEditor/FindReplacement/FindReplacementProvider';

export function assignReplacementGroups(
  groups: PayloadGroup[],
  firstLoad?: boolean,
): ReplaceGroupDraft[] {
  let newReplacementGroups: ReplaceGroupDraft[] = [];

  if (firstLoad) {
    newReplacementGroups = groups.map((group): ReplaceGroupDraft => ({
      groupId: group.id,
      pages: group.pages,
      visible: group.visible,
      isGroup: group.pages.length > 1,
      // NOTE: IF A NEW STATUS IS ADDED TO THE GROUPSTATUSENUM, WE'LL NEED TO HANDLE IT IN GROUPDRAFTSTATUS
      status: GroupDraftStatus[group.groupStatus],
      documentVersionORM: group.documentVersionORM,
      name: group.name ?? 'Untitled Group',
      locked: group.locked ?? false,
    }));
  } else {
    // [TODO]: COMPARE WITH CURRENT STATE OF DRAFT GROUPS TO CHECK WHETHER A DOC RECEIVED AN MAJOR UPDATE, TO
    // UPDATE THE CURRENT DRAFT GROUPS STATE
  }
  return newReplacementGroups;
}
