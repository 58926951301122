import { CacheDBSchema } from '@alucio/core'
import { IDBPDatabase, openDB, deleteDB } from 'idb'
import * as logger from 'src/utils/logger'

// [TODO-PWA] - Enums don't work in worker files for some reason
// [TODO-PWA] - Test cases for upgrading IDB
const setupCacheDB = async () => {
  const createContentManifest = (db: IDBPDatabase<CacheDBSchema>) => {
    const store = db.createObjectStore(
      'CACHE_MANIFEST',
      { keyPath: 'id' },
    );
    store.createIndex('by-status', 'status');
  }
  const createCachePayload = (db: IDBPDatabase<CacheDBSchema>) => {
    const store = db.createObjectStore(
      'CACHE_PAYLOAD',
      { keyPath: 'path' },
    );
    store.createIndex('by-path', 'path');
  }
  const createDB = async () => {
    return await openDB<CacheDBSchema>(
      'CacheDB',
      2,
      {
        upgrade(db, oldVersion, newVersion) {
          if (oldVersion !== 0 && oldVersion !== newVersion) {
            // check if exists in the object store

            const idxObjects : ['CACHE_MANIFEST', 'CACHE_PAYLOAD']  = ['CACHE_MANIFEST', 'CACHE_PAYLOAD'];

            idxObjects.forEach((idx) => {
              if (db.objectStoreNames.contains(idx)) {
                db.deleteObjectStore(idx);
              }
            });
            logger.PWALogger.info('[PWA] - Upgrading CacheDB from version ' + oldVersion + ' to ' + newVersion);
          }

          logger.PWALogger.info('Upgrading CacheDB')
          createContentManifest(db);
          createCachePayload(db);
        },
      });
  }
  const db = await createDB()
  db.close();
  const existingTables = db.objectStoreNames
  if (!existingTables.contains('CACHE_PAYLOAD') || !existingTables.contains('CACHE_MANIFEST')) {
    logger.PWALogger.warn('Found missing table')
    await deleteDB('CacheDB', {
      blocked() {
        console.warn('DB Delete attempt was blocked by another connection')
      },
    })
    logger.PWALogger.info('Recreating DB')
    await createDB()
    logger.PWALogger.info('DB Created')
  }
}

export const validateStorageSpace = async (): Promise<void> => {
  logger.PWALogger.info('Checking StorageQuota');
  const shouldCheckStorageUsage = !window.location.pathname.startsWith('/logout') &&
    !window.location.href.includes('refresh') && !window.location.href.includes('quota-exceeded');

  if (shouldCheckStorageUsage) {
    const quotaLimitReached = await isQuotaLimitReached();
    if (quotaLimitReached) {
      logger.PWALogger.warn('QuotaExceeded Error')
      window.location.href = `${window.location.origin}/quota-exceeded`;
    } else {
      logger.PWALogger.info('Quota not exceeded');
    }
  }
};

export const isQuotaLimitReached = async (gap: number = ((20 * 1024 * 1024))): Promise<boolean> => {
  if (navigator?.storage?.estimate) {
    try {
      logger.PWALogger.info('Checking StorageQuota through Storage API');
      const { quota, usage } = await navigator.storage.estimate();
      if (quota && usage) {
        // TO THE ACTUAL FREE SPACE, WE'LL SUBTRACT 20MB TO HAVE IT AS A GAP //
        const freeSpace = quota - usage - (gap);
        if (freeSpace <= 0) {
          logger.PWALogger.warn('QuotaExceeded');
          return true;
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === 'NotSupportedError') {
          logger.PWALogger.warn(error.name);
          return await checkStorageThroughForceEntry(gap);
        } else {
          logger.PWALogger.warn(error.message);
        }
      }
    }
  } else {
    return await checkStorageThroughForceEntry(gap);
  }

  return false;
}

async function checkStorageThroughForceEntry(gap: number): Promise<boolean> {
  logger.PWALogger.info('Checking StorageQuota through forcing a new entry');
  // IF THE DEVICE DOESN'T SUPPORT THE STORAGE API, WE'LL CREATE A 20MB ENTRY IN A NEW INDEXDB DB/TABLE
  // TO FORCE A QUOTA EXCEEDED TO VERIFY IF THERE'S FREE SPACE
  try {
    await createAndDeleteTempDBToTestQuota(gap);
  } catch (error) {
    if (error instanceof Error) {
      if (error.name === 'QuotaExceededError') {
        logger.PWALogger.warn('QuotaExceeded');
        await deleteDB('TempDB');
        return true;
      } else {
        await deleteDB('TempDB');
        logger.PWALogger.warn(error.message);
      }
    }
  }
  return false;
}

async function createAndDeleteTempDBToTestQuota(stringSize: number): Promise<void> {
  const dbName = 'TempDB';
  const tableName = 'TempTable';
  const tableValue =  { value: 'A'.repeat(stringSize) };
  const dbVersion = 1;
  await deleteDB(dbName);
  const db = await openDB(dbName, dbVersion, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(tableName)) {
        db.createObjectStore(tableName, { keyPath: 'id', autoIncrement: true });
      }
    },
  });
  const transaction = db.transaction(tableName, 'readwrite');
  const table = transaction.objectStore(tableName);
  // IF THE STORAGE IS EXCEEDED, THIS SHOULD TRIGGER THE EXCEEDEDQUOTAERROR //
  await table.add(tableValue);
  await db.close();
  await deleteDB(dbName);
}

export default setupCacheDB
