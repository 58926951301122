import React, { PropsWithChildren, useState } from 'react'
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage'

interface FileDrawerContext {
  onClose: () => void
  localSearchText: string
  setLocalSearchText: React.Dispatch<React.SetStateAction<string>>,
  selectedTab: TabOptions
  setSelectedTab: React.Dispatch<React.SetStateAction<TabOptions>>
  searchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
}

interface FileDrawerContextProps {
  toggleDrawer: () => void
}

export enum TabOptions {
  BOOKMARKS = 'Bookmarks',
  LIBRARY = 'Library',
  SEARCH_RESULTS = 'Search results',
}

const FileDrawerContext = React.createContext<FileDrawerContext>(null!)

export const FileDrawerProvider: React.FC<PropsWithChildren<FileDrawerContextProps>> = ({
  toggleDrawer,
  children,
}) => {
  const route = useCurrentPage({ exact: false })
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const [
    selectedTab,
    setSelectedTab,
  ] = useState<TabOptions>(isPublisherRoute ? TabOptions.LIBRARY : TabOptions.BOOKMARKS)
  const [localSearchText, setLocalSearchText] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('')

  const onClose = () => {
    toggleDrawer()
  }

  const context:FileDrawerContext = {
    onClose,
    localSearchText,
    selectedTab,
    setSelectedTab,
    setLocalSearchText,
    searchText,
    setSearchText,
  }

  return (
    <FileDrawerContext.Provider value={context}>
      {children}
    </FileDrawerContext.Provider>
  )
}

export const useFileDrawerContext = () => React.useContext(FileDrawerContext)
