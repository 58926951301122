import React from 'react'
import { DocumentVersionORM, FolderItemORM } from 'src/types/types'
import { DNACardComponent } from 'src/components/DNA/Grid/Cards/types'

import {
  DNABox,
  DNAText,
  DNACard,
  useTheme,
  Iffy,
  DNAChip,
} from '@alucio/lux-ui'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector'

export const DNADocumentFolderItemCard: DNACardComponent<FolderItemORM> = (props) => {
  const { ORM: folderItemORM, onPress } = props
  /** TODO: Refactor improper use of as assertion */
  const documentVersionORM = folderItemORM.relations.itemORM as DocumentVersionORM
  const { visiblePages } = folderItemORM.model
  const parentDocumentORM = documentVersionORM.relations.documentORM
  const isModified =
    visiblePages?.length &&
    documentVersionORM.meta.permissions.MSLSelectSlides &&
    documentVersionORM.model.status === 'PUBLISHED'

  const theme = useTheme()
  const canPresent = documentVersionORM.relations.documentORM.meta.permissions.MSLPresent
  const title = folderItemORM?.model.customTitle || documentVersionORM.model.title

  // [TODO-2126] - This thumbnail key should be calculated at the ORM level
  let customThumbnailKey: string | undefined
  const selectedThumbnail = documentVersionORM.model.selectedThumbnail ?? 1
  if (folderItemORM.model.visiblePages && !folderItemORM.model.visiblePages.find(vp => vp === selectedThumbnail)) {
    const customPage = documentVersionORM.meta.allPages[folderItemORM.model.visiblePages[0] - 1]
    customThumbnailKey = detectArchivedFileKeyPath(documentVersionORM.model, customPage)
  }
  return (
    <DNABox
      alignX="start"
      style={[{ minWidth: 232, minHeight: 212 }, !canPresent && { opacity: 0.4 }]}
    >
      <DNACard
        interactive="pressable"
        onPress={() => { onPress?.(folderItemORM) }}
        style={{
          padding: 8,
          backgroundColor: theme['color-dark-300'],
        }}
      >
        <DNABox appearance="col" spacing="sm">
          <DNABox style={{ backgroundColor: 'rgb(0, 0, 0)' }}>
            <DNADocumentThumbnail
              documentVersionORM={documentVersionORM}
              width={216}
              height={124}
              customThumbnailKey={customThumbnailKey}
              // Assessing implementation in #BEAC-2155
              // onPress={() => { console.log('pressed fi card') }}
            />
          </DNABox>
          <DNABox appearance="col" spacing="sm">
            <DNAText
              status="basic"
              b2
              style={{ width: 216, overflow: 'hidden' }}
              numberOfLines={2}
            >
              {title}
            </DNAText>
            <DNABox spacing="sm" appearance="row">
              <DNADocumentChip item={parentDocumentORM} variant="purpose" />
              <Iffy is={isModified}>
                <DNAChip
                  appearance="tag"
                  style={{ backgroundColor: 'rgba(255,255,255,0.16)' }}
                >
                  MODIFIED
                </DNAChip>
              </Iffy>

            </DNABox>
          </DNABox>
        </DNABox>
      </DNACard>
    </DNABox>
  );
}

export default DNADocumentFolderItemCard
