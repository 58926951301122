import React from 'react'
import { useDispatch } from 'src/state/redux'
import { GenericToast, ToastOrientations, useToast } from '@alucio/lux-ui';
import { meetingActions } from 'src/state/redux/slice/meeting';
import DNACommonConfirmation from './DNACommonConfirmation';
import { useUserTenant } from 'src/state/redux/selector/user';
import { CRMHandler } from 'src/classes/CRM/CRMHandler';
import { MeetingORM } from 'src/types/orms';
import { useRefreshToken } from 'src/screens/Profile/CRMIntegration';
import { RETRY_MESSAGES } from 'src/state/context/Meetings/saveMeetingHelper';
import * as logger from 'src/utils/logger';

interface DeleteConfirmationProps {
  meeting: MeetingORM,
  onDelete?: () => void,
}

const useDeleteMeetingInCRM = () => {
  const tenant = useUserTenant();
  const refreshToken = useRefreshToken();

  if (!tenant?.config.crmIntegration) {
    return () => null;
  }
  const crmHandlerInstance = CRMHandler(tenant.config.crmIntegration);

  async function handleDeleteMeeting(meetingORM: MeetingORM): Promise<void> {
    try {
      await crmHandlerInstance.Syncer().handleDeleteMeeting(meetingORM);
    } catch (e) {
      logger.deleteMeetingHandler.error('An error occurred while deleting the meeting in CRM', e);
      if (e instanceof ErrorEvent &&
        (RETRY_MESSAGES.includes(e.error) ||
          RETRY_MESSAGES.includes(e.error?.[0]))
      ) {
        await refreshToken();
        return handleDeleteMeeting(meetingORM);
      }
    }
  }

  return handleDeleteMeeting;
}

const DNAMeetingDeleteModal = (props: DeleteConfirmationProps) => {
  const { meeting, onDelete } = props
  const dispatch = useDispatch()
  const toast = useToast();
  const deleteMeetingInCRM = useDeleteMeetingInCRM();

  const onConfirm = async () => {
    try {
      await deleteMeetingInCRM(meeting);
      dispatch(meetingActions.deleteMeeting(meeting.model))
      toast?.add(
        <GenericToast title="Meeting deleted" status="success" />,
        ToastOrientations.TOP_RIGHT,
      )
      onDelete?.()
    } catch (e) {
      let errorMessage = 'An error occurred';

      if (e instanceof ErrorEvent) {
        errorMessage += `: ${e.error}`;
      }

      toast?.add(
        <GenericToast title={errorMessage} status="error" />,
        ToastOrientations.TOP_RIGHT,
      )
    }
  }

  return (<DNACommonConfirmation
    status="danger"
    cancelText="Cancel"
    confirmActionText="Delete"
    onConfirmAction={onConfirm}
    title="Delete meeting?"
    descriptionText={meeting.model.crmRecord ? 'This meeting will also be deleted in your CRM system.' : undefined}
  />)
}

export default DNAMeetingDeleteModal
