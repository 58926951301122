import React from 'react';
import { DNABox, DNAText } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import PresentedIcon from 'src/assets/images/PresentedIcon';

const EmptyStateScreen: React.FC = () => {
  return (
    <DNABox
      fill
      appearance="col"
      alignX="center"
      alignY="center"
      spacing="md"
      style={{ backgroundColor: colors['color-gray-50'] }}
    >
      <DNAText status="flatAlt" bold>No presented slides</DNAText>
      <DNAText status="flatAlt" style={{ width: 520, textAlign: 'center' }}>
        Navigate to “All Slides” from the dropdown in the top header and add
        presented slides by selecting the eyeball icon in the bottom navigation bar.
      </DNAText>
      <DNABox appearance="col" spacing="xs">
        <PresentedIcon />
      </DNABox>
    </DNABox>
  )
}
export default EmptyStateScreen
