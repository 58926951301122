import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNACheckbox, DNADivider, DNAText, Iffy, luxColors } from '@alucio/lux-ui';
import { usePresentationSelector } from './PresentationSelectorStateProvider';

const styles = StyleSheet.create({
  lowerActionWrapper: {
    maxHeight: 56,
    paddingHorizontal: 19,
    borderColor: luxColors.disabled.secondary,
    borderTopWidth: 1,
  },
});

const LowerActionBar: React.FC = () => {
  const {
    allPagesSelected,
    handleSelectAllToggle,
    pages,
    handleGroupAdd,
    selectedDocumentVersionORM,
  } = usePresentationSelector()

  const modifiable = !!selectedDocumentVersionORM?.meta.permissions.MSLSelectSlides
  const selectionCount = pages.filter(p => p.checked).length;

  return (
    <DNABox fill style={styles.lowerActionWrapper} alignY="center">
      {/* 'Select all' check box */}
      <Iffy is={modifiable}>
        <Iffy is={pages.length <= 50}>
          <DNACheckbox
            checked={allPagesSelected}
            onChange={handleSelectAllToggle}
            style={{ marginRight: 7 }}
          />
          <DNAText>Select all</DNAText>

          <DNADivider
            style={{ width: 1, height: 20, marginHorizontal: 12, backgroundColor: luxColors.headerTopColor.primary }}
          />
        </Iffy>
        <DNAText>{`${selectionCount} of ${pages.length} Selected`}</DNAText>
      </Iffy>
      <DNABox fill alignX="end">
        <DNAButton
          appearance="filled"
          onPress={() => handleGroupAdd()}
          testID="add-to-presentation-btn"
        >
          Add to presentation
        </DNAButton>
      </DNABox>
    </DNABox>
  )
}

export default LowerActionBar
