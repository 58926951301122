import { ContentPresented } from '@alucio/aws-beacon-amplify/src/models';
import { LoadedPresentation } from '../ContentProvider/ContentProvider';
import { isDocumentVersionORM, isFolderItemORM, isPageGroupORM } from 'src/types/typeguards';

interface ContentIndex {
  presentedContentIndex?: number,
  presentedMetaIndex?: number,
}

/*
   GIVEN A "CONTENTPRESENTED" DOESN'T HAVE AN ID, AND THE SAME CONTENT_ID CAN BE USED IN DIFFERENT
   CONTEXTS (SAME DOC FROM LIBRARY, FOLDER, GROUP OF THAT DOC, GROUP FROM FOLDER), IT GETS REALLY
   CUMBERSOME TO DETECT WHICH OBJECT OF THE ARRAY ITS REQUIRED TO BE USED. THEREFORE, THIS FUNCTION
   AIMS TO FIND A SPECIFIC CONTENTPRESENTED OBJECT, AND ITS META, BY CREATING AND COMPARING A TEMPORARY
   UNIQUE ID BY A CONCATENATION OF THE IDS WITHIN THE OBJECT (contentId + folderItemId + groupId)
 */
export function getContentPresentedIndexByActivePresentation(
  contentPresented: ContentPresented[],
  activePresentation: LoadedPresentation,
): ContentIndex {
  let activePresentationUniqueId = '';
  const presentableORM = activePresentation.presentable.orm;

  if (isDocumentVersionORM(presentableORM )) {
    activePresentationUniqueId += presentableORM.model.id;
  } else if (isPageGroupORM(presentableORM )) {
    activePresentationUniqueId += presentableORM.relations.documentVersionORM.model.id +
    presentableORM.model.id;
  } else if (isFolderItemORM(presentableORM)) {
    activePresentationUniqueId +=
      presentableORM.relations.itemORM.model.id +
      presentableORM.model.id;
  }

  return getContentAndMetaIndex(
    contentPresented,
    activePresentationUniqueId,
    activePresentation?.currentPresentablePage.page.pageId,
  );
}

/*
   RECEIVES A CONTENT PRESENTED AND RETURNS ITS INDEX ON THE GIVEN ARRAY
 */
export function getContentPresentedIndexByContent(
  contentPresented: ContentPresented[],
  contentToFindIndex: ContentPresented,
): number {
  const mainContentUniqueId = getUniqueId(contentToFindIndex);

  return getContentAndMetaIndex(
    contentPresented,
    mainContentUniqueId,
  ).presentedContentIndex ?? -1;
}

/*
   RECEIVES AN UNIQUE ID AND RETURNS ITS CONTENTPRESENTED
 */
export function getContentAndMetaIndex(
  contentPresented: ContentPresented[],
  customUniqueIdToCompare: string,
  pageId?: string,
): ContentIndex {
  return contentPresented.reduce<ContentIndex>((acc, content, idx) => {
    const contentUniqueId = getUniqueId(content);

    if (contentUniqueId === customUniqueIdToCompare) {
      acc.presentedContentIndex = idx;

      content.presentedMeta.forEach((meta, idx) => {
        if (meta.pageId === pageId) {
          acc.presentedMetaIndex = idx;
        }
      });
    }

    return acc;
  }, {
    presentedContentIndex: undefined,
    presentedMetaIndex: undefined,
  });
}

/*
   WHEN SOME CONTENT IS UPDATED AND SAVED IN THE CPM, WE NEED TO UPDATE IT
   ON THE ADD MEETING DRAWER TO REFLECT THOSE CHANGES BY REPLACING THE CONTENT
   FROM THE STATE WITH THE ONE IN THE ORM (UPDATED)
 */
export function replaceContentPresented(
  contentUpdated: ContentPresented[], // FROM MEETING MODEL (UPDATED IN THE CPM)
  contentToReplace: ContentPresented[], // FROM STATE IN DRAWER
): ContentPresented[] {
  return contentToReplace.map((content) => {
    const mainContentId = getUniqueId(content);
    return contentUpdated.find((updated) =>
      mainContentId === getUniqueId(updated),
    ) || content;
  });
}

function getUniqueId(content: ContentPresented): string {
  return content.contentId +
    (content.folderItemId || '') +
    (content.groupId || '');
}
