import React from 'react'
import { DocumentORM } from 'src/types/types'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'

import { DNABox, DNACard, DNAText, DNAIcon, Iffy, useTheme } from '@alucio/lux-ui'
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'

const DNADocumentVersionVirtualRow: DNARowsComponent<DocumentORM> = (props) => {
  const { ORM: documentORM, onPress, style } = props
  const theme = useTheme()

  const containerStyle = [
    {
      paddingLeft: 12,
      paddingRight: 20,
      paddingVertical: 12,
      backgroundColor: theme['color-dark-300'],
      marginVertical: 1,
      borderRadius: 4,
    },
    style,
  ]

  const canPresent = documentORM.meta.permissions.MSLPresent;

  return (
    <DNACard
      appearance="flat"
      interactive="pressable"
      onPress={() => { onPress?.(documentORM) }}
      style={containerStyle}
    >
      <DNABox alignY="center" style={!canPresent && { opacity: 0.4 }} spacing="between" childFill={0}>
        <DNABox spacing="between" alignY="center" childFill={0}>
          <DNABox spacing="lg" alignY="center" childFill={1}>
            <DNABox style={{ backgroundColor: 'rgb(0, 0, 0)' }}>
              <DNADocumentThumbnail
                documentVersionORM={documentORM.relations.version.latestUsableDocumentVersionORM}
                width={126}
                height={72}
              />
            </DNABox>
            <DNABox spacing="sm" fill childFill={0}>
              <DNABox spacing="sm" appearance="col" fill>
                <DNAText b2 status="basic" numberOfLines={1} testID="my-content-panel-search-result-titile">
                  {documentORM.relations.version.latestUsableDocumentVersionORM.model.title}
                </DNAText>
                <DNABox spacing="sm">
                  <DNADocumentChip
                    item={documentORM}
                    variant="purpose"
                  />
                </DNABox>
              </DNABox>

              <Iffy is={documentORM.meta.bookmark.isBookmarked}>
                <DNAIcon.Styled
                  status="tertiary"
                  appearance="ghost"
                  name="bookmark"
                  style={{ minWidth: 30 }}
                />
              </Iffy>
            </DNABox>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNACard>
  )
}

export default DNADocumentVersionVirtualRow
