import { useState, useMemo, useEffect } from 'react';
import { findDOMNode } from 'react-dom';

const isElement = (element) : element is Element => {
  return element instanceof Element
}

export const useIsSlideRollVisible = () => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting)
      },
      ),
    [],
  );

  useEffect(
    () => {
      return () => {
        observer.disconnect();
      }
    },
    [],
  )

  const setObserverRef = (ref) => {
    if (!ref) {
      observer.disconnect();
      return;
    }

    if (ref) {
      const element = findDOMNode(ref);
      isElement(element) && observer.observe(element)
    }
  }

  return { isIntersecting, setObserverRef };
}
