import { PayloadAction } from '@reduxjs/toolkit';
import createSlice from '../util/createImmerlessSlice';
import returnFreshState from '../util/returnFreshState';

import { DocumentORM, FolderORM } from 'src/types/orms';

export interface InfoPanelSliceType {
    activeItemORM?: FolderORM | DocumentORM
}

const initialState: InfoPanelSliceType = {}

const infoPanelSlice = createSlice({
  name: 'InfoPanelState',
  initialState: initialState,
  reducers: {
    setActiveItem: (state, { payload }: PayloadAction<InfoPanelSliceType['activeItemORM']>) => {
      const newState = { ...state }
      newState.activeItemORM = payload

      return returnFreshState(
        state,
        newState,
        { shallowCheck: { activeItemORM: true } },
      )
    },
  },
})

export default infoPanelSlice
export const infoPanelActions = {
  ...infoPanelSlice.actions,
}
