import React from 'react';
import { ScrollView } from 'react-native';
import { DNABox, DNAButton, DNASlider } from '@alucio/lux-ui';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as slideSettingsSelector from 'src/state/machines/publisherVersioning/SlideSettings/slideSettings.selectors';
import { useSlideSettingsState } from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider';
import { getThumbURL } from 'src/utils/thumbnailHelpers';
import ModalHeader from '../ModalHeader';
import { styles } from '../SlideSettings';

const SetCoverThumbnailModal: React.FC = () => {
  const {
    service,
    currentDocumentVersionORM,
    thumbnailSizes,
    backToIdle,
    saveCoverThumbnail,
    selectCoverThumbnail,
  } = useSlideSettingsState()

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      slideSettingsSelector.versionDraft,
      slideSettingsSelector.selectedCoverThumbnail,
      slideSettingsSelector.isSetCoverThumbnailMode,
      slideSettingsSelector.canSaveCoverThumbnail,
    ),
  )

  return (
    <DNASlider
      visible={cond.isSetCoverThumbnailMode}
      setVisible={backToIdle}
      orientation="horizontal"
    >
      <DNABox
        fill
        appearance="col"
        style={{ backgroundColor: colors['color-text-white'] }}
      >
        <ModalHeader
          title="Select cover thumbnail"
          onSave={saveCoverThumbnail}
          onCancel={backToIdle}
          saveDisabled={!cond.canSaveCoverThumbnail}
        />

        {/* CONTENT */}
        <DNABox fill>
          <DNABox fill appearance="col">
            <DNABox style={styles.rowWrapper} >
              <DNABox fill alignX="end">
                <DNAButton
                  appearance="ghostLink"
                  status="tertiary"
                  size="md"
                  padding="none"
                  onPress={thumbnailSizes.cycleThumbnailSize}
                  iconLeft={thumbnailSizes.thumbnailSize === 'lg' ? 'view-comfy' : 'view-grid'}
                />
              </DNABox>
            </DNABox>
            <DNABox fill>
              <ScrollView style={styles.content}>
                <DNABox
                  appearance="row"
                  wrap="start"
                  spacing="lg"
                  childStyle={{ marginBottom: 32 }}
                >
                  {
                    cond.versionDraft.pages?.map((page) => {
                      const coverThumbnailPageNumber = cond.selectedCoverThumbnail ?? 1
                      const thumbURL = getThumbURL(currentDocumentVersionORM, page.number)
                      const isCoverThumbnail = page.number === coverThumbnailPageNumber

                      return (
                        <DNAThumbnail
                          key={thumbURL}
                          s3URL={thumbURL}
                          useLoadingIndicator
                          size={thumbnailSizes.thumbnailSize}
                          mode={DNAThumbnail.Modes.SELECTABLE}
                          variant={DNAThumbnail.Variants.INFO}
                          pageNumber={page.number}
                          checked={isCoverThumbnail}
                          isCover={isCoverThumbnail}
                          isRequired={page.isRequired}
                          onCheck={() => selectCoverThumbnail(page.number)}
                        />
                      )
                    })
                  }
                </DNABox>
              </ScrollView>
            </DNABox>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNASlider>
  )
}

export default SetCoverThumbnailModal
