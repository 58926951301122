import React from 'react';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { StyleSheet } from 'react-native';
import { DocumentVersionORM } from 'src/types/orms';
import { PageExtended } from 'src/types/types';
import ContentPreviewModalVariant from './Variants/ContentPreviewModal.variant';
import DefaultVariant from './Variants/Default.variant';
import { ContentType, useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { useContent as useContentProxy } from 'src/state/context/ContentProvider/ContentProvider.proxy';

enum UseContentHookVariantOptions {
  default,
  proxied,
}
type UseContentHookVariantOption = keyof typeof UseContentHookVariantOptions
type UseContentHookVariants = Record<UseContentHookVariantOption, () => ContentType>

export const useContentHookVariants: UseContentHookVariants = {
  default: useContent,
  proxied: useContentProxy,
}

enum VariantOptions {
  default,
  /** TODO: remove this variant and associated file once the old contentpreviewmodal code has been removed */
  contentPreviewModal,
}
type VariantOption = keyof typeof VariantOptions

export interface SharedVariantProps {
  /** TODO: remove these two props once the old contentpreviewmodal code has been removed */
  documentVersionORM?: DocumentVersionORM
  visiblePages?: PageExtended[],

  onClose?: () => void,
}

export interface TextSearchPanelProps extends SharedVariantProps{
  variant?: VariantOption,
  contentHookVariant?: UseContentHookVariantOption
}

type Variants = Record<VariantOption, React.ElementType>

const variants:Variants = {
  default: DefaultVariant,
  /** TODO: remove this variant and associated file once the old contentpreviewmodal code has been removed */
  contentPreviewModal: ContentPreviewModalVariant,
}

export const styles = StyleSheet.create({
  searchBar: {
    backgroundColor: colors['color-text-white'],
    borderColor: colors['color-gray-100'],
    borderRadius: 4,
    width: 252,
    overflow: 'hidden',
  },
  subtitle: {
    color: colors['color-gray-300'],
  },
  menuButtonContainer: {
    borderColor: colors['color-gray-100'],
    borderRadius: 4,
    borderWidth: 1,
    marginRight: 16,
  },
  searchIcon: {
    color: colors['color-gray-300'],
    height: 16,
    width: 16,
  },
  thumbnail: {
    marginLeft: 16,
    marginRight: 8,
    marginVertical: 8,
    height: 54,
    width: 96,
  },
  mainContainer: {
    borderColor: colors['color-gray-100'],
    borderRightWidth: 1,
    width: 332,
    backgroundColor: colors['color-text-white'],
  },
})

const TextSearchPanel:React.FC<TextSearchPanelProps> = (props) => {
  const { variant = 'default' } = props
  const CurrentVariant = variants[variant]

  return <CurrentVariant {...props} />
}

export default TextSearchPanel
