import React, { useImperativeHandle, useEffect, useState } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { useForm, Controller } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { DNABox, DNACheckbox, DNAText, Icon, InformationMessage, Iffy, luxColors } from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as publisherVersioningSelector from 'src/state/machines/publisherVersioning/publisherVersioning.selectors';
import { DocumentVersionChangeType, NotificationScope } from '@alucio/aws-beacon-amplify/src/models';
import { DocumentVersionORM } from 'src/types/types';
import VersionChangesPrompt from 'src/screens/Publishers/Versioning/VersionChangesPrompt';
import InputComponent from 'src/components/Publishers/InputComponent';
import { usePublisherVersioningState }
  from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';

import { S } from 'src/screens/Publishers/Versioning/VersioningPanel/VersioningPanel'
import { styles } from 'src/components/Publishers/Styles';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import DocumentReleaseNotes from './DocumentReleaseNotes';

const componentStyles = StyleSheet.create({
  releaseNotes: {
    minHeight: 261,
  },
});

const formSchema = z.object({
  notificationScope: z.nativeEnum(NotificationScope),
  changeType: z.nativeEnum(DocumentVersionChangeType),
  releaseNotes: z.string().nonempty({ message: 'This field is required' }),
})

type PublishFormSchema = z.infer<typeof formSchema>

export type DocumentPublishFormRef = {
  handleSaveDraftForDocumentPublish: () => PublishFormSchema
  handlePublishDocumentInfo: () => Promise<PublishFormSchema | null>
}

// [TODO-2126] - Extract this into a generic type since React doesn't
const useDocumentPublishForm = (ref:
  | ((instance: DocumentPublishFormRef | null) => void)
  | React.MutableRefObject<DocumentPublishFormRef | null>
  | null,
) => {
  const {
    service,
    currentDocumentVersionORM,
  } = usePublisherVersioningState();
  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.documentPublishIsDirty,
      publisherVersioningSelector.changesCheckResult,
    ),
  )

  const computeInitialValues = (docVerORM: DocumentVersionORM): Partial<PublishFormSchema> => {
    const { releaseNotes, changeType, notificationScope } = docVerORM.model
    return {
      releaseNotes,
      changeType: changeType as DocumentVersionChangeType ?? DocumentVersionChangeType.MINOR,
      notificationScope: notificationScope as NotificationScope ?? NotificationScope.NONE,
    }
  }

  const rhForm = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: computeInitialValues(currentDocumentVersionORM),
  })
  const { formState: { isDirty } } = rhForm

  useEffect(() => {
    service.onEvent((event) => {
      if (event.type === 'SAVE_DRAFT' && isDirty && !cond.documentPublishIsDirty) {
        rhForm.reset(rhForm.getValues(), { keepDirty: false });
      }
    });
  }, [service, isDirty, rhForm, cond.documentPublishIsDirty]);

  useEffect(() => {
    if (
      (!isDirty && cond.documentPublishIsDirty) ||
      (isDirty && !cond.documentPublishIsDirty)
    ) {
      service.send({ type: 'SET_IS_DIRTY', payload: { type: 'publish', isDirty } })
    }
  }, [service.send, isDirty, cond.documentPublishIsDirty])

  // [NOTE] - Sync changes form state machine to form
  useEffect(() => {
    if (cond.changesCheckResult?.detectedChangeType) {
      rhForm.setValue('changeType', cond.changesCheckResult.detectedChangeType)
    }
  }, [cond.changesCheckResult?.detectedChangeType])

  // [NOTE] - Update form values on version history selection
  //          This is for the temporary read only mode
  useEffect(() => {
    if (!currentDocumentVersionORM) return;
    const newForm = computeInitialValues(currentDocumentVersionORM)
    rhForm.reset(newForm)
  }, [currentDocumentVersionORM?.model.id])

  const handleSaveDraftForDocumentPublish = (): PublishFormSchema => {
    const formValues = rhForm.getValues() as PublishFormSchema
    return formValues
  }

  const handlePublishDocumentInfo = async (): Promise<PublishFormSchema | null> => {
    const isValid = await rhForm.trigger()
    return isValid
      ? rhForm.getValues() as PublishFormSchema
      : null
  }

  useImperativeHandle(
    ref,
    () => ({
      handleSaveDraftForDocumentPublish,
      handlePublishDocumentInfo,
    }),
  )

  return rhForm
}

const DocumentPublish: React.FC = () => {
  const {
    service,
    documentORM,
    documentPublishRef,
  } = usePublisherVersioningState();
  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.changesCheckResult,
    ),
  )

  const { latestDocumentVersionORM } = documentORM.relations.version
  const detectedChangeType = cond.changesCheckResult?.detectedChangeType ?? DocumentVersionChangeType.MINOR
  const disableMinorChangeType = cond.changesCheckResult?.disableMinor ?? false
  const featureFlags = useFeatureFlags('enableEmailNotification', 'BEAC_2777_immediate_publish_emails');

  const { control, formState: { errors }, getValues } = useDocumentPublishForm(documentPublishRef);
  const { changeType, notificationScope } = getValues();

  const errorMessages = Object
    .values(errors)
    .map(error => error?.type === 'too_small' ? 'Missing required fields' : error?.message)
    .join('\n')

  const [showNote, setShowNote] = useState(false);

  useEffect(() => {
    setShowNote(changeType === DocumentVersionChangeType.MAJOR &&
    notificationScope !== NotificationScope.EMAIL);
  }, [cond.changesCheckResult, changeType, notificationScope])

  return (
    <ScrollView style={S.tabContentContainer}>
      <DNABox
        fill
        appearance="col"
        spacing="lg"
      >

        {/* FORM ERRORS */}
        <Iffy is={errorMessages}>
          <InformationMessage
            testID="publish-tab-error"
            text={errorMessages}
            variance="danger"
          />
        </Iffy>
        <Iffy is={featureFlags.enableEmailNotification && showNote}>
          <InformationMessage
            text={'Note: users with personalized files containing ' +
            'this document will still receive an email notification.'}
            variance="primary"
          />
        </Iffy>
        <Iffy is={featureFlags.enableEmailNotification}>

          { /* NOTIFICATION SCOPE */ }
          <Controller
            control={control}
            name="notificationScope"
            defaultValue={NotificationScope.NONE}
            rules={{ required: true }}
            render={({ field : { onChange, value } }) => {
              return (
                <DNABox
                  fill
                  spacing="sm"
                  appearance="col"
                >
                  {/* PIPELINE EMAIL */}
                  <DNABox
                    childFill={1}
                    spacing="sm"
                    testID="notify-users-email-checkbox"
                  >

                    <DNACheckbox
                      testID={value ? 'checkbox-checked' : 'checkbox-unchecked'}
                      checked={value === NotificationScope.EMAIL}
                      onChange={e => {
                        onChange(e ? NotificationScope.EMAIL : NotificationScope.NONE)
                        setShowNote(!e && changeType === DocumentVersionChangeType.MAJOR);
                      }}
                    />

                    <DNABox style={styles.TitleContainer}>
                      <DNAText
                        bold
                        numberOfLines={3}
                        ellipsizeMode="tail"
                      >
                        Notify users who have saved this file of new version
                      </DNAText>
                      <DNAPopover >
                        <DNAPopover.Anchor>
                          <Icon style={styles.HelpToolTipIconStyle} name="help-circle-outline" />
                        </DNAPopover.Anchor>
                        <DNAPopover.Content>
                          <DNAText style={{ color: colors['color-text-basic'] }}>
                            Users who have saved this file in their folders will
                            receive an email notification at the end of the day.
                          </DNAText>
                        </DNAPopover.Content>
                      </DNAPopover>
                    </DNABox>
                  </DNABox>
                  {/* IMMEDIATELY EMAIL */}
                  {featureFlags.BEAC_2777_immediate_publish_emails && <DNABox
                    childFill={1}
                    spacing="sm"
                    testID="notify-users-email-immediately-checkbox"
                  >

                    <DNACheckbox
                      testID={value ? 'checkbox-immediately-checked' : 'checkbox-immediately-unchecked'}
                      checked={value === NotificationScope.EMAIL_IMMEDIATE}
                      onChange={e => {
                        onChange(e ? NotificationScope.EMAIL_IMMEDIATE : NotificationScope.NONE)
                        setShowNote(!e && changeType === DocumentVersionChangeType.MAJOR);
                      }}
                    />
                    <DNABox style={styles.TitleContainer}>
                      <DNAText
                        bold
                        numberOfLines={3}
                        ellipsizeMode="tail"
                      >
                        Notify all users of new version immediately
                      </DNAText>
                      <DNAPopover >
                        <DNAPopover.Anchor>
                          <Icon style={styles.HelpToolTipIconStyle} name="help-circle-outline" />
                        </DNAPopover.Anchor>
                        <DNAPopover.Content>
                          <DNAText style={{ color: colors['color-text-basic'] }}>
                            All users will immediately receive an email notification.
                          </DNAText>
                        </DNAPopover.Content>
                      </DNAPopover>
                    </DNABox>
                  </DNABox>}
                </DNABox>
              )
            }}
          />
        </Iffy>
        {/* SEMVER CHANGE TYPE */}
        <Iffy is={documentORM.relations.version.latestPublishedDocumentVersionORM != null}>
          <Controller
            name="changeType"
            control={control}
            rules={{ required: true }}
            defaultValue={detectedChangeType}
            render={({ field: { value, onChange } }) => (
              <VersionChangesPrompt
                changeType={value}
                disableMinor={disableMinorChangeType}
                detectedChangeType={detectedChangeType}
                setChangeType={(e) => {
                  onChange(e);
                  setShowNote(e === DocumentVersionChangeType.MAJOR &&
                  notificationScope !== NotificationScope.EMAIL);
                }}
              />
            )}
          />
        </Iffy>

        {/* RELEASE NOTES */}
        <DNABox appearance="col" spacing="sm">
          <Controller
            rules={{ required: true }}
            name="releaseNotes"
            control={control}
            render={({ field }) =>
              (<InputComponent
                characterLimit={1000}
                inputStyle={componentStyles.releaseNotes}
                multiline={true}
                placeHolder="Enter release notes…"
                removeMarginPadding={true}
                showCharacterCounter={true}
                testID="release-notes-container"
                title="RELEASE NOTES"
                titleColor={luxColors.contentPanelBackground.secondary}
                defaultValue={latestDocumentVersionORM.model.releaseNotes || ''}
                {...field}
              />)
            }
          />
          <Iffy is={errors.releaseNotes}>
            <DNAText status="danger">
              This field is required
            </DNAText>
          </Iffy>
        </DNABox>
      </DNABox>
    </ScrollView>
  )
}

const DocumentPublishWrapper: React.FC = () => {
  const { service } = usePublisherVersioningState();
  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.isInDraftingState,
      publisherVersioningSelector.isPublishing,
    ),
  )
  if (!cond.isInDraftingState || cond.isPublishing) return <DocumentReleaseNotes />
  else return <DocumentPublish />
}

export default DocumentPublishWrapper
