import { ObjectRestriction } from 'src/components/CustomFields/ObjectField';
import { TABLES } from 'src/classes/CRM/Translators/VeevaTranslatorUtils';
import { DEFAULT_RESTRICTION, ObjectCheckerProps } from './useObjectRestrictionChecker';
import { CRMIntegrationSession, CRMUtil } from 'src/state/machines/CRM/util';
import { isMeetingORM } from 'src/types/typeguards';
import { MeetingORM } from 'src/types/orms';

const SUPPORTED_VEEVA_OBJECTS_TO_CHECK = {
  [TABLES.TASK]: checkTask,
};

// DETERMINES WHETHER OR NOT AN OBJECT SHOULD BE DISABLED
function veevaObjectChecker(props: ObjectCheckerProps): ObjectRestriction {
  if (isMeetingORM(props.referenceObject)) {
    return SUPPORTED_VEEVA_OBJECTS_TO_CHECK[props.field.id]?.({
      ...props,
      referenceObject: props.referenceObject as MeetingORM,
    }) || DEFAULT_RESTRICTION;
  }

  return DEFAULT_RESTRICTION;
}

interface VeevaObjectCheckerProps extends ObjectCheckerProps {
  referenceObject: MeetingORM
}

function checkTask(props: VeevaObjectCheckerProps): ObjectRestriction {
  const { value: task, referenceObject } = props;
  const CRMSettings: CRMIntegrationSession['authInformation'] | undefined = new CRMUtil().getAuthInformation();
  const referenceTask = referenceObject.model.crmRecord?.crmCustomValues.find(({ fieldId }) => fieldId === 'Task')
    ?.objectRecords?.find(({ id }) => id === task.id);
  const taskOwnerId = referenceTask?.values.find(({ fieldId }) => fieldId === 'Task_OwnerId')?.values;

  // A TASK CAN'T BE UPDATED IF THE OWNER IS DIFFERENT THAN THE CRM LOGGED USER
  if (task.externalId && referenceTask && !taskOwnerId?.includes(CRMSettings?.userInfo.id || '')) {
    return {
      delete: true,
      update: true,
    };
  }

  return DEFAULT_RESTRICTION;
}

export default veevaObjectChecker;
