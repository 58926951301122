import { AccessTokenDataExtended } from 'src/state/redux/slice/authToken';
import {
  CustomDeckORM,
  DocumentVersionORM,
  isCustomDeckORM,
  isDocumentVersionORM,
  isFolderItemORM,
  ORMTypes,
  PageGroupORM,
  PageORM,
  Presentable,
  PresentableGroup,
  PresentablePage,
} from 'src/types/types';
import { v4 as uuid } from 'uuid';
import { PresentableModelORM } from './ContentProvider';
import { Notation } from '@alucio/aws-beacon-amplify/src/models';

function getDocumentVersionVisiblePages(
  documentVersionORM: DocumentVersionORM,
  visiblePages: number[],
  group: PresentableGroup,
): PresentablePage[] {
  let presentationPageNumber = 1;
  return documentVersionORM.meta.allPages.reduce<PresentablePage[]>((acc, page): PresentablePage[] => {
    if (visiblePages.length && !visiblePages.includes(page.number)) {
      return acc;
    }
    acc.push({
      id: `${group.id}_${presentationPageNumber}`,
      page: page,
      documentVersionORM,
      presentationPageNumber: presentationPageNumber++,
      presentableGroup: group,
    });
    return acc;
  }, []);
}

interface PresentableFromCustomDeckResponse {
  presentableGroups: PresentableGroup[],
  presentablePages: PresentablePage[],
  numberOfPages: number,
}

function setAuthToken(
  docVersion: DocumentVersionORM,
  authTokens?: AccessTokenDataExtended[],
) {
  const authToken = authTokens?.find(token =>
    token.documentVersionId === docVersion.model.id,
  );
  if (authToken) {
    return {
      ...docVersion,
      meta: {
        ...docVersion.meta,
        assets: {
          ...docVersion.meta.assets,
          accessToken: authToken.accessToken,
        },
      },
    }
  }

  return docVersion;
}

function getCustomDeckPresentableGroups(
  customDeck: CustomDeckORM,
  authTokens?: AccessTokenDataExtended[],
  visibleOverwrittenPageIds?: string[])
  : PresentableFromCustomDeckResponse {
  // WE GET ALL THE PAGEORMS TO THEN FORM GROUPS WITH THEM
  const standAlonePages = customDeck.meta.customDeckGroups.reduce<PageORM[]>((acc, groupORM) => {
    if (!groupORM.model.visible || !groupORM.pages.length) {
      return acc;
    }

    const pageORMS = groupORM.pages.reduce<PageORM[]>((acc, page) => {
      if (!page.documentVersionORM) return acc;
      const pageORM = page.documentVersionORM.relations.pages
        .find(({ model: { pageId } }) => pageId === page.model.pageId);

      const omitPage = !pageORM ||
        (visibleOverwrittenPageIds?.length && !visibleOverwrittenPageIds.includes(pageORM?.model.pageId))

      if (omitPage) {
        return acc;
      }

      acc.push(pageORM);
      return acc;
    }, []);

    return [...acc, ...pageORMS];
  }, []);

  const { groups, pages } = getPresentableGroups(standAlonePages, [], authTokens);

  return {
    presentableGroups: groups,
    presentablePages: pages,
    numberOfPages: pages.length,
  }
}

interface GetPresentableGroupsReturnType {
  groups: PresentableGroup[],
  pages: PresentablePage[],
}

/**
 * @function getPresentableGroups
 * @param pages
 * @param visiblePages
 * @param authTokens: optional
 * @param existingGroupId: optional - if pass in, return value will keep the same groupId.
 * @description Received a group of pages (can be different group/docs)
 * and returns presentable groups accordingly.
*/
function getPresentableGroups(
  pages: PageORM[],
  visiblePages: number[],
  authTokens?: AccessTokenDataExtended[],
  existingGroupId?: string,
): GetPresentableGroupsReturnType {
  if (!pages.length) {
    return { groups: [], pages: [] };
  }

  const presentableGroups: PresentableGroup[] = [{
    id: existingGroupId ?? uuid(),
    documentVersionORM: setAuthToken(pages[0].relations.documentVersionORM, authTokens),
    pages: [],
  }];
  const presentablePages: PresentablePage[] = [];
  let pageNumber = 1;

  // WE NEED TO HAVE INTERNAL GROUPS (SAME DOCVER) WITHOUT DUPLICATED SLIDES TO ENSURE
  // THE PLAYER'S CORRECT BEHAVIOR. THEREFORE, HERE, WE'RE CREATING GROUPS WITH NO DUPLICATED SLIDES
  pages.forEach((pageORM) => {
    if (!visiblePages.length || visiblePages?.includes(pageORM.model.number)) {
      const latestPresentableGroup = presentableGroups[presentableGroups.length - 1];
      const isIncludedInLatestGroup = latestPresentableGroup.pages
        .some((existingPage) => existingPage.page.pageId === pageORM.model.pageId);
      const isDifferentDocumentVersion = latestPresentableGroup.documentVersionORM.model.id !==
        pageORM.relations.documentVersionORM.model.id;

      // TO AVOID MISBEHAVIORS OF THE PLAYER, THE SLIDES WITHIN A GROUP, MUST BE IN CONSECUTIVE ORDER
      const isPreviousSlideNumber = latestPresentableGroup.pages.some(({ page }) => pageORM.model.number < page.number);

      const newPage: PresentablePage = {
        id: pageORM.model.pageId,
        page: pageORM.model,
        documentVersionORM: pageORM.relations.documentVersionORM,
        presentationPageNumber: pageNumber,
        presentableGroup: latestPresentableGroup,
      };

      // IF THE SLIDE IS ALREADY IN THE CURRENT GROUP OR BELONGS TO A DIFFERENT
      // DOCUMENT VERSION OR IS NOT CONSECUTIVE NUMBER, WE'LL CREATE A NEW GROUP
      if (isIncludedInLatestGroup || isDifferentDocumentVersion || isPreviousSlideNumber) {
        const newGroup: PresentableGroup = {
          id: uuid(),
          documentVersionORM: setAuthToken(pageORM.relations.documentVersionORM, authTokens),
          pages: [],
        };
        newPage.presentableGroup = newGroup;
        presentableGroups.push({
          ...newGroup,
          pages: [newPage],
        });
      } else {
        presentableGroups[presentableGroups.length - 1].pages.push(newPage);
      }
      pageNumber++;
      presentablePages.push(newPage);
    }
  });

  return {
    groups: presentableGroups,
    pages: presentablePages,
  }
}

function getPresentableFromDocVer(
  documentVersionORM: DocumentVersionORM,
  title: string,
  visiblePages: number[],
  notations?: Notation[],
  authTokens?: AccessTokenDataExtended[],
): Presentable {
  const groupId = uuid();
  // FIRST, WE CREATE THE GROUP, WITH AN EMPTY ARRAY OF PAGES,
  // SINCE THE PAGES NEED TO HAVE THE GROUP'S REFERENCE
  const presentableGroups: PresentableGroup[] = [{
    id: groupId,
    documentVersionORM: setAuthToken(documentVersionORM, authTokens),
    pages: [],
  }];
  // THEN, WE ADD THE PAGES WITH THE REFERENCE
  presentableGroups[0].pages =
    getDocumentVersionVisiblePages(documentVersionORM, visiblePages || [], presentableGroups[0]);
  return {
    id: uuid(),
    orm: documentVersionORM,
    title,
    numberOfPages: presentableGroups[0].pages.length,
    presentablePages: presentableGroups[0].pages,
    presentableGroups,
    notations,
  };
}

function getPresentableFromPageGroup(
  item: PageGroupORM,
  visiblePages: number[],
  visibleOverwrittenPageIds?: string[],
  authTokens?: AccessTokenDataExtended[],
): Presentable {
  const title = item.relations.documentVersionORM.model.title || ''
  const groupPages = visibleOverwrittenPageIds?.length
    ? item.relations.pages.filter((page) => visibleOverwrittenPageIds.includes(page.model.pageId))
    : item.relations.pages;
  const { groups, pages } = getPresentableGroups(groupPages, visiblePages, authTokens);

  return {
    id: uuid(),
    orm: item,
    title,
    numberOfPages: pages.length,
    presentablePages: pages,
    presentableGroups: groups,
  }
}

/**
 * @function updatePresentableDocVer
 * @param oldPresentable
 * @param updatedDocVer
 * @param authTokens: optional
 * @description Generate a presentable with updated documentVersionORM.
 * Keeping all the same groupId in presentableGroups so it will not be detected as new groups.
*/
export function updatePresentableDocVer(
  oldPresentable: Presentable,
  updatedDocVer: DocumentVersionORM,
  authTokens?: AccessTokenDataExtended[],
): Presentable {
  const groupId = oldPresentable.presentableGroups[0].id
  const title = updatedDocVer.model.title || ''
  const visiblePages = oldPresentable.presentableGroups[0].pages.map(page => page.presentationPageNumber)
  // FIRST, WE CREATE THE GROUP, WITH AN EMPTY ARRAY OF PAGES,
  // SINCE THE PAGES NEED TO HAVE THE GROUP'S REFERENCE
  const presentableGroups: PresentableGroup[] = [{
    id: groupId,
    documentVersionORM: setAuthToken(updatedDocVer, authTokens),
    pages: [],
  }];
  // THEN, WE ADD THE PAGES WITH THE REFERENCE
  presentableGroups[0].pages =
    getDocumentVersionVisiblePages(updatedDocVer, visiblePages || [], presentableGroups[0]);

  const presentable = {
    id: oldPresentable.id,
    orm: updatedDocVer,
    title,
    numberOfPages: presentableGroups[0].pages.length,
    presentablePages: presentableGroups[0].pages,
    presentableGroups,
  };

  return {
    ...presentable,
    presentableGroups: presentable.presentableGroups.map((group) => ({
      ...group,
      pages: group.pages.map((page) => ({
        ...page,
        presentableGroup: group,
      })),
    })),
  };
}

export function getPresentable(
  item: PresentableModelORM | undefined,
  authTokens?: AccessTokenDataExtended[],
  visibleOverwrittenPageIds?: string[],
  notations?: Notation[],
): Presentable | undefined {
  let presentable: Presentable | undefined;
  if (!item) return presentable;

  switch (item.type) {
    case ORMTypes.DOCUMENT_VERSION: {
      const visiblePages: number[] = [];
      if (visibleOverwrittenPageIds) {
        item.meta.allPages.forEach((page) => {
          if (visibleOverwrittenPageIds.includes(page.pageId)) {
            visiblePages.push(page.number);
          }
        });
      }

      presentable = getPresentableFromDocVer(item, item.model.title || '', visiblePages, notations, authTokens);
      break;
    }

    case ORMTypes.PAGE_GROUP:
      presentable = getPresentableFromPageGroup(item, [], visibleOverwrittenPageIds, authTokens);
      break

    case ORMTypes.FOLDER_ITEM:
      if (isFolderItemORM(item.relations.itemORM)) {
        throw new Error('FolderItem cannot be a folder');
      } else if (isDocumentVersionORM(item.relations.itemORM)) {
        const title = item.model.customTitle || item.relations.itemORM.model.title || '';
        const overwrittenVisiblePages: number[] = [];
        if (visibleOverwrittenPageIds) {
          item.relations.itemORM.meta.allPages.forEach((page) => {
            if (visibleOverwrittenPageIds.includes(page.pageId)) {
              overwrittenVisiblePages.push(page.number);
            }
          });
        }
        presentable = getPresentableFromDocVer(
          item.relations.itemORM,
          title,
          overwrittenVisiblePages.length ? overwrittenVisiblePages : item.model.visiblePages || [],
          undefined,
          authTokens,
        );
        presentable.orm = item;
      } else if (isCustomDeckORM(item.relations.itemORM)) {
        const customDeck = item.relations.itemORM;
        const { presentableGroups, presentablePages, numberOfPages } =
          getCustomDeckPresentableGroups(customDeck, authTokens, visibleOverwrittenPageIds);

        presentable = {
          id: uuid(),
          orm: item,
          title: item.model.customTitle || '',
          presentableGroups,
          presentablePages,
          numberOfPages,
        };
      }
  }

  if (!presentable) {
    throw new Error('Presentable is undefined');
  }
  return {
    ...presentable,
    presentableGroups: presentable.presentableGroups.map((group) => ({
      ...group,
      pages: group.pages.map((page) => ({
        ...page,
        presentableGroup: group,
      })),
    })),
  };
}
