import React, { useCallback, useMemo } from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import { DNABox, DNAText, DNAChip, DNAButton, DNACheckbox } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import {
  usePresentationSelector,
} from 'src/components/PresentationBuilder/PresentationSelector/PresentationSelectorStateProvider';
import { usePresentationBuilderState } from 'src/components/PresentationBuilder/state/PresentationBuilderStateProvider';
import { useCloneBuilder, POOL_CONTAINER_ID } from 'src/components/DnD/Clone/CloneBuilder';
import { SortableItem, SortableItemProps } from 'src/components/DnD/DnDWrapper'
import { useCurrentUser } from 'src/state/redux/selector/user';
import { DocumentAccessLevel, Page } from '@alucio/aws-beacon-amplify/src/models';
import {
  CollapsibleGroupCard,
  GroupDraft,
  SlideThumb,
} from 'src/components/SlideSelector/PageGroupList';
import { thumbnailSizeDimensions } from 'src/hooks/useThumbnailSize/useThumbnailSize';
import CollapsibleCard, { CollapsibleActionMenu } from 'src/components/CollapsibleCard/CollapsibleCard';
import { getSlideContentPageDataTitle } from 'src/hooks/useContentPageData/useContentPageData'

const styles = StyleSheet.create({
  allSlides: {
    height: 48,
    borderRadius: 4,
  },
  overlay: {
    display: 'none',
    marginBottom: 24,
  },
  overlayDragging: {
    opacity: 0.3,
    display: 'flex',
  },
});

const OverlayGroupCard: React.FC<{activeItem: GroupDraft}> = ({ activeItem }) => {
  const { selectedDocumentVersionORM } = usePresentationSelector()

  const groupHeaderTitle = (
    <DNABox fill appearance="col" alignY="center" style={{ width: 100 }}>
      <DNAText h5 numberOfLines={1}>{activeItem.name}</DNAText>
      <DNAText c1 status="flat" numberOfLines={1}>{selectedDocumentVersionORM?.model.title}</DNAText>
    </DNABox>
  )
  return (
    <DNABox style={{ height: 71 }} fill>
      <CollapsibleCard
        headerTitle={groupHeaderTitle}
        isCollapsed={true}
        selected={true}
      >
        <CollapsibleActionMenu>
          <DNABox
            spacing="sm"
            alignY="center"
          >
            <DNAChip
              appearance="subtle"
              status="basic"
              size="lg"
              style={{ borderRadius: 2 }}
            >
              {`${activeItem.pages.length}`}
            </DNAChip>
            {/* These buttons are just placeholder, they are not functional */}
            <DNAButton
              appearance="ghostLink"
              disabled
              size="md"
              iconStyle={{ color: colors['color-gray-200'] }}
              padding="sm"
              rounded="md"
              status="gray"
              iconLeft={activeItem.locked ? 'lock' : 'lock-open-variant-outline'}
            />
            <DNACheckbox
              checked={true}
              status="gray"
            />
          </DNABox>
        </CollapsibleActionMenu>
      </CollapsibleCard>
    </DNABox>
  )
}

const SlideGroupPool : React.FC<{
  handleSelectGroup: (group: GroupDraft) => void,
  handleSelectSlide: (slide: Page) => void,
  index: number,
}> = (props) => {
  const {
    index,
    handleSelectGroup,
    handleSelectSlide,
  } = props

  const {
    selectedDocumentVersionORM,
    selectorThumbnailSize,
    selectedItemIds,
    contentPageData,
  } = usePresentationSelector()

  const { collapsibleGroups, poolItemsGroups } = SlideGroupHook()
  const { editorThumbnailSize } = usePresentationBuilderState()
  const { activeId, activeContainerOrigin } = useCloneBuilder()

  const poolItem = useMemo(() => poolItemsGroups[index], [poolItemsGroups, index])
  const { id, itemId } = poolItem

  const cardContainerWidth = useMemo(() => thumbnailSizeDimensions[editorThumbnailSize].width + 52,
    [editorThumbnailSize])

  const sortableHeader : SortableItemProps = useMemo(() => ({
    id:id,
    itemId:itemId,
    containerId:POOL_CONTAINER_ID,
    style: StyleSheet.flatten([(activeId && activeContainerOrigin.current === POOL_CONTAINER_ID &&
      selectedItemIds.includes(itemId))
      ? { width: cardContainerWidth, position: 'absolute', visibility: 'hidden' }
      : undefined,
    ]),
  }), [activeId, activeContainerOrigin, cardContainerWidth, id, itemId, selectedItemIds])

  const containerStyle = useMemo(() => StyleSheet.flatten([
    styles.overlay,
    (activeId && activeContainerOrigin.current === POOL_CONTAINER_ID &&
    selectedItemIds.includes(itemId))
      ? styles.overlayDragging
      : undefined,
  ]), [activeContainerOrigin, activeId, itemId, selectedItemIds])

  const onCheck = useCallback(() => {
    handleSelectGroup(collapsibleGroups[index])
  }, [collapsibleGroups, handleSelectGroup, index])

  return (
    <DNABox appearance="col" key={`${collapsibleGroups[index].id}-container`}>
      {/* When dragging start, to enable the group overlay's center to be snapped on cursor and for better UX,
    the actual group card will have to transform size to match the overlay.
    But we do not want to show that at slide pool, so OverlayGroupCard is a fake group card
    that is laying on top of the invisible actual groupcard while dragging. */}
      <DNABox
        key={`${collapsibleGroups[index].id}-overlay`}
        fill
        style={containerStyle}
      >
        <OverlayGroupCard activeItem={collapsibleGroups[index]}/>
      </DNABox>
      <DNABox
        key={collapsibleGroups[index].id}
        fill
        appearance="col"
        style={{ marginBottom: 24 }}
      >
        <CollapsibleGroupCard
          key={collapsibleGroups[index].id}
          group={collapsibleGroups[index]}
          documentVersionORM={selectedDocumentVersionORM}
          onCheck={onCheck}
          selectorThumbnailSize={selectorThumbnailSize}
          contentPageData={contentPageData}
          sortableHeader={sortableHeader}
          handleSelectSlide={handleSelectSlide}
        />
      </DNABox>
    </DNABox>
  )
}

const SlideGroupHook = () => {
  const {
    selectedDocumentVersionORM,
    selectedGroups,
    handleSelectGroup,
    getAllGroups,
    getAllGroupedIndividualSlides,
  } = usePresentationSelector()
  const { poolItems } = useCloneBuilder()
  const currentUser = useCurrentUser()

  const collapsibleGroups = useMemo(() => {
    return getAllGroups().sort((a, b) => {
      if (!a.name) return -1
      else if (!b.name) return 1
      else return a.name.localeCompare(b.name)
    })
  }, [getAllGroups])
  const allIndividualSlideGroups = getAllGroupedIndividualSlides()
  // Split poolItems into groups and individualSlides
  const poolItemsGroups = useMemo(
    () => poolItems.slice(0, collapsibleGroups.length),
    [poolItems],
  )
  const poolItemsIndividualSlides = useMemo(
    () => poolItems.slice(collapsibleGroups.length),
    [poolItems],
  )

  const handleSelectSlide = useCallback((page: Page) => {
    const group = allIndividualSlideGroups
      .find(group => group.pages.find(pageWithGroup => pageWithGroup.pageId === page.pageId))

    if (!group) return

    handleSelectGroup(group)
  }, [allIndividualSlideGroups, handleSelectGroup])

  const modifiable = useMemo(
    () => selectedDocumentVersionORM?.meta.permissions.MSLSelectSlides,
    [selectedDocumentVersionORM],
  )

  const documentORMModel = selectedDocumentVersionORM?.relations.documentORM.model
  const isUserUploads = documentORMModel?.accessLevel === DocumentAccessLevel.USER &&
    documentORMModel.createdBy === currentUser.authProfile?.attributes.email

  const handleForcedSelectedCheck = (group: GroupDraft) => {
    let isForcedSelected: boolean = group.pages.some(page => page.isRequired)
    // Check if the selectedGroups already has the required slide(s)
    if (isForcedSelected) {
      selectedGroups?.forEach(selectedGroup => {
        selectedGroup.pages.forEach(slide => {
          const requiredPage = group.pages.find(page => page.isRequired)
          // If the selectedGroups has the required slide already, then no need to force select
          if (slide.pageId === requiredPage?.pageId) isForcedSelected = false
        })
      })
    }
    // Check if the current selected/checked group has the required slides
    if (isForcedSelected) {
      collapsibleGroups.forEach(collapsibleGroup => {
        if (collapsibleGroup.checked) {
          collapsibleGroup.pages.forEach(slide => {
            const requiredPage = group.pages.find(page => page.isRequired)
            if (slide.pageId === requiredPage?.pageId) {
              isForcedSelected = false
              requiredPage.checked = false
            }
          })
        }
      })
    }
    // If the selectGroups does not hve required slide, then modified the slide to be checked
    if (isForcedSelected) {
      group.pages.forEach(page => {
        page.checked = true
      })
    }
  }

  return {
    collapsibleGroups,
    poolItemsGroups,
    poolItemsIndividualSlides,
    handleSelectSlide,
    modifiable,
    handleForcedSelectedCheck,
    isUserUploads,
    allIndividualSlideGroups,
  }
}

const SlidesGroupPool: React.FC = () => {
  const {
    selectedDocumentVersionORM,
    selectorThumbnailSize,
    handleSelectGroup,
    selectedItemIds,
    contentPageData,
  } = usePresentationSelector()
  const { activeId, activeContainerOrigin } = useCloneBuilder()
  const {
    collapsibleGroups,
    poolItemsGroups,
    poolItemsIndividualSlides,
    handleSelectSlide,
    modifiable,
    handleForcedSelectedCheck,
    isUserUploads,
    allIndividualSlideGroups,
  } = SlideGroupHook()

  const groups = useMemo(() => poolItemsGroups.map(({ itemId }, index) => (
    <SlideGroupPool
      key={`${itemId}`}
      index={index}
      handleSelectGroup={handleSelectGroup}
      handleSelectSlide={handleSelectSlide}
    />
  )), [handleSelectGroup, handleSelectSlide])

  if (!selectedDocumentVersionORM) return null

  return (
    <DNABox
      testID="slide-pool-container"
      fill
      style={{ marginHorizontal: 64 }}
    >
      <DNABox as={ScrollView} appearance="col">
        {/* All the groups */}
        {!!collapsibleGroups.length &&
          <DNABox appearance="col">

            {/* Header */}
            <DNABox alignX="center" alignY="center" style={styles.allSlides}>
              <DNAText h5>Groups</DNAText>
            </DNABox>

            {/* Groups */}
            {groups}
          </DNABox>}

        {/* All individual slides */}
        {(modifiable || isUserUploads) &&
          <DNABox appearance="col">

            {/* Header */}

            <CollapsibleCard
              contentPadding="none"
              headerTitle={
                <DNABox alignX="center" alignY="center" style={styles.allSlides} fill>
                  <DNAText h5>View all slides</DNAText>
                </DNABox>
          }
              isCollapsed={!!collapsibleGroups.length}
            >

              {/* Slides */}
              <DNABox wrap="start" alignX="center" style={{ paddingVertical: 18 }}>
                {
                poolItemsIndividualSlides.map(({ id, itemId }, index) => {
                  if (!allIndividualSlideGroups[index]) return null
                  handleForcedSelectedCheck(allIndividualSlideGroups[index])
                  const page = allIndividualSlideGroups[index].pages[0]
                  const title = getSlideContentPageDataTitle(page.number - 1, contentPageData)

                  return (
                    <SortableItem
                      key={itemId}
                      id={id}
                      itemId={itemId}
                      containerId={POOL_CONTAINER_ID}
                      style={
                        StyleSheet.flatten([
                          (activeId && activeContainerOrigin.current === POOL_CONTAINER_ID &&
                            selectedItemIds.includes(itemId))
                            ? { opacity: 0.3 }
                            : undefined,
                        ])
                      }
                    >
                      <DNABox
                        appearance="col"
                        style={{ marginRight: 12 }}
                      >
                        <SlideThumb
                          page={page}
                          documentVersionORM={selectedDocumentVersionORM}
                          onCheck={!page.isPlaceholder
                            ? () => { handleSelectGroup(allIndividualSlideGroups[index]) }
                            : undefined}
                          showAssociation={page.checked}
                          selectorThumbnailSize={selectorThumbnailSize}
                          title={title}
                        />
                      </DNABox>
                    </SortableItem>
                  )
                })
              }
              </DNABox>
            </CollapsibleCard>
          </DNABox>
        }

      </DNABox>
    </DNABox>
  )
}

export default SlidesGroupPool
