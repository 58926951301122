import React from 'react';
import { DNABox, DNAButton, DNAText } from '@alucio/lux-ui';
import { IntegrationRowProps, styles } from '../Tenants/Tabs/Integrations';

export const AdminIntegrationRow = (props: IntegrationRowProps) => {
  const { integration, handleEdit } = props;
  return (
    <DNABox key={integration.id} appearance="row" style={styles.integrationRow}>
      <DNABox appearance="col">
        <DNABox>
          <DNAText>
            {integration.name}
          </DNAText>
        </DNABox>
        <DNABox style={styles.integrationStatus} spacing="xs">
          <DNAText status={integration.enabled ? 'success' : 'danger'} c1>
            {integration.enabled ? 'Enabled' : 'Disabled'}
          </DNAText>
        </DNABox>
      </DNABox>
      <DNAButton
        size="xs"
        status="secondary"
        appearance="outline"
        onPress={() => handleEdit(integration)}
      >
        Edit
      </DNAButton>
    </DNABox>
  );
};
