import { FormValuesType } from 'src/components/CustomFields/ComposableForm';
import { ObjectWithId } from 'src/components/CustomFields/ComposableFormUtilities';
import { SubmitMeetingProps } from './saveMeetingHelper';
import { CrmIntegrationType, MeetingStatus, Tenant } from '@alucio/aws-beacon-amplify/src/models';
import { MeetingORM } from 'src/types/orms';
import {
  veevaPreProcessCRMValues,
  veevaPreProcessFormAttendees,
} from 'src/classes/CRM/Translators/VeevaTranslatorUtils';
import { isArrayOfObjectsWithIds } from 'src/types/typeguards';
import { CRMSubmitResponseReferences, HANDLED_SYNC_ERROR, SYNC_STATUS } from 'src/classes/CRM/CRMIndexedDBTypes';
import truncate from 'lodash/truncate';
import * as logger from 'src/utils/logger'

interface MeetingPreProcessorResponse {
  separatedCustomFields: SeparatedFields,
  formValuesAttendees: FormValuesType[],
  startTime: string,
  endTime: string,
  title: string,
  status: MeetingStatus
}

const ATTENDEES_PREPROCESSOR = {
  [CrmIntegrationType.VEEVA]: veevaPreProcessFormAttendees,
};

interface VALUES_PREPROCESSOR {
  [crmIntegration: string]: (crmValues: FormValuesType,
                             meetingORM: MeetingORM) => FormValuesType,
}

const CRM_VALUES_PREPROCESSOR: VALUES_PREPROCESSOR = {
  [CrmIntegrationType.VEEVA]: veevaPreProcessCRMValues,
};

interface MeetingPreProcessorProps extends SubmitMeetingProps {
  crmIntegrationType: CrmIntegrationType | 'VEEVA' | 'SALESFORCE' | undefined
  hasCRMConfig: boolean,
  tenant: Tenant | undefined,
}

export interface SeparatedFields {
  beaconInternalValues: FormValuesType,
  customFieldValues: FormValuesType,
  crmValues: FormValuesType,
}

// THIS FUNCTION RECEIVES FORM VALUES AND THE MEETING AND PERFORMS
// PRESAVE ACTIONS TO VARIABLES FOR LATER USE
export function getPreprocessedMeetingForm(
  submitMeetingPayload: MeetingPreProcessorProps): MeetingPreProcessorResponse {
  const { formValues, hasCRMConfig, crmIntegrationType, meetingORM, tenant } = submitMeetingPayload;
  const separatedCustomFields = separateCustomFields(
    formValues,
    crmIntegrationType,
    hasCRMConfig,
    meetingORM,
    tenant);

  const formValuesAttendees: FormValuesType[] = [
    ...separatedCustomFields.beaconInternalValues?.primaryAttendee || [],
    ...separatedCustomFields.beaconInternalValues?.additionalAttendees || []] as FormValuesType[];

  const startTime = separatedCustomFields.beaconInternalValues.startTime as string;
  const endTime = separatedCustomFields.beaconInternalValues.endTime as string;
  const title = separatedCustomFields.beaconInternalValues.title as string;
  const status = separatedCustomFields.beaconInternalValues.status as MeetingStatus;
  const response = {
    separatedCustomFields,
    formValuesAttendees,
    endTime,
    startTime,
    title,
    status,
  };

  logger.saveMeetingUtil.debug('Preprocessor response.', response);
  return response;
}

/**
 * @param obj
 * @param tenant
 * @param isIncludedInTenant
 * @returns an object with only the custom fields or only the non-custom fields
 */
function filterByTenant(
  obj: FormValuesType,
  tenant: Tenant | undefined,
  isIncludedInTenant: boolean,
) : FormValuesType {
  if (!tenant) return obj;

  const tenantCustomFieldIds = tenant.config.customFields?.map(({ id }) => id) || [];

  return Object.keys(obj).reduce<FormValuesType>((acc, key) => {
    const isValidField = isIncludedInTenant
      ? tenantCustomFieldIds.includes(key) : !tenantCustomFieldIds.includes(key)

    if (isValidField) {
      acc[key] = obj[key];
    }

    return acc;
  }, {});
}

// ** RECEIVES THE FIELD/VALUES FROM THE FORM AND IDENTIFIES THE BEACONS, CUSTOM FIELDS AND CRM FIELDS ** //
function separateCustomFields(
  formValues: FormValuesType,
  crmIntegrationType: CrmIntegrationType | 'VEEVA' | 'SALESFORCE' | undefined,
  hasCRMConfig: boolean,
  meetingORM: MeetingORM,
  tenant: Tenant | undefined): SeparatedFields {
  const { startTime, endTime, title, additionalAttendees, primaryAttendee, status, ...rest } = formValues;
  // IF THERE'S A CRM CONFIG, THE NON-BEACON FIELDS WILL ALWAYS BE CRM FIELDS. OTHERWISE, CUSTOM FIELDS
  const crmValues: FormValuesType = hasCRMConfig
    ? preProcessCRMValues(meetingORM, filterByTenant(rest, tenant, false), crmIntegrationType) : {};
  const customFieldValues: FormValuesType = hasCRMConfig ? filterByTenant(rest, tenant, true) : rest;
  const beaconInternalValues: FormValuesType = {
    startTime,
    endTime,
    title,
    additionalAttendees,
    primaryAttendee,
    status,
  };

  if (isArrayOfObjectsWithIds(additionalAttendees)) {
    beaconInternalValues.additionalAttendees = preProcessAttendees(
      meetingORM,
      additionalAttendees,
      crmValues,
      crmIntegrationType);
  }

  return {
    beaconInternalValues,
    customFieldValues,
    crmValues,
  }
}

// ADDS, TO THE FORM ATTENDEES, ADDITIONAL REQUIRED FIELD/VALUES FOR LATER USE
function preProcessAttendees(
  meetingORM: MeetingORM,
  attendees: ObjectWithId[],
  crmFormValues: FormValuesType,
  crmIntegrationType: CrmIntegrationType | 'VEEVA' | 'SALESFORCE' | undefined): ObjectWithId[] {
  const processor = ATTENDEES_PREPROCESSOR[crmIntegrationType || ''];

  if (processor) {
    return processor(crmFormValues, meetingORM, attendees);
  }

  return attendees;
}

// ADDS, TO REGULAR CRM VALUES, ADDITIONAL HANDLING IF REQUIRED
function preProcessCRMValues(
  meetingORM: MeetingORM,
  crmFormValues: FormValuesType,
  crmIntegrationType: CrmIntegrationType | 'VEEVA' | 'SALESFORCE' | undefined): FormValuesType {
  const processor = CRM_VALUES_PREPROCESSOR[crmIntegrationType || ''];

  if (processor) {
    return processor(crmFormValues, meetingORM);
  }

  return crmFormValues;
}

// ** RECEIVES CRM RESPONSES AND IDENTIFIES IF AN ERROR NEEDS TO BE THROWN ** //
export function handleCRMErrorResponses(
  crmSubmitResponse?: CRMSubmitResponseReferences, returnErrors?: boolean): void | string {
  const errors: string[] = [];

  if (crmSubmitResponse) {
    Object.entries(crmSubmitResponse).filter(([key, value]) => {
      const isAnUnhandledError = value.syncStatus === SYNC_STATUS.ERROR && !value.handledError;
      const isMainCallDeleted = key === 'main-record' && value.handledError === HANDLED_SYNC_ERROR.ENTITY_DELETED;
      // WE'LL DISPLAY THE ERROR IF IT'S AN UNHANDLED ERROR (OR IF THE MAIN RECORD WAS DELETED) //
      if (value.errorMessages?.length && (isAnUnhandledError || isMainCallDeleted)) {
        errors.push(value.errorMessages.join(''));
      }
    });
  }

  // WE'LL THROW AN ERROR ONLY IF THERE ARE UNHANDLED ONES //
  if (errors.length) {
    logger.saveMeetingUtil.error('An error occurred while submitting to CRM', errors);
    const error = `An error occurred while submitting to CRM. ${truncate(errors.join('\\n'), { length: 200 })}`;
    if (!returnErrors) {
      throw Error(error);
    } else {
      return error;
    }
  }
}
