import React, { useMemo } from 'react';
import { DNAButton } from '@alucio/lux-ui';
import { useLoggerState } from 'src/state/machines/logger/LoggerProvider';
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';
import { SendLogsButtonStates } from 'src/state/machines/logger/logger.selector';
import { useAppSettings } from 'src/state/context/AppSettings';

type SendLogsButtonProps = {
  [key in keyof typeof SendLogsButtonStates]: {
    children: string
    onPress: () => void
    appearance: DNAButtonProps['appearance']
    status: DNAButtonProps['status']
    padding: DNAButtonProps['padding']
    iconLeft?: DNAButtonProps['iconLeft']
  }
}

const SendLogs: React.FC = () => {
  const { isOnline } = useAppSettings()
  const {
    sendLogsToCloudWatch,
    canSendLogs,
    sendLogsButtonState,
  } = useLoggerState()

  const sendLogsButtonStates: SendLogsButtonProps = {
    idle: {
      children: 'Send logs',
      onPress: sendLogsToCloudWatch,
      appearance: 'outline',
      status: 'tertiary',
      padding: 'sm',
    },
    successfullySentLogs: {
      children: 'Sent',
      onPress: () => {},
      appearance: 'filled',
      status: 'success',
      iconLeft: 'check-bold',
      padding: 'sm',
    },
    failedToSendLogs: {
      children: 'Failed',
      onPress:  () => {},
      appearance: 'filled',
      status: 'danger',
      iconLeft: 'close',
      padding: 'sm',
    },
  }
  const sendLogsButtonProps = useMemo(() => sendLogsButtonStates[sendLogsButtonState], [sendLogsButtonState])

  return (
    <DNAButton
      {...sendLogsButtonProps}
      disabled={!canSendLogs || !isOnline}
    />
  )
}

export default SendLogs;
