import { createMachine, sendParent } from 'xstate';
import { assign } from '@xstate/immer';
import { v4 as uuid } from 'uuid';
import deepEqual from 'fast-deep-equal';
// import { inspect } from '@xstate/inspect';
import type * as SlideSettings from './slideSettings.types';
import { DocumentVersion, PageGroup, Page, PageSetting, PageGroupSource } from '@alucio/aws-beacon-amplify/src/models';
import { addSlidesToGroup } from './util';
import * as SS from './slideSettings.types';
import * as logger from 'src/utils/logger';
// inspect({ iframe: false });

export const slideSettingsSM = createMachine(
  {
    id: 'SlideSettingsSM',
    tsTypes: {} as import('./slideSettings.machine.typegen').Typegen0,
    predictableActionArguments: true,
    preserveActionOrder: true,
    schema: {
      context: {} as SlideSettings.SMContext,
      events: {} as SlideSettings.SMEvents,
      services: {} as SlideSettings.SMServices,
    },
    context: {
      documentVersionId: undefined!,
      versionDraft: {
        pages: [],
      },
      associatedSlides: {
        child: {},
        parent: {},
      },
      groupings: {
        selectedPages: {},
        groups: {},
      },
      selectedRemoveAssociatedSlides: {},
      selectedRequiredSlides: {},
      stepOneIsDirty: false,
      stepTwoIsDirty: false,
      // [TODO] - Use a equal comparison instead of setting ctx flags
      isRequiredSlidesDirty: false,
      errors: [],
    },
    initial: 'idle',
    states: {
      idle: {
        description: 'The initial state of slide settings tab.',
        on: {
          SET_COVER_THUMBNAIL: {
            target: 'setCoverThumbnail',
          },
          EDIT_REQUIRED_SLIDES: {
            target: 'editRequiredSlides',
            actions: ['analyticTrackingEditRequiredSlide'],
          },
          ADD_ASSOCIATED_SLIDES: {
            target: 'addAssociatedSlides',
            actions: ['analyticTrackingAddAssociatedSlide'],
          },
          REMOVE_ASSOCIATED_SLIDES: {
            target: 'removeAssociatedSlides',
          },
          EDIT_GROUP_SLIDES: {
            target: 'editGroupSlides',
          },
          REMOVE_IMPORTED_GROUPS: {
            target: 'idle',
            actions: [
              'populateContext',
              'removeImportedGroups',
              'sendVersionDraftSync',
              'clearAllContext',
            ],
          },
          OVERWRITE_SLIDE_SETTINGS: {
            target: 'idle',
            actions: [
              'overwriteSlideSettings',
              'sendVersionDraftSync',
            ],
          },
        },
      },
      setCoverThumbnail: {
        description: 'Enter the modal to set the cover thumbnail.',
        tags: ['SLIDES_SETTING_MODAL'],
        entry: ['populateContext'],
        on: {
          SAVE_COVER_THUMBNAIL: {
            target: '#SlideSettingsSM.idle',
            actions: [
              'saveCoverThumbnailToDraft',
              'sendVersionDraftSync',
              'clearCoverThumbnailContext',
            ],
          },
          UPDATE_COVER_THUMBNAIL: {
            actions: ['updateCoverThumbnail'],
          },
        },
      },
      addAssociatedSlides: {
        description: 'Enter the modal of adding associated slides.',
        tags: ['SLIDES_SETTING_MODAL'],
        initial: 'stepOne',
        states: {
          stepOne: {
            description: 'Step one - choose the slides you want to link to other slides.',
            on: {
              ASSOCIATED_SLIDES_NEXT_STEP: {
                target: 'stepTwo',
                cond: 'checkIfStepOneIsDirty',
              },
              UPDATE_SELECTED_SLIDES: {
                actions: ['updateSelectedSlidesInStepOne'],
              },
            },
          },
          stepTwo: {
            description: 'Step two - choose the slides that require the attachment slides from step one.',
            on: {
              ASSOCIATED_SLIDES_PREV_STEP: {
                target: 'stepOne',
                actions: 'clearParent',
              },
              SAVE_ASSOCIATED_SLIDES: {
                target: '#SlideSettingsSM.idle',
                // [TODO] - Probably create another step here
                cond: 'checkIfAssociatedIsDirty',
                actions: [
                  'saveAsscociatedSlidesDraft',
                  'sendVersionDraftSync',
                  'clearAssociatedSlidesContext',
                ],
              },
              UPDATE_SELECTED_SLIDES: {
                actions: ['updateSelectedSlidesInStepTwo'],
              },
            },
          },
        },
      },
      removeAssociatedSlides: {
        description: 'Enter the modal of removing associated slides.',
        tags: ['SLIDES_SETTING_MODAL'],
        entry: ['populateContext'],
        on: {
          UPDATE_REMOVED_SLIDES: {
            actions: 'updateRemovedSlides',
          },
          SAVE_REMOVED_SLIDES: {
            target: '#SlideSettingsSM.idle',
            actions: [
              'commitRemovedSlidesToDraft',
              'sendVersionDraftSync',
              'clearRemoveAssociatedSlidesContext',
            ],
          },
        },
      },
      editRequiredSlides: {
        description: 'Enter a modal which user can select slides that are required.',
        tags: ['SLIDES_SETTING_MODAL'],
        entry: ['populateContext'],
        on: {
          SAVE_REQUIRED_SLIDES: {
            target: '#SlideSettingsSM.idle',
            actions: [
              'commitRequiredSlidesToDraft',
              'sendVersionDraftSync',
              'clearRequiredSlidesContext',
            ],
          },
          UPDATE_REQUIRED_SLIDES: {
            actions: ['updateRequiredSlides'],
          },
        },
      },
      editGroupSlides: {
        description: 'Enter a modal which user can create his groups.',
        tags: ['SLIDES_SETTING_MODAL'],
        entry: ['populateContext'],
        on: {
          SYNC_GROUPS: {
            actions: [
              'syncDraftGroupSlides',
              'updateGroupSlides',
            ],
          },
          SAVE_GROUP_SLIDES: {
            target: '#SlideSettingsSM.idle',
            cond: 'checkIfGroupingCanSave',
            actions: [
              'commitGroupsToDraft',
              'sendVersionDraftSync',
              'clearAllContext',
            ],
          },
          UPDATE_GROUP_SLIDES: {
            actions: ['updateGroupSlides'],
          },
          ADD_GROUP: {
            actions: ['addGroup'],
          },
          ADD_GROUP_SLIDES: {
            actions: [
              'addGroupSlides',
              'updateGroupSlides',
            ],
            cond: 'canAddSlidesToGroup',
          },
          REMOVE_GROUP_SLIDES: {
            actions: ['removeGroupSlides'],
          },
          RENAME_GROUP: {
            actions: ['renameGroup'],
          },
          REMOVE_GROUP: {
            actions: ['removeGroup'],
          },
          TOGGLE_LOCK_GROUP: {
            actions: ['toggleLockGroup'],
          },
        },
      },
    },
    on: {
      BACK_TO_IDLE: {
        target: 'idle',
        actions: ['clearRemoveAssociatedSlidesContext'],
      },
      SIGNAL_VERSION_UPDATE: {
        actions: ['clearRemoveAssociatedSlidesContext', 'setVersionDraft'],
      },
    },
  },
  {
    guards: {
      // [TODO] - Need to check if against original selection?
      //        - Selecting and unselecting one will trigger "dirty"
      checkIfStepOneIsDirty: (ctx) => {
        // check if stepOne is dirty and NOT everything is selected
        const numberOfSlidesSelected = Object.values(ctx.associatedSlides.child)
          .filter((value) => value)?.length || 0
        const totalNumOfSlides = ctx.versionDraft.pages.length
        return Object.values(ctx.associatedSlides.child).some(child => child) &&
          numberOfSlidesSelected !== totalNumOfSlides
      },
      checkIfAssociatedIsDirty: (ctx) => {
        return (
          Object.values(ctx.associatedSlides.child).some(child => child) &&
          Object.values(ctx.associatedSlides.parent).some(parent => parent)
        )
      },
      checkIfGroupingCanSave: (ctx) => {
        /** Step 1: Check for valid groups */
        const areValidGroups = !Object
          .values(ctx.groupings.groups)
          .some(group => group.pageIds && group.pageIds.length < 2)

        // If we have invalid groups might as well skip the heavier sort and compare below to save cycles
        if (!areValidGroups) return areValidGroups

        /** Step 2: Check for modifications */
        const versionDraft = ctx
          .versionDraft
          .pageGroups
          ?.map((group) => ({ id: group.id, locked: group.locked, pageIds: group.pageIds, name: group.name }))
          ?.sort((a, b) => (a.name.localeCompare(b.name, 'en', { numeric: true })))

        const editDraft = Object
          .values(ctx.groupings.groups)
          .sort((a, b) => (a.name.localeCompare(b.name, 'en', { numeric: true })))

        const isModified = !deepEqual(versionDraft, editDraft)

        return isModified
      },
      canAddSlidesToGroup: (ctx, evt) => {
        const hasSelectedPages = evt.payload.pageIds?.length ?? Object
          .values(ctx.groupings.selectedPages)
          .some(v => v)

        return !!hasSelectedPages
      },
    },
    actions: {
      sendVersionDraftSync: sendParent((ctx, evt) => {
        logger.versioning.settings.debug('Sending version draft sync...')
        // Need to convert pages back to pageSettings
        const pageSettings = ctx.versionDraft.pages.reduce<PageSetting[]>((acc: PageSetting[], currVal: Page) => {
          if (currVal.isRequired || (currVal.linkedSlides && currVal.linkedSlides.length > 0)) {
            acc.push({
              pageId: currVal.pageId,
              number: currVal.number,
              isRequired: currVal.isRequired,
              linkedSlides: currVal.linkedSlides ?? [],
            })
          }
          return acc;
        }, [])

        const versionForm: Partial<DocumentVersion> = {
          pageSettings,
          selectedThumbnail: ctx.versionDraft.selectedThumbnail,
          pageGroups: ctx.versionDraft.pageGroups,
        }

        switch (evt.type) {
          case 'OVERWRITE_SLIDE_SETTINGS':
            return {
              type: 'OVERWRITE_SLIDE_SETTINGS',
              payload: {
                versionForm,
                contentPageData: evt.payload.contentPageData,
              },
            }
          case 'REMOVE_IMPORTED_GROUPS':
            return {
              type: 'SLIDE_SETTINGS_SYNC',
              payload: { versionForm },
            }
          default:
            return {
              type: 'SLIDE_SETTINGS_SYNC_AND_SAVE_DRAFT',
              payload: { versionForm },
            }
        }
      }),
      // ANALYTIC TRACKING
      analyticTrackingEditRequiredSlide: assign((ctx) => {
        analytics?.track('REQUIRED_SLIDE_ADDED', {
          documentVersionId: ctx.documentVersionId,
          type: 'DOCUMENT',
        })
      }),
      analyticTrackingAddAssociatedSlide: assign((ctx) => {
        analytics?.track('REQUIRED_SLIDE_ADDED', {
          documentVersionId: ctx.documentVersionId,
          type: 'SLIDE',
        })
      }),
      // ---- SET/CLEAR SELECTED ----
      clearRequiredSlidesContext: assign((ctx) => {
        ctx.selectedRequiredSlides = {};
        ctx.isRequiredSlidesDirty = false;
      }),
      clearAssociatedSlidesContext: assign((ctx) => {
        ctx.associatedSlides.child = {};
        ctx.associatedSlides.parent = {};
      }),
      clearCoverThumbnailContext: assign((ctx) => {
        ctx.selectedCoverThumbnail = undefined;
      }),
      clearRemoveAssociatedSlidesContext: assign((ctx) => {
        ctx.selectedRemoveAssociatedSlides = {};
      }),
      clearAllContext: assign((ctx) => {
        ctx.selectedRequiredSlides = {};
        ctx.isRequiredSlidesDirty = false;
        ctx.associatedSlides.child = {};
        ctx.associatedSlides.parent = {};
        ctx.selectedCoverThumbnail = undefined;
        ctx.selectedRemoveAssociatedSlides = {};
        ctx.stepOneIsDirty = false;
        ctx.stepTwoIsDirty = false;
        ctx.groupings = {
          selectedPages: {},
          groups: {},
        };
      }),
      clearParent: assign((ctx) => {
        ctx.associatedSlides.parent = {}
      }),
      populateContext: assign((ctx, event) => {
        if (event.type === 'EDIT_REQUIRED_SLIDES') {
          const requiredSlides = ctx.versionDraft.pages.reduce(
            (acc, page) => {
              if (page.isRequired) { acc[page.pageId] = true }
              return acc
            },
            {},
          )
          ctx.selectedRequiredSlides = requiredSlides
        }

        if (event.type === 'SET_COVER_THUMBNAIL') {
          ctx.selectedCoverThumbnail = ctx.versionDraft.selectedThumbnail || 1
        }

        if (event.type === 'REMOVE_ASSOCIATED_SLIDES') {
          const associatedSlides = ctx.versionDraft.pages.reduce<Record<string, string[]>>(
            (acc, page) => {
              acc[page.pageId] = page.linkedSlides ?? []
              return acc
            },
            {},
          )

          ctx.selectedRemoveAssociatedSlides = associatedSlides
        }

        if (event.type === 'EDIT_GROUP_SLIDES' || event.type === 'REMOVE_IMPORTED_GROUPS') {
          if (!ctx.versionDraft?.pageGroups) {
            ctx.versionDraft.pageGroups = []
          }

          ctx.groupings = {
            selectedPages: {},
            groups: ctx
              .versionDraft
              ?.pageGroups
              ?.reduce<Record<string, PageGroup>>(
                (acc, group) => {
                  acc[group.name] = group
                  return acc
                },
                {},
              ),
          }
        }
      }),
      // SLIDE ASSOCIATIONS
      updateSelectedSlidesInStepOne: assign((ctx, evt) => {
        // Select all slides
        if (evt.payload.selection === 'all') {
          const numberOfSlidesSelected = Object.values(ctx.associatedSlides.child)
            .filter((value) => value)?.length || 0

          const SelectableNumberOfSlides = ctx.versionDraft.pages?.reduce((acc, page) => {
            const allChildSlide = {}
            ctx.versionDraft.pages?.forEach(page => {
              page.linkedSlides?.forEach(id => {
                allChildSlide[id] = true
              })
            })
            const disabled = page.linkedSlides?.length
            if (disabled) return acc
            else return acc + 1
          }, 0)

          const isAllAlreadySelected = numberOfSlidesSelected === SelectableNumberOfSlides

          ctx.associatedSlides.child = ctx
            .versionDraft
            .pages
            .reduce<Record<string, boolean>>(
              (acc, page) => {
                // check if the slide is disabled (has linkedskides)
                const disabled = page.linkedSlides?.length
                return { ...acc, [page.pageId]: disabled ? false : !isAllAlreadySelected }
              },
              {},
            )
          return;
        }
        // Toggle individual slides
        ctx.associatedSlides.child[evt.payload.selection] = !ctx.associatedSlides.child[evt.payload.selection]
      }),
      updateSelectedSlidesInStepTwo: assign((ctx, evt) => {
        // Select all slides
        if (evt.payload.selection === 'all') {
          const numberOfSlidesSelected = Object.values(ctx.associatedSlides.parent)
            .filter((value) => value)?.length || 0

          const allChildSlide = {}
          ctx.versionDraft.pages?.forEach(page => {
            page.linkedSlides?.forEach(id => {
              allChildSlide[id] = true
            })
          })
          const SelectableNumberOfSlides = ctx.versionDraft.pages?.reduce((acc, page) => {
            const disabled = !!allChildSlide[page.pageId] || ctx.associatedSlides.child[page.pageId]
            if (disabled) return acc
            else return acc + 1
          }, 0)

          const isAllAlreadySelected = numberOfSlidesSelected === SelectableNumberOfSlides
          ctx.associatedSlides.parent = ctx
            .versionDraft
            .pages
            .reduce<Record<string, boolean>>(
              (acc, page) => {
                const disabled = !!allChildSlide[page.pageId] || ctx.associatedSlides.child[page.pageId]
                return {
                  ...acc,
                  [page.pageId]: disabled
                    ? false
                    : !isAllAlreadySelected,
                }
              },
              {},
            )
          return;
        }
        // Toggle individual slides
        ctx.associatedSlides.parent[evt.payload.selection] = !ctx.associatedSlides.parent[evt.payload.selection]
      }),
      updateRemovedSlides: assign((ctx, evt) => {
        const updatedAssociatedList = evt.payload.removed === 'all'
          ? []
          : ctx
            .selectedRemoveAssociatedSlides[evt.payload.parent]
            .filter(id => id !== evt.payload.removed)

        ctx.selectedRemoveAssociatedSlides[evt.payload.parent] = updatedAssociatedList
      }),
      updateCoverThumbnail: assign((ctx, evt) => {
        ctx.selectedCoverThumbnail = evt.payload
      }),
      updateRequiredSlides: assign((ctx, evt) => {
        ctx.isRequiredSlidesDirty = true
        // Select all slides
        if (evt.payload === 'all') {
          const isAllAlreadySelected = (
            (ctx.versionDraft.pages.length === Object.values(ctx.selectedRequiredSlides).length) &&
            Object.values(ctx.selectedRequiredSlides).every(slide => slide)
          )

          ctx.selectedRequiredSlides = ctx
            .versionDraft
            .pages
            .reduce<Record<string, boolean>>(
              (acc, page) => ({ ...acc, [page.pageId]: !isAllAlreadySelected }),
              {},
            )
          return;
        }
        // Toggle individual slides
        ctx.selectedRequiredSlides[evt.payload] = !ctx.selectedRequiredSlides[evt.payload]
      }),

      // --- COMMIT TO DRAFT ----
      saveAsscociatedSlidesDraft: assign((ctx) => {
        for (const page of ctx.versionDraft.pages) {
          if (ctx.associatedSlides.parent[page.pageId]) {
            const linkedSlides = Object
              .entries(ctx.associatedSlides.child)
              .filter(([_, selected]) => selected)
              .map(([childPageId]) => childPageId)
              .sort()

            const dedupedLinkedSlidesSum = [
              ...new Set([
                ...page?.linkedSlides ?? [],
                ...linkedSlides,
              ]),
            ]

            page.linkedSlides = dedupedLinkedSlidesSum
          }
        }
      }),
      saveCoverThumbnailToDraft: assign((ctx) => {
        ctx.versionDraft.selectedThumbnail = ctx.selectedCoverThumbnail
      }),
      commitRequiredSlidesToDraft: assign((ctx) => {
        for (const page of ctx.versionDraft.pages) {
          page.isRequired = !!ctx.selectedRequiredSlides[page.pageId]
        }
      }),
      commitRemovedSlidesToDraft: assign((ctx) => {
        for (const page of ctx.versionDraft.pages) {
          page.linkedSlides = ctx.selectedRemoveAssociatedSlides[page.pageId]
        }
      }),
      setVersionDraft: assign((ctx, evt) => {
        ctx.versionDraft.pageGroups = evt.payload.model.pageGroups
        ctx.versionDraft.pages = evt.payload.meta.allPages
        ctx.versionDraft.selectedThumbnail = evt.payload.model.selectedThumbnail
      }),
      updateGroupSlides: assign((ctx, event) => {
        const evt = event as SS.EVT_UPDATE_GROUP_SLIDES
        const { scope, forceActive } = evt.payload

        if (scope === 'all') {
          const isAllAlreadySelected = (
            (ctx.versionDraft.pages.length === Object.values(ctx.groupings.selectedPages).length) &&
            Object.values(ctx.groupings.selectedPages).every(page => page)
          )

          ctx.groupings.selectedPages = ctx
            .versionDraft
            .pages
            .reduce<Record<string, boolean>>(
              (acc, page) => ({ ...acc, [page.pageId]: !isAllAlreadySelected }),
              {},
            )
        }
        // [NOTE] - Edge case support for also clearing when SYNC_GROUPS event is called
        //        - Consider adding an optional payload argument that could be used to clear as well
        //        - or raising this from another action
        else if (scope === 'none' || event.type === 'SYNC_GROUPS' || event.type === 'ADD_GROUP_SLIDES') {
          ctx.groupings.selectedPages = {}
        }
        else {
          ctx.groupings.selectedPages[scope] = forceActive
            ? true
            : !ctx.groupings.selectedPages[scope]
        }
      }),
      addGroup: assign((ctx, evt) => {
        const groupId = uuid()
        ctx.groupings.groups[evt.payload.name] = {
          id: groupId,
          name: evt.payload.name,
          pageIds: evt.payload.pageIds ?? [],
          locked: true,
          source: PageGroupSource.USER,
        }

        // ANALYTIC TRACKING: added named group
        analytics?.track('ADD_GROUP', { groupId })
      }),
      addGroupSlides: assign((ctx, evt) => {
        const { groupName, pageIds } = evt.payload
        const selectedPages = ctx.groupings.selectedPages

        if (groupName === 'ADD_TO_ALL_GROUPS') {
          Object.entries(ctx.groupings.groups).forEach(([groupName]) => {
            const group = ctx.groupings.groups[groupName]
            addSlidesToGroup(pageIds, selectedPages, group);
          })
        }
        else {
          const group = ctx.groupings.groups[groupName]
          addSlidesToGroup(pageIds, selectedPages, group);
        }
      }),
      removeGroupSlides: assign((ctx, evt) => {
        if (evt.payload.itemIdx === 'all') {
          const groupId = ctx.groupings.groups[evt.payload.groupName].id
          ctx.groupings.groups[evt.payload.groupName]
            .pageIds
            ?.forEach(slideId => {
              analytics?.track('REMOVE_SLIDE', { groupId, pageId: slideId })
            })
          ctx.groupings.groups[evt.payload.groupName].pageIds = []
        }
        else {
          const group = ctx.groupings.groups[evt.payload.groupName]
          ctx.groupings.groups[evt.payload.groupName] = {
            ...group,
            pageIds: group
              .pageIds
              ?.filter((_, idx) => idx !== evt.payload.itemIdx),
          }

          // ANALYTIC TRACKING: removing slide from a group
          analytics?.track('REMOVE_SLIDE', { groupId: group.id, pageId: evt.payload.pageId })
        }
      }),
      syncDraftGroupSlides: assign((ctx, evt) => {
        // [TODO] - We can probably limit this to only the Active Group
        const { groupItems } = evt.payload

        for (const groupName in groupItems) {
          const group = groupItems[groupName]

          const draftGroup = ctx.groupings.groups[groupName]
          draftGroup.pageIds = group.map(({ itemId }) => itemId)

          // [TODO] - We need to add slide adding analytics tracking here as well
        }
      }),
      renameGroup: assign((ctx, evt) => {
        // TODO: This should change probably an id instead of an string for the key to avoid having collisions
        const { newName, oldName } = evt.payload

        ctx.groupings.groups[newName] = {
          ...ctx.groupings.groups[oldName],
          name: evt.payload.newName,
        }

        delete ctx.groupings.groups[evt.payload.oldName]
      }),
      removeGroup: assign((ctx, evt) => {
        const groupId = ctx.groupings.groups[evt.payload].id

        // ANALYTIC TRACKING: delete named group
        analytics?.track('DELETE_GROUP', { groupId })

        delete ctx.groupings.groups[evt.payload]
      }),
      removeImportedGroups: assign((ctx, evt) => {
        logger.versioning.settings.debug('Removing imported groups...')
        if (!ctx.versionDraft.pageGroups?.length) return
        if (evt.payload?.groupName) {
          ctx.versionDraft.pageGroups =
            ctx.versionDraft
              .pageGroups
              .filter(group => group.name !== evt.payload?.groupName)
        }
        else {
          ctx.versionDraft.pageGroups =
            ctx.versionDraft.pageGroups.filter(group => group.source !== PageGroupSource.DOCUMENT)
        }
      }),
      toggleLockGroup: assign((ctx, evt) => {
        ctx.groupings.groups[evt.payload].locked = !ctx.groupings.groups[evt.payload].locked
      }),
      commitGroupsToDraft: assign((ctx) => {
        ctx.versionDraft.pageGroups = Object.values(ctx.groupings.groups)
      }),
      overwriteSlideSettings: assign((ctx, evt) => {
        logger.versioning.settings.debug('Overwrite slide settings...')
        const { linkedSlidesMapping, requiredSlidePageIds, groups } = evt.payload
        for (const page of ctx.versionDraft.pages) {
          page.isRequired = !!requiredSlidePageIds.includes(page.pageId)
          page.linkedSlides = linkedSlidesMapping[page.pageId] ?? []
        }
        ctx.versionDraft.pageGroups = groups;
      }),
    },
    services: {},
  },
)

export default slideSettingsSM
