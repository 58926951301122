import React, { useEffect, useState } from 'react';
import { DNABox, DNAText } from '@alucio/lux-ui';
import DNAFlatList from 'src/components/DNA/FlatList/DNAFlatList';
import { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';
import SelectFileButton from 'src/components/SelectFileButton/SelectFileButton';
import DocumentDropzone from '../Documents/DocumentDropzone/DocumentDropzone';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useAllPersonalDocuments } from 'src/state/redux/selector/document';
import DNADefaultHeader from 'src/components/DNA/FlatList/Headers/DNADefaultHeaders';
import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions';
import { DocumentORM } from 'src/types/types';
import { StyleSheet } from 'react-native';
import { useHistory } from 'src/router';
import ROUTES from 'src/router/routeDef';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';

const styles = StyleSheet.create({
  header: {
    backgroundColor: 'white',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 0,
    borderColor: colors['color-gray-100'],
  },
})
const MyUploads = () => {
  const [filesToUpload, setFilesToUpload] = useState<File[]>([])

  const history = useHistory();
  const enableMyUploads = useFeatureFlags('enableMyUploads');

  useEffect(() => {
    /*
     Redirect to 404 if my uploads is not enabled
     Ideally this would be placed in routes.tsx, however with the way the components were structured this is the best way
     to do it without major rewrites
    */
    if (!enableMyUploads) {
      history.replace(ROUTES.NOT_FOUND.PATH);
    }
  }, []);

  function onSelectedFiles(files: File[]): void {
    setFilesToUpload(files);
  }

  const personalDocuments = useAllPersonalDocuments();
  const documentActions = useDNADocumentActions();

  const onPress = (documentORM: DocumentORM) => {
    const { latestUsableDocumentVersionORM } = documentORM.relations.version
    if (latestUsableDocumentVersionORM.model.conversionStatus === 'PROCESSED') {
      documentActions.preview(documentORM)();
    }
  }

  return (
    <DNABox appearance="col" fill>
      <DNABox appearance="row" spacing="between" alignY="center" style={styles.header}>
        <DNAText style={{ color: colors['color-warning-500'], margin: 16 }}>
          Ensure your files have been approved for external sharing before uploading.
        </DNAText>
        <SelectFileButton
          acceptedTypes=".pptx,.pdf"
          testID="upload-button"
          onSelect={onSelectedFiles}
          text="Upload"
          appearance="outline"
          status="info"
          size="sm"
          style={{ marginRight: 16 }}
        />
      </DNABox>
      <DocumentDropzone
        files={filesToUpload}
        onCloseModal={() => setFilesToUpload([])}
        toggleMyUnpublishedDocument={() => undefined}
        isUserDocument
        disableDrag
      >
        <DNAFlatList<DocumentORM>
          items={personalDocuments}
          emptyVariant={EmptyVariant.UploadsEmpty}
          onPress={onPress}
        >
          <DNADefaultHeader variant="uploads" />
        </DNAFlatList>
      </DocumentDropzone>
    </DNABox>
  )
}

export default MyUploads;
