import React, { useEffect, useMemo } from 'react';
import { StyleSheet, ViewStyle, useWindowDimensions } from 'react-native';
import { DNASlider, DNABox, Iffy, Stack, util, DNAButton, DNAText } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';
import { MeetingsStateProvider } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import { ContentProvider } from 'src/state/context/ContentProvider/ContentProvider.proxy'
import OpenedPresentations from './TabContent/OpenedPresentations/OpenedPresentations';
import MeetingDetails from './TabContent/MeetingDetails/MeetingDetails';
import Presenting from '../VirtualContent/Presenting';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import BrowseContent from './TabContent/BrowseContent';
import { MeetingType } from '@alucio/aws-beacon-amplify/src/models';
import { Animation } from 'react-native-animatable'
import SidebarButtons from './SideBar/SidebarButtons';

const DEFAULT_SLIDER_MAX_WIDTH = 800;

/** Define the structure of a variant */
interface VariantConfig {
  /** Used to define unique variant styles */
  styles?: ViewStyle,
  animations?: {
    in?: Animation,
    out?: Animation
  }
}
/** Define a mapping for each variant type */
type Variants = Record<MeetingType, VariantConfig>
/** Define the values for each variant type */
const variants: Variants = {
  IN_PERSON: {
    styles: {
      maxWidth: 800,
    },
    animations: {
      in: 'slideInLeft',
      out: 'slideOutLeft',
    },
  },
  VIRTUAL: {
    styles: {
      maxWidth: window.innerWidth < 1225 ? window.innerWidth : 1225,
    },
    animations: {
      in: 'slideInUp',
      out: 'slideOutDown',
    },
  },
  MANUAL: {},
}

const styles = StyleSheet.create({
  /** Styles which are common across variants should be placed here */
  tabContainer: {
    backgroundColor: colors['color-gray-800'],
  },
  panelStackLayer: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: colors['color-gray-900'],
    display: 'flex',
  },
  sideBar: {
    backgroundColor: colors['color-gray-800'],
    borderColor: colors['color-gray-600'],
    borderRightWidth: 1,
    width: 64,
    paddingTop: 40,
  },
  closeIcon: {
    color: colors['color-text-white'],
    width: 24,
    height: 24,
  },
})

export enum Tabs {
  BROWSE_CONTENT,
  OPENED_PRESENTATIONS,
  MEETING_DETAILS,
  PRESENTING,
}
export type Tab = keyof typeof Tabs
interface TabConfig {
  testID: string,
  title: string,
  component: React.ElementType
}

type TabOptions = Record<Tab, TabConfig>

export const tabs: TabOptions = {
  BROWSE_CONTENT: {
    testID: 'browse-content',
    title: 'Browse Content',
    component: BrowseContent,
  },
  OPENED_PRESENTATIONS: {
    testID: 'opened-presentations',
    title: 'Opened Files',
    component: OpenedPresentations,
  },
  MEETING_DETAILS: {
    testID: 'meeting-details',
    title: 'Meeting Details',
    component: MeetingDetails,
  },
  PRESENTING: {
    testID: 'presenting',
    title: 'Presenting',
    component: Presenting,
  },
};

const PresentationControls = () => {
  const meetingsState = useMeetingsState()
  const {
    presentationControlsVisible,
    setPresentationControlsVisible,
    currentTab,
    meetingORM,
    checkFormDiscard,
    togglePresentationControlsVisibility,
    setCurrentTab,
  } = meetingsState
  const content = useContent()
  const { presentations } = content
  const enableNew3PC = useFeatureFlags('enableNew3PC')
  const dimensions = useWindowDimensions()

  useEffect(() => {
    if (currentTab === 'PRESENTING') {
      setPresentationControlsVisible(false);
    }
  }, []);

  const dynamicSliderSize = useMemo(() => {
    const type = meetingORM?.model.type;
    const maxWidth = variants[type || ''].styles?.maxWidth;
    return Math.min(dimensions.width, maxWidth ?? DEFAULT_SLIDER_MAX_WIDTH);
  }, [dimensions, meetingORM?.model.type])

  if (!meetingORM) {
    console.error('Meeting ORM not found');
    return null;
  }

  const { type } = meetingORM.model
  const isVirtualMode = type === 'VIRTUAL'
  const isInPerson = type === 'IN_PERSON'
  const availableTabs = Object.entries(tabs).filter((tab) => isVirtualMode || tab[0] !== 'PRESENTING')
  const tabContentElements = availableTabs.map(([tabName, tabConfig]) => {
    const isHidden = currentTab !== tabName && tabName !== 'PRESENTING'
    const Component = tabConfig.component
    const containerStyle: ViewStyle | undefined = !isHidden
      ? undefined
      : {
        display: 'none',
      }

    return (
      <DNABox key={tabName} fill style={containerStyle}>
        <Component />
      </DNABox>
    )
  })

  const handleTogglePanelVisibility = () => {
    checkFormDiscard(() => {
      togglePresentationControlsVisibility();
      setCurrentTab('OPENED_PRESENTATIONS');
    })
  }

  const nonPresentingTab = tabContentElements.filter((elem) => elem.key !== 'PRESENTING')
  const isPresenting = currentTab === 'PRESENTING'

  const handleBackDropPress = () => {
    // BEFORE CLOSING, CHECKS THE MEETING'S FORM DOESN'T HAVE UNSAVED DATA
    checkFormDiscard(() =>
    {
      setPresentationControlsVisible(false)
      setCurrentTab('OPENED_PRESENTATIONS')
    },
    );
  }

  return (
    <>
      <Iffy is={!enableNew3PC && isVirtualMode && !presentationControlsVisible}>
        <SidebarButtons />
      </Iffy>
      <Iffy is={presentationControlsVisible}>
        <DNASlider
          visible={presentationControlsVisible}
          setVisible={setPresentationControlsVisible}
          orientation="horizontal"
          onBackdropPress={handleBackDropPress}
          backdropStyle={{ backgroundColor: 'black', opacity: 0.48 }}
      // [NOTE] - SlideSize is locked in for now and only supports numbers
      //        - If we need dynamic values (i.e. screen width)
      //          Consider using a layoutEffect and calculating the current screen width from there
          sliderSize={dynamicSliderSize}
          invertAnimation
        >
          <ContentProvider value={content}>
            <MeetingsStateProvider value={meetingsState}>
              <DNABox fill>
                <Iffy is={!enableNew3PC && isVirtualMode}>
                  <SidebarButtons />
                </Iffy>
                <DNABox
                  style={styles.tabContainer}
                  appearance="col"
                  fill
                >
                  {/* Header */}
                  <Iffy is={isInPerson}>
                    <Header />
                  </Iffy>

                  {/* CONTENT */}
                  <DNABox fill appearance="row">
                    <Stack anchor="top" style={{ flex: 1 }}>
                      <Stack.Layer
                        style={util.mergeStyles(undefined, styles.panelStackLayer, [{ display: 'none' }, isPresenting])}
                      >
                        <DNABox fill appearance="col">
                          <Iffy is={isVirtualMode}>
                            <DNABox
                              appearance="row"
                              alignX="end"
                              spacing="between"
                              alignY="center"
                              style={{ marginTop: 15, paddingHorizontal: 25 }}
                            >
                              <DNAText h5 bold={false} status="basic">
                                {tabs[currentTab].title}
                              </DNAText>
                              <DNAButton
                              // @ts-ignore
                                iconStyle={styles.closeIcon}
                                onPress={handleTogglePanelVisibility}
                                padding="sm"
                                iconLeft="close"
                                appearance="ghostLink"
                                status="dark"
                                size="md"
                              />
                            </DNABox>
                          </Iffy>
                          {nonPresentingTab}
                        </DNABox>
                      </Stack.Layer>
                    </Stack>
                  </DNABox>
                  {/* CURRENT STATUS FOOTER */}
                  <Iffy is={presentations.length <= 0 || isInPerson}>
                    <Footer />
                  </Iffy>
                </DNABox>
                {/* CURRENT STATUS FOOTER */}
              </DNABox>
            </MeetingsStateProvider>
          </ContentProvider>
        </DNASlider>
      </Iffy>
    </>
  );
};

PresentationControls.displayName = 'PresentationControls';

export default PresentationControls;
