import { CRMIntegration } from '@alucio/aws-beacon-amplify/src/models'
import { ActorRef, Interpreter, State, StateFrom, InterpreterFrom } from 'xstate'
import { crmConfigFormContext, crmConfigFormsEvents, crmConfigFormsState } from './configSyncMachineTypes';
import { CRMAccount, Config } from 'src/classes/CRM/CRMIndexedDBTypes'
import { CRMIntegrationSession } from './util'
import type crmMachine from './crmMachine'

type ActorRefFromInterpreter<
  T extends Interpreter<any, any, any, any>
> = T extends Interpreter<infer TC, any, infer TE>
  ? ActorRef<TE, State<TC, TE>>
  : never;

export type EXTERNAL_TAB_EVENTS = {
    type: 'LOGOUT_CRM_FROM_OTHER_TAB',
} | {
    type: 'LOGIN_CRM_FROM_OTHER_TAB',
}

export enum CRMSyncStatus {
    IDLE = 'IDLE',
    SYNCING = 'SYNCING',
    ERROR = 'ERROR',
    RE_SYNCING = 'RE_SYNCING',
    SYNCED = 'SYNCED',
}

export enum CRMConnectionStatus {
    CONNECTED = 'CONNECTED',
    DISCONNECTING = 'DISCONNECTING',
    DISCONNECTED = 'DISCONNECTED',
}

export enum CRMAvailabilityStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
}

export enum LOGOUT_DUE_TO {
    ERROR_REFRESHING_TOKEN = 'ERROR_REFRESHING_TOKEN',
    USER_LOGOUT = 'USER_LOGOUT',
}

export type CRMMachine = ReturnType<typeof crmMachine>
export type CRMState = StateFrom<CRMMachine>
export type CRMService = InterpreterFrom<CRMMachine>

export type crmContext = {
   crmSession: CRMIntegrationSession | undefined
   sessionError?: string | undefined
   error?: string | undefined
   accounts: CRMAccount[]
   configItem: Config | undefined
   crmConfig: CRMIntegration | undefined
   crmSyncStatus: CRMSyncStatus,
   crmAvailabilityStatus: CRMAvailabilityStatus,
   isAuthenticating: boolean
   connectionStatus: CRMConnectionStatus
   crmFormConfigActor?: ActorRefFromInterpreter<
   Interpreter<crmConfigFormContext, any, crmConfigFormsEvents, crmConfigFormsState>
   >
   lastSyncedAt: Date | undefined,
   autoSyncActor?: ActorRef<any, any>,
   externalTabActor?: ActorRef<EXTERNAL_TAB_EVENTS, EXTERNAL_TAB_EVENTS>,
   numberOfRetries: number,
}

export type CRMStateValue = State<
    crmContext,
    crmEvents,
    crmState
>

export type REFRESH_DATA = {
    type: 'REFRESH_DATA',
    payload: { trigger: 'USER' | 'SYSTEM' }
}

export type SET_CONFIG = {
    type: 'SET_CONFIG',
    payload: CRMIntegration | undefined
}
export type LOAD_SESSION_DATA = {
    type: 'LOAD_SESSION_DATA',
    payload: { crmSession: CRMIntegrationSession | undefined }
}

export type LOAD_REFRESH_TOKEN_DATA = {
    type: 'LOAD_REFRESH_TOKEN_DATA',
    payload: { crmSession: CRMIntegrationSession | undefined }
}

export type SYNC_DATA_EVENT = {
    type: 'SYNC_DATA_EVENT',
    data: {
        accounts: CRMAccount[],
        configItem: Config,
    }
}

export type CRM_ERROR = {
    type: string,
    data: { message?: string, errorCode?: string, errors?: { message: string }[] }
}

export type SYNC_ERROR_STATUS = {
    type: 'SYNC_ERROR_STATUS',
    data: { error: string }
}

export type REFRESH_TOKEN = {
    type: 'REFRESH_TOKEN',
}

export type CONNECTION_ERROR = {
    type: 'CONNECTION_ERROR',
}

export type EVT_BACK_TO_IDLE = {
    type: 'BACK_TO_IDLE',
}

export type LOGIN_CRM = {
    type: 'LOGIN_CRM',
    payload?: CRMIntegration | undefined
}

export type LOGOUT_CRM = {
    type: 'LOGOUT_CRM',
    data?: { reason?: LOGOUT_DUE_TO }
}

export type SET_FORM_SYNC_STATUS = {
    type: 'SET_FORM_SYNC_STATUS',
    data: { status: CRMSyncStatus }
}

export type SET_FORM_SYNC_ERROR_STATUS = {
    type: 'SET_FORM_SYNC_ERROR_STATUS',
    payload: { error: string, status: CRMSyncStatus }
}

export type SET_CONTEXT_VARS = {
    type: 'SET_CONTEXT_VARS',
    data: { isFirstTimeSync: boolean }
}

export type SET_ACCESS_TOKEN = {
    type: 'SET_ACCESS_TOKEN',
    data: { accessToken: string }
}

export type crmEvents =
    REFRESH_TOKEN |
    EVT_BACK_TO_IDLE |
    REFRESH_DATA |
    SET_CONFIG |
    CONNECTION_ERROR |
    SYNC_DATA_EVENT |
    LOGIN_CRM |
    LOGOUT_CRM |
    LOAD_SESSION_DATA |
    LOAD_REFRESH_TOKEN_DATA |
    SET_ACCESS_TOKEN |
    CRM_ERROR |
    SET_FORM_SYNC_STATUS |
    SET_FORM_SYNC_ERROR_STATUS

export type crmState = {
    value: {},
    context: crmContext,
}
