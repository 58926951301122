import React from 'react'
import { Image } from 'react-native'
import { FolderStatus } from '@alucio/aws-beacon-amplify/src/models'

import { FolderORM, ORMTypes } from 'src/types/types'

const BlueFolder = require('../../../../assets/images/BlueFolder.svg')
const NestedFolder = require('../../../../assets/images/NestedFolder.svg')
const FullFolder = require('../../../../assets/images/FullFolder.svg')
const FilesFolder = require('../../../../assets/images/FilesFolder.svg')
const GrayFolder = require('../../../../assets/images/GrayFolder.svg')
const GrayNestedFolder = require('../../../../assets/images/ArchivedFolderWithFolder.svg')
const GrayFilesFolder = require('../../../../assets/images/ArchivedFolderWithFiles.svg')
const GrayFullFolder = require('../../../../assets/images/ArchivedFolderFilesFolder.svg')
const SharedFolderWithItems = require('../../../../assets/images/SharedBlue.svg');
const EmptyShared = require('../../../../assets/images/EmptyShared.svg');
const SharedBlueNested = require('../../../../assets/images/SharedBlueNested.svg');
const SharedBlueWithFiles =  require('../../../../assets/images/SharedBlueWithFiles.svg');

export interface FolderProps {
  folder: FolderORM,
  height?: number,
  mode?: 'ADD_TO_FOLDER' | 'SELECTED' | 'SHARING'
  width?: number,
}

const FOLDER_IMAGES = {
  [FolderStatus.ACTIVE]: {
    hasBoth: FullFolder,
    hasFiles: FilesFolder,
    hasFilesAndShared: SharedBlueWithFiles,
    hasNestedFolder: NestedFolder,
    hasNestedFolderAndShared: SharedBlueNested,
    hasBothAndShared: SharedFolderWithItems,
    emptyShared: EmptyShared,
  },
  [FolderStatus.ARCHIVED]: {
    hasBoth: GrayFullFolder,
    hasFiles: GrayFilesFolder,
    hasNestedFolder: GrayNestedFolder,
  },
};

// [TODO] - Consider accepting overrides for the defaults
export const Folder: React.FC<FolderProps> = (props) => {
  const {
    folder: folderORM,
    height = 60,
    mode,
    width = 60,
  } = props

  const DEFAULT_IMAGE = folderORM.model.status === FolderStatus.ARCHIVED ? GrayFolder : BlueFolder;
  let FOLDER_IMAGE = DEFAULT_IMAGE;

  const hasFirstLevelFiles = folderORM.relations.items
    .some(
      (item) => [
        ORMTypes.DOCUMENT_VERSION,
        ORMTypes.CUSTOM_DECK,
      ].includes(item.relations.itemORM.type),
    )

  const isRemoveFolder = folderORM.model.status === FolderStatus.REMOVED;
  if (!mode && !isRemoveFolder) {
    if (folderORM.meta.folderCount > 0) {
      if (hasFirstLevelFiles) {
        FOLDER_IMAGE = FOLDER_IMAGES[folderORM.model.status].hasBoth;
        if (folderORM.meta.isSharedFolder) {
          FOLDER_IMAGE = FOLDER_IMAGES[folderORM.model.status].hasBothAndShared;
        }
      } else {
        FOLDER_IMAGE = FOLDER_IMAGES[folderORM.model.status].hasNestedFolder;
        if (folderORM.meta.isSharedFolder) {
          FOLDER_IMAGE = FOLDER_IMAGES[folderORM.model.status].hasNestedFolderAndShared;
        }
      }
    } else if (hasFirstLevelFiles) {
      FOLDER_IMAGE = FOLDER_IMAGES[folderORM.model.status].hasFiles;
      if (folderORM.meta.isSharedFolder) {
        FOLDER_IMAGE = FOLDER_IMAGES[folderORM.model.status].hasFilesAndShared;
      }
    }
    else {
      if (folderORM.meta.isSharedFolder) {
        FOLDER_IMAGE = FOLDER_IMAGES[folderORM.model.status].emptyShared;
      }
    }
  } else if (mode === 'SELECTED') {
    FOLDER_IMAGE = FilesFolder;
  }

  return <Image style={{ height, width }} source={FOLDER_IMAGE || DEFAULT_IMAGE} />;
}

export default Folder
