import React, { useState, useEffect, useMemo } from 'react'
import { ScrollView } from 'react-native'

import {
  FolderORM,
  DocumentORM,
  FolderItemORM,
  ORMTypes,
  isDocumentORM,
  isDocumentVersionORM,
  isFolderItemORM,
  isFolderORM,
} from 'src/types/types'

import { useAllFolders, useAllFoldersInstance } from 'src/state/redux/selector/folder'
import { filters, sorts, merge } from 'src/state/redux/folder/query'

import { DNABox, Collapsible, InformationMessage } from '@alucio/lux-ui'
import DNANestedFolderRow from './DNAFolderNestedRow'
import useCurrentPage from '../../hooks/useCurrentPage'
import { useFetchSharedFolders } from 'src/state/redux/slice/sharedFolder'
import { useAppSettings } from 'src/state/context/AppSettings'
import { PermissionType } from '@alucio/aws-beacon-amplify/src/models'

type DNAFolderNestedListProps = {
  itemORM?: DocumentORM | FolderItemORM | DocumentORM[],
  onFolderSelect?: (f: FolderSelectedMap) => void,
  showSharedFolders?: boolean,
}

export type FolderSelectedMap = {
  [id: string]: { folder: FolderORM, locked: boolean } | undefined
}

export type FolderExpandedMap = {
  [id: string]: boolean | undefined
}

const getLatestDocumentId = (
  itemORM: DocumentORM | FolderItemORM,
  isPublisherView : boolean,
) =>
  isDocumentORM(itemORM)
    ? isPublisherView
      ? itemORM.relations.version.latestPublishedDocumentVersionORM?.model.id ||
        itemORM.relations.version.latestUsableDocumentVersionORM?.model.id
      : itemORM.relations.version.latestPublishedDocumentVersionORM?.model.id
    : isDocumentVersionORM(itemORM?.relations.itemORM) && !itemORM?.meta.isModified
      ? isPublisherView
      /** TODO: should look into destructuring these so we're not having to repeat these super long prop names */
        // eslint-disable-next-line max-len
        ? itemORM?.relations.itemORM.relations.documentORM.relations.version.latestPublishedDocumentVersionORM?.model.id ||
          itemORM?.relations.itemORM.relations.documentORM.relations.version.latestUsableDocumentVersionORM?.model.id
        : itemORM?.relations.itemORM.relations.documentORM.relations.version.latestPublishedDocumentVersionORM?.model.id
      : itemORM?.model.id

const initMap = (
  folders: FolderORM[],
  itemORM?: DocumentORM | FolderItemORM,
  isPublisherView: boolean = false,
) => {
  const folderMap = { }

  for (const folder of folders) {
    const folderId = folder.model.id

    if (itemORM) {
      for (const nestedItem of folder.relations.items) {
        const nestedItemORM = nestedItem.relations.itemORM;
        const folderItemID = getLatestDocumentId(itemORM, isPublisherView);
        const nestedItemID = nestedItemORM.model.id;

        // Root level folder
        if (nestedItemORM.type === ORMTypes.DOCUMENT_VERSION) {
          if (
            isFolderItemORM(itemORM) &&
            itemORM.meta.isModified &&
            nestedItem.model?.id === folderItemID
          )
          { folderMap[folderId] = { folder, locked: true } }
          else if (
            nestedItemID === folderItemID &&
            !nestedItem.meta.isModified
          )
          { folderMap[folderId] = { folder, locked: true } }
        }

        else if (nestedItemORM.type === ORMTypes.CUSTOM_DECK) {
          if (
            nestedItem.model?.id === folderItemID
          )
          {
            folderMap[folderId] = { folder, locked: true }
          }
        }

        // Nested level folder
        if (nestedItemORM.type === ORMTypes.FOLDER) {
          for (const subNestedItem of nestedItemORM.relations.items) {
            if (subNestedItem.relations.itemORM.type === ORMTypes.DOCUMENT_VERSION) {
              if (
                isFolderItemORM(itemORM) &&
                itemORM.meta.isModified &&
                subNestedItem.model.id === folderItemID
              )
              { folderMap[nestedItemID] = { folder, locked: true } }
              else if (
                subNestedItem.relations.itemORM.model.id === folderItemID)
              { folderMap[nestedItemID] = { folder, locked: true } }
            }
            else if (subNestedItem.relations.itemORM.type === ORMTypes.CUSTOM_DECK) {
              if (
                subNestedItem.model.id === folderItemID)
              { folderMap[nestedItemID] = { folder, locked: true } }
            }
          }
        }
      }
    }
  }

  return folderMap
}

const DEFAULT_FOLDER_FILTER = merge(
  filters.active,
  filters.rootFolder,
  sorts.pinnedAsc,
)

export const DEFAULT_SHARED_FOLDER_FILTER = merge(
  filters.active,
  sorts.nameAsc,
)

const DNANestedFolderList: React.FC<DNAFolderNestedListProps> = (props) => {
  const { itemORM, onFolderSelect, showSharedFolders } = props
  const bulkItemORM = (Array.isArray(itemORM) && itemORM.length > 1) ? itemORM : undefined
  const singleItemORM = Array.isArray(itemORM) ? !bulkItemORM ? itemORM[0] : undefined : itemORM
  const [error, setError] = useState<string | undefined>(undefined)

  const route = useCurrentPage({ exact: false })
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')

  const folders =  useAllFolders(DEFAULT_FOLDER_FILTER)
  const sharedFolders =  useAllFoldersInstance(DEFAULT_SHARED_FOLDER_FILTER, true)
  const { isOnline } = useAppSettings()

  const foldersToDisplay = useMemo(() => {
    return showSharedFolders
      ? sharedFolders.filter(p => p.meta.permission === PermissionType.EDIT)
      : folders;
  }, [showSharedFolders, sharedFolders, folders])

  const [selected, setSelected] = useState<FolderSelectedMap>(
    singleItemORM ? initMap(foldersToDisplay, singleItemORM, isPublisherRoute) : {})
  const [expanded, setExpanded] = useState<FolderExpandedMap>({})

  const { fetchSharedFolders } = useFetchSharedFolders();

  useEffect(
    () => {
      setSelected(singleItemORM ? initMap(foldersToDisplay, singleItemORM, isPublisherRoute) : {})
    },
    [singleItemORM, showSharedFolders, isPublisherRoute],
  )

  useEffect(() => {
    if (isOnline) {
      fetchSharedFolders().catch((e) => {
        console.error('Error fetching shared folders', e)
        setError('Error fetching shared folders, please try again later.')
      })
    }
  }, [isOnline])

  useEffect(
    () => { onFolderSelect?.(selected) },
    [onFolderSelect, selected],
  )

  const toggleSelected = (folderORM: FolderORM) => () =>
    setSelected(p => {
      // If this document is already in a folder, it's locked
      if (p[folderORM.model.id]?.locked)
      { return p }

      return {
        ...p,
        [folderORM.model.id]: p[folderORM.model.id]
          ? undefined
          : { folder: folderORM, locked: false },
      }
    })

  const toggleExpanded = (folderORM: FolderORM) => () =>
    setExpanded(p => ({
      ...p,
      [folderORM.model.id]: p[folderORM.model.id]
        ? undefined
        : true,
    }))

  if (error) {
    return (<DNABox appearance="col" fill>

      <InformationMessage
        text={error}
        variance="danger"
      />
    </DNABox>)
  }

  return (
    <DNABox
      as={ScrollView}
      appearance="col"
      spacing="md"
      fill
      style={{ width: 560, height: 320, paddingRight: 24 }}
    >
      {
        foldersToDisplay.map((folderORM) => (
          <DNABox fill appearance="col" key={folderORM.model.id}>
            {/* Root Folder */}
            <DNANestedFolderRow
              folderORM={folderORM}
              onPress={toggleSelected(folderORM)}
              onExpand={toggleExpanded(folderORM)}
              isChecked={!!selected[folderORM.model.id]}
              isCheckedDisabled={!!selected[folderORM.model.id]?.locked}
              isExpanded={!!expanded[folderORM.model.id]}
            />
            {/* Nested Folder */}
            <Collapsible collapsed={!expanded[folderORM.model.id]}>
              <DNABox
                spacing="md"
                appearance="col"
                style={{ marginTop: 16, marginLeft: 48 }}
              >
                {
                  folderORM
                    .relations
                    .items
                    .map(item => item.relations.itemORM)
                    .filter(isFolderORM)
                    .map(itemORM => (
                      <DNANestedFolderRow
                        folderORM={itemORM}
                        onPress={toggleSelected(itemORM)}
                        isChecked={!!selected[itemORM.model.id]}
                        isCheckedDisabled={!!selected[itemORM.model.id]?.locked}
                        key={itemORM.model.id}
                      />
                    ))
                }
              </DNABox>
            </Collapsible>
          </DNABox>
        ))
      }
    </DNABox>
  )
}

export default DNANestedFolderList
