/* eslint-disable max-len */
import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgCalendar = (props: SvgProps) => (
  <Svg
    width={32}
    height={32}
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M26 4h-3V3a1 1 0 0 0-2 0v1H11V3a1 1 0 0 0-2 0v1H6a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2ZM9 6v1a1 1 0 0 0 2 0V6h10v1a1 1 0 0 0 2 0V6h3v4H6V6h3Zm17 20H6V12h20v14ZM14 15v8a1 1 0 0 1-2 0v-6.383l-.553.278a1 1 0 1 1-.894-1.79l2-1A1 1 0 0 1 14 15Zm7.395 3.806L19 22h2a1 1 0 0 1 0 2h-4a1 1 0 0 1-.8-1.6l3.598-4.796a1.002 1.002 0 0 0-.623-1.585 1.002 1.002 0 0 0-1.041.481 1 1 0 1 1-1.732-1 3 3 0 1 1 4.993 3.306Z"
      fill={props.color ?? '#fff'}
    />
  </Svg>
);
export default SvgCalendar;
