import React from 'react'
import {
  DNABox,
  DNAText,
  luxColors,
} from '@alucio/lux-ui'

import { Controller, useFormContext } from 'react-hook-form'

import InputComponent from 'src/components/Publishers/InputComponent'

const ExportS3Integration = () => {
  const { control, formState: { errors } } = useFormContext<{
    name: string
    clientConfigurationFields: {
      s3_bucket: string
      prefix: string
      delimiter: string
      key_target: string
    }
  }>()

  return (
    <DNABox fill>
      <DNABox appearance="col" spacing="md" fill>
        <DNABox appearance="col" spacing="sm">
          <Controller
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <InputComponent
                  onChangeText={onChange}
                  onBlur={onBlur}
                  value={value}
                  characterLimit={250}
                  showCharacterCounter={true}
                  removeMarginPadding
                  inputStyle={errors.name && { borderColor: luxColors.error.primary }}
                  title="Integration name"
                />
              )
            }}
            name="name"
            rules={{ required: 'This field is required', maxLength: 250 }}
            control={control}
          />
          {errors.name && errors.name.type === 'required' &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.name.message}
            </DNAText>
          }
          {errors.name && errors.name.type === 'maxLength' &&
            <DNAText style={{ color: luxColors.error.primary }}>
              Maximum character limit is 250
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="sm">
          <Controller
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <InputComponent
                  onChangeText={onChange}
                  onBlur={onBlur}
                  value={value}
                  characterLimit={250}
                  showCharacterCounter={true}
                  removeMarginPadding
                  inputStyle={
                    (errors.clientConfigurationFields && errors.clientConfigurationFields.s3_bucket) &&
                    { borderColor: luxColors.error.primary }}
                  placeHolder="my-bucket-name"
                  title="S3 Bucket"
                />
              )
            }}
            name="clientConfigurationFields.s3_bucket"
            rules={{
              required: 'This field is required',
            }}
            control={control}
          />
          {(errors.clientConfigurationFields && errors.clientConfigurationFields.s3_bucket) &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.s3_bucket.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="sm">
          <Controller
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <InputComponent
                  onChangeText={onChange}
                  onBlur={onBlur}
                  value={value}
                  characterLimit={250}
                  showCharacterCounter={true}
                  removeMarginPadding
                  inputStyle={
                    (errors.clientConfigurationFields && errors.clientConfigurationFields.prefix) &&
                    { borderColor: luxColors.error.primary }}
                  placeHolder="my-prefix"
                  title="Prefix"
                />
              )
            }}
            name="clientConfigurationFields.prefix"
            rules={{
              required: 'This field is required',
            }}
            control={control}
          />
          {(errors.clientConfigurationFields && errors.clientConfigurationFields.prefix) &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.prefix.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="sm">
          <Controller
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <InputComponent
                  onChangeText={onChange}
                  onBlur={onBlur}
                  value={value}
                  characterLimit={1}
                  showCharacterCounter={true}
                  removeMarginPadding
                  inputStyle={
                    (errors.clientConfigurationFields && errors.clientConfigurationFields.delimiter) &&
                    { borderColor: luxColors.error.primary }}
                  defaultValue={','}
                  title="Delimiter"
                />
              )
            }}
            name="clientConfigurationFields.delimiter"
            rules={{
              required: 'This field is required',
              maxLength: {
                value: 1,
                message: 'Maximum character limit is 1',
              },
            }}
            control={control}
          />
          {(errors.clientConfigurationFields && errors.clientConfigurationFields.delimiter) &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.delimiter.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="sm">
          <Controller
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <InputComponent
                  onChangeText={onChange}
                  onBlur={onBlur}
                  value={value}
                  characterLimit={250}
                  showCharacterCounter={true}
                  removeMarginPadding
                  inputStyle={
                    (errors.clientConfigurationFields && errors.clientConfigurationFields.key_target) &&
                    { borderColor: luxColors.error.primary }}
                  placeHolder="key_target"
                  title="Key target"
                  required={false}
                />
              )
            }}
            name="clientConfigurationFields.key_target"
            control={control}
          />
          {(errors.clientConfigurationFields && errors.clientConfigurationFields.key_target) &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.key_target?.message}
            </DNAText>
          }
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

export default ExportS3Integration
