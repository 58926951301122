import { Page } from '@alucio/aws-beacon-amplify/src/models'

export const isAllSlidesChecked = (
  pages: Page[],
  selectedSlides: Record<string, boolean>,
) => {
  return (
    pages.length === Object.values(selectedSlides).length &&
    Object.values(selectedSlides).every(selected => selected)
  )
}
export const addSlidesToGroup = (pageIds: string[] | undefined, selectedPages, group) => {
  const pagesToAdd = pageIds ?? Object
    .entries(selectedPages)
    .filter(([_, v]) => v)
    .map(([k]) => k)
    .sort((a, b) => a.localeCompare(b, 'en', { numeric: true }))
  group.pageIds = [...group.pageIds ?? [], ...pagesToAdd];

  // ANALYTIC TRACKING: adding slide to a group
  pagesToAdd.forEach(slideId => {
    analytics?.track('ADD_SLIDE', { groupId: group.id, pageId: slideId });
  });
}
