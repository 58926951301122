/* eslint-disable max-len */
import * as React from 'react';
import Svg, { SvgProps, Rect, Path } from 'react-native-svg';
const SvgPresentedIcon = (props: SvgProps) => (
  <Svg
    width={40}
    height={40}
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Rect width={40} height={40} rx={4} fill="#000" />
    <Rect x={8} y={8} width={24} height={24} rx={4} fill="#2C2C2C" />
    <Path
      d="m11.667 14.391 1.066-1.058 13.934 13.933-1.059 1.067-2.566-2.567A9.647 9.647 0 0 1 20 26.25c-4.166 0-7.725-2.592-9.166-6.25a9.795 9.795 0 0 1 2.658-3.784l-1.825-1.825ZM20 17.5a2.5 2.5 0 0 1 2.358 3.333l-3.191-3.192c.258-.091.541-.141.833-.141Zm0-3.75c4.167 0 7.725 2.591 9.167 6.25a9.824 9.824 0 0 1-3.334 4.325l-1.183-1.192A8.22 8.22 0 0 0 27.35 20 8.184 8.184 0 0 0 20 15.416c-.908 0-1.8.15-2.633.417l-1.284-1.275A9.886 9.886 0 0 1 20 13.75ZM12.65 20A8.184 8.184 0 0 0 20 24.583c.575 0 1.142-.058 1.667-.175l-1.9-1.908a2.553 2.553 0 0 1-2.267-2.267l-2.833-2.842A8.213 8.213 0 0 0 12.65 20Z"
      fill="#fff"
    />
  </Svg>
);
export default SvgPresentedIcon;
