import React from 'react'
import { DNABox, DNAButton, DNAText } from '@alucio/lux-ui'
import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  commonPadding: {
    paddingHorizontal: 24,
    paddingVertical: 15,
  },
})

interface Props {
  closeDrawer: () => void,
  title: string
}

const FileDrawerHeader: React.FC<Props> = (props) => {
  const { title } = props
  return (
    <DNABox spacing="between" style={styles.commonPadding} alignX="center" alignY="center">
      <DNAText bold h5 status="brand">{title}</DNAText>
      <DNABox style={{ flexShrink: 1 }}>
        <DNAButton
          status="gray"
          appearance="ghost"
          size="md"
          padding="sm"
          iconLeft="close"
          onPress={props.closeDrawer}
        />
      </DNABox>
    </DNABox>
  )
}

export default FileDrawerHeader
