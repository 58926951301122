import React, { useCallback, useMemo } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import { DNABox, DNAButton, DNAChip, DNAText, util } from '@alucio/lux-ui';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { DocumentVersionORM } from 'src/types/orms';
import { Page } from '@alucio/aws-beacon-amplify/src/models';
import { getSlideTitle, getThumbURL } from 'src/utils/thumbnailHelpers';
import { usePublisherVersioningState }
  from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import { useMatchSlidesState } from 'src/state/machines/publisherVersioning/MatchSlides/MatchSlidesProvider';
import { useMediaQuery } from 'react-responsive';
// Dnd
import {
  useSingleItemDnd,
  POOL_CONTAINER_ID,
  SortableItem,
} from 'src/components/DnD/DNASingleItemDnd';
// Virtualized thumbnail
import {
  RecyclerListView,
  DataProvider,
  LayoutProvider,
  RecyclerListViewProps,
} from 'recyclerlistview';
import { thumbnailSizeDimensions } from 'src/hooks/useThumbnailSize/useThumbnailSize';
import { useDispatch } from 'src/state/redux';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import MatchSlidesPreviewModal from './MatchSlidesPreviewModal';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as matchSlidesSelector from 'src/state/machines/publisherVersioning/MatchSlides/matchSlides.selector'

const LARGER_SCREEN_PADDING = 44
const SMALLER_SCREEN_PADDING = 48
const SLIDES_GROUP_POOL_CONTAINER_WIDTH = thumbnailSizeDimensions.lg.width + LARGER_SCREEN_PADDING;
const SLIDES_GROUP_POOL_CONTAINER_WIDTH_SMALL_SCREEN = thumbnailSizeDimensions.md.width + SMALLER_SCREEN_PADDING;

const styles = StyleSheet.create({
  recyclerListView: {
    flex: 1,
    paddingVertical: 24,
  },
  mainWrapperDefaultStyles: {
    backgroundColor: colors['color-gray-10'],
    width: 0,
    overflow: 'hidden',
  },
  mainWrapper: {
    borderRightColor: colors['color-gray-100'],
    borderRightWidth: 1,
  },
  headerContainer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderBottomColor: colors['color-gray-100'],
    borderBottomWidth: 1,
  },
  headerTextPrimary: {
    fontSize: 16,
  },
  headerTextSecondary: {
    fontSize: 14,
  },
});

/** RECYCLERLISTVIEW TYPES */
export type RViewProps = {
  layoutProvider: LayoutProvider,
  dataProvider: DataProvider,
  rowRenderer: RecyclerListViewProps['rowRenderer'],
  style: ViewStyle,
  canChangeSize: boolean
}

enum ItemType {
  SLIDE,
}

const Slide: React.FC<{
  idx: number
  page: Page,
  latestPublishedDocumentVersionORM: DocumentVersionORM,
}> = ({ idx, page, latestPublishedDocumentVersionORM }) => {
  const {
    currentDocumentVersionORM,
    latestPublishedVersionContentPageData,
    isNonDocumentFile,
  } = usePublisherVersioningState();
  const dispatch = useDispatch()
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1300px)',
  })

  const { poolItems } = useSingleItemDnd();
  const isConverting = currentDocumentVersionORM.model.conversionStatus !== 'PROCESSED'
  const thumbURL = isNonDocumentFile
    ? latestPublishedDocumentVersionORM.meta.assets.thumbnailKey?.replace('small', 'medium')
    : getThumbURL(latestPublishedDocumentVersionORM, page.number)
  const thumbnailTitle = getSlideTitle(
    latestPublishedDocumentVersionORM,
    page.number,
    latestPublishedVersionContentPageData,
  )

  const itemId = poolItems[idx]?.itemId
  const id = poolItems[idx]?.id

  const togglePreviewModal = ({ pageNum }) => {
    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: (props) => (
        <MatchSlidesPreviewModal
          previousDocumentVersionORM={latestPublishedDocumentVersionORM}
          previousPageNum={pageNum}
          latestPublishedVersionContentPageData={latestPublishedVersionContentPageData}
          {...props}
        />
      ),
    }))
  }

  return (
    <SortableItem
      key={itemId}
      id={id}
      itemId={itemId}
      containerId={POOL_CONTAINER_ID}
    >
      <DNABox
        fill
        childFill={1}
        appearance="col"
        alignX="center"
        alignY="start"
        key={`last-version-thumbnail-${page.pageId}`}
        testID={`last-version-thumbnail-${page.pageId}`}
      >
        <DNAThumbnail
          deferLoad={isConverting}
          variant={DNAThumbnail.Variants.MATCH_SLIDE}
          key={thumbURL}
          s3URL={thumbURL}
          useLoadingIndicator
          thumbnailTitle={thumbnailTitle}
          size={isLargeScreen ? 'lg' : 'md'}
          pageNumber={page.number}
          onCheck={() => togglePreviewModal({
            pageNum: page.number,
          })}
        />
      </DNABox>
    </SortableItem>
  )
}

const Header: React.FC = () => {
  const { toggleSlidesGroupPoolVisible } = useMatchSlidesState();
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1300px)',
  })

  return (
    <DNABox alignY="center" spacing="between" style={styles.headerContainer}>
      {/* LEFT */}
      <DNABox appearance="col" spacing="sm">
        <DNABox spacing="sm">
          <DNAText status="flatAlt" style={styles.headerTextPrimary}>Version 1.0</DNAText>
          <DNAChip appearance="tag">CURRENT</DNAChip>
        </DNABox>
        <DNABox alignX="center">
          <DNAText
            status="primary"
            style={util.mergeStyles(
              undefined,
              styles.headerTextSecondary,
              { maxWidth: isLargeScreen ? 300 : 200 },
            )}
            numberOfLines={2}
          >
            Drag & drop slides below into the dotted box
          </DNAText>
        </DNABox>
      </DNABox>
      {/* RIGHT */}
      <DNAButton
        status="tertiary"
        appearance="outline"
        iconLeft="close"
        size={isLargeScreen ? 'md' : 'sm'}
        padding="sm"
        onPress={toggleSlidesGroupPoolVisible}
      />
    </DNABox>
  )
}

const SlidesGroupPool: React.FC = () => {
  const { latestPublishedDocumentVersionORM } = useMatchSlidesState();
  const { service, slideGroupPoolRef } = useMatchSlidesState();
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1300px)',
  })

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      matchSlidesSelector.slidesGroupPoolVisible,
    ),
  )

  const dataProviderInit = useMemo(() => {
    return new DataProvider((r1: string, r2: string) => {
      return r1 !== r2;
    });
  }, []);

  // ROW DATA
  const dataProvider = useMemo(() => {
    return dataProviderInit.cloneWithRows(latestPublishedDocumentVersionORM.relations.pages)
  }, [latestPublishedDocumentVersionORM]);

  const layoutProvider = useMemo(() => {
    const _layoutProvider = new LayoutProvider((_idx) => ItemType.SLIDE,
      (type, dim, _idx) => {
        const thumbnailSizeDimension = thumbnailSizeDimensions.lg;
        const HORIZONTAL_PADDING = 28; // Includes the slide title height
        const BOTTOM_MARGIN = 24;
        switch (type) {
          case ItemType.SLIDE: {
            dim.width = isLargeScreen
              ? SLIDES_GROUP_POOL_CONTAINER_WIDTH : SLIDES_GROUP_POOL_CONTAINER_WIDTH_SMALL_SCREEN;
            dim.height = thumbnailSizeDimension.height + HORIZONTAL_PADDING + BOTTOM_MARGIN;
            break;
          }
        }
      })
    _layoutProvider.shouldRefreshWithAnchoring = false;
    return _layoutProvider;
  }, []);

  const rowRenderer = useCallback<RecyclerListViewProps['rowRenderer']>(
    // @ts-expect-error
    (type, data, idx, _extendedState: ExtendedState) => {
      switch (type) {
        case ItemType.SLIDE: {
          const { model } = data;
          const page = model as Page
          return (
            <Slide
              idx={idx}
              page={page}
              latestPublishedDocumentVersionORM={latestPublishedDocumentVersionORM}
            />
          )
        }
        default: {
          return null
        }
      }
    },
    [layoutProvider, dataProvider, latestPublishedDocumentVersionORM])

  const mainWidth = cond.slidesGroupPoolVisible
    ? isLargeScreen ? SLIDES_GROUP_POOL_CONTAINER_WIDTH : SLIDES_GROUP_POOL_CONTAINER_WIDTH_SMALL_SCREEN
    : 0

  return (
    <DNABox
      appearance="col"
      testID="slide-pool-container"
      style={util.mergeStyles(
        undefined,
        styles.mainWrapperDefaultStyles,
        [styles.mainWrapper, cond.slidesGroupPoolVisible],
        { width: mainWidth },
      )}
    >
      {/* HEADER */}
      <Header/>
      {/* SLIDES */}
      <RecyclerListView
        key={latestPublishedDocumentVersionORM.model.id}
        layoutProvider={layoutProvider}
        dataProvider={dataProvider}
        rowRenderer={rowRenderer}
        style={styles.recyclerListView}
        canChangeSize
        ref={slideGroupPoolRef}
      />
      <DNABox alignX="end" style={{ padding: 12 }}>
        <DNAButton
          appearance="outline"
          status="tertiary"
          size="lg"
          padding="xs"
          iconLeft="arrow-up"
          onPress={() => slideGroupPoolRef.current?.scrollToTop(true)}
        />
      </DNABox>
    </DNABox>
  )
}

export default SlidesGroupPool;
