import React from 'react'
import { DNABox, DNASelect } from '@alucio/lux-ui'
import { useReport } from './ReportProvider';
import { SelectGroup } from '@alucio/lux-ui/src/components/controls/Select/Select';
import { ReportGroup, ReportItem } from '@alucio/aws-beacon-amplify/src/models';

export const ReportSelector = () => {
  const {
    selectedDashboard, onReportSelected,
    isLoadedReportsList, selectedReport,
    folderName,
    reportList,
  } = useReport();

  if (!isLoadedReportsList) return null
  const distinctGroups = reportList.reduce((acc: ReportGroup[], report: ReportItem) => {
    if (!acc.find(group => group.name === report.group.name)) {
      acc.push(report.group)
    }
    return acc
  }, [])

  const groups = distinctGroups?.sort( (a, b) => a.order - b.order ).map( p => p.name) || []

  // if there is just one folder (groups > 2) we dont need to use the grouping selector

  return (
    <DNABox appearance="col" spacing="sm">
      <DNASelect
        size="lgPopover"
        placeholder="Select a Report"
        value={selectedReport?.name}
        onSelect={(v) => {
          if (Array.isArray(v)) return;
          const section = groups[v.section || 0]
          const report = reportList.filter(item => item.group?.name === section)[v.row]
          report && onReportSelected(report)
        }}
      >
        {
          groups.length > 2
            ? groups.map((group) => {
              return (
                group === folderName ? <DNASelect.Item
                  key={selectedDashboard?.dashboardId}
                  title="Dashboard"
                />
                  : <SelectGroup key={group} title={group?.toUpperCase()}>
                    {reportList.filter(p => p.group?.name === group)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((x) => <DNASelect.Item key={x.dashboardId} title={x.name} appearance="grouped" />)}
                  </SelectGroup>
              );
            })
            : reportList.sort((a, b) => a.name.localeCompare(b.name))
              .map((x) => <DNASelect.Item key={x.dashboardId} title={x.name} appearance="grouped" />)

        }
      </DNASelect>
    </DNABox>
  )
}
