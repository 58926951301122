import React, { useState } from 'react'
import { useDispatch } from 'src/state/redux'

import { DNAModal, GenericToast, ToastOrientations } from '@alucio/lux-ui'
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal'
import { DocumentORM } from 'src/types/types'
import { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast'
import { documentVersionActions } from 'src/state/redux/slice/documentVersion'
import InputComponent from 'src/components/Publishers/InputComponent'

export type DNAFileRenameModalProps = {
  documentORM: DocumentORM
  toast?: ToastActions
}

const DNAFileRenameModal: DNAModalVariant<DNAFileRenameModalProps> = (props) => {
  const {
    documentORM,
    toggleModal,
    toast,
  } = props
  const { latestUsableDocumentVersionORM } = documentORM.relations.version
  const [customTitle, setCustomTitle] = useState<string | undefined>(latestUsableDocumentVersionORM.model.title)
  const dispatch = useDispatch()
  const onAction = () => {
    /** NOTE: the rename reducer handles empty strings by resetting back to the source file name */
    dispatch(documentVersionActions.rename(latestUsableDocumentVersionORM, customTitle ))
    analytics?.track('VIEWER_DOCUMENT_RENAME', {
      action: 'RENAME',
      category: 'VIEWER_DOCUMENT',
      documentId: documentORM.model.id,
      documentVersionId: latestUsableDocumentVersionORM.model.id,
    });
    toast?.add(
      <GenericToast
        title="File Renamed."
        status="information"
      />,
      ToastOrientations.TOP_RIGHT,
    )

    toggleModal()
  }

  return (
    <DNAModal>
      <DNAModal.Header>Rename File</DNAModal.Header>
      <DNAModal.Body>
        <InputComponent
          testID="rename-textbox"
          value={customTitle}
          required={false}
          onChangeText={setCustomTitle}
          title="Name"
          removeMarginPadding={true}
          inputStyle={{ width: 512 }}
          onSubmitEditing={onAction}
        />
      </DNAModal.Body>
      <DNAModal.Confirmation
        onCancel={toggleModal}
        onAction={onAction}
        actionLabel="Save"
        actionStatus="primary"
      />
    </DNAModal>
  )
}

export default DNAFileRenameModal
