import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Animated, Easing, StyleSheet } from 'react-native';
import { DNABox } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import * as logger from 'src/utils/logger';
import { DNALoaderContexts, DNALoaderEvents } from '../Loader/DNALoader';

const S = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'auto',
  },
  spinnerBox: {
    backgroundColor: colors['color-black'],
    borderRadius: 100,
    paddingVertical: 24,
    paddingHorizontal: 32,
    bottom: 48,
  },
  pulseContainer: {
    width: 100,
  },
  pulseBubble: {
    width: 16,
    height: 16,
    borderRadius: 50,
    backgroundColor: colors['color-text-white'],
  },
})

interface DNASlideTransitionProps {
  analyticsEventType?: DNALoaderEvents
  analyticsContext?: DNALoaderContexts
}

const DNASlideTransition: React.FC<DNASlideTransitionProps> = ({
  analyticsEventType,
  analyticsContext,
}) => {
  const [containerDimension, setContainerDimension] = useState({ height: 0, width: 0 });
  const containerRef = useRef<null | HTMLDivElement>(null);
  const pulse1 = useRef(new Animated.Value(1)).current;
  const pulse2 = useRef(new Animated.Value(1)).current;
  const pulse3 = useRef(new Animated.Value(1)).current;
  const opacity1 = useRef(new Animated.Value(1)).current;
  const opacity2 = useRef(new Animated.Value(1)).current;
  const opacity3 = useRef(new Animated.Value(1)).current;

  const updateSize = () => {
    if (containerRef.current) {
      const { height, width } = containerRef.current.getBoundingClientRect();
      setContainerDimension({ height, width });
    }
  };

  useEffect(() => {
    if (!containerDimension.width) updateSize()
  }, [containerDimension])

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  // analytics
  useEffect(() => {
    if (analyticsEventType) {
      const startTime = (new Date()).getTime();

      return () => {
        const endTime = (new Date()).getTime();
        const resolution = endTime - startTime;
        const loadingInfo = {
          analyticsEventType,
          analyticsContext,
          time: resolution,
        }
        logger.debug.debug('LOADER_LOAD_TIME', loadingInfo)
        analytics?.track('LOADER_LOAD_TIME', loadingInfo);
      }
    }
  }, [])

  useEffect(() => {
    const createPulseAnimation = (pulse, opacity, delay) => {
      Animated.loop(
        Animated.sequence([
          Animated.parallel([
            Animated.timing(pulse, {
              toValue: 0.75,
              duration: 600,
              easing: Easing.ease,
              useNativeDriver: true,
              delay,
            }),
            Animated.timing(opacity, {
              toValue: 0.25,
              duration: 600,
              easing: Easing.ease,
              useNativeDriver: true,
              delay,
            }),
          ]),
          Animated.parallel([
            Animated.timing(pulse, {
              toValue: 1,
              duration: 600,
              easing: Easing.ease,
              useNativeDriver: true,
              delay,
            }),
            Animated.timing(opacity, {
              toValue: 1,
              duration: 600,
              easing: Easing.ease,
              useNativeDriver: true,
              delay,
            }),
          ]),
        ]),
        {
          iterations: -1,
        },
      ).start();
    };

    createPulseAnimation(pulse1, opacity1, 0);
    createPulseAnimation(pulse2, opacity2, 200);
    createPulseAnimation(pulse3, opacity3, 400);
  }, [pulse1, pulse2, pulse3, opacity1, opacity2, opacity3]);

  return (
    <DNABox
      ref={containerRef}
      alignX="center"
      alignY="end"
      style={S.container}
    >
      <DNABox alignX="center" alignY="center" style={S.spinnerBox}>
        <DNABox alignY="center" spacing="between" style={S.pulseContainer}>
          <Animated.View style={[S.pulseBubble, { transform: [{ scale: pulse1 }], opacity: opacity1 }]} />
          <Animated.View style={[S.pulseBubble, { transform: [{ scale: pulse2 }], opacity: opacity2 }]} />
          <Animated.View style={[S.pulseBubble, { transform: [{ scale: pulse3 }], opacity: opacity3 }]} />
        </DNABox>
      </DNABox>
    </DNABox>
  );
}

export default DNASlideTransition
