import React from 'react'
import { Dispatch } from 'redux'
import { useDispatch } from 'src/state/redux'
import { DocumentStatus } from '@alucio/aws-beacon-amplify/src/models'
import { documentActions } from 'src/state/redux/slice/document'
import { userNotationsActions } from 'src/state/redux/slice/userNotations'

import {
  DNAModal,
  LuxStatus,
  LuxStatusV2,
  GenericToast,
  ToastOrientations,
  DNAText,
  Iffy,
  DNABox,
} from '@alucio/lux-ui'
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal'
import { DocumentORM } from 'src/types/types'
import { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast'
import { styles } from './DNACommonConfirmation';

type AvailableStatusActions = Exclude<DocumentStatus, DocumentStatus.NOT_PUBLISHED> |
  Exclude<keyof typeof DocumentStatus, 'NOT_PUBLISHED'> | 'UNARCHIVE' | 'UNREVOKE'

interface StatusAction {
  header: string,
  message: string,
  messageStatus?: LuxStatusV2,
  body?: React.ReactElement,
  actionLabel: string,
  actionStatus?: LuxStatus,
  action: (d: DocumentORM) => void, // [TODO]: Probably a better way to typecheck an action payload
  showToast: (toast: ToastActions) => void,
  additionalAction?: (dispatch: Dispatch, d: DocumentORM) => void,
}

function deleteUserNotations (dispatch: Dispatch, documentORM: DocumentORM) {
  documentORM.relations.documentVersions.forEach(docVer => {
    const userNotations = docVer.relations.userNotations
    if (userNotations) dispatch(userNotationsActions.deleteUserNotations(userNotations))
  })
}

/**
 * TODO: These properties should be refactored to utilize the new body property on the StatusAction interface
 * to populate the DNAModal.Body in the DNAModal structure below. This would allow for more flexibility and cleaner
 * implementation of the DNAModal.
 */
const docStatus: { [K in AvailableStatusActions]: StatusAction } = {
  [DocumentStatus.ARCHIVED]: {
    header: 'Archive file?',
    message: 'This document will have limited visibility to your audience.',
    actionLabel: 'Archive',
    action: documentActions.archive,
    showToast(toast: ToastActions) {
      toast.add(
        <GenericToast
          title="File archived."
          status="warning"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    },
  },
  [DocumentStatus.ARCHIVED_WITH_VERSIONS]: {
    header: 'Archive file?',
    message: 'This document and associated version(s) will have limited visibility to your audience.',
    actionLabel: 'Archive',
    action: documentActions.archive,
    showToast(toast: ToastActions) {
      toast.add(
        <GenericToast
          title="File archived."
          status="warning"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    },
  },
  [DocumentStatus.DELETED]: {
    header: 'Delete file?',
    message: 'This document will be removed from the Beacon database permanently.',
    actionLabel: 'Delete',
    action: documentActions.delete,
    showToast(toast: ToastActions) {
      toast.add(
        <GenericToast
          title="File deleted"
          status="error"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    },
    additionalAction: deleteUserNotations,
  },
  [DocumentStatus.DELETED_WITH_VERSIONS]: {
    header: 'Delete file?',
    message: 'This document and associated version(s) will be removed from the Beacon database permanently.',
    actionLabel: 'Delete',
    action: documentActions.delete,
    showToast(toast: ToastActions) {
      toast.add(
        <GenericToast
          title="File deleted"
          status="error"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    },
    additionalAction: deleteUserNotations,
  },
  [DocumentStatus.PUBLISHED]: {
    header: 'Ready to Publish?',
    message: 'Document(s) will be visible and accessible to your audience.',
    actionStatus: 'success',
    actionLabel: 'Publish',
    action: documentActions.publish,
    showToast(toast: ToastActions) {
      toast.add(
        <GenericToast
          title="File successfully published."
          status="success"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    },
  },
  [DocumentStatus.REVOKED]: {
    header: 'Revoke file?',
    message: 'This document will not be visible and accessible to your audience.',
    actionLabel: 'Revoke',
    action: documentActions.revoke,
    showToast(toast: ToastActions) {
      toast.add(
        <GenericToast
          title="File revoked."
          status="warning"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    },
  },
  [DocumentStatus.REVOKED_WITH_VERSIONS]: {
    header: 'Revoke file?',
    message: 'This document and associated version(s) will not be visible and accessible to your audience.',
    actionLabel: 'Revoke',
    action: documentActions.revoke,
    showToast(toast: ToastActions) {
      toast.add(
        <GenericToast
          title="File revoked."
          status="warning"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    },
  },
  'UNARCHIVE': {
    header: 'Unarchive file?',
    message: 'This file will revert to its previous state.',
    actionLabel: 'Unarchive',
    action: documentActions.unarchive,
    actionStatus: 'info',
    showToast(toast: ToastActions) {
      toast.add(
        <GenericToast
          title="File unarchived."
          status="success"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    },
  },
  'UNREVOKE': {
    header: 'Unrevoke file?',
    message: 'This file will revert to its previous state.',
    actionLabel: 'Unrevoke',
    action: documentActions.unrevoke,
    actionStatus: 'info',
    showToast(toast: ToastActions) {
      toast.add(
        <GenericToast
          title="File unrevoked."
          status="success"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    },
  },
}

/**
 * Currently userdocs only support being deleted, this typing can be updated to
 * AvailableStatusActions as it is in the const above to support other statuses
 * if necessary in the future
 */
const userDocStatus: Record<DocumentStatus.DELETED, StatusAction>  = {
  'DELETED': {
    header: 'Delete item?',
    message: 'Any content from this file used in your presentations will also be deleted.',
    messageStatus: 'danger',
    actionLabel: 'Delete',
    action: documentActions.delete,
    showToast(toast: ToastActions) {
      toast.add(
        <GenericToast
          title="File deleted"
          status="success"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    },
  },
}

export const DNADocumentStatusChange: DNAModalVariant<{
  documentORM: DocumentORM,
  action: AvailableStatusActions,
  toast?: ToastActions,
  onConfirmCallback?: (cb: () => void) => void
}> = (props) => {
  const { documentORM, toggleModal, action, toast, onConfirmCallback } = props
  const dispatch = useDispatch()
  const isUserDoc = documentORM.model.accessLevel === 'USER'
  const selectedAction: StatusAction = isUserDoc ? userDocStatus[action] : docStatus[action]
  const currentDocStatus = documentORM.model.status
  const isDeletingRevokedDoc = action === 'DELETED' &&
    (currentDocStatus === 'REVOKED' || currentDocStatus === 'REVOKED_WITH_VERSIONS')

  // TODO: refactor this to use the new body property of the StatusAction interface
  // Only show the warning msg when the action is not publish
  // and is not deleting a revoked document (file is already out of circulation)
  const dontShowWarningActions = ['PUBLISHED', 'UNARCHIVE', 'UNREVOKE']
  const showWarning = !isDeletingRevokedDoc && !dontShowWarningActions.includes(action) && !isUserDoc

  const handleAction = () => {
    const executeAction = () => {
      const uppercasedLabel = docStatus[action].actionLabel.toUpperCase()

      // [TODO-2126] - Consider moving analytics to a more centralized layer, like Redux
      analytics?.track(`${isUserDoc ? 'VIEWER_' : ''}DOCUMENT_${uppercasedLabel}`, {
        action: uppercasedLabel,
        category: `${isUserDoc ? 'VIEWER_' : ''}DOCUMENT`,
        documentId: documentORM.model.id,
        documentVersionId: documentORM.relations.version.latestDocumentVersionORM.model.id,
      })

      // Dispatch the correct Redux action
      // @ts-expect-error TODO: Better type this
      dispatch(selectedAction.action(documentORM))
      if (selectedAction.additionalAction) selectedAction.additionalAction(dispatch, documentORM)
      if (toast) selectedAction.showToast(toast)
      toggleModal()
    }

    // [NOTE] - onConfirmCallback is a way to defer the execution of this action based on the completion of another function.
    //          i.e. We use to first close the versioning modal THEN call the appropriate redux action (such as delete, acrhive, revoke)
    if (onConfirmCallback) {
      onConfirmCallback?.(executeAction)
    }
    else {
      executeAction()
    }
  }

  return (
    <DNAModal>
      <DNAModal.Header style={styles.header} onClose={toggleModal}>
        {selectedAction.header}
      </DNAModal.Header>
      <Iffy is={!showWarning}>
        <DNAModal.Body style={styles.body}>
          <DNAText status={selectedAction.messageStatus}>{selectedAction.message}</DNAText>
        </DNAModal.Body>
      </Iffy>
      <Iffy is={showWarning}>
        {/* TODO: refactor this to use the new body property of the StatusAction interface */}
        <DNAModal.Body style={styles.body}>
          <DNABox appearance="col" fill spacing="md">
            <DNAText status={selectedAction.messageStatus}>
              {selectedAction.message}
            </DNAText>
            <DNABox appearance="col" spacing="md" fill>
              <DNAText status="danger" style={{ lineHeight: 24 }}>
                Given this file is no longer meant to be in public circulation,
                there will be downstream impact to potential modified files in use by the field team.
                In such cases, the team will be alerted in order to resolve as necessary.
              </DNAText>
            </DNABox>
          </DNABox>
        </DNAModal.Body>
      </Iffy>
      <DNAModal.Confirmation
        onCancel={toggleModal}
        onAction={handleAction}
        actionLabel={selectedAction.actionLabel}
        actionStatus={selectedAction.actionStatus}
      />
    </DNAModal>
  )
}

export default DNADocumentStatusChange
