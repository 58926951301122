import React, { PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import {
  CustomFieldDefinition,
  CustomFormRecordRelationshipModel,
  CustomFormRecordStatus,
} from '@alucio/aws-beacon-amplify/src/models';
import { DNABox, DNASpinner, DNAText, Iffy, InformationMessage } from '@alucio/lux-ui';
import { ComposableForm, useComposableForm } from '../CustomFields/ComposableForm';
import CustomFormProvider, { useCustomFormRecordProvider }
  from 'src/state/context/CustomFormProvider/CustomFormProvider';
import { ColumnFieldsWrapper } from '../CustomFields/ColumnCustomFieldsWrapper';
import { CustomFieldValuesMap } from 'src/types/orms';
import { StandaloneForm } from 'src/state/redux/selector/crm';
import { useCustomFormRecordById } from 'src/state/redux/selector/customFormRecord';
import { getMappedCustomValues, Usages } from 'src/state/redux/selector/common';

export const styles = StyleSheet.create({
  contentWrapper: {
    height: 'fit-content',
    minHeight: '100%',
    padding: 24,
  },
  content: {
    backgroundColor: colors['color-text-white'],
    borderRadius: 6,
    padding: 24,
  },
  wrapper: {
    backgroundColor: colors['color-gray-50'],
    overflow: 'scroll',
  },
});

interface Props {
  customFields: CustomFieldDefinition[],
  customFormRecordId?: string,
  defaultValues?: CustomFieldValuesMap,
  disableForm?: boolean,
  onSave?: (recordId: string) => void,
  parentId?: string,
  parentModel?: CustomFormRecordRelationshipModel,
  usage: Usages,
  standaloneForm: StandaloneForm,
}

export const CustomFormRecord = () => {
  const { customFields } = useComposableForm();
  const { errorMessage, isSaving } = useCustomFormRecordProvider();

  return (
    <DNABox style={styles.wrapper} fill>
      <DNABox style={styles.contentWrapper} fill appearance="col">
        <Iffy is={!isSaving && errorMessage}>
          <DNABox>
            <InformationMessage
              text={errorMessage || ''}
              variance="danger"
            />
          </DNABox>
        </Iffy>
        <DNABox style={styles.content} appearance="col" fill>
          <Iffy is={!isSaving}>
            <ColumnFieldsWrapper customFields={customFields} />
          </Iffy>
          <Iffy is={isSaving}>
            <DNABox
              alignX="center"
              alignY="center"
              spacing="lg"
              appearance="col"
              fill
            >
              <DNASpinner size="medium" />
              <DNAText bold status="flatAlt">Loading...</DNAText>
            </DNABox>
          </Iffy>
        </DNABox>
      </DNABox>
    </DNABox>
  );
};

export const CustomFormRecordWrapper: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, customFields, disableForm, onSave, parentId, parentModel, standaloneForm } = props;
  const recordORM = useCustomFormRecordById(props.customFormRecordId);
  const mappedValues = getMappedCustomValues(props.usage, recordORM?.model.values, customFields);
  const defaultValues = merge(cloneDeep(props.defaultValues), mappedValues);
  const isReadOnly = recordORM?.model.status === CustomFormRecordStatus.LOCKED;

  return (
    <ComposableForm disabled={!!disableForm} isReadOnly={isReadOnly} values={defaultValues} customFields={customFields}>
      <CustomFormProvider
        recordId={props.customFormRecordId}
        onSave={onSave}
        parentId={parentId}
        parentModel={parentModel}
        standaloneForm={standaloneForm}
      >
        {children}
      </CustomFormProvider>
    </ComposableForm>
  );
};

export default CustomFormRecord;
