import { DBSchema } from 'idb';
import { ContentPresented, Tenant } from '@alucio/aws-beacon-amplify/src/models';
import { SyncEntry } from './ISyncManifest';
import { MEETING_SUBMIT_TYPE } from 'src/components/Meeting/AddMeetingProvider';
import { FormValuesType } from 'src/components/CustomFields/ComposableForm';
import { CustomFormRecordORM, MeetingORM } from 'src/types/orms';
import { AttendeeForm } from 'src/state/context/Meetings/saveMeetingHelper';
import { CustomDeckORMMap } from 'src/state/redux/selector/folder';
import { StandaloneForm } from 'src/state/redux/selector/crm';

export type Config = {
  id: string;
  name: string;
  configBase64: string;
  lastSyncTime?: number;
};

export type CRMAddress = {
  [fieldName: string]: string
  id: string
}

export type CRMAccount = {
  [fieldName: string]: string | CRMAddress[]
  addresses: CRMAddress[]
  id: string
};

export type FormSettings = SalesforceFormSettings

export type FullTextSearch = {
  id: string;
  value: string;
};

export enum TableEnum {
  ACCOUNT = 'ACCOUNT',
  CONFIG_OBJECT = 'CONFIG_OBJECT',
  FULL_TEXT_SEARCH = 'FULL_TEXT_SEARCH',
  FORM_SETTINGS = 'FORM_SETTINGS',
  FORM_MANIFEST = 'FORM_MANIFEST',
}
export type TableName = keyof typeof TableEnum;

export interface CrmDBSchema extends DBSchema {
  ACCOUNT: {
    key: string;
    value: CRMAccount;
    indexes: { id: string, name: string, lastModifiedDate: string };
  };
  CONFIG_OBJECT: {
    key: string;
    value: Config;
    indexes: { id: string };
  };
  FULL_TEXT_SEARCH: {
    key: string;
    value: FullTextSearch;
    indexes: { id: string };
  };
  FORM_SETTINGS: {
    key: string;
    value: FormSettings;
    indexes: { id: string };
  },
  FORM_MANIFEST: {
    key: string;
    value: SyncEntry;
    indexes: { id: string };
  },
}

// ** SALESFORCE TYPING ** //
export type SalesforceFormSettings = {
  id: string;
  apiName: string;
  recordTypeId?: string;
  picklists: SFPicklist;
  lookups: SFLookups;
  layout: SFRecordLayout;
  label: string;
  recordTypeLabel?: string;
  objectInfos: ObjectInfo;
  defaultRecord: SFDefaultRecord;
  // IN CASE THIS FORM SETTING NEEDS TO BE MANUALLY
  // HANDLED INSTEAD OF THE SALESFORCE TRANSLATOR
  ignore?: boolean;
  isMainTable?: boolean;
  isStandaloneForm?: boolean;
  parentTablesRelationship?: ParentTableRelationships;
  // [TODO]: REFACTOR TO USE THE OBJECT ABOVE
  relationshipWithParentApiName?: string;
  presentationsFieldName?: string;
}

export interface ParentTableRelationships {
  // PARENT API NAME: RELATIONSHIP FIELD NAME
  [parentApiName: string]: string;
}

export interface SalesforceFormFetchResponse {
  layout: SFRecordLayout;
  objectInfos: {
    [tableName: string]: ObjectInfo;
  };
  record: SFDefaultRecord;
}

interface SalesforceFormSettingsDependentField {
  [fieldName: string]: SalesforceFormSettingsDependentField
}

export interface SFLookups {
  [valueName: string]: LookupRecord;
}

export interface SFRecordTypes {
  [valueName: string]: RecordType;
}

export interface RecordType {
  id: string;
  apiName: string;
  layout: SFRecordLayout;
  objectInfos: ObjectInfo;
  record: SFDefaultRecord;
}
export interface LookupRecord {
  records: LookupRecordItem[]
}

export interface LookupRecordItem {
  fields: {
    Id: string;
    Name: string;
    [fieldName: string]: string;
  }
}

export interface SFPicklist {
  [valueName: string]: SFPicklistRecord;
}

export interface SFPicklistRecord {
  controllerValues: { [valueName: string]: number };
  defaultValue: SFPicklistValue | null;
  values: SFPicklistValue[];
}

export interface SFPicklistValue {
  label: string;
  validFor: number[];
  value: string;
}

export interface SFDefaultRecord {
  apiName: string;
  fields: {
    [fieldName: string]: SFDefaultField;
  };
  id: string | null;
  recordTypeId: string | null;
}

export interface SFDefaultField {
  displayValue: string | null;
  value: string | SFDefaultRecord | null | boolean;
}

export interface SFRecordInfoType {
  name: string;
  available: boolean;
  defaultRecordTypeMapping: boolean;
  master: boolean;
  recordTypeId: string;
}

export interface ObjectInfo {
  apiName: string;
  createable: boolean;
  deletable: boolean;
  custom: boolean;
  label: string;
  labelPlural?: string;
  nameFields: string[];
  updateable: boolean;
  childRelationships: ChildRelationship[];
  dependentFields: SalesforceFormSettingsDependentField;
  fields: {
    [fieldName: string]: ObjectField
  }
  recordTypeInfos: {
    [recordTypeId: string]: SFRecordInfoType
  }
}

interface ChildRelationship {
  fieldName: string;
  childObjectApiName: string;
  relationshipName: string;
}

export interface ObjectField {
  apiName: string;
  // REPRESENTS PARENT FIELD FOR PICKLIST DEPENDENCY
  controllerName: string | null;
  controllingFields: string[];
  createable: boolean;
  dataType: SALESFORCE_FIELD_DATA_TYPE | keyof typeof SALESFORCE_FIELD_DATA_TYPE;
  inlineHelpText: string | null;
  label: string;
  length: number;
  reference: boolean;
  referenceToInfos: ReferenceInfo[];
  relationshipName: string | null;
  required: boolean;
  updateable: boolean;
  precision?: number;
  scale?: number;
}

interface ReferenceInfo {
  apiName: string;
  nameFields: string[];
}

export interface SFRecordLayout {
  id: string;
  objectApiName: string;
  recordTypeId: string;
  sections: LayoutSection[];
}

export interface LayoutSection {
  columns: number;
  heading: string;
  id: string;
  layoutRows: LayoutRow[];
}

export interface LayoutRow {
  layoutItems: LayoutItem[];
}

export interface LayoutItem {
  editableForNew: boolean;
  editableForUpdate: boolean;
  label: string;
  lookupIdApiName: string | null;
  required: boolean;
  sortable: boolean;
  layoutComponents: LayoutComponent[]
}

export interface LayoutComponent {
  apiName: string | null;
  componentType: string;
  label?: string;
}

export enum SALESFORCE_FIELD_DATA_TYPE {
  Boolean = 'Boolean',
  Date = 'Date',
  DateTime = 'DateTime',
  Double = 'Double',
  Email = 'Email',
  Int = 'Int',
  MultiPicklist = 'MultiPicklist',
  Picklist = 'Picklist',
  Reference = 'Reference',
  String = 'String',
  TextArea = 'TextArea',
  Time = 'Time',
  Currency = 'Currency',
}

// ** SALESFORCE SUBMIT ** //
export interface SalesforceFirstSubmitPayload {
  records: SalesforceFirstSubmitRecord[];
}

export interface SalesforceFirstSubmitRecord {
  attributes: AttributesPayload;
  [fieldName: string]: string | SalesforceFirstSubmitPayload | AttributesPayload | number | null | boolean;
}

interface AttributesPayload {
  referenceId: string;
  type: string;
}

export interface CRMSubmitStandaloneFormPayload {
  isSubmit?: boolean;
  formSettings: FormSettings[];
  recordFormORM: CustomFormRecordORM;
  standaloneForm: StandaloneForm;
  tenant: Tenant;
}

export interface CRMSubmitMeetingPayload {
  startTime: string;
  endTime: string;
  mainCrmValues: FormValuesType;
  mainCrmRecordId?: string;
  // Meeting Before Saving
  meetingORM: MeetingORM;
  formValuesAttendees: AttendeeForm[];
  formSettings: FormSettings[];
  submitType: MEETING_SUBMIT_TYPE;
  tenant: Tenant;
  indexedCustomDecks: CustomDeckORMMap,
  contentPresented?: ContentPresented[],
  title: string,
  status: string,
  crmCustomFormRecords: CustomFormRecordORM[],
}

// THIS STRUCTURE IS USED TO ASSIGN TO BEACON'S OBJECT'S THEIR CRM ID
export interface CRMSubmitResponseReferences {
  [beaconId: string]: RecordSubmitResponse
}

export interface RecordSubmitResponse {
  errorMessages?: string[];
  handledError?: HANDLED_SYNC_ERROR
  syncStatus: SYNC_STATUS;
  externalId?: string;
}

export enum HANDLED_SYNC_ERROR {
  RECORD_SUBMITTED = 'RECORD_SUBMITTED',
  ENTITY_DELETED = 'ENTITY_DELETED',
}

export enum SYNC_STATUS {
  SYNCED = 'SYNCED',
  DELETED = 'DELETED',
  ERROR = 'ERROR'
}

export interface SalesforceFirstSubmitResponse {
  hasErrors: boolean;
  results: SubmitResponseResult[];
}

interface SubmitResponseResult {
  referenceId: string;
  errors?: SubmitResponseErrorObject[];
  id?: string;
}

interface SubmitResponseErrorObject {
  message: string
}

export interface DeleteRecordsResponse {
  id: string,
  success: boolean,
  errors?: SubmitResponseErrorObject[],
}

// ** UPON UPDATING AN EXISTING MAIN RECORD ** //
export interface SalesforceUpdateSubmit {
  recordsToDelete: RecordToDelete[];
  recordsToUpsert: SalesforceRecordToUpsert[];
  recordsToInsert: SalesforceFirstSubmitPayload;
}

export interface SalesforceCompositeUpsertPayload {
  compositeRequest: SalesforceCompositeUpsertRecordBody[];
}

export interface SalesforceCompositeUpsertRecordBody {
  method: 'POST' | 'PATCH',
  url: string,
  referenceId: string,
  body: SalesforceUpsertRecord,
}

export interface SalesforceCompositeUpsertResponse {
  compositeResponse: IndividualSalesforceCompositeResponse[]
}

export interface IndividualSalesforceCompositeResponse {
  body: SalesforceCompositeUpsertResponseBody | SalesforceCompositeUpsertResponseErrorBody[] | null,
  httpStatusCode: number,
  referenceId: string
}

interface SalesforceCompositeUpsertResponseBody {
  id: string,
  success: boolean,
  message: string,
}

interface SalesforceCompositeUpsertResponseErrorBody {
  message: string,
  errorCode: string,
}

export interface RecordToDelete {
  crmId: string;
  beaconId: string;
}

export interface SalesforceRecordToUpsert {
  apiName: string;
  beaconId: string;
  salesforceId?: string;
  fields: SalesforceUpsertRecord
}

interface SalesforceUpsertRecord {
  [fieldKey: string]: string | boolean | number | null;
}

export interface VeevaBasicPayloadFields extends VeevaAddress {
  Account_vod__c: string;
  Address_vod__c: string | null;
  Attendee_Type_vod__c: 'Person_Account_vod' | 'Group_Account_vod',
  Attendees_vod__c: number;
  Call_Date_vod__c: string;
  Call_Datetime_vod__c: string;
  Call_Channel_vod__c: 'Other_vod';
  Credentials_vod__c: string;
  Duration_vod__c: number;
  Last_Device_vod__c: 'Online_vod' | 'iPad_vod' | 'Tablet_vod' |'Mobile_vod' | 'iPhone_vod';
  Mobile_Created_Datetime_vod__c: string;
  Parent_Address_vod__c: string | null;
  Status_vod__c: 'Submitted_vod' | 'Saved_vod' | 'Planned_vod';
}

export interface VeevaAddress {
  City_vod__c: string | null;
  Address_Line_1_vod__c: string | null;
  Address_Line_2_vod__c: string | null;
  State_vod__c: string | null;
  Zip_4_vod__c: string | null;
  Zip_vod__c: string | null;
}

export const isCompositeResponseWithArrayBodyError =
  (items: unknown): items is SalesforceCompositeUpsertResponseErrorBody[] =>
    items ? Array.isArray(items) && items.some((item) => item.errorCode) : false;

export const isCRMStandaloneSubmitPayload =
  (item: unknown): item is CRMSubmitStandaloneFormPayload =>
    item ? !!(item as CRMSubmitStandaloneFormPayload).standaloneForm : false
