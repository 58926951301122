import React from 'react';
import { StyleSheet } from 'react-native';
import { useForm } from 'react-hook-form';
import { DNABox, DNAButton, DNAText, DNAContextMenu, Icon, luxColors, Iffy } from '@alucio/lux-ui';
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import DNACommonConfirmation from 'src/components/DNA/Modal/DNACommonConfirmation';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as publisherVersioningSelector from 'src/state/machines/publisherVersioning/publisherVersioning.selectors';
import { useDispatch } from 'src/state/redux';
import { usePublisherVersioningState }
  from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import { useSlideSettingsState } from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import DNAConditionalButton from 'src/components/DNA/ConditionalButton/DNAConditionalButton';

export const styles = StyleSheet.create({
  actionBar: {
    paddingHorizontal: 24,
    paddingVertical: 16,
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
  },
  helpToolTipIconStyle: {
    color: luxColors.contentText.quaternary,
    height: 20,
    paddingLeft: 5,
    width: 20,
  },
});

const buttonDefaults:DNAButtonProps = {
  appearance:'outline',
  status:'tertiary',
  padding:'xs',
}

const Header: React.FC = () => {
  const {
    thumbnailSizes,
    currentDocumentVersionORM,
    pages,
    isRequiredSlidesDisabled,
    isGroupingDisabled,
    setCoverThumbnail,
    editRequiredSlides,
    addAssociatedSlides,
    removeAssociatedSlides,
    editGroupSlides,
  } = useSlideSettingsState()

  const form = useForm({ mode:'onSubmit' })
  const { service: parentService, latestDocumentVersionContentPageData } = usePublisherVersioningState();
  const dispatch = useDispatch()

  const hasAssociatedSlides = pages.some(page => page.linkedSlides?.length)
  const hasSlideTitlesToClear = !!latestDocumentVersionContentPageData?.some(({ title }) => title !== '' )

  const handleClearAllSlideTitles = form.handleSubmit(() => {
    latestDocumentVersionContentPageData?.forEach(page => (page.title = ''))
    parentService.send({ type: 'SET_IS_DIRTY', payload: { type: 'slidesData', isDirty: true } })
  })

  const handleLaunchClearAllSlideTitlesModal = () => latestDocumentVersionContentPageData &&
    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: false,
      component: (props) =>
        (<DNACommonConfirmation
          { ...props }
          onConfirmAction={handleClearAllSlideTitles}
          confirmActionText="Clear"
          title="Clear all slide titles?"
          cancelText="Cancel"
        />),
    }))

  return (
    <DNABox spacing="between" style={styles.actionBar}>
      {/* left side */}
      <DNABox spacing="md" alignY="center">
        <DNAText>{currentDocumentVersionORM.meta.allPages.length} slide(s)</DNAText>
        <DNAButton
          {...buttonDefaults}
          onPress={setCoverThumbnail}
          style={{ backgroundColor: 'white' }}
        >
          Set cover thumbnail
        </DNAButton>
        <DNAConditionalButton
          {...buttonDefaults}
          onPress={handleLaunchClearAllSlideTitlesModal}
          // onPress={handleClearAllSlideTitles}
          style={{ backgroundColor: 'white' }}
        >
          <DNAConditionalButton.Disable condition={!hasSlideTitlesToClear}>
            <DNAText status="basic">No slide titles to clear</DNAText>
          </DNAConditionalButton.Disable>
          Clear all slide titles
        </DNAConditionalButton>
      </DNABox>
      {/* right side */}
      <DNABox spacing="md">
        <DNAPopover >
          <DNAPopover.Anchor>
            <Icon
              style={styles.helpToolTipIconStyle}
              name="help-circle-outline"
            />
          </DNAPopover.Anchor>
          <DNAPopover.Content>
            <DNAText
              style={{ color: colors['color-text-white'], marginHorizontal: 12, width: 380 }}
            >
              These settings apply when this document is used within a custom presentation.
            </DNAText>
          </DNAPopover.Content>
        </DNAPopover>
        <DNAText>Custom Presentation Settings:</DNAText>
        <DNABox spacing="sm" alignY="center">
          <DNAContextMenu>
            <DNAContextMenu.Anchor>
              {isRequiredSlidesDisabled
                ? <DNAPopover>
                  <DNAPopover.Anchor>
                    <DNAButton
                      {...buttonDefaults}
                      disabled
                      iconRight="chevron-down"
                      style={{ backgroundColor: 'white' }}
                      testID="edit-required-slides-dropdown"
                    >
                      Edit required slides
                    </DNAButton>
                  </DNAPopover.Anchor>
                  <DNAPopover.Content>
                    <DNAText status="basic">
                      Please remove slide groups to proceed.
                    </DNAText>
                  </DNAPopover.Content>
                </DNAPopover>
                : <DNAButton
                    {...buttonDefaults}
                    disabled={isRequiredSlidesDisabled}
                    iconRight="chevron-down"
                    style={{ backgroundColor: 'white' }}
                    testID="edit-required-slides-dropdown"
                >
                  Edit required slides
                </DNAButton>}
            </DNAContextMenu.Anchor>
            <DNAContextMenu.Items>
              <DNAContextMenu.Item
                key="For-presentation"
                collapseOnPress
                delay={100}
                title="For presentation"
                onPress={editRequiredSlides}
              />
              <DNAContextMenu.Item
                key="Add-to-individual-slides"
                collapseOnPress
                delay={100}
                title="Add to individual slides"
                onPress={addAssociatedSlides}
              />
              { hasAssociatedSlides &&
                <DNAContextMenu.Item
                  key="Remove-from-individual-slides"
                  collapseOnPress
                  delay={100}
                  title="Remove from individual slides"
                  onPress={removeAssociatedSlides}
                />
            }
            </DNAContextMenu.Items>
          </DNAContextMenu>
          <DNAText>or</DNAText>
          {isGroupingDisabled
            ? <DNAPopover>
              <DNAPopover.Anchor>
                <DNAButton
                  {...buttonDefaults}
                  disabled
                  style={{ backgroundColor: 'white' }}
                  testID="edit-slide-groups-btn"
                >
                  Edit slide groups
                </DNAButton>
              </DNAPopover.Anchor>
              <DNAPopover.Content>
                <DNAText status="basic">
                  Please remove required slides to proceed.
                </DNAText>
              </DNAPopover.Content>
            </DNAPopover>
            : <DNAButton
                {...buttonDefaults}
                onPress={editGroupSlides}
                style={{ backgroundColor: 'white' }}
                testID="edit-slide-groups-btn"
            >
              Edit slide groups
            </DNAButton>
          }
        </DNABox>
        <DNAButton
          {...buttonDefaults}
          style={{ height: 24, width: 24 }}
          onPress={thumbnailSizes.cycleThumbnailSize}
          iconLeft={thumbnailSizes.thumbnailSize === 'lg' ? 'view-comfy' : 'view-grid'}
        />
      </DNABox>
    </DNABox>
  )
}

const HeaderWrapper: React.FC = () => {
  const {
    service: parentService,
    isNonDocumentFile,
  } = usePublisherVersioningState();

  const versionPanelCond = useMachineSelector(
    parentService,
    (state) => composite(
      state,
      publisherVersioningSelector.isInDraftingState,
    ),
  )

  return (
    <Iffy is={versionPanelCond.isInDraftingState && !isNonDocumentFile}>
      <Header />
    </Iffy>
  )
}

export default HeaderWrapper;
