import React, { createContext, useState, useEffect, PropsWithChildren, useContext } from 'react';
import {
  CustomFieldValueDefinition,
  QuickFilter,
  CustomFieldDefinition,
  CustomFieldUsage,
} from '@alucio/aws-beacon-amplify/src/models';
import { useTenantCustomFields } from 'src/state/redux/selector/tenant';
import { useDNADocumentFilters } from '../DNA/Document/DNADocumentFilters/DNADocumentFilters';
import { FilterEntry } from 'src/state/redux/document/query';
import { DNABox, DNAText, DNAButton } from '@alucio/lux-ui';
import { colors } from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { useHistory, useLocation } from 'react-router';
import useCurrentPage from '../DNA/hooks/useCurrentPage';

interface QuickFiltersProviderProps {
  quickFilters: QuickFilter[]
}

interface QuickFiltersContextType {
  selectedPath: number
  setSelectedPath: React.Dispatch<React.SetStateAction<number>>
  quickFilters: QuickFilter[]
  visibleRows: CustomFieldDefinition[]
  setVisibleRows: React.Dispatch<React.SetStateAction<CustomFieldDefinition[]>>
  handleClear: () => void
  // eslint-disable-next-line max-len
  handleToggleFilterValue: (field: CustomFieldDefinition, value: CustomFieldValueDefinition, isSelected: boolean) => void
  handleToggleFilter: (filterId: string, isSelected: boolean, index: number) => void
  renderPathLabels: () => JSX.Element
  renderFilterRows: () => JSX.Element
}

export const QuickFiltersContext = createContext<QuickFiltersContextType>(null!)

const QuickFiltersProvider : React.FC<PropsWithChildren<QuickFiltersProviderProps>> = (props) => {
  const { quickFilters } = props;
  const tenantCustomFields = useTenantCustomFields({ usages: { internalUsages: [CustomFieldUsage.DOCUMENT] } });
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const initialId = params.get('quickFilter');
  const initialIndex = quickFilters.findIndex((qf) => qf.id === initialId);
  const [selectedPath, setSelectedPath] = useState(initialIndex ?? -1);
  const { activeFilters, setActiveFilters } = useDNADocumentFilters();
  const [visibleRows, setVisibleRows] = useState<CustomFieldDefinition[]>([]);
  const [initialActiveFilters] = useState<FilterEntry[]>(activeFilters);
  const route = useCurrentPage();
  const isLibraryPage = route?.PATH === '/library';

  useEffect(() => {
    if (selectedPath > -1) {
      const selectedFilter = quickFilters[selectedPath];
      const { path } = selectedFilter;
      let stopRender = false;
      const shownFilters = path.reduce<CustomFieldDefinition[]>((acc, id) => {
        if (stopRender) {
          return acc;
        }

        const customField = tenantCustomFields.find((field) => field.id === id && field.status === 'ENABLED');
        if (customField) {
          acc.push(customField);
        }
        const hasSelectedValues = customField
          ?.fieldValueDefinitions.find((value) => activeFilters.find((v) => v.valueId === value.id));

        // we stop rendering rows below because the current row has no selected values
        if (!hasSelectedValues) {
          stopRender = true;
        }
        return acc;
      }, [])
      setVisibleRows(shownFilters);
    }
    else {
      setVisibleRows([])
    }
  }, [selectedPath, activeFilters])

  const handleClear = () => {
    params.delete('quickFilter');
    history.replace({
      search: params.toString(),
    })
    setActiveFilters((prev) => {
      return prev.filter((filter) => !quickFilters[selectedPath].path.includes(filter.fieldId));
    })
    setSelectedPath(-1);
  }

  // eslint-disable-next-line max-len
  const handleToggleFilterValue = (field: CustomFieldDefinition, value: CustomFieldValueDefinition, isSelected: boolean) => {
    if (!isSelected) {
      setActiveFilters((prev) => [...prev, {
        fieldId: field.id,
        active: true,
        default: !!field.showByDefault,
        fieldName: field.fieldName,
        valueId: value.id,
        fieldValue: value.label ?? value.value,
      }]);
    }
    else {
      setActiveFilters((prev) => {
        return prev.filter((filter) => filter.valueId !== value.id);
      })
    }
  }

  const handleToggleFilter = (filterId: string, isSelected: boolean, index: number) => {
    setSelectedPath(isSelected ? -1 : index);
    if (!isSelected) {
      params.set('quickFilter', filterId)
      analytics?.track('SEARCH_QUICK_FILTER', {
        category: 'SEARCH',
        action: 'QUICK_FILTER',
        filterId: filterId,
      });
      if (isLibraryPage) {
        history.replace({
          search: params.toString(),
        });
      }
      else {
        history.push({
          pathname: '/library',
          search: params.toString(),
        });
      }
    }
    else {
      params.delete('quickFilter');
      history.replace({
        search: params.toString(),
      });
    }
  }

  // todo: move this to filterssidebar when we implement it in desktop
  const renderPathLabels = () => {
    return (
      <DNABox alignY="center" spacing="sm">
        <DNAText bold>Find content:</DNAText>
        {quickFilters.map((filter, index) => {
          const isSelected = selectedPath === index;
          return (
            <DNAButton
              key={filter.id}
              testID="quickfilter-path"
              onPress={() => {
                handleToggleFilter(filter.id, isSelected, index)
              }}
              appearance="outline"
              status={isSelected ? 'secondary' : 'tertiary'}
              size="xs"
              style={isSelected && { backgroundColor: colors['brand1-10'] }}
            >
              {filter.label}
            </DNAButton>
          )
        })}
      </DNABox>
    )
  }

  // todo: move this to filterssidebar when we implement it in desktop
  const renderFilterRows = () => {
    return (
      <DNABox appearance="col" fill spacing="md">
        {/* render each custom field from the path  */}
        {visibleRows.map((field) => {
          return (
            <DNABox key={field.id} style={{ flexWrap: 'wrap' }} spacing="sm">
              <DNAText bold>{field.fieldLabel}: </DNAText>
              {field.fieldValueDefinitions.map((value) => {
                const isSelected = !!activeFilters.find((v) => v.valueId === value.id);
                return (
                  <DNAButton
                    key={value.id}
                    testID="quickfilter-value"
                    size="xs"
                    appearance="outline"
                    status={isSelected ? 'secondary' : 'tertiary'}
                    style={[{ marginBottom: 8 }, isSelected && { backgroundColor: colors['brand1-10'] }]}
                    onPress={() => handleToggleFilterValue(field, value, isSelected)}
                  >
                    {value.label ?? value.value}
                  </DNAButton>
                )
              })}
            </DNABox>
          )
        })}
      </DNABox>
    )
  }

  const values = {
    selectedPath,
    setSelectedPath,
    quickFilters,
    visibleRows,
    setVisibleRows,
    initialActiveFilters,
    handleClear,
    handleToggleFilter,
    handleToggleFilterValue,
    renderPathLabels,
    renderFilterRows,
  }

  return (
    <QuickFiltersContext.Provider value={values}>
      {props.children}
    </QuickFiltersContext.Provider>
  )
}

export const useQuickFilters = () => useContext(QuickFiltersContext)

export default QuickFiltersProvider;
