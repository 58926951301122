import { API, graphqlOperation, GraphQLQuery } from '@aws-amplify/api';
import { GetZendeskJWTQuery, ZENDESK_FEATURE, ZendeskTokenPayload } from '@alucio/aws-beacon-amplify/src/API';
import { getZendeskJWT } from '@alucio/aws-beacon-amplify/src/graphql/queries';
import config from 'src/config/app.json';

export const isZendeskTokenPayload = (item: unknown): item is ZendeskTokenPayload => item
  ? (item as ZendeskTokenPayload).__typename === 'ZendeskTokenPayload'
  : false

export async function getZendeskTokenPayload(feature: ZENDESK_FEATURE) {
  const { data } = await API.graphql<GraphQLQuery<GetZendeskJWTQuery>>(
    graphqlOperation(getZendeskJWT,
      {
        feature,
      },
    ),
  );

  if (!data?.getZendeskJWT) {
    throw new Error(`Unable to get Zendesk ${feature} jwt`);
  }

  return data.getZendeskJWT;
}

export async function generateZendeskURL(feature: ZENDESK_FEATURE, returnTo?: string) {
  const tokenObj = await getZendeskTokenPayload(feature);

  const url = new URL(`https://${config.zendeskURL}/access/jwt`);
  const params = new URLSearchParams(url.search)

  if (tokenObj.token) {
    params.append('jwt', tokenObj.token);
  }

  if (returnTo) {
    params.append('return_to', returnTo);
  }
  url.search = params.toString();

  return url.toString();
}
