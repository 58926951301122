import React, { useEffect, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { DNABox, DNASelect, DNAText, Iffy, IndexPath } from '@alucio/lux-ui';
import { Attendee, CustomFieldDefinition } from '@alucio/aws-beacon-amplify/src/models';
import { ATTENDEE_TYPE, MEETING_ATTENDEE_STATUS } from '@alucio/aws-beacon-amplify/src/API';
import { SUPPORTED_ZVODS } from 'src/classes/CRM/Translators/VeevaMarkerHandler';
import { CRMAccount } from 'src/classes/CRM/CRMIndexedDBTypes';
import { Singleton as IndexDbCrm } from 'src/classes/CRM/CRMIndexedDB';
import { FormValuesType, useComposableForm } from '../CustomFields/ComposableForm';
import { toORMAccount } from 'src/screens/Profile/CRMIntegration';
import * as Types from 'src/state/machines/CRM/crmMachineTypes';
import { CRMAccountORM } from 'src/types/orms';
import { ObjectWithId } from '../CustomFields/ComposableFormUtilities';
import * as logger from 'src/utils/logger'

const VeevaLocationWrapper = () => {
  const { customFields, rhForm, isReadOnly } = useComposableForm();

  // CHECKS IF zvod_Business_Account_vod__c IS PRESENT
  const businessAccountCustomField = useMemo(() => customFields
    .find(({ id }) => id === SUPPORTED_ZVODS.BUSINESS_ACCOUNT), [customFields]);

  if (!businessAccountCustomField || !rhForm.getValues().primaryAttendee?.length) {
    return null;
  }

  return (
    <DNABox appearance="col" fill>
      <VeevaLocationComponent
        isReadOnly={isReadOnly}
        field={businessAccountCustomField}
        rhForm={rhForm}
      />
    </DNABox>
  );
};

interface LocationProps {
  rhForm: UseFormReturn<FormValuesType>
  isReadOnly?: boolean
  field: CustomFieldDefinition
}

// VEEVA SPECIFIC LOCATION'S FIELD
const VeevaLocationComponent = (props: LocationProps) => {
  const { field, isReadOnly, rhForm } = props;
  const [primaryAttendee, additionalAttendees] =
    rhForm.watch(['primaryAttendee', 'additionalAttendees']) as unknown as [Attendee[], Attendee[]];
  const selectedBusinessAccount = additionalAttendees
    .find(({ id }) => id === SUPPORTED_ZVODS.BUSINESS_ACCOUNT)?.crmAccountId;
  const [businessAccounts, setBusinessAccounts] = useState<CRMAccountORM[]>([]);
  const index = businessAccounts.findIndex((account) => account.model.id === selectedBusinessAccount);
  const selectIdx = new IndexPath(index > -1 ? index : 0);
  const [isPersonAccount, setIsPersonAccount] = useState<boolean>(false);

  useEffect(() => {
    // AFTER SELECTING A PRIMARY ATTENDEE, GETS ITS BUSINESS ACCOUNTS TO UPDATE THE SELECT ITEMS
    const handlePrimaryAttendeeUpdate = async (): Promise<void> => {
      const crmAccountId = primaryAttendee[0]?.crmAccountId;

      if (crmAccountId) {
        const account = await IndexDbCrm.getById<CRMAccount>('ACCOUNT', crmAccountId);
        if (account?.IsPersonAccount) {
          setIsPersonAccount(true);
          const businessAccountId =
            typeof account.Primary_Parent_vod__c === 'string' ? account.Primary_Parent_vod__c : '';
          // BUSINESS ACCOUNTS IDS OF THE PERSON ACCOUNT TO LATER GET THEM ON INDEXDB
          const businessAccountsRaw = account.Child_Account_vod__r?.['records'];
          const accountsIds = !Array.isArray(businessAccountsRaw)
            ? [businessAccountId]
            : businessAccountsRaw.reduce((acc, businessAccount) =>
              businessAccount.Parent_Account_vod__c ? [...acc, businessAccount.Parent_Account_vod__c] : acc, []);

          // GETS THE BUSINESS ACCOUNTS OF THE PERSON ACCOUNT
          const businessAccounts = await IndexDbCrm.filterById('ACCOUNT', accountsIds);
          const accountsORM = toORMAccount(businessAccounts, Types.CRMSyncStatus.SYNCED);
          // THE BUSINESS ACCOUNTS MUST HAVE "Do_Not_call_vod__c" SET TO "No_vod"
          const filteredAccountsORMS = accountsORM
            .filter((accountORM) => accountORM.model?.Do_Not_Call_vod__c === 'No_vod');

          if (!selectedBusinessAccount && field.required && filteredAccountsORMS.length) {
            // TODO: i will leave this as it is right now, but this is not the best way to do it (comment added after react hook upgrade)
            // additionalAttendees has a property called crmRecord which make it incompatible with objectWithId
            // so we need to cast it to unknown and then to Attendee[] to be able to use it as ObjectWithId[]
            const newValue = [...additionalAttendees, {
              id: SUPPORTED_ZVODS.BUSINESS_ACCOUNT,
              crmAccountId: filteredAccountsORMS[0]?.model.id,
              attendeeType: ATTENDEE_TYPE.SECONDARY,
              name: filteredAccountsORMS[0]?.meta.formattedName,
              status: MEETING_ATTENDEE_STATUS.ACTIVE,
              updatedAt: new Date().toISOString(),
            }] as unknown as ObjectWithId[];

            rhForm.setValue('additionalAttendees', newValue);
          } else if (!filteredAccountsORMS.length) {
            logger.salesforceSyncer.debug('Business Accounts not found...');
          }

          setBusinessAccounts(filteredAccountsORMS);
          return;
        } else {
          setIsPersonAccount(false);
        }
      }
      setBusinessAccounts([]);
    }
    handlePrimaryAttendeeUpdate();
  }, [primaryAttendee]);

  if (!isPersonAccount) {
    return null;
  }

  const onSelectBusinessAccount = (index : IndexPath | IndexPath[]) => {
    const idx = index as IndexPath
    const businessAccount = businessAccounts[idx.row];
    if (businessAccount?.model.id !== selectedBusinessAccount) {
      const remainingAttendees = (additionalAttendees as unknown as Attendee[])?.filter(({ id }) =>
        id !== SUPPORTED_ZVODS.BUSINESS_ACCOUNT);
        // TODO: i will leave this as it is right now, but this is not the best way to do it (comment added after react hook upgrade)
        // additionalAttendees has a property called crmRecord which make it incompatible with objectWithId
        // so we need to cast it to unknown and then to Attendee[] to be able to use it as ObjectWithId[]
      const newValue = [...remainingAttendees, {
        id: SUPPORTED_ZVODS.BUSINESS_ACCOUNT,
        crmAccountId: businessAccount.model.id,
        attendeeType: ATTENDEE_TYPE.SECONDARY,
        name: businessAccount.meta.formattedName,
        status: MEETING_ATTENDEE_STATUS.ACTIVE,
        updatedAt: new Date().toISOString(),
      } as Attendee] as unknown as ObjectWithId[];
      rhForm.setValue('additionalAttendees', newValue);
    }
  };

  return (
    <DNABox>
      <Iffy is={!businessAccounts.length}>
        <DNABox appearance="col" fill spacing="sm">
          <DNAText bold>Location</DNAText>
          <DNAText status="flatAlt">No locations were found</DNAText>
        </DNABox>
      </Iffy>
      <Iffy is={businessAccounts.length}>
        <DNABox appearance="col" fill spacing="sm">
          <DNAText bold status={'dark'}>Location</DNAText>
          <DNASelect
            disabled={isReadOnly}
            onSelect={onSelectBusinessAccount}
            selectedIndex={selectIdx}
            value={businessAccounts[index]?.meta.formattedName || 'Select location'}
          >
            {businessAccounts?.map((businessAccount) => (
              <DNASelect.Item
                key={businessAccount.model.id}
                title={() =>
                  (<DNABox appearance="col" spacing="sm">
                    <DNAText c1 bold numberOfLines={1}>
                      {businessAccount.meta.formattedName}
                    </DNAText>
                    <DNAText c2 status="subtle">
                      {businessAccount.relations.addresses[0]?.meta.formattedName}
                    </DNAText>
                  </DNABox>)}
              />
            ))}
          </DNASelect>
        </DNABox>
      </Iffy>
    </DNABox>
  )
};

export default VeevaLocationWrapper;
