import React from 'react';
import { Iffy } from '@alucio/lux-ui';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';
import DesktopVariant from './InPersonActionBar.desktop';
import TabletVariant from './InPersonActionBar.tablet';

type InPersonActionBarVariants = Record<DeviceMode, React.ElementType>

const inPersonActionBarVariants: InPersonActionBarVariants = {
  desktop: DesktopVariant,
  tablet: TabletVariant,
}

const InPersonActionBar: React.FC = () => {
  const {
    presentationControlsVisible,
  } = useMeetingsState()
  const { deviceMode } = useAppSettings()

  const CurrentVariant = inPersonActionBarVariants[deviceMode]

  return (
    <Iffy is={!presentationControlsVisible}>
      <CurrentVariant />
    </Iffy>
  );
};

export default InPersonActionBar
