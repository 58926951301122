import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAText, Iffy, Stack } from '@alucio/lux-ui';
import useSelector, { composite } from 'src/hooks/useSelector';
import * as findReplacementSelector from 'src/state/machines/findReplacement/FindReplacement.selectors';
import { useFindReplacementState } from './FindReplacementProvider';

const S = StyleSheet.create({
  headerContainer: {
    height: 56,
  },
});

const Header: React.FC = () => {
  const {
    service,
    closeModal,
    navPreviousSlide,
    navNextSlide,
    onSave,
  } = useFindReplacementState();

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      findReplacementSelector.activeGroupId,
      findReplacementSelector.needReviewGroups,
      findReplacementSelector.indexOfActiveGroup,
      findReplacementSelector.canNavPrevious,
      findReplacementSelector.canNavNext,
      findReplacementSelector.isGroupDirty,
      findReplacementSelector.hasAutoUpdatedSlidesFromMapping,
      findReplacementSelector.hasReplacedGroups,
    ),
  );

  return (
    <DNABox>
      <Stack anchor="center" style={S.headerContainer}>
        {/* BOTTOM LAYER */}
        <Stack.Layer>
          <DNABox fill/>
        </Stack.Layer>
        {/* HEADER CENTER */}
        <Stack.Layer anchor="center">
          <Iffy is={cond.needReviewGroups.length}>
            <DNABox alignX="center" alignY="center" spacing="sm">
              <DNAButton
                testID="previous-slide-button"
                appearance="outline"
                status="tertiary"
                padding="sm"
                disabled={!cond.canNavPrevious}
                onPress={navPreviousSlide}
              >
                Previous
              </DNAButton>
              <DNAText bold>{cond.indexOfActiveGroup + 1} of {cond.needReviewGroups.length}</DNAText>
              <DNAButton
                testID="next-slide-button"
                appearance="outline"
                status="tertiary"
                padding="sm"
                disabled={!cond.canNavNext}
                onPress={navNextSlide}
              >
                Next
              </DNAButton>
            </DNABox>
          </Iffy>
        </Stack.Layer>
        {/* HEADER RIGHT */}
        <Stack.Layer anchor="right">
          <DNABox spacing="sm" keepSpacingLastItem>
            <DNAButton
              testID="replacement-modal-cancel-btn"
              appearance="outline"
              status="tertiary"
              padding="sm"
              onPress={closeModal}
            >
              Cancel
            </DNAButton>
            <DNAButton
              testID="replacement-modal-save-btn"
              padding="sm"
              onPress={onSave}
              disabled={
                (!cond.isGroupDirty && !cond.hasAutoUpdatedSlidesFromMapping.length) ||
                !cond.hasReplacedGroups
              }
            >
              {cond.hasAutoUpdatedSlidesFromMapping.length ? 'Accept & save' : 'Save'}
            </DNAButton>
          </DNABox>
        </Stack.Layer>
      </Stack>
    </DNABox>
  )
};

export default Header;
