import { useMemo } from 'react'
import type { ActorRef, Subscribable, StateLike } from 'xstate'
import { useSelector as useXStateSelector } from '@xstate/react'
import deepEqual from 'fast-deep-equal'

type UnionToIntersection<U> = (U extends any ? (arg: U) => void : never) extends (arg: infer R) => void ? R : never

/**
 * Reduces XState selectors into a composite return value. Useful for atomic-like selectors
 *
 * @param state: A State-like value derived from an XState machine instance
 * @param {Selector} selectors: Any numbe of atomic XState selectors
 * @returns a reduced object of selector values
 */
// [NOTE] - TS performance may not be the greatest here ... Not sure if this is the most optimal way of doing this
function compositeSelect<
  S extends StateLike<any>,
  T extends Array<(state: S) => { [k: string]: any }>
>(...args: [S, ...T]): UnionToIntersection<ReturnType<T[number]>> {
  const [state, ...rest] = args

  return rest.reduce(
    (acc, selector) => {
      return {
        ...acc,
        ...selector(state),
      }
    },
    { } as any, // [TODO-TS] - Yeah, not sure on this one due to inferred types
  )
}

export { compositeSelect as composite }

/**
 * This is a slight wrapper around `@xstate/react`'s `useSelector` hook
 * This MEMOIZES the selector function by default (so you don't have to memoize it yourself)
 * HOWEVER, that means the selector function cannot change over time at the moment
 *
 * This also uses `fast-deep-equal` for the compararator to determine whether to re-render or not
 * The comparator is also memoized without being able to change over time as well
 * @param service - State machine service
 * @param selector - Selector function that returns desired results
 * @param compararator - Comparator function that defaults to `fast-deep-equal`
 * @returns Selected, memoized values
 */
function useSelector<
  // [TODO-DX] - Probably a better way to extract these without copying the source types :^)
  TActor extends ActorRef<any, any>,
  T,
  TEmitted = TActor extends Subscribable<infer Emitted>
    ? Emitted
    : never
>(
  service: TActor,
  selector: (emitted: TEmitted) => T,
  compararator: (a: T, b: T) => boolean = deepEqual,
) {
  // [TODO] - Generally feature flag values don't change over the course of the app lifecycle
  //        - This means that Selectors may not refresh properly if a FeatureFlag changes (or we identify a different user in the same session)
  //        - To always get the latest updates from FeatureFlags we could
  //          - Use the ldClient hook directly and hope that the `useXStateSelector` hook also recomputes
  //            - May need to force a recompute with additional code
  //          - Or we can have machines subscribe to the ldClient and receive events on feature flag updates
  //            - That will cause the machine to receive a new state and recompute all selectors

  const selectorArgs = useMemo<[typeof selector, typeof compararator]>(
    () => [selector, compararator],
    [],
  )

  return useXStateSelector(service, ...selectorArgs)
}

export default useSelector
