import React from 'react'
import {
  DNABox,
  DNAText,
  luxColors,
} from '@alucio/lux-ui'

import { Controller, useFormContext } from 'react-hook-form'

import InputComponent from 'src/components/Publishers/InputComponent'

type FormContext = {
  name: string
  clientConfigurationFields: {
    appsync_url: string
    username: string
    aws_cognito_identity_pool_id: string
    aws_user_pools_id: string
    aws_appsync_region: string
    aws_user_pools_web_client_id: string
    tenantIdSource: string
    revokedValues: string
    archivedValues: string
  }
}

// convert this into a variant
const BeaconIntegration = () => {
  const { control, formState: { errors } } = useFormContext<FormContext>()

  return (
    <DNABox fill>
      <DNABox appearance="col" spacing="md" fill>
        <DNABox appearance="col" spacing="sm">
          <Controller
            control={control}
            name="name"
            rules={{ required: 'This field is required', maxLength: 250 }}
            render={({ field, fieldState: { error } }) => (
              <InputComponent
                {...field}
                title="Integration name"
                characterLimit={250}
                showCharacterCounter={true}
                required={true}
                removeMarginPadding
                inputStyle={error && { borderColor: luxColors.error.primary }}
              />
            )}
          />
          {errors.name && errors.name.type === 'required' &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.name.message}
            </DNAText>
          }
          {errors.name && errors.name.type === 'maxLength' &&
            <DNAText style={{ color: luxColors.error.primary }}>
              Maximum character limit is 250
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="sm">
          <Controller
            control={control}
            name="clientConfigurationFields.appsync_url"
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <InputComponent
                {...field}
                title="appSync URL"
                required={true}
                removeMarginPadding
                inputStyle={
                  (errors.clientConfigurationFields && errors.clientConfigurationFields.appsync_url) &&
                  { borderColor: luxColors.error.primary }}
              />
            )}
          />
          {(errors.clientConfigurationFields && errors.clientConfigurationFields.appsync_url) &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.appsync_url.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="sm">
          <Controller
            control={control}
            name="clientConfigurationFields.username"
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <InputComponent
                {...field}
                title="Username"
                required={true}
                removeMarginPadding
                inputStyle={
                  (errors.clientConfigurationFields && errors.clientConfigurationFields.username) &&
                  { borderColor: luxColors.error.primary }}
              />
            )}
          />
          {errors.clientConfigurationFields?.username &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.username.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="sm">
          <Controller
            control={control}
            name="clientConfigurationFields.aws_cognito_identity_pool_id"
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <InputComponent
                {...field}
                title="aws_cognito_identity_pool_id"
                required={true}
                removeMarginPadding
                inputStyle={
                  (errors.clientConfigurationFields && errors.clientConfigurationFields.aws_cognito_identity_pool_id) &&
                  { borderColor: luxColors.error.primary }}
              />
            )}
          />
          {(errors.clientConfigurationFields && errors.clientConfigurationFields.aws_cognito_identity_pool_id) &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.aws_cognito_identity_pool_id.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="sm">
          <Controller
            control={control}
            name="clientConfigurationFields.aws_user_pools_id"
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <InputComponent
                {...field}
                title="aws_user_pools_id"
                required={true}
                removeMarginPadding
                inputStyle={
                  (errors.clientConfigurationFields && errors.clientConfigurationFields.aws_user_pools_id) &&
                  { borderColor: luxColors.error.primary }}
              />
            )}
          />
          {errors.clientConfigurationFields?.aws_user_pools_id &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.aws_user_pools_id.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="sm">
          <Controller
            control={control}
            name="clientConfigurationFields.aws_appsync_region"
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <InputComponent
                {...field}
                title="aws_appsync_region"
                required={true}
                removeMarginPadding
                inputStyle={
                  (errors.clientConfigurationFields && errors.clientConfigurationFields.aws_appsync_region) &&
                  { borderColor: luxColors.error.primary }}
              />
            )}
          />
          {errors.clientConfigurationFields?.aws_appsync_region &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.aws_appsync_region.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="sm">
          <Controller
            control={control}
            name="clientConfigurationFields.aws_user_pools_web_client_id"
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <InputComponent
                {...field}
                title="aws_user_pools_web_client_id"
                required={true}
                removeMarginPadding
                inputStyle={
                  (errors.clientConfigurationFields && errors.clientConfigurationFields.aws_user_pools_web_client_id) &&
                  { borderColor: luxColors.error.primary }}
              />
            )}
          />
          {errors.clientConfigurationFields?.aws_user_pools_web_client_id &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.aws_user_pools_web_client_id.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="sm">
          <Controller
            control={control}
            name="clientConfigurationFields.tenantIdSource"
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <InputComponent
                {...field}
                title="Tenant ID source"
                required={true}
                removeMarginPadding
                inputStyle={
                  (errors.clientConfigurationFields && errors.clientConfigurationFields.tenantIdSource) &&
                  { borderColor: luxColors.error.primary }}
              />
            )}
          />
          {errors.clientConfigurationFields?.tenantIdSource &&
            <DNAText style={{ color: luxColors.error.primary }}>
              {errors.clientConfigurationFields.tenantIdSource.message}
            </DNAText>
          }
        </DNABox>
        <DNABox appearance="col" spacing="sm">
          <Controller
            control={control}
            name="clientConfigurationFields.revokedValues"
            render={({ field }) => (
              <InputComponent
                {...field}
                title="Revoked statuses (comma separated)"
                required={false}
                removeMarginPadding
              />
            )}
          />
        </DNABox>

        <DNABox appearance="col" spacing="sm">
          <Controller
            control={control}
            name="clientConfigurationFields.archivedValues"
            render={({ field }) => (
              <InputComponent
                {...field}
                title="Archived values (comma separated)"
                required={false}
                removeMarginPadding
              />
            )}
          />
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

export default BeaconIntegration
