import React from 'react';
import { FolderItemORM, isPageGroupORM, PageGroupORM } from 'src/types/types'
import {
  useContentViewerModalState,
} from 'src/components/ContentPreviewModal/state/ContentViewerModalStateProvider';
import { useDispatch } from 'src/state/redux';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import DNACommonConfirmation from 'src/components/DNA/Modal/DNACommonConfirmation';
import useMyContentPanelSharedResources, { MyContentPanelProps } from '../useMyContentPanelSharedResources';

import { Animated } from 'react-native';
import { DNABox, DNADivider, Iffy } from '@alucio/lux-ui';
import Header from '../Header';
import Content from '../Content';

function ContentPreviewModalVariant(props: MyContentPanelProps) {
  const {
    setActiveDocVersionORMAndSlide,
    setActiveFolderItemORMAndSlide,
    setActivePageGroupORMAndSlide,
    loadedDocuments,
    documentTabLimit,
    registerLoadedDocumentState,
  } = useContentViewerModalState();
  const {
    selectedDocumentVersion,
    togglePanelView,

    bottom,
    mainWrapperVisibilityStyle,
    selectedTab,
    localSearchText,
    setSelectedTab,
    displayMode,
    setLocalSearchText,
    setSearchText,
    searchText,
    setSelectedDocumentVersion,
    styles,

  } = useMyContentPanelSharedResources(props)
  const dispatch = useDispatch()

  function onPresent(page: number, itemORM?: FolderItemORM | PageGroupORM): void {
    if (loadedDocuments.length === documentTabLimit ) {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: () => (
          <DNACommonConfirmation
            title="Tab limit"
            descriptionText="You can have up to 10 tabs open."
            cancelText="Close"
            status="secondary"
          />
        ),
      }),
      )
      return
    } else if (isPageGroupORM(itemORM)) {
      registerLoadedDocumentState(itemORM, page)
      setActivePageGroupORMAndSlide(itemORM, page)
    } else if (itemORM) {
      registerLoadedDocumentState(itemORM, page)
      setActiveFolderItemORMAndSlide(itemORM, page)
    } else if (selectedDocumentVersion) {
      registerLoadedDocumentState(selectedDocumentVersion, page)
      setActiveDocVersionORMAndSlide(selectedDocumentVersion, page)
    }
    togglePanelView(false);
  }

  return (
    <Animated.View
      style={[
        styles.mainWrapper,
        { transform: [{ translateY: bottom }] },
        mainWrapperVisibilityStyle,
      ]}
    >
      <DNABox appearance="col" fill style={styles.content}>
        <Header
          selectedTab={selectedTab}
          localSearchText={localSearchText}
          onClosePanel={() => togglePanelView(false)}
          selectedDocument={selectedDocumentVersion?.relations.documentORM}
          onSelectTab={setSelectedTab}
          displayMode={displayMode}
          setLocalSearchText={setLocalSearchText}
          setSearchText={setSearchText}
          sessionId={props.sessionId}
        />
        <DNADivider variant="virtual" />
        <Iffy is={selectedTab} >
          <Content
            localSearchText={localSearchText}
            searchText={searchText}
            selectedTab={selectedTab}
            selectedDocumentVersion={selectedDocumentVersion}
            setSelectedDocumentVersion={setSelectedDocumentVersion}
            setSelectedPage={onPresent}
            displayMode={displayMode}
            sessionId={props.sessionId}
          />
        </Iffy>
      </DNABox>
    </Animated.View>
  );
}

export default ContentPreviewModalVariant;
