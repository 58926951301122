import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useContent, LoadedPresentation } from 'src/state/context/ContentProvider/ContentProvider';
import DNASlideRoll from 'src/components/DNA/SlideRoll/DNASlideRoll';
import { SLIDE_ROLL_VARIANT } from 'src/components/DNA/SlideRoll/StyleSetting';
import { DNABox } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useMeetingPresentedMeta } from './State/MeetingPresentedMetaProvider';

const styles = StyleSheet.create({
  mainWrapper: {
    backgroundColor: colors['color-text-white'],
    paddingHorizontal: 16,
  },
});

interface Props {
  presentation: LoadedPresentation,
  onPageSelect: (presentationPageNumber: number) => void,
  closeButtonOnPress?: () => void,
}

const PreviewSlideRoll = (props: Props) => {
  const { presentation, onPageSelect, closeButtonOnPress } = props;
  const { presentedMetas, isPresentedSlideEmpty } = useMeetingPresentedMeta();

  const memoizedSlideRoll = useMemo(() => (
    <DNASlideRoll
      presentedMeta={presentedMetas}
      activeSlide={presentation.currentPresentablePage}
      presentable={presentation.presentable}
      variant={isPresentedSlideEmpty ? SLIDE_ROLL_VARIANT.MEETING_PRESENTED_EMPTY : SLIDE_ROLL_VARIANT.PREVIEW_MODAL}
      horizontal={false}
      onSelect={onPageSelect}
      closeButtonOnPress={closeButtonOnPress}
    />
  ), [presentation, presentedMetas, isPresentedSlideEmpty]);

  return (
    <DNABox testID="preview-slide-roll" style={styles.mainWrapper}>
      {memoizedSlideRoll}
    </DNABox>
  );
};

const PreviewSlideRollConnector = (props) => {
  const { activePresentation, setActiveSlideByPresentationPageNumber } = useContent();
  if (!activePresentation) return null
  return (
    <DNABox>
      <PreviewSlideRoll
        presentation={activePresentation}
        onPageSelect={setActiveSlideByPresentationPageNumber}
        closeButtonOnPress={props.closeButtonOnPress}
      />
    </DNABox>

  );
};

PreviewSlideRoll.title = 'PreviewSlideRoll';

export default PreviewSlideRollConnector;
