import React from 'react'
import { useDispatch } from 'src/state/redux'

import { DNAModal, DNABox, DNAText } from '@alucio/lux-ui'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors'

import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import { DNAThumbnail } from 'src/components/DNA/Thumbnail/DNAThumbnail'
import { getSlideTitle, getThumbURL } from 'src/utils/thumbnailHelpers'
import { DocumentVersionORM } from 'src/types/orms'
import { ContentPageData } from 'src/hooks/useContentPageData/useContentPageData'
import { StyleSheet } from 'react-native'
import { DNAChip } from '@alucio/lux-ui/src'

const style = StyleSheet.create({
  container: {
    padding: 9,
    paddingTop: 0,
  },
  thumbnailContainer: {
    minWidth: 700,
    minHeight: 396,
    borderColor: colors['color-gray-80'],
    borderWidth: 1,
  },
  textColor: {
    color: colors['color-gray-400'],
  },
})

type PreviousSlidePreviewProps = {
  previousDocumentVersionORM?: DocumentVersionORM,
  previousPageId?: string,
  previousPageNum?: number
  latestPublishedVersionContentPageData?: ContentPageData[],
}

type NewSlidePreviewProps = {
  newDocumentVersionORM?: DocumentVersionORM,
  newPageNum?: number,
  latestDocumentVersionContentPageData?: ContentPageData[],
}

type MatchSlidesPreviewModalProps = {
  previousDocumentVersionORM?: DocumentVersionORM,
  previousPageNum?: number,
  newDocumentVersionORM?: DocumentVersionORM,
  newPageNum?: number,
  latestDocumentVersionContentPageData?: ContentPageData[],
  latestPublishedVersionContentPageData?: ContentPageData[],
}

const PreviousSlidePreview: React.FC<PreviousSlidePreviewProps> = (props) => {
  const {
    previousDocumentVersionORM,
    previousPageNum,
    latestPublishedVersionContentPageData,
  } = props

  if (!previousDocumentVersionORM || !previousPageNum || !latestPublishedVersionContentPageData ) return null

  const previousVersionThumbURL = getThumbURL(previousDocumentVersionORM, previousPageNum)
  const previousTitle = getSlideTitle(
    previousDocumentVersionORM,
    previousPageNum,
    latestPublishedVersionContentPageData,
  )

  return (
    <DNABox appearance="col" spacing="sm">
      <DNABox alignX="center" appearance="col" spacing="sm">
        <DNABox appearance="row" spacing="sm" alignY="center">
          <DNAText bold style={style.textColor}>Version 1.0</DNAText>
          <DNABox style={{ paddingVertical: 8 }}>
            <DNAChip style={{ borderRadius: 2 }} appearance="tag">CURRENT</DNAChip>
          </DNABox>
        </DNABox>
        <DNABox style={style.thumbnailContainer}>
          <DNAThumbnail
            key={previousDocumentVersionORM?.model.id}
            s3URL={previousVersionThumbURL}
            size="xxl"
          />
        </DNABox>
      </DNABox>
      <DNABox alignX="start">
        <DNAText bold style={style.textColor}>{previousPageNum}. {previousTitle}</DNAText>
      </DNABox>
    </DNABox>
  )
}

const NewSlidePreview: React.FC<NewSlidePreviewProps> = (props) => {
  const {
    newDocumentVersionORM,
    newPageNum,
    latestDocumentVersionContentPageData,
  } = props

  if (!newDocumentVersionORM || !newPageNum || !latestDocumentVersionContentPageData) return null

  const newVersionThumbURL = getThumbURL(newDocumentVersionORM, newPageNum)
  const newTitle = getSlideTitle(newDocumentVersionORM, newPageNum, latestDocumentVersionContentPageData)

  return (
    <DNABox appearance="col" spacing="sm">
      <DNABox alignX="center" appearance="col" spacing="sm">
        <DNABox appearance="row" spacing="sm" alignY="center">
          <DNAText bold style={style.textColor}>Version 2.0</DNAText>
          <DNABox style={{ paddingVertical: 8 }}>
            <DNAChip style={{ borderRadius: 2 }} appearance="tag" status="success">New</DNAChip>
          </DNABox>
        </DNABox>
        <DNABox style={style.thumbnailContainer}>
          <DNAThumbnail
            key={newDocumentVersionORM?.model.id}
            s3URL={newVersionThumbURL}
            size="xxl"
          />
        </DNABox>
      </DNABox>
      <DNABox alignX="start">
        <DNAText bold style={style.textColor}>{newPageNum}. {newTitle}</DNAText>
      </DNABox>
    </DNABox>
  )
}

export const MatchSlidesPreviewModal: DNAModalVariant<MatchSlidesPreviewModalProps> = (props) => {
  const {
    previousDocumentVersionORM,
    previousPageNum,
    latestPublishedVersionContentPageData,
    newDocumentVersionORM,
    newPageNum,
    latestDocumentVersionContentPageData,
  } = props

  const dispatch = useDispatch();

  function closeModal(): void {
    dispatch(DNAModalActions.closeModal());
  }

  return (
    <DNAModal>
      <DNABox alignX="end">
        <DNAModal.Header onClose={closeModal} />
      </DNABox>
      <DNAModal.Body>
        <DNABox alignX="start" appearance="col" spacing="sm" fill style={style.container}>
          <DNABox appearance="row" spacing="xs">
            <PreviousSlidePreview
              previousDocumentVersionORM={previousDocumentVersionORM}
              previousPageNum={previousPageNum}
              latestPublishedVersionContentPageData={latestPublishedVersionContentPageData}
            />
            <NewSlidePreview
              newDocumentVersionORM={newDocumentVersionORM}
              newPageNum={newPageNum}
              latestDocumentVersionContentPageData={latestDocumentVersionContentPageData}
            />
          </DNABox>
        </DNABox>
      </DNAModal.Body>
    </DNAModal>
  )
}

export default MatchSlidesPreviewModal
