/* eslint-disable max-len */
import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

function SvgCrmIcon(props: SvgProps) {
  return (
    <Svg width="64" height="64" viewBox="0 0 64 64" {...props}>
      <rect width="64" height="64" rx="32" fill="white"/>
      <Path d="M32 55.04C24 55.04 16.928 50.944 12.8 44.8C12.896 38.4 25.6 34.88 32 34.88C38.4 34.88 51.104 38.4 51.2 44.8C47.072 50.944 40 55.04 32 55.04ZM32 9.6C34.5461 9.6 36.9879 10.6114 38.7882 12.4118C40.5886 14.2121 41.6 16.6539 41.6 19.2C41.6 21.7461 40.5886 24.1879 38.7882 25.9882C36.9879 27.7886 34.5461 28.8 32 28.8C29.4539 28.8 27.0121 27.7886 25.2118 25.9882C23.4114 24.1879 22.4 21.7461 22.4 19.2C22.4 16.6539 23.4114 14.2121 25.2118 12.4118C27.0121 10.6114 29.4539 9.6 32 9.6V9.6ZM32 0C27.7977 0 23.6365 0.827705 19.7541 2.43586C15.8717 4.04401 12.3441 6.40111 9.37258 9.37258C3.37142 15.3737 0 23.5131 0 32C0 40.4869 3.37142 48.6263 9.37258 54.6274C12.3441 57.5989 15.8717 59.956 19.7541 61.5641C23.6365 63.1723 27.7977 64 32 64C40.4869 64 48.6263 60.6286 54.6274 54.6274C60.6286 48.6263 64 40.4869 64 32C64 14.304 49.6 0 32 0Z" fill="url(#paint0_linear_841_63947)"/>
      <defs>
        <linearGradient id="paint0_linear_841_63947" x1="64" y1="0" x2="7.62939e-06" y2="64" gradientUnits="userSpaceOnUse">
          <stop offset="0.00520833" stop-color="#57ABDE"/>
          <stop offset="1" stop-color="#724E84"/>
        </linearGradient>
      </defs>
    </Svg>

  );
}

export default SvgCrmIcon;
