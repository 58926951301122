import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 as uuid } from 'uuid';
import { Active } from '@dnd-kit/core';
import { PageGroup, DocumentAccessLevel } from '@alucio/aws-beacon-amplify/src/models';
import {
  DocumentVersionORM,
  isDocumentORM,
  isDocumentVersionORM,
  isFolderORM,
  isFolderItemORM,
  FolderORM,
} from 'src/types/types';
import { useCurrentUser } from 'src/state/redux/selector/user';
import { useAllDocumentsInstance, useAllPersonalDocuments } from 'src/state/redux/selector/document';
import documentQuery, { filters } from 'src/state/redux/document/query';
import {
  GroupStatus,
  ModifiedPayloadGroup,
  PayloadGroup,
  usePresentationBuilderState,
} from '../state/PresentationBuilderStateProvider';
import { ThumbnailDimensions, ThumbnailSize } from 'src/hooks/useThumbnailSize/useThumbnailSize';
import { useDocumentSearchV2 } from 'src/hooks/useDocumentSearchV2';
import { GroupDraft } from 'src/components/SlideSelector/PageGroupList';
import useThumbnailSelector from 'src/components/SlideSelector/useThumbnailSelector';
import { SlideMode, ThumbnailPage } from 'src/components/SlideSelector/SlideSelector';
import { TargetItem } from 'src/components/DnD/DnDWrapper';
import { ContentORMs } from 'src/components/DNA/types';
import useContentPageData, { ContentPageData } from 'src/hooks/useContentPageData/useContentPageData';
import { getPresentable } from 'src/state/context/ContentProvider/helper';
import { useDNAFolderNav, withDNAFolderNav } from 'src/components/DNA/Folder/Nav/DNAFolderNav';

export interface CurrentTab {
  [x: string]: {
      items: any;
      label: string;
  };
}

type DraftRule = (groupDrafts: GroupDraft[]) => GroupDraft[]

interface PresentationSelectorContextValue {
  currentTab: CurrentTab,
  localSearchText: string,
  selectedTab: string,
  searchText: string,
  searchResultsText: string,
  selectedDocumentVersionORM?: DocumentVersionORM,
  isSelectionMode: boolean,
  allPagesSelected: boolean,
  pages: ThumbnailPage[],
  pageGroups: PageGroup[],
  selectedGroups: PayloadGroup[],
  selectorThumbnailSize: ThumbnailSize,
  selectorThumbnailDimensions: ThumbnailDimensions,
  groupsWithSomeSlidesSelected: PageGroup[],
  setLocalSearchText: React.Dispatch<React.SetStateAction<string>>,
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>,
  setSearchText: React.Dispatch<React.SetStateAction<string>>,
  setSelectedDocumentVersionORM: React.Dispatch<React.SetStateAction<DocumentVersionORM | undefined>>,
  handleSelectAllToggle: (checked?: boolean) => void,
  handleThumbSelect: (thumbPage: ThumbnailPage) => void,
  setPageGroups: React.Dispatch<React.SetStateAction<PageGroup[]>>,
  handleGroupAdd: (insertingIndex?: number) => void,
  handleSelectGroup: (groupDraft: GroupDraft, forceSelect?: boolean) => void,
  cycleSelectorThumbnailSize: () => void,
  getGroupDrafts: () => GroupDraft[],
  getAllGroups: () => GroupDraft[],
  getAllGroupedIndividualSlides: () => GroupDraft[],
  poolItems: string[],
  targetItems: string[],
  selectedItemIds: string[],
  rearrangeEditorSlides: (targetItems: TargetItem[], lastActive?: Active) => void,
  active: Active | undefined,
  setActive: React.Dispatch<React.SetStateAction<Active | undefined>>,
  contentPageData?: ContentPageData[],
  onItemPress: (item: ContentORMs) => void,
  clearNavStack: () => void,
  popToFolder: (folderORM?: FolderORM) => void,
  isCurrentlyNested: boolean,
  folderStack: FolderORM[],
}

/** This function filters out slides that are selected */
function filterSelectedSlides (groupDrafts: GroupDraft[]) {
  const allSelected = groupDrafts.filter(({ checked, name, pages }) =>
    checked || (!name && pages.some(page => page.checked)))

  const allGroups:GroupDraft[] = []
  const allSingleSlides:GroupDraft[] = []
  allSelected.forEach(group => {
    if (group.pages.length > 1) allGroups.push(group)
    else allSingleSlides.push(group)
  })
  // sort the groups in alphabetical order
  allGroups.sort((a, b) => {
    if (!a.name) return -1
    else if (!b.name) return 1
    else return a.name.localeCompare(b.name)
  })
  return [...allGroups, ...allSingleSlides]
}

export const Options = {
  BOOKMARKS: 'Bookmarks',
  MY_FOLDERS: 'My Folders',
  LIBRARY: 'Library',
  MY_UPLOADS: 'My Uploads',
  SEARCH_RESULTS: 'Search results',
};

const BOOKMARKED_DOCS_QUERY = documentQuery.merge(
  documentQuery.filters.published,
  documentQuery.filters.bookmarked,
  // vvvv This filter will show all user avaliable docs + nonModifiable docs with at least one group
  documentQuery.filters.hidingEnabledAndNonModifiableWithGroup,
  documentQuery.sorts.bookmarkedDesc,
)

const ALL_DOCS_QUERY = documentQuery.merge(
  documentQuery.sorts.updatedAtDesc,
  documentQuery.sorts.titleAsc,
  documentQuery.filters.published,
  // vvvv This filter will show all user avaliable docs + nonModifiable docs with at least one group
  documentQuery.filters.hidingEnabledAndNonModifiableWithGroup,
  documentQuery.sorts.bookmarkedDesc,
)

export const PresentationSelectorStateContext = createContext<PresentationSelectorContextValue>(null!)
PresentationSelectorStateContext.displayName = 'PresentationSelectorContext'

const PresentationSelectorStateProvider: React.FC<PropsWithChildren> = (props) => {
  const [localSearchText, setLocalSearchText] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [selectedDocumentVersionORM, setSelectedDocumentVersionORM] = useState<DocumentVersionORM | undefined>();
  const presentable = getPresentable(selectedDocumentVersionORM);
  const { contentPageData } = useContentPageData(presentable);
  const {
    builderMode,
    selectorThumbnailDimensions,
    selectorThumbnailSize,
    customDeck,
    selectedGroups,
    setSelectedGroups,
    cycleSelectorThumbnailSize,
    editorType,
    setNumOfRequiredSlides,
    associatedParentsMap,
    setAssociatedParentsMap,
    selectedTargetItems,
    targetFolder,
  } = usePresentationBuilderState();
  const [selectedTab, setSelectedTab] = useState<string>(targetFolder ? Options.MY_FOLDERS : Options.BOOKMARKS);

  const thumbnailSelectorConfig = useMemo(() => {
    return {
      mode: SlideMode.DocumentVersion,
      disableRequiredToggle: false,
    }
  }, [])
  const {
    allPagesSelected,
    pages,
    pageGroups,
    groupsWithSomeSlidesSelected,
    handleSelectAllToggle,
    handleThumbSelect,
    setPageGroups,
    handleSelectGroup,
    getGroupDrafts,
    getAllGroups,
    getAllGroupedIndividualSlides,
    initializeThumbnails,
  } = useThumbnailSelector(thumbnailSelectorConfig, true)

  const isSelectionMode = builderMode === 'selection';

  const bookmarkedDocs = useAllDocumentsInstance(BOOKMARKED_DOCS_QUERY)
  const documents = useAllDocumentsInstance(ALL_DOCS_QUERY)
  const myPersonalDocuments = useAllPersonalDocuments()
  const {
    currentItems,
    pushFolder,
    popToFolder,
    folderStack,
    isCurrentlyNested,
  } = useDNAFolderNav()

  // NON-MODIFIABLE DOC WITHOUT GROUPS ARE NOT ALLOW IN CUSTOM DECK
  const filteredFolderItems = useMemo(() => {
    return currentItems.filter(item => {
      if (
        isDocumentVersionORM(item.relations.itemORM) &&
        item.relations.itemORM.meta.permissions.MSLNonModifiable &&
        item.relations.itemORM.relations.pageGroups.length < 1
      ) return false
      else return true
    })
  }, [currentItems])

  const {
    documentORMSearchResults: resultsFromSearch,
    isLoadingSearch,
  } = useDocumentSearchV2(searchText, filters.published);

  const currentTab: CurrentTab = useMemo(() => {
    return {
      [Options.BOOKMARKS]: {
        items: bookmarkedDocs,
        label: 'Bookmarks',
      },
      [Options.MY_FOLDERS]: {
        items: filteredFolderItems,
        label: 'My Folders',
      },
      [Options.LIBRARY]: {
        items: documents,
        label: 'Library',
      },
      [Options.MY_UPLOADS]: {
        items: myPersonalDocuments,
        label: 'My Uploads',
      },
      // "Ghost tab". It works as a tab but is not shown as an option to be selected.
      // Will be applied upon a valid search text input value.
      [Options.SEARCH_RESULTS]: {
        items: resultsFromSearch.filter((document) =>
          document.relations.version.latestPublishedDocumentVersionORM?.meta.permissions.MSLSelectSlides,
        ),
        label: 'Search results',
      },
    }
  }, [bookmarkedDocs, currentItems, documents, myPersonalDocuments, resultsFromSearch])

  // This function adds the associated slides at the end of groupDrafts array
  const addAssociated = (groupDrafts: GroupDraft[]) => {
    const associatedSlideIds = new Set<string>();
    // Map through groupDraft and add the associated slides to associatedSlides set
    groupDrafts.forEach(groupDraft => {
      groupDraft.pages.forEach(page => {
        page.linkedSlides?.forEach(slideId => {
          associatedSlideIds.add(slideId)
        })
      })
    })
    // Map through groupDrafts pages and remove the id from associatedSlideIds
    groupDrafts.forEach(groupDraft => {
      groupDraft.pages.forEach(page => {
        associatedSlideIds.delete(page.pageId)
      })
    })
    // Map through selectedGroups and remove the id from associatedSlideIds
    selectedGroups.forEach(group => {
      group.pages.forEach(page => {
        associatedSlideIds.delete(page.pageId)
      })
    })

    const result: GroupDraft[] = []
    const associatedParents = new Map(associatedParentsMap)
    // Map through associated slide ids and convert it into an usable object
    associatedSlideIds.forEach(slideId => {
      const page = pages.find(page => page.pageId === slideId)
      if (page) {
        associatedParents.set(page.pageId, true)
        result.push({ id: page.pageId, pages: [page] })
      }
    })

    const allPayloadGroups:GroupDraft[] = []
    const singleSelectedSlides:GroupDraft[] = []
    groupDrafts.forEach(groupDraft => {
      const { pages:{ length } } = groupDraft
      length > 1
        ? allPayloadGroups.push(groupDraft)
        : singleSelectedSlides.push(groupDraft)
    })

    // Sorting all slides: Group first and then the rest in slide number order
    const sortedByPageNum = [...singleSelectedSlides, ...result]
      .sort((a, b) => (a.pages[0].number - b.pages[0].number))

    setAssociatedParentsMap(associatedParents)
    return [...allPayloadGroups, ...sortedByPageNum]
  }

  const calculateSelectionForDraft = (
    groupDrafts: GroupDraft[],
    ...rules: DraftRule[]
  ) => {
    let groupsForProcessing = groupDrafts
    // apply the rule one by one
    for (const rule of rules) {
      groupsForProcessing = rule(groupsForProcessing)
    }
    const allAssociatedParents: {[key: string]: string[]} = {}
    pages.forEach(page => {
      page.linkedSlides?.forEach(slideId => {
        if (allAssociatedParents[slideId]) allAssociatedParents[slideId].push(page.pageId)
        else allAssociatedParents[slideId] = [page.pageId]
      })
    })

    const result = groupsForProcessing.map(group => {
      const groupId = uuid();
      const newPayloadPages: ThumbnailPage[]  = []
      group.pages.forEach((page) => {
        const parentIds: string[] = []
        if (allAssociatedParents[page.pageId]) parentIds.push(...allAssociatedParents[page.pageId])
        const newPayloadPage = Object.assign({}, page)
        newPayloadPage.parentIds = parentIds
        newPayloadPages.push(newPayloadPage)

        // ANALYTIC TRACKING: track every single slide added
        analytics?.track('CUSTOM_SLIDE_ADDED', {
          action: 'SLIDE_ADDED',
          category: 'CUSTOM',
          customDeckId: customDeck?.model.id,
          groupId: groupId,
          pageId: page.pageId,
          editorType: editorType,
        })
      })

      // ANALYTIC TRACKING: named group added
      if (group.pages.length > 1) {
        analytics?.track('GROUP_ADDED', {
          customDeckId: customDeck?.model.id,
          srcGroupId: group.id,
        })
      }

      const mappedPayloadGroup: ModifiedPayloadGroup = {
        documentVersionORM: selectedDocumentVersionORM,
        id: groupId,
        groupSrcId: group.id,
        groupStatus: GroupStatus.ACTIVE,
        pages: newPayloadPages,
        visible: true,
        docAccessLevel: selectedDocumentVersionORM?.relations.documentORM.model.accessLevel!,
        name: group.name,
        locked: !!group.locked,
      }

      return mappedPayloadGroup
    })

    return result
  }

  const searchResultsText: string = localSearchText === ''
    ? `${currentTab[selectedTab].items.length} item(s)` : `Search results for "${localSearchText}"` +
    `${isLoadingSearch ? '' : ` | ${currentTab[selectedTab].items.length} result(s)`}`;

  const handleGroupAdd = (insertingIndex?: number) => {
    const allGroupDrafts = getGroupDrafts()
    const newPayloadGroups = calculateSelectionForDraft(allGroupDrafts, filterSelectedSlides, addAssociated)

    // Calculate the required slides and associated slides added that is not selected by the user
    let allRequiredNum = 0
    const modifiable = !!selectedDocumentVersionORM?.meta.permissions.MSLSelectSlides

    newPayloadGroups.forEach(group => {
      // If the required/associated slide(s) is in a named group, then skip
      const isSlideGroup = group.pages?.length > 1

      if (isSlideGroup) {
        group.pages.forEach(page => {
          // if this is a non-modifiable document, the required slide outside of the
          // group will be checked in the background (not by user)
          const notModifiableNRequired = !modifiable && page.isRequired
          const isNotCheckedNIsAssociatedSlide = !page.checked && associatedParentsMap.get(page.pageId)
          if (notModifiableNRequired || isNotCheckedNIsAssociatedSlide) allRequiredNum++
        })
      }
    })

    // Uncheck any groups
    setPageGroups(p => {
      return p.map(group => {
        return { ...group, checked: false }
      })
    })
    setNumOfRequiredSlides(allRequiredNum)
    if (typeof insertingIndex !== 'undefined') {
      setSelectedGroups(p => {
        const front = p.slice(0, insertingIndex)
        const back = p.slice(insertingIndex)
        return [...front, ...newPayloadGroups, ...back]
      })
    }
    else setSelectedGroups(p => [...p, ...newPayloadGroups])
    // unselect / uncheck all individual slides after adding to presentation
    handleSelectAllToggle(false);
  }

  //  we are recusrively adding the parent in case in the future we have more than 2 levels of nested documents
  const getFolderHierarchy = (folderORM: FolderORM, folderStack: FolderORM[] = []): FolderORM[] => {
    if (folderORM.relations.parentFolderORM) {
      return getFolderHierarchy(folderORM.relations.parentFolderORM, [folderORM, ...folderStack])
    }
    return [folderORM, ...folderStack]
  }

  useEffect(() => {
    if (targetFolder) {
      const folderStack = getFolderHierarchy(targetFolder)
      folderStack.forEach(stack => {
        pushFolder(stack)
      })
    }
  }, [])

  useEffect(() => {
    selectedDocumentVersionORM && initializeThumbnails(selectedDocumentVersionORM, false)
  }, [selectedDocumentVersionORM])

  // For Drag and Drop Feature
  const currentUser = useCurrentUser()
  const poolItems = useMemo(() => {
    if (!selectedDocumentVersionORM) return []

    // Only if document is user uploads or modifiable, then show the single slides
    const documentORMModel = selectedDocumentVersionORM?.relations.documentORM.model
    const isUserUploads = documentORMModel.accessLevel === DocumentAccessLevel.USER &&
      documentORMModel.createdBy === currentUser.authProfile?.attributes.email
    const modifiable = selectedDocumentVersionORM?.meta.permissions.MSLSelectSlides

    const groupIds: string[] = selectedDocumentVersionORM.relations.pageGroups
      .slice()
      .sort((a, b) => {
        if (!a.model.name) return -1
        else if (!b.model.name) return 1
        else return a.model.name.localeCompare(b.model.name)
      }).map(pageGroup => pageGroup.model.id)

    if (!(isUserUploads || modifiable)) return groupIds

    const singleSlideIds: string[] = selectedDocumentVersionORM.relations.pages.map(page => page.model.pageId)
    return [...groupIds, ...singleSlideIds]
  }, [selectedDocumentVersionORM, currentUser])
  const targetItems = useMemo(() => selectedGroups.map(group => group.id), [selectedGroups])
  // selectedItemIds is an array of currently checked item's ids, it can contains slide ids and group ids.
  const selectedItemIds = useMemo(() => {
    const checkedGroupIds = pageGroups
      .filter(group => group.checked)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(group => group.id)
    const checkedPageIds = pages
      .filter(page => page.checked)
      .map(page => page.pageId)
      .sort((a, b) => a.localeCompare(b, 'en', { numeric: true }))
    return [...checkedGroupIds, ...checkedPageIds]
  }, [pages, pageGroups])

  const rearrangeEditorSlides = (targetItems: TargetItem[], lastActive?: Active) => {
    if (targetItems.length !== selectedGroups.length) return

    const selectedGroupsMap = new Map(selectedGroups.map(group => [group.id, group]))
    const targetItemsItemId  = targetItems.map(({ itemId }) => itemId)

    const activeElement = lastActive?.data?.current?.itemId as string
    let newIndex = targetItemsItemId.findIndex((id) => id === activeElement)
    if (newIndex === -1) return

    const selectedTargetItemsWithoutActive = selectedTargetItems.filter((id) => id !== activeElement)
    const newGroupOrder = targetItemsItemId.filter((id) => !selectedTargetItemsWithoutActive.includes(id))

    // the index should be recalculate after removing the active element
    newIndex = newGroupOrder.findIndex((id) => id === activeElement)
    newGroupOrder.splice(newIndex + 1, 0, ...selectedTargetItems.filter((id) => id !== activeElement))

    setSelectedGroups(newGroupOrder.reduce<PayloadGroup[]>((acc, id) => {
      const group = selectedGroupsMap.get(id)
      if (group) acc.push(group)
      return acc
    }, []))
  }

  const [active, setActive] = useState<Active>()

  const onItemPress = (item: ContentORMs) => {
    if (isFolderORM(item)) {
      pushFolder(item)
    }
    else if (isFolderItemORM(item) && isDocumentVersionORM(item.relations.itemORM)) {
      const documentORM = item.relations.itemORM.relations.documentORM
      const updatedItem = documentORM.relations.version.latestPublishedDocumentVersionORM
      setSelectedDocumentVersionORM(updatedItem)
    }
    else if (isDocumentORM(item)) {
      const updatedItem = item.relations.version.latestPublishedDocumentVersionORM
      setSelectedDocumentVersionORM(updatedItem)
    }
  }

  function clearNavStack() {
    popToFolder();
    setSelectedDocumentVersionORM(undefined);
  }

  const contextValue: PresentationSelectorContextValue = {
    currentTab,
    localSearchText,
    selectedTab,
    searchText,
    searchResultsText,
    selectedDocumentVersionORM,
    isSelectionMode,
    allPagesSelected,
    pages,
    pageGroups,
    selectedGroups,
    selectorThumbnailSize,
    selectorThumbnailDimensions,
    groupsWithSomeSlidesSelected,
    setLocalSearchText,
    setSelectedTab,
    setSearchText,
    setSelectedDocumentVersionORM,
    handleSelectAllToggle,
    handleThumbSelect,
    setPageGroups,
    handleGroupAdd,
    handleSelectGroup,
    cycleSelectorThumbnailSize,
    getGroupDrafts,
    getAllGroups,
    getAllGroupedIndividualSlides,
    poolItems,
    targetItems,
    selectedItemIds,
    rearrangeEditorSlides,
    active,
    setActive,
    contentPageData,
    onItemPress,
    clearNavStack,
    popToFolder,
    isCurrentlyNested,
    folderStack,
  }

  return (
    <PresentationSelectorStateContext.Provider value={contextValue}>
      {props.children}
    </PresentationSelectorStateContext.Provider>
  )
}

export function usePresentationSelector() {
  const context = useContext(PresentationSelectorStateContext)
  if (!context) {
    throw new Error('usePresentationSelector must be used within the PresentationSelectorStateProvider')
  }
  return context;
}

export default withDNAFolderNav(PresentationSelectorStateProvider)
