import React from 'react';
import { DNABox, Iffy } from '@alucio/lux-ui';
import { usePresentationSelector } from './PresentationSelectorStateProvider';
import Header from './Header';
import SubHeader from './SubHeader';
import Content from './Content';
import LowerActionBar from './LowerActionBar';

const PresentationSelector: React.FC = () => {
  const {
    isSelectionMode,
    selectedDocumentVersionORM,
  } = usePresentationSelector()

  return (
    <DNABox fill appearance="col" style={{ display: isSelectionMode ? undefined : 'none' }}>
      <Header />
      <SubHeader />
      <Content />
      <Iffy is={selectedDocumentVersionORM}>
        <LowerActionBar/>
      </Iffy>
    </DNABox>
  )
}

export default PresentationSelector
