import React, { ReactNode } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { DNABox, DNADivider, DNAIcon, DNAText, Iffy } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';

type GenericComponent<P = {}> = React.FC<P & {
  testID?: string;
  style?: object;
  children?: ReactNode;
}>;

type SidebarContentProps = GenericComponent & {
  Header: typeof Header,
  Body: typeof Body,
}

type HeaderProps = {
  sidebarHeader: string,
  rightElement?: React.ReactNode,
  descriptionText?: string,
}

export const styles = StyleSheet.create({
  sideBarHeaderContainer: {
    minHeight: 75,
    padding: 18,
  },
  sidebarContentContainer: {
    paddingHorizontal: 24,
    paddingBottom: 20,
  },
  tooltipIcon: {
    color: colors['color-gray-200'],
    height: 18,
    width: 18,
    paddingLeft: 5,
  },
});

const SidebarContent: SidebarContentProps = (props) => {
  const { children } = props

  return (
    <DNABox
      testID={'content-preview-modal-side-bar-scroll-view'}
      fill
      as={ScrollView}
      style={{ width: 320 }}
      spacing="md"
      appearance="col"
      childFill={1}
    >
      {children}
    </DNABox>
  )
}

const Header: GenericComponent<HeaderProps> = (props) => {
  const { sidebarHeader, rightElement, descriptionText } = props

  return (
    <DNABox
      testID="side-bar-header-text"
      appearance="col"
      style={styles.sideBarHeaderContainer}
      spacing="between"
    >
      <DNABox fill appearance="row" spacing="between">
        <DNABox alignY="center">
          <DNAText testID="side-bar-text" h4>
            {sidebarHeader}
          </DNAText>
          <Iffy is={descriptionText}>
            <DNAPopover placement="top" >
              <DNAPopover.Anchor>
                <DNAIcon name="information-outline" status="tertiary" size="lg" style={styles.tooltipIcon} />
              </DNAPopover.Anchor>
              <DNAPopover.Content offset={2}>
                <DNAText status="basic">
                  {descriptionText}
                </DNAText>
              </DNAPopover.Content>
            </DNAPopover>
          </Iffy>
        </DNABox>
        {rightElement && rightElement}
      </DNABox>
      <DNADivider />
    </DNABox>
  )
}

const Body: GenericComponent<{}> = (props) => {
  const { children } = props
  return (
    <DNABox appearance="col" fill style={styles.sidebarContentContainer}>
      {children}
    </DNABox>
  )
}

SidebarContent.displayName = 'SidebarContent'
SidebarContent.Header = Header
SidebarContent.Body = Body

export default SidebarContent
