import { DNABox, DNADivider } from '@alucio/lux-ui'
import React from 'react'
import { FileDrawerProvider } from './FileDrawerProvider'
import FileDrawerContent from './FileDrawerContent'
import FileDrawerHeader from './FileDrawerHeader'
import FileDrawerTabs from './FileDrawerTabs'
import DocumentSearchProvider from 'src/hooks/useDocumentSearchV2'

interface Props {
  toggleDrawer: () => void,
}

const FileDrawer = (props: Props) => {
  const { toggleDrawer } = props
  return (
    <FileDrawerProvider {...props}>
      <DocumentSearchProvider mode="FOLDER">
        <DNABox appearance="col" fill>
          {/* Header */}
          <FileDrawerHeader title="Add files to folder" closeDrawer={toggleDrawer}/>
          <DNADivider />
          {/* Tabs */}
          <FileDrawerTabs />
          {/* Content */}
          <FileDrawerContent />
        </DNABox>
      </DocumentSearchProvider>
    </FileDrawerProvider>
  )
}

export default FileDrawer
