import {
  useDispatch as useDispatchOG,
  useSelector as useSelectorOG,
  TypedUseSelectorHook,
} from 'react-redux'
import { store, RootState } from 'src/state/redux/store'

export { store, observeSlice } from 'src/state/redux/store'

export type { RootState } from 'src/state/redux/store'
export type AppDispatch = typeof store.dispatch

export const useDispatch: () => AppDispatch = useDispatchOG
export const useSelector: TypedUseSelectorHook<RootState> = useSelectorOG
