import { useState, useEffect } from 'react';
import { CustomDeckORM, Presentable } from 'src/types/types';
import { fetchPagesJson } from 'src/utils/loadCloudfrontAsset/common';
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector';
import logger from 'src/utils/logger';
import { getCurrentDocVer } from 'src/state/machines/sharing/shareUtils';

export interface Recommendations {
  pageId: string
  score: number
}

export interface ContentPageData {
  pageId: string;
  content?: string;
  presentationPageNumber: number;
  speakerNotes?: string | null;
  s3Key: string;
  title?: string;
  recommendations: Recommendations[];
  mapping: string | null;
  originalTitle?: string;
  originalSpeakerNotes?: string;
}

// External cache to store fetched data
const cache: { [key: string]: ContentPageData[] } = {};
/**
 * Function for fetching the JSON file contents of a given presentable
 * @param presentable: Presentable
 * @param disableCache: boolean - Disables cache if true
 * @param showOriginalData: boolean - if true, originalTitle & originalSpeakerNotes will be in the return value if there is any.
 * @returns { matches: MatchedPage[], totalMatchCount: number }
 */
export const useContentPageData = (presentable?: Presentable, disableCache?: boolean, showOriginalData?: boolean) => {
  const [contentPageData, setContentPageData] = useState<ContentPageData[] | undefined>(undefined);
  logger.contentPresenting.contentPageData.debug(
    '[START] Inside useContentPageData',
    { cacheKey: presentable?.orm.model.id },
  )

  // Fetches
  useEffect(() => {
    if (!presentable) return;

    // Create an abort controller to cancel async tasks if the component unmounts
    const controller = new AbortController();
    const { signal } = controller;
    // Check if data is already cached
    const cacheKey = presentable.orm.model.id;
    logger.contentPresenting.contentPageData.debug('[UPDATE] inside useEffect', { cacheKey })

    if (cacheKey in cache && !disableCache) {
      logger.contentPresenting.contentPageData.debug('[END] Using cached data', { cacheKey });
      setContentPageData(cache[cacheKey]);
      return;
    }

    // Measure fetch time
    const fetchStartTime = (performance && performance.now) ? performance.now() : Date.now();
    logger.contentPresenting.contentPageData.debug('Start fetching page data...', { cacheKey })

    // Fetch the data and update the cache
    const getDocumentsTextsAndFormat = async () => {
      const s3Keys = presentable.presentablePages.reduce<{ [key: string]: string }>((acc, page) => {
        // since we are storing the titles and speaker notes in the json file, we need to make sure we are
        // getting the correct version of the file. If the file has been updated in the last 10 minutes,
        // we will use the current version of the file. Otherwise, we will use the version of the file
        // that was published at the time of the presentation. This is to ensure that the titles and
        // speaker notes are correct for the presentation, and avoid any caching issues (disk and cloudfront).
        if (!page.documentVersionORM.model.convertedFolderKey) return acc
        const publishedAtDate = page.documentVersionORM.model.publishedAt
          ? new Date(page.documentVersionORM.model.publishedAt)
          : null;
        const timeDiff = publishedAtDate ? (new Date().getTime() - publishedAtDate.getTime()) / 60000 : 0;
        const param = timeDiff < 10 || !publishedAtDate
          ? `?${new Date().getTime()}`
          : `?${publishedAtDate.getTime()}`;
        acc[page.documentVersionORM.model.id] =
          `${page.documentVersionORM.model.convertedFolderKey}json/Pages.json${param}`;
        return acc;
      }, {});

      try {
        // Check if the abort signal is already triggered before starting
        if (signal.aborted) {
          setContentPageData([]);
          return;
        }

        const responses = await fetchPagesJson(s3Keys, disableCache);

        if (!responses || signal.aborted) {
          setContentPageData([]);
          return;
        }

        const pages: ContentPageData[] = [];
        // ASSIGNS THE CONTENT TO EACH PAGE
        presentable.presentablePages.forEach((page) => {
          const formattedPage: ContentPageData = {
            pageId: page.documentVersionORM.model.id + '_' + page.page.number,
            presentationPageNumber: page.presentationPageNumber,
            s3Key: detectArchivedFileKeyPath(page.documentVersionORM.model, page.page, 'sm') || '',
            recommendations: [],
            mapping: null,
          };

          // FINDS THE CONTENT FOR THE PAGE AND ASSIGNS IT
          responses.forEach((response) => {
            if (response.documentVersionId === page.documentVersionORM.model.id) {
              // FINDS THE PAGE
              response.content.pages.forEach((responsePage) => {
                if (responsePage?.number === page.page.number) {
                  formattedPage.speakerNotes = responsePage.overrideSpeakerNotes ?? responsePage.speakerNotes;
                  formattedPage.content = responsePage.content;
                  formattedPage.title = responsePage.overrideTitle ?? responsePage.title;
                  formattedPage.recommendations = responsePage.recommendations ?? [];
                  formattedPage.mapping = responsePage.mapping ?? null;
                  formattedPage.originalTitle = showOriginalData ? responsePage.title : undefined;
                  formattedPage.originalSpeakerNotes = showOriginalData
                    ? responsePage.speakerNotes || undefined
                    : undefined;
                }
              });
            }
          });
          pages.push(formattedPage);
        });

        // Update cache and state
        cache[cacheKey] = pages;
        setContentPageData(pages);
      } catch (error) {
        if (!signal.aborted) {
          // Only log error if not aborted
          const errorText = 'Error fetching pages data';
          logger.contentPresenting.contentPageData.warn(errorText, { cacheKey, error });
        }
      }

      // Measure fetch time
      const fetchEndTime = performance.now();
      logger.contentPresenting.contentPageData.debug(
        `[END] Finished fetching page data. Fetch time: ${(fetchEndTime - fetchStartTime).toFixed(2)} ms`,
        { cacheKey },
      )
    };

    setContentPageData(undefined);
    getDocumentsTextsAndFormat();

    // Cleanup function to abort fetch on unmount
    return () => {
      controller.abort();
    };
  }, [presentable?.orm.model.id, presentable?.numberOfPages, disableCache]);

  if (presentable && cache[presentable.orm.model.id] && !disableCache) {
    logger.contentPresenting.contentPageData.debug('[END] Using cached data', { cacheKey: presentable.orm.model.id });
    return {
      contentPageData: cache[presentable.orm.model.id],
    };
  }

  return {
    contentPageData: contentPageData || [],
  }
}

export const getNewContentPageData = (contentPageData: ContentPageData[]) => {
  return contentPageData.reduce<{ [key: string]: ContentPageData }>((acc, currentPageData) => {
    if (currentPageData.mapping && !acc[currentPageData.mapping]) {
      acc[currentPageData.mapping] = currentPageData
    }
    return acc
  }, {})
}

export const getSlideContentPageDataTitle = (
  idx: number,
  contentPageData? : ContentPageData[],
  showDefaultTitle : boolean = false,
) : string => {
  const defaultTitle = showDefaultTitle ? `Slide ${idx + 1}` : '';
  if (!contentPageData || !contentPageData[idx]) return defaultTitle
  return contentPageData[idx].title || defaultTitle
}

export const hasSpeakerNotes = (idx:number, contentPageData? : ContentPageData[]) : boolean => {
  if (!contentPageData || !contentPageData[idx]) return false
  return !!contentPageData[idx].speakerNotes
}

/**
 * this function takes custom deck and checks if any of the pages within the custom deck have a mapping, then returns an object with the current document version id mapped to the new document version id
 * @param customDeckORM the custom deck to get all the s3Keys of all the document versions
 * @returns an object where each key is the current document version id and value is the new document version id - new document version id is the mapping field in the Pages.json
 */
export const getSlideMappingsFromS3 = async (customDeckORM: CustomDeckORM) => {
  const s3Keys = getS3KeysWithinCustomDeck(customDeckORM, true)
  const responses = await fetchPagesJson(s3Keys, false)

  const newMapping = {}
  customDeckORM.model.groups.forEach(group => {
    responses.forEach(response => {
      const [pageDocId] = group.pages[0].documentVersionId.split('_')
      const [responseDocId] = response.documentVersionId.split('_')
      // only check for mapping if the response and page are from the same Document
      if (responseDocId === pageDocId) {
        response.content.pages
          .filter((responsePage): responsePage is NonNullable<typeof responsePage> & { mapping: string } =>
            responsePage !== null && typeof responsePage.mapping === 'string',
          )
          .forEach(responsePage => {
            if (responsePage.mapping === group.pages[0].pageId) {
              if (!newMapping[responsePage.mapping]) {
                newMapping[responsePage.mapping] = response.documentVersionId + '_' + group.pages[0].pageNumber
              }
            }
          })
      }
    })
  })

  return newMapping
}

/**
 * this function generates all s3Keys of each document version within a custom deck
 * @param customDeckORM the custom deck to get all the s3Keys of all the document versions
 * @param latest boolean, if ture, return the latest version
 * @returns and object where each key is the docVerId from slides within custom deck and the value is the s3Key of that documentVersion
 */
export const getS3KeysWithinCustomDeck = (
  customDeckORM: CustomDeckORM,
  latest?: boolean,
): {[documentVersionId: string]: string} => {
  const s3Keys = customDeckORM.model.groups.reduce<{ [key: string]: string}>((acc, group) => {
    const documentVersionORM = getCurrentDocVer(group.pages[0].documentVersionId)

    const docVerORM = latest
      ? documentVersionORM?.relations.documentORM.relations.version.latestPublishedDocumentVersionORM
      : documentVersionORM

    if (!docVerORM) {
      return acc
    }

    if (docVerORM.model.convertedFolderKey && !acc[docVerORM.model.id]) {
      acc[docVerORM.model.id!] = `${docVerORM?.model.convertedFolderKey}json/Pages.json`
    }
    return acc
  }, {})

  return s3Keys
}

export default useContentPageData;
