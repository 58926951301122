import { DNABox, DNAButton, DNADivider, DNAText, Iffy } from '@alucio/lux-ui'
import React from 'react'
import { FolderORM, SharePermissionORM } from 'src/types/orms'
import UserRow from './UserRow'
import LabelRow from './LabelRow'
import { getRootFolder } from 'src/utils/foldersHelpers';
import { ShareTargetType } from '@alucio/aws-beacon-amplify/src/models';
import { useIndexedUsers } from 'src/state/redux/selector/user';
import { useDNAFolderActions } from 'src/components/DNA/Folder/DNAFolder.actions'
import { useAppSettings } from 'src/state/context/AppSettings'

export interface SharingProps {
  itemORM: FolderORM
}

const Sharing: React.FC<SharingProps> = (props) => {
  const { itemORM } = props
  const { isOnline } = useAppSettings()
  const folderActions  = useDNAFolderActions();
  const name = itemORM.model.name
  const rootFolder = getRootFolder(itemORM);
  const indexedUsers = useIndexedUsers();
  const isSharedWithEveryone = rootFolder.model.sharePermissions?.some(({ targetType, isDeleted }) =>
    targetType === ShareTargetType.ALL && !isDeleted);

  const targetUserPermissions: SharePermissionORM[] = [];
  const labelValuePermissions: SharePermissionORM[] = [];

  // DIVIDES THE PERMISSIONS INTO 2 DIFFERENT ARRAYS TO LATER USE
  rootFolder?.relations.sharePermissions?.forEach((permission) => {
    const {
      model: { isDeleted, targetType, targetUsername },
      meta: { isValidPermission },
    } = permission;

    if (isDeleted || !isValidPermission) {
      return;
    }

    if (targetType === ShareTargetType.USER && indexedUsers[targetUsername || '']) {
      targetUserPermissions.push(permission);
    } else if (targetType === ShareTargetType.FILTER) {
      labelValuePermissions.push(permission);
    }
  });

  // ORDER THE USERS ALPHABETICALLY
  targetUserPermissions.sort((a, b) =>
    (indexedUsers[a.model.targetUsername!].meta.formattedName || '') >
    (indexedUsers[b.model.targetUsername!].meta.formattedName || '') ? 1 : -1,
  );

  return (
    <DNABox
      fill
      appearance="col"
      style={{ paddingHorizontal: 24 }}
      spacing="lg"
      childStyle={[1, { flex: 1, overflow: 'scroll' }]}
    >
      <DNABox appearance="col">
        {/* SECTION - Header */}
        <DNABox style={{ minHeight: 64 }} alignY="center">
          <DNAText bold numberOfLines={2}>{name}</DNAText>
        </DNABox>
        {/* !SECTION - Header */}
        <DNADivider />

        {/* SECTION - Subheader */}
        <DNABox fill alignY="center" style={{ paddingVertical: 12, maxHeight: 56 }}>
          <DNABox>
            <DNAText h4>Sharing</DNAText>
          </DNABox>
          <Iffy is={!itemORM.relations.parentFolderORM && isOnline}>
            <DNABox fill alignX="end">
              <DNAButton
                appearance="outline"
                status="tertiary"
                size="sm"
                onPress={folderActions.share(itemORM)}
              >
                <DNAText>Share</DNAText>
              </DNAButton>
            </DNABox>
          </Iffy>
        </DNABox>
        {/* !SECTION - Subheader */}
      </DNABox>

      {/* SECTION - SHARING PROPERTIES */}
      <Iffy is={rootFolder.meta.isSharedFolder}>
        <DNABox appearance="col" fill spacing="sm">
          {/* SECTION - SHARED WITH EVERYONE */}
          <Iffy is={isSharedWithEveryone}>
            <DNABox appearance="col" fill spacing="md">
              <DNAText bold>Everyone</DNAText>
              <DNADivider />
            </DNABox>
          </Iffy>
          {/* SECTION - LABEL/VALUES PERMISSIONS */}
          {
              labelValuePermissions.map((permissionORM) => (
                <DNABox appearance="col" key={permissionORM.model.id} fill spacing="sm">
                  {
                    Object.keys(permissionORM.meta.customFilterValues).map((fieldId) => (
                      <DNABox key={fieldId} fill appearance="col" style={{ marginTop: 10 }}>
                        <LabelRow
                          label={permissionORM.meta.customFilterValues[fieldId]?.field.fieldLabel}
                          values={permissionORM.meta.customFilterValues[fieldId]?.displayValues}
                        />
                      </DNABox>
                    ))
                  }
                  <DNADivider style={{ marginTop: 10 }}/>
                </DNABox>
              ))
            }
          {/* !SECTION - LABEL/VALUES PERMISSIONS */}
          {/* SECTION - TARGET USER PERMISSIONS */}
          {
              targetUserPermissions.map((permissionORM) => (
                <DNABox key={permissionORM.model.id} fill spacing="sm" appearance="col">
                  <UserRow userID={permissionORM.model.targetUsername!} />
                  <DNADivider />
                </DNABox>
              ))
            }
          {/* !SECTION - TARGET USER PERMISSIONS */}
        </DNABox>
      </Iffy>
      {/* !SECTION - SHARING PROPERTIES */}

      {/* SECTION - NOT SHARING MESSAGE */}
      <Iffy is={!rootFolder.meta.isSharedFolder}>
        <DNAText status="subtle">Only you have access</DNAText>
      </Iffy>
      {/* !SECTION - NOT SHARING MESSAGE */}
    </DNABox>
  )
}

export default Sharing
