import React, {
  useEffect,
  useState,
} from 'react';
import { DNABox, DNAButton, DNADivider, DNAIcon, DNAText, Icon, Iffy } from '@alucio/lux-ui';
import InputComponent from 'src/components/Publishers/InputComponent';
import { Pressable, TouchableOpacity } from 'react-native';
import { styles, TextSearchPanelProps, useContentHookVariants } from '../TextSearchPanel';

import SearchResultsContent from '../SearchResultsContent';
import useFileTextSearch from 'src/hooks/useFileTextSearch/useFileTextSearch';
import useDebouncedState from 'src/hooks/useDebouncedState';
import { LuxSizeEnum } from '@alucio/lux-ui/src/typings';

const DefaultVariant: React.FC<TextSearchPanelProps> = React.memo(({ onClose, contentHookVariant = 'default' }) => {
  const {
    setActiveSlideByPresentationPageNumber,
    activePresentation,
    contentPageData,
  } = useContentHookVariants[contentHookVariant]()
  const {
    activeValue: searchText,
    setActiveValue: setSearchText,
    debouncedValue: debouncedSearchText,
    debouncingActive: searching,
  } = useDebouncedState({ initialValue: '' })

  const { matches, totalMatchCount } = useFileTextSearch(
    debouncedSearchText,
    contentPageData,
  )

  const totalResults = matches.length
  const [activeIndex, setActiveIndex] = useState<number>(0)

  useEffect(() => {
    if (totalResults && totalResults > 0) {
      const { presentationPageNumber } = matches[activeIndex]
      setActiveSlideByPresentationPageNumber(presentationPageNumber)
      const newSelectedPage = activePresentation?.presentable.presentablePages
        .find(p => p.presentationPageNumber === presentationPageNumber)

      newSelectedPage && analytics.track('DOCUMENT_SEARCH_SELECT', {
        action: 'SEARCH_SELECT',
        category: 'DOCUMENT',
        /** Verify with Eric if we also want to include presentation number & custom deck ID */
        pageNumber: newSelectedPage.page.number,
        searchTerm: searchText,
        documentId: newSelectedPage.documentVersionORM.model.documentId,
        documentVersionId: newSelectedPage.documentVersionORM.model.id,
      });
    }
  }, [activeIndex])

  const searchResultsVisible = searchText.length > 2

  const SearchResultsHeader = () => {
    if (!searchResultsVisible) return null
    return (
      <>
        <DNABox appearance="row" alignY="center" style={{ marginHorizontal: 16, marginBottom: 16 }}>
          <DNABox style={styles.menuButtonContainer}>
            <DNAButton
              appearance="ghost"
              status="tertiary"
              size="sm"
              iconLeft="menu-up"
              onPress={() => setActiveIndex((prev) => Math.max(0, prev - 1))}
              disabled={!matches.length || activeIndex < 0}
            />
            <DNADivider vertical />
            <DNAButton
              appearance="ghost"
              status="tertiary"
              size="sm"
              iconLeft="menu-down"
              onPress={() => matches && setActiveIndex((prev) => Math.min(matches.length - 1, prev + 1))}
              disabled={!matches.length}
            />
          </DNABox>
          {
            searching ? <DNAText status="info">Searching...</DNAText>
              : matches && totalMatchCount && totalResults
                ? <DNABox appearance="col">
                  <DNAText testID="cpm-search-results-count" bold>{totalMatchCount} matches</DNAText>
                  <DNAText style={styles.subtitle}>
                    Found on {totalResults} slides
                  </DNAText>
                </DNABox>
                : <DNAText status="danger">No results found</DNAText>
          }
        </DNABox>
        <DNADivider />
      </>

    )
  }

  return (
    <DNABox
      appearance="col"
      style={styles.mainContainer}
    >
      <DNABox appearance="row" style={{ marginLeft: -8 }} alignY="center">
        <InputComponent
          value={searchText}
          onChangeText={setSearchText}
          required={false}
          size={LuxSizeEnum.sm}
          autoFocus={true}
          placeHolder="Search"
          inputStyle={styles.searchBar}
          testID="my-content-panel-search-input"
          getLeftIconFunction={() => (
            <TouchableOpacity>
              <Icon style={styles.searchIcon} name="magnify" />
            </TouchableOpacity>
          )}
          getRightIconFunction={() => (
            <Iffy is={searchText}>
              <Pressable onPress={() => setSearchText('')}>
                <DNAIcon.Styled
                  name="close-circle"
                  appearance="ghost"
                  status="tertiary"
                />
              </Pressable>
            </Iffy>
          )}
        />
        <DNAButton
          appearance="outline"
          status="tertiary"
          size="lg"
          padding="xs"
          iconLeft="close"
          onPress={onClose}
          style={{ marginTop: 2, marginRight: 16 }}
        />
      </DNABox>

      {/* Search Results */}
      <SearchResultsHeader />
      {searchResultsVisible &&
        <SearchResultsContent
          matches={matches}
          searchQuery={debouncedSearchText}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          setActiveSlide={setActiveSlideByPresentationPageNumber}
        />}
    </DNABox>
  )
});

export default DefaultVariant;
