import { DocumentVersionORM, ORMTypes } from 'src/types/types'
import debounce from 'lodash/debounce'
import CacheDB from 'src/worker/db/cacheDB'
import * as logger from 'src/utils/logger'
import { store } from 'src/state/redux'
import { cacheActions } from 'src/state/redux/slice/Cache/cache'
import { allDocumentsSortedAndFilteredFactory } from 'src/state/redux/selector/document'
import { allFolderItems } from 'src/state/redux/selector/folder'
import docQuery from 'src/state/redux/document/query'
import workerChannel from 'src/worker/channels/workerChannel'

/**
 * Identify latest published document versions to sync for offline caching
 * @returns DocumentVersion[]
 */
export const getOfflineDocumentVersions = (): DocumentVersionORM[] => {
  logger.offline.contentCache.info('Identifying versions for offline caching')

  const allDocumentsSelector = allDocumentsSortedAndFilteredFactory()
  const publishedDocuments = allDocumentsSelector(
    store.getState(),
    undefined,
    docQuery.filters.published,
  )
  // 1. All latest, published versions
  const latestPublishedVersions = publishedDocuments
    .map(doc => doc.relations.version.latestPublishedDocumentVersionORM)
    .reduce<Record<string, DocumentVersionORM>>(
      (acc, doc) => {
        if (doc) { acc[doc.model.id] = doc }
        return acc
      },
      {},
    )

  const folderItems = allFolderItems(store.getState(), undefined)
  // 2. All versions found in folders (even if outdated)
  const folderDocVersions = folderItems.reduce<Record<string, DocumentVersionORM>>(
    (acc, folderItem) => {
      if (folderItem.relations.itemORM.type === ORMTypes.DOCUMENT_VERSION) {
        acc[folderItem.relations.itemORM.model.id] = folderItem.relations.itemORM
      }
      return acc
    },
    {},
  )

  // 3. All versions found in custom decks (even if outdated)
  const customDeckDocVersions = folderItems.reduce<Record<string, DocumentVersionORM>>(
    (acc, folderItem) => {
      if (folderItem.relations.itemORM.type === ORMTypes.CUSTOM_DECK) {
        const groups = folderItem.relations.itemORM.meta.customDeckGroups

        groups.forEach((group) => {
          if (group.pages[0]?.documentVersionORM) {
            acc[group.pages[0].documentVersionORM.model.id] = group.pages[0].documentVersionORM
          }
        })
      }
      return acc
    },
    {},
  )

  const docVersionsToSyncMap = {
    ...latestPublishedVersions,
    ...folderDocVersions,
    ...customDeckDocVersions,
  }

  const docVersionsToSync = Object.values(docVersionsToSyncMap)  
  logger.offline.contentCache.verbose({ docVersionsToSync: docVersionsToSync.map(docVer => docVer.model.id) })
  logger.offline.contentCache.info(`Unique Document Versions to Sync ${docVersionsToSync.length}`)
  return docVersionsToSync
}

/**
 * Creating sync manifest record to IndexDB for offline content caching
 */
export const syncCacheManifest = async (): Promise<void> => {
  logger.offline.contentCache.manifest.info('Syncing latest offline content manifest')
  const targetDocVers = getOfflineDocumentVersions()

  logger.offline.contentCache.manifest.debug('Opening IDB')
  const cacheDB = new CacheDB()
  await cacheDB.open()
  await cacheDB.syncCacheManifest(targetDocVers)
  logger.offline.contentCache.manifest.info('Synced offline content manifest record to IDB')

  const syncManifest = await cacheDB.getCacheManifest()
  store.dispatch(cacheActions.setManifestEntries(syncManifest))
  logger.offline.contentCache.manifest.debug('Synced offline content manifest record to Redux')

  await cacheDB.close()
  logger.offline.contentCache.manifest.debug('Closed IDB connection')
}

/**
 * Debounce to have more of a "batched" effect
 * Because if a machine is currently syncing, it will ignore
 * any other START_SYNC signals and miss potential mainfest
 * changes that happened after the in-progress Sync
 */
export const syncCacheManifestDebounced = debounce(async function (startSync: boolean) {
  await syncCacheManifest()
  if (startSync) {
    workerChannel.postMessageExtended({ type: 'CLIENT_CONNECTED', value: 'START_SYNC' })
  }
}, 5000)
