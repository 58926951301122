import createContextProxy from 'src/state/context/contextProxy'
import { SearchContextValue, documentSearchV2Factory } from './useDocumentSearchV2'

const documentSearchStateProvider = createContextProxy<SearchContextValue>('DocumentSearchV2')

export const DocumentSearchContext = documentSearchStateProvider.ContextProxy
export const DocumentSearchProxyProvider = documentSearchStateProvider.ContextProxyProvider
DocumentSearchProxyProvider.displayName = 'DocumentSearchProviderProxy'

export const useDocumentSearchV2Context = documentSearchStateProvider.useProxy
export const useDocumentSearchV2 = documentSearchV2Factory(DocumentSearchContext)
