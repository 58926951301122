import React, { useCallback, useReducer } from 'react';
import { DNABox, DNAButton, DNAText, Iffy } from '@alucio/lux-ui';
import { submitPublisherIntegrationRunAPI } from '.';
import { IntegrationRowProps, styles } from '../Tenants/Tabs/Integrations';

const usePublisherRow = () => {
  const integrationReducer = (state, action) => {
    switch (action.type) {
      case 'startSync':
        return { ...state, apiCall: true, syncError: '' };
      case 'syncSuccess':
        return { ...state, isRunning: true, apiCall: false, syncError: '' };
      case 'syncError':
        return { ...state, apiCall: false, syncError: action.payload };
      case 'endSync':
        return { ...state, apiCall: false };
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const initialState = {
    isRunning: false,
    apiCall: false,
    syncError: ''
  };

  const [state, dispatch] = useReducer(integrationReducer, initialState);
  const { isRunning, apiCall, syncError } = state;

  const handleSync = useCallback(async (integration) => {
    if (apiCall) return;

    analytics.track('ADMIN_VAULT_SYNC', {
      tenantId: integration.tenantId,
      integrationId: integration.id,
      category: 'ADMIN',
    });

    dispatch({ type: 'startSync' });
    const result = await submitPublisherIntegrationRunAPI(integration.id);
    if (result.data.submitUserIntegrationRun?.success) {
      dispatch({ type: 'syncSuccess' });
    } else {
      dispatch({ type: 'syncError', payload: result.data.submitUserIntegrationRun.message });
    }
  }, [apiCall]);

  return {
    isRunning,
    apiCall,
    syncError,
    handleSync
  };
};
export const PublisherIntegrationRow = (props: IntegrationRowProps) => {
  const { isRunning, apiCall, syncError, handleSync } = usePublisherRow();
  const { integration } = props;

  return (
    <DNABox key={integration.id} appearance="row" style={styles.integrationPublisherRow}>
      <DNABox appearance="col">
        <DNABox>
          <DNAText>
            {integration.name}
          </DNAText>
        </DNABox>
        <DNABox style={styles.integrationSync} spacing="xs">
          <DNAButton
            size="xs"
            status="secondary"
            appearance="outline"
            onPress={() => handleSync(integration)}
            disabled={apiCall}
          >
            Sync
          </DNAButton>
          <DNABox appearance="col">
            <Iffy is={isRunning}>
              <DNAText style={{ marginLeft: 24 }} status="primary">
                The sync should be complete in a few minutes.
              </DNAText>
            </Iffy>
            <Iffy is={syncError}>
              <DNAText style={{ marginLeft: 24 }} status="danger">
                {syncError}
              </DNAText>
            </Iffy>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNABox>
  );
};
