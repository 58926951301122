import {
  ContentPresented,
  ContentPresentedStatus,
  DocumentStatus,
  MeetingContentType,
  PresentedMeta,
} from '@alucio/aws-beacon-amplify/src/models';
import { DNABox, DNAButton, DNAChip, DNADivider, DNAIcon, DNAText, Iffy } from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail'
import React, { useMemo, useState } from 'react';
import { GestureResponderEvent, Pressable, StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import { DocumentVersionORMMap, useAllDocumentVersionMap } from 'src/state/redux/selector/document';
import { CustomDeckORMMap, useCustomDeckORMMap, useFolderItemORMByIdNoFolder } from 'src/state/redux/selector/folder';
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
import { useDNADocumentVersionActions } from '../DNA/Document/DNADocumentVersion.actions';
import { useDNACustomDeckActions } from 'src/components/DNA/Document/DNACustomDeck.actions';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import mergeStyles from '@alucio/lux-ui/src/components/util/mergeStyles';
import { parseISO } from 'date-fns';
import { CustomDeckORM, DocumentVersionORM, FolderItemORM, PageGroupORM } from 'src/types/orms';
import { isCustomDeckORM, isDocumentVersionORM } from 'src/types/typeguards';
import CustomFieldBadgeList from '../CustomFields/CustomFieldBadgeList';
import { getThumbURL } from 'src/utils/thumbnailHelpers';
import { PresentableModelORM } from 'src/state/context/ContentProvider/ContentProvider';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { getPresentable } from 'src/state/context/ContentProvider/helper';
import useContentPageData, { getSlideContentPageDataTitle } from 'src/hooks/useContentPageData/useContentPageData';

const styles = StyleSheet.create({
  contentContainer: {
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderColor: colors['color-gray-100'],
    borderRadius: 4,
  },
  rowStyleEdit: {
    padding: 8,
    borderTopWidth: 1,
    borderColor: colors['color-gray-100'],
  },
  rowStyle: {
    paddingTop: 8,
  },
  rowDeckStyle: {
    paddingTop: 8,
    paddingLeft: 30,
  },
  rowDeckStyleEdit: {
    paddingLeft: 38,
    padding: 8,
    borderTopWidth: 1,
    borderColor: colors['color-gray-100'],
  },
  title: {
    color: colors['color-gray-500'],
    fontWeight: '700',
    fontSize: 14,
  },
})

interface PresentedSlidesProps {
  presentedMeta: ContentPresented['presentedMeta'],
  orm: CustomDeckORM | DocumentVersionORM | PageGroupORM,
  handlePress: (e: GestureResponderEvent, pageNumber?: number) => void,
  folderItemOrm?: FolderItemORM,
}

interface ContentPresentedListItemProps {
  contentId: string,
  groupId?: string,
  contentType: ContentPresented['contentType'],
  isEditForm: boolean,
  folderItemId?: string,
  durationInMinutes?: number,
  orm: CustomDeckORM | DocumentVersionORM,
  onDelete?: (contentPresentedId: string, folderItemId?: string) => void,
  meetingId?: string,
  isManuallyAdded?: boolean,
  title: string,
  fallbackGroupName?: string
  presentedMeta: ContentPresented['presentedMeta'],
}

type ListItems = Record<
  ContentPresentedListItemProps['contentType'],
  React.FC<ContentPresentedListItemProps>
  >

type Items = Record<MeetingContentType, CustomDeckORMMap | DocumentVersionORMMap>

interface PresentedList extends PresentedMeta {
  pageNumber: number,
  thumbURL: string,
  // FOR CUSTOM DECKS, WE DON'T USE THE PAGE NUMBER BUT THE PRESENTATION'S POSITION
  pageNumberToUseInCPM: number,
  title?: string,
}

const unavailableText = '(Not available)';
const sharedText = '(Shared with Me)';

const PresentedSlides: React.FC<PresentedSlidesProps> = (props) => {
  const { presentedMeta, orm, handlePress, folderItemOrm } = props
  const presentable = isCustomDeckORM(orm) ? getPresentable(folderItemOrm) : getPresentable(orm)
  const { contentPageData } = useContentPageData(presentable)

  const presentedList: PresentedList[] = useMemo(() => {
    let pageNumberToUseInCPM = 0;
    if (isDocumentVersionORM(orm)) {
      return presentedMeta.reduce<PresentedList[]>((acc, meta) => {
        if (!meta.presented) {
          return acc;
        }
        const pageNumber = parseInt(meta.pageId.split('_').slice(-1)[0], 10)
        const thumbURL = getThumbURL(orm, pageNumber)

        acc.push({
          ...meta,
          pageNumber,
          thumbURL,
          pageNumberToUseInCPM: pageNumber,
          title: contentPageData.length > 0 ? getSlideContentPageDataTitle(pageNumber - 1, contentPageData) : '',
        });
        return acc;
      }, []).sort((a, b) => a.pageNumber - b.pageNumber)
    } else if (isCustomDeckORM(orm)) {
      // TO SUCCESSFULLY ASSIGN THE PRESENTATION NUMBER, WE NEED TO ORDER THE META SLIDES AS THEY
      // ARE ORDERED IN THE CUSTOM DECK
      const flattenCustomDeckPages = orm.meta.customDeckGroups.flatMap((group) =>
        group.model.visible ? group.pages : []);
      const allPagesIds = flattenCustomDeckPages.map((page) => page.model.pageId);

      const orderedMetas = [...presentedMeta].sort((a, b) =>
        allPagesIds.indexOf(a.pageId) > allPagesIds.indexOf(b.pageId) ? 1 : -1);

      return orderedMetas.reduce<PresentedList[]>((acc, meta) => {
        if (!meta.presented) {
          return acc;
        }
        pageNumberToUseInCPM++;
        // WHEN A CUSTOM DECK, WE WANT TO DISPLAY THE PRESENTATION NUMBER INSTEAD OF ITS ORDER IN THE SOURCE DOC
        const presentationNumber = allPagesIds.findIndex((pageId) => pageId === meta.pageId) + 1;
        const pageNumber = parseInt(meta.pageId.split('_').slice(-1)[0], 10)
        const page = flattenCustomDeckPages[presentationNumber - 1];

        // THE PAGE MIGHT HAS BEEN REMOVED/HIDDEN FROM THE CUSTOMDECK
        if (!page) {
          return acc;
        }

        const thumbURL = getThumbURL(page.documentVersionORM, pageNumber);

        acc.push({
          ...meta,
          pageNumber: presentationNumber,
          pageNumberToUseInCPM,
          thumbURL,
          title: contentPageData.length > 0
            ? getSlideContentPageDataTitle(presentationNumber - 1, contentPageData) : '',
        });
        return acc;
      }, []).sort((a, b) => a.pageNumber - b.pageNumber)
    } else {
      const orderedMetas = [...presentedMeta].sort((a, b) =>
        (orm.model.pageIds || []).indexOf(a.pageId) > (orm.model.pageIds || []).indexOf(b.pageId) ? 1 : -1);

      return orderedMetas.reduce<PresentedList[]>((acc, meta) => {
        if (!meta.presented) {
          return acc;
        }
        pageNumberToUseInCPM++;
        let pageNumber = parseInt(meta.pageId.split('_').slice(-1)[0], 10)
        const thumbURL = getThumbURL(orm.relations.documentVersionORM, pageNumber);

        const indexInPresentation = orm.model.pageIds?.indexOf(meta.pageId);
        if (indexInPresentation !== undefined && indexInPresentation >= 0) {
          pageNumber = indexInPresentation + 1;
        }

        acc.push({
          ...meta,
          pageNumber,
          thumbURL,
          pageNumberToUseInCPM,
          title: contentPageData.length > 0
            ? getSlideContentPageDataTitle(pageNumber - 1, contentPageData) : '',
        });
        return acc;
      }, []);
    }
  }, [presentedMeta, orm, contentPageData])

  const showExpandButton = presentedList.length > 4;
  const [expanded, setExpanded] = useState(false)
  const visibleRow = !expanded ? presentedList.slice(0, 4) : presentedList

  return (
    <DNABox appearance="col" spacing="md" style={{ padding: 16 }}>
      <DNADivider />
      <DNAText style={styles.title}>{`Presented Slides (${presentedList.length})`}</DNAText>
      <Iffy is={presentedList.length === 0}>
        <DNAText status="flat">No presented slides</DNAText>
      </Iffy>
      <Iffy is={presentedList.length > 0}>
        <DNABox
          fill
          appearance="row"
          wrap="start"
          spacing="md"
          childStyle={showExpandButton && { marginBottom: 16 }}
        >
          {visibleRow.map((presentedSlide) => {
            return (
              <Pressable
                onPress={e => handlePress(e, presentedSlide.pageNumberToUseInCPM)}
                key={`${presentedSlide.pageId}-${presentedSlide.pageNumber}`}
              >
                <DNAThumbnail
                  variant={DNAThumbnail.Variants.INFO}
                  key={presentedSlide.thumbURL}
                  s3URL={presentedSlide.thumbURL}
                  useLoadingIndicator
                  size="sm"
                  pageNumber={presentedSlide.pageNumber}
                  thumbnailTitle={presentedSlide.title}
                  presentedMeta={presentedMeta.find(({ pageId }) => pageId === presentedSlide.pageId)}
                />
              </Pressable>
            )
          })}
        </DNABox>
      </Iffy>
      <Iffy is={showExpandButton}>
        <DNAButton
          appearance="outline"
          status="tertiary"
          onPress={() => setExpanded(p => !p)}
        >
          {
            !expanded
              ? 'Show all'
              : 'Show less'
          }
        </DNAButton>
      </Iffy>
    </DNABox>
  )
}

// RETURNS TRUE IF AT LEAST ONE PRESENTED META IS AN UNAVAILABLE (REMOVED) PAGE
const isUnavailableCustomDeck = (orm: CustomDeckORM | DocumentVersionORM, presentedMeta: PresentedMeta[]): boolean => {
  if (!isCustomDeckORM(orm)) {
    return false;
  }

  if (orm.meta.version.requiresReview) {
    return true;
  }

  const flattenCustomDeckPages =
    orm.meta.customDeckGroups.flatMap((group) => group.pages);

  return presentedMeta.some(({ pageId, presented }) =>
    presented && !flattenCustomDeckPages.find((page) => page.page.pageId === pageId));
};

const CustomDeckListItem: React.FC<ContentPresentedListItemProps> = (
  {
    contentId,
    meetingId,
    isEditForm,
    onDelete,
    durationInMinutes,
    folderItemId,
    orm,
    isManuallyAdded,
    title,
    presentedMeta,
  },
) => {
  const dispatch = useDispatch()
  const folderItemORM = useFolderItemORMByIdNoFolder({ id: folderItemId });
  const { shareEmail } = useDNACustomDeckActions();
  const featureFlags = useFeatureFlags('enableMeetingFollowUp', 'enableShareCustomDeck');
  const isWithinGracePeriod = isCustomDeckORM(orm) && orm.meta.version.withinGracePeriod

  /** TODO: Quick workaround for the case where a custom deck that is shared with the user has been presented.
       * Since the useCustomDeckORMMap is only loading these entities on folder load, it will is not available
       * here unless the user has first navigated to that particular shared folder.
       *
       * This should be refactored to handle this case but would likely require updates to the data model or a
       * special loading facility that ensures these items are available here.
       * See https://alucioinc.atlassian.net/browse/BEAC-3233 */

  const isSharedCustomDeck = !orm
  const requiresReview = isCustomDeckORM(orm) && orm.meta.version.requiresReview;
  const isDeleted = !folderItemORM;
  const isUnavailable =
    (isDeleted || isSharedCustomDeck || isUnavailableCustomDeck(orm, presentedMeta)) &&
    !isWithinGracePeriod;

  const titleText = isUnavailable
    ? isSharedCustomDeck
      ? sharedText
      : unavailableText
    : undefined
  const fullTitle = titleText ? `${titleText} ${title}` : title

  const handlePress = (e: GestureResponderEvent, pageNumber?: number) => {
    if (isCustomDeckORM(orm) && (!requiresReview || isWithinGracePeriod)) {
      for (const group of orm.meta.customDeckGroups) {
        if (group.model.visible && group.pages[0]) {
          dispatch(
            contentPreviewModalActions.setModalVisibility({
              documentVersionId: group.pages[0].documentVersionORM.model.id,
              folderItemORM: folderItemORM,
              content: folderItemORM,
              isOpen: true,
              meetingId: meetingId,
              pageNumber,
            },
            ))
          break;
        }
      }
    }
  }
  const hasFollowUpItem = presentedMeta.find(presentedPage => presentedPage.followUp)

  const isShareable =  !isUnavailable &&
    isCustomDeckORM(orm) &&
    !requiresReview &&
    featureFlags.enableShareCustomDeck &&
    orm.meta.permissions.MSLShare

  const ContentTitle: React.FC = () => (
    <DNABox appearance="row" fill>
      <DNABox fill={!isEditForm} childFill={0}>
        <DNAPopover placement="top" fill disablePopover={['tabletPWA']}>
          <DNAPopover.Anchor style={{ flex: 1 }}>
            <DNAText
              numberOfLines={2}
              status={
                (requiresReview && !isWithinGracePeriod) ||
                  isUnavailable
                  ? 'subtle'
                  : !isUnavailable ? 'primary' : undefined}
              style={{ maxWidth: isEditForm ? 720 : '16vw' }}
            >
              {fullTitle}
            </DNAText>
          </DNAPopover.Anchor>
          <DNAPopover.Content>
            <DNAText status="basic" numberOfLines={10} style={{ maxWidth: 250 }}>
              {
                (requiresReview && !isWithinGracePeriod)
                  ? 'This file needs to be reviewed and cannot be opened.'
                  : fullTitle
              }
            </DNAText>
          </DNAPopover.Content>
        </DNAPopover>
      </DNABox>
      <Iffy is={!isManuallyAdded}>
        <DNABox shrink alignY="center">
          <DNAText style={{ color: colors['color-gray-400'], marginLeft: 4 }}>
            {`(${durationInMinutes || 0} min)`}
          </DNAText>
        </DNABox>
      </Iffy>
    </DNABox>
  )

  return (
    <DNABox appearance="col">
      <DNABox
        alignY="center"
        fill
        style={mergeStyles(undefined, styles.rowDeckStyle, [styles.rowDeckStyleEdit, isEditForm])}
      >
        <DNABox spacing="xs" fill appearance="col">
          <DNABox appearance="row" fill>
            {isShareable &&
              <DNABox shrink>
                <DNAPopover placement="top" disablePopover={['tabletPWA']}>
                  <DNAPopover.Anchor>
                    <DNAButton
                      testID="share-icon"
                      appearance="ghostLink"
                      status="tertiary"
                      padding="sm"
                      iconLeft="share"
                      onPress={shareEmail(orm, folderItemORM, meetingId)}
                    />
                  </DNAPopover.Anchor>
                  <DNAPopover.Content offset={2}>
                    <DNAText status="basic">
                      Share document(s)
                    </DNAText>
                  </DNAPopover.Content>
                </DNAPopover>
              </DNABox>
}
            {/* Flag icon */}
            <Iffy is={!isEditForm && hasFollowUpItem && featureFlags.enableMeetingFollowUp}>
              <DNABox shrink style={{ marginRight: 8 }}>
                <DNAPopover placement="top">
                  <DNAPopover.Anchor>
                    <DNAIcon name="flag" />
                  </DNAPopover.Anchor>
                  <DNAPopover.Content offset={2}>
                    <DNAText status="basic">
                      Flag for follow-up
                    </DNAText>
                  </DNAPopover.Content>
                </DNAPopover>
              </DNABox>
            </Iffy>
            {/* Workaround for unloaded custom decks. Refactor in https://alucioinc.atlassian.net/browse/BEAC-3233 */}
            <DNABox fill>
              <Iffy is={!isUnavailable}>
                <Pressable onPress={handlePress} disabled={isUnavailable} style={{ flex: 1 }}>
                  <ContentTitle />
                </Pressable>
              </Iffy>
              <Iffy is={isUnavailable}>
                <ContentTitle />
              </Iffy>
            </DNABox>
          </DNABox>
          <DNABox fill>
            <DNAChip appearance="tag">
              MODIFIED
            </DNAChip>
          </DNABox>
        </DNABox>

        <Iffy is={isEditForm && onDelete}>
          <DNAButton
            appearance="filled"
            status="tertiary"
            padding="sm"
            iconRight="trash-can-outline"
            onPress={() => onDelete?.(contentId, folderItemId)}
          />
        </Iffy>
      </DNABox>
      <Iffy is={isEditForm && !isUnavailable}>
        <PresentedSlides
          presentedMeta={presentedMeta}
          orm={orm}
          handlePress={handlePress}
          folderItemOrm={folderItemORM}
        />
      </Iffy>
    </DNABox>
  )
}

const DocumentVersionListItem: React.FC<ContentPresentedListItemProps> = (
  {
    contentId,
    groupId,
    isEditForm,
    onDelete,
    durationInMinutes,
    /** NOTE: we should rethink this approach. If this component is solely for document version list items, why are we typing this prop as DocumentVersionORM | CustomDeckORM? */
    orm,
    folderItemId,
    meetingId,
    isManuallyAdded,
    title,
    fallbackGroupName,
    presentedMeta,
  },
) => {
  const dispatch = useDispatch()
  const { shareEmail } = useDNADocumentVersionActions();
  const folderItemORM = useFolderItemORMByIdNoFolder({ id: folderItemId });
  const documentVersionORM = isDocumentVersionORM(orm) ? orm : null;
  const documentORM = documentVersionORM?.relations.documentORM || null;
  const isModified = folderItemORM?.model?.visiblePages?.length &&
    documentVersionORM?.meta.permissions.MSLSelectSlides &&
    documentVersionORM?.model.status === 'PUBLISHED'

  const isPublishedDocument = documentORM?.model.status === DocumentStatus.PUBLISHED
  const { MSLShare } = { ...documentVersionORM?.meta.permissions }
  const isShareable = MSLShare && documentVersionORM && !isModified;
  const enableMeetingFollowUp = useFeatureFlags('enableMeetingFollowUp');

  const isUnavailable = !orm || !isPublishedDocument || !documentORM;
  const titleText = isUnavailable ? `${unavailableText} ${title}` : title;
  const group =
    isDocumentVersionORM(orm) ? orm.relations.pageGroups.find(({ model:{ id } }) => id === groupId) : undefined;
  const groupNameLookup = group?.model.name ?? '';

  /** NOTE: Since we currently do not have a redux selector that gives us access to deleted items, this workaround
   * of having a fallback property on the presentable model was implemented. Reach out to @alucio-echen for details */
  const groupNameText = isUnavailable ? fallbackGroupName : groupNameLookup

  const handlePress = (e: GestureResponderEvent, pageNumber?: number) => {
    let content: PresentableModelORM | undefined = isDocumentVersionORM(orm) ? orm : folderItemORM;

    if (groupId) {
      content = documentVersionORM?.relations.pageGroups.find((group) => group.model.id === groupId);
    }

    dispatch(
      contentPreviewModalActions.setModalVisibility({
        documentVersionId: documentVersionORM?.model.id,
        folderItemORM,
        content,
        isOpen: true,
        meetingId,
        pageNumber,
      },
      ));
  }
  const hasFollowUpItem = presentedMeta.find(presentedPage => presentedPage.followUp)

  const Badges = () => (
    <DNABox spacing="xs">
      {isPublishedDocument &&
        <CustomFieldBadgeList documentVersionORM={documentORM?.relations.version.latestUsableDocumentVersionORM} />
      }
      <Iffy is={isModified}>
        <DNAChip appearance="tag">
          MODIFIED
        </DNAChip>
      </Iffy>
    </DNABox>)

  return (
    <DNABox appearance="col">
      <DNABox
        alignY="center"
        fill
        style={mergeStyles(undefined, styles.rowStyle,
          [styles.rowStyleEdit, isEditForm],
          [{ paddingLeft: 30 }, (!isPublishedDocument || !MSLShare || isModified) && !isEditForm],
          [{ paddingLeft: 38 }, (!isPublishedDocument || !MSLShare || isModified) && isEditForm])}
      >
        <DNABox fill alignY="center" appearance="row">
          { isShareable &&
            <DNABox shrink>
              <DNAPopover placement="top" disablePopover={['tabletPWA']}>
                <DNAPopover.Anchor>
                  <DNAButton
                    testID="share-icon"
                    appearance="ghostLink"
                    status="tertiary"
                    padding="sm"
                    iconLeft="share"
                    onPress={shareEmail(documentVersionORM, undefined, meetingId)}
                  />
                </DNAPopover.Anchor>
                <DNAPopover.Content offset={2}>
                  <DNAText status="basic">
                    Share document(s)
                  </DNAText>
                </DNAPopover.Content>
              </DNAPopover>
            </DNABox>
            }
          <DNABox appearance="col" spacing="xs" fill>
            <DNABox appearance="row" fill>
              {/* Flag icon */}
              <Iffy is={!isEditForm && hasFollowUpItem && enableMeetingFollowUp}>
                <DNABox shrink style={{ marginRight: 8 }}>
                  <DNAPopover placement="top" disablePopover={['tabletPWA']}>
                    <DNAPopover.Anchor>
                      <DNAIcon name="flag" />
                    </DNAPopover.Anchor>
                    <DNAPopover.Content offset={2}>
                      <DNAText status="basic">
                        Flag for follow-up
                      </DNAText>
                    </DNAPopover.Content>
                  </DNAPopover>
                </DNABox>
              </Iffy>
              <DNABox fill>
                <Pressable disabled={!isPublishedDocument} onPress={handlePress} style={{ flex: 1 }}>
                  <DNABox appearance="row" fill>
                    <DNABox fill={!isEditForm} childFill={0}>
                      <DNAPopover placement="top" fill disablePopover={['tabletPWA']}>
                        <DNAPopover.Anchor style={{ flex: 1 }}>
                          <DNAText
                            numberOfLines={2}
                            status={!isUnavailable ? 'primary' : undefined}
                            style={{ maxWidth: isEditForm ? 700 : '16vw' }}
                            testID="meeting-event-file-item"
                          >
                            {titleText}
                          </DNAText>
                        </DNAPopover.Anchor>
                        <DNAPopover.Content offset={2}>
                          <DNAText numberOfLines={10} status="basic" style={{ maxWidth: 250 }}>
                            {titleText}
                          </DNAText>
                        </DNAPopover.Content>
                      </DNAPopover>
                    </DNABox>
                    <Iffy is={!isManuallyAdded}>
                      <DNABox shrink alignY="center">
                        <DNAText style={{ color: colors['color-gray-400'], marginLeft: 4 }}>
                          {`(${durationInMinutes || 0} min)`}
                        </DNAText>
                      </DNABox>
                    </Iffy>
                  </DNABox>
                </Pressable>
              </DNABox>
            </DNABox>
            <Iffy is={groupId}>
              <DNABox fill>
                <DNAText status="subtle" numberOfLines={1}>
                  {groupNameText}
                </DNAText>
              </DNABox>
            </Iffy>
            <Badges />
          </DNABox>

        </DNABox>
        <Iffy is={isEditForm}>
          <DNAButton
            appearance="filled"
            status="tertiary"
            padding="sm"
            iconRight="trash-can-outline"
            onPress={() => onDelete?.(contentId, folderItemId)}
          />
        </Iffy>
      </DNABox >
      <Iffy is={isEditForm && isPublishedDocument && !isUnavailable}>
        <PresentedSlides
          presentedMeta={presentedMeta}
          orm={group || orm}
          handlePress={handlePress}
          folderItemOrm={folderItemORM}
        />
      </Iffy>
    </DNABox>
  )
}

const listItems: ListItems = {
  CUSTOM_DECK: CustomDeckListItem,
  DOCUMENT_VERSION: DocumentVersionListItem,
  DOCUMENT_VERSION_GROUP: DocumentVersionListItem,
}

interface ContentPresentedListProps {
  contentPresented: (ContentPresented | null)[],
  isEditForm: boolean,
  onDelete?: (contentPresentedId: string) => void,
  meetingId?: string,
  showDivider?: boolean,
}

const getDifferenceInSeconds = (startDate: Date, endDate: Date): number => {
  const diffInMilliseconds: number = endDate.getTime() - startDate.getTime();
  return diffInMilliseconds / 1000; // Convert milliseconds to seconds
};

const ContentPresentedList: React.FC<ContentPresentedListProps> = (props) => {
  const { contentPresented, isEditForm, onDelete, meetingId, showDivider } = props;
  const items: Items = {
    CUSTOM_DECK: useCustomDeckORMMap(),
    DOCUMENT_VERSION: useAllDocumentVersionMap(),
    DOCUMENT_VERSION_GROUP: useAllDocumentVersionMap(),
  }

  const JSXContent = useMemo(() => {
    return contentPresented
      .filter(p => p && p.status !== ContentPresentedStatus.DELETED)
      .sort((a, b) => (a && a.title.localeCompare((b && b.title) || '')) || 1)
      .map((content, index) => {
        if (!content) return null;

        const durationInSeconds = content.events?.reduce((acc, event) => {
          if (!event) return acc;
          const eventDuration = event.end && event.timestamp
            ? getDifferenceInSeconds(parseISO(event.timestamp), parseISO(event.end))
            : 0
          return acc + eventDuration
        }, 0)

        const durationInMinutes = Math.ceil((durationInSeconds ?? 0) / 60);
        const {
          contentType, contentId, folderItemId, groupId, title, groupTitle, presentedMeta,
        } = content
        const item = items[contentType][contentId]

        const ListItem = listItems[contentType]

        return (
          <React.Fragment key={`${content.contentId}-${content.openedAt}`}>
            {showDivider && index > 0 && <DNADivider />}
            <DNABox appearance="col" fill>
              <ListItem
                isEditForm={isEditForm}
                key={`content-item-${contentId}-${index}`}
                contentId={contentId}
                groupId={groupId}
                folderItemId={folderItemId}
                onDelete={onDelete}
                durationInMinutes={durationInMinutes}
                contentType={contentType}
                orm={item}
                meetingId={meetingId}
                isManuallyAdded={!content.openedAt}
                title={title}
                fallbackGroupName={groupTitle}
                presentedMeta={presentedMeta}
              />
            </DNABox>
          </React.Fragment>
        )
      });
  }, [contentPresented, items, listItems, isEditForm, onDelete, meetingId, showDivider]);

  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll((prev) => !prev);
  }

  const contentRowsToRender = showAll
    ? JSXContent.filter(p => p)
    : JSXContent.filter(p => p).slice(0, 5);

  return (<DNABox
    appearance="col"
    shrink={!showDivider}
    spacing="sm"
    fill={showDivider}
    style={mergeStyles(undefined, {}, [styles.contentContainer, isEditForm])}
  >
    {contentRowsToRender}
    {JSXContent.filter(p => p).length > 5 &&
      <DNABox style={isEditForm ? { padding: 8, paddingLeft: 30 } : { paddingLeft: 30 }}>
        <DNAButton
          status="primary"
          appearance="ghostLink"
          padding="none"
          onPress={toggleShowAll}
        >
          {showAll ? 'Show less' : 'Show all'}
        </DNAButton>
      </DNABox>}
  </DNABox>
  )
}

export default ContentPresentedList;
