import React, { PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from 'react';
import { BroadcastChannel } from 'broadcast-channel'
import { CanvasVariantEnum, useCanvas } from './UseCanvas';
import { ActionBarState } from 'src/screens/Meetings/SharedComponents/PresentationMenu';

type VideoSizeType = { width: number, height: number }

export type ContextType = {
  canvasRef: React.MutableRefObject<HTMLCanvasElement | null>;
  prepareCanvas: (() => void) | undefined;
  clearCanvas: (() => void) | undefined;
  setHighlighterMode: (variant: CanvasVariantEnum) => void;
  highlighterVisible: boolean;
  mouseDownListener: ((event: any) => void) | undefined;
  mouseMoveListener: ((event: any) => void) | undefined;
  mouseUpListener: ((event: any) => void) | undefined;
  touchStartListener?: ((event: any) => void) | undefined;
  touchMoveListener?: ((event: any) => void) | undefined;
  touchEndListener?: ((event: any) => void) | undefined;
  setVideoSizeChange: (width: number, height: number) => void;
  setHighlighterVisible: (visibility: boolean) => void;
  videoSize: VideoSizeType,
  resetHighlighter: () => void;
  selectedType: CanvasVariantEnum | undefined;
}

interface HighligterBroadcastPayload {
  type: 'play' | 'stop'
}
interface PlayPayload extends HighligterBroadcastPayload {
  type: 'play'
}
interface StopPayload extends HighligterBroadcastPayload {
  type: 'stop'
}
export type HighligterBroadcastPayloads = PlayPayload | StopPayload

export const channelName = 'highlighterPlayer';
export const canvasId = 'highlighter-canvas';

const HighlighterContext = createContext<ContextType>(undefined!);

const useChannel = () => {
  const channel = useRef<BroadcastChannel<HighligterBroadcastPayloads>>()

  const playMessage = () => {
    channel.current?.postMessage({
      type: 'play',
    });
  }

  const stopMessage = () => {
    channel.current?.postMessage({
      type: 'stop',
    });
  }

  useEffect(() => {
    channel.current = new BroadcastChannel(channelName)
    return () => { channel.current?.close() }
  }, [])

  return {
    playMessage,
    stopMessage,
    channel,
  }
}

export const useHighlighter = () => useContext(HighlighterContext);
export const HighlighterProvider: React.FC<PropsWithChildren<{
  actionBarState?: ActionBarState,
  onModeChange?: (variant: CanvasVariantEnum) => void,
}>> = ({ children, onModeChange, actionBarState }) => {
  const [videoSize, setVideoSize] = useState({ width: 0, height: 0 });
  const [canvasMode, setCanvasMode] = useState<CanvasVariantEnum | undefined>(undefined);
  const lastSelectedMode = useRef<CanvasVariantEnum | undefined>(undefined);
  const { playMessage, stopMessage } = useChannel()

  const {
    canvasRef,
    prepareCanvas,
    clearCanvas,
    mouseDownListener,
    mouseMoveListener,
    mouseUpListener,
    touchStartListener,
    touchEndListener,
    touchMoveListener,
    selectedType,
  } = useCanvas(canvasMode);

  useEffect(() => {
    if (!canvasRef.current) return
    playMessage();
    return () => {
      stopMessage()
    }
  }, [])

  // just to preserve the latest setion
  useEffect(() => {
    if (actionBarState === 'highlighterMenu' && lastSelectedMode.current) {
      setCanvasMode(lastSelectedMode.current);
      playMessage();
    }
  }, [actionBarState])

  const setHighlighterVisible = (visible: boolean) => {
    if (!visible) {
      stopMessage();
      handleSetCanvasMode(undefined);
    } else {
      handleSetCanvasMode(lastSelectedMode.current);
      playMessage();
    }
  }

  const setHighlighterMode = (variant: CanvasVariantEnum) => {
    // when seletedType is undefined no tool is selected
    // turn on highlighter
    if (!selectedType) {
      handleSetCanvasMode(variant);
      playMessage();
    }

    // when highlighter is enabled and user clicks on the same highlighter type
    // turn off highlighter
    if (selectedType === variant) {
      clearCanvas?.();
      stopMessage();
      setCanvasMode(undefined);
    }

    // when highlighter is enabled and user clicks on a different highlighter type
    // turn off highlighter and turn on new highlighter
    else if (selectedType !== variant) {
      handleSetCanvasMode(variant);
      playMessage();
    }
  }

  const handleSetCanvasMode = (variant: CanvasVariantEnum | undefined) => {
    if (variant) {
      lastSelectedMode.current = variant
      onModeChange?.(variant);
    }

    setCanvasMode(variant);
  }

  const setVideoSizeChange = (width, height) => {
    setVideoSize({ width, height })
  }

  const resetHighlighter = () => {
    clearCanvas?.();
  }

  const value = {
    canvasRef,
    clearCanvas,
    mouseDownListener,
    mouseMoveListener,
    mouseUpListener,
    touchStartListener,
    touchMoveListener,
    touchEndListener,
    prepareCanvas,
    resetHighlighter,
    selectedType,
    setHighlighterMode,
    setHighlighterVisible,
    highlighterVisible: !!selectedType,
    setVideoSizeChange,
    videoSize,
  }

  return (
    <HighlighterContext.Provider value={value}>
      {children}
    </HighlighterContext.Provider>
  );
};
