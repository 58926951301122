import React from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { DNABox, DNAIcon, DNAIconStyleProps, util } from '@alucio/lux-ui';
import { useMediaQuery } from 'react-responsive';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { MATCH_SLIDE_STATUS, useSlideGroupTargetsState } from '../SlideGroupTargetsProvider';
import { LuxSizeEnum } from '@alucio/lux-ui/src/typings';
import { thumbnailSizeDimensions } from 'src/hooks/useThumbnailSize/useThumbnailSize';

const S = StyleSheet.create({
  iconContainer: {
    borderRadius: 32,
    width: 32,
    height: 32,
  },
  iconContainerSmallerScreen : {
    borderRadius: 24,
    width: 24,
    height: 24,
  },
})

interface SlideMatchingIconProps {
  pageId: string
}

const SlideMatchingIcon: React.FC<SlideMatchingIconProps> = ({ pageId }) => {
  const { matchSlideIconStates } = useSlideGroupTargetsState();
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1300px)',
  })

  type IconVariantsValues = {
    iconProps: DNAIconStyleProps,
    iconContainerProps: StyleProp<ViewStyle>,
  }
  const iconVariants: Record<MATCH_SLIDE_STATUS, IconVariantsValues> = {
    [MATCH_SLIDE_STATUS.MATCHED]: {
      iconProps: {
        name: 'check-bold',
        size: isLargeScreen ? LuxSizeEnum.md : LuxSizeEnum.sm,
      },
      iconContainerProps: {
        backgroundColor: colors['color-success-500'],
      },
    },
    [MATCH_SLIDE_STATUS.NO_MATCH]: {
      iconProps: {
        name: 'not-equal-variant',
        size: isLargeScreen ? LuxSizeEnum.md : LuxSizeEnum.sm,
      },
      iconContainerProps: {
        backgroundColor: colors['color-gray-400'],
      },
    },
    [MATCH_SLIDE_STATUS.NEEDS_REVIEW]: {
      iconProps: {
        name: 'exclamation',
        size: isLargeScreen ? LuxSizeEnum.md : LuxSizeEnum.sm,
      },
      iconContainerProps: {
        backgroundColor: colors['color-warning-500'],
      },
    },
  }

  if (!matchSlideIconStates[pageId]) return null
  const iconVariant = iconVariants[matchSlideIconStates[pageId]]

  return (
    <DNABox
      alignX="center"
      alignY="center"
      style={util.mergeStyles(
        undefined,
        { height: isLargeScreen ? thumbnailSizeDimensions.lg.height : thumbnailSizeDimensions.md.height },
      )}
    >
      <DNABox
        alignX="center"
        alignY="center"
        style={util.mergeStyles(
          undefined,
          iconVariant.iconContainerProps,
          isLargeScreen ? S.iconContainer : S.iconContainerSmallerScreen,
        )}
      >
        <DNAIcon.Styled
          {...iconVariant.iconProps}
        />
      </DNABox>
    </DNABox>
  )
}

export default SlideMatchingIcon
