import { State as StateDef, ResolveTypegenMeta, BaseActionObject } from 'xstate';
import type * as Logger from './logger.types';
import { Typegen0 } from './logger.machine.typegen';

// [NOTE] - Normally we can use some util helpers, other TS access to get this
//          but it's possible to run into a circular dependency error when using selector as machine guards
//        - This probably creates a 2nd instance of the State type but it's needed for now
type State = StateDef<
  Logger.SMContext,
  Logger.SMEvents,
  any,
  { value: any, context: Logger.SMContext },
  ResolveTypegenMeta<
    Typegen0,
    Logger.SMEvents,
    BaseActionObject,
    Logger.SMServices
  >
>

export enum SendLogsButtonStates {
  idle = 'idle',
  successfullySentLogs = 'successfullySentLogs',
  failedToSendLogs = 'failedToSendLogs',
}

// TAGS
export const isMachineReady = (state: State) => ({ isMachineReady: state.tags.has('MACHINE_READY') })
export const successfullySentLogs = (state: State) =>
  ({ successfullySentLogs: state.tags.has('SUCCESSFULLY_SENT_LOGS') })
export const failedToSendLogs = (state: State) => ({ failedToSendLogs: state.tags.has('FAILED_TO_SEND_LOGS') })

// MACHINE
export const isIdleState = (state: State) => ({ isIdleState: state.matches('idle') })
export const isSendingLogs = (state: State) => ({ isSendingLogs: state.matches('ready.processing.batchSend.active') })
export const canSendLogs = (state: State) => {
  const isSendingLogsValue = isSendingLogs(state).isSendingLogs
  return { canSendLogs: state.can('SEND_LOGS') && !isSendingLogsValue }
}

// COMPONENT
export const sendLogsButtonState = (state: State) => {
  const success = successfullySentLogs(state).successfullySentLogs && SendLogsButtonStates.successfullySentLogs
  const failed = failedToSendLogs(state).failedToSendLogs && SendLogsButtonStates.failedToSendLogs
  const idle = SendLogsButtonStates.idle
  return { sendLogsButtonState: success || failed || idle }
}
