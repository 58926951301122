import React from 'react';
import { ImageBackground, StyleSheet } from 'react-native';
import { DNABox, Iffy, Stack } from '@alucio/lux-ui';
import { useAppSettings } from 'src/state/context/AppSettings';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { DNALoaderEvents } from 'src/components/DNA/Loader/DNALoader';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';
import InPersonActionBar from './InPersonActionBar';
import PlayerWrapper from 'src/components/Presentation/PlayerWrapper';
import TextSearchPanel from 'src/components/TextSearchPanel/TextSearchPanel';
import SlideRollWrapper from 'src/screens/Meetings/SharedComponents/SlideRollWrapper';
import Notes from 'src/screens/Meetings/SharedComponents/Notes/AllNotes';
import { ActionBarState } from '../SharedComponents/PresentationMenu';

const S = StyleSheet.create({
  contentContainer: {
    backgroundColor: 'black',
  },
  contentStack: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  sidebarStackLayer: {
    height: '100%',
  },
  actionbarStateLayer: {
    width: '100%',
  },
  frameContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  backgroundImage: {
    flex: 1,
  },
})

const InPersonContent: React.FC = () => {
  const { deviceMode } = useAppSettings()
  const { activePresentation, isCustomDeck } = useContent()
  const {
    meetingORM,
    slideRollVisible,
    setSlideRollVisible,
    textSearchVisible,
    setTextSearchVisible,
    openNotes,
    actionBarState,
  } = useMeetingsState()
  const enableNew3PC = useFeatureFlags('enableNew3PC');

  if (!meetingORM) return null

  return (
    <DNABox
      fill
      appearance="col"
      style={S.contentContainer}
      testID="presentation-content"
    >
      {/* CONTENT */}
      <Stack anchor="bottom" style={S.contentStack}>
        <Stack.Layer style={S.contentStack}>
          <DNABox fill style={S.frameContainer}>
            {/* BACKGROUND IMAGE */}
            <Iffy is={!activePresentation}>
              <ImageBackground
                source={require('../../../../assets/images/beacon-presentation-bg.jpg')}
                style={S.backgroundImage}
              />
            </Iffy>
            {/* IFRAME */}
            <Iffy is={activePresentation}>
              <PlayerWrapper.Highlighter
                isFullWindow
                meetingId={meetingORM.model.id}
                mode="INTERACTIVE"
                lockAspectRatio={false}
                isTabletMeetingMode
                isFromMeetings={true}
                highlighterVisible
                analyticsEventType={DNALoaderEvents.MEETING}
                isCustomDeck={isCustomDeck}
              />
            </Iffy>
            {/* MY NOTES */}
            <Iffy is={openNotes.length}>
              <Notes openNotes={openNotes}/>
            </Iffy>
          </DNABox>
        </Stack.Layer>
        {/* SLIDE ROLL */}
        <Stack.Layer anchor="topLeft" style={S.sidebarStackLayer}>
          <Iffy is={activePresentation}>
            <DNABox style={{ display: slideRollVisible ? 'flex' : 'none' }} fill>
              <SlideRollWrapper closeButtonOnPress={() => setSlideRollVisible(false)}/>
            </DNABox>
          </Iffy>
        </Stack.Layer>
        {/* TEXT SEARCH */}
        <Stack.Layer anchor="topLeft" style={S.sidebarStackLayer}>
          <DNABox fill>
            {textSearchVisible &&
              <TextSearchPanel
                onClose={() => { setTextSearchVisible(false) }}
                contentHookVariant="proxied"
              />
            }
          </DNABox>
        </Stack.Layer>
        {/* CONTROLS */}
        <Stack.Layer
          anchor="bottomRight"
          style={
            (enableNew3PC && deviceMode === 'tablet' && actionBarState === ActionBarState.PresentationMenu)
              ? S.actionbarStateLayer
              : undefined
          }
        >
          <Iffy is={!(enableNew3PC)}>
            <InPersonActionBar />
          </Iffy>
        </Stack.Layer>
      </Stack>
      {/* FOOTER / CONTROLS */}
      <Iffy is={enableNew3PC}>
        <InPersonActionBar />
      </Iffy>
    </DNABox>
  );
};

export default InPersonContent
