import React, { useState } from 'react';
import { ScrollView } from 'react-native';
import { DNABox, DNAButton, DNAText } from '@alucio/lux-ui';
import { ConversionStatus } from '@alucio/aws-beacon-amplify/src/models';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as publisherVersioningSelector from 'src/state/machines/publisherVersioning/publisherVersioning.selectors';
import { usePublisherVersioningState } from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import { useSlideSettingsState } from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider';
import { TabOptions } from 'src/state/machines/publisherVersioning/publisherVersioning.types';
import { styles } from '../SlideSettings';
import Groups from './Groups';
import Slides from './Slides';

const IMPORT_FROM_PREVIOUS_VERSION_MESSAGE =
  'Import slide settings from previous version? Slide settings are imported based on the matched slides.';

const ImportFromPreviousVersionMessage: React.FC = () => {
  const { service: parentService } = usePublisherVersioningState();
  const { currentDocumentVersionORM, importSlideSettingsFromPreviousVersion } = useSlideSettingsState()
  const [imported, setImported] = useState<boolean>(false)

  const versionPanelCond = useMachineSelector(
    parentService,
    (state) => composite(
      state,
      publisherVersioningSelector.isInDraftingState,
      publisherVersioningSelector.availabletabs,
    ),
  )

  const showImportFromPreviousVersionMessage = versionPanelCond.isInDraftingState &&
    currentDocumentVersionORM.model.conversionStatus === ConversionStatus.PROCESSED &&
    versionPanelCond.availabletabs.includes(TabOptions.DOCUMENT_MATCH_SLIDES_TAB)

  const onPressImport = () => {
    setImported(true)
    importSlideSettingsFromPreviousVersion()
    setTimeout(() => {
      setImported(false)
    }, 3000);
  }

  if (!showImportFromPreviousVersionMessage) return null
  return (
    <DNABox
      alignX="center"
      alignY="center"
      spacing="md"
      wrap="center"
    >
      <DNAText bold numberOfLines={2}>{ IMPORT_FROM_PREVIOUS_VERSION_MESSAGE }</DNAText>
      <DNAButton
        appearance={imported ? 'filled' : 'outline'}
        status={imported ? 'success' : 'tertiary'}
        iconLeft={imported ? 'check' : undefined}
        onPress={onPressImport}
        children={imported ? 'Imported' : 'Import'}
      />
    </DNABox>
  )
}

const Content: React.FC = () => {
  return (
    <ScrollView>
      <DNABox appearance="col" spacing="md" style={styles.content}>
        <ImportFromPreviousVersionMessage />
        <Groups />
        <Slides />
      </DNABox>
    </ScrollView>
  )
}

export default Content
