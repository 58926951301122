import { Hub } from '@alucio/aws-beacon-amplify/src/models';
import { DNAButton, DNAContextMenu, LuxStatusV2Enum, LuxStatusEnum } from '@alucio/lux-ui';
import React from 'react';
import { useAppSettings } from 'src/state/context/AppSettings';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider';

interface HubsContextMenuProps {
  hub: Hub,
}

type HubContextOptions = {
  [key in keyof typeof HubContextMenuActions]: {
    title: string
    icon: string
    onPress: () => void
    status?: LuxStatusV2Enum
    iconStatus?: LuxStatusEnum
  }
}

type HubActions = (keyof typeof HubContextMenuActions)

enum HubContextMenuActions {
  edit = 'Edit',
  preview = 'Preview',
  share = 'Share',
  delete = 'Delete',
}

const HubsContextMenu: React.FC<HubsContextMenuProps> = (props) => {
  const { handleEditHub, handlePressShare, openPreviewWindow, handleDeleteHub } = useHubsState()
  const { hub } = props
  const { id } = hub
  const { isOnline } = useAppSettings()

  const menuActions: HubContextOptions = {
    edit: {
      title: 'Edit',
      icon: 'pencil',
      onPress: () => handleEditHub(id)
    },
    preview: {
      title: 'Preview',
      icon: 'eye-outline',
      onPress: () => openPreviewWindow(id)
    },
    share: {
      title: 'Share',
      icon: 'email-send-outline',
      onPress: () => handlePressShare(id)
    },
    delete: {
      title: 'Delete',
      icon: 'trash-can-outline',
      onPress: () => handleDeleteHub(hub),
      status: LuxStatusV2Enum.danger,
      iconStatus: LuxStatusEnum.danger,
    }
  }

  const actionsToUse: HubActions[] = ['edit']
  isOnline && actionsToUse.push('preview')
  isOnline && actionsToUse.push('share')
  actionsToUse.push('delete')

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        <DNAButton
          iconRight="dots-vertical"
          appearance="outline"
          status="tertiary"
          padding="sm"
        />
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        { actionsToUse.map(item => {
          return (
            <DNAContextMenu.Item
              key={menuActions[item].title}
              icon={menuActions[item].icon}
              title={menuActions[item].title}
              onPress={menuActions[item].onPress}
              status={menuActions[item].status}
              iconStatus={menuActions[item].iconStatus}
            />
          )
        })}
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

export default HubsContextMenu
