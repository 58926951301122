import { useCallback } from 'react';
import { useDispatch } from 'src/state/redux';
import { PlayerActions } from '@alucio/core'
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal'
import { presentationControlActions } from 'src/state/redux/slice/PresentationControl/presentationControl'
import { InputConfig } from '@alucio/lux-ui/lib/hooks/useInputConfig/useInputConfig'
import throttle from 'lodash/throttle';
import { KeyCodes } from '@alucio/lux-ui/lib/hooks/useKeyPress/KeyCodes';

const GetInputConfig = () => {
  const dispatch = useDispatch()
  const toNextKeys: Array<keyof typeof KeyCodes> = ['ArrowRight', 'ArrowDown', 'Space', 'End', 'PageDown'];
  const toPreviousKeys: Array<keyof typeof KeyCodes> = ['ArrowLeft', 'ArrowUp', 'Home', 'PageUp'];

  const handleKeyPress = useCallback(throttle((_, keyPressed) => {
    dispatch(presentationControlActions.triggerPresentationAction({
      action: toNextKeys.includes(keyPressed) ? PlayerActions.next : PlayerActions.prev,
    }))
  }, 500), []);

  const inputConfig:InputConfig[] = [
    {
      keyCodes: [...toNextKeys, ...toPreviousKeys],
      callback: handleKeyPress,
    },
    {
      keyCodes: ['Escape'],
      callback: () => { dispatch(contentPreviewModalActions.setModalVisibility({ isOpen: false })) },
    },
  ]
  return inputConfig
}

export default GetInputConfig
