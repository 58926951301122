import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getAssociatedFilesORM,
  selAttachedFilesMap,
  selDocumentsMap,
  selDocumentVersionsMap,
} from 'src/state/redux/selector/document';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as publisherVersioningSelector from 'src/state/machines/publisherVersioning/publisherVersioning.selectors';
import AssociatedFilesManager from 'src/components/DNA/AssociatedFiles/AssociatedFilesManager';
import { AssociatedFileORM, DocumentVersionORM } from 'src/types/types';
import { usePublisherVersioningState }
  from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import { loggedUser } from 'src/state/redux/selector/user';

const AssociatedFiles: React.FC = () => {
  const {
    service,
    currentDocumentVersionORM,
    onSelectAssociatedFile,
    handleUploadAssociatedFileStatusChange,
    handleFinishedUploadAssociatedFile,
    handleUpdateAssociatedFile,
    handleDeleteAssociatedFile,
  } = usePublisherVersioningState();
  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.selectedTabIndex,
      publisherVersioningSelector.isPublishedViewMode,
      publisherVersioningSelector.versionForm,
      publisherVersioningSelector.isInDraftingState,
    ),
  )

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const attachedFilesMap = useSelector(selAttachedFilesMap)
  const documentsMap = useSelector(selDocumentsMap)
  const documentVersionsMap = useSelector(selDocumentVersionsMap)
  const user = useSelector(loggedUser);

  // Collapse the search bar on tab switching
  useEffect(() => {
    setIsSearching(false)
  }, [cond.selectedTabIndex])

  const associatedFileORMs: AssociatedFileORM[] = (cond.isPublishedViewMode
    ? currentDocumentVersionORM?.relations.associatedFiles
    : getAssociatedFilesORM(
      cond.versionForm.associatedFiles ?? [],
      attachedFilesMap,
      documentsMap,
      documentVersionsMap,
      user.relations.tenant,
    )
  ) ?? []

  if (!currentDocumentVersionORM) return null

  const dummyDocumentVersionORM: DocumentVersionORM = {
    ...currentDocumentVersionORM,
    // @ts-expect-error - Because versionForm is partial, we'll get some possible undefined values, but should be fine
    model: {
      ...currentDocumentVersionORM,
      ...cond.versionForm,
    },
    relations: {
      ...currentDocumentVersionORM.relations,
      associatedFiles: associatedFileORMs,
    },
  }

  return (
    <AssociatedFilesManager<DocumentVersionORM>
      entityORM={dummyDocumentVersionORM}
      canLinkFiles={cond.isInDraftingState}
      isDisabled={cond.isPublishedViewMode}
      isCollapsed={false}
      searching={isSearching}
      setSearching={setIsSearching}
      emptyMessage="No associated files"
      associatedFilesMode={cond.isInDraftingState ? 'EDITABLE' : 'READ_DOWNLOADABLE'}
      files={associatedFileORMs}
      onUploadStatusChange={handleUploadAssociatedFileStatusChange}
      onFinishedUpload={handleFinishedUploadAssociatedFile}
      onSelect={onSelectAssociatedFile}
      onDelete={handleDeleteAssociatedFile}
      onUpdateAssociatedFile={handleUpdateAssociatedFile}
    />
  );
};

export default AssociatedFiles;
