import React from 'react'
import { TouchableOpacity, ImageBackground, StyleSheet, Pressable } from 'react-native'
import { DNABox, DNAIcon, DNAText, Iffy, util } from '@alucio/lux-ui'

import TenantLogo from 'src/components/DNA/Header/TenantLogo'
import MoleculesBGSrc from 'src/../assets/images/Molecules.svg'

import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage'
import useScreenNav from 'src/components/DNA/hooks/useScreenNav'
import { useAppSettings } from 'src/state/context/AppSettings'
import { DNARoute, ROUTES } from 'src/router/routes'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import useIsPublisherView from 'src/hooks/useIsPublisherView/useIsPublisherView'
import { useSearchTextContextProvider } from 'src/components/Header/SearchInput/SearchInput'
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags'
import useIsTenantAdmin from 'src/hooks/useIsTenantAdmin'

const S = StyleSheet.create({
  tabletContainer: {
    shadowColor: colors['color-black'],
    paddingLeft: 80,
    shadowOffset: {
      width: 0,
      height: 9,
    },
    shadowOpacity: 0.50,
    shadowRadius: 12.35,
    paddingVertical: 6,
    paddingHorizontal: 16,
    backgroundColor: colors['color-text-white'],
  },
  navItem: {
    paddingVertical: 12,
    textAlign: 'center',
    width: 88,
    minHeight: 78,
  },
  moleculesBG: {
    flex: 1,
    bottom: 0,
    width: '100%',
    height: 408,
    opacity: 0.2,
    position: 'absolute',
    zIndex: -1,
  },
  publisherViewSideBar: {
    backgroundColor: colors['color-text-white'],
    borderRightColor: colors['color-gray-80'],
    borderRightWidth: 1,
    paddingTop: 20,
  },
  sideBar: {
    backgroundColor: colors['color-brand1-500'],
    paddingTop: 20,
  },
})

const useNavItem = (route: DNARoute) => {
  const currentPage = useCurrentPage()
  const { go } = useScreenNav()
  const { setCurrentSearchString } = useSearchTextContextProvider()

  return {
    isCurrentPage: currentPage === route || currentPage?.PARENT_PATH === route.PATH,
    goTo: () => {
      go(route)
      setCurrentSearchString('')
    },
  }
}

/** SECTION: Desktop */
const NavItemDesktop: React.FC<{ route: DNARoute, isPublisherView?: boolean }> = (props) => {
  const { isPublisherView, route } = props
  const common = useNavItem(route)
  let textStatus
  let iconColor

  if (isPublisherView) {
    textStatus = common.isCurrentPage ? 'primary' : 'subtle'
    iconColor = common.isCurrentPage ? colors['color-brand2-500'] : colors['color-gray-300']
  }

  return (
    <DNABox
      testID={`${route.testID}-nav-button`}
      style={
        util.mergeStyles(
          undefined,
          S.navItem,
          [{ backgroundColor: 'rgba(255,255,255,.16)' }, common.isCurrentPage],
          [{ backgroundColor: colors['color-brand2-5'] }, common.isCurrentPage && isPublisherView],
        )
      }
      appearance="col"
      alignX="center"
      spacing="xs"
      as={TouchableOpacity}
      // @ts-ignore
      onPress={common.goTo}
    >
      <DNAIcon.Styled
        color={iconColor}
        width={32}
        height={32}
        name={route?.ICON}
        CustomIcon={route?.CUSTOM_ICON}
      />
      <DNAText c1 bold={props.isPublisherView} status={textStatus || 'basic'}>
        {route.LABEL}
      </DNAText>
    </DNABox>
  )
}

const DNANavigationMenuDesktop: React.FC = () => {
  const currentPage = useCurrentPage()
  const isPublisherView = useIsPublisherView()

  const featureFlags = useFeatureFlags(
    'disablePublisherFolder',
    'enablePublisherReports',
    'enableEmailTemplates',
    'enableMSLReports',
    'enableHubs',
  );

  const isDisabledOnCurrentRoute = currentPage?.configOptions?.desktop?.disableGlobalNavigationMenu

  const isTenantAdmin = useIsTenantAdmin();
  if (isDisabledOnCurrentRoute) { return null }

  return (
    <DNABox
      appearance="col"
      style={isPublisherView ? S.publisherViewSideBar : S.sideBar}
    >
      {/* MSL View */}
      <Iffy is={!isPublisherView}>
        <NavItemDesktop route={ROUTES.HOME} />
        <NavItemDesktop route={ROUTES.LIBRARY} />
        <NavItemDesktop route={ROUTES.FOLDERS} />
        <NavItemDesktop route={ROUTES.BOOKMARKS} />
        <NavItemDesktop route={ROUTES.MEETING_HISTORY} />
        {featureFlags.enableHubs &&
          <NavItemDesktop route={ROUTES.HUBS}/>
        }
        <Iffy is={featureFlags.enableMSLReports}>
          <NavItemDesktop route={ROUTES.VIEWER_REPORTS} />
        </Iffy>
      </Iffy>

      {/* Publisher View */}
      <Iffy is={isPublisherView}>
        <NavItemDesktop route={ROUTES.PUBLISHER_DASHBOARD} isPublisherView />
        <Iffy is={featureFlags.enableEmailTemplates}>
          <NavItemDesktop route={ROUTES.EMAIL_TEMPLATES} isPublisherView />
        </Iffy>
        <Iffy is={!featureFlags.disablePublisherFolder}>
          <NavItemDesktop route={ROUTES.PUBLISHER_FOLDERS} isPublisherView />
        </Iffy>
        <Iffy is={featureFlags.enablePublisherReports}>
          <NavItemDesktop route={ROUTES.REPORTS} isPublisherView />
        </Iffy>
        <Iffy is={isTenantAdmin}>
          <NavItemDesktop route={ROUTES.ADMIN_SETTINGS} isPublisherView />
        </Iffy>
      </Iffy>

      {/* Background */}
      <Iffy is={!isPublisherView}>
        <ImageBackground
          style={S.moleculesBG}
          source={{ uri: MoleculesBGSrc as string }}
        />
      </Iffy>
    </DNABox>
  )
}
/** !SECTION: Desktop */

/** SECTION: Tablet */
const NavItemTablet: React.FC<{ route: DNARoute }> = ({
  route,
}) => {
  const common = useNavItem(route)
  const iconColor = common.isCurrentPage ? colors['color-brand2-500'] : 'gray'
  const textStatus = common.isCurrentPage ? 'primary' : 'flatAlt'

  return (
    <Pressable
      onPress={common.goTo}
      testID={`${route.testID}-nav-button`}
    >
      <DNABox appearance="col" alignX="center" spacing="xs">
        <DNAIcon.Styled
          color={iconColor as any}
          width={32}
          height={32}
          name={route?.ICON}
          CustomIcon={route?.CUSTOM_ICON}
        />
        <DNAText c1 status={textStatus || 'basic'}>
          {route.LABEL}
        </DNAText>
      </DNABox>
    </Pressable>
  )
}

const DNANavigationMenuTablet: React.FC = () => {
  const currentPage = useCurrentPage()
  const featureFlags = useFeatureFlags('enableHubs', 'enableMSLReports')

  const isDisabledOnCurrentRoute = currentPage?.configOptions?.tablet?.disableGlobalNavigationMenu

  if (isDisabledOnCurrentRoute) { return null }

  return (
    <DNABox style={S.tabletContainer}>
      <DNABox fill alignY="center" spacing="around">
        <NavItemTablet route={ROUTES.FOLDERS} />
        <NavItemTablet route={ROUTES.BOOKMARKS} />
        <NavItemTablet route={ROUTES.LIBRARY} />
        <NavItemTablet route={ROUTES.MEETING_HISTORY} />
        {featureFlags.enableHubs &&
          <NavItemTablet route={ROUTES.HUBS}/>
        }
        {featureFlags.enableMSLReports &&
          <NavItemTablet route={ROUTES.VIEWER_REPORTS} />
        }
      </DNABox>
      <DNABox>
        <TenantLogo size="small" />
      </DNABox>
    </DNABox>
  )
}
/** !SECTION: Tablet */

const DNANavigationMenu = (props) => {
  const { deviceMode } = useAppSettings()

  return deviceMode === 'desktop'
    ? <DNANavigationMenuDesktop {...props} />
    : <DNANavigationMenuTablet {...props} />
}

export default DNANavigationMenu
