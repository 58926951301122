import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
  createContext,
  useContext,
  PropsWithChildren,
} from 'react';
import { v4 as uuid } from 'uuid'
import deepEqual from 'fast-deep-equal'

import {
  DndContext,
  DragEndEvent,
  useSensors,
  useSensor,
  MouseSensor,
  TouchSensor,
  CollisionDetection,
  closestCenter,
  pointerWithin,
  rectIntersection,
  getFirstCollision,
  MeasuringStrategy,
  DragStartEvent,
  DragOverEvent,
  Active,
} from '@dnd-kit/core';

import { arrayMove } from '@dnd-kit/sortable'
import type { TargetItem, GroupedTargetItems } from 'src/components/DnD/DnDWrapper'

type ICloneBuilderContext = {
  activeId: string | null,
  setActiveId: React.Dispatch<React.SetStateAction<string | null>>,
  poolItems: TargetItem[],
  setPoolItems: React.Dispatch<React.SetStateAction<TargetItem[]>>,
  groupedTargetItems: GroupedTargetItems,
  activeContainerOrigin: React.MutableRefObject<string | null>,
  addedToGroup: React.MutableRefObject<{ group: string, id: string, itemId: string } | null>,
}

/**
 * CONTEXT
 */
const CloneBuilderContext = createContext<ICloneBuilderContext>({} as any)
export const useCloneBuilder = () => useContext(CloneBuilderContext)

/**
 * STATICS
 */
export const measureStrategy = { droppable: { strategy: MeasuringStrategy.Always } }
export const pointerOptions = { activationConstraint: { distance: 15 } }
export const touchOptions = { activationConstraint: { delay: 50, tolerance: 0 } }

export const POOL_CONTAINER_ID = 'POOL'

// [NOTE] - Sourced from https://github.com/clauderic/dnd-kit/blob/33e6dd2dc954f1f2da90d8f8af995021031b6b41/stories/2%20-%20Presets/Sortable/4-MultipleContainers.story.tsx
const useCollisionDetectionStrategy = (
  activeId: string | null,
  items: GroupedTargetItems,
  lastOverId: React.MutableRefObject<string | null>,
  recentlyMovedToNewContainer: React.MutableRefObject<boolean>,
) => {
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        // If there are droppables intersecting with the pointer, return those
        pointerIntersections.length > 0
          ? pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId !== null && overId !== undefined) {
        if (overId in items) {
          const containerItems = items[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  containerItems.some(item => item.id === container.id),
              ),
            })[0]?.id;
          }
        }

        if (overId) {
          lastOverId.current = overId.toString();
        }

        return lastOverId.current ? [{ id: lastOverId.current }] : [];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items],
  );

  return collisionDetectionStrategy
}

function arraySwapsert<T>(
  array: T[],
  from: number,
  to: number,
  items: T[],
): T[] {
  const newArray = array.slice();

  newArray.splice(
    to < 0 ? newArray.length + to : to,
    0,
    newArray.splice(from, 1)[0],
    ...items,
  );

  return newArray;
}

const generatePoolIds = (poolItemsIn: string[]): TargetItem[] => poolItemsIn.map(itemId => ({ id: uuid(), itemId }))
const randomizePoolIds = (poolItems: TargetItem[]) => poolItems.map(item => ({ ...item, id: uuid() }))
const generateTargetItems = (
  targetItems: Record<string, string[]>,
  existingItems?: GroupedTargetItems,
): GroupedTargetItems => Object
  .entries(targetItems)
  .reduce(
    (acc, [groupName, groupItems]) => {
      // Attempt to re-use same UUIDs to avoid re-rerenders
      const existingMap = Object
        .entries(existingItems ?? {})
        .reduce<Record<string, Record<string, string>>>(
          (accB, [groupId, existingGroupItems]) => {
            return {
              ...accB,
              [groupId]: existingGroupItems.reduce<Record<string, string>>(
                (accC, existingGroupitem) => {
                  return { ...accC, [existingGroupitem.itemId]: existingGroupitem.id }
                },
                { },
              ),
            }
          },
          { },
        )

      return {
        ...acc,
        [groupName]: groupItems.map(
          itemId => ({
            id: existingMap?.[groupName]?.[itemId] ?? uuid(),
            itemId,
          }),
        ),
      }
    },
    { },
  )

// [NOTE] - The state management interface for CloneBuilder could perhaps be better
//        - On one hand, we want CloneBuilder to take care of as much state management possible
//        - But on the other hand, we still should be able to update the state through other means outside of DnD
//        - What we have here is a middle ground of the two where DnD handles the temporary insertion/swap operations and UUID management
//        - And outside interfaces can still insert group/items by passing without needing to know the internals of the above point
//        - From a performance perspective, we avoid bubbling re-renders by trying to encapsulate frequent state operations in this component
//        - Instead of passing in state/setState props which would also re-render the parent
export const CloneBuilder: React.FC<PropsWithChildren<{
  poolItems: string[],
  targetItems: Record<string, string[]>,
  onDragStart?: (active: Active) => void,
  selectedItemIds: string[], // [TODO] - We should support making this optional, but for now make it required for a peformance optimization to help onDragOver
  onDragEndChanged?: (targetItems: GroupedTargetItems) => void,
}>> = (props) => {
  const {
    targetItems,
    poolItems: poolItemsIn,
    selectedItemIds,
    onDragStart: onDragStartIn,
    onDragEndChanged,
    children,
  } = props

  const [activeId, setActiveId] = useState<string | null>(null);
  const [poolItems, setPoolItems] = useState<TargetItem[]>(() => generatePoolIds(poolItemsIn))
  const [groupedTargetItems, setGroupedTargetItems] = useState<GroupedTargetItems>(
    () => generateTargetItems(targetItems),
  )

  const sensors = useSensors(
    useSensor(MouseSensor, pointerOptions),
    useSensor(TouchSensor, touchOptions),
  );

  useEffect(
    () => {
      setPoolItems(generatePoolIds(poolItemsIn))
    },
    [poolItemsIn],
  )

  const lastOverId = useRef<string | null>(null);
  const recentlyMovedToNewContainer = useRef(false);
  const activeContainerOrigin = useRef<string | null>(null)
  const addedToGroup = useRef<{ group: string, id: string, itemId: string } | null>(null)
  const didMount = useRef<boolean>(false)
  const groupedTargetChangedOrigin = useRef<'props' | 'onDragOver' | 'onDragEnd' | undefined>()

  const groupedItemsIdMap = useMemo<Record<string, Record<string, boolean>>>(
    () => {
      return Object
        .entries(groupedTargetItems)
        .reduce(
          (acc, [groupId, groupItems]) => ({
            ...acc,
            [groupId]: groupItems
              .reduce(
                (accc, item) => ({ ...accc, [item.itemId]: true }),
                {},
              ),
          }),
          {},
        )
    },
    [groupedTargetItems],
  )

  // Apply any changes from outside props to CloneBuilder
  useEffect(
    () => {
      if (!didMount.current) {
        didMount.current = true
        return
      }

      setGroupedTargetItems(p => {
        const destructedTargetItems = Object
          .entries(p)
          .reduce<Record<string, string[]>>(
            (acc, [groupName, groupItems]) => ({
              ...acc,
              [groupName]: groupItems.map(item => item.itemId),
            }),
            {},
          )

        const isEquivalent = deepEqual(destructedTargetItems, targetItems)

        if (isEquivalent) {
          return p
        } else {
          groupedTargetChangedOrigin.current = 'props'
          return generateTargetItems(targetItems, p)
        }
      })
    },
    [targetItems],
  )

  // [NOTE] - implicitly call `onDragEndChanged`
  //        - original implementation used to compare items to a cloned array
  //          but that may not have been necessary.
  //        - we can also consider just calling `onDragEndChanged` directly in the
  //          relevant `setGroupedTargeItems` in `onDragOver`
  useEffect(
    () => {
      if (
        onDragEndChanged &&
        !activeContainerOrigin.current &&
        groupedTargetChangedOrigin.current === 'onDragEnd'
      ) {
        onDragEndChanged(groupedTargetItems)
      }
    },
    [groupedTargetItems, onDragEndChanged],
  )

  const collisionDetectionStrategy = useCollisionDetectionStrategy(
    activeId,
    groupedTargetItems,
    lastOverId,
    recentlyMovedToNewContainer,
  )

  const onDragStart = useCallback(
    ({ active }: DragStartEvent) => {
      const isGroupContainer = groupedTargetItems[active.id]
      if (isGroupContainer) return;

      activeContainerOrigin.current = active.data.current?.containerId
      setActiveId(active.id.toString())
      onDragStartIn?.(active)
    },
    [groupedTargetItems, onDragStartIn],
  )

  const onDragOver = useCallback(
    ({ active, over }: DragOverEvent) => {
      const overId = over?.id;

      // [NOTE] - This is a performance optimization but should be optional in the future
      if (!selectedItemIds?.length) {
        return
      }

      if (!overId) {
        return;
      }

      // The sorting preset handles its own onDragOver, onDragEnd will take care of committing the changes
      // NO-OP if not starting from pool items
      if (activeContainerOrigin.current && activeContainerOrigin.current !== POOL_CONTAINER_ID) {
        return;
      }

      const activeContainer = active.data.current?.containerId
      const overContainer = over?.data.current?.containerId
      const isOverEmptyGroup = over?.data.current?.type === 'container'

      const overGroupItemContainerId = over?.data.current?.type !== 'container' && over?.data.current?.containerId

      // NO-OP if over pool
      if (overContainer === POOL_CONTAINER_ID) {
        return;
      }

      // NO-OP if over itself
      if (activeContainer === overContainer) {
        return;
      }

      // POOL -> GROUP (EMPTY SPACE)
      if (isOverEmptyGroup) {
        const overGroupId = overId.toString()
        const newItem = selectedItemIds.at(0)

        if (!newItem) {
          return;
        }

        setGroupedTargetItems(p => {
          // During temporary item insertion, remove the item from any other groups
          const otherFiltered = (addedToGroup.current && addedToGroup.current.group !== overGroupId)
            ? {
              [addedToGroup.current.group]: p[addedToGroup.current.group]
                .filter(i => i.id !== addedToGroup.current?.id),
            }
            : {}

          const modifiedTargetGroup = {
            [overGroupId]: [
              ...p[overGroupId],
              {
                id: active.id.toString(),
                itemId: newItem,
              },
            ],
          }

          const res = {
            ...p,
            ...otherFiltered,
            ...modifiedTargetGroup,
          }

          // Track the temporary insertion
          addedToGroup.current = {
            group: overGroupId,
            id: active.id.toString(),
            itemId: newItem,
          }

          groupedTargetChangedOrigin.current = 'onDragOver'

          return res
        })

        return;
      }

      // POOL -> GROUP (OVER ITEM)
      if (overGroupItemContainerId) {
        const newItem = selectedItemIds.at(0)

        if (!newItem) {
          return
        }

        // Determine the position to insert the temporary item on first possible collision
        const groupItems = groupedTargetItems[overGroupItemContainerId]
        const overIndex = groupItems.findIndex(i => i.id === overId)

        const isBelowOverItem =
          over &&
          active.rect.current.translated &&
          active.rect.current.translated.top >
          over.rect.top + over.rect.height;

        const modifier = isBelowOverItem ? 1 : 0;

        const newIndex = overIndex >= 0
          ? overIndex + modifier // BEFORE
          : groupItems.length + 1; // END

        setGroupedTargetItems(p => {
          // During temporary item insertion, remove the item from any other groups
          const filterOther = addedToGroup.current && (addedToGroup.current.group !== overGroupItemContainerId)
            ? {
              [addedToGroup.current.group]: p[addedToGroup.current.group]
                .filter(i => i.id !== addedToGroup.current?.id),
            }
            : {}

          const modifiedTargetGroup = {
            [overGroupItemContainerId]: Array.from(new Set([
              ...p[overGroupItemContainerId].slice(0, newIndex),
              { id: active.id, itemId: newItem },
              ...p[overGroupItemContainerId].slice(newIndex, p[overGroupItemContainerId].length),
            ]),
            ),
          }

          const res = {
            ...p,
            ...filterOther,
            ...modifiedTargetGroup,
          }

          // Track the temporary insertion
          addedToGroup.current = {
            group: overGroupItemContainerId,
            id: active.id.toString(),
            itemId: newItem,
          }

          groupedTargetChangedOrigin.current = 'onDragOver'

          return res
        })
      }
    },
    [setGroupedTargetItems, groupedTargetItems, groupedItemsIdMap, selectedItemIds],
  )

  const onDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      const overGroupContainerId = over?.data.current?.type === 'container' && over.id
      const overGroupItemContainerId = over?.data.current?.containerId
      const fromGroupContainerId = active.data.current?.containerId

      const isFromPool = activeContainerOrigin.current === POOL_CONTAINER_ID

      const isFromSameGroup = (
        (fromGroupContainerId === overGroupContainerId) ||
        (fromGroupContainerId === overGroupItemContainerId)
      )

      // GROUP -> GROUP (SAME)
      // - Commit final changes -- sortable does not commit changes, only tracks temporary swapping
      if (!isFromPool && isFromSameGroup) {
        const activeIdx = groupedTargetItems[fromGroupContainerId].findIndex(i => i.id === active.id)
        const overIdx = groupedTargetItems[fromGroupContainerId].findIndex(i => i.id === over?.id)
        const shouldSwap = activeIdx !== overIdx

        if (shouldSwap) {
          setGroupedTargetItems(p => {
            groupedTargetChangedOrigin.current = 'onDragEnd'

            return {
              ...p,
              [fromGroupContainerId]: arrayMove(p[fromGroupContainerId], activeIdx, overIdx),
            }
          })
        }
      }

      // POOL -> GROUP
      if (isFromPool && addedToGroup.current) {
        const isMultiSelected = selectedItemIds && selectedItemIds.length > 1
        const targetGroup = addedToGroup.current.group
        // The initial index of current active item at time of temporary insert
        const activeIdx = groupedTargetItems[targetGroup]
          .findIndex(i => i.id === active.id)
        // This final index of the current active item
        const overIdx = groupedTargetItems[targetGroup]
          .findIndex(i => i.id === over?.id)

        const additionalSlidesToAdd = isMultiSelected
          ? selectedItemIds
            .filter(itemId => itemId !== addedToGroup.current!.itemId)
            .map(itemId => ({ id: uuid(), itemId }))
          : []
        setGroupedTargetItems(p => {
          groupedTargetChangedOrigin.current = 'onDragEnd'

          return {
            ...p,
            [fromGroupContainerId]: arraySwapsert(
              groupedTargetItems[targetGroup],
              activeIdx,
              overIdx,
              additionalSlidesToAdd,
            ),
          }
        })
      }

      // [NOTE] - Need to re-randomize pool ids whenever we finishing dragging
      //          so that the next time we add an item from pool, all pool items have a new UUID
      //        - This allows adding the same item from the pool multiple times without id conflicts
      //        - This can still be optimized by only re-randomizing pool items that were added to target
      //          but it's not a big deal at the moment since the consuming components should use them itemId
      //          as the stable key for mapping items anyways
      setPoolItems(p => {
        const res = addedToGroup.current
          ? randomizePoolIds(p)
          : p

        addedToGroup.current = null
        activeContainerOrigin.current = null

        return res
      })

      setActiveId(null);
    },
    [poolItems, groupedTargetItems, selectedItemIds],
  )

  const value = useMemo<ICloneBuilderContext>(
    () => ({
      activeId,
      setActiveId,
      poolItems,
      setPoolItems,
      groupedTargetItems,
      activeContainerOrigin,
      addedToGroup,
    }),
    [activeId, setActiveId, poolItems, setPoolItems, groupedTargetItems],
  )

  return (
    <DndContext
      sensors={sensors}
      measuring={measureStrategy}
      collisionDetection={collisionDetectionStrategy}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
    >
      <CloneBuilderContext.Provider value={value}>
        {children}
      </CloneBuilderContext.Provider>
    </DndContext>
  )
}

export default CloneBuilder
