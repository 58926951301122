import { DEFAULT_SALESFORCE_HANDLED_ERRORS, ERROR_CODES, HANDLED_ERROR, SalesforceSyncer } from './SalesforceSyncer';
import {
  CRMAccount,
  CRMSubmitMeetingPayload,
  CRMSubmitResponseReferences, CRMSubmitStandaloneFormPayload,
  HANDLED_SYNC_ERROR,
  RecordToDelete, SalesforceFirstSubmitResponse,
  SalesforceFormFetchResponse,
  SalesforceRecordToUpsert,
  SYNC_STATUS,
  TableEnum,
} from '../CRMIndexedDBTypes';
import { MeetingORM } from 'src/types/orms';
import {
  getStandaloneFormFirstPayload,
  getVeevaFirstSubmitPayload,
  getVeevaPayloadToUpdateRecords,
} from '../Translators/VeevaFormTranslator';
import {
  getFollowUpsFormSetting,
  handleCallDiscussionProducts,
  handleCallDiscussionSetup,
  handleCustomLookupEntry,
  VEEVA_LOOKUPS_TO_IGNORE,
} from './VeevaSyncerUtils';
import { SYNC_ENTRY_FETCH_TYPE, SYNC_ENTRY_REQUEST_TYPE, SYNC_ENTRY_STATUS, SyncEntry } from '../ISyncManifest';
import {
  AttendeeType, CRMIntegration,
  CrmIntegrationType,
  CrmSyncStatus,
  MeetingAttendeeStatus,
} from '@alucio/aws-beacon-amplify/src/models';
import { Singleton as IndexDbCrm } from '../CRMIndexedDB';
import { TABLES } from '../Translators/VeevaTranslatorUtils';
import { MEETING_SUBMIT_TYPE } from 'src/components/Meeting/AddMeetingProvider';
import { VEEVA_FIELDS_PLACEHOLDER } from '../Translators/VeevaMarkerHandler';
import * as logger from 'src/utils/logger'

const CRMDB = IndexDbCrm;

const GET_SUBJECTS_QUERY = 'SELECT Id, Name, Language_vod__c, Default_Task_vod__c ' +
  'FROM Call_Followup_Template_vod__c WHERE IsDeleted=false';
const GET_USERS_QUERY = 'SELECT Id, Name, LanguageLocaleKey FROM User WHERE IsActive=true';

enum ERROR_MESSAGES {
  SUBMITTED_INQUIRY = 'Cannot update a submitted inquiry.',
  SUBMITTED_MEDICAL_DISCUSSION = 'You may not update a submitted Medical Discussion.',
  SUBMITTED_CALL = 'You may not update a submitted call or any of the supporting data.',
}

const VEEVA_HANDLED_ERRORS: HANDLED_ERROR = {
  [ERROR_CODES.FIELD_CUSTOM_VALIDATION_EXCEPTION]: {
    [ERROR_MESSAGES.SUBMITTED_CALL]: HANDLED_SYNC_ERROR.RECORD_SUBMITTED,
    [ERROR_MESSAGES.SUBMITTED_INQUIRY]: HANDLED_SYNC_ERROR.RECORD_SUBMITTED,
    [ERROR_MESSAGES.SUBMITTED_MEDICAL_DISCUSSION]: HANDLED_SYNC_ERROR.RECORD_SUBMITTED,
  },
};

export class VeevaSyncer extends SalesforceSyncer {
  constructor(config: CRMIntegration) {
    super(config);
    this.errorHandler = {
      ...DEFAULT_SALESFORCE_HANDLED_ERRORS,
      ...VEEVA_HANDLED_ERRORS,
    };
  }

  public async processManifestEntry(entry: SyncEntry): Promise<SyncEntry[]> {
    return super.processManifestEntry(entry, this.handleTableAdditionalSetup.bind(this));
  }

  // HANDLES ADDITIONAL SETUP FOR A SPECIFIC TABLE
  private async handleTableAdditionalSetup(
    tableInfo: SalesforceFormFetchResponse,
    recordTypeId?: string): Promise<void> {
    const promises: Promise<void>[] = [];
    // AVOIDS CHECKING UNNECESSARY TABLES
    const tablesToCheck = [TABLES.CALL, TABLES.CALL_DISCUSSION];
    if (!tablesToCheck.includes(tableInfo.layout.objectApiName)) {
      return;
    }

    const ADDITIONAL_SETUPS = {
      [TABLES.CALL]: {
        'zvod_Followup_vod__c': () => this.handleFollowUpSetup(),
      },
      [TABLES.CALL_DISCUSSION]: {
        'Product_Strategy_vod__c': () => handleCallDiscussionSetup(
          this.fetchSOQL, this.baseUrl, this.getAuthInformation(), tableInfo, recordTypeId),
        'Product_vod__c': () => handleCallDiscussionProducts(tableInfo, recordTypeId),
      },
    };

    tableInfo.layout.sections.forEach(({ layoutRows }) => {
      layoutRows.forEach(({ layoutItems }) => {
        layoutItems.forEach(({ layoutComponents }) => {
          layoutComponents.forEach(({ apiName }) => {
            const setupHandler = ADDITIONAL_SETUPS[tableInfo.layout.objectApiName]?.[apiName || ''];
            if (setupHandler) {
              promises.push(setupHandler());
            }
          });
        });
      });
    });
    await Promise.all(promises);
  }

  // FETCHES THE REQUIRED INFORMATION TO RENDER A FOLLOWUP FORM
  public async handleFollowUpSetup(): Promise<void> {
    logger.veevaSyncer.debug('Handling FollowUp setup');
    const crmUser = this.getAuthInformation();

    const syncEntryBase = {
      type: CrmIntegrationType.SALESFORCE,
      subType: SYNC_ENTRY_REQUEST_TYPE.LOOKUP,
      fetchType: SYNC_ENTRY_FETCH_TYPE.SOQL,
      lastSynced: new Date().getTime(),
      status: SYNC_ENTRY_STATUS.PENDING,
      parameters: {},
    };

    const subjectsQueryEntry: SyncEntry = {
      ...syncEntryBase,
      url: `${this.baseUrl}/query/?q=${GET_SUBJECTS_QUERY}`,
      id: 'VeevaSubjects',
    }

    const usersQueryEntry: SyncEntry = {
      ...syncEntryBase,
      url: `${this.baseUrl}/query/?q=${GET_USERS_QUERY}`,
      id: 'VeevaUsers',
    };

    // FETCHES THE SUBJECTS/USERS/ACCOUNTS TO BE USED WITHIN THE FOLLOWUP'S FORM
    const [usersResponse, subjectsResponse, accounts] = await Promise.all([
      this.fetchSOQL(usersQueryEntry),
      this.fetchSOQL(subjectsQueryEntry),
      CRMDB.getAll<CRMAccount>(TableEnum.ACCOUNT),
    ]);

    // CALLS THE FUNCTION THAT TRANSLATES THE INFO INTO A TASK FORMSETTING
    const followUpFormSettings = getFollowUpsFormSetting(
      usersResponse.data.records, subjectsResponse.data.records, accounts, crmUser, this.config);
    await CRMDB.upsert(TableEnum.FORM_SETTINGS, followUpFormSettings);
  }

  // OVERRIDES PARENT'S FUNCTION TO ADD ADDITIONAL VEEVA BEHAVIORS IF REQUIRED
  protected handleAddLookupEntry(
    apiName: string, // TABLE THAT USES THE FIELD
    lookupApiName: string, // FIELD NAME IN THE TABLE
    lookupTableName: string, // TABLE'S NAME OF THE LOOKUP
    parentId: string): SyncEntry[] {
    const crmUser = this.getAuthInformation();
    const entries = handleCustomLookupEntry({
      apiName,
      lookupApiName,
      lookupTableName,
      parentId,
      baseUrl: this.baseUrl,
      userId: crmUser.userInfo.id,
    });

    if (entries.length) {
      return entries;
    } else if (VEEVA_LOOKUPS_TO_IGNORE[apiName]?.includes(lookupApiName)) {
      return [];
    }

    return super.handleAddLookupEntry(apiName, lookupApiName, lookupTableName, parentId);
  }

  async submitToCRM(crmSubmitPayload: CRMSubmitMeetingPayload): Promise<CRMSubmitResponseReferences> {
    // REMOVES FIELDS NOT MEANT TO BE SENT TO CRM
    delete crmSubmitPayload.mainCrmValues[VEEVA_FIELDS_PLACEHOLDER.CALL_DETAIL];
    logger.veevaSyncer.debug('Veeva Submit to CRM is called.', crmSubmitPayload);
    // IF IT'S THE FIRST TIME A MEETING IS BEING SUBMITTED, WE CAN USE THE COMPOSITE TREE SF'S
    // ENDPOINT TO CREATE ALL THE RECORDS WITH ONE CALL
    const responses = await (crmSubmitPayload.mainCrmRecordId
      ? this.handleVeevaUpdates(crmSubmitPayload)
      : this.handleVeevaFirstSubmit(crmSubmitPayload)
    );

    await this.addBeaconContentRecords(crmSubmitPayload, responses);

    // IF WE'RE SUBMITTING FINAL, WE FIRST UPDATE THE OBJECTS AND THEN WE UPDATE THEIR STATUS
    if (crmSubmitPayload.submitType === MEETING_SUBMIT_TYPE.SUBMIT_LOCK_TO_CRM) {
      return this.submitMeetings(crmSubmitPayload, responses);
    }

    return responses;
  }

  async submitStandaloneFormToCRM(crmSubmitPayload: CRMSubmitStandaloneFormPayload):
    Promise<CRMSubmitResponseReferences> {
    logger.veevaSyncer.debug('StandaloneForm Veeva Submit to CRM is called.', crmSubmitPayload);
    const isFirstSubmission = !crmSubmitPayload.recordFormORM.model.crmFields?.externalId;
    return isFirstSubmission ? this.handleStandaloneFormFirstSubmit(crmSubmitPayload) : {};
  }

  private async submitMeetings(
    crmSubmitPayload: CRMSubmitMeetingPayload,
    responses: CRMSubmitResponseReferences): Promise<CRMSubmitResponseReferences> {
    logger.veevaSyncer.debug('Handling locking the calls');
    const apiName = crmSubmitPayload.formSettings.find((record) => record.isMainTable)?.apiName || '';

    // GETS THE UPSERT RECORDS TO LOCK OF ALREADY SAVED AND VALID CALLS
    const upserts = crmSubmitPayload.meetingORM.model.attendees
      .reduce<{[beaconId: string]: SalesforceRecordToUpsert}>((acc, attendee) => {
        const isPrimaryAttendee = attendee.attendeeType === AttendeeType.PRIMARY;
        const beaconId = isPrimaryAttendee ? 'main-record' : attendee.id;
        const isValidCall = attendee.status === MeetingAttendeeStatus.ACTIVE && attendee.crmRecord?.crmCallId &&
          attendee.crmRecord.crmSyncStatus === CrmSyncStatus.SYNCED;

        if (isValidCall) {
          acc[beaconId] = {
            apiName,
            beaconId,
            salesforceId: attendee.crmRecord.crmCallId,
            fields: {
              Status_vod__c: 'Submitted_vod',
            },
          };
        }
        return acc;
      }, {});

    // GET THE UPSERT RECORDS TO LOCK OF THE NEW CALLS
    const hasErrors = crmSubmitPayload.formValuesAttendees.some((attendee) => {
      const isPrimaryAttendee = attendee.attendeeType === AttendeeType.PRIMARY;
      const beaconId = isPrimaryAttendee ? 'main-record' : attendee.id;
      const response = responses[attendee.id] ||
        (isPrimaryAttendee && responses['main-record']);
      if (response?.syncStatus === SYNC_STATUS.ERROR) {
        return true;
      }

      const attendeeCallId = response?.externalId;
      if (attendeeCallId && !upserts[beaconId]) {
        upserts[beaconId] = {
          apiName,
          beaconId,
          salesforceId: attendeeCallId,
          fields: {
            Status_vod__c: 'Submitted_vod',
          },
        }
      }
    });

    if (hasErrors || !apiName) {
      return responses;
    }

    const responsesFromLocking = await
    this.upsertRecords(crmSubmitPayload.tenant, Object.values(upserts), { records: [] });

    return { ...responses, ...responsesFromLocking };
  }

  private async handleStandaloneFormFirstSubmit(crmSubmitPayload: CRMSubmitStandaloneFormPayload):
    Promise<CRMSubmitResponseReferences> {
    logger.veevaSyncer.debug('Handling first Veeva Standalone Form submit to CRM');
    const payload = await getStandaloneFormFirstPayload(crmSubmitPayload);
    const apiName = crmSubmitPayload.standaloneForm.crmFormSetting?.apiName;
    const response = await super.postFirstSubmit(payload, crmSubmitPayload.tenant, apiName);
    return this.handleFormatFirstSubmitResponses(response);
  }

  private async handleVeevaFirstSubmit(crmSubmitPayload: CRMSubmitMeetingPayload):
    Promise<CRMSubmitResponseReferences> {
    logger.veevaSyncer.debug('Handling first Veeva submit to CRM');
    const payload = await getVeevaFirstSubmitPayload(crmSubmitPayload);
    const response = await super.postFirstSubmit(payload, crmSubmitPayload.tenant);
    return this.handleFormatFirstSubmitResponses(response);
  }

  private handleFormatFirstSubmitResponses(response: SalesforceFirstSubmitResponse): CRMSubmitResponseReferences {
    // THIS ERROR CHECK IS HERE FIRST SINCE THIS IS THE EXPECTED
    // ERROR'S FORMAT ACCORDING TO THE DOCUMENTATION
    if (response.hasErrors) {
      logger.veevaSyncer.error('An error occurred ', response.results);
      const errorMessages = response.results?.reduce<string[]>((acc, { errors }) =>
        [...acc, errors?.map(({ message }) => message)?.join(', ') || ''], []) || [];
      throw new ErrorEvent('API_ERROR', { error: errorMessages });
    }

    // SECOND ERROR FORMAT CHECK (WE WOULD RECEIVE THIS ERROR FORMAT AS WELL)
    if (Array.isArray(response)) {
      // INVALID/EXPIRED TOKEN ERROR
      if (response.some((res) => res.errorCode === 'INVALID_SESSION_ID')) {
        throw new ErrorEvent('INVALID_SESSION_ID', { error: 'Session expired or invalid' });
      }
      const unhandledError = response.find(({ errorCode, message }) => !!errorCode && !!message);
      // UNHANDLED ERROR
      if (unhandledError) {
        throw new ErrorEvent(unhandledError.errorCode, { error: unhandledError.message });
      }
    }

    const submitResponse = response.results.reduce<CRMSubmitResponseReferences>((acc, result) => {
      const errors = result.errors?.map((error) => error.message) || [];

      return {
        ...acc,
        [result.referenceId]: {
          externalId: result.id,
          syncStatus: errors.length ? SYNC_STATUS.ERROR : SYNC_STATUS.SYNCED,
          errorMessages: errors,
        },
      }
    }, {});

    logger.veevaSyncer.debug('Requests responses', submitResponse);
    return submitResponse;
  }

  public async handleDeleteMeeting(meeting: MeetingORM): Promise<CRMSubmitResponseReferences> {
    const { crmRecord } = meeting.model;
    const recordsToDelete: RecordToDelete[] = [];
    if (!crmRecord?.crmCallId) {
      return {};
    }

    recordsToDelete.push({
      beaconId: 'main-record',
      crmId: crmRecord.crmCallId,
    })

    meeting.model.attendees.forEach((attendee) => {
      if (attendee.crmRecord?.crmCallId) {
        recordsToDelete.push({
          beaconId: attendee.id,
          crmId: attendee.crmRecord?.crmCallId,
        })
      }
    });

    analytics.track('MEETING_DELETE', {
      category: 'MEETING',
      type: 'DELETE',
      integration: 'VEEVA',
      crmRecordId: crmRecord.crmCallId,
    });

    return this.deleteRecords(recordsToDelete);
  }

  private async handleVeevaUpdates(crmSubmitPayload: CRMSubmitMeetingPayload): Promise<CRMSubmitResponseReferences> {
    logger.veevaSyncer.debug('Handling Veeva update submit to CRM', crmSubmitPayload);
    // GETS AN ARRAY OF RECORDS TO BE UPDATED/CREATED/DELETED
    const separatedRecords = await getVeevaPayloadToUpdateRecords(crmSubmitPayload);
    logger.veevaSyncer.debug('Veeva upserts payload', separatedRecords);

    const responses = await Promise.all([
      this.deleteRecords(separatedRecords.recordsToDelete),
      this.upsertRecords(
        crmSubmitPayload.tenant,
        separatedRecords.recordsToUpsert,
        separatedRecords.recordsToInsert,
      ),
    ]);

    return responses.reduce((acc, resp) => ({ ...acc, ...resp }), {});
  }
}
