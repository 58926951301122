import React, { useState } from 'react';
import { useDispatch } from 'src/state/redux';
import { CustomDeckGroup } from '@alucio/aws-beacon-amplify/src/models';
import {
  DNAModal,
  DNABox,
  DNAButton,
  DNAText,
  GenericToast,
  ToastOrientations,
  useDisableSwipe,
  useToast,
  Tabs,
  Iffy,
} from '@alucio/lux-ui';
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal';
import DNAFolderNestedList,
{ DEFAULT_SHARED_FOLDER_FILTER, FolderSelectedMap } from 'src/components/DNA/Folder/NestedList/DNAFolderNestedList';
import DNAFolderUpsertModal from 'src/components/DNA/Modal/DNAFolderUpsert';
import { folderActions } from 'src/state/redux/slice/folder';
import { sharedFolderActions } from 'src/state/redux/slice/sharedFolder';
import * as guards from 'src/types/typeguards';
import DNAConditionalButton from '../DNA/ConditionalButton/DNAConditionalButton';
import { TABS_FOLDERS } from '../DNA/Modal/DNADocumentAddToFolder';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { useAllFoldersInstance } from 'src/state/redux/selector/folder';

export type SelectFolderPresentationBuilderProps = {
  itemORM: CustomDeckGroup[]
  title: string,
  onDone: () => void
}

export const SelectFolderPresentationBuilder: DNAModalVariant<SelectFolderPresentationBuilderProps> = (props) => {
  const { itemORM, toggleModal, pushModal, closeModal, title, onDone } = props
  const [selected, setSelected] = useState<FolderSelectedMap>({})
  const toast = useToast();
  const [selectedTab, setSelectedTab] = useState(TABS_FOLDERS[0].title)
  const enableEditSharedFolder = useFeatureFlags('enableEditSharedFolder')
  const sharedFolders =  useAllFoldersInstance(DEFAULT_SHARED_FOLDER_FILTER, true)
  const isAnyRootFolderShared = sharedFolders.some(folder => !folder.relations.parentFolderORM)
  const tabs = enableEditSharedFolder && isAnyRootFolderShared ? TABS_FOLDERS : [TABS_FOLDERS[0]]

  const dispatch = useDispatch()
  useDisableSwipe()

  const targetFolders = Object
    .values(selected)
    .filter(selection => selection && !selection.locked)
    .map(selection => selection?.folder).filter(guards.isFolderORM)

  const onCreateCustomDeck = () => {
    if (targetFolders.length) {
      const isSharedFolder = selectedTab === 'Shared Folders'
      targetFolders.forEach(folder => {
        if (isSharedFolder) {
          dispatch(sharedFolderActions.createCustomDeckInFolder({
            targetFolder: folder,
            selectedSlides: itemORM,
            title,
          },
          ))
        }
        else {
          dispatch(folderActions.createCustomDeck(
            folder,
            itemORM,
            title,
          ))
        }
        toast?.add(
          <GenericToast
            title="Presentation created"
            status="success"
          />,
          ToastOrientations.TOP_RIGHT,
        )
      })
      toggleModal()
      onDone()
    }
  }

  const onCreateFolder = () => {
    pushModal((props) => (<DNAFolderUpsertModal {...props} toast={toast} />))
  }

  const onSelectedTab = (tab: string) => setSelectedTab(tab)
  const showSharedFolders = selectedTab === TABS_FOLDERS[1].title

  return (
    <DNAModal>
      <DNAModal.Header onClose={closeModal}>
        <DNABox spacing="sm" style={{ paddingTop: 12, paddingLeft: 16 }} >
          <DNAText h5>Add to Folders </DNAText>
          <DNAText status="subtle">{targetFolders.length} selected</DNAText>
        </DNABox>
      </DNAModal.Header>
      <DNAModal.Body>
        <DNABox appearance="col" fill>
          <Tabs
            style={{ paddingLeft: 0 }}
            selectedTab={selectedTab}
            selectedTabVariant="primary"
            tabs={tabs}
            childFill
            onSelectTab={onSelectedTab}
            styleTab={ tabs.length === 1 && { justifyContent: 'flex-start' }}
          />
          <DNAFolderNestedList
            onFolderSelect={setSelected}
            showSharedFolders={selectedTab === 'Shared Folders'}
          />
        </DNABox>
      </DNAModal.Body>
      <DNAModal.Confirmation>
        <Iffy is={!showSharedFolders}>
          <DNAButton
            onPress={onCreateFolder}
            appearance="outline"
          >Create new folder</DNAButton>
        </Iffy>
        <DNAConditionalButton
          onPress={onCreateCustomDeck}
        >
          <DNAConditionalButton.Disable condition={!targetFolders.length}>
            <DNAText status="basic">Select destination folder(s)</DNAText>
          </DNAConditionalButton.Disable>
          Done
        </DNAConditionalButton>
      </DNAModal.Confirmation>
    </DNAModal>
  )
}

export default SelectFolderPresentationBuilder
