import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  PropsWithChildren,
  Dispatch,
  SetStateAction,
} from 'react'
import { DocumentORM } from 'src/types/types';

/** TODO: This should be a map entity */
export type SelectedDocuments = {[id: string]: boolean}

interface DocumentSelectManagerContextType {
  selectEnabled: boolean,
  setSelectEnabled: Dispatch<SetStateAction<boolean>>,
  selectedDocuments: SelectedDocuments,
  setSelectedDocuments: Dispatch<SetStateAction<SelectedDocuments>>,
  onSelectDocuments: (documents: DocumentORM[]) => void,
}

const DocumentSelectManagerContext = createContext<DocumentSelectManagerContextType>({
  selectEnabled: false,
  setSelectEnabled: () => null,
  selectedDocuments: {},
  setSelectedDocuments: () => null,
  onSelectDocuments: () => null,
});

DocumentSelectManagerContext.displayName = 'DocumentSelectManagerContext';

const DocumentSelectManager: React.FC<PropsWithChildren> = (props) => {
  const [selectEnabled, setSelectEnabled] = useState(false)
  const [selectedDocuments, setSelectedDocuments] = useState<SelectedDocuments>({})

  useEffect(() => {
    if (!selectEnabled) setSelectedDocuments({})
  }, [selectEnabled])

  const onSelectDocuments = (documents: DocumentORM[]) => {
    if (!selectEnabled) return

    setSelectedDocuments(pre => {
      const allDocmentSelected = documents.every(doc => pre[doc.model.id])
      // If allDocmentSelected than unselect all documents, else select all documents
      const newSelection = { ...pre }
      documents.forEach(doc => {
        newSelection[doc.model.id] = !allDocmentSelected;
      });
      return newSelection
    })
  }

  const contextValue: DocumentSelectManagerContextType = {
    selectEnabled,
    setSelectEnabled,
    selectedDocuments,
    setSelectedDocuments,
    onSelectDocuments,
  };

  return (
    <DocumentSelectManagerContext.Provider value={contextValue}>
      {props.children}
    </DocumentSelectManagerContext.Provider>
  );
}

export const useDocumentSelectManager = () => {
  const context = useContext(DocumentSelectManagerContext);
  if (!context) {
    throw new Error('useDocumentSelectManager must be used within the DocumentSelectManager')
  }
  return context;
};

export default DocumentSelectManager;
