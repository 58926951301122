import React, { useEffect } from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { DNABox, Iffy } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { useInPersonActionBarSharedComponents } from './useInPersonActionBarSharedComponents';
import { ActionBarState } from '../../SharedComponents/PresentationMenu';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';

const S = StyleSheet.create({
  actionsContainer: {
    backgroundColor: colors['color-black'],
    padding: 8,
    borderTopLeftRadius: 4,
  },
  actionsContainerV2: {
    backgroundColor: colors['color-black'],
    paddingHorizontal: 8,
    height: 64,
    borderTopLeftRadius: 4,
  },
})

const DESKTOP_PWA_MIN_WINDOW_WIDTH = 1050;

const TabletVariant: React.FC = () => {
  const enableNew3PC = useFeatureFlags('enableNew3PC');
  const dimensions = useWindowDimensions();
  const { setPresentationMenu } = useMeetingsState();
  const {
    presentationNav,
    myContentButton,
    presentationsButton,
    slidesButton,
    highlighterButton,
    highlighterActions,
    highlighterContextMenu,
    textSearchButton,
    reactionButtons,
    followUpButton,
    sentimentsContextMenu,
    myNotesButton,
    fullScreenButton,
    endMeetingButton,
    changeToVirtualModeButton,
    collapseButton,
    actionBarState,
    CurrentActionsToggle,
    currentActionsGroup,
  } = useInPersonActionBarSharedComponents();

  useEffect(() => {
    if (enableNew3PC && dimensions.width < DESKTOP_PWA_MIN_WINDOW_WIDTH) {
      setPresentationMenu(ActionBarState.Collapsed)
    }
  }, [dimensions])

  if (!enableNew3PC) {
    return (
      <DNABox spacing="sm" style={S.actionsContainer} alignY="center">
        <Iffy is={actionBarState === ActionBarState.PresentationMenu}>
          <Iffy is={currentActionsGroup === 'tabletSentiments'}>
            <CurrentActionsToggle
              group="default"
              iconLeft="arrow-left-thick"
              style={{ maxHeight: 49 }}
            >
              Back
            </CurrentActionsToggle>
            {reactionButtons}
            {followUpButton}
          </Iffy>
          <Iffy is={currentActionsGroup === 'default'}>
            {presentationNav}
            {myContentButton}
            {presentationsButton}
            {slidesButton}
            {highlighterButton}
            {textSearchButton}
            {myNotesButton}
            <Iffy is={(reactionButtons || followUpButton)}>
              <CurrentActionsToggle
                group="tabletSentiments"
                iconLeft="dots-vertical"
                isIconButton={true}
              />
            </Iffy>
            {endMeetingButton}
            {collapseButton}
          </Iffy>
        </Iffy>
        <Iffy is={actionBarState === ActionBarState.HighlighterMenu} >
          {highlighterActions}
        </Iffy>
        <Iffy is={actionBarState === ActionBarState.Collapsed} >
          {presentationNav}
          {collapseButton}
        </Iffy>
      </DNABox>
    )
  } else {
    return (
      <DNABox
        spacing={actionBarState === ActionBarState.PresentationMenu ? 'between' : 'sm'}
        style={S.actionsContainerV2}
        alignY="center"
        alignX={actionBarState === ActionBarState.Collapsed ? 'end' : undefined}
        fill={false}
      >
        <Iffy is={actionBarState === ActionBarState.PresentationMenu} >
          {/* LEFT */}
          <DNABox spacing="sm">
            {myContentButton}
            {presentationsButton}
            {slidesButton}
          </DNABox>
          {/* MIDDLE */}
          <DNABox>
            {presentationNav}
          </DNABox>
          {/* RIGHT */}
          <DNABox spacing="sm">
            {highlighterContextMenu}
            {textSearchButton}
            {sentimentsContextMenu}
            {myNotesButton}
            {fullScreenButton}
            {endMeetingButton}
            {changeToVirtualModeButton}
            {collapseButton}
          </DNABox>
        </Iffy>
        <Iffy is={actionBarState === ActionBarState.Collapsed} >
          {presentationNav}
          {collapseButton}
        </Iffy>
      </DNABox>
    )
  }
}

export default TabletVariant
