import React from 'react'
import { DocumentVersionORM, FolderItemORM } from 'src/types/types'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'

import { DNABox, DNACard, DNAChip, DNAText, DNAIcon, Iffy, useTheme } from '@alucio/lux-ui'
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import { DocumentStatus } from '@alucio/aws-beacon-amplify/src/models'
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector'

const DNADocumentFolderItemVirtualRow: DNARowsComponent<FolderItemORM> = (props) => {
  const { ORM: folderItemORM, onPress, style } = props
  const theme = useTheme()
  /** TODO: refactor improper use of as assertion */
  const documentVersionORM = folderItemORM.relations.itemORM as DocumentVersionORM
  const parentDocumentORM = documentVersionORM.relations.documentORM
  const title = folderItemORM.model.customTitle || documentVersionORM.model.title
  const canPresent = parentDocumentORM.meta.permissions.MSLPresent
  const { visiblePages } = folderItemORM.model
  const isModified = (
    visiblePages?.length &&
    documentVersionORM.meta.permissions.MSLSelectSlides &&
    documentVersionORM.model.status === 'PUBLISHED'
  )

  const isArchived = documentVersionORM.meta.sealedStatus === DocumentStatus.ARCHIVED

  const containerStyle = [
    {
      paddingLeft: 12,
      paddingRight: 20,
      paddingVertical: 12,
      backgroundColor: theme['color-dark-300'],
      marginVertical: 1,
      borderRadius: 4,
    },
    style,
  ]

  // If the publisher's selected cover page was hidden by the user
  // we use the first visible page
  // [TODO-2126] - We should be able calculate this at the ORM level instead
  let customThumbnailKey: string | undefined
  const selectedThumbnail = documentVersionORM.model.selectedThumbnail ?? 1
  if (folderItemORM.model.visiblePages && !folderItemORM.model.visiblePages.find(vp => vp === selectedThumbnail)) {
    const customPage = documentVersionORM.meta.allPages[folderItemORM.model.visiblePages[0] - 1]
    customThumbnailKey = detectArchivedFileKeyPath(documentVersionORM.model, customPage)
  }

  return (
    <DNACard
      appearance="flat"
      interactive="pressable"
      onPress={() => { onPress?.(folderItemORM) }}
      style={containerStyle}
    >
      <DNABox alignY="center" style={!canPresent && { opacity: 0.4 }} spacing="between" childFill={0}>
        <DNABox spacing="lg" alignY="center" childFill={1}>
          <DNABox style={{ backgroundColor: 'rgb(0, 0, 0)' }}>
            <DNADocumentThumbnail
              documentVersionORM={documentVersionORM}
              customThumbnailKey={customThumbnailKey}
              width={126}
              height={72}
            />
          </DNABox>
          <DNABox spacing="sm" fill childFill={0}>
            <DNABox spacing="sm" appearance="col" fill>
              <DNAText b2 status="basic" numberOfLines={1}>
                {title}
              </DNAText>
              <DNABox spacing="sm">
                <DNADocumentChip
                  item={documentVersionORM}
                  variant="purpose"
                />
                <Iffy is={isArchived}>
                  <DNADocumentChip
                    item={documentVersionORM}
                    variant="status"
                  />
                </Iffy>
                <Iffy is={isModified}>
                  <DNAChip
                    appearance="tag"
                    style={{ backgroundColor: 'rgba(255,255,255,0.16)' }}
                  >
                    MODIFIED
                  </DNAChip>
                </Iffy>
              </DNABox>
            </DNABox>

            <Iffy is={parentDocumentORM.meta.bookmark.isBookmarked}>
              <DNAIcon.Styled
                status="tertiary"
                appearance="ghost"
                name="bookmark"
                style={{ minWidth: 30 }}
              />
            </Iffy>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNACard>
  )
}

export default DNADocumentFolderItemVirtualRow
