import { DNAText } from '@alucio/lux-ui';
import { DNATextProps } from '@alucio/lux-ui/src/components/type/DNAText/DNAText';
import React from 'react';

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const highlightText = (
  text : string,
  shouldBeInBold: string,
  status?: DNATextProps['status'],
  numberOfLines?: number,
  testID?: string,
) => {
  const parts = text.split(new RegExp(`(${escapeRegExp(shouldBeInBold)})`, 'gi'));
  return (
    <DNAText numberOfLines={numberOfLines} testID={testID}>
      {parts.map((item, index) => (
        <DNAText key={index} bold={item.toLowerCase() === shouldBeInBold.toLowerCase()} status={status}>{item}</DNAText>
      ),
      )}
    </DNAText>
  )
}

export {
  highlightText
}
