/** Check out this flow chart for Hubs machines
 * https://www.figma.com/file/MZASmn9JmaSGXqRt7jwfjn/MSL-Notation-machine?type=whiteboard&node-id=0-1&t=zjZM1qWYYJpKJxsj-0
 */
import { createMachine, spawn } from 'xstate';
import { assign } from '@xstate/immer';
import { v4 as uuid } from 'uuid';
import { BroadcastChannel } from 'broadcast-channel';
import {
  NotationType,
  HubSectionItemStatus,
  Notation,
} from '@alucio/aws-beacon-amplify/src/models';
import {
  NotationsContext,
  NotationsEvents,
  NotationsState,
  EVT_SET_COORDINATE,
  EVT_UPDATE_DESCRIPTION,
  EVT_EDIT_NOTATION,
  EVT_DELETE_NOTATION,
  EVT_SET_CURRENT_ACTIVE_NOTATION,
} from './notationTypes';
import {
  getNotations,
  getUpdatedSharedFiles,
  presentationStateObserver,
} from './notationUtils';
import { PW } from 'src/state/machines/presentation/playerWrapper';
import { SharedFilesContextType } from 'src/screens/Hubs/EditHub/Widgets/SharedFilesComponents/SharedFilesProvider';
import ActiveUser from 'src/state/global/ActiveUser';
import * as logger from 'src/utils/logger'

const notations = (
  sharedFilesState: SharedFilesContextType,
  presentationStateChannel: BroadcastChannel<PW.PresentationChannelMessage>,
  disableNotationDescription: boolean,
  updateNotations: (notations?: Notation[]) => void,
  setActiveSlideByPresentationPageNumber: (presentationPageNumber: number, step?: number, totalSteps?: number) => void,
  meetingId: string,
  currentActiveNotationId?: string,
) => {
  const sharedFiles = sharedFilesState.rhForm.getValues('sharedFilesWidget')?.sharedFiles
  if (!sharedFiles) {
    const errorText = `Cannot find form value of shared files widget when initializing notation machine ${meetingId}`
    logger.hub.widgets.sharedFiles.callOuts.error(errorText)
    throw new Error(errorText)
  }

  return createMachine<
    NotationsContext,
    NotationsEvents,
    NotationsState
  >(
    {
      predictableActionArguments: false,
      id: 'Notation',
      context: {
        presentationStateChannel,
        hasPermissionToEditDescription: !disableNotationDescription,
        meetingId,
        currentActiveNotationId,
      },
      initial: 'syncPresentable',
      entry: 'spawnPresentationStateObserver',
      states: {
        syncPresentable: {
          description: 'Sync presentable for the first time',
          entry: 'syncPresentable',
          on: {
            PRESENTATION_STATE_SYNC: {
              actions: 'setPresentableState',
            },
            IFRAME_READY: {
              actions: ctx => {
                if (ctx.currentActiveNotationId) {
                  ctx.presentationStateChannel.postMessage({
                    type: 'SYNC_CURRENT_ACTIVE_NOTATION',
                    meetingId: ctx.meetingId,
                    notationId: ctx.currentActiveNotationId,
                  })
                }
              },
              target: '#Notation.idle',
            },
          },
        },
        idle: {
          description: 'Waiting for user actions',
          entry: 'toggleNotations',
          on: {
            PRESENTATION_STATE_SYNC: {
              actions: 'setPresentableState',
            },
            ENTER_SET_COORDINATE_MODE: {
              description: 'When user click on create callout button',
              actions: 'setCurrentActiveNotationId',
              target: 'setCoordinateMode',
            },
            EDIT_NOTATION: {
              cond: 'hasPermissionToEditDescription',
              actions: ['setCurrentActiveNotationId', 'setEditingNotationId'],
              target: 'editDescription',
            },
            DELETE_NOTATION: {
              actions: 'deleteNotation',
            },
            SET_CURRENT_ACTIVE_NOTATION: {
              actions: 'setCurrentActiveNotationId',
            },
          },
        },
        setCoordinateMode: {
          description: 'Waiting for user to set the coordinate',
          entry: 'toggleOnCoordinateMode',
          on: {
            SET_COORDINATE: {
              description: 'When user click on playerWrapper',
              target: 'generateNotation',
            },
            BACK_TO_IDLE: {
              target: 'idle',
            },
          },
          exit: 'toggleOffCoordinateMode',
        },
        generateNotation: {
          description: 'Generate all details for created notation',
          entry: 'generateNotationDetails',
          always: {
            target: 'determineShouldEditDescription',
          },
        },
        determineShouldEditDescription: {
          description: 'Determine the next step, should user be able to edit description',
          always: [
            {
              cond: 'hasPermissionToEditDescription',
              target: 'editDescription',
              actions: ctx => {
                ctx.isNewNotation = true
              },
            },
            {
              target: 'idle',
            },
          ],
        },
        editDescription: {
          description: 'User editing the description textarea',
          on: {
            PRESENTATION_STATE_SYNC: {
              actions: 'setPresentableState',
            },
            UPDATE_DESCRIPTION: {
              actions: 'updateDescription',
              target: 'idle',
            },
            BACK_TO_IDLE: {
              target: 'idle',
            },
            DELETE_NOTATION: {
              cond: 'isNewNotation',
              actions: 'deleteNotation',
              target: 'idle',
            },
          },
          exit: ctx => {
            ctx.isNewNotation = undefined
          },
        },
      },
    },
    {
      actions: {
        spawnPresentationStateObserver: assign(ctx => {
          logger.hub.widgets.sharedFiles.callOuts.info(
            `Entering notation state machine. 
            Initialize presentation state observer, meetingId: ${ctx.meetingId}`,
          )
          if (!ctx.presentationStateObserver) {
            const actor = spawn(
              presentationStateObserver(ctx.presentationStateChannel, ctx.meetingId),
              'presentationStateObserverActor',
            )
            ctx.presentationStateObserver = actor
          }
        }),
        setPresentableState: assign((ctx, event) => {
          const evt = event as PW.EVT_PRESENTATION_STATE_SYNC
          ctx.presentableState = evt.payload
        }),
        syncPresentable: assign((ctx) => {
          logger.hub.widgets.sharedFiles.callOuts.debug(
            `Sending SYNC_PRESENTABLE signal to presentationStateChannel, meetingId: ${ctx.meetingId}`,
          )
          ctx.presentationStateChannel.postMessage({
            type: 'SYNC_PRESENTABLE',
            meetingId: ctx.meetingId,
          })
        }),
        toggleNotations: assign((ctx) => {
          logger.hub.widgets.sharedFiles.callOuts.debug(`Toggle on notations, meetingId: ${ctx.meetingId}`)
          ctx.presentationStateChannel.postMessage({
            type: 'TOGGLE_NOTATIONS',
            meetingId: ctx.meetingId,
            toggleOn: true,
          })
        }),
        toggleOnCoordinateMode: assign((ctx) => {
          logger.hub.widgets.sharedFiles.callOuts.debug(`Toggle on coordinate mode, meetingId: ${ctx.meetingId}`)
          ctx.presentationStateChannel.postMessage({
            type: 'TOGGLE_COORDINATE_MODE',
            meetingId: ctx.meetingId,
            toggleOn: true,
          })
        }),
        toggleOffCoordinateMode: assign((ctx) => {
          logger.hub.widgets.sharedFiles.callOuts.debug(`Toggle off coordinate mode, meetingId: ${ctx.meetingId}`)
          ctx.presentationStateChannel.postMessage({
            type: 'TOGGLE_COORDINATE_MODE',
            meetingId: ctx.meetingId,
            toggleOn: false,
          })
        }),
        generateNotationDetails: assign((ctx, event) => {
          // This action generate natation object and send to edit hub rhform
          const evt = event as EVT_SET_COORDINATE
          if (!ActiveUser.user) {
            const errorText = `Cannot find current user when generating notation, meetingId: ${ctx.meetingId}`
            logger.hub.widgets.sharedFiles.callOuts.error(errorText)
            throw new Error(errorText)
          }
          else if (!ctx.presentableState) {
            const errorText = `Cannot find presentableState when generating notation, meetingId: ${ctx.meetingId}`
            logger.hub.widgets.sharedFiles.callOuts.error(errorText)
            throw new Error(errorText)
          }

          const notation: Notation = {
            id: uuid(),
            type: NotationType.CALLOUT,
            description: undefined,
            status: HubSectionItemStatus.ACTIVE,
            pageId: evt.pageId,
            coordinate: evt.coordinate,
            createdAt: new Date().toISOString(),
            createdBy: ActiveUser.user.id,
            updatedAt: new Date().toISOString(),
            updatedBy: ActiveUser.user.id,
          }
          logger.hub.widgets.sharedFiles.callOuts.debug(`Create notation details, notationId: ${notation.id}`)

          const widgetFormValue = sharedFilesState.rhForm.getValues('sharedFilesWidget')
          const docVerId = ctx.presentableState.documentVersionId
          if (!widgetFormValue || !widgetFormValue.sharedFiles) {
            const errorText = `Cannot find form value of shared files widget when generating notation: ${ctx.meetingId}`
            logger.hub.widgets.sharedFiles.callOuts.error(errorText)
            throw new Error(errorText)
          }

          const updatedSharedFiles = getUpdatedSharedFiles(
            widgetFormValue.sharedFiles,
            docVerId,
            { type: 'add', notation },
          )

          sharedFilesState.rhForm.setValue('sharedFilesWidget', {
            ...widgetFormValue,
            sharedFiles: updatedSharedFiles,
            updatedAt: new Date().toISOString(),
          }, { shouldDirty: true })
          sharedFilesState.setItems(updatedSharedFiles)
          const notations = getNotations(updatedSharedFiles, docVerId)
          updateNotations(notations)
          if (ctx.hasPermissionToEditDescription) ctx.editingNotationId = notation.id
        }),
        updateDescription: assign((ctx, event) => {
          if (!ctx.presentableState) {
            const errorText = `Cannot find presentableState when generating notation, meetingId: ${ctx.meetingId}`
            logger.hub.widgets.sharedFiles.callOuts.error(errorText)
            throw new Error(errorText)
          }
          if (!ctx.editingNotationId) {
            const errorText = `Cannot find current editing Notation: ${ctx.meetingId}`
            logger.hub.widgets.sharedFiles.callOuts.error(errorText)
            throw new Error(errorText)
          }
          logger.hub.widgets.sharedFiles.callOuts.debug(
            `Update notation description, editingNotationId: ${ctx.editingNotationId}`,
          )
          const evt = event as EVT_UPDATE_DESCRIPTION
          const widgetFormValue = sharedFilesState.rhForm.getValues('sharedFilesWidget')
          const docVerId = ctx.presentableState.documentVersionId
          if (!widgetFormValue || !widgetFormValue.sharedFiles) {
            // eslint-disable-next-line max-len
            const errorText = `Cannot find form value of shared files widget when updating description: ${ctx.meetingId}`
            logger.hub.widgets.sharedFiles.callOuts.error(errorText)
            throw new Error(errorText)
          }

          const updatedSharedFiles = getUpdatedSharedFiles(
            widgetFormValue.sharedFiles,
            docVerId,
            { type: 'edit', notationId: ctx.editingNotationId, description: evt.description },
          )

          sharedFilesState.rhForm.setValue('sharedFilesWidget', {
            ...widgetFormValue,
            sharedFiles: updatedSharedFiles,
            updatedAt: new Date().toISOString(),
          }, { shouldDirty: true })
          sharedFilesState.setItems(updatedSharedFiles)
          const notations = getNotations(updatedSharedFiles, docVerId)
          updateNotations(notations)
          ctx.editingNotationId = undefined
        }),
        setEditingNotationId: assign((ctx, event) => {
          const evt = event as EVT_EDIT_NOTATION
          ctx.editingNotationId = evt.notationId
          logger.hub.widgets.sharedFiles.callOuts.debug(`Set current editing notation: ${evt.notationId}`)
        }),
        deleteNotation: assign((ctx, event) => {
          if (!ctx.presentableState) {
            const errorText = `Cannot find presentableState when deleting notation, meetingId: ${ctx.meetingId}`
            logger.hub.widgets.sharedFiles.callOuts.error(errorText)
            throw new Error(errorText)
          }
          const evt = event as EVT_DELETE_NOTATION
          logger.hub.widgets.sharedFiles.callOuts.debug(`Delete notation: ${evt.notationId}`)
          if (ctx.currentActiveNotationId === evt.notationId) {
            ctx.currentActiveNotationId = undefined
          }
          const widgetFormValue = sharedFilesState.rhForm.getValues('sharedFilesWidget')
          const docVerId = ctx.presentableState.documentVersionId
          if (!widgetFormValue || !widgetFormValue.sharedFiles) {
            // eslint-disable-next-line max-len
            const errorText = `Cannot find form value of shared files widget when deleting description: ${ctx.meetingId}`
            logger.hub.widgets.sharedFiles.callOuts.error(errorText)
            throw new Error(errorText)
          }

          const updatedSharedFiles = getUpdatedSharedFiles(
            widgetFormValue.sharedFiles,
            docVerId,
            { type: 'delete', notationId: evt.notationId },
          )

          sharedFilesState.rhForm.setValue('sharedFilesWidget', {
            ...widgetFormValue,
            sharedFiles: updatedSharedFiles,
            updatedAt: new Date().toISOString(),
          }, { shouldDirty: true })
          sharedFilesState.setItems(updatedSharedFiles)
          const notations = getNotations(updatedSharedFiles, docVerId)
          updateNotations(notations)
        }),
        setCurrentActiveNotationId: assign((ctx, event) => {
          const evt = event as EVT_SET_CURRENT_ACTIVE_NOTATION

          if (ctx.currentActiveNotationId === evt.notationId) {
            logger.hub.widgets.sharedFiles.callOuts.debug(`Toggle off active notation ${evt.notationId}`)
            ctx.currentActiveNotationId = undefined
            ctx.presentationStateChannel.postMessage({
              type: 'SYNC_CURRENT_ACTIVE_NOTATION',
              meetingId: ctx.meetingId,
            })
          } else {
            logger.hub.widgets.sharedFiles.callOuts.debug(`Toggle on active notation ${evt.notationId}`)
            ctx.currentActiveNotationId = evt.notationId
            const targetNotationPageNum = Number(ctx.presentableState?.notations
              ?.find(notation => notation.id === evt.notationId)?.pageId.split('_').pop()) ?? 0
            if (targetNotationPageNum && ctx.presentableState?.state.page !== targetNotationPageNum) {
              setActiveSlideByPresentationPageNumber(targetNotationPageNum)
            }
            ctx.presentationStateChannel.postMessage({
              type: 'SYNC_CURRENT_ACTIVE_NOTATION',
              meetingId: ctx.meetingId,
              notationId: evt.notationId,
            })
          }
        }),
      },
      guards: {
        hasPermissionToEditDescription: (ctx) => ctx.hasPermissionToEditDescription,
        isNewNotation: (ctx) => !!ctx.isNewNotation,
      },
    },
  )
}

export default notations
