import React from 'react'
import { useDispatch } from 'src/state/redux'
import { folderActions } from 'src/state/redux/slice/folder'

import { DNABox, DNAModal, DNAText } from '@alucio/lux-ui'
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal'
import { DocumentVersionORM, FolderItemORM, VERSION_UPDATE_STATUS } from 'src/types/types'
import { isCustomDeckORM, isDocumentVersionORM } from 'src/types/typeguards'
import { customDeckActions } from 'src/state/redux/slice/customDeck'
import { isSharedEditableFolder } from 'src/utils/foldersHelpers'
import { UpdateType, sharedFolderActions } from 'src/state/redux/slice/sharedFolder'

export const DNADocumentVersionUpdateModal: DNAModalVariant<{
  folderItemORM: FolderItemORM,
}> = (props) => {
  const { folderItemORM, toggleModal } = props
  let documentVersionORM: DocumentVersionORM | undefined

  if (isCustomDeckORM(folderItemORM.relations.itemORM)) {
    const customDeckORM = folderItemORM.relations.itemORM
    const updatedGroup = customDeckORM.meta.customDeckGroups.find(groupORM =>
      groupORM.meta.version.updateStatus === VERSION_UPDATE_STATUS.PENDING_MINOR,
    )
    if (updatedGroup) {
      documentVersionORM = updatedGroup.pages[0].documentVersionORM
    }
  }
  else if (isDocumentVersionORM(folderItemORM.relations.itemORM)) {
    documentVersionORM = folderItemORM.relations.itemORM
  }
  const dispatch = useDispatch()

  const onAction = () => {
    if (isCustomDeckORM(folderItemORM.relations.itemORM)) {
      dispatch(customDeckActions.updateDocumentVersion([folderItemORM.relations.itemORM]))
    }
    else {
      const isSharedEditFolder = folderItemORM.relations.parentORM &&
       isSharedEditableFolder(folderItemORM.relations.parentORM)

      dispatch( isSharedEditFolder && folderItemORM.relations.parentORM ? sharedFolderActions.updateItems({
        folder: folderItemORM.relations.parentORM,
        folderItems: [folderItemORM],
        action: UpdateType.UPDATE_DOCUMENT_VERSION,
      })
        : folderActions.updateDocumentVersion([folderItemORM], false))
    }
    toggleModal()
  }

  return (
    <DNAModal>
      <DNAModal.Header>Update File</DNAModal.Header>
      <DNAModal.Body>
        <DNABox appearance="col" spacing="md">
          <DNAText bold>
            {documentVersionORM?.model.title}
          </DNAText>
          <DNABox style={{ maxWidth: 560 }} appearance="col" spacing="sm">
            <DNAText>RELEASE NOTES</DNAText>
            <DNAText>
              {/* eslint-disable-next-line max-len */}
              {documentVersionORM?.relations.documentORM.relations.version.latestPublishedDocumentVersionORM?.model.releaseNotes}
            </DNAText>
          </DNABox>
        </DNABox>
      </DNAModal.Body>
      <DNAModal.Confirmation
        onCancel={toggleModal}
        onAction={onAction}
        actionLabel="Update"
        actionStatus="success"
      />
    </DNAModal>
  )
}

export default DNADocumentVersionUpdateModal
