import { DNADivider } from '@alucio/lux-ui'
import { DNAFlatListRows, DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'
import { DocumentORM, FolderItemORM, FolderORM } from 'src/types/types'
import DNAFileDrawerDefaultRow from './DNAFileDrawerDefaultRow'

export const DNADefaultRows: DNAFlatListRows = {
  DNAFileDrawerRow: DNAFileDrawerDefaultRow,
  //  TODO BEAC-1954 this new variant does not need the varints below but in DNAFlatListRows it is required! We should refactor to not require unwnated variants
  DNADocumentRow: undefined as unknown as DNARowsComponent<DocumentORM, {}>,
  DNADocumentFolderItemRow: undefined as unknown as DNARowsComponent<FolderItemORM, {}>,
  DNADocumentFolderItemCustomDeckRow: undefined as unknown as DNARowsComponent<FolderItemORM, {}>,
  DNAFolderRow: undefined as unknown as DNARowsComponent<FolderORM, {}>,
  DNAListDivider: DNADivider,
}

export default DNADefaultRows
