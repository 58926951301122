import React from 'react'
import { DNAText } from '@alucio/lux-ui'
import { useContent } from 'src/state/context/ContentProvider/ContentProvider'
import { SidebarOptions, tabConfigs } from 'src/components/ContentPreviewModalV2/SideBar/SideBar';
import SidebarContent from 'src/components/ContentPreviewModalV2/SideBar/SideBarContent';

const SlideNotes: React.FC = () => {
  const { activePresentation, contentPageData } = useContent()
  const speakerNotes = activePresentation?.currentPresentablePage
    ? contentPageData[activePresentation?.currentPresentablePage.presentationPageNumber - 1]?.speakerNotes : undefined
  return (
    <SidebarContent>
      <SidebarContent.Header
        sidebarHeader={tabConfigs[SidebarOptions.speakerNotesSidebar].label}
      />
      <SidebarContent.Body>
        <DNAText style={{ maxWidth: 280 }}>
          {speakerNotes ?? 'No slide notes'}
        </DNAText>
      </SidebarContent.Body>
    </SidebarContent>
  )
}

export default SlideNotes
