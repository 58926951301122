import React, { useEffect, useState } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useAppSettings } from 'src/state/context/AppSettings'
import { DNAText, DNABox, Icon, DNAIcon, DNAIconStyleProps } from '@alucio/lux-ui'
import { RootState } from 'src/state/redux'
import { useSelector } from 'react-redux'
import { getZendeskTokenPayload } from 'src/utils/zendeskHelpers'
import { ZENDESK_FEATURE } from '@alucio/aws-beacon-amplify/src/API'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

const sharedStyles = StyleSheet.create({
  notificationIcon: {
    width: 8,
    height: 8,
    color: colors['color-danger-500'],
  },
})

export const useChatButtonSharedResources = () => {
  const { isOnline } = useAppSettings();
  const { isOpen:CPMisOpen } = useSelector((state: RootState) => state.contentPreviewModal)
  const [unreadMessages, setUnreadMessages] = useState<boolean>()
  const openChat = () => {
    // @ts-ignore
    zE('messenger', 'open');
  }

  /** TODO: Consider moving some of this stuff to a more central/general
 * zendesk related management facility such as a context or hook */
  useEffect(() => {
    const updatedIndex = CPMisOpen ? -1 : 999999
    // @ts-ignore
    isOnline && zE('messenger:set', 'zIndex', updatedIndex);
  }, [CPMisOpen, isOnline])

  /** Component init */
  useEffect(() => {
    /** Login User */
    // @ts-ignore
    isOnline && zE('messenger', 'loginUser', async (cb) => {
      const payload = await getZendeskTokenPayload(ZENDESK_FEATURE.CHAT)
      cb(payload.token)
    });

    /** Setup unread message event handler */
    /** TODO: Identify approach for cleaning up this handler. It is currently causing a memory leak when logging out and back in again. */
    // @ts-ignore
    isOnline && zE('messenger:on', 'unreadMessages', (count) => {
      setUnreadMessages(count > 0)
    })

    /** On component destroy, logout the user */
    return () => {
      // @ts-ignore
      isOnline && zE('messenger', 'logoutUser')
    }
  }, [isOnline])

  return {
    openChat,
    unreadMessages,
  }
}

const deviceModeButtonProps:DNAIconStyleProps = {
  desktop: {
    padding:'sm',
    name:'chat-processing-outline',
    appearance:'ghost',
    status:'tertiary',
    size:'md',
  },
  tablet: {
    appearance:'ghostLink',
    padding:'none',
    rounded:'full',
    status:'dark',
    size:'xl',
    name:'chat-outline',
  },
}

const ChatButton:React.FC = () => {
  const { openChat, unreadMessages } = useChatButtonSharedResources()
  const { deviceMode } = useAppSettings()
  const { notificationIcon } = sharedStyles
  const currentDeviceModeButtonProps = deviceModeButtonProps[deviceMode]

  return (
    <TouchableOpacity
      onPress={() => openChat()}
      activeOpacity={1}
      testID="account-chat-button"
    >
      <DNABox
        style={{ padding: 12 }}
        alignY="center"
        spacing={!unreadMessages ? 'sm' : 'xs'}
      >
        <DNABox>
          <DNAIcon.Styled
            {...currentDeviceModeButtonProps}
          />
          {unreadMessages && <Icon name={'record'} style={notificationIcon}/>}
        </DNABox>
        <DNAText>Chat support</DNAText>
      </DNABox>
    </TouchableOpacity>

  )
}

export default ChatButton
