import { DNABox, DNAButton, DNAText, Iffy } from '@alucio/lux-ui';
import React from 'react';
import { StyleSheet } from 'react-native';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { Tab } from '../PresentationControls';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import SvgCalendarEditOutline from 'src/assets/icons/CalendarEditOutline';
import SvgFileDocumentMultipleOutline from 'src/assets/icons/FileDocumentMultipleOutline';
import SvgFileArrowLeftRightOutline from 'src/assets/icons/FileArrowLeftRightOutline';

const styles = StyleSheet.create({
  sideBar: {
    backgroundColor: colors['color-gray-800'],
    borderColor: colors['color-gray-600'],
    borderRightWidth: 1,
    width: 64,
    paddingTop: 25,
  },
  presentationNumber: {
    borderRadius: 3,
    paddingHorizontal: 6,
    paddingVertical: 3,
    backgroundColor: colors['color-success-500'],
    width: 15,
    height: 20,
    position: 'absolute',
    zIndex: 1,
    top: 5,
    right: 25,
  },
  iconStyle: {
    width: 28,
    height: 28,
  },
})

const SidebarButtons = () => {
  const {
    setCurrentTab,
    currentTab,
    setPresentationControlsVisible,
    checkFormDiscard,
    presentationControlsVisible,

  } = useMeetingsState();

  const { presentations } = useContent();

  const getCommonButtonProps = (tab) : DNAButtonProps => ({
    status: isButtonToggle(tab) ? 'tertiary' : 'dark',
    appearance: isButtonToggle(tab) ? 'filled' : 'ghost',
    iconStyle: styles.iconStyle,
    colorMode: 'dark',
    padding: 'md',
    size: 'md',
    onPress: () => onSelectTab(tab),
  });

  function onSelectTab(newTab: Tab): void {
    if (!presentationControlsVisible) setPresentationControlsVisible(true)
    newTab !== currentTab && checkFormDiscard(() => { setCurrentTab(newTab) })
  }

  const isButtonToggle = (tab: Tab) => currentTab === tab && presentationControlsVisible

  return (
    <DNABox
      alignX="center"
      appearance="col"
      style={styles.sideBar}
      spacing="lg"
    >
      <DNAPopover placement="left" disablePopover={['tabletPWA']}>
        <DNAPopover.Anchor>
          <DNAButton
            testID="browse-content-button"
            customIconRight={SvgFileDocumentMultipleOutline}
            {...getCommonButtonProps('BROWSE_CONTENT')}
          />
        </DNAPopover.Anchor>
        <DNAPopover.Content>
          <DNAText status="basic">
            Browse Content
          </DNAText>
        </DNAPopover.Content>
      </DNAPopover>
      <DNAPopover placement="left" disablePopover={['tabletPWA']}>
        <DNAPopover.Anchor>
          <DNABox alignX="center">
            <Iffy is={presentations.length}>
              <DNABox
                style={styles.presentationNumber}
                alignX="center"
                alignY="center"
                testID="presentations-length-container"
              >
                <DNAText status="basic" testID="presentations-length">
                  {presentations.filter((p) => !p.meta).length}
                </DNAText>
              </DNABox>
            </Iffy>
            <DNAButton
              {...getCommonButtonProps('OPENED_PRESENTATIONS')}
              customIconRight={SvgFileArrowLeftRightOutline}
            />
          </DNABox>
        </DNAPopover.Anchor>
        <DNAPopover.Content>
          <DNAText status="basic">
            Opened Files
          </DNAText>
        </DNAPopover.Content>
      </DNAPopover>
      <DNAPopover placement="left" disablePopover={['tabletPWA']}>
        <DNAPopover.Anchor>
          <DNAButton
            {...getCommonButtonProps('MEETING_DETAILS')}
            customIconRight={SvgCalendarEditOutline}
          />
        </DNAPopover.Anchor>
        <DNAPopover.Content>
          <DNAText status="basic">
            Meeting Details
          </DNAText>
        </DNAPopover.Content>
      </DNAPopover>
    </DNABox>
  );
};

export default SidebarButtons
