import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAText, Iffy, util } from '@alucio/lux-ui/src';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { HubSectionType } from '@alucio/aws-beacon-amplify/src/models';
import {
  WidgetContentCardProps,
  WidgetCardHeader,
  WidgetCardBody,
  WidgetContextMenu,
  widgetContentCardStyles,
} from 'src/screens/Hubs/EditHub/Widgets/WidgetContentCard';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import ContentSearchBar from 'src/components/ContentSearchBar/ContentSearchBar';
import SharedFilesDocumentGroup from 'src/screens/Hubs/EditHub/Widgets/SharedFilesComponents/SharedFilesDocumentGroup';
import { SharedFilesProvider, useSharedFilesState } from './SharedFilesComponents/SharedFilesProvider';

export const styles = StyleSheet.create({
  emptyStateTextBox: {
    marginTop: 24,
  },
  documentGroupsContainer: {
    borderColor: colors['color-gray-80'],
    borderWidth: 1,
  },
  associatedFilesGroupContainer: {
    paddingLeft: 24,
    paddingBottom: 24,
  },
  associatedFilesGroupBorderBox: {
    borderColor: colors['color-gray-80'],
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
  },
  bottomBorder: {
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
  },
  associatedFilesGroupHeader: {
    paddingHorizontal: 16,
    paddingVertical: 4,
    backgroundColor: colors['color-gray-10'],
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
  },
  row: {
    padding: 24,
  },
  rowHovered: {
    backgroundColor: colors['color-gray-10'],
  },
  documentThumbnailBorder: {
    borderColor: colors['color-gray-80'],
    borderWidth: 1,
    marginRight: 16,
    maxWidth: 122,
  },
  attachedFileIcon: {
    height: 30,
    color: colors['color-gray-300']
  },
  attachedFileThumbnailBox: {
    minWidth: 120,
    minHeight: 65,
    backgroundColor: colors['color-gray-10'],
  },
  errMsgContainer: {
    marginTop: 5,
  },
});

const SharedFiles: React.FC = () => {
  const { showFullSizeBtn } = useHubsState()
  const {
    widget,
    activeItems,
    selectedIds,
    hasReachedMaxNum,
    errorMsg,
    isSearchBarVisible,
    setIsSearchBarVisible,
    handleShowSearchBar,
    handleSelectItem,
    handleDeleteItem,
  } = useSharedFilesState()

  const rightElement = useMemo(() => (
    <DNABox alignY="center" spacing="sm">
      <DNAButton
        testID='hub-add-file-btn'
        iconLeft="plus"
        appearance="outline"
        status="tertiary"
        onPress={handleShowSearchBar}
        children={showFullSizeBtn ? 'Add file' : undefined}
        style={util.mergeStyles(
          undefined,
          [widgetContentCardStyles.contextMenuAnchorBtn, !showFullSizeBtn],
        )}
      />
      <WidgetContextMenu widgetType={HubSectionType.SHARED_FILES} id={widget.id} />
    </DNABox>
  ), [handleShowSearchBar, showFullSizeBtn])

  if (widget.type !== HubSectionType.SHARED_FILES) return null
  return (       
    <>
      <WidgetCardHeader
        widget={widget}
        rightElement={rightElement}
      />
      <WidgetCardBody>
        <ContentSearchBar
          onSelectItem={handleSelectItem}
          selectedIds={selectedIds}
          variant="HUBS"
          isVisible={isSearchBarVisible}
          setIsVisible={setIsSearchBarVisible}
          disable={hasReachedMaxNum}
        />
        <Iffy is={isSearchBarVisible && errorMsg.length}>
          <DNABox style={styles.errMsgContainer}>
            {errorMsg.map(err => <DNAText status="danger">{err}</DNAText>)}
          </DNABox>
        </Iffy>
        <DNABox fill appearance="col" alignY="center" style={isSearchBarVisible ? styles.emptyStateTextBox : undefined}>
          <Iffy is={!selectedIds.length}>
            <DNAText status="flatAlt">Add files to see them appear here</DNAText>
          </Iffy>
          <Iffy is={activeItems.length}>
            <DNABox testID='shared-files-document-item' fill appearance="col" style={styles.documentGroupsContainer}>
              {activeItems.map((sharedFile, index) => (
                <SharedFilesDocumentGroup
                  key={sharedFile.id}
                  sharedFile={sharedFile}
                  isLastItem={index === activeItems.length - 1}
                  handleDeleteItem={handleDeleteItem}
                />
              ))}
            </DNABox>
          </Iffy>
        </DNABox>
      </WidgetCardBody>
    </>
  )
}

const SharedFilesWrapper: React.FC<WidgetContentCardProps> = (props) => {
  return (
    <SharedFilesProvider {...props}>
      <SharedFiles />
    </SharedFilesProvider>
  )
}

export default SharedFilesWrapper
