// [TODO] - Not sure if anything is using this Row Variant at the moment
import React from 'react'
import { DocumentVersionORM, FolderItemORM } from 'src/types/types'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'
import { DocumentStatus, FileType } from '@alucio/aws-beacon-amplify/src/models';
import { StyleSheet } from 'react-native';

import {
  DNABox, DNACard, DNAText,
  Iffy, useTheme, DNAChip, luxColors,
} from '@alucio/lux-ui'
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage';
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';

export type DNADocumentVersionMeetingRowProps = {
  isOnline?: boolean,
}

const styles = StyleSheet.create({
  bookmarkButtonPWAWrapper: {
    marginRight: 10,
    paddingRight: 30,
    width: 22,
  },
  containerStyle: {
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: 4,
    marginVertical: 4,
  },
});

const DNADocumentFolderItemMeetingRow: DNARowsComponent<
  FolderItemORM,
  DNADocumentVersionMeetingRowProps
> = ({
  ORM: folderItem,
  onPress,
  style,
  isDesktop = true,
  isOnline,
}) => {
  const theme = useTheme()
  const { meetingORM } = useMeetingsState();
  // [TODO] - I think we need to revisit this? A Folder Item should not be limited to a DocVerORM
  /** TODO: Refactor improper use of as assertion */
  const documentVersionORM = (folderItem.relations.itemORM as DocumentVersionORM)
  const route = useCurrentPage({ exact: false })
  const { status: docStatus } = documentVersionORM.relations.documentORM.model
  const { visiblePages } = folderItem.model
  const isModified =
    visiblePages?.length &&
    documentVersionORM.meta.permissions.MSLSelectSlides &&
    documentVersionORM.model.status === 'PUBLISHED'
  const isVirtualMeeting = meetingORM?.model.type === 'VIRTUAL'
  const isWebDoc = documentVersionORM.model.type === FileType.WEB
  const isHTMLDoc = documentVersionORM.model.type === FileType.HTML

  /** [TODO-ORM] - We should have a property that combines isOnline and isContentCached on the
   * DocumentVersionORM rather than checking both at the component level */
  const unavailableContent =
    (!isOnline && !documentVersionORM.meta.assets.isContentCached) ||
    (isWebDoc && (isVirtualMeeting || !isOnline)) ||
    (isHTMLDoc && isVirtualMeeting)

  const isDisabledHTMLorWebDocument =
    (isWebDoc && isVirtualMeeting) ||
    (isHTMLDoc && isVirtualMeeting)

  const isInteractive = documentVersionORM.meta.permissions.MSLPresent && !unavailableContent;
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const handleOnPress = () => {
    onPress?.(folderItem)
  }

  // If the publisher's selected cover page was hidden by the user
  // we use the first visible page
  // [TODO-2126] - We should be able calculate this at the ORM level instead
  let customThumbnailKey: string | undefined
  const selectedThumbnail = documentVersionORM.model.selectedThumbnail ?? 1
  if (folderItem.model.visiblePages && !folderItem.model.visiblePages.find(vp => vp === selectedThumbnail)) {
    const customPage = documentVersionORM.meta.allPages[folderItem.model.visiblePages[0] - 1]
    customThumbnailKey = detectArchivedFileKeyPath(documentVersionORM.model, customPage)
  }

  return (
    <DNACard
      appearance="flat"
      interactive={isInteractive ? 'pressable' : undefined}
      onPress={handleOnPress}
      style={[
        {
          paddingHorizontal: theme['row-padding-horizontal'],
          paddingVertical: theme['row-padding-vertical'],
        },
        style,
        styles.containerStyle,
        !isInteractive && { opacity: 0.4 },
      ]}
    >
      <DNAPopover disablePopover={!isDisabledHTMLorWebDocument} placement="top-start">
        <DNAPopover.Anchor>
          <DNABox
            testID="folder-item-row"
            alignY="center"
            spacing={isDesktop ? 'between' : 'md'}
            childFill={1}
          >
            <DNABox
              spacing="lg"
              alignY="center"
              childFill={1}
            >
              <DNADocumentThumbnail
                documentVersionORM={documentVersionORM}
                width={76}
                height={44}
                unavailableContent={unavailableContent}
                customThumbnailKey={customThumbnailKey}
              />
              {/* Title */}
              <DNABox
                spacing="sm"
                appearance="col"
                childFill
                style={{ paddingRight: 16 }}
              >
                <DNABox fill style={{ width: 580 }}>
                  <DNAText testID="document-item-title" b1 numberOfLines={1} status="basic">
                    {folderItem.meta.title}
                  </DNAText>
                </DNABox>
                <DNABox
                  alignY="center"
                  spacing="sm"
                  fill
                  childStyle={[4, { flex: 1 }]}
                >
                  <DNABox appearance="row" spacing="sm">
                    <CustomFieldBadgeList documentVersionORM={documentVersionORM} />
                    <DNAChip appearance="tag">
                      { documentVersionORM.model.type }
                    </DNAChip>
                    <Iffy is={isPublisherRoute}>
                      <DNABox style={{ marginHorizontal: 8 }}>
                        <DNADocumentChip
                          item={documentVersionORM.relations.documentORM}
                          variant="status"
                        />
                      </DNABox>
                    </Iffy>
                  </DNABox>
                  <Iffy is={docStatus === DocumentStatus.ARCHIVED}>
                    <DNAChip appearance="tag">
                      {docStatus}
                    </DNAChip>
                  </Iffy>
                  <Iffy is={isModified}>
                    <DNAChip appearance="tag">
                      MODIFIED
                    </DNAChip>
                  </Iffy>
                  <Iffy is={unavailableContent}>
                    <DNAChip
                      appearance="tag"
                      style={{ backgroundColor: luxColors.basicBlack.primary }}
                      testID={`content-not-available-${folderItem.meta.title}`}
                    >
                      CONTENT NOT AVAILABLE
                    </DNAChip>
                  </Iffy>
                </DNABox>
              </DNABox>
            </DNABox>

          </DNABox>
        </DNAPopover.Anchor>
        <DNAPopover.Content>
          <DNAText status="basic">WEB and HTML file types are not presentable in virtual meetings.</DNAText>
        </DNAPopover.Content>
      </DNAPopover>
    </DNACard>
  );
}

export default DNADocumentFolderItemMeetingRow
