import React from 'react'
import { DNABox, DNAText } from '@alucio/lux-ui'
import { S } from '..'

const ServiceWorker: React.FC = () => {
  // [TODO]: Implement
  return (
    <DNABox fill appearance="col" style={S.tabContainer}>
      <DNAText>Installed Version</DNAText>
      <DNAText>Number of Startups</DNAText>
    </DNABox>
  )
}

export default ServiceWorker
