/* eslint-disable max-len */
import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgHome = (props: SvgProps) => (
  <Svg
    width={32}
    height={32}
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M29 23h-2V11.444a2 2 0 0 0-.646-1.473l-10-9.435a2 2 0 0 0-2.705-.014l-.014.014-9.989 9.435A2 2 0 0 0 3 11.444V23H1a1 1 0 1 0 0 2h28a1 1 0 0 0 0-2ZM5 11.444l.014-.013L15 2.001l9.988 9.428.013.012v11.56H19v-6a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v6H5V11.443ZM17 23h-4v-6h4v6Z"
      fill={props.color ?? '#fff'}
    />
  </Svg>
);
export default SvgHome;
