import React, { PropsWithChildren, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { DNABox, DNAButton, Hoverable, Iffy, luxColors } from '@alucio/lux-ui';

const styles = StyleSheet.create({
  overlay: {
    alignItems: 'center',
    backgroundColor: luxColors.opacityBackground.primary,
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },
  presentButton: {
    alignItems: 'center',
    backgroundColor: luxColors.thumbnailBorder.primary,
    borderRadius: 50,
    height: 45,
    justifyContent: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    width: 100,
  },
  presentText: {
    color: luxColors.tag.primary,
    fontSize: 15,
    fontWeight: 'bold',
  },
});

interface Props {
  bypassWrapper?: boolean;
  itemHeight: number;
  onPresent?: () => void;
  onPreview?: () => void;
  showPreview: boolean;
}

const Wrapper: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, itemHeight, onPresent, onPreview, showPreview } = props;
  const [isHovered, setIsHovered] = useState<boolean>(false);

  function toggleHover(): void {
    setIsHovered(!isHovered);
  }

  if (props.bypassWrapper) {
    return <>{children}</>;
  }

  return (
    <Hoverable onHoverIn={toggleHover} onHoverOut={toggleHover}>
      <View>
        {children}
        <Iffy is={isHovered}>
          <DNABox spacing="sm" appearance="col" style={[styles.overlay, { height: itemHeight + 4 }]}>
            <DNAButton
              size="sm"
              iconLeft="play"
              onPress={onPresent}
              testID="meeting-present-button"
            >
              Present
            </DNAButton>
            <Iffy is={!!showPreview}>
              <DNAButton
                size="sm"
                appearance="filled"
                iconLeft="eye-outline"
                onPress={onPreview}
                status="tertiary"
              >
                Preview
              </DNAButton>
            </Iffy>
          </DNABox>
        </Iffy>
      </View>
    </Hoverable>
  );
};

export default Wrapper;
