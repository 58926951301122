import { useEffect, useState } from 'react';
import {
  LuxStatus,
  LuxStatusEnum,
} from '@alucio/lux-ui';
import { GLOBAL_FORM_ERROR, useAddMeeting } from './AddMeetingProvider';
import { FormValuesType } from '../CustomFields/ComposableForm';

// Define an enum for the different syncing statuses
enum SYNCING_STATUS {
    IDLE = 'Sync to CRM',
    SYNCING = 'Syncing...',
    SYNCED = 'Synced'
  }

// Define an interface for the different button states
export interface SyncMetaType {
    [x: string]: {
      color: LuxStatus,
      icon?: string,
      disabled?: boolean,
      text?: string,
    }
  }

// Define an object that maps the syncing statuses to button states
const SYNCING_BUTTON_META: SyncMetaType = {
  [SYNCING_STATUS.IDLE]: {
    color: LuxStatusEnum.primary,
    icon: undefined,
    text: 'Save',
  },
  [SYNCING_STATUS.SYNCING]: {
    color: LuxStatusEnum.primary,
    icon: 'sync',
    disabled: true,
    text: 'Saving...',
  },
  [SYNCING_STATUS.SYNCED]: {
    color: LuxStatusEnum.success,
    icon: 'check',
    disabled: true,
    text: 'Saved',
  },
};

// Define a custom hook that returns the current button state based on the isSubmitting flag
export const useSubmitButtonStatus = () => {
  const [submitStatus, setSubmitStatus] = useState<SYNCING_STATUS>(SYNCING_STATUS.IDLE)
  const { isSubmitting } = useAddMeeting()

  useEffect(() => {
    const syncStepDuration = 1500;

    // If we're not submitting, return early
    if (!isSubmitting) {
      return;
    }

    // Set the button state to "Syncing..."
    setSubmitStatus(SYNCING_STATUS.SYNCING);

    // Set a timer to change the button state to "Synced" after a delay
    const syncedTimer = setTimeout(() => {
      setSubmitStatus(SYNCING_STATUS.SYNCED);

      // Set a timer to change the button state back to "Sync to CRM" after a delay
      const idleTimer = setTimeout(() => {
        setSubmitStatus(SYNCING_STATUS.IDLE);
        clearTimeout(idleTimer);
      }, syncStepDuration);

      clearTimeout(syncedTimer);
    }, syncStepDuration);
  }, [isSubmitting]);

  // Return the current button state based on the submitStatus variable
  return SYNCING_BUTTON_META[submitStatus]
}

const cleanValues = (values: any): FormValuesType => {
  const cleanedValues: any = Array.isArray(values) ? [] : {};
  Object.keys(values).forEach((key) => {
    const value = values[key];
    if (typeof value === 'object' && value !== null) {
      const cleanedChild = cleanValues(value);
      if (Array.isArray(cleanedChild) && cleanedChild.length === 0) {
        return;
      }
      if (typeof cleanedChild === 'string' && cleanedChild === '') {
        return;
      }
      if (Array.isArray(values)) {
        cleanedValues.push(cleanedChild);
      } else {
        cleanedValues[key] = cleanedChild;
      }
    } else {
      if (Array.isArray(value) && value.length === 0) {
        return;
      }
      if (value === '') {
        return;
      }
      if (Array.isArray(values)) {
        cleanedValues.push(value);
      } else {
        cleanedValues[key] = value;
      }
    }
  });
  return cleanedValues;
};

/**
 * This function removes empty fields from the provided form values.
 * It uses the `cleanValues` function to recursively clean the values.
 * Empty fields are considered as:
 * - Empty arrays
 * - Empty strings
 * - Null or undefined values
 * - Objects with all properties as empty fields
 *
 * @param {FormValuesType} values - The form values to clean.
 * @returns {FormValuesType} The cleaned form values with no empty fields.
 */
export const removeEmptyFields = (values: FormValuesType): FormValuesType => {
  const cleanedValues = cleanValues(values);
  return cleanedValues;
}

export const consolidateFormErrorMessages = (errors: { [s: string]: unknown; } | ArrayLike<unknown>): string => {
  const consolidatedFormErrors = [...new Set(
    Object
      .values(errors)
      .map(err => {
        // Non-nested errors
        if ((err as { message?: string })?.message === GLOBAL_FORM_ERROR.invalid_date) {
          return GLOBAL_FORM_ERROR.invalid_date;
        } else if (typeof (err as { type: string })?.type === 'string') {
          return GLOBAL_FORM_ERROR[(err as { type: string }).type];
        }
        else {
          return GLOBAL_FORM_ERROR.invalid_type;
        }
      })
      .flat(),
  )].join('\n');
  return consolidatedFormErrors;
}
