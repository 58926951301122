export const schema = {
    "models": {
        "Folder": {
            "name": "Folder",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "isPinned": {
                    "name": "isPinned",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "FolderStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "FolderItem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "sharePermissions": {
                    "name": "sharePermissions",
                    "isArray": true,
                    "type": {
                        "nonModel": "SharePermission"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "shareStatus": {
                    "name": "shareStatus",
                    "isArray": false,
                    "type": {
                        "enum": "ShareStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Folders",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByTenantStatusShared",
                        "queryField": "foldersByTenantStatusShared",
                        "fields": [
                            "tenantId",
                            "status",
                            "shareStatus"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "createdBy",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "update",
                                    "read"
                                ],
                                "identityClaim": "custom:user_id"
                            }
                        ]
                    }
                }
            ]
        },
        "CustomDeck": {
            "name": "CustomDeck",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "autoUpdateAcknowledgedAt": {
                    "name": "autoUpdateAcknowledgedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "groups": {
                    "name": "groups",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomDeckGroup"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "editMutex": {
                    "name": "editMutex",
                    "isArray": false,
                    "type": {
                        "nonModel": "EditMutex"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "CustomDecks",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByTenantIdCreatedBy",
                        "fields": [
                            "tenantId",
                            "createdBy"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "createdBy",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:user_id"
                            }
                        ]
                    }
                }
            ]
        },
        "Document": {
            "name": "Document",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "accessLevel": {
                    "name": "accessLevel",
                    "isArray": false,
                    "type": {
                        "enum": "DocumentAccessLevel"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "DocumentStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "owner": {
                    "name": "owner",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "expiresAt": {
                    "name": "expiresAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "integration": {
                    "name": "integration",
                    "isArray": false,
                    "type": {
                        "nonModel": "DocumentIntegration"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "integrationType": {
                    "name": "integrationType",
                    "isArray": false,
                    "type": {
                        "enum": "IntegrationType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "FileType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "editPermissions": {
                    "name": "editPermissions",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Documents",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByTenantIdStatus",
                        "fields": [
                            "tenantId",
                            "status"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "editPermissions",
                                "operations": [
                                    "read",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "tenantId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:org_id"
                            }
                        ]
                    }
                }
            ]
        },
        "DocumentVersion": {
            "name": "DocumentVersion",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "documentId": {
                    "name": "documentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "versionNumber": {
                    "name": "versionNumber",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "srcFilename": {
                    "name": "srcFilename",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "contentURL": {
                    "name": "contentURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "conversionStatus": {
                    "name": "conversionStatus",
                    "isArray": false,
                    "type": {
                        "enum": "ConversionStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "DocumentStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "srcFile": {
                    "name": "srcFile",
                    "isArray": false,
                    "type": {
                        "nonModel": "S3Object"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "srcHash": {
                    "name": "srcHash",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "srcSize": {
                    "name": "srcSize",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "numPages": {
                    "name": "numPages",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "pageSettings": {
                    "name": "pageSettings",
                    "isArray": true,
                    "type": {
                        "nonModel": "PageSetting"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "pageGroups": {
                    "name": "pageGroups",
                    "isArray": true,
                    "type": {
                        "nonModel": "PageGroup"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "FileType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "releaseNotes": {
                    "name": "releaseNotes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "changeType": {
                    "name": "changeType",
                    "isArray": false,
                    "type": {
                        "enum": "DocumentVersionChangeType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "labelValues": {
                    "name": "labelValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "LabelValue"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "customValues": {
                    "name": "customValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomValues"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "shortDescription": {
                    "name": "shortDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "longDescription": {
                    "name": "longDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "owner": {
                    "name": "owner",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "expiresAt": {
                    "name": "expiresAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "hasCopyright": {
                    "name": "hasCopyright",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "hasCustomThumbnail": {
                    "name": "hasCustomThumbnail",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "purpose": {
                    "name": "purpose",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "canHideSlides": {
                    "name": "canHideSlides",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "distributable": {
                    "name": "distributable",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "downloadable": {
                    "name": "downloadable",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "isInternalGenerated": {
                    "name": "isInternalGenerated",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "semVer": {
                    "name": "semVer",
                    "isArray": false,
                    "type": {
                        "nonModel": "SemVer"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "notificationScope": {
                    "name": "notificationScope",
                    "isArray": false,
                    "type": {
                        "enum": "NotificationScope"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "selectedThumbnail": {
                    "name": "selectedThumbnail",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "publishedAt": {
                    "name": "publishedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "uploadedAt": {
                    "name": "uploadedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "uploadedBy": {
                    "name": "uploadedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "convertedArchiveKey": {
                    "name": "convertedArchiveKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "convertedArchiveSize": {
                    "name": "convertedArchiveSize",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "convertedFolderKey": {
                    "name": "convertedFolderKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "associatedFiles": {
                    "name": "associatedFiles",
                    "isArray": true,
                    "type": {
                        "nonModel": "AssociatedFile"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "editPermissions": {
                    "name": "editPermissions",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "converterVersion": {
                    "name": "converterVersion",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integration": {
                    "name": "integration",
                    "isArray": false,
                    "type": {
                        "nonModel": "DocumentVersionIntegration"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "integrationType": {
                    "name": "integrationType",
                    "isArray": false,
                    "type": {
                        "enum": "IntegrationType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "conversionWarningCode": {
                    "name": "conversionWarningCode",
                    "isArray": false,
                    "type": {
                        "enum": "ConversionWarningCode"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "conversionErrorCode": {
                    "name": "conversionErrorCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "scheduledPublish": {
                    "name": "scheduledPublish",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "mappingStatus": {
                    "name": "mappingStatus",
                    "isArray": false,
                    "type": {
                        "enum": "MappingStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "DocumentVersions",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByTenantIdStatus",
                        "queryField": "documentVersionsByTenantIdStatus",
                        "fields": [
                            "tenantId",
                            "status"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByDocumentIdVersionNumber",
                        "queryField": "documentVersionsByDocumentIdVersionNumber",
                        "fields": [
                            "documentId",
                            "versionNumber"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "editPermissions",
                                "operations": [
                                    "read",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "tenantId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:org_id"
                            }
                        ]
                    }
                }
            ]
        },
        "Tenant": {
            "name": "Tenant",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "licensedUsers": {
                    "name": "licensedUsers",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "TenantStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "fields": {
                    "name": "fields",
                    "isArray": true,
                    "type": {
                        "nonModel": "FieldConfig"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "folderUpdateGracePeriodDays": {
                    "name": "folderUpdateGracePeriodDays",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "medInfoURL": {
                    "name": "medInfoURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "publisherListConfig": {
                    "name": "publisherListConfig",
                    "isArray": false,
                    "type": {
                        "nonModel": "ListConfiguration"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "mslListConfig": {
                    "name": "mslListConfig",
                    "isArray": false,
                    "type": {
                        "nonModel": "ListConfiguration"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "featureFlags": {
                    "name": "featureFlags",
                    "isArray": true,
                    "type": {
                        "nonModel": "FeatureFlags"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "statusChangedAt": {
                    "name": "statusChangedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "statusChangedBy": {
                    "name": "statusChangedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "ssoDomains": {
                    "name": "ssoDomains",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "integrations": {
                    "name": "integrations",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "config": {
                    "name": "config",
                    "isArray": false,
                    "type": {
                        "nonModel": "TenantConfig"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Tenants",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "AlucioAdmin"
                                ],
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "id",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:org_id"
                            }
                        ]
                    }
                }
            ]
        },
        "AttachedFile": {
            "name": "AttachedFile",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "documentId": {
                    "name": "documentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "srcFile": {
                    "name": "srcFile",
                    "isArray": false,
                    "type": {
                        "nonModel": "S3Object"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "srcFileName": {
                    "name": "srcFileName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "srcHash": {
                    "name": "srcHash",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "srcSize": {
                    "name": "srcSize",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "FileType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "editPermissions": {
                    "name": "editPermissions",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "AttachedFiles",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByTenantId",
                        "fields": [
                            "tenantId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "editPermissions",
                                "operations": [
                                    "read",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "tenantId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:org_id"
                            }
                        ]
                    }
                }
            ]
        },
        "ContentShare": {
            "name": "ContentShare",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "token": {
                    "name": "token",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "ShareType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "contentId": {
                    "name": "contentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "meetingId": {
                    "name": "meetingId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "expiresAt": {
                    "name": "expiresAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customValues": {
                    "name": "customValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomValues"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "revoked": {
                    "name": "revoked",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "customDeckDependencies": {
                    "name": "customDeckDependencies",
                    "isArray": false,
                    "type": {
                        "nonModel": "CustomDeckDependencies"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "notificationScope": {
                    "name": "notificationScope",
                    "isArray": false,
                    "type": {
                        "enum": "ShareNotificationScope"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": false,
            "pluralName": "ContentShares",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByToken",
                        "queryField": "contentShareByToken",
                        "fields": [
                            "token"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByContentID",
                        "queryField": "contentShareByContentID",
                        "fields": [
                            "contentId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "createdBy",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "custom:user_id"
                            }
                        ]
                    }
                }
            ]
        },
        "User": {
            "name": "User",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cognitoSubId": {
                    "name": "cognitoSubId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "givenName": {
                    "name": "givenName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "phoneNumber": {
                    "name": "phoneNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "familyName": {
                    "name": "familyName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "meetingId": {
                    "name": "meetingId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "defaultFiltersCustomValues": {
                    "name": "defaultFiltersCustomValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomValues"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "lockedFiltersCustomValues": {
                    "name": "lockedFiltersCustomValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomValues"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "defaultFilterValues": {
                    "name": "defaultFilterValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "LabelValues"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "lockedFilters": {
                    "name": "lockedFilters",
                    "isArray": true,
                    "type": {
                        "nonModel": "LabelValues"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "shareBCC": {
                    "name": "shareBCC",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "shareCC": {
                    "name": "shareCC",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "UserStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "role": {
                    "name": "role",
                    "isArray": false,
                    "type": {
                        "enum": "UserRole"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "bookmarkedDocs": {
                    "name": "bookmarkedDocs",
                    "isArray": true,
                    "type": {
                        "nonModel": "BookmarkedDoc"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "isExcludedFromReporting": {
                    "name": "isExcludedFromReporting",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "additionalRoles": {
                    "name": "additionalRoles",
                    "isArray": true,
                    "type": {
                        "enum": "UserRole"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            },
            "syncable": true,
            "pluralName": "Users",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByTenantId",
                        "fields": [
                            "tenantId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByMeetingId",
                        "fields": [
                            "meetingId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "AlucioAdmin"
                                ],
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "tenantId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:org_id"
                            }
                        ]
                    }
                }
            ]
        },
        "EmailTemplate": {
            "name": "EmailTemplate",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "labelValues": {
                    "name": "labelValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "LabelValue"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "customFilterValues": {
                    "name": "customFilterValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomValues"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "subject": {
                    "name": "subject",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "cc": {
                    "name": "cc",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "bcc": {
                    "name": "bcc",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "body": {
                    "name": "body",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "associatedFiles": {
                    "name": "associatedFiles",
                    "isArray": true,
                    "type": {
                        "nonModel": "AssociatedFile"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "EmailTemplateStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "editPermissions": {
                    "name": "editPermissions",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "EmailTemplates",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "editPermissions",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "tenantId",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "custom:org_id"
                            }
                        ]
                    }
                }
            ]
        },
        "DocumentSetting": {
            "name": "DocumentSetting",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "documentId": {
                    "name": "documentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "documentVersionId": {
                    "name": "documentVersionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "notation": {
                    "name": "notation",
                    "isArray": true,
                    "type": {
                        "nonModel": "Notation"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "UserNotationsStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "DocumentSettings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "createdBy",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:user_id"
                            }
                        ]
                    }
                }
            ]
        },
        "UserNotations": {
            "name": "UserNotations",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "documentId": {
                    "name": "documentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "documentVersionId": {
                    "name": "documentVersionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "customDeckId": {
                    "name": "customDeckId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "notation": {
                    "name": "notation",
                    "isArray": true,
                    "type": {
                        "nonModel": "Notation"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "UserNotationsStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "UserNotationsType"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UserNotations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "createdBy",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "identityClaim": "custom:user_id"
                            }
                        ]
                    }
                }
            ]
        },
        "IntegrationLog": {
            "name": "IntegrationLog",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "integrationId": {
                    "name": "integrationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "log": {
                    "name": "log",
                    "isArray": true,
                    "type": {
                        "nonModel": "IntegrationLogEntry"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "IntegrationRunStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": false,
            "pluralName": "IntegrationLogs",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByIntegrationIdLog",
                        "fields": [
                            "tenantId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "AlucioAdmin"
                                ],
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "IntegrationSettings": {
            "name": "IntegrationSettings",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "syncContentEvery": {
                    "name": "syncContentEvery",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "mapping": {
                    "name": "mapping",
                    "isArray": true,
                    "type": {
                        "nonModel": "IntegrationFieldMapping"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "clientConfigurationFields": {
                    "name": "clientConfigurationFields",
                    "isArray": true,
                    "type": {
                        "nonModel": "IntegrationClientConfigurationTuple"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "integrationType": {
                    "name": "integrationType",
                    "isArray": false,
                    "type": {
                        "enum": "IntegrationType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "enabled": {
                    "name": "enabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "notificationEmail": {
                    "name": "notificationEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "errorSyncEmail": {
                    "name": "errorSyncEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": false,
            "pluralName": "IntegrationSettings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByTenantIdSetting",
                        "fields": [
                            "tenantId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "AlucioAdmin"
                                ],
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Meeting": {
            "name": "Meeting",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "startTime": {
                    "name": "startTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "endTime": {
                    "name": "endTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "contentPresented": {
                    "name": "contentPresented",
                    "isArray": true,
                    "type": {
                        "nonModel": "ContentPresented"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "MeetingType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "fieldValues": {
                    "name": "fieldValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "FieldValue"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "customValues": {
                    "name": "customValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomValues"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "notes": {
                    "name": "notes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "MeetingStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "attendees": {
                    "name": "attendees",
                    "isArray": true,
                    "type": {
                        "nonModel": "Attendee"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "crmRecord": {
                    "name": "crmRecord",
                    "isArray": false,
                    "type": {
                        "nonModel": "CRMMeetingRecordInfo"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Meetings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByCreatedByStartTime",
                        "queryField": "attendemeetingsByCreatedByStartTimeesByHostIdStatus",
                        "fields": [
                            "createdBy",
                            "startTime"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "createdBy",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "update",
                                    "read"
                                ],
                                "identityClaim": "custom:user_id"
                            }
                        ]
                    }
                }
            ]
        },
        "Hub": {
            "name": "Hub",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "HubStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "hubSections": {
                    "name": "hubSections",
                    "isArray": true,
                    "type": {
                        "nonModel": "HubSection"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "customValues": {
                    "name": "customValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomValues"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "meetingId": {
                    "name": "meetingId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Hubs",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByCreatedByByStatus",
                        "queryField": "hubsByOwnerByStatus",
                        "fields": [
                            "createdBy",
                            "status"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "createdBy",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "update",
                                    "read"
                                ],
                                "identityClaim": "custom:user_id"
                            }
                        ]
                    }
                }
            ]
        },
        "CustomFormRecord": {
            "name": "CustomFormRecord",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customFormId": {
                    "name": "customFormId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "entity": {
                    "name": "entity",
                    "isArray": false,
                    "type": {
                        "enum": "CustomFormRecordEntity"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "CustomFormRecordStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "crmFields": {
                    "name": "crmFields",
                    "isArray": false,
                    "type": {
                        "nonModel": "CustomFormRecordCRMFields"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "parentId": {
                    "name": "parentId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "parentModel": {
                    "name": "parentModel",
                    "isArray": false,
                    "type": {
                        "enum": "CustomFormRecordRelationshipModel"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "values": {
                    "name": "values",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomValues"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "CustomFormRecords",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByCreatedByByStatus",
                        "queryField": "customFormRecordByOwnerByStatus",
                        "fields": [
                            "createdBy",
                            "status"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "createdBy",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "update",
                                    "read"
                                ],
                                "identityClaim": "custom:user_id"
                            }
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "ShareType": {
            "name": "ShareType",
            "values": [
                "DOCUMENT_VERSION",
                "RECORDING",
                "ATTACHED_FILE",
                "HUB",
                "CUSTOM_DECK"
            ]
        },
        "PageGroupSource": {
            "name": "PageGroupSource",
            "values": [
                "USER",
                "DOCUMENT"
            ]
        },
        "NotationType": {
            "name": "NotationType",
            "values": [
                "CALLOUT",
                "PAGE_NOTE"
            ]
        },
        "HubSectionItemStatus": {
            "name": "HubSectionItemStatus",
            "values": [
                "ACTIVE",
                "DELETED"
            ]
        },
        "HubStatus": {
            "name": "HubStatus",
            "values": [
                "ACTIVE",
                "DELETED"
            ]
        },
        "HubSectionStatus": {
            "name": "HubSectionStatus",
            "values": [
                "ACTIVE",
                "DELETED"
            ]
        },
        "HubSectionType": {
            "name": "HubSectionType",
            "values": [
                "TEXTAREA",
                "SHARED_FILES",
                "TODO_LIST",
                "LINKS"
            ]
        },
        "HubSharedFileContentType": {
            "name": "HubSharedFileContentType",
            "values": [
                "DOCUMENT_VERSION"
            ]
        },
        "TodoStatus": {
            "name": "TodoStatus",
            "values": [
                "ACTIVE",
                "COMPLETED",
                "DELETED"
            ]
        },
        "FolderStatus": {
            "name": "FolderStatus",
            "values": [
                "ACTIVE",
                "ARCHIVED",
                "REMOVED"
            ]
        },
        "FolderItemType": {
            "name": "FolderItemType",
            "values": [
                "FOLDER",
                "DOCUMENT_VERSION",
                "CUSTOM_DECK"
            ]
        },
        "FolderItemStatus": {
            "name": "FolderItemStatus",
            "values": [
                "ACTIVE",
                "REMOVED"
            ]
        },
        "PermissionType": {
            "name": "PermissionType",
            "values": [
                "VIEW",
                "EDIT"
            ]
        },
        "ShareTargetType": {
            "name": "ShareTargetType",
            "values": [
                "USER",
                "FILTER",
                "ALL"
            ]
        },
        "CrmSyncStatus": {
            "name": "CrmSyncStatus",
            "values": [
                "SYNCED",
                "NOT_SYNCED",
                "DELETED"
            ]
        },
        "ObjectRecordStatus": {
            "name": "ObjectRecordStatus",
            "values": [
                "ACTIVE",
                "REMOVED"
            ]
        },
        "ShareStatus": {
            "name": "ShareStatus",
            "values": [
                "IS_SHARED",
                "NOT_SHARED"
            ]
        },
        "DocumentAccessLevel": {
            "name": "DocumentAccessLevel",
            "values": [
                "TENANT",
                "USER"
            ]
        },
        "DocumentStatus": {
            "name": "DocumentStatus",
            "values": [
                "ARCHIVED",
                "REVOKED",
                "NOT_PUBLISHED",
                "PUBLISHED",
                "DELETED",
                "ARCHIVED_WITH_VERSIONS",
                "REVOKED_WITH_VERSIONS",
                "DELETED_WITH_VERSIONS"
            ]
        },
        "IntegrationType": {
            "name": "IntegrationType",
            "values": [
                "VEEVA",
                "BEACON",
                "EXPORT_TO_S3",
                "NONE"
            ]
        },
        "FileType": {
            "name": "FileType",
            "values": [
                "JPG",
                "JPEG",
                "XLS",
                "XLSX",
                "XLSM",
                "DOC",
                "DOCX",
                "PDF",
                "PPTX",
                "PNG",
                "MP4",
                "WEB",
                "HTML",
                "ZIP"
            ]
        },
        "ConversionStatus": {
            "name": "ConversionStatus",
            "values": [
                "PENDING",
                "PROCESSING",
                "PROCESSED",
                "ERROR"
            ]
        },
        "DocumentVersionChangeType": {
            "name": "DocumentVersionChangeType",
            "values": [
                "MAJOR",
                "MINOR"
            ]
        },
        "NotificationScope": {
            "name": "NotificationScope",
            "values": [
                "NONE",
                "EMAIL",
                "EMAIL_IMMEDIATE"
            ]
        },
        "AssociatedFileType": {
            "name": "AssociatedFileType",
            "values": [
                "DOCUMENT",
                "ATTACHED_FILE"
            ]
        },
        "AssociatedFileStatus": {
            "name": "AssociatedFileStatus",
            "values": [
                "ACTIVE",
                "DELETED"
            ]
        },
        "ConversionWarningCode": {
            "name": "ConversionWarningCode",
            "values": [
                "PASSWORD_PROTECTED"
            ]
        },
        "MappingStatus": {
            "name": "MappingStatus",
            "values": [
                "HAS_MAPPINGS",
                "NO_MAPPINGS"
            ]
        },
        "ZendeskFeature": {
            "name": "ZendeskFeature",
            "values": [
                "CHAT",
                "SSO"
            ]
        },
        "FieldDataType": {
            "name": "FieldDataType",
            "values": [
                "CATEGORICAL",
                "MULTICATEGORICAL",
                "STRING",
                "DATE",
                "USER_LIST",
                "OBJECT",
                "NUMBER"
            ]
        },
        "TenantStatus": {
            "name": "TenantStatus",
            "values": [
                "ACTIVE",
                "INACTIVE"
            ]
        },
        "FieldStatus": {
            "name": "FieldStatus",
            "values": [
                "DISABLED",
                "ENABLED"
            ]
        },
        "FormatValidation": {
            "name": "FormatValidation",
            "values": [
                "EMAIL"
            ]
        },
        "ControlType": {
            "name": "ControlType",
            "values": [
                "SELECT",
                "RADIOLIST",
                "CHECKBOX",
                "CHECKBOXLIST",
                "CRMACCOUNTSEARCHER",
                "CRMATTENDEELIST",
                "INPUT",
                "TEXTAREA",
                "DATEPICKER",
                "USERLIST",
                "CUSTOM",
                "OBJECT",
                "LABEL",
                "DATETIMEPICKER"
            ]
        },
        "FieldPosition": {
            "name": "FieldPosition",
            "values": [
                "LEFT",
                "RIGHT"
            ]
        },
        "CustomFieldUsage": {
            "name": "CustomFieldUsage",
            "values": [
                "USER_FILTER",
                "MEETING",
                "DOCUMENT",
                "EMAIL_TEMPLATE_FILTER",
                "CONTENT_SHARE",
                "HUB",
                "TENANT_FORM"
            ]
        },
        "PermissionEffect": {
            "name": "PermissionEffect",
            "values": [
                "ALLOW",
                "BLOCK"
            ]
        },
        "DateRestriction": {
            "name": "DateRestriction",
            "values": [
                "ONLY_FUTURE",
                "ONLY_PAST"
            ]
        },
        "TenantFormTargetType": {
            "name": "TenantFormTargetType",
            "values": [
                "API"
            ]
        },
        "TenantFormStatus": {
            "name": "TenantFormStatus",
            "values": [
                "ACTIVE",
                "INACTIVE"
            ]
        },
        "CrmIntegrationType": {
            "name": "CrmIntegrationType",
            "values": [
                "SALESFORCE",
                "VEEVA"
            ]
        },
        "StandaloneFormUsage": {
            "name": "StandaloneFormUsage",
            "values": [
                "STANDALONE",
                "MEETINGS"
            ]
        },
        "Frequency": {
            "name": "Frequency",
            "values": [
                "DAILY",
                "WEEKLY",
                "MONTHLY"
            ]
        },
        "AttachedFileEntityType": {
            "name": "AttachedFileEntityType",
            "values": [
                "DOCUMENT_VERSION",
                "EMAIL_TEMPLATE"
            ]
        },
        "ShareNotificationScope": {
            "name": "ShareNotificationScope",
            "values": [
                "EMAIL",
                "NONE"
            ]
        },
        "UserStatus": {
            "name": "UserStatus",
            "values": [
                "ACTIVE",
                "DEACTIVATED"
            ]
        },
        "UserRole": {
            "name": "UserRole",
            "values": [
                "AlucioAdmin",
                "TenantAdmin",
                "TenantPublisher",
                "TenantViewer",
                "TeamReportViewer"
            ]
        },
        "ForcePropertyUpdate": {
            "name": "ForcePropertyUpdate",
            "values": [
                "OVERRIDE",
                "POPULATE"
            ]
        },
        "InputEngineType": {
            "name": "InputEngineType",
            "values": [
                "MULTICATEGORICAL",
                "SINGLECATEGORICAL"
            ]
        },
        "EmailTemplateStatus": {
            "name": "EmailTemplateStatus",
            "values": [
                "DRAFT",
                "ACTIVE",
                "DEACTIVATED",
                "REMOVED"
            ]
        },
        "UserNotationsStatus": {
            "name": "UserNotationsStatus",
            "values": [
                "ACTIVE",
                "DELETED"
            ]
        },
        "UserNotationsType": {
            "name": "UserNotationsType",
            "values": [
                "DOCUMENT_VERSION",
                "CUSTOM_DECK"
            ]
        },
        "PurposeType": {
            "name": "PurposeType",
            "values": [
                "INTERNAL_USE_ONLY",
                "EXTERNAL_PROACTIVE",
                "EXTERNAL_REACTIVE",
                "NOT_DEFINED"
            ]
        },
        "LogLevel": {
            "name": "LogLevel",
            "values": [
                "WARNING",
                "ERROR",
                "SUCCESS",
                "INFO"
            ]
        },
        "IntegrationRunStatus": {
            "name": "IntegrationRunStatus",
            "values": [
                "RUNNING",
                "COMPLETED",
                "WARNING",
                "ERROR"
            ]
        },
        "ContentPresentedStatus": {
            "name": "ContentPresentedStatus",
            "values": [
                "ACTIVE",
                "DELETED"
            ]
        },
        "MeetingContentType": {
            "name": "MeetingContentType",
            "values": [
                "CUSTOM_DECK",
                "DOCUMENT_VERSION",
                "DOCUMENT_VERSION_GROUP"
            ]
        },
        "Sentiment": {
            "name": "Sentiment",
            "values": [
                "POSITIVE",
                "NEGATIVE"
            ]
        },
        "MeetingType": {
            "name": "MeetingType",
            "values": [
                "IN_PERSON",
                "VIRTUAL",
                "MANUAL"
            ]
        },
        "MeetingStatus": {
            "name": "MeetingStatus",
            "values": [
                "IN_PROGRESS",
                "ENDED_EXIT",
                "ENDED_TIMEOUT",
                "DELETED",
                "LOCKED",
                "PLANNED"
            ]
        },
        "MeetingAttendeeStatus": {
            "name": "MeetingAttendeeStatus",
            "values": [
                "ACTIVE",
                "REMOVED"
            ]
        },
        "AttendeeType": {
            "name": "AttendeeType",
            "values": [
                "PRIMARY",
                "SECONDARY"
            ]
        },
        "CustomFormRecordEntity": {
            "name": "CustomFormRecordEntity",
            "values": [
                "BEACON_TENANT_FORM",
                "CRM_OBJECT",
                "SURVEY_TARGET"
            ]
        },
        "CustomFormRecordStatus": {
            "name": "CustomFormRecordStatus",
            "values": [
                "ACTIVE",
                "LOCKED",
                "DELETED"
            ]
        },
        "CustomFormRecordRelationshipModel": {
            "name": "CustomFormRecordRelationshipModel",
            "values": [
                "MEETING"
            ]
        }
    },
    "nonModels": {
        "ContentTokenOutput": {
            "name": "ContentTokenOutput",
            "fields": {
                "token": {
                    "name": "token",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "expiresAt": {
                    "name": "expiresAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "PresentableContentMetadata": {
            "name": "PresentableContentMetadata",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "contentId": {
                    "name": "contentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "token": {
                    "name": "token",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "expiresAt": {
                    "name": "expiresAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "fileName": {
                    "name": "fileName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "associatedFileId": {
                    "name": "associatedFileId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "shareType": {
                    "name": "shareType",
                    "isArray": false,
                    "type": {
                        "enum": "ShareType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "fileExtension": {
                    "name": "fileExtension",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "contentURL": {
                    "name": "contentURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "pageGroups": {
                    "name": "pageGroups",
                    "isArray": true,
                    "type": {
                        "nonModel": "PageGroup"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "numPages": {
                    "name": "numPages",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "notation": {
                    "name": "notation",
                    "isArray": true,
                    "type": {
                        "nonModel": "Notation"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "coverThumbnailPageNum": {
                    "name": "coverThumbnailPageNum",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "sharedBy": {
                    "name": "sharedBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "PageGroup": {
            "name": "PageGroup",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "pageIds": {
                    "name": "pageIds",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "locked": {
                    "name": "locked",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "sourceID": {
                    "name": "sourceID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "source": {
                    "name": "source",
                    "isArray": false,
                    "type": {
                        "enum": "PageGroupSource"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Notation": {
            "name": "Notation",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "NotationType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "HubSectionItemStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "pageId": {
                    "name": "pageId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "coordinate": {
                    "name": "coordinate",
                    "isArray": true,
                    "type": {
                        "nonModel": "Coordinate"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Coordinate": {
            "name": "Coordinate",
            "fields": {
                "x": {
                    "name": "x",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "y": {
                    "name": "y",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "HubAndContentDetails": {
            "name": "HubAndContentDetails",
            "fields": {
                "hubId": {
                    "name": "hubId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "HubStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "hubSections": {
                    "name": "hubSections",
                    "isArray": true,
                    "type": {
                        "nonModel": "HubSection"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "content": {
                    "name": "content",
                    "isArray": true,
                    "type": {
                        "nonModel": "PresentableContentMetadata"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "tenantLogoUrl": {
                    "name": "tenantLogoUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "sharedBy": {
                    "name": "sharedBy",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "featureFlags": {
                    "name": "featureFlags",
                    "isArray": true,
                    "type": {
                        "nonModel": "FeatureFlags"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "HubSection": {
            "name": "HubSection",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "sectionHeader": {
                    "name": "sectionHeader",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "HubSectionStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "HubSectionType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "visible": {
                    "name": "visible",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "order": {
                    "name": "order",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "textarea": {
                    "name": "textarea",
                    "isArray": false,
                    "type": {
                        "nonModel": "TextareaHubSettings"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "sharedFiles": {
                    "name": "sharedFiles",
                    "isArray": true,
                    "type": {
                        "nonModel": "SharedFileHubSetting"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "toDos": {
                    "name": "toDos",
                    "isArray": true,
                    "type": {
                        "nonModel": "ToDo"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "links": {
                    "name": "links",
                    "isArray": true,
                    "type": {
                        "nonModel": "Link"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "TextareaHubSettings": {
            "name": "TextareaHubSettings",
            "fields": {
                "content": {
                    "name": "content",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "SharedFileHubSetting": {
            "name": "SharedFileHubSetting",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "contentId": {
                    "name": "contentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "contentType": {
                    "name": "contentType",
                    "isArray": false,
                    "type": {
                        "enum": "HubSharedFileContentType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "documentVersionSettings": {
                    "name": "documentVersionSettings",
                    "isArray": false,
                    "type": {
                        "nonModel": "DocumentVersionSettings"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "HubSectionItemStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "notation": {
                    "name": "notation",
                    "isArray": true,
                    "type": {
                        "nonModel": "Notation"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "DocumentVersionSettings": {
            "name": "DocumentVersionSettings",
            "fields": {
                "associatedFiles": {
                    "name": "associatedFiles",
                    "isArray": true,
                    "type": {
                        "nonModel": "HubSharedAssociatedFile"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "HubSharedAssociatedFile": {
            "name": "HubSharedAssociatedFile",
            "fields": {
                "associatedFileId": {
                    "name": "associatedFileId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "versionId": {
                    "name": "versionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "notation": {
                    "name": "notation",
                    "isArray": true,
                    "type": {
                        "nonModel": "Notation"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "ToDo": {
            "name": "ToDo",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "TodoStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "completedAt": {
                    "name": "completedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "resolution": {
                    "name": "resolution",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "order": {
                    "name": "order",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Link": {
            "name": "Link",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "url": {
                    "name": "url",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "HubSectionItemStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "order": {
                    "name": "order",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "FeatureFlags": {
            "name": "FeatureFlags",
            "fields": {
                "flagId": {
                    "name": "flagId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "AvailableDashboards": {
            "name": "AvailableDashboards",
            "fields": {
                "dashboards": {
                    "name": "dashboards",
                    "isArray": true,
                    "type": {
                        "nonModel": "Dashboard"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "isTeamLeader": {
                    "name": "isTeamLeader",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Dashboard": {
            "name": "Dashboard",
            "fields": {
                "reports": {
                    "name": "reports",
                    "isArray": true,
                    "type": {
                        "nonModel": "ReportItem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "dashboardUrl": {
                    "name": "dashboardUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "dashboardId": {
                    "name": "dashboardId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "dashboardSheets": {
                    "name": "dashboardSheets",
                    "isArray": true,
                    "type": {
                        "nonModel": "Sheet"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "ReportItem": {
            "name": "ReportItem",
            "fields": {
                "dashboardId": {
                    "name": "dashboardId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "group": {
                    "name": "group",
                    "isArray": false,
                    "type": {
                        "nonModel": "ReportGroup"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ReportGroup": {
            "name": "ReportGroup",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "order": {
                    "name": "order",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Sheet": {
            "name": "Sheet",
            "fields": {
                "sheetId": {
                    "name": "sheetId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "GetSharedFoldersOutput": {
            "name": "GetSharedFoldersOutput",
            "fields": {
                "folders": {
                    "name": "folders",
                    "isArray": true,
                    "type": {
                        "model": "Folder"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "permissions": {
                    "name": "permissions",
                    "isArray": true,
                    "type": {
                        "nonModel": "FolderPermission"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "FolderItem": {
            "name": "FolderItem",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "FolderItemType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "itemId": {
                    "name": "itemId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "FolderItemStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "addedAt": {
                    "name": "addedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updateAcknowledgedAt": {
                    "name": "updateAcknowledgedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "itemLastUpdatedAt": {
                    "name": "itemLastUpdatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "visiblePages": {
                    "name": "visiblePages",
                    "isArray": true,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "customTitle": {
                    "name": "customTitle",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "SharePermission": {
            "name": "SharePermission",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "isDeleted": {
                    "name": "isDeleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "PermissionType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "targetType": {
                    "name": "targetType",
                    "isArray": false,
                    "type": {
                        "enum": "ShareTargetType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "targetUsername": {
                    "name": "targetUsername",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "targetCustomValues": {
                    "name": "targetCustomValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomValues"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "targetLabels": {
                    "name": "targetLabels",
                    "isArray": true,
                    "type": {
                        "nonModel": "LabelValues"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updatedBy": {
                    "name": "updatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "deletedBy": {
                    "name": "deletedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "deletedAt": {
                    "name": "deletedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "CustomValues": {
            "name": "CustomValues",
            "fields": {
                "fieldId": {
                    "name": "fieldId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "objectRecords": {
                    "name": "objectRecords",
                    "isArray": true,
                    "type": {
                        "nonModel": "ObjectRecord"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "values": {
                    "name": "values",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "ObjectRecord": {
            "name": "ObjectRecord",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "externalId": {
                    "name": "externalId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "syncStatus": {
                    "name": "syncStatus",
                    "isArray": false,
                    "type": {
                        "enum": "CrmSyncStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "ObjectRecordStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "values": {
                    "name": "values",
                    "isArray": true,
                    "type": {
                        "nonModel": "ObjectRecordValue"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "ObjectRecordValue": {
            "name": "ObjectRecordValue",
            "fields": {
                "fieldId": {
                    "name": "fieldId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "values": {
                    "name": "values",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "LabelValues": {
            "name": "LabelValues",
            "fields": {
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "values": {
                    "name": "values",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "FolderPermission": {
            "name": "FolderPermission",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "PermissionType"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "GetDependenciesOutput": {
            "name": "GetDependenciesOutput",
            "fields": {
                "customDecks": {
                    "name": "customDecks",
                    "isArray": true,
                    "type": {
                        "model": "CustomDeck"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "documents": {
                    "name": "documents",
                    "isArray": true,
                    "type": {
                        "model": "Document"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "documentVersions": {
                    "name": "documentVersions",
                    "isArray": true,
                    "type": {
                        "model": "DocumentVersion"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "accessTokens": {
                    "name": "accessTokens",
                    "isArray": true,
                    "type": {
                        "nonModel": "AccessTokenData"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "CustomDeckGroup": {
            "name": "CustomDeckGroup",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "pages": {
                    "name": "pages",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomDeckPage"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "visible": {
                    "name": "visible",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "srcId": {
                    "name": "srcId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "docAccessLevel": {
                    "name": "docAccessLevel",
                    "isArray": false,
                    "type": {
                        "enum": "DocumentAccessLevel"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "locked": {
                    "name": "locked",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "CustomDeckPage": {
            "name": "CustomDeckPage",
            "fields": {
                "pageId": {
                    "name": "pageId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "pageNumber": {
                    "name": "pageNumber",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "documentVersionId": {
                    "name": "documentVersionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "EditMutex": {
            "name": "EditMutex",
            "fields": {
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "timeStarted": {
                    "name": "timeStarted",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "lastSeenAt": {
                    "name": "lastSeenAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "DocumentIntegration": {
            "name": "DocumentIntegration",
            "fields": {
                "integrationId": {
                    "name": "integrationId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "externalId": {
                    "name": "externalId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "firstSync": {
                    "name": "firstSync",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "lastSync": {
                    "name": "lastSync",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "S3Object": {
            "name": "S3Object",
            "fields": {
                "bucket": {
                    "name": "bucket",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "region": {
                    "name": "region",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "url": {
                    "name": "url",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "PageSetting": {
            "name": "PageSetting",
            "fields": {
                "pageId": {
                    "name": "pageId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "number": {
                    "name": "number",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "isRequired": {
                    "name": "isRequired",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "linkedSlides": {
                    "name": "linkedSlides",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "LabelValue": {
            "name": "LabelValue",
            "fields": {
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "SemVer": {
            "name": "SemVer",
            "fields": {
                "minor": {
                    "name": "minor",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "major": {
                    "name": "major",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "AssociatedFile": {
            "name": "AssociatedFile",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "isDistributable": {
                    "name": "isDistributable",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "isDefault": {
                    "name": "isDefault",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "AssociatedFileType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "attachmentId": {
                    "name": "attachmentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "AssociatedFileStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdBy": {
                    "name": "createdBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "DocumentVersionIntegration": {
            "name": "DocumentVersionIntegration",
            "fields": {
                "externalVersionId": {
                    "name": "externalVersionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "timestamp": {
                    "name": "timestamp",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "srcFileHash": {
                    "name": "srcFileHash",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "srcDocumentHash": {
                    "name": "srcDocumentHash",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "AccessTokenData": {
            "name": "AccessTokenData",
            "fields": {
                "documentVersionId": {
                    "name": "documentVersionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "accessToken": {
                    "name": "accessToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "accessTokenExpire": {
                    "name": "accessTokenExpire",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "PublisherIntegration": {
            "name": "PublisherIntegration",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "integrationType": {
                    "name": "integrationType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "enabled": {
                    "name": "enabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ZendeskTokenPayload": {
            "name": "ZendeskTokenPayload",
            "fields": {
                "token": {
                    "name": "token",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "expiresAt": {
                    "name": "expiresAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "AuthInformationPayload": {
            "name": "AuthInformationPayload",
            "fields": {
                "accessToken": {
                    "name": "accessToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "instanceUrl": {
                    "name": "instanceUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "refreshToken": {
                    "name": "refreshToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "userInfo": {
                    "name": "userInfo",
                    "isArray": false,
                    "type": {
                        "nonModel": "UserInfoPayload"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "UserInfoPayload": {
            "name": "UserInfoPayload",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "organizationId": {
                    "name": "organizationId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "displayName": {
                    "name": "displayName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "thumbnail": {
                    "name": "thumbnail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "RefreshTokenCRMPayload": {
            "name": "RefreshTokenCRMPayload",
            "fields": {
                "accessToken": {
                    "name": "accessToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "issuedAt": {
                    "name": "issuedAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "signature": {
                    "name": "signature",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "FullTextSearchOutput": {
            "name": "FullTextSearchOutput",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "FullTextSearchResultItem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "searchServerTime": {
                    "name": "searchServerTime",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "searchClientTime": {
                    "name": "searchClientTime",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "FullTextSearchResultItem": {
            "name": "FullTextSearchResultItem",
            "fields": {
                "documentId": {
                    "name": "documentId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": {
                        "nonModel": "TextWithHighlights"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "documentExcerpt": {
                    "name": "documentExcerpt",
                    "isArray": false,
                    "type": {
                        "nonModel": "TextWithHighlights"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "queryId": {
                    "name": "queryId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "resultId": {
                    "name": "resultId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "pageNumber": {
                    "name": "pageNumber",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TextWithHighlights": {
            "name": "TextWithHighlights",
            "fields": {
                "text": {
                    "name": "text",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "highlights": {
                    "name": "highlights",
                    "isArray": true,
                    "type": {
                        "nonModel": "Highlights"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "Highlights": {
            "name": "Highlights",
            "fields": {
                "beginOffset": {
                    "name": "beginOffset",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "endOffset": {
                    "name": "endOffset",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "topAnswer": {
                    "name": "topAnswer",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "FieldConfig": {
            "name": "FieldConfig",
            "fields": {
                "fieldName": {
                    "name": "fieldName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "required": {
                    "name": "required",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "isEmailTemplateFilter": {
                    "name": "isEmailTemplateFilter",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "dataType": {
                    "name": "dataType",
                    "isArray": false,
                    "type": {
                        "enum": "FieldDataType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "userFilter": {
                    "name": "userFilter",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "defaultSearchFilter": {
                    "name": "defaultSearchFilter",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "values": {
                    "name": "values",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "ListConfiguration": {
            "name": "ListConfiguration",
            "fields": {
                "fields": {
                    "name": "fields",
                    "isArray": true,
                    "type": {
                        "nonModel": "ListConfigurationField"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "sort": {
                    "name": "sort",
                    "isArray": false,
                    "type": {
                        "nonModel": "ListConfigurationSort"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ListConfigurationField": {
            "name": "ListConfigurationField",
            "fields": {
                "fieldName": {
                    "name": "fieldName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "header": {
                    "name": "header",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "width": {
                    "name": "width",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ListConfigurationSort": {
            "name": "ListConfigurationSort",
            "fields": {
                "field": {
                    "name": "field",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "isAsc": {
                    "name": "isAsc",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "TenantConfig": {
            "name": "TenantConfig",
            "fields": {
                "requiredSlidesHiddenMessage": {
                    "name": "requiredSlidesHiddenMessage",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "forms": {
                    "name": "forms",
                    "isArray": true,
                    "type": {
                        "nonModel": "TenantForm"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "meetingFields": {
                    "name": "meetingFields",
                    "isArray": true,
                    "type": {
                        "nonModel": "MeetingFieldDefinition"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "customFields": {
                    "name": "customFields",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomFieldDefinition"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "defaultDocumentPermissions": {
                    "name": "defaultDocumentPermissions",
                    "isArray": false,
                    "type": {
                        "nonModel": "DocumentPermission"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "mslListConfig": {
                    "name": "mslListConfig",
                    "isArray": false,
                    "type": {
                        "nonModel": "ListConfig"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "publisherListConfig": {
                    "name": "publisherListConfig",
                    "isArray": false,
                    "type": {
                        "nonModel": "ListConfig"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "crmIntegration": {
                    "name": "crmIntegration",
                    "isArray": false,
                    "type": {
                        "nonModel": "CRMIntegration"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "sessionTimeout": {
                    "name": "sessionTimeout",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "quickFilters": {
                    "name": "quickFilters",
                    "isArray": true,
                    "type": {
                        "nonModel": "QuickFilter"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "hubsConfig": {
                    "name": "hubsConfig",
                    "isArray": false,
                    "type": {
                        "nonModel": "HubConfig"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "reports": {
                    "name": "reports",
                    "isArray": true,
                    "type": {
                        "nonModel": "KeyValue"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "emailDocumentUpdateFrequency": {
                    "name": "emailDocumentUpdateFrequency",
                    "isArray": false,
                    "type": {
                        "enum": "Frequency"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "sharePPTXasPDF": {
                    "name": "sharePPTXasPDF",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TenantForm": {
            "name": "TenantForm",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "label": {
                    "name": "label",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "fields": {
                    "name": "fields",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomFieldDefinition"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "targetRequestTemplate": {
                    "name": "targetRequestTemplate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "targetType": {
                    "name": "targetType",
                    "isArray": false,
                    "type": {
                        "enum": "TenantFormTargetType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "targetAddress": {
                    "name": "targetAddress",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "targetParameters": {
                    "name": "targetParameters",
                    "isArray": true,
                    "type": {
                        "nonModel": "TenantFormTargetParameters"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "TenantFormStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "CustomFieldDefinition": {
            "name": "CustomFieldDefinition",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "fieldName": {
                    "name": "fieldName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "fieldLabel": {
                    "name": "fieldLabel",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "reportingName": {
                    "name": "reportingName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "FieldStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "required": {
                    "name": "required",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "maxLength": {
                    "name": "maxLength",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "formatValidation": {
                    "name": "formatValidation",
                    "isArray": false,
                    "type": {
                        "enum": "FormatValidation"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "fieldType": {
                    "name": "fieldType",
                    "isArray": false,
                    "type": {
                        "enum": "FieldDataType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "showByDefault": {
                    "name": "showByDefault",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "dependentFieldId": {
                    "name": "dependentFieldId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "controlType": {
                    "name": "controlType",
                    "isArray": false,
                    "type": {
                        "enum": "ControlType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "order": {
                    "name": "order",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "settings": {
                    "name": "settings",
                    "isArray": false,
                    "type": {
                        "nonModel": "CommonFieldSettings"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "usage": {
                    "name": "usage",
                    "isArray": true,
                    "type": {
                        "enum": "CustomFieldUsage"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "documentSettings": {
                    "name": "documentSettings",
                    "isArray": false,
                    "type": {
                        "nonModel": "DocumentFieldSettings"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "fieldValueDefinitions": {
                    "name": "fieldValueDefinitions",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomFieldValueDefinition"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "displayOnParentSelection": {
                    "name": "displayOnParentSelection",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "displayOnValueSelection": {
                    "name": "displayOnValueSelection",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "objectSetting": {
                    "name": "objectSetting",
                    "isArray": false,
                    "type": {
                        "nonModel": "ObjectSetting"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "isChildField": {
                    "name": "isChildField",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "dateRestriction": {
                    "name": "dateRestriction",
                    "isArray": false,
                    "type": {
                        "enum": "DateRestriction"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "scaleNumber": {
                    "name": "scaleNumber",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "precisionNumber": {
                    "name": "precisionNumber",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "CommonFieldSettings": {
            "name": "CommonFieldSettings",
            "fields": {
                "isBadge": {
                    "name": "isBadge",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "fieldPosition": {
                    "name": "fieldPosition",
                    "isArray": false,
                    "type": {
                        "enum": "FieldPosition"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "DocumentFieldSettings": {
            "name": "DocumentFieldSettings",
            "fields": {
                "fieldPosition": {
                    "name": "fieldPosition",
                    "isArray": false,
                    "type": {
                        "enum": "FieldPosition"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "CustomFieldValueDefinition": {
            "name": "CustomFieldValueDefinition",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "label": {
                    "name": "label",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "disabled": {
                    "name": "disabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "isDefault": {
                    "name": "isDefault",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "documentSettings": {
                    "name": "documentSettings",
                    "isArray": false,
                    "type": {
                        "nonModel": "DocumentFieldValueSettings"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "badgeColor": {
                    "name": "badgeColor",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "badgeLabel": {
                    "name": "badgeLabel",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "dependentValueIds": {
                    "name": "dependentValueIds",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "DocumentFieldValueSettings": {
            "name": "DocumentFieldValueSettings",
            "fields": {
                "presentationWatermarkText": {
                    "name": "presentationWatermarkText",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "permission": {
                    "name": "permission",
                    "isArray": false,
                    "type": {
                        "nonModel": "DocumentPermission"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "DocumentPermission": {
            "name": "DocumentPermission",
            "fields": {
                "present": {
                    "name": "present",
                    "isArray": false,
                    "type": {
                        "enum": "PermissionEffect"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "modify": {
                    "name": "modify",
                    "isArray": false,
                    "type": {
                        "enum": "PermissionEffect"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "share": {
                    "name": "share",
                    "isArray": false,
                    "type": {
                        "enum": "PermissionEffect"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "download": {
                    "name": "download",
                    "isArray": false,
                    "type": {
                        "enum": "PermissionEffect"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "externalNotation": {
                    "name": "externalNotation",
                    "isArray": false,
                    "type": {
                        "enum": "PermissionEffect"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ObjectSetting": {
            "name": "ObjectSetting",
            "fields": {
                "childrenFieldIds": {
                    "name": "childrenFieldIds",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "restriction": {
                    "name": "restriction",
                    "isArray": false,
                    "type": {
                        "nonModel": "ObjectRestriction"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ObjectRestriction": {
            "name": "ObjectRestriction",
            "fields": {
                "syncUpdate": {
                    "name": "syncUpdate",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "syncDelete": {
                    "name": "syncDelete",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "update": {
                    "name": "update",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "delete": {
                    "name": "delete",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TenantFormTargetParameters": {
            "name": "TenantFormTargetParameters",
            "fields": {
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "MeetingFieldDefinition": {
            "name": "MeetingFieldDefinition",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "fieldName": {
                    "name": "fieldName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "FieldStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "required": {
                    "name": "required",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "maxLength": {
                    "name": "maxLength",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "FieldDataType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "controlType": {
                    "name": "controlType",
                    "isArray": false,
                    "type": {
                        "enum": "ControlType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "fieldValueDefinitions": {
                    "name": "fieldValueDefinitions",
                    "isArray": true,
                    "type": {
                        "nonModel": "MeetingFieldValueDefinition"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "MeetingFieldValueDefinition": {
            "name": "MeetingFieldValueDefinition",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "disabled": {
                    "name": "disabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ListConfig": {
            "name": "ListConfig",
            "fields": {
                "sort": {
                    "name": "sort",
                    "isArray": false,
                    "type": {
                        "nonModel": "ListSort"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "fields": {
                    "name": "fields",
                    "isArray": true,
                    "type": {
                        "nonModel": "FieldListConfig"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "ListSort": {
            "name": "ListSort",
            "fields": {
                "field": {
                    "name": "field",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "isAsc": {
                    "name": "isAsc",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "FieldListConfig": {
            "name": "FieldListConfig",
            "fields": {
                "field": {
                    "name": "field",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "width": {
                    "name": "width",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "CRMIntegration": {
            "name": "CRMIntegration",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "crmIntegrationType": {
                    "name": "crmIntegrationType",
                    "isArray": false,
                    "type": {
                        "enum": "CrmIntegrationType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "instanceUrl": {
                    "name": "instanceUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "clientId": {
                    "name": "clientId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "redirectUri": {
                    "name": "redirectUri",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "accountsSettings": {
                    "name": "accountsSettings",
                    "isArray": false,
                    "type": {
                        "nonModel": "CRMAccountSettings"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "meetingSetting": {
                    "name": "meetingSetting",
                    "isArray": false,
                    "type": {
                        "nonModel": "CRMTableSetting"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "additionalSettings": {
                    "name": "additionalSettings",
                    "isArray": false,
                    "type": {
                        "nonModel": "CRMAdditionalSettings"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "loginUrl": {
                    "name": "loginUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "crmStandaloneForms": {
                    "name": "crmStandaloneForms",
                    "isArray": true,
                    "type": {
                        "nonModel": "CRMStandaloneForm"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "CRMAccountSettings": {
            "name": "CRMAccountSettings",
            "fields": {
                "query": {
                    "name": "query",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "displaySettings": {
                    "name": "displaySettings",
                    "isArray": false,
                    "type": {
                        "nonModel": "CRMDisplaySettings"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "addressSettings": {
                    "name": "addressSettings",
                    "isArray": false,
                    "type": {
                        "nonModel": "CRMAddressSettings"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "CRMDisplaySettings": {
            "name": "CRMDisplaySettings",
            "fields": {
                "shortened": {
                    "name": "shortened",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "extended": {
                    "name": "extended",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "CRMAddressSettings": {
            "name": "CRMAddressSettings",
            "fields": {
                "alias": {
                    "name": "alias",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "displaySettings": {
                    "name": "displaySettings",
                    "isArray": false,
                    "type": {
                        "nonModel": "CRMDisplaySettings"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "CRMTableSetting": {
            "name": "CRMTableSetting",
            "fields": {
                "apiName": {
                    "name": "apiName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "presentationsFieldName": {
                    "name": "presentationsFieldName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "children": {
                    "name": "children",
                    "isArray": true,
                    "type": {
                        "nonModel": "CRMChildTableSetting"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "CrmIntegrationType"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "CRMChildTableSetting": {
            "name": "CRMChildTableSetting",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "apiName": {
                    "name": "apiName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "relationshipName": {
                    "name": "relationshipName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "CrmIntegrationType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "veevaSetting": {
                    "name": "veevaSetting",
                    "isArray": false,
                    "type": {
                        "nonModel": "VeevaSetting"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "VeevaSetting": {
            "name": "VeevaSetting",
            "fields": {
                "markerFieldName": {
                    "name": "markerFieldName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "CRMAdditionalSettings": {
            "name": "CRMAdditionalSettings",
            "fields": {
                "allowCommentsOnTasks": {
                    "name": "allowCommentsOnTasks",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "detailContentTableSettings": {
                    "name": "detailContentTableSettings",
                    "isArray": false,
                    "type": {
                        "nonModel": "CRMTableDetailContentPresented"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "enableSurveys": {
                    "name": "enableSurveys",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "crmAccountToObjectSettings": {
                    "name": "crmAccountToObjectSettings",
                    "isArray": true,
                    "type": {
                        "nonModel": "CRMAccountToObjectSetting"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "CRMTableDetailContentPresented": {
            "name": "CRMTableDetailContentPresented",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "apiName": {
                    "name": "apiName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "relationshipName": {
                    "name": "relationshipName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "prefix": {
                    "name": "prefix",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "CRMAccountToObjectSetting": {
            "name": "CRMAccountToObjectSetting",
            "fields": {
                "accountFieldNames": {
                    "name": "accountFieldNames",
                    "isArray": true,
                    "type": {
                        "nonModel": "CRMAccountToObjectFields"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "apiName": {
                    "name": "apiName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "CRMAccountToObjectFields": {
            "name": "CRMAccountToObjectFields",
            "fields": {
                "accountFieldName": {
                    "name": "accountFieldName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "objectFieldName": {
                    "name": "objectFieldName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "CRMStandaloneForm": {
            "name": "CRMStandaloneForm",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "apiName": {
                    "name": "apiName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "children": {
                    "name": "children",
                    "isArray": true,
                    "type": {
                        "nonModel": "CRMChildTableSetting"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "CrmIntegrationType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "parents": {
                    "name": "parents",
                    "isArray": true,
                    "type": {
                        "nonModel": "CRMStandaloneFormParent"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "usage": {
                    "name": "usage",
                    "isArray": true,
                    "type": {
                        "enum": "StandaloneFormUsage"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "CRMStandaloneFormParent": {
            "name": "CRMStandaloneFormParent",
            "fields": {
                "apiName": {
                    "name": "apiName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "relationshipName": {
                    "name": "relationshipName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "QuickFilter": {
            "name": "QuickFilter",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "label": {
                    "name": "label",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "path": {
                    "name": "path",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "HubConfig": {
            "name": "HubConfig",
            "fields": {
                "disabledSections": {
                    "name": "disabledSections",
                    "isArray": true,
                    "type": {
                        "enum": "HubSectionType"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "disableNotationDescription": {
                    "name": "disableNotationDescription",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "KeyValue": {
            "name": "KeyValue",
            "fields": {
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "CustomDeckDependencies": {
            "name": "CustomDeckDependencies",
            "fields": {
                "versions": {
                    "name": "versions",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "folderId": {
                    "name": "folderId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "BookmarkedDoc": {
            "name": "BookmarkedDoc",
            "fields": {
                "docID": {
                    "name": "docID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "SubmitIntegrationResult": {
            "name": "SubmitIntegrationResult",
            "fields": {
                "success": {
                    "name": "success",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "message": {
                    "name": "message",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "UpsertVersionContentsResult": {
            "name": "UpsertVersionContentsResult",
            "fields": {
                "documentVersionId": {
                    "name": "documentVersionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "result": {
                    "name": "result",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "UpdateDocVerPageMatchesResult": {
            "name": "UpdateDocVerPageMatchesResult",
            "fields": {
                "tenantId": {
                    "name": "tenantId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "documentVersionId": {
                    "name": "documentVersionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "message": {
                    "name": "message",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Page": {
            "name": "Page",
            "fields": {
                "pageId": {
                    "name": "pageId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "number": {
                    "name": "number",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "srcId": {
                    "name": "srcId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "srcHash": {
                    "name": "srcHash",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "isRequired": {
                    "name": "isRequired",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "speakerNotes": {
                    "name": "speakerNotes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "linkedSlides": {
                    "name": "linkedSlides",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "IntegrationClientConfigurationTuple": {
            "name": "IntegrationClientConfigurationTuple",
            "fields": {
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "IntegrationValueMapping": {
            "name": "IntegrationValueMapping",
            "fields": {
                "srcValue": {
                    "name": "srcValue",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "targetValue": {
                    "name": "targetValue",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "IntegrationFieldMapping": {
            "name": "IntegrationFieldMapping",
            "fields": {
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "fieldType": {
                    "name": "fieldType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "dataType": {
                    "name": "dataType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "targetFieldName": {
                    "name": "targetFieldName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "srcFieldname": {
                    "name": "srcFieldname",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "valueMappings": {
                    "name": "valueMappings",
                    "isArray": true,
                    "type": {
                        "nonModel": "IntegrationValueMapping"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "IntegrationLogEntry": {
            "name": "IntegrationLogEntry",
            "fields": {
                "level": {
                    "name": "level",
                    "isArray": false,
                    "type": {
                        "enum": "LogLevel"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "timestamp": {
                    "name": "timestamp",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "message": {
                    "name": "message",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "srcDocId": {
                    "name": "srcDocId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "srcDocVersion": {
                    "name": "srcDocVersion",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ContentPresented": {
            "name": "ContentPresented",
            "fields": {
                "contentId": {
                    "name": "contentId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "groupId": {
                    "name": "groupId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "folderItemId": {
                    "name": "folderItemId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "groupTitle": {
                    "name": "groupTitle",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "ContentPresentedStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "contentType": {
                    "name": "contentType",
                    "isArray": false,
                    "type": {
                        "enum": "MeetingContentType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "events": {
                    "name": "events",
                    "isArray": true,
                    "type": {
                        "nonModel": "PagePresentedEvent"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "openedAt": {
                    "name": "openedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "closedAt": {
                    "name": "closedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "presentedMeta": {
                    "name": "presentedMeta",
                    "isArray": true,
                    "type": {
                        "nonModel": "PresentedMeta"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "PagePresentedEvent": {
            "name": "PagePresentedEvent",
            "fields": {
                "pageNumber": {
                    "name": "pageNumber",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "pageId": {
                    "name": "pageId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "timestamp": {
                    "name": "timestamp",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "end": {
                    "name": "end",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "PresentedMeta": {
            "name": "PresentedMeta",
            "fields": {
                "pageId": {
                    "name": "pageId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "presented": {
                    "name": "presented",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "followUp": {
                    "name": "followUp",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "sentiment": {
                    "name": "sentiment",
                    "isArray": false,
                    "type": {
                        "enum": "Sentiment"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "note": {
                    "name": "note",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "FieldValue": {
            "name": "FieldValue",
            "fields": {
                "fieldId": {
                    "name": "fieldId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "valueOrId": {
                    "name": "valueOrId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Attendee": {
            "name": "Attendee",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "MeetingAttendeeStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "crmAccountId": {
                    "name": "crmAccountId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "crmAddressId": {
                    "name": "crmAddressId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "attendeeType": {
                    "name": "attendeeType",
                    "isArray": false,
                    "type": {
                        "enum": "AttendeeType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "crmRecord": {
                    "name": "crmRecord",
                    "isArray": false,
                    "type": {
                        "nonModel": "CRMMeetingRecordInfo"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "lastSyncedAt": {
                    "name": "lastSyncedAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "CRMMeetingRecordInfo": {
            "name": "CRMMeetingRecordInfo",
            "fields": {
                "crmCallId": {
                    "name": "crmCallId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "crmCustomValues": {
                    "name": "crmCustomValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "CustomValues"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "crmSyncStatus": {
                    "name": "crmSyncStatus",
                    "isArray": false,
                    "type": {
                        "enum": "CrmSyncStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "lastSyncedAt": {
                    "name": "lastSyncedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "CustomFormRecordCRMFields": {
            "name": "CustomFormRecordCRMFields",
            "fields": {
                "accountId": {
                    "name": "accountId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "externalId": {
                    "name": "externalId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "apiName": {
                    "name": "apiName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "recordTypeId": {
                    "name": "recordTypeId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "syncStatus": {
                    "name": "syncStatus",
                    "isArray": false,
                    "type": {
                        "enum": "CrmSyncStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "lastSyncedAt": {
                    "name": "lastSyncedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            }
        }
    },
    "codegenVersion": "3.3.4",
    "version": "f88175b32fc727019ec3581480a7e247"
};
