import { useRef } from 'react'

function useLazyRef<T extends any>(init: () => T) {
  const ref = useRef<T>();

  if (!ref.current) {
    ref.current = init();
  }

  return ref;
}

export default useLazyRef
