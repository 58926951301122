import { GetProviderByUserEmailQuery } from '@alucio/aws-beacon-amplify/src/API';
import awsmobile from '@alucio/aws-beacon-amplify/src/aws-exports';
import { getProviderByUserEmail } from '@alucio/aws-beacon-amplify/src/graphql/queries';
import { API, graphqlOperation, GraphQLQuery } from '@aws-amplify/api';
import debounce from 'lodash/debounce';
import config from 'src/config/app.json';
import { useState, useCallback } from 'react';
import useRetryOnErrosSSO from './useRetryOnSSoError';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import * as logger from 'src/utils/logger';

const navigation = window.location;

const useSSO = () => {
  const [isValidating, doSSOValidation] = useState<boolean>(false);
  const [isSSoUser, setIsSSoUser] = useState<boolean>(false);
  const [domain, setDomain] = useState<string>('');
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const { saveUrl, error: ssoError, cleanSSoError } = useRetryOnErrosSSO();
  const enableZendeskKnowledgebase = useFeatureFlags('enableZendeskKnowledgebase');
  const validateEmail = (email) => {
    // eslint-disable-next-line max-len
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const validateUser: any = async (email, redirect = true) => {
    logger.auth.signIn.sso.info('Validating user email', email);

    if (!validateEmail(email)) {
      logger.auth.signIn.sso.warn('Invalid email format', email);
      setIsSSoUser(false);
      return {
        url: null,
        isSSO: false,
      };
    }

    doSSOValidation(true);
    logger.auth.signIn.sso.info('Started SSO validation');

    const domain: string = email.split('@').pop().toLowerCase();
    logger.auth.signIn.sso.info('Extracted domain from email', domain);

    const gqlParams = {
      ...graphqlOperation(getProviderByUserEmail, { idpIdentifier: domain }),
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    }

    try {
      const providerData = await API.graphql<GraphQLQuery<GetProviderByUserEmailQuery>>(gqlParams);
      const result = providerData.data?.getProviderByUserEmail;
      doSSOValidation(false);
      setDomain(domain);

      if (result) {
        // The react router is not available at this moment in the app for that reason we use the window.location
        logger.auth.signIn.sso.info('SSO provider found for domain', domain);

        let url = `
          https://${config.authUrl}.auth.us-west-2.amazoncognito.com/oauth2/authorize
          ?client_id=${awsmobile.aws_user_pools_web_client_id}
          &response_type=code&scope=email+openid+profile+aws.cognito.signin.user.admin
          &redirect_uri=${window.location.origin}/
          &idp_identifier=${domain}`
          .replace(/\s/g, '')

        const state = `
          ${window.location.pathname}
          ${window.location.search}
          ${window.location.hash}`
          .replace(/\s/g, '')

        url += `&state=${encodeURIComponent(state)}`
        saveUrl(url);

        if (redirect) {
          logger.auth.signIn.sso.info('Redirecting user to SSO URL', url);
          navigation.href = url;
        } else {
          setRedirectUrl(url);
        }

        setIsSSoUser(true);
        return {
          url,
          isSSO: true,
        };
      } else {
        logger.auth.signIn.sso.warn('No SSO provider found for domain', domain);
      }
    } catch (error) {
      logger.auth.signIn.sso.error('Error during SSO validation', error);
    }

    setIsSSoUser(false);
    return {
      url: null,
      isSSO: false,
    };
  };
  const SSOUserValidation = useCallback(validateUser, [enableZendeskKnowledgebase])

  const DebouncedSSOUserValidation = useCallback(debounce(validateUser, 1000), [])

  return {
    SSOUserValidation,
    DebouncedSSOUserValidation,
    isValidating,
    isSSoUser,
    redirectUrl,
    domain,
    ssoError,
    cleanSSoError,
  }
}

export default useSSO;
