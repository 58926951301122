import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags'
import { RootState } from 'src/state/redux'
import { isFolderORM } from 'src/types/typeguards'

export enum ActionBarOptions {
  Details,
  Sharing,
}
export type ActionBarOption = keyof typeof ActionBarOptions

/** TODO: investigate deriving these from the states defined in the provider so we
 * don't have to update this any time we add additional options to the provider */
export interface InfoPanelStateType {
  collapsed: boolean
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  activeActionBarOption: ActionBarOption
  setActiveActionBarOption: React.Dispatch<React.SetStateAction<ActionBarOption>>
  handlePressActionBarOption: (option: ActionBarOption) => void
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const InfoPanelStateContext = createContext<InfoPanelStateType | null>(null)
InfoPanelStateContext.displayName = 'InfoPanelContext'

interface InitialValues {
  activeActionBarOption?: ActionBarOption
}

const InfoPanelStateProvider: React.FC<PropsWithChildren<InitialValues>> = props => {
  /** SECTION - States */

  /** SECTION - Global */
  const globalInfoPanelState = useSelector((state: RootState) => state.infoPanel)
  const isSharedWithTheUser =
    isFolderORM(globalInfoPanelState.activeItemORM) &&
    globalInfoPanelState.activeItemORM.meta.isSharedWithTheUser

  /** !SECTION - Global */

  /** SECTION - Local */
  const enableSharedFolders = useFeatureFlags('enableSharedFolders')
  const [activeActionBarOption, setActiveActionBarOption] = useState<ActionBarOption>(
    /** Look for passed in props to override default */
    props.activeActionBarOption ||
    /** Otherwise, for folder owner show sharing panel by default, for recipients show details */
    (isSharedWithTheUser || !enableSharedFolders ) ? 'Details' : 'Sharing',
  )
  const [collapsed, setCollapsed] = useState<boolean>(true)
  const [visible, setVisible] = useState<boolean>(false)
  /** !SECTION - Local */

  /** !SECTION - States */

  /** SECTION - UTILITY FUNCTIONS */
  /** !SECTION - UTILITY FUNCTIONS */

  /** SECTION - HANDLERS */
  const handlePressActionBarOption = (option: ActionBarOption) => {
    setCollapsed(false)
    setActiveActionBarOption(option)
  }

  /** TODO: update this with the appropriate values and enable associated useeffect below */
  //   const handleTrackingUpdate = () => {
  //     const trackingObject = {
  //         action: 'PRESENTED',
  //         category: 'SLIDE',
  //         context,
  //         pageNumber: activeSlide,
  //         documentId: activeDocVersion?.model.documentId,
  //         documentVersionId: activeDocVersion?.model.id,
  //       };
  //     analytics?.track('SLIDE_PRESENTED', trackingObject);
  //     }
  //   };

  const handleContextInit = () => {
    /** Place any global component initializations here
     * (i.e. if we want to use some derived value for
     * settings or to do some initial processing or
     * function calls) */
  }

  //   useEffect(handleTrackingUpdate, [activeSlide, isFullWindow, activeDocVersion])
  useEffect(handleContextInit, [])
  /** !SECTION - HANDLERS */

  const contextValue = {
    collapsed,
    setCollapsed,
    activeActionBarOption,
    setActiveActionBarOption,
    handlePressActionBarOption,
    visible,
    setVisible,
    globalInfoPanelState,
  }

  return <InfoPanelStateContext.Provider value={contextValue}>{props.children}</InfoPanelStateContext.Provider>
}
InfoPanelStateProvider.displayName = 'InfoPanelStateProvider'
export function useInfoPanelState() {
  const context = useContext(InfoPanelStateContext)
  if (!context) {
    throw new Error('useInfoPanelState must be used within the InfoPanelStateProvider')
  }
  return context
}

export default InfoPanelStateProvider
