import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgFileAccountOutline = (props: SvgProps) => (
  <Svg
    width={48}
    height={48}
    fill="none"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      // eslint-disable-next-line max-len
      d="M20 0H4C1.78 0 0 1.8 0 4v32c0 2.22 1.78 4 4 4h24c2.22 0 4-1.78 4-4V12L20 0Zm8 36H4V4h14v10h10v22ZM18 22c0 2.2-1.8 4-4 4s-4-1.8-4-4 1.8-4 4-4 4 1.8 4 4Zm4 10v2H6v-2c0-2.66 5.34-4 8-4s8 1.34 8 4Z"
      fill="#44A2DA"
    />
  </Svg>
);
export default SvgFileAccountOutline;
