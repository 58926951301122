import React from 'react';
import { useLocation } from 'react-router';
import { DNAButton, DNAText } from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { useDispatch } from 'src/state/redux';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import { EDITOR_TYPE, presentationBuilderActions } from 'src/state/redux/slice/PresentationBuilder/PresentationBuilder';
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';
import { useAllFolders, useAllFoldersInstance } from 'src/state/redux/selector/folder';
import { isSharedEditableFolder } from 'src/utils/foldersHelpers';
import { useCurrentUser } from 'src/state/redux/selector/user';

type DeviceModeButtonProps = Record<DeviceMode, DNAButtonProps>

const baseButtonProps: DNAButtonProps = {
  appearance: 'outline',
  status: 'tertiary',
  size: 'md',
  padding: 'sm',
  iconLeft: 'view-grid-plus',
}

const deviceModeButtonHeaderProps:DeviceModeButtonProps = {
  desktop: {
    ...baseButtonProps,
    size: 'lg',
  },
  tablet: {
    padding:'none',
    rounded:'full',
    appearance:'ghostLink',
    status:'dark',
    size:'xl',
    iconLeft: 'view-grid-plus',
  },
}

const deviceModeButtonMyFoldersProps:DeviceModeButtonProps = {
  desktop: {
    ...baseButtonProps,
  },
  tablet: {
    ...baseButtonProps,
  },
}

export type CustomDeckButtonProps = {
  mode: 'header' | 'myFolders'
}

export const CustomDeckButton : React.FC<CustomDeckButtonProps> = (props) => {
  const dispatch = useDispatch();
  const { mode } = props;
  const ownFolders = useAllFolders();
  const sharedFolders = useAllFoldersInstance(undefined, true)
  const location = useLocation();
  const enableEditSharedFolder = useFeatureFlags('enableEditSharedFolder');
  const currentUser = useCurrentUser()
  const sharedFoldersTab = !!location?.pathname.includes('shared_folders')

  const getFolder = () => {
    const pathTokens = location.pathname.split('/').slice(1)
    if (pathTokens.length === 0 || mode === 'header') {
      return;
    }

    const folderId = pathTokens.reverse()[0];
    let folderORMFromPath = ownFolders
      .find(folderORM => folderORM.model.id === folderId)
    if (folderORMFromPath) {
      return folderORMFromPath
    }
    folderORMFromPath = sharedFolders
      .find(folderORM => folderORM.model.id === folderId)

    if (folderORMFromPath &&
        isSharedEditableFolder(folderORMFromPath, currentUser.userProfile?.id) && enableEditSharedFolder) {
      return folderORMFromPath
    }
  }
  const folder = getFolder()

  const onCreatePresentation = (): void => {
    dispatch(presentationBuilderActions.openPresentationBuilderNew({
      targetFolder: folder,
      editorType: EDITOR_TYPE.OWNER,
    }))
  };
  const { deviceMode } = useAppSettings()
  const currentDeviceModeButtonProps = mode === 'header'
    ? deviceModeButtonHeaderProps[deviceMode] : deviceModeButtonMyFoldersProps[deviceMode]

  if (sharedFoldersTab && mode === 'myFolders' && !folder) {
    return null
  }

  return (
    <DNAPopover disablePopover={ mode === 'myFolders' || ['tabletPWA']}>
      <DNAPopover.Anchor>
        <DNAButton
          onPress={onCreatePresentation}
          testID="create-presentation-btn"
          {...currentDeviceModeButtonProps}
        >
          { mode === 'myFolders' && deviceMode !== 'tablet' &&
            <DNAText testID="create-presentation-folder-btn" bold>
              Create presentation
            </DNAText>
          }

        </DNAButton>
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">Create presentation</DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  )
}

export default CustomDeckButton
