import React, { useCallback, useRef } from 'react';
import { DNABox, DNAText, luxColors } from '@alucio/lux-ui';
import { DisplayMode } from 'src/components/DNA/hooks/useDisplayMode'
/** Possible future refactor of Input to lux-ui export per Jose suggestion  */
import { Input } from '@ui-kitten/components';
import { useCanPerformSearch } from 'src/state/redux/selector/documentSearch/documentSearch';
import debounce from 'lodash/debounce';
import { DocumentORM } from 'src/types/types';
import { Options } from '../../useMyContentPanelSharedResources';
import DNAActionBar, { DNAActionBarItemProps } from 'src/components/DNA/ActionBar/DNAActionBar';
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';

interface HeaderPresenterModeProps {
  localSearchText: string,
  onSelectTab: (tab: Options) => void,
  selectedTab: Options,
  displayMode: DisplayMode,
  setLocalSearchText: (text: string) => void,
  setSearchText: (text: string) => void,
  sessionId?: string,
  selectedDocument?: DocumentORM
}

const actionBarItemDefaults: DNAActionBarItemProps = {
  status: 'dark',
  size: 'md',
  padding: 'sm',
}

const HeaderPresenterMode: React.FC<HeaderPresenterModeProps> = (props) => {
  const {
    localSearchText,
    onSelectTab,
    selectedTab,
    setLocalSearchText,
    setSearchText,
    sessionId,
  } = props;

  const getTabAppearance = (tabID: Options) => {
    const appearance: DNAButtonProps['appearance'] = selectedTab === tabID ? 'filled' : 'ghostLink'
    return appearance
  }

  const canPerformSearch = useCanPerformSearch();
  // Used to return to that tab if user clears the search input
  const tabSelectedBeforeSearch = useRef<Options>(Options.FOLDERS)
  const inputRef = useRef<Input>(null)

  const handleTabSelect = (tab: Options) => () => {
    setLocalSearchText('');
    onSelectTab(tab);
  }

  const handleTrackAction = debounce((text: string): void => {
    analytics?.track('VIRTUAL_CONTENT_SEARCH', {
      action: 'CONTENT_SEARCH',
      category: 'VIRTUAL',
      searchText: text,
      sessionId,
    });
  }, 1000);

  // IF WE DON'T MEMOIZE, ANOTHER INSTANCE OF HANDLESEARCH WILL BE CREATED UPON EVERY RENDER
  // THEREFORE, THE DEBOUNCE EFFECT WE NEED WON'T HAVE ANY EFFECT`
  const MemoizedHandleTrackAction = useCallback(handleTrackAction, [sessionId]);

  const onSearchTextChange = (text: string) => {
    const canTextBeSearchable = canPerformSearch(text);
    setLocalSearchText(text);

    if (text) {
      debounce(() => {
        setSearchText(text);
      }, 1000)();
    }

    text && MemoizedHandleTrackAction(text);

    if (canTextBeSearchable && selectedTab && selectedTab !== 'Search results') {
      tabSelectedBeforeSearch.current = selectedTab;
      onSelectTab(Options.SEARCH_RESULTS);
    } else if (!canTextBeSearchable && selectedTab === 'Search results') {
      onSelectTab(tabSelectedBeforeSearch.current);
    }
  }

  return (
    <DNABox alignY="center" style={{ paddingHorizontal: 24, justifyContent: 'space-between' }}>
      <DNABox alignY="center" style={{ paddingTop: 24, paddingBottom:16 }} appearance="col" fill spacing="md">
        <Input
          ref={inputRef}
          onChangeText={onSearchTextChange}
          placeholderTextColor={luxColors.contentPanelBackground.quinary}
          size="sm"
          value={localSearchText}
          placeholder={'Search files'}
          testID="meeting-presentations-search-input"
        />
        <DNABox>

          <DNAActionBar
            fill
            spacing="md"
            itemDefaults={actionBarItemDefaults}
          >

            <DNAActionBar.Item
              onPress={handleTabSelect(Options.FOLDERS)}
              appearance={getTabAppearance(Options.FOLDERS)}
              key={Options.FOLDERS}
              testID="meeting-my-folders-tab"
            >
              <DNAText status="basic">{Options.FOLDERS}</DNAText>
            </DNAActionBar.Item>

            <DNAActionBar.Item
              onPress={handleTabSelect(Options.SHARED_WITH_ME)}
              appearance={getTabAppearance(Options.SHARED_WITH_ME)}
              key={Options.SHARED_WITH_ME}
              testID="meeting-shared-with-me-tab"
            >
              <DNAText status="basic">{Options.SHARED_WITH_ME}</DNAText>
            </DNAActionBar.Item>

            <DNAActionBar.Item
              onPress={handleTabSelect(Options.BOOKMARKS)}
              appearance={getTabAppearance(Options.BOOKMARKS)}
              key={Options.BOOKMARKS}
              testID="meeting-bookmarks-tab"
            >
              <DNAText status="basic">{Options.BOOKMARKS}</DNAText>
            </DNAActionBar.Item>

            <DNAActionBar.Item
              onPress={handleTabSelect(Options.LIBRARY)}
              appearance={getTabAppearance(Options.LIBRARY)}
              key={Options.LIBRARY}
              testID="meeting-library-tab"
            >
              <DNAText status="basic">{Options.LIBRARY}</DNAText>
            </DNAActionBar.Item>

          </DNAActionBar>
        </DNABox>
      </DNABox>
    </DNABox>
  );
}

HeaderPresenterMode.displayName = 'ContentPanelHeader';

export default HeaderPresenterMode;
