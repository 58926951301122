import React, { useCallback, useState, useEffect } from 'react';
import {
  DNABox,
  Select,
} from '@alucio/lux-ui'
import { DocumentStatus } from '@alucio/aws-beacon-amplify/src/models';
import { DocumentORM, DocumentVersionORM } from 'src/types/types';
import { useDispatch } from 'src/state/redux';
import { useContentViewerModalState } from './state/ContentViewerModalStateProvider';
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
// TODO:  Check whhy our custom select doesnt work as expected sometimes generates unexpected exceptions

const DocumentVersionSelector: React.FC<{
    documentORM: DocumentORM,
    documentVersionORM: DocumentVersionORM,
    isPublisherMode?: boolean
}> = (props) => {
  const { documentORM, documentVersionORM, isPublisherMode } = props;
  const { latestPublishedDocumentVersionORM, latestDocumentVersionORM } = documentORM.relations.version
  const latestVersion = isPublisherMode ? latestDocumentVersionORM : latestPublishedDocumentVersionORM;

  const [currentDocumentVersionORM, setCurrentDocumentVersionORM] = useState(latestVersion || documentVersionORM);
  const { activeDocVersionORM, setActiveDocVersionORM } = useContentViewerModalState()
  const dispatch = useDispatch()

  const documentVersions = isPublisherMode
    ? documentORM.relations.documentVersions
    : documentORM.relations.documentVersions.filter(document => document.model.status === DocumentStatus.PUBLISHED);
  const disableSelect = documentVersions.length === 1;

  const formatVersionLabel = (docVerORM: DocumentVersionORM): string => {
    const { latestPublishedDocumentVersionORM } = documentORM.relations.version;
    let label = `Version ${docVerORM.meta.version.semVerLabel}`;

    if (latestPublishedDocumentVersionORM?.model.id === docVerORM.model.id) {
      label += ' (Current Version)'
    }
    else if (docVerORM.model.status === DocumentStatus.NOT_PUBLISHED) {
      label += ' (Draft in progress)';
    }

    return label;
  }

  const onVersionChanged = (e) => {
    const { row } = e;
    const version = documentVersions[row];
    setActiveDocVersionORM(version);
    dispatch(
      contentPreviewModalActions.changeDocument({
        documentVersionId: version.model.id,
        addToHistory: false,
      },
      ));
  }

  useEffect(
    () => setCurrentDocumentVersionORM(activeDocVersionORM),
    [activeDocVersionORM],
  )

  const selectedValue = useCallback(
    () => {
      const versionLabel = formatVersionLabel(currentDocumentVersionORM);
      return versionLabel
    },
    [documentORM.relations.version.latestDocumentVersionORM, currentDocumentVersionORM],
  )

  return (
    <DNABox testID="document-version-drop-down" appearance="col" style={{ marginHorizontal: 16, marginTop: 8 }} fill>
      <Select
        style={{ minWidth: 190 }}
        value={selectedValue()}
        onSelect={onVersionChanged}
        disabled={disableSelect}
      >
        {
          documentVersions.map((docVerORM) => {
            const formattedLabel = formatVersionLabel(docVerORM)

            return (
              <Select.Item
                key={formattedLabel}
                title={formattedLabel}
              />
            )
          })
        }
      </Select>
    </DNABox>
  )
}

export default DocumentVersionSelector;
