import React from 'react'
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import {
  MeetingButtonProps,
  useMeetingButtonSharedComponents,
} from '../MeetingButton';

interface ContentPreviewModalVariantProps extends MeetingButtonProps {}

export const ContentPreviewModalVariant: React.FC<ContentPreviewModalVariantProps> = () => {
  const { activePresentation } = useContent()
  const {
    meetingButtonComponents,
  } = useMeetingButtonSharedComponents(activePresentation)

  return meetingButtonComponents
}
