import { CRMIntegration, CrmIntegrationType } from '@alucio/aws-beacon-amplify/src/models';
import {
  CRMAccount,
  LayoutSection,
  ObjectInfo,
  SALESFORCE_FIELD_DATA_TYPE,
  SalesforceFormFetchResponse,
  SalesforceFormSettings,
} from '../CRMIndexedDBTypes';
import { SYNC_ENTRY_FETCH_TYPE, SYNC_ENTRY_REQUEST_TYPE, SYNC_ENTRY_STATUS, SyncEntry } from '../ISyncManifest';
import { TABLES } from '../Translators/VeevaTranslatorUtils';
import { CRMIntegrationSession } from 'src/state/machines/CRM/util';
import { Singleton as IndexDbCrm } from '../CRMIndexedDB';
const CRMDB = IndexDbCrm;

// MANUALLY CREATES THE FORM SETTING FOR A FOLLOWUP (TASK)
export function getFollowUpsFormSetting(users, subjects, accounts: CRMAccount[],
  crmUser: CRMIntegrationSession['authInformation'], crmConfig: CRMIntegration): SalesforceFormSettings {
  const formConfig: SalesforceFormSettings = {
    id: 'Task',
    relationshipWithParentApiName: 'WhatId',
    apiName: 'Task',
    label: 'Follow-up Activities',
    picklists: {},
    parentTablesRelationship: {
      Call2_vod__c: 'WhatId',
    },
    ignore: true,
    lookups: {},
    layout: {
      sections: getFollowUpLayoutSections(crmConfig),
      id: 'Task',
      objectApiName: 'Task',
      recordTypeId: '',
    },
    objectInfos: getTaskObjectInfo(),
    defaultRecord: {
      apiName: 'Task',
      fields: {
        OwnerId: {
          displayValue: crmUser.userInfo.displayName,
          value: crmUser.userInfo.id,
        },
      },
      recordTypeId: '',
      id: null,
    },
  }

  const userLanguage = users.find(({ Id }) => Id === crmUser.userInfo.id)?.LanguageLocaleKey;
  // FILTER OUT THE SUBJECTS FROM DIFFERENT LANGUAGES
  subjects = userLanguage ? subjects.filter(({ Language_vod__c }) =>
    Language_vod__c === userLanguage || !Language_vod__c) : subjects;
  const defaultSubject = subjects.find((subject) => subject.Default_Task_vod__c);
  formConfig.picklists.Followup_Activity_Type_vod__c = {
    controllerValues: {},
    defaultValue: defaultSubject ? {
      label: defaultSubject.Name,
      value: defaultSubject.Id,
      validFor: [],
    } : null,
    values: subjects.map((subject) => ({
      label: subject.Name,
      value: subject.Id,
      validFor: [],
    })),
  };

  if (defaultSubject) {
    formConfig.defaultRecord.fields.Followup_Activity_Type_vod__c = {
      displayValue: defaultSubject.Name,
      value: defaultSubject.Id,
    };
  }

  formConfig.picklists.OwnerId = {
    controllerValues: {},
    defaultValue: null,
    values: users.map((user) => ({
      label: user.Name,
      value: user.Id,
      validFor: [],
    })),
  };

  formConfig.picklists.WhoId = {
    controllerValues: {},
    defaultValue: null,
    values: [],
  };

  return formConfig;
}

// RETURNS A MANUALLY FORMED LAYOUT FOR FOLLOWUPS (TASK)
function getFollowUpLayoutSections(crmConfig: CRMIntegration): LayoutSection[] {
  return [{
    heading: 'Follow-up Activities',
    id: 'Task',
    columns: 2,
    layoutRows: [{
      layoutItems: [{
        editableForNew: true,
        editableForUpdate: true,
        label: 'Subject',
        layoutComponents: [
          {
            apiName: 'Followup_Activity_Type_vod__c',
            componentType: 'Field',
            label: 'Subject',
          },
        ],
        lookupIdApiName: null,
        required: true,
        sortable: false,
      }, {
        editableForNew: true,
        editableForUpdate: true,
        label: 'Assigned To',
        layoutComponents: [
          {
            apiName: 'OwnerId',
            componentType: 'Field',
            label: 'Assigned To',
          },
        ],
        lookupIdApiName: null,
        required: true,
        sortable: false,
      }],
    }, {
      layoutItems: [{
        editableForNew: true,
        editableForUpdate: true,
        label: 'Name',
        layoutComponents: [
          {
            apiName: 'WhoId',
            componentType: 'Field',
            label: 'Name',
          },
        ],
        lookupIdApiName: null,
        required: true,
        sortable: false,
      }, {
        editableForNew: true,
        editableForUpdate: true,
        label: 'Date',
        layoutComponents: [
          {
            apiName: 'ActivityDate',
            componentType: 'Field',
            label: 'Date',
          },
        ],
        lookupIdApiName: null,
        required: true,
        sortable: false,
      }],
    }, {
      layoutItems: [{
        editableForNew: !!crmConfig.additionalSettings?.allowCommentsOnTasks,
        editableForUpdate: !!crmConfig.additionalSettings?.allowCommentsOnTasks,
        label: 'Comments',
        layoutComponents: [
          {
            apiName: 'Description',
            componentType: 'Field',
            label: 'Comments',
          },
        ],
        lookupIdApiName: null,
        required: false,
        sortable: false,
      }],
    }],
  }];
}

// RETURNS A MANUALLY FORMED OBJECT INFO FOR FOLLOWUPS (TASK)
function getTaskObjectInfo(): ObjectInfo {
  const baseField = {
    controllerName: null,
    controllingFields: [],
    createable: true,
    updateable: true,
    inlineHelpText: null,
    length: 0,
    reference: false,
    referenceToInfos: [],
    relationshipName: null,
    required: true,
  };

  return {
    apiName: 'Task',
    recordTypeInfos: {},
    childRelationships: [],
    createable: true,
    custom: false,
    dependentFields: {},
    deletable: true,
    fields: {
      Followup_Activity_Type_vod__c: {
        ...baseField,
        apiName: 'Followup_Activity_Type_vod__c',
        dataType: SALESFORCE_FIELD_DATA_TYPE.Picklist,
        label: 'Subject',
      },
      OwnerId: {
        ...baseField,
        apiName: 'OwnerId',
        dataType: SALESFORCE_FIELD_DATA_TYPE.Picklist,
        label: 'Assigned To',
      },
      WhatId: {
        ...baseField,
        apiName: 'WhatId',
        dataType: SALESFORCE_FIELD_DATA_TYPE.Reference,
        label: 'Related To',
      },
      WhoId: {
        ...baseField,
        apiName: 'WhoId',
        dataType: SALESFORCE_FIELD_DATA_TYPE.Picklist,
        label: 'Name',
      },
      ActivityDate: {
        ...baseField,
        apiName: 'ActivityDate',
        dataType: SALESFORCE_FIELD_DATA_TYPE.Date,
        label: 'Date',
      },
      Description: {
        ...baseField,
        apiName: 'Description',
        dataType: SALESFORCE_FIELD_DATA_TYPE.TextArea,
        length: 10000,
        label: 'Comments',
        required: false,
      },
    },
    label: 'Follow-up Activities',
    nameFields: [],
    updateable: true,
  };
}

interface LookupOverride {
  [tableName: string]: {
    [fieldName: string]: (params: LookupParams) => SyncEntry[]
  }
}

interface LookupsToIgnore {
  [tableName: string]: string[]
}

interface LookupParams {
  apiName: string,
  lookupApiName: string,
  lookupTableName: string,
  parentId: string,
  baseUrl: string,
  userId: string
}

export const VEEVA_LOOKUP_OVERRIDE: LookupOverride = {
  [TABLES.MEDICAL_DISCUSSION]: {
    Product_vod__c: handleProductsLookup,
  },
  [TABLES.CALL_DETAIL]: {
    Product_vod__c: handleProductsGroupLookup,
  },
  [TABLES.MEDICAL_INQUIRY]: {
    Account_vod__c: handleAccountsLookupPlaceholder,
  },
  [TABLES.MEDICAL_INSIGHT]: {
    Account_vod__c: handleAccountsLookupPlaceholder,
  },
}

// THESE LOOKUPS WILL BE IGNORED AS THEY WILL BE HANDLED BY OURSELVES
// (NO NEED TO PERFORM A SQL QUERY TO GET THESE RECORDS)
export const VEEVA_LOOKUPS_TO_IGNORE: LookupsToIgnore = {
  [TABLES.CALL_DETAIL]: ['Call2_vod__c'],
  [TABLES.MEDICAL_DISCUSSION]: ['Interaction_vod__c'],
  [TABLES.CALL_DISCUSSION]: ['Account_vod__c', 'Call2_vod__c', 'Product_vod__c',
    'Detail_Group_vod__c', 'Product_Tactic_vod__c', 'Product_Strategy_vod__c'],
  [TABLES.CALL]: ['Account_vod__c', 'Account_Plan_vod__c', 'Parent_Address_vod__c'],
  [TABLES.MEDICAL_INSIGHT]: ['Interaction_vod__c'],
}

export const VEEVA_SECTION_TO_IGNORE = [TABLES.CALL_DETAIL];

// RETURNS AN ARRAY OF ENTRIES WHERE EACH REPRESENT A MODIFIED LOOKUP
export function handleCustomLookupEntry(params: LookupParams): SyncEntry[] {
  if (VEEVA_LOOKUP_OVERRIDE[params.apiName]?.[params.lookupApiName]) {
    return VEEVA_LOOKUP_OVERRIDE[params.apiName][params.lookupApiName](params);
  }
  return [];
}

// RETURNS A LOOKUP PLACEHOLDER FOR ACCOUNTS SO THE FORM SETTING HAS IT ON THEIR SETTINGS
function handleAccountsLookupPlaceholder(params: LookupParams): SyncEntry[] {
  const entry = getEntrySkeleton(params);
  return [{
    ...entry,
    url: `${params.baseUrl}/query/?q=SELECT FIELDS(ALL) FROM Account LIMIT 0`,
  }];
}

const ONLY_PRODUCTS_QUERY = 'SELECT Id, Name FROM Product_vod__c ' +
  'WHERE Id IN (SELECT Product_vod__c FROM My_Setup_Products_vod__c ' +
  'WHERE OwnerId = {UserId}) AND Product_Type_vod__c = \'Detail\'';

// RETURNS A LOOKUP WHERE THE QUERY INCLUDES THE FILTERING FOR THE USERS PRODUCT
function handleProductsLookup(params: LookupParams): SyncEntry[] {
  const query = ONLY_PRODUCTS_QUERY.replace('{UserId}', `'${params.userId}'`);
  const entry = getEntrySkeleton(params);

  return [{
    ...entry,
    url: `${params.baseUrl}/query/?q=${query}`,
  }];
}

// USED FOR PRODUCT DETAILING
const AVAILABLE_PRODUCTS_AND_GROUPS_QUERY = 'SELECT Id, Name, Product_Type_vod__c FROM Product_vod__c ' +
  'WHERE Id IN (SELECT Product_vod__c FROM My_Setup_Products_vod__c ' +
  'WHERE OwnerId = {UserId}) AND No_Details_vod__c = false';

const PRODUCT_GROUP_RELATIONSHIP = 'SELECT Product_Catalog_vod__c, Product_vod__c FROM Product_Group_vod__c ' +
  'WHERE Product_vod__c IN (SELECT Product_vod__c FROM My_Setup_Products_vod__c ' +
  'WHERE OwnerId = {UserId})';

// RETURNS A LOOKUP WHERE THE QUERY INCLUDES THE USERS PRODUCT AND THEIR GROUPS
function handleProductsGroupLookup(params: LookupParams): SyncEntry[] {
  const productsQuery = AVAILABLE_PRODUCTS_AND_GROUPS_QUERY.replace('{UserId}', `'${params.userId}'`);
  const productsGroupsQuery = PRODUCT_GROUP_RELATIONSHIP.replace('{UserId}', `'${params.userId}'`);
  const entry = getEntrySkeleton(params);

  return [{
    ...entry,
    url: `${params.baseUrl}/query/?q=${productsQuery}`,
  }, {
    ...entry,
    url: `${params.baseUrl}/query/?q=${productsGroupsQuery}`,
    parameters: {
      apiName: TABLES.CALL_DETAIL,
      lookupApiName: 'Product_Group_vod__c',
      parentId: params.parentId,
    },
    id: `${params.apiName}_Detail_Group_vod__c`,
  }];
}

// USED FOR PRODUCT DISCUSSIONS
const AVAILABLE_PRODUCT_PLANS = 'SELECT Name, Id, Product_vod__c, Detail_Group_vod__c FROM Product_Plan_vod__c ' +
  'WHERE Product_vod__c IN (SELECT Product_vod__c FROM My_Setup_Products_vod__c ' +
  'WHERE OwnerId = {UserId})';

const PRODUCT_STRATEGIES = 'SELECT Name, Id, Product_Plan_vod__c FROM Product_Strategy_vod__c ' +
  'WHERE Product_Plan_vod__c IN ({ProductPlans}) AND Active_vod__c = true';

const PRODUCT_TACTICS = 'SELECT Name, Id, Product_Strategy_vod__c FROM Product_Tactic_vod__c ' +
  'WHERE Product_Strategy_vod__c IN ({ProductStrategies})';

// FETCHES THE PRODUCTS PLANS/STRATEGIES/TACTICS
export async function handleCallDiscussionSetup(
  fetchSOQL: (entry: SyncEntry) => Promise<SyncEntry>,
  baseUrl: string,
  crmUser: CRMIntegrationSession['authInformation'],
  tableInfo: SalesforceFormFetchResponse,
  recordTypeId?: string): Promise<void> {
  const parentId = `${tableInfo.layout.objectApiName}${recordTypeId ? `_${recordTypeId}` : ''}`;
  const syncEntryBase = {
    type: CrmIntegrationType.SALESFORCE,
    subType: SYNC_ENTRY_REQUEST_TYPE.LOOKUP,
    fetchType: SYNC_ENTRY_FETCH_TYPE.SOQL,
    lastSynced: new Date().getTime(),
    status: SYNC_ENTRY_STATUS.PENDING,
    parameters: {},
  };

  const productPlansQueryEntry: SyncEntry = {
    ...syncEntryBase,
    url: `${baseUrl}/query/?q=${AVAILABLE_PRODUCT_PLANS.replace('{UserId}', `'${crmUser.userInfo.id}'`)}`,
    id: 'VeevaProductPlans',
    parameters: {
      apiName: parentId,
      lookupApiName: 'Product_Plan_vod__c',
      parentId,
    },
  }

  const productPlansResponse = await fetchSOQL(productPlansQueryEntry);
  const productPlans = productPlansResponse.data.records;
  const plansIdsForQuery = getRecordsIdsForQuery(productPlans);

  const productStrategiesQueryEntry: SyncEntry = {
    ...syncEntryBase,
    url: `${baseUrl}/query/?q=${PRODUCT_STRATEGIES.replace('{ProductPlans}', plansIdsForQuery)}`,
    id: 'VeevaProductStrategies',
    parameters: {
      apiName: parentId,
      lookupApiName: 'Product_Strategy_vod__c',
      parentId,
    },
  }

  const productStrategiesResponse = await fetchSOQL(productStrategiesQueryEntry);
  const productStrategies = productStrategiesResponse.data.records;
  const strategiesIdsForQuery = getRecordsIdsForQuery(productStrategies);

  const productTacticsQueryEntry: SyncEntry = {
    ...syncEntryBase,
    url: `${baseUrl}/query/?q=${PRODUCT_TACTICS.replace('{ProductStrategies}', strategiesIdsForQuery)}`,
    id: 'VeevaProductTactics',
    parameters: {
      apiName: parentId,
      lookupApiName: 'Product_Tactic_vod__c',
      parentId,
    },
  }

  const productTacticsResponse = await fetchSOQL(productTacticsQueryEntry);

  // ADDS THE PRODUCTS TACTICS/PLANS/STRATEGIES TO INDEXDB
  await Promise.all([
    CRMDB.addSyncEntry(productTacticsResponse),
    CRMDB.addSyncEntry(productStrategiesResponse),
    CRMDB.addSyncEntry(productPlansResponse),
  ]);
}

function getRecordsIdsForQuery(records): string {
  return records?.reduce((acc, { Id }, idx) => {
    const isLastRecord = records.length === idx + 1;
    return acc + `'${Id}'` + (isLastRecord ? '' : ', ');
  }, '') || '';
}

// PRODUCTS WILL BE USED FROM CALL DETAILS SO THIS IS A PLACEHOLDER FOR DISCUSSIONS
export async function handleCallDiscussionProducts(
  tableInfo: SalesforceFormFetchResponse,
  recordTypeId?: string): Promise<void> {
  const parentId = `${tableInfo.layout.objectApiName}${recordTypeId ? `_${recordTypeId}` : ''}`;
  const emptyProductsEntry: SyncEntry = {
    type: CrmIntegrationType.SALESFORCE,
    subType: SYNC_ENTRY_REQUEST_TYPE.LOOKUP,
    fetchType: SYNC_ENTRY_FETCH_TYPE.SOQL,
    lastSynced: new Date().getTime(),
    url: '',
    id: '',
    data: {
      records: [],
    },
    status: SYNC_ENTRY_STATUS.SYNCED,
    parameters: {
      apiName: parentId,
      lookupApiName: 'Product_vod__c',
      parentId,
    },
  }

  await CRMDB.addSyncEntry(emptyProductsEntry);
}

function getEntrySkeleton(params: LookupParams): SyncEntry {
  return {
    url: '',
    type: CrmIntegrationType.SALESFORCE,
    subType: SYNC_ENTRY_REQUEST_TYPE.LOOKUP,
    parameters: { apiName: params.apiName, lookupApiName: params.lookupTableName, parentId: params.parentId },
    fetchType: SYNC_ENTRY_FETCH_TYPE.SOQL,
    id: `${params.apiName}_${params.lookupApiName}`,
    lastSynced: new Date().getTime(),
    status: SYNC_ENTRY_STATUS.PENDING,
  }
}
