import { saveAs } from 'file-saver';

import { FileType } from '@alucio/aws-beacon-amplify/src/models'
import { getImageFromCloudfront, getContentFromCloudfront, downloadRecordingFromCloudfront } from './common'

/**
 * Processes the video mime types based on the file extension.
 * @param {string} fileName - The name of the file.
 * @return {string} The processed mime type.
 */
const getMimeTypeForVideoFile = (fileName: string): string => {
  const extension = fileName.split('.').pop();
  let mimeType = 'unknown';
  if (extension === 'mp4') {
    mimeType = 'video/mp4';
  }
  else if (extension === 'webm') {
    mimeType = 'video/webm';
  }
  else if (extension === 'mpeg') {
    mimeType = 'video/mpeg';
  }
  else if (extension === 'mkv') {
    mimeType = 'video/x-matroska';
  }
  else if (extension === 'avi') {
    mimeType = 'video/x-msvideo';
  }
  else if (extension === 'mov') {
    mimeType = 'video/quicktime';
  }
  return mimeType;
}

async function downloadContentFromCloudfront(
  key: string,
  fileName: string,
  fileType: FileType | keyof typeof FileType,
): Promise<void> {
  const content = await getContentFromCloudfront(key, { 'Alucio-Action': 'download' });
  let mimeType = 'application/octet-stream'
  if (fileType === FileType.PPTX) {
    mimeType = 'application/vnd.openxmlformats-officedocument.presentationml'
  } else if (fileType === FileType.PDF) {
    mimeType = 'application/pdf'
  } else if (fileType === FileType.PNG) {
    mimeType = 'image/png'
  } else if (fileType === FileType.MP4) {
    mimeType = getMimeTypeForVideoFile(fileName);
  }
  const fileBlob = new Blob([new Uint8Array(content)], { type: mimeType })
  saveAs(fileBlob, fileName);
}

export {
  getImageFromCloudfront,
  downloadContentFromCloudfront,
  downloadRecordingFromCloudfront,
}
