import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNADivider, DNAText, Iffy, util } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { MeetingType } from '@alucio/aws-beacon-amplify/src/models';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import SpeakerNotes from './SpeakerNotes';
import CustomNotes from './CustomNotes/CustomNotes';
import MeetingNotes from './MeetingNotes';

export const styles = StyleSheet.create({
  notesContainer: {
    backgroundColor: colors['color-gray-900'],
    borderColor: colors['color-gray-600'],
    borderLeftWidth: 1,
    width: 320,
  },
  headerContainer: {
    height: 56,
    paddingHorizontal: 16,
  },
});

export enum NOTES {
  SPEAKER_NOTES,
  MEETING_NOTES,
  CUSTOM_NOTES,
}

interface Props {
  openNotes: NOTES[]
}

const Notes: React.FC<Props> = ({ openNotes }) => {
  const { meetingORM, notesPanelVisible, toggleAllNotes } = useMeetingsState();
  const isInPerson = meetingORM?.model.type === MeetingType.IN_PERSON;
  const bodyContainerRef = useRef<null | HTMLDivElement>(null);
  const [containerDimension, setContainerDimension] = useState({ height: 0, width: 0 });
  const [childContainerDimension, setChildContainerDimension] = useState({ height: 0, width: 0 });
  const featureFlags = useFeatureFlags('enableCustomNotes', 'enableMeetingNotes')

  const updateSize = () => {
    if (bodyContainerRef.current) {
      const { height, width } = bodyContainerRef.current.getBoundingClientRect();
      setContainerDimension({ height, width });
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  useEffect(() => {
    if (notesPanelVisible && !containerDimension.width) updateSize()
  }, [notesPanelVisible, containerDimension])

  useEffect(() => {
    if (containerDimension.width && openNotes.length) {
      let totalNoteTabs = 1
      if (featureFlags.enableCustomNotes) totalNoteTabs++
      if (featureFlags.enableMeetingNotes) totalNoteTabs++
      const newChildContainerHeight = (containerDimension.height - (56 * totalNoteTabs)) / openNotes.length
      setChildContainerDimension({ width: containerDimension.width, height: newChildContainerHeight })
    }
  }, [containerDimension, openNotes])

  if ((isInPerson && !openNotes.length) || (!isInPerson && !notesPanelVisible)) return null
  return (
    <DNABox
      style={util.mergeStyles(undefined,
        styles.notesContainer,
        [{ paddingBottom: 40 }, isInPerson],
      )}
      appearance="col"
    >
      {/* Virtual Meeting Header */}
      <Iffy is={!isInPerson}>
        <DNABox alignY="center" style={styles.headerContainer}>
          <DNABox fill alignX="center" alignY="center">
            <DNAText status="basic">NOTES</DNAText>
          </DNABox>
          <DNAButton
            status="dark"
            iconLeft="close"
            padding="sm"
            colorMode="dark"
            onPress={toggleAllNotes}
          />
        </DNABox>
        <DNADivider variant="virtual" />
      </Iffy>
      {/* Body */}
      <DNABox fill appearance="col" ref={bodyContainerRef}>
        <Iffy is={!isInPerson}>
          <SpeakerNotes openNotes={openNotes} containerDimension={childContainerDimension}/>
        </Iffy>
        <Iffy is={featureFlags.enableCustomNotes}>
          <CustomNotes openNotes={openNotes} containerDimension={childContainerDimension}/>
        </Iffy>
        <Iffy is={featureFlags.enableMeetingNotes}>
          <MeetingNotes openNotes={openNotes} containerDimension={childContainerDimension}/>
        </Iffy>
      </DNABox>
    </DNABox>
  );
}

export default Notes;
