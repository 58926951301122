import React from 'react'
import { DNABox, DNAButton, DNAText } from '@alucio/lux-ui'
import { useAppSettings } from 'src/state/context/AppSettings';
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import { useUserTenant } from 'src/state/redux/selector/user';
import { MEETING_SUBMIT_TYPE } from './AddMeetingProvider';
import { useActor } from '@xstate/react';
import { CRMConnectionStatus, CRMSyncStatus } from 'src/state/machines/CRM/crmMachineTypes';

interface Props {
  errorMessage?: string;
  isValid: boolean;
  onSaveForm?: (submitType?: MEETING_SUBMIT_TYPE, overrideErrorMessage?: string) => Promise<void>
}

const MeetingCRMAuth = (props: Props) => {
  const { errorMessage, isValid, onSaveForm } = props;
  const currentTenant = useUserTenant();
  const { crmMachine } = useAppSettings();
  const { syncStatus, isInSessionError, displayCRMConnectButton } = useCRMStatus();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, send] = useActor(crmMachine);

  const isInError = errorMessage || syncStatus === CRMSyncStatus.ERROR;

  const msg =  isInError
    ? errorMessage || 'CRM is disconnected, please reconnect. If the issue persists, please contact support.'
    : 'Your organization has enabled CRM integration. Please log into your CRM to sync the latest data.';

  const btnMsg = isInError
    ? 'Reconnect to CRM'
    : 'Connect to CRM';

  if (!displayCRMConnectButton) return null;

  const handleConnect = async () => {
    await onSaveForm?.(MEETING_SUBMIT_TYPE.SILENT_SAVE, 'Please correct the error(s) below before connecting to CRM.');
    if (!isValid && !isInSessionError) return;
    send({
      type: 'LOGIN_CRM',
      payload: currentTenant?.config.crmIntegration,
    })
  }

  return (
    <DNABox
      appearance="col"
      spacing="md"
      alignX="center"
      alignY="center"
      fill
      style={{ backgroundColor: 'white', minHeight: 172 }}
    >
      <DNAText status={isInError ? 'danger' : 'flat'}>
        {msg}
      </DNAText>
      <DNAButton onPress={handleConnect}>
        {btnMsg}
      </DNAButton>
    </DNABox>
  )
}

export const useMeetingCRMCustomFieldVisibility = (): boolean => {
  const currentTenant = useUserTenant();
  const { connectionStatus } = useCRMStatus();

  if (!currentTenant?.config.crmIntegration) return true;
  if (connectionStatus !== CRMConnectionStatus.CONNECTED) return false;

  return true;
}

export default MeetingCRMAuth
