import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import {
  DNABox,
  DNAButton,
  DNAConditionalWrapper,
  DNAContextMenu,
  DNAIcon,
  DNAText,
} from '@alucio/lux-ui';
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { FileType, MeetingType, Sentiment } from '@alucio/aws-beacon-amplify/src/models';
import { isDocumentVersionORM, isFolderItemORM } from 'src/types/typeguards';
import { Tab } from 'src/screens/Meetings/PresentationControls/PresentationControls';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { useHighlighter } from 'src/components/Highlighter/HighlighterProvider';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import PresentationNav from 'src/screens/Meetings/SharedComponents/PresentationNav';
import OpenedPresentations from
  'src/screens/Meetings/PresentationControls/TabContent/OpenedPresentations/OpenedPresentations';
import { CanvasVariantEnum } from 'src/components/Highlighter/UseCanvas';
import SentimentsIcon from 'src/assets/icons/SentimentsIcon';
import { getPresentationVirtualStatus } from '../../util';

const S = StyleSheet.create({
  icon: {
    width: 16,
    height: 16,
  },
  activePositiveIcon: {
    backgroundColor: colors['color-success-500'],
  },
  activeNegativeIcon: {
    backgroundColor: colors['color-warning-500'],
  },
});

export const useVirtualnActionBarComponents = () => {
  const {
    meetingORM,
    setCurrentTab,
    endMeeting,
    setPresentationControlsVisible,
    slideRollVisible,
    toggleSlideRollVisibility,
    setSlideRollVisible,
    textSearchVisible,
    toggleTextSearchVisibility,
    canEndMeeting,
    notesPanelVisible,
    toggleAllNotes,
    toggleMeetingType,
    updateReaction,
    updateFollowUp,
    presentedMeta,
    focusPopoutWindow,
  } = useMeetingsState();

  const featureFlags = useFeatureFlags(
    'enableMeetingNotes',
    'enableMeetingReactions',
    'enableMeetingFollowUp',
    'enableNew3PC',
  );

  const { presentations, activePresentation, isPlayerReady } = useContent();
  const { orm } = { ...activePresentation?.presentable };
  const [showPresentations, setShowPresentations] = useState<boolean>(false);
  const presentationVirtualStatus = useMemo(() => getPresentationVirtualStatus(orm), [orm]);
  const isVirtual = meetingORM?.model.type === MeetingType.VIRTUAL;

  useEffect(() => {
    // WHEN SWITCHING DOCUMENT, RESET THE HIGHLIGHTER MODE
    setHighlighterVisible(false)
  }, [activePresentation?.presentable.id])

  const buttonDefaults: DNAButtonProps = {
    appearance: 'ghost',
    colorMode: 'dark',
    size: 'sm',
    status: 'dark',
    padding: 'sm',
  };

  const iconButtonProps = {
    style: {
      paddingHorizontal: 0,
      height: 32,
      width: 32,
    },
  };

  const currentORM = isDocumentVersionORM(orm)
    ? orm
    : isFolderItemORM(orm)
      ? orm.relations.itemORM
      : undefined

  const isWebVideoHTMLDoc = isDocumentVersionORM(currentORM) && [
    FileType.WEB,
    FileType.MP4,
    FileType.HTML,
  ].includes(FileType[currentORM?.model.type]);

  const { setHighlighterMode, selectedType, setHighlighterVisible } = useHighlighter();

  const openSidePanel = (tab?: Tab) => {
    setSlideRollVisible(false);
    setPresentationControlsVisible(true);
    if (tab) setCurrentTab(tab)
  };

  const toggleShowPresentations = () => {
    setShowPresentations((showPresentations) => !showPresentations)
  };

  const toggleMeetingTypeTooltipMessage =
    !isPlayerReady
      ? `${isVirtual ? 'Exit' : ''} Presenter View ${!isVirtual ? 'available' : ''} once document is loaded`
      : (presentationVirtualStatus.isHTMLWebPresentation && presentationVirtualStatus.message) || 'Exit Presenter View';
  const presentationNav = !isWebVideoHTMLDoc && !!presentations.length && <PresentationNav />;

  const myContentButton = (
    <DNAButton
      {...buttonDefaults}
      onPress={() => openSidePanel('BROWSE_CONTENT')}
      testID="my-content-button"
    >
      My Content
    </DNAButton>
  );

  const presentationsButton = !!presentations.length && (
    <DNAPopover
      lazyMount
      placement="top"
      interactive={true}
      visible={showPresentations}
      onBackdropPress={toggleShowPresentations}
      type="menu"
      offset={30}
    >
      <DNAPopover.Anchor>
        <DNAButton
          {...buttonDefaults}
          appearance={showPresentations ? 'filled' : 'ghost'}
          status={showPresentations ? 'primary' : 'dark'}
          onPress={toggleShowPresentations}
          testID="files-button"
        >
          Opened Files
        </DNAButton>
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <OpenedPresentations
          variant="actionBar"
          onClose={toggleShowPresentations}
        />
      </DNAPopover.Content>
    </DNAPopover>
  );

  const slidesButton = !!activePresentation && !isWebVideoHTMLDoc && !!presentations.length && (
    <DNAButton
      {...buttonDefaults}
      appearance={slideRollVisible ? 'filled' : 'ghost'}
      status={slideRollVisible ? 'primary' : 'dark'}
      onPress={toggleSlideRollVisibility}
      testID="in-person-meeting-slides-btn"
    >
      Slides
    </DNAButton>
  );

  const meetingDetailsButton = (
    <DNAContextMenu placement="top" style={{ bottom: 8 }}>
      <DNAContextMenu.Anchor>
        <DNAButton
          {...buttonDefaults}
          {...iconButtonProps}
          iconLeft="dots-vertical"
        />
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        <DNAContextMenu.Item
          icon="calendar-edit"
          title="Meeting Details"
          onPress={() => openSidePanel('MEETING_DETAILS')}
        />
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )

  const highlighterContextMenu = !!activePresentation && (
    <DNAConditionalWrapper
      condition={!selectedType}
      wrapper={children => (
        <DNAPopover
          placement="top"
          type="tooltip"
          offset={30}
          disablePopover={['tabletPWA']}
        >
          <DNAPopover.Anchor>
            <DNAContextMenu placement="top" style={{ bottom: 8 }}>
              <DNAContextMenu.Anchor>
                {children}
              </DNAContextMenu.Anchor>
              <DNAContextMenu.Items>
                <DNAContextMenu.Item
                  icon="arrow-top-right"
                  title="Arrow"
                  onPress={() => setHighlighterMode(CanvasVariantEnum.arrow)}
                  testID="arrow-btn"
                />
                <DNAContextMenu.Item
                  icon="checkbox-blank"
                  title="Box highlighter"
                  onPress={() => setHighlighterMode(CanvasVariantEnum.square)}
                  testID="box-btn"
                />
              </DNAContextMenu.Items>
            </DNAContextMenu>
          </DNAPopover.Anchor>
          <DNAPopover.Content>
            <DNAText status="basic">Highlighter</DNAText>
          </DNAPopover.Content>
        </DNAPopover>
      )}
    >
      <DNAButton
        {...buttonDefaults}
        {...iconButtonProps}
        appearance={selectedType ? 'filled' : 'ghost'}
        status={selectedType ? 'primary' : 'dark'}
        key="Marker"
        testID="toggle-marker-button"
        iconLeft="marker"
        onPress={() => {
          if (selectedType) setHighlighterVisible(false)
        }}
      />
    </DNAConditionalWrapper>
  );

  const textSearchButton = activePresentation && !isWebVideoHTMLDoc && !!presentations.length && (
    <DNAPopover
      placement="top"
      type="tooltip"
      offset={30}
      disablePopover={['tabletPWA']}
    >
      <DNAPopover.Anchor>
        <DNAButton
          {...buttonDefaults}
          {...iconButtonProps}
          appearance={textSearchVisible ? 'filled' : 'ghost'}
          status={textSearchVisible ? 'primary' : 'dark'}
          onPress={toggleTextSearchVisibility}
          testID="in-person-meeting-search-in-document-btn"
          iconLeft="magnify"
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">Search</DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  );

  const positiveIcon = (
    <DNABox
      alignX="center"
      alignY="center"
      style={presentedMeta?.sentiment === Sentiment.POSITIVE && S.activePositiveIcon}
    >
      <DNAIcon.Styled
        appearance="ghost"
        name={'chevron-up'}
        status={presentedMeta?.sentiment === Sentiment.POSITIVE ? 'dark' : 'tertiary'}
      />
    </DNABox>
  );

  const negativeIcon = (
    <DNABox
      alignX="center"
      alignY="center"
      style={presentedMeta?.sentiment === Sentiment.NEGATIVE && S.activeNegativeIcon}
    >
      <DNAIcon.Styled
        appearance="ghost"
        name={'chevron-down'}
        status={presentedMeta?.sentiment === Sentiment.NEGATIVE ? 'dark' : 'tertiary'}
      />
    </DNABox>
  );

  const sentimentsContextMenu = !!activePresentation &&
    (featureFlags.enableMeetingReactions || featureFlags.enableMeetingFollowUp) ? (
      <DNAPopover
        placement="top"
        type="tooltip"
        offset={30}
        disablePopover={['tabletPWA']}
      >
        <DNAPopover.Anchor>
          <DNAContextMenu placement="top" style={{ bottom: 8 }}>
            <DNAContextMenu.Anchor>
              <DNAButton
                {...buttonDefaults}
                {...iconButtonProps}
                appearance={(presentedMeta?.sentiment || presentedMeta?.followUp) ? 'filled' : 'ghost'}
                status={(presentedMeta?.sentiment || presentedMeta?.followUp) ? 'primary' : 'dark'}
                customIconLeft={SentimentsIcon}
                customIconName="sentiments-icon"
                iconStyle={S.icon as ViewStyle}
              />
            </DNAContextMenu.Anchor>
            <DNAContextMenu.Items>
              {featureFlags.enableMeetingReactions &&
                <DNAContextMenu.Item
                  icon={positiveIcon}
                  title="Positive"
                  onPress={() => updateReaction(Sentiment.POSITIVE)}
                  testID="positive-reaction-btn"
                />
              }
              {featureFlags.enableMeetingReactions &&
                <DNAContextMenu.Item
                  icon={negativeIcon}
                  title="Negative"
                  onPress={() => updateReaction(Sentiment.NEGATIVE)}
                  testID="negative-reaction-btn"
                />
              }
              {featureFlags.enableMeetingFollowUp &&
                <DNAContextMenu.Item
                  icon={presentedMeta?.followUp ? 'flag' : 'flag-outline'}
                  iconStatus={presentedMeta?.followUp ? 'primary' : 'tertiary'}
                  title="Flag for follow-up"
                  onPress={updateFollowUp}
                  testID="follow-up-flag-btn"
                />
              }
            </DNAContextMenu.Items>
          </DNAContextMenu>
        </DNAPopover.Anchor>
        <DNAPopover.Content>
          <DNAText status="basic">Sentiments</DNAText>
        </DNAPopover.Content>
      </DNAPopover>
    ) : null;

  const myNotesButton = !!activePresentation && !!presentations.length ? (
    <DNAPopover
      placement="top"
      type="tooltip"
      offset={30}
      disablePopover={['tabletPWA']}
    >
      <DNAPopover.Anchor>
        <DNAButton
          {...buttonDefaults}
          {...iconButtonProps}
          appearance={notesPanelVisible ? 'filled' : 'ghost'}
          status={notesPanelVisible ? 'primary' : 'dark'}
          onPress={toggleAllNotes}
          key="My Notes"
          testID="toggle-my-notes-button"
          iconLeft="playlist-edit"
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">My Notes</DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  ) : null;

  const endMeetingButton = (
    <DNAButton
      {...buttonDefaults}
      appearance="filled"
      status="danger"
      onPress={endMeeting}
      disabled={!canEndMeeting}
      testID="in-person-meeting-end-meeting-btn"
      children="End meeting"
    />
  );

  const changeToVirtualModeButton = featureFlags.enableNew3PC && (
    <DNAPopover
      placement="top"
      type="tooltip"
      offset={30}
    >
      <DNAPopover.Anchor>
        <DNAButton
          {...buttonDefaults}
          {...iconButtonProps}
          onPress={toggleMeetingType}
          testID="change-meeting-mode-button"
          iconRight={isVirtual ? 'projector-screen-off-outline' : 'projector-screen-outline'}
          disabled={presentationVirtualStatus.isHTMLWebPresentation || !isPlayerReady}
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">{toggleMeetingTypeTooltipMessage}</DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  );

  const focusPopoutWindowButton = (
    <DNAPopover
      placement="top"
      type="tooltip"
      offset={30}
      disablePopover={['tabletPWA']}
    >
      <DNAPopover.Anchor>
        <DNAButton
          size="md"
          status="dark"
          padding="sm"
          onPress={focusPopoutWindow}
          testID="focus-popout-window"
          iconLeft="book-open-outline"
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">View presenter window</DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  );

  return {
    presentationNav,
    myContentButton,
    presentationsButton,
    slidesButton,
    meetingDetailsButton,
    highlighterContextMenu,
    textSearchButton,
    sentimentsContextMenu,
    myNotesButton,
    endMeetingButton,
    changeToVirtualModeButton,
    focusPopoutWindowButton,
  }
}

export default useVirtualnActionBarComponents
