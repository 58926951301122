import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  createContext,
  useContext,
  ReactNode,
} from 'react';
import { arrayMove } from '@dnd-kit/sortable';
import { useFieldArray } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { Link, HubSectionItemStatus, HubSection } from '@alucio/aws-beacon-amplify/src/models';
import { EditHubRHForm } from 'src/screens/Hubs/EditHub/useHubForm';
import { ModifiedHubSection } from 'src/state/context/Hubs/HubsStateProvider';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import * as logger from 'src/utils/logger';

const ACTIVE_LINKS_LIMIT = 50
const ERROR_MESSAGES = {
  MAX_LENGTH: `You have reached the max limit of ${ACTIVE_LINKS_LIMIT} items. Please remove one to continue.`,
}

export interface LinksProviderProps {
  children: ReactNode;
  rhForm: EditHubRHForm;
  widget: ModifiedHubSection;
}

export interface LinksContextProps {
  rhForm: EditHubRHForm
  widget: ModifiedHubSection
  items: Link[]
  errorMsg: string[]
  setErrorMsg: React.Dispatch<React.SetStateAction<string[]>>
  activeItems: Link[]
  isNewItem: boolean,
  editingId?: string
  setEditingId: React.Dispatch<React.SetStateAction<string | undefined>>
  updateFormValue: (items?: Link[]) => void
  addLink: () => void
  deleteLink: (id: string) => void
  onCancelEdit: () => void
}

export const LinksContext = createContext<LinksContextProps>(undefined!);

export const LinksProvider: React.FC<LinksProviderProps> = ({ children, rhForm, widget }) => {
  const { hubORM } = useHubsState()
  const { setValue, getValues, trigger } = rhForm
  const { remove } = useFieldArray({
    control: rhForm.control,
    name: 'linksWidget.links',
  })
  
  const initialItems = useMemo(() => {
    return widget.links ? [...widget.links].sort((a, b) => a.order - b.order) : []
  }, [widget])
  const [items, setItems] = useState(initialItems)
  const [errorMsg, setErrorMsg] = useState<string[]>([])
  const [editingId, setEditingId] = useState<string | undefined>(undefined)
  const [isNewItem, setIsNewItem] = useState<boolean>(false)

  const activeItems = useMemo(() => {
    return items
      .filter(item => item.status === HubSectionItemStatus.ACTIVE)
      .sort((a, b) => a.order - b.order)
  }, [items])

  useEffect(() => {
    if (activeItems.length < ACTIVE_LINKS_LIMIT) {
      setErrorMsg(p => {
        const errMsgIdx = p.findIndex(errMsg => errMsg === ERROR_MESSAGES.MAX_LENGTH)
        if (errMsgIdx !== -1) {
          const errors = [...p]
          errors.splice(errMsgIdx, 1)
          return errors
        }
        else return p
      })
    }
  }, [activeItems])

  // *** Functions ***
  const updateFormValue = useCallback((links?: Link[]) => {
    const widgetFormValue = getValues('linksWidget')
    const linksValue = links?.slice() || widgetFormValue?.links?.slice()
    if (editingId) {
      const editingLinkIdx = linksValue?.findIndex(link => link.id === editingId) ?? -1
      if (linksValue && editingLinkIdx !== -1) {
        linksValue.splice(editingLinkIdx, 1, {...linksValue[editingLinkIdx], updatedAt: new Date().toISOString()})
      }
    }
    
    if (widgetFormValue) {
      const newValue = {
        ...widgetFormValue,
        links: linksValue,
        updatedAt: new Date().toISOString(),
      }
      setValue('linksWidget', newValue, { shouldDirty: true })
    }

    setItems(linksValue || [])
    trigger('linksWidget.links').then(isValid => {
      if (isValid) {
        isNewItem && analytics?.track('HUB_LINK_ADD', {
          action: 'CREATE',
          category: 'HUB',
          hubId: hubORM?.model.id,
          linkId: editingId,
        });
        setEditingId(undefined)
        setIsNewItem(false)
      }
    })
  }, [getValues, setValue, trigger, isNewItem, editingId])

  const addLink = useCallback(() => {
    if (activeItems.length >= ACTIVE_LINKS_LIMIT) {
      logger.hub.widgets.links.warn(`Reached the maximum link limit ${ACTIVE_LINKS_LIMIT}, Cannot add new link`)
      setErrorMsg([ERROR_MESSAGES.MAX_LENGTH])
      return
    }
    if (!editingId) {
      const newLink = {
        id: uuid(),
        url: '',
        title: '',
        status: HubSectionItemStatus.ACTIVE,
        order: 0,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      }
      const newItems = [newLink, ...items].map((link, index) => ({ ...link, order: index }))
      const widgetFormValue = getValues('linksWidget')
      if(widgetFormValue) {
        const newValue = {...widgetFormValue, links: newItems}
        setValue('linksWidget', newValue, { shouldDirty: true })
      }
      setItems(newItems)
      setIsNewItem(true)
      setEditingId(newLink.id)
      logger.hub.widgets.links.debug(`Adding new link item: ${newLink.id}`)
    }
  }, [items, activeItems, editingId, getValues, setValue])

  const deleteLink = useCallback((id: string) => {
    logger.hub.widgets.links.debug(`Delete link item: ${id}`)
    const newIndex = items.length - 1
    const itemsCopy = [...items]
    const index = items.findIndex((item) => item.id === id)
    itemsCopy[index] = {
      ...items[index],
      status: HubSectionItemStatus.DELETED,
      updatedAt: new Date().toISOString(),
    }
    const newItems = arrayMove(itemsCopy, index, newIndex < 0 ? 0 : newIndex)
    const newItemsWithUpdatedOrder = newItems.map((item, index) => {
      return { ...item, order: index }
    })
    updateFormValue(newItemsWithUpdatedOrder)
  }, [items])

  const onCancelEdit = useCallback(() => {
    if (isNewItem) {
      remove(items.findIndex((link) => link.id === editingId))
      const newItems = items.filter((link) => link.id !== editingId)
      setIsNewItem(false)
      setEditingId(undefined)
      updateFormValue(newItems)
    }
    else {
      setIsNewItem(false)
      setEditingId(undefined)
      updateFormValue(items)
    }
  }, [items, isNewItem, editingId])

  const value: LinksContextProps = {
    rhForm,
    widget,
    items,
    errorMsg,
    setErrorMsg,
    activeItems,
    isNewItem,
    editingId,
    setEditingId,
    updateFormValue,
    addLink,
    deleteLink,
    onCancelEdit,
  }

  return (
    <LinksContext.Provider value={value}>
      {children}
    </LinksContext.Provider>
  );
};

export const useLinksState = () => {
  const context = useContext(LinksContext)
  if (!context) {
    const errorText = 'useLinksState must be used within the LinksProvider'
    logger.hub.widgets.links.error(errorText)
    throw new Error(errorText)
  }
  return context;
}
