import React from 'react';
import { StyleSheet } from 'react-native';
import { useContentViewerModalState } from '../../state/ContentViewerModalStateProvider'
import { DNABox, DNAText } from '@alucio/lux-ui';
import format from 'date-fns/format'
import { DocumentStatus } from '@alucio/aws-beacon-amplify/src/models';

const S = StyleSheet.create({
  entryContainer: { paddingBottom: 32 },
})

function ReleaseNotesSidebar() {
  const { activeDocORM } = useContentViewerModalState()
  const docVersions = activeDocORM.relations.documentVersions

  const publishedVersionsEntries = docVersions
    .filter((ver) => ver.model.status === DocumentStatus.PUBLISHED)
    .map(docVer => {
      const header = `Version ${docVer.meta.version.semVerLabel}`
      const publishDate = `Published ${format(new Date(docVer.model.updatedAt || 0), 'MMMM dd, yyyy')}`
      const { releaseNotes } = docVer.model

      return (
        <DNABox
          key={docVer.model.id}
          appearance="col"
          style={S.entryContainer}
          spacing="xs"
        >
          <DNAText bold>{header}</DNAText>
          <DNAText status="subtle">{publishDate}</DNAText>
          <DNAText>{releaseNotes}</DNAText>
        </DNABox>
      )
    })

  return (
    <DNABox appearance="col">
      { publishedVersionsEntries }
    </DNABox>
  )
}

export default ReleaseNotesSidebar;
