import React, { useState, useCallback, PropsWithChildren } from 'react';
import { Pressable, ScrollView, StyleSheet } from 'react-native';
import format from 'date-fns/format';
import { DNABox, DNAButton, DNADivider, DNAText, Iffy, util } from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip';
import DNADocumentContextMenu from 'src/components/DNA/Document/DNADocumentContextMenu/DNADocumentContextMenu';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useDispatch } from 'src/state/redux';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as publisherVersioningSelector from 'src/state/machines/publisherVersioning/publisherVersioning.selectors';
import { Variant, DocumentORM, DocumentVersionORM } from 'src/types/types';
import { ConversionStatus, DocumentStatus, FileType } from '@alucio/aws-beacon-amplify/src/models';
import { useUserByEmail } from 'src/state/redux/selector/user';
import { usePublisherVersioningState }
  from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import * as VersioningModals from 'src/components/DNA/Modal/DNAVersioningModals';
import { useDNADocumentVersionActions } from 'src/components/DNA/Document/DNADocumentVersion.actions';

const styles = StyleSheet.create({
  arrowIcon: {
    height: 32,
    width: 32,
  },
  header: {
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  mainWrapper: {
    backgroundColor: colors['color-text-white'],
    borderRightColor: colors['color-gray-80'],
    borderRightWidth: 1,
  },
  versionRow: {
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
});

interface VersionRowProps {
  addSeparator: boolean;
  isAnyFormDirty: boolean;
  documentVersionORM: DocumentVersionORM;
  isSelected: boolean;
  isDraft: boolean;
  showStatusBadge: boolean;
  onSelect: (documentVersionId: string) => void;
  showDownloadButton: boolean;
}

export const SidebarWrapper: React.FC<PropsWithChildren> = (props) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const { service, documentORM, toggleSlider } = usePublisherVersioningState();
  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.isModifying,
    ),
  )

  const toggleCollapse = (): void => {
    setCollapsed(p => !p);
  };

  return (
    <DNABox
      appearance="row"
      style={util.mergeStyles(
        undefined,
        styles.mainWrapper,
        [{ width: 360 }, !collapsed],
      )}
    >
      <DNABox fill appearance="col">
        {/* SIDEBAR HEADER */}
        <DNABox
          spacing="between"
          alignY="center"
          style={util.mergeStyles(
            undefined,
            styles.header,
            [{ maxHeight: 64 }, !collapsed],
          )}
        >
          <Iffy is={!collapsed}>
            <DNAText testID="version-history-text" bold>Version History</DNAText>
          </Iffy>
          <DNABox spacing="sm" appearance={collapsed ? 'colReverse' : 'row'}>
            <DNADocumentContextMenu
              documentORM={documentORM}
              variant={Variant.versioning}
              onCallback={toggleSlider}
            >
              <DNAButton
                testID="version-history-ellipsis"
                status="tertiary"
                appearance="outline"
                rounded="full"
                size="md"
                style={styles.arrowIcon}
                iconLeft="dots-vertical"
                disabled={cond.isModifying}
              />
            </DNADocumentContextMenu>
            <DNAPopover placement="right">
              <DNAPopover.Anchor>
                <DNAButton
                  testID="version-history-chevron"
                  status="tertiary"
                  iconLeft={`chevron-${collapsed ? 'right' : 'left'}`}
                  appearance="outline"
                  rounded="full"
                  size="md"
                  style={styles.arrowIcon}
                  onPress={toggleCollapse}
                />
              </DNAPopover.Anchor>
              <DNAPopover.Content>
                <DNAText style={{ color: colors['color-text-white'] }}>
                  {collapsed ? 'Version History' : 'Collapse'}
                </DNAText>
              </DNAPopover.Content>
            </DNAPopover>
          </DNABox>
        </DNABox>
        <Iffy is={!collapsed}>
          <DNADivider />
        </Iffy>
        {/* SIDEBAR CONTENT */}
        <Iffy is={!collapsed}>
          {props.children}
        </Iffy>
      </DNABox>
    </DNABox>
  )
}

const VersionHistoryList: React.FC<{
  onSelect: (docVerId: string) => void,
  isAnyFormDirty?: boolean,
  selectedDocVerId?: string,
  documentORM: DocumentORM
}> = (props) => {
  const { documentORM, onSelect, selectedDocVerId, isAnyFormDirty = false } = props

  const { latestDocumentVersionORM } = documentORM.relations.version
  const isLatestDraft = latestDocumentVersionORM.model.status === DocumentStatus.NOT_PUBLISHED;
  const onVersionSelect = useCallback(
    (docVerId: string) => () => onSelect(docVerId),
    [onSelect],
  )

  return (
    <ScrollView>
      {
        documentORM.relations.documentVersions.map((documentVersionORM, idx) =>
          (<VersionRow
            isAnyFormDirty={isAnyFormDirty}
            key={documentVersionORM.model.id}
            documentVersionORM={documentVersionORM}
            isSelected={documentVersionORM.model.id === selectedDocVerId}
            isDraft={isLatestDraft && latestDocumentVersionORM.model.id === documentVersionORM.model.id}
            onSelect={onVersionSelect(documentVersionORM.model.id)}
            addSeparator={documentVersionORM.model.semVer?.major !==
              documentORM.relations.documentVersions[idx + 1]?.model.semVer?.major}
            showStatusBadge={documentVersionORM.model.id ===
            documentORM.relations.version.latestPublishedDocumentVersionORM?.model.id}
            showDownloadButton={documentORM.model.type !== FileType.WEB}
          />),
        )
      }
    </ScrollView>
  )
}

const VersionRow = (props: VersionRowProps) => {
  const { documentVersionORM, isDraft, isSelected, onSelect, isAnyFormDirty, showDownloadButton } = props;
  const updatedBy = useUserByEmail(documentVersionORM.model.updatedBy)?.meta.formattedName;
  const dispatch = useDispatch()
  const { download } = useDNADocumentVersionActions();

  const docORM = documentVersionORM.relations.documentORM
  const status = ['ARCHIVED', 'REVOKED'].includes(docORM.model.status)
    ? docORM.model.status
    : documentVersionORM.model.status

  const handleOnSelect = () => {
    if (!isAnyFormDirty) {
      onSelect(documentVersionORM.model.id)
      return;
    }

    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: () => <VersioningModals.DiscardChangesModal onConfirm={() => onSelect(documentVersionORM.model.id)} />,
    }))
  }

  return (
    <Pressable onPress={handleOnSelect}>
      <DNABox
        appearance="col"
        style={util.mergeStyles(
          undefined,
          styles.versionRow,
          [{ backgroundColor: colors['color-gray-80'] },
            isSelected],
        )}
      >
        <DNABox appearance="col" spacing="xs">
          <DNABox spacing="sm">
            <Iffy is={!isDraft}>
              <DNAText bold>
                {`v ${documentVersionORM.model.semVer?.major}.${documentVersionORM.model.semVer?.minor}`}
              </DNAText>
            </Iffy>
            <Iffy is={isDraft}>
              <DNAText bold>Draft In Progress</DNAText>
            </Iffy>
            <Iffy is={props.showStatusBadge || isDraft}>
              <DNADocumentChip item={documentVersionORM} variant={'status'} status={status} />
            </Iffy>
          </DNABox>
          <DNABox spacing="between" alignY="center">
            <DNAText b2 status="subtle" numberOfLines={1} style={ showDownloadButton ? { width: 290 } : {}}>
              {
              isDraft
                ? `Created ${format(new Date(documentVersionORM.model.createdAt), 'MMM d, yyyy')}`
                : `Published ${format(new Date(documentVersionORM.model.publishedAt || 0), 'MMM d, yyyy')}`
            }&nbsp; by {updatedBy || documentVersionORM.model.updatedBy}
            </DNAText>
            {showDownloadButton && documentVersionORM.model.conversionStatus === ConversionStatus.PROCESSED &&
              <DNAButton
                status="tertiary"
                appearance="ghostLink"
                size="md"
                iconLeft="download"
                onPress={download(documentVersionORM)}
              />}
          </DNABox>
        </DNABox>
      </DNABox>
      <DNADivider
        style={{
          backgroundColor: props.addSeparator && !isDraft
            ? colors['color-gray-300'] : colors['color-gray-80'],
        }}
      />
    </Pressable>
  );
};

export default VersionHistoryList;
