import { CustomDeckORM, DocumentVersionORM, PageExtended, FolderItemORM, PageGroupORM } from 'src/types/types';
import { isCustomDeckORM } from 'src/types/typeguards';
import { Page, PageSetting } from '@alucio/aws-beacon-amplify/src/models';

export const VIDEO_EXTENSIONS = ['MP4', 'MOV', 'AVI', 'MPEG', 'WEBM', 'MKV']

/**
 * Returns a string that represents the size of the file
 * @param bytes: number
 * @param decimals: number
 */
export function bytesToSize(bytes: number, decimals: number = 2): string {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * Returns a boolean that represents, given the params, whether it's customSlides (not custom deck)
 * @param activeDoc?: DocumentORM
 * @param folderItemORM?: FolderItemORM
 */
export function haveHiddenSlides(activeDocVersion?: DocumentVersionORM, folderItemORM?: FolderItemORM): boolean {
  const { MSLSelectSlides } = {
    ...activeDocVersion?.relations.documentORM.relations?.version.latestPublishedDocumentVersionORM?.meta.permissions,
  }
  const { visiblePages } = { ...folderItemORM?.model }

  return !isCustomDeckORM(folderItemORM?.relations.itemORM) &&
    !!(MSLSelectSlides && visiblePages?.length) &&
    /** TODO: identify why we need this if we are ensuring our return is only evaluating non-customdeck orms, */
    // @ts-expect-error, This validation it is to only affect the latest version of the custom document
    (activeDocVersion?.model.versionNumber === folderItemORM?.relations.itemORM.model.versionNumber)
}

/**
 * Returns an array of the visible pages according to the document/folderItemORM
 * @param activeDoc?: DocumentORM
 * @param folderItemORM?: FolderItemORM
 * @param customDeck?: CustomDeckORM
 */
export function getVisiblePages(
  activeDocVersion?: DocumentVersionORM,
  folderItemORM?: FolderItemORM,
  customDeck?: CustomDeckORM): PageExtended[] {
  if (!haveHiddenSlides(activeDocVersion, folderItemORM) && !customDeck) {
    return activeDocVersion?.meta.allPages || [];
  }

  if (customDeck) {
    return customDeck.meta.customDeckGroups.reduce((acc, group) => {
      if (!group.model.visible) {
        return acc;
      }

      // IF THE ITEM IS A CUSTOM DECK, WE NEED A WAY TO IDENTIFY THE PAGE'S DOCUMENTVERSION
      // THEREFORE THE DOCUMENTVERSIONPAGE TYPE (WHICH IS THE SAME AS A PAGE BUT WITH AN OPTIONAL
      // DOCUMENTVERSION KEY)
      return [
        ...acc,
        ...group.pages.map(
          (page) => (
            {
              ...page.page,
              documentVersionORM: page.documentVersionORM,
              groupId: group.model.id,
            }
          ),
        ),
      ]
    }, [] as PageExtended[]);
  }

  return activeDocVersion?.meta.allPages
    .filter(({ number, isRequired }) => (folderItemORM?.model?.visiblePages?.includes(number) || isRequired)) || [];
}

export function getVisiblePagesFromPageGroups(
  pageGroupORM: PageGroupORM,
): PageExtended[] {
  return pageGroupORM.relations.pages.map(page => {
    return {
      ...page.model,
      groupId: pageGroupORM.model.id,
      documentVersionORM: pageGroupORM.relations.documentVersionORM,
    }
  })
}
export function formatDocumentFileNameHeader (fileName: string) : string {
  return fileName.replace(/[^a-z._0-9+]+/gi, '_').substring(0, 50)
}

export function getExtension(file: File) {
  let fileExtension = file.name.split('.').pop() ?? 'unknown';
  fileExtension = VIDEO_EXTENSIONS.includes(fileExtension?.toUpperCase()) ? 'MP4' : fileExtension;
  return fileExtension;
}

export function generateAllPagesForVersion(
  docVersionId: string,
  numPages: number,
  pageSettings: PageSetting[],
): Page[] {
  const pageSettingsMap = pageSettings.reduce((acc, pageSetting) => {
    acc.set(pageSetting.number, pageSetting)
    return acc
  }, new Map<number, PageSetting>())

  return Array.from(Array(numPages ?? 0).keys()).map((idx): Page => {
    const pageNum = idx + 1
    const settings = pageSettingsMap.get(pageNum)
    return {
      pageId: `${docVersionId}_${pageNum}`,
      number: pageNum,
      srcHash: '',
      isRequired: settings?.isRequired ?? false,
      linkedSlides: settings?.linkedSlides ?? [],
    }
  })
}
