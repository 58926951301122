import { useEffect, useState } from 'react';
import { StandaloneForm } from 'src/state/redux/selector/crm';
import { CustomFieldDefinition } from '@alucio/aws-beacon-amplify/src/models';
import { handleVeevaAdditionalFormSettings } from './veevaStandaloneFormSettingsUtil';
import { useUserTenant } from 'src/state/redux/selector/user';

interface Response {
  isLoading: boolean;
  customFields: CustomFieldDefinition[];
}

export enum FORM_SOURCE {
  STANDALONE,
  MEETING
}

interface AdditionProps {
  crmAccountIds?: string[];
}

export interface Props {
  additionalProps?: AdditionProps;
  customFields: CustomFieldDefinition[];
  formSource: FORM_SOURCE;
  standaloneForm?: StandaloneForm;
}

const HANDLERS_ADAPTERS = {
  VEEVA: handleVeevaAdditionalFormSettings,
}

// AIMS TO HANDLE SPECIFIC ADDITIONAL STANDALONE FORM SETTINGS TWEAKS OF FIELDS //
const useStandaloneFormAdditionalSetting = (props: Props): Response => {
  const [updatedCustomFields, setUpdatedCustomFields] = useState<CustomFieldDefinition[]>(props.customFields);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const userTenant = useUserTenant();

  useEffect(() => {
    async function handleAdditionalSettings(): Promise<void> {
      const handler = HANDLERS_ADAPTERS[userTenant?.config.crmIntegration?.crmIntegrationType || ''];

      if (handler && props.customFields.length) {
        const fields = await handler(props);
        setUpdatedCustomFields(fields);
      } else if (props.customFields.length) {
        setUpdatedCustomFields(props.customFields);
      }
      // THIS IS TO AVOID "FLICKERING" WHILE OPENING THE FORM AND DATA FETCHING //
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }

    handleAdditionalSettings();
  }, [props.customFields]);

  return {
    isLoading,
    customFields: updatedCustomFields,
  }
};

export default useStandaloneFormAdditionalSetting;
