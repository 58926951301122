import { reachabilityMonitor } from '@alucio/core';
import formatISO from 'date-fns/formatISO';
import { createStore, peek, pop, push } from './queueIdb';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import * as logger from 'src/utils/logger';

interface User {
    sub: string;
    email_verified: boolean;
    'custom:user_id': string;
    phone_number_verified: boolean;
    'custom:org_id': string;
    'cognito:username': string;
    given_name: string;
    aud: string;
    event_id: string;
    token_use: string;
    auth_time: number;
    phone_number: string;
    exp: number;
    iat: number;
    family_name: string;
    email: string;
}

interface PersistedItem {
  key: number,
  value: {
    user: User,
    payload: string
  }
}

let originalTrackingFunction = noop;
const retentionConfig = { maxNumber: 10000, batchEvictionNumber: 10 };
let isSendPendingMessages = false;
let isTrackingFunctionOverwritten = false;
export const withStore = createStore('analyticsOffline', 'analyticsOffline', 'key');

function isReadyToSendMessages() {
  return window.analytics && typeof window.analytics.track === 'function' &&
      originalTrackingFunction !== noop && !isSendPendingMessages
}

const SKIPPED_ROUTES = ['folders/']

export const pageFunctionIntercept = (f) => {
  return new Proxy(f, {
    apply(target, thisArg, args) {
      const isSkippedEvent = args[0] && SKIPPED_ROUTES.some(route => args[0].includes(route));
      if (!isSkippedEvent) {
        target.apply(thisArg, args);
      }
    },
  });
}

const getUser = async (): Promise<User | null> => {
  try {
    if (!navigator.onLine) {
      const localUser = localStorage.getItem('amplify-latest-user-attributes');
      if (!localUser) {
        logger.appInit.analytics.warn('No local user attributes found while offline')
        return null;
      }
      const user = JSON.parse(localUser);
      return user?.attributes || null;
    }

    const data = await Auth.currentSession();
    return data.getIdToken().payload as User;
  } catch (error) {
    logger.appInit.analytics.error('Error getting user:', error);
    return null;
  }
};

async function sendPendingMessages() {
  const isReadyToSend = isReadyToSendMessages()
  if (!isReadyToSend) {
    logger.appInit.analytics.warn('Not ready to send pending messages')
    return;
  }
  try {
    isSendPendingMessages = true;
    logger.appInit.analytics.info('Starting to send pending messages')
    const user = await getUser();
    if (!user) return;
    let [item] = await peek(1, withStore) as PersistedItem[];
    while (!isEmpty(item)) {
      if (user.email === item.value.user.email) {
        const [removedItem] = await pop<PersistedItem>(1, withStore);
        const [eventName, eventPayload] = JSON.parse(removedItem.value.payload);
        analytics.track(eventName, Object.assign(eventPayload, { connectionStatus: 'offline' }));
        [item] = await peek(1, withStore) as PersistedItem[];
      } else {
        logger.appInit.analytics.warn('User email mismatch, stopping pending messages');
        break;
      }
      // Uncomment this one to do debug
      // console.info('dequeue', removedItem);
    }
  } catch (error) {
    logger.appInit.analytics.error('Error sending pending messages', error);
  } finally {
    isSendPendingMessages = false;
    logger.appInit.analytics.info('Finished sending pending messages');
  }
}

function applyTrackingFunctionOverwrite() {
  if (isTrackingFunctionOverwritten) return;
  if (window.analytics && typeof window.analytics.track === 'function') {
    originalTrackingFunction = window.analytics.track;
    logger.appInit.analytics.info('Original tracking function saved');
  }
  const overwriteTrackingFunction = async (...options) => {
    const isPWA = window.matchMedia('(display-mode: standalone)').matches ||
      !!document.querySelector('[data-testid="tablet-header"]');
    const user = await getUser();
    const deviceMode = isPWA ? 'tablet' : 'web';
    options[1] = Object.assign(options[1], { deviceMode, groupId: user?.['custom:org_id'] });
    logger.appInit.analytics.verbose({ event: options[0], payload: options[1] }); // add the event name and payload to the logs
    if (navigator.onLine && originalTrackingFunction && typeof originalTrackingFunction === 'function') {
      const { connectionStatus } = options[1];
      if (!connectionStatus) {
        Object.assign(options[1], { connectionStatus: 'online' });
      }
      return originalTrackingFunction.apply(window.analytics, options);
    } else {
      const timeStamp = formatISO(new Date());
      options[1] = Object.assign(options[1], { timeStamp });
      push({ key: Date.now(), value: { payload: JSON.stringify(options), user } }, retentionConfig, withStore);
      logger.appInit.analytics.info('Stored offline analytics event', options);
    }
  };

  window.analytics.track = overwriteTrackingFunction;
  Object.keys(originalTrackingFunction).forEach(prop => {
    overwriteTrackingFunction[prop] = originalTrackingFunction[prop];
  });
  isTrackingFunctionOverwritten = true;
  logger.appInit.analytics.info('Tracking function overwritten');
}

const ensureAnalyticsReady = () => {
  let attempts = 0;
  const maxAttempts = 5;
  const interval = 5000;

  const checkAnalyticsReady = setInterval(() => {
    if (isReadyToSendMessages()) {
      sendPendingMessages();
      clearInterval(checkAnalyticsReady);
    } else if (attempts >= maxAttempts) {
      clearInterval(checkAnalyticsReady);
      logger.appInit.analytics.warn('Max attempts reached, stopping analytics readiness check');
    } else {
      attempts++;
      logger.appInit.analytics.info('Checking analytics readiness, attempt', attempts);
    }
  }, interval);
};

window.onload = () => {
  if (!window.analytics || typeof window.analytics.track !== 'function') {
    window.analytics = window.analytics || {};
    window.analytics.track = noop;
    logger.appInit.analytics.warn('Analytics tracking function not found, setting to noop');
  }

  setTimeout(applyTrackingFunctionOverwrite, 3000);
  analytics.ready(() => {
    applyTrackingFunctionOverwrite();
  });
  reachabilityMonitor.subscribe(() => {
    if (navigator.onLine) {
      ensureAnalyticsReady();
      logger.appInit.analytics.info('Online status detected, ensuring analytics readiness');
    }
  });
};

Hub.listen('auth', async (data) => {
  if (data.payload.event === 'signIn' && navigator.onLine) {
    sendPendingMessages();
    logger.appInit.analytics.info('Auth signIn event detected, sending pending messages');
  }
});
