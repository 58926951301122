import React, { PropsWithChildren } from 'react'
import { StyleSheet, ViewStyle } from 'react-native'

import {
  DNABox,
  DNAButton,
  DNAText,
  DNAContextMenu,
  Stack,
  DNAIcon,
  Iffy,
} from '@alucio/lux-ui'

import {
  PayloadGroup,
  usePresentationBuilderState,
  GroupStatus,
  ModifiedPayloadGroup,
  unavailableStatus,
} from 'src/components/PresentationBuilder/state/PresentationBuilderStateProvider'

const S = StyleSheet.create({
  opaqueBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    borderRadius: 4,
  },
  containerMargin: {
    margin: 8,
  },
})

export const GroupItemContextMenu: React.FC<{ group: ModifiedPayloadGroup }> = (props) => {
  const { group } = props
  const {
    setSelectedGroups,
    customDeck,
    editorType,
    canRemoveGroup,
    isSingleAndHasAssociation,
    onRemoveGroup,
  } = usePresentationBuilderState()

  const onHide = () => {
    setSelectedGroups(
      groups => {
        const rv = groups.reduce<PayloadGroup[]>(
          (acc, grp) => {
            if (grp.id === group.id) {
              const action = grp.visible ? 'HIDE' : 'UNHIDE';

              grp.pages.forEach((page) => {
                analytics?.track(`CUSTOM_SLIDE_${action}`, {
                  action: `SLIDE_${action}`,
                  category: 'CUSTOM',
                  customDeckId: customDeck?.model.id,
                  groupId: group.id,
                  pageId: page.pageId,
                  editorType: editorType,
                });
              });

              return [...acc, { ...grp, visible: !grp.visible }]
            }

            return [...acc, grp]
          },
          [],
        )

        return rv
      },
    )
  }

  const canRemove = canRemoveGroup(group)
  const hasAssociatedSlide = isSingleAndHasAssociation(group)

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        <DNAButton
          iconLeft="dots-vertical"
          size="sm"
          padding="sm"
          style={S.opaqueBackground}
        />
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        <DNAContextMenu.Item
          title={ group.visible ? 'Hide' : 'Unhide'}
          icon={ group.visible ? 'eye-off-outline' : 'eye-outline'}
          onPress={onHide}
        />
        {
          canRemove && !hasAssociatedSlide &&
            <DNAContextMenu.Item
              title="Remove"
              icon="trash-can-outline"
              onPress={() => onRemoveGroup(group)}
            />
        }
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

export const FindAndReplaceOverlay: React.FC<{ group: PayloadGroup }> = (props) => {
  const { group } = props
  const { isLocked, onRemoveGroup } = usePresentationBuilderState()

  return (
    <DNABox
      fill
      appearance="col"
      style={S.containerMargin}
    >
      <DNABox alignX="end">
        <Iffy is={!isLocked}>
          <DNAButton
            iconLeft="trash-can-outline"
            onPress={() => onRemoveGroup(group)}
            size="sm"
            padding="sm"
            style={S.opaqueBackground}
          />
        </Iffy>
      </DNABox>
      <Iffy is={isLocked}>
        <DNABox fill alignX="center" alignY="center">
          <DNAText bold b1 status="basic">
            Source file has been recently updated
          </DNAText>
        </DNABox>
      </Iffy>
    </DNABox>
  )
}

interface GroupItemOverlayProps {
  style?: ViewStyle,
  group: ModifiedPayloadGroup,
  enabled?: boolean,
  isLocked?: boolean,
}

export const GroupItemOverlay: React.FC<PropsWithChildren<GroupItemOverlayProps>> = (
  { group, style, enabled, children, isLocked },
) => {
  const isUnavailable = !!unavailableStatus[group.groupStatus]
  const isFindAndReplace = group.groupStatus === GroupStatus.MAJOR_UPDATE
  const hideContextMenu = isFindAndReplace || isUnavailable
  const isSingleSlide = group.pages.length === 1

  const finalStyle = StyleSheet.flatten([
    { padding: 4 },
    style,
  ])

  if (!enabled)
  { return children as React.ReactElement }

  const applyOpaqueBackground = !group.visible ||
    (isFindAndReplace && isSingleSlide && isLocked)

  return (
    <DNABox fill style={finalStyle}>
      <Stack>
        {/* Children - Base Layer */}
        <Stack.Layer>
          { children }
        </Stack.Layer>

        {/* Opaque background for multiple scenarios */}
        {
          applyOpaqueBackground &&
            <Stack.Layer fill style={{ pointerEvents: 'none', margin: 6 }}>
              <DNABox
                fill
                alignX="center"
                alignY="center"
                style={S.opaqueBackground}
              />
            </Stack.Layer>
        }

        {/* Hidden Group Overlay */}
        {
          !group.visible &&
            <Stack.Layer fill style={{ pointerEvents: 'none' }}>
              <DNABox
                fill
                alignX="center"
                alignY="center"
              >
                <DNAIcon.Styled
                  name="eye-off-outline"
                  size="xl"
                />
              </DNABox>
            </Stack.Layer>
        }

        {/* Find & Replace Overlay */}
        {
          isFindAndReplace && isSingleSlide &&
            <Stack.Layer fill>
              <FindAndReplaceOverlay group={group}/>
            </Stack.Layer>
        }

        {/* Context Menu */}
        {
          !hideContextMenu && !isLocked && isSingleSlide &&
            <Stack.Layer anchor="topRight">
              <DNABox style={S.containerMargin}>
                <GroupItemContextMenu group={group}/>
              </DNABox>
            </Stack.Layer>
        }
      </Stack>
    </DNABox>
  )
}
