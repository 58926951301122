import { useState, useRef, useEffect } from 'react'
import { Animated, Dimensions, StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { DocumentVersionORM } from 'src/types/orms';
import { isFolderItemORM } from 'src/types/typeguards';
import useDisplayMode, { DisplayModes } from '../DNA/hooks/useDisplayMode';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { PresentableModelORM } from 'src/state/context/ContentProvider/ContentProvider';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import { useFetchSharedFolders } from 'src/state/redux/slice/sharedFolder';
import { useAppSettings } from 'src/state/context/AppSettings';
import { BroadcastChannel } from 'broadcast-channel';
import {
  PopoutContentBCPayloads,
  PopoutContentBCTypes,
} from 'src/state/context/Meetings/Hooks/useMeetingsPopoutContentState';

export enum Options {
  FOLDERS = 'My Folders',
  BOOKMARKS = 'Bookmarks',
  LIBRARY = 'Library',
  SEARCH_RESULTS = 'Search results',
  SHARED_WITH_ME = 'Shared With Me',
}

const styles = StyleSheet.create({
  content: {
    backgroundColor: colors['color-gray-800'],
    paddingBottom: 60,
  },
  mainWrapper: {
    flexDirection: 'row',
    height: '62%',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
  },
});

export interface MyContentPanelProps {
  onClosePanel?: () => void;
  displayContentPanel: boolean;
  meetingId?: string;
  sessionId?: string;
  onSelectPresentableORM?: (PresentableModelORM: PresentableModelORM, slide?: number) => void;
}

const useMyContentPanelSharedResources = (
  props: MyContentPanelProps,
) => {
  const { activePresentation } = useContent();
  const isActivePresentationFromSharedFolder = isFolderItemORM(activePresentation?.presentable.orm) &&
    activePresentation?.presentable.orm.relations.parentORM?.meta.isSharedWithTheUser;
  const [selectedTab, setSelectedTab] = useState<Options>(
    isActivePresentationFromSharedFolder ? Options.SHARED_WITH_ME : Options.FOLDERS);
  const broadcastChannel = useRef<BroadcastChannel<PopoutContentBCPayloads>>();
  const [localSearchText, setLocalSearchText] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const windowHeight = (Dimensions.get('window').height)
  const [selectedDocumentVersion, setSelectedDocumentVersion] = useState<DocumentVersionORM | undefined>();
  const bottom = useRef(new Animated.Value(windowHeight)).current;
  const { fetchSharedFolders } = useFetchSharedFolders();
  const { isOnline } = useAppSettings();

  const displayMode = useDisplayMode(
    DisplayModes.list,
    { manageURL: false },
  )

  const mainWrapperVisibilityStyle: StyleProp<ViewStyle> = props.displayContentPanel
    ? undefined
    : { display: 'none' }

  function trackCurrentTab(tab: Options, sessionId?: string): void {
    let action = 'LIBRARY';

    if (tab === Options.FOLDERS) {
      action = 'FOLDER';
    } else if (tab === Options.SHARED_WITH_ME) {
      if (isOnline) {
        fetchSharedFolders()
        if (props.meetingId) {
          broadcastChannel.current?.postMessage({
            type: PopoutContentBCTypes.getSharedFolders,
            meetingId: props.meetingId,
          })
        }
      }
    } else if (tab === Options.BOOKMARKS) {
      action = 'BOOKMARK';
    } else if (tab === Options.SEARCH_RESULTS) {
      // IF THE CURRENT TAB IS SEARCH_RESULTS, WE ARE ALREADY TRACKING THE
      // VIRTUAL_CONTENT_SEARCH ACTION (NO NEED TO TRACK A TAB CHANGE ONE)
      return;
    }

    analytics?.track(`VIRTUAL_CONTENT_${action}`, {
      action: `CONTENT_${action}`,
      category: 'VIRTUAL',
      sessionId: sessionId,
    });
  }

  function togglePanelView(show = false): void {
    if (show) {
      trackCurrentTab(selectedTab, props.sessionId);
    }

    Animated.timing(
      bottom,
      {
        toValue: show ? (windowHeight / 2.5) : (windowHeight),
        duration: 1000,
        useNativeDriver: false,
      },
    )
      .start((result) => {
        if (result.finished && !show) {
          props.onClosePanel?.();
          setSelectedDocumentVersion(undefined);
        }
      });
  }

  useEffect(() => {
    broadcastChannel.current = new BroadcastChannel<PopoutContentBCPayloads>('MEETING_CONTENT');
    return () => {
      broadcastChannel.current?.close();
      broadcastChannel.current = undefined;
    };
  }, []);

  useEffect(() => {
    togglePanelView(props.displayContentPanel)
  }, [props.displayContentPanel])

  useEffect(() => {
    selectedTab && props.displayContentPanel && trackCurrentTab(selectedTab)
  }, [selectedTab]);

  return {
    styles,
    selectedTab,
    setSelectedTab,
    localSearchText,
    setLocalSearchText,
    searchText,
    setSearchText,
    windowHeight,
    selectedDocumentVersion,
    setSelectedDocumentVersion,
    bottom,
    displayMode,
    mainWrapperVisibilityStyle,
    togglePanelView,
  }
}

export default useMyContentPanelSharedResources
