import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useWindowDimensions } from 'react-native'
import { GenericToast, ToastOrientations, useToast } from '@alucio/lux-ui';
import { Hub, HubSection, HubStatus, HubSectionType, HubSectionStatus } from '@alucio/aws-beacon-amplify/src/models';
import { HUB_SECTION_STATUS } from '@alucio/aws-beacon-amplify/src/API';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router';
import format from 'date-fns/format';
import { useAppSettings } from 'src/state/context/AppSettings';
import { HubORM } from 'src/types/orms';
import { useDispatch } from 'src/state/redux';
import { hubActions } from 'src/state/redux/slice/hub';
import { useHub, useHubList } from 'src/state/redux/selector/hub';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import usePreviewHub from 'src/hooks/usePreviewHub/usePreviewHub';
import DNACommonConfirmation from 'src/components/DNA/Modal/DNACommonConfirmation';
import { ShareVariantOptions } from 'src/components/DNA/Modal/ContentShareModal/ContentShareModal';
import { EditHubRHForm } from 'src/screens/Hubs/EditHub/useHubForm';
import { FormName, rhFormFieldName } from 'src/screens/Hubs/EditHub/useHubRHFormStateProvider';
import { useDisabledWidgets, widgetContextMenu } from 'src/screens/Hubs/EditHub/LeftSideBar';
import { ContentShareModal } from 'src/components/DNA/Modal/ContentShareModal/ContentShareModal';
import * as logger from 'src/utils/logger';

export interface HubsStateType {
  hubORM?: HubORM,
  handleEditHub: (id: string) => void,
  handleSaveNCallback: (data: any, callback?: () => void) => void,
  handleFormInvalid: () => void,
  openFormInvalidWarningModal: () => void,
  openPreviewWindow: (hubId: string, docVerId?: string) => void,
  handleCreateHubs: () => void,
  handlePressShare: (hubId: string) => void,
  handleDeleteHub: (hub: Hub, backToHubList?: boolean) => void,
  hubsORM: HubORM[],
  handleCancelEdit: (isFormDirty: boolean, callback?: () => void) => void,

  onInvisibleCallback: React.MutableRefObject<(() => void) | undefined>,
  editHubSliderVisible: boolean,
  setEditHubSliderVisible: React.Dispatch<React.SetStateAction<boolean>>,
  widgets: ModifiedHubSection[],
  setWidgets: React.Dispatch<React.SetStateAction<ModifiedHubSection[]>>,
  items: string[],
  setItems: React.Dispatch<React.SetStateAction<string[]>>,
  handleAddWidget: (type: HubSectionType, rhForm: EditHubRHForm) => void,
  handleDeleteWidget: (type: HubSectionType, rhForm: EditHubRHForm, id: string) => void,
  errorMessages: string[],
  setErrorMessages: React.Dispatch<React.SetStateAction<string[]>>,
  errorMsgRef: React.MutableRefObject<HTMLDivElement | null>,
  getHubSections: (data: any, widgetNamesMapping: FormName[], hubORM: HubORM) => HubSection[],
  showFullSizeBtn: boolean,

  /* RIGHT SIDEBAR START */
  hubRightSideBarOptions: SidebarOptions[],
  selectedRightSideBarOption?: string,
  setSelectedRightSideBarOption: React.Dispatch<React.SetStateAction<string | undefined>>,
  toggleRightSidebar: () => void,
  /* RIGHT SIDEBAR END */
}

export const HubsStateContext = createContext<HubsStateType | null>(null!)
HubsStateContext.displayName = 'HubsContext'

interface InitialValues {
  hubId: string
}

export interface AdditionalWidgetOptions {
  [key: string]: WidgetOptionsContextMenu[];
}

export interface ModifiedHubSection extends HubSection {
  additionalWidgetOptions?: AdditionalWidgetOptions
}

export const defaultWidgetsValue: Record<HubSectionType, HubSection> = {
  [HubSectionType.TEXTAREA]: {
    id: uuid(),
    sectionHeader: 'Text Box',
    status: HUB_SECTION_STATUS.ACTIVE,
    type: HubSectionType.TEXTAREA,
    visible: true,
    order: 0,
    textarea: {
      content: '',
    },
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  [HubSectionType.SHARED_FILES]: {
    id: uuid(),
    sectionHeader: 'Shared Files',
    status: HUB_SECTION_STATUS.ACTIVE,
    type: HubSectionType.SHARED_FILES,
    visible: true,
    order: 1,
    sharedFiles: undefined,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  [HubSectionType.TODO_LIST]: {
    id: uuid(),
    sectionHeader: 'To-Do List',
    status: HUB_SECTION_STATUS.ACTIVE,
    type: HubSectionType.TODO_LIST,
    visible: true,
    toDos: [],
    order: 2,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  [HubSectionType.LINKS]: {
    id: uuid(),
    sectionHeader: 'Links',
    status: HUB_SECTION_STATUS.ACTIVE,
    type: HubSectionType.LINKS,
    visible: true,
    links: [],
    order: 3,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
}

export interface WidgetOptionsContextMenu {
  title: string;
  status: string;
  iconStatus: string;
  icon: string;
  onPress: () => void;
}

/* RIGHT SIDEBAR START */
export enum SidebarOptions {
  SHARED_WITH_SIDE_BAR = 'SHARED_WITH_SIDE_BAR',
  DETAILS_SIDE_BAR = 'DETAILS_SIDE_BAR'
}

export interface TabConfig {
  key: string,
  id: SidebarOptions,
  label: string,
  icon: string,
  tooltip: string,
  testID?: string,
}

export const tabConfigs: Record<SidebarOptions, TabConfig> = {
  [SidebarOptions.SHARED_WITH_SIDE_BAR]: {
    key: 'shared-with-button',
    id: SidebarOptions.SHARED_WITH_SIDE_BAR,
    label: 'Shared With',
    icon: 'account-multiple',
    tooltip: 'Shared With',
    testID: 'edit-hub-sidebar-shared-with',
  },
  [SidebarOptions.DETAILS_SIDE_BAR]: {
    key: 'meeting-event-hubs-page',
    id: SidebarOptions.DETAILS_SIDE_BAR,
    label: 'Meeting Event',
    icon: 'information-outline',
    tooltip: 'Meeting Event',
    testID: 'meeting-event-hubs-page',
  },
}
/* RIGHT SIDEBAR END */

const HubsStateProvider: React.FC<PropsWithChildren<InitialValues>> = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const hubsORM = useHubList()
  const hubORM = useHub(props.hubId)
  const toast = useToast()
  const { openPreviewWindow } = usePreviewHub()
  const { deviceMode } = useAppSettings()
  const isTablet = deviceMode === 'tablet'
  const dimensions = useWindowDimensions()

  const [showFullSizeBtn, setShowFullSizeBtn] = useState(dimensions.width > 1200)
  const [editHubSliderVisible, setEditHubSliderVisible] = useState<boolean>(false)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const errorMsgRef = useRef<null | HTMLDivElement>(null)
  const onInvisibleCallback = useRef<() => void>()
  const [ isWidgetDisabled ] =  useDisabledWidgets()
  const returnToHubList = () => history.push('/hubs')
  const toggleSlider = (onInvisCb?: () => void) => {
    onInvisibleCallback.current = onInvisCb
    setEditHubSliderVisible(p => !p)
  }

  useEffect(() => {
    setShowFullSizeBtn(dimensions.width > 1200)
  }, [dimensions])

  useEffect(() => {
    if (hubORM) setEditHubSliderVisible(true)
  }, [props.hubId, hubsORM.length])

  const [widgets, setWidgets] = useState<ModifiedHubSection[]>([])
  // For Drag and Drop
  const sortedWidgetsIds = useMemo(() =>
    widgets.sort((a, b) => a.order - b.order).map(widget => widget.id), [widgets])
  const [items, setItems] = useState(sortedWidgetsIds)

  useEffect(() => {
    setItems(sortedWidgetsIds)
  }, [sortedWidgetsIds.length])

  useEffect(() => {
    if (hubORM) {
      const hubSections = hubORM.model.hubSections?.filter(widget => widget.status === HubSectionStatus.ACTIVE) || []
      const modifiedWidgets = hubSections.map(widget => ({ ...widget }))
      const updatedSortedWidgetsIds = modifiedWidgets.sort((a, b) => a.order - b.order).map(widget => widget.id)
      setWidgets(modifiedWidgets)
      setItems(updatedSortedWidgetsIds)
    }
    else {
      setWidgets([])
    }
  }, [hubORM])

  const handlePressShare = (hubId: string) => {
    logger.hub.sharing.debug(`Opening share modal for hub: ${hubId}`)
    const selectedHubORM = hubsORM.find(hub => hub.model.id === hubId)
    if (selectedHubORM) {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: false,
        backdropVisible: true,
        component: (modalProps) => (
          <ContentShareModal
            entityShareIds={[hubId]}
            {...modalProps}
            variant={ShareVariantOptions.HUB_SHARE}
          />
        ),
      }))
    } else {
      const errorText = `Unable to find Hub ${hubId} when trying to share`
      logger.hub.sharing.error(errorText)
      throw Error(errorText)
    }
  }

  const createHubsCB = (hub: Hub) => {
    logger.hub.hubManagement.debug(`New hub created, navigating to hub: ${hub.id}`)
    history.push(`/hubs/${hub.id}`)
    toggleSlider()
  }

  const handleCreateHubs = () => {
    logger.hub.hubManagement.debug('Attempt to create new hub')
    const defaultHubSections: HubSection[] = Object
      .values(defaultWidgetsValue)
      .filter(hubSection => !isWidgetDisabled(hubSection.type))
      .map(hubSection => {
        // Update createdAt/updatedAt to present time
        return {
          ...hubSection,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        }
      })

    dispatch(hubActions.createHub({
      name: 'Hub Created ' + format(new Date(), 'LLL d, y'),
      status: HubStatus.ACTIVE,
      hubSections: defaultHubSections,
      // what if hubs creation fails?
      callback: createHubsCB,
    }))
  }

  const handleEditHub = (id: string) => {
    logger.hub.hubManagement.debug(`Opening edit hub page for hub: ${id}`)
    analytics?.track('HUB_EDIT', {
      action: 'EDIT',
      category: 'HUB',
      hubId: id,
    });
    history.push(`/hubs/${id}`)
  }

  const handleDeleteHub = (hub: Hub, backToHubList?: boolean) => {
    logger.hub.hubManagement.debug(`Attempt to delete hub: ${hub.id}`)
    const deleteHub = async () => {
      await dispatch(hubActions.deleteHub(hub))
      toast?.add(
        <GenericToast title="Hub deleted" status="success" />,
        ToastOrientations.TOP_RIGHT,
      )
    }

    const deleteHubCB = () => {
      returnToHubList()
      deleteHub()
    }

    dispatch(
      DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: () => (<DNACommonConfirmation
          status="danger"
          cancelText="Cancel"
          confirmActionText="Delete"
          onConfirmAction={backToHubList ? () => toggleSlider(deleteHubCB) : deleteHub}
          title="Delete hub?"
          descriptionText="This action cannot be undone."
        />),
      }));
  }

  const SaveHubToDB = (hub: Hub, updatedContent: Hub, callback?: () => void) => {
    dispatch(hubActions.updateHub({hub, updatedContent, callback}))
    if (!callback) toggleSlider(returnToHubList)
  }

  const widgetNamesMapping = Object.values(rhFormFieldName)

  const getHubSections = (data: any, widgetNamesMapping: FormName[], hubORM: HubORM) => {
    const hubSections: HubSection[] = hubORM.model.hubSections?.slice() ?? []
    widgetNamesMapping.forEach(key => {
      const existingHubSectionIndex = data[key] ? hubSections.findIndex(hubSection=> hubSection.id === data[key].id) : -1
      if (data[key] && existingHubSectionIndex !== -1) {
        hubSections.splice(existingHubSectionIndex, 1, {...data[key], updatedAt: new Date().toISOString()})
      } else {
        const existingHubSectionIndex = hubSections.findIndex(hubSection=> {
          return hubSection.status === HubSectionStatus.ACTIVE && rhFormFieldName[hubSection.type] === key
        })
        if (existingHubSectionIndex !== -1) {
          const updatedWidget = {
            ...hubSections[existingHubSectionIndex],
            status: HubSectionStatus.DELETED,
            updatedAt: new Date().toISOString(),
          }
          hubSections.splice(existingHubSectionIndex, 1, updatedWidget)
        }
        if (data[key]) hubSections.push(data[key])
      }
    })
    return hubSections
  }

  const handleSaveNCallback = useCallback(async (data: any, callback?: () => void) => {
    if (hubORM) {
      try {
        logger.hub.hubManagement.debug(`Attempt to save hub: ${hubORM.model.id}`)
        const hubSections = getHubSections(data, widgetNamesMapping, hubORM)
        const payload = {
          ...hubORM.model,
          name: data.name,
          hubSections,
        }
        SaveHubToDB(hubORM.model, payload, callback)
      } catch (e) {
        setErrorMessages(pre => [...pre, 'An error has occured, please try again. If this issue persists, please contact support'])
        const errorText = 'Hub saving faild'
        logger.hub.hubManagement.error(errorText, e)
        console.error(errorText, e)
      }
    }
  }, [hubORM, widgets, setErrorMessages])

  const handleFormInvalid = useCallback(() => {
    errorMsgRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }, [errorMsgRef])

  const openFormInvalidWarningModal = useCallback(() => {
    handleFormInvalid()
    dispatch(
      DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: () => (<DNACommonConfirmation
          cancelText="Okay"
          cancelButtonProps={{ status: 'primary' }}
          title="Please correct errors before proceeding."
        />),
      }));
  }, [handleFormInvalid])

  const handleCancelEdit = (isFormDirty: boolean, callback?: () => void) => {
    const onConfirm = () => {
      if (callback) callback()
      toggleSlider(returnToHubList)
    }

    if (isFormDirty) {
      dispatch(
        DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: true,
          component: () => (<DNACommonConfirmation
            cancelText="Continue editing"
            confirmActionText="Discard changes"
            onConfirmAction={onConfirm}
            title="Unsaved changes"
            descriptionText="All unsaved changes will be lost."
          />),
        }));
    } else onConfirm()
  }

  const handleAddWidget = useCallback((type: HubSectionType, rhForm: EditHubRHForm) => {
    logger.hub.hubManagement.debug(`Add new widget ${type}`, { hubId: hubORM?.model.id })
    const currentFormValue = rhForm.getValues()
    const widgetToAdd = defaultWidgetsValue[type]
    const newId = uuid()
    setWidgets(pre => {
      // Reset widgets to match current form
      const updatedWidgets = pre.map(widget => {
        const formFieldName: string = rhFormFieldName[widget.type]
        const currentFormFieldValue = currentFormValue[formFieldName] ?? {}
        return {
          ...currentFormFieldValue,
          id: widget.id,
        }
      })
      const newWidget = {
        ...widgetToAdd,
        id: newId,
        order: pre.length,
        // Update createdAt/updatedAt to present time
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      }
      rhForm.setValue(rhFormFieldName[type], newWidget, { shouldValidate: true, shouldDirty: true })
      return [...updatedWidgets, newWidget]
    })
    analytics?.track('HUB_SECTION_ADD', {
      action: 'HUB_SECTION_ADD',
      category: 'HUB',
      hubId: hubORM?.model.id,
      sectionType: type,
      sectionId: newId,
    });
  }, [setWidgets])

  const handleDeleteWidget = useCallback((type: HubSectionType, rhForm: EditHubRHForm, id: string) => {
    logger.hub.hubManagement.debug(`Delete widget ${type}`, { hubId: hubORM?.model.id, widgetId: id })
    dispatch(
      DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: () => (<DNACommonConfirmation
          status="danger"
          cancelText="Cancel"
          confirmActionText="Delete"
          onConfirmAction={() => {
            const currentFormValue = rhForm.getValues()
            const filteredWidget = widgets.filter(widget => widget.type !== type)
            const updatedWidgets = filteredWidget.map(widget => {
              const formFieldName: string = rhFormFieldName[widget.type]
              const currentFormFieldValue = currentFormValue[formFieldName] ?? {}
              return {
                ...currentFormFieldValue,
                id: widget.id,
              }
            })
            setWidgets(updatedWidgets)
            const formName = widgetContextMenu[type].formName
            rhForm.setValue(formName, undefined, { shouldValidate: true, shouldDirty: true })
            analytics?.track('HUB_SECTION_REMOVE', {
              action: 'HUB_SECTION_REMOVE',
              category: 'HUB',
              hubId: hubORM?.model.id,
              sectionType: type,
              sectionId: id,
            });
          }}
          title="Delete section?"
        />),
      }));
  }, [widgets, setWidgets])

  /* RIGHT SIDEBAR START */
  const hubRightSideBarOptions = useMemo(() => {
    return [
      ...(!!hubORM ? [SidebarOptions.SHARED_WITH_SIDE_BAR] : []),
      ...(!!hubORM?.model.meetingId ? [SidebarOptions.DETAILS_SIDE_BAR] : [])
    ]
  }, [hubORM])

  const defaultRightSideBarOption = isTablet ? undefined : hubRightSideBarOptions[0]
  const [selectedRightSideBarOption, setSelectedRightSideBarOption] = useState<string | undefined>(defaultRightSideBarOption)

  const toggleRightSidebar = useCallback(() => {
    selectedRightSideBarOption ? setSelectedRightSideBarOption(undefined) : setSelectedRightSideBarOption(hubRightSideBarOptions[0])
  }, [selectedRightSideBarOption, hubRightSideBarOptions])

  useEffect(() => {
    setSelectedRightSideBarOption(defaultRightSideBarOption)
  }, [hubORM])
  /* RIGHT SIDEBAR END */

  const contextValue: HubsStateType = {
    hubORM,
    handleEditHub,
    handleSaveNCallback,
    handleFormInvalid,
    openFormInvalidWarningModal,
    openPreviewWindow,
    handleCreateHubs,
    handlePressShare,
    handleDeleteHub,
    hubsORM,
    handleCancelEdit,

    onInvisibleCallback,
    editHubSliderVisible,
    setEditHubSliderVisible,
    widgets,
    setWidgets,
    items,
    setItems,
    handleAddWidget,
    handleDeleteWidget,
    errorMessages,
    setErrorMessages,
    errorMsgRef,
    getHubSections,
    showFullSizeBtn,

    /* RIGHT SIDEBAR START */
    hubRightSideBarOptions,
    selectedRightSideBarOption,
    setSelectedRightSideBarOption,
    toggleRightSidebar,
    /* RIGHT SIDEBAR END */
  }

  return (
    <HubsStateContext.Provider value={contextValue}>
      {props.children}
    </HubsStateContext.Provider>
  )
}
HubsStateProvider.displayName = 'HubsStateProvider'
export const useHubsState = () => {
  const context = useContext(HubsStateContext)
  if (!context) {
    const errorText = 'useHubsState must be used within the HubsStateProvider'
    logger.hub.hubManagement.error(errorText)
    throw new Error(errorText)
  }
  return context;
}

export default HubsStateProvider
