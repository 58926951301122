import React from 'react';

import { DNABox, DNAButton, DNAModal, DNAText } from '@alucio/lux-ui';
import { DNAModalVariant } from '../DNAConnectedModal';
interface CloseConfirmationProps {
}

const RemoveErrorMessage:DNAModalVariant<CloseConfirmationProps> = (props) => {
  const { toggleModal } = props

  const handleCloseWithoutSaving = () => {
    toggleModal()
  }

  return (
    <DNAModal>
      <DNAModal.Header>
        <DNAText h5>Error</DNAText>
      </DNAModal.Header>
      <DNAModal.Body>
        <DNABox style={{ padding: 8 }}>
          <DNAText status="flat">
            The document you are attempting to update was either deleted or you no longer have access.
          </DNAText>
        </DNABox>
      </DNAModal.Body>
      <DNAModal.Confirmation>
        <DNAButton size="sm" onPress={handleCloseWithoutSaving} status="primary">
          Close
        </DNAButton>
      </DNAModal.Confirmation>
    </DNAModal>
  )
};

export default RemoveErrorMessage;
