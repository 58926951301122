import React from 'react'
import { StyleSheet } from 'react-native'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors'
import {
  DNABox,
  DNAText,
  DNAButton,
} from '@alucio/lux-ui'
import SvgFileAccountOutline from 'src/assets/icons/FileAccountOutline'
import SvgListBoxOutline from 'src/assets/icons/ListBoxOutline'
import SvgLightbulbOn20 from 'src/assets/icons/LightbulbOn20'
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider'

const styles = StyleSheet.create({
  emptyStateContainer: {
    minHeight: 'calc(100vh - 280px)',
    padding: 30,
  },
  subTitle: {
    width: 560,
    textAlign: 'center',
  },
  cardContainer: {
    padding: 32,
    maxWidth: 350,
    borderColor: colors['color-gray-100'],
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 25,
  },
  cardText: {
    lineHeight: 25,
  },
})

const HubEmptyState = () => {
  const { handleCreateHubs } = useHubsState()
  return (
    <DNABox
      fill
      appearance="col"
      spacing="md"
      alignX="center"
      alignY="center"
      style={styles.emptyStateContainer}
    >
      <DNAText testID='hub-empty-state-text' h2 bold>What are Hubs?</DNAText>
      <DNAText h3 bold={false} status="flatAlt" style={ styles.subTitle }>
        Hubs can be used to share content in meeting follow-ups and
        can be created for a single recipient or multiple recipients.
      </DNAText>
      <DNABox fill spacing="md" childFill>
        <DNABox fill appearance="col" spacing="md" style={ styles.cardContainer }>
          <SvgFileAccountOutline />
          <DNAText h4>Share content with your audience</DNAText>
          <DNAText b1 status="flatAlt" style={ styles.cardText }>
            Select documents and journal links of interest to share with your target audience.
          </DNAText>
        </DNABox>
        <DNABox fill appearance="col" spacing="md" style={ styles.cardContainer }>
          <SvgListBoxOutline />
          <DNAText h4>Create action items</DNAText>
          <DNAText b1 status="flatAlt" style={ styles.cardText }>
            You and your audience can create to-do lists to work towards common goals.
          </DNAText>
        </DNABox>
        <DNABox fill appearance="col" spacing="md" style={ styles.cardContainer }>
          <SvgLightbulbOn20 />
          <DNAText h4>Get insights from audience activity</DNAText>
          <DNAText b1 status="flatAlt" style={ styles.cardText }>
            Gain insights to which content your audience engaged with most.
          </DNAText>
        </DNABox>
      </DNABox>
      <DNAButton
        iconLeft="plus"
        size="md"
        onPress={handleCreateHubs}
        testID="hub-empty-state-create-hub-btn"
      >
        Create hub
      </DNAButton>
    </DNABox>
  )
}

export default HubEmptyState
