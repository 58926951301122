import React from 'react';
import { CustomFieldValueDefinition } from '@alucio/aws-beacon-amplify/src/models';
import { DNAChip, DNAText } from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'

interface Props {
  badge: CustomFieldValueDefinition,
}
const CustomFieldBadge: React.FC<Props> = (props) => {
  const { badge } = props;

  if (!badge.badgeColor && !badge.badgeLabel) {
    return null;
  }

  return (
    <DNAPopover placement="top" >
      <DNAPopover.Anchor>
        <DNAChip testID="chip-purpose-type" appearance="tag" style={{ backgroundColor: badge.badgeColor }}>
          {badge.badgeLabel}
        </DNAChip>
      </DNAPopover.Anchor>
      <DNAPopover.Content offset={2}>
        <DNAText status="basic">
          {badge.value}
        </DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  )
}

export default CustomFieldBadge;
