import React, { useMemo } from 'react'
import { DNABox, DNACard, DNAChip, DNAConditionalWrapper, DNAText, Iffy, useTheme, util } from '@alucio/lux-ui'
import { DocumentStatus } from '@alucio/aws-beacon-amplify/src/models';
import { FolderItemORM, DocumentVersionORM } from 'src/types/types'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList'
import { CustomLabelDisplay } from '../default/CustomLabelDisplay'
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector'

const DNACustomDeckFolderItemRow: DNARowsComponent<FolderItemORM> = (props) => {
  const {
    ORM: folderItem,
    onPress,
  } = props

  const theme = useTheme()

  const documentVersionORM = (folderItem.relations.itemORM as DocumentVersionORM)
  const { status: docStatus } = documentVersionORM.relations.documentORM.model
  const { visiblePages } = folderItem.model
  const isModified =
    visiblePages?.length &&
    documentVersionORM.meta.permissions.MSLSelectSlides &&
    documentVersionORM.model.status === 'PUBLISHED'
  const isArchived = docStatus === DocumentStatus.ARCHIVED
  const isLatestPublished = documentVersionORM.meta.version.isLatestPublished
  const isInteractive = isLatestPublished && !isModified && !isArchived
  // If the publisher's selected cover page was hidden by the user
  // we use the first visible page
  // [TODO-2126] - We should be able calculate this at the ORM level instead
  let customThumbnailKey: string | undefined
  const selectedThumbnail = documentVersionORM.model.selectedThumbnail ?? 1
  if (folderItem.model.visiblePages && !folderItem.model.visiblePages.find(vp => vp === selectedThumbnail)) {
    const customPage = documentVersionORM.meta.allPages[folderItem.model.visiblePages[0] - 1]
    customThumbnailKey = detectArchivedFileKeyPath(documentVersionORM.model, customPage)
  }

  const allStyle = {
    paddingHorizontal: theme['row-padding-horizontal'],
    paddingVertical: theme['row-padding-vertical'],
  }

  const popoverInfoText = useMemo(() => {
    return isModified
      ? 'Modified files cannot be used when building presentations'
      : isArchived
        ? 'Archived files cannot be used when building presentations'
        : !isLatestPublished
          ? 'This file is an outdated version and cannot be used when building presentations'
          : ''
  }, [isModified, isLatestPublished])

  return (
    <DNAConditionalWrapper
      condition={!isInteractive}
      wrapper={children => (
        <DNAPopover disablePopover={['tabletPWA']} placement="top">
          <DNAPopover.Anchor>
            {children}
          </DNAPopover.Anchor>
          <DNAPopover.Content>
            <DNAText status="basic">{popoverInfoText}</DNAText>
          </DNAPopover.Content>
        </DNAPopover>
      )}
    >
      <DNACard
        appearance="flat"
        interactive={isInteractive ? 'pressable' : undefined}
        disabled={!isInteractive}
        onPress={() => onPress?.(folderItem)}
        style={util.mergeStyles(props, allStyle)}
      >
        <DNABox
          fill
          testID="folder-item-row"
          alignY="center"
          style={{ opacity: isInteractive ? 1 : 0.4 }}
        >
          <DNABox
            fill
            childFill={1}
            spacing="lg"
            alignY="center"
          >
            {/* Thumbnail */}
            <DNADocumentThumbnail
              documentVersionORM={documentVersionORM}
              width={76}
              height={44}
              customThumbnailKey={customThumbnailKey}
            />
            <DNABox
              childFill
              spacing="sm"
              appearance="col"
              style={{ paddingRight: 16 }}
            >
              {/* Title */}
              <DNAText testID="document-item-title" b1 numberOfLines={1}>
                {folderItem.meta.title}
              </DNAText>
              {/* Badge & Tags */}
              <DNABox
                fill
                childFill={1}
                alignY="center"
                childStyle={[4, { flex: 1 }]}
              >
                <DNABox appearance="row" spacing="sm" alignY="center">
                  <CustomFieldBadgeList documentVersionORM={documentVersionORM} />
                  <Iffy is={isArchived}>
                    <DNAChip appearance="tag">
                      {DocumentStatus.ARCHIVED}
                    </DNAChip>
                  </Iffy>
                  <Iffy is={isModified}>
                    <DNAChip appearance="tag">
                      MODIFIED
                    </DNAChip>
                  </Iffy>
                </DNABox>
                <CustomLabelDisplay
                  documentORM={documentVersionORM.relations.documentORM}
                />
              </DNABox>
            </DNABox>
          </DNABox>
        </DNABox>
      </DNACard>
    </DNAConditionalWrapper>
  )
}

export default DNACustomDeckFolderItemRow
