import React from 'react'
import { StyleSheet, ScrollView } from 'react-native'
import { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'

import {
  DNABox,
  DNAButton,
  DNAText,
  DNADivider,
  Iffy,
} from '@alucio/lux-ui'

import DNADocumentGridList from 'src/components/DNA/GridList/DNADocumentGridList'
import DNAGrid from 'src/components/DNA/Grid/DNAGrid'

import {
  useAllDocuments,
} from 'src/state/redux/selector/document'
import useDisplayMode, { DisplayModes } from 'src/components/DNA/hooks/useDisplayMode'
import { useSort, withSort } from 'src/components/DNA/hooks/useSort'
import documentQuery from 'src/state/redux/document/query'
import useFixedHeader from '@alucio/lux-ui/src/hooks/useFixedLayout'
import { useAppSettings } from 'src/state/context/AppSettings'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import GridListTabletHeader from 'src/components/DNA/GridList/GridListTabletHeader'
import { useCPMPageRoute } from 'src/components/ContentPreviewModalV2/Util/useCPMDedicatedUrl'

const filter = documentQuery.merge(
  documentQuery.filters.published,
  documentQuery.filters.bookmarked,
)

const styles = StyleSheet.create({
  mobileGridViewContainer: {
    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  mobileGridViewHeader: {
    backgroundColor: colors['color-gray-10'],
    paddingVertical: 5,
  },
});

const useBookmarks = () => {
  const { displayMode, displayModeIconName, toggleDisplayMode } = useDisplayMode(DisplayModes.list, { manageURL: true })
  const { sortSelectorOpts } = useSort()
  const documents = useAllDocuments({ ...filter, ...sortSelectorOpts })

  return {
    documents,
    displayMode,
    displayModeIconName,
    toggleDisplayMode,
  };
}

export const DNABookmarksDeskTop: React.FC = () => {
  const { documents, displayMode, displayModeIconName, toggleDisplayMode } = useBookmarks();
  const fixedHeaderWidth = useFixedHeader({ offSet: 200 });

  return (
    <DNABox alignX="center">
      <DNABox
        fill
        style={{
          // This is a jank workaround to fill the space so the pinkish bg doesn't show
          // flex doesn't work well here, so we calculate the screen height - (header + footer)
          minHeight: 'calc(100vh - 160px)',
          minWidth: 'calc(100vw - 158px)',
          paddingHorizontal: 48,
          paddingVertical: 20,
          maxWidth: displayMode === DisplayModes.grid ? fixedHeaderWidth : undefined,
        }}
      >
        <DNABox fill spacing="md" appearance="col">
          {/* Header */}
          <DNABox alignY="center" spacing="between" style={{ maxWidth :  fixedHeaderWidth }}>
            <DNAText testID="page-title" h5 status="secondary">Bookmarks</DNAText>
            <DNABox spacing="md">
              <DNAButton
                size="md"
                padding="sm"
                appearance="outline"
                status="secondary"
                iconLeft={displayModeIconName}
                onPress={toggleDisplayMode}
              />
            </DNABox>
          </DNABox>
          <DNADivider />
          {/* Content */}
          <Iffy is={displayMode === DisplayModes.list}>
            <DNADocumentGridList
              documents={documents}
              emptyVariant={EmptyVariant.DocumentGridBookmark}
            />
          </Iffy>
          <Iffy is={displayMode === DisplayModes.grid}>
            <DNAGrid
              items={documents}
              emptyVariant={EmptyVariant.DocumentGridBookmark}
            />
          </Iffy>
        </DNABox>
      </DNABox>
    </DNABox>
  );
}

export const DNABookmarksTablet: React.FC = () => {
  const { documents, displayMode } = useBookmarks();

  return (
    <DNABox fill>
      <DNABox fill appearance="col">
        {/* Header */}
        <GridListTabletHeader
          totalRecordCount={documents.length}
        />
        <DNADivider />
        <DNABox fill style={{ overflow: 'scroll' }}>
          <DNABox appearance="col" fill style={{ minWidth: 'fit-content' }}>
            {/* LIST CONTENT */}
            <Iffy is={displayMode === DisplayModes.list}>
              <DNADocumentGridList
                documents={documents}
                emptyVariant={EmptyVariant.DocumentGridBookmark}
              />
            </Iffy>
            {/* GRID CONTENT */}
            <Iffy is={displayMode === DisplayModes.grid}>
              <DNABox as={ScrollView} fill appearance="col" spacing="md">
                <DNABox style={styles.mobileGridViewContainer}>
                  <DNAGrid
                    items={documents}
                    emptyVariant={EmptyVariant.DocumentGridBookmark}
                  />
                </DNABox>
              </DNABox>
            </Iffy>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNABox>
  );
}

const DNALibrarySwitcher: React.FC = () => {
  const { deviceMode } = useAppSettings()
  useCPMPageRoute()

  return deviceMode === 'desktop'
    ? <DNABookmarksDeskTop />
    : <DNABookmarksTablet />
}

export default withSort(DNALibrarySwitcher)
