import { useMemo } from 'react';
import { CrmIntegrationType, CustomFieldDefinition } from '@alucio/aws-beacon-amplify/src/models';
import { DocumentVersionORM, MeetingORM } from 'src/types/orms';
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import { ObjectRestriction } from 'src/components/CustomFields/ObjectField';
import { ObjectWithId } from 'src/components/CustomFields/ComposableFormUtilities';
import { useComposableForm } from 'src/components/CustomFields/ComposableForm';
import veevaObjectChecker from './veevaObjectChecker';

const OBJECT_RECORD_CHECKER_ADAPTER = {
  [CrmIntegrationType.VEEVA]: veevaObjectChecker,
}

export const DEFAULT_RESTRICTION: ObjectRestriction = {
  delete: false,
  update: false,
};

export interface ObjectCheckerProps {
  field: CustomFieldDefinition,
  value: { [key: string]: string | string[] } | ObjectWithId,
  referenceObject?: MeetingORM | DocumentVersionORM,
}

// DETERMINES WHETHER OR NOT AN OBJECT SHOULD BE DISABLED
function useObjectRestrictionChecker(props: ObjectCheckerProps) {
  const { crmIntegrationType } = useCRMStatus();
  const { referenceObject } = useComposableForm();

  return useMemo(() =>
    OBJECT_RECORD_CHECKER_ADAPTER[crmIntegrationType || '']?.({ ...props, referenceObject }) ||
    DEFAULT_RESTRICTION, [props.value, props.field, referenceObject]);
}

export default useObjectRestrictionChecker;
