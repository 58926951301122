import { useEffect, useRef, useState } from 'react';
import { NETWORK_STATUS_EVENT } from '@alucio/core';
import workerChannel from 'src/worker/channels/workerChannel';
import { canPingURL } from 'src/worker/util/onlineChecker';
import { Logger } from '@aws-amplify/core';
const logger = new Logger('Monitor Connection Hook', 'INFO');

const useMonitorConnection = (monitorConnectionSWChannel?: boolean): boolean => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const isLastStatusOnline = useRef<boolean>(navigator.onLine);

  // IN CHARGE OF RECEIVING THE BEAT FROM THE SERVICE WORKER //
  useEffect(() => {
    let subscription;
    if (monitorConnectionSWChannel) {
      subscription = workerChannel
        .observable
        .filter(msg => { return msg.type === 'NETWORK_STATUS' })
        .subscribe((m) => {
          logger.debug('Network message received: ', m);
          const msg = m as NETWORK_STATUS_EVENT
          setIsOnline(msg.value === 'ONLINE');
        });
    }

    return () => {
      subscription?.unsubscribe?.();
    };
  }, [monitorConnectionSWChannel]);

  // IN CHARGE OF LISTENING ONLINE/OFFLINE EVENTS //
  useEffect(() => {
    const handleOffline = (): void => {
      logger.debug('Offline event triggered by the client');
      setIsOnline(false);
      isLastStatusOnline.current = false;
    };

    const handleOnline = async(): Promise<void> => {
      logger.debug('Online event triggered by the client');
      // IF IT'S PWA/OFFLINE MODE, WE'LL VERIFY THE CONNECTION BY DOING A REQUEST
      if (monitorConnectionSWChannel) {
        const isOnline = await canPingURL(window.location.origin);
        setIsOnline(isOnline);
      } else {
        setIsOnline(window.navigator.onLine);
      }
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [monitorConnectionSWChannel]);

  return isOnline;
}

export default useMonitorConnection;
