import React from 'react';
import { DNABox, DNAButton, DNAText, useOrientationChange } from '@alucio/lux-ui';
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';

interface PresentationNavProps {
  disablePageCount?: boolean
}

type ButtonDefaultsVariants = Record<DeviceMode, DNAButtonProps>

const PresentationNav:React.FC<PresentationNavProps> = ({
  disablePageCount,
}) => {
  const enableNew3PC = useFeatureFlags('enableNew3PC');
  const isInPersonMeetingV2 = useMeetingsState().meetingORM?.model.type === 'IN_PERSON' && enableNew3PC;
  const { activePresentation, nextStepPage, prevStepPage } = useContent()
  const { deviceMode } = useAppSettings()
  const orientation = useOrientationChange()
  if (!activePresentation) return null

  const buttonDefaultsVariants: ButtonDefaultsVariants = {
    desktop: {
      appearance: enableNew3PC ? 'ghost' : 'filled',
      colorMode: enableNew3PC ? 'dark' : 'light',
      size: 'sm',
      padding: isInPersonMeetingV2 ? 'md' : 'sm',
      status: 'dark',
      style: {
        height: isInPersonMeetingV2 ? 40 : 32,
        width: isInPersonMeetingV2 ? 40 : 32,
      },
    },
    tablet: {
      appearance:'filled',
      size: orientation === 'LANDSCAPE' ? 'lg' : 'sm',
      padding: orientation === 'LANDSCAPE' ? 'lg' : 'sm',
      status: 'dark',
      style: {
        height: orientation === 'LANDSCAPE' ? 49 : 32,
        width: orientation === 'LANDSCAPE' ? 49 : 32,
      },
    },
  }

  const currentButtonDefaultsVariant = buttonDefaultsVariants[deviceMode]
  const presentable = activePresentation?.presentable;
  const currentPageNumber = activePresentation?.currentPresentablePage.presentationPageNumber

  return (
    <DNABox spacing="sm" alignY="center">
      <DNAButton
        {...currentButtonDefaultsVariant}
        onPress={prevStepPage}
        testID="previous-slide-button"
        iconLeft="chevron-left"
      />
      {!disablePageCount &&
        <DNAText status="basic" testID="slide-of-number">
          {`${currentPageNumber} of ${presentable?.numberOfPages}`}
        </DNAText>
      }
      <DNAButton
        {...currentButtonDefaultsVariant}
        onPress={nextStepPage}
        testID="next-slide-button"
        iconRight="chevron-right"
      />
    </DNABox>
  )
}

export default PresentationNav;
