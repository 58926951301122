import React, { useMemo } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { DNABox, DNAText, Iffy, Stack, util } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import DNAThumbnail from '../DNA/Thumbnail/DNAThumbnail';

const S = StyleSheet.create({
  thumbnailSize: {
    height: 66,
    width: 120,
  },
  thumbnail: {
    backgroundColor: colors['color-gray-300'],
    borderRadius: 3,
    borderWidth: 1,
  },
  unavailableThumbnail: {
    backgroundColor: colors['color-warning-5'],
    borderRadius: 3,
    borderWidth: 1,
  },
  slideTitleContainer: {
    width: 120,
    marginTop: 4,
  },
  warningRect1: {
    backgroundColor: colors['color-warning-100'],
    height: 66,
    width: 110,
    top: 4,
  },
  warningRect2: {
    backgroundColor: colors['color-warning-20'],
    height: 66,
    width: 100,
  },
  successRect1: {
    backgroundColor: colors['color-success-100'],
    height: 66,
    width: 110,
    top: 4,
  },
  successRect2: {
    backgroundColor: colors['color-success-20'],
    height: 66,
    width: 100,
  },
  thumbnailBorder: {
    borderColor: 'transparent',
    borderWidth: 5,
    borderRadius: 5,
    marginHorizontal: 6,
    top: 3,
  },
  warningActiveBorder: {
    borderColor: colors['color-warning-500'],
    backgroundColor: colors['color-warning-500'],
  },
  warningBorder: {
    borderColor: colors['color-warning-100'],
    backgroundColor: colors['color-warning-100'],
  },
  successBorder: {
    borderColor: colors['color-success-500'],
    backgroundColor: colors['color-success-500'],
  },
});

enum SLIDEROLL_THUMBNAIL_STATUS {
  warning = 'warning',
  success = 'success',
}

type SlideRollThumbnailStatus = keyof typeof SLIDEROLL_THUMBNAIL_STATUS

const SlideRollThumbnail: React.FC<{
  isGroup?: boolean,
  isUnavailableDocument?: boolean,
  unavailableText?: string,
  s3URL: string,
  status: SlideRollThumbnailStatus,
  isSelected: boolean,
  onPress?: () => void,
  slideNum?: number,
  pageTitle?: string,
  height?: number,
  width?: number,
  slideFooter?: JSX.Element,
  lazyLoad?: boolean,
}> = ({
  isGroup = false,
  isUnavailableDocument = false,
  unavailableText = '',
  s3URL,
  status,
  isSelected,
  onPress,
  slideNum,
  pageTitle = '',
  height,
  width,
  slideFooter,
  lazyLoad = true,
}) => {
  const slideFooterText = (slideNum ? `${slideNum}. ` : '') + pageTitle;

  const isWarning = status === SLIDEROLL_THUMBNAIL_STATUS.warning
  const isSuccess = status === SLIDEROLL_THUMBNAIL_STATUS.success

  const thumbnailStyle = useMemo(() => {
    return [
      S.thumbnailSize,
      isUnavailableDocument ? S.unavailableThumbnail : S.thumbnail,
      isWarning && { borderColor: colors['color-warning-100'] },
      isSuccess && { borderColor: colors['color-success-500'] },
      isWarning && isSelected && { borderColor: colors['color-warning-500'] },
    ];
  }, [status, isSelected, isUnavailableDocument])

  const thumbnailBorderStyle = useMemo(() => {
    return [
      S.thumbnailBorder,
      isSuccess && isSelected && S.successBorder,
      isWarning && isSelected && S.warningActiveBorder,
    ]
  }, [status, isSelected])

  return (
    <DNABox appearance="col">
      <DNABox>
        <Stack anchor="top">
          <Stack.Layer>
            <DNABox style={[S.thumbnailSize, { marginTop: 8 }]}/>
          </Stack.Layer>
          <Stack.Layer>
            <DNABox
              style={util.mergeStyles(
                undefined,
                [S.warningRect2, isGroup && isWarning],
                [S.successRect2, isGroup && isSuccess],
              )}
            />
          </Stack.Layer>
          <Stack.Layer>
            <DNABox
              style={util.mergeStyles(
                undefined,
                [S.warningRect1, isGroup && isWarning],
                [S.successRect1, isGroup && isSuccess],
              )}
            />
          </Stack.Layer>
          <Stack.Layer>
            <DNABox style={thumbnailBorderStyle}>
              {isUnavailableDocument ? <DNABox style={thumbnailStyle} />
                : lazyLoad
                  ? <DNAThumbnail.Lazy
                      style={thumbnailStyle}
                      height={height || 66}
                      width={width || 120}
                      s3URL={s3URL}
                  />
                  : <DNAThumbnail
                      style={thumbnailStyle}
                      height={height || 66}
                      width={width || 120}
                      s3URL={s3URL}
                  />
              }
            </DNABox>
          </Stack.Layer>
          <Stack.Layer anchor="center">
            <Iffy is={unavailableText}>
              <DNAText status="warning" style={{ marginTop : 8 }}>{unavailableText}</DNAText>
            </Iffy>
          </Stack.Layer>
          <Stack.Layer>
            <Pressable
              onPress={onPress}
              style={[S.thumbnailSize, { marginTop: 8 }]}
            />
          </Stack.Layer>
        </Stack>
      </DNABox>
      <Iffy is={slideFooterText && !slideFooter}>
        <DNABox style={S.slideTitleContainer}>
          <DNAText numberOfLines={1}>{slideFooterText}</DNAText>
        </DNABox>
      </Iffy>
      <Iffy is={slideFooter}>
        {slideFooter}
      </Iffy>
    </DNABox>
  );
}

export default SlideRollThumbnail
