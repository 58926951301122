import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import {
  ContentPresented,
  ContentPresentedStatus,
  MeetingContentType,
  PresentedMeta,
  Sentiment,
} from '@alucio/aws-beacon-amplify/src/models';
import { RootState } from 'src/state/redux';
import { MeetingORM } from 'src/types/orms';
import { isCustomDeckORM, isFolderItemORM, isPageGroupORM } from 'src/types/typeguards';
import { useMeeting } from 'src/state/redux/selector/meeting';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { getContentId } from 'src/state/context/Meetings/Hooks/useSyncContentPresented';
import { meetingActions } from 'src/state/redux/slice/meeting';
import isEqual from 'lodash/isEqual';
import { getContentAndMetaIndex } from 'src/state/context/Meetings/helper';

interface MeetingPresentedMetaContextType {
  meetingORM?: MeetingORM,
  currentPresentedMeta?: PresentedMeta,
  presentedMetas?: PresentedMeta[],
  contentPresented?: ContentPresented,
  isSaveEnabled: boolean,
  setIsPresented: () => void,
  setSentiment: (sentiment: Sentiment) => void,
  setFollowUp: () => void,
  onOpenMeetingNotes: () => void,
  setNotes: (notes: string) => void,
  saveContentPresentedChanges: () => void,
  presentedSlides: PRESENTED_SLIDES | undefined,
  setPresentedSlides: (presentedSlides: PRESENTED_SLIDES | undefined) => void,
  isPresentedSlideEmpty: boolean,
}

export enum ANALYTICS_EVENT_NAME {
  SLIDE_REACTION = 'SLIDE_REACTION',
  SLIDE_FLAG = 'SLIDE_FLAG',
  SLIDE_PRESENTED = 'SLIDE_PRESENTED',
  SLIDE_NOTES_OPENED = 'SLIDE_NOTES_OPENED',
  SLIDES_NOTES_CREATED = 'SLIDES_NOTES_CREATED',
}

export const SENTIMENT_VALUE = {
  [`${Sentiment.NEGATIVE}`]: -1,
  [`${Sentiment.POSITIVE}`]: 1,
}

export enum PRESENTED_SLIDES {
  PRESENTED_SLIDES = 'Presented slides',
  ALL_SLIDES = 'All slides',
}

const MeetingPresentedMetaContext = createContext<MeetingPresentedMetaContextType>({
  setIsPresented: () => null,
  setSentiment: () => null,
  setFollowUp: () => null,
  setNotes: () => null,
  onOpenMeetingNotes: () => null,
  setPresentedSlides: () => null,
  saveContentPresentedChanges: () => null,
  presentedSlides: PRESENTED_SLIDES.PRESENTED_SLIDES,
  isSaveEnabled: false,
  isPresentedSlideEmpty: false,
});
MeetingPresentedMetaContext.displayName = 'MeetingPresentedMetaContext';

interface FindMetaIndex {
  currentPresentedMetaIndex?: number,
  currentPresentedMeta: PresentedMeta
}

const useProviderUtilities = () => {
  const { activePresentation, contentPageData, initPresentation } = useContent();
  const modalState = useSelector((state: RootState) => state.contentPreviewModal);
  const folderItemId = useMemo(() => modalState?.folderItemORM &&
    modalState?.folderItemORM.model.id, [modalState?.folderItemORM?.model.id]);
  const contentId = useMemo(() => getContentId(modalState.content), [modalState.content]);
  const initialMetasToCompare = useRef<PresentedMeta[]>();
  const groupId = isPageGroupORM(modalState.content) ? modalState.content.model.id : undefined;
  const contentPresentedUniqueId = contentId +
    (folderItemId || '') +
    (groupId || '');
  const dispatch = useDispatch();

  return {
    contentId,
    dispatch,
    groupId,
    contentPresentedUniqueId,
    modalState,
    folderItemId,
    activePresentation,
    initPresentation,
    initialMetasToCompare,
    contentPageData,
  }
};

const MeetingPresentedMetaProvider: React.FC<PropsWithChildren> = (props) => {
  const {
    activePresentation,
    contentId,
    contentPresentedUniqueId,
    dispatch,
    folderItemId,
    initialMetasToCompare,
    initPresentation,
    modalState,
    contentPageData,
  } = useProviderUtilities();
  const meetingORM = useMeeting(modalState.meetingId || '');
  const [presentedSlides, setPresentedSlides] =
    useState<PRESENTED_SLIDES | undefined>(PRESENTED_SLIDES.PRESENTED_SLIDES);
  const [presentedMetas, setPresentedMetas] = useState<PresentedMeta[]>([]);
  const contentPresented = useMemo(getContentPresented, [meetingORM?.model.id, contentId]);
  const { currentPresentedMetaIndex, currentPresentedMeta } =
    useMemo(getCurrentPresentedMeta, [activePresentation?.currentPresentablePage, presentedMetas]);
  const isSaveEnabled = useMemo(() => !isEqual(initialMetasToCompare.current, presentedMetas)
    , [initialMetasToCompare.current, presentedMetas]);
  const [isPresentedSlideEmpty, setIsPresentedSlideEmpty] = useState(false)
  const debouncedRecordAnalytics = useCallback(debounce(recordAnalytics, 1000),
    [activePresentation?.currentPresentablePage?.page.pageId, modalState]);

  useEffect(() => {
    if (meetingORM && modalState.content) {
      // WE'LL KEEP, IN THE STATE, AN ARRAY OF PRESENTEDMETA, WHICH WILL BE USED TO BE EDITED
      if (contentPresented) {
        setPresentedMetas(contentPresented.presentedMeta);
        initialMetasToCompare.current = contentPresented.presentedMeta;
        initPresentedSlidesPresentation(undefined, true);
      }
      else {
        setPresentedMetas([]);
        initialMetasToCompare.current = [];
        initPresentedSlidesPresentation();
        setPresentedSlides(PRESENTED_SLIDES.ALL_SLIDES);
      }
    }
  }, [meetingORM?.model.id, contentPresented?.contentId, modalState.content]);

  // UPON CHANGING WHICH SLIDES WILL BE SHOWN (ALL/PRESENTED)
  // THE ACTIVE PRESENTATION WILL BE UPDATED
  useEffect(() => {
    if (presentedSlides !== PRESENTED_SLIDES.PRESENTED_SLIDES) {
      setIsPresentedSlideEmpty(false)
    }
    if (modalState.content && presentedSlides) {
      if (presentedSlides === PRESENTED_SLIDES.ALL_SLIDES) {
        initPresentation(modalState.content);
      } else {
        initPresentedSlidesPresentation();
      }
    }
  }, [presentedSlides]);

  useEffect(() => {
    if (presentedSlides === PRESENTED_SLIDES.PRESENTED_SLIDES) {
      // IT'LL CREATE A NEW PRESENTATION ONLY IF THE PAGES DIFFER (WILL HAPPEN WHEN A SLIDE GETS "UNEYEBALLED" AND NEED TO REMOVE IT)
      const currentAmountOfPages = [...new Set(activePresentation?.presentable.presentablePages
        .map((page) => page.page.pageId))].length;
      initPresentedSlidesPresentation(currentAmountOfPages);
    }
  }, [presentedMetas]);

  function initPresentedSlidesPresentation(numberOfPagesToCompare?: number, initialLoad?: boolean): void {
    setIsPresentedSlideEmpty(false)
    if (modalState.content) {
      // WE'LL GET THE PAGE NUMBERS OF THE PRESENTED PAGES
      const metasToUse = (initialLoad ? contentPresented?.presentedMeta : presentedMetas) || [];
      const presentedPagesIds = metasToUse
        .filter((pageMeta) => pageMeta.presented)
        .map((pageMeta) => pageMeta.pageId);

      if (!presentedPagesIds.length) {
        setIsPresentedSlideEmpty(true)
        initPresentation(
          modalState.content,
          modalState.pageNumber,
          presentedPagesIds);
        initialLoad && setPresentedSlides(PRESENTED_SLIDES.ALL_SLIDES);
      }
      else if (numberOfPagesToCompare === undefined || numberOfPagesToCompare !== presentedPagesIds.length) {
        initPresentation(
          modalState.content,
          modalState.pageNumber,
          presentedPagesIds);
      }
    }
  }

  function getCurrentPresentedMeta(): FindMetaIndex {
    const currentPageMetaIndex = presentedMetas.findIndex(({ pageId }) =>
      pageId === activePresentation?.currentPresentablePage.page.pageId);
    return {
      currentPresentedMetaIndex: currentPageMetaIndex,
      currentPresentedMeta: presentedMetas[currentPageMetaIndex] || {
        pageId: activePresentation?.currentPresentablePage.page.pageId || '',
        presented: false,
      },
    };
  }

  function getContentPresented(): ContentPresented | undefined {
    const { presentedContentIndex } = getContentAndMetaIndex(
      meetingORM?.model.contentPresented || [],
      contentPresentedUniqueId);

    return meetingORM?.model.contentPresented[presentedContentIndex ?? -1];
  }

  const setNotes = (note: string): void => {
    if (activePresentation && note !== currentPresentedMeta.note) {
      updateCurrentPresentedMeta({
        pageId: activePresentation.currentPresentablePage.page.pageId,
        note,
        presented: true,
        title: contentPageData?.find((page) =>
          page.presentationPageNumber === activePresentation.currentPresentablePage.presentationPageNumber)?.title,
      });
      debouncedRecordAnalytics(ANALYTICS_EVENT_NAME.SLIDES_NOTES_CREATED);
    }
  };

  const setFollowUp = (): void => {
    if (activePresentation) {
      updateCurrentPresentedMeta({
        pageId: activePresentation.currentPresentablePage.page.pageId,
        presented: true,
        followUp: !currentPresentedMeta.followUp,
        title: contentPageData?.find((page) =>
          page.presentationPageNumber === activePresentation.currentPresentablePage.presentationPageNumber)?.title,
      });
      recordAnalytics(ANALYTICS_EVENT_NAME.SLIDE_FLAG, !currentPresentedMeta.followUp ? 1 : -1)
    }
  };

  const setSentiment = (sentiment: Sentiment): void => {
    if (activePresentation) {
      const currentSentiment = currentPresentedMeta?.sentiment;
      const finalSentiment = !currentSentiment ? sentiment
        : currentSentiment === sentiment ? null : sentiment;
      updateCurrentPresentedMeta({
        pageId: activePresentation.currentPresentablePage.page.pageId,
        presented: true,
        // @ts-ignore (IT NEEDS TO BE SET TO NULL INSTEAD OF UNDEFINED TO COMPARE WITH THE RETURNED VALUE FROM DATASTORE)
        sentiment: finalSentiment,
        title: contentPageData?.find((page) =>
          page.presentationPageNumber === activePresentation.currentPresentablePage.presentationPageNumber)?.title,
      });
      debouncedRecordAnalytics(ANALYTICS_EVENT_NAME.SLIDE_REACTION,
        !finalSentiment ? 0 : SENTIMENT_VALUE[finalSentiment]);
    }
  };

  const setIsPresented = (): void => {
    if (activePresentation) {
      let presented: boolean;
      if (currentPresentedMetaIndex === undefined) presented = true
      else presented = !presentedMetas[currentPresentedMetaIndex]?.presented
      updateCurrentPresentedMeta({
        pageId: activePresentation.currentPresentablePage.page.pageId,
        presented,
        title: contentPageData?.find((page) =>
          page.presentationPageNumber === activePresentation.currentPresentablePage.presentationPageNumber)?.title,
      });
      debouncedRecordAnalytics(ANALYTICS_EVENT_NAME.SLIDE_PRESENTED, presented ? 1 : -1);
    }
  };

  const saveContentPresentedChanges = (): void => {
    if (meetingORM) {
      // @ts-ignore (SENTIMENT CANNOT BE NULL BUT UNDEFINED. BUT IF SET TO UNDEFINED, THE FIELD IS IGNORED WHEN SENT TO DATASTORE)
      const presentedMetasClean: PresentedMeta[] = presentedMetas.map((meta) => ({
        ...meta,
        sentiment: meta.presented ? meta.sentiment : null,
      }));
      let isContentFound = false;

      const { presentedContentIndex } = getContentAndMetaIndex(
        meetingORM?.model.contentPresented || [],
        contentPresentedUniqueId);

      const contentPresentedUpdated = meetingORM?.model.contentPresented.map((content, idx) => {
        if (idx === presentedContentIndex) {
          isContentFound = true;
          return {
            ...content,
            presentedMeta: presentedMetasClean,
          }
        }
        return content;
      }) || [];

      // THIS OCCURS WHEN THE FILE HAS JUST BEEN ADDED WITHOUT SAVING
      // SO THE CONTENT PRESENTED FOR THIS FILE DOESN'T EXIST
      if (!isContentFound && contentId) {
        const isCustomDeck = isFolderItemORM(modalState.content) &&
          isCustomDeckORM(modalState.content.relations.itemORM);

        contentPresentedUpdated.push({
          contentId,
          folderItemId,
          presentedMeta: presentedMetasClean,
          contentType: isCustomDeck ? MeetingContentType.CUSTOM_DECK : MeetingContentType.DOCUMENT_VERSION,
          status: ContentPresentedStatus.ACTIVE,
          title: activePresentation?.presentable.title || '',
        });
      }

      dispatch(meetingActions.updateMeeting(meetingORM.model, {
        ...meetingORM.model,
        contentPresented: contentPresentedUpdated,
      }));
      initialMetasToCompare.current = presentedMetasClean;
      setPresentedMetas(presentedMetasClean);
    }
  };

  const updateCurrentPresentedMeta = (draftMeta: PresentedMeta): void => {
    const newContentPresentedMetaArray = [...presentedMetas];
    if (currentPresentedMetaIndex !== undefined && currentPresentedMetaIndex >= 0) {
      newContentPresentedMetaArray[currentPresentedMetaIndex] = {
        ...newContentPresentedMetaArray[currentPresentedMetaIndex],
        ...draftMeta,
      };
    } else {
      newContentPresentedMetaArray.push({ ...draftMeta, presented: true });
    }

    setPresentedMetas(newContentPresentedMetaArray);
  };

  const onOpenMeetingNotes = (): void => {
    debouncedRecordAnalytics(ANALYTICS_EVENT_NAME.SLIDE_NOTES_OPENED);
  };

  function recordAnalytics(eventName: ANALYTICS_EVENT_NAME, value?: number): void {
    const customDeckId = isCustomDeckORM(modalState.folderItemORM?.relations.itemORM)
      ? modalState.folderItemORM?.relations.itemORM.model.id : undefined;

    analytics?.track(eventName, {
      category: 'MEETING',
      context: 'CONTENT_PREVIEW',
      documentId: activePresentation?.currentPresentablePage?.documentVersionORM.model.documentId,
      documentVersionId: activePresentation?.currentPresentablePage?.documentVersionORM.model.id,
      customDeckId,
      folderItemId: modalState.folderItemORM?.model.id,
      meetingId: modalState.meetingId,
      pageNumber: activePresentation?.currentPresentablePage?.page.number,
      value,
    })
  }

  const contextValue: MeetingPresentedMetaContextType = {
    setSentiment,
    setNotes,
    setFollowUp,
    setIsPresented,
    saveContentPresentedChanges,
    onOpenMeetingNotes,
    meetingORM,
    presentedSlides,
    setPresentedSlides,
    presentedMetas,
    currentPresentedMeta,
    isSaveEnabled,
    isPresentedSlideEmpty,
  };

  return (
    <MeetingPresentedMetaContext.Provider value={contextValue}>
      {props.children}
    </MeetingPresentedMetaContext.Provider>
  );
};

export const useMeetingPresentedMeta = () => {
  const context = useContext(MeetingPresentedMetaContext);
  if (!context) {
    throw new Error('useMeetingPresentedMeta must be used within the MeetingPresentedMetaProvider')
  }
  return context;
};

export default MeetingPresentedMetaProvider;
