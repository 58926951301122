import React from 'react';
import { DNABox, DNAButton, DNAText } from '@alucio/lux-ui';
import { styles } from './SlideSettings';

const HeaderSettings : React.FC<{
  title: string,
  onSave: () => void,
  onCancel: () => void,
  saveDisabled?: boolean,
}> = (props) => {
  const { title, onSave, onCancel, saveDisabled } = props
  return (
    <DNABox style={styles.headerBar} spacing="between">
      <DNAButton
        appearance="outline"
        status="tertiary"
        padding="sm"
        onPress={onCancel}
      >
        Cancel
      </DNAButton>
      <DNABox
        alignY="center"
        spacing="sm"
      >
        <DNAText h4>{title}</DNAText>
      </DNABox>
      <DNAButton
        padding="sm"
        disabled={saveDisabled}
        onPress={onSave}
      >
        Save
      </DNAButton>
    </DNABox>
  )
}

export default HeaderSettings
