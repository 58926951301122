import React from 'react';
import {
  DNABox,
  DNAButton,
  DNACard,
  DNAChip,
  DNAText,
  Icon,
  Iffy,
  Stack,
} from '@alucio/lux-ui/src';
import mergeStyles from '@alucio/lux-ui/src/components/util/mergeStyles';
import { useAppSettings } from 'src/state/context/AppSettings';
import { AttachedFile } from '@alucio/aws-beacon-amplify/src/models';
import { downloadContentFromCloudfront } from 'src/utils/loadCloudfrontAsset/loadCloudfrontAsset';
import { styles } from 'src/screens/Hubs/EditHub/Widgets/SharedFiles';
import { AssociatedFileDetails, RowProps } from 'src/screens/Hubs/EditHub/Widgets/SharedFilesComponents/SharedFilesProvider'
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import { S } from './DocumentRow';

interface AttachedFileRowProps extends RowProps {
  associatedContent: AssociatedFileDetails
}

const AttachedFileRow: React.FC<AttachedFileRowProps> = ({ associatedContent, isLastItem, handleDeleteItem }) => {
  const { isOnline } = useAppSettings()
  const { showFullSizeBtn } = useHubsState()
  const file = associatedContent.associatedFileORM.file as AttachedFile
  const isRequired = associatedContent.isRequired
  const handleDownload = () => {
    if (!isOnline) return
    downloadContentFromCloudfront(file.srcFile.key, file.srcFileName, file.type)
  }
  return (
    <DNACard
      onPress={handleDownload}
      interactive="pressableAlt"
      appearance="flat"
      style={mergeStyles(
        undefined,
        { minHeight: 149 },
        [{ minHeight: 113 }, showFullSizeBtn],
        [S.unavailableContent, !isOnline],
        [styles.bottomBorder, !isLastItem],
      )}
    >
      <DNABox alignY="center" style={styles.row}>
        <DNABox
          fill
          alignY="center"
          appearance={showFullSizeBtn ? 'row' : 'col'}
          spacing={showFullSizeBtn ? undefined : 'sm'}
        >
          {/* Thumbnail */}
          <DNABox style={[styles.documentThumbnailBorder, styles.attachedFileThumbnailBox]}>
            <Stack>
              <Stack.Layer>
                <DNABox fill alignX="center" alignY="center" style={styles.attachedFileThumbnailBox}>
                  <Icon name="file-document-outline" style={styles.attachedFileIcon} />
                </DNABox>
              </Stack.Layer>
              <Stack.Layer anchor="bottomLeft">
                <DNAChip appearance="tag">{file.type}</DNAChip>
              </Stack.Layer>
            </Stack>
          </DNABox>
          {/* Document title & badges */}
          <DNABox fill appearance="col" alignY="center" spacing="sm">
            <DNAText
              status="primary"
              numberOfLines={2}
              style={{ textDecorationLine: 'underline' }}
            >
              {file.srcFileName}
            </DNAText>
            <DNABox spacing="sm">
              <Iffy is={isRequired}>
                <DNAChip>REQUIRED</DNAChip>
              </Iffy>
              <Iffy is={!isOnline}>
                <DNAChip
                  appearance="tag"
                  testID={`content-not-available-${file.srcFileName}`}
                >
                  CONTENT NOT AVAILABLE
                </DNAChip>
                </Iffy>
            </DNABox>
          </DNABox>
        </DNABox>
        {/* Buttons */}
        <DNABox alignY="center" spacing="sm">
          <DNAButton
            iconLeft="download"
            appearance="ghostLink"
            status="gray"
            size="md"
            padding="sm"
            onPress={handleDownload}
          />
          <Iffy is={!isRequired}>
            <DNAButton
              iconLeft="trash-can-outline"
              appearance="ghostLink"
              status={!isOnline ? 'tertiary' : 'gray'}
              size="md"
              padding="sm"
              onPress={() => handleDeleteItem(associatedContent.associatedFileId, associatedContent)}
            />
          </Iffy>
        </DNABox>
      </DNABox>
    </DNACard>
  )
}

export default AttachedFileRow