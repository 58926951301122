import React, { useEffect, useRef } from 'react';
import { FlatList, Pressable, ScrollView, StyleSheet } from 'react-native';
import Skelley from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {
  Collapsible,
  DNABox,
  DNAButton,
  DNADivider,
  DNAIcon,
  DNAText,
  Iffy,
} from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { MeetingType, Notation } from '@alucio/aws-beacon-amplify/src/models';
import useSelector, { composite } from 'src/hooks/useSelector';
import * as customNotesSelector from 'src/state/machines/customNote/customNote.selectors';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import { NOTES } from '../AllNotes';
import CustomNotesProvider, { useCustomNotes } from './CustomNotesProvider';

export const styles = StyleSheet.create({
  inpersonNotesHeader: {
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  virtualNotesHeader: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    backgroundColor: colors['color-gray-800'],
  },
  notesContent: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    width: '100%',
  },
  activeNotationContainer: {
    backgroundColor: colors['color-gray-800'],
  },
  fullWidth: {
    width: '100%',
  },
  notationCard: {
    padding: 12,
    paddingLeft: 30,
  },
  yellowDot: {
    position: 'absolute',
    left: 10,
    top: 15,
    width: '12px',
    height: '12px',
    borderRadius: 50,
    backgroundColor: colors['color-notation-yellow-dot'],
  },
  lockIcon: {
    fontSize: 20,
    color: colors['color-gray-200'],
    marginLeft: 8,
  },
  skeletonContainer: {
    padding: 24,
  },
  skeletonLoadingState: {
    width: '100%',
    height: 20,
    opacity: 0.8,
    marginBottom: 15,
  },
});

type CustomNotesProps = {
  openNotes: NOTES[]
  containerDimension?: { width: number, height: number }
}

type CustomNoteRowProps = {
  notation: Notation
}

const CustomNoteRow: React.FC<CustomNoteRowProps> = ({ notation }) => {
  const {
    service,
    setCurrentActiveNotationId,
    currentPageNotationsInfo,
    isCustomDeck,
  } = useCustomNotes()
  const notationRef = useRef<null | HTMLDivElement>(null)

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      customNotesSelector.currentActiveNotationId,
    ),
  )

  const isCurrentActiveNotation = cond.currentActiveNotationId === notation.id
  const displayYellowDot = !!notation.coordinate

  // SHOW LOCK ICON FOR THE NOTE FROM SOURCE DOC WHEN VIEWING CUSTOM DECK
  const showLockIcon = isCustomDeck &&
  currentPageNotationsInfo.currentPageNotationsLevelMap[notation.id] === 'documentLevel'

  useEffect(() => {
    if (isCurrentActiveNotation) {
      notationRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [isCurrentActiveNotation])

  return (
    <Pressable onPress={() => setCurrentActiveNotationId(notation.id)}>
      <DNABox
        ref={notationRef}
        fill
        appearance="col"
        spacing="md"
        style={isCurrentActiveNotation && styles.activeNotationContainer}
      >
        <DNABox fill alignY="center" style={styles.notationCard}>
          {displayYellowDot && <DNABox style={styles.yellowDot}/>}
          <DNABox fill alignY="center">
            <DNAText
              testID="meeting-my-speaker-notes-text"
              status="basic"
              style={styles.fullWidth}
              numberOfLines={isCurrentActiveNotation ? undefined : 5}
            >
              {notation.description || ''}
            </DNAText>
          </DNABox>
          <Iffy is={showLockIcon}>
            <DNAIcon
              testID="viewer-notes-row-lock-icon"
              name="sort-variant-lock"
              size="md"
              style={styles.lockIcon}
            />
          </Iffy>
        </DNABox>
      </DNABox>
    </Pressable>
  )
}

const CustomNotesContent: React.FC = () => {
  const { service } = useCustomNotes()

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      customNotesSelector.currentPageNotations,
      customNotesSelector.isIdleState,
      customNotesSelector.currentPageNotationsInfo,
    ),
  )

  useEffect(() => {
    analytics?.track('USERNOTES_OPENED', {
      action: 'VIEW',
      category: 'USERNOTES',
      context: 'MEETING',
    });
  }, [])

  const renderItem = ({ item : notation } : { item: Notation }) => {
    return (<CustomNoteRow notation={notation} />)
  }

  return (
    <DNABox fill appearance="col">
      {/* LOADING STATE */}
      <Iffy is={!cond.isIdleState}>
        <DNABox spacing="sm" appearance="col" style={styles.skeletonContainer}>
          <Skelley
            count={3}
            baseColor={colors['color-gray-700']}
            highlightColor={colors['color-gray-500']}
            style={styles.skeletonLoadingState}
          />
        </DNABox>
      </Iffy>
      {/* EMPTY STATE */}
      <Iffy is={cond.isIdleState && !cond.currentPageNotations.length}>
        <DNAText status="basic" style={styles.notesContent}>
          No slide notes
        </DNAText>
      </Iffy>
      {/* RENDER NOTES */}
      <Iffy is={cond.isIdleState && cond.currentPageNotations.length}>
        <FlatList
          data={cond.currentPageNotations}
          keyExtractor={(notation) => `custom-note-row-${notation.id}`}
          renderItem={renderItem}
        />
      </Iffy>
    </DNABox>
  )
}

const CustomNotes: React.FC<CustomNotesProps> = ({ openNotes, containerDimension }) => {
  const { meetingORM, toggleCustomNotes } = useMeetingsState();
  const isInPerson = meetingORM?.model.type === MeetingType.IN_PERSON;
  const isSpeakerNoteOpen = openNotes.includes(NOTES.CUSTOM_NOTES);

  if (isInPerson) return null
  return (
    <DNABox testID="meeting-my-speaker-notes" appearance="col" fill={isSpeakerNoteOpen}>
      {/* Virtual Header */}
      <Pressable onPress={toggleCustomNotes}>
        <DNABox
          spacing="sm"
          alignY="center"
          style={styles.virtualNotesHeader}
        >
          <DNAButton
            status="dark"
            appearance="ghost"
            rounded="full"
            padding="xs"
            iconLeft={isSpeakerNoteOpen ? 'chevron-down' : 'chevron-right'}
            onPress={toggleCustomNotes}
          />
          <DNAText status="basic">My Speaker Notes</DNAText>
        </DNABox>
      </Pressable>
      <DNADivider variant="virtual" />
      {/* Content */}
      <DNABox fill>
        <Collapsible collapsed={!isSpeakerNoteOpen}>
          <ScrollView style={containerDimension}>
            <Iffy is={isSpeakerNoteOpen}>
              <CustomNotesProvider>
                <CustomNotesContent/>
              </CustomNotesProvider>
            </Iffy>
          </ScrollView>
        </Collapsible>
      </DNABox>
    </DNABox>
  )
}

export default CustomNotes
