import React, { useCallback, useEffect, useState } from 'react'
import { StyleSheet, ScrollView } from 'react-native';
import { API, graphqlOperation, GraphQLResult } from '@aws-amplify/api';

import {
  DNABox,
  DNAButton,
  DNACard,
  DNAChip,
  DNADivider,
  DNAModal, DNAText, GridList, Iffy,
  useTheme,
  util,
} from '@alucio/lux-ui'
import { DNAModalVariant } from './DNAConnectedModal'
import { IntegrationLog, IntegrationSettings, IntegrationRunStatus } from '@alucio/aws-beacon-amplify/src/models'
import { ListIntegrationLogsQuery } from '@alucio/aws-beacon-amplify/src/API';
import { compareDesc, format } from 'date-fns/esm';
import { listIntegrationLogs } from '../../../../../../packages/awsHelpers/src/queries';

const styles = StyleSheet.create({
  modalContainer: {
    minWidth: 1000,
    minHeight: 752,
    flex: 1,
  },
  modalHeaderContainer: {
    margin: 21,
    fill: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bodyTitle: {
    marginBottom: 0,
    margin: 24,
  },
})

const DNAIntegrationLogsModal: DNAModalVariant<{
  integrationSettings?: IntegrationSettings
}> = (props) => {
  const { integrationSettings, closeModal } = props
  const [integrationLogsList, setIntegrationLogsList] = useState<IntegrationLog[]>([] as IntegrationLog[])
  const [showLogRow, setShowLogRow] = useState<boolean>(false)
  const [selectedLogRow, setSelectedLogRow] = useState<IntegrationLog | null>(null)
  const theme = useTheme()

  const allStyle = {
    paddingHorizontal: theme['row-padding-horizontal'],
    paddingVertical: theme['row-padding-vertical'],
  }

  const refreshData = () => {
    getData()
  }

  const handleRowClick = (currentRow: IntegrationLog) => {
    setShowLogRow(true)
    setSelectedLogRow(currentRow)
  }

  const formatTimestamp = (time: string) => {
    return format(new Date(time), 'MM/dd/yyyy hh:mm:ss aaaa')
  }

  const handleBackPress = () => {
    setShowLogRow(false)
    setSelectedLogRow(null)
  }

  const getData = useCallback(async () => {
    const { data } = await API.graphql(graphqlOperation(
      listIntegrationLogs, {
        filter: { integrationId: { eq: integrationSettings?.id } },
      },
    )) as GraphQLResult<ListIntegrationLogsQuery>
    const integrationLogs = data?.listIntegrationLogs?.items
    if (integrationLogs) {
      // @ts-ignore TODO we are checking for this variable but TS is still throwing a warning
      setIntegrationLogsList(integrationLogs)
    }
  }, [])

  useEffect(() => {
    getData()
  }, [])

  const getLogStatus = (logStatus: keyof typeof IntegrationRunStatus) => {
    switch (logStatus) {
      case IntegrationRunStatus.RUNNING:
        return 'info'
      case IntegrationRunStatus.COMPLETED:
        return 'success'
      case IntegrationRunStatus.ERROR:
        return 'danger'
      case IntegrationRunStatus.WARNING:
        return 'warning'
      default:
        return 'danger'
    }
  }

  return (
    <DNAModal style={styles.modalContainer}>
      <Iffy is={!showLogRow}>
        <DNAModal.Header onClose={closeModal} style={styles.modalHeaderContainer}>
          <DNABox appearance="row" spacing="between" fill alignY="center">
            <DNAText>
              Status Log
            </DNAText>
            <DNAButton
              onPress={refreshData}
              size="sm"
              status="secondary"
              appearance="outline"
              style={{ marginLeft: 20 }}
            >
              Refresh log
            </DNAButton>
          </DNABox>
        </DNAModal.Header>
        <DNAModal.Body>
          <DNABox as={ScrollView} style={{ width: 1000, height: 696 }} appearance="col" spacing="md" fill>
            <Iffy is={integrationLogsList.length}>
              <DNABox appearance="row" spacing="between" style={styles.bodyTitle}>
                <DNAText>Timestamp</DNAText>
                <DNAText>Status</DNAText>
              </DNABox>
              <DNADivider />
              {
                integrationLogsList.sort((a, b) => {
                  // @ts-ignore
                  return compareDesc(new Date(a.createdAt), new Date(b.createdAt))
                }).map((integrationLog) => {
                  const logStatus = getLogStatus(integrationLog.status)
                  // @ts-ignore
                  const timestamp = formatTimestamp(integrationLog.createdAt)
                  return (
                    <>
                      <DNACard
                        appearance="flat"
                        style={util.mergeStyles(props, allStyle)}
                        interactive="pressable"
                        onPress={() => handleRowClick(integrationLog)}
                      >
                        <DNABox key={integrationLog.id} appearance="row" spacing="between">
                          {/* @ts-ignore */}
                          <DNAText>{timestamp}</DNAText>
                          <DNAChip status={logStatus}>{integrationLog.status}</DNAChip>
                        </DNABox>
                      </DNACard>
                      <DNADivider />
                    </>
                  )
                })
              }
            </Iffy>
            <Iffy is={!integrationLogsList.length}>
              <DNAText style={{ marginTop: 24 }}>No records for this integratoin log</DNAText>
            </Iffy>
          </DNABox>
        </DNAModal.Body>
      </Iffy>
      <Iffy is={showLogRow}>
        <DNAModal.Header onClose={closeModal} style={styles.modalHeaderContainer}>
          <DNABox appearance="row" spacing="between" fill alignY="center">
            <DNAButton
              appearance="ghost"
              iconLeft="chevron-left"
              style={{ marginRight: 30 }}
              onPress={handleBackPress}
              status="secondary"
            />
            <DNAText>
              {/* @ts-ignore */}
              {selectedLogRow?.createdAt && formatTimestamp(selectedLogRow?.createdAt)}
            </DNAText>
            <DNAChip
              status={getLogStatus(selectedLogRow?.status!)}
              style={{ marginLeft: 15 }}
            >
              {selectedLogRow?.status}
            </DNAChip>
          </DNABox>
        </DNAModal.Header>
        <DNAModal.Body>
          <DNABox as={ScrollView} style={{ width: 'inherit', height: 696 }} appearance="col" spacing="md" fill>
            <Iffy is={selectedLogRow}>
              {/* @ts-ignore TS is not aware that GridList.ROW as a DNACard gets the DNACard props ie appearance */}
              <GridList.Row as={DNACard} appearance="flat">
                {selectedLogRow?.log?.sort((a, b) => {
                  return (
                    (a?.timestamp || b?.timestamp) ? compareDesc(new Date(a?.timestamp!), new Date(b?.timestamp!)) : 0
                  )
                }).map((log) => {
                  return (
                    <GridList.Col>
                      <DNABox spacing="lg" style={styles.bodyTitle}>
                        <DNAText>{log?.timestamp && formatTimestamp(log?.timestamp)}</DNAText>
                        <DNAText>{log?.level}</DNAText>
                        <DNAText>{log?.srcDocId}</DNAText>
                        <DNAText>{log?.message}</DNAText>
                      </DNABox>
                    </GridList.Col>
                  )
                })}
              </GridList.Row>
            </Iffy>
            <Iffy is={!selectedLogRow}>
              <DNAText style={{ marginTop: 24 }}>No records for this integratoin log</DNAText>
            </Iffy>
          </DNABox>
        </DNAModal.Body>
      </Iffy>
    </DNAModal>
  )
}

export default DNAIntegrationLogsModal
