import React from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAIcon, DNAText, Iffy } from '@alucio/lux-ui';
import { useAppSettings } from 'src/state/context/AppSettings';
import { usePresentationSelector } from './PresentationSelectorStateProvider';
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip';
import { DocumentAccessLevel } from '@alucio/aws-beacon-amplify/src/models';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList';
import { usePresentationBuilderState } from '../state/PresentationBuilderStateProvider';

export const S = StyleSheet.create({
  chevronIcon: { marginTop: 2 },
  breadcrumb: {
    fontSize: 14,
    fontWeight: '700',
  },
  breadcrumbContainer: {
    marginTop: 32,
    marginBottom: 16,
  },
})

const SubHeader: React.FC = () => {
  const {
    selectedDocumentVersionORM,
    searchResultsText,
    setSelectedDocumentVersionORM,
    isCurrentlyNested,
    folderStack,
    popToFolder,
  } = usePresentationSelector()

  const {
    selectorThumbnailSize,
    cycleSelectorThumbnailSize,
  } = usePresentationBuilderState()

  const { deviceMode } = useAppSettings()
  const isTablet = deviceMode === 'tablet'
  const isPersonalFile =
    selectedDocumentVersionORM?.relations.documentORM.model.accessLevel === DocumentAccessLevel.USER;

  const isSubFolder = isCurrentlyNested && !!folderStack.length

  return (
    <DNABox alignY="center" style={{ marginHorizontal: 64 }}>
      {/* Folder Breadcrumb Nav */}
      <Iffy is={!selectedDocumentVersionORM && isSubFolder}>
        <DNABox alignY="center" fill style={S.breadcrumbContainer}>
          <DNAButton
            appearance="ghostLink"
            status="gray"
            padding="none"
            onPress={() => popToFolder()}
          >
            <DNAText testID="page-title" status="secondary" style={S.breadcrumb}>
              My Folders
            </DNAText>
          </DNAButton>
          {folderStack.map(folderORM => (
            <DNABox shrink key={folderORM.model.id}>
              <DNAIcon.Styled
                name="chevron-right"
                appearance="ghostLink"
                status="gray"
                style={S.chevronIcon}
              />
              <Pressable onPress={() => popToFolder(folderORM)} style={{ flexShrink: 1 }}>
                <DNAText
                  status="secondary"
                  style={S.breadcrumb}
                  ellipsizeMode="tail"
                  numberOfLines={1}
                >
                  {folderORM.model.name}
                </DNAText>
              </Pressable>
            </DNABox>
          ))}
        </DNABox>
      </Iffy>
      {/* Search Results / Item Length */}
      <Iffy is={!selectedDocumentVersionORM && !isSubFolder}>
        <DNABox style={S.breadcrumbContainer}>
          <DNAText h5 status="secondary">
            {searchResultsText}
          </DNAText>
        </DNABox>
      </Iffy>
      {/* Selected Document Name & Back Button */}
      <Iffy is={selectedDocumentVersionORM}>
        <DNABox
          fill
          alignY="center"
          style={{ height: 80 }}
        >
          <DNABox testID="custom-deck-breadcrumbs-back-button" style={{ marginRight: 16 }}>
            <DNAButton
              status="tertiary"
              appearance="outline"
              onPress={() => setSelectedDocumentVersionORM(undefined)}
              iconLeft="chevron-left"
              padding="sm"
            />
          </DNABox>
          <DNABox appearance="col" fill spacing="sm">
            <DNAText numberOfLines={1} b1>
              {selectedDocumentVersionORM?.model.title}
            </DNAText>
            <DNABox appearance="row" spacing="md">
              <Iffy is={!isPersonalFile}>
                <CustomFieldBadgeList documentVersionORM={selectedDocumentVersionORM}/>
              </Iffy>
              { selectedDocumentVersionORM &&
                <DNADocumentChip
                  item={selectedDocumentVersionORM.relations.documentORM}
                  variant="docType"
                />}
            </DNABox>
          </DNABox>
          { !isTablet && <DNABox fill alignX="end">
            <DNAButton
              appearance="outline"
              status="tertiary"
              size="md"
              padding="sm"
              iconLeft={selectorThumbnailSize === 'lg' ? 'view-comfy' : 'view-grid'}
              onPress={cycleSelectorThumbnailSize}
            />
          </DNABox> }
        </DNABox>
      </Iffy>
    </DNABox>

  )
}

export default SubHeader
