import React, { useEffect, useRef, useState } from 'react'
import { compose } from 'redux'

import {
  DNABox,
  DNACard,
  DNADivider,
  DNAText,
  Iffy,
  util,
} from '@alucio/lux-ui'

import { useSort, withSort } from 'src/components/DNA/hooks/useSort'
import { filters } from 'src/state/redux/document/query'

import DNADocumentFilters,
{
  useDNADocumentFilters,
  withDNADocumentFilters,
} from 'src/components/DNA/Document/DNADocumentFilters/DNADocumentFilters'
import useFixedHeader from '@alucio/lux-ui/src/hooks/useFixedLayout'
import { StyleSheet, FlatList } from 'react-native';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { useCanPerformSearch } from 'src/state/redux/selector/documentSearch/documentSearch'
import { useHistory, useLocation } from 'src/router'
import qs from 'qs'
import debounce from 'lodash/debounce';
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import FiltersSidebar, { FilterButton, VariantOptions } from 'src/components/QuickFilters/FiltersSidebar'
import QuickFiltersProvider from 'src/components/QuickFilters/QuickFiltersProvider'
import DocumentSearchProvider, { useDocumentSearchV2 } from 'src/hooks/useDocumentSearchV2'
import { SearchResultRow } from 'src/components/DNA/GridList/DNADocumentGridList'
import { DocumentORM } from 'src/types/orms'
import VersioningPanelWrapper from '../Publishers/Versioning/VersioningPanel/VersioningPanel'
import ListSkeleton from 'src/components/ListSkeleton/ListSkeleton'

const styles = StyleSheet.create({
  mainWrapper: {
    backgroundColor: colors['color-text-white'],
    flex: 1,
    flexDirection: 'column',
    minHeight: 'calc(100vh - 160px)',
    minWidth: 'auto',
    paddingHorizontal: 33,
    paddingVertical: 24,
  },
  content: {
    borderWidth: 1,
    borderColor: colors['color-gray-100'],
  },
});

const PublisherSearchResults: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const qsParam = qs.parse(location.search, { ignoreQueryPrefix: true })
  const [searchText, setSearchText] = useState(qsParam.searchText as string ?? '');
  const searchTextHolder = useRef(qsParam.searchText as string ?? '');
  const [isLoading, setIsLoading] = useState(false);
  const { sortSelectorOpts } = useSort()
  const fixedHeaderWidth = useFixedHeader({ offSet: 163 });
  const { filterSelectorOpts } = useDNADocumentFilters()
  const [filtersVisible, setFiltersVisible] = useState(false);
  const canPerformSearch = useCanPerformSearch();
  const [documentId, setDocumentId] = useState<string>();

  useEffect(() => {
    const searchInputReference = document.getElementById('header-search-input') as HTMLInputElement;
    const debouncedKeyDownHandler = debounce(() => {
      setSearchText(searchTextHolder.current)
      setIsLoading(false)

      // Update the url with  the new search text
      history.replace({
        pathname: location.pathname,
        search: qs.stringify({ ...qsParam, searchText: searchTextHolder.current }),
      })
    }, 500);

    const keyDownHandler = (newSearchText: string) => {
      const canSearch = canPerformSearch(newSearchText.trim());
      if (canSearch && !isLoading) {
        setIsLoading(true)
      }
      // Store the updated text in a ref to update
      // the state searchText in the debounced handler
      searchTextHolder.current = newSearchText
      debouncedKeyDownHandler()
    }

    const keyDownHandlerWrapper = () => {
      if (searchInputReference.value !== searchTextHolder.current) {
        keyDownHandler(searchInputReference.value)
      }
    }

    const clearClickHandler = (e: any) => {
      if (e.target?.closest("[data-testid='header-search-input-clear']")) {
        keyDownHandler('')
      }
    }
    searchInputReference?.addEventListener('keyup', keyDownHandlerWrapper);

    // We bind directly to document for clear search icon click because the icon
    // is being re-rendered by Search Input and ref would be lost
    document.addEventListener('click', clearClickHandler)
    return () => {
      searchInputReference?.removeEventListener('keyup', keyDownHandlerWrapper);
      document.removeEventListener('click', clearClickHandler)
    }
  }, []);

  const {
    documentORMSearchResults: searchedDocs,
    isLoadingSearch,
    status,
  } = useDocumentSearchV2(
    searchText,
    filters.nonDeleted,
    filterSelectorOpts,
    sortSelectorOpts,
  )

  const pluralResults = searchedDocs.length > 1 ? 's' : ''

  const title =
    `Search results for "${searchText}"${isLoadingSearch ? '' : ` | ${searchedDocs.length} result${pluralResults}`}`

  const renderSearchItem = ({ item: documentORM }: { item: DocumentORM }) => {
    return (
      <SearchResultRow
        documentORM={documentORM}
        onPress={() => setDocumentId(documentORM.model.id)}
      />
    )
  }

  return (
    <QuickFiltersProvider quickFilters={[]}>
      <DNABox style={styles.mainWrapper} spacing="md">
        <DNABox fill spacing="md" appearance="col">
          {/* Header */}
          <DNABox alignY="end" spacing="between" style={{ maxWidth: fixedHeaderWidth }}>
            <DNABox appearance="col" spacing="md">
              <DNAText testID="page-title" h5 status="subtle">{title}</DNAText>
            </DNABox>
          </DNABox>
          <DNADivider />
          {/* Content */}
          <DNABox appearance="col" spacing="md">
            <DNABox testID="filters-header-button" spacing="sm">
              <FilterButton
                setFiltersVisible={setFiltersVisible}
                filtersVisible={filtersVisible}
                variant={VariantOptions.publisher}
              />
              <DNADocumentFilters.Chips
                documents={searchedDocs}
                chipsStatus="primary"
                isLoading={isLoadingSearch}
              />
            </DNABox>
            <DNABox appearance="row">
              <DNABox fill>
                <DNABox style={util.mergeStyles(undefined, [{ display: 'none' }, !filtersVisible])}>
                  <FiltersSidebar setVisible={setFiltersVisible} textValue={searchText} />
                </DNABox>
                <Iffy is={isLoadingSearch}>
                  <DNACard appearance="outline">
                    <ListSkeleton numberOfItems={5} />
                  </DNACard>
                </Iffy>
                <Iffy is={status === 'RESULTS' && !!searchedDocs.length}>
                  <DNACard
                    appearance="outline"
                    style={[
                      filtersVisible && { paddingLeft: 16 },
                      { width: fixedHeaderWidth - (filtersVisible ? 400 : 0) },
                    ]}
                  >
                    <FlatList
                      data={searchedDocs}
                      renderItem={renderSearchItem}
                      ItemSeparatorComponent={DNADivider}
                      keyExtractor={(item) => item.model.id}
                    />
                  </DNACard>
                </Iffy>
                <Iffy is={status === 'EMPTY_RESULTS' || (!searchedDocs.length && (status !== 'PENDING'))}>
                  <DNAEmpty emptyVariant={EmptyVariant.DocumentListEmptySearch} />
                </Iffy>
              </DNABox>
            </DNABox>
          </DNABox>
        </DNABox>
        <VersioningPanelWrapper documentId={documentId} setDocumentId={setDocumentId} />
      </DNABox>
    </QuickFiltersProvider>
  );
}

const ProviderSearchResults: React.FC = () => {
  return (
    <DocumentSearchProvider mode="PUBLISHER_RESULTS">
      <PublisherSearchResults />
    </DocumentSearchProvider>
  )
}

export default compose(
  withDNADocumentFilters,
  withSort,
)(ProviderSearchResults)
