import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  createContext,
  useContext,
  ReactNode,
} from 'react';
import { arrayMove } from '@dnd-kit/sortable';
import { useFieldArray } from 'react-hook-form';
import { useDispatch } from 'src/state/redux';
import { v4 as uuid } from 'uuid';
import { ToDo, TodoStatus } from '@alucio/aws-beacon-amplify/src/models';
import { EditHubRHForm } from 'src/screens/Hubs/EditHub/useHubForm';
import { ModifiedHubSection } from 'src/state/context/Hubs/HubsStateProvider';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import DNACommonConfirmation from 'src/components/DNA/Modal/DNACommonConfirmation';
import * as logger from 'src/utils/logger';

const ACTIVE_TODOS_LIMIT = 50
const ERROR_MESSAGES = {
  MAX_LENGTH: `You have reached the max limit of ${ACTIVE_TODOS_LIMIT} items. Please remove one to continue.`,
}

interface TodoProviderProps {
  children: ReactNode;
  rhForm: EditHubRHForm;
  widget: ModifiedHubSection;
}
interface TodoContextProps {
  rhForm: EditHubRHForm
  widget: ModifiedHubSection
  items:ToDo[]
  errorMsg: string[]
  setErrorMsg: React.Dispatch<React.SetStateAction<string[]>>
  showCompleted: boolean
  setShowCompleted: React.Dispatch<React.SetStateAction<boolean>>
  activeItems: ToDo[]
  completedItems: ToDo[]
  isNewItem: boolean
  editingId?: string
  setEditingId: React.Dispatch<React.SetStateAction<string | undefined>>
  updateFormValue: (items?: ToDo[]) => void
  addToDo: () => void
  updateItemStatus: (id: string, status: TodoStatus) => void
  onCancelEdit: () => void
  handleOnCompleted: (id: string, isCompleted: boolean, setIsCompleted: React.Dispatch<React.SetStateAction<boolean>>) => void
}

export const TodoContext = createContext<TodoContextProps>(undefined!);

export const TodoProvider: React.FC<TodoProviderProps> = ({ children, rhForm, widget }) => {
  const { hubORM } = useHubsState()
  const dispatch = useDispatch()
  const { setValue, getValues, trigger } = rhForm
  const { remove } = useFieldArray({
    control: rhForm.control,
    name: 'todoListWidget.toDos',
  })

  const initialItems = useMemo(() => {
    return widget.toDos
      ?.map(toDo => ({ ...toDo, completedAt: toDo.completedAt || undefined }))
      .sort((a, b) => a.order - b.order)
      ?? []
  }, [widget])
  const [items, setItems] = useState<ToDo[]>(initialItems)
  const [errorMsg, setErrorMsg] = useState<string[]>([])
  const [editingId, setEditingId] = useState<string | undefined>(undefined)
  const [isNewItem, setIsNewItem] = useState<boolean>(false)
  const [showCompleted, setShowCompleted] = useState<boolean>(false)

  const activeItems = useMemo(() => {
    return items
      .filter(item => item.status === TodoStatus.ACTIVE)
      .sort((a, b) => a.order - b.order)
  }, [items])

  const completedItems = useMemo(() => {
    return items
      .filter(item => item.status === TodoStatus.COMPLETED)
      .sort((a, b) => {
        const dateA = a.completedAt ? new Date(a.completedAt) : null
        const dateB = b.completedAt ? new Date(b.completedAt) : null
        if (dateA === null && dateB === null) return 0; // Both dates are undefined, keep their order unchanged
        if (dateA === null) return 1; // dateA is undefined, move it to the end of the array
        if (dateB === null) return -1; // dateB is undefined, move it to the end of the array
        // Compare the dates in descending order (from the most recent to the oldest)
        return dateB.getTime() - dateA.getTime();
      })
  }, [items])

  useEffect(() => {
    if ((activeItems.length + completedItems.length) < ACTIVE_TODOS_LIMIT) {
      setErrorMsg(p => {
        const errMsgIdx = p.findIndex(errMsg => errMsg === ERROR_MESSAGES.MAX_LENGTH)
        if (errMsgIdx !== -1) {
          const errors = [...p]
          errors.splice(errMsgIdx, 1)
          return errors
        }
        else return p
      })
    }
  }, [activeItems])

  // *** Functions ***
  const updateFormValue = useCallback((toDos?: ToDo[]) => {
    const widgetFormValue = getValues('todoListWidget')
    const toDosValue = toDos?.slice() || widgetFormValue?.toDos?.slice()
    if (editingId) {
      const editingToDoIdx = toDosValue?.findIndex(toDo => toDo.id === editingId) ?? -1
      if (toDosValue && editingToDoIdx !== -1) {
        toDosValue.splice(editingToDoIdx, 1, {...toDosValue[editingToDoIdx], updatedAt: new Date().toISOString()})
      }
    }

    if (widgetFormValue) {
      const newValue = {
        ...widgetFormValue,
        toDos: toDosValue,
        updatedAt: new Date().toISOString(),
      }
      setValue('todoListWidget', newValue, { shouldDirty: true })
    }
    setItems(toDosValue || [])
    trigger('todoListWidget.toDos').then(isValid => {
      if (isValid) {
        setEditingId(undefined)
        setIsNewItem(false)
      }
    })
  }, [getValues, setValue, trigger, isNewItem, editingId])
  
  const addToDo = useCallback(() => {
    if ((activeItems.length + completedItems.length) >= ACTIVE_TODOS_LIMIT) {
      logger.hub.widgets.todo.warn(`Reached the maximum todo limit ${ACTIVE_TODOS_LIMIT}, Cannot add new todos`)
      setErrorMsg([ERROR_MESSAGES.MAX_LENGTH])
      return
    }
    if (!editingId) {
      const newTodo = {
        id: uuid(),
        title: '',
        resolution: '',
        status: TodoStatus.ACTIVE,
        order: 0,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      }
      const newItems = [newTodo, ...items].map((toDo, index) => ({ ...toDo, order: index }))
      const widgetFormValue = getValues('todoListWidget')
      if(widgetFormValue){
        setValue('todoListWidget', {...widgetFormValue, toDos: newItems}, { shouldDirty: true })
      }
      setItems(newItems)
      setIsNewItem(true)
      setEditingId(newTodo.id)

      analytics?.track('HUB_TASK_ADD', {
        action: 'CREATE',
        category: 'HUB',
        hubId: hubORM?.model.id,
        toDoId: newTodo.id,
      });
      logger.hub.widgets.todo.debug(`Adding new todo item: ${newTodo.id}`)
    }
  }, [items, activeItems, completedItems, editingId, getValues, setValue])

  const updateItemStatus = useCallback((id: string, status: TodoStatus) => {
    logger.hub.widgets.todo.debug(`Updating todo item ${id} status to ${status}`)
    // The items array is sorted in this order: [...completedItems, ...activeItems, ...deletedItems]
    const isCompleted = status === TodoStatus.COMPLETED
    const isActive = status === TodoStatus.ACTIVE
    const newIndex = isCompleted
      ? 0
      : isActive
        ? completedItems.length - 1
        : items.length - 1
    const itemsCopy = [...items]
    const index = items.findIndex((item) => item.id === id)
    // We only want to track TASK_COMPLETE for the first time
    if (isCompleted && !itemsCopy[index].completedAt) {
      analytics?.track('HUB_TASK_COMPLETE', {
        category: 'HUB',
        hubId: hubORM?.model.id,
        toDoId: id,
      })
    }
    itemsCopy[index] = {
      ...items[index],
      status,
      updatedAt: new Date().toISOString(),
      completedAt: isCompleted ? new Date().toISOString() : items[index].completedAt,
    };
    const newItems = arrayMove(itemsCopy, index, newIndex)
    const newItemsWithUpdatedOrder = newItems.map((item, index) => {
      return { ...item, order: index }
    })
    updateFormValue(newItemsWithUpdatedOrder)
  }, [items, completedItems])

  const onCancelEdit = useCallback(() => {
    if (isNewItem) {
      remove(items.findIndex((toDo) => toDo.id === editingId))
      const newItems = items.filter((toDo) => toDo.id !== editingId)
      setIsNewItem(false)
      setEditingId(undefined)
      updateFormValue(newItems)
    }
    else {
      setIsNewItem(false)
      setEditingId(undefined)
      updateFormValue(items)
    }
  }, [items, isNewItem, editingId])

  const handleOnCompleted = useCallback((
    id: string,
    isCompleted: boolean,
    setIsCompleted: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (!isCompleted) {
      setIsCompleted(isCompleted)
      updateItemStatus(id, TodoStatus.ACTIVE)
    }
    else {
      const toDoItem = items.find((toDo) => toDo.id === id)
      if (!toDoItem?.resolution) {
        const onCancel = () => {
          setIsCompleted(isCompleted)
          setEditingId(id)
        }
        const onConfirm = () => {
          setIsCompleted(isCompleted)
          updateItemStatus(id, TodoStatus.COMPLETED)
        }
        dispatch(
          DNAModalActions.setModal({
            isVisible: true,
            allowBackdropCancel: true,
            component: () => (<DNACommonConfirmation
              title="Add resolution?"
              confirmActionText="Add resolution"
              onConfirmAction={onCancel}
              cancelText="Mark as complete"
              onCancelCallback={onConfirm}
              cancelButtonProps={{
                status: 'primary',
                iconLeft: 'checkbox-marked-outline',
                appearance: 'outline',
              }}
              descriptionText={'Adding a resolution or description can help your audience better understand\n' +
                'this task and how it was solved.'}

            />),
          }));
      }
      else {
        setIsCompleted(isCompleted)
        updateItemStatus(id, TodoStatus.COMPLETED)
      }
    }
  }, [dispatch, items, updateItemStatus])

  return (
    <TodoContext.Provider
      value={
        {
          rhForm,
          widget,
          items,
          errorMsg,
          setErrorMsg,
          showCompleted,
          setShowCompleted,
          activeItems,
          completedItems,
          isNewItem,
          editingId,
          setEditingId,
          updateFormValue,
          addToDo,
          updateItemStatus,
          onCancelEdit,
          handleOnCompleted,
        }}
    >
      {children}
    </TodoContext.Provider>
  );
};

export const useTodoState = () => {
  const context = useContext(TodoContext)
  if (!context) {
    const errorText = 'useTodoState must be used within the TodoProvider'
    logger.hub.widgets.todo.error(errorText)
    throw new Error(errorText)
  }
  return context;
}
