import React from 'react'
import { StyleSheet } from 'react-native'
import { DNABox, DNAText } from '@alucio/lux-ui'
import { DNACategories } from '@alucio/lux-ui/lib/components/type/DNAText/DNAText'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'

import { useCurrentUser } from 'src/state/redux/selector/user'

const S = StyleSheet.create({
  placeholderContainer: {
    borderRadius: 200,
    backgroundColor: colors['color-brand2-600'],
  },
})

type ProfilePlaceHolderProps = {
  size?: 'small' | 'medium' | 'large'
}

const sizeMap: {
  [K in Exclude<ProfilePlaceHolderProps['size'], undefined>]: {
    width: number,
    height: number,
    typeSize: DNACategories,
    textStyle: {}
  }
} = {
  small: { height: 45, width: 45, typeSize: 'h5', textStyle: { marginTop: -1 } },
  medium: { height: 90, width: 90, typeSize: 'h1', textStyle: { marginTop: 1 } },
  large: { height: 135, width: 135, typeSize: 'h1', textStyle: { marginTop: 2, fontSize: 48 } },
}

// Currently serves a placeholder since we have not implemented profile pictures yet
export const ProfilePicture: React.FC<ProfilePlaceHolderProps> = (props) => {
  const { size = 'small' } = props
  const { userProfile: user } = useCurrentUser();
  const abbreviated = `${user?.givenName.charAt(0)}${user?.familyName.charAt(0)}`

  const categoryProp = {
    [sizeMap[size].typeSize]: true,
  }

  return (
    <DNABox
      alignX="center"
      alignY="center"
      style={[S.placeholderContainer, sizeMap[size]]}
    >
      <DNAText
        {...categoryProp}
        status="basic"
        style={sizeMap[size].textStyle}
      >
        {abbreviated}
      </DNAText>
    </DNABox>
  )
}

export default ProfilePicture
