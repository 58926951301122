import React from 'react'
import { GenericToast, ToastOrientations, useToast } from '@alucio/lux-ui';
import DNACommonConfirmation from './DNACommonConfirmation';

interface DeleteConfirmationProps {
  onDelete: () => void,
}

const DNAMeetingContentDeleteModal = (props: DeleteConfirmationProps) => {
  const { onDelete } = props
  const toast = useToast();

  const onConfirm = () => {
    onDelete();
    toast?.add(
      <GenericToast title="Content presented deleted" status="error" />,
      ToastOrientations.TOP_RIGHT,
    )
  }

  return (<DNACommonConfirmation
    status="danger"
    cancelText="Cancel"
    descriptionText="All meeting-related information will be lost."
    confirmActionText="Delete"
    onConfirmAction={onConfirm}
    title="Delete content presented?"
  />)
}

export default DNAMeetingContentDeleteModal
