import { CONTENT_PRESENTED_STATUS } from '@alucio/aws-beacon-amplify/src/API';
import {
  ContentPresented,
  MeetingContentType,
  PagePresentedEvent,
  PresentedMeta,
  Sentiment,
} from '@alucio/aws-beacon-amplify/src/models';
import { useDispatch } from 'src/state/redux';
import { meetingActions } from 'src/state/redux/slice/meeting';
import { isCustomDeckORM, isDocumentVersionORM, isFolderItemORM, isPageGroupORM, MeetingORM } from 'src/types/types'
import { getContentPresentedIndexByActivePresentation } from '../helper';
import { PresentableModelORM, useContent } from 'src/state/context/ContentProvider/ContentProvider'
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  ANALYTICS_EVENT_NAME,
  SENTIMENT_VALUE,
} from 'src/components/ContentPreviewModalV2/State/MeetingPresentedMetaProvider';
import debounce from 'lodash/debounce';
import * as logger from 'src/utils/logger'

const getPresentableModelTitle = (orm: PresentableModelORM) => {
  const title = (
    (isCustomDeckORM(orm) || isDocumentVersionORM(orm))
      ? orm.model.title
      : isPageGroupORM(orm)
        ? orm.relations.documentVersionORM.model.title
        : (orm.model.customTitle || orm.meta.title)
  ) ?? ''
  return title
}

export const getContentId = (orm?: PresentableModelORM) => {
  return orm && ((isFolderItemORM(orm) && orm.relations.itemORM.model.id) ||
    (isDocumentVersionORM(orm) && orm.model.id) ||
    // NOTE: We are getting the document ID instead of the pageGroup ID because in the meetings log we want to show the entire doc instead of the group
    // We might decide to show the group later in the meetings list but for now we are only showing the entire document
    (isPageGroupORM(orm) && orm.relations.documentVersionORM.model.id))
}

const useSyncContentPresented = (isMainPlayer: boolean, meetingORM?: MeetingORM) => {
  const dispatch = useDispatch();
  const {
    activePresentation, addContentPresented, updateContentPresented, contentPresented,
    contentPageData,
  } = useContent();
  // extract the documentVersionIdsPresented from the contentPresented
  const documentVersionIdsPresentedRef = useRef(
    Array.from(
      new Set(
        meetingORM?.model.contentPresented
          ?.flatMap((content) =>
            content?.events?.map((meta) => {
              const lastIndex = meta.pageId.lastIndexOf('_');
              return lastIndex !== -1 ? meta.pageId.slice(0, lastIndex) : '';
            }),
          )
          .filter((value) => value !== ''),
      ),
    ),
  );

  const currentContentId = useMemo(() => getContentId(activePresentation?.presentable.orm), [activePresentation]);
  const folderItemId = useMemo(() => isFolderItemORM(activePresentation?.presentable.orm)
    ? activePresentation?.presentable.orm.model.id : undefined, [activePresentation]);
  const presentedMeta = useMemo(getCurrentPresentedMeta, [contentPresented]);
  const debouncedRecordPresentedMetaAnalytics = useCallback(debounce(recordPresentedMetaAnalytics, 1000),
    [activePresentation?.currentPresentablePage, activePresentation?.presentable]);

  useEffect(() => setContentPresented(isMainPlayer), [
    activePresentation?.currentPresentablePage.presentationPageNumber,
    activePresentation?.presentable.id,
  ]);

  // ANALYTICS: DOCUMENT_PRESENT //
  useEffect(() => {
    if (activePresentation?.presentable && isMainPlayer) {
      const { orm } = activePresentation.presentable;
      const { documentVersionORM } = activePresentation.currentPresentablePage;
      const isModifiedDocumentVersion = isFolderItemORM(orm)

      let documentVersionId;
      let documentId;
      if (isModifiedDocumentVersion) {
        documentVersionId = documentVersionORM.model.id
        documentId = documentVersionORM.relations.documentORM.model.id;
      }
      else if (isDocumentVersionORM(orm)) {
        documentVersionId = orm.model.id
        documentId = orm.relations.documentORM.model.id;
      }

      const shouldSendDocumentPresentedEvent = !documentVersionIdsPresentedRef.current?.includes(documentVersionId)
      if (shouldSendDocumentPresentedEvent) {
        documentVersionIdsPresentedRef.current?.push(documentVersionId)
        analytics?.track('DOCUMENT_PRESENT', {
          action: 'PRESENT',
          category: 'DOCUMENT',
          context: meetingORM?.model.type,
          meetingId: meetingORM?.model.id,
          documentId,
          documentVersionId,
        });
      }
    }
  }, [activePresentation?.currentPresentablePage.documentVersionORM]);

  // ANALYTICS: SLIDE_PRESENTED //
  useEffect(() => {
    if (activePresentation?.currentPresentablePage && isMainPlayer) {
      const { orm } = activePresentation.presentable;
      const { documentVersionORM, page } = activePresentation.currentPresentablePage;

      const isModifiedDocumentVersion = isFolderItemORM(orm)
      let documentVersionId;
      let documentId;
      let customDeckId
      if (isModifiedDocumentVersion) {
        documentVersionId = documentVersionORM.model.id
        documentId = documentVersionORM.relations.documentORM.model.id;
      }
      else if (isDocumentVersionORM(orm)) {
        documentVersionId = orm.model.id
        documentId = orm.relations.documentORM.model.id;
      } else if (isPageGroupORM(orm)) {
        documentVersionId = orm.relations.documentVersionORM.model.id
        documentId = orm.relations.documentVersionORM.relations.documentORM.model.id
      }

      if (isModifiedDocumentVersion &&
        !isPageGroupORM(orm) &&
        !isDocumentVersionORM(orm) &&
        !isDocumentVersionORM(orm.relations.itemORM)
      ) {
        customDeckId = orm.relations.itemORM.model.id
      }

      const pageNumber = isModifiedDocumentVersion ? page.number
        : activePresentation?.currentPresentablePage.presentationPageNumber
      analytics?.track('SLIDE_PRESENTED', {
        action: 'PRESENTED',
        category: 'SLIDE',
        context: meetingORM?.model.type,
        customDeckId,
        meetingId: meetingORM?.model.id,
        documentId,
        documentVersionId,
        pageNumber: pageNumber,
      });
    }
  }, [activePresentation?.currentPresentablePage])

  function setContentPresented(shouldSaveChangesInDB: boolean): void {
    if (!activePresentation || !meetingORM) return;
    const { orm } = activePresentation.presentable
    const isDocumentVersion =
      isDocumentVersionORM(orm) ||
      (isFolderItemORM(orm) && isDocumentVersionORM(orm.relations.itemORM))
    const contentType = isPageGroupORM(orm)
      ? MeetingContentType.DOCUMENT_VERSION_GROUP
      : isDocumentVersion
        ? MeetingContentType.DOCUMENT_VERSION
        : MeetingContentType.CUSTOM_DECK
    if (!currentContentId) return;
    const groupId = isPageGroupORM(orm) ? orm.model.id : undefined
    const folderItemId = isFolderItemORM(orm) ? orm.model.id : undefined
    const now = new Date().toISOString();

    const { presentedContentIndex, presentedMetaIndex } =
          getContentPresentedIndexByActivePresentation(contentPresented || [], activePresentation);
    let existingContentPresented
    const [prevContentPresented] = contentPresented?.slice(-1) || [];
    if (contentPresented && presentedContentIndex !== undefined) {
      existingContentPresented = contentPresented[presentedContentIndex];
    }

    logger.useSyncContentPresented.debug('Set Content Presented: ', { existingContentPresented })

    const newPagePresentedEvent: PagePresentedEvent = {
      pageNumber: activePresentation?.currentPresentablePage.presentationPageNumber,
      timestamp: new Date().toISOString(),
      pageId: activePresentation?.currentPresentablePage.page.pageId,
    };

    const newPresentedMeta = {
      pageId: newPagePresentedEvent.pageId,
      presented: true,
      title: contentPageData?.find(page => page.presentationPageNumber === newPagePresentedEvent.pageNumber)?.title,
    }
    const updatedContentPresented: ContentPresented = {
      contentId: currentContentId,
      groupId:  groupId,
      folderItemId: folderItemId,
      status: CONTENT_PRESENTED_STATUS.ACTIVE,
      contentType: contentType,
      openedAt: now,
      events: [newPagePresentedEvent],
      title: getPresentableModelTitle(orm),
      groupTitle: isPageGroupORM(orm) ? orm.model.name : undefined,
      presentedMeta: [newPresentedMeta],
    };

    let events : PagePresentedEvent[] = []
    if (prevContentPresented) {
      events = (prevContentPresented?.events || []).map((event, i, arr) => ({
        ...event,
        end: i === arr.length - 1 ? event.end ?? now : event.end,
      }));

      updateContentPresented({
        ...prevContentPresented,
        events: events,
      })
    }

    if (!existingContentPresented) {
      addContentPresented(updatedContentPresented)
      const newContentPresented = (contentPresented || meetingORM.model.contentPresented).map((content) => {
        if (prevContentPresented && content.contentId === prevContentPresented.contentId) {
          return { ...prevContentPresented, events };
        }
        return content;
      });

      // THIS FLAG IS ADDED SO ONLY ONE MEETING'S INSTANCE SAVES TO DATASTORE (INSTEAD OF BOTH THE EXTERNAL/MAIN WINDOW) //
      shouldSaveChangesInDB &&
      dispatch(meetingActions.updateMeeting(meetingORM.model, {
        ...meetingORM.model,
        contentPresented: [...newContentPresented, updatedContentPresented],
      }))
      return;
    }

    events = existingContentPresented.events?.map((event, i) => {
      if (i === existingContentPresented.events.length - 1) {
        return {
          ...event,
          end: event.end || now,
        }
      }
      return event
    }) || [];

    updateContentPresented({
      ...existingContentPresented,
      events: [...events, newPagePresentedEvent],
      presentedMeta: Number.isInteger(presentedMetaIndex) && presentedMetaIndex! >= 0
        ? existingContentPresented.presentedMeta : [...existingContentPresented.presentedMeta, newPresentedMeta],
    })
  }

  // ** PRESENTEDMETA RELATED FUNCTIONS ** //
  function getCurrentPresentedMeta(): PresentedMeta | undefined {
    if (!contentPresented || !activePresentation || !meetingORM || !currentContentId) {
      return;
    }
    const { presentedContentIndex, presentedMetaIndex } =
      getContentPresentedIndexByActivePresentation(contentPresented, activePresentation);

    if (presentedContentIndex === undefined || presentedMetaIndex === undefined) {
      return;
    }

    return contentPresented[presentedContentIndex].presentedMeta[presentedMetaIndex]
  }

  function updateFollowUp(): void {
    if (!contentPresented || !activePresentation || !meetingORM || !currentContentId) {
      return;
    }

    const { presentedContentIndex, presentedMetaIndex } =
      getContentPresentedIndexByActivePresentation(contentPresented, activePresentation);

    if (presentedContentIndex === undefined || presentedMetaIndex === undefined) {
      return;
    }

    // UPDATE EXISTED PRESENTEDMETA
    const contentPresentedMeta = [...contentPresented[presentedContentIndex].presentedMeta];

    const existedFollowUp = contentPresentedMeta[presentedMetaIndex].followUp
    contentPresentedMeta[presentedMetaIndex] = {
      ...contentPresentedMeta[presentedMetaIndex],
      followUp: !existedFollowUp,
    };

    updateContentPresented({
      ...contentPresented[presentedContentIndex],
      presentedMeta: contentPresentedMeta,
    });
    debouncedRecordPresentedMetaAnalytics(ANALYTICS_EVENT_NAME.SLIDE_FLAG, !existedFollowUp ? 1 : -1);
  }

  function updateReaction(sentiment: Sentiment): void {
    if (!contentPresented || !activePresentation || !meetingORM || !currentContentId) {
      return;
    }
    const { presentedContentIndex, presentedMetaIndex } =
      getContentPresentedIndexByActivePresentation(contentPresented, activePresentation);

    if (presentedContentIndex === undefined || presentedMetaIndex === undefined) {
      return;
    }

    // UPDATE EXISTED PRESENTEDMETA
    const contentPresentedMeta = [...contentPresented[presentedContentIndex].presentedMeta];
    const existedSentiment = contentPresentedMeta[presentedMetaIndex].sentiment
    const newSentiment = (!existedSentiment || existedSentiment !== sentiment) ? sentiment : undefined;
    contentPresentedMeta[presentedMetaIndex] = {
      ...contentPresentedMeta[presentedMetaIndex],
      sentiment: newSentiment,
    };

    updateContentPresented({
      ...contentPresented[presentedContentIndex],
      presentedMeta: contentPresentedMeta,
    });
    debouncedRecordPresentedMetaAnalytics(ANALYTICS_EVENT_NAME.SLIDE_REACTION,
      !newSentiment ? 0 : SENTIMENT_VALUE[newSentiment]);
  }

  function addNotes(notes: string): void {
    if (!contentPresented || !activePresentation || !meetingORM || !currentContentId) {
      return;
    }
    const { presentedContentIndex, presentedMetaIndex } =
      getContentPresentedIndexByActivePresentation(contentPresented, activePresentation);

    const presentedMeta: PresentedMeta = {
      note: notes,
      pageId: activePresentation.currentPresentablePage.page.pageId,
      presented: true,
    };

    if (presentedContentIndex === undefined || presentedMetaIndex === undefined) {
      return;
    }

    // WE UPDATE THE NOTES OF THE CURRENT EXISTING PRESENTEDMETA
    // IF THE NOTES ARE THE SAME (THERE ARE NO CHANGES)
    if (notes === contentPresented[presentedContentIndex].presentedMeta[presentedMetaIndex].note) {
      return;
    }
    const contentPresentedMeta = [...contentPresented[presentedContentIndex].presentedMeta];
    contentPresentedMeta[presentedMetaIndex] = {
      ...contentPresentedMeta[presentedMetaIndex],
      ...presentedMeta,
    };

    const updatedContent = {
      ...contentPresented[presentedContentIndex],
      presentedMeta: contentPresentedMeta,
    };

    updateContentPresented(updatedContent);

    dispatch(meetingActions.updateMeeting(meetingORM.model, {
      ...meetingORM.model,
      contentPresented:
        contentPresented.map((content, idx) => idx === presentedContentIndex ? updatedContent : content),
    }))
    debouncedRecordPresentedMetaAnalytics(ANALYTICS_EVENT_NAME.SLIDES_NOTES_CREATED);
  }

  function recordPresentedMetaAnalytics(eventName: ANALYTICS_EVENT_NAME, value?: number): void {
    const customDeckId = isFolderItemORM(activePresentation?.presentable.orm)
      ? (isCustomDeckORM(activePresentation?.presentable.orm.relations.itemORM)
        ? activePresentation?.presentable.orm.relations.itemORM.model.id : undefined) : undefined;

    analytics?.track(eventName, {
      category: 'MEETING',
      context: 'MEETING',
      documentId: activePresentation?.currentPresentablePage?.documentVersionORM.model.documentId,
      documentVersionId: activePresentation?.currentPresentablePage?.documentVersionORM.model.id,
      customDeckId,
      folderItemId,
      meetingId: meetingORM?.model.id,
      pageNumber: activePresentation?.currentPresentablePage?.page.number,
      value,
    })
  }

  return { addNotes, updateReaction, updateFollowUp, presentedMeta };
}

export default useSyncContentPresented;
