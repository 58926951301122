/* eslint-disable max-len */
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgTextBoxEditOutline = (props: SvgProps) => (
  <Svg
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <Path
      fill={props.color ?? '#fff'}
      d="M10 21H5c-1.11 0-2-.89-2-2V5c0-1.11.89-2 2-2h14c1.11 0 2 .89 2 2v5.33c-.3-.12-.63-.19-.96-.19-.37 0-.72.08-1.04.23V5H5v14h5.11l-.11.11V21M7 9h10V7H7v2m0 8h5.11L14 15.12V15H7v2m0-4h9.12l.88-.88V11H7v2m14.7.58-1.28-1.28a.55.55 0 0 0-.77 0l-1 1 2.05 2.05 1-1a.55.55 0 0 0 0-.77M12 22h2.06l6.05-6.07-2.05-2.05L12 19.94V22Z"
    />
  </Svg>
);

export default SvgTextBoxEditOutline;
