import { DNAButton, DNAModal, DNAText } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import React from 'react';
import { DNAModalVariant } from '../DNAConnectedModal';
import { ShareFolderMode } from './DNAShareFolderModal';

interface UnshareWarningProps {
  setMode: (mode: ShareFolderMode) => void;
  display: boolean;
  onUnshare: () => void;
}
const UnshareWarning: DNAModalVariant<UnshareWarningProps> = React.memo((props) => {
  const { setMode, display, onUnshare } = props;

  return (
    <DNAModal style={{ display: display ? undefined : 'none' }}>
      <DNAModal.Header style={{ paddingVertical: 16, paddingHorizontal: 24 }}>
        <DNAText bold>Unshare folder?</DNAText>
      </DNAModal.Header>
      <DNAModal.Body style={{ padding : 24 }}>
        <DNAText style={{ color: colors['color-gray-300'], width: 512 }}>
          {/* eslint-disable-next-line max-len */}
          All users and access filters will be removed from this folder. You’ll still have this folder in your My Folders.
        </DNAText>
      </DNAModal.Body>
      <DNAModal.Confirmation style={{ paddingVertical: 12, paddingHorizontal: 24 }}>
        <DNAButton
          size="sm"
          onPress={() => setMode(ShareFolderMode.SHARE_FOLDER)}
          appearance="outline"
          status="info"
        >
          Cancel
        </DNAButton>
        <DNAButton size="sm" onPress={onUnshare} status="primary">
          Unshare
        </DNAButton>
      </DNAModal.Confirmation>
    </DNAModal>
  )
});

export default UnshareWarning;
