import React from 'react';
import { Pressable, ScrollView, StyleSheet } from 'react-native';
import {
  Collapsible,
  DNABox,
  DNAButton,
  DNADivider,
  DNAText,
} from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { MeetingType } from '@alucio/aws-beacon-amplify/src/models';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import { NOTES } from './AllNotes';

export const styles = StyleSheet.create({
  inpersonNotesHeader: {
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  virtualNotesHeader: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    backgroundColor: colors['color-gray-800'],
  },
  notesContent: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    width: '100%',
  },
});

interface SpeakerNotesProps {
  openNotes: NOTES[]
  containerDimension: { width: number, height: number }
}

const SpeakerNotes: React.FC<SpeakerNotesProps> = ({ openNotes, containerDimension }) => {
  const { activePresentation, contentPageData } = useContent();
  const { meetingORM, toggleSpeakerNotes } = useMeetingsState();
  const isInPerson = meetingORM?.model.type === MeetingType.IN_PERSON;
  const speakerNotes = activePresentation?.currentPresentablePage && contentPageData
    ? contentPageData[activePresentation?.currentPresentablePage.presentationPageNumber - 1]?.speakerNotes : undefined;
  const isSpeakerNoteOpen = openNotes.includes(NOTES.SPEAKER_NOTES);

  if (isInPerson) return null
  return (
    <DNABox appearance="col" fill={isSpeakerNoteOpen}>
      {/* Virtual Header */}
      <Pressable onPress={toggleSpeakerNotes}>
        <DNABox
          spacing="sm"
          alignY="center"
          style={styles.virtualNotesHeader}
        >
          <DNAButton
            status="dark"
            appearance="ghost"
            rounded="full"
            padding="xs"
            iconLeft={isSpeakerNoteOpen ? 'chevron-down' : 'chevron-right'}
            onPress={toggleSpeakerNotes}
          />
          <DNAText status="basic">Speaker Notes</DNAText>
        </DNABox>
      </Pressable>
      <DNADivider variant="virtual" />
      {/* Content */}
      <DNABox fill>
        <Collapsible collapsed={!isSpeakerNoteOpen}>
          <ScrollView style={containerDimension}>
            <DNAText status="basic" style={styles.notesContent}>{speakerNotes ?? 'No slide notes'}</DNAText>
          </ScrollView>
        </Collapsible>
      </DNABox>
    </DNABox>
  )
}

export default SpeakerNotes
