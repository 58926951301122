import {
  Attendee,
  ContentPresented,
  CustomValues,
  Meeting,
  MeetingStatus,
  MeetingType,
} from '@alucio/aws-beacon-amplify/src/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { commonReducers, initialState, SliceState } from './common';
import { DataStore } from '@aws-amplify/datastore';
import ActiveUser from '../../global/ActiveUser'

const sliceName = 'meeting'
const { reducers, extraReducers } = commonReducers<Meeting>(sliceName)

interface CreateMeetingPayload {
  title?: string;
  startTime: string;
  endTime?: string;
  contentPresented?: ContentPresented[];
  type: MeetingType;
  notes?: string;
  status: MeetingStatus;
  customValues: CustomValues[];
  callback: (meeting: Meeting) => void,
  attendees?: Attendee[],
}

interface EndMeetingPayload {
  contentPresented: ContentPresented[];
}

interface StartedMeetingPayload {
  type: MeetingType;
  title: string;
  context?: string;
  callback?: (meetingId: string) => void;
}

function getLastContentPresented(endMeetingPayload: EndMeetingPayload): ContentPresented | undefined {
  return endMeetingPayload.contentPresented?.reduce((prev: ContentPresented | undefined, current: ContentPresented) => {
    if (!prev || !prev.events || prev.events.length === 0) {
      return current;
    }
    if (!current.events || current.events.length === 0) {
      return prev;
    }

    const prevMaxTimestamp = Math.max(...prev.events.map(event => new Date(event.timestamp).getTime()));
    const currentMaxTimestamp = Math.max(...current.events.map(event => new Date(event.timestamp).getTime()));

    return prevMaxTimestamp > currentMaxTimestamp ? prev : current;
  }, undefined);
}

function shouldUpdateContentPresented (lastContentPresented: ContentPresented | undefined, meeting: Meeting): boolean {
  if (!lastContentPresented) {
    return false;
  }
  const { events = [], contentId, groupId, folderItemId } = lastContentPresented
  const matchingContent = meeting.contentPresented.find(content =>
    content.contentId === contentId &&
    (groupId === content.groupId || !groupId) &&
    (folderItemId === content.folderItemId || !folderItemId))

  const matchingContentEvents = matchingContent?.events || [];
  return events?.length > matchingContentEvents.length;
}

function updateContentPresented(meeting: Meeting, endMeetingPayload: EndMeetingPayload): ContentPresented[] {
  const now = new Date().toISOString();
  const lastContentPresented = getLastContentPresented(endMeetingPayload);
  const shouldUpdateLastEvent = shouldUpdateContentPresented(lastContentPresented, meeting);
  return endMeetingPayload.contentPresented.map((content) => {
    if (lastContentPresented && content.contentId === lastContentPresented.contentId &&
      (content.groupId === lastContentPresented.groupId || !lastContentPresented.groupId) &&
      (content.folderItemId === lastContentPresented.folderItemId || !lastContentPresented.folderItemId)
    ) {
      return {
        ...content,
        closedAt: content.closedAt ?? now,
        events: (content.events || []).map((event, eventIndex) => {
          if (eventIndex === (content.events || []).length - 1 && shouldUpdateLastEvent) {
            return {
              ...event,
              end: event.end ?? now,
            };
          }
          return event;
        }),
      };
    }
    return content;
  });
}

const meetingSlice = createSlice({
  name: sliceName,
  initialState: initialState<Meeting>(),
  reducers: {
    ...reducers,
    createMeeting: ( // this method should require the startTime/endTime because it is created from the meetings  history page
      _state: SliceState<Meeting>,
      action: PayloadAction<CreateMeetingPayload>,
    ): void => {
      const { payload } = action;
      const user = ActiveUser.user;
      const now = new Date().toISOString();
      const meeting = new Meeting({
        tenantId: user!.tenantId,
        title: payload.title,
        startTime: payload.startTime,
        contentPresented: payload.contentPresented || [],
        type: payload.type,
        notes: payload.notes,
        endTime: payload.endTime,
        status: payload.status,
        createdBy: user!.id,
        createdAt: now,
        updatedBy: user!.id,
        updatedAt: now,
        customValues: payload.customValues,
        // [TODO-2780] - This contradicts the type assertion above - fix it up there or here
        attendees: payload.attendees!,
        // TO BE REMOVED
        fieldValues: [],
      });

      analytics?.track('MEETING_MANUAL_ADD', {
        action: 'MANUAL_ADD',
        category: 'MEETING',
        meetingId: meeting.id,
      });

      if (payload.status === MeetingStatus.PLANNED || payload.status === MeetingStatus.ENDED_EXIT) {
        const track = payload.status === MeetingStatus.PLANNED ? 'MEETING_PLANNED_STATUS' : 'MEETING_COMPLETED_STATUS';
        analytics?.track(track, {
          category: 'MEETING',
          meetingId: meeting.id,
        });
      }

      DataStore.save(meeting).then(payload.callback) // need to verify next step!
    },
    updateMeeting: {
      prepare: (meeting: Meeting, updatedContent: Meeting) => {
        if (meeting.status !== MeetingStatus.IN_PROGRESS && updatedContent.status === MeetingStatus.IN_PROGRESS) {
          analytics?.track('MEETING_STARTED', {
            action: 'STARTED',
            category: 'MEETING',
            mode: meeting.type,
            meetingId: meeting.id,
            context: 'MEETING_HISTORY',
          });
        }

        if (meeting.status !== MeetingStatus.ENDED_EXIT && updatedContent.status === MeetingStatus.ENDED_EXIT) {
          analytics?.track('MEETING_COMPLETED_STATUS', {
            category: 'MEETING',
            mode: meeting.type,
            meetingId: meeting.id,
            context: 'MEETING_HISTORY',
          });
        }

        return {
          payload: {
            model: Meeting,
            entity: meeting,
            updates: { ...updatedContent },
          },
        }
      },
      reducer: reducers.batchSave,
    },
    startMeeting: (
      _state: SliceState<Meeting>,
      action: PayloadAction<StartedMeetingPayload>,
    ): void => {
      const { payload } = action;
      const user = ActiveUser.user;
      const now = new Date().toISOString();

      DataStore.save(
        new Meeting({
          tenantId: user!.tenantId,
          startTime: now,
          type: payload.type,
          title: payload.title,
          status: MeetingStatus.IN_PROGRESS,
          contentPresented: [],
          createdAt: now,
          createdBy: user!.id,
          updatedAt: now,
          updatedBy: user!.id,
          fieldValues: [],
          attendees: [],
        }),
      ).then((meeting) => {
        payload.callback?.(meeting.id)
        analytics?.track('MEETING_STARTED', {
          action: 'STARTED',
          category: 'MEETING',
          mode: meeting.type,
          meetingId: meeting.id,
          context: payload.context,
        });
      })
    },
    endMeeting: {
      prepare: (meeting: Meeting, endMeetingPayload: EndMeetingPayload) => {
        analytics?.track('MEETING_ENDED', {
          action: 'ENDED',
          category: 'MEETING',
          mode: meeting.type,
          meetingId: meeting.id,
        });
        const now = new Date().toISOString();
        const contentPresented = updateContentPresented(meeting, endMeetingPayload);

        return {
          payload: {
            model: Meeting,
            entity: meeting,
            updates: {
              endTime: meeting.endTime ?? now,
              status: MeetingStatus.ENDED_EXIT,
              contentPresented,
            },
          },
        }
      },
      reducer: reducers.batchSave,
    },
    deleteMeeting: {
      prepare: (meeting: Meeting) => {
        analytics?.track('MEETING_DELETED', {
          action: 'DELETED',
          category: 'MEETING',
          meetingId: meeting.id,
        });
        return {
          payload: {
            model: Meeting,
            entity: meeting,
            updates: {
              status: MeetingStatus.DELETED,
            },
          },
        }
      },
      reducer: reducers.save,
    },
    toggleMeetingType: {
      prepare: (meeting: Meeting, contentPresented: ContentPresented[]) => {
        const newMeetingType: MeetingType = meeting.type === MeetingType.VIRTUAL
          ? MeetingType.IN_PERSON : MeetingType.VIRTUAL;
        const eventName = newMeetingType === MeetingType.VIRTUAL
          ? 'MEETING_PRESENT_OPEN' : 'MEETING_PRESENT_CLOSE';

        analytics?.track(eventName, {
          category: 'MEETING',
          meetingId: meeting.id,
        });
        return {
          payload: {
            model: Meeting,
            entity: meeting,
            updates: {
              contentPresented,
              type: newMeetingType,
            },
          },
        }
      },
      reducer: reducers.save,
    },
  },
  extraReducers,
});

export default meetingSlice;
export const meetingActions = {
  ...meetingSlice.actions,
};
