import { useSelector } from 'react-redux'
import { createSelector, Selector } from '@reduxjs/toolkit'
import {
  CustomFieldUsage,
  LabelValues,
  Tenant,
  User,
  UserRole,
  UserStatus,
} from '@alucio/aws-beacon-amplify/src/models'
import { RootState } from '../store'
import { CognitoUser } from '../../../models/User';
import { mapFieldFilterEntries } from 'src/components/DNA/Document/DNADocumentFilters/DNADocumentFilters'
import { FilterEntry } from 'src/state/redux/document/query';
import { ORMTypes, UserORM } from '../../../types/orms';
import { getMappedCustomValues } from './common';

export interface LockedFilterEntry {
  [key: string]: string[]
}

export interface IndexedUserORM {
  [key: string]: UserORM
}

export interface CurrentUser {
  authProfile?: CognitoUser,
  userProfile?: User
  meta: {
    formattedFilters?: FilterEntry[],
    formattedLockedFilters?: LockedFilterEntry,
  },
  relations: {
    tenant?: Tenant,
  }
}

export const selUsers = (state: RootState): User[] => state.user.records
export const selCognitoUser = (state: RootState): CognitoUser | undefined => state.user.cognitoUser;
export const selTenants = (state: RootState): Tenant[] => state.tenant.records;
export const selIsLoading = (state: RootState): boolean => state.user.isLoading

export const usersList: Selector<RootState, UserORM[]> = createSelector(
  selUsers,
  selTenants,
  (users, tenants): UserORM[] => {
    const mapped = users.map<UserORM>(user => {
      const tenant = tenants.find(({ id }) => id === user.tenantId);
      if (!tenant && user?.role !== UserRole.ALUCIO_ADMIN) {
        throw new Error('Could not identify user\'s tenant')
      }

      const ORM: UserORM = {
        model: user,
        type: ORMTypes.USER,
        meta: {
          customFilterValues: {
            defaultFilters:
              getMappedCustomValues(
                { internalUsages: [CustomFieldUsage.USER_FILTER] },
                user.defaultFiltersCustomValues,
                tenant?.config.customFields),
            lockedFilters:
              getMappedCustomValues(
                { internalUsages: [CustomFieldUsage.USER_FILTER] },
                user.lockedFiltersCustomValues,
                tenant?.config.customFields),
          },
          relations: {
            tenant,
          },
          formattedName:
            `${user.givenName} ${user.familyName}${user.status === UserStatus.DEACTIVATED ? ' (deactivated)' : ''}`,
        },
      }

      return ORM
    })

    return mapped
  },
)

export const indexedUsersList: Selector<RootState, IndexedUserORM> =
  createSelector(
    usersList,
    (users) => users.reduce<IndexedUserORM>((acc, userORM) => {
      acc[userORM.model.id] = userORM;
      return acc;
    }, {}),
  )

export const publisherList: Selector<RootState, UserORM[]> = createSelector(
  usersList,
  (usersList): UserORM[] => usersList
    .filter(ORM => ORM.model.role === 'TenantPublisher'), // [TODO]: ENUM?
)

// TODO: What happens if the user is in cognito but no in Dynamo ?
// A Pre-SignIn lambda function should be created to verify before allowing the signIn (?)
export const loggedUser: Selector<RootState, CurrentUser> = createSelector(
  selUsers,
  selCognitoUser,
  selTenants,
  (users, cognitoUser, tenants): CurrentUser => {
    const user = users.find(({ id }) => id === cognitoUser?.attributes['custom:user_id'])
    const userTenant = tenants.find(({ id }) => id === cognitoUser?.attributes['custom:org_id']);

    const mapLockedFilters = (lockedFilters: LabelValues[]) => {
      return lockedFilters?.reduce((acc, lockedFilter) => {
        acc[lockedFilter.key] = lockedFilter.values;
        return acc;
      }, {});
    }

    return {
      authProfile: cognitoUser,
      userProfile: user,
      meta: {
        formattedFilters: mapFieldFilterEntries(
          [],
          user?.defaultFiltersCustomValues,
          userTenant,
          false,
        ).filter(entry => entry.default),
        formattedLockedFilters: mapLockedFilters(user?.lockedFilters!),
      },
      relations: {
        tenant: userTenant,
      },
    };
  },
);

export const currentUserORM: Selector<RootState, UserORM | undefined> = createSelector(
  usersList,
  selCognitoUser,
  selTenants,
  (usersList, cognitoUser): UserORM | undefined => {
    const user = usersList.find(({ model: { id } }) => id === cognitoUser?.attributes['custom:user_id'])
    return user
  },
)

export const userByEmail: Selector<RootState, UserORM | undefined> = createSelector(
  usersList,
  (_: RootState, email: string | undefined) => email,
  (usersList: UserORM[], email): UserORM | undefined => email ? usersList
    .find(ORM => ORM.model.email === email) : undefined, // [TODO]: ENUM?
)

export const usersByTenantId: Selector<RootState, UserORM[]> = createSelector(
  usersList,
  (_: RootState, tenantId: string | undefined) => tenantId,
  (usersList: UserORM[], tenantId): UserORM[] => tenantId ? usersList
    .filter(userORM => userORM.model.tenantId === tenantId) : [],
)

export const userById: Selector<RootState, UserORM | undefined> = createSelector(
  indexedUsersList,
  (_: RootState, id: string | undefined) => id,
  (indexedUsersList: IndexedUserORM, id): UserORM | undefined => (id && indexedUsersList[id]) || undefined,
)

export const userTenant: Selector<RootState, Tenant | undefined> = createSelector(
  loggedUser,
  selTenants,
  (user, tenants): Tenant | undefined => {
    return tenants.find(({ id }) => id === user?.authProfile?.attributes['custom:org_id']);
  },
);

export const usePublisherList = (): ReturnType<typeof publisherList> =>
  useSelector((state: RootState) => publisherList(state))

export const useCurrentUser = (): ReturnType<typeof loggedUser> =>
  useSelector((state: RootState) => loggedUser(state));

export const useCurrentUserORM = (): ReturnType<typeof currentUserORM> =>
  useSelector((state: RootState) => currentUserORM(state))

export const useUserTenant = (): ReturnType<typeof userTenant> =>
  useSelector((state: RootState) => userTenant(state));

export const useUserByEmail = (email: string | undefined): ReturnType<typeof userByEmail> =>
  useSelector((state: RootState) => userByEmail(state, email));

export const useUsersByTenantId = (tenantId: string | undefined): ReturnType<typeof usersByTenantId> =>
  useSelector((state: RootState) => usersByTenantId(state, tenantId));

export const useUserById = (id: string): ReturnType<typeof userById> =>
  useSelector((state: RootState) => userById(state, id));

export const useIndexedUsers = (): ReturnType<typeof indexedUsersList> =>
  useSelector((state: RootState) => indexedUsersList(state));
