import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAText, Iffy, util } from '@alucio/lux-ui';
import { useMediaQuery } from 'react-responsive';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import { getSlideTitle, getThumbURL } from 'src/utils/thumbnailHelpers';
import { usePublisherVersioningState } from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import { useMatchSlidesState } from 'src/state/machines/publisherVersioning/MatchSlides/MatchSlidesProvider';
// Dnd
import { DroppableContainer, SortableItem, TargetItem, useSingleItemDnd } from 'src/components/DnD/DNASingleItemDnd';
import { useSlideGroupTargetsState } from '../SlideGroupTargetsProvider';
import { useDispatch } from 'src/state/redux';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import MatchSlidesPreviewModal from '../MatchSlidesPreviewModal';
import { thumbnailSizeDimensions } from 'src/hooks/useThumbnailSize/useThumbnailSize';

const S = StyleSheet.create({
  emptyMatchingContainer: {
    width: thumbnailSizeDimensions.lg.width,
    height: thumbnailSizeDimensions.lg.height,
    borderStyle: 'dashed',
    borderWidth: 3,
    borderColor: colors['color-gray-200'],
    borderRadius: 4,
    backgroundColor: colors['color-gray-10'],
  },
  hoverStateEmptyMatchingContainer: {
    borderColor: colors['color-primary-500'],
  },
  emptyMatchingContainerText: {
    color: colors['color-text-tertiary'],
  },
  emptyOverlay: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
  revertButton: {
    opacity: 0.64,
  },
})

interface EmptySlideContainerProps {
  pageId: string,
  index: number,
}

interface SelectedMatchingSlideProps {
  matchingSlide: TargetItem[],
  containerId: string,
}

interface PreviousVersionContainerProps {
  pageId: string,
  index: number,
}

const EmptySlideContainer: React.FC<EmptySlideContainerProps> = ({ pageId, index }) => {
  const { onScrollToIndexInSlideGroupPool, onRevert, canRevert } = useMatchSlidesState();
  const { overContainer } = useSlideGroupTargetsState();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1300px)' })
  const enableRevert = canRevert(pageId)

  return (
    <>
      <DNABox
        fill
        appearance="col"
        alignX="center"
        alignY="center"
        spacing="md"
        style={util.mergeStyles(
          undefined,
          S.emptyMatchingContainer,
          [S.hoverStateEmptyMatchingContainer, overContainer === pageId],
          { width: isLargeScreen ? thumbnailSizeDimensions.lg.width : thumbnailSizeDimensions.md.width },
          { height: isLargeScreen ? thumbnailSizeDimensions.lg.height : thumbnailSizeDimensions.md.height },
        )}
      >
        <DNABox fill appearance="col" alignX="center" spacing="sm">
          <DNAText b1 bold style={S.emptyMatchingContainerText}>
            No matching slide
          </DNAText>
          <DNAText b1 style={S.emptyMatchingContainerText}>
            Drag slide here if there's a match
          </DNAText>
        </DNABox>
        <DNAButton
          onPress={() => onScrollToIndexInSlideGroupPool(index)}
          appearance="outline"
        >
          Find slide
        </DNAButton>
      </DNABox>
      <Iffy is={enableRevert}>
        <DNABox style={S.emptyOverlay} fill>
          <DNAButton
            appearance="filled"
            status="dark"
            size="md"
            iconLeft="undo"
            padding="sm"
            onPress={() => onRevert(pageId)}
            style={S.revertButton}
          />
        </DNABox>
      </Iffy>
    </>
  )
}

const SelectedMatchingSlide: React.FC<SelectedMatchingSlideProps> = ({ matchingSlide, containerId }) => {
  const {
    latestDocumentVersionContentPageData,
    latestPublishedVersionContentPageData,
  } = usePublisherVersioningState();
  const {
    currentDocumentVersionORM,
    latestPublishedDocumentVersionORM,
  } = useMatchSlidesState();
  const { groupedTargetItems } = useSingleItemDnd()
  const { onUnmatch, onRevert, canRevert } = useMatchSlidesState()
  const dispatch = useDispatch()
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1300px)' })

  const togglePreviewModal = ({ previousPageNum, newPageNum }) => {
    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: (props) => (
        <MatchSlidesPreviewModal
          newDocumentVersionORM={currentDocumentVersionORM}
          previousDocumentVersionORM={latestPublishedDocumentVersionORM}
          newPageNum={newPageNum}
          previousPageNum={previousPageNum}
          latestDocumentVersionContentPageData={latestDocumentVersionContentPageData}
          latestPublishedVersionContentPageData={latestPublishedVersionContentPageData}
          {...props}
        />
      ),
    }))
  }

  return (
    <>
      {
        matchingSlide.map(({ id, itemId }, idx) => {
          // since itemid or id could be duplicated if we add the same slide multiple times
          // we need to add a unique key to the sortable item
          // so that it can be sorted and remove correctly if whe have the same slides multiple times
          // the keys will be itemId, itemId-2, etc
          const slideLength = matchingSlide.slice(0, idx + 1).filter(i => i.itemId === itemId).length
          const key = slideLength > 1 ? `${itemId}-${slideLength}` : `${itemId}`
          const previousPageNum = Number(itemId.split('_').pop() ?? 0);
          const newPageNum = Number(groupedTargetItems[containerId][0]?.itemId.split('_').pop() ?? 0);
          const enableRevert = canRevert(containerId, itemId)

          const thumbnailTitle = getSlideTitle(
            latestPublishedDocumentVersionORM,
            newPageNum,
            latestPublishedVersionContentPageData,
          )

          return (
            <SortableItem
              key={key}
              id={id}
              itemId={itemId}
              containerId={containerId}
            >
              <DNABox appearance="col">
                <DNAThumbnail.Lazy
                  s3URL={getThumbURL(latestPublishedDocumentVersionORM, previousPageNum)}
                  useLoadingIndicator
                  size={isLargeScreen ? 'lg' : 'md'}
                  mode={DNAThumbnail.Modes.REMOVABLE_AND_PREVIEW}
                  thumbnailTitle={thumbnailTitle}
                  variant={DNAThumbnail.Variants.MATCH_SLIDE}
                  onClose={() => onUnmatch(containerId)}
                  pageNumber={previousPageNum}
                  onCheck={() => togglePreviewModal({
                    previousPageNum,
                    newPageNum,
                  })}
                  onRevert={enableRevert ? () => onRevert(containerId) : undefined}
                  style={{ margin: 0 }}
                />
              </DNABox>
            </SortableItem>
          )
        })
      }
    </>
  )
}

const PreviousVersionContainer: React.FC<PreviousVersionContainerProps> = ({ pageId, index }) => {
  const { activeId, groupedTargetItems } = useSingleItemDnd();
  const matchingSlide: TargetItem[] | undefined = groupedTargetItems[pageId]

  // Disable dropable container when it contains item and NOT during dragging.
  // So user cannot drag item within this container but only able to replace it during dnd.
  const disableDropable = !!matchingSlide?.length && !activeId

  return (
    <DroppableContainer
      id={pageId}
      items={matchingSlide}
      strategy={'verticalListSortingStrategy'}
      disabled={disableDropable}
    >
      <Iffy is={matchingSlide?.length}>
        <SelectedMatchingSlide matchingSlide={matchingSlide} containerId={pageId}/>
      </Iffy>
      <Iffy is={!matchingSlide?.length}>
        <EmptySlideContainer pageId={pageId} index={index} />
      </Iffy>
    </DroppableContainer>
  )
}

export default PreviousVersionContainer
