import React, { useEffect, useState } from 'react';
import { DNABox, DNAButton, DNAText, Iffy } from '@alucio/lux-ui'
import { IFieldComponentProps } from '../../ComposableField';
import {
  CRMAccountBadge,
  CRMAttendeeAddress,
  PartialNewAttendee,
  SearchEnterContactInput,
  styles,
} from '../../AttendeeInput';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { Singleton as IndexDbCrm } from 'src/classes/CRM/CRMIndexedDB';
import { CRMAccount } from 'src/classes/CRM/CRMIndexedDBTypes';
import { CRMAccountORM } from 'src/types/orms';
import { toORMAccount } from 'src/screens/Profile/CRMIntegration';
import { CRMSyncStatus } from 'src/state/machines/CRM/crmMachineTypes';

const CRMAccountSearcher: React.FC<IFieldComponentProps<string | undefined>> = (props) => {
  const { isReadOnly, onChange, value } = props;

  const onSelectedAccount = (crmAccount: PartialNewAttendee): void => {
    if (crmAccount.crmAccountId) {
      onChange(crmAccount.crmAccountId);
    }
  };

  const onDeleteAccount = (): void => {
    onChange(undefined);
  };

  return (
    <DNABox appearance="col" spacing="md">
      { /* SEARCH INPUT */ }
      <Iffy is={!value && !isReadOnly}>
        <SearchEnterContactInput
          isPrimary={true}
          onNewAttendee={onSelectedAccount}
          placeholder="Search accounts"
        />
      </Iffy>
      { /* NO ACCOUNT SELECTED | READ ONLY MODE */ }
      <Iffy is={!value && isReadOnly}>
        <DNAText status="flat">No account selected</DNAText>
      </Iffy>
      { /* ACCOUNT */ }
      {value &&
        <CRMAccountRow
          crmAccountId={value}
          onDelete={onDeleteAccount}
          isReadOnly={!!isReadOnly}
        />
      }
    </DNABox>
  );
};

interface CRMAccountRow {
  crmAccountId: string
  isReadOnly: boolean
  onDelete: () => void
}

const CRMAccountRow = (props: CRMAccountRow) => {
  const { crmAccountId, isReadOnly, onDelete } = props;
  const [account, setAccount] = useState<CRMAccountORM | undefined>();
  const accountId = account?.model.id;

  // GETS THE ACCOUNT ON INDEXDB TO DISPLAY ITS NAME //
  useEffect(() => {
    if (crmAccountId) {
      async function getAccountInIndexDB(): Promise<void> {
        try {
          const account = await IndexDbCrm.getById<CRMAccount>('ACCOUNT', crmAccountId);
          if (account) {
            const [accountORM] = toORMAccount([account], CRMSyncStatus.SYNCED);
            accountORM && setAccount(accountORM);
          }
        } catch (e) {
          console.error(e);
        }
      }
      getAccountInIndexDB();
    }
  }, [crmAccountId]);

  return (
    <DNABox style={!isReadOnly && [{ ...styles.rowsWrapper, borderColor: colors['color-gray-80'] }]}>
      <DNABox style={!isReadOnly && styles.attendeeRow} spacing="between" alignY="center" fill>
        <DNABox alignY="center" spacing="sm" appearance="col" fill>
          <DNAText bold>
            {account?.meta.formattedName || 'Account not found.'}
          </DNAText>
          <Iffy is={accountId}>
            <CRMAttendeeAddress accountId={accountId!} />
          </Iffy>
        </DNABox>
        <DNABox spacing="sm" alignY="center">
          <Iffy is={accountId}>
            <CRMAccountBadge crmAccountId={accountId!} />
          </Iffy>
          <Iffy is={!isReadOnly}>
            <DNAButton
              onPress={onDelete}
              appearance="ghost"
              status="gray"
              iconLeft="trash-can-outline"
              size="md"
              padding="xs"
            />
          </Iffy>
        </DNABox>
      </DNABox>
    </DNABox>
  );
};

export default CRMAccountSearcher;
