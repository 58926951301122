import React from 'react';
import {
  DNABox,
  InformationMessage,
} from '@alucio/lux-ui';
import { FileType } from '@alucio/aws-beacon-amplify/src/models';
import { useAppSettings } from 'src/state/context/AppSettings';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail';
import { usePublisherVersioningState }
  from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import { goToHTMLTroubleshootingPage } from 'src/router/routes';

export const ERROR_MESSAGES = {
  1: {
    status: 'Unknown',
    message: 'Invalid file. Please delete draft and upload new file.',
  },
  2: {
    status: 'FileNotFound',
    message: 'Unexpected error. Please try again or contact Alucio Support at support@alucio.io',
  },
  3: {
    status: 'IndexFileNotFound',
    message: 'Please ensure your file contains index.html in the main folder.',
  },
  4: {
    status: 'MultipleIndexFiles',
    message: 'Please ensure there is only one index.html file.',
  },
  5: {
    status: 'ZipFileCorrupted',
    message: 'Document is corrupted, please check and re-upload.',
  },
}

const VersioningCriticalError = () => {
  const { documentORM } = usePublisherVersioningState()
  const { isOnline }  = useAppSettings()
  const enableZendeskKnowledgebase = useFeatureFlags('enableZendeskKnowledgebase')
  const docVersion = documentORM.relations.version.latestDocumentVersionORM
  const isHTMLDoc = docVersion.model.type === FileType.HTML
  const showHTMLTroubleshootingLink = isOnline && isHTMLDoc && enableZendeskKnowledgebase

  const errorCode = isHTMLDoc && docVersion.model.conversionErrorCode
    ? parseInt(docVersion.model.conversionErrorCode, 10)
    : 1

  return (
    <DNABox
      fill
      appearance="col"
      style={{ marginTop: 40, marginHorizontal: 40 }}
      spacing="md"
    >
      <InformationMessage
        text={ERROR_MESSAGES[errorCode].message}
        variance="danger"
        actionButtonFunction={goToHTMLTroubleshootingPage}
        actionButtonText={showHTMLTroubleshootingLink ? 'See troubleshooting tips' : undefined}
      />
      <DNABox style={{ maxWidth: 159, maxHeight: 91 }}>
        <DNADocumentThumbnail
          documentVersionORM={docVersion}
          width={159}
          height={91}
          showProcessing={true}
        />
      </DNABox>
    </DNABox>
  )
}

export default VersioningCriticalError
