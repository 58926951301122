import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import { DNASelect } from '@alucio/lux-ui'
import { SelectGroup } from '@alucio/lux-ui/src/components/controls/Select/Select';
import { PRESENTED_SLIDES, useMeetingPresentedMeta } from './State/MeetingPresentedMetaProvider';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { useContentPreviewModal } from './State/ContentPreviewModalStateProvider';
import { isPageGroupORM } from 'src/types/typeguards';
import { RootState } from 'src/state/redux';

interface Props {
  docHasPageGroups: boolean,
}
const PresentedSlideSelector = (props: Props) => {
  const { docHasPageGroups } = props
  const { setPresentedSlides, presentedSlides } = useMeetingPresentedMeta();
  const {
    activeDocumentVersionORM,
  } = useContentPreviewModal()
  const { addPresentation, activePresentation } = useContent()
  const modalState = useSelector((state: RootState) => state.contentPreviewModal);
  const displayGroupsOptions = docHasPageGroups && !isPageGroupORM(modalState.content);

  const groups = useMemo(() =>
    [...(activeDocumentVersionORM?.relations.pageGroups || [])]
      .sort((a, b) =>
        a.model.name.localeCompare(b.model.name)), [activeDocumentVersionORM]);

  const onPresentedSlidesSelected = (e): void => {
    if (e.section === 0) {
      setPresentedSlides(Object.values(PRESENTED_SLIDES)[e.row])
    } else if (docHasPageGroups && e.section === 1) {
      const group = groups[e.row]
      addPresentation(group)
      setPresentedSlides(undefined)
      // ANALYTIC TRACKING: group shown in the content preview modal
      analytics?.track('CONTENT_PREVIEW', {
        pageGroupId: group.model.id,
      })
    } else throw Error('unknown group selected')
  };

  const selectedGroupName = (isPageGroupORM(activePresentation?.presentable.orm) &&
  activePresentation?.presentable.orm.model.name) || '';

  return (
    <DNASelect
      style={{ width: 170 }}
      onSelect={onPresentedSlidesSelected}
      testID="meeting-presented-slides-selector"
      value={presentedSlides || selectedGroupName}
    >
      <SelectGroup title="SLIDES">
        {
          Object.keys(PRESENTED_SLIDES).map((presented) => (
            <DNASelect.Item
              testID={`meeting-${presented}-slides-drop-down-list-item`}
              key={PRESENTED_SLIDES[presented]}
              title={PRESENTED_SLIDES[presented]}
            />
          ))
        }
      </SelectGroup>
      {
        displayGroupsOptions ? (
          <SelectGroup title="GROUPS">
            {groups?.map(group => {
              return (
                <DNASelect.Item
                  key={group.model.id}
                  title={group.model.name}
                />
              )
            })}
          </SelectGroup>
        ) : <></>
      }
    </DNASelect>
  )
}

export default PresentedSlideSelector
