import { createMachine } from 'xstate';
import * as MatchSlides from './matchSlides.types';
import { assign } from '@xstate/immer';

export const matchSlidesSM = createMachine(
  {
    id: 'MatchSlidesSM',
    tsTypes: {} as import('./matchSlides.machine.typegen').Typegen0,
    predictableActionArguments: true,
    preserveActionOrder: true,
    schema: {
      context: {} as MatchSlides.SMContext,
      events: {} as MatchSlides.SMEvents,
      services: {} as MatchSlides.SMServices,
    },
    context: {
      slidesGroupPoolVisible: false,
    },
    initial: 'idle',
    states: {
      idle: {
        description: 'Waiting for user action',
        on: {
          SET_SLIDES_POOL_VISIBLE: {
            actions: 'setSlidesPoolVisible',
          },
        },
      },
    },
  },
  {
    guards: {},
    actions: {
      setSlidesPoolVisible: assign((ctx, evt) => {
        ctx.slidesGroupPoolVisible = evt.payload
      }),
    },
    services: {},
  },
)

export default matchSlidesSM
