export enum Variant {
    MSL = 'MSL',
    publisher = 'publisher',
    versioning = 'versioning',
    bookmark = 'bookmark',
}

// TODO: add video file types here as we add supports to them
export enum VIDEO_TYPES_ENUM {
    MP4 = 'MP4'
}
