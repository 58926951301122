import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { NOTES } from 'src/screens/Meetings/SharedComponents/Notes/AllNotes';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';

export interface MeetingComponentsVisibility {
  notesPanelVisible: boolean,
  openNotes: NOTES[],
  presentationControlsVisible: boolean,
  setNotesPanelVisible: Dispatch<SetStateAction<boolean>>,
  setOpenNotes: Dispatch<SetStateAction<NOTES[]>>,
  setPresentationControlsVisible: Dispatch<SetStateAction<boolean>>,
  setSlideRollVisible: Dispatch<SetStateAction<boolean>>,
  setTextSearchVisible: Dispatch<SetStateAction<boolean>>,
  slideRollVisible: boolean,
  toggleAllNotes: () => void,
  toggleOffComponents: () => void,
  togglePresentationControlsVisibility: () => void,
  textSearchVisible: boolean,
  toggleTextSearchVisibility: () => void,
  toggleSlideRollVisibility: () => void,
  toggleSpeakerNotes: () => void,
  toggleCustomNotes: () => void,
  toggleMeetingNotes: () => void,
}

const useMeetingsComponentVisibility = (): MeetingComponentsVisibility => {
  const [presentationControlsVisible, setPresentationControlsVisible] = useState<boolean>(false)
  const [slideRollVisible, setSlideRollVisible] = useState<boolean>(false)
  const [textSearchVisible, setTextSearchVisible] = useState<boolean>(false)
  const [notesPanelVisible, setNotesPanelVisible] = useState<boolean>(false);
  const featureFlags = useFeatureFlags('enableCustomNotes', 'enableMeetingNotes')
  const [openNotes, setOpenNotes] = useState<NOTES[]>([]);

  const togglePresentationControlsVisibility = () => {
    setPresentationControlsVisible(p => !p)
  }

  const toggleSlideRollVisibility = () => {
    setTextSearchVisible(false)
    setSlideRollVisible(p => !p)
  }

  const toggleTextSearchVisibility = () => {
    setSlideRollVisible(false)
    setTextSearchVisible(p => !p)
  }

  const toggleMeetingNotes = (): void => {
    setOpenNotes((notes) =>
      notes.includes(NOTES.MEETING_NOTES)
        ? notes.filter((note) => note !== NOTES.MEETING_NOTES)
        : [...notes, NOTES.MEETING_NOTES])
  }

  const toggleCustomNotes = (): void => {
    setOpenNotes((notes) =>
      notes.includes(NOTES.CUSTOM_NOTES)
        ? notes.filter((note) => note !== NOTES.CUSTOM_NOTES)
        : [...notes, NOTES.CUSTOM_NOTES])
  }

  const toggleSpeakerNotes = (): void => {
    setOpenNotes((notes) =>
      notes.includes(NOTES.SPEAKER_NOTES)
        ? notes.filter((note) => note !== NOTES.SPEAKER_NOTES)
        : [...notes, NOTES.SPEAKER_NOTES])
  }

  const toggleAllNotes = useCallback((): void => {
    if (!notesPanelVisible) {
      const allNotes = [NOTES.SPEAKER_NOTES]
      if (featureFlags.enableCustomNotes) allNotes.push(NOTES.CUSTOM_NOTES)
      if (featureFlags.enableMeetingNotes) allNotes.push(NOTES.MEETING_NOTES)
      setOpenNotes(allNotes)
    }
    setNotesPanelVisible(state => !state)
  }, [notesPanelVisible, featureFlags.enableCustomNotes, featureFlags.enableMeetingNotes])

  const toggleOffComponents = () => {
    setSlideRollVisible(false);
    setTextSearchVisible(false);
    setPresentationControlsVisible(false);
    setNotesPanelVisible(false);
  };

  return {
    notesPanelVisible,
    openNotes,
    presentationControlsVisible,
    setNotesPanelVisible,
    setPresentationControlsVisible,
    slideRollVisible,
    setSlideRollVisible,
    setOpenNotes,
    setTextSearchVisible,
    textSearchVisible,
    toggleAllNotes,
    toggleCustomNotes,
    toggleMeetingNotes,
    togglePresentationControlsVisibility,
    toggleSlideRollVisibility,
    toggleSpeakerNotes,
    toggleOffComponents,
    toggleTextSearchVisibility,
  }
}

export default useMeetingsComponentVisibility
