import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAChip, DNADivider, DNAText, Iffy } from '@alucio/lux-ui';
import { CUSTOM_FORM_RECORD_ENTITY } from '@alucio/aws-beacon-amplify/lib/API';
import { CustomFormRecordStatus } from '@alucio/aws-beacon-amplify/src/models';
import { StandaloneForm } from 'src/state/redux/selector/crm';
import { LabelField } from '../../CustomFields/ComposableField';
import { CustomFormsSettings, useAddMeeting } from '../AddMeetingProvider';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import { S } from 'src/screens/Publishers/Versioning/VersioningPanel/Header';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { TABLES } from 'src/classes/CRM/Translators/VeevaTranslatorUtils';
import { useComposableForm } from '../../CustomFields/ComposableForm';
import { useCustomFormRecordByParentIdApiName } from 'src/state/redux/selector/customFormRecord';
import { CustomFormRecordORM } from 'src/types/orms';

const styles = StyleSheet.create({
  recordRowWrapper: {
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  recordsWrapper: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors['color-gray-80'],
  },
  recordTypeButton: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  recordTypesWrapper: {
    ...S.headerActionsWrapper,
    paddingVertical: 5,
    paddingHorizontal: 0,
  },
});

interface Props {
  customForms: StandaloneForm[];
  isReadOnly: boolean;
  setCustomFormSettings: (setting: CustomFormsSettings) => void;
  parentId: string;
}

const CustomFormComponent = {
  [CUSTOM_FORM_RECORD_ENTITY.CRM_OBJECT]: CRMFormSection,
};

const FORMS_THAT_REQUIRE_PRIMARY_ATTENDEE = [TABLES.MEDICAL_INSIGHT, TABLES.MEDICAL_INQUIRY];

const CustomFormSection = (props: Props) => {
  const FormComponent = CustomFormComponent[props.customForms[0]?.type || ''];

  if (!FormComponent) {
    return null;
  }

  return (
    <FormComponent {...props} />
  )
};

function CRMFormSection(props: Props) {
  const { customForms, isReadOnly, parentId } = props;
  const { rhForm } = useComposableForm();
  const primaryAttendee = rhForm.watch('primaryAttendee');
  const requiresPrimaryAttendee = FORMS_THAT_REQUIRE_PRIMARY_ATTENDEE
    .includes(customForms[0]?.crmFormSetting?.apiName || '');
  const [attendeeRequiredError, setAttendeeRequiredError] = useState(false);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const label = customForms[0]?.crmFormSetting?.label || '';
  const formTitle = customForms[0]?.crmFormSetting?.objectInfos.labelPlural;
  const records = useCustomFormRecordByParentIdApiName(parentId, customForms[0].apiName || customForms[0].id);
  const { removeCustomRecord } = useAddMeeting();

  useEffect(() => {
    if (primaryAttendee.length && attendeeRequiredError) {
      setAttendeeRequiredError(false);
    }
  }, [primaryAttendee]);

  const onCreateRecord = (index: number): void => {
    const canCreateRecord = !requiresPrimaryAttendee || (requiresPrimaryAttendee && primaryAttendee.length);
    if (canCreateRecord) {
      props.setCustomFormSettings({
        standaloneForm: props.customForms[index],
      });
    } else {
      setAttendeeRequiredError(true);
    }
    setOptionsVisible(false);
  };

  const onAddPressed = (): void => {
    // IF THIS IS TRUE, THERE ARE MORE THAN ONE RECORD TYPE
    if (customForms.length > 1) {
      setOptionsVisible(visible => !visible);
    } else {
      onCreateRecord(0);
    }
  };

  const onUpdateRecord = (customFormRecordId: string, standaloneFormId: string, recordTypeId?: string): void => {
    const standaloneForm = customForms.find(({ id, crmFormSetting }) => id === standaloneFormId &&
      (recordTypeId ? crmFormSetting?.recordTypeId === recordTypeId : true));

    if (standaloneForm) {
      props.setCustomFormSettings({
        standaloneForm,
        customFormRecordId,
      })
    }
  };

  const onDeleteRecord = (customFormRecordId: string): void => {
    removeCustomRecord(customFormRecordId, label);
  };

  return (
    <DNABox spacing="md" appearance="col" fill>
      <LabelField label={formTitle || label} />
      {/* ADD RECORD BUTTON */}
      <DNAPopover
        lazyMount
        placement="bottom-start"
        interactive={true}
        visible={optionsVisible}
        onBackdropPress={onAddPressed}
        type="menu"
      >
        <DNAPopover.Anchor>
          <Iffy is={!isReadOnly}>
            <DNAButton
              appearance="outline"
              status="tertiary"
              iconLeft="plus"
              onPress={onAddPressed}
            >
              Add {label.toLowerCase()}
            </DNAButton>
          </Iffy>
        </DNAPopover.Anchor>
        {/* RECORD TYPES OPTIONS */}
        <DNAPopover.Content>
          <DNABox style={styles.recordTypesWrapper} spacing="sm" appearance="col">
            {
            customForms.map((formSetting, idx) => (
              <DNAButton
                style={styles.recordTypeButton}
                appearance="ghost"
                status="tertiary"
                onPress={() => onCreateRecord(idx)}
              >
                <DNAText>
                  {formSetting.crmFormSetting?.recordTypeLabel}
                </DNAText>
              </DNAButton>
            ))
          }
          </DNABox>
        </DNAPopover.Content>
      </DNAPopover>
      {/* ERROR */}
      <Iffy is={attendeeRequiredError}>
        <DNAText status="danger">Please add a primary attendee before creating a {label}.</DNAText>
      </Iffy>
      {/* NO RECORDS MESSAGE */}
      <Iffy is={!records.length && isReadOnly}>
        <DNABox appearance="col" style={{ ...styles.recordsWrapper, ...styles.recordRowWrapper }}>
          <DNAText status="flatAlt">
            No record was added
          </DNAText>
        </DNABox>
      </Iffy>
      {/* RECORDS LIST */}
      <Iffy is={records.length}>
        <DNABox appearance="col" style={styles.recordsWrapper}>
          {
            records.map((record, idx) =>
              (<DNABox key={record.model.id} appearance="col">
                <CustomFormRecordRow
                  customFormRecordORM={record}
                  isReadOnly={isReadOnly}
                  onUpdateRecord={onUpdateRecord}
                  onDeleteRecord={onDeleteRecord}
                />
                <Iffy is={idx !== records.length - 1}>
                  <DNADivider />
                </Iffy>
              </DNABox>),
            )
          }
        </DNABox>
      </Iffy>
    </DNABox>
  );
}

interface CustomFormRecordRowProps {
  customFormRecordORM: CustomFormRecordORM,
  isReadOnly: boolean,
  onUpdateRecord: (recordId: string, formId: string, recordTypeId?: string) => void,
  onDeleteRecord: (recordId: string) => void,
}

function CustomFormRecordRow(props: CustomFormRecordRowProps) {
  const { customFormRecordORM, isReadOnly, onUpdateRecord, onDeleteRecord } = props;
  const isRecordLocked = customFormRecordORM.model.status === CustomFormRecordStatus.LOCKED;

  const onDelete = (): void => {
    onDeleteRecord(customFormRecordORM.model.id);
  };

  const onUpdate = (): void => {
    onUpdateRecord(customFormRecordORM.model.id,
      customFormRecordORM.model.customFormId,
      customFormRecordORM.model.crmFields?.recordTypeId);
  };

  return (
    <DNABox fill spacing="between" style={styles.recordRowWrapper} alignY="center">
      <DNABox alignY="center" appearance="col" spacing="xs">
        { /* NAME */ }
        <DNAText bold>{customFormRecordORM.model.name}</DNAText>
        <DNAText status="subtle">
          Created {new Date(customFormRecordORM.model.createdAt).toLocaleString()}
        </DNAText>
      </DNABox>
      <Iffy is={!isReadOnly && !isRecordLocked}>
        <DNABox alignY="center" spacing="sm">
          { /* UPDATE ICON */ }
          <DNAButton
            onPress={onUpdate}
            appearance="ghost"
            status="gray"
            iconLeft="pencil"
            size="md"
            padding="xs"
          />
          { /* DELETE ICON */ }
          <DNAButton
            onPress={onDelete}
            appearance="ghost"
            status="gray"
            iconLeft="trash-can-outline"
            size="md"
            padding="xs"
          />
        </DNABox>
      </Iffy>
      <Iffy is={isRecordLocked}>
        { /* SUBMITTED BADGE */ }
        <DNAChip status="success">
          SUBMITTED
        </DNAChip>
      </Iffy>
    </DNABox>
  );
}

export default CustomFormSection;
