import { useForm, UseFormReturn } from 'react-hook-form'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import cloneDeep from 'lodash/cloneDeep';
import { useHub } from 'src/state/redux/selector/hub';
import {
  HubSection,
  HubSectionType,
  HubSectionItemStatus,
  HubSharedFileContentType,
  TodoStatus,
  HubSectionStatus,
  NotationType,
} from '@alucio/aws-beacon-amplify/src/models';
import { HubORM } from 'src/types/orms';
import { URL_REGEX } from '@alucio/core';

export enum WidgetFormNames {
  textareaWidget = 'textareaWidget',
  sharedFilesWidget = 'sharedFilesWidget',
  todoListWidget = 'todoListWidget',
  linksWidget = 'linksWidget',
}

// [TODO-DEBT] - Consider using https://github.com/fabien0102/ts-to-zod
//             - May automatically do the conversion from TS to Zod Schema for us
//               Since Zod only does Zod -> TS

const widgetTypeFormSchema = z.union([
  z.nativeEnum(HubSectionType),
  z.literal('TEXTAREA'),
  z.literal('SHARED_FILES'),
  z.literal('TODO_LIST'),
  z.literal('LINKS')
])

const boilerplate = {
  id: z.string(),
  sectionHeader: z.string()
    .min(1, { message: 'This field is required ' })
    .max(150),
  status: z.union([
    z.nativeEnum(HubSectionStatus),
    z.literal('ACTIVE'),
    z.literal('DELETED'),
  ]),
  type: widgetTypeFormSchema,
  visible: z.boolean(),
  order: z.number().nonnegative(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
}

const textareaFormSchema = {
  content: z.string().optional(),
}

const notationFormSchema = {
  id: z.string(),
  type: z.union([
    z.nativeEnum(NotationType),
    z.literal('CALLOUT'),
    z.literal('PAGE_NOTE'),
  ]),
  description: z.string().optional(),
  status: z.union([
    z.nativeEnum(HubSectionItemStatus),
    z.literal('ACTIVE'),
    z.literal('DELETED'),
  ]),
  pageId: z.string(),
  coordinate: z.array(z.object({
    x: z.number(),
    y: z.number(),
  })).optional(),
  createdAt: z.string().datetime(),
  createdBy: z.string(),
  updatedAt: z.string().datetime(),
  updatedBy: z.string(),
}

const sharedFilesFormSchema = {
  id: z.string(),
  contentId: z.string(),
  title: z.string(),
  contentType: z.union([
    z.nativeEnum(HubSharedFileContentType),
    z.literal('DOCUMENT_VERSION'),
  ]),
  documentVersionSettings: z.object({
    associatedFiles: z.array(z.object({
      associatedFileId: z.string(),
      versionId: z.string().optional(),
      notation: z.array(z.object({ ...notationFormSchema })).optional(),
    })).optional()
  }).optional(),
  status: z.union([
    z.nativeEnum(HubSectionItemStatus),
    z.literal('ACTIVE'),
    z.literal('DELETED'),
  ]),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  notation: z.array(z.object({ ...notationFormSchema })).optional(),
}

const toDosFormSchema = {
  id: z.string(),
  title: z
    .string()
    .min(1, { message: 'This field is required ' })
    .max(150),
  resolution: z
    .string()
    .max(500)
    .optional(),
  status: z.union([
    z.nativeEnum(TodoStatus),
    z.literal('ACTIVE'),
    z.literal('COMPLETED'),
    z.literal('DELETED'),
  ]),
  order: z.number().nonnegative(),
  completedAt: z.string().datetime().optional(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
}

const linksFormSchema = {
  id: z.string(),
  url: z
    .string()
    .min(1, { message: 'This field is required ' })
    .refine((value) => {
      const url = value.startsWith('http://') || value.startsWith('https://') ? value : 'https://' + value;
      return URL_REGEX.test(url);
    }
    , { message: 'Invalid URL' }),
  title: z
    .string()
    .max(150)
    .optional(),
  status: z.union([
    z.nativeEnum(HubSectionItemStatus),
    z.literal('ACTIVE'),
    z.literal('DELETED'),
  ]),
  order: z.number().nonnegative(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
}

const formSchema = z.object({
  name: z
    .string()
    .min(1, { message: 'This field is required' }),
  textareaWidget: z
    .object({
      ...boilerplate,
      sectionHeader: z.string().max(150),
      textarea: z.object({ ...textareaFormSchema }),
    })
    .optional(),
  sharedFilesWidget: z
    .object({
      ...boilerplate,
      sharedFiles: z.array(z.object({ ...sharedFilesFormSchema })).optional()
    })
    .optional(),
  todoListWidget: z
    .object({
      ...boilerplate,
      toDos: z.array(z.object({ ...toDosFormSchema })).optional()
    })
    .optional(),
  linksWidget: z
    .object({
      ...boilerplate,
      links: z.array(z.object({ ...linksFormSchema })).optional()
    })
    .optional(),
})

interface useHubFormStateType {
  hubId: string
}

type HubFormValue = {
  name: string,
  textareaWidget?: HubSection,
  sharedFilesWidget?: HubSection,
  todoListWidget?: HubSection,
  linksWidget?: HubSection
}

export type EditHubRHForm = UseFormReturn<HubFormValue>

/**
 * Check to see if the widget is active and the type matched the type passed in the second argument
 */
function isActiveWidgetType (widget: HubSection, type: HubSectionType):boolean {
  return widget.type === type && widget.status === HubSectionStatus.ACTIVE
}

export function getDefaultHubFormValue (hubORM?: HubORM): HubFormValue {
  const textareaWidget = hubORM?.model.hubSections
    ?.find(widget => isActiveWidgetType(widget, HubSectionType.TEXTAREA))
  const sharedFilesWidget = hubORM?.model.hubSections
    ?.find(widget => isActiveWidgetType(widget, HubSectionType.SHARED_FILES))
  const sharedFiles = sharedFilesWidget?.sharedFiles || undefined
  // typescript work around - convert all value into undefined 
  const modifiedSharedFiles = sharedFiles?.map(sharedFile => {
    const associatedFiles = sharedFile.documentVersionSettings?.associatedFiles?.map(associatedFile => {
      const notation = associatedFile.notation?.map(notation => {
        return {
          ...notation,
          description: notation.description || undefined,
        }
      })
      return {
        ...associatedFile,
        versionId: associatedFile.versionId || undefined,
        notation,
      }
    })
    const notation = sharedFile.notation?.map(notation => {
      return {
        ...notation,
        description: notation.description || undefined,
      }
    }) || undefined
    return {
      ...sharedFile,
      documentVersionSettings: associatedFiles ? {associatedFiles} : undefined,
      notation,
    }
  })
  const todoListWidget = hubORM?.model.hubSections
    ?.find(widget => isActiveWidgetType(widget, HubSectionType.TODO_LIST))
  const toDos = todoListWidget?.toDos || undefined
  const modifiedtoDos = toDos?.map(toDo => ({...toDo, completedAt: toDo.completedAt || undefined}))
  const linksWidget = hubORM?.model.hubSections
    ?.find(widget => isActiveWidgetType(widget, HubSectionType.LINKS))
  
  const defaultHubFormValue = {
    name: hubORM?.model.name || '',
    textareaWidget: textareaWidget,
    sharedFilesWidget: sharedFilesWidget ? { ...sharedFilesWidget, sharedFiles: modifiedSharedFiles } : undefined,
    todoListWidget: todoListWidget ? { ...todoListWidget, toDos: modifiedtoDos } : undefined,
    linksWidget: linksWidget ? {...linksWidget, links: linksWidget.links || [] } : undefined,
  }

  return cloneDeep(defaultHubFormValue)
}

const useHubFormState = (props: useHubFormStateType): EditHubRHForm => {
  const { hubId } = props
  const hubORM = useHub(hubId)
  const defaultValues = getDefaultHubFormValue(hubORM)

  const rhForm = useForm({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues,
  })

  return rhForm
}

export default useHubFormState
