import React, { createContext, useContext, useRef, useState } from 'react';

import { useForm, UseFormReturn } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { CustomFieldDefinition, CustomFieldValueDefinition, FieldDataType } from '@alucio/aws-beacon-amplify/src/models'
import { CustomFieldValuesMap, CustomObjectFieldValue, DocumentVersionORM, MeetingORM } from 'src/types/orms'
import { StyleSheet, TextStyle, ViewStyle } from 'react-native';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { fieldToSchema, getDefaultValues, ObjectWithId } from './ComposableFormUtilities';
import { RefinementCtx, ZodEffects, ZodIssueCode, ZodObject } from 'zod';
import { isArrayOfObjectsWithIds } from 'src/types/typeguards';

// [TODO-2780] - See recent UI userland variant implementation here
//             - https://github.com/alucioinc/eeb/pull/1716/files#diff-7c1f6f492ffa23363932f2f4b72347f5dc644ea691b14c5272eacd653bad0559R10-R20
export enum ComposableVariant{
  DEFAULT = 'DEFAULT',
  VIRTUAL = 'VIRTUAL',
}

type ComposableComponentStyles =
  | 'title'
  | 'defaultCharCount'
  | 'required'
  | 'contentContainer'
  | 'inputLabel'
  | 'helpToolTipIconStyle'
  | 'description'

export type ComposableStyles = Record<ComposableComponentStyles, ViewStyle | TextStyle>;

export type RHForm = UseFormReturn<FormValuesType>

const defaultComposableStyles: ComposableStyles = {
  title: {
    color: colors['color-gray-800'],
    fontWeight: 'bold',
    marginBottom: 4,
    marginTop: 4,
    textTransform: 'uppercase',
    fontSize:12,
  },
  description: {
    color: colors['color-gray-800'],
    fontSize:12,
  },
  defaultCharCount: {
    color: colors['color-gray-800'],
  },
  required: {
    color: colors['color-danger-500'],
    paddingLeft: 5,
    paddingRight: 5,
  },
  contentContainer: {
    paddingRight: 24,
  },
  inputLabel: {
    color: colors['color-gray-800'],
    fontSize: 13,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  helpToolTipIconStyle: {
    // [NOTE]: this color is not present  on the theme file
    color: colors['color-gray-200'],
    height: 16,
    paddingLeft: 5,
    width: 16,
  },
}

const virtualComposableStyles: ComposableStyles = {
  ...defaultComposableStyles,
  title: {
    color: colors['color-gray-300'],
    fontWeight: 'bold',
    marginBottom: 4,
    marginTop: 4,
    textTransform: 'uppercase',
    fontSize:12,
  },
  description: {
    color: colors['color-gray-300'],
    fontSize:12,
  },
  defaultCharCount: {
    color: colors['color-gray-300'],
  },
}

export const composableVariantStyles: Record<ComposableVariant, ComposableStyles> = {
  [ComposableVariant.DEFAULT]: StyleSheet.create(defaultComposableStyles),
  [ComposableVariant.VIRTUAL]: StyleSheet.create(virtualComposableStyles),
}

// [TODO-2780] - Maybe separate this out, don't need to overload a single type
//               Since the Custom Fields do not use this pattern either (separates fields and customValuesMap)
export type StaticFields = {
  fields: CustomFieldDefinition[],
  // [TODO-2780] - Allow usage of overrideSchema in `fieldToSchema`
  overrideSchema?: {
    // [TODO] - Not available in our current version of Zod? Add future support later
    superRefine?: Array<(val, ctx) => void>,
    refine?: Array<{
      // [TODO-2780] - Proper Type this
      func: (func: any) => any,
      conf: {},
    }>
  },
  // [TODO-2780] - Implement overrideFormat (reverse of overrideSchema)
}

type UpdateCustomFieldValues = (
  customValues: CustomFieldValueDefinition[],
  filedId: string
) => void;

/** --------------
 *  REACT CONTEXT
 ** -------------- */
interface IComposableFormContext {
  referenceObject?: MeetingORM | DocumentVersionORM,
  rhForm: UseFormReturn<FormValuesType>,
  values: CustomFieldValuesMap,
  resetWithLatestDefaultValues: () => void,
  resetForm: (...args: Parameters<typeof getDefaultValues>) => void,
  disabled?: boolean,
  isReadOnly?: boolean,
  getChildFields: (fieldId: string) => CustomFieldDefinition[],
  customFields: CustomFieldDefinition[],
  updateCustomFieldValues: UpdateCustomFieldValues,
}

const ComposableFormContext = createContext<IComposableFormContext>({
  rhForm: null!,
  values: {},
  resetWithLatestDefaultValues: () => {},
  resetForm: () => {},
  getChildFields: () => [],
  isReadOnly: false,
  customFields: [],
  updateCustomFieldValues: () => {},
})

export const useComposableForm = () => useContext(ComposableFormContext)

export interface FormValuesType {
  [x: string]: string | string[] | ObjectWithId[],
}

function useComposableFormSetup(
  staticFields: StaticFields,
  customFields: CustomFieldDefinition[],
  values: CustomFieldValuesMap,
  staticValues: CustomFieldValuesMap,
) {
  const staticSchema = useRef(fieldToSchema(staticFields.fields)).current
  const customSchema = useRef(fieldToSchema(customFields)).current
  const mergedSchema = useRef(staticSchema.merge(customSchema)).current
  const staticRefinements = staticFields.overrideSchema?.refine

  // REPRESENTS AN ARRAY OF SUPERREFINEMENTS PER DEPENDENT FIELD TO VALIDATE THEM WITHIN THE FORM
  const dependentFieldsSuperRefinements = customFields
    .filter((field) => field.dependentFieldId && field.required)
    .map((field) => {
      return (data, ctx) => {
        const parentCF = customFields.find((cf) => cf.id === field.dependentFieldId);

        if (parentCF) {
          if (field.isChildField) {
            areObjectsWithRequiredChildFieldValid(data, field, parentCF, ctx);
          } else {
            const isValid = !isObjectFieldDependencyInvalid(data, field, parentCF.id);
            if (!isValid) {
              ctx?.addIssue({
                code: ZodIssueCode.custom,
                message: 'This field is required.',
                path: [field.id],
              });
            }
          }
        }
      }
    });

  function addRefines(schema: ZodObject<any>, refinements, superRefinements): ZodEffects<any> | ZodObject<any> {
    let updatedSchema = schema;
    if (refinements?.length) {
      updatedSchema = refinements.reduce((acc, { func, conf }) =>
        acc.refine(func, conf), schema);
    }

    if (superRefinements?.length) {
      updatedSchema = superRefinements.reduce((acc, superRefinement) =>
        acc.superRefine(superRefinement), updatedSchema);
    }

    return updatedSchema;
  }

  function areObjectsWithRequiredChildFieldValid(
    formData: FormValuesType,
    childField: CustomFieldDefinition,
    parentCustomField: CustomFieldDefinition,
    ctx: RefinementCtx): void {
    const parentObjectField =
      customFields?.find(({ objectSetting }) => objectSetting?.childrenFieldIds.includes(childField.id));
    const fieldArray = formData[parentObjectField?.id || ''];

    // ITERATE OVER THE CHILD OBJECTS TO VERIFY THE DEPENDENCY FIELD CONDITION
    if (parentObjectField && Array.isArray(fieldArray) && fieldArray.length && isArrayOfObjectsWithIds(fieldArray)) {
      // CHECK THE OBJECTS
      fieldArray.forEach((object, idx) => {
        const isValid = !isObjectFieldDependencyInvalid(object, childField, parentCustomField.id);
        if (!isValid) {
          ctx.addIssue({
            code: ZodIssueCode.custom,
            message: 'This field is required.',
            path: [parentObjectField.id, idx, childField.id],
          });
        }
      });
    }
  }

  function isObjectFieldDependencyInvalid(
    object: ObjectWithId | FormValuesType,
    field: CustomFieldDefinition,
    parentFieldId: string): boolean {
    const parentValue = Array.isArray(object[parentFieldId]) ? object[parentFieldId] : [object[parentFieldId]];

    if (!object[parentFieldId] || !Array.isArray(parentValue)) {
      return false;
    }

    const hasAvailableFields = field.fieldValueDefinitions.some((fieldValueDefinition) =>
      parentValue.some((value) => fieldValueDefinition.dependentValueIds?.includes(value)));
    const hasValue = Array.isArray(object[field.id]) ? object[field.id]?.length : !!object[field.id];

    return hasAvailableFields && !hasValue;
  }

  const defaultValues = getDefaultValues({
    ...values,
    ...staticValues, // [NOTE] - This could also be passed in a separate prop (since we do it for customFields values)
  })

  const rhForm = useForm<FormValuesType>({
    resolver: zodResolver(addRefines(
      mergedSchema,
      staticRefinements,
      dependentFieldsSuperRefinements)),
    mode: 'onChange',
    defaultValues: { ...defaultValues },
  // [NOTE] - Find why the schemas generates a different structure
  })

  return { defaultValues, rhForm, customFields };
}

interface ComposableFormProps {
  referenceObject?: MeetingORM | DocumentVersionORM,
  staticFields?: StaticFields,
  customFields: CustomFieldDefinition[],
  values?: CustomFieldValuesMap,
  staticValues?: CustomFieldValuesMap,
  disabled?: boolean,
  isReadOnly?: boolean,
  children?: JSX.Element|JSX.Element[],
  objectValues?: CustomObjectFieldValue[],
}

export function ComposableForm(props: ComposableFormProps) {
  const {
    staticFields = { fields: [] },
    values = {},
    staticValues = {},
    disabled,
    isReadOnly,
    referenceObject,
  } = props

  const [customFields, setCustomFields] = useState<CustomFieldDefinition[]>(props.customFields)

  const { defaultValues, rhForm } = useComposableFormSetup(
    staticFields,
    customFields,
    values,
    staticValues,
  )

  const resetForm = (...args: Parameters<typeof getDefaultValues>) => rhForm.reset(getDefaultValues(...args))
  const resetWithLatestDefaultValues = () => rhForm.reset(defaultValues);

  const getChildFields = (fieldId: string) : CustomFieldDefinition[] => {
    const field = customFields.find((f) => f.id === fieldId)
    if (!field || !field.objectSetting) {
      return []
    }
    return customFields.filter((f) => field.objectSetting?.childrenFieldIds?.includes(f.id) &&
    f.fieldType !== FieldDataType.OBJECT)
      .sort((a, b) => (a.order || 0) - (b?.order || 0))
  }

  const updateField = (field: CustomFieldDefinition, customValues: CustomFieldValueDefinition[]) => {
    return {
      ...field,
      fieldValueDefinitions: customValues,
      createdAt: new Date().toISOString(),
    };
  };

  const updateCustomFieldValues = (
    customValues: CustomFieldValueDefinition[],
    fieldId: string,
  ) => {
    const newCustomFields = customFields.map<CustomFieldDefinition>((field) => {
      if (field.id === fieldId) {
        return updateField({ ...field, required: true }, customValues);
      }
      return field;
    });

    setCustomFields(newCustomFields);
  };

  return (
    <ComposableFormContext.Provider
      value={{
        referenceObject,
        rhForm,
        values,
        resetWithLatestDefaultValues,
        resetForm,
        disabled,
        getChildFields,
        isReadOnly,
        customFields,
        updateCustomFieldValues,
      }}
    >
      {props.children}
    </ComposableFormContext.Provider>
  )
}
