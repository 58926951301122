
import { DNABox, DNAButton, DNAText, Iffy } from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { SidebarOptions, useContentViewerModalState } from '../../state/ContentViewerModalStateProvider'
import { tabConfigs } from '../SideBar'

interface ControlsProps {
  isUserDocument: boolean;
}

const Controls: React.FC<ControlsProps> = ({ isUserDocument }) => {
  const {
    visibleSidebar,
    setVisibleSidebar,
    getSidebarOptions,
    setVisibleSearch,
  } = useContentViewerModalState()
  const containerStyle: StyleProp<ViewStyle> = visibleSidebar
    ? {
      borderLeftWidth: 1,
      borderStyle: 'solid',
      borderColor: '#EBECF0',
    }
    : {}

  const buttons = getSidebarOptions()
    .map((optionId, idx) => {
      const config = tabConfigs.find((tab) => tab.id === optionId)
      if (isUserDocument && optionId !== SidebarOptions.notesSidebar) {
        return null;
      }
      if (!config)
      { throw Error(`Unknown tab ${optionId}`) }

      const buttonStyle: StyleProp<ViewStyle> = idx === 0
        ? { margin: 16, marginBottom: 12 }
        : { marginRight: 16, marginBottom: 12 }
      return (
        <DNAPopover key={config.id} placement="left">
          <DNAPopover.Anchor>
            <DNAButton
              status="tertiary"
              iconLeft={config.icon}
              onPress={() => {
                setVisibleSidebar(config.id)
                setVisibleSearch(false);
              }}
              style={buttonStyle}
            />
          </DNAPopover.Anchor>
          <DNAPopover.Content>
            <DNAText
              style={{ color: 'white' }}
            >{config.label}</DNAText>
          </DNAPopover.Content>
        </DNAPopover>
      )
    })

  return (
    <DNABox
      appearance="col"
      alignX="end"
      style={containerStyle}
    >
      <Iffy is={visibleSidebar}>
        <DNAButton
          status="tertiary"
          iconLeft="chevron-right"
          onPress={() => { setVisibleSidebar(undefined) }}
          style={{
            margin: 16,
            marginBottom: 12,
          }}
        />
      </Iffy>
      <Iffy is={!visibleSidebar}>
        {buttons}
      </Iffy>
    </DNABox>
  )
}

export default Controls
