import {
  AttendeeType,
  ControlType,
  CustomFieldDefinition,
  CustomFieldUsage,
  FieldDataType,
  FieldStatus,
  MeetingAttendeeStatus,
  MeetingStatus,
  ObjectRecordStatus,
} from '@alucio/aws-beacon-amplify/src/models';
import { add, format } from 'date-fns';
import { CustomFieldValuesMap, MeetingORM } from 'src/types/orms';

const childrenAttendeeFieldIds =
  ['id', 'name', 'status', 'attendeeType', 'email', 'crmAccountId', 'crmAddressId', 'updatedAt'];

const meetingCreatedAt =  new Date().toUTCString()

export const fields: CustomFieldDefinition[] = [
  // Meeting Title
  {
    id: 'title',
    fieldLabel: 'Meeting Title',
    fieldName: 'Meeting Title',
    status: FieldStatus.ENABLED,
    required: true,
    maxLength: 1000,
    fieldType: FieldDataType.STRING,
    usage: [CustomFieldUsage.MEETING],
    controlType: ControlType.INPUT,
    fieldValueDefinitions: [],
    createdAt: meetingCreatedAt,
  },
  {
    id: 'startTime',
    fieldName: 'START TIME',
    fieldLabel: 'START TIME',
    status: FieldStatus.ENABLED,
    required: true,
    fieldType: FieldDataType.DATE,
    controlType: ControlType.DATEPICKER,
    usage: [CustomFieldUsage.MEETING],
    fieldValueDefinitions: [],
    createdAt: meetingCreatedAt,
  },
  {
    id: 'endTime',
    fieldName: 'END TIME',
    fieldLabel: 'END TIME',
    status: FieldStatus.ENABLED,
    required: true,
    usage: [CustomFieldUsage.MEETING],
    fieldType: FieldDataType.DATE,
    controlType: ControlType.DATEPICKER,
    fieldValueDefinitions: [],
    createdAt: meetingCreatedAt,
  },
  // Attendees
  {
    id: 'primaryAttendee',
    fieldName: 'Attendee',
    fieldLabel: '',
    status: FieldStatus.ENABLED,
    usage: [CustomFieldUsage.MEETING],
    required: false,
    maxLength: undefined, // We can reuse this as a limit for many attendee entrees we can add?
    fieldType: FieldDataType.OBJECT,
    controlType: ControlType.CUSTOM,
    fieldValueDefinitions: [],
    createdAt: meetingCreatedAt,
    objectSetting: {
      childrenFieldIds : childrenAttendeeFieldIds,
    },
  },
  {
    id: 'additionalAttendees',
    fieldName: 'Attendees',
    fieldLabel: '',
    status: FieldStatus.ENABLED,
    usage: [CustomFieldUsage.MEETING],
    required: false,
    maxLength: undefined, // We can reuse this as a limit for many attendee entrees we can add?
    fieldType: FieldDataType.OBJECT, // Can we reuse MULTICATEGORICAL HERE? | Maybe we should have a Multi Input type
    controlType: ControlType.CUSTOM,
    fieldValueDefinitions: [],
    createdAt: meetingCreatedAt,
    objectSetting: {
      childrenFieldIds : childrenAttendeeFieldIds,
    },
  },
  {
    id: 'id',
    fieldName: 'attendeeId',
    fieldLabel: 'attendeeId',
    status: FieldStatus.ENABLED,
    usage: [CustomFieldUsage.MEETING],
    required: true,
    isChildField: true,
    maxLength: undefined,
    fieldType: FieldDataType.STRING,
    controlType: ControlType.INPUT,
    fieldValueDefinitions: [],
    createdAt: meetingCreatedAt,
  },
  {
    id: 'name',
    fieldName: 'attendeeName',
    fieldLabel: 'attendeeName',
    status: FieldStatus.ENABLED,
    usage: [CustomFieldUsage.MEETING],
    required: true,
    isChildField: true,
    maxLength: undefined,
    fieldType: FieldDataType.STRING,
    controlType: ControlType.INPUT,
    fieldValueDefinitions: [],
    createdAt: meetingCreatedAt,
  },
  {
    id: 'status',
    fieldName: 'attendeeStatus',
    fieldLabel: 'attendeeStatus',
    status: FieldStatus.ENABLED,
    usage: [CustomFieldUsage.MEETING],
    required: true,
    isChildField: true,
    maxLength: undefined,
    fieldType: FieldDataType.STRING,
    controlType: ControlType.INPUT,
    createdAt: meetingCreatedAt,
    fieldValueDefinitions: [
      {
        id: 'attendeeStatusActive',
        value: MeetingAttendeeStatus.ACTIVE,
        label: 'Active',
        disabled: false,
        createdAt: meetingCreatedAt,
        dependentValueIds: [],
      },
      {
        id: 'attendeeStatusRemoved',
        value: MeetingAttendeeStatus.REMOVED,
        label: 'removed',
        disabled: false,
        createdAt: meetingCreatedAt,
        dependentValueIds: [],
      },
    ],
  },
  {
    id: 'attendeeType',
    fieldName: 'attendeeType',
    fieldLabel: 'attendeeType',
    status: FieldStatus.ENABLED,
    usage: [CustomFieldUsage.MEETING],
    required: true,
    isChildField: true,
    maxLength: undefined,
    fieldType: FieldDataType.STRING,
    controlType: ControlType.INPUT,
    createdAt: meetingCreatedAt,
    fieldValueDefinitions: [
      {
        id: 'attendeeTypePrimary',
        value: AttendeeType.PRIMARY,
        label: 'Primary',
        disabled: false,
        createdAt: meetingCreatedAt,
        dependentValueIds: [],
      },
      {
        id: 'attendeeTypeSecondary',
        value: AttendeeType.SECONDARY,
        label: 'Secondary',
        disabled: false,
        createdAt: meetingCreatedAt,
        dependentValueIds: [],
      },
    ],
  },
  {
    id: 'email',
    fieldName: 'attendeeEmail',
    fieldLabel: 'attendeeEmail',
    status: FieldStatus.ENABLED,
    usage: [CustomFieldUsage.MEETING],
    required: false,
    isChildField: true,
    maxLength: undefined,
    fieldType: FieldDataType.STRING,
    controlType: ControlType.INPUT,
    createdAt: meetingCreatedAt,
    fieldValueDefinitions: [],
  },
  {
    id: 'crmAccountId',
    fieldName: 'attendeeCrmAccountId',
    fieldLabel: 'attendeeCrmAccountId',
    status: FieldStatus.ENABLED,
    usage: [CustomFieldUsage.MEETING],
    required: undefined,
    isChildField: true,
    maxLength: undefined,
    fieldType: FieldDataType.STRING,
    controlType: ControlType.INPUT,
    createdAt: meetingCreatedAt,
    fieldValueDefinitions: [],
  },
  {
    id: 'crmAddressId',
    fieldName: 'crmAddressId',
    fieldLabel: 'crmAddressId',
    status: FieldStatus.ENABLED,
    usage: [CustomFieldUsage.MEETING],
    required: undefined,
    isChildField: true,
    maxLength: undefined,
    fieldType: FieldDataType.STRING,
    controlType: ControlType.INPUT,
    createdAt: meetingCreatedAt,
    fieldValueDefinitions: [],
  },
  {
    id: 'updatedAt',
    fieldName: 'updatedAt',
    fieldLabel: 'updatedAt',
    status: FieldStatus.ENABLED,
    usage: [CustomFieldUsage.MEETING],
    required: false,
    isChildField: true,
    maxLength: undefined,
    fieldType: FieldDataType.STRING,
    controlType: ControlType.INPUT,
    createdAt: meetingCreatedAt,
    fieldValueDefinitions: [],
  },
  {
    id: 'status',
    fieldLabel: 'Meeting Status',
    fieldName: 'Meeting Status',
    status: FieldStatus.ENABLED,
    required: true,
    maxLength: 1000,
    fieldType: FieldDataType.CATEGORICAL,
    usage: [CustomFieldUsage.MEETING],
    controlType: ControlType.SELECT,
    fieldValueDefinitions: [{
      id: 'PLANNED',
      value: MeetingStatus.PLANNED,
      label: 'Planned',
      disabled: false,
      createdAt: meetingCreatedAt,
      dependentValueIds: [],
    },
    {
      id: 'ENDED_EXIT',
      value: MeetingStatus.ENDED_EXIT,
      label: 'Completed',
      disabled: false,
      createdAt: meetingCreatedAt,
      dependentValueIds: [],
    },
    {
      id: 'IN_PROGRESS',
      value: MeetingStatus.IN_PROGRESS,
      label: 'In Progress',
      disabled: false,
      createdAt: meetingCreatedAt,
      dependentValueIds: [],
    },
    {
      id: 'ENDED_TIMEOUT',
      value: MeetingStatus.ENDED_TIMEOUT,
      label: 'Completed - Timeout',
      disabled: false,
      createdAt: meetingCreatedAt,
      dependentValueIds: [],
    },
    {
      id: 'LOCKED',
      value: MeetingStatus.LOCKED,
      label: 'Locked',
      disabled: false,
      createdAt: meetingCreatedAt,
      dependentValueIds: [],
    },
    ],
    createdAt: meetingCreatedAt,
  },
];

export const getDefaultValues = (meetingORM?: MeetingORM, isOnGoingMeeting?: boolean,
  isPlannedCRMMeetingEnabled?: boolean): CustomFieldValuesMap => {
  const title = meetingORM?.model.title || (isOnGoingMeeting ? ''
    : `Meeting Started at ${format(new Date(), 'MM/dd/yyyy h:mm aa')}`);
  const startTime = meetingORM ? meetingORM.model.startTime : new Date().toISOString();
  const endTime = !meetingORM ? add(new Date(), { hours: 1 }).toISOString()
    : meetingORM?.model.endTime ? meetingORM.model.endTime! : '';
  const status = meetingORM?.model.status ? meetingORM?.model.status
    : isPlannedCRMMeetingEnabled
      ? MeetingStatus.PLANNED : MeetingStatus.ENDED_EXIT;
  const meetingStatus = MeetingStatus[status];

  const baseObject = {
    title: {
      field: fields[0],
      values: [title],
      displayValues: [title],
    },
    primaryAttendee: {
      field: fields[3],
      values: [],
      displayValues: [],
    },
    additionalAttendees: {
      field: fields[4],
      values: [],
      displayValues: [],
    },
    status: {
      field:  getStatusField(meetingStatus),
      values: [status],
      displayValues: [meetingORM?.model.status || MeetingStatus.PLANNED],
    },
  };

  if (isOnGoingMeeting) {
    return baseObject;
  }

  return {
    ...baseObject,
    startTime: {
      field: fields[1],
      values: [startTime],
      displayValues: [startTime],
    },
    endTime: {
      field: fields[2],
      values: [endTime],
      displayValues: [endTime],
    },
  }
}

export const refine = (partialInput) => {
  if (!partialInput.startTime || !partialInput.endTime) {
    return true;
  }

  const isValidDate = new Date(partialInput.startTime) < new Date(partialInput.endTime)
  return isValidDate
}

const overrideSchema = {
  // [NOTE] - Possible bug in Zod? Generally the path should be able to accept multiple fields (as a string array)
  //          but it doesn't work for either when multiple fields are passed
  //        - For now, just do two separate refinements targeting each field
  // [TODO] - Zod's superRefine may be better here to control/clear out exact errors
  refine: [
    {
      func: refine,
      conf: { message: 'End time must be after start time.', path: ['endTime'] },
    },
  ],
}

const beaconConfig = {
  fields,
  overrideSchema,
}

export const getStatusField = (status: MeetingStatus): CustomFieldDefinition => {
  const customFieldStatus = {
    ...fields[13],
    fieldValueDefinitions: fields[13].fieldValueDefinitions?.map((fieldValueDefinition) => {
      if (status === MeetingStatus.PLANNED) {
        return {
          ...fieldValueDefinition,
          disabled: !(fieldValueDefinition.value === MeetingStatus.PLANNED ||
            fieldValueDefinition.value === MeetingStatus.ENDED_EXIT),
        }
      }
      return fieldValueDefinition
    },
    ),
  }

  return customFieldStatus;
}

export const getSeparatedAttendees = (meetingORM?: MeetingORM): CustomFieldValuesMap => {
  const attendees = meetingORM?.model.attendees
    .filter(attendee => attendee.status !== MeetingAttendeeStatus.REMOVED) || [];

  const result: CustomFieldValuesMap = {
    primaryAttendee: {
      field: fields[3],
      values: [],
      displayValues: [],
      objectValues: [],
    },
    additionalAttendees: {
      field: fields[4],
      values: [],
      displayValues: [],
      objectValues: [],
    },
  };

  if (!attendees.length) return result;

  const ATTENDEE_TYPE =  {
    PRIMARY: 'primaryAttendee',
    SECONDARY: 'additionalAttendees',
  }

  const attendeeFields = Object.keys(attendees[0]);

  attendees.forEach((attendee) => {
    result[ATTENDEE_TYPE[attendee.attendeeType]].objectValues?.push({
      customFieldValues : attendeeFields.reduce((acc, attendeeField) => {
        const field = fields.find((field) => field.id === attendeeField);
        if (field) {
          acc[attendeeField] = {
            field,
            values: attendee[attendeeField] ? [attendee[attendeeField]] : [],
            displayValues: [],
            valuesDefinition: [],
          }
        }
        return acc;
      }, {}),
      id: attendee.id,
      status: ObjectRecordStatus[attendee.status],
    },

    );
  },
  )

  return result
};

export default beaconConfig;
