import { UserRole } from '@alucio/aws-beacon-amplify/src/models';
import { useEffect, useState } from 'react';
import { useCurrentUserORM } from 'src/state/redux/selector/user';
import { getAuthHeaders } from 'src/utils/loadCloudfrontAsset/common';
import { CognitoIdToken } from 'amazon-cognito-identity-js';

const useIsTenantAdmin = () => {
  const [isTenantAdmin, setIsTenantAdmin] = useState(false);
  const currentUser = useCurrentUserORM();

  const checkTenantAdmin = async () => {
    const headers = await getAuthHeaders();
    const userAttributtes = new CognitoIdToken({
      IdToken: headers['Alucio-Authorization'],
    }).decodePayload();

    if (userAttributtes && userAttributtes['cognito:groups']) {
      // cognito group can get stale / not in sync so it's important we check for both values here in the frontend and the backend;
      const isTenantAdminUserORM = currentUser?.model.additionalRoles?.includes(UserRole.TENANT_ADMIN);
      const isTenantAdminCognito = userAttributtes['cognito:groups'].includes(UserRole.TENANT_ADMIN);
      setIsTenantAdmin(isTenantAdminUserORM && isTenantAdminCognito);
    }
  }
  useEffect(() => {
    checkTenantAdmin().catch(console.error);
  }, [])

  return isTenantAdmin;
}

export default useIsTenantAdmin;
