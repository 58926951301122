import { MeetingContentType } from '@alucio/aws-beacon-amplify/src/models';
import { DocumentVersionORMMap, useAllDocumentVersionMap } from 'src/state/redux/selector/document';
import { FolderItemORMMap, useFolderItemORMMap } from 'src/state/redux/selector/folder';

export type PresentableORM = Record<MeetingContentType, FolderItemORMMap | DocumentVersionORMMap>

export const useAllPresentableOrm = () : PresentableORM => {
  const folderItems = useFolderItemORMMap();
  const folderItemMap: FolderItemORMMap = Object.fromEntries(folderItems);

  return {
    CUSTOM_DECK: folderItemMap,
    DOCUMENT_VERSION: useAllDocumentVersionMap(),
    DOCUMENT_VERSION_GROUP: useAllDocumentVersionMap(),
  }
}
