import { compareDesc } from 'date-fns/esm';
import { Notation } from '@alucio/aws-beacon-amplify/src/models';

/**
 * Returns a new sorted notation array in descending order
 * @param notations: an array of notation
 */
export function descendingOrderNotations(notations: Notation[]): Notation[] {
  return notations
    .slice()
    .sort((a, b) => compareDesc(new Date(a.createdAt), new Date(b.createdAt)))
}
