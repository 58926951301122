import { DNABox, DNACard, DNAText, DNAButton, Iffy } from '@alucio/lux-ui';
import React from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { ComposableVariant, composableVariantStyles } from './ComposableForm';

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 18,
    minHeight: 48,
  },
  DNACard: {
    minHeight: 'fit-content',
    borderRadius: 4,
    borderBottomWidth: 1,
  },
});

interface ObjectFieldHeaderProps {
  isCollapsed?: boolean
  setIsCollapsed: (flag: boolean) => void
  title: string;
  variant?: ComposableVariant;
  isRequired?: boolean;
}

export const ObjectFieldHeader = (props: ObjectFieldHeaderProps) => {
  const { title, isCollapsed, setIsCollapsed, isRequired, variant = ComposableVariant.DEFAULT } = props
  const variantStyle = composableVariantStyles[variant]

  const collapseButtonIcon =
  !isCollapsed ? 'chevron-down' : 'chevron-right'
  return (
    <DNABox appearance="col" fill>
      <DNACard
        style={ { ...styles.DNACard, borderBottomWidth : isCollapsed ? 1 : 0 } }
        appearance="outline"
      >
        { /* HEADER */}
        <Pressable onPress={() => setIsCollapsed(!isCollapsed)}>
          <DNABox
            style={styles.wrapper}
            alignY="center"
          >
            <DNABox alignY="center" fill>
              <DNAButton
                status="tertiary"
                appearance="ghost"
                rounded="full"
                padding="xs"
                iconLeft={collapseButtonIcon}
                onPress={() => setIsCollapsed(!isCollapsed)}
              />
              <DNABox fill alignY="center">
                <Iffy is={isRequired}>
                  <DNAText numberOfLines={1} style={variantStyle.required}>*</DNAText>
                </Iffy>
                <DNAText style={variantStyle.title} b1 bold numberOfLines={1}>
                  {title}
                </DNAText>
              </DNABox>
            </DNABox>
          </DNABox>
        </Pressable>
      </DNACard>
    </DNABox>
  )
}
