import React from 'react';
import { DNABox, Iffy } from '@alucio/lux-ui';
import PresentationControls from './PresentationControls/PresentationControls';
import InPersonContent from './InPersonContent/InPersonContent';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import {
  MeetingsStateProvider as MeetingsStateProviderProxy,
} from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import { MeetingType } from '@alucio/aws-beacon-amplify/src/models';
import { ContentProvider } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { HighlighterProvider } from 'src/components/Highlighter/HighlighterProvider';
import { CanvasVariantEnum } from 'src/components/Highlighter/UseCanvas';
import Presenting from './VirtualContent/Presenting';
import VirtualContent from './VirtualContent/VirtualContent';

const MeetingsContent = () => {
  const meetingState = useMeetingsState();
  const contentState = useContent();
  const { meetingORM, actionBarState, isMainPlayer } = meetingState;
  const { activePresentation } = useContent();
  const enableNew3PC = useFeatureFlags('enableNew3PC');

  const addAnalytics = (variant: CanvasVariantEnum) => {
    analytics?.track('MEETING_HIGHLIGHTER', {
      type: variant === CanvasVariantEnum.arrow ? 'Arrow' : 'Box',
      category: 'MEETING',
      meetingId: meetingORM?.model.id,
      documentVersionId: activePresentation?.currentPresentablePage.documentVersionORM.model.id,
      documentId: activePresentation?.currentPresentablePage.documentVersionORM.model.documentId,
      pageId: activePresentation?.currentPresentablePage.id,
      page: activePresentation?.currentPresentablePage.page.number,
    })
  }

  return (
    <DNABox fill childFill={0}>
      <MeetingsStateProviderProxy value={meetingState}>
        <ContentProvider value={contentState}>
          <Iffy is={isMainPlayer}>
            <PresentationControls />
          </Iffy>
          <HighlighterProvider actionBarState={actionBarState} onModeChange={addAnalytics}>
            <Iffy is={meetingORM?.model?.type === MeetingType.IN_PERSON}>
              <InPersonContent />
            </Iffy>
            <Iffy is={meetingORM?.model?.type === MeetingType.VIRTUAL}>
              {enableNew3PC ? <VirtualContent /> : <Presenting />}
            </Iffy>
          </HighlighterProvider>
        </ContentProvider>
      </MeetingsStateProviderProxy>
    </DNABox>
  );
};

MeetingsContent.displayName = 'MeetingsContent';

export default MeetingsContent;
