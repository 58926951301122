import React, { useRef, useEffect, useMemo } from 'react';
import {
  DNADivider,
  DNACard,
  Iffy,
  DNABox,
  DNAButton,
  DNAText,
  Collapsible,
  DNAIcon,
} from '@alucio/lux-ui'
import { statusMap } from 'src/components/DNA/Document/DNADocumentChip'
import { useFAQState, Sections } from './FAQ.context'
import { useAppSettings } from 'src/state/context/AppSettings';
import { StyleProp, ViewStyle } from 'react-native';

/**
 * H1 - Page Title
 * H2 - Section Title
 * H3 - Question Title
 * NOTE - Generally we try to memoize the renders since this content is static
 *  Otherwise render times are pretty bad
 */
export const Header: React.FC<any> = (props) => {
  const { index, level, children, node } = props
  const thisRef = useRef()
  const FAQState = useFAQState()
  const headingSize = { [`h${level + 1}`]: true }

  useEffect(() => {
    FAQState.addCollapse(index)

    // Use H1 as the page title
    if (level === 1)
    { FAQState.setTitle(node.children[0].value) }

    // Any H2 will be added as entry on the nav menu
    if (level === 2)
    { FAQState.pushSection({ ref: thisRef, title: node.children[0].value }) }
  }, [])

  const render = useMemo(() => {
    // Render the Section Title
    if (level === 2) {
      return (
        <DNABox
          ref={thisRef}
          style={{ marginBottom: 24, marginTop: index > 1 ? 72 : 0 }}
        >
          <DNAText {...headingSize}>
            { children }
          </DNAText>
        </DNABox>

      )
    }

    // Render the Question Card
    if (level === 3) {
      return (
        <DNACard
          appearance="flat"
          interactive="pressable"
          onPress={() => FAQState.toggleCollapse(index)}
          style={{
            flexDirection: 'row',
            backgroundColor: '#FAFBFC',
            padding: 16,
            marginTop: 8,
          }}
        >
          <DNABox alignY="center" spacing="sm">
            <DNAButton
              onPress={() => FAQState.toggleCollapse(index)}
              appearance="ghostLink"
              padding="none"
              size="md"
              iconLeft={
                FAQState.collapseMap[index] === false
                  ? 'chevron-up'
                  : 'chevron-right'
              }
              style={{ paddingTop: 4 }}
            />
            <DNAText b1 bold>{props.children}</DNAText>
          </DNABox>
        </DNACard>
      )
    }

    // H4+ is not supported currently
    return null;
  }, [FAQState.collapseMap[index], index])

  return render
}

export const List: React.FC<any> = (props) => {
  const { index, children, depth, minimizePadding } = props
  const FAQState = useFAQState()

  // We can generally tell if a List is nested by the parent count
  // Might be possible to also just wrap the children in containers with left padding
  const isNestedList = depth > 0

  const minimalOffsets = !isNestedList
    ? { paddingHorizontal: 24, paddingBottom: 10, paddingTop: 2 }
    : { paddingHorizontal: 12, paddingTop: 8 }

  const offsets = !isNestedList
    ? { paddingHorizontal: 48, paddingBottom: 16 }
    : { paddingTop: 8 }

  const render = useMemo(() => (
    <DNABox
      // fill
      appearance="col"
      spacing="sm"
      style={[{ backgroundColor: 'white' }, minimizePadding ? minimalOffsets : offsets]}
    >
      { children.filter(child => child !== '\n') }
    </DNABox>
  ), [FAQState.collapseMap[index - 1]])

  // Only use collapsible for root level lists
  return !isNestedList && !minimizePadding
    ? (
      <Collapsible collapsed={FAQState.collapseMap[index - 1] ?? true}>
        { render }
      </Collapsible>
    )
    : render
}

export const ListItem: React.FC<any> = (props) => {
  const { index, children } = props

  const render = useMemo(() => {
    const nonListChildren = React.Children
      .toArray(children) // @ts-ignore
      .filter(child => !child?.key?.includes('list'))

    const nestedLists = React.Children
      .toArray(children) // @ts-ignore
      .filter(child => child?.key?.includes('list'))

    return (
      <DNABox fill>
        <DNAText status="secondary" style={{ marginRight: 8 }}>•</DNAText>
        <DNABox appearance="col" fill>
          <DNABox>
            {/*
              This assumes all Non-List children are <Text> based
              which allows us to nest <Text> within each other
            */}
            <DNAText status="secondary">
              { nonListChildren as any }
            </DNAText>
          </DNABox>
          <Iffy is={nestedLists.length}>
            <DNABox fill style={{ marginLeft: 16 }}>
              { nestedLists }
            </DNABox>
          </Iffy>
        </DNABox>
      </DNABox>
    )
  }, [index])

  return render
}

export const Link = (props) => {
  return (
    <DNAText
      status="primary"
      accessibilityRole="link"
      href={props.href}
    >
      {props.children}
    </DNAText>
  )
}

export const Text = (props) => {
  // Other components render Text as children (Header, Link, etc)
  // Let those components handle how to style the text
  if (props.index > -1)
  { return props.children }

  // This is essentially <p> style text
  return (
    <DNAText b1 status="secondary">
      { props.children }
    </DNAText>
  )
}

export const Strong = (props) => {
  // Pretty specific work-around to allow coloring for targeted text
  const [firstChild] = props.children
  const [tagType] = firstChild.split(' ')
  const tagStyle = statusMap.acronym[tagType] || 'subtle'

  return (
    <DNAText b1 bold status={tagStyle}>
      { props.children }
    </DNAText>
  )
}

const scrollToSection = (scrollRef: any, section: any, scrollYOffset: number) => {
  section.ref.current.measure(
    (fx, fy, w, h, px, py) => {
      const scrollTop = scrollRef?.current?.getScrollableNode().scrollTop
      scrollRef?.current?.scrollTo({ y:py - scrollYOffset + scrollTop, animated:true })
    },
  )
}

export const SectionNav = React.memo((props: { scrollRef: any, sections: Sections }) => {
  // Since this is rendered at the root
  // It doesn't need to use the context
  const { scrollRef, sections } = props
  const scrollYOffset = 24

  // Desktop Variant
  const Desktop: React.FC = () => {
    return (
      <DNABox appearance="col" style={{ marginRight: 48 }}>
        {
          sections.map(section => (
            <>
              <DNADivider />
              <DNAButton
                appearance="ghostLink"
                key={section.title}
                style={{ marginVertical: 8, alignSelf: 'flex-start' }}
                onPress={() => {
                  scrollToSection(scrollRef, section, scrollYOffset)
                }}
              >
                {section.title}
              </DNAButton>
            </>
          ))
        }
        <DNADivider />
      </DNABox>)
  }

  // Tablet Variant
  const Tablet: React.FC = () => {
    const sectionLinks = sections.map((section, idx) => {
      const isFirst = idx === 0
      const isLast = idx === sections.length - 1
      let borderRadiusStyle:StyleProp<ViewStyle>
      const defaultRadius = 8
      if (isFirst) {
        borderRadiusStyle = { borderRadius:0, borderTopLeftRadius: defaultRadius, borderTopRightRadius: defaultRadius }
      } else if (isLast) {
        borderRadiusStyle = {
          borderRadius:0,
          borderBottomLeftRadius: defaultRadius,
          borderBottomRightRadius: defaultRadius,
        }
      } else {
        borderRadiusStyle = { borderRadius: 0 }
      }

      return (
        <>
          <DNACard
            style={[{ padding:16, shadowOpacity: 0 }, borderRadiusStyle]}
            interactive="pressable"
            onPress={() => {
              scrollToSection(scrollRef, section, 100)
            }}
          >
            <DNABox spacing="between" alignY="center">
              <DNAText
                status="primary"
              >
                {section.title}
              </DNAText>
              <DNAIcon.Styled
                name="chevron-right"
                appearance="ghost"
                status="primary"
              />
            </DNABox>
          </DNACard>
          <Iffy is={!isLast}>
            <DNADivider />
          </Iffy>
        </>
      ) })

    return (
      <DNABox fill appearance="col">
        <DNAText h2 style={{ marginBottom: 24 }}>Table of Contents</DNAText>
        {sectionLinks}
      </DNABox>
    )
  }

  const Switcher: React.FC = () => {
    const { deviceMode } = useAppSettings()

    return deviceMode === 'desktop'
      ? <Desktop />
      : <Tablet />
  }

  return (
    <Switcher />
  )
})
