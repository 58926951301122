import React, { useMemo } from 'react';
import MyContentPanel from 'src/components/MyContentPanel/MyContentPanel';
import DocumentSearchProvider from 'src/hooks/useDocumentSearchV2';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';

const BrowseContent = () => {
  const { addPresentation } = useContent();
  const MemoizedPanel = useMemo(() => (
    <DocumentSearchProvider mode="MEETING">
      <MyContentPanel
        variant="Meetings"
        displayContentPanel={true}
        onSelectPresentableORM={addPresentation}
      />
    </DocumentSearchProvider>
  ), []);

  return MemoizedPanel;
};

BrowseContent.displayName = 'BrowseContent';

export default BrowseContent;
