import React, { createContext, useContext, useState, useCallback, useMemo, useEffect, PropsWithChildren } from 'react';
import im from 'immer';
import { Page, PageGroup } from '@alucio/aws-beacon-amplify/src/models';
import { useDispatch } from 'src/state/redux';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as slideSettingsSelector from 'src/state/machines/publisherVersioning/SlideSettings/slideSettings.selectors';
import { useSlideSettingsState } from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import AddGroupModal from './AddGroupModal';

export type DraftPageGroup = Omit<PageGroup, 'id'>

export type GroupContextType = {
  showEmptyMessage: boolean,
  selectedPageIds: string[],
  selectedPagesMap: Record<string, boolean>,
  pages: Page[],
  groups: DraftPageGroup[],
  latestAddedGroup?: string,
  collapsedGroups: Record<string, boolean>,
  toggleCollapsedGroups: (groupName?: string) => void,
  isExpanded: boolean,
  toggleExpanded: () => void,
  addGroupHandler: (pageIds?: string[]) => void,
  poolItemIds: string[],
  pagesMap: Record<string, Page>,
  groupItemIds: Record<string, string[]>,
  overContainer: string | null,
  setOverContainer: React.Dispatch<React.SetStateAction<string|null>>
}

const GroupContext = createContext<GroupContextType>(undefined!);

export const GroupsProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const { service, addGroup } = useSlideSettingsState()

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      slideSettingsSelector.versionDraft,
      slideSettingsSelector.groupings,
      slideSettingsSelector.isSetGroupSlidesMode,
    ),
  )
  const pages = cond.versionDraft.pages

  const [isExpanded, setIsExpanded] = useState(false)
  const [collapsedGroups, setCollapsedGroups] = useState<Record<string, boolean>>({})
  const [latestAddedGroup, setLatestAddedGroup] = useState<string>()
  const [overContainer, setOverContainer] = useState<string|null>(null)

  useEffect(() => setIsExpanded(false), [cond.isSetGroupSlidesMode])

  useEffect(() => {
    setCollapsedGroups(p => Object
      .keys(cond.groupings.groups)
      .reduce<Record<string, boolean>>(
        (acc, groupName) => ({
          ...acc,
          [groupName]: !!p[groupName],
        }),
        { },
      ),
    )
  }, [cond.groupings.groups])

  const selectedPageIds = useMemo(() => {
    return Object
      .entries(cond.groupings.selectedPages)
      .filter(([_, selected]) => selected)
      .map(([pageId]) => pageId)
      .sort((a, b) => a.localeCompare(b, 'en', { numeric: true }))
  }, [cond.groupings.selectedPages])

  const selectedPagesMap = useMemo<Record<string, boolean>>(() => {
    return Object
      .entries(cond.groupings.selectedPages)
      .reduce(
        (acc, [key, value]) => ({ ...acc, [key]: !!value }),
        {},
      )
  }, [cond.groupings.selectedPages])

  const poolItemIds = useMemo<string[]>(() => cond.versionDraft.pages.map(p => p.pageId), [pages])

  const groups = useMemo(() => {
    return Object
      .values(cond.groupings.groups)
      .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
  }, [cond.groupings.groups])

  const groupItemIds = useMemo(() => {
    return groups.reduce<Record<string, string[]>>(
      (acc, group) => ({
        ...acc,
        [group.name]: group.pageIds ?? [],
      }),
      {},
    )
  }, [groups])

  const pagesMap = useMemo<Record<string, Page>>(() => {
    return pages
      .reduce<Record<string, Page>>(
        (acc, page) => ({ ...acc, [page.pageId]: page }),
        {},
      )
  }, [pages])

  const addGroupHandler = useCallback((pageIds?: string[]): void => {
    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: false,
      component: (props) => (
        <AddGroupModal
          groupNames={groups.map(group => group.name)}
          mode="add"
          onSuccess={(name) => {
            addGroup(name, pageIds)
            setLatestAddedGroup(name)
          }}
          {...props}
        />
      ),
    }),
    )
  }, [groups, addGroup])

  const toggleExpanded = useCallback(() => setIsExpanded(p => !p), [])

  const toggleCollapsedGroups = useCallback((groupName?: string) => {
    setCollapsedGroups(im(draft => {
      if (groupName)
      { draft[groupName] = !draft[groupName] }
      else {
        const shouldCollapseAll = Object
          .values(draft)
          .some(val => !val)

        for (const name in draft) {
          draft[name] = shouldCollapseAll
        }
      }

      return draft
    }))
  }, [])

  const showEmptyMessage = groups.length === 0

  const value: GroupContextType = {
    addGroupHandler,
    groups,
    collapsedGroups,
    toggleCollapsedGroups,
    isExpanded,
    latestAddedGroup,
    pages,
    selectedPageIds,
    selectedPagesMap,
    toggleExpanded,
    showEmptyMessage,
    poolItemIds,
    groupItemIds,
    pagesMap,
    overContainer,
    setOverContainer,
  }

  return (
    <GroupContext.Provider value={value}>
      {children}
    </GroupContext.Provider>
  );
}

const useGroups = () => {
  const ctx = useContext(GroupContext);
  return ctx
}

export default useGroups;
