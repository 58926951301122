import React, { createContext, useContext } from 'react'

/**
 * A way to proxy providers due to certain modal implementations.
 * Some modal implementations render modals beside their root providers (near the top of the app's render tree)
 * which can cause issues with context usage because the context is often
 * not portal'd alongside the modal content (i.e. it consumes a bit further up the tree)
 * When the modal content is portal'd it not longer under the context thus cannot consume the context correctly
 *
 * One can always prop drill context values into the portal'd content
 * but prop drilling is far from ideal
 *
 * Instead, we can proxy the context by creating anoter context and forwarding
 * its value. We can include this proxied copy with the content to be portal'd
 *
 * This requires components to used a "proxied" copy of the various `useContext` hooks however
 * @param contextName
 */
function createContextProxy<T>(contextName: string) {
  const ContextProxy = createContext<T>(null!)

  function ContextProxyProvider(props: React.PropsWithChildren<{ value: T }> ) {
    const { value } = props

    return (
      <ContextProxy.Provider value={value}>
        { props.children }
      </ContextProxy.Provider>
    )
  }

  ContextProxyProvider.displayName = `${contextName}Provider`

  const useProxy = () => useContext<T>(ContextProxy)

  return {
    ContextProxy,
    ContextProxyProvider,
    useProxy,
  }
}

export default createContextProxy
