import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Icon } from '@alucio/lux-ui'
import { useContentViewerModalState } from 'src/components/ContentPreviewModal/state/ContentViewerModalStateProvider'
import { useDispatch } from 'src/state/redux'
import { presentationControlActions } from 'src/state/redux/slice/PresentationControl/presentationControl'
import { PlayerActions } from '@alucio/core'

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
  iconContainer: {
    position: 'absolute',
    flexDirection: 'row',
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    margin: 0,
    marginBottom: 8,
    padding: 0,
    width: 181,
    height: 40,
    borderRadius: 4,
  },
  icon: {
    color: 'white',
    width: 32,
    height: 32,
  },
  slideCounter: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
  },
  wrapper: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

const Controls = () => {
  const { totalSlides, adjustedSlideNumber } = useContentViewerModalState()
  const dispatch = useDispatch()

  return (
    <View style={styles.wrapper}>
      <TouchableOpacity
        key={'content_nav_icon_0'}
        style={styles.button}
        onPress={() => dispatch(presentationControlActions.triggerPresentationAction({ action:PlayerActions.prev }))}
      >
        <Icon name="chevron-left" style={styles.icon} />
      </TouchableOpacity>
      {/* Conditionally switch to index of visibleSlides+1 */}
      <Text testID="content-navigation-text" style={styles.slideCounter}>{adjustedSlideNumber} of {totalSlides}</Text>
      <TouchableOpacity
        key={'content_nav_icon_1'}
        style={styles.button}
        onPress={() => dispatch(presentationControlActions.triggerPresentationAction({ action:PlayerActions.next }))}
      >
        <Icon name="chevron-right" style={styles.icon} />
      </TouchableOpacity>
    </View>
  )
}

export default Controls
