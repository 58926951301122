import React, { createContext, useContext } from 'react'

export type FAQState = {
  collapseMap: CollapseMap,
  sections: Sections,
  title?: string,
  setTitle: (f: string) => void,
  addCollapse: (idx: number) => void,
  toggleCollapse: (idx: number) => void,
  pushSection: (arg: { ref: any, title: string }) => void,
}

export type CollapseMap = {
  [key: number]: boolean
}

export type Sections = {
  ref: React.MutableRefObject<any>,
  title: string
}[]

export const FAQStateContext = createContext<FAQState>({
  collapseMap: {},
  sections: [],
  title: undefined,
  setTitle: () => {},
  addCollapse: () => {},
  toggleCollapse: () => {},
  pushSection: () => {},
})
FAQStateContext.displayName = 'FAQStateContext'

export const useFAQState = () => useContext(FAQStateContext)
