import { DNAFlatListRows } from 'src/components/DNA/FlatList/Rows/types'

import DNADocumentSearchRow from './DNADocumentSearchRow'
import DNADocumentVersionSearchRow from './DNADocumentVersionSearchRow'
import DNAFolderVirtualRow from './DNAFolderSearchRow'
import DNADocumentVersionCustomDeckSearchRow from './DNADocumentVersionCustomDeckSearchRow'
import { DNADivider } from '@alucio/lux-ui';
import DNAFileDrawerDefaultRow from '../fileDrawer/DNAFileDrawerDefaultRow'

export const DNASearchRows: DNAFlatListRows = {
  DNADocumentRow: DNADocumentSearchRow,
  DNADocumentFolderItemRow: DNADocumentVersionSearchRow,
  DNADocumentFolderItemCustomDeckRow: DNADocumentVersionCustomDeckSearchRow,
  DNAFolderRow: DNAFolderVirtualRow,
  DNAListDivider: DNADivider,
  DNAFileDrawerRow: DNAFileDrawerDefaultRow,
}

export default DNASearchRows
