import { ViewStyle, ImageStyle, TextStyle, StyleSheet } from 'react-native';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { DNABoxProps } from '@alucio/lux-ui/lib/components/layout/DNABox/DNABox';

export enum SLIDE_ROLL_VARIANT {
  DEFAULT,
  MEETINGS,
  PREVIEW_MODAL,
  MEETING_PRESENTED_EMPTY,
}

type ThumbnailStyles = Record<ThumbnailComponentStyles, ViewStyle | ImageStyle | TextStyle>;

type ThumbnailComponentStyles =
  | 'container'
  | 'innerContainer'
  | 'selected'
  | 'unselected'
  | 'pageText'
  | 'pageTextSelected'
  | 'pressableContainer'
  | 'thumbnail'

export enum ThumbnailNumberPosition {
  TOP_LEFT,
  TOP_RIGHT,
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
  LEFT_TOP,
  LEFT_BOTTOM,
  RIGHT_TOP,
  RIGHT_BOTTOM,
}

export const thumbnailNumberPositionProps: Record<
  ThumbnailNumberPosition,
  Pick<DNABoxProps, 'appearance' | 'alignX' | 'alignY'>
> = {
  [ThumbnailNumberPosition.TOP_LEFT]: {
    appearance: 'colReverse',
  },
  [ThumbnailNumberPosition.TOP_RIGHT]: {
    appearance: 'colReverse',
    // [TODO]: DNABOX NOT ADDING THE END
    alignX: 'end',
  },
  [ThumbnailNumberPosition.BOTTOM_LEFT]: {
    appearance: 'col',
  },
  [ThumbnailNumberPosition.BOTTOM_RIGHT]: {
    appearance: 'col',
    alignX: 'end',
  },
  [ThumbnailNumberPosition.LEFT_TOP]: {
    appearance: 'rowReverse',
  },
  [ThumbnailNumberPosition.LEFT_BOTTOM]: {
    appearance: 'rowReverse',
    // [TODO]: DNABOX NOT ADDING THE END
    alignY: 'end',
  },
  [ThumbnailNumberPosition.RIGHT_TOP]: {
    appearance: 'row',
  },
  [ThumbnailNumberPosition.RIGHT_BOTTOM]: {
    appearance: 'row',
    alignY: 'end',
  },
}

interface ThumbnailSettings {
  thumbnailHeight: number,
  thumbnailWidth: number,
  numberPosition: ThumbnailNumberPosition
}

// ***  THUMBNAILS SETTINGS   *** //
// * DEFAULT THUMBNAIL SETTINGS * //
const defaultThumbnailSettings: ThumbnailSettings = {
  thumbnailHeight: 67,
  thumbnailWidth: 120,
  numberPosition: ThumbnailNumberPosition.BOTTOM_LEFT,
}

// * MEETINGS THUMBNAIL SETTINGS * //
const meetingsThumbnailSettings: ThumbnailSettings = {
  thumbnailHeight: 190,
  thumbnailWidth: 336,
  numberPosition: ThumbnailNumberPosition.BOTTOM_LEFT,
}

// * DEFAULT THUMBNAIL SETTINGS * //
const previewModalThumbnailSettings: ThumbnailSettings = {
  thumbnailHeight: 134,
  thumbnailWidth: 240,
  numberPosition: ThumbnailNumberPosition.BOTTOM_LEFT,
}

export const thumbnailVariantSettings: Record<SLIDE_ROLL_VARIANT, ThumbnailSettings> = {
  [SLIDE_ROLL_VARIANT.DEFAULT]: defaultThumbnailSettings,
  [SLIDE_ROLL_VARIANT.MEETINGS]: meetingsThumbnailSettings,
  [SLIDE_ROLL_VARIANT.PREVIEW_MODAL]: previewModalThumbnailSettings,
  [SLIDE_ROLL_VARIANT.MEETING_PRESENTED_EMPTY]: meetingsThumbnailSettings,
}

// ***  THUMBNAILS STYLES  *** //
// * DEFAULT THUMBNAIL STYLE * //
const defaultThumbnailStyles: ThumbnailStyles = {
  pressableContainer: {
    alignSelf: 'flex-start',
  },
  thumbnail: {
    // [TODO] - Check with Thai how portrait thumbnails should be handled?
    backgroundColor: colors['color-black'],
  },
  container: {
    width: thumbnailVariantSettings[SLIDE_ROLL_VARIANT.DEFAULT].thumbnailWidth + 8,
    height: thumbnailVariantSettings[SLIDE_ROLL_VARIANT.DEFAULT].thumbnailHeight + 8,
  },
  innerContainer: {
    width: thumbnailVariantSettings[SLIDE_ROLL_VARIANT.DEFAULT].thumbnailWidth + 3,
    height: thumbnailVariantSettings[SLIDE_ROLL_VARIANT.DEFAULT].thumbnailHeight + 3,
  },
  selected: {
    backgroundColor: colors['color-brand2-500'],
  },
  unselected: {
    backgroundColor: colors['color-gray-300'],
  },
  pageText: {
    marginLeft: 3,
    color: colors['color-black'],
  },
  pageTextSelected: {
    color: colors['color-black'],
  },
}

// * MEETING THUMBNAIL STYLE * //
const meetingsStyle: ThumbnailStyles = {
  ...defaultThumbnailStyles,
  container: {
    backgroundColor: colors['color-black'],
    width: thumbnailVariantSettings[SLIDE_ROLL_VARIANT.MEETINGS].thumbnailWidth + 8,
    height: thumbnailVariantSettings[SLIDE_ROLL_VARIANT.MEETINGS].thumbnailHeight + 8,
  },
  innerContainer: {
    width: thumbnailVariantSettings[SLIDE_ROLL_VARIANT.MEETINGS].thumbnailWidth + 3,
    height: thumbnailVariantSettings[SLIDE_ROLL_VARIANT.MEETINGS].thumbnailHeight + 3,
  },
  selected: {
    backgroundColor: colors['color-success-500'],
  },
  pageText: {
    marginLeft: 3,
    color: colors['color-gray-300'],
  },
  pageTextSelected: {
    color: colors['color-gray-300'],
  },
};

// * MEETING THUMBNAIL STYLE * //
const previewModalStyle: ThumbnailStyles = {
  ...defaultThumbnailStyles,
  container: {
    backgroundColor: colors['color-text-white'],
    width: thumbnailVariantSettings[SLIDE_ROLL_VARIANT.PREVIEW_MODAL].thumbnailWidth + 8,
    height: thumbnailVariantSettings[SLIDE_ROLL_VARIANT.PREVIEW_MODAL].thumbnailHeight + 8,
  },
  innerContainer: {
    width: thumbnailVariantSettings[SLIDE_ROLL_VARIANT.PREVIEW_MODAL].thumbnailWidth + 3,
    height: thumbnailVariantSettings[SLIDE_ROLL_VARIANT.PREVIEW_MODAL].thumbnailHeight + 3,
  },
  selected: {
    backgroundColor: colors['color-success-500'],
  },
  pageText: {
    marginLeft: 3,
    color: colors['color-gray-200'],
  },
  pageTextSelected: {
    color: colors['color-gray-200'],
  },
};

export const thumbnailVariantStyles: Record<SLIDE_ROLL_VARIANT, ThumbnailStyles> = {
  [SLIDE_ROLL_VARIANT.DEFAULT]: StyleSheet.create(defaultThumbnailStyles),
  [SLIDE_ROLL_VARIANT.MEETINGS]: StyleSheet.create(meetingsStyle),
  [SLIDE_ROLL_VARIANT.PREVIEW_MODAL]: StyleSheet.create(previewModalStyle),
  [SLIDE_ROLL_VARIANT.MEETING_PRESENTED_EMPTY]: StyleSheet.create(meetingsStyle),
}
